import PropTypes from 'prop-types';
import numeral from 'numeral';
import pick from 'lodash/pick';
import context from '../../../../../../shared/component/context';
import {generateChartTitle} from '../lib/chart-title-generator';
import DoublePanelsContainer, {
  getDoublePanelsStyle,
  getRightDoublePanelsStyle
} from '../common/layout/double-panels-container-component';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {
  graphBlue2Area,
  graphBlue2Main,
  graphBlue3Main,
  graphColor6,
  graphElectricPurple,
  graphGreen,
  graphRedMain,
  graphPhilippineGray,
  graphYellow
} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'newCustomers';

/**
 * Finally, the component.
 */
class InsightNewCustomersComponent extends InsightPageComponent {
  _getInboundCustomersSeriesConfiguration = (chartType) => {
    const {enableUIWhatsAppChannelFeature} = this.props;
    const schema = [
      {
        color: graphBlue2Main,
        evolution: 'phoneCall',
        pie: 'phoneCallPercent'
      },
      {
        color: graphPhilippineGray,
        evolution: 'gbm',
        pie: 'gbmPercent'
      },
      {
        color: graphElectricPurple,
        evolution: 'messenger',
        pie: 'messengerPercent'
      },
      {
        color: graphColor6,
        enable: enableUIWhatsAppChannelFeature,
        evolution: 'whatsapp',
        pie: 'whatsappPercent'
      },
      {
        color: graphGreen,
        evolution: 'sms',
        pie: 'smsPercent'
      },
      {
        color: graphYellow,
        evolution: 'widget',
        pie: 'widgetPercent'
      },
      {
        color: graphRedMain,
        evolution: 'sdk',
        pie: 'sdkPercent'
      },
      {
        color: graphBlue3Main,
        evolution: 'others',
        pie: 'othersPercent'
      }
    ];

    return (
      schema
        // Drop items disabled
        .filter(({enable}) => enable || typeof enable === 'undefined')
        // Transform and keep only props useful for a Highcharts prop "series"
        .map((item) => ({
          color: item.color,
          property: item[chartType]
        }))
    );
  };

  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      isSmallScreen,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    const {failed, loading, newInboundCustomers, newOutboundCustomers} =
      insight.newCustomersHistogram;

    const newInboundCustomersFigure = newInboundCustomers.newInboundCustomers;

    const isClassicPeriod = periodTabSelected === 'classic';
    const aggregationPeriod = isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod;

    const seriesInboundPieConfiguration = this._getInboundCustomersSeriesConfiguration('pie');
    const seriesInboundPieProperties = seriesInboundPieConfiguration.map(({property}) => property);

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <DoublePanelsContainer>
            <InsightPanelContentBlock
              dataTestId="new-inbound-customers-pie"
              noEffect
              style={getDoublePanelsStyle(isSmallScreen)}
            >
              <HistogramChart
                items={pick(newInboundCustomers, seriesInboundPieProperties)}
                legendEnabled={false}
                translations={i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.newInboundCustomers.charts.pie`,
                  {returnObjects: true}
                )}
                type="pie"
                valueSuffix="%"
                {...{
                  aggregationPeriod,
                  failed,
                  loading
                }}
                title={generateChartTitle({
                  figure: numeral(newInboundCustomersFigure).format(),
                  hasData: newInboundCustomersFigure !== null,
                  label: i18n.t(`insight.pages.${INSIGHT_PAGE}.newInboundCustomers.charts.title`),
                  showVariation: isClassicPeriod,
                  variation: newInboundCustomers.newInboundCustomersIncrement
                })}
                series={seriesInboundPieConfiguration}
              />
            </InsightPanelContentBlock>

            <InsightPanelContentBlock
              dataTestId="new-inbound-customers-evolution"
              noEffect
              style={getRightDoublePanelsStyle(isSmallScreen)}
            >
              <HistogramChart
                items={newInboundCustomers.newInboundCustomersHistogram}
                legendVerticalAlign={isSmallScreen ? 'top' : undefined}
                stacking="normal"
                translations={i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.newInboundCustomers.charts.histogram`,
                  {returnObjects: true}
                )}
                {...{
                  aggregationPeriod,
                  failed,
                  loading
                }}
                series={this._getInboundCustomersSeriesConfiguration('evolution')}
              />
            </InsightPanelContentBlock>
          </DoublePanelsContainer>

          <InsightPanelContentBlock dataTestId="new-outbound-customers">
            <HistogramChart
              items={newOutboundCustomers.newOutboundCustomersHistogram}
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.newOutboundCustomers.chart`, {
                returnObjects: true
              })}
              type="column"
              {...{
                aggregationPeriod,
                failed,
                loading
              }}
              title={generateChartTitle({
                figure: numeral(newOutboundCustomers.newOutboundCustomers).format(),
                hasData: newOutboundCustomers.newOutboundCustomersHistogram.length,
                label: i18n.t(`insight.pages.${INSIGHT_PAGE}.newOutboundCustomers.title`),
                showVariation: isClassicPeriod,
                variation: newOutboundCustomers.newOutboundCustomersIncrement
              })}
              series={[
                {
                  property: 'newOutboundCustomers',
                  color: graphBlue2Main,
                  fillColor: graphBlue2Area
                }
              ]}
            />
          </InsightPanelContentBlock>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightNewCustomersComponent.contextTypes = context;

InsightNewCustomersComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  enableUIWhatsAppChannelFeature: PropTypes.bool.isRequired,
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightNewCustomersComponent;
