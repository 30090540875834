import truncate from 'lodash/truncate';

/**
 * Simplified truncate
 *
 * @param {String} text
 * @param {Number} maxLength
 * @returns {String} truncated text
 */
export default function simpleTruncate(text, maxLength = 20) {
  return truncate(text, {length: maxLength});
}
