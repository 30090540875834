import axios from 'axios';
import {from} from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import currentPlatform from '../lib/current-platform';
import logout from '../lib/logout';
import {SIGN_IN_URL} from '../data/route';
import {TIMEOUT_REQUEST_DEFAULT} from '../data/settings';

export const getAuthenticationHeader = (token) => {
  return {
    'authentication-token': token
  };
};

/**
 * Get middle-end accessors.
 *
 * @param {String} token
 * @returns {Object}
 */
export default function middleEndAccessors(token = null) {
  const middleend = axios.create({
    baseURL: MIDDLE_END_URL,
    timeout: TIMEOUT_REQUEST_DEFAULT,
    headers: {
      application: 'desktop',
      'Instaply-Source-Client': 'Instaply-Webapp',
      'Instaply-Source-Client-OS': currentPlatform.osFamily,
      'Instaply-Source-Client-OS-Version': currentPlatform.osVersion,
      'Instaply-Source-Client-Version': REVISION,
      ...(token ? getAuthenticationHeader(token) : null)
    },
    paramsSerializer(params) {
      return qs.stringify(params, {
        arrayFormat: 'repeat'
      });
    }
  });

  middleend.interceptors.request.use(function onRequestFulfilled(config) {
    const configCopy = cloneDeep(config);
    if (localStorage.getItem('instaplyDesktop.fake-server-error') === 'on') {
      configCopy.headers['x-fake-error'] = 'on';
    }

    return configCopy;
  });

  middleend.interceptors.response.use(
    function onResponseFulfilled(response) {
      if (response.data && response.data.errors) {
        const errors = cloneDeep(response.data.errors);

        // is specific (to sign in) and a bit ugly for the moment, but may be reviewed and extended later
        const lastModified = response.headers['last-modified'];
        if (lastModified) {
          errors[0]._serverDate = lastModified;
        }

        throw errors;
      }

      return response;
    },
    function onResponseRejected(error) {
      if (error.response && error.response.status === 401) {
        // prevent authentication error infinite loop if user token is invalid
        if (error.response.config.url.indexOf('get-app-initial-state') > -1) {
          logout();
        }

        window.location = SIGN_IN_URL;
      }

      throw error;
    }
  );

  return {
    query(name, params = {}) {
      return from(
        middleend({
          method: 'get',
          url: `/query/${name}`,
          params
        })
      );
    },
    command(name, data = {}) {
      return from(
        middleend({
          method: 'post',
          url: `/command/${name}`,
          data
        })
      );
    },
    graphql(query, variables, options) {
      const fakeTimeout = localStorage.getItem('instaplyDesktop.fake-request-timeout');
      const urlSuffix = /\s/.test(query) ? '' : `?${query}`;

      return from(
        middleend({
          method: 'post',
          url: `/graphql${urlSuffix}`,
          ...(fakeTimeout ? {timeout: fakeTimeout} : null),
          ...options,
          data: {
            query,
            variables
          }
        }).then(({data}) => data.data)
      );
    }
  };
}
