import PropTypes from 'prop-types';
import Radium from 'radium';
import Link from '../../../common/link/link-component';
import {AdministrationLeftMenuItemLayout} from './administration-left-menu-item-component';
import {blue} from '../../../../../../shared/style/colors';

const AdministrationLeftMenuShowAllItemsComponent = Radium(
  ({dataTestId, expandItems, itemLabel, itemUrl, doShowMore}) => {
    const childStyle = {
      ':hover': {
        color: blue
      },
      color: '#666',
      fontSize: '10px',
      paddingTop: '10px',
      textTransform: 'uppercase'
    };

    return (
      <AdministrationLeftMenuItemLayout>
        {expandItems ? (
          <span data-test-id={dataTestId} onClick={doShowMore} style={childStyle}>
            {itemLabel}
          </span>
        ) : (
          <Link data-test-id={dataTestId} href={itemUrl} label={itemLabel} style={childStyle} />
        )}
      </AdministrationLeftMenuItemLayout>
    );
  }
);

AdministrationLeftMenuShowAllItemsComponent.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  expandItems: PropTypes.bool.isRequired,
  itemLabel: PropTypes.string.isRequired,
  itemUrl: PropTypes.string.isRequired,
  doShowMore: PropTypes.func
};

export default AdministrationLeftMenuShowAllItemsComponent;
