import canSeeThreadsFromMoreThanOneBusiness from '../can-see-threads-from-more-than-one-business';

const canShowRankingTable = (periodTabSelected, account) => {
  if (periodTabSelected !== 'classic') {
    return false;
  }

  return canSeeThreadsFromMoreThanOneBusiness(account);
};

const getFilterInsightsCanShowRankingTable = (state$) => () => {
  const state = state$.value;

  const account = state.get('account').toJS();
  const periodTabSelected = state.getIn(['insight', 'periodTabSelected']);

  return canShowRankingTable(periodTabSelected, account);
};

export {canShowRankingTable, getFilterInsightsCanShowRankingTable};
