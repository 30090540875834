import PropTypes from 'prop-types';
import ContentImportantMessageComponent from './content/content-important-message-component';

const ErrorMessageAdvancedComponent = (props) => (
  <ContentImportantMessageComponent data-test-id="error-message" type="error" {...props} />
);

ErrorMessageAdvancedComponent.propTypes = {
  'data-test-id': PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default ErrorMessageAdvancedComponent;
