import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import moment from 'moment';
import customerInboxThreadsSorter from '../../../lib/customer-inbox-helper';
import {
  legacyUpdateSelectedParticipation,
  updateSelectedParticipation
} from '../../../lib/update-selected-participation';
import {
  CUSTOMER_INBOX_LEAVE,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_INBOX_NEW_THREAD_START_CLOSE,
  CUSTOMER_INBOX_NEW_THREAD_START_SHOW,
  CUSTOMER_INBOX_SEARCH_ALL_FAILURE,
  CUSTOMER_INBOX_SEARCH_ALL_SUCCESS,
  CUSTOMER_INBOX_SEARCH_FORM_RESET,
  CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE,
  CUSTOMER_INBOX_SEARCH_FORM_TOGGLE,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS,
  CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  CUSTOMER_INBOX_SNACK_NOTIFICATION_HIDE,
  CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW,
  CUSTOMER_INBOX_THREADS_LIST_LOAD,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_SUCCESS,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_SUCCESS,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT,
  LEGACY_CUSTOMER_INBOX_SEARCH_ALL_FAILURE,
  LEGACY_CUSTOMER_INBOX_SEARCH_ALL_SUCCESS,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-new-thread-actions';
import {
  CUSTOMER_THREAD_PAGE_LEAVE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE
} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS
} from '../../../../actions/customer-thread-assignment-actions';
import {LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-invisible-actions';
import {
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS
} from '../../../../actions/customer-thread-profile-actions';
import {
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
  EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD,
  LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
  LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD
} from '../../../../actions/ext-actions';
import {
  LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD,
  ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION
} from '../../../../actions/router-actions';
import {
  CUSTOMER_INBOX_ITEMS_LIMIT,
  CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_LIMIT,
  CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_LIMIT
} from '../../../../data/settings';

export const DEFAULT_STATE = {
  businessId: null,
  businessName: null,
  businessScope: 'mine',
  hasMore: false,
  hasReachedLimit: false,
  items: [],
  loaded: false,
  loading: false,
  offset: 0,
  repliedTotal: 0,
  resolvedTotal: 0,
  selected: null,
  selectedCustomerId: null,
  showSearchHeader: false,
  showSnack: false,
  showOptionsToSortFilter: true,
  showStartNewThread: false,
  sortBy: 'mostRecent',
  statuses: ['waiting', 'replied', 'resolved'],
  waitingTotal: 0,
  newThread: {
    businessId: null,
    formattedPhoneNumber: null,
    isValidEmail: null,
    isValidSmsPhoneNumber: null,
    text: '',
    items: null
  },
  search: {
    text: '',
    customers: {
      hasMore: false,
      hasReachedLimit: false,
      items: [],
      loading: false
    },
    messages: {
      hasMore: false,
      hasReachedLimit: false,
      items: [],
      loading: false,
      selected: null
    }
  }
};

export const findItemIndexByParticipationId = (state, participationId) =>
  state.get('items').findIndex((item) => item.get('participationId') == participationId); // eslint-disable-line eqeqeq
export const resetItemUnreadCount = (state, participationId, countProperty) => {
  const index = findItemIndexByParticipationId(state, participationId);

  if (index === -1) {
    return state;
  }

  return state.updateIn(['items', index], (item) => item.set(countProperty, 0));
};

const updateCounterPerStatus = (state, previousStatus, newStatus, referenceStatus) => {
  const total = state.get(`${referenceStatus}Total`);

  if (referenceStatus === previousStatus) {
    return total - 1;
  }

  if (referenceStatus === newStatus) {
    return total + 1;
  }

  return total;
};

// See @AWI-5302
export const secureRawCustomerItems = (items) =>
  items.map((item) => {
    if (item.displayName) {
      return item;
    }

    return {
      ...item,
      displayName: ''
    };
  });

const updateLastReplyingUserIfThreadInItems = (
  state,
  participationId,
  displayName,
  pictureHref
) => {
  const index = findItemIndexByParticipationId(state, participationId);

  if (index === -1) {
    return state;
  }

  return state.setIn(
    ['items', index, 'lastReplyingUser'],
    fromJS({
      displayName,
      pictureHref
    })
  );
};

/**
 * Assign & release.
 */
const assignIfThreadItem = (state, {assignedTo, participationId}) => {
  const index = findItemIndexByParticipationId(state, participationId);

  if (index === -1) {
    return state;
  }

  return state.setIn(['items', index, 'assignedTo'], fromJS(assignedTo));
};
const releaseIfThreadItem = (state, {participationId}) => {
  const index = findItemIndexByParticipationId(state, participationId);

  if (index === -1) {
    return state;
  }

  return state.deleteIn(['items', index, 'assignedTo']);
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Reset state when leaving an inbox
   * in order to prevent badges from not being correctly updated
   *
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_LEAVE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  /**
   * Reset selected thread when leaving thread component
   * in order to prevent badges from not being correctly updated
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_PAGE_LEAVE]: (state) =>
    state.set('selected', null).set('selectedCustomerId', null),

  /**
   * Load customer inbox threads list.
   *
   * @param {Object} state
   * @param {Boolean} noClipping
   * @param {Number} offset
   * @param {String} sortBy
   * @param {Array} statuses
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_THREADS_LIST_LOAD]: (state, {noClipping, offset, sortBy, statuses}) => {
    const statusesContainsWaiting = /waiting/.test(statuses);

    const newState = state
      .set('loading', !noClipping)
      .set('offset', offset)
      .set('showOptionsToSortFilter', statusesContainsWaiting)
      .set('sortBy', statusesContainsWaiting ? sortBy : 'mostRecent')
      .set('statuses', statuses);

    if (offset || noClipping) {
      return newState;
    }

    return newState.set('items', fromJS([]));
  },

  [CUSTOMER_INBOX_THREADS_LIST_LOAD_SUCCESS]: (state, {data}) => {
    let items = fromJS(secureRawCustomerItems(data.items));

    if (state.get('offset') > 0) {
      items = state.get('items').concat(items);
    } else {
      const selected = state.get('selected');
      if (selected) {
        // Prevent counters to be > 0 on xxx_sent_message_to_yyy_thread if socket mark-as-read is not finished
        items = items.map((item) =>
          selected == item.get('participationId') // eslint-disable-line eqeqeq
            ? item.set('unreadInternalMessagesCount', 0).set('unreadMessagesCount', 0)
            : item
        );
      }
    }

    const totalByStatus = state
      .get('statuses')
      .map((status) => data[`${status}Total`])
      .reduce((reduction, value) => reduction + value, 0);

    const cappedTotalByStatus = Math.min(totalByStatus, CUSTOMER_INBOX_ITEMS_LIMIT);

    return state
      .set('hasMore', totalByStatus > items.size)
      .set(
        'hasReachedLimit',
        cappedTotalByStatus === items.size && totalByStatus > cappedTotalByStatus
      )
      .set('items', items)
      .set('loaded', true)
      .set('loading', false)
      .set('repliedTotal', data.repliedTotal)
      .set('resolvedTotal', data.resolvedTotal)
      .set('waitingTotal', data.waitingTotal);
  },

  /**
   * Set business filter state
   *
   * @param {Object} state
   * @param {String} businessId
   * @param {String} businessName
   * @param {String} businessScope
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS]: (
    state,
    {businessId, businessName, businessScope}
  ) => {
    return state
      .set('businessId', businessId || null)
      .set('businessName', businessName || null)
      .set('businessScope', businessScope);
  },

  /**
   * Set participationId as soon as possible and hide "new thread" stuff.
   * In the case of a redirection to a customer thread is detected, it is especially useful to handle all cases, for example
   * if participationId can not be retrieved from anything else than browser url (page refresh, navigation through browser history...).
   */
  [LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: legacyUpdateSelectedParticipation,
  [LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD]: updateSelectedParticipation,

  /* SEARCH */

  [CUSTOMER_INBOX_SEARCH_FORM_RESET]: (state) => {
    return state.set('search', fromJS(DEFAULT_STATE.search));
  },

  [CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE]: (state, {payload: {text}}) => {
    const hasText = text.trim() !== '';

    return state.update('search', (search) =>
      search
        .set('text', text)
        .update('customers', (customers) =>
          customers.set('items', fromJS([])).set('loading', hasText)
        )
        .update('messages', (messages) => messages.set('items', fromJS([])).set('loading', hasText))
    );
  },

  [CUSTOMER_INBOX_SEARCH_FORM_TOGGLE]: (state, {payload: {showSearchHeader}}) => {
    return state
      .set('search', fromJS(DEFAULT_STATE.search))
      .set('showSearchHeader', showSearchHeader);
  },

  [CUSTOMER_INBOX_SEARCH_ALL_FAILURE]: (state) => {
    return state
      .setIn(['search', 'customers', 'loading'], false)
      .setIn(['search', 'messages', 'loading'], false);
  },

  [CUSTOMER_INBOX_SEARCH_ALL_SUCCESS]: (state, {payload}) =>
    state
      .updateIn(['search', 'customers'], (customers) =>
        customers
          .set('hasMore', payload.customers.pageInfo.hasNextPage)
          .set('hasReachedLimit', false)
          .set('items', fromJS(payload.customers.edges))
          .set('loading', false)
      )
      .updateIn(['search', 'messages'], (messages) =>
        messages
          .set('hasMore', payload.messages.pageInfo.hasNextPage)
          .set('hasReachedLimit', false)
          .set('items', fromJS(payload.messages.edges))
          .set('loading', false)
      ),

  [CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS]: (state) => {
    return state.setIn(['search', 'customers', 'loading'], true);
  },

  [CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE]: (state) => {
    return state.setIn(['search', 'customers', 'loading'], false);
  },

  [CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS]: (state, {payload}) => {
    const allItems = state
      .getIn(['search', 'customers', 'items'])
      .concat(fromJS(payload.customers.edges));

    return state.updateIn(['search', 'customers'], (customers) =>
      customers
        .set('hasMore', payload.customers.pageInfo.hasNextPage)
        .set('hasReachedLimit', allItems.size === CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_LIMIT)
        .set('items', allItems)
        .set('loading', false)
    );
  },

  [CUSTOMER_INBOX_SEARCH_MORE_MESSAGES]: (state) => {
    return state.setIn(['search', 'messages', 'loading'], true);
  },

  [CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE]: (state) => {
    return state.setIn(['search', 'messages', 'loading'], false);
  },

  [CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS]: (state, {payload}) => {
    const allItems = state
      .getIn(['search', 'messages', 'items'])
      .concat(fromJS(payload.messages.edges));

    return state.updateIn(['search', 'messages'], (messages) =>
      messages
        .set('hasMore', payload.messages.pageInfo.hasNextPage)
        .set('hasReachedLimit', allItems.size === CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_LIMIT)
        .set('items', allItems)
        .set('loading', false)
    );
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_ALL_FAILURE]: (state) => {
    return state
      .setIn(['search', 'customers', 'loading'], false)
      .setIn(['search', 'messages', 'loading'], false);
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_ALL_SUCCESS]: (state, {data}) => {
    const customersItems = fromJS(data.customers.items);
    const messagesItems = fromJS(data.messages.items);

    return state
      .updateIn(['search', 'customers'], (customers) =>
        customers
          .set('hasMore', customersItems.size < data.customers.total)
          .set('hasReachedLimit', false)
          .set('items', customersItems)
          .set('loading', false)
      )
      .updateIn(['search', 'messages'], (messages) =>
        messages
          .set('hasMore', messagesItems.size < data.messages.total)
          .set('hasReachedLimit', false)
          .set('items', messagesItems)
          .set('loading', false)
      );
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS]: (state) => {
    return state.setIn(['search', 'customers', 'loading'], true);
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE]: (state) => {
    return state.setIn(['search', 'customers', 'loading'], false);
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS]: (state, {data}) => {
    const allItems = state
      .getIn(['search', 'customers', 'items'])
      .concat(fromJS(data.customers.items));

    return state.updateIn(['search', 'customers'], (customers) =>
      customers
        .set('hasMore', allItems.size < data.customers.total)
        .set('hasReachedLimit', allItems.size === CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_LIMIT)
        .set('items', allItems)
        .set('loading', false)
    );
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES]: (state) => {
    return state.setIn(['search', 'messages', 'loading'], true);
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE]: (state) => {
    return state.setIn(['search', 'messages', 'loading'], false);
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS]: (state, {data}) => {
    const allItems = state
      .getIn(['search', 'messages', 'items'])
      .concat(fromJS(data.messages.items));

    return state.updateIn(['search', 'messages'], (messages) =>
      messages
        .set('hasMore', allItems.size < data.messages.total)
        .set('hasReachedLimit', allItems.size === CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_LIMIT)
        .set('items', allItems)
        .set('loading', false)
    );
  },

  [CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK]: (state, {payload: {indexInList}}) => {
    const customerId = state.getIn([
      'search',
      'customers',
      'items',
      indexInList,
      'node',
      'customer',
      'id'
    ]);

    return state
      .set('selectedCustomerId', customerId)
      .setIn(['search', 'messages', 'selected'], null);
  },
  [CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: (state, {payload: {message}}) => {
    return state
      .set('selectedCustomerId', null)
      .setIn(['search', 'messages', 'selected'], message.id);
  },

  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER]: (state, {participationId}) => {
    return state.set('selected', participationId).setIn(['search', 'messages', 'selected'], null);
  },
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: (state, {messageId}) => {
    return state.set('selected', null).setIn(['search', 'messages', 'selected'], messageId);
  },

  [LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    return resetItemUnreadCount(state, data.id, 'unreadInternalMessagesCount');
  },

  // we can move this to an external legacy file when we need to update tasks related to inbox
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    return resetItemUnreadCount(state, data.id, 'unreadMessagesCount');
  },

  [CUSTOMER_INBOX_NEW_THREAD_START_SHOW]: (state, {payload}) => {
    const businessId = payload?.business?.id || null;
    const newNewThreadState = fromJS(DEFAULT_STATE.newThread).set('businessId', businessId);

    return state.set('newThread', newNewThreadState).set('showStartNewThread', true);
  },

  [CUSTOMER_INBOX_NEW_THREAD_START_CLOSE]: (state) => state.set('showStartNewThread', false),

  [CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED]: (state, {payload: {text}}) => {
    const businessId = state.getIn(['newThread', 'businessId']);

    return state.set(
      'newThread',
      fromJS(DEFAULT_STATE.newThread).set('businessId', businessId).set('text', text)
    );
  },

  [CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_SUCCESS]: (
    state,
    {payload: {customers, formattedPhoneNumber, isValidEmail, isValidSmsPhoneNumber}}
  ) => {
    return state.update('newThread', (newThread) =>
      newThread
        .set('formattedPhoneNumber', formattedPhoneNumber)
        .set('isValidEmail', isValidEmail)
        .set('isValidSmsPhoneNumber', isValidSmsPhoneNumber)
        .set('items', fromJS(customers.edges))
    );
  },
  [LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_SUCCESS]: (
    state,
    {formattedPhoneNumber, isValidEmail, isValidSmsPhoneNumber, items}
  ) => {
    return state.update('newThread', (newThread) =>
      newThread
        .set('formattedPhoneNumber', formattedPhoneNumber)
        .set('isValidEmail', isValidEmail)
        .set('isValidSmsPhoneNumber', isValidSmsPhoneNumber)
        .set('items', fromJS(items))
    );
  },

  /**
   * Optimistic update (only if the thread is visible in the inbox) of:
   * - counter per status
   * - thread status in threads list
   * when updating manually a thread status.
   * If the thread is not visible in the inbox, we prefer not to do optimistic update even of counters
   * in order to avoid a visible difference between counters and real items count in the threads list
   *
   * @param {Object} state
   * @param {String} newStatus
   * @param {String} participationId
   * @param {String} previousStatus
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE]: (
    state,
    {newStatus, participationId, previousStatus}
  ) => {
    const index = findItemIndexByParticipationId(state, participationId);

    if (index === -1) {
      return state;
    }

    return (
      state
        .set('waitingTotal', updateCounterPerStatus(state, previousStatus, newStatus, 'waiting'))
        .set('repliedTotal', updateCounterPerStatus(state, previousStatus, newStatus, 'replied'))
        .set('resolvedTotal', updateCounterPerStatus(state, previousStatus, newStatus, 'resolved'))
        .updateIn(['items', index], (item) =>
          item
            .set('pendingSinceDate', newStatus === 'waiting' ? moment().toISOString() : null)
            .set('status', newStatus)
        )
        // sort by status, then lastPublicMessageDate or pendingSinceDate (depends of sortBy)
        .update('items', customerInboxThreadsSorter(state.get('sortBy')))
    );
  },

  /**
   * Show Snackbar
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW]: (state) => {
    return state.set('showSnack', true);
  },

  /**
   * Hide Snackbar
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_SNACK_NOTIFICATION_HIDE]: (state) => {
    return state.set('showSnack', false);
  },

  /**
   * Assign the customer thread to the current user / from socket.
   */
  [EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD]: (state, {payload: {participationId}}) =>
    // To render the item, inbox just need to know if is assigned, no matter who
    assignIfThreadItem(state, {assignedTo: {}, participationId}),

  [LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS]: assignIfThreadItem,
  [LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD]: assignIfThreadItem,

  /**
   * Release the customer thread from the current user / socket.
   */
  [EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD]: (state, {payload: {participationId}}) =>
    releaseIfThreadItem(state, {participationId}),

  [LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS]: releaseIfThreadItem,
  [LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD]: releaseIfThreadItem,

  /**
   * Close new customer thread panel when start new thread with new customer
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD]: (state) => {
    return state.set('selected', null).set('showStartNewThread', false);
  },

  /**
   * Close new customer thread panel when start new thread with existing customer
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT]: (state) => {
    return state.set('showStartNewThread', false);
  },

  /**
   * Update company & displayName conversation when success edit customer profile
   *
   * @param {Object} state
   * @param {String} company
   * @param {String} participantName
   * @param {Number} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS]: (
    state,
    {company, participantName, participationId}
  ) => {
    const index = findItemIndexByParticipationId(state, participationId);

    if (index === -1) {
      return state;
    }

    return state.updateIn(['items', index], (item) =>
      item.set('company', company).set('displayName', participantName)
    );
  },

  /**
   * Change customer picture when upload is finished.
   *
   * @param {Object} state
   * @param {String} participationId
   * @param {String} href
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS]: (
    state,
    {participationId, href}
  ) => {
    const index = findItemIndexByParticipationId(state, participationId);

    if (index === -1) {
      return state;
    }

    return state.setIn(['items', index, 'pictureHref'], href);
  },

  /**
   * Eventually update optimistically the "last replying user" when current employee sent a message in the visible thread.
   *
   * @param {Object} state
   * @param {String} fullName
   * @param {String} participationId
   * @param {String} picture
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: (
    state,
    {fullName, participationId, picture}
  ) => {
    return updateLastReplyingUserIfThreadInItems(state, participationId, fullName, picture);
  },

  /**
   * Automatically select customer item in the inbox when the employee open a customer conversation.
   * @param {Immutable.Map} state
   * @param {String} customerIdUrlParam
   * @returns {Immutable.Map} state
   */
  [ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION]: (state, {payload: {customerIdUrlParam}}) =>
    state.set('selectedCustomerId', customerIdUrlParam),

  /**
   * Eventually update optimistically the "last replying user" when a colleague sent a message in the visible thread.
   *
   * @param {Object} state
   * @param {String} participationId
   * @param {String} senderName
   * @param {String} userIcon
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER]: (
    state,
    {data: {participationId, senderName, userIcon}}
  ) => {
    return updateLastReplyingUserIfThreadInItems(state, participationId, senderName, userIcon);
  }
});
