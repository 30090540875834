import React from 'react';
import PropTypes from 'prop-types';
import context from '../../../../shared/component/context';
import HeaderBanner from '../common/header/header-banner-component';
import {employeeSelectedBrowserNotificationPermission} from '../../lib/browser-notification-helper';
import {updateEmployeeDesktopPreferences} from '../../lib/employee-preferences';

const Link = ({labelKey, ...otherProps}, {i18n}) => (
  <a
    {...otherProps}
    style={{
      marginLeft: '6px',
      cursor: 'pointer',
      color: 'white',
      fontWeight: 'bold',
      textDecoration: 'underline'
    }}
  >
    {i18n.t(`app.browserNotification.enableNotification.${labelKey}`)}
  </a>
);
Link.contextTypes = context;

Link.propTypes = {
  labelKey: PropTypes.string
};

/**
 * Finally, the component.
 */
class BrowserNotificationBannerComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this._doNotAskAnymore = this._doNotAskAnymore.bind(this);
    this._enableNow = this._enableNow.bind(this);
    this._renderEnableNowLink = this._renderEnableNowLink.bind(this);
  }

  _doNotAskAnymore() {
    const {doBrowserNotificationBannerClose} = this.props;
    updateEmployeeDesktopPreferences({notifAlreadyPrompted: true});

    doBrowserNotificationBannerClose();
  }

  _enableNow() {
    const {doBrowserNotificationBannerClose} = this.props;
    Notification.requestPermission(() => {
      if (employeeSelectedBrowserNotificationPermission()) {
        doBrowserNotificationBannerClose();
      }
    });
  }

  _renderEnableNowLink() {
    return <Link data-test-id="enable-now-button" labelKey="enableNow" onClick={this._enableNow} />;
  }

  render() {
    const {i18n} = this.context;
    const {
      browserNotificationBannerStep,
      doBrowserNotificationBannerClose,
      doBrowserNotificationBannerShowLast
    } = this.props;

    const isFirst = browserNotificationBannerStep === 'first';

    return (
      <HeaderBanner
        dataTestId="browser-notification-banner"
        dataTestIdCloseButton="close-button"
        doClose={isFirst ? doBrowserNotificationBannerShowLast : null}
      >
        {isFirst ? (
          <span>
            {i18n.t('app.browserNotification.enableNotification.messageFirstAsk')}
            {this._renderEnableNowLink()}
          </span>
        ) : (
          <span>
            {i18n.t('app.browserNotification.enableNotification.messageLastAsk')}
            {this._renderEnableNowLink()}
            <Link
              data-test-id="ask-next-time-button"
              labelKey="askNextTime"
              onClick={doBrowserNotificationBannerClose}
            />
            <Link
              data-test-id="do-not-ask-anymore-button"
              labelKey="doNotAskAnymore"
              onClick={this._doNotAskAnymore}
            />
          </span>
        )}
      </HeaderBanner>
    );
  }
}

BrowserNotificationBannerComponent.contextTypes = context;

BrowserNotificationBannerComponent.propTypes = {
  browserNotificationBannerStep: PropTypes.string.isRequired,
  doBrowserNotificationBannerClose: PropTypes.func.isRequired,
  doBrowserNotificationBannerShowLast: PropTypes.func.isRequired
};

export default BrowserNotificationBannerComponent;
