import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Snackbar} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import context from '../../../../../../shared/component/context';
import themeV1 from '../../../../../../shared/style/theme-v1';
import CheckedIcon from '../../../../../../shared/component/icons/checked-icon';
import LegacyCustomerInboxThreadListItem from '../../../common/inbox/legacy-customer-thread-list-item-component';
import EmptyMessage from '../../../common/empty-message-component';
import FilterAndSortMenu from './filter-and-sort-menu-component';
import InboxThreadListLayout from '../../../common/inbox/inbox-thread-list-layout-component';
import InboxThreadListResultLayout from '../../../common/inbox/inbox-thread-list-result-layout-component';
import {fontSize, iconSize} from '../../../../../../shared/style/variables';
import {blue} from '../../../../../../shared/style/colors';

class ThreadListComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._loadMore = this._loadMore.bind(this);
    this._renderList = this._renderList.bind(this);
  }

  _loadMore() {
    const {items, sortBy, statuses, doLoadInboxThreadsList} = this.props;

    doLoadInboxThreadsList(statuses, items.length, sortBy);
  }

  _renderHeaderStatusWithCounter(status) {
    const {i18n} = this.context;
    const {repliedTotal, resolvedTotal, waitingTotal} = this.props;

    return {
      waiting: (
        <strong data-test-id="thread-list-waiting-count">
          {i18n.t('customerInbox.threadList.statuses.waiting')} {waitingTotal}
        </strong>
      ),
      replied: (
        <strong data-test-id="thread-list-replied-count">
          {i18n.t('customerInbox.threadList.statuses.replied')} {repliedTotal}
        </strong>
      ),
      resolved: (
        <strong data-test-id="thread-list-resolved-count">
          {i18n.t('customerInbox.threadList.statuses.resolved')} {resolvedTotal}
        </strong>
      )
    }[status];
  }

  _renderList() {
    const {
      enableLasRespondentAvatarInInbox,
      headerStyle,
      items,
      repliedTotal,
      resolvedTotal,
      selected,
      showOptionsToSortFilter,
      sortBy,
      statuses,
      waitingTotal,
      doFilterInboxByStatuses,
      doInboxThreadsListThreadClick,
      doLoadInboxThreadsList
    } = this.props;

    return (
      <div style={{position: 'relative'}}>
        <FilterAndSortMenu
          {...{
            repliedTotal,
            resolvedTotal,
            showOptionsToSortFilter,
            sortBy,
            statuses,
            waitingTotal,
            doFilterInboxByStatuses,
            doLoadInboxThreadsList
          }}
          filterStyles={{
            padding: '3px 0 0 12px',
            position: 'absolute',
            right: '0',
            color: blue,
            fontSize: fontSize.medium
          }}
        />

        {statuses.map((status) => (
          <span data-test-id={`thread-list-${status}-result`} key={status}>
            <div data-test-id="header" style={headerStyle}>
              {this._renderHeaderStatusWithCounter(status)}
              {status === 'waiting' &&
              items.filter((item) => item.status === 'waiting').length === 0 ? (
                <CheckedIcon
                  data-test-id="empty-waiting"
                  style={{marginLeft: '5px', fontSize: iconSize.large}}
                />
              ) : null}
            </div>

            {items
              .filter((item) => item.status === status)
              .map((props, indexInList, list) => (
                <LegacyCustomerInboxThreadListItem
                  {...props}
                  isLastChild={!list[indexInList + 1]}
                  isSelected={props.participationId == selected} // eslint-disable-line eqeqeq
                  key={props.participationId}
                  selectItem={doInboxThreadsListThreadClick}
                  sortByWaiting={sortBy === 'waiting'}
                  {...{
                    enableLasRespondentAvatarInInbox,
                    indexInList
                  }}
                />
              ))}
          </span>
        ))}
      </div>
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      hasBusinesses,
      hasMore,
      hasReachedLimit,
      isAllowedToSeeCustomerThreadsOfAllBusinesses,
      items,
      loading,
      offset,
      showSnack,
      statuses
    } = this.props;

    if (!hasBusinesses && !isAllowedToSeeCustomerThreadsOfAllBusinesses) {
      return (
        <EmptyMessage
          description={i18n.t('customerInbox.checkOtherBusinesses')}
          title={i18n.t('customerInbox.noBusinessToBrowse')}
        />
      );
    }

    return (
      <InboxThreadListLayout>
        <MuiThemeProvider theme={themeV1}>
          <Snackbar
            data-test-id="snack-notification-replied"
            message={i18n.t('customerInbox.snackNotificationReplied')}
            open={showSnack}
            style={{
              position: 'absolute'
            }}
          />
        </MuiThemeProvider>

        <InboxThreadListResultLayout
          emptyDescription={i18n.t('customerInbox.checkOtherBusinesses')}
          emptyTitle={i18n.t(
            `customerInbox.emptyThreadList.${statuses.length === 1 ? statuses : 'waitingReplied'}`
          )}
          loadMore={this._loadMore}
          renderList={this._renderList}
          {...{
            hasMore,
            hasReachedLimit,
            items,
            loading,
            offset
          }}
        />
      </InboxThreadListLayout>
    );
  }
}

ThreadListComponent.contextTypes = context;

ThreadListComponent.propTypes = {
  enableLasRespondentAvatarInInbox: PropTypes.bool.isRequired,
  hasBusinesses: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  hasReachedLimit: PropTypes.bool.isRequired,
  headerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  isAllowedToSeeCustomerThreadsOfAllBusinesses: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  repliedTotal: PropTypes.number.isRequired,
  resolvedTotal: PropTypes.number.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showOptionsToSortFilter: PropTypes.bool.isRequired,
  showSnack: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  waitingTotal: PropTypes.number.isRequired,
  doFilterInboxByStatuses: PropTypes.func.isRequired,
  doInboxThreadsListThreadClick: PropTypes.func.isRequired,
  doLoadInboxThreadsList: PropTypes.func.isRequired
};

export default ThreadListComponent;
