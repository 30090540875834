import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import FontIcon from 'material-ui/FontIcon';
import context from '../../../../../../shared/component/context';
import {InboxHeaderLayout} from '../../../common/inbox/inbox-header-components';
import {lightSlate, lightSnow} from '../../../../../../shared/style/colors';

class ColleagueInboxStartNewThreadFormComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doCancelStartNewThreadWithColleague =
      this._doCancelStartNewThreadWithColleague.bind(this);
    this._onClickResetInput = this._onClickResetInput.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
  }

  _onInputChange(event) {
    const {doSearchColleagueToStartThread} = this.props;
    doSearchColleagueToStartThread(event.target.value);
  }

  _onClickResetInput() {
    const {doSearchColleagueToStartThreadInputReset} = this.props;
    doSearchColleagueToStartThreadInputReset();

    this._input.focus();
  }

  _doCancelStartNewThreadWithColleague() {
    const {doCancelStartNewThreadWithColleague} = this.props;
    doCancelStartNewThreadWithColleague();
  }

  render() {
    const {i18n} = this.context;
    const {searchInputText} = this.props;

    return (
      <InboxHeaderLayout
        style={{
          height: 'initial',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            position: 'relative',
            minHeight: '50px',
            height: '50px',
            borderCollapse: 'collapse',
            padding: '8px',
            alignItems: 'center'
          }}
        >
          <span style={{marginRight: '15px', marginLeft: '5px'}}>
            {i18n.t('colleagueInbox.newThread.to')}
          </span>
          <input
            data-test-id="search-colleague-input"
            autoFocus
            onChange={this._onInputChange}
            placeholder={i18n.t('colleagueInbox.newThread.searchColleaguePlaceholder')}
            ref={(itself) => {
              this._input = itself;

              return this._input;
            }}
            type="text"
            value={searchInputText}
            style={{
              width: '100%',
              padding: '10px 32px 10px 12px',
              outline: 'none',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: lightSnow,
              fontSize: '100%'
            }}
          />

          {searchInputText ? (
            <FontIcon
              data-test-id="search-colleague-input-clear-button"
              className="material-icons"
              color={lightSlate}
              onClick={this._onClickResetInput}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '12px',
                right: '12px'
              }}
            >
              clear
            </FontIcon>
          ) : null}
        </div>
      </InboxHeaderLayout>
    );
  }
}

ColleagueInboxStartNewThreadFormComponent.contextTypes = context;

ColleagueInboxStartNewThreadFormComponent.propTypes = {
  searchInputText: PropTypes.string.isRequired,
  doCancelStartNewThreadWithColleague: PropTypes.func.isRequired,
  doSearchColleagueToStartThread: PropTypes.func.isRequired,
  doSearchColleagueToStartThreadInputReset: PropTypes.func.isRequired
};

export default ColleagueInboxStartNewThreadFormComponent;
