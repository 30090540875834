import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import {InboxHeaderLayout, InboxHeaderTitle} from '../../../common/inbox/inbox-header-components';
import NewOutboundMessageIcon from '../../../common/inbox/new-outbound-message-icon';
import {spacing} from '../../../../../../shared/style/variables';

const ColleagueInboxHeaderComponent = ({doShowStartNewThreadWithColleague}, {i18n}) => (
  <InboxHeaderLayout>
    <InboxHeaderTitle>{i18n.t('colleagueInbox.title')}</InboxHeaderTitle>

    <NewOutboundMessageIcon
      onClick={doShowStartNewThreadWithColleague}
      style={{
        padding: `${spacing.medium} ${spacing.small}`
      }}
    />
  </InboxHeaderLayout>
);

ColleagueInboxHeaderComponent.contextTypes = context;

ColleagueInboxHeaderComponent.propTypes = {
  doShowStartNewThreadWithColleague: PropTypes.func.isRequired
};

export default ColleagueInboxHeaderComponent;
