import moment from 'moment';
import {createSelector} from 'reselect';
import cloneDeep from 'lodash/cloneDeep';

function _legacyGroupMessages(accumulated, value, index, items) {
  const messagesAccumulated = cloneDeep(accumulated);
  const previousItem = items[index - 1];
  const isGroupable = value.type !== 'transfer';

  const isInBatch =
    previousItem &&
    previousItem.senderId == value.senderId && // eslint-disable-line eqeqeq
    previousItem.senderType === value.senderType &&
    previousItem.type === 'message' &&
    previousItem.automatic === value.automatic && // since we are sending messages with a random employee id...
    isGroupable &&
    moment(value.date).diff(previousItem.date, 'minutes') < 2;

  if (isGroupable && previousItem) {
    const showDate = !moment(previousItem.date).isSame(moment(value.date), 'day');
    if (showDate) {
      messagesAccumulated.push({
        type: 'date',
        date: value.date
      });
    }
  } else {
    messagesAccumulated.push({
      type: 'date',
      date: value.date
    });
  }

  if (value.isFirstOfSegment) {
    messagesAccumulated.push({
      type: 'newConversation'
    });
  }

  const message = {
    text: value.text,
    attachment: value.attachment,
    moderated: value.moderated,
    status: value.status,
    date: value.date,
    selected: value.selected,
    mentionedUserName: value.mentionedUserName || value.receiverName,
    _sendAction: value._sendAction
  };

  if (
    isInBatch &&
    (!messagesAccumulated || messagesAccumulated[messagesAccumulated.length - 1].type !== 'date')
  ) {
    const previousAccumulated = messagesAccumulated[messagesAccumulated.length - 1];

    previousAccumulated.messages.push(message);
  } else {
    messagesAccumulated.push({
      senderId: value.senderId,
      date: value.date,
      isFirstOfSegment: value.isFirstOfSegment,
      senderName: value.senderName,
      businessName: value.businessName,
      type: value.type,
      userIcon: value.userIcon,
      orgIcon: value.orgIcon,
      presentationType: value.presentationType,
      automatic: value.automatic,
      messages: [message]
    });
  }

  return messagesAccumulated;
}

export default createSelector(
  (state) => state.get('messages'),
  (messages) => messages.toJS().reduce(_legacyGroupMessages, [])
);
