import PropTypes from 'prop-types';
import HeaderContentLayout from './header-content-layout-component';
import HeaderContentTitle from './header-content-title-component';

const SimpleContentHeaderComponent = ({title, ...props}) => {
  return (
    <HeaderContentLayout>
      <HeaderContentTitle label={title} {...props} />
    </HeaderContentLayout>
  );
};

SimpleContentHeaderComponent.propTypes = {
  title: PropTypes.string
};

export default SimpleContentHeaderComponent;
