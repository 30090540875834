export const EXT_COLLEAGUE_SENT_MESSAGE_TO_ME = 'EXT_COLLEAGUE_SENT_MESSAGE_TO_ME';

export const EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_FROM_ME =
  'EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_FROM_ME';
export const EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME =
  'EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME';
export const EXT_CONVERSATION_NEW_MESSAGE_SPREAD_FAILURE =
  'EXT_CONVERSATION_NEW_MESSAGE_SPREAD_FAILURE';
export const EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER =
  'EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER';
export const EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME =
  'EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME';
export const EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME =
  'EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME';

export const EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION =
  'EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION';

export const EXT_EMPLOYEE_RELEASED_CONVERSATION = 'EXT_EMPLOYEE_RELEASED_CONVERSATION';
export const EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD = 'EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD';

export const EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER = 'EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER';
export const EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD =
  'EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD';

export const EXT_EMPLOYEE_TOOK_CONVERSATION = 'EXT_EMPLOYEE_TOOK_CONVERSATION';
export const EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD = 'EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD';

export const EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS =
  'EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS';

export const EXT_ORGANIZATION_SENT_AUTOMATIC_MESSAGE_TO_CUSTOMER =
  'EXT_ORGANIZATION_SENT_AUTOMATIC_MESSAGE_TO_CUSTOMER';

export const EXT_CONVERSATION_STATUS_CHANGED = 'EXT_CONVERSATION_STATUS_CHANGED';

// Legacy
export const LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS =
  'LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS';

export const LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED =
  'LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED';

export const LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD =
  'LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD';
export const LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD = 'LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD';

export const LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED =
  'LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED';
