import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AdministrationBusinessPageChannelsFacebookListItem from './administration-business-page-channels-list-facebook-item-component';
import AdministrationBusinessPageChannelsFacebookPagesDialog from '../../dialog/administration-business-page-channels-facebook-pages-dialog-component';
import AdministrationBusinessPageChannelsFacebookPagesDisconnectDialog from '../../dialog/administration-business-page-channels-facebook-pages-disconnect-dialog-component';
import LoadingIndicator from '../../../../../../../../../shared/component/loading-indicator-component';

const AdministrationBusinessPageChannelsFacebookComponent = ({
  business,
  facebookChannel,
  i18n,
  doAdministrationBusinessPageChannelsOpen,
  doFacebookPageDisconnection,
  doFacebookPageConnection,
  doFacebookPageRestartDataToReconnection,
  doStoreFacebookChannelRetrievedToken
}) => {
  const [isOpenFacebookPageDisconnectionDialog, setIsOpenFacebookPageDisconnectionDialog] =
    useState(false);

  const [isOpenFacebookPagesDialog, setIsOpenFacebookPagesDialog] = useState(false);

  const {facebookPageConnectionError, facebookConnectionStatus, facebookLogin, facebookPages} =
    facebookChannel;

  useEffect(() => {
    doAdministrationBusinessPageChannelsOpen(business.id);
  }, []);

  const facebookChannelConnected = !!(
    facebookConnectionStatus.data && facebookConnectionStatus.data.connected
  );

  const facebookPagesLength = facebookPages.data && facebookPages.data.length;
  const canShowFacebookPagesDialog = facebookPagesLength > 1 && !facebookChannelConnected;

  useEffect(() => {
    if (canShowFacebookPagesDialog) {
      setIsOpenFacebookPagesDialog(true);
    }
  }, [facebookPages]);

  const handleFacebookPageDisconnectionDialog = () => {
    setIsOpenFacebookPageDisconnectionDialog(!isOpenFacebookPageDisconnectionDialog);
  };

  const disconnectFacebookPage = () => {
    doFacebookPageDisconnection(business.id);
    setIsOpenFacebookPageDisconnectionDialog(false);
  };

  const connectFacebookPage = (facebookPageIdSelected) => {
    setIsOpenFacebookPagesDialog(false);
    doFacebookPageConnection(business.id, facebookPageIdSelected);
  };

  const closeFacebookPagesDialog = () => {
    setIsOpenFacebookPagesDialog(false);
    doFacebookPageRestartDataToReconnection();
  };

  return (
    <React.Fragment>
      {facebookConnectionStatus.loading ? (
        <LoadingIndicator />
      ) : (
        <AdministrationBusinessPageChannelsFacebookListItem
          dataTestId="facebook-channel-connect-button"
          channelType="facebook"
          facebookChannelError={facebookPageConnectionError}
          {...{
            canShowFacebookPagesDialog,
            facebookChannelConnected,
            facebookLogin,
            facebookPages,
            i18n,
            handleFacebookPageDisconnectionDialog,
            doFacebookPageConnection,
            doStoreFacebookChannelRetrievedToken
          }}
        />
      )}
      {canShowFacebookPagesDialog ? (
        <AdministrationBusinessPageChannelsFacebookPagesDialog
          {...{
            closeFacebookPagesDialog,
            facebookChannelConnected,
            facebookPages,
            i18n,
            isOpenFacebookPagesDialog,
            connectFacebookPage,
            doFacebookPageRestartDataToReconnection
          }}
        />
      ) : null}
      {facebookChannelConnected ? (
        <AdministrationBusinessPageChannelsFacebookPagesDisconnectDialog
          {...{
            disconnectFacebookPage,
            i18n,
            handleFacebookPageDisconnectionDialog,
            isOpenFacebookPageDisconnectionDialog
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

AdministrationBusinessPageChannelsFacebookComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  facebookChannel: PropTypes.objectOf(PropTypes.any),
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  doAdministrationBusinessPageChannelsOpen: PropTypes.func.isRequired,
  doFacebookPageConnection: PropTypes.func.isRequired,
  doFacebookPageDisconnection: PropTypes.func.isRequired,
  doFacebookPageRestartDataToReconnection: PropTypes.func.isRequired,
  doStoreFacebookChannelRetrievedToken: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsFacebookComponent;
