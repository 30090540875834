import React from 'react';
import PropTypes from 'prop-types';
import NotificationsNone from '@material-ui/icons/NotificationsNone';
import BadgedLink from '../link/badged-link-component';
import MentionPopup from './mention-popup-component';

const MentionPopupHandlerComponent = ({
  headerMention,
  i18n,
  muiTheme,
  doHeaderMentionsPopupClose,
  doHeaderMentionsPopupItemClick,
  doHeaderMentionsPopupItemsLoadMore,
  doHeaderMentionsPopupOpen
}) => {
  const badgeAnchorEl = React.useRef(null);

  return (
    <span ref={badgeAnchorEl}>
      <BadgedLink
        data-test-id="mention-popup-button"
        badgeStyle={{
          position: 'relative',
          left: '-6px'
        }}
        label={
          <NotificationsNone
            color="inherit"
            fontSize="inherit"
            style={{position: 'relative', top: '1px'}}
          />
        }
        number={headerMention.unreadMentionsCount}
        onClick={doHeaderMentionsPopupOpen}
        style={{
          // do not change marginLeft & fontSize (see AWI-5224)
          marginLeft: '10px',
          fontSize: '28px'
        }}
      />
      <MentionPopup
        anchorEl={badgeAnchorEl.current}
        {...{
          headerMention,
          i18n,
          muiTheme,
          doHeaderMentionsPopupClose,
          doHeaderMentionsPopupItemClick,
          doHeaderMentionsPopupItemsLoadMore
        }}
      />
    </span>
  );
};

MentionPopupHandlerComponent.propTypes = {
  headerMention: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  muiTheme: PropTypes.objectOf(PropTypes.any).isRequired,
  doHeaderMentionsPopupClose: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemClick: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemsLoadMore: PropTypes.func.isRequired,
  doHeaderMentionsPopupOpen: PropTypes.func.isRequired
};

export default MentionPopupHandlerComponent;
