import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import context from '../../../../../../../shared/component/context';
import {getI18NWidgetPrefix} from '../../lib/integration-area-widgets-helper';
import {isNumeric} from '../../../../../../../shared/lib/number-helper';
import WidgetPage, {
  SECTION_DESCRIPTION_STYLE,
  SECTION_BLOCK_STYLE,
  WIDGET_PAGES_COMMON_PROP_TYPES
} from './common/widget-page-component';
import {blue, red, slate} from '../../../../../../../shared/style/colors';
import {fontSize} from '../../../../../../../shared/style/variables';

const ACTION_BTN_DETECT_CLASS_REGEXP = new RegExp(/^\.\w+?/, 'i');

/**
 * Finally, the component.
 */
class CTAWidgetComponent extends WidgetPage {
  constructor(props) {
    super(props);

    this.state = {
      containerId: 'iw-bubble-container',
      i18nWidgetPrefix: getI18NWidgetPrefix('cta'),
      scriptFile: 'bubble',
      showErrorActionBtn: false,
      widgetType: 'cta',
      snippetProps: [
        'lang',
        'defaultContact',
        'contactChoice',
        'businessId',
        'errorInvalidEmail',
        'errorInvalidPhone',
        'placeholderEmail',
        'placeholderMsg',
        'placeholderPhone',
        'thankyouMsg',
        'actionBtn'
      ]
    };

    this._doActionBtnChange = this._doActionBtnChange.bind(this);
  }

  _doActionBtnChange(e, selector) {
    const {widgetWindow} = this.state;

    // Very simplified class validation, that should be improved in future
    const isSelectorValid =
      ACTION_BTN_DETECT_CLASS_REGEXP.test(selector) &&
      selector.length > 1 &&
      !isNumeric(selector.substring(1, 2));

    widgetWindow._iwq.actionBtn = isSelectorValid ? selector : '.cta';
    widgetWindow.document.querySelector('#cta').className = widgetWindow._iwq.actionBtn.replace(
      /^\./g,
      ''
    );
    this.__updateWidget();

    this.props.doChangeActionBtn(widgetWindow._iwq.actionBtn);

    this.setState({showErrorActionBtn: selector && !isSelectorValid});
  }

  render() {
    const {i18n} = this.context;
    const {actionBtn, businessId} = this.props;
    const {showErrorActionBtn} = this.state;

    return this._renderLayout(
      'cta-link-wireframe.png',
      'cta.html',
      '300px',
      businessId,
      i18n.t('integrationArea.widgets.cta.finalResultAndCode.description', {
        actionBtn: actionBtn.replace('.', '') || 'cta'
      }),
      <div>
        {this._renderSingleBusinessSelector('businessId', this._doSingleBusinessSelect)}

        {this._renderSectionBehaviour()}

        {this._renderSectionStyleAndCustomize(
          <div>
            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={actionBtn}
                errorStyle={{bottom: '21px'}}
                errorText={
                  showErrorActionBtn
                    ? i18n.t('integrationArea.widgets.cta.sectionStyleAndCustomize.actionBtn.error')
                    : null
                }
                floatingLabelFixed
                floatingLabelStyle={{color: actionBtn ? blue : slate}}
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.cta.sectionStyleAndCustomize.actionBtn.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.cta.sectionStyleAndCustomize.actionBtn.hint'
                )}
                onChange={this._doActionBtnChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                <span
                  style={{
                    color: red,
                    fontSize: fontSize.xsmall,
                    fontWeight: 'normal',
                    display: 'block'
                  }}
                >
                  {i18n.t('integrationArea.widgets.cta.mandatoryFlag')}
                </span>
                {i18n.t(
                  'integrationArea.widgets.cta.sectionStyleAndCustomize.actionBtn.description',
                  {actionBtn: actionBtn.replace('.', '') || 'cta'}
                )}
              </span>
            </div>

            {this._renderLanguage()}

            {this._renderInputPlaceholder()}

            {this._renderInputPlaceholderPhone()}
            {this._renderInputPlaceholderEmail()}
            {this._renderErrorInvalidEmail()}
            {this._renderErrorInvalidPhone()}

            {this._renderThankYouMessage()}
          </div>
        )}
      </div>
    );
  }
}

CTAWidgetComponent.contextTypes = context;

CTAWidgetComponent.propTypes = {
  actionBtn: PropTypes.string.isRequired,
  businessesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessId: PropTypes.string.isRequired,
  communicationMethod: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  thankYouMessage: PropTypes.string.isRequired,
  doChangeActionBtn: PropTypes.func.isRequired,
  doChangeBusiness: PropTypes.func.isRequired,
  doChangeCommunicationMethod: PropTypes.func.isRequired,
  doChangeErrorInvalidEmail: PropTypes.func,
  doChangeErrorInvalidPhone: PropTypes.func,
  doChangeInputPlaceholder: PropTypes.func.isRequired,
  doChangeInputPlaceholderEmail: PropTypes.func.isRequired,
  doChangeInputPlaceholderPhone: PropTypes.func.isRequired,
  doChangeThankYouMessage: PropTypes.func.isRequired,
  ...WIDGET_PAGES_COMMON_PROP_TYPES
};

export default CTAWidgetComponent;
