import {fromEvent, merge, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import buildAssignedTo from '../../../../lib/assignment-helper';
import {filterByMultistoreConversationDisabled} from '../../../../lib/feature-toggle-helper';
import {getFilterIsCurrentPageInHeadingCustomers} from '../../../lib/route-helper';
import {LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_RELEASE,
  LEGACY_CUSTOMER_THREAD_RELEASE_FAILURE,
  LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_TAKE,
  LEGACY_CUSTOMER_THREAD_TAKE_FAILURE,
  LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS
} from '../../../../actions/customer-thread-assignment-actions';
import {
  LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
  LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD
} from '../../../../actions/ext-actions';

const LegacyCustomerThreadAssignmentEpic =
  ({command}, socketio) =>
  (action$, state$) => {
    const employeeReleasedThread = fromEvent(socketio, 'employee_released_customer_thread').pipe(
      filter(filterByMultistoreConversationDisabled(state$)),
      filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
      map(({participationId}) => {
        return {
          type: LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
          participationId
        };
      })
    );

    const legacyEmployeeTookCustomerThread = fromEvent(
      socketio,
      'employee_took_customer_thread'
    ).pipe(
      filter(filterByMultistoreConversationDisabled(state$)),
      filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
      map(({employeeUserId, firstName, lastName, participationId, pictureHref}) => {
        return {
          type: LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD,
          // here we prefer reformat the socket payload as other assignments backend's payloads
          assignedTo: buildAssignedTo(employeeUserId, pictureHref, firstName, lastName),
          participationId
        };
      })
    );

    const legacyFocusToThreadOnTakeSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS),
      map(() => {
        return {
          type: LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE,
          threadType: 'visible'
        };
      })
    );

    const releaseThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_RELEASE),
      mergeMap(({participationId}) => {
        return command('release-customer-thread', {
          participationId
        }).pipe(
          map(() => {
            return {
              type: LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS,
              participationId
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_RELEASE_FAILURE,
              error
            });
          })
        );
      })
    );

    const takeThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_TAKE),
      mergeMap(() => {
        const account = state$.value.get('account');
        const participationId = state$.value.getIn(['legacyCustomerThread', 'participationId']);

        return command('take-customer-thread', {
          participationId
        }).pipe(
          map(() => {
            return {
              type: LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS,
              assignedTo: buildAssignedTo(
                account.get('id'),
                account.get('picture'),
                account.get('firstName'),
                account.get('lastName')
              ),
              participationId
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_TAKE_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(
      employeeReleasedThread,
      legacyEmployeeTookCustomerThread,
      legacyFocusToThreadOnTakeSuccess,
      releaseThread,
      takeThread
    );
  };

export default LegacyCustomerThreadAssignmentEpic;
