import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import i18n from 'i18next';
import {
  getOrganizationAgent,
  mapOrganizationAgentFormToGraphQL
} from '../../lib/administration-organization-channels-helper';
import {
  graphqlCancelAgentVerificationMutation,
  graphqlCreateAgentMutation,
  graphqlGetOrganizationAgentQuery,
  graphqlUpdateAgentMutation
} from '../../graphql-queries/graphql-organization-channels-gbm-query-helper';
import onRouteChange from '../../../../lib/on-route-change';
import {parseSearch} from '../../../../../../../shared/lib/route-helper';
import uploadFile from '../../../../lib/upload-file';
import {
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_SELECT,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS_SNACKBAR_HIDE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_LAYOUT_MOUNTED,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_ROUTE_REDIRECTED_TO,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_DRAFT_FIELDS_PERSIST,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_HANDLE_DRAFT_FIELDS
} from '../../../../../actions/administration/administration-organization-channels-gbm-actions';
import {ADMINISTRATION_ORGANIZATION_CHANNELS} from '../../../../../data/administration/administration-organization-data';
import {
  DEBOUNCE_TIME_MULTIPLE_CLICK,
  SNACKBAR_HIDE_DEBOUNCE_TIME
} from '../../../../../../../shared/data/settings';

const AdministrationOrganizationChannelsGbmEpic =
  ({command, graphql}) =>
  (action$, state$) => {
    const cancelAgentValidation = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL),
      mergeMap(() => {
        const successAction = {
          type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS
        };

        return graphql(graphqlCancelAgentVerificationMutation()).pipe(
          map(() => successAction),
          catchError((error) => {
            const requestFakeSuccess = localStorage.getItem(
              'instaplyDesktop.fake-organization-agent-verification-cancel-successful'
            );

            if (requestFakeSuccess) {
              return of(successAction);
            }

            return of({
              type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_FAILURE,
              error
            });
          })
        );
      })
    );

    const cancelAgentValidationOnAgentValidationCancelButtonClick = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL
      }))
    );

    const createAgent = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE),
      mergeMap(({payload: {fields}}) => {
        const graphqlParams = mapOrganizationAgentFormToGraphQL(fields);
        const successAction = {
          type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS
        };

        return graphql(graphqlCreateAgentMutation(graphqlParams)).pipe(
          map(() => successAction),
          catchError((error) => {
            const requestFakeSuccess = localStorage.getItem(
              'instaplyDesktop.fake-organization-agent-creation-successful'
            );

            if (requestFakeSuccess) {
              return of(successAction);
            }

            return of({
              type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const createAgentOnAgentFormSubmitButtonClick = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({payload}) => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE,
        payload
      }))
    );

    const detectRedirectionToGbm = onRouteChange({
      action: action$,
      regex: new RegExp(`^${ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.desktopAdminPageUrl}`),
      mapFn: ({search}) => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_ROUTE_REDIRECTED_TO,
        payload: {
          ...parseSearch(search)
        }
      })
    });

    const hideUpdateAgentSuccessSnackbarAfterSomeDelay = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS),
      debounceTime(SNACKBAR_HIDE_DEBOUNCE_TIME),
      map(() => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS_SNACKBAR_HIDE
      }))
    );

    const loadAgent = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD),
      mergeMap(() =>
        graphql(graphqlGetOrganizationAgentQuery()).pipe(
          map(({organizationAgent}) => ({
            type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS,
            payload: {
              organizationAgent: getOrganizationAgent(i18n, state$.value, organizationAgent)
            }
          })),
          catchError((error) =>
            of({
              type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_FAILURE,
              error
            })
          )
        )
      )
    );

    const loadAgentOnSomeActionsSuccessful = action$.pipe(
      ofType(
        ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS,
        ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS,
        ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS,
        ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_LAYOUT_MOUNTED
      ),
      map(() => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD
      }))
    );

    const updateAgent = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE),
      mergeMap(({payload: {fields}}) => {
        const {agent} = mapOrganizationAgentFormToGraphQL(fields);
        const successAction = {
          type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS
        };

        return graphql(graphqlUpdateAgentMutation(agent)).pipe(
          map(() => successAction),
          catchError((error) => {
            const requestFakeSuccess = localStorage.getItem(
              'instaplyDesktop.fake-organization-agent-update-successful'
            );

            if (requestFakeSuccess) {
              return of(successAction);
            }

            return of({
              type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const updateAgentOnAgentUpdateButtonClick = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({payload}) => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE,
        payload
      }))
    );

    const uploadAgentLogo = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD),
      mergeMap(({payload: {file}}) =>
        uploadFile({
          command,
          file,
          actions: {
            failure: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_FAILURE,
            success: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_SUCCESS
          }
        })
      )
    );

    const uploadAgentLogoOnFileSelect = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_SELECT),
      map(({payload: {file}}) => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD,
        payload: {
          file
        }
      }))
    );

    const persistAgentDraftFields = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_HANDLE_DRAFT_FIELDS),
      map(({payload}) => ({
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_DRAFT_FIELDS_PERSIST,
        payload
      }))
    );

    return merge(
      cancelAgentValidation,
      cancelAgentValidationOnAgentValidationCancelButtonClick,
      createAgent,
      createAgentOnAgentFormSubmitButtonClick,
      detectRedirectionToGbm,
      hideUpdateAgentSuccessSnackbarAfterSomeDelay,
      loadAgent,
      loadAgentOnSomeActionsSuccessful,
      persistAgentDraftFields,
      updateAgent,
      updateAgentOnAgentUpdateButtonClick,
      uploadAgentLogo,
      uploadAgentLogoOnFileSelect
    );
  };

export default AdministrationOrganizationChannelsGbmEpic;
