import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {fontSize} from '../../../../../shared/style/variables';
import {lightSlate} from '../../../../../shared/style/colors';

class InboxLikeTimeLayoutComponent extends PureComponent {
  render() {
    const {children, ...otherProps} = this.props;

    return (
      <span
        {...otherProps}
        style={{
          textAlign: 'right',
          whiteSpace: 'nowrap',
          color: lightSlate,
          fontSize: fontSize.small
        }}
      >
        {children}
      </span>
    );
  }
}

InboxLikeTimeLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default InboxLikeTimeLayoutComponent;
