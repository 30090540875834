import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import {fontSize, spacing} from '../../../../../shared/style/variables';
import {lightSlate} from '../../../../../shared/style/colors';

const AttachmentPreviewComponent = ({contentType, i18n}) => {
  const isImage = contentType.indexOf('image') > -1;

  return (
    <span
      className="attachment-preview"
      style={{
        color: lightSlate,
        fontSize: fontSize.medium
      }}
    >
      <FontIcon
        className="material-icons"
        style={{
          marginRight: spacing.small,
          color: lightSlate,
          fontSize: fontSize.large
        }}
      >
        {isImage ? 'image' : 'attachment'}
      </FontIcon>
      {i18n.t(isImage ? 'inbox.imageAttached' : 'inbox.fileAttached')}
    </span>
  );
};

AttachmentPreviewComponent.propTypes = {
  contentType: PropTypes.string.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AttachmentPreviewComponent;
