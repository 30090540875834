import PropTypes from 'prop-types';
import CloseOnEscapeComponent from './close-on-escape-component';

class KeyboardClosableComponent extends CloseOnEscapeComponent {
  componentWillUpdate(nextProps) {
    if (this.props.open && !nextProps.open) {
      window.removeEventListener('keydown', this._onKeyDown);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      window.addEventListener('keydown', this._onKeyDown);
    }
  }

  // Called on [Escape]
  _doClose() {
    this.props.onRequestClose();
  }
}

KeyboardClosableComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

export default KeyboardClosableComponent;
