import {jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Get query to load a customer conversation.
 * Properties selection VS thread type depends of their utility regarding the type.
 * @param {String} conversationId
 * @param {Boolean} isInternal
 * @param {Object} slice
 * @returns {String}
 */
const graphqlGetConversationQuery = ({conversationId, isInternal, slice}) => {
  const isVisible = !isInternal;

  const query = {
    query: {
      conversation: {
        __args: {
          conversationId
        },
        business: isVisible
          ? {
              deactivated: true,
              id: true,
              name: true
            }
          : false,
        conversationItems: {
          __args: {
            internal: isInternal,
            slice
          },
          edges: {
            cursor: true,
            node: {
              attachment: {
                contentLength: true,
                contentType: true,
                href: true,
                name: true,
                sticker: isVisible
              },
              automatic: true,
              clientItemId: true,
              date: true,
              employee: {
                displayName: true,
                id: true,
                pictureHref: true
              },
              firstOfSegment: true,
              id: true,
              mentions: isInternal
                ? {
                    name: true,
                    type: true
                  }
                : false,
              moderated: isVisible,
              orgIcon: true,
              sortId: true,
              status: true, // Is required in case node type is "STATUS_UPDATE"
              text: true,
              type: true
            }
          },
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: true
          }
        },
        hasCannedResponses: isVisible,
        id: true,
        readOnly: isVisible,
        windowExpiration: isVisible
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

/**
 * Get query to load conversations & customer profile.
 * @param {String} customerId
 * @returns {String}
 */
const graphqlGetConversationsAndCustomerProfileQuery = (customerId) => {
  return `
    query {
      conversations(customerId: "${customerId}") {
        assignedTo {
          displayName
          id
          pictureHref
        }
        business {
          id
          name
          shortName
        }
        engaged
        id
        order
        readOnly
        status
        unreadInternalMessages
        unreadVisibleMessages
      }
      customer(id: "${customerId}") {
        channel
        company
        displayName
        emailAddress
        firstName
        id
        lastName
        phoneNumber
        pictureHref
      }
    }
  `;
};

/**
 * Get mutation to create a payment message into a conversation.
 * @param {Number} amount
 * @param {String} conversationId
 * @param {String} description
 * @param {String} firstName
 * @param {String} lastName
 * @returns {String}
 */
const graphqlGetCreatePaymentMutation = ({
  amount,
  conversationId,
  description,
  firstName,
  lastName
}) => {
  const mutation = {
    mutation: {
      createPayment: {
        __args: {
          amount,
          conversationId,
          description,
          firstName,
          lastName
        },
        flag: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Get mutation to mark a customer conversation as read.
 * @param {String} conversationId
 * @param {Boolean} internal
 * @returns {String}
 */
const graphqlGetMarkCustomerConversationAsReadMutation = (conversationId, internal) => {
  const mutation = {
    mutation: {
      markConversationAsRead: {
        __args: {
          conversationId,
          internal
        },
        flag: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Get query to just load conversations.
 * @param {String} customerId
 * @returns {String}
 */
const graphqlGetConversationsQuery = (customerId, props) => {
  const query = {
    query: {
      conversations: {
        __args: {
          customerId
        },
        id: true,
        ...props
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

export {
  graphqlGetConversationsQuery,
  graphqlGetConversationQuery,
  graphqlGetConversationsAndCustomerProfileQuery,
  graphqlGetCreatePaymentMutation,
  graphqlGetMarkCustomerConversationAsReadMutation
};
