import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {List, ListItem} from 'material-ui/List';
import IconButton from 'material-ui/IconButton/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import context from '../../../../../../shared/component/context';
import EnhancedPopover from '../../../common/enhanced-popover-component';
import InviteButton from '../../../global/invitation/global-invitation-invite-button-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import UserAvatar from '../../../common/avatar/user-avatar-component';
import {animationSpeed, avatarSize, fontSize} from '../../../../../../shared/style/variables';
import {blue, smoke, lightSlate, lightSnow, black} from '../../../../../../shared/style/colors';

const InvisibleThreadMentionComponent = (props, {i18n}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const textInput = useRef(null);
  const {
    business,
    items,
    loading,
    open,
    text,
    userToMention,
    doColleagueInvitationPopupOpen,
    doConversationInternalMentionPopupClose,
    doConversationInternalMentionPopupOpen,
    doConversationInternalMentionPopupSearch,
    doConversationInternalMentionPopupSelect,
    doConversationInternalMentionUnselect
  } = props;

  useEffect(() => {
    if (anchorEl) {
      doConversationInternalMentionPopupOpen();
    }
  }, [anchorEl]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (textInput.current) {
          textInput.current.focus();
        }
      }, animationSpeed.medium);
    }
  }, [open]);

  const doOpenPopin = (event) => {
    event.preventDefault();

    if (userToMention) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const closePoppin = () => {
    setAnchorEl(null);
    doConversationInternalMentionPopupClose();
  };

  const selectCollegueToMentionFromList = (user) => {
    setAnchorEl(null);
    doConversationInternalMentionPopupSelect(user);
  };

  const renderColleagueList = () => {
    return items.length ? (
      items.map((user) => {
        return (
          <ListItem
            key={user.userId}
            onClick={() => selectCollegueToMentionFromList(user)}
            innerDivStyle={{width: '100%'}}
            primaryText={
              <span
                data-test-id="item-label"
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {user.displayName}
              </span>
            }
            leftAvatar={
              <UserAvatar
                size={avatarSize.xsmall}
                src={user.pictureHref}
                userIconStyle={{
                  position: 'absolute',
                  left: '16px',
                  top: '8px'
                }}
              />
            }
          />
        );
      })
    ) : (
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        {i18n.t('common.noResult')}
      </span>
    );
  };

  const doSearchColleague = (event) => {
    doConversationInternalMentionPopupSearch(business.id, event.target.value);
  };

  const onClickColleagueInvitationPopupOpenButton = () => {
    setAnchorEl(null);
    doConversationInternalMentionPopupClose();
    doColleagueInvitationPopupOpen();
  };

  const showColleagueInvite = !loading && !items.length;

  return (
    <span style={{display: 'flex'}}>
      <EnhancedPopover
        dataTestId="mention-popup"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'middle', vertical: 'top'}}
        targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
        onRequestClose={closePoppin}
        style={{
          minHeight: '300px',
          maxHeight: '300px',
          height: '300px',
          width: '300px',
          maxWidth: '300px'
        }}
      >
        <h3
          data-test-id="search-a-colleague-to-mention-title"
          style={{padding: '15px 15px 0 15px'}}
        >
          {i18n.t('customerThread.invisibleThread.mentionPopup.title', {
            businessName: ''
          })}
        </h3>
        <span
          style={{
            display: 'flex',
            padding: '0px 15px 10px 15px'
          }}
        >
          <input
            data-test-id="search-a-colleague-to-mention-input"
            ref={textInput}
            value={text}
            placeholder={i18n.t(
              'customerThread.invisibleThread.mentionPopup.searchInputPlaceholder'
            )}
            onChange={doSearchColleague}
            style={{
              boxSizing: 'border-box',
              width: '100%',
              marginTop: '8px',
              padding: '7px 32px 7px 12px',
              outline: 'none',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: lightSnow,
              fontSize: fontSize.medium
            }}
          />
        </span>
        <List
          data-test-id="mention-colleagues-list"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: showColleagueInvite ? '180px' : '220px',
            minHeight: '180px',
            overflow: 'scroll',
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          {loading ? <LoadingIndicator color={lightSlate} size={30} /> : renderColleagueList()}
        </List>
        {showColleagueInvite ? (
          <InviteButton
            color={blue}
            label={i18n.t('colleagueInvitation.longLinkLabel')}
            doColleagueInvitationPopupOpen={onClickColleagueInvitationPopupOpenButton}
            style={{
              marginTop: '10px'
            }}
          />
        ) : null}
      </EnhancedPopover>
      <span onClick={doOpenPopin}>
        {userToMention ? (
          <span
            style={{
              display: 'flex',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginBottom: '3px',
              marginLeft: '3px',
              padding: '3px 3px 3px 10px',
              borderRadius: '50px',
              lineHeight: '20px',
              whiteSpace: 'nowrap',
              background: smoke
            }}
          >
            <span
              data-test-id="mention-pill-colleague-to-mention"
              style={{
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '60px',
                whiteSpace: 'nowrap'
              }}
            >
              {userToMention.displayName}
            </span>
            <IconButton
              data-test-id="mention-remove-mention-pill-button"
              tabIndex={-1}
              onClick={doConversationInternalMentionUnselect}
              style={{
                width: '20px',
                height: '20px',
                marginLeft: '5px',
                padding: 0,
                background: 'transparent'
              }}
              iconStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '0px',
                width: '20px',
                height: '20px',
                borderRadius: '50px',
                background: lightSlate,
                color: smoke
              }}
            >
              <CloseIcon />
            </IconButton>
          </span>
        ) : (
          <IconButton
            data-test-id="mention-a-colleague-button"
            tabIndex={-1}
            style={{
              width: '33px',
              height: '33px',
              padding: 0,
              background: 'transparent'
            }}
            iconStyle={{
              width: '25px',
              height: '25px',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '5px',
              position: 'relative',
              borderRadius: '50px',
              fontSize: '18px',
              color: black
            }}
          >
            <span>@</span>
          </IconButton>
        )}
      </span>
    </span>
  );
};

InvisibleThreadMentionComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  userToMention: PropTypes.objectOf(PropTypes.any),
  doConversationInternalMentionPopupClose: PropTypes.func.isRequired,
  doConversationInternalMentionPopupOpen: PropTypes.func.isRequired,
  doConversationInternalMentionPopupSearch: PropTypes.func.isRequired,
  doConversationInternalMentionPopupSelect: PropTypes.func.isRequired,
  doConversationInternalMentionUnselect: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired
};

InvisibleThreadMentionComponent.contextTypes = context;

export default InvisibleThreadMentionComponent;
