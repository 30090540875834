const checkBusinessInScope = (businesses, businessId) => {
  return businesses.some((business) => business.businessId === businessId);
};

const findBusinessLocaleByBusinessId = (accountBusinesses, businessId) =>
  accountBusinesses.find((business) => business.businessId === businessId)?.locale;

const findBusinessAddressByBusinessId = (accountBusinesses, businessId) =>
  accountBusinesses.find((business) => business.businessId === businessId)?.address;

const findBusinessNameByBusinessId = (accountBusinesses, businessId) =>
  accountBusinesses.find((business) => business.businessId === businessId)?.name;

export {
  checkBusinessInScope,
  findBusinessLocaleByBusinessId,
  findBusinessAddressByBusinessId,
  findBusinessNameByBusinessId
};
