import {fromEvent, merge, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {filterByMultistoreConversationEnabled} from '../lib/feature-toggle-helper';
import {getFilterIsCurrentPageInHeadingCustomers} from './lib/route-helper';
import {guessSpreadActionFromMessage} from './lib/socket-helper';
import {CONVERSATION_ITEM_TYPES} from '../data/thread/message';
import {
  EXT_CONVERSATION_NEW_MESSAGE_SPREAD_FAILURE,
  EXT_CONVERSATION_STATUS_CHANGED,
  EXT_EMPLOYEE_RELEASED_CONVERSATION,
  EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
  EXT_EMPLOYEE_TOOK_CONVERSATION,
  EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD
} from '../actions/ext-actions';
import {containStatusOnCustomerInboxFilteredStatuses} from './content/customer/inbox/lib/customer-inbox-helper';

const SocketEpic = (socketio) => (action$, state$) => {
  const spreadEventEmployeeReleasedConversation = fromEvent(
    socketio,
    'employee_released_conversation'
  ).pipe(
    filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
    filter(filterByMultistoreConversationEnabled(state$)),
    map(({conversation, employee, event}) => ({
      type: EXT_EMPLOYEE_RELEASED_CONVERSATION,
      payload: {
        event: {
          ...event,
          type: CONVERSATION_ITEM_TYPES.RELEASED
        },
        conversation,
        employee
      }
    }))
  );

  const spreadEventEmployeeReleasedCustomerThread = fromEvent(
    socketio,
    'employee_released_customer_thread'
  ).pipe(
    filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
    filter(filterByMultistoreConversationEnabled(state$)),
    map(({participationId}) => ({
      type: EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
      payload: {
        participationId
      }
    }))
  );

  const spreadEventEmployeeTookConversation = fromEvent(
    socketio,
    'employee_took_conversation'
  ).pipe(
    filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
    filter(filterByMultistoreConversationEnabled(state$)),
    map(({conversation, employee, event}) => ({
      type: EXT_EMPLOYEE_TOOK_CONVERSATION,
      payload: {
        event: {
          ...event,
          type: CONVERSATION_ITEM_TYPES.ENGAGED
        },
        conversation,
        employee
      }
    }))
  );

  const spreadEventEmployeeTookCustomerThread = fromEvent(
    socketio,
    'employee_took_customer_thread'
  ).pipe(
    filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
    filter(filterByMultistoreConversationEnabled(state$)),
    map(({participationId}) => ({
      type: EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD,
      payload: {
        participationId
      }
    }))
  );

  // Because each type of new message event has its own and very different impact from the others,
  // we prefer to differentiate each case from the beginning of the redux flow.
  const spreadEventNewMessage = fromEvent(socketio, 'new_message').pipe(
    filter(filterByMultistoreConversationEnabled(state$)),
    map(({conversation, message, unreadConversationsCounter}) => ({
      type: guessSpreadActionFromMessage(state$, message),
      payload: {
        conversation,
        message,
        unreadConversationsCounter
      }
    })),
    catchError((error) =>
      of({
        type: EXT_CONVERSATION_NEW_MESSAGE_SPREAD_FAILURE,
        error: error ? error.toString() : null
      })
    )
  );

  const spreadEventEmployeeStatusChangeConversation = fromEvent(
    socketio,
    'conversation_status_changed'
  ).pipe(
    filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
    filter(filterByMultistoreConversationEnabled(state$)),
    map(({event, conversation, employee, manualChange, status}) => {
      const customerInboxState = state$.value.get('customerInbox');

      return {
        type: EXT_CONVERSATION_STATUS_CHANGED,
        needInboxRefresh: containStatusOnCustomerInboxFilteredStatuses(customerInboxState, status),
        payload: {
          event: {...event, type: CONVERSATION_ITEM_TYPES.STATUS_UPDATE, status: status.new},
          conversation,
          employee,
          manualChange,
          status
        }
      };
    })
  );

  return merge(
    spreadEventEmployeeStatusChangeConversation,
    spreadEventEmployeeReleasedConversation,
    spreadEventEmployeeReleasedCustomerThread,
    spreadEventEmployeeTookConversation,
    spreadEventEmployeeTookCustomerThread,
    spreadEventNewMessage
  );
};

export default SocketEpic;
