import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {cloneDeep} from 'lodash';
import {
  getConversationMentionsFormatted,
  getCurrentConversationFromState,
  getInternalConversationText,
  getSliceParams
} from './lib/customer-thread-conversations-helper';
import {graphqlGetConversationQuery} from './graphql-queries/graphql-conversation-query-helper';
import {isCurrentPageCustomerThread} from '../../../../lib/route-helper';
import {isEmployeeOnConversationType} from '../../../lib/conversation-helper';
import uploadFile from '../../../lib/upload-file';
import {CUSTOMER_CONVERSATION_MARK_AS_READ} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD,
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_FAILURE,
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_SUCCESS,
  CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_CHANGED,
  CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_PERSIST,
  CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION,
  CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION_BUTTON_CLICK,
  CUSTOMER_THREAD_INVISIBLE_LOAD,
  CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED,
  CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE,
  CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_FAILURE,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_SUCCESS,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY_BUTTON_CLICK
} from '../../../../actions/customer-thread-invisible-actions';
import {CUSTOMER_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-thread-visible-actions';
import {EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME} from '../../../../actions/ext-actions';
import {DEBOUNCE_TIME_TYPING_THREAD} from '../../../../data/settings';
import {CONVERSATION_MESSAGES_LOAD_DIRECTIONS} from '../../../../data/thread/message';
import {getSendMessageActionFromMessageForm} from './lib/conversation-item-helper';
import {postToServerTheMessageInConversation} from '../../../lib/conversation-item-helper';
import {getFilterIsCurrentPageInHeadingCustomers} from '../../../lib/route-helper';
import {findMessageIndexInConversationItem} from '../../../../lib/thread/conversation-item-helper';

const CustomerThreadInvisibleEpic =
  ({command, graphql}) =>
  (action$, state$) => {
    const loadInvisibleThread = action$.pipe(
      ofType(CUSTOMER_THREAD_INVISIBLE_LOAD),
      mergeMap(({customerId, direction, messageCursor, participationId}) => {
        const conversationId = getCurrentConversationFromState(state$).get('id');
        const slice = getSliceParams(direction, messageCursor);

        return graphql(graphqlGetConversationQuery({conversationId, isInternal: true, slice})).pipe(
          map(({conversation}) => {
            if (
              !isCurrentPageCustomerThread({conversationId, customerId, participationId, state$})
            ) {
              return {
                type: CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED,
                // useful only for debug
                conversationId,
                customerId,
                participationId
              };
            }

            return {
              type: CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS,
              payload: {
                data: {
                  conversation
                },
                params: {
                  direction,
                  messageCursor
                }
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadInvisibleThreadOnVisibleThreadLoad = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_LOAD),
      map(({customerId, internalMessageCursor, participationId}) => {
        return {
          type: CUSTOMER_THREAD_INVISIBLE_LOAD,
          customerId,
          participationId,
          // The only flow that involves an internal message cursor is to load messages around it
          ...(internalMessageCursor
            ? {
                direction: CONVERSATION_MESSAGES_LOAD_DIRECTIONS.AROUND,
                messageCursor: internalMessageCursor
              }
            : null)
        };
      })
    );

    // UI does it optimistically on load in order to avoid counters clipping,
    // but we ensure that there is no loading error before doing it for real
    const markConversationInternalAsReadOnLoadSuccess = action$.pipe(
      ofType(CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS),
      map(
        ({
          payload: {
            data: {conversation}
          }
        }) => ({
          type: CUSTOMER_CONVERSATION_MARK_AS_READ,
          payload: {
            conversation: {
              id: conversation.id
            },
            internal: true
          }
        })
      )
    );

    const markConversationInternalAsReadOnNewMessageNotFromMe = action$.pipe(
      ofType(EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME),
      filter(({payload: {conversation}}) =>
        isEmployeeOnConversationType(state$, conversation, 'invisible')
      ),
      map(({payload: {conversation}}) => ({
        type: CUSTOMER_CONVERSATION_MARK_AS_READ,
        payload: {
          conversation: {
            id: conversation.id
          },
          internal: true
        }
      }))
    );

    const persistMessageFormTextOnTextChanged = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_CHANGED),
      debounceTime(DEBOUNCE_TIME_TYPING_THREAD),
      map(({payload: {conversationId, text}}) => ({
        type: CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_PERSIST,
        payload: {
          conversationId,
          text
        }
      }))
    );

    const uploadFileInConversationInternal = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD),
      mergeMap(({file}) =>
        uploadFile({
          command,
          file,
          actions: {
            failure: CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_FAILURE,
            success: CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_SUCCESS
          }
        })
      )
    );

    const clickButtonOnMentionColleague = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION_BUTTON_CLICK),
      map(() => ({
        type: CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION
      }))
    );

    const clickButtonConversationInternalMessageFormSubmit = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_BUTTON_CLICK),
      map(({payload: {message}}) =>
        getSendMessageActionFromMessageForm(
          state$,
          CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE,
          message
        )
      )
    );

    const sendMessageInternalConversation = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE),
      mergeMap((sendAction) => {
        const {
          payload: {conversation, message, mentions}
        } = sendAction;

        const defaultPropertiesToSendInternalMessage = {
          graphql,
          conversation: {
            id: conversation.id,
            internal: true
          },
          message: {
            attachment: message.attachment,
            clientItemId: message.clientItemId,
            text: getInternalConversationText(message, mentions)
          }
        };

        const params = mentions
          ? {
              ...defaultPropertiesToSendInternalMessage,
              mentions: getConversationMentionsFormatted(mentions)
            }
          : defaultPropertiesToSendInternalMessage;

        return postToServerTheMessageInConversation(params).pipe(
          filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
          map(({sendMessageToConversation}) => ({
            type: CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_SUCCESS,
            payload: {
              conversation: {
                id: conversation.id
              },
              message: {
                clientItemId: message.clientItemId,
                cursor: sendMessageToConversation.cursor,
                id: sendMessageToConversation.id,
                sortId: sendMessageToConversation.sortId
              }
            }
          })),
          catchError((error) => {
            const failedAction = cloneDeep(sendAction);
            delete failedAction._googleAnalytics;

            return of({
              type: CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_FAILURE,
              payload: {
                failedAction
              },
              error
            });
          })
        );
      })
    );

    const retrySendMessageConversationInternalOnButtonClick = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY_BUTTON_CLICK),
      map(({payload: {clientItemId}}) => {
        const customerInvisibleThreadState = state$.value.get('customerInvisibleThread');

        const messageIndex = findMessageIndexInConversationItem(customerInvisibleThreadState, {
          clientItemId
        });

        const failedAction = customerInvisibleThreadState
          .getIn(['conversationItems', 'edges', messageIndex, 'node', '_sendAction'])
          .toJS();

        return {
          type: CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY,
          failedAction
        };
      })
    );

    return merge(
      clickButtonConversationInternalMessageFormSubmit,
      clickButtonOnMentionColleague,
      loadInvisibleThread,
      loadInvisibleThreadOnVisibleThreadLoad,
      markConversationInternalAsReadOnLoadSuccess,
      markConversationInternalAsReadOnNewMessageNotFromMe,
      persistMessageFormTextOnTextChanged,
      retrySendMessageConversationInternalOnButtonClick,
      sendMessageInternalConversation,
      uploadFileInConversationInternal
    );
  };

export default CustomerThreadInvisibleEpic;
