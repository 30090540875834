import compact from 'lodash/compact';
import {SERVER_HIGHLIGHT_REGEX} from '../../../lib/list-item-helper';
import {fontWeight} from '../../../../../shared/style/variables';

const HIGHLIGHT_PROPS = {
  'data-test-id': 'highlight',
  style: {
    fontWeight: fontWeight.bold
  }
};

/**
 * Transform a text from server highlight
 * into React components ui highlight.
 *
 * @param {String} text
 * @returns {String|any[]}
 */
// eslint-disable-next-line import/prefer-default-export
export const highlight = (text) => {
  // see @AWI-5298
  if ([undefined, null].includes(text)) {
    return '';
  }

  const items = text.split(SERVER_HIGHLIGHT_REGEX);

  // this edge case could be removed, but it happens most of the time and its code is faster
  if (items.length === 1) {
    return text;
  }

  return compact(
    items.map((textPart, index) => {
      if (!textPart) {
        return null;
      }

      return (
        <span key={String(index)} {...(index % 2 !== 0 ? HIGHLIGHT_PROPS : null)}>
          {textPart}
        </span>
      );
    })
  );
};
