module.exports = [
  'family',
  'man-boy',
  'man-boy-boy',
  'man-girl',
  'man-girl-boy',
  'man-girl-girl',
  'man-heart-man',
  'man-kiss-man',
  'man-man-boy',
  'man-man-boy-boy',
  'man-man-girl',
  'man-man-girl-boy',
  'man-man-girl-girl',
  'man-woman-boy',
  'man-woman-boy-boy',
  'man-woman-girl',
  'man-woman-girl-boy',
  'man-woman-girl-girl',
  'woman-boy',
  'woman-girl',
  'woman-girl-boy',
  'woman-girl-girl',
  'woman-boy-boy',
  'woman-heart-man',
  'woman-heart-woman',
  'woman-kiss-man',
  'woman-kiss-woman',
  'woman-woman-boy',
  'woman-woman-boy-boy',
  'woman-woman-girl',
  'woman-woman-girl-boy',
  'woman-woman-girl-girl',

  // repeated and/or inconsistent
  'blond-haired-man',
  'face_palm',
  'handball',
  'juggling',
  'male-construction-worker',
  'male-detective',
  'male-guard',
  'male-police-officer',
  'man-biking',
  'man-bouncing-ball',
  'man-bowing',
  'man-golfing',
  'man-lifting-weights',
  'man-mountain-biking',
  'man-rowing-boat',
  'man-running',
  'man-surfing',
  'man-swimming',
  'man-walking',
  'man-wearing-turban',
  'person_doing_cartwheel',
  'shrug',
  'water_polo',
  'woman-frowning',
  'woman-getting-haircut',
  'woman-getting-massage',
  'woman-gesturing-no',
  'woman-gesturing-ok',
  'woman-pouting',
  'woman-raising-hand',
  'woman-tipping-hand',
  'woman-with-bunny-ears-partying',
  'wrestlers'
];
