import {isMultistoreConversationEnabled} from './feature-toggle-helper';

const isBusinessInMyScope = (myBusinesses, businessId) =>
  myBusinesses.some((business) => business.get('businessId') === businessId);

const isInboxFilteredByBusiness = (state$) =>
  state$.value.getIn(['customerInbox', 'businessId']) !== null;

const isThreadCurrentlyOpened = (state$, participationId) => {
  const participationIdFromState = isMultistoreConversationEnabled(state$)
    ? state$.value.getIn(['customerThread', 'participationId'])
    : state$.value.getIn(['legacyCustomerThread', 'participationId']);

  return participationId === participationIdFromState;
};

export {isBusinessInMyScope, isInboxFilteredByBusiness, isThreadCurrentlyOpened};
