import PropTypes from 'prop-types';
import LegacyCustomerInboxThreadListItem from '../../../../common/inbox/legacy-customer-thread-list-item-component';

const ResultItemBase = ({hasMore, indexInList, list, onResultItemClick, props, ...otherProps}) => {
  const {
    businessName,
    businessParticipationId,
    company,
    customerChannel,
    customerPictureHref,
    title
  } = props;

  const SEARCH_ITEM_COMMON_WRAPPER_HEIGHT = '110px';

  return (
    <LegacyCustomerInboxThreadListItem
      displayName={title}
      handleCustomerChannel
      isLastChild={!list[indexInList + 1]}
      participationId={businessParticipationId}
      pictureHref={customerPictureHref}
      selectItem={onResultItemClick}
      showLastChildBorder={hasMore}
      {...otherProps}
      {...{
        businessName,
        company,
        customerChannel,
        indexInList
      }}
      centerWrapperStyle={{height: SEARCH_ITEM_COMMON_WRAPPER_HEIGHT}}
      rightWrapperStyle={{height: SEARCH_ITEM_COMMON_WRAPPER_HEIGHT}}
    />
  );
};
ResultItemBase.propTypes = {
  businessName: PropTypes.string,
  businessParticipationId: PropTypes.string,
  company: PropTypes.string,
  customerChannel: PropTypes.string,
  customerPictureHref: PropTypes.string,
  hasMore: PropTypes.bool,
  indexInList: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  onResultItemClick: PropTypes.func.isRequired,
  props: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string
};

const renderResultItemForCustomerLegacy = ({
  hasMore,
  indexInList,
  list,
  onResultItemClick,
  props,
  selected
}) => (
  <ResultItemBase
    isSelected={props.businessParticipationId === selected}
    key={indexInList}
    lastPublicMessageBody={props.lastPublicMessageBody}
    lastPublicMessageDate={props.lastPublicMessageDate}
    {...{
      hasMore,
      indexInList,
      list,
      onResultItemClick,
      props
    }}
  />
);

const renderResultItemForMessageLegacy = ({
  hasMore,
  indexInList,
  list,
  onResultItemClick,
  props,
  selected
}) => (
  <ResultItemBase
    isSelected={props.messageId === selected}
    key={indexInList}
    lastPublicMessageBody={props.messageBody}
    lastPublicMessageDate={props.messageDate}
    messageId={props.messageId}
    {...{
      hasMore,
      indexInList,
      list,
      onResultItemClick,
      props
    }}
  />
);

export {renderResultItemForCustomerLegacy, renderResultItemForMessageLegacy};
