/**
 * Format a duration following some very custom rules for insights.
 * Algorithm is intentionally kind of language-agnostic (simple usage of "if", minimal library)
 * in order to be easily compared with same feature's code on mobile.
 * @see https://instaply.atlassian.net/wiki/spaces/Product/pages/1078689810/Weekly+email+and+Summary+Page-+Display+rules
 *
 * @param {Number} value time in millisecond
 * @param {Object} i18n
 * @returns {String}
 */
export default (value, i18n) => {
  if (!value) {
    return '0sec';
  }

  // Milliseconds to seconds
  const duration = Math.floor(value / 1000);

  const seconds = (duration % 3600) % 60;
  const minutes = Math.floor((duration % 3600) / 60);
  const hours = Math.floor((duration % (24 * 3600)) / 3600);
  const days = Math.floor(duration / (24 * 3600));

  // Less than 1min (Show just seconds)
  if (duration < 60) {
    return `${seconds}sec`;
  }

  // Until 2min31 (Show minutes and seconds)
  if (duration <= 151) {
    const result = `${minutes}min`;

    return seconds === 0 ? result : result + `${seconds}`.padStart(2, '0');
  }

  // Less than 60 minutes (Show just minutes)
  if (duration < 3600) {
    return `${minutes}min`;
  }

  // Less than 24h (Show hours and minutes)
  if (duration < 3600 * 24) {
    // eslint-disable-next-line no-nested-ternary
    const hoursNew = minutes === 60 ? hours + 1 : hours > 0 ? hours : 1;
    const minutesNew = minutes === 60 || hours === 0 ? 0 : minutes;

    const result = `${hoursNew}h`;

    return minutesNew === 0 ? result : `${result + `${minutes}`.padStart(2, '0')}min`;
  }

  // Until 72h 59m (Show just hours (even if it's a day, show 24h))
  if (duration <= 3600 * 72 + 59 * 60) {
    const daysInHours = days * 24 + hours;

    return `${daysInHours}h`;
  }

  // More than 72h 59m (Show just days)
  return `${days}${i18n.t('insight.content.common.durationSuffixes.day')}`;
};
