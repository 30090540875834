import {APP_HOME_URL} from '../../../shared/data/route';
import {parseSearch} from '../../../shared/lib/route-helper';

export default () => {
  const {next} = parseSearch(window.location.search);

  if (next) {
    return next + window.location.hash;
  }

  return APP_HOME_URL;
};
