export const CUSTOMER_THREAD_RELEASE_BUTTON_CLICK = 'CUSTOMER_THREAD_RELEASE_BUTTON_CLICK';
export const CUSTOMER_THREAD_RELEASE = 'CUSTOMER_THREAD_RELEASE';
export const CUSTOMER_THREAD_RELEASE_FAILURE = 'CUSTOMER_THREAD_RELEASE_FAILURE';
export const CUSTOMER_THREAD_RELEASE_SUCCESS = 'CUSTOMER_THREAD_RELEASE_SUCCESS';

export const CUSTOMER_THREAD_TAKE_BUTTON_CLICK = 'CUSTOMER_THREAD_TAKE_BUTTON_CLICK';
export const CUSTOMER_THREAD_TAKE = 'CUSTOMER_THREAD_TAKE';
export const CUSTOMER_THREAD_TAKE_FAILURE = 'CUSTOMER_THREAD_TAKE_FAILURE';
export const CUSTOMER_THREAD_TAKE_SUCCESS = 'CUSTOMER_THREAD_TAKE_SUCCESS';

// Legacy

export const LEGACY_CUSTOMER_THREAD_RELEASE = 'LEGACY_CUSTOMER_THREAD_RELEASE';
export const LEGACY_CUSTOMER_THREAD_RELEASE_FAILURE = 'LEGACY_CUSTOMER_THREAD_RELEASE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS = 'LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_TAKE = 'LEGACY_CUSTOMER_THREAD_TAKE';
export const LEGACY_CUSTOMER_THREAD_TAKE_FAILURE = 'LEGACY_CUSTOMER_THREAD_TAKE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS = 'LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS';
