export const SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_FAILURE =
  'SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_FAILURE';
export const SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_SUCCESS =
  'SIGNIN_CHECK_NOT_SUPPORTED_MOBILE_SUCCESS';

export const SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE =
  'SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE';
export const SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS =
  'SIGNIN_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS';

export const SIGNIN_LOGIN_BLOCKING_EXPIRED = 'SIGNIN_LOGIN_BLOCKING_EXPIRED';

export const SIGNIN_LOGIN_SUBMIT = 'SIGNIN_LOGIN_SUBMIT';
export const SIGNIN_LOGIN_SUBMIT_FAILURE = 'SIGNIN_LOGIN_SUBMIT_FAILURE';
export const SIGNIN_LOGIN_SUBMIT_SUCCESS = 'SIGNIN_LOGIN_SUBMIT_SUCCESS';
