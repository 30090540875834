import PropTypes from 'prop-types';
import context from '../../../../shared/component/context';
import LoadingArea from './loading-area-component';
import Waypoint from './waypoint-component';

const AutomaticLoadMoreComponent = ({dataTestId, height, loading, loadMore}, {i18n}) => (
  <LoadingArea
    dataTestId={dataTestId}
    height={height}
    content={loading ? i18n.t('common.loading') : <Waypoint {...{loadMore}} />}
  />
);

AutomaticLoadMoreComponent.contextTypes = context;

AutomaticLoadMoreComponent.propTypes = {
  dataTestId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired
};

export default AutomaticLoadMoreComponent;
