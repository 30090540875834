import {merge, of} from 'rxjs';
import {catchError, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND,
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND_FAILURE,
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND_SUCCESS,
  APP_STATE_BOOT_LOAD_RETRY,
  APP_STATE_BOOT_LOAD_SUCCESS
} from '../../actions/app-actions';
import {VERIFY_EMAIL_CHECK_VERIFIED_DELAY} from '../../data/settings';

const VerifyEmailEpic =
  ({graphql}) =>
  (action$) => {
    const resendVerificationEmail = action$.pipe(
      ofType(APP_GLOBAL_VERIFICATION_EMAIL_RESEND),
      mergeMap(() => {
        return graphql('send-email-verification-email-mutation').pipe(
          map(() => {
            return {
              type: APP_GLOBAL_VERIFICATION_EMAIL_RESEND_SUCCESS
            };
          }),
          catchError((error) => {
            return of({
              type: APP_GLOBAL_VERIFICATION_EMAIL_RESEND_FAILURE,
              error
            });
          })
        );
      })
    );

    const triggerRegularlyEmailVerifiedCheck = action$.pipe(
      ofType(APP_STATE_BOOT_LOAD_SUCCESS),
      filter(({data}) => !data.account.emailVerified),
      delay(VERIFY_EMAIL_CHECK_VERIFIED_DELAY),
      map(() => {
        return {
          type: APP_STATE_BOOT_LOAD_RETRY
        };
      })
    );

    return merge(resendVerificationEmail, triggerRegularlyEmailVerifiedCheck);
  };

export default VerifyEmailEpic;
