import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  APP_STATE_BOOT_LOAD_SUCCESS,
  APP_STATE_REFRESH_LOAD_SUCCESS
} from '../../actions/app-actions';
import {LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED} from '../../actions/ext-actions';
import {
  HEADER_MENTIONS_POPUP_CLOSE,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS,
  HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_SUCCESS,
  HEADER_MENTIONS_POPUP_OPEN
} from '../../actions/header-actions';

export const DEFAULT_STATE = {
  edges: [],
  loading: false,
  open: false,
  pageInfo: {
    hasNextPage: false
  },
  unreadMentionsCount: null
};

const refreshUnreadMentionsCount = (state, {data: {unreadMentionsCount}}) =>
  state.set('unreadMentionsCount', unreadMentionsCount);

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Update interesting properties from app initial state.
   */
  [APP_STATE_BOOT_LOAD_SUCCESS]: refreshUnreadMentionsCount,
  [APP_STATE_REFRESH_LOAD_SUCCESS]: refreshUnreadMentionsCount,

  /**
   * A socket event indicates that user has a new mention.
   */
  [LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED]: (state, {payload: {unreadMentionsCount}}) =>
    refreshUnreadMentionsCount(state, {data: {unreadMentionsCount}}),

  /**
   * Handle the visibility of the header mention's popup.
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [HEADER_MENTIONS_POPUP_CLOSE]: (state) => state.set('open', false),
  [HEADER_MENTIONS_POPUP_OPEN]: (state) => state.set('loading', true).set('open', true),

  /**
   * Load the header mentions's popup list.
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [HEADER_MENTIONS_POPUP_ITEMS_LOAD]: (state) => state.set('loading', true),

  /**
   * Header mention popup's list loading success.
   * @param {Immutable.Map} state
   * @param {String} after
   * @param {String} before
   * @param {Object} mentions
   * @returns {Immutable.Map} new state
   */
  [HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS]: (state, {payload: {after, before, mentions}}) => {
    const loadedItems = fromJS(mentions.edges);

    let newState;
    if (after) {
      newState = state
        .set('edges', state.get('edges').concat(loadedItems))
        .setIn(['pageInfo', 'hasNextPage'], mentions.pageInfo.hasNextPage);
    } else if (before) {
      // Be careful if you need to touch next lines, as duplicates removal handling is touchy (beware of object memory comparison)
      newState = state.set(
        'edges',
        loadedItems
          .concat(state.get('edges'))
          .groupBy((edge) => edge.get('cursor'))
          .map((groupedEdges) => groupedEdges.first())
          .toList()
      );
    } else {
      newState = state
        .set('edges', loadedItems)
        .setIn(['pageInfo', 'hasNextPage'], mentions.pageInfo.hasNextPage);
    }

    return newState.set('loading', false);
  },

  /**
   * Decrease unread counter on one mentions popup item as read success.
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_SUCCESS]: (state) =>
    state.set('unreadMentionsCount', state.get('unreadMentionsCount') - 1)
});
