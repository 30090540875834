import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FontIcon from 'material-ui/FontIcon';
import context from '../../../../../../shared/component/context';
import {InboxHeaderLayout} from '../../../common/inbox/inbox-header-components';
import Link from '../../../common/link/link-component';
import {lightSlate, lightSnow} from '../../../../../../shared/style/colors';

class CustomerInboxSearchListHeaderComponent extends PureComponent {
  componentDidMount() {
    this.doFocusInput();
  }

  onClickCancel = () => {
    const {doInboxSearchFormToggle} = this.props;
    doInboxSearchFormToggle(false);
  };

  onClickTextInputReset = () => {
    const {onInboxSearchFormTextClearButtonClick} = this.props;
    onInboxSearchFormTextClearButtonClick();

    this.doFocusInput();
  };

  onTextInputChange = (event) => {
    const {onInboxSearchFormTextChange} = this.props;
    onInboxSearchFormTextChange(event.target.value);
  };

  doFocusInput() {
    this._input.focus();
  }

  render() {
    const {i18n} = this.context;
    const {text} = this.props;

    return (
      <InboxHeaderLayout style={{padding: '8px'}}>
        <input
          data-test-id="search-inbox-input"
          onChange={this.onTextInputChange}
          placeholder={i18n.t('customerInbox.header.search.inputPlaceholder')}
          ref={(itself) => {
            this._input = itself;

            return this._input;
          }}
          title={i18n.t('customerInbox.header.search.inputPlaceholder')}
          value={text}
          style={{
            width: '100%',
            padding: '0px 35px 0px 12px',
            outline: 'none',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: lightSnow,
            fontSize: '100%'
          }}
        />
        {text ? (
          <FontIcon
            data-test-id="search-inbox-input-clear-button"
            className="material-icons"
            color={lightSlate}
            onClick={this.onClickTextInputReset}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '12px',
              right: 0,
              marginRight: '75px'
            }}
          >
            clear
          </FontIcon>
        ) : null}
        <Link
          data-test-id="search-inbox-cancel-button"
          label={i18n.t('common.cancel')}
          onClick={this.onClickCancel}
          style={{
            padding: '10px'
          }}
        />
      </InboxHeaderLayout>
    );
  }
}

CustomerInboxSearchListHeaderComponent.contextTypes = context;
CustomerInboxSearchListHeaderComponent.propTypes = {
  text: PropTypes.string.isRequired,
  onInboxSearchFormTextChange: PropTypes.func.isRequired,
  onInboxSearchFormTextClearButtonClick: PropTypes.func.isRequired,
  doInboxSearchFormToggle: PropTypes.func.isRequired
};

export default connect((state) => {
  return {
    search: state.get('search')
  };
})(CustomerInboxSearchListHeaderComponent);
