/**
 * Read a local file.
 *
 * @see https://scotch.io/tutorials/use-the-html5-file-api-to-work-with-files-locally-in-the-browser
 *
 * @param {File} file
 * @param {Object} eventSubscriptions
 * @param {String} as
 */
export function readLocalFile(file, eventSubscriptions, as) {
  // generate a new FileReader object
  const reader = new FileReader();

  // subscribe to events
  ['onabort', 'onerror', 'onload', 'onloadstart', 'onloadend', 'onprogress'].forEach((value) => {
    const subscription = eventSubscriptions[value];

    if (typeof subscription === 'function') {
      reader[value] = subscription;
    }
  });

  // when the file is read, it triggers the onload event above
  switch (as) {
    case 'DataURL': {
      reader.readAsDataURL(file);
      break;
    }
    case 'Text': {
      reader.readAsText(file);
      break;
    }
    default:
      break;
  }
}

/**
 * This method prevents few unexplainable cases where undefined files are sent.
 * @param {Object} file
 * @param {Function} onSelectFile
 * @returns {boolean} true if file is valid
 */
export const validateAndForwardFile = (file, onSelectFile) => {
  if (file && file.type) {
    onSelectFile(file);

    return true;
  }

  return false;
};
