import PropTypes from 'prop-types';
import {lightGrey} from '../../../../../../shared/style/colors';

const AdministrationLeftMenuBlockLayoutComponent = ({children, dataTestId, style}) => {
  return (
    <ul
      data-test-id={dataTestId}
      style={{
        borderBottom: `1px solid ${lightGrey}`,
        listStyle: 'none',
        marginLeft: '26px',
        padding: '0 0 20px 0',
        ...style
      }}
    >
      {children}
    </ul>
  );
};

AdministrationLeftMenuBlockLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default AdministrationLeftMenuBlockLayoutComponent;
