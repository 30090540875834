import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form/dist/index.ie11';
import PropTypes from 'prop-types';
import {Snackbar} from '@material-ui/core';
import AdministrationDialog from '../../../../layout/administration-dialog-component';
import AdministrationDisclaimer from '../../../../layout/administration-disclaimer-component';
import AdministrationFormOverlay from '../../../../layout/administration-form-overlay-component';
import ContentImportantMessage from '../../../../../../common/content/content-important-message-component';
import ErrorMessageAdvanced from '../../../../../../common/error-message-advanced-component';
import {getFormValidators} from '../../../../../../../../../shared/component/lib/react-hook-form-validators';
import FieldBlock from '../../../../layout/field-block-component';
import FilePreviewRemoveIcon from '../../../../../../common/icon/file-preview-remove-icon';
import FileSelector from '../../../../../../common/file/file-selector-component';
import LoadingIndicator from '../../../../../../../../../shared/component/loading-indicator-component';
import OrganizationAvatar from '../../../../../../common/avatar/organization-avatar-component';
import OutlinedButton from '../../../../../../../../../shared/component/button/outlined-button-component';
import StandardRaisedButton from '../../../../../../../../../shared/component/button/standard-raised-button-component';
import availableLanguages from '../../../../../../../../../shared/data/language';
import {FORM_SELECT_STYLE} from '../../../../../../../../../shared/style/form';
import {radius} from '../../../../../../../../../shared/style/variables';
import {
  black,
  blue,
  grey,
  honeydew,
  lightGrey,
  red,
  veryLightGrey
} from '../../../../../../../../../shared/style/colors';
import {
  ADMINISTRATION_ORGANIZATION_CHANNELS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES
} from '../../../../../../../data/administration/administration-organization-data';
import AdministrationOrganizationChannelsGbmPreview from './administration-organization-channels-gbm-preview-component';

const AdministrationOrganizationChannelsGbmAgentFormComponent = ({
  agent,
  agentDraftFields,
  agentForm: {
    cancelling,
    hasGlobalError,
    logoUploaded,
    showUpdateSuccessSnackbar,
    submitting,
    updating,
    uploadingLogo
  },
  i18n,
  onAgentFormLogoRemoveButtonClick,
  onAgentFormLogoSelect,
  onAgentFormSubmitConfirmButtonClick,
  onAgentUpdateButtonClick,
  onAgentValidationCancelConfirmButtonClick,
  onGbmHandleDraftFields
}) => {
  const [showDialogConfirmCreateAgent, setShowDialogConfirmCreateAgent] = useState(false);
  const [showDialogConfirmCancelValidationAgent, setShowDialogConfirmCancelValidationAgent] =
    useState(false);

  const {LAUNCH_STATE_LAUNCHED, LAUNCH_STATE_PENDING, STATE_NEW, VERIFICATION_STATE_PENDING} =
    ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES;
  const isLaunched = agent.status === LAUNCH_STATE_LAUNCHED;
  const isLaunchPending = agent.status === LAUNCH_STATE_PENDING;
  const isVerificationPending = agent.status === VERIFICATION_STATE_PENDING;
  const isStateNew = agent.status === STATE_NEW;

  const isOnlyUpdatable = isLaunched || isLaunchPending;

  const brandNameCharactersMaxCount =
    ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.steps.createAgent.brandName.charactersMaxCount;
  const fieldStyle = {
    border: `1px solid ${lightGrey}`,
    padding: '5px 10px',
    borderRadius: radius.large
  };
  const i18nPrefix = 'administration.organization.channels.flows.gbm.steps.agentForm';

  const canShowDraftAgentValues = isStateNew && agentDraftFields;

  const defaultIntialValuesObject = canShowDraftAgentValues ? agentDraftFields : agent;

  const {
    formState: {errors, isSubmitted},
    watch,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      applyLogoToOrg: canShowDraftAgentValues ? agentDraftFields.applyLogoToOrg : true,
      brandName: defaultIntialValuesObject.brandName,
      brandWebsiteUrl: defaultIntialValuesObject.brandWebsiteUrl,
      contactEmail: defaultIntialValuesObject.contactEmail,
      contactName: defaultIntialValuesObject.contactName,
      locale: defaultIntialValuesObject.locale,
      privacyPolicyUrl: defaultIntialValuesObject.privacyPolicyUrl,
      welcomeMessage: defaultIntialValuesObject.welcomeMessage,
      // The trick about logo is to validate it through several props, but submit it using it's uuid
      logoContentLength: '',
      logoUuid: ''
    }
  });

  const {
    validationRuleCharactersMaxCount,
    validationRuleEmail,
    validationRuleFileSize,
    validationRuleRequired,
    validationRuleUrl
  } = getFormValidators({
    charactersMaxCount: brandNameCharactersMaxCount,
    fileMaxSize: {
      errorLabel: ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.steps.createAgent.logo.fileMaxSizeLabel,
      value: ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.steps.createAgent.logo.fileMaxSize
    }
  });

  const onHandleDraftFields = () => {
    const draftFields = watch([
      'applyLogoToOrg',
      'brandName',
      'locale',
      'welcomeMessage',
      'privacyPolicyUrl',
      'contactName',
      'contactEmail',
      'brandWebsiteUrl'
    ]);

    if (isStateNew) {
      onGbmHandleDraftFields({...draftFields, agentLogoUrl: logoUploaded.href});
    }
  };

  useEffect(() => {
    setValue('logoContentLength', logoUploaded.contentLength, {shouldValidate: isSubmitted});
    setValue('logoUuid', logoUploaded.uuid, {shouldValidate: isSubmitted});
    onHandleDraftFields();
  }, [logoUploaded.uuid]);

  const onClickCancelCreateAgent = () => {
    setShowDialogConfirmCreateAgent(false);
  };

  const onClickConfirmCreateAgent = () => {
    const values = getValues();

    setShowDialogConfirmCreateAgent(false);

    onAgentFormSubmitConfirmButtonClick(values);

    reset(values, {
      dirtyFields: true,
      errors: true,
      isDirty: true,
      isSubmitted: false,
      isValid: true,
      submitCount: false,
      touched: true
    });
  };

  const onClickCancelValidationAgent = () => {
    setShowDialogConfirmCancelValidationAgent(true);
  };

  const onClickCancelTheCancellingOfValidationAgent = () => {
    setShowDialogConfirmCancelValidationAgent(false);
  };

  const onClickConfirmTheCancellingOfValidationAgent = () => {
    setShowDialogConfirmCancelValidationAgent(false);

    onAgentValidationCancelConfirmButtonClick();
  };

  const onSubmitFormButtonClick = () => {
    if (isVerificationPending) {
      return;
    }

    if (isOnlyUpdatable) {
      const mutableValues = getValues(['locale', 'privacyPolicyUrl', 'welcomeMessage']);

      onAgentUpdateButtonClick(mutableValues);

      return;
    }

    setShowDialogConfirmCreateAgent(true);
  };

  const renderBlockLogo = () => {
    const hasLogoUploaded = agent.agentLogoUrl;
    const logoPreviewSize = 100;

    if (isOnlyUpdatable) {
      return (
        <FieldBlock
          data-test-id="agent-logo-url-block"
          disabled
          label={i18n.t(`${i18nPrefix}.accountPersonalization.fields.agentLogoUrl.label`)}
        >
          <OrganizationAvatar size={logoPreviewSize} src={agent.agentLogoUrl} />
        </FieldBlock>
      );
    }

    return (
      <FieldBlock
        data-test-id="agent-logo-url-block"
        errorMessage={errors.logoUuid?.message || errors.logoContentLength?.message}
        label={i18n.t(`${i18nPrefix}.accountPersonalization.fields.agentLogoUrl.label`)}
      >
        <input name="logoContentLength" ref={register(validationRuleFileSize)} type="hidden" />
        <input name="logoUuid" ref={register(validationRuleRequired)} type="hidden" />
        <div
          style={{
            backgroundColor: veryLightGrey,
            borderRadius: '5px',
            display: 'flex',
            padding: '6px'
          }}
        >
          <div
            style={{
              alignItems: 'center',
              backgroundColor: lightGrey,
              display: 'flex',
              minHeight: `${logoPreviewSize}px`,
              justifyContent: 'center',
              position: 'relative',
              minWidth: `${logoPreviewSize}px`
            }}
          >
            {uploadingLogo ? (
              <LoadingIndicator />
            ) : (
              <React.Fragment>
                {(hasLogoUploaded && !isVerificationPending) ||
                (canShowDraftAgentValues && agentDraftFields.agentLogoUrl) ? (
                  <FilePreviewRemoveIcon
                    data-test-id="logo-preview-delete-button"
                    onClick={onAgentFormLogoRemoveButtonClick}
                    style={{
                      top: '-3px',
                      right: '-5px'
                    }}
                  />
                ) : null}
                <OrganizationAvatar
                  dataTestId="main-agent-logo"
                  size={hasLogoUploaded || canShowDraftAgentValues ? logoPreviewSize : null}
                  src={canShowDraftAgentValues ? agentDraftFields.agentLogoUrl : agent.agentLogoUrl}
                />
              </React.Fragment>
            )}
          </div>
          <div
            style={{
              color: grey,
              fontSize: '12px',
              padding: '6px 8px',
              position: 'relative',
              width: '100%'
            }}
          >
            {uploadingLogo ? (
              <LoadingIndicator />
            ) : (
              <div style={{display: 'flex', flexDirection: 'column', marginTop: '25px'}}>
                <FileSelector
                  clickableElement={(doInputClick) => (
                    <span
                      data-test-id="edit-logo-link"
                      dangerouslySetInnerHTML={{
                        __html: i18n.t(
                          `${i18nPrefix}.accountPersonalization.fields.agentLogoUrl.clickHereToEdit`
                        )
                      }}
                      onClick={doInputClick}
                      style={{color: isVerificationPending ? grey : blue, fontSize: '14px'}}
                    />
                  )}
                  extensionsSupported={
                    ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.steps.createAgent.logo
                      .fileExtensionsSupported
                  }
                  onSelectFile={onAgentFormLogoSelect}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(
                      `${i18nPrefix}.accountPersonalization.fields.agentLogoUrl.fileSizeRules`
                    )
                  }}
                  style={{marginTop: '5px'}}
                />
              </div>
            )}
            <label
              htmlFor="agentLogoUrlCheckbox"
              style={{alignItems: 'center', display: 'flex', position: 'absolute', bottom: 0}}
            >
              <input
                data-test-id="apply-logo-to-org"
                id="agentLogoUrlCheckbox"
                name="applyLogoToOrg"
                ref={register}
                type="checkbox"
                onChange={onHandleDraftFields}
              />
              {i18n.t(`${i18nPrefix}.accountPersonalization.fields.agentLogoUrl.checkboxLabel`)}
            </label>
          </div>
        </div>
      </FieldBlock>
    );
  };

  const renderActionsButtons = () => {
    if (cancelling || submitting || updating) {
      return <LoadingIndicator size={36} />;
    }

    if (isOnlyUpdatable) {
      return (
        <StandardRaisedButton
          label={i18n.t(`${i18nPrefix}.updatableOnly.updateButton`)}
          style={{width: 'auto'}}
        />
      );
    }

    if (isVerificationPending) {
      return (
        <OutlinedButton
          color={red}
          data-test-id="validation-cancel-button"
          onClick={onClickCancelValidationAgent}
        >
          {i18n.t(`${i18nPrefix}.validationPending.validationCancelButton`)}
        </OutlinedButton>
      );
    }

    return (
      <StandardRaisedButton
        label={i18n.t(`${i18nPrefix}.createAgent.validateButton`)}
        style={{width: 'auto'}}
      />
    );
  };

  const renderDisclaimer = () => {
    const style = {marginBottom: '15px'};
    let labelsI18nKey;

    if (isLaunched) {
      labelsI18nKey = `${i18nPrefix}.launched.disclaimer`;

      style.backgroundColor = honeydew;
    }

    if (isLaunchPending) {
      labelsI18nKey = `${i18nPrefix}.launchPending.disclaimer`;
    }

    if (isVerificationPending) {
      labelsI18nKey = `${i18nPrefix}.validationPending.disclaimer`;
    }

    if (!labelsI18nKey) {
      return null;
    }

    return (
      <AdministrationDisclaimer
        labels={i18n.t(labelsI18nKey, {returnObjects: true})}
        {...{style}}
      />
    );
  };

  const renderFormDisabledOverlay = () => {
    if (!isVerificationPending) {
      return null;
    }

    return <AdministrationFormOverlay />;
  };

  const renderGlobalMessages = () => (
    <React.Fragment>
      {isOnlyUpdatable ? (
        <ContentImportantMessage
          data-test-id="info-message"
          description={i18n.t(`${i18nPrefix}.updatableOnly.infoMessage`)}
          style={{margin: '5px 0 20px 0'}}
          type="info"
        />
      ) : null}

      {hasGlobalError ? (
        <ErrorMessageAdvanced
          data-test-id="global-error-message"
          title={i18n.t('common.errorDefault')}
        />
      ) : null}
    </React.Fragment>
  );

  const renderSectionTitle = (label) => (
    <h2 style={{fontSize: '14px', marginBottom: '5px'}}>{label}</h2>
  );

  const renderSnackbar = () => (
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      data-test-id="snack-gbm-agent-update-success"
      message={i18n.t('form.actionResult.success.update')}
      open={showUpdateSuccessSnackbar}
    />
  );

  const previewProps = watch(['brandName', 'welcomeMessage']);
  const logoPreview = logoUploaded.href ?? null;

  return (
    <div style={{display: 'flex'}}>
      <div style={{color: black, width: '600px'}}>
        <form onSubmit={handleSubmit(onSubmitFormButtonClick)}>
          {renderDisclaimer()}

          <div style={{position: 'relative'}}>
            {renderFormDisabledOverlay()}

            {renderSectionTitle(i18n.t(`${i18nPrefix}.accountPersonalization.title`))}

            {renderBlockLogo()}

            <FieldBlock
              data-test-id="brand-name-block"
              disabled={isOnlyUpdatable}
              errorMessage={errors.brandName?.message}
              label={i18n.t(`${i18nPrefix}.accountPersonalization.fields.brandName.label`)}
              subLabel={i18n.t(`${i18nPrefix}.accountPersonalization.fields.brandName.subLabel`)}
            >
              <input
                autoComplete="organization"
                autoFocus={!isOnlyUpdatable && !isVerificationPending}
                data-test-id="brand-name-input"
                disabled={isOnlyUpdatable}
                name="brandName"
                ref={register({
                  ...validationRuleRequired,
                  ...validationRuleCharactersMaxCount
                })}
                style={fieldStyle}
                onChange={onHandleDraftFields}
              />
            </FieldBlock>

            <FieldBlock
              data-test-id="locale-block"
              label={i18n.t(`${i18nPrefix}.accountPersonalization.fields.locale`)}
            >
              <select
                data-test-id="language-select"
                name="locale"
                ref={register}
                style={{
                  ...FORM_SELECT_STYLE,
                  ...fieldStyle,
                  borderRadius: '15px',
                  maxWidth: '200px'
                }}
                onChange={onHandleDraftFields}
              >
                {availableLanguages.map(({key, label}) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </FieldBlock>

            <FieldBlock
              data-test-id="welcome-message-block"
              description={i18n.t(
                `${i18nPrefix}.accountPersonalization.fields.welcomeMessage.description`
              )}
              errorMessage={errors.welcomeMessage?.message}
              label={i18n.t(`${i18nPrefix}.accountPersonalization.fields.welcomeMessage.label`)}
            >
              <textarea
                autoComplete="off"
                data-test-id="welcome-message-textarea"
                name="welcomeMessage"
                ref={register(validationRuleRequired)}
                rows={3}
                style={fieldStyle}
                onChange={onHandleDraftFields}
              />
            </FieldBlock>

            <FieldBlock
              data-test-id="privacy-policy-url-block"
              description={i18n.t(
                `${i18nPrefix}.accountPersonalization.fields.privacyPolicyUrl.description`
              )}
              errorMessage={errors.privacyPolicyUrl?.message}
              label={i18n.t(`${i18nPrefix}.accountPersonalization.fields.privacyPolicyUrl.label`)}
            >
              <input
                autoComplete="url"
                data-test-id="privacy-policy-url-input"
                name="privacyPolicyUrl"
                placeholder={i18n.t(
                  `${i18nPrefix}.accountPersonalization.fields.privacyPolicyUrl.hint`
                )}
                ref={register({...validationRuleRequired, ...validationRuleUrl})}
                style={fieldStyle}
                onChange={onHandleDraftFields}
              />
            </FieldBlock>

            {!isOnlyUpdatable ? (
              <React.Fragment>
                <hr color={lightGrey} noshade="true" size={1} style={{margin: '20px 0'}} />

                {renderSectionTitle(i18n.t(`${i18nPrefix}.contact.title`))}

                <FieldBlock
                  data-test-id="contact-name-block"
                  errorMessage={errors.contactName?.message}
                  label={i18n.t(`${i18nPrefix}.contact.fields.contactName.label`)}
                >
                  <input
                    autoComplete="name"
                    data-test-id="contact-name-input"
                    name="contactName"
                    placeholder={i18n.t(`${i18nPrefix}.contact.fields.contactName.hint`)}
                    ref={register(validationRuleRequired)}
                    style={fieldStyle}
                    onChange={onHandleDraftFields}
                  />
                </FieldBlock>

                <FieldBlock
                  data-test-id="contact-email-block"
                  description={i18n.t(`${i18nPrefix}.contact.fields.contactEmail.description`)}
                  errorMessage={errors.contactEmail?.message}
                  label={i18n.t(`${i18nPrefix}.contact.fields.contactEmail.label`)}
                >
                  <input
                    autoComplete="email"
                    data-test-id="contact-email-input"
                    name="contactEmail"
                    placeholder={i18n.t(`${i18nPrefix}.contact.fields.contactEmail.hint`)}
                    ref={register({...validationRuleRequired, ...validationRuleEmail})}
                    style={fieldStyle}
                    onChange={onHandleDraftFields}
                  />
                </FieldBlock>

                <FieldBlock
                  data-test-id="brand-website-url-block"
                  errorMessage={errors.brandWebsiteUrl?.message}
                  label={i18n.t(`${i18nPrefix}.contact.fields.brandWebsiteUrl.label`)}
                >
                  <input
                    autoComplete="url"
                    data-test-id="brand-website-url-input"
                    name="brandWebsiteUrl"
                    placeholder={i18n.t(`${i18nPrefix}.contact.fields.brandWebsiteUrl.hint`)}
                    ref={register({...validationRuleRequired, ...validationRuleUrl})}
                    style={fieldStyle}
                    onChange={onHandleDraftFields}
                  />
                </FieldBlock>
              </React.Fragment>
            ) : null}
          </div>

          {renderGlobalMessages()}

          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
            {renderActionsButtons()}
          </div>
        </form>
        <AdministrationDialog
          labels={i18n.t(`${i18nPrefix}.createAgent.dialogConfirm`, {returnObjects: true})}
          onClickCancel={onClickCancelCreateAgent}
          onClickConfirm={onClickConfirmCreateAgent}
          opened={showDialogConfirmCreateAgent}
        />

        <AdministrationDialog
          confirmColor={red}
          labels={i18n.t(`${i18nPrefix}.validationPending.dialogConfirm`, {returnObjects: true})}
          onClickCancel={onClickCancelTheCancellingOfValidationAgent}
          onClickConfirm={onClickConfirmTheCancellingOfValidationAgent}
          opened={showDialogConfirmCancelValidationAgent}
        />

        {renderSnackbar()}
      </div>
      <AdministrationOrganizationChannelsGbmPreview {...{previewProps, i18n, logoPreview}} />
    </div>
  );
};

AdministrationOrganizationChannelsGbmAgentFormComponent.propTypes = {
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
  agentDraftFields: PropTypes.objectOf(PropTypes.any),
  agentForm: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onAgentFormLogoRemoveButtonClick: PropTypes.func.isRequired,
  onAgentFormLogoSelect: PropTypes.func.isRequired,
  onAgentFormSubmitConfirmButtonClick: PropTypes.func.isRequired,
  onAgentUpdateButtonClick: PropTypes.func.isRequired,
  onAgentValidationCancelConfirmButtonClick: PropTypes.func.isRequired,
  onGbmHandleDraftFields: PropTypes.func.isRequired
};

export default AdministrationOrganizationChannelsGbmAgentFormComponent;
