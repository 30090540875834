import i18n from 'i18next';
import isEmail from 'validator/es/lib/isEmail';
import isURL from 'validator/es/lib/isURL';

// Basic rules
const checkIsEmail = (value) => isEmail(value) || i18n.t('form.emailAddressRules');

const checkIsUrl = (value) =>
  isURL(value, {protocols: ['https'], require_protocol: true}) || i18n.t('form.urlRule');

const getCheckFileMaxSize = (maxSize, maxSizeLabel) => (value) =>
  value <= maxSize || i18n.t('form.fileMaxSize', {maxSize: maxSizeLabel});

// Validators
const getFormValidators = ({charactersMaxCount, fileMaxSize}) => {
  const validationRuleCharactersMaxCount = {
    maxLength: {
      value: charactersMaxCount,
      message: i18n.t('form.charactersMaxCountRule', {count: charactersMaxCount})
    }
  };

  const validationRuleEmail = {
    validate: {
      checkIsEmail
    }
  };

  const validationRuleFileSize = {
    validate: {
      checkFileMaxSize: getCheckFileMaxSize(fileMaxSize.value, fileMaxSize.errorLabel)
    }
  };

  const validationRuleRequired = {
    required: i18n.t('form.thisFieldIsRequired')
  };

  const validationRuleUrl = {
    validate: {
      checkIsUrl
    }
  };

  return {
    validationRuleCharactersMaxCount,
    validationRuleEmail,
    validationRuleFileSize,
    validationRuleRequired,
    validationRuleUrl
  };
};

// eslint-disable-next-line import/prefer-default-export
export {getFormValidators};
