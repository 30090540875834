import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from './lib/get-insights-map-dispatch-to-props';
import InsightNewInboundConversationsComponent from '../../../../component/content/insights/page/insight-new-inbound-conversations-component';

const InsightNewInboundConversationsContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.get('insight'),
      (state) => state.get('insightBusinessFilter'),
      (state) => state.get('ui')
    ],
    (businesses, insight, insightBusinessFilter, ui) => {
      return {
        accountBusinesses: businesses.toJS(),
        businessesSelection: insight.get('businessesSelection').toJS(),
        classicAggregationDate: insight.get('classicAggregationDate'),
        classicAggregationPeriod: insight.get('classicAggregationPeriod'),
        customAggregationPeriod: insight.get('customAggregationPeriod'),
        customPeriodEndDate: insight.get('customPeriodEndDate'),
        customPeriodStartDate: insight.get('customPeriodStartDate'),
        insight: insight.get('newInboundConversations').toJS(),
        insightBusinessFilter: insightBusinessFilter.toJS(),
        isSmallScreen: ui.get('isSmallScreen'),
        periodTabSelected: insight.get('periodTabSelected'),
        uiWidth: ui.get('width')
      };
    }
  ),
  getInsightsMapDispatchToProps('newInboundConversations')
)(InsightNewInboundConversationsComponent);

export default InsightNewInboundConversationsContainer;
