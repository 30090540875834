import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {fontSize, fontWeight, spacing} from '../../../../../../shared/style/variables';

class SectionTextWithLinkComponent extends PureComponent {
  render() {
    const {description, linkDataTestId, linkLabel, title, url} = this.props;

    return (
      <div
        style={{
          marginBottom: spacing.xxlarge
        }}
      >
        <h2
          style={{
            marginBottom: spacing.large,
            fontSize: fontSize.xlarge,
            fontWeight: fontWeight.semiBold
          }}
        >
          {title}
        </h2>

        {description ? <span dangerouslySetInnerHTML={{__html: description}} /> : null}

        <a
          data-test-id={linkDataTestId}
          href={url}
          target="_blank"
          rel="noreferrer"
          style={description ? {marginLeft: '4px'} : null}
        >
          {linkLabel}
        </a>
      </div>
    );
  }
}

SectionTextWithLinkComponent.propTypes = {
  description: PropTypes.string,
  linkDataTestId: PropTypes.string,
  linkLabel: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default SectionTextWithLinkComponent;
