import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import AdministrationComponent from '../../../component/content/administration/administration-component';
import administrationMapDispatchToProps from './administration-map-dispatch-to-props';

const AdministrationContainer = connect(
  createSelector(
    [
      (state) => state.get('account'),
      (state) => state.getIn(['administration', 'layout']),
      (state) => state.get('featureToggle'),
      (state) => state.get('ui')
    ],
    (account, administrationLayout, featureToggle, ui) => ({
      account: account.toJS(),
      administrationLayoutState: administrationLayout.toJS(),
      enableBusinessMessages: featureToggle.get('enable_business_messages'),
      enableIpRanges: featureToggle.get('ip_ranges'),
      language: ui.get('language')
    })
  ),
  administrationMapDispatchToProps
)(AdministrationComponent);

export default AdministrationContainer;
