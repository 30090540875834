import moment from 'moment';

/**
 * This function homogenize backend payload regarding object "assignedTo".
 * @param {String} id
 * @param {String} picture
 * @param {String} firstName
 * @param {String} lastName
 * @returns {Object}
 */
function buildAssignedTo(id, picture, firstName, lastName) {
  return {
    assignedAt: moment().format(),
    user: {
      firstName,
      lastName,
      id,
      picture
    }
  };
}

export default buildAssignedTo;
