import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import CustomerComponent from '../../../component/content/customer/customer-component';
import {
  CUSTOMER_INBOX_BUSINESS_FILTER_CLOSE,
  CUSTOMER_INBOX_BUSINESS_FILTER_OPEN,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES,
  CUSTOMER_INBOX_LEAVE,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK,
  CUSTOMER_INBOX_NEW_CONVERSATION_NEW_CUSTOMER_FORM_CUSTOMER_CLICK,
  CUSTOMER_INBOX_NEW_THREAD_START_CLOSE,
  CUSTOMER_INBOX_NEW_THREAD_START_SHOW,
  CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE,
  CUSTOMER_INBOX_SEARCH_FORM_TEXT_CLEAR_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_FORM_TOGGLE,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK,
  CUSTOMER_INBOX_THREADS_LIST_FILTER_BY_STATUS,
  CUSTOMER_INBOX_THREADS_LIST_LOAD,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS,
  CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_START
} from '../../../actions/customer-inbox-actions';
import {UI_AREA_CLICK} from '../../../../../shared/actions/ui-actions';

const CustomerContainer = connect(
  createSelector(
    [
      (state) => state.get('account'),
      (state) => state.get('customerInbox'),
      (state) => state.get('customerInboxBusinessFilter'),
      (state) => state.get('featureToggle')
    ],
    (account, customerInbox, customerInboxBusinessFilter, featureToggle) => {
      return {
        account: account.toJS(),
        customerInboxBusinessFilter: customerInboxBusinessFilter.toJS(),
        enableLasRespondentAvatarInInbox: featureToggle.get(
          'enable_last_respondent_avatar_in_inbox'
        ),
        enableMultistoreConversation: featureToggle.get('enable_multistore_conversation'),
        hasBusinesses: account.get('businesses').size > 0,
        inbox: customerInbox.toJS()
      };
    }
  ),
  {
    onUIAreaClick(area) {
      return {
        type: UI_AREA_CLICK,
        payload: {
          area
        }
      };
    },
    doLeaveCustomerInbox() {
      return {
        type: CUSTOMER_INBOX_LEAVE
      };
    },
    doInboxThreadsListThreadClick({
      company,
      directedToBusinessName,
      participantIcon,
      participantName,
      participationId,
      readOnly,
      status
    }) {
      return {
        type: CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK,
        payload: {
          company,
          directedToBusinessName,
          participantIcon,
          participantName,
          participationId,
          readOnly,
          status
        }
      };
    },
    doFilterInboxByStatuses(statuses) {
      return {
        type: CUSTOMER_INBOX_THREADS_LIST_FILTER_BY_STATUS,
        _googleAnalytics: true,
        statuses
      };
    },
    doLoadInboxThreadsList(statuses, offset, sortBy) {
      return {
        type: CUSTOMER_INBOX_THREADS_LIST_LOAD,
        offset,
        sortBy,
        statuses
      };
    },

    // Search
    onInboxSearchFormTextChange(text) {
      return {
        type: CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE,
        _googleAnalytics: true,
        payload: {
          text
        }
      };
    },
    onInboxSearchFormTextClearButtonClick() {
      return {
        type: CUSTOMER_INBOX_SEARCH_FORM_TEXT_CLEAR_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doInboxSearchFormToggle(showSearchHeader) {
      return {
        type: CUSTOMER_INBOX_SEARCH_FORM_TOGGLE,
        payload: {
          showSearchHeader
        }
      };
    },
    onInboxSearchMoreCustomersButtonClick() {
      return {
        type: CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK
      };
    },
    onInboxSearchMoreMessagesButtonClick() {
      return {
        type: CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK
      };
    },
    onInboxSearchResultCustomerClick({indexInList}) {
      return {
        type: CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK,
        _googleAnalytics: true,
        payload: {
          indexInList
        }
      };
    },
    onInboxSearchResultMessageClick({indexInList}) {
      return {
        type: CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK,
        _googleAnalytics: true,
        payload: {
          indexInList
        }
      };
    },

    // Filter threads by business
    doCustomerInboxBusinessFilterOpen() {
      return {
        type: CUSTOMER_INBOX_BUSINESS_FILTER_OPEN,
        _googleAnalytics: true
      };
    },
    doCustomerInboxBusinessFilterClose() {
      return {
        type: CUSTOMER_INBOX_BUSINESS_FILTER_CLOSE,
        _googleAnalytics: true
      };
    },
    doCustomerInboxBusinessFilterSearch(event) {
      return {
        type: CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES,
        _googleAnalytics: true,
        text: event.target.value
      };
    },
    doLoadInboxThreadsListFilteredByBusiness(businessId, businessName, businessScope) {
      return {
        type: CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS,
        _googleAnalytics: true,
        businessId,
        businessName,
        businessScope
      };
    },

    // Start new conversation
    doCustomerInboxNewConversationFormChanged(country, text) {
      return {
        type: CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED,
        _googleAnalytics: true,
        payload: {
          country,
          text
        }
      };
    },
    doCustomerInboxNewConversationFormCustomerClick({business, customer}) {
      return {
        type: CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK,
        _googleAnalytics: true,
        payload: {
          business,
          customer
        }
      };
    },

    doCustomerInboxNewConversationNewCustomerFormCustomerClick({
      businessId,
      businessName,
      email,
      phoneNumber
    }) {
      return {
        type: CUSTOMER_INBOX_NEW_CONVERSATION_NEW_CUSTOMER_FORM_CUSTOMER_CLICK,
        _googleAnalytics: true,
        payload: {
          businessId,
          businessName,
          email,
          phoneNumber
        }
      };
    },

    // Start new conversation (shared with legacy)
    doShowStartNewThread() {
      return {
        type: CUSTOMER_INBOX_NEW_THREAD_START_SHOW
      };
    },
    doCloseStartNewThread() {
      return {
        type: CUSTOMER_INBOX_NEW_THREAD_START_CLOSE
      };
    },

    // Start new thread (legacy)
    doStartNewCustomerThreadSelectLegacy({businessId, businessName, inScope, participationId}) {
      return {
        type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT,
        _googleAnalytics: true,
        businessId,
        businessName,
        inScope,
        participationId
      };
    },
    doStartNewCustomerThreadLegacy({businessId, businessName, email, phoneNumber}) {
      return {
        type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_START,
        _googleAnalytics: true,
        businessId,
        businessName,
        email,
        phoneNumber
      };
    }
  }
)(CustomerComponent);

export default CustomerContainer;
