import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {ListItem} from '@material-ui/core';
import context from '../../../../../shared/component/context';
import {
  getMarginType,
  getSelectedColor,
  getSelectedDataTestId,
  MARGIN_BETWEEN_SUB_ITEMS,
  SUB_ITEM_TITLE_LINE_HEIGHT
} from './lib/thread-list-item-helper';
import AttachmentPreview from './attachment-preview-component';
import CappedBadge from '../capped-badge-component';
import Elapsed from '../elapsed-component';
import EmojiRender from '../emoji/emoji-render';
import UserAvatar from '../avatar/user-avatar-component';
import {
  avatarSize,
  fontSize,
  fontWeight,
  radius,
  spacing
} from '../../../../../shared/style/variables';
import {oneline, multiline} from '../../../../../shared/style/ellipsis';
import {
  darkSlate,
  mediumGreen,
  transparentLightGreen,
  white
} from '../../../../../shared/style/colors';

const ListItemStyled = withStyles({
  root: {
    '&:hover': {
      backgroundColor: transparentLightGreen
    }
  }
})(ListItem);

class ColleagueInboxThreadListItemComponent extends PureComponent {
  _selectItem = () => {
    const {displayName, participationId, pictureHref, selectItem, unreadMessagesCount} = this.props;

    selectItem({
      participantIcon: pictureHref,
      participantName: displayName,
      participationId,
      unreadMessagesCount
    });
  };

  render() {
    const {i18n} = this.context;
    const {
      displayName,
      isLastChild,
      isSelected,
      lastPublicMessageAttachmentContentType,
      lastPublicMessageBody,
      lastPublicMessageDate,
      pictureHref,
      style,
      unreadMessagesCount
    } = this.props;

    const itemHeight = '80px';
    const itemContentColor = getSelectedColor(isSelected);
    const marginType = getMarginType(isLastChild);
    const waitingBackground = 'transparent';
    const backgroundSelected = isSelected ? transparentLightGreen : waitingBackground;
    const selectedDataTestId = getSelectedDataTestId(isSelected);

    return (
      <div
        data-test-id="colleague-list-item"
        style={{
          marginBottom: marginType,
          borderRadius: radius.medium,
          backgroundColor: backgroundSelected
        }}
      >
        <ListItemStyled
          button
          data-test-id={selectedDataTestId}
          onClick={this._selectItem}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: itemHeight,
            maxHeight: itemHeight,
            padding: 0,
            boxSizing: 'border-box',
            borderRadius: radius.medium,
            ...style
          }}
        >
          <div
            style={{
              position: 'relative',
              padding: '10px 10px 10px 5px'
            }}
          >
            <UserAvatar size={avatarSize.large} src={pictureHref} />
          </div>

          <div
            style={{
              flex: '1',
              padding: spacing.medium,
              paddingLeft: 0,
              overflow: 'hidden'
            }}
          >
            <h2
              style={{
                marginBottom: MARGIN_BETWEEN_SUB_ITEMS,
                lineHeight: SUB_ITEM_TITLE_LINE_HEIGHT,
                fontSize: fontSize.xlarge,
                fontWeight: isSelected ? fontWeight.semiBold : fontWeight.normal,
                color: darkSlate,
                ...oneline
              }}
            >
              <span data-test-id="title">{displayName}</span>
            </h2>
            {!lastPublicMessageBody && lastPublicMessageAttachmentContentType ? (
              <AttachmentPreview {...{contentType: lastPublicMessageAttachmentContentType, i18n}} />
            ) : (
              <div
                data-test-id="message"
                style={{
                  maxHeight: '40px',
                  lineHeight: '18px',
                  fontSize: fontSize.medium,
                  color: itemContentColor,
                  ...multiline(2)
                }}
              >
                <EmojiRender>{lastPublicMessageBody}</EmojiRender>
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: `10px ${spacing.medium} 0 ${spacing.medium}`,
              height: '60px',
              justifyContent: 'space-around'
            }}
          >
            <Elapsed value={lastPublicMessageDate} i18nPrefix="inbox.list.item" />

            <div
              data-test-id="notifications"
              style={{
                display: 'flex',
                flex: '0 1 45%',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              {unreadMessagesCount ? (
                <CappedBadge
                  data-test-id="unread-thread"
                  title={i18n.t('customerInbox.pendingUnreadMessagesVisibleThread')}
                  number={unreadMessagesCount}
                  badgeStyle={{
                    backgroundColor: mediumGreen,
                    color: white,
                    borderColor: mediumGreen
                  }}
                />
              ) : null}
            </div>
          </div>
        </ListItemStyled>
      </div>
    );
  }
}

ColleagueInboxThreadListItemComponent.contextTypes = context;

ColleagueInboxThreadListItemComponent.propTypes = {
  displayName: PropTypes.string.isRequired,
  isLastChild: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  lastPublicMessageAttachmentContentType: PropTypes.string,
  lastPublicMessageBody: PropTypes.string.isRequired,
  lastPublicMessageDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  participationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pictureHref: PropTypes.string,
  selectItem: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  unreadMessagesCount: PropTypes.number
};

export default ColleagueInboxThreadListItemComponent;
