import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_RESTART_DATA_TO_RECONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_FAILURE
} from '../../../../../actions/administration-business-actions';

export const DEFAULT_STATE = {
  facebookChannel: {
    businessId: null,
    facebookConnectionStatus: {
      loading: false,
      data: null
    },
    facebookPageConnectionError: null,
    facebookLogin: {
      authenticationToken: null
    },
    facebookPages: {
      loading: false,
      data: null
    }
  }
};
/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD]: (
    state,
    {businessId}
  ) => {
    return state
      .setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], true)
      .setIn(['facebookChannel', 'businessId'], businessId);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_FAILURE]: (state) => {
    return state.setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], false);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_SUCCESS]: (
    state,
    {facebookConnectionStatus}
  ) => {
    return state.updateIn(
      ['facebookChannel', 'facebookConnectionStatus'],
      (facebookConnectionStatusData) =>
        facebookConnectionStatusData
          .set('data', fromJS(facebookConnectionStatus))
          .set('loading', false)
    );
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR]: (
    state,
    {errors}
  ) => {
    return state.update('facebookChannel', (facebookChannel) =>
      facebookChannel
        .set(['facebookPageConnectionError'], errors)
        .setIn(['facebookConnectionStatus', 'loading'], false)
        .set('facebookPages', fromJS(DEFAULT_STATE.facebookChannel.facebookPages))
    );
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN]: (state, {accessToken}) => {
    return state.setIn(['facebookChannel', 'facebookLogin', 'authenticationToken'], accessToken);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD]: (state) => {
    return state.setIn(['facebookChannel', 'facebookPages', 'loading'], true);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_FAILURE]: (state) => {
    return state.setIn(['facebookChannel', 'facebookPages', 'loading'], false);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_SUCCESS]: (state, {facebookPages}) => {
    return state
      .setIn(['facebookChannel', 'facebookPages', 'loading'], false)
      .setIn(['facebookChannel', 'facebookPages', 'data'], fromJS(facebookPages));
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT]: (state) => {
    return state.setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], true);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_FAILURE]: (state) => {
    return state.update('facebookChannel', (facebookChannel) =>
      facebookChannel
        .setIn(['facebookConnectionStatus', 'loading'], false)
        .set('facebookPages', fromJS(DEFAULT_STATE.facebookChannel.facebookPages))
    );
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_SUCCESS]: (
    state,
    {createFacebookPageConnection}
  ) => {
    return state.update('facebookChannel', (facebookChannel) =>
      facebookChannel
        .setIn(['facebookConnectionStatus', 'data'], fromJS(createFacebookPageConnection))
        .setIn(['facebookConnectionStatus', 'loading'], false)
        .set('facebookPages', fromJS(DEFAULT_STATE.facebookChannel.facebookPages))
    );
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION]: (state) => {
    return state.setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], true);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_FAILURE]: (state) => {
    return state.setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], false);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_SUCCESS]: (
    state,
    {createFacebookPageConnection}
  ) => {
    return state.updateIn(
      ['facebookChannel', 'facebookConnectionStatus'],
      (facebookConnectionStatus) =>
        facebookConnectionStatus
          .set('data', fromJS(createFacebookPageConnection))
          .set('loading', false)
    );
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT]: (state) => {
    return state.setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], true);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_FAILURE]: (state) => {
    return state.setIn(['facebookChannel', 'facebookConnectionStatus', 'loading'], false);
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_SUCCESS]: (
    state,
    {disconnectFacebookPage}
  ) => {
    return state.update('facebookChannel', (facebookChannel) =>
      facebookChannel
        .setIn(['facebookConnectionStatus', 'data'], fromJS(disconnectFacebookPage))
        .setIn(['facebookConnectionStatus', 'loading'], false)
        .set('facebookLogin', fromJS(DEFAULT_STATE.facebookChannel.facebookLogin))
        .set('facebookPages', fromJS(DEFAULT_STATE.facebookChannel.facebookPages))
    );
  },
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_RESTART_DATA_TO_RECONNECT]: (state) => {
    return state.update('facebookChannel', (facebookChannel) =>
      facebookChannel
        .set(
          'facebookConnectionStatus',
          fromJS(DEFAULT_STATE.facebookChannel.facebookConnectionStatus)
        )
        .set('facebookLogin', fromJS(DEFAULT_STATE.facebookChannel.facebookLogin))
        .set('facebookPages', fromJS(DEFAULT_STATE.facebookChannel.facebookPages))
    );
  }
});
