import {PureComponent} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import ColleagueInboxHeader from './inbox/colleague-inbox-header-component';
import ColleagueInboxStartNewConversation from './inbox/colleague-inbox-start-new-thread-component';
import ColleagueInboxThreadList from './inbox/colleague-inbox-thread-list-component';
import ColleagueThreadContainer from '../../../container/content/colleague/thread/colleague-thread-container';
import ContentLayout from '../../common/layout/content-layout-component';
import EmptyAreaWithLogo from '../../common/empty-area-with-logo-component';
import EnhancedSwitch from '../../common/enhanced-switch-component';
import FloatPanel from '../../common/float-panel-component';
import LeftPanelLayout from '../../common/layout/left-panel-layout-component';
import RightPanelLayout from '../../common/layout/right-panel-layout-component';

class ColleagueComponent extends PureComponent {
  componentDidMount() {
    const {doLoadColleagueInboxThreads} = this.props;
    doLoadColleagueInboxThreads();
  }

  componentWillUnmount() {
    const {doLeaveColleagueInbox} = this.props;
    doLeaveColleagueInbox();
  }

  render() {
    const {i18n} = this.context;
    const {
      colleagueInbox,
      match,
      doCancelStartNewThreadWithColleague,
      doColleagueInvitationPopupOpen,
      doLoadColleagueInboxThreads,
      doSearchColleagueToStartThread,
      doSearchColleagueToStartThreadInputReset,
      doSelectColleagueThread,
      doSelectItemOnColleagueSearch,
      doShowStartNewThreadWithColleague
    } = this.props;

    return (
      <ContentLayout>
        <LeftPanelLayout>
          <FloatPanel
            dataTestId="start-new-conversation"
            animationSide="left"
            show={colleagueInbox.showStartNewThread}
            title={i18n.t('colleagueInbox.newThread.title')}
            doClose={doCancelStartNewThreadWithColleague}
          >
            <ColleagueInboxStartNewConversation
              edges={colleagueInbox.newThread.edges}
              loading={colleagueInbox.newThread.loading}
              text={colleagueInbox.newThread.text}
              {...{
                doCancelStartNewThreadWithColleague,
                doColleagueInvitationPopupOpen,
                doSearchColleagueToStartThread,
                doSearchColleagueToStartThreadInputReset,
                doSelectItemOnColleagueSearch
              }}
            />
          </FloatPanel>

          <ColleagueInboxHeader {...{doShowStartNewThreadWithColleague}} />

          <ColleagueInboxThreadList
            edges={colleagueInbox.edges}
            loading={colleagueInbox.loading}
            offset={colleagueInbox.offset}
            pageInfo={colleagueInbox.pageInfo}
            selected={colleagueInbox.selected}
            {...{
              doLoadColleagueInboxThreads,
              doSelectColleagueThread
            }}
          />
        </LeftPanelLayout>

        <RightPanelLayout>
          <EnhancedSwitch>
            <Route
              exact
              path={match.url}
              render={() => (
                <EmptyAreaWithLogo
                  dataTestId="no-thread-selected"
                  label={i18n.t('thread.emptyThread')}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/:participationId`}
              component={ColleagueThreadContainer}
            />
          </EnhancedSwitch>
        </RightPanelLayout>
      </ContentLayout>
    );
  }
}

ColleagueComponent.contextTypes = context;

ColleagueComponent.propTypes = {
  colleagueInbox: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  doCancelStartNewThreadWithColleague: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doLeaveColleagueInbox: PropTypes.func.isRequired,
  doLoadColleagueInboxThreads: PropTypes.func.isRequired,
  doSearchColleagueToStartThread: PropTypes.func.isRequired,
  doSearchColleagueToStartThreadInputReset: PropTypes.func.isRequired,
  doSelectColleagueThread: PropTypes.func.isRequired,
  doSelectItemOnColleagueSearch: PropTypes.func.isRequired,
  doShowStartNewThreadWithColleague: PropTypes.func.isRequired
};

export default ColleagueComponent;
