const iconFooterPath = (threadType) => {
  const attachIconName =
    threadType === 'colleague' || threadType === 'invisible' ? 'ATTACH-INVISIBLE' : 'ATTACH';

  return {
    cannedResponsesPath: `${ASSETS_PATH}/img/icon/thread-footer/CANNED.svg`,
    attachImagePath: `${ASSETS_PATH}/img/icon/thread-footer/${attachIconName}.svg`,
    actionSendPathForVisible: `${ASSETS_PATH}/img/icon/thread-footer/ACTION-SEND.svg`,
    actionSendPathForInvisibleAndColleagues: `${ASSETS_PATH}/img/icon/thread-footer/ACTION-SEND-INVISIBLE.svg`
  };
};

export default iconFooterPath;
