import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import context from '../../../../../../../shared/component/context';
import {BUTTON_LABEL_STYLE} from '../../../../../../../shared/style/theme-v0';
import buildCustomerName from '../../../../../lib/build-customer-name';
import FloatPanel from '../../../../common/float-panel-component';
import {inputMaxLength} from '../../../../../../../shared/style/variables';

class LegacyCustomerProfileEditComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleSubmit(e) {
    e.preventDefault();

    const {
      doCustomerProfileEditTextUpdate,
      participantEmail,
      participantId,
      participantPhone,
      participationId
    } = this.props;

    const firstName = this._firstName.getValue();
    const lastName = this._lastName.getValue();

    doCustomerProfileEditTextUpdate(
      participationId,
      participantId,
      buildCustomerName(participantEmail, firstName, lastName, participantPhone),
      firstName,
      lastName,
      this._companyName.getValue()
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      isSmallScreen,
      participantCompany,
      participantFirstName,
      participantLastName,
      showEditCustomerProfile,
      doCustomerProfileEditClose
    } = this.props;

    const buttonStyle = {flex: 1, borderRadius: '18px', textAlign: 'center'};
    const inputStyle = {width: '100%'};

    return (
      <FloatPanel
        animationSide="right"
        show={showEditCustomerProfile}
        doClose={doCustomerProfileEditClose}
        style={{
          top: '50px',
          padding: '20px',
          [isSmallScreen ? 'paddingRight' : undefined]: '70px'
        }}
      >
        <form onSubmit={this._handleSubmit} style={{height: '100%', width: '100%'}}>
          <TextField
            data-test-id="firstname-input"
            autoComplete="given-name"
            defaultValue={participantFirstName}
            floatingLabelText={i18n.t('customerThread.customerProfile.editForm.firstName')}
            hintText={i18n.t('customerThread.customerProfile.editForm.firstName')}
            maxLength={inputMaxLength.firstName}
            ref={(input) => {
              this._firstName = input;

              return this._firstName;
            }}
            style={inputStyle}
          />
          <TextField
            data-test-id="lastname-input"
            autoComplete="family-name"
            defaultValue={participantLastName}
            floatingLabelText={i18n.t('form.lastName')}
            hintText={i18n.t('form.lastName')}
            maxLength={inputMaxLength.lastName}
            ref={(input) => {
              this._lastName = input;

              return this._lastName;
            }}
            style={inputStyle}
          />
          <TextField
            data-test-id="company-input"
            autoComplete="organization"
            defaultValue={participantCompany}
            floatingLabelText={i18n.t('form.companyName')}
            hintText={i18n.t('form.companyName')}
            maxLength={inputMaxLength.companyName}
            ref={(input) => {
              this._companyName = input;

              return this._companyName;
            }}
            style={inputStyle}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
              height: '50px'
            }}
          >
            <FlatButton
              data-test-id="cancel-button"
              label={i18n.t('common.cancel')}
              labelStyle={BUTTON_LABEL_STYLE}
              secondary
              style={buttonStyle}
              onClick={doCustomerProfileEditClose}
            />
            <FlatButton
              data-test-id="save-button"
              label={i18n.t('common.save')}
              labelStyle={BUTTON_LABEL_STYLE}
              primary
              style={buttonStyle}
              type="submit"
            />
          </div>
        </form>
      </FloatPanel>
    );
  }
}

LegacyCustomerProfileEditComponent.contextTypes = context;

LegacyCustomerProfileEditComponent.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
  participantCompany: PropTypes.string,
  participantEmail: PropTypes.string,
  participantFirstName: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  participantLastName: PropTypes.string.isRequired,
  participantPhone: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  showEditCustomerProfile: PropTypes.bool.isRequired,
  doCustomerProfileEditClose: PropTypes.func.isRequired,
  doCustomerProfileEditTextUpdate: PropTypes.func.isRequired
};

export default LegacyCustomerProfileEditComponent;
