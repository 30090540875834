import {fromJS} from 'immutable';
import assert from '../../../../shared/lib/assert';
import {getDateTimeLegacy} from '../../lib/date-time-helper';

/**
 * Find index of a specific message in a collection of messages
 *
 * @param {Object} messages
 * @param {String} id
 * @param {String} clientMessageId
 * @returns {*|number|Observable<number>|Observable|any}
 */
export function findMessageIndex(messages, id, clientMessageId) {
  return messages.findIndex((message) => {
    return (
      (id && message.get('id') == id) || // eslint-disable-line eqeqeq
      (message.get('clientMessageId') && message.get('clientMessageId') === clientMessageId)
    );
  });
}

/**
 * Add a message to the list of messages.
 *
 * @param {Object} data
 * @param {Immutable.List} messages
 * @param {Object} otherData
 * @returns {Immutable.List} messages
 */
export const pushMessage = (data, messages, otherData) => {
  return messages.push(
    fromJS({
      attachment: data.attachmentHref
        ? {
            contentLength: data.attachmentContentLength,
            contentType: data.attachmentContentType,
            href: data.attachmentHref,
            name: data.attachmentName
          }
        : undefined,
      date: data.date,
      id: data.id,
      isFirstOfSegment: data.isFirstOfSegment,
      orgIcon: data.orgIcon,
      senderId: data.senderId,
      senderType: data.senderType,
      text: data.text,
      type: 'message',
      userIcon: data.userIcon,
      ...otherData
    })
  );
};

/**
 * Optimistic update of thread on sending a message.
 * Eventual previously message with same clientMessageId is removed
 *
 * @param {Object} state current state
 * @param {String} presentationType
 * @param {Object} user
 * @param {String} mentionedUser
 * @param {Object} message
 * @returns {Object} new state
 */
export function sendMessage(state, {presentationType, user, mentionedUser, message}) {
  assert(message.clientMessageId);

  return (
    state
      .set('sending', true)
      // only thread form 'fileUploaded' needs reset, because 'text' is reset from editor component
      .setIn(['threadsForm', state.get('participationId'), 'fileUploaded'], null)
      .update('messages', (messages) => {
        return pushMessage(
          {
            date: getDateTimeLegacy(),
            orgIcon: user.orgIcon,
            text: message.text,
            userIcon: user.userIcon
          },
          messages.filter(
            (msg) =>
              !msg.get('clientMessageId') || msg.get('clientMessageId') !== message.clientMessageId
          ),
          {
            presentationType,
            attachment: message.attachment,
            clientMessageId: message.clientMessageId,
            mentionedUserName: mentionedUser ? mentionedUser.displayName : undefined,
            senderName: user.fullName,
            status: 'sending'
          }
        );
      })
  );
}

/**
 * Update state after a success of sending a message
 * - thread is not in a sending state anymore
 * - some message data are updated : id from server / status to 'sent' / date is added
 *
 * @param {Object} state current state
 * @param {String} id
 * @param {String} clientMessageId
 * @param {String=} [mentionedUserName]
 * @returns {Object} new state
 */
export function sendMessageSuccessful(state, {id, clientMessageId, mentionedUserName}) {
  assert(id);
  assert(clientMessageId);

  const index = findMessageIndex(state.get('messages'), id, clientMessageId);

  const newState = state.set('sending', false);

  if (index === -1) {
    return newState;
  }

  return newState.updateIn(['messages', index], (message) => {
    const messageSent = message.set('id', id).set('status', 'sent');

    if (mentionedUserName) {
      return messageSent.set('mentionedUserName', mentionedUserName);
    }

    return messageSent;
  });
}

/**
 * Update state after failure of sending a message
 * - thread is not in a sending state anymore
 * - message status is updated to 'error'
 * - sending parameters are backup to be used later on retry
 *
 * @param {Object} state current state
 * @param {Object} failedAction
 * @returns {Object} new state
 */
export function sendMessageFail(state, {failedAction}) {
  assert(failedAction.clientMessageId);

  const index = findMessageIndex(state.get('messages'), null, failedAction.clientMessageId);

  const newState = state.set('sending', false);

  if (index === -1) {
    return newState;
  }

  return newState.updateIn(['messages', index], (message) => {
    return message.set('status', 'error').set('_sendAction', fromJS(failedAction));
  });
}

/**
 * A sort function for immutable js that guarantee
 * messages are always sorted by id (natural sort)
 *
 * @param {Immutable.Map} a message
 * @param {Immutable.Map} b message
 * @returns {number}
 */
function messagesSorter(a, b) {
  if (parseInt(a.get('id'), 10) < parseInt(b.get('id'), 10)) {
    return -1;
  }
  if (parseInt(a.get('id'), 10) > parseInt(b.get('id'), 10)) {
    return 1;
  }

  return 0;
}

/**
 * Enforce messages invariants after update
 *
 * @param {Immutable.List} messages
 * @returns {Immutable.List}
 */
export function sortMessages(messages) {
  return messages.sort(messagesSorter);
}
