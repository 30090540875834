const getOrganizationMenuOptions = ({
  enableBusinessMessages,
  enableIpRanges,
  i18n,
  isOrganizationAdministrator,
  managerOfABusiness
}) => {
  const oldAdminBaseUrl = `${OLD_WEBAPP_URL}/a#/administration/`;

  return [
    {
      canShowOption: isOrganizationAdministrator,
      organizationOptionDataTestId: 'item-profile',
      organizationOptionName: i18n.t('administration.leftPanel.organization.profile'),
      organizationOptionUrl: `${oldAdminBaseUrl}organization-profile`
    },
    {
      canShowOption: isOrganizationAdministrator && enableBusinessMessages,
      organizationOptionDataTestId: 'item-channels',
      organizationOptionName: i18n.t('administration.leftPanel.organization.channels'),
      organizationOptionUrl: '/app/administration/organization/channels'
    },
    {
      canShowOption: isOrganizationAdministrator || managerOfABusiness,
      organizationOptionDataTestId: 'item-stores',
      organizationOptionName: i18n.t('administration.leftPanel.organization.stores'),
      organizationOptionUrl: `${oldAdminBaseUrl}organization-stores`
    },
    {
      canShowOption: isOrganizationAdministrator,
      organizationOptionDataTestId: 'item-colleagues',
      organizationOptionName: i18n.t('administration.leftPanel.organization.employees'),
      organizationOptionUrl: `${oldAdminBaseUrl}organization-employees`
    },
    {
      canShowOption: isOrganizationAdministrator,
      organizationOptionDataTestId: 'item-authorized_domain_names',
      organizationOptionName: i18n.t('administration.leftPanel.organization.authorizedDomainNames'),
      organizationOptionUrl: `${oldAdminBaseUrl}authorized-domain-names`
    },
    {
      canShowOption: isOrganizationAdministrator && enableIpRanges,
      organizationOptionDataTestId: 'item-security',
      organizationOptionName: i18n.t('administration.leftPanel.organization.security'),
      organizationOptionUrl: `${oldAdminBaseUrl}security`
    }
  ];
};

export default getOrganizationMenuOptions;
