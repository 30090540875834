import PropTypes from 'prop-types';
import Message from './message/message-component';

const MessagesRowBubblesComponent = ({
  account,
  doSendMessageRetry,
  doThreadFilePreviewOpen,
  employee,
  i18n,
  messages,
  threadType,
  type
}) => {
  const lastMessageIndex = messages.length - 1;

  return messages.map((message, messageIndex) => (
    <Message
      hasMessageAfter={messageIndex < lastMessageIndex}
      key={String(messageIndex)}
      {...message}
      {...{
        account,
        doSendMessageRetry,
        doThreadFilePreviewOpen,
        employee,
        i18n,
        messageIndex,
        threadType,
        type
      }}
    />
  ));
};

MessagesRowBubblesComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  employee: PropTypes.objectOf(PropTypes.any), // Can be null if message is sent by customer
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired
};

export default MessagesRowBubblesComponent;
