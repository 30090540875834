import {jsonToGraphQLQuery} from 'json-to-graphql-query';
import {CUSTOMER_INBOX_NEW_CONVERSATION_CUSTOMER_SEARCH_LIMIT} from '../../../../../data/settings';

/**
 * Get query to search customers and/or messages.
 * @param {Object} sliceCustomers
 * @param {Object} sliceMessages
 * @param {String} text
 * @returns {String}
 */
const graphqlSearchCustomersAndOrMessagesQuery = ({sliceCustomers, sliceMessages, text}) => {
  const commonResponseProps = {
    edges: {
      node: {
        customer: {
          channel: true,
          company: true,
          displayName: true,
          emailAddress: true,
          id: true,
          pictureHref: true,
          phoneNumber: true
        },
        message: {
          attachment: {
            contentType: true
          },
          conversationId: true,
          cursor: true,
          date: true,
          id: true,
          text: true
        },
        title: true
      },
      cursor: true
    },
    pageInfo: {
      hasNextPage: true
    }
  };

  const query = {
    query: {
      search: {
        __args: {
          text
        }
      }
    }
  };

  if (sliceCustomers) {
    query.query.search.customers = {
      __args: {
        slice: sliceCustomers
      },
      ...commonResponseProps
    };
  }

  if (sliceMessages) {
    query.query.search.messages = {
      __args: {
        slice: sliceMessages
      },
      ...commonResponseProps
    };
  }

  return jsonToGraphQLQuery(query);
};

/**
 * Get query to search customers for new conversation.
 * @param {String} text
 * @param {String} country
 * @returns {String}
 */
const graphqlSearchCustomersForNewConversationQuery = (text, country) => {
  const query = {
    query: {
      search: {
        __args: {
          country,
          text
        },
        customers: {
          __args: {
            slice: {
              first: CUSTOMER_INBOX_NEW_CONVERSATION_CUSTOMER_SEARCH_LIMIT
            }
          },
          edges: {
            node: {
              customer: {
                channel: true,
                id: true,
                pictureHref: true
              },
              title: true
            }
          }
        },
        formattedPhoneNumber: true,
        isValidEmail: true,
        isValidSmsPhoneNumber: true
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

// eslint-disable-next-line import/prefer-default-export
export {graphqlSearchCustomersAndOrMessagesQuery, graphqlSearchCustomersForNewConversationQuery};
