import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import TitleBlinkIndicator from './title-blink-indicator-component';

const TitleBlinkIndicatorWrapperComponent = ({actionNotificationType, i18n, windowFocused}) => {
  const [defaultTitle, setDefaultTitle] = useState(document.title);
  const titleToShow = {
    customerMessage: i18n.t('app.browserTitle.newMessage'),
    colleagueMessage: i18n.t('app.browserTitle.newMessage'),
    newHeaderMention: i18n.t('app.browserTitle.newMention')
  }[actionNotificationType];

  const canShowTextBlink = !windowFocused && !!titleToShow;

  useEffect(() => {
    if (windowFocused) {
      setDefaultTitle(defaultTitle);
      document.title = defaultTitle;
    }
  }, [windowFocused]);

  return canShowTextBlink ? <TitleBlinkIndicator title={titleToShow} /> : null;
};

TitleBlinkIndicatorWrapperComponent.propTypes = {
  actionNotificationType: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  windowFocused: PropTypes.bool.isRequired
};

export default TitleBlinkIndicatorWrapperComponent;
