import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {ListItem} from 'material-ui/List';
import context from '../../../../../shared/component/context';
import ThreadChannel from '../icon/thread-channel';
import UserAvatar from '../avatar/user-avatar-component';
import {highlight} from '../script/highlight';
import {oneline} from '../../../../../shared/style/ellipsis';
import {avatarSize, fontSize, fontWeight, spacing} from '../../../../../shared/style/variables';
import {black, lightSmoke, slate, white} from '../../../../../shared/style/colors';

class StartNewThreadListItemComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._selectItem = this._selectItem.bind(this);
  }

  _selectItem() {
    const {disableSelection, selectItem, value} = this.props;

    if (disableSelection) {
      return;
    }

    return selectItem(value);
  }

  render() {
    const {i18n, muiTheme} = this.context;
    const {
      customerChannel,
      dataTestId,
      displayName,
      isLastChild,
      isSelected,
      pictureHref,
      showLastChildBorder,
      style,
      unreadMessagesCount,
      unreadInternalMessagesCount
    } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        style={{
          borderBottom: !isLastChild || showLastChildBorder ? 'none' : `1px solid ${lightSmoke}`,
          backgroundColor: white
        }}
      >
        <ListItem
          onClick={this._selectItem}
          innerDivStyle={{
            display: 'flex',
            boxSizing: 'border-box',
            width: '100%',
            height: '65px',
            padding: 0,
            justifyContent: 'space-between',
            backgroundColor: isSelected ? muiTheme.palette.selectedColor : undefined
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: `${spacing.medium} ${spacing.medium} ${spacing.medium} ${spacing.large}`
            }}
          >
            <UserAvatar size={avatarSize.medium} src={pictureHref} />
          </div>

          <div
            style={{
              flex: 1,
              padding: spacing.medium,
              paddingLeft: 0,
              borderBottom: !isLastChild || showLastChildBorder ? `1px solid ${lightSmoke}` : 0,
              overflow: 'hidden',
              ...style
            }}
          >
            <h2
              data-test-id="title"
              style={{
                lineHeight: '28px',
                fontSize: fontSize.xlarge,
                fontWeight:
                  unreadMessagesCount || unreadInternalMessagesCount
                    ? fontWeight.semiBold
                    : fontWeight.normal,
                color: unreadMessagesCount || unreadInternalMessagesCount ? black : slate,
                ...oneline
              }}
            >
              {highlight(displayName)}
            </h2>
            <ThreadChannel
              showLabel
              type={customerChannel}
              {...{
                i18n
              }}
            />
          </div>
        </ListItem>
      </div>
    );
  }
}

StartNewThreadListItemComponent.contextTypes = context;

StartNewThreadListItemComponent.propTypes = {
  customerChannel: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  disableSelection: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  isLastChild: PropTypes.bool,
  isSelected: PropTypes.bool,
  pictureHref: PropTypes.string,
  selectItem: PropTypes.func.isRequired,
  showLastChildBorder: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  unreadInternalMessagesCount: PropTypes.number,
  unreadMessagesCount: PropTypes.number,
  value: PropTypes.objectOf(PropTypes.any)
};

export default StartNewThreadListItemComponent;
