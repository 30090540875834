import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog, DialogContent, DialogActions} from '@material-ui/core';
import StandardButton from '../../../../../../../../shared/component/button/standard-button-component';
import {blue, grey, lightSmoke} from '../../../../../../../../shared/style/colors';

const StyledDialog = withStyles({
  paper: {
    borderRadius: '4px',
    padding: '2em'
  }
})(Dialog);

const AdministrationBusinessPageChannelsFacebookPagesDisconnectDialog = ({
  isOpenFacebookPageDisconnectionDialog,
  i18n,
  handleFacebookPageDisconnectionDialog,
  disconnectFacebookPage
}) => {
  const renderFacebookPagesDialogContent = () => (
    <DialogContent
      style={{color: grey, fontSize: '16px', lineHeight: '22px', padding: '2em 0 2em 0'}}
    >
      <IconButton
        aria-label="close"
        onClick={handleFacebookPageDisconnectionDialog}
        style={{
          color: lightSmoke,
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem'
        }}
      >
        <CloseIcon />
      </IconButton>
      {i18n.t(
        'administration.business.pages.channels.facebook.disconnectFacebookPageDialog.description'
      )}
    </DialogContent>
  );

  const renderFacebookPagesDialogButtons = () => (
    <DialogActions style={{padding: 0}}>
      <StandardButton
        data-test-id="facebook-pages-disconnect-cancel-button"
        onClick={handleFacebookPageDisconnectionDialog}
        variant="outlined"
        style={{
          borderColor: blue,
          color: blue,
          fontWeight: 600
        }}
      >
        {i18n.t(
          'administration.business.pages.channels.facebook.disconnectFacebookPageDialog.cancelButton'
        )}
      </StandardButton>

      <StandardButton
        data-test-id="facebook-pages-dialog-disconnect-button"
        variant="outlined"
        onClick={disconnectFacebookPage}
        style={{
          borderColor: blue,
          color: blue,
          fontWeight: 600
        }}
      >
        {i18n.t(
          'administration.business.pages.channels.facebook.disconnectFacebookPageDialog.disconnectButton'
        )}
      </StandardButton>
    </DialogActions>
  );

  return (
    <StyledDialog
      maxWidth="sm"
      fullWidth
      open={isOpenFacebookPageDisconnectionDialog}
      data-test-id="facebook-pages-disconnect-dialog"
    >
      {renderFacebookPagesDialogContent()}
      {renderFacebookPagesDialogButtons()}
    </StyledDialog>
  );
};

AdministrationBusinessPageChannelsFacebookPagesDisconnectDialog.propTypes = {
  isOpenFacebookPageDisconnectionDialog: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  disconnectFacebookPage: PropTypes.func.isRequired,
  handleFacebookPageDisconnectionDialog: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsFacebookPagesDisconnectDialog;
