import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../shared/component/context';
import ChangePasswordForm from '../../../shared/component/form/change-password-form-component';
import {ContinueOrSignInLink} from '../../../shared/component/link/links-to-desktop-component';
import getRouteParam from '../../../shared/lib/get-route-param';
import isTokenValid from '../../../shared/lib/is-token-valid';
import PublicForm from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';

class ChangePasswordComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      token: getRouteParam('token', props.location)
    };

    this._doChangePasswordSubmit = this._doChangePasswordSubmit.bind(this);
  }

  _doChangePasswordSubmit(password, isFormValid) {
    const {doChangePasswordSubmit} = this.props;
    const {token} = this.state;
    doChangePasswordSubmit(password, token, isFormValid);
  }

  render() {
    const {i18n} = this.context;
    const {errors, language, passwordChanged, submitting, doUIEndMount, doUIUpdateLanguage} =
      this.props;
    const {token} = this.state;

    const title = i18n.t('changePassword.title');

    return (
      <PublicPage
        dataTestId="change-password"
        {...{
          language,
          title,
          doUIEndMount,
          doUIUpdateLanguage
        }}
      >
        {isTokenValid(token) ? (
          <ChangePasswordForm
            doChangePasswordSubmit={this._doChangePasswordSubmit}
            submitLabel={i18n.t('changePassword.submitButton')}
            {...{
              errors,
              passwordChanged,
              submitting,
              title
            }}
          />
        ) : (
          <PublicForm
            buttons={<ContinueOrSignInLink />}
            globalErrorMessage={i18n.t('changePassword.errorInvalidForgotPasswordKeyCode')}
            title={title}
          />
        )}
      </PublicPage>
    );
  }
}

ChangePasswordComponent.contextTypes = context;

ChangePasswordComponent.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  language: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  passwordChanged: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  doChangePasswordSubmit: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired
};

export default ChangePasswordComponent;
