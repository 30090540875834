import {Component} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import context from '../../../../../../shared/component/context';
import BusinessSelectionPopup, {
  focusItem,
  businessListItem
} from '../../../common/business-selection-popup-component';
import Link from '../../../common/link/link-component';
import {animationSpeed, fontWeight, lineHeight} from '../../../../../../shared/style/variables';
import {lightYellow, slate} from '../../../../../../shared/style/colors';

/**
 * Render an area with warning message and action buttons.
 *
 * @param {String} dataTestId
 * @param {String} title
 * @param {String} message
 * @param {Function} onClickBack
 * @param {Function} onClickAction
 * @param {Object} i18n
 * @returns {XML}
 */
const showWarning = ({dataTestId, title, message, onClickBack, onClickAction, i18n}) => (
  <div>
    <div
      data-test-id={dataTestId}
      style={{
        display: 'flex',
        padding: '15px',
        backgroundColor: lightYellow
      }}
    >
      <FontIcon className="material-icons" color={slate} style={{display: 'block'}}>
        warning
      </FontIcon>
      <div style={{marginLeft: '15px'}}>
        <h2>{title}</h2>
        <p style={{marginTop: '5px', lineHeight: lineHeight.large}}>{message}</p>
      </div>
    </div>
    <Link
      data-test-id="transfer-other-business-confirm-button-no"
      label={i18n.t('customerThread.transfer.businessPopup.backButton')}
      onClick={onClickBack}
      style={{
        float: 'left',
        padding: '20px',
        fontWeight: fontWeight.semiBold
      }}
    />
    {typeof onClickAction === 'function' ? (
      <Link
        data-test-id="transfer-other-business-confirm-button-yes"
        label={i18n.t('customerThread.transfer.businessPopup.confirmTransferToOtherBusinessButton')}
        onClick={onClickAction}
        style={{
          float: 'right',
          padding: '20px',
          fontWeight: fontWeight.semiBold
        }}
      />
    ) : null}
  </div>
);

/**
 * Finally, the component.
 */
class ThreadTransferPopupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedItemIdTemp: null
    };

    this._cancelWarningMessage = this._cancelWarningMessage.bind(this);
    this._changeFocusedItem = this._changeFocusedItem.bind(this);
    this._onItemKeyboardFocus = this._onItemKeyboardFocus.bind(this);
    this._onRequestClose = this._onRequestClose.bind(this);
    this._onSelectBusiness = this._onSelectBusiness.bind(this);
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/destructuring-assignment */
    if (!prevProps.selectedBusinessOutOfScope && this.props.selectedBusinessOutOfScope) {
      this._changeFocusedItem(0);
    }
    /* eslint-enable react/destructuring-assignment */
  }

  _cancelWarningMessage() {
    const {doTransferCancelWarningMessage} = this.props;
    this.setState({focusedItemIdTemp: null});
    doTransferCancelWarningMessage();
  }

  _changeFocusedItem(index) {
    this.setState({
      focusedItemIdTemp: index
    });
  }

  _onItemKeyboardFocus(event, isKeyboardFocused) {
    const {focusedItemIdTemp} = this.state;
    // Used only the first time, when selecting filtered businesses first item with [Tab] keyboard key
    if (isKeyboardFocused && focusedItemIdTemp === null) {
      focusItem(this, 0);
    }
  }

  _onRequestClose() {
    const {selectedBusinessOutOfScope, warningNoPhoneIsVisible, doTransferPopupToggle} = this.props;
    if (selectedBusinessOutOfScope || warningNoPhoneIsVisible) {
      this._cancelWarningMessage();
    } else {
      doTransferPopupToggle(false);
    }
  }

  _onSelectBusiness(business) {
    const {
      fullName,
      isSmsPhoneNumber,
      participationId,
      selectedBusinessOutOfScope,
      doTransferDoTransfer,
      doTransferSelectBusinessOutOfScope,
      doTransferShowWarningNoPhone,
      doTransferShowWarningOutOfScope
    } = this.props;
    const businessData = selectedBusinessOutOfScope || business;

    if (isSmsPhoneNumber && !businessData.phoneNumber) {
      doTransferShowWarningNoPhone();

      return;
    }

    if (businessData.isEmployee || selectedBusinessOutOfScope) {
      doTransferDoTransfer(
        participationId,
        fullName,
        businessData.id,
        businessData.name,
        businessData.isEmployee
      );

      if (selectedBusinessOutOfScope) {
        doTransferSelectBusinessOutOfScope(null);
      }
    } else {
      doTransferSelectBusinessOutOfScope(businessData);

      // Handle special graphical effect
      setTimeout(() => {
        focusItem(this, 0);
        doTransferShowWarningOutOfScope();
      }, animationSpeed.fast);
    }
  }

  render() {
    const {i18n} = this.context;
    const {
      allBusinesses,
      anchorEl,
      filteredBusinesses,
      initializing,
      organizationPicture,
      recentlySuccessful,
      searchText,
      selectedBusinessOutOfScope,
      transferBackInScope,
      transferring,
      transferShowPopup,
      warningNoPhoneIsVisible,
      warningOutOfScopeIsVisible,
      doTransferSearchBusiness
    } = this.props;

    const userBelongsToMoreThanOneBusiness = allBusinesses.length > 1;

    return (
      <BusinessSelectionPopup
        dataTestId="transfer-popup"
        fullProgressMessage={i18n.t('customerThread.transfer.businessPopup.transferring')}
        open={transferShowPopup}
        showBusinessesList={!selectedBusinessOutOfScope}
        showFullProgress={transferring || recentlySuccessful}
        showInput={!selectedBusinessOutOfScope && !warningNoPhoneIsVisible}
        subTitle={i18n.t('customerThread.transfer.businessPopup.description')}
        title={i18n.t('customerThread.transfer.businessPopup.title')}
        doSearchBusinesses={doTransferSearchBusiness}
        onRequestClose={this._onRequestClose}
        onSelectBusiness={this._onSelectBusiness}
        {...{
          anchorEl,
          filteredBusinesses,
          initializing,
          organizationPicture,
          searchText
        }}
        alternativeToContainer={
          userBelongsToMoreThanOneBusiness
            ? null
            : showWarning({
                i18n,
                dataTestId: 'warning-no-business',
                message: i18n.t('customerThread.transfer.businessPopup.warningNoBusiness.message'),
                title: i18n.t('customerThread.transfer.businessPopup.warningNoBusiness.title'),
                onClickAction: null,
                onClickBack: this._onRequestClose
              })
        }
        alternativeToBusinessesList={
          warningNoPhoneIsVisible
            ? showWarning({
                i18n,
                dataTestId: 'warning-business-no-phone',
                message: i18n.t(
                  'customerThread.transfer.businessPopup.warningNoBusinessPhone.message'
                ),
                title: i18n.t('customerThread.transfer.businessPopup.warningNoBusinessPhone.title'),
                onClickAction: null,
                onClickBack: this._cancelWarningMessage
              })
            : null
        }
      >
        {selectedBusinessOutOfScope
          ? businessListItem({
              organizationPicture,
              i: 0,
              business: selectedBusinessOutOfScope,
              showBorderBottom: false,
              onClick: this._onSelectBusiness,
              onItemKeyboardFocus: this._onItemKeyboardFocus
            })
          : null}

        <TransitionGroup>
          {warningOutOfScopeIsVisible ? (
            <CSSTransition
              data-test-id="warning-business-out-of-scope"
              classNames="heightAnimation"
              appear
              exit={false}
              timeout={animationSpeed.fast}
            >
              {showWarning({
                i18n,
                message: i18n.t(
                  `customerThread.transfer.businessPopup.businessOutOfScope.${
                    transferBackInScope ? 'messageNoWarning' : 'messageWarning'
                  }`
                ),
                title: i18n.t('customerThread.transfer.businessPopup.businessOutOfScope.title'),
                onClickAction: this._onSelectBusiness,
                onClickBack: this._cancelWarningMessage
              })}
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </BusinessSelectionPopup>
    );
  }
}

ThreadTransferPopupComponent.contextTypes = context;

ThreadTransferPopupComponent.propTypes = {
  allBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  anchorEl: PropTypes.objectOf(PropTypes.any),
  filteredBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  fullName: PropTypes.string.isRequired,
  initializing: PropTypes.bool.isRequired,
  isSmsPhoneNumber: PropTypes.bool,
  organizationPicture: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  recentlySuccessful: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedBusinessOutOfScope: PropTypes.objectOf(PropTypes.any),
  transferBackInScope: PropTypes.bool.isRequired,
  transferShowPopup: PropTypes.bool.isRequired,
  transferring: PropTypes.bool.isRequired,
  warningNoPhoneIsVisible: PropTypes.bool.isRequired,
  warningOutOfScopeIsVisible: PropTypes.bool.isRequired,
  doTransferCancelWarningMessage: PropTypes.func.isRequired,
  doTransferDoTransfer: PropTypes.func.isRequired,
  doTransferPopupToggle: PropTypes.func.isRequired,
  doTransferSearchBusiness: PropTypes.func.isRequired,
  doTransferSelectBusinessOutOfScope: PropTypes.func.isRequired,
  doTransferShowWarningNoPhone: PropTypes.func.isRequired,
  doTransferShowWarningOutOfScope: PropTypes.func.isRequired
};

export default ThreadTransferPopupComponent;
