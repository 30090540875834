import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_REMOVE_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS_SNACKBAR_HIDE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_FAILURE,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_ROUTE_REDIRECTED_TO,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_DRAFT_FIELDS_PERSIST
} from '../../../../../actions/administration/administration-organization-channels-gbm-actions';
import {APP_LOGOUT} from '../../../../../actions/app-actions';

export const DEFAULT_STATE = {
  agent: {
    agentLogoUrl: null,
    brandName: null,
    brandWebsiteUrl: null,
    contactEmail: null,
    contactName: null,
    locale: null,
    privacyPolicyUrl: null,
    status: null,
    welcomeMessage: null
  },
  agentForm: {
    cancelling: null,
    hasGlobalError: null,
    logoUploaded: {
      contentLength: '',
      uuid: ''
    },
    showUpdateSuccessSnackbar: false,
    submitting: null,
    updating: null,
    uploadingLogo: null
  },
  agentDraftFields: null,
  error: null,
  loading: true
};

const deleteGbmOrganizationPersistedState = () => {
  return fromJS(DEFAULT_STATE);
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Load Agent handling.
   */
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD]: (state) =>
    state
      .set('agent', fromJS(DEFAULT_STATE.agent))
      .set('loading', true)
      .update('agentForm', (agentForm) =>
        agentForm.set('cancelling', false).set('submitting', false).set('updating', false)
      ),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_FAILURE]: (state) =>
    state.set('loading', false),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS]: (
    state,
    {payload: {organizationAgent}}
  ) => state.set('agent', fromJS(organizationAgent)).set('loading', false),

  /**
   * Agent form handling.
   */
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_REMOVE_BUTTON_CLICK]: (state) =>
    state
      .setIn(['agent', 'agentLogoUrl'], null)
      .setIn(['agentDraftFields', 'agentLogoUrl'], null)
      .setIn(['agentForm', 'logoUploaded'], fromJS(DEFAULT_STATE.agentForm.logoUploaded)),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD]: (state) =>
    state.setIn(['agentForm', 'uploadingLogo'], true),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_FAILURE]: (state) =>
    state.setIn(['agentForm', 'uploadingLogo'], false),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_SUCCESS]: (state, {data}) =>
    state
      .setIn(['agent', 'agentLogoUrl'], data.href)
      .update('agentForm', (agentForm) =>
        agentForm.set('logoUploaded', fromJS(data)).set('uploadingLogo', false)
      ),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', false).set('submitting', true)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_FAILURE]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', true).set('submitting', false)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS]: (state) =>
    state.setIn(['agentForm', 'hasGlobalError'], false).set('agentDraftFields', null),

  /**
   * Cancel an ongoing agent validation.
   */
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', false).set('cancelling', true)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_FAILURE]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', true).set('cancelling', false)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS]: (state) =>
    state.setIn(['agentForm', 'hasGlobalError'], false),

  /**
   * Update an agent.
   */
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', false).set('updating', true)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_FAILURE]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', true).set('updating', false)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS]: (state) =>
    state.update('agentForm', (agentForm) =>
      agentForm.set('hasGlobalError', false).set('showUpdateSuccessSnackbar', true)
    ),
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS_SNACKBAR_HIDE]: (state) =>
    state.setIn(['agentForm', 'showUpdateSuccessSnackbar'], false),

  /**
   * Redirection to the page handling.
   */
  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_ROUTE_REDIRECTED_TO]: (state, {payload: {error}}) =>
    state.set('error', error),

  [ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_DRAFT_FIELDS_PERSIST]: (
    state,
    {payload: {draftFields}}
  ) => {
    return state.set('agentDraftFields', fromJS(draftFields));
  },
  [APP_LOGOUT]: deleteGbmOrganizationPersistedState
});
