import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import {canShowRankingTable} from '../../../../lib/insights/insights-helper';
import formatInsightDuration from '../lib/format-insight-duration';
import {generateChartTitle, getLinkedFigure} from '../lib/chart-title-generator';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import TableRanked from '../common/table/table-ranked-component';
import TABLES_DEFINITION from '../../../../data/insights/top-stores-data';
import {graphGreen} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'responseTime';

/**
 * Finally, the component.
 */
class InsightResponseTimeComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      account,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod,
      onClickRankingButtonShowAllItems
    } = this.props;

    const {businesses: accountBusinesses} = account;

    const {averageResponseTime, returningCustomers} = insight.responseTimePageHistogram;

    const rankingItem = insight.ranking.averageResponseTime;
    const {loadingCsv} = insight.ranking;
    const {valueType} = TABLES_DEFINITION.averageResponseTime;
    const newInboundConversationsNumber = returningCustomers.data.newInboundConversations;

    const isClassicPeriod = periodTabSelected === 'classic';
    const aggregationPeriod = isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod;

    const showRankingTable = canShowRankingTable(periodTabSelected, account);

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout ref={this.wrapperPanelContentRef} onScroll={this._handleScroll}>
          <InsightPanelContentBlock dataTestId="response-time">
            <HistogramChart
              failed={averageResponseTime.failed}
              items={averageResponseTime.data.averageResponseTimeHistogram}
              loading={averageResponseTime.loading}
              tooltipValueFormatter={this._formatInsightDurationFromSec}
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.items.responseTime.chart`, {
                returnObjects: true
              })}
              type="line"
              {...{
                aggregationPeriod
              }}
              title={generateChartTitle({
                hasData: averageResponseTime.data.averageResponseTimeHistogram.length,
                isColorInverted: true,
                label: i18n.t(`insight.pages.${INSIGHT_PAGE}.items.responseTime.chart.title`),
                figure: getLinkedFigure(
                  newInboundConversationsNumber,
                  formatInsightDuration(averageResponseTime.data.averageResponseTime, i18n)
                )
              })}
              series={[
                {
                  property: 'averageResponseTime',
                  color: graphGreen
                }
              ]}
            />
          </InsightPanelContentBlock>

          {showRankingTable ? (
            <TableRanked
              dataTestId="response-time-ranking-table"
              downloadCsvLabelTranslation={i18n.t(`insight.pages.${INSIGHT_PAGE}.downloadCsvLabel`)}
              failed={rankingItem.failed}
              insightDownloadCsv={this._doInsightDownloadCsv}
              insightSortRankings={this._doInsightSortButtonClick}
              items={rankingItem.data}
              loading={rankingItem.loading}
              onClickButtonShowAllItems={onClickRankingButtonShowAllItems}
              showRankingControlButtons
              showCsvButton
              showSortButton
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.ranking.averageResponseTime`, {
                returnObjects: true
              })}
              {...{
                loadingCsv,
                valueType
              }}
            />
          ) : null}
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightResponseTimeComponent.contextTypes = context;

InsightResponseTimeComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any),
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  uiWidth: PropTypes.number.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doInsightSortButtonClick: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired,
  onClickRankingButtonShowAllItems: PropTypes.func.isRequired
};

export default InsightResponseTimeComponent;
