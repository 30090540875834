import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import BubbleWidgetComponent from '../../../../../component/content/integration-area/page/widgets/bubble-widget-component';
import {
  INTEGRATION_AREA_WIDGET_BUBBLE_BACKGROUND_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_BORDER_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_COMMUNICATION_METHOD_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_HAS_BORDER_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_HAS_SHADOW_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_RESET_STATE,
  INTEGRATION_AREA_WIDGET_BUBBLE_SIDE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_TOOLTIP_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';
import {
  doChangeErrorInvalidEmail,
  doChangeErrorInvalidPhone,
  doChangeInputPlaceholderEmail,
  doChangeInputPlaceholderPhone,
  doChangeThankYouMessage
} from './lib/widget-page-map-dispatch-to-props';

const BubbleWidgetContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.getIn(['integrationArea', 'bubbleWidget'])
    ],
    (businesses, bubbleWidget) => {
      return {
        businessesList: businesses.toJS(),
        ...bubbleWidget.toJS()
      };
    }
  ),
  {
    doChangeLanguage(language) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_LANGUAGE_CHANGE,
        language
      };
    },
    doChangeBusiness(businessId) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_BUSINESS_CHANGE,
        businessId
      };
    },
    doChangeBubbleTooltip(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_TOOLTIP_CHANGE,
        text
      };
    },
    doChangeCommunicationMethod(method) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_COMMUNICATION_METHOD_CHANGE,
        method
      };
    },
    doChangeInputPlaceholder(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_CHANGE,
        text
      };
    },
    doChangeBackgroundColor(color) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_BACKGROUND_COLOR_CHANGE,
        color
      };
    },
    doChangeHasBorder(hasBorder) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_HAS_BORDER_CHANGE,
        hasBorder
      };
    },
    doChangeBorderColor(color) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_BORDER_COLOR_CHANGE,
        color
      };
    },
    doChangeSide(side) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_SIDE_CHANGE,
        side
      };
    },
    doChangeHasShadow(hasShadow) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_HAS_SHADOW_CHANGE,
        hasShadow
      };
    },
    doUpdateSnippet(snippet) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_CHANGE,
        snippet
      };
    },
    doCopyCode(copied) {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE,
        _googleAnalytics: true,
        copied
      };
    },
    doResetState() {
      return {
        type: INTEGRATION_AREA_WIDGET_BUBBLE_RESET_STATE
      };
    },
    doChangeErrorInvalidEmail,
    doChangeErrorInvalidPhone,
    doChangeInputPlaceholderEmail,
    doChangeInputPlaceholderPhone,
    doChangeThankYouMessage
  }
)(BubbleWidgetComponent);

export default BubbleWidgetContainer;
