import {jsonToGraphQLQuery} from 'json-to-graphql-query';
import {getMessageToSend} from '../../../../lib/conversation-item-helper';

/**
 * Get mutation to send a message on a new conversation with customer email.
 * @param {String} businessId
 * @param {String} clientItemId
 * @param {String} customerEmail
 * @param {Object} message
 * @returns {String}
 */
const graphqlGetEmployeeStartCustomerConversationToEmailMutation = ({
  businessId,
  clientItemId,
  customerEmail,
  message
}) => {
  const mutation = {
    mutation: {
      employeeStartCustomerConversationToEmail: {
        __args: {
          businessId,
          clientItemId,
          customerEmail,
          message: getMessageToSend(message)
        },
        conversation: {
          id: true
        },
        customer: {
          id: true
        }
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Get mutation to send a message on a new conversation with customer phone number.
 * @param {String} businessId
 * @param {String} clientItemId
 * @param {String} customerPhoneNumber
 * @param {Object} message
 * @returns {String}
 */
const graphqlGetEmployeeStartCustomerConversationToPhoneMutation = ({
  businessId,
  clientItemId,
  customerPhoneNumber,
  message
}) => {
  const mutation = {
    mutation: {
      employeeStartCustomerConversationToPhoneNumber: {
        __args: {
          businessId,
          clientItemId,
          customerPhoneNumber,
          message: getMessageToSend(message)
        },
        conversation: {
          id: true
        },
        customer: {
          id: true
        }
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

export {
  graphqlGetEmployeeStartCustomerConversationToEmailMutation,
  graphqlGetEmployeeStartCustomerConversationToPhoneMutation
};
