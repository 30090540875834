export const APP_BROWSER_NOTIFICATION_BANNER_CLOSE = 'APP_BROWSER_NOTIFICATION_BANNER_CLOSE';
export const APP_BROWSER_NOTIFICATION_BANNER_SHOW_FIRST =
  'APP_BROWSER_NOTIFICATION_BANNER_SHOW_FIRST';
export const APP_BROWSER_NOTIFICATION_BANNER_SHOW_LAST =
  'APP_BROWSER_NOTIFICATION_BANNER_SHOW_LAST';
export const APP_BROWSER_NOTIFICATION_SHOW = 'APP_BROWSER_NOTIFICATION_SHOW';

export const APP_GLOBAL_VERIFICATION_EMAIL_RESEND = 'APP_GLOBAL_VERIFICATION_EMAIL_RESEND';
export const APP_GLOBAL_VERIFICATION_EMAIL_RESEND_FAILURE =
  'APP_GLOBAL_VERIFICATION_EMAIL_RESEND_FAILURE';
export const APP_GLOBAL_VERIFICATION_EMAIL_RESEND_SUCCESS =
  'APP_GLOBAL_VERIFICATION_EMAIL_RESEND_SUCCESS';

export const APP_LOGOUT = 'APP_LOGOUT';
export const APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE =
  'APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE';
export const APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS =
  'APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS';
export const APP_LOGOUT_FAILURE = 'APP_LOGOUT_FAILURE';
export const APP_LOGOUT_SUCCESS = 'APP_LOGOUT_SUCCESS';

export const APP_NEW_RELEASE_BANNER_SHOW = 'APP_NEW_RELEASE_BANNER_SHOW';
export const APP_NEW_RELEASE_CHECK = 'APP_NEW_RELEASE_CHECK';
export const APP_NEW_RELEASE_CHECK_FAILURE = 'APP_NEW_RELEASE_CHECK_FAILURE';
export const APP_NEW_RELEASE_CHECK_SAME_REVISION = 'APP_NEW_RELEASE_CHECK_SAME_REVISION';
export const APP_NEW_RELEASE_IGNORE = 'APP_NEW_RELEASE_IGNORE';

export const APP_RELOAD = 'APP_RELOAD';

export const APP_SOCKET_RECONNECT = 'APP_SOCKET_RECONNECT';

export const APP_STATE_BOOT_LOAD = 'APP_STATE_BOOT_LOAD';
export const APP_STATE_BOOT_LOAD_FAILURE = 'APP_STATE_BOOT_LOAD_FAILURE';
export const APP_STATE_BOOT_LOAD_RETRY = 'APP_STATE_BOOT_LOAD_RETRY';
export const APP_STATE_BOOT_LOAD_SUCCESS = 'APP_STATE_BOOT_LOAD_SUCCESS';

export const APP_STATE_REFRESH_LOAD = 'APP_STATE_REFRESH_LOAD';
export const APP_STATE_REFRESH_LOAD_FAILURE = 'APP_STATE_REFRESH_LOAD_FAILURE';
export const APP_STATE_REFRESH_LOAD_SUCCESS = 'APP_STATE_REFRESH_LOAD_SUCCESS';

export const APP_TWILIO_TOKEN_LOAD = 'APP_TWILIO_TOKEN_LOAD';
export const APP_TWILIO_TOKEN_LOAD_FAILURE = 'APP_TWILIO_TOKEN_LOAD_FAILURE';
export const APP_TWILIO_TOKEN_LOAD_SUCCESS = 'APP_TWILIO_TOKEN_LOAD_SUCCESS';
