const getBusinessTabsOptions = ({
  businessId,
  enableBotAPI,
  enableBusinessMessages,
  enableChannelAdmin,
  enablePaymentFeature,
  enableSurvey,
  i18n,
  isManagerOfCurrentBusiness
}) => {
  const oldAdminBusinessPath = `${OLD_WEBAPP_URL}/a#/administration/store/${businessId}/`;

  return [
    {
      businessTabDataTestId: 'automatic-message-tab',
      businessTabOptionName: i18n.t('administration.business.businessTabs.automaticMessages'),
      canShowTabOption: isManagerOfCurrentBusiness,
      url: `${oldAdminBusinessPath}automatic-messages`
    },
    {
      businessTabDataTestId: 'canned-response-tab',
      businessTabOptionName: i18n.t('administration.business.businessTabs.cannedResponses'),
      canShowTabOption: isManagerOfCurrentBusiness,
      url: `${oldAdminBusinessPath}canned-responses`
    },
    {
      businessTabDataTestId: 'survey-tab',
      businessTabOptionName: i18n.t('administration.business.businessTabs.surveys'),
      canShowTabOption: isManagerOfCurrentBusiness && enableSurvey,
      url: `${oldAdminBusinessPath}surveys`
    },
    {
      businessTabDataTestId: 'api-bot-tab',
      businessTabOptionName: i18n.t('administration.business.businessTabs.bot'),
      canShowTabOption: isManagerOfCurrentBusiness && enableBotAPI,
      url: `${oldAdminBusinessPath}bot`
    },
    {
      businessTabDataTestId: 'payment-tab',
      businessTabOptionName: i18n.t('administration.business.businessTabs.payment'),
      canShowTabOption: isManagerOfCurrentBusiness && enablePaymentFeature,
      url: `${oldAdminBusinessPath}payment`
    },
    {
      businessTabDataTestId: 'channels-tab',
      businessTabOptionName: i18n.t('administration.business.businessTabs.channels'),
      canShowTabOption: enableChannelAdmin || enableBusinessMessages,
      url: `/app/administration/business/${businessId}/channels`
    }
  ];
};

const getIndexTabByPageName = ({businessPageName, businessTabsOptions}) => {
  const DEFAULT_TAB_INDEX = false;

  const indexTab = businessTabsOptions.findIndex(
    ({businessTabOptionName}) => businessTabOptionName.toLowerCase() === businessPageName
  );

  return indexTab === -1 ? DEFAULT_TAB_INDEX : indexTab;
};

export {getBusinessTabsOptions, getIndexTabByPageName};
