import PropTypes from 'prop-types';
import {lineHeight, radius, spacing} from '../../../../../../shared/style/variables';
import {cosmicLatte} from '../../../../../../shared/style/colors';

const AdministrationDisclaimerComponent = ({labels: {description, title}, style}) => (
  <div
    data-test-id="disclaimer"
    style={{
      backgroundColor: cosmicLatte,
      borderRadius: radius.large,
      lineHeight: lineHeight.large,
      padding: spacing.medium,
      ...style
    }}
  >
    {title ? (
      <span
        dangerouslySetInnerHTML={{__html: title}}
        style={{display: 'block', fontWeight: 700, marginBottom: '3px'}}
      />
    ) : null}

    <span dangerouslySetInnerHTML={{__html: description}} />
  </div>
);

AdministrationDisclaimerComponent.propTypes = {
  labels: PropTypes.objectOf(PropTypes.any).isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationDisclaimerComponent;
