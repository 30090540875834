import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import TopLayout from './top-layout-component';
import {screenWidth} from '../style/variables';

export const BottomButtonsContainer = ({children, style, ...props}) => (
  <div
    {...props}
    style={{
      display: 'flex',
      justifyContent: 'center',
      ...style
    }}
  >
    {children}
  </div>
);

BottomButtonsContainer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any)
};

/**
 * Finally, the component
 */
class FullscreenLayoutComponent extends PureComponent {
  render() {
    const {
      children,
      dataTestId,
      header,
      pageTitle,
      innerStyle,
      showLogo,
      style,
      title,
      titleStyle,
      doUIEndMount
    } = this.props;

    return (
      <TopLayout
        dataTestId={dataTestId}
        title={pageTitle}
        doUIEndMount={doUIEndMount}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          ...style
        }}
      >
        {header}

        <div
          id={`${dataTestId}-inner`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: `${screenWidth.mobileMin}px`,
            width: '100%',
            ...innerStyle
          }}
        >
          <h1
            id={`${dataTestId}-title`}
            style={{
              textAlign: 'center',
              ...titleStyle
            }}
          >
            {showLogo ? (
              <a href="/">
                <img
                  alt="Instaply logo"
                  src={`${ASSETS_PATH}/img/logo/instaply-bubble-text-vertical-color.png`}
                  height="150"
                  width="150"
                />
              </a>
            ) : (
              title
            )}
          </h1>

          {children}
        </div>
      </TopLayout>
    );
  }
}

FullscreenLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  header: PropTypes.objectOf(PropTypes.any),
  innerStyle: PropTypes.objectOf(PropTypes.any),
  pageTitle: PropTypes.string,
  showLogo: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  titleStyle: PropTypes.objectOf(PropTypes.any),
  doUIEndMount: PropTypes.func
};

export default FullscreenLayoutComponent;
