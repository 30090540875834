import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import {lightSmoke, blue} from '../../../../../../../../../shared/style/colors';

const StyledCheckbox = withStyles({
  root: {
    width: 'auto',
    padding: 0,
    marginRight: 0,
    '&$checked': {
      color: blue
    }
  },
  checked: {}
})(Checkbox);

const AdministrationBusinessPageFacebookPagesListComponent = ({
  checkedFacebookPage,
  facebookPagesList,
  handleFacebookPageChange
}) => {
  const isSinglefacebookPageChecked = Object.keys(checkedFacebookPage).length === 1;
  const borderBottomStyle = (indexPageElement) =>
    facebookPagesList.length - 1 === indexPageElement ? 0 : `1px solid ${lightSmoke}`;

  return (
    <ul style={{fontSize: '16px'}}>
      {facebookPagesList &&
        facebookPagesList.map((facebookPage, indexPageElement) => (
          <li
            data-test-id={`facebook-pages-checkbox-${indexPageElement}`}
            key={facebookPage.id}
            style={{
              alignItems: 'center',
              borderBottom: borderBottomStyle(indexPageElement),
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1em 0 1em 0'
            }}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img src={facebookPage.pictureUrl} alt="" style={{marginRight: '1em'}} />
              <span> {facebookPage.name}</span>
            </div>
            <StyledCheckbox
              data-test-id="facebook-pages-checkbox"
              disabled={!checkedFacebookPage[indexPageElement] && isSinglefacebookPageChecked}
              checked={checkedFacebookPage[indexPageElement] || false}
              onChange={(event) =>
                handleFacebookPageChange(event, indexPageElement, facebookPage.id)
              }
            />
          </li>
        ))}
    </ul>
  );
};

AdministrationBusinessPageFacebookPagesListComponent.propTypes = {
  checkedFacebookPage: PropTypes.objectOf(PropTypes.any),
  facebookPagesList: PropTypes.arrayOf(PropTypes.any),
  handleFacebookPageChange: PropTypes.func.isRequired
};

export default AdministrationBusinessPageFacebookPagesListComponent;
