import i18n from 'i18next';
import {UI_END_MOUNT, UI_UPDATE_LANGUAGE} from '../actions/ui-actions';

export default {
  doUIEndMount() {
    return {
      type: UI_END_MOUNT,
      language: i18n.language.substring(0, 2) // substring is in case that old webapp set cookie 'i18next' value to a 5-chars locale
    };
  },
  doUIUpdateLanguage(language) {
    return {
      type: UI_UPDATE_LANGUAGE,
      _googleAnalytics: true,
      language
    };
  }
};
