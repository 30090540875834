import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import getStandardParamsFromState from './lib/get-standard-params-from-state';
import prepareRequestParams from './lib/prepare-request-params';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {
  INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_CUSTOMERS_LOAD,
  INSIGHT_NEW_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_NEW_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_NEW_CUSTOMERS_OPEN,
  INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../actions/insight-actions';

const InsightNewCustomersEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(
        INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_NEW_CUSTOMERS_OPEN,
        INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ),
      map(() => {
        return {
          type: INSIGHT_NEW_CUSTOMERS_LOAD,
          ...getStandardParamsFromState(state$)
        };
      })
    );

    const loadTopStores = action$.pipe(
      ofType(INSIGHT_NEW_CUSTOMERS_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate
        }) => {
          return graphql(
            'get-new-customers-histogram-query',
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            }),
            {timeout: TIMEOUT_REQUEST_LONG}
          ).pipe(
            map(({newCustomersHistogram}) => {
              return {
                type: INSIGHT_NEW_CUSTOMERS_LOAD_SUCCESS,
                newCustomersHistogram
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_NEW_CUSTOMERS_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    return merge(loadInsights, loadTopStores);
  };

export default InsightNewCustomersEpic;
