import PropTypes from 'prop-types';
import {blue, lightGrey} from '../../../../../../../../../shared/style/colors';
import AdministrationBusinessPageChannelGbmBusinessLocationForm from './administration-business-page-channels-gbm-business-location-form-component';
import AdministrationBusinessPageChannelGbmHeaderItem from './administration-business-page-channels-gbm-header-item-component';

const AdministrationBusinessPageChannelGbmBusinessLocationComponent = ({
  business,
  gbmBusinessState: {businessLocationForm},
  i18n,
  onCloseBusinessLocation,
  onGbmBusinessLocationFormSubmitButtonClick
}) => {
  return (
    <div
      data-test-id="business-location-wrapper"
      style={{margin: '7px 0 0 16px', maxWidth: '698px', width: '100%'}}
    >
      <div
        style={{
          alignItems: 'center',
          borderBottom: `1px solid ${lightGrey}`,
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '2rem',
          paddingBottom: '20px'
        }}
      >
        <AdministrationBusinessPageChannelGbmHeaderItem {...{i18n}} />
        <span
          data-test-id="cancel"
          style={{color: blue, cursor: 'pointer'}}
          onClick={onCloseBusinessLocation}
        >
          {i18n.t('administration.business.pages.channels.gbm.cancelButton')}
        </span>
      </div>
      <AdministrationBusinessPageChannelGbmBusinessLocationForm
        {...{
          business,
          businessLocationForm,
          i18n,
          onGbmBusinessLocationFormSubmitButtonClick,
          onCloseBusinessLocation
        }}
      />
    </div>
  );
};

AdministrationBusinessPageChannelGbmBusinessLocationComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  gbmBusinessState: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseBusinessLocation: PropTypes.func.isRequired,
  onGbmBusinessLocationFormSubmitButtonClick: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelGbmBusinessLocationComponent;
