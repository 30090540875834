import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Picker as EmojiPicker} from 'emoji-mart';
import context from '../../../../../../../shared/component/context';
import appleEmojiListExcluded from './apple-emoji-list-excluded';
import {backgroundImageFn} from '../../../emoji/emoji-tools';
import EnhancedPopover from '../../../enhanced-popover-component';
import 'emoji-mart/css/emoji-mart.css';

const emojisToShowFilter = (e) =>
  !appleEmojiListExcluded.find((ael) => e.short_names && e.short_names[0] === ael);

/**
 * Finally, the component.
 */
class EmojisPopoverComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._selectEmoji = this._selectEmoji.bind(this);
  }

  _selectEmoji(e) {
    const {selectEmoji} = this.props;
    selectEmoji(e.native);
  }

  render() {
    const {anchorEl, open, onRequestClose, ...otherProps} = this.props;

    return (
      <EnhancedPopover
        anchorOrigin={{horizontal: 'middle', vertical: 'top'}}
        dataTestId="emoji-popup"
        targetOrigin={{horizontal: 'right', vertical: 'bottom'}}
        {...{
          anchorEl,
          open,
          onRequestClose
        }}
        {...otherProps}
      >
        <div style={{padding: '8px', textAlign: 'left'}}>
          <EmojiPicker
            autoFocus
            backgroundImageFn={backgroundImageFn}
            emoji="+1"
            emojiSize={20}
            emojisToShowFilter={emojisToShowFilter}
            onClick={this._selectEmoji}
            perLine={6}
            sheetSize={32}
            skin={1}
            title="Instaply"
            style={{
              width: '302px'
            }}
          />
        </div>
      </EnhancedPopover>
    );
  }
}

EmojisPopoverComponent.contextTypes = context;

EmojisPopoverComponent.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  selectEmoji: PropTypes.func.isRequired
};

export default EmojisPopoverComponent;
