import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Button, MobileStepper} from '@material-ui/core';
import PaymentHeader from '../payment-header-component';
import PaymentLayout from './payment-layout-component';
import {paymentSteps, paymentStepsIndex} from '../../../../../../../../data/thread/payment-steps';
import {lightSlate, slate} from '../../../../../../../../../../shared/style/colors';

const StyledMobileStepper = withStyles({
  root: {
    width: '100%',
    maxHeight: '40px',
    backgroundColor: 'transparent'
  }
})(MobileStepper);

/**
 * Finally, the component.
 */
const PaymentStepLayoutComponent = ({
  amount,
  canGoNext,
  children,
  conversationId,
  errorMessage,
  i18n,
  nextButtonLabelI18NPath,
  step,
  submitting,
  subTitle,
  title,
  onClickCancelButton,
  onClickMinimizeButton,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick
}) => {
  const renderStepper = () => {
    const activeStep = paymentStepsIndex[step];
    const nextButtonCustomLabelI18NPath =
      nextButtonLabelI18NPath ||
      `customerThread.visibleThread.apps.list.payment.${step}.nextButtonLabel`;

    const onClickStepBackButton = () => doAppPaymentStepperBackButtonClick(conversationId);
    const onClickStepNextButton = () => doAppPaymentStepperNextButtonClick();

    return (
      <StyledMobileStepper
        data-test-id={`step-${activeStep}`}
        activeStep={activeStep}
        position="static"
        steps={paymentSteps.length}
        variant="dots"
        backButton={
          activeStep === 0 ? (
            // Looks like the only way to maintain Stepper's dots at middle of a layout is to have always both buttons
            <Button disabled />
          ) : (
            <Button
              data-test-id="back-button"
              color="primary"
              disabled={submitting}
              size="small"
              onClick={onClickStepBackButton}
            >
              {i18n.t('customerThread.visibleThread.apps.stepper.backButton')}
            </Button>
          )
        }
        nextButton={
          <Button
            data-test-id="next-button"
            color="primary"
            disabled={!canGoNext || submitting}
            size="small"
            onClick={onClickStepNextButton}
            variant={activeStep === 2 ? 'contained' : 'text'}
          >
            {i18n.exists(nextButtonCustomLabelI18NPath)
              ? i18n.t(nextButtonCustomLabelI18NPath)
              : i18n.t('customerThread.visibleThread.apps.stepper.nextButton')}
          </Button>
        }
      />
    );
  };

  const descriptionI18NPath = `customerThread.visibleThread.apps.list.payment.${step}.description`;

  return (
    <PaymentLayout step={step}>
      <PaymentHeader
        amount={amount}
        onlyShowCancelButton={false}
        onClickCancelButton={onClickCancelButton}
        onClickMinimizeButton={onClickMinimizeButton}
        step={step}
        title={title}
        {...{
          conversationId,
          i18n,
          submitting
        }}
      />
      <span style={{color: slate, textTransform: 'uppercase'}}>
        {subTitle || i18n.t(`customerThread.visibleThread.apps.list.payment.${step}.subTitle`)}
      </span>

      {i18n.exists(descriptionI18NPath) ? (
        <span style={{marginTop: '5px', color: lightSlate}}>{i18n.t(descriptionI18NPath)}</span>
      ) : null}

      <span data-test-id={errorMessage ? 'error' : null} style={{color: 'red', height: '20px'}}>
        {errorMessage}
      </span>

      {children}

      {renderStepper()}
    </PaymentLayout>
  );
};

PaymentStepLayoutComponent.propTypes = {
  amount: PropTypes.string,
  canGoNext: PropTypes.bool.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  conversationId: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  nextButtonLabelI18NPath: PropTypes.string,
  step: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  onClickCancelButton: PropTypes.func.isRequired,
  onClickMinimizeButton: PropTypes.func.isRequired
};

export default PaymentStepLayoutComponent;
