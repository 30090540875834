import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {push} from 'react-router-redux';
import AppComponent from '../component/app-component';
import doRegisterTutorial from './lib/actions/tutorials-dispatch-to-props';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {
  APP_BROWSER_NOTIFICATION_BANNER_CLOSE,
  APP_BROWSER_NOTIFICATION_BANNER_SHOW_LAST,
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND,
  APP_LOGOUT,
  APP_RELOAD,
  APP_STATE_BOOT_LOAD_RETRY,
  APP_TWILIO_TOKEN_LOAD
} from '../actions/app-actions';
import {
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_CLOSE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_OPEN,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SELECT_BUSINESS,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_SELECTION_SET,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_CLOSE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_INPUT_UPDATE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_INVITE_MORE_OPEN,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_HEADER,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_BUSINESS_REMOVE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_COLLEAGUE_REMOVE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT
} from '../actions/global-actions';
import {
  HEADER_MENTIONS_POPUP_CLOSE,
  HEADER_MENTIONS_POPUP_ITEM_CLICK,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD,
  HEADER_MENTIONS_POPUP_OPEN
} from '../actions/header-actions';
import {APP_THREAD_FILE_PREVIEW_CLOSE} from '../actions/thread-actions';
import {TUTORIAL_CLOSE} from '../actions/tutorial-actions';
import {UI_AREA_CLICK} from '../../../shared/actions/ui-actions';

const AppContainer = connect(
  createSelector(
    [
      (state, router) => router,
      (state) => state.get('account'),
      (state) => state.get('app'),
      (state) => state.get('colleagueInvitation'),
      (state) => state.get('featureToggle'),
      (state) => state.get('headerMention'),
      (state) => state.get('threadFilePreview'),
      (state) => state.get('tutorial'),
      (state) => state.get('twilio'),
      (state) => state.get('ui'),
      (state) => state.get('warning')
    ],
    (
      router,
      account,
      app,
      colleagueInvitation,
      featureToggle,
      headerMention,
      threadFilePreview,
      tutorial,
      twilio,
      ui,
      warning
    ) => {
      const currentTutorial = tutorial.get('current');

      return {
        browserNotificationBannerStep: app.get('browserNotificationBannerStep'),
        colleagueInvitation: colleagueInvitation.toJS(),
        emailAddress: account.get('emailAddress'),
        emailVerified: account.get('emailVerified'),
        enableHeaderMentionsPopup: featureToggle.get('notification'), // @todo rename / delete the feature toggle
        enableStatisticsMenu: !featureToggle.get('statistics_disable_menu'),
        enableStatisticsMenuRealTime:
          !featureToggle.get('statistics_disable_menu_contact_centers') &&
          !featureToggle.get('statistics_disable_menu_contact_centers_real_time'),
        fullName: account.get('fullName'),
        globalErrorsCount: app.get('globalErrorsCount'),
        headerMention: headerMention.toJS(),
        isLoadingApp: app.get('isLoading'),
        isLoggingOut: app.get('isLoggingOut'),
        isResendingVerifyEmail: app.get('isResendingVerifyEmail'),
        isSmallScreen: ui.get('isSmallScreen'),
        isVisibleNewReleaseBanner: app.get('isVisibleNewReleaseBanner'),
        newNotificationEventType: app.get('newNotificationEventType'),
        organizationPicture: account.get('organizationPicture'),
        picture: account.get('picture'),
        routePathname: router.location.pathname,
        showAdmin: account.get('manager') || account.get('managerOfABusiness'),
        threadFilePreview: threadFilePreview.toJS(),
        tutorial: currentTutorial ? currentTutorial.toJS() : null,
        twilio: twilio.toJS(),
        twilioEnable: featureToggle.get('twilio_enable'),
        userBusinesses: account.get('businesses').toJS(),
        unreadColleagueThreadCount: account.get('unreadColleagueThreadCount'),
        unreadCustomerThreadCount: account.get('unreadCustomerThreadCount'),
        warning: warning.toJS(),
        windowFocused: ui.get('windowFocused')
      };
    }
  ),
  {
    push,
    ...uiDispatchToProps,
    onUIAreaClick(area) {
      return {
        type: UI_AREA_CLICK,
        payload: {
          area
        }
      };
    },
    doRegisterTutorial,
    doApplicationStateBootLoadRetry() {
      return {
        type: APP_STATE_BOOT_LOAD_RETRY,
        _googleAnalytics: true
      };
    },
    doCloseTutorial() {
      return {
        type: TUTORIAL_CLOSE,
        _googleAnalytics: true
      };
    },
    // colleague invitation
    doColleagueInvitationPopupOpen() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_HEADER,
        _googleAnalytics: true
      };
    },
    // file preview
    doFilePreviewClose() {
      return {
        type: APP_THREAD_FILE_PREVIEW_CLOSE,
        _googleAnalytics: true
      };
    },
    // header mentions popup
    doHeaderMentionsPopupClose() {
      return {
        type: HEADER_MENTIONS_POPUP_CLOSE,
        _googleAnalytics: true
      };
    },
    doHeaderMentionsPopupItemClick(cursor) {
      return {
        type: HEADER_MENTIONS_POPUP_ITEM_CLICK,
        _googleAnalytics: true,
        payload: {
          cursor
        }
      };
    },
    doHeaderMentionsPopupItemsLoadMore(after) {
      return {
        type: HEADER_MENTIONS_POPUP_ITEMS_LOAD,
        _googleAnalytics: true,
        payload: {
          after
        }
      };
    },
    doHeaderMentionsPopupOpen() {
      return {
        type: HEADER_MENTIONS_POPUP_OPEN,
        _googleAnalytics: true
      };
    },
    // browser's notification
    doBrowserNotificationBannerClose() {
      return {
        type: APP_BROWSER_NOTIFICATION_BANNER_CLOSE,
        _googleAnalytics: true
      };
    },
    doBrowserNotificationBannerShowLast() {
      return {
        type: APP_BROWSER_NOTIFICATION_BANNER_SHOW_LAST,
        _googleAnalytics: true
      };
    },
    // new release
    doApplicationReload() {
      return {
        type: APP_RELOAD,
        _googleAnalytics: true
      };
    },
    // colleague invitation
    doAddColleagueInvitationPopupColleagues(emails) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD,
        _googleAnalytics: true,
        emails
      };
    },
    doCloseColleagueInvitationPopup() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_CLOSE,
        _googleAnalytics: true
      };
    },
    doCloseColleagueInvitationPopupBusinessesPopup() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_CLOSE,
        _googleAnalytics: true
      };
    },
    doColleagueInvitationPopupSearchBusiness(text) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH,
        _googleAnalytics: true,
        text
      };
    },
    doColleagueInvitationPopupBusinessesPopupSelectBusiness(business) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SELECT_BUSINESS,
        _googleAnalytics: true,
        business
      };
    },
    doColleagueInvitationPopupOpenBusinessesPopup() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_OPEN,
        _googleAnalytics: true
      };
    },
    doColleagueInvitationPopupOpenInviteMore() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_INVITE_MORE_OPEN,
        _googleAnalytics: true
      };
    },
    doRemoveColleagueInvitationPopupSelectedBusiness(businessId) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_BUSINESS_REMOVE,
        _googleAnalytics: true,
        businessId
      };
    },
    doRemoveColleagueInvitationPopupSelectedColleague(email) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_COLLEAGUE_REMOVE,
        _googleAnalytics: true,
        email
      };
    },
    doSetColleagueInvitationPopupBusinessesSelection(businesses) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_SELECTION_SET,
        businesses
      };
    },
    doSubmitColleagueInvitationPopup() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT,
        _googleAnalytics: true
      };
    },
    doUpdateColleagueInvitationPopupInput(event) {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_INPUT_UPDATE,
        inputText: event.target.value
      };
    },
    // header
    doLogout() {
      return {
        type: APP_LOGOUT,
        _googleAnalytics: true
      };
    },
    doTwilioTokenLoad() {
      return {
        type: APP_TWILIO_TOKEN_LOAD,
        _googleAnalytics: true
      };
    },
    // verify email
    doResendVerificationEmail() {
      return {
        type: APP_GLOBAL_VERIFICATION_EMAIL_RESEND,
        _googleAnalytics: true
      };
    }
  }
)(AppComponent);

export default AppContainer;
