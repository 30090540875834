import {fromEvent, merge} from 'rxjs';
import {debounceTime, filter, map, mergeMap, throttleTime} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import moment from 'moment';
import reportEmployeeUsage from '../../../../../../shared/epic/lib/report-employee-usage';
import {
  CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE,
  CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_INACTIVE,
  CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD,
  CUSTOMER_THREAD_ACTIVITY_START_WATCHING,
  CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING,
  CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE,
  CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS,
  CUSTOMER_THREAD_PAGE_LEAVE,
  LEGACY_CUSTOMER_THREAD_CHANGED
} from '../../../../actions/customer-thread-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS} from '../../../../actions/customer-thread-transfer-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';
import {CUSTOMER_THREAD_INACTIVITY_TIMER} from '../../../../data/settings';

const MAPPING_REDUX_ACTION_TO_ACTIVITY_TYPE = {
  CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD: 'EmployeeLeftThread',
  CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE: 'EmployeeBecameActive',
  CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_INACTIVE: 'EmployeeBecameInactive'
};

const activateThreadState = (state) => ({
  ...state,
  active: true,
  activeAt: moment()
});

const interactions = merge(fromEvent(window, 'keypress'), fromEvent(window, 'mousemove'));

let currentThreadState = {};

const calculateDuration = () => moment().diff(currentThreadState.activeAt, 'milliseconds');

/**
 * Finally, the epic.
 *
 * Possible workflows :
 * - EmployeeBecameActive > EmployeeBecameInactive
 * - EmployeeBecameActive > EmployeeLeftThread
 * - EmployeeBecameInactive > EmployeeBecameActive
 * Impossible workflow :
 * - EmployeeBecameInactive > EmployeeLeftThread
 */
const CustomerThreadActivityEpic =
  ({graphql}) =>
  (action$, state$) => {
    const customerThreadChanged = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_CHANGED),
      map(({oldThread: {directedToBusinessIdentifier, participantId, status}}) => {
        return {
          type: CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING,
          directedToBusinessIdentifier,
          participantId,
          status
        };
      })
    );

    const customerThreadComponentWillUnmount = action$.pipe(
      ofType(CUSTOMER_THREAD_PAGE_LEAVE),
      filter(
        ({directedToBusinessIdentifier, participantId, status}) =>
          // We test the props because the page could be left before all thread data was loaded
          directedToBusinessIdentifier && participantId && status
      ),
      map(({directedToBusinessIdentifier, participantId, status}) => {
        return {
          type: CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING,
          directedToBusinessIdentifier,
          participantId,
          status
        };
      })
    );

    const legacyCustomerThreadTransferred = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS),
      map(({newInScopeBusiness, previousBusinessId}) => {
        const legacyCustomerThreadState = state$.value.get('legacyCustomerThread');

        return {
          type: CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING,
          directedToBusinessIdentifier: previousBusinessId,
          participantId: legacyCustomerThreadState.get('participantId'),
          restartWatchingAfterStop: newInScopeBusiness,
          status: legacyCustomerThreadState.get('status')
        };
      })
    );

    const legacyDetectInactivity = merge(
      action$.pipe(ofType(CUSTOMER_THREAD_ACTIVITY_START_WATCHING)),
      interactions
    ).pipe(
      debounceTime(CUSTOMER_THREAD_INACTIVITY_TIMER),
      // in case of the employee left the thread component
      filter(() => currentThreadState.active),
      map(() => {
        currentThreadState.active = false;

        return {
          type: CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_INACTIVE,
          directedToBusinessIdentifier: currentThreadState.directedToBusinessIdentifier,
          duration: calculateDuration(),
          participantId: currentThreadState.participantId,
          previousStatus: currentThreadState.status,
          status: state$.value.getIn(['legacyCustomerThread', 'status'])
        };
      })
    );

    const legacyDetectNewActivity = merge(
      action$.pipe(ofType(CUSTOMER_THREAD_ACTIVITY_START_WATCHING)),
      interactions
    ).pipe(
      throttleTime(CUSTOMER_THREAD_INACTIVITY_TIMER),
      // in case of the employee remains active on same thread
      filter(() => currentThreadState.participantId && !currentThreadState.active),
      map(() => {
        currentThreadState = activateThreadState(currentThreadState);

        const legacyCustomerThreadState = state$.value.get('legacyCustomerThread');

        return {
          type: CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE,
          directedToBusinessIdentifier: legacyCustomerThreadState.get(
            'directedToBusinessIdentifier'
          ),
          participantId: legacyCustomerThreadState.get('participantId')
        };
      })
    );

    const legacyTriggerStartWatchingActivityOnReportSuccess = action$.pipe(
      ofType(CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS),
      filter(({payload: {restartWatchingAfterStop}}) => restartWatchingAfterStop),
      map(() => {
        const legacyCustomerThreadState = state$.value.get('legacyCustomerThread');

        return {
          type: CUSTOMER_THREAD_ACTIVITY_START_WATCHING,
          payload: {
            directedToBusinessIdentifier: legacyCustomerThreadState.get(
              'directedToBusinessIdentifier'
            ),
            participantId: legacyCustomerThreadState.get('participantId'),
            status: legacyCustomerThreadState.get('status')
          }
        };
      })
    );

    const legacyTriggerStartWatchingActivityOnThreadLoadSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS),
      filter(() => !currentThreadState.active), // in case of the employee reloads the same thread
      map(({data: {directedToBusinessIdentifier, participantId, status}}) => ({
        type: CUSTOMER_THREAD_ACTIVITY_START_WATCHING,
        payload: {
          directedToBusinessIdentifier,
          participantId,
          status
        }
      }))
    );

    const logActivityChange = action$.pipe(
      ofType(
        CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE,
        CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_INACTIVE,
        CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD
      ),
      filter(() => state$.value.getIn(['featureToggle', 'thread_activity'])),
      filter(({logActivity}) => logActivity !== false),
      mergeMap(
        ({
          directedToBusinessIdentifier,
          duration,
          participantId,
          previousStatus,
          restartWatchingAfterStop,
          status,
          type
        }) => {
          const eventType = MAPPING_REDUX_ACTION_TO_ACTIVITY_TYPE[type];
          let requestPayload = {
            eventType,
            customerId: participantId,
            businessId: directedToBusinessIdentifier
          };

          if (eventType !== 'EmployeeBecameActive') {
            requestPayload = Object.assign(requestPayload, {
              duration,
              previousStatus,
              status
            });
          }

          if (eventType === 'EmployeeBecameInactive') {
            requestPayload.timeout = CUSTOMER_THREAD_INACTIVITY_TIMER;
          }

          return reportEmployeeUsage(
            graphql,
            requestPayload,
            null,
            () => ({
              type: CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS,
              payload: {
                restartWatchingAfterStop
              }
            }),
            CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE
          );
        }
      )
    );

    const startWatchingCustomerThreadActivity = action$.pipe(
      ofType(CUSTOMER_THREAD_ACTIVITY_START_WATCHING),
      map(({payload: {directedToBusinessIdentifier, participantId, status}}) => {
        currentThreadState = activateThreadState({
          directedToBusinessIdentifier,
          participantId,
          status
        });

        return {
          type: CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE,
          directedToBusinessIdentifier,
          participantId
        };
      })
    );

    const stopWatchingCustomerThreadActivity = action$.pipe(
      ofType(CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING),
      map(({directedToBusinessIdentifier, participantId, restartWatchingAfterStop, status}) => {
        // in case of thread closed while employee is inactive
        if (!currentThreadState.active) {
          currentThreadState = {};

          return {
            type: CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD,
            logActivity: false,
            restartWatchingAfterStop
          };
        }

        const duration = calculateDuration();
        const previousStatus = currentThreadState.status;

        currentThreadState = {};

        return {
          type: CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD,
          directedToBusinessIdentifier,
          participantId,
          duration,
          previousStatus,
          restartWatchingAfterStop,
          status
        };
      })
    );

    return merge(
      customerThreadChanged,
      customerThreadComponentWillUnmount,
      legacyCustomerThreadTransferred,
      legacyDetectInactivity,
      legacyDetectNewActivity,
      legacyTriggerStartWatchingActivityOnReportSuccess,
      legacyTriggerStartWatchingActivityOnThreadLoadSuccess,
      logActivityChange,
      startWatchingCustomerThreadActivity,
      stopWatchingCustomerThreadActivity
    );
  };

export default CustomerThreadActivityEpic;
