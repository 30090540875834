import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../shared/component/context';

class ErrorMessageComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {children, style} = this.props;

    return (
      <div
        data-test-id="error-message"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...style
        }}
      >
        {i18n.t('common.errorDefault')}
        {children}
      </div>
    );
  }
}

ErrorMessageComponent.contextTypes = context;

ErrorMessageComponent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any)
};

export default ErrorMessageComponent;
