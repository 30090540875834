import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import ForgottenPasswordComponent from '../component/forgotten-password-component';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {FORGOTTEN_PASSWORD_SUBMIT} from '../actions/forgotten-password-actions';

const ForgottenPasswordContainer = connect(
  createSelector(
    [(state) => state.get('forgottenPassword'), (state) => state.get('ui')],
    (forgottenPassword, ui) => {
      return {
        language: ui.get('language'),
        ...forgottenPassword.toJS()
      };
    }
  ),
  {
    ...uiDispatchToProps,
    doForgottenPasswordSubmit(email, isFormValid) {
      return {
        type: FORGOTTEN_PASSWORD_SUBMIT,
        _googleAnalytics: true,
        email,
        isFormValid
      };
    }
  }
)(ForgottenPasswordComponent);

export default ForgottenPasswordContainer;
