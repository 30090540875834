import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import assert from '../../../../../../shared/lib/assert';
import {
  findMessageIndex,
  pushMessage,
  sendMessage,
  sendMessageFail,
  sendMessageSuccessful
} from '../../../lib/message-helper';
import {
  DEFAULT_STATE_THREAD,
  DEFAULT_STATE_THREAD_FORM,
  checkMessageForm,
  failUploadFile,
  persistMessageFormText,
  removeUploadedFile,
  startUploadFile,
  succeedUploadFile
} from '../../../lib/message-form-helper';
import addTransferMessage from '../../../lib/add-transfer-message';
import {customerThreadNewLoaded, initCustomerThreadNew} from './lib/customer-thread-helper';
import {deletePersistedState, resetNotPersistedState} from '../../../lib/persistence-helper';
import {getDateTimeLegacy} from '../../../../lib/date-time-helper';
import {APP_LOGOUT, APP_STATE_BOOT_LOAD_SUCCESS} from '../../../../actions/app-actions';
import {LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD} from '../../../../actions/customer-inbox-actions';
import {
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-new-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_LOAD,
  LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE,
  LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE
} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_REMOVE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_PERSIST,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_FAILURE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_UNSELECT_COLLEAGUE_TO_MENTION
} from '../../../../actions/customer-thread-invisible-actions';
import {LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE} from '../../../../actions/customer-thread-invisible-mention-popup-actions';
import {
  LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE,
  LEGACY_CUSTOMER_THREAD_PROFILE_SHOW
} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS} from '../../../../actions/customer-thread-transfer-actions';
import {
  EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD,
  EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS
} from '../../../../actions/ext-actions';

const DEFAULT_STATE_INVISIBLE_THREAD_FORM = {
  ...DEFAULT_STATE_THREAD_FORM,
  ...{
    userToMention: null
  }
};

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_THREAD,
  ...{
    fullName: null,
    hasMoreMessagesAfter: false,
    isFocused: false,
    loadingAfter: false,
    messages: [],
    organizationPicture: null,
    picture: null,
    showMentionTooltip: true,
    userId: null,
    colleagues: {
      loading: false,
      items: []
    }
  }
};

const upleteUserToMention = (state, data) => {
  const userToMention = data && data.user ? fromJS(data.user) : null;

  return checkMessageForm(state).setIn(
    ['threadsForm', state.get('participationId'), 'userToMention'],
    userToMention
  );
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Load some useful current user information.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_SUCCESS]: (state, {data}) => {
    return state.set('userId', data.account.id);
  },

  /**
   * Load a customer thread.
   *
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_LOAD]: (state, {payload: {participationId}}) =>
    checkMessageForm(
      state.set('loading', true).set('participationId', participationId),
      DEFAULT_STATE_INVISIBLE_THREAD_FORM
    ),

  /**
   * Load a customer invisible thread.
   *
   * @param {String} direction
   * @param {Object} state the current reducer state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD]: (state, {direction}) =>
    state
      .set('direction', direction)
      .set('loading', !/before|after/.test(direction))
      .set('loadingAfter', direction === 'after')
      .set('loadingBefore', direction === 'before'),

  /**
   * Triggered when the thread is loaded from the server
   * May be a partial messages part to add to current (loadingBefore)
   * or a new one (loading)
   *
   * @param {Object} state
   * @param {Object} data resulting of the get-conversation resource
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS]: (state, {data, params}) => {
    const newState = state
      .merge(fromJS(data))
      .set('direction', params.direction)
      .set('loading', false)
      .set('loadingAfter', false)
      .set('loadingBefore', false)
      .set('participationId', data.id)
      .set('unreadInternalMessagesCount', 0);

    const loadedMessages = fromJS(data.messages);

    if (params.direction === 'before') {
      return newState
        .set('hasMoreMessagesAfter', state.get('hasMoreMessagesAfter'))
        .set('messages', loadedMessages.concat(state.get('messages')));
    }

    if (params.direction === 'after') {
      return newState
        .set('hasMoreMessagesBefore', state.get('hasMoreMessagesBefore'))
        .set('messages', state.get('messages').concat(loadedMessages));
    }

    const {fromId} = params;

    return fromId
      ? newState.update('messages', (messages) => {
          const foundMessageIndex = messages.findIndex((message) => message.get('id') == fromId); // eslint-disable-line eqeqeq

          return foundMessageIndex > -1
            ? messages.update(foundMessageIndex, (message) => message.set('selected', true))
            : messages;
        })
      : newState;
  },

  /**
   * Reset the state on load
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: (state) => {
    return resetNotPersistedState(state, DEFAULT_STATE).set('userId', state.get('userId'));
  },

  /**
   * Send message handling.
   * 1st action is an optimistic update, with a temporary identifier
   * to the message so it can be updated in the <..._SUCCESS> action.
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE]: (
    state,
    {attachment, clientMessageId, fullName, mentionedUser, text}
  ) => {
    if (state.get('hasMoreMessagesAfter')) {
      return upleteUserToMention(state);
    }

    assert(fullName);

    return upleteUserToMention(
      sendMessage(state, {
        mentionedUser,
        presentationType: 'InternalOutgoing',
        message: {
          clientMessageId,
          text,
          attachment
        },
        user: {
          fullName
        }
      })
    );
  },
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_FAILURE]: sendMessageFail,
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_SUCCESS]: sendMessageSuccessful,

  /**
   * File upload handling
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_REMOVE]: removeUploadedFile,
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD]: startUploadFile,
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_SUCCESS]: succeedUploadFile,
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_FAILURE]: failUploadFile,

  [LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE]: (state, {threadType}) => {
    return state.set('isFocused', threadType === 'invisible').set('showMentionTooltip', true);
  },

  [LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE]: (state, {threadType}) => {
    return state.set('isFocused', threadType === 'invisible');
  },

  [EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD]: (state, {data}) => {
    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId') || state.get('hasMoreMessagesAfter')) {
      return state;
    }

    return state.set('direction', null).update('messages', (messages) => {
      const index = findMessageIndex(messages, data.id, data.clientMessageId);

      if (index > -1) {
        return messages.update(index, (message) =>
          message.set('id', data.id).set('status', 'sent')
        );
      }

      // insert the message
      const isMe = state.get('userId') == data.senderId; // eslint-disable-line eqeqeq

      return pushMessage(data, messages, {
        clientMessageId: data.clientMessageId,
        presentationType: isMe ? 'InternalOutgoing' : 'InternalIncoming',
        receiverName: data.receiverName,
        senderName: data.senderName
      });
    });
  },

  /**
   * Triggered when user load a new thread
   *
   * @param {Object} state
   * @param {String} businessName
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD]: (state, {businessName}) => {
    return initCustomerThreadNew(state, DEFAULT_STATE, businessName);
  },

  /**
   * Triggered when user successfully load a new thread
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS]: customerThreadNewLoaded,

  /**
   * Optimistic update after a successful transfer a thread to a business made by current user.
   *
   * @param {Object} state
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS]: (state, {params}) => {
    return addTransferMessage(state, {
      businessName: params.name,
      date: getDateTimeLegacy(),
      senderName: params.fullName
    });
  },

  /**
   * Add message after thread is transferred to a business.
   * Do nothing if it's current user that did the action
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Boolean} doneByOtherUserToCurrentThread
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS]: (
    state,
    {data, doneByOtherUserToCurrentThread}
  ) => {
    if (doneByOtherUserToCurrentThread) {
      return addTransferMessage(state, {
        businessName: data.newBusinessName,
        date: getDateTimeLegacy(data.timestamp),
        senderName: data.employeeName
      });
    }

    return state;
  },

  /**
   * Set the participation id when thread is created
   *
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: (state, {participationId}) => {
    return state.set('participationId', participationId);
  },

  /**
   * Show customer profile
   *
   * Triggered when user click on customer profile card or icon in small screen version
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_SHOW]: (state) => {
    return state.set('showMentionTooltip', false);
  },

  /**
   * Close customer profile
   *
   * Triggered when user click or press ESC to close customer profile
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE]: (state) => {
    return state.set('showMentionTooltip', true);
  },

  /**
   * Persist message form text.
   *
   * @param {Object} state
   * @param {String} participationId
   * @param {String} text
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_PERSIST]: persistMessageFormText,

  /**
   * Update the mentioned user.
   *
   * @param {Object} state
   * @param {Object} user
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE]: upleteUserToMention,

  /**
   * Remove the mentioned user.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_UNSELECT_COLLEAGUE_TO_MENTION]: upleteUserToMention,

  /**
   * Clean storage on logout.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_LOGOUT]: deletePersistedState
});
