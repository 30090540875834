import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {saveAs} from 'file-saver';
import ReactDomServer from 'react-dom/server';
import moment from 'moment';
import numeral from 'numeral';
import IconButton from 'material-ui/IconButton/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import context from '../../../../../../../shared/component/context';
import getStyledHelpLink from '../get-styled-help-link';
import HighchartsCustom from '../../../../../../../shared/component/highcharts/highcharts-custom';
import HistogramTooltipLine from './histogram-tooltip-line-component';
import LoadingIndicator from '../../../../../../../shared/component/loading-indicator-component';
import {DELAY_ANIMATION_HIDE} from '../../../../../../../shared/data/settings';
import {fontSize, fontWeight, radius, spacing} from '../../../../../../../shared/style/variables';
import {
  graphColor1,
  graphColor2,
  graphColor3,
  graphColor4,
  graphColor5,
  graphColor6,
  lightSlate,
  white
} from '../../../../../../../shared/style/colors';

const CSV_SEPARATOR = ';';
const TOOLTIP_BORDER_RADIUS = parseInt(radius.xlarge.replace('px', ''), 10);

const StyledHelpLink = getStyledHelpLink({
  top: '7px',
  right: '33px'
});

const getMomentFromServerTimestamp = (d) => moment.utc(d);

const _toCsv = (series, items, dateFormat, dateTranslation, csvColumnsTranslations) => {
  const header = series.reduce(
    (reduction, {property}) =>
      Object.assign(reduction, {
        [property]: csvColumnsTranslations[property]
      }),
    {
      date: dateTranslation
    }
  );

  const renderDateFormat = ({item, key}) => {
    return key === 'date' ? dateFormat(item[key]) : item[key];
  };

  const rows = items.map((item) => {
    // don't use another reference to browse columns to export
    const columns = Object.keys(header).map((key) =>
      // the test on property presence is just to avoid error on client in case of server doesn't return the expected key defined in the serie
      item.hasOwnProperty(key) // eslint-disable-line no-prototype-builtins
        ? renderDateFormat({item, key})
        : ''
    );

    return columns.join(CSV_SEPARATOR);
  });

  return `${Object.values(header).join(CSV_SEPARATOR)}\n${rows.join('\n')}`;
};

/**
 * Finally, the component.
 */
class HistogramChartComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._downloadCSV = this._downloadCSV.bind(this);
  }

  __getFormatAndTick() {
    const {i18n} = this.context;
    const {aggregationPeriod} = this.props;

    return {
      PT1H: {
        tickInterval: 6,
        dateFormat(d, short = false) {
          return i18n.t(
            `insight.contentHeader.periodSelector.periods.PT1H.${
              short ? 'graphDateFormatShort' : 'graphDateFormat'
            }`,
            {date: getMomentFromServerTimestamp(d)}
          );
        }
      },
      P1D: {
        tickInterval: 4,
        dateFormat(d) {
          return i18n.t('insight.contentHeader.periodSelector.periods.P1D.graphDateFormat', {
            date: getMomentFromServerTimestamp(d)
          });
        }
      },
      P1W: {
        tickInterval: 4,
        dateFormat(d) {
          return i18n.t('insight.contentHeader.periodSelector.periods.P1W.graphDateFormat', {
            date: getMomentFromServerTimestamp(d)
          });
        }
      },
      P1M: {
        tickInterval: 2,
        dateFormat(d) {
          return i18n.t('insight.contentHeader.periodSelector.periods.P1M.graphDateFormat', {
            date: getMomentFromServerTimestamp(d)
          });
        },
        dateFormatTooltip(d) {
          return i18n.t('insight.contentHeader.periodSelector.periods.P1M.graphDateFormatTooltip', {
            date: getMomentFromServerTimestamp(d)
          });
        }
      },
      P1Y: {
        dateFormat(d) {
          return i18n.t('insight.contentHeader.periodSelector.periods.P1Y.graphDateFormat', {
            date: getMomentFromServerTimestamp(d)
          });
        }
      }
    }[aggregationPeriod];
  }

  _downloadCSV() {
    const {i18n} = this.context;
    const {items, series, translations} = this.props;

    return saveAs(
      new Blob(
        [
          _toCsv(
            series,
            items,
            this.__getFormatAndTick().dateFormat,
            i18n.t('insight.content.chart.csv.exportedFile.columns.date'),
            translations.csv.columns
          )
        ],
        {type: 'text/csv;charset=utf-8'}
      ),
      `${translations.csv.filename}.csv`
    );
  }

  render() {
    const {i18n, muiTheme} = this.context;
    const {
      aggregationPeriod,
      colors,
      dataTestId,
      failed,
      items,
      legendEnabled,
      legendVerticalAlign,
      loading,
      reversedStacks,
      series,
      showHelpPageUrl,
      stacking,
      subtitle,
      subtitleAlign,
      title,
      tooltipShowPercent,
      tooltipValueFormatter,
      tooltipValuesFormatter,
      translations,
      type,
      valueSuffix,
      yAxisOptions
    } = this.props;

    const renderTooltipForPie = (tooltip) => (
      <HistogramTooltipLine
        label={tooltip.point.nameTooltip}
        style={{color: tooltip.color}}
        value={`${numeral(tooltip.y).format('0,0')}${valueSuffix}`}
      />
    );
    const renderTooltipForOtherThanPie = ({formattedValue, index, point}) => (
      <HistogramTooltipLine
        key={index}
        label={point.series.userOptions.nameTooltip}
        style={{marginRight: spacing.xsmall, color: point.color}}
        value={`${
          tooltipShowPercent
            ? `${Math.round(point.percentage)}% (${formattedValue})`
            : formattedValue
        }`}
      />
    );
    const {dateFormat, dateFormatTooltip, tickInterval} = this.__getFormatAndTick();

    const dateFormatterTooltip = dateFormatTooltip || dateFormat;
    const chartTitle = title || translations.title;
    const isPie = type === 'pie';
    const hasValues = isPie ? items[series[0].property] !== null : items.length > 0;

    const graphIsPercent = stacking === 'percent' || valueSuffix === '%';
    const yAxisExtremes = graphIsPercent ? {floor: 0, ceiling: 100} : null;

    return (
      <div
        style={{
          width: '100%', // explicit width is required in order to fill the eventual flex container
          textAlign: 'right',
          position: 'relative'
        }}
      >
        {loading ? <LoadingIndicator overlay /> : null}

        {translations.helpPageUrl && showHelpPageUrl ? (
          <StyledHelpLink url={translations.helpPageUrl} />
        ) : null}

        {translations.csv && translations.csv.filename && hasValues ? (
          <IconMenu
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'right', vertical: 'top'}}
            iconButtonElement={
              <IconButton
                data-test-id="menu-button"
                iconClassName="material-icons"
                iconStyle={{color: 'black'}}
              >
                more_vert
              </IconButton>
            }
            style={
              chartTitle
                ? {
                    position: 'absolute',
                    zIndex: 1,
                    marginTop: '-5px',
                    right: '-12px'
                  }
                : null
            }
          >
            <div data-test-id="menu-list">
              <MenuItem
                data-test-id="item-label"
                primaryText={i18n.t('insight.content.chart.csv.downloadButton')}
                style={{fontSize: '13px'}}
                onClick={this._downloadCSV}
              />
            </div>
          </IconMenu>
        ) : null}

        <HighchartsCustom
          containerProps={{'data-test-id': dataTestId}}
          {...{
            failed,
            loading
          }}
          options={{
            colors,
            chart: {
              type,
              animation: false
            },
            legend: {
              align: 'left',
              enabled: legendEnabled && hasValues,
              verticalAlign: legendVerticalAlign
            },
            subtitle: subtitle
              ? {
                  align: subtitleAlign,
                  text: ReactDomServer.renderToStaticMarkup(subtitle),
                  useHTML: true,
                  x: subtitleAlign === 'right' ? -30 : 0,
                  y: 12,
                  style: {
                    color: 'inherit'
                  }
                }
              : undefined,
            title: {
              align: 'left',
              text: ReactDomServer.renderToStaticMarkup(chartTitle),
              useHTML: true,
              style: {
                fontFamily: muiTheme.fontFamily,
                fontSize: fontSize.xlarge,
                color: muiTheme.palette.textColor
              }
            },
            yAxis: {
              reversedStacks,
              title: {
                text: null
              },
              ...yAxisExtremes,
              ...yAxisOptions
            },
            tooltip: {
              borderColor: lightSlate,
              borderRadius: TOOLTIP_BORDER_RADIUS,
              borderWidth: 2,
              hideDelay: DELAY_ANIMATION_HIDE,
              padding: 0,
              shared: true,
              useHTML: true,
              formatter() {
                return ReactDomServer.renderToStaticMarkup(
                  <div
                    style={{
                      padding: '8px 10px',
                      borderRadius: `${TOOLTIP_BORDER_RADIUS / 2}px`,
                      backgroundColor: white,
                      fontSize: fontSize.large
                    }}
                  >
                    {isPie ? null : (
                      <span
                        style={{
                          fontWeight: fontWeight.bold
                        }}
                      >
                        {i18n.t(
                          `insight.contentHeader.periodSelector.periods.${aggregationPeriod}.tooltipTitle`,
                          {
                            aggregationDate: dateFormatterTooltip(
                              items[this.points[0].point.index].date
                            )
                          }
                        )}
                      </span>
                    )}

                    {tooltipValuesFormatter ? (
                      tooltipValuesFormatter(this.points)
                    ) : (
                      <ul style={{listStyle: 'none'}}>
                        {isPie
                          ? renderTooltipForPie(this)
                          : this.points.map((point, index) => {
                              const formattedValue = tooltipValueFormatter
                                ? tooltipValueFormatter(point.y)
                                : `${numeral(point.y).format('0,0')}${valueSuffix || ''}`;

                              return renderTooltipForOtherThanPie({formattedValue, index, point});
                            })}
                      </ul>
                    )}
                  </div>
                );
              }
            },
            ...(isPie
              ? {
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      borderWidth: 0,
                      cursor: 'pointer',
                      showInLegend: true,
                      dataLabels: {
                        enabled: true,
                        format: `{point.y}${valueSuffix}`
                      }
                    }
                  },
                  series: [
                    {
                      animation: false,
                      data: hasValues
                        ? series.map(({color, property}) => {
                            return {
                              color,
                              name: translations.legend.columns[property],
                              nameTooltip: translations.tooltip.columns[property],
                              y: items[property] || null
                            };
                          })
                        : []
                    }
                  ],
                  xAxis: {
                    visible: false
                  }
                }
              : {
                  plotOptions: {
                    area: {
                      lineWidth: 1,
                      stacking: 'normal',
                      marker: {
                        lineWidth: 1
                      }
                    },
                    column: {
                      borderWidth: 0
                    },
                    series: {
                      stacking,
                      animation: false
                    }
                  },
                  series: series.map(({color, fillColor, property}) => {
                    return {
                      color,
                      fillColor,
                      name: translations.legend.columns[property],
                      nameTooltip: translations.tooltip.columns[property],
                      data: items.map((item) => item[property])
                    };
                  }),
                  xAxis: hasValues
                    ? {
                        categories: items.map(({date}) => dateFormat(date, true)),
                        tickInterval: tickInterval || 1,
                        tickmarkPlacement: 'on',
                        visible: true
                      }
                    : {
                        visible: false
                      }
                })
          }}
        />
      </div>
    );
  }
}

HistogramChartComponent.contextTypes = context;

HistogramChartComponent.defaultProps = {
  colors: [graphColor1, graphColor2, graphColor3, graphColor4, graphColor5, graphColor6],
  dataTestId: 'histogram-chart',
  legendEnabled: true,
  legendVerticalAlign: 'bottom',
  subtitleAlign: 'center',
  reversedStacks: false,
  showHelpPageUrl: true,
  type: 'area',
  yAxisOptions: {}
};

HistogramChartComponent.propTypes = {
  aggregationPeriod: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.any),
  dataTestId: PropTypes.string,
  failed: PropTypes.bool.isRequired,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  legendEnabled: PropTypes.bool,
  legendVerticalAlign: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  reversedStacks: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.any).isRequired,
  showHelpPageUrl: PropTypes.bool,
  stacking: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitleAlign: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tooltipShowPercent: PropTypes.bool,
  tooltipValueFormatter: PropTypes.func,
  tooltipValuesFormatter: PropTypes.func,
  translations: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string,
  valueSuffix: PropTypes.string,
  yAxisOptions: PropTypes.objectOf(PropTypes.any)
};

export default HistogramChartComponent;
