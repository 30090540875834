import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import CTAWidgetComponent from '../../../../../component/content/integration-area/page/widgets/cta-widget-component';
import {
  INTEGRATION_AREA_WIDGET_CTA_ACTION_BTN_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_COMMUNICATION_METHOD_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_RESET_STATE,
  INTEGRATION_AREA_WIDGET_CTA_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';
import {
  doChangeErrorInvalidEmail,
  doChangeErrorInvalidPhone,
  doChangeInputPlaceholderEmail,
  doChangeInputPlaceholderPhone,
  doChangeThankYouMessage
} from './lib/widget-page-map-dispatch-to-props';

const CTAWidgetContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.getIn(['integrationArea', 'ctaWidget'])
    ],
    (businesses, ctaWidget) => {
      return {
        businessesList: businesses.toJS(),
        ...ctaWidget.toJS()
      };
    }
  ),
  {
    doChangeLanguage(language) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_LANGUAGE_CHANGE,
        language
      };
    },
    doChangeBusiness(businessId) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_BUSINESS_CHANGE,
        businessId
      };
    },
    doChangeCommunicationMethod(method) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_COMMUNICATION_METHOD_CHANGE,
        method
      };
    },
    doChangeInputPlaceholder(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_CHANGE,
        text
      };
    },
    doUpdateSnippet(snippet) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_SNIPPET_CHANGE,
        snippet
      };
    },
    doChangeActionBtn(selector) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_ACTION_BTN_CHANGE,
        selector
      };
    },
    doCopyCode(copied) {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE,
        _googleAnalytics: true,
        copied
      };
    },
    doResetState() {
      return {
        type: INTEGRATION_AREA_WIDGET_CTA_RESET_STATE
      };
    },
    doChangeErrorInvalidEmail,
    doChangeErrorInvalidPhone,
    doChangeInputPlaceholderEmail,
    doChangeInputPlaceholderPhone,
    doChangeThankYouMessage
  }
)(CTAWidgetComponent);

export default CTAWidgetContainer;
