const GBM_MAPS_AUTOCOMPLETE_FIELDS = ['name', 'geometry', 'place_id', 'formatted_address'];
const GBM_MAPS_CONTAINER_SIZE = {
  width: '665px',
  height: '312px'
};

const GBM_MAPS_DEFAULT_ZOOM = 14;
const GBM_MAPS_LIBRARIES = ['places'];
const GBM_MAPS_VERSION = '3.45';

export {
  GBM_MAPS_AUTOCOMPLETE_FIELDS,
  GBM_MAPS_CONTAINER_SIZE,
  GBM_MAPS_DEFAULT_ZOOM,
  GBM_MAPS_LIBRARIES,
  GBM_MAPS_VERSION
};
