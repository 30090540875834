import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import isAuthenticated from '../../shared/lib/is-authenticated';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import signInLogin from '../signin/reducer/signin-login-reducer';
import signInLoginEpic from '../signin/epic/signin-login-epic';
import signUp from './reducer/signup-reducer';
import signUpEpic from './epic/signup-epic';
import {APP_HOME_URL} from '../../shared/data/route';

export default () => {
  if (isAuthenticated()) {
    window.location = APP_HOME_URL;

    return null;
  }

  const mea = middleEndAccessors();

  const rootEpic = createRootEpic(signInLoginEpic(mea), signUpEpic(mea));

  const rootReducer = createRootReducer({
    signInLogin,
    signUp
  });

  return {
    rootEpic,
    rootReducer
  };
};
