import PropTypes from 'prop-types';
import context from '../context';
import checkPasswordRules from '../../lib/check-password-rules';
import {ContinueOrSignInLink} from '../link/links-to-desktop-component';
import FormFieldsContainer from './fields-container-component';
import getErrorMessage from '../../lib/get-graphql-error-message';
import PageFormHandler from '../page-form-handler-component';
import PasswordField from './password-field-component';
import PublicForm from '../public/public-form-component';
import StandardRaisedButton from '../button/standard-raised-button-component';

class ChangePasswordFormComponent extends PageFormHandler {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      newPasswordConfirm: '',
      showError: {
        newPassword: false,
        newPasswordConfirm: false
      }
    };

    this._doChangePasswordSubmitForm = this._doChangePasswordSubmitForm.bind(this);
    this._getPasswordFieldErrorText = this._getPasswordFieldErrorText.bind(this);
  }

  _doChangePasswordSubmitForm() {
    const {newPassword, newPasswordConfirm} = this.state;

    const newPasswordIsValid = checkPasswordRules(newPassword).isValid;
    const passwordsAreEquals = newPasswordConfirm === newPassword;
    const arePasswordsValid = newPasswordIsValid && passwordsAreEquals;

    this.setState(
      {
        showError: {
          newPassword: !arePasswordsValid,
          newPasswordConfirm: !arePasswordsValid
        }
      },
      () => this.props.doChangePasswordSubmit(newPassword, arePasswordsValid)
    );
  }

  _getPasswordFieldErrorText(field) {
    if (!this.state.showError[field]) {
      return;
    }

    const {i18n} = this.context;

    if (this.state.newPasswordConfirm !== this.state.newPassword) {
      return i18n.t('changePassword.errorNewPasswordsShouldBeEqual');
    }

    // keeping a space for error label allow us to only underline in red the invalid fields
    return i18n.t('form.passwordShouldMatchSecurityRules');
  }

  render() {
    const {i18n} = this.context;
    const {
      description,
      descriptionKeepHtml,
      errors,
      passwordChanged,
      submitLabel,
      submitting,
      title,
      doContinueToTheApp
    } = this.props;
    const {newPassword, newPasswordConfirm} = this.state;

    if (passwordChanged) {
      return (
        <PublicForm
          buttons={<ContinueOrSignInLink {...{doContinueToTheApp}} />}
          description={i18n.t('changePassword.passwordChanged')}
          descriptionDataTestId="password-changed"
          {...{
            title
          }}
        />
      );
    }

    return (
      <PublicForm
        doSubmit={this._doChangePasswordSubmitForm}
        globalErrorMessage={getErrorMessage(errors, 'changePassword', i18n)}
        submittingLabel={i18n.t('form.submitting')}
        {...{
          description,
          descriptionKeepHtml,
          submitting,
          title
        }}
        buttons={
          submitting ? null : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <StandardRaisedButton label={submitLabel} style={{width: 'auto'}} />
            </div>
          )
        }
      >
        <FormFieldsContainer showRequired={false}>
          {/* Prevent form warnings on Google Chrome */}
          <input
            autoComplete="username"
            name="username"
            type="text"
            style={{
              display: 'none'
            }}
          />
          <PasswordField
            data-test-id="new-password-input"
            autoFocus
            disabled={submitting}
            errorText={this._getPasswordFieldErrorText('newPassword')}
            label={i18n.t('changePassword.newPassword')}
            name="newPassword"
            onBlur={this._updateFieldErrorStatus}
            onChange={this._onFieldChange}
            onFocus={this._updateFieldErrorStatus}
            value={newPassword}
          />
          <PasswordField
            data-test-id="new-password-confirm-input"
            disabled={submitting}
            errorText={this._getPasswordFieldErrorText('newPasswordConfirm')}
            label={i18n.t('changePassword.newPasswordConfirm')}
            name="newPasswordConfirm"
            onBlur={this._updateFieldErrorStatus}
            onChange={this._onFieldChange}
            onFocus={this._updateFieldErrorStatus}
            showRulesCheck
            value={newPasswordConfirm}
          />
        </FormFieldsContainer>
      </PublicForm>
    );
  }
}

ChangePasswordFormComponent.contextTypes = context;

ChangePasswordFormComponent.propTypes = {
  description: PropTypes.string,
  descriptionKeepHtml: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  passwordChanged: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  doChangePasswordSubmit: PropTypes.func.isRequired,
  doContinueToTheApp: PropTypes.func
};

export default ChangePasswordFormComponent;
