import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from './lib/get-insights-map-dispatch-to-props';
import InsightNewCustomersComponent from '../../../../component/content/insights/page/insight-new-customers-component';

const InsightNewCustomersContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.get('featureToggle'),
      (state) => state.get('insight'),
      (state) => state.get('insightBusinessFilter'),
      (state) => state.get('ui')
    ],
    (businesses, featureToggle, insight, insightBusinessFilter, ui) => {
      return {
        accountBusinesses: businesses.toJS(),
        businessesSelection: insight.get('businessesSelection').toJS(),
        classicAggregationDate: insight.get('classicAggregationDate'),
        classicAggregationPeriod: insight.get('classicAggregationPeriod'),
        customAggregationPeriod: insight.get('customAggregationPeriod'),
        customPeriodEndDate: insight.get('customPeriodEndDate'),
        customPeriodStartDate: insight.get('customPeriodStartDate'),
        enableUIWhatsAppChannelFeature: featureToggle.get('enable_ui_whatsapp_channel'),
        insight: insight.get('newCustomers').toJS(),
        insightBusinessFilter: insightBusinessFilter.toJS(),
        isSmallScreen: ui.get('isSmallScreen'),
        periodTabSelected: insight.get('periodTabSelected')
      };
    }
  ),
  getInsightsMapDispatchToProps('newCustomers')
)(InsightNewCustomersComponent);

export default InsightNewCustomersContainer;
