import {fromJS} from 'immutable';
import {createReducer} from 'redux-immutablejs';
import reduceReducers from 'reduce-reducers';
import errorsHandler from '../../../shared/reducer/lib/errors-handler';
import {
  SIGNUP_GET_PENDING_INVITATION,
  SIGNUP_GET_PENDING_INVITATION_FAILURE,
  SIGNUP_GET_PENDING_INVITATION_SUCCESS,
  SIGNUP_SUBMIT,
  SIGNUP_SUBMIT_FAILURE,
  SIGNUP_SUBMIT_SUCCESS
} from '../actions/signup-actions';

export const DEFAULT_STATE = {
  loading: false,
  email: '',
  invitingUserDisplayName: '',
  organizationName: '',
  signedUp: false,
  submitting: false,
  businesses: [],
  errors: []
};

export default reduceReducers(
  createReducer(DEFAULT_STATE, {
    /**
     * Get pending invitation
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNUP_GET_PENDING_INVITATION]: (state) => {
      return state.set('loading', true);
    },

    /**
     * Get pending invitation success
     *
     * @param {Object} state
     * @param {Object} userInvitation
     * @returns {Object} new state
     */
    [SIGNUP_GET_PENDING_INVITATION_SUCCESS]: (state, {userInvitation}) => {
      return state
        .set('businesses', fromJS(userInvitation.businesses.map((business) => business.name)))
        .set('email', userInvitation.invitedEmail)
        .set('invitingUserDisplayName', userInvitation.invitingUser.displayName)
        .set('loading', false)
        .set('organizationName', userInvitation.organization.name);
    },

    /**
     * Get pending invitation failure
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNUP_GET_PENDING_INVITATION_FAILURE]: (state) => {
      return state.set('loading', false);
    },

    /**
     * Submit signup
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNUP_SUBMIT]: (state) => {
      return state.set('errors', fromJS([])).set('submitting', true);
    },

    /**
     * Submit signup success
     *
     * @returns {Object} new state
     */
    [SIGNUP_SUBMIT_SUCCESS]: () => {
      return fromJS(DEFAULT_STATE).set('signedUp', true);
    },

    /**
     * Submit signup failure
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNUP_SUBMIT_FAILURE]: (state) => {
      return state.set('submitting', false);
    }
  }),
  errorsHandler
);
