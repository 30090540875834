import PropTypes from 'prop-types';
import {Avatar, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import kebabCase from 'lodash/kebabCase';
import context from '../../../../../../shared/component/context';
import CardTitle from '../../../common/employee/card-title-component';
import ErrorMessage from '../../../common/error-message-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightWaitingCustomersHeader from './header/insight-waiting-customers-header-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import Paper from '../../../../../../shared/component/paper-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {spacing} from '../../../../../../shared/style/variables';
import {
  darkRed,
  darkYellow,
  graphGreen,
  grey,
  lightGreen,
  red,
  snow,
  white,
  yellow
} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'waitingCustomers';

const BUSINESS_REAL_TIME_PROPS = {
  moreThanSix: {
    margin: spacing.medium,
    backgroundColor: darkRed
  },
  twoHoursToSix: {
    margin: spacing.medium,
    backgroundColor: red
  },
  thirtyToTwoHours: {
    margin: spacing.medium,
    backgroundColor: darkYellow
  },
  tenToThirty: {
    margin: spacing.medium,
    backgroundColor: yellow
  },
  fiveToTen: {
    margin: spacing.medium,
    backgroundColor: lightGreen
  },
  upToFive: {
    margin: spacing.medium,
    backgroundColor: graphGreen
  }
};

const COLUMN_CONTAINER_STYLE = {
  position: 'relative',
  minHeight: '130px'
};

const StyledGrid = withStyles({
  ...BUSINESS_REAL_TIME_PROPS,
  noThread: {
    margin: spacing.medium,
    backgroundColor: white,
    border: `1px solid ${grey}`,
    color: grey
  }
})(({business, classes, durationLabels}) => (
  <Grid container justifyContent="space-around">
    {Object.keys(BUSINESS_REAL_TIME_PROPS).map((key) => {
      const value = business[key];

      return (
        <div
          key={key}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar data-test-id={kebabCase(key)} className={classes[value ? key : 'noThread']}>
            {value > 99 ? '99+' : value}
          </Avatar>
          <span>{durationLabels[key]}</span>
        </div>
      );
    })}
  </Grid>
));

export const ConditionalLoadingIndicator = ({loading}) =>
  loading ? <LoadingIndicator overlay style={{backgroundColor: snow}} /> : null;

ConditionalLoadingIndicator.propTypes = {
  loading: PropTypes.bool
};

const StyledPaper = withStyles({
  notLast: {
    marginBottom: spacing.mlarge
  }
})(({children, classes, isLast}) => (
  <Paper className={isLast ? null : classes.notLast}>{children}</Paper>
));

/**
 * Finally, the component.
 */
class InsightWaitingCustomersComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      insight,
      insightBusinessFilter,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doInsightDownloadCsv
    } = this.props;

    const {waitingCustomersList} = insight;
    const {businessRealTime, loading, loadingCsv} = waitingCustomersList;
    const businessRealTimeLastIndex = businessRealTime.length - 1;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            insightBusinessFilter,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch
          }}
        />

        <PanelContentLayout>
          <InsightWaitingCustomersHeader {...{i18n, loadingCsv, doInsightDownloadCsv}} />
          {waitingCustomersList.failed ? (
            <ErrorMessage style={{height: '100%'}} />
          ) : (
            <div
              data-test-id="business-real-time-list"
              style={{
                ...COLUMN_CONTAINER_STYLE
              }}
            >
              <ConditionalLoadingIndicator {...{loading}} />

              {businessRealTime.map((business, index) => (
                <StyledPaper isLast={businessRealTimeLastIndex === index} key={business.id}>
                  <CardTitle data-test-id="business-name" text={business.businessName} />
                  <StyledGrid
                    durationLabels={i18n.t(
                      `insight.pages.${INSIGHT_PAGE}.businessRealTime.columns`,
                      {returnObjects: true}
                    )}
                    {...{business}}
                  />
                </StyledPaper>
              ))}
            </div>
          )}
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightWaitingCustomersComponent.contextTypes = context;

InsightWaitingCustomersComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  uiWidth: PropTypes.number.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired
};

export default InsightWaitingCustomersComponent;
