import PropTypes from 'prop-types';
import StandardButton from '../../../../../../../shared/component/button/standard-button-component';

const ThreadReleaseButtonComponent = ({i18n, onClick, releasing, style}) => (
  <StandardButton
    data-test-id="release-button"
    variant="outlined"
    {...{
      onClick
    }}
    padding="noPadding"
    disabled={releasing}
    style={{...style}}
  >
    {i18n.t('customerThread.visibleThread.assignment.releaseButton')}
  </StandardButton>
);

ThreadReleaseButtonComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
  releasing: PropTypes.bool.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ThreadReleaseButtonComponent;
