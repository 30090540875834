export const LEGACY_CUSTOMER_THREAD_TRANSFER = 'LEGACY_CUSTOMER_THREAD_TRANSFER';
export const LEGACY_CUSTOMER_THREAD_TRANSFER_EXPIRED = 'LEGACY_CUSTOMER_THREAD_TRANSFER_EXPIRED';
export const LEGACY_CUSTOMER_THREAD_TRANSFER_FAILURE = 'LEGACY_CUSTOMER_THREAD_TRANSFER_FAILURE';
export const LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS = 'LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_TRANSFER_CANCEL_WARNING_MESSAGE =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_CANCEL_WARNING_MESSAGE';

export const LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE';

export const LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS';
export const LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_FAILURE =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_FAILURE';
export const LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_TRANSFER_SELECT_BUSINESS_OUT_OF_SCOPE =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_SELECT_BUSINESS_OUT_OF_SCOPE';

export const LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_NO_PHONE =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_NO_PHONE';
export const LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_OUT_OF_SCOPE =
  'LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_OUT_OF_SCOPE';
