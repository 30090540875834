import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import VerifyComponent from '../component/verify-component';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {VERIFY_EMAIL_CONFIRM} from '../actions/verify-actions';

const VerifyContainer = connect(
  createSelector([(state) => state.get('ui'), (state) => state.get('verify')], (ui, verify) => {
    return {
      language: ui.get('language'),
      ...verify.toJS()
    };
  }),
  {
    ...uiDispatchToProps,
    doVerifyEmailConfirm(email, token) {
      return {
        type: VERIFY_EMAIL_CONFIRM,
        _googleAnalytics: true,
        email,
        token
      };
    }
  }
)(VerifyComponent);

export default VerifyContainer;
