import Radium from 'radium';
import {black, blue} from '../../../../../shared/style/colors';

const LinkComponent = Radium(({label, style, ...props}) => (
  <a
    {...props}
    style={{
      ':hover': {
        color: black
      },
      cursor: 'pointer',
      textDecoration: 'none',
      color: blue,
      ...style
    }}
  >
    {label}
  </a>
));

export default LinkComponent;
