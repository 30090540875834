const getResendMessageOptions = () => {
  return [
    {
      value: '1',
      translation: 'administration.business.pages.channels.gbm.smsDayOne'
    },
    {
      value: '7',
      translation: 'administration.business.pages.channels.gbm.smsDaySeven'
    },
    {
      value: '14',
      translation: 'administration.business.pages.channels.gbm.smsDayFourTeen'
    },
    {
      value: '30',
      translation: 'administration.business.pages.channels.gbm.smsDayThirty'
    },
    {
      value: 'never',
      translation: 'administration.business.pages.channels.gbm.smsNever'
    }
  ];
};

// eslint-disable-next-line import/prefer-default-export
export {getResendMessageOptions};
