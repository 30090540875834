import axios from 'axios';
import {from} from 'rxjs';
import qs from 'qs';
import {TIMEOUT_REQUEST_DEFAULT} from '../data/settings';

/**
 * Get any server accessor.
 *
 * @returns {Object}
 */
export default function anyServerAccessor() {
  const anyserver = axios.create({
    timeout: TIMEOUT_REQUEST_DEFAULT,
    paramsSerializer(params) {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    }
  });

  return {
    getURL(url, params = {}) {
      return from(
        anyserver({
          method: 'get',
          url,
          params
        })
      );
    }
  };
}
