import {useState} from 'react';
import PropTypes from 'prop-types';
import AdministrationLeftMenuBlockLayout from './administration-left-menu-block-layout-component';
import AdministrationLeftMenuBlockTitle from './administration-left-menu-block-title-component';
import AdministrationLeftMenuItem from './administration-left-menu-item-component';
import AdministrationLeftMenuShowAllItems from './administration-left-menu-show-all-items-component';
import getCountriesByLanguage from '../../../../../../shared/lib/countries-helper';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import {mediumGrey} from '../../../../../../shared/style/colors';
import {ADMIN_AREA_LEFT_MENU_LIMIT} from '../../../../data/settings';

const AdministrationLeftMenuCountriesComponent = ({i18n, language, organizationCountries}) => {
  const [showAllCountries, setShowAllCountries] = useState(false);
  const doShowAllCountries = () => setShowAllCountries(true);

  const getCountriesToRender = (items) => {
    const countriesTranslations = getCountriesByLanguage(language);

    const itemsToRender = items
      .map(({code}) => ({countryCode: code, countryName: countriesTranslations[code]}))
      .sort((itemA, itemB) => (itemA.countryName < itemB.countryName ? -1 : 1));

    return showAllCountries ? itemsToRender : itemsToRender.slice(0, ADMIN_AREA_LEFT_MENU_LIMIT);
  };

  const renderCountriesList = () => {
    const {items, loading} = organizationCountries;
    if (loading) {
      return <LoadingIndicator />;
    }

    const countriesToRender = getCountriesToRender(items);
    const showButtonAllCountries = !showAllCountries && items.length > ADMIN_AREA_LEFT_MENU_LIMIT;

    return (
      <AdministrationLeftMenuBlockLayout dataTestId="left-menu-countries-list">
        {countriesToRender.map(({countryCode, countryName}) => (
          <AdministrationLeftMenuItem
            href={`${OLD_WEBAPP_URL}/a#/administration/country/${countryCode}/canned-responses`}
            key={countryCode}
            label={countryName}
            showLabelAdmin
            {...{i18n}}
          />
        ))}

        {showButtonAllCountries ? (
          <AdministrationLeftMenuShowAllItems
            dataTestId="show-all-countries"
            doShowMore={doShowAllCountries}
            expandItems
            itemUrl={`${OLD_WEBAPP_URL}/a#/administration/organization-stores`}
            itemLabel={i18n.t('administration.leftPanel.businesses.showAll', {
              itemsAmount: items.length
            })}
            {...{i18n}}
          />
        ) : null}
      </AdministrationLeftMenuBlockLayout>
    );
  };

  return (
    <div style={{color: mediumGrey, padding: '20px 0 0 20px'}}>
      <AdministrationLeftMenuBlockTitle
        iconName="COUNTRY"
        title={i18n.t('administration.leftPanel.countries')}
      />
      {renderCountriesList()}
    </div>
  );
};

AdministrationLeftMenuCountriesComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  language: PropTypes.string.isRequired,
  organizationCountries: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationLeftMenuCountriesComponent;
