const SERVER_HIGHLIGHT_REGEX = /<\/?em>/g;

/**
 * Remove highlight from a text.
 * @param {String} text
 * @returns {String}
 */
const cleanHighlight = (text) => {
  // see @AWI-5302
  if ([undefined, null].includes(text)) {
    return '';
  }

  return text.replace(SERVER_HIGHLIGHT_REGEX, '');
};

export {cleanHighlight, SERVER_HIGHLIGHT_REGEX};
