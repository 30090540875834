export const CUSTOMER_CONVERSATION_INTERNAL_FILE_REMOVE_BUTTON_CLICK =
  'CUSTOMER_CONVERSATION_INTERNAL_FILE_REMOVE_BUTTON_CLICK';

export const CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD =
  'CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD';
export const CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_FAILURE =
  'CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_FAILURE';
export const CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_SUCCESS =
  'CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_SUCCESS';

export const CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_PERSIST =
  'CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_PERSIST';
export const CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_CHANGED =
  'CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_CHANGED';

export const CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE =
  'CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE';
export const CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_BUTTON_CLICK =
  'CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_BUTTON_CLICK';
export const CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_FAILURE =
  'CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_FAILURE';
export const CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_SUCCESS =
  'CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_SUCCESS';

export const CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY =
  'CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY';
export const CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY_BUTTON_CLICK =
  'CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY_BUTTON_CLICK';

export const CUSTOMER_THREAD_INVISIBLE_LOAD = 'CUSTOMER_THREAD_INVISIBLE_LOAD';
export const CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED = 'CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED';
export const CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE = 'CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE';
export const CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS = 'CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS';

// Shared

export const CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION =
  'CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION';

export const CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION_BUTTON_CLICK =
  'CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION_BUTTON_CLICK';

// Legacy

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_REMOVE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_REMOVE';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD = 'LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_PERSIST =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_PERSIST';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_UPDATE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_UPDATE';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_FAILURE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_RETRY =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_RETRY';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_UNSELECT_COLLEAGUE_TO_MENTION =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_UNSELECT_COLLEAGUE_TO_MENTION';
