import context from '../context';
import {SIGN_IN_URL} from '../../data/route';

const SignInLink = (_, {i18n}) => {
  return (
    <a data-test-id="sign-in-link" href={SIGN_IN_URL}>
      {i18n.t('public.signIn')}
    </a>
  );
};

SignInLink.contextTypes = context;

export default SignInLink;
