const findConversationIndexByConversationId = (state, conversationId) =>
  state.get('items').findIndex((item) => item.get('id') === conversationId);

const findConversationIndexByBusinessId = (state, businessId) =>
  state.get('items').findIndex((item) => item.getIn(['business', 'id']) === businessId);

const getIncrementCounter =
  (counterKey) =>
  (state, {payload: {conversation}}) => {
    const conversationItemIndex = findConversationIndexByConversationId(state, conversation.id);
    const indexesThatShouldByPassUpdate = [-1, state.get('selectedItemOrder')];

    if (indexesThatShouldByPassUpdate.includes(conversationItemIndex)) {
      return state;
    }

    return state.updateIn(['items', conversationItemIndex, counterKey], (counter) => counter + 1);
  };

export {
  findConversationIndexByBusinessId,
  findConversationIndexByConversationId,
  getIncrementCounter
};
