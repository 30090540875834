import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {fontSize} from '../../../../shared/style/variables';

class ContentAreaComponent extends PureComponent {
  render() {
    const {children, style} = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: fontSize.xxxxxlarge,
          ...style
        }}
      >
        {children}
      </div>
    );
  }
}

ContentAreaComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default ContentAreaComponent;
