import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ColleagueMentioned from './colleague-mentionned-component';
import ContentModerated from './content-moderated-component';
import ContentText from './content-text-component';
import MessageAttachment from './message-attachment-component';
import {
  black,
  blue,
  darkGrey,
  darkSnow,
  lightSlate,
  red,
  snow,
  veryDarkGrey,
  veryLightGreen,
  white
} from '../../../../../../shared/style/colors';
import {fontSize, spacing} from '../../../../../../shared/style/variables';
import {CONVERSATION_MESSAGE_FLOW_STATUS} from '../../../../data/thread/message';
import {getColleagueBubbleStyles, PADDING_MAPPING} from '../lib/message-helper';

const LegacyMessageComponent = ({
  _sendAction,
  attachment,
  date,
  doSendMessageRetry,
  doThreadFilePreviewOpen,
  hasMessageAfter,
  i18n,
  mentionedUserName,
  moderated,
  presentationType,
  selected,
  status = CONVERSATION_MESSAGE_FLOW_STATUS.LOADED,
  text,
  threadType
}) => {
  // Handle padding variations based of bubble height
  // @see https://stackoverflow.com/questions/49058890/how-to-get-a-react-components-size-height-width-before-render
  const BUBBLE_MIN_HEIGHT = 30;
  const contentContainerRef = useRef();

  const BUBBLE_LINE_HEIGHT = '20px';
  const isInError = status === CONVERSATION_MESSAGE_FLOW_STATUS.ERROR;
  const isInvisibleThread = ['InternalIncoming', 'InternalOutgoing'].includes(presentationType);
  const isColleagueThread = threadType === 'colleague';

  const getBubbleStyle = () => {
    if (isInError) {
      return {
        backgroundColor: white,
        border: `1px solid ${red}`,
        color: red,
        padding: PADDING_MAPPING.withBorder
      };
    }

    if (moderated) {
      return {
        backgroundColor: snow,
        border: `1px solid ${snow}`,
        color: lightSlate,
        padding: PADDING_MAPPING.withBorder
      };
    }

    if (isColleagueThread) {
      return getColleagueBubbleStyles(presentationType);
    }

    switch (presentationType) {
      case 'AutomaticOutgoing':
      case 'ColleagueOutgoing':
        return {
          backgroundColor: blue,
          color: white,
          padding: PADDING_MAPPING.noBorder
        };
      case 'InternalIncoming':
        return {
          backgroundColor: white,
          color: black,
          padding: PADDING_MAPPING.withBorder
        };
      case 'InternalOutgoing':
        return {
          backgroundColor: veryLightGreen,
          color: black,
          padding: PADDING_MAPPING.withBorder
        };
      case 'MineOutgoing':
        return {
          backgroundColor: blue,
          color: white,
          padding: PADDING_MAPPING.noBorder
        };
      default:
        break;
    }

    return {
      backgroundColor: darkSnow,
      color: black,
      padding: PADDING_MAPPING.noBorder
    };
  };

  const bubbleStyle = getBubbleStyle();

  const renderSendingState = () => {
    if (isInError) {
      const onClickError = () => doSendMessageRetry(_sendAction);

      return (
        <span
          data-test-id="send-message-retry"
          onClick={onClickError}
          tabIndex={-1}
          style={{
            color: red,
            cursor: 'pointer',
            display: 'block',
            fontSize: fontSize.small,
            padding: '5px'
          }}
        >
          {i18n.t('thread.messages.messageHasNotBeenSent')}
        </span>
      );
    }

    if (status === CONVERSATION_MESSAGE_FLOW_STATUS.SENDING) {
      return (
        <span
          data-test-id="sending-status-indicator"
          style={{
            color: lightSlate,
            display: 'block',
            fontSize: fontSize.xsmall,
            paddingLeft: spacing.medium,
            paddingRight: spacing.medium,
            paddingTop: hasMessageAfter ? 0 : '3px'
          }}
        >
          {i18n.t('common.sending')}
        </span>
      );
    }

    return null;
  };

  const getTimeTextColorForGlobalThread = () => {
    const isVisibleThread = threadType === 'visible';

    return isVisibleThread ? white : darkGrey;
  };

  const getTimeTextColorForInvisibleThread = () => {
    const isOutgoing = presentationType.includes('Outgoing');
    const isInternalIncoming = presentationType.includes('InternalIncoming');

    return (isInvisibleThread && isInternalIncoming) || !isOutgoing
      ? veryDarkGrey
      : getTimeTextColorForGlobalThread();
  };

  const renderTime = () => {
    const showLabel = [
      CONVERSATION_MESSAGE_FLOW_STATUS.LOADED,
      CONVERSATION_MESSAGE_FLOW_STATUS.SENT
    ].includes(status);

    return (
      <span
        data-test-id="time"
        style={{
          color: getTimeTextColorForInvisibleThread(),
          float: 'right',
          fontSize: fontSize.xsmall,
          minHeight: BUBBLE_LINE_HEIGHT,
          minWidth: '28px',
          marginLeft: spacing.medium,
          position: 'relative',
          right: '-3px',
          top: '6px' // do not touch unless validate new value with Designer
        }}
      >
        {showLabel ? moment(date).format('LT') : null}
      </span>
    );
  };

  const renderAttachment = () => {
    const wrapperBubbleWidth = contentContainerRef.current
      ? contentContainerRef.current.offsetWidth
      : null;

    return (
      <MessageAttachment
        containerStyle={text ? {marginBottom: spacing.medium} : null}
        invisibleThread={isInvisibleThread}
        linkStyle={{
          color: bubbleStyle.color
        }}
        {...attachment}
        {...{wrapperBubbleWidth, doThreadFilePreviewOpen}}
      />
    );
  };

  const renderText = () => (
    <React.Fragment>
      {mentionedUserName ? <span> </span> : null}
      <ContentText
        textColor={bubbleStyle.color}
        {...{
          selected,
          text
        }}
      />
    </React.Fragment>
  );

  return (
    <div>
      {/* useful only for test */}
      <span data-test-id="sending-status" data-sending-status={status} />

      <div
        ref={contentContainerRef}
        title={moment(date).format('LLL')}
        style={{
          borderRadius: '10px',
          display: 'inline-block',
          fontSize: fontSize.large,
          fontWeight: 'regular',
          lineHeight: BUBBLE_LINE_HEIGHT,
          marginBottom: hasMessageAfter ? '5px' : 0,
          maxWidth: '100%',
          minHeight: `${BUBBLE_MIN_HEIGHT}px`,
          textAlign: 'left',
          width: attachment ? '300px' : 'auto',
          wordBreak: 'break-word',
          wordWrap: 'break-word',
          ...bubbleStyle
        }}
      >
        {moderated ? (
          <ContentModerated {...{i18n}} />
        ) : (
          <React.Fragment>
            <span data-test-id="item-label">
              {attachment ? renderAttachment() : null}
              {mentionedUserName ? <ColleagueMentioned {...{mentionedUserName}} /> : null}
              {text ? renderText() : null}
            </span>
            {isInError ? null : renderTime()}
          </React.Fragment>
        )}
      </div>

      {renderSendingState()}
    </div>
  );
};

LegacyMessageComponent.propTypes = {
  _sendAction: PropTypes.objectOf(PropTypes.any),
  attachment: PropTypes.objectOf(PropTypes.any),
  date: PropTypes.string,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  hasMessageAfter: PropTypes.bool,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  mentionedUserName: PropTypes.string,
  moderated: PropTypes.bool,
  presentationType: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  status: PropTypes.string,
  text: PropTypes.string.isRequired,
  threadType: PropTypes.string
};

export default LegacyMessageComponent;
