import {merge, of} from 'rxjs';
import {catchError, ignoreElements, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import moment from 'moment-timezone';
import logout from '../../../../shared/lib/logout';
import reportEmployeeUsage from '../../../../shared/epic/lib/report-employee-usage';
import {SIGN_IN_URL} from '../../../../shared/data/route';
import {
  APP_LOGOUT,
  APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE,
  APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS,
  APP_LOGOUT_FAILURE,
  APP_LOGOUT_SUCCESS
} from '../../actions/app-actions';

/**
 * Finally, the epic.
 */
const HeaderDropDownMenuEpic =
  ({graphql}) =>
  (action$) => {
    const employeeLogout = action$.pipe(
      ofType(
        APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE, // activity log failure is not a reason to block logout,
        APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS // but we must wait any server response to continue the logout process
      ),
      mergeMap(() => {
        return graphql('employee-logout-mutation').pipe(
          map(() => {
            return {
              type: APP_LOGOUT_SUCCESS
            };
          }),
          catchError((error) => {
            return of({
              type: APP_LOGOUT_FAILURE,
              error
            });
          })
        );
      })
    );

    const redirectToLogin = action$.pipe(
      ofType(APP_LOGOUT_FAILURE, APP_LOGOUT_SUCCESS),
      map(() => {
        window.location = SIGN_IN_URL;

        return null;
      }),
      ignoreElements()
    );

    const reportLogout = action$.pipe(
      ofType(APP_LOGOUT),
      mergeMap(() => {
        // Don't move from a later action !!! It could lead into a wrong state in reports, or lock user into an infinite loop of invalid token
        logout();

        return reportEmployeeUsage(
          graphql,
          {
            eventType: 'Logout',
            timezone: moment.tz.guess()
          },
          null,
          () => {
            return {
              type: APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS
            };
          },
          APP_LOGOUT_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE
        );
      })
    );

    return merge(employeeLogout, redirectToLogin, reportLogout);
  };

export default HeaderDropDownMenuEpic;
