const getBusinessLocation = ({businessLocation}) => {
  const fakeValue = localStorage.getItem('instaplyDesktop.fake-business-location');

  if (fakeValue) {
    return JSON.parse(fakeValue);
  }

  return businessLocation;
};

const mapBusinessLocationFormToGraphQL = (businessId, fields) => {
  return {
    automaticResponseMessage: fields.automaticResponse,
    businessId,
    locale: fields.locale,
    placeId: fields.placeId,
    resendAfterMinutes: parseInt(fields.resendMessage, 10)
  };
};

export {getBusinessLocation, mapBusinessLocationFormToGraphQL};
