import {fromJS} from 'immutable';
import {getDateTimeLegacy} from '../../lib/date-time-helper';

/**
 * Add a redirection message at the end of state's messages.
 * @param {Object} state
 * @param {String} businessName
 * @returns {Object} new state
 */
export default (state, businessName) =>
  fromJS(state).update('messages', (messages) =>
    messages.push(
      fromJS({
        businessName,
        automatic: true,
        date: getDateTimeLegacy(),
        isFirstOfSegment: true,
        type: 'redirection'
      })
    )
  );
