import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {List} from 'material-ui/List';
import context from '../../../../../../shared/component/context';
import EnhancedListItem from '../../../common/enhanced-list-item-component';
import EnhancedPopover from '../../../common/enhanced-popover-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import {fontSize, popupHeight, popupWidth} from '../../../../../../shared/style/variables';
import {lightSmoke, lightSnow} from '../../../../../../shared/style/colors';

class BusinessFilterPopupComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doRenderItem = this._doRenderItem.bind(this);
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/destructuring-assignment */
    if (!prevProps.isVisibleBusinessFilterPopup && this.props.isVisibleBusinessFilterPopup) {
      setTimeout(() => {
        if (this._input) {
          // test of input availability required, see @AWI-5300
          this._input.focus();
        }
      }, 0);
    }
    /* eslint-enable react/destructuring-assignment */
  }

  /**
   * Render a clickable item.
   *
   * @param {String} key
   * @param {String} primaryText
   * @param {String} businessScope
   * @param {String} businessName
   * @param {String} businessId
   * @returns {Object}
   * @private
   */
  _doRenderItem({key, primaryText, businessScope, businessName, businessId}) {
    const {filterBusinessId, filterBusinessScope, doLoadInboxThreadsListFilteredByBusiness} =
      this.props;
    const isCurrent =
      filterBusinessScope === businessScope &&
      (filterBusinessScope !== 'business' || filterBusinessId === businessId);

    return (
      <EnhancedListItem
        dataTestId="name"
        isCurrent={isCurrent}
        key={key}
        onClick={() =>
          doLoadInboxThreadsListFilteredByBusiness(businessId, businessName, businessScope)
        }
        primaryText={primaryText}
      />
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      anchorEl,
      filterItems,
      filterLoading,
      filterText,
      isAllowedToSeeCustomerThreadsOfAllBusinesses,
      isVisibleBusinessFilterPopup,
      myBusinessLabel,
      doCustomerInboxBusinessFilterClose,
      doCustomerInboxBusinessFilterSearch
    } = this.props;

    return (
      <EnhancedPopover
        dataTestId="inbox-filter-threads-by-business-popup"
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'middle', vertical: 'bottom'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
        open={isVisibleBusinessFilterPopup}
        onRequestClose={doCustomerInboxBusinessFilterClose}
        style={{
          width: popupWidth.small,
          maxWidth: popupWidth.small,
          height: popupHeight.small,
          maxHeight: popupHeight.small
        }}
      >
        <div
          style={{
            padding: '10px',
            borderBottom: `1px solid ${lightSmoke}`
          }}
        >
          <List data-test-id="inbox-business-filter-combined-result">
            {this._doRenderItem({
              businessScope: 'mine',
              key: 0,
              primaryText: myBusinessLabel
            })}

            {isAllowedToSeeCustomerThreadsOfAllBusinesses
              ? this._doRenderItem({
                  businessScope: 'all',
                  key: 1,
                  primaryText: i18n.t('customerInbox.header.businessFilter.allBusinesses')
                })
              : null}
          </List>
        </div>

        <div style={{padding: '10px'}}>
          <input
            data-test-id="inbox-business-filter-search-input"
            onChange={doCustomerInboxBusinessFilterSearch}
            placeholder={i18n.t('customerInbox.header.businessFilter.typeABusinessName')}
            ref={(itself) => {
              this._input = itself;

              return this._input;
            }}
            value={filterText}
            style={{
              boxSizing: 'border-box',
              width: '100%',
              padding: '7px 32px 7px 12px',
              outline: 'none',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: lightSnow,
              fontSize: fontSize.medium
            }}
          />

          {filterLoading ? (
            <LoadingIndicator />
          ) : (
            <List
              data-test-id="inbox-business-filter-result"
              style={{
                maxHeight: '200px',
                overflowX: 'hidden',
                overflowY: filterItems.length > 4 ? 'scroll' : 'auto',
                paddingTop: 0,
                paddingBottom: 0
              }}
            >
              {filterItems.length ? (
                filterItems.map((props, i) =>
                  this._doRenderItem({
                    businessId: props.businessId,
                    businessName: props.businessName,
                    businessScope: 'business',
                    key: i,
                    primaryText: props.businessName
                  })
                )
              ) : (
                <div
                  data-test-id="inbox-business-filter-no-result"
                  style={{
                    padding: '10px',
                    textAlign: 'center'
                  }}
                >
                  {i18n.t('business.noBusinessFound')}
                </div>
              )}
            </List>
          )}
        </div>
      </EnhancedPopover>
    );
  }
}

BusinessFilterPopupComponent.contextTypes = context;

BusinessFilterPopupComponent.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  filterBusinessId: PropTypes.number,
  filterBusinessScope: PropTypes.string.isRequired,
  filterItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterLoading: PropTypes.bool.isRequired,
  filterText: PropTypes.string.isRequired,
  isAllowedToSeeCustomerThreadsOfAllBusinesses: PropTypes.bool.isRequired,
  isVisibleBusinessFilterPopup: PropTypes.bool.isRequired,
  myBusinessLabel: PropTypes.string.isRequired,
  doCustomerInboxBusinessFilterClose: PropTypes.func.isRequired,
  doCustomerInboxBusinessFilterSearch: PropTypes.func.isRequired,
  doLoadInboxThreadsListFilteredByBusiness: PropTypes.func.isRequired
};

export default BusinessFilterPopupComponent;
