import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import AddIcon from 'material-ui/svg-icons/content/add';
import RaisedButton from 'material-ui/RaisedButton';
import isEmail from 'validator/lib/isEmail';
import context from '../../../../../shared/component/context';
import {BUTTON_LABEL_STYLE} from '../../../../../shared/style/theme-v0';
import {readLocalFile} from '../../../../../shared/lib/file-upload-helper';
import trimSplit from '../../../../../shared/lib/trim-split';
import BusinessesSelection from './global-invitation-businesses-selection-component';
import ColleaguesSelection from './global-invitation-colleagues-selection-component';
import BusinessSelectionPopup from '../../common/business-selection-popup-component';
import {BottomButtonsContainer} from '../../../../../shared/component/fullscreen-layout-component';
import FullscreenOverlay from '../../common/fullscreen-overlay-component';
import KeyboardClosableComponent from '../../../../../shared/component/keyboard-closable-component';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import EnhancedTextField from '../../../../../shared/component/form/enhanced-text-field-component';
import StandardRaisedButton, {
  BUTTON_STYLE
} from '../../../../../shared/component/button/standard-raised-button-component';
import {fontSize, fontWeight, lineHeight} from '../../../../../shared/style/variables';
import {
  blue,
  lightSlate,
  lightSmoke,
  red,
  snow,
  white95Opacity
} from '../../../../../shared/style/colors';

const SelectionContainer = ({children, addElements, dataTestId, label}) => (
  <div
    data-test-id={dataTestId}
    style={{
      borderBottom: `1px solid ${lightSmoke}`
    }}
  >
    <h3
      style={{
        position: 'relative',
        margin: '30px 0',
        color: lightSlate,
        fontSize: fontSize.medium,
        fontWeight: fontWeight.normal,
        lineHeight: lineHeight.xxlarge
      }}
    >
      {label}
      {addElements}
    </h3>
    {children}
  </div>
);

SelectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  addElements: PropTypes.node,
  dataTestId: PropTypes.string,
  label: PropTypes.string
};

const isColleagueInputValid = (text) => {
  const values = trimSplit(text);

  return values.length && values.reduce((isEmails, value) => isEmails && isEmail(value), true);
};

/**
 * The component itself.
 */
class ColleagueInvitationPopupComponent extends KeyboardClosableComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      submitButtonPosition: false,
      uploadedFilename: null,
      uploadingFile: false,
      uploadingFileInError: false
    };

    this._doAddColleagueInvitationPopupColleagues =
      this._doAddColleagueInvitationPopupColleagues.bind(this);
    this._doRemoveColleagueInvitationPopupSelectedBusiness =
      this._doRemoveColleagueInvitationPopupSelectedBusiness.bind(this);
    this._doRemoveColleagueInvitationPopupSelectedColleague =
      this._doRemoveColleagueInvitationPopupSelectedColleague.bind(this);
    this._onClickToAddColleagueInvitationPopupColleagues =
      this._onClickToAddColleagueInvitationPopupColleagues.bind(this);
    this._onClickUploadLink = this._onClickUploadLink.bind(this);
    this._onFileChange = this._onFileChange.bind(this);
    this._onInputKeyPress = this._onInputKeyPress.bind(this);
    this._openBusinessesPopup = this._openBusinessesPopup.bind(this);
    this.csvTxtFile = React.createRef();
  }

  componentDidMount() {
    this.props.doSetColleagueInvitationPopupBusinessesSelection(this.props.userBusinesses);
  }

  componentDidUpdate(prevProps) {
    // Focus text input
    if (
      (prevProps.colleagueInvitation.validating && !this.props.colleagueInvitation.validating) ||
      (prevProps.colleagueInvitation.popupBusinessSelect.open &&
        !this.props.colleagueInvitation.popupBusinessSelect.open)
    ) {
      this._textInput.focus();
    }

    // Guess submit button position
    this._updateSubmitButtonPosition();
  }

  _updateSubmitButtonPosition = () => {
    const submit = document.getElementById('colleague-invitation-popup-submit');
    if (submit) {
      const popup = document.getElementById('colleague-invitation-popup-inner');
      const title = document.getElementById('colleague-invitation-popup-title');
      const content = document.getElementById('colleague-invitation-popup-content');

      this.setState({
        submitButtonPosition:
          title.clientHeight + content.clientHeight + submit.clientHeight > popup.clientHeight
            ? 'fixed'
            : 'static'
      });
    }
  };

  _doAddColleagueInvitationPopupColleagues(text) {
    this.setState({
      uploadingFileInError: false,
      uploadedFilename: null
    });

    this.props.doAddColleagueInvitationPopupColleagues(text);
  }

  _doRemoveColleagueInvitationPopupSelectedBusiness(businessId) {
    this.props.doRemoveColleagueInvitationPopupSelectedBusiness(businessId);
    this._textInput.focus();
  }

  _doRemoveColleagueInvitationPopupSelectedColleague(email) {
    this.props.doRemoveColleagueInvitationPopupSelectedColleague(email);
    this._textInput.focus();
  }

  _onClickToAddColleagueInvitationPopupColleagues() {
    if (
      isColleagueInputValid(this.props.colleagueInvitation.inputText) &&
      !this.props.colleagueInvitation.validating
    ) {
      this._doAddColleagueInvitationPopupColleagues(this.props.colleagueInvitation.inputText);
    }
  }

  _onClickUploadLink() {
    this.csvTxtFile.click();
  }

  _onFileChange() {
    const file = this.csvTxtFile.files[0];
    const self = this;

    if (file && file.type) {
      this.setState({
        uploadedFilename: file.name,
        uploadingFile: true,
        uploadingFileInError: false
      });

      readLocalFile(
        file,
        {
          onload: (event) => {
            const emails = event.target.result.replace(/[;\t\n\r]/g, ',');

            if (!isColleagueInputValid(emails)) {
              self.setState({
                uploadingFileInError: true
              });

              return;
            }

            self._doAddColleagueInvitationPopupColleagues(emails);
          },
          onloadend: () => {
            self.setState({
              uploadingFile: false
            });

            self.csvTxtFile.value = '';
          }
        },
        'Text'
      );
    }
  }

  _onInputKeyPress(event) {
    if (keycode('ENTER') === event.charCode) {
      event.preventDefault();

      this._onClickToAddColleagueInvitationPopupColleagues();
    }
  }

  _openBusinessesPopup(event) {
    this.setState(
      {
        anchorEl: event.currentTarget
      },
      () => {
        this.props.doColleagueInvitationPopupOpenBusinessesPopup();
      }
    );
  }

  render() {
    const {i18n} = this.context;
    const {anchorEl, submitButtonPosition, uploadedFilename, uploadingFile, uploadingFileInError} =
      this.state;
    const {
      colleagueInvitation,
      organizationPicture,
      doCloseColleagueInvitationPopup,
      doCloseColleagueInvitationPopupBusinessesPopup,
      doColleagueInvitationPopupBusinessesPopupSelectBusiness,
      doColleagueInvitationPopupOpenInviteMore,
      doColleagueInvitationPopupSearchBusiness,
      doSubmitColleagueInvitationPopup,
      doUpdateColleagueInvitationPopupInput
    } = this.props;

    const {businessesSelection, colleaguesSelection, inputText, sentTo} = colleagueInvitation;

    const validColleagues = colleaguesSelection.filter(
      (colleague) => colleague.isOrganizationDomain
    );
    const fullscreenStyle = {width: '600px'};

    /* Screen 2 : the confirmation */
    if (sentTo.length) {
      return (
        <FullscreenOverlay
          dataTestId="colleague-invitation-popup"
          innerStyle={fullscreenStyle}
          title={i18n.t('colleagueInvitation.popup.titleSent')}
          onClickClose={doCloseColleagueInvitationPopup}
        >
          <SelectionContainer dataTestId="colleagues-selection">
            <ColleaguesSelection
              colleaguesSelection={validColleagues.map((colleague) =>
                Object.assign(colleague, {checked: true})
              )}
              organizationPicture={organizationPicture}
            />
          </SelectionContainer>

          <BottomButtonsContainer style={{padding: '30px 25px 25px 25px'}}>
            <RaisedButton
              data-test-id="invite-more"
              buttonStyle={BUTTON_STYLE}
              label={i18n.t('colleagueInvitation.popup.inviteMoreButton')}
              labelStyle={BUTTON_LABEL_STYLE}
              onClick={doColleagueInvitationPopupOpenInviteMore}
              overlayStyle={BUTTON_STYLE}
              style={BUTTON_STYLE}
            />
            <RaisedButton
              data-test-id="close-big-button"
              buttonStyle={BUTTON_STYLE}
              label={i18n.t('common.close')}
              labelStyle={BUTTON_LABEL_STYLE}
              primary
              onClick={doCloseColleagueInvitationPopup}
              style={{
                ...BUTTON_STYLE,
                marginLeft: '20px'
              }}
            />
          </BottomButtonsContainer>
        </FullscreenOverlay>
      );
    }

    /* Screen 1 : the form */
    const isInputTextValid = isColleagueInputValid(inputText);

    return (
      <FullscreenOverlay
        dataTestId="colleague-invitation-popup"
        innerStyle={fullscreenStyle}
        title={i18n.t('colleagueInvitation.popup.titleSend')}
        onClickClose={doCloseColleagueInvitationPopup}
      >
        <span id="colleague-invitation-popup-content">
          <span style={{position: 'relative'}}>
            <EnhancedTextField
              data-test-id="input"
              autoFocus
              disabled={colleagueInvitation.validating}
              id="invite-text-field"
              inputRef={(itself) => {
                this._textInput = itself;

                return this._textInput;
              }}
              onChange={doUpdateColleagueInvitationPopupInput}
              onKeyPress={this._onInputKeyPress}
              placeholder={i18n.t('colleagueInvitation.popup.colleaguesInputPlaceholder')}
              underlineShow={false}
              value={inputText}
              style={{
                background: snow
              }}
              inputStyle={{
                width: '536px',
                padding: '0 50px 0 12px'
              }}
            />
            <AddIcon
              data-test-id="add-colleague-button"
              color={isInputTextValid ? blue : lightSmoke}
              disabled={!isInputTextValid}
              onClick={this._onClickToAddColleagueInvitationPopupColleagues}
              style={{
                [isInputTextValid ? 'cursor' : undefined]: 'pointer',
                position: 'absolute',
                top: '14px',
                right: '10px'
              }}
            />
          </span>

          {uploadingFile ? null : (
            <div
              style={{
                marginTop: '8px',
                color: blue
              }}
            >
              {i18n.t('colleagueInvitation.popup.uploadFile.csvOrTxtFile')}
              <span
                tabIndex={-1}
                onClick={this._onClickUploadLink}
                style={{
                  marginLeft: '5px',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                {i18n.t('colleagueInvitation.popup.uploadFile.doUploadLink')}
              </span>
              <input
                data-test-id="csv-txt-file-input"
                accept=".csv,.txt"
                onChange={this._onFileChange}
                ref={(csvTxtFileElement) => {
                  this.csvTxtFile = csvTxtFileElement;

                  return this.csvTxtFile;
                }}
                style={{display: 'none'}}
                type="file"
              />
              <span
                style={{
                  marginLeft: '5px',
                  fontSize: fontSize.small,
                  color: lightSlate
                }}
              >
                {i18n.t('colleagueInvitation.popup.uploadFile.description')}
              </span>
            </div>
          )}

          {uploadingFileInError ? (
            <div
              data-test-id="csv-txt-file-upload-error"
              style={{
                marginTop: '10px',
                color: red
              }}
            >
              {i18n.t('colleagueInvitation.popup.uploadFile.error', {filename: uploadedFilename})}
            </div>
          ) : null}

          {colleagueInvitation.validating || uploadingFile ? <LoadingIndicator /> : null}

          <SelectionContainer
            dataTestId="colleagues-selection"
            label={i18n.t('colleagueInvitation.popup.colleagues.selection')}
          >
            <ColleaguesSelection
              colleaguesSelection={colleaguesSelection}
              organizationPicture={organizationPicture}
              doRemoveColleagueInvitationPopupSelectedColleague={
                this._doRemoveColleagueInvitationPopupSelectedColleague
              }
            />
          </SelectionContainer>

          <SelectionContainer
            dataTestId="businesses-selection"
            label={i18n.t('colleagueInvitation.popup.newBusinesses', {
              businessesCount: businessesSelection.length
            })}
            addElements={
              <span>
                <AddCircleOutline
                  data-test-id="add-business"
                  onClick={this._openBusinessesPopup}
                  color={blue}
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    top: '3px',
                    right: '2px'
                  }}
                />
                <BusinessSelectionPopup
                  dataTestId="businesses-selection-popup"
                  anchorEl={anchorEl}
                  filteredBusinesses={colleagueInvitation.popupBusinessSelect.filteredBusinesses}
                  fullProgressMessage={i18n.t('common.loading')}
                  initializing={colleagueInvitation.popupBusinessSelect.initializing}
                  open={colleagueInvitation.popupBusinessSelect.open}
                  organizationPicture={organizationPicture}
                  searchText={colleagueInvitation.popupBusinessSelect.searchText}
                  showFullProgress={colleagueInvitation.popupBusinessSelect.initializing}
                  title={i18n.t('colleagueInvitation.popup.businesses.addPopup.title')}
                  doSearchBusinesses={doColleagueInvitationPopupSearchBusiness}
                  onRequestClose={doCloseColleagueInvitationPopupBusinessesPopup}
                  onSelectBusiness={doColleagueInvitationPopupBusinessesPopupSelectBusiness}
                />
              </span>
            }
          >
            <BusinessesSelection
              businessesSelection={businessesSelection}
              organizationPicture={organizationPicture}
              doRemoveColleagueInvitationPopupSelectedBusiness={
                this._doRemoveColleagueInvitationPopupSelectedBusiness
              }
            />
          </SelectionContainer>
        </span>

        <BottomButtonsContainer
          id="colleague-invitation-popup-submit"
          style={{
            position: submitButtonPosition,
            bottom: 0,
            left: 0,
            right: 0,
            padding: '30px 25px 25px 25px',
            backgroundColor: white95Opacity
          }}
        >
          {colleagueInvitation.sending ? (
            <span>
              <LoadingIndicator /> {i18n.t('common.sending')}
            </span>
          ) : (
            <StandardRaisedButton
              disabled={!businessesSelection.length || !validColleagues.length}
              label={i18n.t('colleagueInvitation.popup.submitButton')}
              onClick={doSubmitColleagueInvitationPopup}
              style={{
                width: '200px'
              }}
            />
          )}
        </BottomButtonsContainer>
      </FullscreenOverlay>
    );
  }
}

ColleagueInvitationPopupComponent.contextTypes = context;

ColleagueInvitationPopupComponent.propTypes = {
  colleagueInvitation: PropTypes.objectOf(PropTypes.any).isRequired,
  organizationPicture: PropTypes.string,
  userBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  doAddColleagueInvitationPopupColleagues: PropTypes.func.isRequired,
  doCloseColleagueInvitationPopup: PropTypes.func.isRequired,
  doCloseColleagueInvitationPopupBusinessesPopup: PropTypes.func.isRequired,
  doColleagueInvitationPopupBusinessesPopupSelectBusiness: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpenBusinessesPopup: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpenInviteMore: PropTypes.func.isRequired,
  doColleagueInvitationPopupSearchBusiness: PropTypes.func.isRequired,
  doRemoveColleagueInvitationPopupSelectedBusiness: PropTypes.func.isRequired,
  doRemoveColleagueInvitationPopupSelectedColleague: PropTypes.func.isRequired,
  doSetColleagueInvitationPopupBusinessesSelection: PropTypes.func.isRequired,
  doSubmitColleagueInvitationPopup: PropTypes.func.isRequired,
  doUpdateColleagueInvitationPopupInput: PropTypes.func.isRequired
};

export default ColleagueInvitationPopupComponent;
