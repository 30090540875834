import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-duration-format';
import InboxLikeTimeLayout from './inbox-like-time-layout-component';
import FormattedWaitingTime from './formatted-waiting-time';

/**
 * Finally, the component.
 */
class WaitingComponent extends PureComponent {
  render() {
    const {value} = this.props;

    return (
      <InboxLikeTimeLayout data-test-id="waiting-time">
        <FormattedWaitingTime diff={moment().diff(value)} />
      </InboxLikeTimeLayout>
    );
  }
}

WaitingComponent.propTypes = {
  value: PropTypes.string.isRequired
};

export default WaitingComponent;
