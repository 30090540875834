import {PureComponent} from 'react';
import context from '../../../../../../shared/component/context';
import DocumentationPanel from '../common/documentation-panel-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import SectionTextWithLink from '../common/section-text-with-link-component';
import SimpleContentHeader from '../../../common/content-header/simple-content-header-component';
import mobileAppsSupported from '../../../../../../shared/data/mobile-apps';

class MobileAppsComponent extends PureComponent {
  render() {
    const {i18n} = this.context;

    return (
      <RightPanelContentLayout>
        <SimpleContentHeader title={i18n.t('integrationArea.mobileApps.title')} />

        <DocumentationPanel dataTestId="mobile-apps-panel">
          {Object.entries(mobileAppsSupported).map((entry) => {
            const item = entry[1];
            const {key} = item;

            return (
              <SectionTextWithLink
                key={key}
                linkDataTestId={`${key}-link`}
                linkLabel={
                  <img
                    src={item.storePictureUrl}
                    alt={i18n.t(`common.mobile.downloadOnTheStore.${key}`)}
                    style={{height: '45px', border: 0}}
                  />
                }
                title={item.name}
                url={item.storeUrl}
              />
            );
          })}
        </DocumentationPanel>
      </RightPanelContentLayout>
    );
  }
}

MobileAppsComponent.contextTypes = context;

export default MobileAppsComponent;
