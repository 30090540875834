import {fromEvent, merge, of} from 'rxjs';
import {auditTime, catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {APP_STATE_REFRESH_LOAD} from '../../../../actions/app-actions';
import {
  LEGACY_CUSTOMER_THREAD_LOAD,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE
} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS,
  LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED
} from '../../../../actions/ext-actions';
import {CUSTOMER_THREAD_LOAD_ON_REDIRECTION_AUDIT_TIME} from '../../../../data/settings';
import {LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD} from '../../../../actions/customer-thread-invisible-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-thread-visible-actions';
import {filterByMultistoreConversationDisabled} from '../../../../lib/feature-toggle-helper';

const LegacyCustomerThreadEpic =
  ({command}, socketio) =>
  (action$, state$) => {
    const legacyForwardToAppStateRefreshOnCustomerThreadRedirectedOutOfScope = action$.pipe(
      ofType(LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS),
      filter(({data: {newBusinessOutOfScope}}) => newBusinessOutOfScope),
      map(() => ({
        type: APP_STATE_REFRESH_LOAD
      }))
    );

    const legacyReloadThreadOnRedirection = action$.pipe(
      ofType(LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS),
      auditTime(CUSTOMER_THREAD_LOAD_ON_REDIRECTION_AUDIT_TIME),
      filter(
        ({
          data: {
            payload: {participationId}
          }
        }) => state$.value.getIn(['legacyCustomerThread', 'participationId']) === participationId
      ),
      map(
        ({
          data: {
            payload: {participationId}
          }
        }) => ({
          type: LEGACY_CUSTOMER_THREAD_LOAD,
          payload: {
            participationId
          }
        })
      )
    );

    const legacySwitchToThreadType = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE),
      map(({participationId, threadType}) => {
        return {
          type:
            threadType === 'visible'
              ? LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD
              : LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
          participationId
        };
      })
    );

    const legacyThreadStatusChanged = fromEvent(socketio, 'customer_thread_status_changed').pipe(
      filter(filterByMultistoreConversationDisabled(state$)),
      map((message) => {
        return {
          type: LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED,
          data: message,
          needInboxRefresh: !message.triggeredOnReply
        };
      })
    );

    const legacyUpdateThreadStatus = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE),
      mergeMap(({newStatus, participationId, previousStatus}) => {
        return command('update-thread-status', {
          status: newStatus,
          participationId
        }).pipe(
          map(() => {
            return {
              type: LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
              newStatus,
              previousStatus
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE,
              error,
              previousStatus
            });
          })
        );
      })
    );

    return merge(
      legacyForwardToAppStateRefreshOnCustomerThreadRedirectedOutOfScope,
      legacyReloadThreadOnRedirection,
      legacySwitchToThreadType,
      legacyThreadStatusChanged,
      legacyUpdateThreadStatus
    );
  };

export default LegacyCustomerThreadEpic;
