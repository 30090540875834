import PropTypes from 'prop-types';
import ReplyIcon from 'material-ui/svg-icons/content/reply';
import context from '../../../../../shared/component/context';
import {PADDING_THREAD} from '../../../data/settings';
import {fontSize, fontWeight} from '../../../../../shared/style/variables';
import {lightSlate, lightSmoke, white} from '../../../../../shared/style/colors';

const TransferRowComponent = ({businessName, senderName}, {i18n}) => {
  const styleTransformScaleProperty = 'scale(-1, 1)';

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: PADDING_THREAD,
        lineHeight: '16px',
        fontSize: fontSize.small,
        color: lightSlate
      }}
    >
      <div data-test-id="item-label" style={{padding: '6px 0', lineHeight: '13px'}}>
        {i18n.t('customerInbox.threadList.transfer', {senderName})}
        <span style={{fontWeight: fontWeight.bold}}> {businessName}</span>
      </div>

      <div
        style={{
          margin: '0px 6px 0px',
          marginBottom: 0,
          width: '22px',
          height: '22px',
          border: `1px solid ${lightSmoke}`,
          borderRadius: '50%',
          backgroundColor: white
        }}
      >
        <ReplyIcon
          style={{
            display: 'inline-block',
            margin: '4px',
            width: '12px',
            height: '12px',
            fill: 'currentcolor',
            WebkitUserSelect: 'none',
            stroke: lightSlate,
            transform: styleTransformScaleProperty,
            MozTransform: styleTransformScaleProperty,
            WebkitTransform: styleTransformScaleProperty,
            OTransform: styleTransformScaleProperty,
            MsTransform: styleTransformScaleProperty,
            color: white
          }}
        />
      </div>
    </div>
  );
};

TransferRowComponent.contextTypes = context;

TransferRowComponent.propTypes = {
  businessName: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired
};

export default TransferRowComponent;
