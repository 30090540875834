import PropTypes from 'prop-types';
import CustomerProfileAccountItem from './customer-profile-account-item';
import CustomerProfileEdit from './customer-profile-edit-component';
import {lightSlate} from '../../../../../../../shared/style/colors';
import {fontWeight, spacing} from '../../../../../../../shared/style/variables';

const CustomerProfileContentComponent = ({
  customerProfile,
  i18n,
  participationId,
  doCustomerProfileEditClose,
  doCustomerProfileEditTextUpdate
}) => {
  const containerDataTestId = customerProfile.showDrawerEdit
    ? 'customer-profile-edit-container'
    : 'customer-profile-account-container';

  return (
    <div data-test-id={containerDataTestId} style={{margin: '110px 20px'}}>
      <div
        style={{
          color: lightSlate,
          fontWeight: fontWeight.semiBold,
          marginBottom: spacing.large
        }}
      >
        {customerProfile.showDrawerEdit
          ? i18n.t('customerThread.customerProfile.editForm.title')
          : i18n.t('customerThread.customerProfile.accountTitle')}
      </div>
      {customerProfile.showDrawerEdit ? (
        <CustomerProfileEdit
          {...{
            customerProfile,
            i18n,
            participationId,
            doCustomerProfileEditClose,
            doCustomerProfileEditTextUpdate
          }}
        />
      ) : (
        <CustomerProfileAccountItem {...{customerProfile, i18n}} />
      )}
    </div>
  );
};

CustomerProfileContentComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  participationId: PropTypes.string,
  doCustomerProfileEditClose: PropTypes.func.isRequired,
  doCustomerProfileEditTextUpdate: PropTypes.func.isRequired
};

export default CustomerProfileContentComponent;
