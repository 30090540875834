/**
 * Useful miscellaneous settings.
 */

export const ADMIN_AREA_LEFT_MENU_LIMIT = 5;
export const APP_STATE_REFRESH_LOAD_AUDIT_TIME = 1000; // 1 second

export const BROWSER_NOTIFICATION_AUTOMATIC_CLOSE_DELAY = 8000; // 8 seconds (most of browsers will close automatically after 4 seconds)
export const BROWSER_NOTIFICATION_DEBOUNCE_TIME = 1000; // 1 second

export const CUSTOMER_INBOX_ITEMS_LIMIT = 200;
export const CUSTOMER_INBOX_NEW_CONVERSATION_CUSTOMER_SEARCH_LIMIT = 25;
export const CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_LIMIT = 50;
export const CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_PER_PAGE = 5;
export const CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_LIMIT = 125;
export const CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_PER_PAGE = 25;
export const CUSTOMER_INBOX_THREADS_LIST_LOAD_AUDIT_TIME = 500; // 500 ms

export const CUSTOMER_THREAD_CANNED_RESPONSE_LOAD_DELAY = 200; // 0,2 second
export const CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY = 1000; // 1 second
export const CUSTOMER_THREAD_INACTIVITY_TIMER = 5 * 1000; // 5 seconds
export const CUSTOMER_THREAD_LOAD_ON_REDIRECTION_AUDIT_TIME = 2 * 1000; // 2 seconds

export const DEBOUNCE_TIME_STATISTIC = 500; // 0.5 seconds
export const DEBOUNCE_TIME_TWILIO_TOKEN_RELOAD = 5000; // 5 seconds
export const DEBOUNCE_TIME_TYPING_THREAD = 1000; // 1 second ; do not set too slow (min 500 ms) as it may trigger a lot of re-rendering which could slow down the ui on some browser

export const GLOBAL_ERROR_RETRY_DELAY = 10; // 10 seconds

export const HEADER_MENTIONS_POPUP_ITEMS_NEW_LOAD_AUDIT_TIME = 2000; // 2 seconds
export const HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE = 10;
export const HEADER_MENTIONS_POPUP_ITEMS_MISSED_RELOAD_COUNT_MAX = 999;

export const NEW_RELEASE_BANNER_CHECK_DELAY = 2 * 3600 * 1000; // 2 hours
export const NEW_RELEASE_BANNER_FORCE_REFRESH_TIMEOUT = 3600 - 1; // 1 hour

export const PADDING_THREAD = '0 10px';

export const PAYMENT_AMOUNT_MAX = 20000; // euros

export const STATISTICS_CLASSIC_PERIOD_MORE_START_DATE = '2015-01-01';
export const STATISTICS_CUSTOM_PERIOD_CALENDAR_LIMIT = 1095; // 3 years
export const STATISTICS_TABLE_RANKED_FIRST_PAGE_ITEMS_COUNT_MAX = 50;

export const THREAD_ITEMS_PER_PAGE = 50;

export const TRANSFER_SUCCESSFUL_EXPIRED_DELAY = 2000; // 2 seconds

export const VERIFY_EMAIL_CHECK_VERIFIED_DELAY = 5000; // 5 seconds
export const VERIFY_EMAIL_SEND_EMAIL_RETRY_DELAY = 60 - 1; // 1 minute

export const CUSTOMER_PROFILE_DRAWER_TRANSITION_VALUE = 500;
