import {PureComponent} from 'react';
import keycode from 'keycode';

class CloseOnEscapeComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doClose = this._doClose.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
  }

  _onKeyDown(event) {
    if (keycode('esc') !== event.keyCode) {
      return true;
    }

    event.preventDefault();

    this._doClose();
  }
}

export default CloseOnEscapeComponent;
