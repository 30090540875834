import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../../shared/component/context';
import LoadingIndicator from '../../../../../../../shared/component/loading-indicator-component';
import Paper from '../../../../../../../shared/component/paper-component';
import {
  fontSize,
  fontWeight,
  lineHeight,
  spacing
} from '../../../../../../../shared/style/variables';

export const SEPARATOR_WIDTH = spacing.xlarge;

/**
 * Finally, the component.
 */
class InsightPanelContentBlockComponent extends PureComponent {
  render() {
    const {
      children,
      contentStyle,
      dataTestId,
      description,
      indicators,
      indicatorsTitle,
      loading,
      noEffect,
      style,
      title
    } = this.props;

    const chartWidth = indicators ? '70%' : '100%';

    return (
      <div
        data-test-id={dataTestId}
        style={{
          marginBottom: SEPARATOR_WIDTH,
          ...style
        }}
      >
        {title ? (
          <div
            style={{
              fontSize: fontSize.xlarge,
              fontWeight: fontWeight.semiBold,
              marginBottom: SEPARATOR_WIDTH,
              width: chartWidth
            }}
          >
            {title}
          </div>
        ) : null}

        {description ? (
          <div
            dangerouslySetInnerHTML={{__html: description}}
            style={{
              marginBottom: SEPARATOR_WIDTH,
              lineHeight: lineHeight.large,
              width: chartWidth
            }}
          />
        ) : null}

        <div
          style={{
            display: 'flex',
            position: 'relative'
          }}
        >
          <Paper
            elevation={noEffect ? 0 : undefined}
            style={{
              flex: `1 1 ${chartWidth}`,
              ...contentStyle
            }}
          >
            {children}
          </Paper>

          {indicators ? (
            <div
              data-test-id="indicators"
              style={{
                display: 'flex',
                flex: '1 1 30%',
                flexDirection: 'column',
                paddingLeft: SEPARATOR_WIDTH,
                minWidth: '100px',
                maxWidth: '250px'
              }}
            >
              {indicatorsTitle ? (
                <div
                  style={{
                    marginTop: spacing.large,
                    lineHeight: lineHeight.large,
                    fontSize: fontSize.xlarge,
                    fontWeight: fontWeight.semiBold
                  }}
                >
                  {indicatorsTitle}
                </div>
              ) : null}
              {indicators}
            </div>
          ) : null}

          {loading ? (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(255, 255, 255, 0.7)'
              }}
            >
              <LoadingIndicator />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

InsightPanelContentBlockComponent.contextTypes = context;

InsightPanelContentBlockComponent.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  contentStyle: PropTypes.objectOf(PropTypes.any),
  dataTestId: PropTypes.string,
  description: PropTypes.string,
  indicators: PropTypes.objectOf(PropTypes.any),
  indicatorsTitle: PropTypes.string,
  loading: PropTypes.bool,
  noEffect: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string
};

export default InsightPanelContentBlockComponent;
