import PropTypes from 'prop-types';
import {black} from '../../../../../shared/style/colors';

const HeaderContentLayoutComponent = ({children, onClick}) => {
  return (
    <div
      data-test-id="content-header"
      style={{
        boxShadow: '0 -1px 1px 0 rgba(0,0,0,.05), 0 1px 3px 0 rgba(0,0,0,.1)',
        minHeight: '50px',
        height: '50px',
        borderCollapse: 'collapse',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 10,
        color: black
      }}
      {...{
        onClick
      }}
    >
      {children}
    </div>
  );
};

HeaderContentLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired, // Will be array when Colleague Thread will be filled
  onClick: PropTypes.func
};

export default HeaderContentLayoutComponent;
