import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import Dropzone from 'react-dropzone';
import context from '../../../../shared/component/context';
import debug from '../../../../shared/lib/debug';
import {FILE_UPLOAD_MAX_SIZE_DEFAULT} from '../../../../shared/data/settings';
import {fontSize} from '../../../../shared/style/variables';
import {darkSmoke} from '../../../../shared/style/colors';

const _onDropRejected = (fileRejections) =>
  debug('An error occurred. File rejection(s): ', fileRejections);

/**
 * Finally, the component.
 *
 * @todo Handle error cases
 */
class DroppableAreaComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showLayer: false
    };

    this._layerHide = this._layerHide.bind(this);
    this._layerShow = this._layerShow.bind(this);
    this._onDropAccepted = this._onDropAccepted.bind(this);
  }

  _layerHide() {
    this.setState({showLayer: false});
  }

  _layerShow() {
    this.setState({showLayer: true});
  }

  _onDropAccepted(acceptedFiles) {
    const {onDropAccepted} = this.props;
    onDropAccepted(acceptedFiles[0]);
  }

  render() {
    const {i18n} = this.context;
    const {children, validateFileSize} = this.props;
    const {showLayer} = this.state;

    return (
      <Dropzone
        maxFiles={1}
        maxSize={validateFileSize ? FILE_UPLOAD_MAX_SIZE_DEFAULT : null}
        multiple={false}
        noClick
        noDragEventsBubbling
        noKeyboard
        onDragEnter={this._layerShow}
        onDragLeave={this._layerHide}
        onDrop={this._layerHide}
        onDropAccepted={this._onDropAccepted}
        onDropRejected={_onDropRejected}
      >
        {({getRootProps, getInputProps}) => (
          <div
            {...getRootProps()}
            style={{
              position: 'relative',
              flexGrow: 1
            }}
          >
            <input {...getInputProps()} />

            {showLayer ? (
              <div
                style={{
                  zIndex: 1,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `2px dashed ${darkSmoke}`,
                  backgroundColor: 'white', // required in order to hide the underneath text input placeHolder
                  opacity: 0.7,
                  fontSize: fontSize.xxlarge
                }}
              >
                {i18n.t('droppableArea.description')}
              </div>
            ) : null}

            {children}
          </div>
        )}
      </Dropzone>
    );
  }
}

DroppableAreaComponent.contextTypes = context;

DroppableAreaComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  validateFileSize: PropTypes.bool.isRequired,
  onDropAccepted: PropTypes.func.isRequired
};

export default DroppableAreaComponent;
