import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FullscreenLayout from '../../../../shared/component/fullscreen-layout-component';
import LoadingIndicator from '../../../../shared/component/loading-indicator-component';

class GlobalLoadingComponent extends PureComponent {
  render() {
    const {doUIEndMount} = this.props;

    return (
      <FullscreenLayout
        dataTestId="app-loading"
        showLogo
        {...{
          doUIEndMount
        }}
      >
        <LoadingIndicator />
      </FullscreenLayout>
    );
  }
}

GlobalLoadingComponent.propTypes = {
  doUIEndMount: PropTypes.func
};

export default GlobalLoadingComponent;
