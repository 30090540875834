import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {withStyles} from '@material-ui/core/styles';
import {red} from '../../style/colors';

const StyledIcon = withStyles({
  colorAction: {
    color: red
  }
})(ErrorOutlineIcon);

const ErroIconComponent = ({fontSize}) => (
  <div style={{fontSize}}>
    <StyledIcon color="action" fontSize="inherit" />
  </div>
);

ErroIconComponent.propTypes = {
  fontSize: PropTypes.string.isRequired
};
export default ErroIconComponent;
