import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';
import {blue} from '../style/colors';

const LoadingIndicatorComponent = ({
  color = blue,
  size = 40,
  label,
  style = {},
  overlay = false
}) => (
  <div
    data-test-id="loading"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...(overlay
        ? {
            zIndex: 3,
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            opacity: 0.8
          }
        : {
            margin: '10px 0',
            height: `${size}px`
          }),
      ...style
    }}
  >
    <CircularProgress size={size} thickness={2} color={color} />
    {label ? <span dangerouslySetInnerHTML={{__html: label}} style={{marginLeft: '10px'}} /> : null}
  </div>
);

LoadingIndicatorComponent.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  overlay: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any)
};

export default LoadingIndicatorComponent;
