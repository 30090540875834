export const COLLEAGUE_NEW_THREAD_LOAD = 'COLLEAGUE_NEW_THREAD_LOAD';
export const COLLEAGUE_NEW_THREAD_LOAD_SUCCESS = 'COLLEAGUE_NEW_THREAD_LOAD_SUCCESS';

export const COLLEAGUE_NEW_THREAD_SEND_MESSAGE = 'COLLEAGUE_NEW_THREAD_SEND_MESSAGE';
export const COLLEAGUE_NEW_THREAD_SEND_MESSAGE_FAILURE =
  'COLLEAGUE_NEW_THREAD_SEND_MESSAGE_FAILURE';
export const COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS =
  'COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS';

export const COLLEAGUE_THREAD_FILE_REMOVE = 'COLLEAGUE_THREAD_FILE_REMOVE';
export const COLLEAGUE_THREAD_FILE_UPLOAD = 'COLLEAGUE_THREAD_FILE_UPLOAD';
export const COLLEAGUE_THREAD_FILE_UPLOAD_FAILURE = 'COLLEAGUE_THREAD_FILE_UPLOAD_FAILURE';
export const COLLEAGUE_THREAD_FILE_UPLOAD_SUCCESS = 'COLLEAGUE_THREAD_FILE_UPLOAD_SUCCESS';

export const COLLEAGUE_THREAD_LEAVE = 'COLLEAGUE_THREAD_LEAVE';

export const COLLEAGUE_THREAD_LOAD = 'COLLEAGUE_THREAD_LOAD';
export const COLLEAGUE_THREAD_LOAD_FAILURE = 'COLLEAGUE_THREAD_LOAD_FAILURE';
export const COLLEAGUE_THREAD_LOAD_SUCCESS = 'COLLEAGUE_THREAD_LOAD_SUCCESS';

export const COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_PERSIST =
  'COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_PERSIST';
export const COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_UPDATE =
  'COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_UPDATE';

export const COLLEAGUE_THREAD_SEND_MESSAGE = 'COLLEAGUE_THREAD_SEND_MESSAGE';
export const COLLEAGUE_THREAD_SEND_MESSAGE_FAILURE = 'COLLEAGUE_THREAD_SEND_MESSAGE_FAILURE';
export const COLLEAGUE_THREAD_SEND_MESSAGE_RETRY = 'COLLEAGUE_THREAD_SEND_MESSAGE_RETRY';
export const COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS = 'COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS';
