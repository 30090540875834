import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import EnhancedPopover from '../../../common/enhanced-popover-component';
import {lightSmoke, lightGrey, slate} from '../../../../../../shared/style/colors';
import {fontSize, fontWeight, spacing} from '../../../../../../shared/style/variables';

const AutomaticResponseVariablesComponent = ({
  automaticResponseVariables,
  i18n,
  selectVariable,
  ...otherProps
}) => {
  const renderSectionWithItems = (sectionItems) => (
    <div style={{overflowY: 'auto'}}>
      <div data-test-id="list" style={{padding: 0, width: '180px'}}>
        {sectionItems.map(({appendValue, dataTestId, label}, index) => {
          const onVariableClick = () => selectVariable(appendValue);

          return (
            <MenuItem
              data-test-id="item"
              key={label}
              onClick={onVariableClick}
              style={{
                minHeight: 'auto',
                lineHeight: '18px',
                textAlign: 'center'
              }}
              innerDivStyle={{
                padding: '10px 0',
                borderBottom: index === sectionItems.length - 1 ? 0 : `1px solid ${lightSmoke}`
              }}
            >
              <p
                data-test-id={dataTestId}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  fontSize: fontSize.medium,
                  color: slate,
                  lineHeight: '16px',
                  display: '-webkit-box',
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {label}
              </p>
            </MenuItem>
          );
        })}
      </div>
    </div>
  );

  return (
    <EnhancedPopover
      dataTestId="automatic-responses-variable-popup"
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
      {...otherProps}
      style={{
        overflowY: 'hidden'
      }}
    >
      <h2
        style={{
          paddingTop: spacing.medium,
          paddingBottom: spacing.medium,
          paddingLeft: spacing.large,
          paddingRight: spacing.large,
          lineHeight: '22px',
          background: lightGrey,
          color: slate,
          fontSize: fontSize.xlarge,
          fontWeight: fontWeight.semiBold,
          textTransform: 'capitalize',
          textAlign: 'center'
        }}
      >
        {i18n.t('administration.business.pages.channels.gbm.automaticResponseVariableTitle')}
      </h2>

      {renderSectionWithItems(automaticResponseVariables)}
    </EnhancedPopover>
  );
};

AutomaticResponseVariablesComponent.propTypes = {
  automaticResponseVariables: PropTypes.arrayOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  selectVariable: PropTypes.func.isRequired
};

export default AutomaticResponseVariablesComponent;
