import {routerReducer} from 'react-router-redux';
import combineReducers from 'redux-immutable-combine-reducers';
import socketIOClient from 'socket.io-client';
import anyServerAccessor from '../../shared/store/any-server-accessor';
import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import {getCookie} from '../../shared/lib/cookie-helper';
import isAuthenticated from '../../shared/lib/is-authenticated';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import {SIGN_IN_URL} from '../../shared/data/route';
import account from './reducer/global/account-reducer';
import administrationBusiness from './reducer/content/administration/business/administration-business-reducer';
import administrationBusinessPageChannels from './reducer/content/administration/business/page/administration-business-page-channels-reducer';
import administrationBusinessPageChannelsGbm from './reducer/content/administration/business/page/administration-business-page-channels-gbm-reducer';
import administrationLayout from './reducer/content/administration/administration-layout-reducer';
import administrationOrganizationChannelsGbm from './reducer/content/administration/organization/channels/administration-organization-channels-gbm-reducer';
import app from './reducer/app-reducer';
import bubbleWidgetGenerator from './reducer/content/integration-area/page/widgets/bubble-widget-generator-reducer';
import ctaWidgetGenerator from './reducer/content/integration-area/page/widgets/cta-widget-generator-reducer';
import embedFormWidgetGenerator from './reducer/content/integration-area/page/widgets/embed-form-widget-generator-reducer';
import mobileWidgetGenerator from './reducer/content/integration-area/page/widgets/mobile-widget-generator-reducer';
import colleagueInbox from './reducer/content/colleague/colleague-inbox-reducer';
import colleagueInvitation from './reducer/global/colleague-invitation-reducer';
import colleagueThread from './reducer/content/colleague/colleague-thread-reducer';
import customerInbox from './reducer/content/customer/inbox/customer-inbox-reducer';
import customerInboxBusinessFilter from './reducer/content/customer/inbox/customer-inbox-business-filter-reducer';
import customerInvisibleThread from './reducer/content/customer/thread/customer-invisible-thread-reducer';
import customerThreadProfile from './reducer/content/customer/thread/customer-thread-profile-reducer';
import customerThread from './reducer/content/customer/thread/customer-thread-reducer';
import customerThreadAppPayment from './reducer/content/customer/thread/apps/customer-thread-app-payment-reducer';
import customerThreadConversations from './reducer/content/customer/thread/customer-thread-conversations-reducer';
import customerThreadMention from './reducer/content/customer/thread/customer-thread-mention-reducer';
import customerThreadSurvey from './reducer/content/customer/thread/customer-thread-survey-reducer';
import customerThreadTransfer from './reducer/content/customer/thread/customer-thread-transfer-reducer';
import customerVisibleThread from './reducer/content/customer/thread/customer-visible-thread-reducer';
import featureToggle from './reducer/global/feature-toggle-reducer';
import headerMention from './reducer/header/header-mention-reducer';
import insight from './reducer/content/insight/insight-reducer';
import insightBusinessFilter from './reducer/content/insight/insight-business-filter-reducer';
import legacyCustomerInvisibleThread from './reducer/content/customer/thread/legacy-customer-invisible-thread-reducer';
import legacyCustomerProfile from './reducer/content/customer/thread/legacy-customer-profile-reducer';
import legacyCustomerThread from './reducer/content/customer/thread/legacy-customer-thread-reducer';
import legacyCustomerThreadAppPayment from './reducer/content/customer/thread/apps/legacy-customer-thread-app-payment-reducer';
import legacyCustomerThreadMention from './reducer/content/customer/thread/legacy-customer-thread-mention-reducer';
import legacyCustomerVisibleThread from './reducer/content/customer/thread/legacy-customer-visible-thread-reducer';
import threadFilePreview from './reducer/common/thread/thread-file-preview-reducer';
import tutorial from './reducer/global/tutorial-reducer';
import twilio from './reducer/global/twilio-reducer';
import warning from './reducer/header/warning-reducer';
import administrationBusinessEpic from './epic/content/administration/business/administration-business-epic';
import administrationBusinessPageChannelsEpic from './epic/content/administration/business/page/administration-business-page-channels-epic';
import administrationBusinessPageChannelsGbmEpic from './epic/content/administration/business/page/administration-business-page-channels-gbm-epic';
import administrationLeftMenuEpic from './epic/content/administration/left-menu/administration-left-menu-epic';
import administrationOrganizationChannelsGbmEpic from './epic/content/administration/organization/channels/administration-organization-channels-gbm-epic';
import appEpic from './epic/app-epic';
import browserNotificationEpic from './epic/global/browser-notification-epic';
import colleagueInboxEpic from './epic/content/colleague/colleague-inbox-epic';
import colleagueInvitationEpic from './epic/global/colleague-invitation-epic';
import colleagueThreadEpic from './epic/content/colleague/colleague-thread-epic';
import customerInboxBusinessFilterEpic from './epic/content/customer/inbox/customer-inbox-business-filter-epic';
import customerInboxEpic from './epic/content/customer/inbox/customer-inbox-epic';
import customerInboxNewConversationEpic from './epic/content/customer/inbox/customer-inbox-new-conversation-epic';
import customerInboxSearchEpic from './epic/content/customer/inbox/customer-inbox-search-epic';
import customerInboxThreadsListEpic from './epic/content/customer/inbox/customer-inbox-threads-list-epic';
import customerThreadActivityEpic from './epic/content/customer/thread/customer-thread-activity-epic';
import customerThreadAppPaymentEpic from './epic/content/customer/thread/apps/customer-thread-app-payment-epic';
import customerThreadAssignmentEpic from './epic/content/customer/thread/customer-thread-assignment-epic';
import customerThreadCannedResponsesEpic from './epic/content/customer/thread/customer-thread-canned-responses-epic';
import customerThreadCompatibilityQueryConversationsEpic from './epic/content/customer/thread/customer-thread-compatibility-query-conversations-epic';
import customerThreadConversationsEpic from './epic/content/customer/thread/customer-thread-conversations-epic';
import customerThreadEpic from './epic/content/customer/thread/customer-thread-epic';
import customerThreadInvisibleEpic from './epic/content/customer/thread/customer-thread-invisible-epic';
import customerThreadInvisibleMentionEpic from './epic/content/customer/thread/customer-thread-invisible-mention-epic';
import customerThreadProfileEpic from './epic/content/customer/thread/customer-thread-profile-epic';
import customerThreadVisibleEpic from './epic/content/customer/thread/customer-thread-visible-epic';
import customerThreadVisibleWhatsAppEpic from './epic/content/customer/thread/customer-thread-visible-whatsapp-epic';
import errorEpic from './epic/global/error-epic';
import headerDropDownEpic from './epic/header/header-dropdown-menu-epic';
import headerMentionEpic from './epic/header/header-mention-epic';
import insightEpic from './epic/content/insight/insight-epic';
import insightContactCentersContactsEpic from './epic/content/insight/contact-centers/insight-contacts-epic';
import insightContactCentersUsersEpic from './epic/content/insight/contact-centers/insight-users-epic';
import insightContactCentersWebAHTEpic from './epic/content/insight/contact-centers/insight-web-aht-epic';
import insightContactCentersWebConnectedUsersEpic from './epic/content/insight/contact-centers/insight-web-connected-users-epic';
import insightDevicesEpic from './epic/content/insight/insight-devices-epic';
import insightMessagesEpic from './epic/content/insight/insight-messages-epic';
import insightNewCustomersEpic from './epic/content/insight/insight-new-customers-epic';
import insightNewInboundConversationsEpic from './epic/content/insight/insight-new-inbound-conversations-epic';
import insightNewOutboundConversationsEpic from './epic/content/insight/insight-new-outbound-conversations-epic';
import insightPreferredChannelEpic from './epic/content/insight/insight-preferred-channel-epic';
import insightResponseRateEpic from './epic/content/insight/insight-response-rate-epic';
import insightSatisfactionEpic from './epic/content/insight/insight-satisfaction-epic';
import insightConnectedCustomersEpic from './epic/content/insight/insight-connected-customers-epic';
import insightConversationsEpic from './epic/content/insight/insight-conversations-epic';
import insightContactsEpic from './epic/content/insight/insight-contacts-epic';
import insightResponseTimeEpic from './epic/content/insight/insight-response-time-epic';
import insightTopStoresEpic from './epic/content/insight/insight-top-stores-epic';
import insightWaitingCustomersEpic from './epic/content/insight/insight-waiting-customers-epic';
import integrationAreaEpic from './epic/content/integration-area/integration-area-epic';
import legacyBrowserNotificationEpic from './epic/global/legacy-browser-notification-epic';
import legacyCustomerInboxEpic from './epic/content/customer/inbox/legacy-customer-inbox-epic';
import legacyCustomerInboxNewThreadEpic from './epic/content/customer/inbox/legacy-customer-inbox-new-thread-epic';
import legacyCustomerInboxSearchEpic from './epic/content/customer/inbox/legacy-customer-inbox-search-epic';
import legacyCustomerInboxThreadsListEpic from './epic/content/customer/inbox/legacy-customer-inbox-threads-list-epic';
import legacyCustomerProfileEpic from './epic/content/customer/thread/legacy-customer-profile-epic';
import legacyCustomerThreadAppPaymentEpic from './epic/content/customer/thread/apps/legacy-customer-thread-app-payment-epic';
import legacyCustomerThreadAssignmentEpic from './epic/content/customer/thread/legacy-customer-thread-assignment-epic';
import legacyCustomerThreadCannedResponsesEpic from './epic/content/customer/thread/legacy-customer-thread-canned-responses-epic';
import legacyCustomerThreadInvisibleEpic from './epic/content/customer/thread/legacy-customer-thread-invisible-epic';
import legacyCustomerThreadInvisibleMentionEpic from './epic/content/customer/thread/legacy-customer-thread-invisible-mention-epic';
import legacyCustomerThreadSurveyEpic from './epic/content/customer/thread/legacy-customer-thread-survey-epic';
import legacyCustomerThreadTransferEpic from './epic/content/customer/thread/legacy-customer-thread-transfer-epic';
import legacyCustomerThreadVisibleEpic from './epic/content/customer/thread/legacy-customer-thread-visible-epic';
import legacyCustomerThreadVisibleWhatsAppEpic from './epic/content/customer/thread/legacy-customer-thread-visible-whatsapp-epic';
import legacyCustomerThreadEpic from './epic/content/customer/thread/legacy-customer-thread-epic';
import legacyHeaderMentionEpic from './epic/header/legacy-header-mention-epic';
import legacySocketEpic from './epic/legacy-socket-epic';
import socketEpic from './epic/socket-epic';
import tutorialEpic from './epic/global/tutorial-epic';
import twilioEpic from './epic/global/twilio-epic';
import verifyEmailEpic from './epic/global/verify-email-epic';

const socketIOAccessor = (token) => {
  const client = socketIOClient(MIDDLE_END_URL, {
    timeout: 120 * 1000
  });

  client.on('connect', () => {
    client.emit('authentication', token);
  });

  return client;
};

/**
 * Finally, the store.
 */
export default function appStore() {
  if (!isAuthenticated()) {
    window.location = SIGN_IN_URL;

    return null;
  }

  const token = getCookie('token');

  const asa = anyServerAccessor();
  const mea = middleEndAccessors(token);
  const ioc = socketIOAccessor(token);

  const rootEpic = createRootEpic(
    administrationBusinessEpic(mea),
    administrationBusinessPageChannelsEpic(mea),
    administrationBusinessPageChannelsGbmEpic(mea),
    administrationLeftMenuEpic(mea),
    administrationOrganizationChannelsGbmEpic(mea),
    appEpic(mea, ioc, asa),
    browserNotificationEpic(),
    colleagueInboxEpic(mea),
    colleagueInvitationEpic(mea),
    colleagueThreadEpic(mea, ioc),
    customerInboxBusinessFilterEpic(mea),
    customerInboxEpic(mea),
    customerInboxNewConversationEpic(mea),
    customerInboxSearchEpic(mea),
    customerInboxThreadsListEpic(mea),
    customerThreadActivityEpic(mea),
    customerThreadAppPaymentEpic(mea),
    customerThreadAssignmentEpic(mea),
    customerThreadCannedResponsesEpic(mea),
    customerThreadCompatibilityQueryConversationsEpic(mea),
    customerThreadConversationsEpic(mea),
    customerThreadEpic(mea),
    customerThreadInvisibleEpic(mea),
    customerThreadInvisibleMentionEpic(mea),
    customerThreadProfileEpic(mea),
    customerThreadVisibleEpic(mea),
    customerThreadVisibleWhatsAppEpic(mea),
    errorEpic(),
    headerDropDownEpic(mea),
    headerMentionEpic(mea),
    insightEpic(mea),
    insightContactCentersContactsEpic(mea),
    insightContactCentersUsersEpic(mea),
    insightContactCentersWebAHTEpic(mea),
    insightContactCentersWebConnectedUsersEpic(mea),
    insightDevicesEpic(mea),
    insightMessagesEpic(mea),
    insightNewCustomersEpic(mea),
    insightNewInboundConversationsEpic(mea),
    insightNewOutboundConversationsEpic(mea),
    insightPreferredChannelEpic(mea),
    insightResponseRateEpic(mea),
    insightResponseTimeEpic(mea),
    insightSatisfactionEpic(mea),
    insightConnectedCustomersEpic(mea),
    insightConversationsEpic(mea),
    insightContactsEpic(mea),
    insightTopStoresEpic(mea),
    insightWaitingCustomersEpic(mea),
    integrationAreaEpic(),
    legacyBrowserNotificationEpic(),
    legacyCustomerInboxEpic(mea),
    legacyCustomerInboxNewThreadEpic(mea),
    legacyCustomerInboxSearchEpic(mea),
    legacyCustomerInboxThreadsListEpic(mea),
    legacyCustomerProfileEpic(mea),
    legacyCustomerThreadAppPaymentEpic(mea),
    legacyCustomerThreadAssignmentEpic(mea, ioc),
    legacyCustomerThreadCannedResponsesEpic(mea),
    legacyCustomerThreadInvisibleEpic(mea, ioc),
    legacyCustomerThreadInvisibleMentionEpic(mea),
    legacyCustomerThreadSurveyEpic(mea),
    legacyCustomerThreadTransferEpic(mea, ioc),
    legacyCustomerThreadVisibleEpic(mea, ioc),
    legacyCustomerThreadVisibleWhatsAppEpic(mea),
    legacyCustomerThreadEpic(mea, ioc),
    legacyHeaderMentionEpic(mea),
    legacySocketEpic(ioc),
    socketEpic(ioc),
    tutorialEpic(),
    twilioEpic(mea),
    verifyEmailEpic(mea)
  );

  const rootReducer = createRootReducer({
    router: routerReducer,
    account,
    administration: combineReducers({
      layout: administrationLayout,
      organization: combineReducers({
        channels: combineReducers({
          gbm: administrationOrganizationChannelsGbm
        })
      }),
      business: combineReducers({
        channels: combineReducers({
          gbm: administrationBusinessPageChannelsGbm
        })
      })
    }),
    administrationBusiness,
    administrationBusinessPageChannels,
    app,
    colleagueInbox,
    colleagueInvitation,
    colleagueThread,
    customerInbox,
    customerInboxBusinessFilter,
    customerInvisibleThread,
    customerThread,
    customerThreadAppPayment,
    customerThreadMention,
    customerThreadTransfer,
    customerVisibleThread,
    featureToggle,
    headerMention,
    insight,
    insightBusinessFilter,
    legacyCustomerInvisibleThread,
    legacyCustomerProfile,
    legacyCustomerThread,
    legacyCustomerThreadAppPayment,
    legacyCustomerThreadMention,
    legacyCustomerVisibleThread,
    threadFilePreview,
    tutorial,
    twilio,
    warning,
    customer: combineReducers({
      thread: combineReducers({
        // @todo move here others thread specialized reducers
        conversations: customerThreadConversations,
        profile: customerThreadProfile,
        survey: customerThreadSurvey
      })
    }),
    integrationArea: combineReducers({
      bubbleWidget: bubbleWidgetGenerator,
      ctaWidget: ctaWidgetGenerator,
      embedForm: embedFormWidgetGenerator,
      mobile: mobileWidgetGenerator
    })
  });

  return {
    rootEpic,
    rootReducer
  };
}
