import React from 'react';
import PropTypes from 'prop-types';
import CardTitle from './card-title-component';
import {oneline} from '../../../../../shared/style/ellipsis';
import {spacing} from '../../../../../shared/style/variables';
import {lightSlate} from '../../../../../shared/style/colors';

const EmployeeListItemLayoutComponent = ({
  avatar,
  children,
  displayName,
  emailAddress,
  textMaxWidth
}) => (
  <React.Fragment>
    {avatar}

    <div
      style={{
        flex: 1,
        maxWidth: textMaxWidth,
        ...oneline,
        ...(avatar ? {marginLeft: spacing.medium} : null)
      }}
    >
      <CardTitle
        data-test-id="name"
        text={displayName}
        style={{
          ...(emailAddress ? null : {marginBottom: 0})
        }}
      />

      {emailAddress ? (
        <span
          data-test-id="email"
          title={emailAddress}
          style={{
            color: lightSlate
          }}
        >
          {emailAddress}
        </span>
      ) : null}

      {children}
    </div>
  </React.Fragment>
);
EmployeeListItemLayoutComponent.propTypes = {
  avatar: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  displayName: PropTypes.string.isRequired,
  emailAddress: PropTypes.string,
  textMaxWidth: PropTypes.string.isRequired
};

export default EmployeeListItemLayoutComponent;
