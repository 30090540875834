import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import definitions from './lib/graph-definitions';
import downloadFileFromUrl from '../../../../../shared/lib/download-file-from-url';
import {getFilterInsightsCanShowRankingTable} from '../../../lib/insights/insights-helper';
import {loadInitialInsights} from './lib/prepare-graph-fill';
import prepareRequestParams from './lib/prepare-request-params';
import triggerLoadTablesAction from './lib/trigger-table-ranking-actions';
import updateSortDirection from './lib/update-sort-direction';
import updateTopStorePayloadFromQueryResponse from './lib/transform-top-store-response';
import {
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_SORT_BUTTON_CLICK,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION,
  INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_FAILURE,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_SUCCESS,
  INSIGHT_RESPONSE_RATE_OPEN,
  INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../actions/insight-actions';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';

const rankingStateParamName = 'responseRate';

const InsightResponseRateEpic =
  ({graphql}) =>
  (action$, state$) => {
    const {actionRankingTypes, actionTypes, epicName, sortBy} = definitions.responseRate;
    const [loadAction] = actionTypes;
    const [loadRankingAction, successRankingAction, failureRankingAction] = actionRankingTypes;

    const loadInsights = loadInitialInsights(action$, state$, {
      actionsName: [
        INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_RESPONSE_RATE_OPEN,
        INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ],
      loadAction,
      loadRankingAction,
      rankingStateParamName
    });

    const handleClickSortDirection = action$.pipe(
      ofType(INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_SORT_BUTTON_CLICK),
      map(() => {
        const storedSortDirection = state$.value.getIn([
          'insight',
          'responseRate',
          'ranking',
          'sortedDirection'
        ]);

        return {
          type: INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION,
          sortDirection: updateSortDirection(storedSortDirection)
        };
      })
    );

    const updateSortInsights = action$.pipe(
      ofType(INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION),
      map(() => triggerLoadTablesAction(state$, {loadRankingAction, rankingStateParamName}))
    );

    const downloadResponseRateCsv = downloadFileFromUrl(
      action$,
      INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD
    );

    const loadResponseRateCsvToDownload = action$.pipe(
      ofType(INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD),
      mergeMap(({classicAggregationDate, classicAggregationPeriod}) =>
        graphql(
          'get-employee-response-rate-ranking-report-query',
          prepareRequestParams(state$, {
            classicAggregationDate,
            classicAggregationPeriod,
            forceStartDate: true
          }),
          {timeout: TIMEOUT_REQUEST_LONG}
        ).pipe(
          map(({employeeResponseRateRankingReport}) => ({
            type: INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD,
            url: employeeResponseRateRankingReport.url
          }))
          // @todo handle failure
        )
      )
    );

    const loadEmployeeResponseRate = action$.pipe(
      ofType(INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate
        }) => {
          return graphql(
            'get-employee-response-rate-query',
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            })
          ).pipe(
            map(({employeeResponseRateHistogram, employeeResponseRateIndicators}) => {
              return {
                type: INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_SUCCESS,
                employeeResponseRateHistogram,
                employeeResponseRateIndicators
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    const loadEmployeeResponseRateRankingTable = action$.pipe(
      ofType(loadRankingAction),
      filter(getFilterInsightsCanShowRankingTable(state$)),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate,
          sortedDirection
        }) => {
          return graphql(
            `query { topStores { ${epicName} { ranking increment businessId businessName value isBusinessInUserScope } } }`,
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate,
              forceStartDate: true,
              getAll: true,
              orderBy: [{field: sortBy, order: sortedDirection}]
            }),
            {timeout: TIMEOUT_REQUEST_LONG}
          ).pipe(
            map(({topStores}) => {
              return {
                type: successRankingAction,
                ranking: updateTopStorePayloadFromQueryResponse(topStores)
              };
            }),
            catchError((error) => {
              return of({
                type: failureRankingAction,
                error
              });
            })
          );
        }
      )
    );

    return merge(
      downloadResponseRateCsv,
      handleClickSortDirection,
      loadEmployeeResponseRate,
      loadEmployeeResponseRateRankingTable,
      loadInsights,
      loadResponseRateCsvToDownload,
      updateSortInsights
    );
  };

export default InsightResponseRateEpic;
