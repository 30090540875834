import PropTypes from 'prop-types';
import StandardButton from '../../../../../../../shared/component/button/standard-button-component';
import {spacing} from '../../../../../../../shared/style/variables';
import {lightSmoke} from '../../../../../../../shared/style/colors';

const ThreadAssignmentInputOverlayComponent = ({
  assignedTo,
  i18n,
  taking,
  onThreadTakeButtonClick
}) => {
  return (
    <div
      style={{
        height: '67px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `1px solid ${lightSmoke}`,
        padding: spacing.medium
      }}
    >
      <StandardButton
        data-test-id="take-button"
        disabled={taking}
        variant="outlined"
        onClick={onThreadTakeButtonClick}
      >
        {assignedTo
          ? i18n.t('customerThread.visibleThread.assignment.takeIfAssignedButton')
          : i18n.t('customerThread.visibleThread.assignment.takeIfNotAssignedButton')}
      </StandardButton>
    </div>
  );
};

ThreadAssignmentInputOverlayComponent.propTypes = {
  assignedTo: PropTypes.objectOf(PropTypes.any),
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  taking: PropTypes.bool.isRequired,
  onThreadTakeButtonClick: PropTypes.func
};

export default ThreadAssignmentInputOverlayComponent;
