export const CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_CHANGED =
  'CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_CHANGED';
export const CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_PERSIST =
  'CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_PERSIST';

export const CUSTOMER_THREAD_VISIBLE_FILE_REMOVE = 'CUSTOMER_THREAD_VISIBLE_FILE_REMOVE';
export const CUSTOMER_THREAD_VISIBLE_FILE_SELECT = 'CUSTOMER_THREAD_VISIBLE_FILE_SELECT';
export const CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE =
  'CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE';
export const CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK =
  'CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK';
export const CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED =
  'CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED';
export const CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD = 'CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD';
export const CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE =
  'CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE';
export const CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS =
  'CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS';

export const CUSTOMER_THREAD_VISIBLE_LOAD = 'CUSTOMER_THREAD_VISIBLE_LOAD';
export const CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED = 'CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED';
export const CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE = 'CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE';
export const CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS = 'CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS';

export const CUSTOMER_THREAD_VISIBLE_PAGINATE = 'CUSTOMER_THREAD_VISIBLE_PAGINATE';
export const CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE = 'CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE';
export const CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS = 'CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS';

export const CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE = 'CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE';
export const CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_BUTTON_CLICK =
  'CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_BUTTON_CLICK';
export const CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE =
  'CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE';
export const CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY_BUTTON_CLICK =
  'CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY_BUTTON_CLICK';
export const CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY =
  'CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY';
export const CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS =
  'CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS';

export const CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK =
  'CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK';
export const CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD =
  'CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD';
export const CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE =
  'CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE';
export const CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS =
  'CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS';
export const CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK =
  'CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK';

// Legacy

export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_REMOVE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_REMOVE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD = 'LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_UPDATE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_UPDATE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_PERSIST =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_PERSIST';

export const LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE = 'LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_TEXT_SEND =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_TEXT_SEND';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS';
export const LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK';
