import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import searchOrganizationBusinesses from '../lib/search-organization-businesses-helper';
import trimSplit from '../../../../shared/lib/trim-split';
import {
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_FAILURE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_SUCCESS,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_FAILURE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_SUCCESS,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_FAILURE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_SUCCESS
} from '../../actions/global-actions';

const ColleagueInvitationEpic =
  ({graphql, query}) =>
  (action$, state$) => {
    const addColleagues = action$.pipe(
      ofType(GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD),
      mergeMap(({emails}) => {
        return graphql('get-colleague-from-email-query', {
          emails: trimSplit(emails).filter(
            (email) =>
              !state$.value
                .getIn(['colleagueInvitation', 'colleaguesSelection'])
                .some((colleague) => email === colleague.email)
          )
        }).pipe(
          map(({colleagueFromEmail}) => {
            return {
              type: GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_SUCCESS,
              edges: colleagueFromEmail.edges
            };
          }),
          catchError((error) => {
            return of({
              type: GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_FAILURE,
              error
            });
          })
        );
      })
    );

    const searchBusiness = searchOrganizationBusinesses(
      {
        action$,
        query,
        state$
      },
      {
        actionType: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH,
        success: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_SUCCESS,
        failure: GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_FAILURE
      }
    );

    const submitInvitation = action$.pipe(
      ofType(GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT),
      mergeMap(() => {
        const colleagueInvitationStore = state$.value.get('colleagueInvitation');
        const emails = colleagueInvitationStore
          .get('colleaguesSelection')
          .filter((colleague) => colleague.get('isOrganizationDomain'))
          .map((colleague) => colleague.get('email'));

        return graphql('invite-users-to-join-organization-mutation', {
          emails,
          businessIds: colleagueInvitationStore
            .get('businessesSelection')
            .map((business) => business.get('id'))
        }).pipe(
          map(() => {
            return {
              type: GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_SUCCESS,
              emails
            };
          }),
          catchError((error) => {
            return of({
              type: GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(addColleagues, searchBusiness, submitInvitation);
  };

export default ColleagueInvitationEpic;
