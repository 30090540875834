export default [
  {
    key: 'android',
    name: 'Android APP',
    osFamily: 'Android',
    storePictureUrl: `${ASSETS_PATH}/img/mobile/get-it-on-google-play.png`,
    storeUrl: 'https://play.google.com/store/apps/details?id=com.instaply'
  },
  {
    key: 'ios',
    name: 'iOS APP',
    osFamily: 'iOS',
    storePictureUrl: `${ASSETS_PATH}/img/mobile/download-on-the-app-store.png`,
    storeUrl: 'https://itunes.apple.com/us/app/instaply/id558562920?mt=8'
  }
];
