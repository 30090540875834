import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import downloadFileFromUrl from '../../../../../shared/lib/download-file-from-url';
import getStandardParamsFromState from './lib/get-standard-params-from-state';
import prepareRequestParams from './lib/prepare-request-params';
import updateSortDirection from './lib/update-sort-direction';
import updateTopStorePayloadFromQueryResponse from './lib/transform-top-store-response';
import {
  INSIGHT_TOP_STORES_CSV_DOWNLOAD,
  INSIGHT_TOP_STORES_CSV_LOAD,
  INSIGHT_TOP_STORES_OPEN,
  INSIGHT_TOP_STORES_SORT_BUTTON_CLICK,
  INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION
} from '../../../actions/insight-actions';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';
import TABLES_DEFINITION, {sortBy} from '../../../data/insights/top-stores-data';

const triggerLoadTablesAction = (state$) => {
  const params = getStandardParamsFromState(state$);

  return Object.entries(TABLES_DEFINITION).map((tableDefinition) => {
    return {
      type: tableDefinition[1].actionTypes[0],
      ...params
    };
  });
};

/**
 * Finally, the epic.
 */
const InsightTopStoresEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(INSIGHT_TOP_STORES_OPEN, INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE),
      mergeMap(() => triggerLoadTablesAction(state$))
    );

    const handleClickSortDirection = action$.pipe(
      ofType(INSIGHT_TOP_STORES_SORT_BUTTON_CLICK),
      map(() => {
        const storedSortDirection = state$.value.getIn([
          'insight',
          'topStores',
          'ranking',
          'sortedDirection'
        ]);

        return {
          type: INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION,
          sortDirection: updateSortDirection(storedSortDirection)
        };
      })
    );

    const updateSortInsights = action$.pipe(
      ofType(INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION),
      mergeMap(() => triggerLoadTablesAction(state$))
    );

    const downloadTopStoresCsv = downloadFileFromUrl(action$, INSIGHT_TOP_STORES_CSV_DOWNLOAD);

    const loadTopStoresCsvToDownload = action$.pipe(
      ofType(INSIGHT_TOP_STORES_CSV_LOAD),
      mergeMap(({classicAggregationDate, classicAggregationPeriod}) => {
        return graphql(
          'get-top-stores-report-query',
          prepareRequestParams(state$, {
            classicAggregationDate,
            classicAggregationPeriod,
            forceStartDate: true
          }),
          {timeout: TIMEOUT_REQUEST_LONG}
        ).pipe(
          map(({topStoresReport}) => {
            return {
              type: INSIGHT_TOP_STORES_CSV_DOWNLOAD,
              url: topStoresReport.url
            };
          })
          // @todo handle failure
        );
      })
    );

    const handleRequestToTopStoresTablesData = ({
      actionTypes,
      classicAggregationDate,
      classicAggregationPeriod,
      epicName,
      sortedDirection
    }) => {
      const paramsForTopStoresPayload = {
        classicAggregationDate,
        classicAggregationPeriod,
        forceStartDate: true,
        orderBy: [{field: sortBy, order: sortedDirection}]
      };

      return graphql(
        `query { topStores { ${epicName} { ranking increment businessId businessName value isBusinessInUserScope } } }`,
        prepareRequestParams(state$, paramsForTopStoresPayload),
        {timeout: TIMEOUT_REQUEST_LONG}
      ).pipe(
        map(({topStores}) => {
          return {
            type: actionTypes[1],
            topStores: updateTopStorePayloadFromQueryResponse(topStores)
          };
        }),
        catchError((error) => {
          return of({
            type: actionTypes[2],
            error
          });
        })
      );
    };

    const loadTopStoresTables = Object.entries(TABLES_DEFINITION).map((topStoresTableElements) => {
      const topStoresTableElement = topStoresTableElements[1];
      const {actionTypes, epicName, sortOrder} = topStoresTableElement;

      return action$.pipe(
        ofType(actionTypes[0]),
        debounceTime(DEBOUNCE_TIME_STATISTIC),
        mergeMap((data) =>
          handleRequestToTopStoresTablesData({
            ...data,
            actionTypes,
            epicName,
            sortOrder
          })
        )
      );
    });

    return merge(
      downloadTopStoresCsv,
      handleClickSortDirection,
      loadInsights,
      loadTopStoresCsvToDownload,
      ...loadTopStoresTables,
      updateSortInsights
    );
  };

export default InsightTopStoresEpic;
