import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ListItem} from '@material-ui/core';
import context from '../../../../../shared/component/context';
import {cleanHighlight} from '../../../lib/list-item-helper';
import {highlight} from '../script/highlight';
import {
  getMarginType,
  getSelectedColor,
  getSelectedDataTestId,
  MARGIN_BETWEEN_SUB_ITEMS,
  SUB_ITEM_TITLE_LINE_HEIGHT
} from './lib/thread-list-item-helper';
import AttachmentPreview from './attachment-preview-component';
import BadgeLayout from '../badge-layout-component';
import CappedBadge from '../capped-badge-component';
import Elapsed from '../elapsed-component';
import EmojiRender from '../emoji/emoji-render';
import ThreadChannel from '../icon/thread-channel';
import UserAvatar from '../avatar/user-avatar-component';
import Waiting from './waiting-component';
import {
  avatarSize,
  fontSize,
  fontWeight,
  radius,
  spacing
} from '../../../../../shared/style/variables';
import {oneline, multiline} from '../../../../../shared/style/ellipsis';
import {
  black,
  blue,
  darkGrey,
  darkSlate,
  mediumGreen,
  white
} from '../../../../../shared/style/colors';

class LegacyCustomerInboxThreadListItemComponent extends PureComponent {
  _renderTakenLabel = () => {
    const {i18n} = this.context;
    const takenLabel = i18n.t('customerInbox.threadList.assignment.takenLabel');

    return (
      <BadgeLayout
        data-test-id="taken-label"
        title={takenLabel}
        style={{
          maxWidth: '100%',
          minHeight: '5px',
          lineHeight: '17px',
          padding: '0 0.5em',
          borderRadius: '18px',
          textAlign: 'left',
          fontSize: '14px',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          margin: '2px 0.4em 0 0',
          backgroundColor: darkGrey,
          color: white
        }}
      >
        {takenLabel}
      </BadgeLayout>
    );
  };

  _selectItem = () => {
    const {
      businessName,
      company,
      customerChannel,
      displayName,
      indexInList,
      messageId,
      participationId,
      pictureHref,
      readOnly,
      selectItem,
      status,
      unreadMessagesCount,
      unreadInternalMessagesCount
    } = this.props;

    selectItem({
      company: company ? cleanHighlight(company) : null,
      customerChannel,
      directedToBusinessName: businessName,
      indexInList,
      messageId,
      participantIcon: pictureHref,
      participantName: cleanHighlight(displayName),
      participationId,
      readOnly,
      status,
      unreadInternalMessagesCount,
      unreadMessagesCount
    });
  };

  _renderChannel(itemContentColor) {
    const {i18n} = this.context;
    const {customerChannel} = this.props;

    return (
      <ThreadChannel
        color={itemContentColor}
        showLabel
        type={customerChannel}
        {...{
          i18n
        }}
        style={{
          marginTop: MARGIN_BETWEEN_SUB_ITEMS,
          height: '20px'
        }}
      />
    );
  }

  render() {
    const {muiTheme, i18n} = this.context;
    const {
      assignedTo,
      centerWrapperStyle,
      company,
      customerChannel,
      displayName,
      enableLasRespondentAvatarInInbox,
      handleCustomerChannel = false,
      isLastChild,
      isSelected,
      lastPublicMessageAttachmentContentType,
      lastPublicMessageBody,
      lastPublicMessageDate,
      lastReplyingUser,
      pendingSinceDate,
      pictureHref,
      rightWrapperStyle,
      sortByWaiting,
      status,
      style,
      unreadInternalMessagesCount,
      unreadMessagesCount
    } = this.props;

    const isWaiting = status === 'waiting';
    const itemHeight = handleCustomerChannel ? '100px' : '80px';
    const lastReplyingUserAvatarBottomPosition = handleCustomerChannel ? '30px' : '10px';
    const colorSelected = getSelectedColor(isSelected);
    const itemContentColor = isWaiting || colorSelected;
    const marginType = getMarginType(isLastChild);
    const waitingBackground = isWaiting ? white : 'transparent';
    const itemBackgroundColor = isSelected ? muiTheme.palette.selectedColor : waitingBackground;
    const selectedDataTestId = getSelectedDataTestId(isSelected);

    return (
      <div
        data-test-id="customer-list-item"
        style={{
          marginBottom: marginType,
          borderRadius: radius.medium,
          backgroundColor: itemBackgroundColor
        }}
      >
        <ListItem
          button
          data-test-id={selectedDataTestId}
          onClick={this._selectItem}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: itemHeight,
            maxHeight: itemHeight,
            padding: 0,
            boxSizing: 'border-box',
            borderRadius: radius.medium,
            ...style
          }}
        >
          <div
            style={{
              position: 'relative',
              padding: '10px 10px 10px 5px'
            }}
          >
            <UserAvatar size={avatarSize.large} src={pictureHref} />
            {enableLasRespondentAvatarInInbox && lastReplyingUser ? (
              <UserAvatar
                data-test-id="last-replying-user-avatar"
                size={avatarSize.medium}
                src={lastReplyingUser.pictureHref}
                title={lastReplyingUser.displayName}
                userIconStyle={{
                  border: '1px solid white'
                }}
                style={{
                  position: 'absolute',
                  bottom: lastReplyingUserAvatarBottomPosition,
                  right: '5px'
                }}
              />
            ) : null}
          </div>

          <div
            style={{
              flex: '1',
              padding: '19px 10px 10px 0',
              overflow: 'hidden',
              height: '100px',
              ...centerWrapperStyle
            }}
          >
            <h2
              style={{
                marginBottom: MARGIN_BETWEEN_SUB_ITEMS,
                lineHeight: SUB_ITEM_TITLE_LINE_HEIGHT,
                fontSize: fontSize.xlarge,
                fontWeight: isWaiting || isSelected ? fontWeight.semiBold : fontWeight.normal,
                color: isWaiting ? black : darkSlate,
                ...oneline
              }}
            >
              {assignedTo ? this._renderTakenLabel() : null}
              <span data-test-id="title">{highlight(displayName)}</span>
              {company ? (
                <React.Fragment>
                  , <span data-test-id="company-name">{highlight(company)}</span>
                </React.Fragment>
              ) : null}
            </h2>
            {!lastPublicMessageBody && lastPublicMessageAttachmentContentType ? (
              <AttachmentPreview {...{contentType: lastPublicMessageAttachmentContentType, i18n}} />
            ) : (
              <div
                data-test-id="message"
                style={{
                  maxHeight: '40px',
                  lineHeight: '18px',
                  fontSize: fontSize.medium,
                  color: itemContentColor,
                  ...multiline(2)
                }}
              >
                <EmojiRender transformer={highlight}>{lastPublicMessageBody}</EmojiRender>
              </div>
            )}
            {handleCustomerChannel && customerChannel
              ? this._renderChannel(itemContentColor)
              : null}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100px',
              lineHeight: '23px',
              padding: `20px ${spacing.medium} 0 ${spacing.medium}`,
              ...rightWrapperStyle
            }}
          >
            {sortByWaiting && isWaiting ? (
              <Waiting value={pendingSinceDate} />
            ) : (
              <Elapsed value={lastPublicMessageDate} i18nPrefix="inbox.list.item" />
            )}

            <div
              data-test-id="notifications"
              style={{
                display: 'flex',
                flex: '0 1 50%',
                justifyContent: 'flex-end',
                alignItems: 'self-start'
              }}
            >
              {unreadMessagesCount ? (
                <CappedBadge
                  data-test-id="unread-thread"
                  title={i18n.t('customerInbox.pendingUnreadMessagesVisibleThread')}
                  number={unreadMessagesCount}
                  badgeStyle={{
                    backgroundColor: blue,
                    color: white,
                    borderColor: blue
                  }}
                />
              ) : null}
              {unreadInternalMessagesCount ? (
                <CappedBadge
                  data-test-id="unread-invisible-thread"
                  number={unreadInternalMessagesCount}
                  title={i18n.t('customerInbox.pendingUnreadMessagesInvisibleThread')}
                  badgeStyle={{
                    marginLeft: '5px',
                    backgroundColor: mediumGreen,
                    color: white,
                    borderColor: mediumGreen
                  }}
                />
              ) : null}
            </div>
          </div>
        </ListItem>
      </div>
    );
  }
}

LegacyCustomerInboxThreadListItemComponent.contextTypes = context;

LegacyCustomerInboxThreadListItemComponent.propTypes = {
  assignedTo: PropTypes.objectOf(PropTypes.any),
  businessName: PropTypes.string,
  centerWrapperStyle: PropTypes.objectOf(PropTypes.any),
  company: PropTypes.string,
  customerChannel: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  enableLasRespondentAvatarInInbox: PropTypes.bool,
  handleCustomerChannel: PropTypes.bool,
  indexInList: PropTypes.number.isRequired,
  isLastChild: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  lastPublicMessageAttachmentContentType: PropTypes.string,
  lastPublicMessageBody: PropTypes.string.isRequired,
  lastPublicMessageDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lastReplyingUser: PropTypes.objectOf(PropTypes.any),
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  participationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pictureHref: PropTypes.string,
  pendingSinceDate: PropTypes.string,
  readOnly: PropTypes.bool,
  rightWrapperStyle: PropTypes.objectOf(PropTypes.any),
  selectItem: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  sortByWaiting: PropTypes.bool,
  status: PropTypes.string,
  unreadMessagesCount: PropTypes.number,
  unreadInternalMessagesCount: PropTypes.number
};

export default LegacyCustomerInboxThreadListItemComponent;
