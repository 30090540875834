import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import {BUTTON_LABEL_STYLE} from '../../style/theme-v0';
import {fontSize, fontWeight, radius} from '../../style/variables';

export const BUTTON_STYLE = {
  borderRadius: radius.xxlarge,
  boxShadow: 'none'
};

const StandardRaisedButtonComponent = ({style, ...props}) => (
  <RaisedButton
    data-test-id="submit-button"
    primary
    type="submit"
    {...props}
    buttonStyle={BUTTON_STYLE}
    style={{
      width: '100%',
      ...BUTTON_STYLE,
      ...style
    }}
    labelStyle={{
      fontSize: fontSize.xlarge,
      fontWeight: fontWeight.semiBold,
      ...BUTTON_LABEL_STYLE
    }}
  />
);

StandardRaisedButtonComponent.propTypes = {
  style: PropTypes.objectOf(PropTypes.any)
};

export default StandardRaisedButtonComponent;
