import {of} from 'rxjs';
import {catchError, delay, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_FAILURE,
  CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';

const LegacyCustomerThreadSurveyEpic =
  ({graphql}) =>
  (action$) => {
    return action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS),
      delay(300),
      mergeMap(({data}) => {
        return graphql('get-business-survey-configuration-query', {
          businessId: data.directedToBusinessIdentifier
        }).pipe(
          map(({businessSurveyConfiguration}) => {
            return {
              type: CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_SUCCESS,
              businessSurveyConfiguration
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );
  };

export default LegacyCustomerThreadSurveyEpic;
