import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import ColleagueComponent from '../../../component/content/colleague/colleague-component';
import {
  COLLEAGUE_INBOX_LEAVE,
  COLLEAGUE_INBOX_NEW_THREAD_CANCEL,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET,
  COLLEAGUE_INBOX_NEW_THREAD_SHOW,
  COLLEAGUE_INBOX_NEW_THREAD_START,
  COLLEAGUE_INBOX_THREADS_LIST_LOAD,
  COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../actions/colleague-inbox-actions';
import {GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_NEW_COLLEAGUE_THREAD} from '../../../actions/global-actions';

const ColleagueContainer = connect(
  createSelector([(state) => state.get('colleagueInbox')], (colleagueInbox) => {
    return {
      colleagueInbox: colleagueInbox.toJS()
    };
  }),
  {
    doLeaveColleagueInbox() {
      return {
        type: COLLEAGUE_INBOX_LEAVE
      };
    },
    doLoadColleagueInboxThreads(after) {
      return {
        type: COLLEAGUE_INBOX_THREADS_LIST_LOAD,
        after
      };
    },
    doSelectColleagueThread({participantName, participationId}) {
      return {
        type: COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD,
        _googleAnalytics: true,
        participantName,
        participationId
      };
    },
    doCancelStartNewThreadWithColleague() {
      return {
        type: COLLEAGUE_INBOX_NEW_THREAD_CANCEL,
        _googleAnalytics: true
      };
    },
    doShowStartNewThreadWithColleague() {
      return {
        type: COLLEAGUE_INBOX_NEW_THREAD_SHOW,
        _googleAnalytics: true
      };
    },
    doSearchColleagueToStartThread(text) {
      return {
        type: COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE,
        _googleAnalytics: true,
        text
      };
    },
    doSearchColleagueToStartThreadInputReset() {
      return {
        type: COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET,
        _googleAnalytics: true
      };
    },
    doSelectItemOnColleagueSearch({displayName, participationId, userId}) {
      if (participationId) {
        return {
          type: COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD,
          _googleAnalytics: true,
          participantName: displayName,
          participationId
        };
      }

      return {
        type: COLLEAGUE_INBOX_NEW_THREAD_START,
        _googleAnalytics: true,
        displayName,
        userId
      };
    },
    // colleague invitation
    doColleagueInvitationPopupOpen() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_NEW_COLLEAGUE_THREAD,
        _googleAnalytics: true
      };
    }
  }
)(ColleagueComponent);

export default ColleagueContainer;
