import PropTypes from 'prop-types';
import StandardButton from './standard-button-component';
import {blue} from '../../style/colors';

const OutlinedButtonComponent = ({children, color = blue, ...otherProps}) => (
  <StandardButton
    {...otherProps}
    style={{
      border: `1px solid ${color}`,
      color
    }}
  >
    {children}
  </StandardButton>
);

OutlinedButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  'data-test-id': PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default OutlinedButtonComponent;
