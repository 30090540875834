import {merge, of} from 'rxjs';
import {catchError, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import handleErrors from '../../../shared/epic/lib/handle-errors';
import {SIGNIN_LOGIN_SUBMIT} from '../../signin/actions/signin-login-actions';
import {
  SIGNUP_GET_PENDING_INVITATION,
  SIGNUP_GET_PENDING_INVITATION_FAILURE,
  SIGNUP_GET_PENDING_INVITATION_SUCCESS,
  SIGNUP_SUBMIT,
  SIGNUP_SUBMIT_FAILURE,
  SIGNUP_SUBMIT_SUCCESS
} from '../actions/signup-actions';
import {SIGNUP_SUCCESS_CONFIRMATION_SCREEN_DURATION} from '../data/settings';

const ERROR_MAPPING_AUTOJOIN = {
  40001: 'errorEmailExists',
  40310: 'errorCustomerServiceDisabled',
  40400: 'errorDomainNotInInstaply'
};

const ERROR_MAPPING_INVITATION = {
  409: 'errorEmailExists',
  40101: 'errorInvalidOrExpiredToken',
  40102: 'errorInvalidTokenType'
};

const handleSignUpFormSubmission = (
  isFormValid,
  graphql,
  graphqlMutation,
  graphqlVariables,
  email,
  errorMapping
) => {
  if (!isFormValid) {
    return of({
      type: SIGNUP_SUBMIT_FAILURE,
      errors: []
    });
  }

  return graphql(graphqlMutation, graphqlVariables).pipe(
    map(() => ({
      type: SIGNUP_SUBMIT_SUCCESS,
      password: graphqlVariables.password,
      email
    })),
    catchError((errors) => {
      return of({
        type: SIGNUP_SUBMIT_FAILURE,
        errors: handleErrors(errors, errorMapping)
      });
    })
  );
};

/**
 * The epic itself.
 */
const SignUpEpic =
  ({graphql}) =>
  (action$) => {
    const getPendingInvitationToSignUp = action$.pipe(
      ofType(SIGNUP_GET_PENDING_INVITATION),
      mergeMap(({token}) => {
        return graphql('get-user-invitation-query', {invitationToken: token}).pipe(
          map(({userInvitation}) => {
            return {
              type: SIGNUP_GET_PENDING_INVITATION_SUCCESS,
              userInvitation
            };
          }),
          catchError((errors) => {
            return of({
              type: SIGNUP_GET_PENDING_INVITATION_FAILURE,
              errors: handleErrors(errors, ERROR_MAPPING_INVITATION)
            });
          })
        );
      })
    );

    const signInAutomaticallyAfterSignUpSuccess = action$.pipe(
      ofType(SIGNUP_SUBMIT_SUCCESS),
      delay(SIGNUP_SUCCESS_CONFIRMATION_SCREEN_DURATION),
      map(({email, password}) => ({
        type: SIGNIN_LOGIN_SUBMIT,
        keepSignedIn: false,
        isFormValid: true,
        email,
        password
      }))
    );

    const submitSignUpFromAutoJoin = action$.pipe(
      ofType(SIGNUP_SUBMIT),
      filter(({token}) => !token),
      mergeMap(({email, firstName, lastName, locale, password, isFormValid}) =>
        handleSignUpFormSubmission(
          isFormValid,
          graphql,
          'sign-up-new-employee-mutation',
          {
            email,
            firstName,
            lastName,
            locale,
            password
          },
          email,
          ERROR_MAPPING_AUTOJOIN
        )
      )
    );

    const submitSignUpFromInvitation = action$.pipe(
      ofType(SIGNUP_SUBMIT),
      filter(({token}) => token),
      mergeMap(({email, firstName, lastName, password, token, isFormValid}) =>
        handleSignUpFormSubmission(
          isFormValid,
          graphql,
          'user-accept-invitation-to-join-organization-mutation',
          {
            invitationToken: token,
            firstName,
            lastName,
            password
          },
          email,
          ERROR_MAPPING_INVITATION
        )
      )
    );

    return merge(
      getPendingInvitationToSignUp,
      signInAutomaticallyAfterSignUpSuccess,
      submitSignUpFromAutoJoin,
      submitSignUpFromInvitation
    );
  };

export default SignUpEpic;
