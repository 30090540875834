import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {List, makeSelectable} from 'material-ui/List';
import {InboxHeaderTitle} from '../inbox/inbox-header-components';
import LeftPanelLayout from './left-panel-layout-component';

export const LEFT_LIST_ITEM_STYLE = {
  margin: '1px 10px'
};

const SelectableList = makeSelectable(List);

/**
 * Finally, the component.
 */
class LeftPanelWithMenuLayoutComponent extends PureComponent {
  render() {
    const {children, path, title} = this.props;

    return (
      <LeftPanelLayout style={{maxWidth: '300px', overflowY: 'scroll'}}>
        <InboxHeaderTitle>{title}</InboxHeaderTitle>

        <SelectableList value={path}>{children}</SelectableList>
      </LeftPanelLayout>
    );
  }
}

LeftPanelWithMenuLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default LeftPanelWithMenuLayoutComponent;
