import {black, veryLightGreen, white} from '../../../../../../shared/style/colors';

const PADDING_MAPPING = {
  noBorder: '8px 10px',
  withBorder: '7px 9px'
};

const commonColleagueMessageStyle = {
  backgroundColor: veryLightGreen,
  color: black,
  padding: PADDING_MAPPING.withBorder
};

const colleagueBubbleStyle = {
  ColleagueOutgoing: commonColleagueMessageStyle,
  MineOutgoing: commonColleagueMessageStyle,
  StandardIncoming: {
    backgroundColor: white,
    color: black,
    padding: PADDING_MAPPING.withBorder
  }
};

const customContainerScrollableThreadMessagesListStylesPerThreadType = {
  colleague: {
    backgroundImage: `url(${ASSETS_PATH}/img/thread/colleague-background.png)`,
    backgroundRepeat: 'repeat',
    backgroundSize: '200px'
  }
};

const getThreadContainerScrollableThreadMessagesListStyles = ({inputHeight, threadType}) => {
  const commonStyles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: `calc(100% - ${inputHeight}px)`,
    minHeight: '1px',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch'
  };

  const customStyles = customContainerScrollableThreadMessagesListStylesPerThreadType[threadType];
  if (customStyles) {
    return {
      ...commonStyles,
      ...customStyles
    };
  }

  return commonStyles;
};

const getColleagueBubbleStyles = (presentationType) => {
  return colleagueBubbleStyle[presentationType];
};

export {
  getColleagueBubbleStyles,
  getThreadContainerScrollableThreadMessagesListStyles,
  PADDING_MAPPING
};
