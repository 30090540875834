import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD,
  ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_FAILURE,
  ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_SUCCESS
} from '../../../actions/administration-actions';
import {ADMINISTRATION_BUSINESS_OPEN} from '../../../actions/administration-business-actions';

export const DEFAULT_STATE = {
  currentPageContext: {},
  organizationCountries: {
    items: [],
    loading: true
  }
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  // on some page opening
  [ADMINISTRATION_BUSINESS_OPEN]: (state, {businessId}) =>
    state.set('currentPageContext', fromJS({businessId})),

  // left menu
  [ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD]: (state) =>
    state.setIn(['organizationCountries', 'loading'], true),

  [ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_FAILURE]: (state) =>
    state.setIn(['organizationCountries', 'loading'], false),

  [ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_SUCCESS]: (state, {countries}) =>
    state.update('organizationCountries', (organizationCountries) =>
      organizationCountries.set('items', fromJS(countries)).set('loading', false)
    )
});
