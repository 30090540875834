import {merge} from 'rxjs';
import {map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {COLLEAGUE_THREAD_SEND_MESSAGE_RETRY} from '../../actions/colleague-thread-actions';
import {
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_RETRY
} from '../../actions/customer-thread-invisible-actions';
import {
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY
} from '../../actions/customer-thread-visible-actions';

const errorEpic = () => (action$) => {
  const retryFailedAction = action$.pipe(
    ofType(
      COLLEAGUE_THREAD_SEND_MESSAGE_RETRY,
      CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY,
      CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY,
      LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_RETRY,
      LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY
    ),
    map(({failedAction}) => failedAction)
  );

  return merge(retryFailedAction);
};

export default errorEpic;
