import PropTypes from 'prop-types';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import DoneIcon from 'material-ui/svg-icons/action/done';
import {fontSize, iconSize, lineHeight} from '../../../../../shared/style/variables';
import {darkSmoke, green, lightSmoke} from '../../../../../shared/style/colors';

const itemPadding = '15px';

const SelectionItemComponent = ({
  avatar,
  color,
  dataTestId,
  index,
  item,
  itemsCount,
  status,
  onDeleteClick
}) => {
  const renderRemoveSelectionItemButton = () => {
    return onDeleteClick ? (
      <CloseIcon
        data-test-id="remove-button"
        color={darkSmoke}
        onClick={onDeleteClick}
        style={{
          margin: '0 4px',
          width: iconSize.medium,
          height: iconSize.medium,
          minWidth: iconSize.medium,
          minHeight: iconSize.medium,
          cursor: 'pointer'
        }}
      />
    ) : null;
  };

  return (
    <li
      data-test-id={dataTestId}
      style={{
        display: 'flex',
        paddingTop: itemPadding
      }}
    >
      {item.checked ? null : avatar}
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginLeft: '10px',
          paddingBottom: itemPadding,
          [index === itemsCount - 1 ? undefined : 'borderBottom']: `1px solid ${lightSmoke}`
        }}
      >
        <span
          data-test-id="name"
          style={{
            width: '100%',
            lineHeight: lineHeight.xlarge,
            fontSize: fontSize.xlarge,
            [color ? 'color' : undefined]: color
          }}
        >
          {item.name ? item.name : item.email}
        </span>
        {status ? (
          <span
            data-test-id="status"
            style={{
              position: 'relative',
              top: '3px',
              fontStyle: 'italic'
            }}
          >
            {status}
          </span>
        ) : null}
        {item.checked ? (
          <DoneIcon data-test-id="invited-colleague" color={green} />
        ) : (
          renderRemoveSelectionItemButton()
        )}
      </div>
    </li>
  );
};

SelectionItemComponent.propTypes = {
  avatar: PropTypes.objectOf(PropTypes.any).isRequired,
  color: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  itemsCount: PropTypes.number.isRequired,
  status: PropTypes.node,
  onDeleteClick: PropTypes.func
};

export default SelectionItemComponent;
