import {merge, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {conditionalReloadOfHeaderMentionsPopupMissedItems} from './lib/header-mention-epic-helper';
import {filterByMultistoreConversationEnabled} from '../../lib/feature-toggle-helper';
import {graphqlGetEmployeeMentionsQuery} from './graphql-queries/graphql-header-mention-query-helper';
import {APP_STATE_REFRESH_LOAD_SUCCESS} from '../../actions/app-actions';
import {CUSTOMER_CONVERSATION_URL_GO} from '../../actions/customer-thread-actions';
import {CUSTOMER_THREAD_PROFILE_CLOSE} from '../../actions/customer-thread-profile-actions';
import {
  HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ,
  HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_FAILURE,
  HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_SUCCESS,
  HEADER_MENTIONS_POPUP_CLOSE,
  HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ,
  HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_FAILURE,
  HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_SUCCESS,
  HEADER_MENTIONS_POPUP_ITEM_CLICK,
  HEADER_MENTIONS_POPUP_ITEM_OPEN,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD_FAILURE,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS,
  HEADER_MENTIONS_POPUP_OPEN,
  LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN
} from '../../actions/header-actions';
import {HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE} from '../../data/settings';

const HeaderMentionEpic =
  ({graphql}) =>
  (action$, state$) => {
    const closeCustomerThreadProfileOnHeaderMentionsPopupItemClick = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEM_CLICK),
      filter(() => state$.value.getIn(['customer', 'thread', 'profile', 'showDrawer'])),
      map(() => ({
        type: CUSTOMER_THREAD_PROFILE_CLOSE
      }))
    );

    const closeMentionsPopupAndOpenItemOnHeaderMentionsPopupItemClick = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEM_CLICK),
      filter(filterByMultistoreConversationEnabled(state$)),
      mergeMap(({payload: {cursor}}) => {
        const itemNode = state$.value
          .getIn(['headerMention', 'edges'])
          .find((edge) => edge.get('cursor') === cursor)
          .get('node');

        return [
          {
            type: HEADER_MENTIONS_POPUP_CLOSE
          },
          {
            type: HEADER_MENTIONS_POPUP_ITEM_OPEN,
            payload: {
              conversation: {
                id: itemNode.getIn(['conversation', 'id'])
              },
              customer: {
                company: itemNode.getIn(['customer', 'company']),
                displayName: itemNode.getIn(['customer', 'displayName']),
                id: itemNode.getIn(['customer', 'id'])
              },
              message: {
                cursor: itemNode.getIn(['message', 'cursor'])
              },
              unreadMentionId: itemNode.get('isRead') ? null : itemNode.get('id')
            }
          }
        ];
      })
    );

    const goToCustomerConversationOnHeaderMentionsPopupItemOpen = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEM_OPEN),
      map(({payload: {conversation, customer, message}}) => ({
        type: CUSTOMER_CONVERSATION_URL_GO,
        payload: {
          conversation,
          customer,
          queryStringParams: {imc: message.cursor}
        }
      }))
    );

    const loadHeaderMentionsPopupItems = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEMS_LOAD),
      filter(filterByMultistoreConversationEnabled(state$)),
      mergeMap(({payload: {after, before}}) => {
        return graphql(
          graphqlGetEmployeeMentionsQuery({
            // In case of failure, we try to reload a bit more more than just 1 item, but without going over current edges page size
            first: HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE,
            after,
            before
          })
        ).pipe(
          map(({employeeMentions}) => ({
            type: HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS,
            payload: {
              after,
              before,
              mentions: employeeMentions
            }
          })),
          catchError((error) =>
            of({
              type: HEADER_MENTIONS_POPUP_ITEMS_LOAD_FAILURE,
              error
            })
          )
        );
      })
    );

    // Shared with legacy
    const loadMentionsOnHeaderMentionsPopupOpen = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_OPEN),
      map(() => ({
        type: HEADER_MENTIONS_POPUP_ITEMS_LOAD,
        payload: {}
      }))
    );

    // Shared with legacy
    const markOneMentionAsRead = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ),
      mergeMap(({payload: {mentionId}}) => {
        return graphql('mark-mention-as-read-mutation', {mentionId}).pipe(
          map(() => ({
            type: HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_SUCCESS
          })),
          catchError((error) =>
            of({
              type: HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_FAILURE,
              error
            })
          )
        );
      })
    );

    // Shared with legacy
    const markOneMentionAsReadOnHeaderMentionsPopupItemOpen = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEM_OPEN, LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN),
      filter(({payload: {unreadMentionId}}) => unreadMentionId),
      map(({payload: {unreadMentionId}}) => ({
        type: HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ,
        payload: {
          mentionId: unreadMentionId
        }
      }))
    );

    // Shared with legacy
    const markUserHasNewMentionsAsRead = action$.pipe(
      ofType(HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ),
      mergeMap(() => {
        return graphql('mark-user-has-new-mentions-as-read-mutation').pipe(
          map(() => ({
            type: HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_SUCCESS
          })),
          catchError((error) =>
            of({
              type: HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_FAILURE,
              error
            })
          )
        );
      })
    );

    // Shared with legacy
    const markUserHasNewMentionsAsReadOnHeaderMentionsPopupItemsLoadSuccess = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS),
      filter(({payload: {after, mentions}}) => !after && mentions.edges.length > 0),
      map(() => ({
        type: HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ
      }))
    );

    // Shared with legacy
    const reloadHeaderMentionsPopupMissedItemsOnAppInitialStateReload =
      conditionalReloadOfHeaderMentionsPopupMissedItems(
        state$,
        action$,
        APP_STATE_REFRESH_LOAD_SUCCESS,
        ({data, oldData}) => data.unreadMentionsCount !== oldData.unreadMentionsCount
      );

    return merge(
      closeCustomerThreadProfileOnHeaderMentionsPopupItemClick,
      closeMentionsPopupAndOpenItemOnHeaderMentionsPopupItemClick,
      goToCustomerConversationOnHeaderMentionsPopupItemOpen,
      loadHeaderMentionsPopupItems,
      loadMentionsOnHeaderMentionsPopupOpen,
      markOneMentionAsRead,
      markOneMentionAsReadOnHeaderMentionsPopupItemOpen,
      markUserHasNewMentionsAsRead,
      markUserHasNewMentionsAsReadOnHeaderMentionsPopupItemsLoadSuccess,
      reloadHeaderMentionsPopupMissedItemsOnAppInitialStateReload
    );
  };

export default HeaderMentionEpic;
