/**
 * Useful miscellaneous settings.
 */
export const DEBOUNCE_TIME_MULTIPLE_CLICK = 100;
export const DEBOUNCE_TIME_TYPING_FILTER = 300;

export const DELAY_ANIMATION_HIDE = 200;

export const DELAY_TRANSFER_BACK_IN_SCOPE_REDIRECT = 500; // 0,5 second

export const FILE_UPLOAD_MAX_SIZE_DEFAULT = 1048576 * 10; // 10 Mo
export const FILE_UPLOAD_MAX_SIZE_EXCEEDED_TIMEOUT = 30000; // 30s

export const SNACKBAR_HIDE_DEBOUNCE_TIME = 3000; // 3s

export const TIMEOUT_REQUEST_DEFAULT = 30000;
export const TIMEOUT_REQUEST_LONG = 60000;
