import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import assert from '../../../../../shared/lib/assert';
import {
  pushMessage,
  sendMessage,
  sendMessageFail,
  sendMessageSuccessful
} from '../../lib/message-helper';
import {
  DEFAULT_STATE_THREAD,
  checkMessageForm,
  failUploadFile,
  persistMessageFormText,
  removeUploadedFile,
  startUploadFile,
  succeedUploadFile
} from '../../lib/message-form-helper';
import createThreadFromMessage from '../../lib/thread-helper';
import {deletePersistedState, resetNotPersistedState} from '../../lib/persistence-helper';
import {APP_LOGOUT} from '../../../actions/app-actions';
import {
  COLLEAGUE_INBOX_LEAVE,
  COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../actions/colleague-inbox-actions';
import {
  COLLEAGUE_NEW_THREAD_LOAD,
  COLLEAGUE_NEW_THREAD_LOAD_SUCCESS,
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE,
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS,
  COLLEAGUE_THREAD_FILE_REMOVE,
  COLLEAGUE_THREAD_FILE_UPLOAD,
  COLLEAGUE_THREAD_FILE_UPLOAD_FAILURE,
  COLLEAGUE_THREAD_FILE_UPLOAD_SUCCESS,
  COLLEAGUE_THREAD_LEAVE,
  COLLEAGUE_THREAD_LOAD,
  COLLEAGUE_THREAD_LOAD_SUCCESS,
  COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_PERSIST,
  COLLEAGUE_THREAD_SEND_MESSAGE,
  COLLEAGUE_THREAD_SEND_MESSAGE_FAILURE,
  COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS
} from '../../../actions/colleague-thread-actions';
import {EXT_COLLEAGUE_SENT_MESSAGE_TO_ME} from '../../../actions/ext-actions';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_THREAD,
  ...{
    messages: [],
    participantId: null,
    participantName: null
  }
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Reset state when leaving an inbox
   * in order to prevent badges from not being correctly updated
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [COLLEAGUE_INBOX_LEAVE]: (state) => {
    return resetNotPersistedState(state, DEFAULT_STATE);
  },

  /**
   * Reset state when leaving thread component
   * in order to prevent badges from not being correctly updated
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LEAVE]: (state) => {
    return resetNotPersistedState(state, DEFAULT_STATE);
  },

  /**
   * Optimistic update of the thread using data from the thread list
   *
   * @param {Object} state
   * @param {Object} participantName
   * @returns {Object} new state
   */
  [COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD]: (state, {participantName}) => {
    return resetNotPersistedState(state, DEFAULT_STATE)
      .set('loading', true)
      .set('participantName', participantName);
  },

  /**
   * Triggered when user request a thread
   *
   * @param {Object} state
   * @param {String} direction
   * @param {String} participationId
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LOAD]: (state, {direction, participationId}) => {
    const isLoading = !/before|after/.test(direction);

    const newState = state
      .set('direction', direction)
      .set('loading', isLoading)
      .set('loadingBefore', direction === 'before')
      .set('participationId', participationId);

    if (isLoading) {
      return checkMessageForm(newState);
    }

    return newState;
  },

  /**
   * Triggered when the colleague thread is successfully loaded from the server
   *
   * @param {Object} state
   * @param {Object} data resulting of the get-conversation resource
   * @param {Object} params
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LOAD_SUCCESS]: (state, {data, params}) => {
    const loadedMessages = fromJS(
      data.messages
        // ---
        // Temporary fix to hide org icon. Will be removed when using new dedicated query to load colleague thread
        .map((message) => Object.assign(message, {orgIcon: undefined}))
    );
    // ---

    const newState = state
      .merge(fromJS(data))
      .set('direction', params.direction)
      .set('loading', false)
      .set('loadingBefore', false);
    if (params.direction === 'before') {
      return newState.set('messages', loadedMessages.concat(state.get('messages')));
    }

    const {fromId} = params;
    if (fromId) {
      return newState.updateIn(['messages'], (messages) => {
        const foundMessageIndex = messages.findIndex((message) => message.get('id') == fromId); // eslint-disable-line eqeqeq

        return foundMessageIndex > -1
          ? messages.update(foundMessageIndex, (message) => message.set('selected', true))
          : messages;
      });
    }

    return newState;
  },

  /**
   * Triggered when user load a new thread
   *
   * @param {Object} state
   * @param {String} displayName
   * @param {String} userId
   * @returns {Object} new state
   */
  [COLLEAGUE_NEW_THREAD_LOAD]: (state, {displayName, userId}) => {
    return checkMessageForm(
      resetNotPersistedState(state, DEFAULT_STATE)
        .set('loading', true)
        .set('participantId', userId)
        .set('participantName', displayName)
        .set('participationId', 'new')
    );
  },

  /**
   * Triggered when user successfully load a new thread.
   * Even if it's seems useless, we need this to force re-rendering of message input
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [COLLEAGUE_NEW_THREAD_LOAD_SUCCESS]: (state) => {
    return state.set('loading', false);
  },

  /**
   * File upload handling
   */
  [COLLEAGUE_THREAD_FILE_REMOVE]: removeUploadedFile,
  [COLLEAGUE_THREAD_FILE_UPLOAD]: startUploadFile,
  [COLLEAGUE_THREAD_FILE_UPLOAD_SUCCESS]: succeedUploadFile,
  [COLLEAGUE_THREAD_FILE_UPLOAD_FAILURE]: failUploadFile,

  /**
   * Send message handling.
   * 1st action is an optimistic update, with a temporary identifier
   * to the message so it can be updated in the <..._SUCCESS> action.
   */
  [COLLEAGUE_THREAD_SEND_MESSAGE]: (
    state,
    {attachment, clientMessageId, fullName, mentionedUser, picture, text}
  ) => {
    assert(fullName);

    return sendMessage(state, {
      presentationType: 'MineOutgoing',
      mentionedUser,
      message: {
        attachment,
        clientMessageId,
        text
      },
      user: {
        fullName,
        userIcon: picture
      }
    });
  },
  [COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS]: sendMessageSuccessful,
  [COLLEAGUE_THREAD_SEND_MESSAGE_FAILURE]: sendMessageFail,

  /**
   * Triggered when user send a message in a new thread
   *
   * @param {Object} state
   * @param {Object} attachment
   * @param {String} clientMessageId
   * @param {String} colleagueName
   * @param {Number} colleagueId
   * @param {String} fullName
   * @param {String} text
   * @returns {Object} new state
   */
  [COLLEAGUE_NEW_THREAD_SEND_MESSAGE]: (
    state,
    {attachment, clientMessageId, colleagueName, colleagueId, fullName, text}
  ) => {
    const newState = state.set('participantId', colleagueId).set('participantName', colleagueName);

    return sendMessage(newState, {
      presentationType: 'MineOutgoing',
      message: {
        clientMessageId,
        text,
        attachment
      },
      user: {
        fullName
      }
    });
  },

  /**
   * The first message was sent successfully.
   */
  [COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS]: createThreadFromMessage,

  /**
   * A colleague sent a message to me
   *
   * - filter out message sent on other channels
   * - avoid duplicate
   * - add the new message to the conversation
   *
   * @param {Object} state
   * @param {Object} data the socket action
   * @returns {Object} new state
   */
  [EXT_COLLEAGUE_SENT_MESSAGE_TO_ME]: (state, {data}) => {
    // filtering
    /* eslint-disable eqeqeq */
    if (data.receiverParticipationId != state.get('participationId')) {
      return state;
    }

    // deduplicating
    if (state.get('messages').findIndex((message) => message.get('id') == data.id) !== -1) {
      return state;
    }
    /* eslint-enable eqeqeq */

    // insert the message
    return state.updateIn(['messages'], (messages) => {
      return pushMessage(data, messages, {
        presentationType: 'StandardIncoming',
        senderName: data.senderName
      });
    });
  },

  /**
   * Persist message form text.
   *
   * @param {Object} state
   * @param {String} participationId
   * @param {String} text
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_PERSIST]: persistMessageFormText,

  /**
   * Clean storage on logout.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_LOGOUT]: deletePersistedState
});
