import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from 'material-ui/svg-icons/editor/insert-drive-file';
import MovieIcon from 'material-ui/svg-icons/av/movie';
import FileWeight from '../../file/file-weight-component';
import ImageComponent from '../../image-component';
import {iconSize} from '../../../../../../shared/style/variables';
import {black, white} from '../../../../../../shared/style/colors';

class MessageAttachmentComponent extends React.PureComponent {
  _onClickDownloadButton = () => {
    const {href, doThreadFilePreviewOpen} = this.props;
    doThreadFilePreviewOpen(href);
  };

  _renderAsDownloadableButton = (iconType) => {
    const {containerStyle, contentLength, href, invisibleThread, linkStyle, name} = this.props;

    return (
      <span
        data-test-id="conversation-message-attachment-file-download-button"
        style={{
          display: 'flex',
          cursor: 'pointer',
          ...containerStyle
        }}
      >
        {React.createElement(iconType, {
          style: {
            width: iconSize.medium,
            height: iconSize.medium,
            marginRight: '5px',
            color: invisibleThread ? black : white
          }
        })}
        <span>
          <a href={href} style={linkStyle}>
            {name}
          </a>
          <FileWeight contentLength={contentLength} />
        </span>
      </span>
    );
  };

  // Most of images are cropped, so they are rendered as a square
  _renderAsImage = () => {
    const {containerStyle, href, invisibleThread, wrapperBubbleWidth} = this.props;

    const imageSize = wrapperBubbleWidth - 20;
    const imageSizePx = `${imageSize}px`;
    const loaderColor = invisibleThread ? null : white;
    const src = `${href}?width=${imageSize}&height=${imageSize}`;

    return (
      <ImageComponent
        data-test-id="conversation-message-attachment-picture-fullscreen-button"
        containerStyle={{
          ...containerStyle,
          height: imageSizePx,
          width: imageSizePx
        }}
        imgStyle={{
          borderRadius: '10px',
          cursor: 'pointer',
          width: '100%'
        }}
        onClick={this._onClickDownloadButton}
        {...{
          loaderColor,
          src
        }}
      />
    );
  };

  _renderAsSticker = () => {
    const {containerStyle, href} = this.props;
    const imageSizePx = iconSize.xxlarge;

    return (
      <ImageComponent
        data-test-id="sticker-picture"
        containerStyle={{
          ...containerStyle,
          height: imageSizePx,
          width: imageSizePx
        }}
        src={href}
      />
    );
  };

  render() {
    const {contentType, sticker} = this.props;

    const attachmentType = contentType.match(/^(.*?)\//i)[1];

    switch (attachmentType) {
      case 'image':
        return sticker ? this._renderAsSticker() : this._renderAsImage();
      case 'video':
        return this._renderAsDownloadableButton(MovieIcon);
      default:
        break;
    }

    return this._renderAsDownloadableButton(FileIcon);
  }
}

MessageAttachmentComponent.propTypes = {
  containerStyle: PropTypes.objectOf(PropTypes.any),
  contentLength: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  invisibleThread: PropTypes.bool.isRequired,
  linkStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  sticker: PropTypes.bool,
  wrapperBubbleWidth: PropTypes.number,
  doThreadFilePreviewOpen: PropTypes.func.isRequired
};

export default MessageAttachmentComponent;
