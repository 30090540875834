import PropTypes from 'prop-types';
import ThreadChannelFooterLayout from '../../../../../common/thread/permission/thread-channel-footer-layout-component';

const ThreadFacebookExpiredFooterComponent = ({i18n}) => (
  <ThreadChannelFooterLayout data-test-id="facebook-window-expired">
    {i18n.t('customerThread.visibleThread.channels.facebook.permission.messagesInformation')}
  </ThreadChannelFooterLayout>
);

ThreadFacebookExpiredFooterComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ThreadFacebookExpiredFooterComponent;
