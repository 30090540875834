import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import debug from '../../lib/debug';

/**
 * Report an employee usage.
 *
 * @param {Function} graphql
 * @param {Object} payload
 * @param {Object} options
 * @param {Function} next
 * @param {String} failureType
 */
export default (graphql, payload, options, next, failureType) => {
  debug('report-employee-usage-mutation', payload, options);

  return graphql('report-employee-usage-mutation', payload, options).pipe(
    map(next),
    catchError((error) => {
      return of({
        type: failureType,
        error
      });
    })
  );
};
