import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FullscreenLayout from '../../../../shared/component/fullscreen-layout-component';
import {fontSize, fontWeight, iconSize} from '../../../../shared/style/variables';
import {darkSmoke, smoke, white} from '../../../../shared/style/colors';

class FullscreenOverlayComponent extends PureComponent {
  render() {
    const {children, dataTestId, innerStyle, title, onClickClose} = this.props;

    return (
      <FullscreenLayout
        dataTestId={dataTestId}
        title={title}
        header={
          <CloseIcon
            data-test-id="close-button"
            onClick={onClickClose}
            hoverColor={smoke}
            color={darkSmoke}
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: '20px',
              right: '20px',
              width: iconSize.xlarge,
              height: iconSize.xlarge,
              margin: 0,
              padding: 0,
              cursor: 'pointer'
            }}
          />
        }
        innerStyle={{
          height: '100%',
          overflow: 'auto',
          ...innerStyle
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 999,
          padding: '20px',
          backgroundColor: white
        }}
        titleStyle={{
          padding: '30px 20px',
          fontSize: fontSize.xxxxlarge,
          fontWeight: fontWeight.normal
        }}
      >
        {children}
      </FullscreenLayout>
    );
  }
}

FullscreenOverlayComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  innerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.node.isRequired,
  onClickClose: PropTypes.func.isRequired
};

export default FullscreenOverlayComponent;
