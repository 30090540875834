import {useState} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../../../shared/component/context';
import getChannelSetting from '../../../../../../data/thread/channels-settings';
import IconButton from '../../../../../../../../shared/component/button/icon-button-component';
import LegacyInputMessage from '../../../../../common/input-message/legacy-input-message-component';
import LegacyVisibleThreadAppsArea from './apps/legacy-visible-thread-apps-area-component';
import ThreadInteractionArea from '../../../../../common/thread/thread-interaction-area-component';

const LegacyVisibleThreadInteractionAreaComponent = ({
  cannedResponses,
  customerChannel,
  deactivated,
  disableThreadInputPersistence,
  enablePaymentFeature,
  i18n,
  isFocused,
  isHistoryAction,
  isNewThread,
  legacyCustomerThreadAppPayment,
  participationId,
  readOnly,
  sending,
  sendMessage,
  setInputHeight,
  threadForm,
  uploadingFile,
  doAppPaymentCancelButtonClick,
  doAppPaymentMaximizeButtonClick,
  doAppPaymentMinimizeButtonClick,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick,
  doAppsAreaMenuButtonClick,
  doAppsAreaMinimize,
  doAppsMenuSelectPayment,
  doThreadVisibleFileRemove,
  doUpdateMessage,
  onAppPaymentAmountChange,
  onAppPaymentCustomChange,
  onAppPaymentCustomerChange,
  onThreadVisibleFileSelect
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const isVisibleAppsMenuButton = enablePaymentFeature && !isNewThread;

  const onAppsMenuButtonClick = () => {
    if (
      !menuVisible &&
      // add here 1 test per app
      legacyCustomerThreadAppPayment &&
      !legacyCustomerThreadAppPayment.minimized
    ) {
      doAppsAreaMinimize(participationId);
    }

    setMenuVisible(!menuVisible);
    doAppsAreaMenuButtonClick();
  };

  const onAppsMenuItemSelect = (appStartFn) => {
    return () => {
      setMenuVisible(false);
      appStartFn(participationId);
    };
  };

  const renderAppsMenuButton = () => {
    const menuToogleIconPath = {
      appsMenuButtonVisible: `${ASSETS_PATH}/img/icon/thread-footer/APPS-MENU-OPENED.svg`,
      appsMenuButtonHidden: `${ASSETS_PATH}/img/icon/thread-footer/APPS-MENU-CLOSED.svg`
    };

    const MENU_TOGGLE_ICON_SIZE = '25px';
    const MENU_TOGGLE_ICON_SRC = menuVisible
      ? menuToogleIconPath.appsMenuButtonVisible
      : menuToogleIconPath.appsMenuButtonHidden;

    return (
      <IconButton
        data-test-id="apps-button"
        aria-label={i18n.t('customerThread.visibleThread.apps.menu.toggleButtonAriaLabel')}
        onClick={onAppsMenuButtonClick}
        style={{padding: '0 0 22px 15px'}}
      >
        <img
          alt=""
          src={MENU_TOGGLE_ICON_SRC}
          style={{
            height: MENU_TOGGLE_ICON_SIZE,
            width: MENU_TOGGLE_ICON_SIZE
          }}
        />
      </IconButton>
    );
  };

  return (
    <ThreadInteractionArea onResize={setInputHeight}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexGrow: 1
        }}
      >
        {isVisibleAppsMenuButton ? renderAppsMenuButton() : null}

        <LegacyInputMessage
          dataTestId="message-input"
          data={threadForm}
          extensionsSupported={getChannelSetting(customerChannel, 'extensionsSupported')}
          maxFileSize={getChannelSetting(customerChannel, 'maxFileSize')}
          placeholder={i18n.t('customerThread.visibleThread.inputMessagePlaceHolder')}
          readOnly={deactivated || readOnly}
          tabIndex={0}
          threadType="visible"
          doRemoveFile={doThreadVisibleFileRemove}
          doSendMessage={sendMessage}
          onSelectFile={onThreadVisibleFileSelect}
          {...{
            cannedResponses,
            disableThreadInputPersistence,
            isFocused,
            isHistoryAction,
            participationId,
            sending,
            uploadingFile,
            doUpdateMessage
          }}
        />
      </div>

      {enablePaymentFeature ? (
        <LegacyVisibleThreadAppsArea
          onSelectMenuItem={onAppsMenuItemSelect}
          {...{
            doAppPaymentCancelButtonClick,
            doAppPaymentMaximizeButtonClick,
            doAppPaymentMinimizeButtonClick,
            doAppPaymentStepperBackButtonClick,
            doAppPaymentStepperNextButtonClick,
            doAppsMenuSelectPayment,
            i18n,
            legacyCustomerThreadAppPayment,
            menuVisible,
            onAppPaymentAmountChange,
            onAppPaymentCustomChange,
            onAppPaymentCustomerChange,
            participationId
          }}
        />
      ) : null}
    </ThreadInteractionArea>
  );
};

LegacyVisibleThreadInteractionAreaComponent.contextTypes = context;

LegacyVisibleThreadInteractionAreaComponent.propTypes = {
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  customerChannel: PropTypes.string,
  deactivated: PropTypes.bool,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isFocused: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  legacyCustomerThreadAppPayment: PropTypes.objectOf(PropTypes.any),
  participationId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  setInputHeight: PropTypes.func.isRequired,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsAreaMenuButtonClick: PropTypes.func.isRequired,
  doAppsAreaMinimize: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  doThreadVisibleFileRemove: PropTypes.func.isRequired,
  doUpdateMessage: PropTypes.func.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired
};

export default LegacyVisibleThreadInteractionAreaComponent;
