import {fromJS} from 'immutable';
import {createReducer} from 'redux-immutablejs';
import reduceReducers from 'reduce-reducers';
import errorsHandler from '../../../shared/reducer/lib/errors-handler';
import {
  SIGNIN_IMPROVE_PASSWORD_SUBMIT,
  SIGNIN_IMPROVE_PASSWORD_SUBMIT_FAILURE,
  SIGNIN_IMPROVE_PASSWORD_SUBMIT_SUCCESS
} from '../actions/signin-improve-actions';

export const DEFAULT_STATE = {
  passwordChanged: false,
  submitting: false,
  errors: []
};

export default reduceReducers(
  createReducer(DEFAULT_STATE, {
    /**
     * Submit password change.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_IMPROVE_PASSWORD_SUBMIT]: (state) => {
      return state.set('errors', fromJS([])).set('submitting', true);
    },

    /**
     * Submit password change success.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_IMPROVE_PASSWORD_SUBMIT_SUCCESS]: (state) => {
      return state.set('passwordChanged', true).set('submitting', false);
    },

    /**
     * Submit password change failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [SIGNIN_IMPROVE_PASSWORD_SUBMIT_FAILURE]: (state) => {
      return state.set('submitting', false);
    }
  }),
  errorsHandler
);
