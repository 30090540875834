import PropTypes from 'prop-types';
import MailOutlineIcon from 'material-ui/svg-icons/communication/mail-outline';
import Radium from 'radium';
import {fontSize, iconSize} from '../../../../../shared/style/variables';

const InviteButtonComponent = Radium(
  ({color, doColleagueInvitationPopupOpen, iconColor, label, style}) => (
    <div
      data-test-id="invitation-link"
      onClick={doColleagueInvitationPopupOpen}
      style={{
        color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ...style
      }}
    >
      <span
        style={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '-3px',
            left: '-6px',
            fontSize: fontSize.medium
          }}
        >
          +
        </span>
        <MailOutlineIcon
          style={{
            width: iconSize.medium,
            height: iconSize.medium,
            color: iconColor || 'inherit'
          }}
        />
      </span>
      <span style={{display: 'flex', paddingLeft: '3px'}}>{label}</span>
    </div>
  )
);

InviteButtonComponent.propTypes = {
  color: PropTypes.string,
  iconColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired
};

export default InviteButtonComponent;
