import PropTypes from 'prop-types';
import {black, blue, darkGrey, mediumGreen, white} from '../../../../../../shared/style/colors';
import {CONVERSATION_VISIBLE_STATUS_VALUES} from '../../../../data/thread/conversation-status';
import {checkBusinessInScope} from '../../../../lib/business-helper';

const ThreadCustomBusinessTabComponent = ({
  account,
  business,
  engaged,
  i18n,
  isNewConversation,
  selectedTab,
  status,
  tabItemPosition,
  takenLabel,
  unreadInternalMessages,
  unreadVisibleMessages
}) => {
  const hasUnreadMessages = unreadVisibleMessages || unreadInternalMessages;
  const isSelected = selectedTab === tabItemPosition;
  const resolvedStatusTextColor =
    CONVERSATION_VISIBLE_STATUS_VALUES.RESOLVED === status ? darkGrey : black;
  const selectedColor = blue;
  const statusLabels = {
    WAITING: i18n.t('customerThread.storeTabs.statuses.waiting'),
    REPLIED: i18n.t('customerThread.storeTabs.statuses.replied'),
    RESOLVED: i18n.t('customerThread.storeTabs.statuses.resolved')
  }[status];

  const statusLabelNewConversation = '...';

  const statusLabelStyle = {
    fontSize: '12px'
  };

  const statusLabelStyleForNewConversation = {
    fontSize: '31px',
    left: '66px',
    position: 'absolute',
    top: 0
  };

  const isBusinessInScope = checkBusinessInScope(account.businesses, business.id);

  const renderTakenLabel = () => {
    return (
      <span
        data-test-id="taken-label"
        style={{
          backgroundColor: isSelected ? blue : darkGrey,
          color: white,
          borderRadius: '18px',
          padding: '0 8px',
          textTransform: 'uppercase',
          justifyContent: 'center',
          fontWeight: 600,
          fontSize: '12px',
          height: '18px',
          lineHeight: '17px',
          display: 'flex'
        }}
      >
        {takenLabel}
      </span>
    );
  };

  const renderStatusLabel = () => {
    const isNewConversationAndNotStatusLabels = isNewConversation && !statusLabels;

    const statusLabelStyles = isNewConversationAndNotStatusLabels
      ? statusLabelStyleForNewConversation
      : statusLabelStyle;

    const statusLabel = isNewConversationAndNotStatusLabels
      ? statusLabelNewConversation
      : statusLabels;

    return (
      <span data-test-id="status-label" style={statusLabelStyles}>
        {statusLabel}
      </span>
    );
  };

  const renderUnreadMessagesDot = (unreadMessageType) => {
    return (
      <div
        data-test-id={unreadMessageType}
        style={{
          backgroundColor: unreadMessageType === 'visible' ? blue : mediumGreen,
          borderRadius: '100%',
          height: '6px',
          marginRight: unreadMessageType === 'visible' ? '3px' : 0,
          width: '6px'
        }}
      />
    );
  };

  return (
    <div
      style={{
        alignItems: 'baseline',
        bottom: '0',
        color: isSelected ? selectedColor : resolvedStatusTextColor,
        display: 'flex',
        height: '55px',
        justifyContent: 'center',
        left: 0,
        width: '160px',
        opacity: isBusinessInScope ? 1 : 0.6,
        position: 'absolute',
        padding: '5px',
        top: '0px'
      }}
    >
      <div>
        <div
          data-test-id={`store-short-name-${tabItemPosition}`}
          title={business.name}
          style={{
            whiteSpace: 'nowrap',
            width: '80px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {business.shortName}
        </div>
        {engaged ? renderTakenLabel() : renderStatusLabel()}
      </div>
      {hasUnreadMessages ? (
        <div
          data-test-id="messages-unread-badges"
          style={{
            display: 'flex',
            margin: '8px 0 0 6px'
          }}
        >
          {unreadVisibleMessages > 0 ? renderUnreadMessagesDot('visible') : null}
          {unreadInternalMessages > 0 ? renderUnreadMessagesDot('internal') : null}
        </div>
      ) : null}
    </div>
  );
};

ThreadCustomBusinessTabComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  engaged: PropTypes.bool,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isNewConversation: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number.isRequired,
  status: PropTypes.string,
  takenLabel: PropTypes.string.isRequired,
  tabItemPosition: PropTypes.number.isRequired,
  unreadInternalMessages: PropTypes.number,
  unreadVisibleMessages: PropTypes.number
};

export default ThreadCustomBusinessTabComponent;
