import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import availableLanguages from '../data/language';

class LanguageSelectComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doUIUpdateLanguage = this._doUIUpdateLanguage.bind(this);
  }

  _doUIUpdateLanguage(event, index) {
    const {doUIUpdateLanguage} = this.props;
    doUIUpdateLanguage(availableLanguages[index].key);
  }

  render() {
    const {language} = this.props;

    return (
      <DropDownMenu
        data-test-id="language-select"
        value={language}
        onChange={this._doUIUpdateLanguage}
      >
        {availableLanguages.map(({key, label}) => {
          return (
            <MenuItem data-test-id="language-option" key={key} primaryText={label} value={key} />
          );
        })}
      </DropDownMenu>
    );
  }
}

LanguageSelectComponent.propTypes = {
  language: PropTypes.string, // can be null until ui is not fully initialized
  doUIUpdateLanguage: PropTypes.func.isRequired
};

export default LanguageSelectComponent;
