import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import context from '../../../../../shared/component/context';
import {darkSmoke, lightSlate} from '../../../../../shared/style/colors';

class HeaderBannerComponent extends PureComponent {
  render() {
    const {children, dataTestId, dataTestIdCloseButton, style, doClose} = this.props;

    return (
      <div
        id={dataTestId}
        data-test-id={dataTestId}
        style={{
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: lightSlate,
          color: 'white',
          position: 'relative',
          ...style
        }}
      >
        {children}

        {typeof doClose === 'function' ? (
          <CloseIcon
            data-test-id={dataTestIdCloseButton}
            color="white"
            hoverColor={darkSmoke}
            onClick={doClose}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              right: 0,
              margin: '7px',
              padding: 0,
              width: '25px',
              height: '25px',
              cursor: 'pointer'
            }}
          />
        ) : null}
      </div>
    );
  }
}

HeaderBannerComponent.contextTypes = context;

HeaderBannerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  dataTestIdCloseButton: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  doClose: PropTypes.func
};

export default HeaderBannerComponent;
