import PropTypes from 'prop-types';
import {IconButton} from '@material-ui/core';

const IconButtonComponent = (props) => <IconButton disableFocusRipple disableRipple {...props} />;

IconButtonComponent.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  'data-test-id': PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default IconButtonComponent;
