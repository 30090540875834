import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import definitions from './lib/graph-definitions';
import downloadFileFromUrl from '../../../../../shared/lib/download-file-from-url';
import {getFilterInsightsCanShowRankingTable} from '../../../lib/insights/insights-helper';
import {loadGraphInformation, loadInitialInsights} from './lib/prepare-graph-fill';
import prepareRequestParams from './lib/prepare-request-params';
import triggerLoadTablesAction from './lib/trigger-table-ranking-actions';
import updateSortDirection from './lib/update-sort-direction';
import updateTopStorePayloadFromQueryResponse from './lib/transform-top-store-response';
import {
  INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_RESPONSE_TIME_OPEN,
  INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD,
  INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD,
  INSIGHT_RESPONSE_TIME_RANKING_SORT_BUTTON_CLICK,
  INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_UPDATE_SORT_DIRECTION
} from '../../../actions/insight-actions';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';

const rankingStateParamName = 'responseTime';

/**
 * Finally, the epic.
 */
const InsightResponseTimeEpic =
  ({graphql}) =>
  (action$, state$) => {
    const {actionRankingTypes, actionTypes, epicName, sortBy, onSuccess, query} =
      definitions.responseTime;
    const [loadAction, successAction, failureAction] = actionTypes;
    const [loadRankingAction, successRankingAction, failureRankingAction] = actionRankingTypes;

    const loadInsights = loadInitialInsights(action$, state$, {
      actionsName: [
        INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_RESPONSE_TIME_OPEN,
        INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ],
      loadAction,
      loadRankingAction,
      rankingStateParamName
    });

    const loadResponseTimeGraphs = loadGraphInformation(action$, state$, graphql, {
      query,
      loadAction,
      successAction,
      failureAction,
      onSuccess,
      DEBOUNCE_TIME_STATISTIC,
      TIMEOUT_REQUEST_LONG
    });

    const handleClickSortDirection = action$.pipe(
      ofType(INSIGHT_RESPONSE_TIME_RANKING_SORT_BUTTON_CLICK),
      map(() => {
        const storedSortDirection = state$.value.getIn([
          'insight',
          'responseTime',
          'ranking',
          'sortedDirection'
        ]);

        return {
          type: INSIGHT_RESPONSE_TIME_UPDATE_SORT_DIRECTION,
          sortDirection: updateSortDirection(storedSortDirection)
        };
      })
    );

    const updateSortInsights = action$.pipe(
      ofType(INSIGHT_RESPONSE_TIME_UPDATE_SORT_DIRECTION),
      map(() => triggerLoadTablesAction(state$, {loadRankingAction, rankingStateParamName}))
    );

    const downloadResponseTimeCsv = downloadFileFromUrl(
      action$,
      INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD
    );

    const loadResponseTimeCsvToDownload = action$.pipe(
      ofType(INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD),
      mergeMap(({classicAggregationDate, classicAggregationPeriod}) =>
        graphql(
          'get-response-time-ranking-report-query',
          prepareRequestParams(state$, {
            classicAggregationDate,
            classicAggregationPeriod,
            forceStartDate: true
          }),
          {timeout: TIMEOUT_REQUEST_LONG}
        ).pipe(
          map(({responseTimeRankingReport}) => ({
            type: INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD,
            url: responseTimeRankingReport.url
          }))
          // @todo handle failure
        )
      )
    );

    const loadResponseTimeRankingTable = action$.pipe(
      ofType(loadRankingAction),
      filter(getFilterInsightsCanShowRankingTable(state$)),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate,
          sortedDirection
        }) => {
          return graphql(
            `query { topStores { ${epicName} { ranking increment businessId businessName value isBusinessInUserScope } } }`,
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate,
              forceStartDate: true,
              getAll: true,
              orderBy: [{field: sortBy, order: sortedDirection}]
            }),
            {timeout: TIMEOUT_REQUEST_LONG}
          ).pipe(
            map(({topStores}) => {
              return {
                type: successRankingAction,
                ranking: updateTopStorePayloadFromQueryResponse(topStores)
              };
            }),
            catchError((error) => {
              return of({
                type: failureRankingAction,
                error
              });
            })
          );
        }
      )
    );

    return merge(
      downloadResponseTimeCsv,
      handleClickSortDirection,
      loadInsights,
      loadResponseTimeCsvToDownload,
      loadResponseTimeGraphs,
      loadResponseTimeRankingTable,
      updateSortInsights
    );
  };

export default InsightResponseTimeEpic;
