export const CUSTOMER_THREAD_PROFILE_CLOSE = 'CUSTOMER_THREAD_PROFILE_CLOSE';
export const CUSTOMER_THREAD_PROFILE_SHOW = 'CUSTOMER_THREAD_PROFILE_SHOW';

export const CUSTOMER_THREAD_PROFILE_EDIT_CLOSE = 'CUSTOMER_THREAD_PROFILE_EDIT_CLOSE';
export const CUSTOMER_THREAD_PROFILE_EDIT_OPEN = 'CUSTOMER_THREAD_PROFILE_EDIT_OPEN';
export const CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD =
  'CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD';
export const CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE =
  'CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE';
export const CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS =
  'CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS';
export const CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE = 'CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE';
export const CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE =
  'CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE';
export const CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS =
  'CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS';

// Legacy
export const LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE = 'LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE';
export const LEGACY_CUSTOMER_THREAD_PROFILE_SHOW = 'LEGACY_CUSTOMER_THREAD_PROFILE_SHOW';

export const LEGACY_CUSTOMER_THREAD_PROFILE_LOAD = 'LEGACY_CUSTOMER_THREAD_PROFILE_LOAD';
export const LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_CLOSE =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_CLOSE';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_OPEN = 'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_OPEN';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS';
