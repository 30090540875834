import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {fontWeight} from '../../../../../../../../shared/style/variables';
import {blue} from '../../../../../../../../shared/style/colors';

const AdministrationBusinessPageChannelsLinkedTextComponent = ({
  description,
  linkLabel,
  linkProps,
  style
}) => (
  <span
    className="label"
    data-test-id="linked-text"
    style={{
      fontSize: '12px',
      margin: '10px 0 0 36px',
      lineHeight: '26px',
      ...style
    }}
  >
    {description}
    <a
      style={{
        color: blue,
        fontWeight: fontWeight.semiBold,
        marginLeft: '3px',
        textDecoration: 'none'
      }}
      {...linkProps}
    >
      {linkLabel}
      <span style={{position: 'relative', top: '4px', left: '-2px'}}>
        <ChevronRightIcon fontSize="small" style={{color: blue}} />
      </span>
    </a>
  </span>
);

AdministrationBusinessPageChannelsLinkedTextComponent.propTypes = {
  description: PropTypes.string.isRequired,
  linkProps: PropTypes.objectOf(PropTypes.any).isRequired,
  linkLabel: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default AdministrationBusinessPageChannelsLinkedTextComponent;
