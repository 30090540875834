const throttle = (fn) => {
  if (window.timer) {
    clearTimeout(window.timer);
    window.timer = null;
  }

  window.timer = setTimeout(() => {
    fn();
  }, 1);
};

export default throttle;
