import PropTypes from 'prop-types';

const AdministrationRightPanelContentComponent = ({children, style}) => (
  <div
    data-test-id="content"
    style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      overflowY: 'auto',
      ...style
    }}
  >
    {children}
  </div>
);

AdministrationRightPanelContentComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default AdministrationRightPanelContentComponent;
