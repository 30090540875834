const getOrganizationAgent = (i18n, state, agentFromServer) => {
  const organizationName = state.getIn(['account', 'organizationName']);
  const uiLanguage = state.getIn(['ui', 'language']);
  const brandWelcomeMessageDefault = i18n.t(
    'administration.organization.channels.flows.gbm.steps.agentForm.accountPersonalization.fields.welcomeMessage.hint',
    {organizationName}
  );

  const defaultNewAgentData = {
    agentLogoUrl: null,
    brandName: organizationName,
    brandWebsiteUrl: '',
    contactEmail: '',
    contactName: '',
    locale: uiLanguage,
    privacyPolicyUrl: '',
    welcomeMessage: brandWelcomeMessageDefault
  };

  const agentFakeStorage = localStorage.getItem('instaplyDesktop.fake-organization-agent');
  if (agentFakeStorage) {
    return {
      ...defaultNewAgentData,
      ...JSON.parse(agentFakeStorage)
    };
  }

  if (agentFromServer.locale) {
    return agentFromServer;
  }

  return {
    ...defaultNewAgentData,
    status: agentFromServer.status
  };
};

const mapOrganizationAgentFormToGraphQL = (fields) => ({
  agent: {
    displayName: fields.brandName,
    logoUuid: fields.logoUuid,
    mainLanguage: fields.locale,
    privacyPolicyUrl: fields.privacyPolicyUrl,
    welcomeMessage: fields.welcomeMessage
  },
  applyLogoToOrg: fields.applyLogoToOrg,
  brandContactInformation: {
    contactEmailAddress: fields.contactEmail,
    contactName: fields.contactName,
    websiteUrl: fields.brandWebsiteUrl
  }
});

export {getOrganizationAgent, mapOrganizationAgentFormToGraphQL};
