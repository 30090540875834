import React from 'react';
import PropTypes from 'prop-types';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FileDownloadIcon from '../../../../../../../../shared/component/icons/file-download-icon';
import LoadingIndicator from '../../../../../../../../shared/component/loading-indicator-component';
import StandardButton from '../../../../../../../../shared/component/button/standard-button-component';
import IconButton from '../../../../../../../../shared/component/button/icon-button-component';
import {blue, darkSmoke, white} from '../../../../../../../../shared/style/colors';

const InsightRankingControlButtonsComponent = ({
  downloadCsvLabelTranslation,
  i18n,
  insightDownloadCsv,
  insightSortRankings,
  loadingCsv,
  showCsvButton,
  showSortButton
}) => {
  return (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      {loadingCsv ? (
        <LoadingIndicator size={18} style={{justifyContent: 'normal'}} />
      ) : (
        <React.Fragment>
          {showCsvButton ? (
            <StandardButton
              data-test-id="download-csv-button"
              onClick={insightDownloadCsv}
              padding="noPadding"
              startIcon={<FileDownloadIcon style={{width: '20px'}} />}
              style={{
                background: white,
                border: `1px solid ${darkSmoke}`,
                color: blue,
                margin: '10px 0 0 6px',
                padding: '3px 10px',
                minWidth: '100px'
              }}
            >
              {downloadCsvLabelTranslation}
            </StandardButton>
          ) : null}
          {showSortButton ? (
            <IconButton
              aria-label={i18n.t('insight.contentHeader.sortButtonAriaLabel')}
              data-test-id="insight-sort-button"
              onClick={insightSortRankings}
              style={{
                background: blue,
                borderRadius: '16px',
                width: '47px',
                padding: '3px',
                margin: '10px 0 0 8px'
              }}
            >
              <ImportExportIcon style={{color: white, fontWeight: 'bold', width: '20px'}} />
            </IconButton>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

InsightRankingControlButtonsComponent.propTypes = {
  downloadCsvLabelTranslation: PropTypes.string.isRequired,
  insightDownloadCsv: PropTypes.func.isRequired,
  insightSortRankings: PropTypes.func,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingCsv: PropTypes.bool.isRequired,
  showCsvButton: PropTypes.bool.isRequired,
  showSortButton: PropTypes.bool.isRequired
};

export default InsightRankingControlButtonsComponent;
