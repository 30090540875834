import {EnumType, jsonToGraphQLQuery} from 'json-to-graphql-query';

const getMessageToSend = (message) => {
  let attachmentToSend = null;
  if (message.attachment) {
    attachmentToSend = {
      uuid: message.attachment.uuid
    };
  }

  return {
    attachment: attachmentToSend,
    text: message.text
  };
};

const postToServerTheMessageInConversation = ({
  conversation,
  graphql,
  message,
  mentions = null
}) => {
  const transformMentionsToGraphqlQueryFormat = (mentionsList) =>
    mentionsList.map((mention) => ({...mention, type: new EnumType(mention.type)}));

  const defaultArgsSendMessageToConversation = {
    clientItemId: message.clientItemId,
    conversationId: conversation.id,
    internal: conversation.internal,
    message: getMessageToSend(message)
  };

  const mutationAsString = jsonToGraphQLQuery({
    mutation: {
      sendMessageToConversation: {
        __args: mentions
          ? {
              ...defaultArgsSendMessageToConversation,
              mentions: transformMentionsToGraphqlQueryFormat(mentions)
            }
          : defaultArgsSendMessageToConversation,
        cursor: true,
        id: true,
        sortId: true
      }
    }
  });

  return graphql(mutationAsString);
};

export {getMessageToSend, postToServerTheMessageInConversation};
