import {merge, of} from 'rxjs';
import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  CUSTOMER_INBOX_BUSINESS_FILTER_OPEN,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_FAILURE,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_SUCCESS,
  CUSTOMER_INBOX_THREADS_LIST_LOAD,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS
} from '../../../../actions/customer-inbox-actions';

const CustomerInboxBusinessFilterEpic =
  ({query}) =>
  (action$, state$) => {
    const loadThreadsListFilteredByBusiness = action$.pipe(
      ofType(CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS),
      map(({businessScope, businessId}) => {
        const inboxStore = state$.value.get('customerInbox');

        return {
          type: CUSTOMER_INBOX_THREADS_LIST_LOAD,
          offset: 0,
          sortBy: inboxStore.get('sortBy'),
          statuses: inboxStore.get('statuses'),
          businessId,
          businessScope
        };
      })
    );

    const openFilter = action$.pipe(
      ofType(CUSTOMER_INBOX_BUSINESS_FILTER_OPEN),
      map(() => {
        return {
          type: CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES,
          text: ''
        };
      })
    );

    const searchBrowsableBusinesses = action$.pipe(
      ofType(CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES),
      mergeMap(({text = ''}) => {
        const params = {
          text
        };

        return query('search-browsable-businesses', params).pipe(
          map(({data}) => {
            return {
              type: CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_SUCCESS,
              data,
              params
            };
          }),
          takeUntil(
            action$.pipe(ofType(CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES))
          ),
          catchError((error) => {
            return of({
              type: CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(loadThreadsListFilteredByBusiness, openFilter, searchBrowsableBusinesses);
  };

export default CustomerInboxBusinessFilterEpic;
