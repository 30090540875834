import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import currentPlatform from '../../lib/current-platform';
import {radius} from '../../style/variables';
import {lightSlate} from '../../style/colors';

class EnhancedTextFieldComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disabled: currentPlatform.isMobile // will be used to prevent ugly yellow background style on Safari iOS
    };
  }

  componentDidMount() {
    const {disabled} = this.props;
    if (currentPlatform.isMobile) {
      setTimeout(() => this.setState({disabled}), 1000);
    }
  }

  render() {
    const {inputRef, inputStyle, style, type, ...other} = this.props;
    const {disabled} = this.state;

    return (
      <TextField
        {...other}
        ref={inputRef}
        type={disabled ? 'text' : type}
        style={{
          width: '100%',
          ...style
        }}
        inputStyle={{
          borderRadius: radius.medium,
          color: lightSlate,
          ...inputStyle
        }}
      />
    );
  }
}

EnhancedTextFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func, // Old React Ref's API
    PropTypes.object // New React Ref's API
  ]),
  inputStyle: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string
};

export default EnhancedTextFieldComponent;
