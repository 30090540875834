export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_CLOSE =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_CLOSE';
export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN';
export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN_BUTTON_CLICK =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN_BUTTON_CLICK';

export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE';
export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE';
export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS';

export const CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE =
  'CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE';

// legacy

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_CLOSE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_CLOSE';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE =
  'LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE';
