import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import InvisibleIcon from 'material-ui/svg-icons/action/visibility-off';
import context from '../../../../../../shared/component/context';
import LegacyInputMessage, {
  INITIAL_INPUT_MESSAGE_HEIGHT
} from '../../../common/input-message/legacy-input-message-component';
import LegacyCustomerProfileCard from './profile/legacy-customer-profile-card-component';
import LegacyScrollableThreadMessagesList from '../../../common/thread/legacy-scrollable-thread-messages-list-component';
import LegacyMentionComponent from './legacy-invisible-thread-mention-component';
import RightPanelColumnLayout from '../../../common/layout/right-panel-column-layout-component';
import ThreadInteractionArea from '../../../common/thread/thread-interaction-area-component';
import {fontSize, fontWeight, lineHeight, spacing} from '../../../../../../shared/style/variables';
import {black, mediumGreen, mediumSnow, snow} from '../../../../../../shared/style/colors';

class LegacyCustomerInvisibleThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputHeight: INITIAL_INPUT_MESSAGE_HEIGHT
    };

    this._focusOnTextInput = this._focusOnTextInput.bind(this);
    this._loadMore = this._loadMore.bind(this);
    this._sendMessage = this._sendMessage.bind(this);
  }

  _setInputHeight = ({height}) => {
    this.setState({
      inputHeight: height
    });
  };

  _loadMore(fromId, direction) {
    const {participationId, doThreadInvisibleLoad} = this.props;
    doThreadInvisibleLoad(participationId, fromId, direction);
  }

  _sendMessage(clientMessageId, text) {
    const {fullName, threadForm, organizationPicture, picture, doSendMessage} = this.props;

    doSendMessage({
      clientMessageId,
      fullName,
      organizationPicture,
      picture,
      text,
      attachment: threadForm.fileUploaded,
      internal: true,
      mentionedUser: threadForm.userToMention
    });
  }

  _focusOnTextInput() {
    if (this.textinput) {
      this.textinput.focusEditor();

      return true;
    }
  }

  render() {
    const {i18n} = this.context;
    const {
      currentSection,
      directedToBusinessIdentifier,
      directedToBusinessName,
      direction,
      disableThreadInputPersistence,
      hasMoreMessagesAfter,
      hasMoreMessagesBefore,
      isFocused,
      isHistoryAction,
      isSmallScreen,
      latestMessage,
      loading,
      loadingAfter,
      loadingBefore,
      messages,
      oldestMessage,
      participantIcon,
      participantName,
      participationId,
      readOnly,
      sending,
      doColleagueInvitationPopupOpen,
      // customer profile
      customerProfileArea,
      enableCustomerProfile,
      participantCompany,
      participantEmail,
      participantPhone,
      doCustomerProfileShow,
      // mention
      mention,
      showMentionTooltip,
      doThreadInvisibleMentionPopupClose,
      doThreadInvisibleMentionPopupOpen,
      doThreadInvisibleMentionPopupSearch,
      doThreadInvisibleMentionPopupSelect,
      doThreadInvisibleMentionUnselect,
      // message input
      threadForm,
      uploadingFile,
      doThreadFilePreviewOpen,
      doThreadInvisibleFileRemove,
      doThreadInvisibleSendMessageRetry,
      doUpdateMessage,
      doUploadFile
    } = this.props;
    const {inputHeight} = this.state;

    const backgroundColor = mediumSnow;
    const showCustomerProfileCard = !isSmallScreen && enableCustomerProfile && participationId;

    return (
      <RightPanelColumnLayout dataTestId="invisible-thread">
        {showCustomerProfileCard ? (
          <LegacyCustomerProfileCard
            {...{
              participantCompany,
              participantEmail,
              participantIcon,
              participantName,
              participantPhone,
              readOnly,
              doCustomerProfileShow
            }}
            style={{
              borderLeft: currentSection ? '0px' : `1px solid ${snow}`
            }}
          />
        ) : null}

        {showCustomerProfileCard ? customerProfileArea : null}

        <div
          data-test-id="invisible-thread-header"
          style={{
            backgroundColor: mediumSnow,
            minHeight: '50px',
            height: '50px',
            borderCollapse: 'collapse',
            display: 'flex'
          }}
        >
          <div
            style={{
              flex: 2,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              margin: '0 15px'
            }}
          >
            <InvisibleIcon style={{width: '20px', height: '20px'}} color={mediumGreen} />
            <h1
              data-test-id="invisible-thread-title"
              style={{
                fontWeight: fontWeight.semiBold,
                marginLeft: spacing.large,
                fontSize: fontSize.medium,
                lineHeight: lineHeight.large,
                color: black
              }}
            >
              {i18n.t('customerThread.invisibleThread.title')}
            </h1>
          </div>
        </div>

        <div
          style={{
            backgroundColor,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 50px)',
            minHeight: '1px',
            width: '100%',
            flex: 1
          }}
        >
          <LegacyScrollableThreadMessagesList
            dataTestId="invisible-thread-result"
            canShowIncomingSenderName
            loadMore={this._loadMore}
            threadType="invisible"
            doSendMessageRetry={doThreadInvisibleSendMessageRetry}
            {...{
              backgroundColor,
              direction,
              hasMoreMessagesAfter,
              hasMoreMessagesBefore,
              i18n,
              inputHeight,
              latestMessage,
              loading,
              loadingAfter,
              loadingBefore,
              messages,
              oldestMessage,
              sending,
              doThreadFilePreviewOpen
            }}
          />

          {loading || readOnly ? null : (
            <ThreadInteractionArea onResize={this._setInputHeight}>
              <LegacyInputMessage
                dataTestId="message-invisible-input"
                data={threadForm}
                isVisibleThread={false}
                placeholder={i18n.t('customerThread.invisibleThread.inputMessagePlaceHolder')}
                ref={(input) => {
                  this.textinput = input;

                  return this.textinput;
                }}
                tabIndex={0}
                threadType="invisible"
                doRemoveFile={doThreadInvisibleFileRemove}
                doSendMessage={this._sendMessage}
                onSelectFile={doUploadFile}
                mention={
                  <LegacyMentionComponent
                    show={showMentionTooltip && isFocused}
                    userToMention={threadForm.userToMention}
                    doThreadInvisibleMentionPopupSelect={flow([
                      doThreadInvisibleMentionPopupSelect,
                      this._focusOnTextInput
                    ])}
                    doThreadInvisibleMentionUnselect={flow([
                      doThreadInvisibleMentionUnselect,
                      this._focusOnTextInput
                    ])}
                    {...mention}
                    {...{
                      directedToBusinessIdentifier,
                      directedToBusinessName,
                      doColleagueInvitationPopupOpen,
                      doThreadInvisibleMentionPopupClose,
                      doThreadInvisibleMentionPopupOpen,
                      doThreadInvisibleMentionPopupSearch
                    }}
                  />
                }
                {...{
                  disableThreadInputPersistence,
                  isFocused,
                  isHistoryAction,
                  participationId,
                  readOnly,
                  sending,
                  uploadingFile,
                  doUpdateMessage
                }}
              />
            </ThreadInteractionArea>
          )}
        </div>
      </RightPanelColumnLayout>
    );
  }
}

LegacyCustomerInvisibleThreadComponent.contextTypes = context;

LegacyCustomerInvisibleThreadComponent.propTypes = {
  currentSection: PropTypes.bool.isRequired,
  customerProfileArea: PropTypes.node,
  directedToBusinessIdentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  directedToBusinessName: PropTypes.string,
  direction: PropTypes.string,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enableCustomerProfile: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  hasMoreMessagesAfter: PropTypes.bool.isRequired,
  hasMoreMessagesBefore: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingAfter: PropTypes.bool,
  loadingBefore: PropTypes.bool.isRequired,
  mention: PropTypes.objectOf(PropTypes.any).isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  oldestMessage: PropTypes.string,
  organizationPicture: PropTypes.string,
  participantCompany: PropTypes.string,
  participantEmail: PropTypes.string,
  participantIcon: PropTypes.string,
  participantName: PropTypes.string,
  participantPhone: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  picture: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  showMentionTooltip: PropTypes.bool.isRequired,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doCustomerProfileShow: PropTypes.func.isRequired,
  doSendMessage: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  doThreadInvisibleFileRemove: PropTypes.func.isRequired,
  doThreadInvisibleLoad: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupClose: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupOpen: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupSearch: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupSelect: PropTypes.func.isRequired,
  doThreadInvisibleMentionUnselect: PropTypes.func.isRequired,
  doThreadInvisibleSendMessageRetry: PropTypes.func.isRequired,
  doUpdateMessage: PropTypes.func.isRequired,
  doUploadFile: PropTypes.func.isRequired
};

export default LegacyCustomerInvisibleThreadComponent;
