import React from 'react';
import PropTypes from 'prop-types';
import AppPaymentMaximized from './payment/payment-maximized-component';
import AppPaymentMinimized from './payment/payment-minimized-component';
import VisibleThreadAppsAreaLayout from './visible-thread-apps-area-layout-component';
import VisibleThreadAppsMenu from './visible-thread-apps-menu-component';

const VisibleThreadAppsAreaComponent = ({
  conversationId,
  customerThreadAppPayment,
  doAppPaymentCancelButtonClick,
  doAppPaymentMaximizeButtonClick,
  doAppPaymentMinimizeButtonClick,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick,
  doAppsMenuSelectPayment,
  i18n,
  menuVisible,
  onAppPaymentAmountChange,
  onAppPaymentCustomChange,
  onAppPaymentCustomerChange,
  onSelectMenuItem
}) => {
  const renderMenu = () => (
    <VisibleThreadAppsAreaLayout showBorder={false}>
      <VisibleThreadAppsMenu
        onSelectMenuItemPayment={onSelectMenuItem(doAppsMenuSelectPayment)}
        {...{
          i18n
        }}
      />
    </VisibleThreadAppsAreaLayout>
  );
  const renderAppPayment = () =>
    customerThreadAppPayment.minimized ? (
      <AppPaymentMinimized
        amount={customerThreadAppPayment.amount}
        {...{
          conversationId,
          doAppPaymentMaximizeButtonClick,
          i18n
        }}
      />
    ) : (
      <AppPaymentMaximized
        {...{
          conversationId,
          customerThreadAppPayment,
          doAppPaymentCancelButtonClick,
          doAppPaymentMinimizeButtonClick,
          doAppPaymentStepperBackButtonClick,
          doAppPaymentStepperNextButtonClick,
          i18n,
          onAppPaymentAmountChange,
          onAppPaymentCustomChange,
          onAppPaymentCustomerChange
        }}
      />
    );

  return (
    <React.Fragment>
      {menuVisible ? renderMenu() : null}

      {customerThreadAppPayment ? (
        <VisibleThreadAppsAreaLayout>{renderAppPayment()}</VisibleThreadAppsAreaLayout>
      ) : null}
    </React.Fragment>
  );
};

VisibleThreadAppsAreaComponent.propTypes = {
  conversationId: PropTypes.string.isRequired,
  customerThreadAppPayment: PropTypes.objectOf(PropTypes.any),
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  menuVisible: PropTypes.bool.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onSelectMenuItem: PropTypes.func.isRequired
};

export default VisibleThreadAppsAreaComponent;
