import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-new-thread-actions';
import {LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_CLOSE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_OPEN,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_PROFILE_LOAD,
  LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_PROFILE_SHOW
} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';

export const DEFAULT_STATE = {
  channel: null,
  loading: false,
  participantCompany: null,
  participantEmail: null,
  participantFirstName: '',
  participantIcon: null,
  participantIconIsUploading: false,
  participantLastName: '',
  participantNps: null,
  participantPhone: null,
  participantPreferredChannel: null,
  showCustomerProfile: false,
  showEditCustomerProfile: false
};

/**
 * Update of the state from selected thread.
 *
 * @param {Object} state the current reducer state
 * @param {String} company
 * @param {String} customerChannel
 * @param {String} participantIcon
 * @param {Boolean} readOnly
 * @returns {Object} new state
 */
const onInboxThreadSelect = (state, {company, customerChannel, participantIcon, readOnly}) => {
  const newState = state
    .set('channel', customerChannel)
    .set('loading', true)
    .set('participantCompany', company)
    .set('participantEmail', null)
    .set('participantFirstName', '')
    .set('participantIcon', participantIcon !== 'default' ? participantIcon : null)
    .set('participantIconIsUploading', false)
    .set('participantLastName', '')
    .set('participantNps', null)
    .set('participantPhone', null)
    .set('participantPreferredChannel', null)
    .set('showEditCustomerProfile', false);

  if (readOnly) {
    return newState.set('showCustomerProfile', false);
  }

  return newState;
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Optimistic update of the thread using data from the inbox (search?) thread list.
   *
   * @param {Object} state the current reducer state
   * @param {Object} data
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER]: onInboxThreadSelect,
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: onInboxThreadSelect,
  [LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: onInboxThreadSelect,

  /**
   * Reset the customer profile when the employee open a new customer thread.
   *
   * @param {Object} state
   * @param {String} email
   * @param {String} phoneNumber
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD]: (state, {email, phoneNumber}) => {
    return fromJS(DEFAULT_STATE)
      .set('participantEmail', email)
      .set('participantPhone', phoneNumber);
  },

  /**
   * Triggered when the thread is loaded from the server
   * May be a partial messages part to add to current (loadingBefore)
   * or a new one (loading)
   *
   * @param {Object} state the current reducer state
   * @param {Object} data resulting of the get-conversation resource
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    return state.set(
      'participantIcon',
      data.participantIcon !== 'default' ? data.participantIcon : null
    );
  },

  /**
   * [CustomerThread]
   *
   * Triggered when user is in small screen view and switch between
   * invisible and visible thread
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE]: (state) => {
    return state.set('showCustomerProfile', false);
  },

  /**
   * Loading start
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_LOAD]: (state) => {
    return state.set('loading', true);
  },

  /**
   * Loading Failure
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_FAILURE]: (state) => {
    return state.set('loading', false);
  },

  /**
   * Loading success
   *
   * @param {Object} state
   * @param {Object} customer
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_SUCCESS]: (state, {customer}) => {
    const {channel, company, firstName, lastName, emailAddress, phoneNumber, lastSurveys} =
      customer;

    return (
      state
        // Material TextField return a error in console when the value (participantFirsName and participantLastName in this case) is null.
        .set('channel', channel)
        .set('loading', false)
        .set('participantCompany', company)
        .set('participantEmail', emailAddress)
        .set('participantFirstName', firstName || '')
        .set('participantLastName', lastName || '')
        .set('participantNps', lastSurveys.nps)
        .set('participantPhone', phoneNumber)
        .set('participantPreferredChannel', lastSurveys.preferredChannel)
    );
  },

  /**
   * [CustomerInvisibleThread]
   *
   * Triggered when user click on customer profile card or icon in small screen version
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_SHOW]: (state) => {
    return state.set('showCustomerProfile', true);
  },

  /**
   * [CustomerInvisibleThread]
   *
   * Triggered when user click or press ESC to close customer profile
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE]: (state) => {
    return state.set('showCustomerProfile', false).set('showEditCustomerProfile', false);
  },

  /**
   * Triggered when user click on edit button for customer profile
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_OPEN]: (state) => {
    return state.set('showEditCustomerProfile', true);
  },

  /**
   * Triggered when user click or press ESC to close customer profile
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_CLOSE]: (state) => {
    return state.set('showEditCustomerProfile', false);
  },

  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS]: (
    state,
    {company, firstName, lastName}
  ) => {
    return state
      .set('participantCompany', company)
      .set('participantFirstName', firstName)
      .set('participantLastName', lastName)
      .set('showEditCustomerProfile', false);
  },

  /**
   * Upload of picture
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD]: (state) => {
    return state.set('participantIconIsUploading', true);
  },

  /**
   * Change customer file reference after upload
   *
   * @param {Object} state
   * @param {String} href
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS]: (state, {href}) => {
    return state.set('participantIcon', href).set('participantIconIsUploading', false);
  },

  /**
   * Hide loader, if picture upload fails
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE]: (state) => {
    return state.set('participantIconIsUploading', false);
  }
});
