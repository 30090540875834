import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VisibleIcon from 'material-ui/svg-icons/action/visibility';
import InvisibleIcon from 'material-ui/svg-icons/action/visibility-off';
import ActionIcon from 'material-ui/svg-icons/action/account-circle';
import context from '../../../../../../shared/component/context';
import CappedBadge from '../../../common/capped-badge-component';
import {snow, white, mediumGreen, blue, black, green} from '../../../../../../shared/style/colors';

const IconButtonStyled = withStyles({
  root: {
    borderRadius: 0,
    height: '50px',
    position: 'relative',
    width: '50px'
  }
})(IconButton);

class LegacyCustomerThreadSidebarComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doShowVisibleThread = this._doShowVisibleThread.bind(this);
    this._doShowInvisibleThread = this._doShowInvisibleThread.bind(this);
  }

  _doShowVisibleThread() {
    const {changeVisibility} = this.props;
    changeVisibility('visible');
  }

  _doShowInvisibleThread() {
    const {changeVisibility} = this.props;
    changeVisibility('invisible');
  }

  render() {
    const {
      threadType,
      unreadMessagesCount,
      unreadInternalMessagesCount,
      doCustomerProfileShow,
      showCustomerProfile,
      enableCustomerProfile,
      isNewThread
    } = this.props;

    const activeTab = showCustomerProfile ? 'profile' : threadType;

    return (
      <div>
        <IconButtonStyled
          data-test-id="visible-button"
          disabled={isNewThread}
          onClick={this._doShowVisibleThread}
          style={{
            background: activeTab === 'visible' ? snow : 'none'
          }}
        >
          {unreadMessagesCount ? (
            <CappedBadge
              data-test-id="notification"
              number={unreadMessagesCount}
              badgeStyle={{
                backgroundColor: blue,
                color: white,
                padding: '1px 5px',
                position: 'absolute',
                right: '8px',
                top: '8px',
                border: 0
              }}
            />
          ) : null}
          <VisibleIcon color={activeTab === 'visible' ? blue : black} />
        </IconButtonStyled>
        <IconButtonStyled
          data-test-id="invisible-button"
          disabled={isNewThread}
          onClick={this._doShowInvisibleThread}
          style={{
            background: activeTab === 'invisible' ? mediumGreen : 'none'
          }}
        >
          {unreadInternalMessagesCount ? (
            <CappedBadge
              data-test-id="notification"
              number={unreadInternalMessagesCount}
              badgeStyle={{
                backgroundColor: mediumGreen,
                color: white,
                padding: '1px 5px',
                position: 'absolute',
                right: '8px',
                top: '8px',
                border: 0
              }}
            />
          ) : null}
          <InvisibleIcon color={activeTab === 'invisible' ? white : black} />
        </IconButtonStyled>
        {enableCustomerProfile ? (
          <IconButton
            data-test-id="customer-profile-button"
            disabled={isNewThread}
            onClick={doCustomerProfileShow}
            style={{
              width: '50px',
              height: '50px',
              background: activeTab === 'profile' ? green : 'none'
            }}
          >
            <ActionIcon color={showCustomerProfile ? white : black} />
          </IconButton>
        ) : null}
      </div>
    );
  }
}

LegacyCustomerThreadSidebarComponent.contextTypes = context;

LegacyCustomerThreadSidebarComponent.propTypes = {
  changeVisibility: PropTypes.func.isRequired,
  enableCustomerProfile: PropTypes.bool.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  showCustomerProfile: PropTypes.bool.isRequired,
  threadType: PropTypes.string.isRequired,
  unreadInternalMessagesCount: PropTypes.number,
  unreadMessagesCount: PropTypes.number,
  doCustomerProfileShow: PropTypes.func.isRequired
};

export default LegacyCustomerThreadSidebarComponent;
