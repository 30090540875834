import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {List} from 'material-ui/List';
import AutomaticLoadMore from '../../common/automatic-load-more-component';
import EnhancedPopover from '../../common/enhanced-popover-component';
import LoadingArea from '../../common/loading-area-component';
import MentionRow from './mention-row-component';
import {
  animationSpeed,
  popupHeight,
  popupWidth,
  radius,
  spacing
} from '../../../../../shared/style/variables';

const MentionPopupComponent = ({
  anchorEl,
  headerMention,
  i18n,
  muiTheme,
  doHeaderMentionsPopupClose,
  doHeaderMentionsPopupItemClick,
  doHeaderMentionsPopupItemsLoadMore
}) => {
  const LOADING_AREA_HEIGHT = '30px';
  const {edges, loading, open, pageInfo} = headerMention;

  const canLoadMoreItems = open && pageInfo.hasNextPage;

  const loadMoreItems = () => {
    doHeaderMentionsPopupItemsLoadMore(edges[edges.length - 1].cursor);
  };

  const renderMentionsList = () => {
    const isEmpty = edges.length === 0;

    // The reason to handle loading in 2 places (here + through AutomaticLoadMore) is to avoid scroll clipping,
    // because the list is not emptied on popup open
    if (isEmpty) {
      if (loading) {
        return <LoadingArea content={i18n.t('common.loading')} height={LOADING_AREA_HEIGHT} />;
      }

      return (
        <div style={{padding: spacing.medium}}>{i18n.t('header.mentions.popup.emptyList')}</div>
      );
    }

    return (
      <List style={{padding: 0}}>
        {edges.map((edge) => (
          <MentionRow
            key={edge.cursor}
            {...{doHeaderMentionsPopupItemClick, edge, i18n, muiTheme}}
          />
        ))}
      </List>
    );
  };

  return (
    <EnhancedPopover
      anchorEl={anchorEl}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      targetOrigin={{horizontal: 'right', vertical: 'top'}}
      onRequestClose={doHeaderMentionsPopupClose}
      style={{
        maxHeight: popupHeight.small,
        width: popupWidth.medium,
        maxWidth: popupWidth.medium,
        overflowY: 'hidden',
        borderRadius: radius.large
      }}
      {...{open}}
    >
      <TransitionGroup>
        <CSSTransition classNames="heightAnimation" timeout={animationSpeed.medium}>
          <div
            data-test-id="mention-list"
            style={{
              maxHeight: popupHeight.small,
              overflow: 'scroll'
            }}
          >
            {renderMentionsList()}

            {canLoadMoreItems ? (
              <AutomaticLoadMore
                height={LOADING_AREA_HEIGHT}
                loadMore={loadMoreItems}
                {...{loading}}
              />
            ) : null}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </EnhancedPopover>
  );
};

MentionPopupComponent.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  headerMention: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  muiTheme: PropTypes.objectOf(PropTypes.any).isRequired,
  doHeaderMentionsPopupClose: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemClick: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemsLoadMore: PropTypes.func.isRequired
};

export default MentionPopupComponent;
