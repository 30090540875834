import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Paper from '../../../../../../../shared/component/paper-component';
import {SEPARATOR_WIDTH} from './insight-panel-content-block-component';

export const getDoublePanelsStyle = (isSmallScreen) => {
  return {
    float: isSmallScreen ? 'none' : 'left',
    marginBottom: 0,
    width: isSmallScreen
      ? 'auto'
      : `calc(50% - ${parseInt(SEPARATOR_WIDTH.replace('px', ''), 10) / 2}px)`
  };
};

export const getRightDoublePanelsStyle = (isSmallScreen) => {
  return {
    ...getDoublePanelsStyle(isSmallScreen),
    marginLeft: isSmallScreen ? 0 : SEPARATOR_WIDTH
  };
};

/**
 * Finally, the component.
 */
class DoublePanelsContainerComponent extends PureComponent {
  render() {
    const {children, noEffect} = this.props;

    return (
      <Paper
        elevation={noEffect ? 0 : undefined}
        style={noEffect ? {backgroundColor: 'inherit'} : {marginBottom: SEPARATOR_WIDTH}}
      >
        {children}
      </Paper>
    );
  }
}

DoublePanelsContainerComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  noEffect: PropTypes.bool
};

export default DoublePanelsContainerComponent;
