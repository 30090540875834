import CheckIcon from '@material-ui/icons/Check';
import {withStyles} from '@material-ui/core/styles';
import {green} from '../../style/colors';

const StyledIcon = withStyles({
  colorAction: {
    color: green
  }
})(CheckIcon);

export default (props) => (
  <div {...props}>
    <StyledIcon color="action" fontSize="inherit" />
  </div>
);
