import PropTypes from 'prop-types';
import CappedLoader from './capped-loader-component';
import Waypoint from '../waypoint-component';

const getLoadingTriggerComponent = ({loadMore}) => <Waypoint {...{loadMore}} />;

/**
 * Finally, the component.
 */
const AutomaticCappedLoaderComponent = ({
  hasMore,
  hasReachedLimit,
  limitText,
  loading,
  loadMore
}) => (
  <CappedLoader
    {...{
      getLoadingTriggerComponent,
      hasMore,
      hasReachedLimit,
      limitText,
      loading,
      loadMore
    }}
  />
);

AutomaticCappedLoaderComponent.propTypes = {
  hasMore: PropTypes.bool,
  hasReachedLimit: PropTypes.bool.isRequired,
  limitText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired
};

export default AutomaticCappedLoaderComponent;
