import PropTypes from 'prop-types';

const RightPanelLayoutComponent = ({children, style, ...otherProps}) => {
  return (
    <div
      style={{
        position: 'relative', // required by some full panel loading overlay
        display: 'flex',
        justifyContent: 'center',
        minWidth: '430px',
        flexGrow: 1.5,
        borderCollapse: 'collapse',
        alignItems: children ? undefined : 'center',
        ...style
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
};

RightPanelLayoutComponent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any)
};

export default RightPanelLayoutComponent;
