import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import {fontSize, fontWeight, lineHeight, spacing} from '../../../../../../shared/style/variables';
import {lightSlate} from '../../../../../../shared/style/colors';

class IndicatorComponent extends PureComponent {
  render() {
    const {dataTestId, label, loading, value, valueColor} = this.props;

    return (
      <div
        style={{
          marginTop: spacing.large,
          lineHeight: lineHeight.large
        }}
      >
        {label ? (
          <div
            style={{
              marginBottom: spacing.xsmall,
              fontSize: fontSize.xlarge,
              fontWeight: fontWeight.semiBold
            }}
          >
            {label}
          </div>
        ) : null}
        {loading ? (
          <LoadingIndicator size={50} />
        ) : (
          <span
            data-test-id={dataTestId}
            style={{
              fontSize: fontSize.xxxxlarge,
              color: valueColor || lightSlate
            }}
          >
            {value}
          </span>
        )}
      </div>
    );
  }
}

IndicatorComponent.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.node,
  valueColor: PropTypes.string
};

export default IndicatorComponent;
