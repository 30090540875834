import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import InviteButton from '../../../global/invitation/global-invitation-invite-button-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import StartNewThreadListItem from '../../../common/thread/start-new-thread-list-item-component';
import {blue} from '../../../../../../shared/style/colors';

class ColleagueInboxStartNewThreadSearchListComponent extends PureComponent {
  onClickColleagueInvitationPopupOpenButton = () => {
    const {doCancelStartNewThreadWithColleague, doColleagueInvitationPopupOpen} = this.props;
    doCancelStartNewThreadWithColleague();
    doColleagueInvitationPopupOpen();
  };

  render() {
    const {i18n} = this.context;
    const {edges, loading, text, doSelectItem} = this.props;
    if (loading) {
      return <LoadingIndicator data-test-id="loading" />;
    }

    return (
      <span
        style={{
          flex: 1,
          minHeight: '1px',
          height: '100%',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        {edges.length ? (
          <div data-test-id="colleagues-list">
            <div data-test-id="search-colleagues-result">
              {edges.map((edge, i, list) => {
                const {node, participationId} = edge;

                return (
                  <StartNewThreadListItem
                    dataTestId="colleague-list-item"
                    displayName={node.displayName}
                    isLastChild={!list[i + 1]}
                    key={node.userId}
                    organizationPicture={node.organizationPictureHref}
                    pictureHref={node.pictureHref}
                    selectItem={doSelectItem}
                    value={{
                      participationId,
                      displayName: node.displayName,
                      userId: node.userId
                    }}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            <span style={{paddingLeft: '44px'}}>
              {text ? i18n.t('common.noResultForText', {text}) : i18n.t('common.noResult')}
            </span>
            <InviteButton
              color={blue}
              doColleagueInvitationPopupOpen={this.onClickColleagueInvitationPopupOpenButton}
              label={i18n.t('colleagueInvitation.longLinkLabel')}
              style={{
                marginTop: '20px'
              }}
            />
          </div>
        )}
      </span>
    );
  }
}

ColleagueInboxStartNewThreadSearchListComponent.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  doCancelStartNewThreadWithColleague: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doSelectItem: PropTypes.func.isRequired
};

ColleagueInboxStartNewThreadSearchListComponent.contextTypes = context;

export default ColleagueInboxStartNewThreadSearchListComponent;
