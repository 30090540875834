import PropTypes from 'prop-types';

const RightPanelColumnWrapperComponent = ({children, onClick, style}) => (
  <div
    onClick={onClick}
    style={{
      flexDirection: 'row',
      ...style
    }}
  >
    {children}
  </div>
);

RightPanelColumnWrapperComponent.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any)
};

export default RightPanelColumnWrapperComponent;
