import PropTypes from 'prop-types';
import LegacyMessage from './message/legacy-message-component';

const LegacyMessagesRowBubblesComponent = ({
  doSendMessageRetry,
  doThreadFilePreviewOpen,
  i18n,
  messages,
  presentationType,
  threadType
}) => {
  const lastMessageIndex = messages.length - 1;

  return messages.map((message, messageIndex) => (
    <LegacyMessage
      hasMessageAfter={messageIndex < lastMessageIndex}
      key={String(messageIndex)}
      {...message}
      {...{
        doSendMessageRetry,
        doThreadFilePreviewOpen,
        i18n,
        messageIndex,
        presentationType,
        threadType
      }}
    />
  ));
};

LegacyMessagesRowBubblesComponent.propTypes = {
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  presentationType: PropTypes.string.isRequired
};

export default LegacyMessagesRowBubblesComponent;
