import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {
  LEGACY_CUSTOMER_THREAD_TRANSFER,
  LEGACY_CUSTOMER_THREAD_TRANSFER_CANCEL_WARNING_MESSAGE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_EXPIRED,
  LEGACY_CUSTOMER_THREAD_TRANSFER_FAILURE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_SUCCESS,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SELECT_BUSINESS_OUT_OF_SCOPE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_NO_PHONE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_OUT_OF_SCOPE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS
} from '../../../../actions/customer-thread-transfer-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';
import {EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS} from '../../../../actions/ext-actions';

export const DEFAULT_STATE = {
  businessId: null,
  initializing: false,
  recentlySuccessful: false,
  searchText: '',
  selectedBusinessOutOfScope: null,
  transferring: false,
  transferShowPopup: false,
  warningNoPhoneIsVisible: false,
  warningOutOfScopeIsVisible: false,
  allBusinesses: [],
  filteredBusinesses: []
};

/**
 * Filter businesses with assigned business exclusion.
 *
 * @param {Object} businesses
 * @param {String} businessId
 * @returns {Object}
 */
const filterBusinesses = (businesses, businessId) => {
  return businesses.filter((business) => business.get('id') !== businessId);
};

/**
 * Transfer popup reset.
 *
 * @param {Object} state
 * @returns {Object} new state
 */
const resetTransferPopup = (state) => {
  return state
    .set('searchText', '')
    .set('selectedBusinessOutOfScope', null)
    .set('transferShowPopup', false)
    .set('warningNoPhoneIsVisible', false)
    .set('warningOutOfScopeIsVisible', false);
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Some actions that reset transfer state:
   * - select a thread
   * - select a searched thread
   * - select a search message
   */
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER]: resetTransferPopup,
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: resetTransferPopup,
  [LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: resetTransferPopup,

  /**
   * Triggered on a successful thread loading.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {*}
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    return state.set('businessId', data.directedToBusinessIdentifier);
  },

  /**
   * Toggle transfer popup.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE]: (state) => {
    // State call will be removed when we will have time to split 1 'toggle' action into 2 actions ('open' / 'close')
    if (state.get('transferShowPopup')) {
      return resetTransferPopup(state);
    }

    return state.set('transferShowPopup', true).set('initializing', true);
  },

  /**
   * Search business to transfer.
   *
   * @param {Object} state
   * @param {String} text
   * @returns {*}
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS]: (state, {text}) => {
    return state.set('searchText', text);
  },

  /**
   * Search businesses success.
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_SUCCESS]: (state, {data, params}) => {
    const allItems = fromJS(data.items);
    const filteredItems = filterBusinesses(allItems, state.get('businessId'));

    const newState = state.set('initializing', false).set('filteredBusinesses', filteredItems);

    return params.q === ''
      ? newState.set('allBusinesses', allItems) // Always refresh "reference" businesses
      : newState;
  },

  /**
   * Show warning no phone.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_NO_PHONE]: (state) => {
    return state.set('warningNoPhoneIsVisible', true);
  },

  /**
   * Show warning out of scope.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_OUT_OF_SCOPE]: (state) => {
    return state.set('warningOutOfScopeIsVisible', true);
  },

  /**
   * Select a business out of scope.
   *
   * @param {Object} state
   * @param {Object} business
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SELECT_BUSINESS_OUT_OF_SCOPE]: (state, {business}) => {
    return state.set('selectedBusinessOutOfScope', business);
  },

  /**
   * Cancel any warning message.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_CANCEL_WARNING_MESSAGE]: (state) => {
    return state
      .set('selectedBusinessOutOfScope', null)
      .set('warningNoPhoneIsVisible', false)
      .set('warningOutOfScopeIsVisible', false);
  },

  /**
   * Transfer a thread to a business.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER]: (state) => {
    return state.set('transferring', true);
  },

  /**
   * Optimistic update after a transfer a thread to a business.
   * Is uselessly called after a transfer from search results
   * in customer inbox new thread, but it's fine.
   *
   * @param {Object} state
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS]: (state, {params}) => {
    const businessId = params.id;

    return resetTransferPopup(
      state
        .set('businessId', businessId)
        .set('filteredBusinesses', filterBusinesses(state.get('allBusinesses'), businessId))
        .set('recentlySuccessful', true)
        .set('transferring', false)
    );
  },

  /**
   * Failed transfer a thread to a business.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_FAILURE]: (state) => {
    return state.set('transferring', false);
  },

  /**
   * A successful transfer a thread to a business timeout has reached the end.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_EXPIRED]: (state) => {
    return state.set('recentlySuccessful', false);
  },

  /**
   * Successful transfer a thread to a business.
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Boolean} doneByOtherUserToCurrentThread
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS]: (
    state,
    {data, doneByOtherUserToCurrentThread}
  ) => {
    if (doneByOtherUserToCurrentThread) {
      const businessId = data.newBusinessId.toString();

      return resetTransferPopup(
        state
          .set('businessId', businessId)
          .set('filteredBusinesses', filterBusinesses(state.get('allBusinesses'), businessId))
      );
    }

    return state;
  }
});
