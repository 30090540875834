import PropTypes from 'prop-types';

const formatBytes = (bytes, decimals) => {
  if (bytes === 0) {
    return '0 Byte';
  }

  const k = 1024;
  const decimalLength = decimals || 3;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(decimalLength))} ${sizes[i]}`;
};

const FileWeightComponent = ({contentLength}) => {
  return <span style={{paddingLeft: 5}}>({formatBytes(contentLength, 2)})</span>;
};

FileWeightComponent.propTypes = {
  contentLength: PropTypes.number.isRequired
};

export default FileWeightComponent;
