import PropTypes from 'prop-types';
import AdministrationRightPanelContentComponent from './administration-right-panel-content-component';
import AdministrationRightPanelHeaderComponent from './administration-right-panel-header-component';
import RightPanelLayout from '../../../common/layout/right-panel-layout-component';

const AdministrationRightPanelLayoutComponent = ({
  children,
  contentStyle,
  pageTitle,
  ...otherProps
}) => (
  <RightPanelLayout style={{flexDirection: 'column', justifyContent: 'flex-start'}} {...otherProps}>
    {pageTitle ? <AdministrationRightPanelHeaderComponent title={pageTitle} /> : null}

    <AdministrationRightPanelContentComponent style={contentStyle}>
      {children}
    </AdministrationRightPanelContentComponent>
  </RightPanelLayout>
);

AdministrationRightPanelLayoutComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  contentStyle: PropTypes.objectOf(PropTypes.any),
  otherProps: PropTypes.objectOf(PropTypes.any),
  pageTitle: PropTypes.string
};

export default AdministrationRightPanelLayoutComponent;
