import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';

// Do not change keys order because it's used to render in forms
const rules = {
  minLength: /^.{8,}$/,
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  digitOrSpecial: /\d|\W/
};

/**
 * Check if a text follows all password's validation rules.
 *
 * @param {String} text
 * @returns {Object}
 */
export default (text) => {
  const testByRule = mapValues(rules, (rule) => rule.test(text));

  return {
    ...testByRule,
    isValid: reduce(testByRule, (isValid, ruleOk) => isValid && ruleOk, true)
  };
};
