import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from './lib/get-insights-map-dispatch-to-props';
import InsightWaitingCustomersComponent from '../../../../component/content/insights/page/insight-wating-customers-component';

const InsightWaitingCustomersContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.get('insight'),
      (state) => state.get('insightBusinessFilter'),
      (state) => state.get('ui')
    ],
    (businesses, insight, insightBusinessFilter, ui) => {
      return {
        accountBusinesses: businesses.toJS(),
        businessesSelection: insight.get('businessesSelection').toJS(),
        insight: insight.get('waitingCustomers').toJS(),
        insightBusinessFilter: insightBusinessFilter.toJS(),
        uiWidth: ui.get('width')
      };
    }
  ),
  getInsightsMapDispatchToProps('waitingCustomers')
)(InsightWaitingCustomersComponent);

export default InsightWaitingCustomersContainer;
