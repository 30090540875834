import CloseIcon from 'material-ui/svg-icons/navigation/close';
import context from '../../../../../shared/component/context';
import CloseOnEscapeComponent from '../../../../../shared/component/close-on-escape-component';
import ImageComponent from '../image-component';
import {black90Opacity, darkSmoke, white} from '../../../../../shared/style/colors';

class FilePreviewComponent extends CloseOnEscapeComponent {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this._onClick);
    window.addEventListener('keydown', this._onKeyDown);
  }

  _onClick(event) {
    event.preventDefault();

    if (event.target.id === 'file-preview-container') {
      this._doClose();
    }
  }

  // Called on [Escape]
  _doClose() {
    window.removeEventListener('click', this._onClick);
    window.removeEventListener('keydown', this._onKeyDown);

    this.props.doFilePreviewClose();
  }

  render() {
    const {src} = this.props;

    return (
      <div
        data-test-id="file-preview-container"
        id="file-preview-container"
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          minHeight: '100%',
          background: black90Opacity,
          zIndex: '999',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CloseIcon
          data-test-id="file-preview-close-button"
          color={white}
          hoverColor={darkSmoke}
          onClick={this._doClose}
          style={{
            position: 'absolute',
            top: '30px',
            right: '30px',
            width: '35px',
            height: '35px',
            margin: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            cursor: 'pointer'
          }}
        />

        <div
          style={{
            position: 'relative',
            maxHeight: '80%',
            maxWidth: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ImageComponent
            data-test-id="file-preview-content-image"
            containerStyle={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
            {...{src}}
          />
        </div>
      </div>
    );
  }
}

FilePreviewComponent.contextTypes = context;

export default FilePreviewComponent;
