import {Component} from 'react';
import PropTypes from 'prop-types';
import context from '../context';
import isAuthenticated from '../../lib/is-authenticated';
import SignInLink from './signin-link';
import {APP_HOME_URL} from '../../data/route';

/**
 * Get a link to continue to the desktop's app or sign in.
 * Do not use PureComponent in order to take in account the language change.
 */
class ContinueOrSignInLink extends Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick(event) {
    const {doContinueToTheApp} = this.props;

    if (doContinueToTheApp) {
      event.preventDefault();

      doContinueToTheApp();
    }
  }

  render() {
    const {doContinueToTheApp} = this.props;
    const {i18n} = this.context;

    if (isAuthenticated() || doContinueToTheApp) {
      return (
        <a data-test-id="continue-link" href={APP_HOME_URL} onClick={this._onClick}>
          {i18n.t('public.continueToTheApp')}
        </a>
      );
    }

    return <SignInLink />;
  }
}
ContinueOrSignInLink.contextTypes = context;
ContinueOrSignInLink.propTypes = {
  doContinueToTheApp: PropTypes.func
};

export {ContinueOrSignInLink, SignInLink};
