import PropTypes from 'prop-types';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import {darkSmoke, lightSlate} from '../../../../../shared/style/colors';

const CloseIconComponent = ({onClick}) => (
  <CloseIcon
    data-test-id="close-button"
    color={lightSlate}
    hoverColor={darkSmoke}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      marginRight: '5px',
      cursor: 'pointer'
    }}
    {...{
      onClick
    }}
  />
);

CloseIconComponent.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CloseIconComponent;
