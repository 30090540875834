import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import RedirectIcon from '../icon/redirect-icon';
import {PADDING_THREAD} from '../../../data/settings';
import {fontSize} from '../../../../../shared/style/variables';
import {lightSlate, lightSmoke, white} from '../../../../../shared/style/colors';

const RedirectionRowComponent = ({businessName}, {i18n}) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: PADDING_THREAD,
      lineHeight: '16px',
      fontSize: fontSize.small,
      color: lightSlate
    }}
  >
    <RedirectIcon
      style={{border: `1px solid ${lightSmoke}`, borderRadius: '50%', backgroundColor: white}}
    />
    <div data-test-id="item-label" style={{padding: '6px 0'}}>
      {`${i18n.t('customerInbox.threadList.redirection')} `}
      <span style={{fontWeight: 'bold'}}>{businessName}</span>
    </div>
  </div>
);

RedirectionRowComponent.contextTypes = context;

RedirectionRowComponent.propTypes = {
  businessName: PropTypes.string.isRequired
};

export default RedirectionRowComponent;
