import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import context from '../../../../../shared/component/context';
import HeaderBanner from '../../common/header/header-banner-component';
import highlighted from '../../../../../shared/lib/highlighted';
import {darkOrange} from '../../../../../shared/style/colors';

class WarningBannersComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {warning} = this.props;

    return Object.entries(warning).map((entry) => {
      const item = entry[1];
      if (!item.active) {
        return;
      }

      const key = entry[0];

      return (
        <HeaderBanner
          dataTestId={`warning-${kebabCase(key)}-banner`}
          key={key}
          style={{
            height: '44px',
            backgroundColor: darkOrange
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(`header.warning.${key}`, {
                business: highlighted(item.businessName, null, 200)
              })
            }}
          />
        </HeaderBanner>
      );
    });
  }
}

WarningBannersComponent.contextTypes = context;

WarningBannersComponent.propTypes = {
  warning: PropTypes.objectOf(PropTypes.any).isRequired
};

export default WarningBannersComponent;
