import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form/dist/index.ie11';
import {useState, useEffect} from 'react';
import keycode from 'keycode';
import {
  americanSilver,
  blue,
  lightGrey,
  white
} from '../../../../../../../../../shared/style/colors';
import {fontWeight} from '../../../../../../../../../shared/style/variables';
import StandardButtonComponent from '../../../../../../../../../shared/component/button/standard-button-component';
import availableLanguages from '../../../../../../../../../shared/data/language';
import {getResendMessageOptions} from '../../../../lib/channels-gbm-helper';
import AutomaticResponseVariables from '../../../administration-business-automatic-response-variables-component';
import AdministrationBusinessPageChannelsGbmMapWrapper from './map/administration-business-page-channels-gbm-map-wrapper-component';
import {FORM_SELECT_STYLE} from '../../../../../../../../../shared/style/form';
import ErrorMessageAdvanced from '../../../../../../common/error-message-advanced-component';
import LoadingIndicator from '../../../../../../../../../shared/component/loading-indicator-component';

const AdministrationBusinessPageChannelGbmBusinessLocationFormComponent = ({
  business,
  businessLocationForm: {formState, hasGlobalError, placeIdError, submitting},
  i18n,
  onGbmBusinessLocationFormSubmitButtonClick,
  onCloseBusinessLocation
}) => {
  const [openAutomaticResponseVariablesPopup, setOpenAutomaticResponseVariablePopup] =
    useState(false);
  const [automaticResponseVariableAnchorEl, setAutomaticResponseVariableAnchorEl] = useState(null);

  const [userHasSearchedForAddress, setUserHasSearchedForAddress] = useState(false);

  const {register, handleSubmit, setValue, getValues} = useForm({
    defaultValues: {
      locale: business.locale,
      resendMessage: '1'
    }
  });

  useEffect(() => {
    if (formState === 'success') {
      onCloseBusinessLocation();
    }
  }, [formState]);

  const checkKeyDown = (event) => {
    if (event.keyCode === keycode('ENTER')) event.preventDefault();
  };

  const onSubmit = (data) => {
    onGbmBusinessLocationFormSubmitButtonClick(data);
  };

  const onOpenAutomaticResponseVariablesPopup = (event) => {
    setAutomaticResponseVariableAnchorEl(event.currentTarget);
    setOpenAutomaticResponseVariablePopup(true);
  };

  const onCloseAutomaticResponseVariablesPopup = () => {
    setOpenAutomaticResponseVariablePopup(false);
  };

  const onSelectAutomaticResponseVariable = (value) => {
    setOpenAutomaticResponseVariablePopup(false);
    const automaticResponsePreviousValue = getValues('automaticResponse');

    setValue('automaticResponse', `${automaticResponsePreviousValue} ${value}`);
  };

  const onHandlePlaceIdAfterMapPlaceUpdate = (placeId) => {
    register({name: 'placeId', type: 'custom'});
    setValue('placeId', placeId);
  };

  const onUserSearchOnMap = (userHasSearched) => {
    setUserHasSearchedForAddress(userHasSearched);
  };

  const commonSelectInputFieldWrapperStyles = {
    alignItems: 'center',
    display: 'flex',
    marginTop: '20px'
  };
  const commonLabelStyles = {fontWeight: fontWeight.semiBold};
  const commonSelectInputFieldStyles = {
    ...FORM_SELECT_STYLE,
    borderColor: lightGrey,
    borderRadius: '30px',
    marginLeft: '10px',
    padding: '9px 5px 9px 10px',
    width: '253px'
  };

  const automaticResponseVariables = [
    {
      appendValue: '%STORE_NAME%',
      dataTestId: 'store-name',
      label: i18n.t('administration.business.pages.channels.gbm.storeName')
    },
    {
      appendValue: '%STORE_PHONE_NUMBER%',
      dataTestId: 'store-phone-number',
      label: i18n.t('administration.business.pages.channels.gbm.storePhoneNumber')
    },
    {
      appendValue: '%ORG_NAME%',
      dataTestId: 'org-name',
      label: i18n.t('administration.business.pages.channels.gbm.organizationName')
    },
    {
      appendValue: '%WEBSOLO_URL%',
      dataTestId: 'websolo-url',
      label: i18n.t('administration.business.pages.channels.gbm.conversationUrl')
    }
  ];

  const storeIcon = `${ASSETS_PATH}/img/icon/channel/STORE_NAME.svg`;
  const storeAddress = `${business.name.bold()}, ${business.address}`;

  const renderBusinessLocationError = () => {
    if (placeIdError) {
      return (
        <ErrorMessageAdvanced
          data-test-id="placeid-error-message"
          title={i18n.t(`administration.business.pages.channels.gbm.${placeIdError}`)}
        />
      );
    }

    if (hasGlobalError) {
      return (
        <ErrorMessageAdvanced
          data-test-id="global-error-message"
          title={i18n.t('administration.business.pages.channels.gbm.errorDefaultMessage')}
        />
      );
    }

    return null;
  };

  return (
    <form
      data-test-id="business-location-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={checkKeyDown}
      style={{display: 'flex', flexDirection: 'column'}}
    >
      <div>
        <h6 style={{...commonLabelStyles, lineHeight: '17px', margin: '23px 0 5px 0'}}>
          {i18n.t('administration.business.pages.channels.gbm.maps.mapSearchTitle')}
        </h6>
        <p style={{margin: '3px 0 15px 0', fontSize: '11px', lineHeight: '15px'}}>
          {i18n.t('administration.business.pages.channels.gbm.maps.mapSearchDisclaimer')}
        </p>
        <span
          data-test-id="gbm-business-locatiom-customer-address-reference"
          style={{display: 'flex', alignItems: 'center', marginBottom: '22px'}}
        >
          <img src={storeIcon} alt="store-icon" style={{width: '22px'}} />
          <span style={{marginLeft: '9px'}} dangerouslySetInnerHTML={{__html: storeAddress}} />
        </span>
        <AdministrationBusinessPageChannelsGbmMapWrapper
          onHandlePlaceUpdate={onHandlePlaceIdAfterMapPlaceUpdate}
          {...{business, i18n, onUserSearchOnMap}}
        />
      </div>
      <div style={commonSelectInputFieldWrapperStyles}>
        <label style={commonLabelStyles} htmlFor="locale">
          {i18n.t('administration.business.pages.channels.gbm.storeLanguageTitle')}
        </label>
        <select
          data-test-id="store-languages"
          style={commonSelectInputFieldStyles}
          ref={register}
          name="locale"
          id="locale"
        >
          {availableLanguages.map((language) => (
            <option key={language.key} value={language.key}>
              {language.label}
            </option>
          ))}
        </select>
      </div>
      <div data-test-id="automatic-response-block" style={{marginTop: '10px'}}>
        <h6 data-test-id="title" style={{...commonLabelStyles, lineHeight: '17px'}}>
          {i18n.t('administration.business.pages.channels.gbm.automaticResponseTitle')}
        </h6>
        <p style={{marginTop: '3px', fontSize: '11px', lineHeight: '15px'}}>
          {i18n.t('administration.business.pages.channels.gbm.automaticResponseDescription')}
        </p>
        <div
          style={{
            border: `1px solid ${lightGrey}`,
            borderRadius: '4px',
            height: '121px',
            margin: '13px 0px 0px',
            maxWidth: '660px',
            position: 'relative',
            width: '100%'
          }}
        >
          <AutomaticResponseVariables
            anchorEl={automaticResponseVariableAnchorEl}
            onRequestClose={onCloseAutomaticResponseVariablesPopup}
            open={openAutomaticResponseVariablesPopup}
            selectVariable={onSelectAutomaticResponseVariable}
            {...{automaticResponseVariables, i18n}}
          />
          <span
            data-test-id="variables-button"
            style={{
              color: blue,
              position: 'absolute',
              bottom: '12px',
              left: '10px',
              cursor: 'pointer'
            }}
            onClick={onOpenAutomaticResponseVariablesPopup}
          >
            {i18n.t('administration.business.pages.channels.gbm.addVariable')}
          </span>
          <textarea
            data-test-id="textarea"
            ref={register}
            name="automaticResponse"
            placeholder={i18n.t('administration.business.pages.channels.gbm.automaticResponseHint')}
            rows={4}
            style={{
              border: 'none',
              fontFamily: 'inherit',
              height: '56px',
              maxWidth: '646px',
              outline: 'none',
              padding: '5px 0 25px 10px',
              resize: 'none',
              width: '100%'
            }}
          />
        </div>
      </div>

      <div style={{...commonSelectInputFieldWrapperStyles, marginTop: '10px'}}>
        <label htmlFor="resendMessage">
          {i18n.t('administration.business.pages.channels.gbm.resendMessageLabel')}
        </label>
        <select
          data-test-id="resend-message"
          id="resendMessage"
          style={{
            ...commonSelectInputFieldStyles,
            width: '123px'
          }}
          ref={register}
          name="resendMessage"
        >
          {getResendMessageOptions().map((resendOption) => (
            <option key={resendOption.value} value={resendOption.value}>
              {i18n.t(resendOption.translation)}
            </option>
          ))}
        </select>
      </div>
      {renderBusinessLocationError()}

      {submitting ? (
        <LoadingIndicator size={20} />
      ) : (
        <StandardButtonComponent
          data-test-id="business-location-submit-button"
          disabled={!userHasSearchedForAddress}
          style={{
            background: userHasSearchedForAddress ? blue : americanSilver,
            color: white,
            alignSelf: 'end',
            fontWeight: fontWeight.semiBold,
            margin: '8px 32px 0 0'
          }}
          type="submit"
        >
          {i18n.t('administration.business.pages.channels.gbm.connectLabelButton')}
        </StandardButtonComponent>
      )}
    </form>
  );
};

AdministrationBusinessPageChannelGbmBusinessLocationFormComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  businessLocationForm: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseBusinessLocation: PropTypes.func.isRequired,
  onGbmBusinessLocationFormSubmitButtonClick: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelGbmBusinessLocationFormComponent;
