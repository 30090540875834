import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {createSelector, createStructuredSelector} from 'reselect';
import InsightsComponent from '../../../component/content/insights/insights-component';
import {INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH} from '../../../actions/insight-actions';

const routeSelector = createStructuredSelector({
  path: (routing) => routing.location.pathname
});

const InsightsContainer = connect(
  createSelector(
    [
      (state, route) => routeSelector(route),
      (state) => state.get('account'),
      (state) => state.get('featureToggle'),
      (state) => state.getIn(['insight', 'businessesSelection'])
    ],
    (route, account, featureToggle, businessesSelection) => {
      return {
        account: account.toJS(),
        businessesSelection: businessesSelection ? businessesSelection.toJS() : null,
        featureToggles: featureToggle.toJS(),
        isInsightBusinessesSelectionInitialized: businessesSelection !== null,
        path: route.path
      };
    }
  ),
  {
    push,
    doInsightBusinessesSelectionInitialize: () => ({
      type: INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH
    })
  }
)(InsightsComponent);

export default InsightsContainer;
