import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import ChangePasswordComponent from '../component/change-password-component';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {CHANGE_PASSWORD_SUBMIT} from '../actions/change-password-actions';

const ChangePasswordContainer = connect(
  createSelector(
    [(state) => state.get('ui'), (state) => state.get('changePassword')],
    (ui, changePassword) => {
      return {
        language: ui.get('language'),
        ...changePassword.toJS()
      };
    }
  ),
  {
    ...uiDispatchToProps,
    doChangePasswordSubmit(password, token, isFormValid) {
      return {
        type: CHANGE_PASSWORD_SUBMIT,
        _googleAnalytics: true,
        isFormValid,
        password,
        token
      };
    }
  }
)(ChangePasswordComponent);

export default ChangePasswordContainer;
