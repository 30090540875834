import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import SignInComponent from '../component/signin-component';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {
  SIGNIN_IMPROVE_PASSWORD_CONTINUE,
  SIGNIN_IMPROVE_PASSWORD_SUBMIT
} from '../actions/signin-improve-actions';
import {SIGNIN_LOGIN_BLOCKING_EXPIRED, SIGNIN_LOGIN_SUBMIT} from '../actions/signin-login-actions';

const SignInContainer = connect(
  createSelector(
    [
      (state) => state.get('signInImprovePassword'),
      (state) => state.get('signInLogin'),
      (state) => state.get('ui')
    ],
    (signInImprovePassword, signInLogin, ui) => {
      const signInLoginError = signInLogin.getIn(['errors', 0]);

      return {
        blockedUntil:
          typeof signInLoginError === 'object' && signInLoginError.get('context')
            ? signInLoginError.getIn(['context', 'blockedUntil'])
            : null,
        isTinyScreen: ui.get('isTinyScreen'),
        language: ui.get('language'),
        mustUpdatePassword: signInLogin.get('login')
          ? signInLogin.getIn(['login', 'mustUpdatePassword'])
          : null,
        signInImprovePassword: signInImprovePassword.toJS(),
        ...signInLogin.toJS()
      };
    }
  ),
  {
    ...uiDispatchToProps,
    doContinueToTheApp() {
      return {
        type: SIGNIN_IMPROVE_PASSWORD_CONTINUE,
        _googleAnalytics: true
      };
    },
    doImprovePasswordSubmit(password, isFormValid) {
      return {
        type: SIGNIN_IMPROVE_PASSWORD_SUBMIT,
        _googleAnalytics: true,
        isFormValid,
        password
      };
    },
    doSubmitSignIn(email, keepSignedIn, password, isFormValid) {
      return {
        type: SIGNIN_LOGIN_SUBMIT,
        _googleAnalytics: true,
        email,
        isFormValid,
        keepSignedIn,
        password
      };
    },
    onBlockingExpired() {
      return {
        type: SIGNIN_LOGIN_BLOCKING_EXPIRED
      };
    }
  }
)(SignInComponent);

export default SignInContainer;
