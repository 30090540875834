import PropTypes from 'prop-types';
import numeral from 'numeral';
import context from '../../../../../../shared/component/context';
import formatInsightDuration from '../lib/format-insight-duration';
import {
  generateChartTitle,
  getLinkedFigure,
  getLinkedFigureAndVariation
} from '../lib/chart-title-generator';
import DoublePanelsContainer, {
  getDoublePanelsStyle,
  getRightDoublePanelsStyle
} from '../common/layout/double-panels-container-component';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {graphYellow} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'newOutboundConversations';

/**
 * Finally, the component.
 */
class InsightNewOutboundConversationsComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      isSmallScreen,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    const {averageCustomerResponseRate, averageCustomerResponseTime, newOutboundConversations} =
      insight.newOutboundConversationsPageHistogram;

    const doublePanelsStyle = getDoublePanelsStyle(isSmallScreen);
    const rightDoublePanelsStyle = getRightDoublePanelsStyle(isSmallScreen);

    const isClassicPeriod = periodTabSelected === 'classic';
    const aggregationPeriod = isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod;

    const customerResponseNumber = averageCustomerResponseRate.data.averageCustomerResponseRate;
    const newOutboundConversationsNumber = newOutboundConversations.data.newOutboundConversations;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <DoublePanelsContainer>
            <InsightPanelContentBlock dataTestId="new-outbound-conversations" noEffect>
              <HistogramChart
                failed={newOutboundConversations.failed}
                items={newOutboundConversations.data.newOutboundConversationsHistogram}
                loading={newOutboundConversations.loading}
                translations={i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.items.newOutboundConversations.chart`,
                  {returnObjects: true}
                )}
                type="column"
                {...{
                  aggregationPeriod
                }}
                title={generateChartTitle({
                  figure: numeral(newOutboundConversationsNumber).format(),
                  hasData: newOutboundConversations.data.newOutboundConversationsHistogram.length,
                  label: i18n.t(
                    `insight.pages.${INSIGHT_PAGE}.items.newOutboundConversations.chart.title`
                  ),
                  showVariation: isClassicPeriod,
                  variation: newOutboundConversations.data.newOutboundConversationsIncrement
                })}
                series={[
                  {
                    property: 'newOutboundConversations',
                    color: graphYellow
                  }
                ]}
              />
            </InsightPanelContentBlock>

            <InsightPanelContentBlock
              dataTestId="customer-response"
              noEffect
              style={doublePanelsStyle}
            >
              <HistogramChart
                failed={averageCustomerResponseRate.failed}
                items={averageCustomerResponseRate.data.averageCustomerResponseRateHistogram}
                loading={averageCustomerResponseRate.loading}
                translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.items.customerResponse.chart`, {
                  returnObjects: true
                })}
                type="line"
                valueSuffix="%"
                {...{
                  aggregationPeriod
                }}
                title={generateChartTitle({
                  hasData:
                    averageCustomerResponseRate.data.averageCustomerResponseRateHistogram.length,
                  label: i18n.t(`insight.pages.${INSIGHT_PAGE}.items.customerResponse.chart.title`),
                  showVariation: isClassicPeriod,
                  ...getLinkedFigureAndVariation(
                    newOutboundConversationsNumber,
                    `${numeral(customerResponseNumber).format()}%`,
                    averageCustomerResponseRate.data.averageCustomerResponseRateIncrement
                  )
                })}
                series={[
                  {
                    property: 'averageCustomerResponseRate',
                    color: graphYellow
                  }
                ]}
              />
            </InsightPanelContentBlock>

            <InsightPanelContentBlock
              dataTestId="customer-response-time"
              noEffect
              style={rightDoublePanelsStyle}
            >
              <HistogramChart
                failed={averageCustomerResponseTime.failed}
                items={averageCustomerResponseTime.data.averageCustomerResponseTimeHistogram}
                loading={averageCustomerResponseTime.loading}
                tooltipValueFormatter={this._formatInsightDurationFromSec}
                translations={i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.items.customerResponseTime.chart`,
                  {returnObjects: true}
                )}
                type="line"
                {...{
                  aggregationPeriod
                }}
                title={generateChartTitle({
                  hasData:
                    averageCustomerResponseTime.data.averageCustomerResponseTimeHistogram.length,
                  isColorInverted: true,
                  label: i18n.t(
                    `insight.pages.${INSIGHT_PAGE}.items.customerResponseTime.chart.title`
                  ),
                  figure: getLinkedFigure(
                    newOutboundConversationsNumber,
                    formatInsightDuration(
                      averageCustomerResponseTime.data.averageCustomerResponseTime,
                      i18n
                    )
                  )
                })}
                series={[
                  {
                    property: 'averageCustomerResponseTime',
                    color: graphYellow
                  }
                ]}
              />
            </InsightPanelContentBlock>
          </DoublePanelsContainer>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightNewOutboundConversationsComponent.contextTypes = context;

InsightNewOutboundConversationsComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any),
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightNewOutboundConversationsComponent;
