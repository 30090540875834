import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import context from '../context';
import {BottomButtonsContainer} from '../fullscreen-layout-component';
import CheckedIcon from '../icons/checked-icon';
import ErrorOutlineIcon from '../icons/error-icon';
import FormMessage from '../form/form-message-component';
import LoadingIndicator from '../loading-indicator-component';
import Paper from '../paper-component';
import {fontSize, fontWeight, iconSize, loaderSize, radius, spacing} from '../../style/variables';
import {red} from '../../style/colors';

export const inputStyle = {
  margin: '2px 0'
};

export const BottomLink = ({labelKey, link}, {i18n}) => (
  <p style={{marginTop: '20px', textAlign: 'center'}}>
    {labelKey ? i18n.t(labelKey) : null}{' '}
    {typeof link === 'string' ? <span dangerouslySetInnerHTML={{__html: link}} /> : link}
  </p>
);
BottomLink.contextTypes = context;

BottomLink.propTypes = {
  labelKey: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
};

const StyledPaper = withStyles({
  elevation1: {
    padding: '10px 30px 32px 30px'
  },
  rounded: {
    borderRadius: radius.xxxlarge
  }
})((props) => <Paper square={false} {...props} />);

/**
 * Finally, the component.
 */
class PublicFormComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);
  }

  _renderResultIcon = () => {
    const {customSuccessIcon, globalErrorMessage} = this.props;

    return globalErrorMessage ? (
      <ErrorOutlineIcon fontSize={iconSize.xxxlarge} />
    ) : (
      customSuccessIcon || <CheckedIcon style={{fontSize: iconSize.xxxlarge}} />
    );
  };

  _renderBottomButtons = () => {
    const {buttons, isTinyScreen} = this.props;

    return Array.isArray(buttons) ? (
      <div
        style={{
          display: 'flex',
          flexDirection: isTinyScreen ? 'column' : 'row-reverse',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        {buttons}
      </div>
    ) : (
      buttons
    );
  };

  _onSubmit(event) {
    const {doSubmit} = this.props;
    event.preventDefault();

    doSubmit();
  }

  render() {
    const {
      bottomLinks,
      children,
      description,
      descriptionDataTestId,
      descriptionKeepHtml,
      globalErrorMessage,
      showErrorMessage,
      showResultIcon,
      submitting,
      submittingLabel,
      title
    } = this.props;

    return (
      <div data-test-id="form">
        <StyledPaper>
          <h2
            style={{
              margin: `${spacing.medium} 0`,
              padding: 0,
              textAlign: 'center',
              fontSize: fontSize.xxxxlarge,
              fontWeight: fontWeight.normal
            }}
          >
            {showResultIcon ? this._renderResultIcon() : null}
            <span data-test-id="title">{title}</span>
          </h2>

          {description ? (
            <FormMessage
              dataTestId={descriptionDataTestId}
              keepHtml={descriptionKeepHtml}
              label={description}
            />
          ) : null}

          <form onSubmit={this._onSubmit}>
            {children}

            {globalErrorMessage && showErrorMessage ? (
              <div
                data-test-id="form-error"
                dangerouslySetInnerHTML={{__html: globalErrorMessage}}
                style={{marginTop: children ? '20px' : null, textAlign: 'center', color: red}}
              />
            ) : null}

            <BottomButtonsContainer style={{paddingTop: '25px'}}>
              {submitting ? (
                <span>
                  <LoadingIndicator
                    size={loaderSize.small}
                    label={submittingLabel}
                    style={{margin: '6px 0'}}
                  />
                </span>
              ) : (
                this._renderBottomButtons()
              )}
            </BottomButtonsContainer>
          </form>
        </StyledPaper>

        {bottomLinks}
      </div>
    );
  }
}

PublicFormComponent.defaultProps = {
  showErrorMessage: true
};

PublicFormComponent.propTypes = {
  bottomLinks: PropTypes.arrayOf(PropTypes.any),
  buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  customSuccessIcon: PropTypes.objectOf(PropTypes.any),
  description: PropTypes.string,
  descriptionDataTestId: PropTypes.string,
  descriptionKeepHtml: PropTypes.bool,
  globalErrorMessage: PropTypes.string,
  isTinyScreen: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  showResultIcon: PropTypes.bool,
  submitting: PropTypes.bool,
  submittingLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  doSubmit: PropTypes.func
};

export default PublicFormComponent;
