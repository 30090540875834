import {of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Submit a message into a thread.
 *
 * @param {Object} callback
 * @param {Function} command
 * @param {Object} extraParams
 * @param {Object} extraProps
 * @param {Object} extraSuccessProps
 * @param {Object} nextActions
 * @param {String} participationId
 * @param {Object} sendAction
 * @returns {Object} command result
 */
export default ({
  callback,
  command,
  extraParams,
  extraProps,
  extraSuccessProps,
  nextActions,
  participationId,
  sendAction
}) => {
  return command('send-message', {
    attachment: sendAction.attachment ? {href: sendAction.attachment.href} : null,
    clientMessageId: sendAction.clientMessageId,
    text: sendAction.text,
    participationId,
    ...extraParams
  }).pipe(
    mergeMap(({data}) => {
      const successAction = {
        clientMessageId: sendAction.clientMessageId,
        fullName: sendAction.fullName,
        id: data.id,
        picture: sendAction.picture,
        text: sendAction.text,
        type: nextActions.success,
        userId: sendAction.userId,
        participationId,
        ...extraProps,
        ...extraSuccessProps
      };

      if (callback) {
        return [successAction, callback];
      }

      return [successAction];
    }),
    catchError((error) => {
      const sendActionCopy = cloneDeep(sendAction);
      delete sendActionCopy._googleAnalytics;

      return of({
        type: nextActions.failure,
        failedAction: sendActionCopy,
        error,
        ...extraProps
      });
    })
  );
};
