import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import RedirectIcon from '../icon/redirect-icon';
import {lightBlue, lightSlate} from '../../../../../shared/style/colors';
import {PADDING_THREAD} from '../../../data/settings';

const NewConversationRowComponent = ({threadType}, {i18n}) => {
  const COMMON_PROPS = {
    'data-test-id': 'new-conversation'
  };

  const COMMON_STYLES = {
    margin: '10px 0'
  };

  if (threadType === 'invisible') {
    return (
      <div
        {...COMMON_PROPS}
        style={{
          display: 'flex',
          alignItems: 'center',
          color: lightSlate,
          padding: PADDING_THREAD,
          ...COMMON_STYLES
        }}
      >
        <RedirectIcon />{' '}
        <span
          style={{padding: '6px 0'}}
          dangerouslySetInnerHTML={{
            __html: i18n.t('customerThread.invisibleThread.newConversationSeparator')
          }}
        />
      </div>
    );
  }

  return (
    <div
      {...COMMON_PROPS}
      style={{
        left: '-20px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: lightBlue,
        padding: '10px',
        ...COMMON_STYLES
      }}
    >
      {i18n.t('thread.newConversationSeparatorVisible')}
    </div>
  );
};

NewConversationRowComponent.contextTypes = context;

NewConversationRowComponent.propTypes = {
  threadType: PropTypes.string.isRequired
};

export default NewConversationRowComponent;
