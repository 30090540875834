import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Switch} from '@material-ui/core';
import {fontSize} from '../../style/variables';
import {blue, darkSmoke, white} from '../../style/colors';

const LABEL_MARGIN = '3px';
const DISABLE_HOVER = {
  '&:hover': {
    backgroundColor: 'rgb(0, 0, 0, 0) !important'
  }
};

/**
 * Finally, the component.
 */
export default withStyles({
  root: {
    width: '62px'
  },
  track: {
    position: 'absolute',
    opacity: '1 !important',
    marginTop: '-5px',
    width: '50px',
    height: '24px',
    borderRadius: '15px',
    backgroundColor: `${white} !important`
  },
  switchBase: {
    marginLeft: '5px',
    color: darkSmoke,
    '&:after': {
      content: '"OFF"',
      marginLeft: LABEL_MARGIN,
      fontSize: fontSize.small,
      color: darkSmoke
    },
    ...DISABLE_HOVER
  },
  checked: {
    marginLeft: '-12px',
    color: blue,
    '&:before': {
      content: '"ON"',
      marginRight: LABEL_MARGIN,
      fontSize: fontSize.small,
      color: blue
    },
    '&:after': {
      display: 'none'
    },
    ...DISABLE_HOVER
  },
  disabled: {
    opacity: 0.5
  }
})(React.forwardRef((props, ref) => <Switch disableRipple {...props} ref={ref} />));
