import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from './lib/get-insights-map-dispatch-to-props';
import InsightTopStoresComponent from '../../../../component/content/insights/page/insight-top-stores-component';

const InsightTopStoresContainer = connect(
  createSelector([(state) => state.get('insight')], (insight) => {
    return {
      classicAggregationDate: insight.get('classicAggregationDate'),
      classicAggregationPeriod: insight.get('classicAggregationPeriod'),
      insight: insight.get('topStores').toJS(),
      periodTabSelected: insight.get('periodTabSelected')
    };
  }),
  getInsightsMapDispatchToProps('topStores')
)(InsightTopStoresComponent);

export default InsightTopStoresContainer;
