import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from './lib/get-insights-map-dispatch-to-props';
import InsightContactsComponent from '../../../../component/content/insights/page/insight-contacts-component';

const InsightContactsContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.get('insight'),
      (state) => state.get('insightBusinessFilter')
    ],
    (businesses, insight, insightBusinessFilter) => {
      return {
        accountBusinesses: businesses.toJS(),
        businessesSelection: insight.get('businessesSelection').toJS(),
        classicAggregationDate: insight.get('classicAggregationDate'),
        classicAggregationPeriod: insight.get('classicAggregationPeriod'),
        customAggregationPeriod: insight.get('customAggregationPeriod'),
        customPeriodEndDate: insight.get('customPeriodEndDate'),
        customPeriodStartDate: insight.get('customPeriodStartDate'),
        insight: insight.get('contacts').toJS(),
        insightBusinessFilter: insightBusinessFilter.toJS(),
        periodTabSelected: insight.get('periodTabSelected')
      };
    }
  ),
  getInsightsMapDispatchToProps('contacts')
)(InsightContactsComponent);

export default InsightContactsContainer;
