import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import MessagesRowBubbles from './messages-row-bubbles-component';
import OrganizationAvatar from '../avatar/organization-avatar-component';
import UserAvatar from '../avatar/user-avatar-component';
import {avatarSize, fontSize} from '../../../../../shared/style/variables';
import {lightSlate} from '../../../../../shared/style/colors';
import {CONVERSATION_ITEM_TYPES} from '../../../data/thread/message';
import {PADDING_THREAD} from '../../../data/settings';

const MessagesRowComponent = (
  {
    account,
    automatic,
    canShowIncomingAvatar = true,
    canShowIncomingSenderName = false,
    doSendMessageRetry,
    doThreadFilePreviewOpen,
    employee,
    messages,
    orgIcon,
    type,
    threadType
  },
  {i18n}
) => {
  const isOutgoing = type === CONVERSATION_ITEM_TYPES.OUTBOUND;
  const showAvatar = isOutgoing || canShowIncomingAvatar;
  const showSenderName = (isOutgoing && !automatic) || (!isOutgoing && canShowIncomingSenderName);

  let alignMessagesToTheRight;
  switch (type) {
    case CONVERSATION_ITEM_TYPES.INTERNAL:
      alignMessagesToTheRight = employee && employee.id === account.id;
      break;
    case CONVERSATION_ITEM_TYPES.OUTBOUND:
      alignMessagesToTheRight = true;
      break;
    default:
      alignMessagesToTheRight = false;
  }

  const renderAvatar = () => {
    const employeePictureHref = employee ? employee.pictureHref : null;
    const messageAvatarSize = avatarSize.small;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-end',
          width: `${messageAvatarSize}px`,
          maxWidth: `${messageAvatarSize}px`
        }}
      >
        <div
          style={{
            position: 'relative',
            width: `${messageAvatarSize}px`,
            height: `${messageAvatarSize}px`
          }}
        >
          {automatic ? (
            <OrganizationAvatar
              dataTestId="organization-avatar"
              size={messageAvatarSize}
              src={orgIcon}
            />
          ) : (
            <UserAvatar
              organizationIconSize={avatarSize.xxsmall}
              size={messageAvatarSize}
              src={employeePictureHref}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSenderName = () => (
    <div
      data-test-id="sender-name"
      style={{
        marginBottom: '2px',
        fontSize: fontSize.small,
        color: lightSlate
      }}
    >
      {employee ? employee.displayName : null}
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: alignMessagesToTheRight ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
        width: '100%',
        padding: PADDING_THREAD
      }}
    >
      {showAvatar ? renderAvatar() : null}

      <div
        style={{
          maxWidth: '65%',
          margin: showAvatar ? '0 5px' : null,
          textAlign: alignMessagesToTheRight ? 'right' : 'left'
        }}
      >
        {showSenderName ? renderSenderName() : null}

        <MessagesRowBubbles
          {...{
            account,
            doSendMessageRetry,
            doThreadFilePreviewOpen,
            employee,
            i18n,
            messages,
            threadType,
            type
          }}
        />
      </div>
    </div>
  );
};

MessagesRowComponent.contextTypes = context;

MessagesRowComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  automatic: PropTypes.bool, // may not be present in few cases, for example when current employee is sending a message
  canShowIncomingAvatar: PropTypes.bool,
  canShowIncomingSenderName: PropTypes.bool,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  employee: PropTypes.objectOf(PropTypes.any), // is not present when rendering customer messages
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  orgIcon: PropTypes.string,
  threadType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default MessagesRowComponent;
