import {fromJS} from 'immutable';
import {cloneDeep} from 'lodash';
import {omitNil} from '../../../../shared/lib/object-helper';
import {findMessageIndexInConversationItem} from '../../lib/thread/conversation-item-helper';
import {CONVERSATION_MESSAGE_FLOW_STATUS} from '../../data/thread/message';

const conversationItemsSorter = (conversationItems) =>
  conversationItems.update('edges', (edges) =>
    edges.sortBy((item) => item.getIn(['node', 'sortId']))
  );

const getRenderableMentions = (mentions) => {
  if (!mentions || !mentions.length) {
    return null;
  }

  return mentions.reduce(
    (renderableMentions, mention) => [
      ...renderableMentions,
      {name: mention.name, type: mention.type}
    ],
    []
  );
};

/**
 * Add a conversation item at the end of a list of conversation items.
 * We voluntarily don't destructure too much in order to better understand where data comes from.
 * @param {Object} item
 * @param {Immutable.List} conversationItems
 * @param {Object} [employee]
 * @param {Object} [organization]
 * @returns {Immutable.List}
 */
const appendItemToConversationItems = (item, conversationItems, employee, organization = {}) => {
  const node = omitNil({
    automatic: item.automatic,
    clientItemId: item.clientItemId,
    date: item.date,
    employee: employee
      ? omitNil({
          displayName: employee.displayName,
          id: employee.id,
          pictureHref: employee.pictureHref
        })
      : null,
    firstOfSegment: item.firstOfSegment,
    flowStatus: item.flowStatus,
    id: item.id,
    mentions: getRenderableMentions(item.mentions),
    orgIcon: organization.pictureHref,
    sortId: item.sortId,
    status: item.status,
    text: item.text,
    type: item.type
  });

  const {attachment} = item;
  if (attachment) {
    node.attachment = {
      contentLength: attachment.contentLength,
      contentType: attachment.contentType,
      href: attachment.href,
      name: attachment.name,
      sticker: attachment.sticker
    };
  }

  const newMessageEdge = fromJS(
    omitNil({
      cursor: item.cursor,
      node
    })
  );

  return conversationItems.push(newMessageEdge);
};

/**
 * Optimistic update of thread on start sending a message.
 * Reset only thread form "fileUploaded" because "text" is reset separately from editor component.
 * Eventual message previously added with same clientItemId is moved from its current position to the end.
 * @param {Object} employee
 * @param {Object} message
 * @param {String} messageType
 * @param {Array} mentions
 * @param {Object} organization
 * @param {Immutable.Map} state
 * @returns {Immutable.Map} new state
 */
const startSendingMessageInConversation = ({
  employee,
  mentions,
  message,
  messageType,
  organization,
  state
}) =>
  state
    .set('sending', true)
    .setIn(['threadsForm', state.get('id'), 'fileUploaded'], null)
    .updateIn(['conversationItems', 'edges'], (edges) => {
      const edgesWithoutCurrentMessage = edges.filter((edge) => {
        const nodeClientItemId = edge.getIn(['node', 'clientItemId']);

        return !nodeClientItemId || nodeClientItemId !== message.clientItemId;
      });

      let messageBeingSent = {
        ...cloneDeep(message),
        flowStatus: CONVERSATION_MESSAGE_FLOW_STATUS.SENDING,
        type: messageType
      };

      messageBeingSent = mentions ? {...messageBeingSent, mentions} : messageBeingSent;

      return appendItemToConversationItems(
        messageBeingSent,
        edgesWithoutCurrentMessage,
        employee,
        organization
      );
    });

/**
 * Update some thread state after failure of sending a message:
 * - thread is not in a "sending state" anymore
 * - message flow status is updated to 'error'
 * - sending parameters are backed up to be used later on retry
 * The early exit if the message index if found is a security in case failure is triggered after conversation changed.
 * @param {Immutable.Map} state
 * @param {Object} failedAction
 * @returns {Immutable.Map} new state
 */
const failSendingMessageInConversation = (state, {payload: {failedAction}}) => {
  const newState = state.set('sending', false);

  const messageIndex = findMessageIndexInConversationItem(state, failedAction.payload.message);

  if (messageIndex === -1) {
    return newState;
  }

  return newState.updateIn(
    ['conversationItems', 'edges', messageIndex, 'node'],
    (conversationItemNode) =>
      conversationItemNode
        .set('_sendAction', fromJS(failedAction))
        .set('flowStatus', CONVERSATION_MESSAGE_FLOW_STATUS.ERROR)
  );
};

/**
 * Update some thread state after a success of sending a message:
 * - thread is not in a "sending state" anymore
 * - some message data are updated : id from server / flow status to "sent"
 * = items total count is incremented by 1
 * The early exit if the message is found is a security in case success is triggered after conversation changed.
 * Because date and also employee behaviour are not reliable, we must sort the items once the message is updated with its sortId.
 * @param {Immutable.Map} state
 * @param {Object} message
 * @returns {Immutable.Map} new state
 */
const succeedSendingMessageInConversation = (state, {payload: {message}}) => {
  const newState = state.set('sending', false);

  const messageIndex = findMessageIndexInConversationItem(state, message);

  if (messageIndex === -1) {
    return newState;
  }

  const conversationItemUpdater = (item) =>
    item
      .set('cursor', message.cursor)
      .update('node', (node) =>
        node
          .set('flowStatus', CONVERSATION_MESSAGE_FLOW_STATUS.SENT)
          .set('id', message.id)
          .set('sortId', message.sortId)
      );

  return newState
    .updateIn(['conversationItems', 'edges', messageIndex], conversationItemUpdater)
    .update('conversationItems', conversationItemsSorter);
};

/**
 * Add an item into the conversation items, then sort them.
 * @param {Immutable.Map} state
 * @param {Object} item
 * @param {Object} conversation
 * @param {Object} [employee]
 * @returns {Immutable.Map} new state
 */
const addItemInConversation = (state, item, conversation, employee = null) =>
  state
    .updateIn(['conversationItems', 'edges'], (edges) =>
      appendItemToConversationItems(item, edges, employee)
    )
    .update('conversationItems', conversationItemsSorter);

/**
 * Eventually add an event into the conversation items.
 * @param {Immutable.Map} state
 * @param {Object} conversation
 * @param {Object} employee
 * @param {Object} event
 * @returns {Immutable.Map} new state
 */
const addEventuallyEventInConversation = (state, {payload: {conversation, employee, event}}) => {
  if (conversation.id !== state.get('id')) {
    return state;
  }

  return addItemInConversation(state, event, conversation, employee);
};

/**
 * Eventually add a message into the conversation items.
 * @param {Immutable.Map} state
 * @param {Object} conversation
 * @param {Object} message
 * @returns {Immutable.Map} new state
 */
const addEventuallyMessageInConversation = (state, {payload: {conversation, message}}) => {
  if (conversation.id !== state.get('id')) {
    return state;
  }

  const index = findMessageIndexInConversationItem(state, message);

  if (index !== -1) {
    return state;
  }

  return addItemInConversation(state, message, conversation, message.employee);
};

export {
  addEventuallyEventInConversation,
  addEventuallyMessageInConversation,
  failSendingMessageInConversation,
  startSendingMessageInConversation,
  succeedSendingMessageInConversation
};
