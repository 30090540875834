import PropTypes from 'prop-types';
import {startsWith} from 'lodash';
import {getBusinessesManaged, limitBusinessesList} from '../../../../lib/administration-businesses';
import getOrganizationMenuOptions from '../lib/administration-organization-menu-options';
import AdministrationLeftMenuBlockLayout from './administration-left-menu-block-layout-component';
import AdministrationLeftMenuBlockTitle from './administration-left-menu-block-title-component';
import AdministrationLeftMenuCountries from './administration-left-menu-countries-component';
import AdministrationLeftMenuItem from './administration-left-menu-item-component';
import AdministrationLeftMenuShowAllItems from './administration-left-menu-show-all-items-component';
import AdministrationLeftPanelTitle from './administration-left-panel-title-component';
import LeftPanelLayout from '../../../common/layout/left-panel-layout-component';
import {mediumGrey} from '../../../../../../shared/style/colors';
import {ADMIN_AREA_LEFT_MENU_LIMIT} from '../../../../data/settings';

const AdministrationLeftMenu = ({
  account,
  currentPageContext,
  enableBusinessMessages,
  enableIpRanges,
  i18n,
  language,
  location,
  organizationCountries
}) => {
  const {businesses, managerOfABusiness, organizationName} = account;

  const hasABusinessInScope = businesses.length > 0;
  const isOrganizationAdministrator = account.manager;

  const adminBusinesses = getBusinessesManaged(businesses);
  const limitedAdminBusinesses = limitBusinessesList(adminBusinesses, ADMIN_AREA_LEFT_MENU_LIMIT);
  const totalAdminBusinesses = adminBusinesses.length;
  const canShowButtonAllBusinessItems = totalAdminBusinesses > ADMIN_AREA_LEFT_MENU_LIMIT;

  const organizationMenuOptions = getOrganizationMenuOptions({
    enableBusinessMessages,
    enableIpRanges,
    i18n,
    isOrganizationAdministrator,
    managerOfABusiness
  });

  return (
    <LeftPanelLayout style={{minWidth: '310px'}}>
      <AdministrationLeftPanelTitle title={i18n.t('administration.header.leftPanelTitle')} />

      {hasABusinessInScope ? (
        <div
          data-test-id="left-menu-block-businesses"
          style={{color: mediumGrey, padding: '20px 0 0 20px'}}
        >
          <AdministrationLeftMenuBlockTitle
            iconName="STORE"
            title={i18n.t('administration.leftPanel.businesses.stores')}
          />
          <AdministrationLeftMenuBlockLayout dataTestId="left-menu-businesses-list">
            {limitedAdminBusinesses.map(({businessId, name: businessName}) => (
              <AdministrationLeftMenuItem
                href={`${OLD_WEBAPP_URL}/a#/administration/store/${businessId}`}
                isSelected={businessId === currentPageContext.businessId}
                key={businessId}
                label={businessName}
                showLabelAdmin
                {...{i18n}}
              />
            ))}

            {canShowButtonAllBusinessItems ? (
              <AdministrationLeftMenuShowAllItems
                dataTestId="show-all-businesses"
                expandItems={false}
                itemLabel={i18n.t('administration.leftPanel.businesses.showAll', {
                  itemsAmount: totalAdminBusinesses
                })}
                itemUrl={`${OLD_WEBAPP_URL}/a#/administration/organization-stores`}
                {...{i18n}}
              />
            ) : null}
          </AdministrationLeftMenuBlockLayout>
        </div>
      ) : null}

      {isOrganizationAdministrator ? (
        <AdministrationLeftMenuCountries {...{i18n, language, organizationCountries}} />
      ) : null}

      <div
        data-test-id="left-menu-block-organization"
        style={{color: mediumGrey, padding: '20px 0 0 20px'}}
      >
        <AdministrationLeftMenuBlockTitle title={organizationName} iconName="ORGANIZATION" />
        <AdministrationLeftMenuBlockLayout
          dataTestId="left-menu-organization-menu-list"
          style={{borderBottom: 'none'}}
        >
          {organizationMenuOptions
            .filter(({canShowOption}) => canShowOption)
            .map(
              ({organizationOptionDataTestId, organizationOptionName, organizationOptionUrl}) => (
                <AdministrationLeftMenuItem
                  dataTestId={organizationOptionDataTestId}
                  href={organizationOptionUrl}
                  isSelected={startsWith(location.pathname, organizationOptionUrl)}
                  key={organizationOptionDataTestId}
                  label={organizationOptionName}
                />
              )
            )}
        </AdministrationLeftMenuBlockLayout>
      </div>
    </LeftPanelLayout>
  );
};

AdministrationLeftMenu.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  currentPageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  enableBusinessMessages: PropTypes.bool.isRequired,
  enableIpRanges: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  language: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  organizationCountries: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationLeftMenu;
