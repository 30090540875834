import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import getSignInNextUrl from './lib/get-signin-next-url';
import isAuthenticated from '../../shared/lib/is-authenticated';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import signInImprovePassword from './reducer/signin-improve-password-reducer';
import signInLogin from './reducer/signin-login-reducer';
import signInImprovePasswordEpic from './epic/signin-improve-password-epic';
import signInLoginEpic from './epic/signin-login-epic';

export default () => {
  if (isAuthenticated()) {
    window.location = getSignInNextUrl();

    return null;
  }

  const mea = middleEndAccessors();

  const rootEpic = createRootEpic(signInImprovePasswordEpic(mea), signInLoginEpic(mea));

  const rootReducer = createRootReducer({
    signInImprovePassword,
    signInLogin
  });

  return {
    rootEpic,
    rootReducer
  };
};
