import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {List, ListItem} from 'material-ui/List';
import keycode from 'keycode';
import context from '../../../../shared/component/context';
import EnhancedPopover from './enhanced-popover-component';
import LoadingIndicator from '../../../../shared/component/loading-indicator-component';
import OrganizationAvatar from './avatar/organization-avatar-component';
import {animationSpeed, fontSize, radius} from '../../../../shared/style/variables';
import {darkSnow, lightSlate, lightSmoke, lightSnow} from '../../../../shared/style/colors';

export const focusItem = (refsContainer, index, delayed = false) => {
  if (index === null) {
    return;
  }

  // Sometime without setTimeout, will not always give focus
  setTimeout(
    () => {
      const item = refsContainer.refs[`businessItem${index}`];
      if (!item) {
        return;
      }

      // @see https://github.com/callemall/material-ui/issues/1670
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(item).focus();
      item.applyFocusState('keyboard-focused');
    },
    delayed ? animationSpeed.fast : 0
  );
};

export const businessListItem = ({
  business,
  i,
  organizationPicture,
  showBorderBottom,
  onClick,
  onItemKeyboardFocus
}) => (
  <ListItem
    data-test-id="list-item"
    key={i}
    onClick={onClick}
    onKeyboardFocus={onItemKeyboardFocus}
    ref={`businessItem${i}`}
    innerDivStyle={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      boxSizing: 'border-box',
      padding: 0
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        width: '100%'
      }}
    >
      <div style={{padding: '10px 10px 10px 20px'}}>
        <OrganizationAvatar src={organizationPicture} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          borderBottom: showBorderBottom ? `1px solid ${darkSnow}` : null
        }}
      >
        <h3 data-test-id="item-label" style={{fontSize: fontSize.medium}}>
          {business.name}
        </h3>
      </div>
    </div>
  </ListItem>
);

/**
 * Finally, the component.
 */
class BusinessSelectionPopupComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedItemIndex: null
    };

    this._onClickBusiness = this._onClickBusiness.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onInputClick = this._onInputClick.bind(this);
    this._onItemKeyboardFocus = this._onItemKeyboardFocus.bind(this);
    this._onKeypress = this._onKeypress.bind(this);
    this._updateFocusedItemIndex = this._updateFocusedItemIndex.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    /* eslint-disable react/destructuring-assignment */
    if (!this.props.open && nextProps.open) {
      this.props.doSearchBusinesses('');
    } else if (this.props.open && !nextProps.open) {
      window.removeEventListener('keydown', this._onKeypress);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      window.addEventListener('keydown', this._onKeypress);
      this._updateFocusedItemIndex(null);
    } else if (this.props.showBusinessesList) {
      this._handleFocusItemCases(prevProps, prevState);
    }
    /* eslint-enable react/destructuring-assignment */
  }

  _showBusinessNotFoundMessage = () => {
    const {i18n} = this.context;

    return (
      <div
        data-test-id="no-business-found"
        style={{
          padding: '10px',
          textAlign: 'center'
        }}
      >
        {i18n.t('business.noBusinessFound')}
      </div>
    );
  };

  _showBusisnessListData = () => {
    const {filteredBusinesses, organizationPicture} = this.props;

    return filteredBusinesses.length
      ? filteredBusinesses.map((business, i) =>
          businessListItem({
            business,
            i,
            organizationPicture,
            showBorderBottom: i < filteredBusinesses.length - 1,
            onClick: () => this._onClickBusiness(business, i),
            onItemKeyboardFocus: this._onItemKeyboardFocus
          })
        )
      : this._showBusinessNotFoundMessage();
  };

  _showLayoutBusinessData = () => {
    const {filteredBusinesses, showBusinessesList} = this.props;

    return showBusinessesList ? (
      <div style={{borderTop: `1px solid ${lightSmoke}`}}>
        <List
          data-test-id="businesses-result"
          style={{
            maxHeight: '240px',
            overflowX: 'hidden',
            overflowY: filteredBusinesses.length > 4 ? 'scroll' : 'auto',
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          {this._showBusisnessListData()}
        </List>
      </div>
    ) : null;
  };

  _showBusinessListTypes = () => {
    const {alternativeToBusinessesList, children} = this.props;

    return (
      alternativeToBusinessesList || (
        <div>
          {children}
          {this._showLayoutBusinessData()}
        </div>
      )
    );
  };

  _showBusinessListBody = () => {
    const {initializing} = this.props;

    return initializing ? <LoadingIndicator /> : this._showBusinessListTypes();
  };

  _showBusinessContainer = () => {
    const {alternativeToContainer, initializing} = this.props;

    return alternativeToContainer && !initializing ? (
      alternativeToContainer
    ) : (
      <div>
        {this._showSearchInput()}
        {this._showBusinessListBody()}
      </div>
    );
  };

  _showSearchInput() {
    const {i18n} = this.context;
    const {showInput, searchText} = this.props;

    return (
      <div style={{padding: '0 15px 15px 15px'}}>
        <TransitionGroup>
          {showInput ? (
            <CSSTransition classNames="heightAnimation" timeout={animationSpeed.fast}>
              <div key="search-input">
                <input
                  data-test-id="search-input"
                  autoFocus
                  onClick={this._onInputClick}
                  onChange={this._onInputChange}
                  placeholder={i18n.t('business.selectionPopup.inputPlaceholder')}
                  ref={(itself) => {
                    this._input = itself;

                    return this._input;
                  }}
                  value={searchText}
                  style={{
                    boxSizing: 'border-box',
                    marginTop: '8px',
                    width: '100%',
                    padding: '7px 32px 7px 12px',
                    outline: 'none',
                    border: 'none',
                    borderRadius: radius.large,
                    backgroundColor: lightSnow,
                    fontSize: fontSize.medium
                  }}
                />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    );
  }

  _onItemKeyboardFocus(event, isKeyboardFocused) {
    // Used only the first time, when selecting filtered businesses first item with [Tab] keyboard key
    const {focusedItemIndex} = this.state;
    if (isKeyboardFocused && focusedItemIndex === null) {
      this._updateFocusedItemIndex(0);
    }
  }

  // Some key handling may be simplified by using another component than <List>
  _onKeypress(event) {
    switch (keycode(event.keyCode)) {
      case 'tab': {
        // Handle [Tab]
        // Once in filtered businesses list, we prevent navigation for this key
        if (this._onHandleTab()) {
          event.preventDefault();
        }
        break;
      }

      case 'up': // Handle [Arrow up/down]
      case 'down': {
        event.preventDefault();

        const {filteredBusinesses} = this.props;
        const filteredBusinessesLength = filteredBusinesses.length;

        if (filteredBusinessesLength === 0) {
          break;
        }

        const businessIndexToFocus = this._getBusinessIndexToFocus(event, filteredBusinessesLength);

        this._updateFocusedItemIndex(businessIndexToFocus);

        break;
      }

      default: {
        break;
      }
    }

    return true;
  }

  _getBusinessIndexToFocus(event, filteredBusinessesLength) {
    const {focusedItemIndex} = this.state;

    if (focusedItemIndex === null) {
      return 0;
    }

    return event.keyCode === keycode('UP')
      ? this._isFocusedOnFirstItem(filteredBusinessesLength)
      : this._isFocusedOnLastItem(filteredBusinessesLength);
  }

  _isFocusedOnFirstItem(filteredBusinessesLength) {
    const {focusedItemIndex} = this.state;

    return focusedItemIndex === 0 ? filteredBusinessesLength - 1 : focusedItemIndex - 1;
  }

  _isFocusedOnLastItem(filteredBusinessesLength) {
    const {focusedItemIndex} = this.state;

    return focusedItemIndex === filteredBusinessesLength - 1 ? 0 : focusedItemIndex + 1;
  }

  _onHandleTab() {
    const {focusedItemIndex} = this.state;
    const {filteredBusinesses} = this.props;

    return focusedItemIndex !== null || !filteredBusinesses.length;
  }

  _updateFocusedItemIndex(index, next) {
    this.setState(
      {
        focusedItemIndex: index
      },
      next
    );
  }

  _showFullProgressMessage() {
    const {fullProgressMessage} = this.props;

    return (
      <div
        style={{
          display: 'flex',
          padding: '30px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LoadingIndicator size={30} color={lightSlate} style={{marginRight: '10px'}} />
        {fullProgressMessage}
      </div>
    );
  }

  _showSubtitle(subTitle) {
    return subTitle ? (
      <span style={{color: lightSlate, margin: '5px 15px', display: 'block'}}>{subTitle}</span>
    ) : null;
  }

  _onInputClick() {
    this._updateFocusedItemIndex(null);
  }

  _onInputChange(event) {
    const {doSearchBusinesses} = this.props;
    doSearchBusinesses(event.target.value);
  }

  _onClickBusiness(business, index) {
    const {onSelectBusiness} = this.props;
    this._updateFocusedItemIndex(index, () => onSelectBusiness(business));
  }

  _isNewLengthDifferentThanFilteredBusinessesLength(prevProps, newLength) {
    const {focusedItemIndex} = this.state;

    if (focusedItemIndex + 1 > newLength) {
      this._updateFocusedItemIndex(newLength - 1);
    } else {
      focusItem(this, focusedItemIndex);
    }
  }

  _handleFocusItemCases(prevProps, prevState) {
    const {focusedItemIndex} = this.state;
    const {filteredBusinesses} = this.props;

    if (!prevProps.showBusinessesList) {
      focusItem(this, focusedItemIndex, true); // let list appearance effect finish
    } else if (focusedItemIndex !== null && focusedItemIndex !== prevState.focusedItemIndex) {
      focusItem(this, focusedItemIndex);
    } else {
      const newLength = filteredBusinesses.length;
      if (newLength !== prevProps.filteredBusinesses.length) {
        this._isNewLengthDifferentThanFilteredBusinessesLength(prevProps, newLength);
      }
    }
  }

  render() {
    const {anchorEl, dataTestId, open, showFullProgress, subTitle, title, onRequestClose} =
      this.props;

    return (
      <EnhancedPopover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
        onRequestClose={onRequestClose}
        style={{
          width: '420px',
          maxWidth: '420px',
          border: `1px solid ${lightSmoke}`,
          borderRadius: radius.large,
          boxShadow: `2px 2px 5px ${lightSmoke}`,
          background: 'white'
        }}
      >
        <div data-test-id={dataTestId}>
          {showFullProgress ? (
            this._showFullProgressMessage()
          ) : (
            <div>
              <h3 style={{padding: '15px 15px 0 15px'}}>{title}</h3>
              {this._showSubtitle(subTitle)}
              {this._showBusinessContainer()}
            </div>
          )}
        </div>
      </EnhancedPopover>
    );
  }
}

BusinessSelectionPopupComponent.defaultProps = {
  showBusinessesList: true,
  showInput: true
};

BusinessSelectionPopupComponent.propTypes = {
  alternativeToBusinessesList: PropTypes.node,
  alternativeToContainer: PropTypes.node,
  anchorEl: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.arrayOf(PropTypes.any),
  dataTestId: PropTypes.string.isRequired,
  filteredBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  fullProgressMessage: PropTypes.string,
  initializing: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  organizationPicture: PropTypes.string,
  searchText: PropTypes.string.isRequired,
  showBusinessesList: PropTypes.bool,
  showFullProgress: PropTypes.bool,
  showInput: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  doSearchBusinesses: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSelectBusiness: PropTypes.func.isRequired
};

BusinessSelectionPopupComponent.contextTypes = context;

export default BusinessSelectionPopupComponent;
