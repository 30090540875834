import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import convertFromMs from '../lib/convert-from-ms';
import getStandardParamsFromState from '../lib/get-standard-params-from-state';
import prepareRequestParams from '../lib/prepare-request-params';
import {TIMEOUT_REQUEST_LONG} from '../../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../../data/settings';
import {
  INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../../actions/insight-actions';

const InsightContactCentersWebAHTEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(
        INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN,
        INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ),
      map(() => {
        return {
          type: INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD,
          ...getStandardParamsFromState(state$)
        };
      })
    );

    const loadAverageHandlingTime = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate
        }) => {
          return graphql(
            'get-average-handling-time-query',
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            }),
            {timeout: TIMEOUT_REQUEST_LONG}
          ).pipe(
            map(({averageHandlingTimeHistogram}) => {
              return {
                type: INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_SUCCESS,
                averageHandlingTimeHistogram: averageHandlingTimeHistogram.map((item) =>
                  convertFromMs(item, 'averageHandlingTimePerContact')
                )
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    return merge(loadAverageHandlingTime, loadInsights);
  };

export default InsightContactCentersWebAHTEpic;
