import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import context from '../../../../../shared/component/context';
import {BUTTON_LABEL_STYLE} from '../../../../../shared/style/theme-v0';
import {BUTTON_STYLE} from '../../../../../shared/component/button/standard-raised-button-component';
import ContentArea from '../content-area-component';
import ContentLayout from './content-layout-component';
import CountdownCircleTimer from '../../../../../shared/component/react-countdown-circle-timer/countdown-circle-timer-component';
import HeaderLayout from '../header/header-layout-component';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import TopLayout from '../../../../../shared/component/top-layout-component';
import {fontSize, fontWeight, lineHeight} from '../../../../../shared/style/variables';
import {lightSlate, slate} from '../../../../../shared/style/colors';

const LOGO_SIZE = '110px';
const bottomHeight = 70;

/**
 * Finally, the component.
 */
class RetryableHeadedLayoutComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    // We need 2 boolean because few ms after retrying finished, the countdown is still hidden but retry button is not visible.
    // Same when retry button is clicked and hidden but not yet retrying and countdown not visible.
    this.state = {
      showCountdown: false,
      showRetryButton: true
    };

    this._allowRetry = this._allowRetry.bind(this);
    this._onClickRetry = this._onClickRetry.bind(this);
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.isRetrying && !this.props.isRetrying) {
      this._showCountDown();
    }
  }

  _showCountDown = () => {
    this.setState({
      showCountdown: true
    });
  };

  _renderCountDown = () => {
    const {retryDelay, retryInText} = this.props;
    const {showCountdown} = this.state;

    return showCountdown ? (
      <span data-test-id="countdown-clock" style={{display: 'flex', alignItems: 'center'}}>
        <span style={{marginRight: '8px'}}>{retryInText}</span>
        <CountdownCircleTimer
          duration={retryDelay}
          fontSize={fontSize.xxxxlarge}
          onComplete={this._allowRetry}
          size={bottomHeight}
          strokeWidth={10}
        />
      </span>
    ) : null;
  };

  _renderRetryButton = () => {
    const {retryButtonLabel} = this.props;
    const {showRetryButton} = this.state;

    return showRetryButton ? (
      <RaisedButton
        data-test-id="retry-button"
        buttonStyle={BUTTON_STYLE}
        label={retryButtonLabel}
        labelStyle={BUTTON_LABEL_STYLE}
        primary
        onClick={this._onClickRetry}
        style={BUTTON_STYLE}
      />
    ) : null;
  };

  _renderLogout = () => {
    const {i18n} = this.context;
    const {doLogout} = this.props;

    return doLogout ? (
      <span style={{display: 'flex', alignItems: 'center'}}>
        <span style={{margin: '0 20px'}}>{i18n.t('retryableScreen.choiceSeparator')}</span>
        <RaisedButton
          data-test-id="logout-button"
          buttonStyle={BUTTON_STYLE}
          label={i18n.t('retryableScreen.takeMeHome')}
          labelStyle={BUTTON_LABEL_STYLE}
          onClick={doLogout}
          primary
          style={BUTTON_STYLE}
        />
      </span>
    ) : null;
  };

  _allowRetry() {
    this.setState({
      showCountdown: false,
      showRetryButton: true
    });
  }

  _onClickRetry() {
    const {onClickRetry} = this.props;
    this.setState(
      {
        showRetryButton: false
      },
      onClickRetry
    );
  }

  render() {
    const {dataTestId, isRetrying, text} = this.props;

    return (
      <TopLayout dataTestId={dataTestId}>
        <HeaderLayout />

        <ContentLayout>
          <ContentArea>
            <img
              alt="Instaply logo"
              src={`${ASSETS_PATH}/img/logo/instaply-small-square.jpg`}
              style={{
                width: LOGO_SIZE,
                height: LOGO_SIZE
              }}
            />

            <span
              dangerouslySetInnerHTML={{__html: text}}
              style={{
                marginBottom: '20px',
                textAlign: 'center',
                lineHeight: lineHeight.large,
                fontSize: fontSize.xxxlarge,
                fontWeight: 'bold',
                color: slate
              }}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: `${bottomHeight}px`,
                fontSize: fontSize.xxxlarge,
                fontWeight: fontWeight.semiBold,
                color: lightSlate
              }}
            >
              {isRetrying ? (
                <LoadingIndicator />
              ) : (
                <React.Fragment>
                  {this._renderCountDown()}

                  {this._renderRetryButton()}

                  {this._renderLogout()}
                </React.Fragment>
              )}
            </div>
          </ContentArea>
        </ContentLayout>
      </TopLayout>
    );
  }
}

RetryableHeadedLayoutComponent.contextTypes = context;

RetryableHeadedLayoutComponent.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  doLogout: PropTypes.func,
  isRetrying: PropTypes.bool.isRequired,
  onClickRetry: PropTypes.func.isRequired,
  retryButtonLabel: PropTypes.string.isRequired,
  retryDelay: PropTypes.number.isRequired,
  retryInText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default RetryableHeadedLayoutComponent;
