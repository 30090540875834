import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import TrendingFlatIcon from 'material-ui/svg-icons/action/trending-flat';
import {lightSlate} from '../../../../../shared/style/colors';

class RedirectIcon extends PureComponent {
  render() {
    const {style} = this.props;

    return (
      <div
        style={{
          margin: '0 7px 0',
          marginBottom: 0,
          width: '22px',
          height: '22px',
          ...style
        }}
      >
        <TrendingFlatIcon
          style={{
            display: 'inline-block',
            margin: '4px',
            height: '12px',
            width: '12px',
            WebkitUserSelect: 'none',
            fill: 'currentcolor',
            color: lightSlate
          }}
        />
      </div>
    );
  }
}

RedirectIcon.propTypes = {
  style: PropTypes.objectOf(PropTypes.any)
};

export default RedirectIcon;
