import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import context from '../../../../../shared/component/context';
import CountdownCircleTimer from '../../../../../shared/component/react-countdown-circle-timer/countdown-circle-timer-component';
import HeaderBanner from '../../common/header/header-banner-component';
import {fontSize, fontWeight, lineHeight} from '../../../../../shared/style/variables';
import {lightRed} from '../../../../../shared/style/colors';
import {NEW_RELEASE_BANNER_FORCE_REFRESH_TIMEOUT} from '../../../data/settings';

class NewReleaseBannerComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {doApplicationReload} = this.props;

    return (
      <HeaderBanner
        dataTestId="new-release-banner"
        style={{
          padding: '0 8px',
          height: '52px'
        }}
      >
        <div
          style={{
            marginRight: '5px',
            lineHeight: lineHeight.medium,
            textAlign: 'right'
          }}
        >
          <span style={{marginRight: '3px', fontWeight: fontWeight.semiBold}}>
            {i18n.t('global.newRelease.title')}
          </span>
          {i18n.t('global.newRelease.description')}
        </div>

        <CountdownCircleTimer
          color={lightRed}
          duration={NEW_RELEASE_BANNER_FORCE_REFRESH_TIMEOUT}
          fontSize={fontSize.small}
          onComplete={doApplicationReload}
          size={44}
          strokeWidth={5}
        />

        <RaisedButton
          data-test-id="reload-button"
          label={i18n.t('common.doUpdate')}
          labelStyle={{whiteSpace: 'nowrap'}}
          primary
          style={{marginLeft: '10px'}}
          onClick={doApplicationReload}
        />
      </HeaderBanner>
    );
  }
}

NewReleaseBannerComponent.contextTypes = context;

NewReleaseBannerComponent.propTypes = {
  doApplicationReload: PropTypes.func.isRequired
};

export default NewReleaseBannerComponent;
