import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {blue, darkSnow, slate, snow} from './colors';

export const BUTTON_LABEL_STYLE = {
  textTransform: 'none'
};

export const FONT_FAMILY = 'Open Sans, sans-serif';

export default getMuiTheme({
  contentFontFamily: FONT_FAMILY,
  fontFamily: FONT_FAMILY,
  palette: {
    accent1Color: blue,
    hoverColor: snow,
    primary1Color: blue,
    selectedColor: darkSnow,
    textColor: slate
  }
});
