import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import verify from './reducer/verify-reducer';
import verifyEpic from './epic/verify-epic';

export default () => {
  const mea = middleEndAccessors();

  const rootEpic = createRootEpic(verifyEpic(mea));

  const rootReducer = createRootReducer({
    verify
  });

  return {
    rootEpic,
    rootReducer
  };
};
