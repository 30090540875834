import PropTypes from 'prop-types';

const FilePreviewRemoveIcon = ({style, ...otherProps}) => (
  <img
    alt=""
    src={`${ASSETS_PATH}/img/thread/close.png`}
    style={{
      cursor: 'pointer',
      position: 'absolute',
      top: '30px',
      right: '23px',
      ...style
    }}
    {...otherProps}
  />
);

FilePreviewRemoveIcon.propTypes = {
  'data-test-id': PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired
};

export default FilePreviewRemoveIcon;
