const IMAGE_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];

const THREAD_CHANNELS_SETTINGS = {
  EMAIL: {
    code: 'EMAIL'
  },
  FACEBOOK: {
    code: 'FACEBOOK'
  },
  GBM: {
    code: 'GBM',
    extensionsSupported: IMAGE_FILE_EXTENSIONS
  },
  SMS: {
    code: 'SMS'
  },
  WHATSAPP: {
    code: 'WHATSAPP',
    extensionsSupported: [
      ...IMAGE_FILE_EXTENSIONS,
      '.amr',
      '.mp3',
      '.ogg', // audio
      '.mp4', // video
      '.pdf' // document
    ],
    maxFileSize: 1048576 * 16 // 16 Mo
  }
};

/**
 * Convenient function to retrieve safely a customer channel setting.
 * @param {String} customerChannel
 * @param {String} setting
 * @returns {*} the eventual setting
 */
const getChannelSetting = (customerChannel, setting) => {
  const channelSettings = THREAD_CHANNELS_SETTINGS[customerChannel];

  return channelSettings ? channelSettings[setting] : null;
};

export {THREAD_CHANNELS_SETTINGS};

export default getChannelSetting;
