import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import getNewClientMessageId from '../../../../lib/message-helper';
import {
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_TEXT_SEND,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK
} from '../../../../actions/customer-thread-visible-actions';

const LegacyCustomerThreadVisibleWhatsAppEpic =
  ({graphql}) =>
  (action$, state$) => {
    const legacyClickButtonLoadWhatsappTemplates = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK),
      map(({businessId}) => ({
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD,
        businessId
      }))
    );

    const legacyLoadWhatsAppTemplates = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD),
      mergeMap(({businessId}) => {
        const params = {
          businessId
        };

        return graphql('get-business-whatsapp-templates-query', params).pipe(
          map(({businessWhatsappTemplates}) => {
            const {templates} = businessWhatsappTemplates;

            return {
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS,
              data: templates
            };
          }),
          catchError((error) =>
            of({
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE,
              error
            })
          )
        );
      })
    );

    const legacyClickTemplate = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK),
      map(({text}) => ({
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_TEXT_SEND,
        participationId: state$.value.getIn(['legacyCustomerThread', 'participationId']),
        text
      }))
    );

    const legacySendTemplateMessageToCustomer = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_TEXT_SEND),
      map(({text}) => ({
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
        clientMessageId: getNewClientMessageId(),
        fullName: state$.value.getIn(['account', 'fullName']),
        organizationPicture: state$.value.getIn(['account', 'organizationPicture']),
        picture: state$.value.getIn(['account', 'picture']),
        status: state$.value.getIn(['legacyCustomerThread', 'status']),
        userId: state$.value.getIn(['account', 'id']),
        text
      }))
    );

    return merge(
      legacyClickButtonLoadWhatsappTemplates,
      legacyClickTemplate,
      legacyLoadWhatsAppTemplates,
      legacySendTemplateMessageToCustomer
    );
  };

export default LegacyCustomerThreadVisibleWhatsAppEpic;
