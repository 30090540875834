import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {getSendMessageActionFromMessageForm} from './lib/conversation-item-helper';
import {
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK
} from '../../../../actions/customer-thread-visible-actions';

const CustomerThreadVisibleWhatsAppEpic =
  ({graphql}) =>
  (action$, state$) => {
    const clickButtonLoadWhatsappTemplates = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK),
      map(({businessId}) => ({
        type: CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD,
        businessId
      }))
    );

    const loadWhatsAppTemplates = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD),
      mergeMap(({businessId}) => {
        const params = {
          businessId
        };

        return graphql('get-business-whatsapp-templates-query', params).pipe(
          map(({businessWhatsappTemplates}) => {
            const {templates} = businessWhatsappTemplates;

            return {
              type: CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS,
              data: templates
            };
          }),
          catchError((error) =>
            of({
              type: CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_FAILURE,
              error
            })
          )
        );
      })
    );

    const sendMessageToCustomerFromTemplateClicked = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK),
      map(({payload: {text}}) =>
        getSendMessageActionFromMessageForm(state$, CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE, {text})
      )
    );

    return merge(
      clickButtonLoadWhatsappTemplates,
      loadWhatsAppTemplates,
      sendMessageToCustomerFromTemplateClicked
    );
  };

export default CustomerThreadVisibleWhatsAppEpic;
