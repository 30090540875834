import PropTypes from 'prop-types';
import {badge, fontSize} from '../../../../shared/style/variables';

const BadgeLayoutComponent = ({children, style, ...otherProps}) => (
  <div
    {...otherProps}
    style={{
      display: 'inline-block',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: 'auto',
      color: 'white',
      height: badge.medium,
      minWidth: badge.medium,
      padding: '0 5px',
      fontSize: fontSize.xsmall,
      fontWeight: '600',
      textAlign: 'center',
      WebkitBorderRadius: '12px',
      borderRadius: '12px',
      boxSizing: 'border-box',
      ...style
    }}
  >
    {children}
  </div>
);

BadgeLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default BadgeLayoutComponent;
