import {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import LoadingIndicator from '../../../../../../../../shared/component/loading-indicator-component';
import AdministrationBusinessPageFacebookPagesList from '../channel/facebook/administration-business-page-facebook-pages-list-component';
import StandardButton from '../../../../../../../../shared/component/button/standard-button-component';
import {
  black,
  blue,
  lightGrey,
  lightSmoke,
  white
} from '../../../../../../../../shared/style/colors';

const StyledDialog = withStyles({
  paper: {
    borderRadius: '4px',
    padding: '6em 6em 3em 6em'
  }
})(Dialog);

const AdministrationBusinessPageChannelsFacebookPagesDialog = ({
  closeFacebookPagesDialog,
  facebookPages,
  i18n,
  isOpenFacebookPagesDialog,
  connectFacebookPage
}) => {
  const [checkedFacebookPage, setCheckedFacebookPage] = useState({});
  const [facebookPageIdSelected, setFacebookPageIdSelected] = useState(null);

  const handleFacebookPageChange = (event, indexPageElement, facebookPageId) => {
    if (event.target.checked) {
      setCheckedFacebookPage({
        ...checkedFacebookPage,
        [indexPageElement]: !checkedFacebookPage[indexPageElement]
      });
      setFacebookPageIdSelected(facebookPageId);
    } else {
      setCheckedFacebookPage({});
      setFacebookPageIdSelected(null);
    }
  };

  const handleConnectFacebookPage = () => {
    connectFacebookPage(facebookPageIdSelected);
  };

  const renderFacebookPagesDialogTitle = () => (
    <DialogTitle id="customized-dialog-title" style={{paddingBottom: 0}}>
      {i18n.t('administration.business.pages.channels.facebook.facebookPages.headerTitle')}
      <IconButton
        aria-label="close"
        onClick={closeFacebookPagesDialog}
        style={{
          color: lightSmoke,
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem'
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );

  const renderFacebookPagesDialogContent = () => (
    <DialogContent style={{padding: '1em 1em 1em 2em'}}>
      {facebookPages.loading ? (
        <LoadingIndicator />
      ) : (
        <AdministrationBusinessPageFacebookPagesList
          facebookPagesList={facebookPages.data}
          {...{checkedFacebookPage, handleFacebookPageChange}}
        />
      )}
    </DialogContent>
  );

  const renderFacebookPagesDialogButtons = () => (
    <DialogActions style={{justifyContent: 'space-between', paddingLeft: '1.5em'}}>
      <StandardButton
        data-test-id="facebook-pages-cancel-button"
        onClick={closeFacebookPagesDialog}
        style={{
          backgroundColor: lightGrey,
          borderRadius: '6px',
          color: black,
          fontWeight: 600
        }}
      >
        {i18n.t('administration.business.pages.channels.facebook.facebookPages.cancelButton')}
      </StandardButton>

      <StandardButton
        data-test-id="facebook-pages-connect-button"
        onClick={handleConnectFacebookPage}
        style={{
          backgroundColor: blue,
          borderRadius: '6px',
          color: white,
          fontWeight: 600
        }}
      >
        {i18n.t('administration.business.pages.channels.facebook.facebookPages.connectButton')}
      </StandardButton>
    </DialogActions>
  );

  return (
    <StyledDialog
      maxWidth="md"
      fullWidth
      open={isOpenFacebookPagesDialog}
      style={{padding: '3em'}}
      data-test-id="facebook-pages-list-dialog"
    >
      {renderFacebookPagesDialogTitle()}
      {renderFacebookPagesDialogContent()}
      {renderFacebookPagesDialogButtons()}
    </StyledDialog>
  );
};

AdministrationBusinessPageChannelsFacebookPagesDialog.propTypes = {
  closeFacebookPagesDialog: PropTypes.func.isRequired,
  connectFacebookPage: PropTypes.func.isRequired,
  facebookLogin: PropTypes.objectOf(PropTypes.any),
  facebookPages: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpenFacebookPagesDialog: PropTypes.bool.isRequired
};

export default AdministrationBusinessPageChannelsFacebookPagesDialog;
