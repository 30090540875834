import PropTypes from 'prop-types';
import AdministrationBusinessPageChannelsConnectLayout from './administration-business-page-channels-connect-layout-component';
import SwitchButton from '../../../../../../../../shared/component/button/switch-button-component';
import {davysGrey} from '../../../../../../../../shared/style/colors';

const AdministrationBusinessPageChannelsConnectComponent = ({
  channelConnected,
  icon,
  isSwitchButtonChecked,
  labelButton,
  onHandleChange,
  switchButtonColor,
  switchButtonDataTestId,
  switchButtonLabelDataTestId,
  title,
  wrapperStyle,
  ...otherProps
}) => {
  return (
    <AdministrationBusinessPageChannelsConnectLayout
      {...{
        icon,
        title,
        wrapperStyle
      }}
      {...otherProps}
    >
      <span
        className="label"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '11px',
          lineHeight: '26px',
          margin: '10px 0 0 36px'
        }}
      >
        <span
          data-test-id={switchButtonLabelDataTestId}
          data-channel-status-connection-button={channelConnected}
          style={{color: davysGrey, fontSize: '13px', marginRight: '7px'}}
        >
          {labelButton}
        </span>
        <SwitchButton
          dataTestId={switchButtonDataTestId}
          onHandleChange={onHandleChange}
          isSwitchButtonChecked={isSwitchButtonChecked}
          switchcolor={switchButtonColor}
        />
      </span>
    </AdministrationBusinessPageChannelsConnectLayout>
  );
};

AdministrationBusinessPageChannelsConnectComponent.propTypes = {
  channelConnected: PropTypes.bool,
  'data-test-id': PropTypes.string,
  icon: PropTypes.string.isRequired,
  isSwitchButtonChecked: PropTypes.bool.isRequired,
  labelButton: PropTypes.string.isRequired,
  switchButtonColor: PropTypes.string.isRequired,
  switchButtonDataTestId: PropTypes.string.isRequired,
  switchButtonLabelDataTestId: PropTypes.string,
  title: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.objectOf(PropTypes.any),
  onHandleChange: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsConnectComponent;
