import {merge, of} from 'rxjs';
import {catchError, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {push} from 'react-router-redux';
import cloneDeep from 'lodash/cloneDeep';
import {findConversationIndexByConversationId} from '../../../../reducer/content/customer/thread/lib/customer-thread-conversations-helper';
import {
  graphqlGetConversationsAndCustomerProfileQuery,
  graphqlGetConversationsQuery
} from './graphql-queries/graphql-conversation-query-helper';
import {queryfyObject} from '../../../../../../shared/lib/route-helper';
import {
  CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL,
  CUSTOMER_CONVERSATION_URL_GO,
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE,
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_THREAD_CONVERSATION_TAB_CLICK,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_SUCCESS,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_FAILURE,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_SUCCESS,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_START,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_SUCCESS,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_FAILURE,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD,
  LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {
  ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION,
  ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION,
  ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER
} from '../../../../actions/router-actions';
import {CUSTOMER_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-thread-visible-actions';
import {
  CUSTOMER_NEW_CONVERSATION_URL_GO,
  CUSTOMER_NEW_CONVERSATION_START,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_FAILURE,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION,
  CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_START,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_GO
} from '../../../../actions/customer-new-thread-actions';
import {
  filterByCustomerConversationUrlFollowsFormat,
  filterByCustomerNewConversationUrlFollowsFormat,
  generateCustomerConversationUrl
} from '../../../../lib/route-helper';
import {
  addNewConversationToConversationsList,
  findExistingConversationsByBusinessId,
  getConversationByBusinessId,
  getCurrentConversationFromState,
  getExistingConversation,
  getExistingConversations,
  isNotBusinessInConversation,
  isNotSameBusinessInTheNewConversation,
  isThereNewConversation
} from './lib/customer-thread-conversations-helper';
import {CONVERSATION_MESSAGES_LOAD_DIRECTIONS} from '../../../../data/thread/message';
import {CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY} from '../../../../data/settings';
import {
  isAvailableFeatureToggle,
  isNotAvailableFeatureToggle
} from '../../../../lib/feature-toggle-helper';

const CustomerThreadConversationsEpic =
  ({graphql}) =>
  (action$, state$) => {
    const goToCustomerConversationUrl = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_URL_GO),
      map(({payload: {conversation, customer, queryStringParams}}) =>
        push({
          pathname: generateCustomerConversationUrl(customer.id, conversation.id),
          search: queryfyObject(queryStringParams)
        })
      )
    );

    const goToCustomerNewConversationUrl = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_URL_GO),
      map(({payload: {customer, queryStringParams}}) =>
        push({
          pathname: generateCustomerConversationUrl(customer.id, 'new'),
          search: queryfyObject(queryStringParams)
        })
      )
    );

    const goToCustomerNewConversationNewCustomerUrl = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_GO),
      map(({payload: {queryStringParams}}) =>
        push({
          pathname: generateCustomerConversationUrl('new', 'new'),
          search: queryfyObject(queryStringParams)
        })
      )
    );

    const goToCustomerConversationUrlOnConversationTabClick = action$.pipe(
      ofType(CUSTOMER_THREAD_CONVERSATION_TAB_CLICK),
      filter(filterByCustomerConversationUrlFollowsFormat(state$)),
      map(({payload: {orderTab}}) => {
        const conversationId = state$.value.getIn([
          'customer',
          'thread',
          'conversations',
          'items',
          orderTab,
          'id'
        ]);
        const customerId = state$.value.getIn(['customer', 'thread', 'profile', 'customerId']);

        return {
          type: CUSTOMER_CONVERSATION_URL_GO,
          payload: {
            conversation: {
              id: conversationId
            },
            customer: {
              id: customerId
            }
          }
        };
      })
    );

    const legacyLoadConversationsAndProfile = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD),
      mergeMap(({payload: {customerId, participationId}}) =>
        graphql(graphqlGetConversationsAndCustomerProfileQuery(customerId)).pipe(
          map(({conversations, customer}) => {
            return {
              type: LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
              payload: {
                conversations,
                customer,
                orderTab: 0,
                participationId
              }
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE,
              error
            });
          })
        )
      )
    );

    const loadConversationsAndProfile = action$.pipe(
      ofType(CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD),
      mergeMap(
        ({payload: {conversationId, customerId, internalMessageCursor, visibleMessageCursor}}) => {
          return graphql(graphqlGetConversationsAndCustomerProfileQuery(customerId)).pipe(
            map(({conversations, customer}) => {
              const conversationToSelect = conversations.find(
                (conversation) => conversation.id === conversationId
              );

              return {
                type: CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
                payload: {
                  conversations,
                  customer,
                  internalMessageCursor,
                  orderTab: conversationToSelect.order,
                  visibleMessageCursor
                }
              };
            }),
            catchError((error) => {
              return of({
                type: CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    const triggerLoadConversationVisibleOnCustomerConversationsReady = action$.pipe(
      ofType(CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS),
      map(({payload: {customer, internalMessageCursor, visibleMessageCursor}}) => {
        const loadAroundVisibleMessageProps = visibleMessageCursor
          ? {
              direction: CONVERSATION_MESSAGES_LOAD_DIRECTIONS.AROUND,
              messageCursor: visibleMessageCursor
            }
          : null;

        return {
          type: CUSTOMER_THREAD_VISIBLE_LOAD,
          customerId: customer.id,
          internalMessageCursor,
          ...loadAroundVisibleMessageProps
        };
      })
    );

    const triggerLoadLegacyConversationsAndProfileOnCompatibilityQuerySuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_SUCCESS),
      map(({payload: {customer}}) => {
        return {
          type: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD,
          payload: {customer}
        };
      })
    );

    const triggerNextStepOnLocationChangedToCustomerConversation = action$.pipe(
      ofType(ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION),
      map(({payload: {conversationIdUrlParam, customerIdUrlParam, imc, vmc}}) => {
        const currentCustomerId = state$.value.getIn([
          'customer',
          'thread',
          'profile',
          'customerId'
        ]);

        const isSameCustomer = currentCustomerId === customerIdUrlParam;
        const conversationsExist =
          state$.value.getIn(['customer', 'thread', 'conversations', 'items']).size > 0;

        if (isSameCustomer && conversationsExist) {
          return {
            type: CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL,
            payload: {
              // This prop should be cleaned later, as in theory should not be needed to load a visible thread
              customer: {
                id: currentCustomerId
              },
              internalMessageCursor: imc,
              orderTab: findConversationIndexByConversationId(
                state$.value.getIn(['customer', 'thread', 'conversations']),
                conversationIdUrlParam
              ),
              visibleMessageCursor: vmc
            }
          };
        }

        return {
          type: CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD,
          payload: {
            customerId: customerIdUrlParam,
            conversationId: conversationIdUrlParam,
            internalMessageCursor: imc,
            isSameCustomer,
            visibleMessageCursor: vmc
          }
        };
      })
    );

    const triggerLoadCompatibilityQueryOnCustomerConversationUrlGeneration = action$.pipe(
      ofType(LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_START),
      map(({payload: {customer, participationId}}) => {
        return {
          type: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD,
          payload: {
            customer,
            participationId
          }
        };
      })
    );

    const loadConversationsOnUrlGenerationCompatibilityLoadSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_SUCCESS),
      map(({payload: {customer}}) => {
        return {
          type: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD,
          payload: {customer}
        };
      })
    );

    const loadConversations = (actionFlowType) => {
      const actionsForConversationsQuery = {
        generation: {
          failure: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_FAILURE,
          load: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD,
          success: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_SUCCESS
        },
        redirection: {
          failure: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_FAILURE,
          load: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD,
          success: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_SUCCESS
        }
      }[actionFlowType];

      return action$.pipe(
        ofType(actionsForConversationsQuery.load),
        mergeMap(({payload: {customer}}) => {
          return graphql(graphqlGetConversationsQuery(customer.id)).pipe(
            map(({conversations}) => {
              const [firstConversation] = conversations;

              return {
                type: actionsForConversationsQuery.success,
                payload: {
                  customer,
                  conversation: {
                    id: firstConversation.id
                  }
                }
              };
            }),
            catchError((error) => {
              return of({
                type: actionsForConversationsQuery.failure,
                error
              });
            })
          );
        })
      );
    };

    const loadConversationsOnNewOutbound = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD),
      mergeMap(({payload: {business, customer}}) => {
        const propsToQuery = {
          business: {
            id: true
          }
        };

        return graphql(graphqlGetConversationsQuery(customer.id, propsToQuery)).pipe(
          map(({conversations}) => {
            const conversationByBusinesss = getConversationByBusinessId(conversations, business);

            if (conversationByBusinesss) {
              return {
                type: CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_SUCCESS,
                payload: {
                  customer,
                  conversation: {
                    id: conversationByBusinesss.id
                  }
                }
              };
            }

            return {
              type: CUSTOMER_NEW_CONVERSATION_START,
              payload: {
                business,
                customer
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const startCustomerConversation = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_START),
      map(({payload: {business, customer}}) => {
        return {
          type: CUSTOMER_NEW_CONVERSATION_URL_GO,
          payload: {
            customer,
            queryStringParams: {
              bid: business.id
            }
          }
        };
      })
    );

    const triggerNewConversationNewCustomerUrlGo = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START),
      map(({payload: {businessId, email, phoneNumber}}) => {
        return {
          type: CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_GO,
          payload: {
            queryStringParams: {
              bid: businessId,
              email,
              phoneNumber
            }
          }
        };
      })
    );

    const triggerNextStepOnLocationChangedToCustomerNewConversation = action$.pipe(
      ofType(ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION),
      map(({payload: {bid, customerIdUrlParam}}) => {
        const businessId = bid;

        return {
          type: CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD,
          payload: {
            businessId,
            customerId: customerIdUrlParam
          }
        };
      })
    );

    const loadNewOutboundConversationsAndProfile = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD),
      filter(isAvailableFeatureToggle(state$)),
      mergeMap(({payload: {businessId, customerId}}) => {
        return graphql(graphqlGetConversationsAndCustomerProfileQuery(customerId)).pipe(
          map(({conversations, customer}) => {
            const conversationsCopy = cloneDeep(conversations);

            return {
              type: CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
              payload: {
                businessId,
                conversations: isNotBusinessInConversation(conversationsCopy, businessId)
                  ? addNewConversationToConversationsList({
                      businessId,
                      conversations: conversationsCopy,
                      state$
                    })
                  : conversationsCopy,
                customer
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const retryLoadConversationsAndProfileOnNewConversation = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD),
      filter(isNotAvailableFeatureToggle(state$)),
      delay(CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY)
    );

    const triggerRemoveItemOnNewConversationTabClick = action$.pipe(
      ofType(CUSTOMER_THREAD_CONVERSATION_TAB_CLICK),
      filter(filterByCustomerNewConversationUrlFollowsFormat(state$)),
      filter(isNotSameBusinessInTheNewConversation(state$)),
      map(() => {
        const currentConversation = getCurrentConversationFromState(state$);
        const newOrderTab = currentConversation.get('order');

        return {
          type: CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS,
          payload: {
            orderTab: newOrderTab,
            conversations: getExistingConversations(state$)
          }
        };
      })
    );

    const redrirectToCustomerConversationUrl = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS),
      map(({payload: {orderTab}}) => {
        const conversationId = state$.value.getIn([
          'customer',
          'thread',
          'conversations',
          'items',
          orderTab,
          'id'
        ]);
        const customerId = state$.value.getIn(['customer', 'thread', 'profile', 'customerId']);

        return {
          type: CUSTOMER_CONVERSATION_URL_GO,
          payload: {
            conversation: {
              id: conversationId
            },
            customer: {
              id: customerId
            }
          }
        };
      })
    );

    const triggerNextStepOnNewOutboundConversationsAndProfileSuccess = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS),
      map(({payload: {businessId, customer}}) => {
        const conversation = getExistingConversation(state$, businessId);

        if (conversation) {
          return {
            type: CUSTOMER_CONVERSATION_URL_GO,
            payload: {
              conversation: {
                id: conversation.get('id')
              },
              customer
            }
          };
        }

        return {
          type: CUSTOMER_NEW_CONVERSATION_VISIBLE_START,
          payload: {
            businessId,
            customerId: customer.id
          }
        };
      })
    );

    const triggerNextActionOnSelectConversationTabFromUrl = action$.pipe(
      ofType(CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL),
      map(({payload: {customer, internalMessageCursor, visibleMessageCursor}}) => {
        if (isThereNewConversation(state$)) {
          return {
            type: CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS,
            payload: {
              orderTab: 0,
              conversations: getExistingConversations(state$)
            }
          };
        }

        const loadAroundVisibleMessageProps = visibleMessageCursor
          ? {
              direction: CONVERSATION_MESSAGES_LOAD_DIRECTIONS.AROUND,
              messageCursor: visibleMessageCursor
            }
          : null;

        return {
          type: CUSTOMER_THREAD_VISIBLE_LOAD,
          customerId: customer.id,
          internalMessageCursor,
          ...loadAroundVisibleMessageProps
        };
      })
    );

    const loadConversationsOnUrlGeneration = loadConversations('generation');

    const loadConversationsOnRedirection = loadConversations('redirection');

    const triggerGoToConversationUrlOnSomeActions = action$.pipe(
      ofType(
        CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_SUCCESS,
        CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS,
        LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_SUCCESS,
        LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_SUCCESS
      ),
      map(({payload: {conversation, customer}}) => {
        return {
          type: CUSTOMER_CONVERSATION_URL_GO,
          payload: {
            conversation,
            customer
          }
        };
      })
    );

    const addNewConversationNewCustomer = action$.pipe(
      ofType(ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER),
      filter(isAvailableFeatureToggle(state$)),
      map(({payload: {bid, email, phoneNumber}}) => {
        const businessId = bid;
        const customerId = state$.value.getIn(['customer', 'thread', 'profile', 'customerId']);

        let payload = {
          businessId,
          conversations: addNewConversationToConversationsList({state$, businessId})
        };

        if (phoneNumber) {
          payload = {...payload, phoneNumber};
        }

        if (email) {
          payload = {...payload, email};
        }

        const existingConversation = findExistingConversationsByBusinessId(state$, businessId);
        if (existingConversation && customerId && customerId !== 'new') {
          const conversationId = existingConversation.get('id');

          return {
            type: CUSTOMER_CONVERSATION_URL_GO,
            payload: {
              conversation: {
                id: conversationId
              },
              customer: {
                id: customerId
              }
            }
          };
        }

        return {
          type: CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION,
          payload
        };
      })
    );

    const retryNewConversionNewCustomerAdd = action$.pipe(
      ofType(ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER),
      filter(isNotAvailableFeatureToggle(state$)),
      delay(CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY)
    );

    return merge(
      addNewConversationNewCustomer,
      goToCustomerConversationUrl,
      goToCustomerNewConversationUrl,
      goToCustomerNewConversationNewCustomerUrl,
      goToCustomerConversationUrlOnConversationTabClick,
      legacyLoadConversationsAndProfile,
      loadConversationsAndProfile,
      loadConversationsOnNewOutbound,
      loadConversationsOnRedirection,
      loadConversationsOnUrlGeneration,
      loadConversationsOnUrlGenerationCompatibilityLoadSuccess,
      loadNewOutboundConversationsAndProfile,
      redrirectToCustomerConversationUrl,
      retryLoadConversationsAndProfileOnNewConversation,
      retryNewConversionNewCustomerAdd,
      startCustomerConversation,
      triggerNextStepOnNewOutboundConversationsAndProfileSuccess,
      triggerGoToConversationUrlOnSomeActions,
      triggerLoadCompatibilityQueryOnCustomerConversationUrlGeneration,
      triggerLoadConversationVisibleOnCustomerConversationsReady,
      triggerLoadLegacyConversationsAndProfileOnCompatibilityQuerySuccess,
      triggerNextActionOnSelectConversationTabFromUrl,
      triggerNextStepOnLocationChangedToCustomerConversation,
      triggerNextStepOnLocationChangedToCustomerNewConversation,
      triggerRemoveItemOnNewConversationTabClick,
      triggerNewConversationNewCustomerUrlGo
    );
  };

export default CustomerThreadConversationsEpic;
