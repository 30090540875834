const CONVERSATION_ITEM_TYPES = {
  ENGAGED: 'ENGAGED',
  INBOUND: 'INBOUND',
  INTERNAL: 'INTERNAL',
  OUTBOUND: 'OUTBOUND',
  RELEASED: 'RELEASED',
  STATUS_UPDATE: 'STATUS_UPDATE'
};

const CONVERSATION_MENTION_TARGET_TYPES = {
  USER: 'USER'
};

const CONVERSATION_MESSAGE_FLOW_STATUS = {
  ERROR: 'error',
  LOADED: 'loaded',
  SENDING: 'sending',
  SENT: 'sent'
};

const CONVERSATION_MESSAGES_LOAD_DIRECTIONS = {
  AFTER: 'after',
  AROUND: 'around',
  BEFORE: 'before'
};

const CONVERSATION_META_ITEM_RENDERERS = {
  DATE: 'META_DATE',
  NEW_CONVERSATION: 'META_NEW_CONVERSATION'
};

export {
  CONVERSATION_ITEM_TYPES,
  CONVERSATION_MENTION_TARGET_TYPES,
  CONVERSATION_MESSAGE_FLOW_STATUS,
  CONVERSATION_MESSAGES_LOAD_DIRECTIONS,
  CONVERSATION_META_ITEM_RENDERERS
};
