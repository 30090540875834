import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mapTo, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import convertFromMs from '../lib/convert-from-ms';
import getStandardParamsFromState from '../lib/get-standard-params-from-state';
import prepareRequestParams from '../lib/prepare-request-params';
import {DEBOUNCE_TIME_STATISTIC} from '../../../../data/settings';
import {
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_OPEN,
  INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../../actions/insight-actions';

const getAverageEmployeeLoggedTimeAction = (state$, override = {}) => {
  const state = state$.value.getIn(['insight', 'contactCentersUsers', 'averageEmployeeLoggedTime']);

  return {
    type: INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD,
    ...getStandardParamsFromState(state$),
    keyword: state.get('keyword'),
    page: state.get('page'),
    rowsPerPage: state.get('rowsPerPage'),
    ...override
  };
};

/**
 * Finally, the epic.
 */
const InsightContactCentersUsersEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_OPEN),
      mapTo({
        type: INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS
      })
    );

    const loadInsightsSuccess = action$.pipe(
      ofType(
        INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS,
        INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ),
      mergeMap(() => {
        return [
          {
            type: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD,
            ...getStandardParamsFromState(state$)
          },
          {
            type: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD,
            ...getStandardParamsFromState(state$)
          },
          {
            type: INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD,
            ...getStandardParamsFromState(state$)
          },
          getAverageEmployeeLoggedTimeAction(state$)
        ];
      })
    );

    const loadActivityPerBusiness = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(({customPeriodEndDate, customPeriodStartDate}) => {
        return graphql(
          'get-activity-per-business-query',
          prepareRequestParams(state$, {
            customPeriodEndDate,
            customPeriodStartDate
          })
        ).pipe(
          map(({activityPerBusiness}) => {
            return {
              type: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_SUCCESS,
              activityPerBusiness
            };
          }),
          catchError((error) => {
            return of({
              type: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadActivityPerEmployee = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(({customPeriodEndDate, customPeriodStartDate}) => {
        return graphql(
          'get-activity-per-employee-query',
          prepareRequestParams(state$, {
            customPeriodEndDate,
            customPeriodStartDate
          })
        ).pipe(
          map(({activityPerEmployee}) => {
            return {
              type: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_SUCCESS,
              activityPerEmployee
            };
          }),
          catchError((error) => {
            return of({
              type: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadAverageLoggedTime = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate
        }) => {
          return graphql(
            'get-average-logged-time-query',
            prepareRequestParams(state$, {
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            })
          ).pipe(
            map(({averageLoggedTimeHistogram, averageLoggedTimeIndicators}) => {
              return {
                type: INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_SUCCESS,
                averageLoggedTimeHistogram: averageLoggedTimeHistogram.map((item) =>
                  convertFromMs(item, null, 60)
                ),
                averageLoggedTimeIndicators
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    const changeAverageEmployeeLoggedTimeBusiness = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE), // @todo This action type should be renamed 'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_BUSINESSES_SELECTION_CHANGE'
      map(({businessesSelection}) =>
        getAverageEmployeeLoggedTimeAction(state$, {businessesSelection, page: 0})
      )
    );

    const changeAverageEmployeeLoggedTimeKeyword = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE),
      map(({keyword}) => getAverageEmployeeLoggedTimeAction(state$, {keyword, page: 0}))
    );

    const changeAverageEmployeeLoggedTimeResultPage = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE),
      map(({resultPage}) =>
        getAverageEmployeeLoggedTimeAction(state$, {resultPage, page: resultPage})
      ) // We need both here
    );

    const changeAverageEmployeeLoggedTimeRowsPerPage = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE),
      map(({rowsPerPage}) => getAverageEmployeeLoggedTimeAction(state$, {rowsPerPage, page: 0}))
    );

    const loadAverageEmployeeLoggedTime = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          customPeriodEndDate,
          customPeriodStartDate,
          keyword,
          page,
          rowsPerPage
        }) => {
          const after = page ? (page * rowsPerPage - 1).toString() : undefined;

          return graphql('get-average-employee-logged-time-query', {
            ...prepareRequestParams(state$, {
              businessesSelection,
              customPeriodEndDate,
              customPeriodStartDate
            }),
            employee: keyword,
            slice: {
              first: rowsPerPage,
              after
            }
          }).pipe(
            map(({averageEmployeeLoggedTime}) => {
              return {
                type: INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_SUCCESS,
                averageEmployeeLoggedTime: {
                  items: averageEmployeeLoggedTime.edges,
                  totalCount: averageEmployeeLoggedTime.pageInfo.totalCount
                }
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    return merge(
      changeAverageEmployeeLoggedTimeBusiness,
      changeAverageEmployeeLoggedTimeKeyword,
      changeAverageEmployeeLoggedTimeResultPage,
      changeAverageEmployeeLoggedTimeRowsPerPage,
      loadActivityPerBusiness,
      loadActivityPerEmployee,
      loadAverageEmployeeLoggedTime,
      loadAverageLoggedTime,
      loadInsights,
      loadInsightsSuccess
    );
  };

export default InsightContactCentersUsersEpic;
