import {EnumType, jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Send mutation to update thread status
 * @param {String} clientItemId
 * @param {String} conversationId
 * @param {Enumerator} status
 * @returns {String}
 */
const graphqlUpdateConversationStatusMutation = ({clientItemId, conversationId, status}) => {
  const mutation = {
    mutation: {
      updateConversationStatus: {
        __args: {
          clientItemId,
          conversationId,
          status: new EnumType(status)
        },
        id: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/* eslint-disable import/prefer-default-export */
export {graphqlUpdateConversationStatusMutation};
