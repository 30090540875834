import {
  INSIGHT_CONNECTED_CUSTOMERS_OPEN,
  INSIGHT_CONTACT_CENTERS_CONTACTS_OPEN,
  INSIGHT_CONTACT_CENTERS_USERS_OPEN,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN,
  INSIGHT_CONTACTS_OPEN,
  INSIGHT_CONVERSATIONS_OPEN,
  INSIGHT_DEVICES_OPEN,
  INSIGHT_MESSAGES_OPEN,
  INSIGHT_NEW_CUSTOMERS_OPEN,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_OPEN,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN,
  INSIGHT_PREFERRED_CHANNEL_OPEN,
  INSIGHT_RESPONSE_RATE_OPEN,
  INSIGHT_RESPONSE_TIME_OPEN,
  INSIGHT_SATISFACTION_OPEN,
  INSIGHT_TOP_STORES_OPEN,
  INSIGHT_WAITING_CUSTOMERS_OPEN
} from '../../actions/insight-actions';

const INSIGHT_DOMAIN_TO_ACTION_TYPE_MAPPING_FOR_OPEN = {
  connectedCustomers: INSIGHT_CONNECTED_CUSTOMERS_OPEN,
  contactCentersContacts: INSIGHT_CONTACT_CENTERS_CONTACTS_OPEN,
  contactCentersUsers: INSIGHT_CONTACT_CENTERS_USERS_OPEN,
  contactCentersWebAHT: INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN,
  contactCentersWebConnectedUsers: INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN,
  contacts: INSIGHT_CONTACTS_OPEN,
  conversations: INSIGHT_CONVERSATIONS_OPEN,
  devices: INSIGHT_DEVICES_OPEN,
  messages: INSIGHT_MESSAGES_OPEN,
  newCustomers: INSIGHT_NEW_CUSTOMERS_OPEN,
  newInboundConversations: INSIGHT_NEW_INBOUND_CONVERSATIONS_OPEN,
  newOutboundConversations: INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN,
  preferredChannel: INSIGHT_PREFERRED_CHANNEL_OPEN,
  responseRate: INSIGHT_RESPONSE_RATE_OPEN,
  responseTime: INSIGHT_RESPONSE_TIME_OPEN,
  satisfaction: INSIGHT_SATISFACTION_OPEN,
  topStores: INSIGHT_TOP_STORES_OPEN,
  waitingCustomers: INSIGHT_WAITING_CUSTOMERS_OPEN
};

// eslint-disable-next-line import/prefer-default-export
export {INSIGHT_DOMAIN_TO_ACTION_TYPE_MAPPING_FOR_OPEN};
