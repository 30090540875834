import PropTypes from 'prop-types';
import {useState} from 'react';
import IconButton from 'material-ui/IconButton/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import {ToolbarGroup, ToolbarTitle} from 'material-ui/Toolbar';
import {isCurrentPageInHeading, isCurrentPageInHeadingCustomers} from '../../lib/route-helper';
import {shouldShowTutorial, TUTORIALS} from '../../data/tutorial/tutorial-settings';
import BadgedLink from './link/badged-link-component';
import HeaderLayout from '../common/header/header-layout-component';
import InviteButton from '../global/invitation/global-invitation-invite-button-component';
import MentionsPopupHandler from './mention/mention-popup-handler-component';
import OrganizationAvatar from '../common/avatar/organization-avatar-component';
import Twilio from './twilio/twilio-component';
import UserAvatar from '../common/avatar/user-avatar-component';
import {avatarSize, fontSize, fontWeight, spacing} from '../../../../shared/style/variables';
import {darkSmoke} from '../../../../shared/style/colors';
import {CLICKABLE_AREAS} from '../../data/ui/clickable-areas';
import TOP_MENU_LINK_STYLE from './lib/utils';

const HeaderComponent = ({
  enableHeaderMentionsPopup,
  enableStatisticsMenu,
  enableStatisticsMenuRealTime,
  fullName,
  headerMention,
  i18n,
  isSmallScreen,
  muiTheme,
  organizationPicture,
  picture,
  routePathname,
  showAdmin,
  twilio,
  twilioEnable,
  unreadColleagueThreadCount,
  unreadCustomerThreadCount,
  doHeaderMentionsPopupClose,
  doHeaderMentionsPopupItemClick,
  doHeaderMentionsPopupItemsLoadMore,
  doHeaderMentionsPopupOpen,
  doLogout,
  doColleagueInvitationPopupOpen,
  doRegisterTutorial,
  doTwilioTokenLoad,
  onUIAreaClick,
  push
}) => {
  const [tutorialStatisticsRealTimeChecked, setTutorialStatisticsRealTimeChecked] = useState(false);

  const onClickAppHeader = () => {
    onUIAreaClick(CLICKABLE_AREAS.APP_HEADER);
  };

  const {appHeaderStatisticsRealTime} = TUTORIALS;
  const tutorialKey = appHeaderStatisticsRealTime.key;
  const shouldCheckTutorialStatisticsRealTime =
    enableStatisticsMenuRealTime && !tutorialStatisticsRealTimeChecked;

  if (shouldCheckTutorialStatisticsRealTime) {
    if (shouldShowTutorial({key: tutorialKey})) {
      doRegisterTutorial(tutorialKey);
    }

    setTutorialStatisticsRealTimeChecked(true);
  }

  const menuItems = [
    {
      action: () => {
        window.location = `${OLD_WEBAPP_URL}/a#/account-settings/general`;
      },
      primaryText: i18n.t('header.userMenu.accountSettings'),
      testId: 'account-settings'
    },
    {
      action: doLogout,
      primaryText: i18n.t('header.userMenu.signOut'),
      testId: 'signout'
    }
  ];

  if (showAdmin) {
    menuItems.unshift(
      {
        action: () => {
          window.location = `${OLD_WEBAPP_URL}/a#/administration/organization-stores`;
        },
        primaryText: i18n.t('header.userMenu.administration'),
        testId: 'administration'
      },
      {
        action: () => {
          push({
            pathname: '/app/integration-area/widgets/bubble'
          });
        },
        primaryText: i18n.t('header.userMenu.integrationArea'),
        testId: 'integration-area'
      }
    );
  }

  return (
    <HeaderLayout
      onClick={onClickAppHeader}
      style={{
        justifyContent: 'flex-start',
        fontSize: fontSize.small,
        fontWeight: fontWeight.semiBold
      }}
    >
      <ToolbarGroup firstChild>
        <OrganizationAvatar src={organizationPicture} style={{marginLeft: spacing.large}} />
      </ToolbarGroup>

      <ToolbarGroup
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          paddingLeft: spacing.large,
          alignItems: 'center'
        }}
      >
        <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
          <BadgedLink
            data-test-id="customers-header-link"
            href={{pathname: '/app/customers'}}
            isActive={isCurrentPageInHeadingCustomers(routePathname)}
            label={i18n.t('header.customers')}
            number={unreadCustomerThreadCount}
            title={i18n.t('header.unreadCustomerThreads')}
          />
          <BadgedLink
            data-test-id="colleagues-header-link"
            href={{pathname: '/app/colleagues'}}
            isActive={isCurrentPageInHeading('colleagues', routePathname)}
            label={i18n.t('header.colleagues')}
            number={unreadColleagueThreadCount}
          />
          {enableStatisticsMenu ? (
            <BadgedLink
              className={appHeaderStatisticsRealTime.className}
              data-test-id="statistics-header-link"
              href={{pathname: '/app/insights'}}
              isActive={isCurrentPageInHeading('insights', routePathname)}
              label={i18n.t('header.insights')}
            />
          ) : null}

          <InviteButton
            color={darkSmoke}
            label={i18n.t('colleagueInvitation.shortLinkLabel')}
            {...{
              doColleagueInvitationPopupOpen
            }}
            style={{
              marginLeft: '20px',
              textTransform: 'uppercase',
              ...TOP_MENU_LINK_STYLE
            }}
          />
        </div>

        {twilioEnable ? <Twilio {...{doTwilioTokenLoad, twilio}} /> : null}

        {enableHeaderMentionsPopup ? (
          <MentionsPopupHandler
            {...{
              doHeaderMentionsPopupClose,
              doHeaderMentionsPopupItemClick,
              doHeaderMentionsPopupItemsLoadMore,
              doHeaderMentionsPopupOpen,
              headerMention,
              i18n,
              muiTheme
            }}
          />
        ) : null}
      </ToolbarGroup>

      <ToolbarGroup lastChild style={{marginLeft: isSmallScreen ? '30px' : '50px'}}>
        <UserAvatar size={avatarSize.small} src={picture} title={fullName} />

        {isSmallScreen ? null : (
          <ToolbarTitle
            data-test-id="fullName"
            text={fullName}
            style={{
              marginLeft: spacing.medium,
              lineHeight: '50px',
              textTransform: 'uppercase',
              color: 'white',
              fontSize: 'inherit'
            }}
          />
        )}

        <IconMenu
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          targetOrigin={{horizontal: 'right', vertical: 'top'}}
          iconButtonElement={
            <IconButton
              data-test-id="user-menu-button"
              iconClassName="material-icons"
              iconStyle={{color: 'white'}}
              style={{paddingLeft: 0, width: 'auto'}}
            >
              more_vert
            </IconButton>
          }
        >
          {menuItems.map(({action, primaryText, testId}) => (
            <MenuItem
              data-test-id={testId}
              key={testId}
              onClick={action}
              {...{
                primaryText
              }}
              style={{
                fontSize: fontSize.medium
              }}
            />
          ))}
        </IconMenu>
      </ToolbarGroup>
    </HeaderLayout>
  );
};

HeaderComponent.propTypes = {
  enableHeaderMentionsPopup: PropTypes.bool.isRequired,
  enableStatisticsMenu: PropTypes.bool.isRequired,
  enableStatisticsMenuRealTime: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  headerMention: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  muiTheme: PropTypes.objectOf(PropTypes.any).isRequired,
  organizationPicture: PropTypes.string,
  picture: PropTypes.string,
  showAdmin: PropTypes.bool.isRequired,
  routePathname: PropTypes.string.isRequired,
  twilio: PropTypes.objectOf(PropTypes.any).isRequired,
  twilioEnable: PropTypes.bool.isRequired,
  unreadColleagueThreadCount: PropTypes.number.isRequired,
  unreadCustomerThreadCount: PropTypes.number.isRequired,
  doHeaderMentionsPopupClose: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemClick: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemsLoadMore: PropTypes.func.isRequired,
  doHeaderMentionsPopupOpen: PropTypes.func.isRequired,
  doLogout: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doRegisterTutorial: PropTypes.func.isRequired,
  doTwilioTokenLoad: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
};

export default HeaderComponent;
