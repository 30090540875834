import PropTypes from 'prop-types';
import {List, ListItemIcon, MenuItem, Typography} from '@material-ui/core';
import {CreditCard} from '@material-ui/icons';

const VisibleThreadAppsMenuComponent = ({onSelectMenuItemPayment, i18n}) => {
  const MENU_ITEMS = [
    {
      icon: <CreditCard />,
      onClick: onSelectMenuItemPayment,
      text: i18n.t('customerThread.visibleThread.apps.menu.items.payment')
    }
  ];

  return (
    <List data-test-id="apps-menu-list">
      {MENU_ITEMS.map(({icon, onClick, text}) => (
        <MenuItem
          key={text}
          {...{
            onClick
          }}
        >
          <ListItemIcon color="primary">{icon}</ListItemIcon>
          <Typography data-test-id="item-label" variant="inherit">
            {text}
          </Typography>
        </MenuItem>
      ))}
    </List>
  );
};

VisibleThreadAppsMenuComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelectMenuItemPayment: PropTypes.func.isRequired
};

export default VisibleThreadAppsMenuComponent;
