import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Input, InputAdornment} from '@material-ui/core';
import isAcceptableAsAmountInput from '../../../../../../../../lib/payment-helper';
import {fontSize} from '../../../../../../../../../../shared/style/variables';
import {PAYMENT_AMOUNT_MAX} from '../../../../../../../../data/settings';

const StyledInputAdornment = withStyles({
  root: {
    position: 'relative',
    top: '-3px',
    fontSize: fontSize.xxxxxxlarge,
    color: 'inherit'
  }
})(InputAdornment);

/**
 * Finally, the component.
 */
const PaymentAmountComponent = ({amount, conversationId, i18n, onAppPaymentAmountChange}) => {
  const onInputChange = (event) => {
    const newAmount = event.target.value;

    if (!isAcceptableAsAmountInput(newAmount, i18n.language)) {
      return;
    }

    return onAppPaymentAmountChange(newAmount, conversationId);
  };

  // should equal amount limit chars count + 1 for decimal separator + 2 for eventual decimals count
  const inputMaxLength = PAYMENT_AMOUNT_MAX.toString().length + 3;

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center'
      }}
    >
      <Input
        autoComplete="off"
        autoFocus
        disableUnderline
        endAdornment={<StyledInputAdornment position="end">€</StyledInputAdornment>}
        inputProps={{
          'data-test-id': 'amount-input',
          maxLength: inputMaxLength,
          style: {
            width: `${(amount.length + 1) * 34}px`, // tried with unit "ch", but it does not work well on IE 11
            fontSize: fontSize.xxxxxxlarge,
            color: 'inherit',
            textAlign: 'center'
          }
        }}
        onChange={onInputChange}
        value={amount}
      />
    </div>
  );
};

PaymentAmountComponent.propTypes = {
  conversationId: PropTypes.string.isRequired,
  amount: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired
};

export default PaymentAmountComponent;
