import PropTypes from 'prop-types';
import {lightSmoke} from '../../../../../shared/style/colors';

const LeftPanelLayoutComponent = ({style, children, onClick}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 25%',
      maxWidth: '420px',
      minWidth: '280px',
      position: 'relative',
      borderRight: `1px solid ${lightSmoke}`,
      ...style
    }}
    {...{
      onClick
    }}
  >
    {children}
  </div>
);

LeftPanelLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any)
};

export default LeftPanelLayoutComponent;
