import PropTypes from 'prop-types';
import clamp from 'lodash/clamp';
import BadgeLayout from './badge-layout-component';

const CappedBadgeComponent = ({badgeStyle, max, number, ...otherProps}) => (
  <BadgeLayout
    style={{
      borderStyle: 'solid',
      borderWidth: '1px',
      lineHeight: '16px',
      ...badgeStyle
    }}
    {...otherProps}
  >
    {clamp(number, 0, max)}
  </BadgeLayout>
);

CappedBadgeComponent.defaultProps = {
  max: 99
};

CappedBadgeComponent.propTypes = {
  badgeStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  max: PropTypes.number,
  number: PropTypes.number.isRequired
};

export default CappedBadgeComponent;
