export const LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD =
  'LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD';

export const ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION =
  'ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION';

export const ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION =
  'ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION';

export const ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER =
  'ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER';
