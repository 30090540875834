import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {oneline} from '../../../../../../../shared/style/ellipsis';
import {lineHeight} from '../../../../../../../shared/style/variables';
import {slate} from '../../../../../../../shared/style/colors';

// @todo Simplify / remove this component when migration to material-ui v1 is finished
class TableCellTextComponent extends PureComponent {
  render() {
    const {children, style, ...props} = this.props;

    return (
      <span
        {...props}
        style={{
          display: 'block',
          color: slate,
          lineHeight: lineHeight.large,
          ...oneline,
          ...style
        }}
      >
        {children}
      </span>
    );
  }
}

TableCellTextComponent.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired
};

export default TableCellTextComponent;
