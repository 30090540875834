import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';

const ThreadWhatsAppTemplateHeaderComponent = ({i18n, doCancelWhatsappTemplatesScreen}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <Button
        data-test-id="cancel-button"
        color="primary"
        onClick={doCancelWhatsappTemplatesScreen}
        size="small"
      >
        {i18n.t('customerThread.visibleThread.channels.whatsapp.templates.cancelButton')}
      </Button>
      <h3 data-test-id="title">
        {i18n.t('customerThread.visibleThread.channels.whatsapp.templates.headerTitle')}
      </h3>
      <div style={{width: '64px'}} />
    </div>
  );
};

ThreadWhatsAppTemplateHeaderComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  doCancelWhatsappTemplatesScreen: PropTypes.func.isRequired
};

export default ThreadWhatsAppTemplateHeaderComponent;
