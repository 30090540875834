import {ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES} from '../../../../../../../../data/administration/administration-organization-data';

const setGbmSwitchButtonState = (businessLocation, onCheckSwitchButton) => {
  const {
    LAUNCH_STATE_LAUNCHED,
    LAUNCH_STATE_PENDING_UNLAUNCH,
    LAUNCH_STATE_UNSPECIFIED,
    LAUNCH_STATE_UNLAUNCHED,
    STATE_NEW,
    VERIFICATION_STATE_PENDING,
    VERIFICATION_STATE_SUSPENDED_IN_GMB,
    VERIFICATION_STATE_UNSPECIFIED,
    VERIFICATION_STATE_VERIFIED
  } = ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES;

  const checkedGbmSwitchButtonStatus = [
    LAUNCH_STATE_LAUNCHED,
    LAUNCH_STATE_PENDING_UNLAUNCH,
    LAUNCH_STATE_UNSPECIFIED,
    VERIFICATION_STATE_PENDING,
    VERIFICATION_STATE_SUSPENDED_IN_GMB,
    VERIFICATION_STATE_UNSPECIFIED,
    VERIFICATION_STATE_VERIFIED
  ];
  const notCheckedGbmSwitchButtonStatus = [LAUNCH_STATE_UNLAUNCHED, STATE_NEW];

  if (checkedGbmSwitchButtonStatus.includes(businessLocation.status)) {
    onCheckSwitchButton(true);
  }

  if (notCheckedGbmSwitchButtonStatus.includes(businessLocation.status)) {
    onCheckSwitchButton(false);
  }
};

// eslint-disable-next-line import/prefer-default-export
export {setGbmSwitchButtonState};
