import {merge, of} from 'rxjs';
import {auditTime, catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {getFilterIsCurrentPageInHeadingCustomers} from '../../../lib/route-helper';
import {
  CUSTOMER_INBOX_THREADS_LIST_FILTER_BY_STATUS,
  CUSTOMER_INBOX_THREADS_LIST_LOAD,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_FAILURE,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_SUCCESS,
  CUSTOMER_INBOX_THREADS_LIST_RELOAD
} from '../../../../actions/customer-inbox-actions';
import {
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
  CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS
} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS} from '../../../../actions/customer-thread-transfer-actions';
import {
  EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME,
  EXT_CONVERSATION_STATUS_CHANGED,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME,
  EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD,
  EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS,
  LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED
} from '../../../../actions/ext-actions';
import {CUSTOMER_INBOX_THREADS_LIST_LOAD_AUDIT_TIME} from '../../../../data/settings';

/**
 * Get the action to reload the threads list.
 * @param {Immutable.Map} customerInboxState
 * @param {String} [statuses]
 * @returns {Object}
 */
const getActionThreadsListLoad = (customerInboxState, statuses = null) => ({
  type: CUSTOMER_INBOX_THREADS_LIST_LOAD,
  businessId: customerInboxState.get('businessId'),
  businessScope: customerInboxState.get('businessScope'),
  noClipping: !statuses,
  offset: 0,
  sortBy: customerInboxState.get('sortBy'),
  statuses: statuses || customerInboxState.get('statuses')
});

/**
 * Finally, the epic.
 */
const CustomerInboxThreadsListEpic =
  ({query}) =>
  (action$, state$) => {
    const loadThreadsList = action$.pipe(
      ofType(CUSTOMER_INBOX_THREADS_LIST_LOAD),
      auditTime(CUSTOMER_INBOX_THREADS_LIST_LOAD_AUDIT_TIME),
      mergeMap(({businessId, businessScope, offset, sortBy, statuses}) => {
        const customerInboxState = state$.value.get('customerInbox');

        const aggregatedQuery = {
          all: 'get-organization-inbox',
          mine: 'get-employee-inbox'
        }[businessScope || customerInboxState.get('businessScope')];

        return query(aggregatedQuery || 'get-business-inbox', {
          id: aggregatedQuery ? undefined : businessId || customerInboxState.get('businessId'),
          sortBy:
            statuses.indexOf('waiting') > -1
              ? sortBy || customerInboxState.get('sortBy')
              : undefined,
          offset: offset || undefined,
          statuses
        }).pipe(
          map(({data}) => {
            return {
              type: CUSTOMER_INBOX_THREADS_LIST_LOAD_SUCCESS,
              data
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_INBOX_THREADS_LIST_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const reloadThreadsListOnFilterStatusChange = action$.pipe(
      ofType(CUSTOMER_INBOX_THREADS_LIST_FILTER_BY_STATUS),
      map(({statuses}) => getActionThreadsListLoad(state$.value.get('customerInbox'), statuses))
    );

    const reloadThreadsListOnThreadEventThatMayChangeTheList = action$.pipe(
      ofType(
        // Customer profile
        // @todo When working on MS Phase 2, remove these 2 actions from here and replace by optimistic updates
        CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
        CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS,
        // Others
        CUSTOMER_INBOX_THREADS_LIST_RELOAD,
        EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME,
        EXT_CONVERSATION_STATUS_CHANGED,
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME,
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME,
        EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
        EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS,
        EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
        EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD,
        LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS,
        LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED // this one replaces the need of using CUSTOMER_THREAD_STATUS_UPDATE_SUCCESS
      ),
      filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
      mergeMap(({needInboxRefresh}) => {
        if (needInboxRefresh || typeof needInboxRefresh === 'undefined') {
          const customerInboxState = state$.value.get('customerInbox');

          return [getActionThreadsListLoad(customerInboxState)];
        }

        return [];
      })
    );

    return merge(
      loadThreadsList,
      reloadThreadsListOnFilterStatusChange,
      reloadThreadsListOnThreadEventThatMayChangeTheList
    );
  };

export default CustomerInboxThreadsListEpic;
