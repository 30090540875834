/**
 * Add an async javascript tag.
 *
 * @param {String} url
 * @param {Object} options
 */
export default function addScript(url, options = {}) {
  const doc = typeof options.target !== 'undefined' ? options.target : document;

  const s = doc.createElement('script');

  s.type = 'text/javascript';
  s.async = true;

  if (typeof options.onload === 'function') {
    s.onload = options.onload;
  }

  if (typeof options.id !== 'undefined') {
    s.id = options.id;
  }

  s.src = url;

  doc.body.appendChild(s);
}
