import PropTypes from 'prop-types';

const AdministrationRightPanelHeaderComponent = ({title}) => (
  <div
    data-test-id="content-header"
    style={{
      borderBottom: '1px solid #d3dce6',
      color: '#333',
      fontSize: '22px',
      fontWeight: 'normal',
      lineHeight: '1.4em',
      padding: '15px 20px'
    }}
  >
    {title}
  </div>
);

AdministrationRightPanelHeaderComponent.propTypes = {
  title: PropTypes.string.isRequired
};

export default AdministrationRightPanelHeaderComponent;
