import {ListItem} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {spacing} from '../../../../../../shared/style/variables';
import {lightSlate, slate} from '../../../../../../shared/style/colors';

const MARGIN_BETWEEN_SUB_ITEMS = '3px';
const SUB_ITEM_TITLE_LINE_HEIGHT = '21px';

const getMarginType = (isLastChild) => (isLastChild ? spacing.large : spacing.xsmall);
const getSelectedColor = (isSelected) => (isSelected ? slate : lightSlate);
const getSelectedDataTestId = (isSelected) => (isSelected ? 'selected' : undefined);

const getStyledListItem = (muiTheme) =>
  withStyles({
    root: {
      '&:hover': {
        backgroundColor: `${muiTheme.palette.hoverColor} !important`
      },
      '&$selected': {
        backgroundColor: muiTheme.palette.selectedColor
      }
    },
    selected: {}
  })(ListItem);

export {
  getMarginType,
  getSelectedColor,
  getSelectedDataTestId,
  getStyledListItem,
  MARGIN_BETWEEN_SUB_ITEMS,
  SUB_ITEM_TITLE_LINE_HEIGHT
};
