import PropTypes from 'prop-types';
import {spacing} from '../../../../../../shared/style/variables';
import {blue, lightSmoke, mediumGreen} from '../../../../../../shared/style/colors';
import BubbleIcon from '../../../../../../shared/component/icons/bubble-icon';
import StandardButtonComponent from '../../../../../../shared/component/button/standard-button-component';
import {CLICKABLE_AREAS} from '../../../../data/ui/clickable-areas';

const ThreadBusinessOutOfScopeComponent = ({i18n, onUIAreaClick}) => {
  const iconName = {
    collaborate: 'COLLABORATE',
    myStore: 'MY-STORE'
  };

  const PADDING_BUTTON = 0;

  const onClickButtonCollaborate = () => {
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.INTERNAL);
  };

  return (
    <div
      style={{
        alignItems: 'center',
        borderTop: `1px solid ${lightSmoke}`,
        display: 'flex',
        flexDirection: 'column',
        height: '75px',
        justifyContent: 'center',
        padding: spacing.medium
      }}
    >
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px', width: '100%'}}>
        <div style={{marginRight: '15px'}}>
          <StandardButtonComponent
            data-test-id="customer-thread-my-store-button"
            onClick={() => {}}
            position="positionLeft"
            startIcon={
              <BubbleIcon
                containerStyle={{padding: '4px 0 0 1px'}}
                iconName={iconName.myStore}
                iconStyle={{width: '16px'}}
              />
            }
            style={{
              border: `1px solid ${blue}`,
              color: blue,
              padding: PADDING_BUTTON,
              width: '220px'
            }}
            variant="outlined"
          >
            {i18n.t('customerThread.visibleThread.outOfScope.myStore')}
          </StandardButtonComponent>
        </div>
        <div>
          <StandardButtonComponent
            data-test-id="customer-thread-collaborate-button"
            endIcon={
              <BubbleIcon
                iconName={iconName.collaborate}
                iconStyle={{width: '16px'}}
                containerStyle={{background: mediumGreen, paddingTop: '6px'}}
              />
            }
            onClick={onClickButtonCollaborate}
            position="positionRight"
            style={{
              border: `1px solid ${mediumGreen}`,
              color: mediumGreen,
              padding: PADDING_BUTTON,
              width: '175px'
            }}
            variant="outlined"
          >
            {i18n.t('customerThread.visibleThread.outOfScope.collaborate')}
          </StandardButtonComponent>
        </div>
      </div>
      <div data-test-id="customer-thread-out-of-scope-label">
        {i18n.t('customerThread.visibleThread.outOfScope.information')}
      </div>
    </div>
  );
};

ThreadBusinessOutOfScopeComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onUIAreaClick: PropTypes.func.isRequired
};

export default ThreadBusinessOutOfScopeComponent;
