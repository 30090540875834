import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover/Popover';
import PopoverAnimationVertical from 'material-ui/Popover/PopoverAnimationVertical';
import KeyboardClosableComponent from '../../../../shared/component/keyboard-closable-component';
import {lightSmoke} from '../../../../shared/style/colors';

class EnhancedPopoverComponent extends KeyboardClosableComponent {
  render() {
    const {children, dataTestId, style, ...otherProps} = this.props;

    return (
      <Popover
        animation={PopoverAnimationVertical}
        {...otherProps}
        style={{
          border: `1px solid ${lightSmoke}`,
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0,0,0,.15)',
          background: 'white',
          ...style
        }}
      >
        <div data-test-id={dataTestId}>{children}</div>
      </Popover>
    );
  }
}

EnhancedPopoverComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any)
};

export default EnhancedPopoverComponent;
