const CLICKABLE_AREAS = {
  APP_HEADER: 'app-header',
  CUSTOMERS: {
    CONVERSATION: {
      BUSINESSES_TABS: 'customer-conversation-businesses-tabs',
      HEADER: 'customer-conversation-header',
      INTERNAL: 'customer-conversation-internal',
      SIDEBAR: 'customer-thread-sidebar',
      VISIBLE: {
        FOOTER: 'customer-conversation-visible-footer',
        SCROLLABLE_MESSAGES_LIST: 'customer-conversation-visible-scrollable-messages-list',
        STATUS_BUTTONS: 'customer-conversation-status-buttons'
      }
    },
    INBOX: 'customer-inbox'
  }
};

const CLICKABLE_AREAS_CUSTOMER_CONVERSATION_FOCUSABLE = [
  CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.VISIBLE.FOOTER,
  CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.VISIBLE.STATUS_BUTTONS
];

const CLICKABLE_AREAS_CUSTOMER_CONVERSATION_VISIBLE = Object.values(
  CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.VISIBLE
);

export {
  CLICKABLE_AREAS,
  CLICKABLE_AREAS_CUSTOMER_CONVERSATION_FOCUSABLE,
  CLICKABLE_AREAS_CUSTOMER_CONVERSATION_VISIBLE
};
