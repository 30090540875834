import {merge, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {push} from 'react-router-redux';
import {
  conditionalReloadOfHeaderMentionsPopupMissedItems,
  getBeforeFirst
} from './lib/header-mention-epic-helper';
import {filterByMultistoreConversationDisabled} from '../../lib/feature-toggle-helper';
import {queryfyObject} from '../../../../shared/lib/route-helper';
import {LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED} from '../../actions/ext-actions';
import {
  HEADER_MENTIONS_POPUP_CLOSE,
  HEADER_MENTIONS_POPUP_ITEM_CLICK,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD_FAILURE,
  HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS,
  LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN
} from '../../actions/header-actions';
import {HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE} from '../../data/settings';

const LegacyHeaderMentionEpic =
  ({graphql}) =>
  (action$, state$) => {
    const legacyGoToCustomerThreadOnHeaderMentionsPopupItemOpen = action$.pipe(
      ofType(LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN),
      map(({payload: {messageId, participationId}}) =>
        push({
          pathname: `/app/customers/${participationId}`,
          search: queryfyObject({
            invisibleDirection: 'both',
            invisibleFromId: messageId
          })
        })
      )
    );

    const legacyLoadHeaderMentionsPopupItems = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEMS_LOAD),
      filter(filterByMultistoreConversationDisabled(state$)),
      mergeMap(({payload: {after, before}}) => {
        const headerMentionState = state$.value.get('headerMention');

        // @todo use param "before" as a mentionId as soon as graphql endpoint can handle it
        return graphql('get-mentions-query', {
          slice: {
            // In case of failure, we try to reload a bit more more than usual pagination, but without going over current edges size
            first: before
              ? getBeforeFirst(
                  headerMentionState.get('edges').size,
                  headerMentionState.getIn(['pageInfo', 'hasNextPage'])
                )
              : HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE,
            after
          }
        }).pipe(
          map(({mentions}) => ({
            type: HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS,
            payload: {
              after,
              before,
              mentions
            }
          })),
          catchError((error) =>
            of({
              type: HEADER_MENTIONS_POPUP_ITEMS_LOAD_FAILURE,
              error
            })
          )
        );
      })
    );

    const legacyOpenItemOnHeaderMentionsPopupItemClick = action$.pipe(
      ofType(HEADER_MENTIONS_POPUP_ITEM_CLICK),
      filter(filterByMultistoreConversationDisabled(state$)),
      mergeMap(({payload: {cursor}}) => {
        const itemNode = state$.value
          .getIn(['headerMention', 'edges'])
          .find((edge) => edge.get('cursor') === cursor)
          .get('node');

        return [
          {
            type: HEADER_MENTIONS_POPUP_CLOSE
          },
          {
            type: LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN,
            payload: {
              customer: {
                company: itemNode.getIn(['customerThread', 'companyName']),
                displayName: itemNode.getIn(['customerThread', 'title'])
              },
              messageId: itemNode.get('messageId'),
              participationId: itemNode.getIn(['customerThread', 'participationId']),
              unreadMentionId: itemNode.get('isRead') ? null : itemNode.get('mentionId')
            }
          }
        ];
      })
    );

    const legacyReloadHeaderMentionsPopupMissedItemsOnNewMention =
      conditionalReloadOfHeaderMentionsPopupMissedItems(
        state$,
        action$,
        LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED,
        () => true
      );

    return merge(
      legacyGoToCustomerThreadOnHeaderMentionsPopupItemOpen,
      legacyLoadHeaderMentionsPopupItems,
      legacyOpenItemOnHeaderMentionsPopupItemClick,
      legacyReloadHeaderMentionsPopupMissedItemsOnNewMention
    );
  };

export default LegacyHeaderMentionEpic;
