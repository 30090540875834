import {PureComponent} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ListItem} from 'material-ui/List';
import context from '../../../../../shared/component/context';
import ContentLayout from '../../common/layout/content-layout-component';
import EnhancedSwitch from '../../common/enhanced-switch-component';
import LeftPanelWithMenuLayout, {
  LEFT_LIST_ITEM_STYLE
} from '../../common/layout/left-panel-with-menu-layout-component';
import RightPanelLayout from '../../common/layout/right-panel-layout-component';
import BubbleWidgetContainer from '../../../container/content/integration-area/page/widgets/bubble-widget-container';
import CTAWidgetContainer from '../../../container/content/integration-area/page/widgets/cta-widget-container';
import EmbedFormWidgetContainer from '../../../container/content/integration-area/page/widgets/embed-form-widget-container';
import MobileWidgetContainer from '../../../container/content/integration-area/page/widgets/mobile-widget-container';
import APISDKDocumentationComponent from './page/api-sdk-documentation-component';
import MobileAppsComponent from './page/mobile-apps-component';
import ThirdPartyIntegrationsComponent from './page/third-party-integrations-component';

class IntegrationAreaComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {match, path, push} = this.props;

    return (
      <ContentLayout>
        <LeftPanelWithMenuLayout path={path} title={i18n.t('integrationArea.title')}>
          <ListItem
            disabled
            initiallyOpen
            primaryText={i18n.t('integrationArea.widgets.title')}
            style={LEFT_LIST_ITEM_STYLE}
            nestedItems={Object.entries({
              bubble: 'bubble',
              cta: 'cta',
              'embed-form': 'embedForm',
              mobile: 'mobile'
            }).map((entry) => {
              const value = `${match.url}/widgets/${entry[0]}`;

              return (
                <ListItem
                  data-test-id={`${entry[0]}-menu-item`}
                  key={value}
                  onClick={() => push(value)}
                  primaryText={i18n.t(`integrationArea.widgets.${entry[1]}.title`)}
                  style={LEFT_LIST_ITEM_STYLE}
                  value={value}
                />
              );
            })}
          />
          {Object.entries({
            'api-sdk-documentation': 'apiSdkDocumentation',
            'third-party-integrations': 'thirdPartyIntegrations',
            'mobile-apps': 'mobileApps'
          }).map((entry) => {
            const value = `${match.url}/${entry[0]}`;

            return (
              <ListItem
                data-test-id={entry[0]}
                key={value}
                onClick={() => push(value)}
                primaryText={i18n.t(`integrationArea.${entry[1]}.menuTitle`)}
                style={LEFT_LIST_ITEM_STYLE}
                value={value}
              />
            );
          })}
        </LeftPanelWithMenuLayout>

        <RightPanelLayout>
          <EnhancedSwitch>
            <Route path={`${match.url}/widgets/bubble`} exact component={BubbleWidgetContainer} />
            <Route path={`${match.url}/widgets/cta`} exact component={CTAWidgetContainer} />
            <Route
              path={`${match.url}/widgets/embed-form`}
              exact
              component={EmbedFormWidgetContainer}
            />
            <Route path={`${match.url}/widgets/mobile`} exact component={MobileWidgetContainer} />
            <Route
              path={`${match.url}/api-sdk-documentation`}
              exact
              component={APISDKDocumentationComponent}
            />
            <Route path={`${match.url}/mobile-apps`} exact component={MobileAppsComponent} />
            <Route
              path={`${match.url}/third-party-integrations`}
              exact
              component={ThirdPartyIntegrationsComponent}
            />
          </EnhancedSwitch>
        </RightPanelLayout>
      </ContentLayout>
    );
  }
}

IntegrationAreaComponent.contextTypes = context;

IntegrationAreaComponent.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired
};

export default IntegrationAreaComponent;
