import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
import context from '../../../../shared/component/context';
import ContentLayout from './layout/content-layout-component';
import {Error404Content} from './empty-area-with-logo-component';

export const Error404 = (props, {i18n}) => (
  <ContentLayout>
    <Error404Content {...{i18n}} />
  </ContentLayout>
);

Error404.contextTypes = context;

/**
 * Finally, the component.
 *
 * @param {Array} children
 */
const EnhancedSwitchComponent = ({children}) => (
  <Switch>
    {children}
    <Route component={Error404} />
  </Switch>
);

EnhancedSwitchComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default EnhancedSwitchComponent;
