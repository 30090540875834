import {createReducer} from 'redux-immutablejs';
import reduceReducers from 'reduce-reducers';
import errorsHandler from '../../../shared/reducer/lib/errors-handler';
import {
  UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE,
  UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS,
  UNSUBSCRIBE_CONFIRM_FAILURE,
  UNSUBSCRIBE_CONFIRM_SUCCESS,
  UNSUBSCRIBE_RESUBSCRIBE,
  UNSUBSCRIBE_RESUBSCRIBE_FAILURE,
  UNSUBSCRIBE_RESUBSCRIBE_SUCCESS
} from '../actions/unsubscribe-actions';

const setAsNotSubmitting = (state) => state.set('submitting', false);

/**
 * Finally, the reducer.
 */
export const DEFAULT_STATE = {
  email: null,
  step: 'unsubscribe',
  submitting: true,
  errors: []
};

export default reduceReducers(
  createReducer(DEFAULT_STATE, {
    /**
     * Parameters validation success.
     *
     * @param {Object} state
     * @param {String} email
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS]: (state, {email}) => {
      return state.set('email', email);
    },

    /**
     * Parameters validation failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE]: setAsNotSubmitting,

    /**
     * Unsubscription success.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_CONFIRM_SUCCESS]: setAsNotSubmitting,

    /**
     * Unsubscription failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_CONFIRM_FAILURE]: setAsNotSubmitting,

    /**
     * Resubscribe.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_RESUBSCRIBE]: (state) => {
      return state.set('step', 'resubscribe').set('submitting', true);
    },

    /**
     * Resubscribe failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_RESUBSCRIBE_FAILURE]: setAsNotSubmitting,

    /**
     * Resubscribe success.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [UNSUBSCRIBE_RESUBSCRIBE_SUCCESS]: setAsNotSubmitting
  }),
  errorsHandler
);
