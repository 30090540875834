import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  ADMINISTRATION_LAYOUT_MOUNTED,
  ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD,
  ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_FAILURE,
  ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_SUCCESS
} from '../../../../actions/administration-actions';

const AdministrationLeftMenuEpic =
  ({graphql}) =>
  (action$) => {
    const loadOrganizationCountries = action$.pipe(
      ofType(ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD),
      mergeMap(() =>
        graphql('get-organization-countries-query').pipe(
          map(({organizationCountries: {countries}}) => ({
            type: ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_SUCCESS,
            countries
          })),
          catchError((error) =>
            of({
              type: ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD_FAILURE,
              error
            })
          )
        )
      )
    );

    const onAdministrationLayoutMounted = action$.pipe(
      ofType(ADMINISTRATION_LAYOUT_MOUNTED),
      map(() => ({
        type: ADMINISTRATION_LEFT_MENU_ORGANIZATION_COUNTRIES_LOAD
      }))
    );

    return merge(loadOrganizationCountries, onAdministrationLayoutMounted);
  };

export default AdministrationLeftMenuEpic;
