import PropTypes from 'prop-types';
import HeaderContentTitle from '../../../common/content-header/header-content-title-component';
import ThreadChannel from '../../../common/icon/thread-channel';
import {oneline} from '../../../../../../shared/style/ellipsis';
import {fontSize, spacing} from '../../../../../../shared/style/variables';
import {black, lightSlate} from '../../../../../../shared/style/colors';
import {TUTORIALS} from '../../../../data/tutorial/tutorial-settings';

const LegacyThreadHeaderCustomerCustomerInformationComponent = ({
  customerChannel,
  directedToBusinessName,
  i18n,
  marginBetweenBusinessNameAndItsEventualPrefix,
  participantName
}) => {
  return (
    <div style={{flex: 1, overflow: 'hidden'}}>
      <HeaderContentTitle
        dataTestId="thread-participant-name"
        label={participantName}
        style={{marginTop: '3px'}}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          top: '2px',
          marginLeft: spacing.medium,
          height: '20px',
          color: lightSlate,
          ...oneline
        }}
      >
        <ThreadChannel
          className={TUTORIALS.customerThreadHeaderChannel.className}
          type={customerChannel}
          {...{i18n}}
        />
        <span
          data-test-id="thread-business-name"
          style={{
            color: black,
            fontSize: fontSize.xsmall,
            marginLeft: marginBetweenBusinessNameAndItsEventualPrefix,
            position: 'relative',
            top: '-1px'
          }}
        >
          {directedToBusinessName}
        </span>
      </div>
    </div>
  );
};

LegacyThreadHeaderCustomerCustomerInformationComponent.propTypes = {
  customerChannel: PropTypes.string,
  directedToBusinessName: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  marginBetweenBusinessNameAndItsEventualPrefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  participantName: PropTypes.string
};
export default LegacyThreadHeaderCustomerCustomerInformationComponent;
