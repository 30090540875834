import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_FAILURE
} from '../../../../../actions/administration/administration-business-channels-gbm-actions';

export const DEFAULT_STATE = {
  businessLocation: {
    status: null
  },
  businessLocationForm: {
    formState: 'init',
    hasGlobalError: null,
    placeIdError: null,
    submitting: null
  },
  businessLocationDisconnectDialog: {
    dialogState: 'init',
    sending: null,
    hasGlobalError: null
  },
  loading: false
};
/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD]: (state) =>
    state.set('businessLocation', fromJS(DEFAULT_STATE.businessLocation)).set('loading', true),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_FAILURE]: (state) =>
    state.set('loading', false),

  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_SUCCESS]: (
    state,
    {payload: {businessLocation}}
  ) => state.set('businessLocation', fromJS(businessLocation)).set('loading', false),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK]: (state) =>
    state.setIn(['businessLocationForm', 'submitting'], true),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS]: (state) =>
    state.update('businessLocationForm', (businessLocationForm) =>
      businessLocationForm.set('formState', 'success').set('submitting', false)
    ),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_FAILURE]: (state, data) =>
    state.update('businessLocationForm', (businessLocationForm) =>
      businessLocationForm
        .set('hasGlobalError', true)
        .set('submitting', false)
        .set('placeIdError', data.payload?.error)
    ),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK]: (state) =>
    state.setIn(['businessLocationDisconnectDialog', 'sending'], true),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS]: (state) =>
    state.update('businessLocationDisconnectDialog', (businessLocationDisconnectDialog) =>
      businessLocationDisconnectDialog.set('dialogState', 'success').set('sending', false)
    ),
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_FAILURE]: (state) =>
    state.update('businessLocationDisconnectDialog', (businessLocationDisconnectDialog) =>
      businessLocationDisconnectDialog.set('hasGlobalError', true).set('sending', false)
    )
});
