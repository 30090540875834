import {useState} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../../shared/component/context';
import getChannelSetting from '../../../../../data/thread/channels-settings';
import IconButton from '../../../../../../../shared/component/button/icon-button-component';
import InputMessage from '../../../../common/input-message/input-message-component';
import ThreadInteractionArea from '../../../../common/thread/thread-interaction-area-component';
import VisibleThreadAppsArea from './apps/visible-thread-apps-area-component';

const VisibleThreadInteractionAreaComponent = ({
  cannedResponses,
  conversationId,
  customerChannel,
  customerThreadAppPayment,
  disableThreadInputPersistence,
  enablePaymentFeature,
  footerHasFocus,
  i18n,
  isActiveThreadType,
  isAssignedToMe,
  isHistoryAction,
  isNewConversation,
  readOnly,
  releasing,
  sending,
  sendMessage,
  setInputHeight,
  showAssignment,
  threadForm,
  uploadingFile,
  doAppPaymentCancelButtonClick,
  doAppPaymentMaximizeButtonClick,
  doAppPaymentMinimizeButtonClick,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick,
  doAppsAreaMenuButtonClick,
  doAppsAreaMinimize,
  doAppsMenuSelectPayment,
  doThreadVisibleFileRemove,
  onAppPaymentAmountChange,
  onAppPaymentCustomChange,
  onAppPaymentCustomerChange,
  onConversationVisibleMessageFormTextChanged,
  onReleaseThread,
  onThreadVisibleFileSelect
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const isVisibleAppsMenuButton = enablePaymentFeature && !isNewConversation;

  const onAppsMenuButtonClick = () => {
    if (
      !menuVisible &&
      // add here 1 test per app
      customerThreadAppPayment &&
      !customerThreadAppPayment.minimized
    ) {
      doAppsAreaMinimize(conversationId);
    }

    setMenuVisible(!menuVisible);
    doAppsAreaMenuButtonClick();
  };

  const onAppsMenuItemSelect = (appStartFn) => {
    return () => {
      setMenuVisible(false);
      appStartFn(conversationId);
    };
  };

  const renderAppsMenuButton = () => {
    const menuToogleIconPath = {
      appsMenuButtonVisible: `${ASSETS_PATH}/img/icon/thread-footer/APPS-MENU-OPENED.svg`,
      appsMenuButtonHidden: `${ASSETS_PATH}/img/icon/thread-footer/APPS-MENU-CLOSED.svg`
    };

    const MENU_TOGGLE_ICON_SIZE = '25px';
    const MENU_TOGGLE_ICON_SRC = menuVisible
      ? menuToogleIconPath.appsMenuButtonVisible
      : menuToogleIconPath.appsMenuButtonHidden;

    return (
      <IconButton
        data-test-id="apps-button"
        aria-label={i18n.t('customerThread.visibleThread.apps.menu.toggleButtonAriaLabel')}
        onClick={onAppsMenuButtonClick}
        style={{padding: '0 0 22px 15px'}}
      >
        <img
          alt=""
          src={MENU_TOGGLE_ICON_SRC}
          style={{
            height: MENU_TOGGLE_ICON_SIZE,
            width: MENU_TOGGLE_ICON_SIZE
          }}
        />
      </IconButton>
    );
  };

  return (
    <ThreadInteractionArea onResize={setInputHeight}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexGrow: 1
        }}
      >
        {isVisibleAppsMenuButton ? renderAppsMenuButton() : null}

        <InputMessage
          dataTestId="message-input"
          extensionsSupported={getChannelSetting(customerChannel, 'extensionsSupported')}
          maxFileSize={getChannelSetting(customerChannel, 'maxFileSize')}
          placeholder={i18n.t('customerThread.visibleThread.inputMessagePlaceHolder')}
          threadType="visible"
          doRemoveFile={doThreadVisibleFileRemove}
          doSendMessage={sendMessage}
          onSelectFile={onThreadVisibleFileSelect}
          onTextChanged={onConversationVisibleMessageFormTextChanged}
          {...{
            cannedResponses,
            disableThreadInputPersistence,
            footerHasFocus,
            isActiveThreadType,
            isAssignedToMe,
            isHistoryAction,
            readOnly,
            releasing,
            sending,
            showAssignment,
            threadForm,
            uploadingFile,
            onReleaseThread
          }}
        />
      </div>

      {enablePaymentFeature ? (
        <VisibleThreadAppsArea
          onSelectMenuItem={onAppsMenuItemSelect}
          {...{
            conversationId,
            customerThreadAppPayment,
            doAppPaymentCancelButtonClick,
            doAppPaymentMaximizeButtonClick,
            doAppPaymentMinimizeButtonClick,
            doAppPaymentStepperBackButtonClick,
            doAppPaymentStepperNextButtonClick,
            doAppsMenuSelectPayment,
            i18n,
            menuVisible,
            onAppPaymentAmountChange,
            onAppPaymentCustomChange,
            onAppPaymentCustomerChange
          }}
        />
      ) : null}
    </ThreadInteractionArea>
  );
};

VisibleThreadInteractionAreaComponent.contextTypes = context;

VisibleThreadInteractionAreaComponent.propTypes = {
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  conversationId: PropTypes.string.isRequired,
  customerChannel: PropTypes.string,
  customerThreadAppPayment: PropTypes.objectOf(PropTypes.any),
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  footerHasFocus: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isActiveThreadType: PropTypes.bool.isRequired,
  isAssignedToMe: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewConversation: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  releasing: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  setInputHeight: PropTypes.func.isRequired,
  showAssignment: PropTypes.bool.isRequired,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsAreaMenuButtonClick: PropTypes.func.isRequired,
  doAppsAreaMinimize: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  doThreadVisibleFileRemove: PropTypes.func.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onConversationVisibleMessageFormTextChanged: PropTypes.func.isRequired,
  onReleaseThread: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired
};

export default VisibleThreadInteractionAreaComponent;
