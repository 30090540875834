import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import changePassword from './reducer/change-password-reducer';
import changePasswordEpic from './epic/change-password-epic';

export default () => {
  const mea = middleEndAccessors();

  const rootEpic = createRootEpic(changePasswordEpic(mea));

  const rootReducer = createRootReducer({
    changePassword
  });

  return {
    rootEpic,
    rootReducer
  };
};
