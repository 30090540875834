import PropTypes from 'prop-types';
import {ListItem} from 'material-ui/List';
import Elapsed from '../../common/elapsed-component';
import {getCustomerDisplayCompany} from '../../../../../shared/lib/display-data';
import UserAvatar from '../../common/avatar/user-avatar-component';
import {avatarSize, fontSize, fontWeight, spacing} from '../../../../../shared/style/variables';
import {darkYellow} from '../../../../../shared/style/colors';

const MentionRowComponent = ({doHeaderMentionsPopupItemClick, edge, i18n, muiTheme}) => {
  const STYLE_OVERFLOWABLE_SPAN = {wordBreak: 'break-word'};

  const {node} = edge;
  let customerDisplayName;
  let customerCompany;
  let mentionAuthor;
  let mentionDate;

  if (node.customer) {
    customerCompany = node.customer.company;
    customerDisplayName = node.customer.displayName;
    mentionAuthor = node.employee;
    mentionDate = node.date;
  } else {
    // Legacy
    customerCompany = node.customerThread.companyName;
    customerDisplayName = node.customerThread.title;
    mentionAuthor = node.sender;
    mentionDate = node.createdAt;
  }

  const onMentionItemClick = () => {
    doHeaderMentionsPopupItemClick(edge.cursor);
  };

  return (
    <ListItem
      data-test-id="list-item"
      innerDivStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        padding: '10px 10px 10px 0px',
        backgroundColor: node.isRead ? undefined : muiTheme.palette.selectedColor
      }}
      onClick={onMentionItemClick}
    >
      {/* useful only for test */}
      <span data-test-id="status" data-read-status={node.isRead ? 'read' : 'unread'} />

      <div
        style={{
          padding: `${spacing.medium} ${spacing.medium} ${spacing.medium} ${spacing.large}`
        }}
      >
        <UserAvatar size={avatarSize.medium} src={mentionAuthor.pictureHref} />
      </div>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        <p style={{fontSize: fontSize.medium}}>
          <span
            data-test-id="sender-display-name"
            style={{
              ...STYLE_OVERFLOWABLE_SPAN,
              fontWeight: 'bold'
            }}
          >
            {mentionAuthor.displayName}
          </span>
          <span>{` ${i18n.t('header.mentions.popup.mention')} `}</span>
          <span
            data-test-id="customer-display-name"
            style={{
              ...STYLE_OVERFLOWABLE_SPAN,
              fontWeight: fontWeight.semiBold
            }}
          >
            {customerDisplayName}
            {getCustomerDisplayCompany(customerCompany)}
          </span>
        </p>
        <p style={{fontSize: fontSize.medium, marginTop: '5px'}}>
          <span style={{color: darkYellow}}>@</span>{' '}
          <Elapsed i18nPrefix="header.mentions.popup" value={mentionDate} />
        </p>
      </div>
    </ListItem>
  );
};

MentionRowComponent.propTypes = {
  doHeaderMentionsPopupItemClick: PropTypes.func.isRequired,
  edge: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  muiTheme: PropTypes.objectOf(PropTypes.any).isRequired
};

export default MentionRowComponent;
