import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../shared/component/context';
import RetryableHeadedLayout from '../common/layout/retryable-headed-layout-component';
import {GLOBAL_ERROR_RETRY_DELAY} from '../../data/settings';

class GlobalErrorComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {doApplicationStateBootLoadRetry, isLoadingApp} = this.props;

    return (
      <RetryableHeadedLayout
        dataTestId="global-error"
        isRetrying={isLoadingApp}
        retryButtonLabel={i18n.t('globalError.refreshButton')}
        retryDelay={GLOBAL_ERROR_RETRY_DELAY}
        retryInText={i18n.t('globalError.youCanRetryIn')}
        text={i18n.t('globalError.errorMessage')}
        onClickRetry={doApplicationStateBootLoadRetry}
      />
    );
  }
}

GlobalErrorComponent.contextTypes = context;

GlobalErrorComponent.propTypes = {
  doApplicationStateBootLoadRetry: PropTypes.func.isRequired,
  isLoadingApp: PropTypes.bool.isRequired
};

export default GlobalErrorComponent;
