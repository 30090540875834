import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import LegacyMessagesRowBubbles from './legacy-messages-row-bubbles-component';
import OrganizationAvatar from '../avatar/organization-avatar-component';
import UserAvatar from '../avatar/user-avatar-component';
import {PADDING_THREAD} from '../../../data/settings';
import {lightSlate} from '../../../../../shared/style/colors';
import {avatarSize, fontSize} from '../../../../../shared/style/variables';

const LegacyMessagesRowComponent = (
  {
    automatic,
    canShowIncomingAvatar = true,
    canShowIncomingSenderName = false,
    doSendMessageRetry,
    doThreadFilePreviewOpen,
    messages,
    orgIcon,
    presentationType,
    senderName,
    threadType,
    userIcon
  },
  {i18n}
) => {
  const isOutgoing = presentationType.includes('Outgoing');
  const showAvatar = isOutgoing || canShowIncomingAvatar;
  const showSenderName = (isOutgoing && !automatic) || (!isOutgoing && canShowIncomingSenderName);

  const renderAvatar = () => {
    const messageAvatarSize = avatarSize.small;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-end',
          width: `${messageAvatarSize}px`,
          maxWidth: `${messageAvatarSize}px`
        }}
      >
        <div
          style={{
            position: 'relative',
            width: `${messageAvatarSize}px`,
            height: `${messageAvatarSize}px`
          }}
        >
          {automatic ? (
            <OrganizationAvatar
              dataTestId="organization-avatar"
              size={messageAvatarSize}
              src={orgIcon}
            />
          ) : (
            <UserAvatar
              organizationIconSize={avatarSize.xxsmall}
              size={messageAvatarSize}
              src={userIcon}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSenderName = () => (
    <div
      data-test-id="sender-name"
      style={{
        marginBottom: '2px',
        fontSize: fontSize.small,
        color: lightSlate
      }}
    >
      {senderName}
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isOutgoing ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
        width: '100%',
        padding: PADDING_THREAD
      }}
    >
      {showAvatar ? renderAvatar() : null}

      <div
        style={{
          maxWidth: '65%',
          margin: showAvatar ? '0 5px' : null,
          textAlign: isOutgoing ? 'right' : 'left'
        }}
      >
        {showSenderName ? renderSenderName() : null}

        <LegacyMessagesRowBubbles
          {...{
            doSendMessageRetry,
            doThreadFilePreviewOpen,
            i18n,
            messages,
            presentationType,
            threadType
          }}
        />
      </div>
    </div>
  );
};

LegacyMessagesRowComponent.contextTypes = context;

LegacyMessagesRowComponent.propTypes = {
  automatic: PropTypes.bool, // may not be present in few cases, for example when current employee is sending a message
  canShowIncomingAvatar: PropTypes.bool,
  canShowIncomingSenderName: PropTypes.bool,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  orgIcon: PropTypes.string,
  presentationType: PropTypes.string.isRequired,
  senderName: PropTypes.string, // may not be present in few cases, for example when an automatic message is received by socket
  threadType: PropTypes.string,
  userIcon: PropTypes.string
};

export default LegacyMessagesRowComponent;
