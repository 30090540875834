import PropTypes from 'prop-types';
import Radium from 'radium';
import Link from '../../../common/link/link-component';
import {blue, mediumGrey} from '../../../../../../shared/style/colors';

export const AdministrationLeftMenuItemLayout = ({children, style, ...props}) => (
  <li
    {...props}
    style={{
      borderRight: '3px solid white',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '3px 17px 3px 0',
      ...style
    }}
  >
    {children}
  </li>
);
AdministrationLeftMenuItemLayout.propTypes = {
  children: PropTypes.node.isRequired,
  'data-test-id': PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any)
};

const AdministrationLeftMenuItemComponent = Radium(
  ({dataTestId = 'item', href, i18n, isSelected, label, showLabelAdmin = false}) => {
    const layoutStyle = isSelected ? {borderRight: `3px solid ${blue}`} : {};
    const linkColor = isSelected ? blue : mediumGrey;
    const linkFontWeight = isSelected ? '600' : 'inherit';

    return (
      <AdministrationLeftMenuItemLayout data-test-id={dataTestId} style={layoutStyle}>
        <Link
          data-test-id="name"
          {...{href, label}}
          style={{
            ':hover': {
              color: blue
            },
            color: linkColor,
            fontSize: '14px',
            fontWeight: linkFontWeight,
            lineHeight: '1.4em',
            maxWidth: '75%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        />

        {showLabelAdmin ? (
          <span style={{color: '#bbb', fontSize: '10px', textTransform: 'uppercase'}}>
            {i18n.t('administration.leftPanel.adminLabel')}
          </span>
        ) : null}
      </AdministrationLeftMenuItemLayout>
    );
  }
);
AdministrationLeftMenuItemComponent.propTypes = {
  dataTestId: PropTypes.string,
  href: PropTypes.string.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any),
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  showLabelAdmin: PropTypes.bool
};

export default AdministrationLeftMenuItemComponent;
