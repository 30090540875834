import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import AdministrationOrganizationChannelsGbmComponent from '../../../../../component/content/administration/organization/channels/gbm/administration-organization-channels-gbm-component';
import {
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_REMOVE_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_SELECT,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_LAYOUT_MOUNTED,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_HANDLE_DRAFT_FIELDS
} from '../../../../../actions/administration/administration-organization-channels-gbm-actions';

const AdministrationOrganizationChannelsGbmContainer = connect(
  createSelector(
    [
      (state) => state.get('account'),
      (state) => state.getIn(['administration', 'organization', 'channels', 'gbm'])
    ],
    (account, administrationOrganizationChannelsGbm) => ({
      gbmState: administrationOrganizationChannelsGbm.toJS(),
      organizationId: account.get('organizationId')
    })
  ),
  {
    onAgentFormLogoRemoveButtonClick: () => ({
      type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_REMOVE_BUTTON_CLICK,
      _googleAnalytics: true
    }),
    onAgentFormLogoSelect: (file) => ({
      type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_SELECT,
      _googleAnalytics: true,
      payload: {
        file
      }
    }),
    onAgentFormSubmitConfirmButtonClick: (fields) => ({
      type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK,
      _googleAnalytics: true,
      payload: {
        fields
      }
    }),
    onAgentUpdateButtonClick: (fields) => ({
      type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK,
      _googleAnalytics: true,
      payload: {
        fields
      }
    }),
    onAgentValidationCancelConfirmButtonClick: () => ({
      type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK,
      _googleAnalytics: true
    }),
    onGbmLayoutMounted: () => ({
      type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_LAYOUT_MOUNTED
    }),
    onGbmHandleDraftFields: (draftFields) => {
      return {
        type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_HANDLE_DRAFT_FIELDS,
        payload: {
          draftFields
        }
      };
    }
  }
)(AdministrationOrganizationChannelsGbmComponent);

export default AdministrationOrganizationChannelsGbmContainer;
