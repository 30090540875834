import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import {grey200} from 'material-ui/styles/colors';
import context from '../../../../../shared/component/context';
import propTypeOptionalUrl from '../../../../../shared/component/lib/prop-type-optional-url';
import {avatarSize, radius} from '../../../../../shared/style/variables';

class OrganizationAvatarComponent extends PureComponent {
  render() {
    const {dataTestId, name, size, src, style} = this.props;

    return src ? (
      <img
        data-test-id={dataTestId}
        src={src}
        alt={name}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: radius.medium,
          ...style
        }}
      />
    ) : (
      <div
        data-test-id={dataTestId}
        title={name}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: grey200,
          ...style
        }}
      >
        <FontIcon className="material-icons">business</FontIcon>
      </div>
    );
  }
}

OrganizationAvatarComponent.contextTypes = context;

OrganizationAvatarComponent.defaultProps = {
  size: avatarSize.small
};

OrganizationAvatarComponent.propTypes = {
  dataTestId: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  src: propTypeOptionalUrl,
  style: PropTypes.objectOf(PropTypes.any)
};

export default OrganizationAvatarComponent;
