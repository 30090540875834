import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import OutlinedButton from '../../../../../../shared/component/button/outlined-button-component';
import {black, darkGrey} from '../../../../../../shared/style/colors';

const AdministrationDialogComponent = ({
  confirmColor,
  labels,
  opened,
  onClickCancel,
  onClickConfirm
}) => {
  const StyledDialog = withStyles({
    paper: {
      maxWidth: '500px',
      padding: '12px 5px'
    }
  })(Dialog);

  const StyledDialogTitle = withStyles({
    root: {
      fontSize: '15px',
      fontWeight: 700
    }
  })(DialogTitle);

  const StyledDialogContentText = withStyles({
    root: {
      color: black,
      fontSize: '14px',
      margin: 0
    }
  })(DialogContentText);

  const StyledDialogActions = withStyles({
    root: {
      padding: '16px 24px'
    }
  })(DialogActions);

  const renderTitle = () => (
    <StyledDialogTitle disableTypography id="customized-dialog-title">
      {labels.title}
    </StyledDialogTitle>
  );

  const renderContent = () => (
    <DialogContent>
      <StyledDialogContentText dangerouslySetInnerHTML={{__html: labels.contentText}} />
    </DialogContent>
  );

  const renderButtons = () => (
    <StyledDialogActions>
      <OutlinedButton color={darkGrey} data-test-id="cancel-button" onClick={onClickCancel}>
        {labels.cancelButton}
      </OutlinedButton>

      <OutlinedButton color={confirmColor} data-test-id="confirm-button" onClick={onClickConfirm}>
        {labels.confirmButton}
      </OutlinedButton>
    </StyledDialogActions>
  );

  return (
    <StyledDialog data-test-id="dialog-container" open={opened}>
      {renderTitle()}
      {renderContent()}
      {renderButtons()}
    </StyledDialog>
  );
};

AdministrationDialogComponent.propTypes = {
  confirmColor: PropTypes.string,
  labels: PropTypes.objectOf(PropTypes.any).isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired
};

export default AdministrationDialogComponent;
