import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {lineHeight} from '../../../../../shared/style/variables';
import {blue, lightBlue, white} from '../../../../../shared/style/colors';

const BUTTON_TEXT_PADDING = '50px';

const StyledButton = withStyles({
  root: {
    backgroundColor: white,
    cursor: 'pointer',
    paddingLeft: BUTTON_TEXT_PADDING,
    paddingRight: BUTTON_TEXT_PADDING,
    '&:hover': {
      backgroundColor: lightBlue
    }
  }
})(Button);

const TutorialTooltipComponent = ({closeProps, step, tooltipProps}) => {
  const mainContainerStyles = {
    backgroundColor: blue,
    borderRadius: '24px',
    color: white,
    fontSize: '16px',
    lineHeight: lineHeight.large,
    maxWidth: '520px',
    minWidth: '390px',
    padding: '44px 48px',
    width: '520px'
  };

  const titleStyles = {
    margin: 0,
    textAlign: 'center'
  };

  const contentStyles = {
    margin: '28px 0',
    padding: 0,
    textAlign: 'center'
  };

  const buttonContainerStyles = {
    display: 'flex',
    justifyContent: 'center'
  };

  const {closeButton, content, title} = step;

  return (
    <div {...tooltipProps} style={mainContainerStyles} data-test-id="tutorial-tooltip">
      {title && <h2 style={titleStyles}>{title}</h2>}

      <p style={contentStyles} dangerouslySetInnerHTML={{__html: content}} />

      <div style={buttonContainerStyles}>
        <StyledButton color="primary" {...closeProps} data-test-id="close-button">
          {closeButton}
        </StyledButton>
      </div>
    </div>
  );
};

TutorialTooltipComponent.propTypes = {
  closeProps: PropTypes.objectOf(PropTypes.any).isRequired,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  tooltipProps: PropTypes.objectOf(PropTypes.any).isRequired
};

export default TutorialTooltipComponent;
