import emojiDataSourceLib from 'emoji-datasource';
import emojiUnicode from 'emoji-unicode';
import '../../../../../shared/component/emoji-mart/emoji-mart-custom.css';

const emojiDataSource = {};
emojiDataSourceLib.forEach((e) => {
  const data = {...e};
  delete data.skin_variations;

  if (e.unified && e.short_name) {
    const indexName = e.unified.toLowerCase();
    data.mart_name = `:${e.short_name}:`;
    emojiDataSource[indexName] = data;
  }

  if (e.skin_variations && Object.values(e.skin_variations).length) {
    Object.values(e.skin_variations).forEach((sv, index) => {
      const skinKey = Object.keys(e.skin_variations)[index];
      const skinName = {
        b: 'skin-tone-2',
        c: 'skin-tone-3',
        d: 'skin-tone-4',
        e: 'skin-tone-5',
        f: 'skin-tone-6'
      }[skinKey.toLowerCase().substring(skinKey.length - 1)];
      const skinData = {...data};
      skinData.mart_name = `:${e.short_name}::${skinName}:`;
      const indexName = sv.unified.toLowerCase();
      emojiDataSource[indexName] = skinData;

      return emojiDataSource[indexName];
    });
  }
});

// special emojies
const _normalizeEmojiCode = (emojiCode) => {
  if ([7, 12].indexOf(emojiCode.length) > -1) {
    return `00${emojiCode}`;
  }

  return emojiCode;
};

// In case of upgrade of package "emoji-mart", this png should be updated.
// To get the new version of it, just comment usage of this function and check browser request to load it from cdn "unpkg.com"
const backgroundImageFn = () => `${ASSETS_PATH}/ext/emoji-mart/apple-emojis.png`;

const getEmojiCode = (emoji) => {
  const emojiCode = [...emoji]
    .map((ce) => emojiUnicode(ce))
    .filter((e) => e && e !== 'NaN')
    .join('-');

  return _normalizeEmojiCode(emojiCode);
};

const getEmojiColons = (emojiCode) => {
  let fromDataSource = emojiDataSource[emojiCode];

  if (!fromDataSource) {
    fromDataSource = emojiDataSource[`${emojiCode}-fe0f`];

    if (!fromDataSource) {
      return null;
    }
  }

  return fromDataSource.mart_name;
};

export {backgroundImageFn, getEmojiCode, getEmojiColons};
