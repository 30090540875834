import ReactDomServer from 'react-dom/server';
import simpleTruncate from './truncate';
import {fontWeight} from '../style/variables';

export default (text, dataTestId, maxLength = 20) => {
  return ReactDomServer.renderToStaticMarkup(
    <span data-test-id={dataTestId} style={{fontWeight: fontWeight.semiBold}}>
      {simpleTruncate(text, maxLength)}
    </span>
  );
};
