import PropTypes from 'prop-types';
import {spacing} from '../../../../../../shared/style/variables';
import {lightSmoke} from '../../../../../../shared/style/colors';

const ThreadChannelFooterLayoutComponent = ({children, ...otherProps}) => (
  <div
    {...otherProps}
    style={{
      alignItems: 'center',
      borderTop: `1px solid ${lightSmoke}`,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '120px',
      justifyContent: 'space-around',
      lineHeight: '18px',
      padding: `${spacing.medium} ${spacing.xxxlarge}`,
      textAlign: 'center'
    }}
  >
    {children}
  </div>
);

ThreadChannelFooterLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  'data-test-id': PropTypes.string.isRequired
};

export default ThreadChannelFooterLayoutComponent;
