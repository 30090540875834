import {EditorState} from 'draft-js';
import attachImmutableEntitiesToEmojis from './attachImmutableEntitiesToEmojis';
import Emoji from '../../../emoji/emoji-component';
import findWithRegex from './find-with-regex';

export default () => {
  const callbacks = {
    onChange: undefined
  };

  return {
    decorators: [
      {
        strategy: findWithRegex,
        component: Emoji
      }
    ],

    onChange: (editorState) => {
      let newEditorState = attachImmutableEntitiesToEmojis(editorState);

      if (!newEditorState.getCurrentContent().equals(editorState.getCurrentContent())) {
        // Forcing the current selection ensures that it will be at it's right place.
        // This solves the issue where inserting an Emoji on OSX with Apple's Emoji
        // selector led to the right selection the data, but wrong position in
        // the contenteditable.
        newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
      }

      if (callbacks.onChange) {
        return callbacks.onChange(newEditorState);
      }

      return newEditorState;
    }
  };
};
