import {Chip} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {fontSize, spacing} from '../style/variables';
import {black, white} from '../style/colors';

const StyledComponent = withStyles({
  root: {
    marginLeft: spacing.small,
    fontSize: fontSize.small,
    backgroundColor: black,
    color: white
  },
  outlined: {
    backgroundColor: 'inherit',
    color: black
  },
  clickable: {
    backgroundColor: white
  }
})(Chip);

export default (props) => <StyledComponent {...props} />;
