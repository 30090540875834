import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import AdministrationOrganizationChannelsComponent from '../../../../../component/content/administration/organization/channels/administration-organization-channels-component';

const AdministrationOrganizationChannelsContainer = connect(
  createSelector(
    [(state) => state.get('account'), (state) => state.get('featureToggle')],
    (account, featureToggle) => ({
      enableBusinessMessages: featureToggle.get('enable_business_messages'),
      isOrganizationAdministrator: account.get('manager')
    })
  )
)(AdministrationOrganizationChannelsComponent);

export default AdministrationOrganizationChannelsContainer;
