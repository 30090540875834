import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import {MuiThemeProvider} from '@material-ui/core/styles';
import context from '../../../../../../../shared/component/context';
import themeV1 from '../../../../../../../shared/style/theme-v1';
import AdministrationOrganizationChannelsGbmContainer from '../../../../../container/content/administration/organization/channels/administration-organization-channels-gbm-container';
import AdministrationRightPanelLayout from '../../layout/administration-right-panel-layout-component';
import EnhancedSwitch, {Error404} from '../../../../common/enhanced-switch-component';
import {StyledTab, StyledTabs} from '../../../../../../../shared/style/material-ui/tab-styles';
import {ADMINISTRATION_ORGANIZATION_CHANNELS} from '../../../../../data/administration/administration-organization-data';

const AdministrationOrganizationChannelsComponent = (
  {enableBusinessMessages, isOrganizationAdministrator, match},
  {i18n}
) => {
  if (!isOrganizationAdministrator) {
    return <Error404 />;
  }

  // Because for now this component handles only 1 channel, if this unique channel's FT is disabled then we prefer to not render at all the page
  if (!enableBusinessMessages) {
    return <Error404 />;
  }

  const renderTabs = () => {
    const tabLabel = (
      <span data-test-id="label">
        {i18n.t('administration.organization.channels.flows.gbm.tabLabel')}
      </span>
    );

    return (
      <MuiThemeProvider theme={themeV1}>
        <StyledTabs data-test-id="channels-tabs" value="GBM">
          <StyledTab
            data-test-id="item-container-and-selected-state"
            label={tabLabel}
            style={{marginLeft: 0}}
            value="GBM"
          />
        </StyledTabs>
      </MuiThemeProvider>
    );
  };

  const renderActiveTabContent = () => (
    <div>
      <EnhancedSwitch>
        <Redirect
          exact
          from={match.url}
          to={ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.desktopAdminPageUrl}
        />
        <Route
          component={AdministrationOrganizationChannelsGbmContainer}
          exact
          path={ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.desktopAdminPageUrl}
        />
      </EnhancedSwitch>
    </div>
  );

  return (
    <AdministrationRightPanelLayout
      contentStyle={{paddingTop: 0}}
      data-test-id="organization-channels-right-panel"
      pageTitle={i18n.t('administration.organization.channels.title')}
    >
      {renderTabs()}
      {renderActiveTabContent()}
    </AdministrationRightPanelLayout>
  );
};

AdministrationOrganizationChannelsComponent.contextTypes = context;

AdministrationOrganizationChannelsComponent.propTypes = {
  enableBusinessMessages: PropTypes.bool.isRequired,
  isOrganizationAdministrator: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationOrganizationChannelsComponent;
