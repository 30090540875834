import PropTypes from 'prop-types';
import ResizeObserver from 'rc-resize-observer';
import {lightSmoke} from '../../../../../shared/style/colors';

const ThreadInteractionAreaComponent = ({children, onClick, onResize, style, ...otherProps}) => (
  <ResizeObserver {...{onResize}}>
    <div
      data-test-id="interactions"
      {...otherProps}
      style={{
        borderTop: `1px solid ${lightSmoke}`,
        ...style
      }}
      {...{
        onClick
      }}
    >
      {children}
    </div>
  </ResizeObserver>
);
ThreadInteractionAreaComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onResize: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default ThreadInteractionAreaComponent;
