import addRedirectionMessage from '../../../../lib/add-redirection-message';
import {checkMessageForm} from '../../../../lib/message-form-helper';
import {resetNotPersistedState} from '../../../../lib/persistence-helper';

/**
 * Init a customer thread new.
 * @param {Object} state
 * @param {Object} defaultState
 * @param {String} businessName
 * @returns {Object}
 */
const initCustomerThreadNew = (state, defaultState, businessName) =>
  checkMessageForm(
    addRedirectionMessage(
      resetNotPersistedState(state, defaultState)
        .set('loading', true)
        .set('participationId', 'new'),
      businessName
    )
  );

/**
 * Finish to load a customer thread new.
 * @param {Object} state
 * @returns {Object}
 */
const customerThreadNewLoaded = (state) => state.set('loading', false);

export {customerThreadNewLoaded, initCustomerThreadNew};
