import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  DEFAULT_STATE_WIDGET_COMMON,
  errorInvalidEmailChange,
  errorInvalidPhoneChange,
  inputPlaceHolderEmailChange,
  inputPlaceHolderPhoneChange,
  languageChange,
  snippetChange,
  snippetCopiedChange,
  thankYouMessageChange
} from '../../../../lib/widget-helper';
import {
  INTEGRATION_AREA_WIDGET_CTA_ACTION_BTN_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_COMMUNICATION_METHOD_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_RESET_STATE,
  INTEGRATION_AREA_WIDGET_CTA_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_THANKYOU_MESSAGE_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_WIDGET_COMMON,
  actionBtn: '',
  businessId: '',
  communicationMethod: '',
  contactChoice: true,
  defaultContact: 'email',
  errorInvalidEmail: '',
  errorInvalidPhone: '',
  inputPlaceholder: '',
  inputPlaceholderEmail: '',
  inputPlaceholderPhone: '',
  thankYouMessage: ''
};

export default createReducer(DEFAULT_STATE, {
  // Common
  [INTEGRATION_AREA_WIDGET_CTA_LANGUAGE_CHANGE]: languageChange,

  [INTEGRATION_AREA_WIDGET_CTA_SNIPPET_CHANGE]: snippetChange,

  [INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE]: snippetCopiedChange,

  // Specific
  [INTEGRATION_AREA_WIDGET_CTA_RESET_STATE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  [INTEGRATION_AREA_WIDGET_CTA_BUSINESS_CHANGE]: (state, {businessId}) => {
    return state.set('businessId', businessId);
  },

  [INTEGRATION_AREA_WIDGET_CTA_COMMUNICATION_METHOD_CHANGE]: (state, {method}) => {
    return state.set('communicationMethod', method).set('defaultContact', method || 'email');
  },

  [INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_CHANGE]: (state, {text}) => {
    return state.set('inputPlaceholder', text);
  },

  [INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_EMAIL_CHANGE]: errorInvalidEmailChange,

  [INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_PHONE_CHANGE]: errorInvalidPhoneChange,

  [INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_EMAIL_CHANGE]: inputPlaceHolderEmailChange,

  [INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_PHONE_CHANGE]: inputPlaceHolderPhoneChange,

  [INTEGRATION_AREA_WIDGET_CTA_THANKYOU_MESSAGE_CHANGE]: thankYouMessageChange,

  [INTEGRATION_AREA_WIDGET_CTA_ACTION_BTN_CHANGE]: (state, {selector}) => {
    return state.set('actionBtn', selector);
  }
});
