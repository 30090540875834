import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {validateAndForwardFile} from '../../../../../shared/lib/file-upload-helper';

const FileSelectorComponent = ({
  clickableElement,
  extensionsSupported,
  onClickAttachFile,
  onSelectFile
}) => {
  const fileInputAccept = extensionsSupported ? extensionsSupported.join(',') : null;
  const fileInputRef = useRef(null);

  function _onFileInputChange() {
    const [firstFile] = fileInputRef.current.files;

    validateAndForwardFile(firstFile, onSelectFile);
  }

  function _simulateClickOnFileInput() {
    fileInputRef.current.click();

    if (onClickAttachFile) {
      onClickAttachFile();
    }
  }

  return (
    <React.Fragment>
      {clickableElement(_simulateClickOnFileInput)}
      <input
        accept={fileInputAccept}
        onChange={_onFileInputChange}
        ref={fileInputRef}
        style={{
          display: 'none'
        }}
        type="file"
      />
    </React.Fragment>
  );
};

FileSelectorComponent.propTypes = {
  clickableElement: PropTypes.func.isRequired,
  extensionsSupported: PropTypes.arrayOf(PropTypes.any),
  onClickAttachFile: PropTypes.func,
  onSelectFile: PropTypes.func.isRequired
};

export default FileSelectorComponent;
