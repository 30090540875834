import React from 'react';
import LinkifyIt from 'linkify-it';
import PropTypes from 'prop-types';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

class LinkifyComponent extends React.PureComponent {
  static IGNORE = /instap.ly/;

  static MATCH = 'LINKIFY_MATCH';

  parseCounter = 0;

  _parseString(stringToParse) {
    const {textColor} = this.props;
    if (stringToParse === '') {
      return [];
    }

    const matches = linkify.match(stringToParse);
    if (!matches) {
      return stringToParse;
    }

    const TAG_PROPERTIES = {
      target: '_blank',
      style: {
        color: textColor
      }
    };
    const TAG_PROPERTIES_KEYS = Object.keys(TAG_PROPERTIES);

    const elements = [];
    let lastIndex = 0;

    matches
      .filter(({text}) => !text.match(LinkifyComponent.IGNORE))
      .forEach((match, idx) => {
        // Push the preceding text if there is any
        if (match.index > lastIndex) {
          elements.push(stringToParse.substring(lastIndex, match.index));
        }

        // Shallow update values that specified the match
        const props = {
          href: match.url,
          key: `parse${this.parseCounter}match${idx}`
        };

        TAG_PROPERTIES_KEYS.forEach((key) => {
          let val = TAG_PROPERTIES[key];
          if (val === LinkifyComponent.MATCH) {
            val = match.url;
          }

          props[key] = val;
        });

        elements.push(<a {...props}>{match.text}</a>);

        lastIndex = match.lastIndex;
      });

    if (lastIndex < stringToParse.length) {
      elements.push(stringToParse.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  }

  _parse(children) {
    let parsed = children;

    if (typeof children === 'string') {
      parsed = this._parseString(children);
    } else if (
      React.isValidElement(children) &&
      children.type !== 'a' &&
      children.type !== 'button'
    ) {
      this.parseCounter += 1;
      parsed = React.cloneElement(
        children,
        {key: `parse${this.parseCounter}`},
        this._parse(children.props.children)
      );
    } else if (children instanceof Array) {
      parsed = children.map((child) => this._parse(child));
    }

    return parsed;
  }

  render() {
    const {children, className, dataTestId, style} = this.props;

    this.parseCounter = 0;

    return (
      <span
        data-test-id={dataTestId}
        {...{
          className,
          style
        }}
      >
        {this._parse(children)}
      </span>
    );
  }
}

LinkifyComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  textColor: PropTypes.string.isRequired
};

export default LinkifyComponent;
