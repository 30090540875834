/**
 * Find index of a specific message in a collection of conversation items,
 * comparing by clientItemId if possible, else by id.
 * @param {Immutable.Map} conversationState
 * @param {Object} message
 * @returns {Number}
 */
const findMessageIndexInConversationItem = (conversationState, message) =>
  conversationState.getIn(['conversationItems', 'edges']).findIndex((edge) => {
    const messageNode = edge.get('node');

    if (message.clientItemId) {
      const nodeClientItemId = messageNode.get('clientItemId');

      if (nodeClientItemId) {
        return nodeClientItemId === message.clientItemId;
      }
    }

    if (!message.id) {
      return false;
    }

    return messageNode.get('id') === message.id;
  });

// eslint-disable-next-line import/prefer-default-export
export {findMessageIndexInConversationItem};
