import PropTypes from 'prop-types';
import {useState} from 'react';
import CircularProgress from 'material-ui/CircularProgress';

const ImageComponent = ({containerStyle, imgStyle, loaderColor, ...otherProps}) => {
  const [loaded, setLoaded] = useState(false);

  const markAsLoaded = () => {
    setLoaded(true);
  };

  return (
    <span style={{display: 'flex', ...containerStyle}}>
      <img
        alt=""
        onLoad={markAsLoaded}
        style={{
          display: loaded ? 'block' : 'none',
          maxHeight: '100%',
          maxWidth: '100%',
          ...imgStyle
        }}
        {...otherProps}
      />
      {loaded ? null : <CircularProgress data-test-id="loading-image" color={loaderColor} />}
    </span>
  );
};

ImageComponent.propTypes = {
  'data-test-id': PropTypes.string.isRequired,
  containerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  imgStyle: PropTypes.objectOf(PropTypes.any),
  loaderColor: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired
};

export default ImageComponent;
