import {jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Get query to load administration GBM business location.
 * @returns {String}
 */
const graphqlGetBusinessLocationQuery = (businessId) => {
  const query = {
    query: {
      businessLocation: {
        __args: {
          businessId
        },
        status: true
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

/**
 * Get mutation to create a GB business location.
 * @param {Object} graphqlParams
 * @returns {String}
 */
const graphqlCreateBusinessLocation = (graphqlParams) => {
  const mutation = {
    mutation: {
      createBusinessGbmLocation: {
        __args: graphqlParams,
        status: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Get mutation to disconnect a GB business location.
 * @param {String} businessId
 * @returns {String}
 */
const graphqlDisconnectBusinessLocation = (businessId) => {
  const mutation = {
    mutation: {
      unlaunchBusinessGbmLocation: {
        __args: {businessId},
        status: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

export {
  graphqlGetBusinessLocationQuery,
  graphqlCreateBusinessLocation,
  graphqlDisconnectBusinessLocation
};
