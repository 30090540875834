import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DoneIcon from 'material-ui/svg-icons/action/done';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import ErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import context from '../../../../../shared/component/context';
import SelectionItem from './global-invitation-selection-item-component';
import UserAvatar from '../../common/avatar/user-avatar-component';
import {avatarSize} from '../../../../../shared/style/variables';
import {green, lightSlate, red} from '../../../../../shared/style/colors';

class ColleaguesSelectionComponent extends PureComponent {
  _getOrganizationDomainColor = (colleague) => {
    return colleague.isOrganizationDomain ? null : red;
  };

  _renderOrganizationDomainAvatar = ({colleague, color, iconStyle}) => {
    return colleague.isOrganizationDomain ? (
      <EmailIcon color={color} style={iconStyle} />
    ) : (
      <ErrorOutline color={color} style={iconStyle} />
    );
  };

  _renderColleagueChecked = (colleague) => {
    return colleague.checked ? null : (
      <DoneIcon color={green} style={{position: 'relative', top: '-5px'}} />
    );
  };

  _renderOrganizationDomainColleagueStatus = ({colleague, color}) => {
    const {i18n} = this.context;

    return colleague.isOrganizationDomain ? (
      this._renderColleagueChecked(colleague)
    ) : (
      <span
        style={{
          whiteSpace: 'nowrap',
          color
        }}
      >
        {i18n.t('colleagueInvitation.popup.colleagues.domainNotAvailable')}
      </span>
    );
  };

  render() {
    const {i18n} = this.context;
    const {
      colleaguesSelection,
      organizationPicture,
      doRemoveColleagueInvitationPopupSelectedColleague
    } = this.props;

    const itemsCount = colleaguesSelection.length;
    const iconSize = avatarSize.xsmall;
    const iconStyle = {width: iconSize, height: iconSize};

    return (
      <ul>
        {colleaguesSelection.map((colleague, key) => {
          const color = colleague.isRegistered
            ? lightSlate
            : this._getOrganizationDomainColor(colleague);
          const avatarStylesFixes = {
            [organizationPicture ? 'marginLeft' : undefined]: '3px'
          };

          return (
            <SelectionItem
              dataTestId="colleague"
              key={String(key)}
              color={color}
              index={key}
              item={colleague}
              itemsCount={itemsCount}
              onDeleteClick={() =>
                doRemoveColleagueInvitationPopupSelectedColleague(colleague.email)
              }
              avatar={
                colleague.isRegistered ? (
                  <UserAvatar
                    src={colleague.pictureHref}
                    organizationIconSrc={organizationPicture}
                    size={iconSize}
                    style={avatarStylesFixes}
                    userIconStyle={avatarStylesFixes}
                    organizationIconStyle={{
                      [organizationPicture ? 'left' : undefined]: 0,
                      bottom: '12px'
                    }}
                  />
                ) : (
                  this._renderOrganizationDomainAvatar({colleague, color, iconStyle})
                )
              }
              status={
                colleague.isRegistered ? (
                  <span style={{whiteSpace: 'nowrap'}}>
                    {i18n.t('colleagueInvitation.popup.colleagues.onInstaply')}
                  </span>
                ) : (
                  this._renderOrganizationDomainColleagueStatus({colleague, color})
                )
              }
            />
          );
        })}
      </ul>
    );
  }
}

ColleaguesSelectionComponent.contextTypes = context;

ColleaguesSelectionComponent.propTypes = {
  colleaguesSelection: PropTypes.arrayOf(PropTypes.any).isRequired,
  organizationPicture: PropTypes.string,
  doRemoveColleagueInvitationPopupSelectedColleague: PropTypes.func
};

export default ColleaguesSelectionComponent;
