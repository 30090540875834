import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import kebabCase from 'lodash/kebabCase';
import context from '../../../../../../../shared/component/context';
import {formatConnectionTime} from '../../../../../lib/date-time-helper';
import EmployeeListItem from '../../../../common/employee/employee-list-item-layout-component';
import ErrorMessage from '../../../../common/error-message-component';
import InsightContentHeader from '../../common/layout/content-header-component';
import InsightPageComponent from '../../common/insight-page-component';
import PanelContentLayout from '../../../../common/layout/panel-content-layout-component';
import Paper from '../../../../../../../shared/component/paper-component';
import RightPanelContentLayout from '../../../../common/right-panel-content-layout-component';
import UserAvatar from '../../../../common/avatar/user-avatar-component';
import {avatarSize, fontSize, spacing} from '../../../../../../../shared/style/variables';
import {slate} from '../../../../../../../shared/style/colors';
import {ConditionalLoadingIndicator} from '../insight-wating-customers-component';

const INSIGHT_PAGE = 'contactCentersWebConnectedUsers';

const COLUMN_CONTAINER_STYLE = {
  position: 'relative',
  minHeight: '130px'
};

const StyledPaper = withStyles({
  notLast: {
    marginBottom: spacing.mlarge
  }
})(({children, classes, isLast}) => (
  <Paper className={isLast ? null : classes.notLast}>{children}</Paper>
));

const EmployeeInsightColumn = ({label, property, value}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      color: slate
    }}
  >
    <span
      data-test-id={kebabCase(property)}
      style={{
        marginBottom: spacing.medium,
        fontSize: fontSize.xxxlarge
      }}
    >
      {value}
    </span>
    {label}
  </div>
);

EmployeeInsightColumn.propTypes = {
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

/**
 * Finally, the component.
 */
class InsightContactCentersWebConnectedUsersComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      insight,
      insightBusinessFilter,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch
    } = this.props;

    const {webConnectedUsers} = insight;
    const {employeesRealTime, loading} = webConnectedUsers;
    const employeesRealTimeLastIndex = employeesRealTime.length - 1;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            insightBusinessFilter,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch
          }}
        />

        <PanelContentLayout>
          {webConnectedUsers.failed ? (
            <ErrorMessage style={{height: '100%'}} />
          ) : (
            <div
              data-test-id="employees-web-connected-users-list"
              style={{
                ...COLUMN_CONTAINER_STYLE
              }}
            >
              <ConditionalLoadingIndicator {...{loading}} />

              {employeesRealTime.map((employee, index) => {
                return (
                  <StyledPaper isLast={employeesRealTimeLastIndex === index} key={employee.id}>
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <EmployeeListItem
                        avatar={
                          <UserAvatar
                            data-test-id="avatar"
                            isConnected={employee.isConnected}
                            size={avatarSize.medium}
                            src={employee.imageUrl}
                            title={employee.displayName}
                          />
                        }
                        displayName={employee.displayName}
                        emailAddress={employee.email}
                        textMaxWidth="calc(100% - 40px)"
                      >
                        <div style={{display: 'flex', marginTop: '17px'}}>
                          <EmployeeInsightColumn
                            label={i18n.t(
                              `insight.pages.${INSIGHT_PAGE}.employeesRealTime.columns.connectionTime`
                            )}
                            property="connectionTime"
                            value={formatConnectionTime(employee.connectionTime)}
                          />
                          <EmployeeInsightColumn
                            label={i18n.t(
                              `insight.pages.${INSIGHT_PAGE}.employeesRealTime.columns.handledContacts`
                            )}
                            property="handledContacts"
                            value={employee.handledContacts}
                          />
                        </div>
                      </EmployeeListItem>
                    </div>
                  </StyledPaper>
                );
              })}
            </div>
          )}
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightContactCentersWebConnectedUsersComponent.contextTypes = context;

InsightContactCentersWebConnectedUsersComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired
};

export default InsightContactCentersWebConnectedUsersComponent;
