/**
 * Retrieve the error translation for a GraphQL error key.
 * If no translation matches the key, returns a default message.
 *
 * @param {Array|Object} errors
 * @param {String} i18nPrefix
 * @param {Object} i18n
 * @returns {String} the error message
 */
export default (errors, i18nPrefix, i18n) => {
  if (Array.isArray(errors) && !errors.length) {
    return null;
  }

  let errorMessage;

  const errorKey = errors[0];

  if (typeof errorKey === 'string') {
    const messagePath = `${i18nPrefix}.${errorKey}`;

    errorMessage = i18n.t(messagePath);

    if (errorMessage === messagePath) {
      errorMessage = null;
    }
  }

  return errorMessage || i18n.t('common.errorDefault');
};
