import {of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import getStandardParamsFromState from './get-standard-params-from-state';
import prepareRequestParams from './prepare-request-params';

const loadGraphInformation = (
  action$,
  state$,
  graphql,
  {
    customResponsePayload,
    loadAction,
    failureAction,
    successAction,
    onSuccess,
    query,
    DEBOUNCE_TIME_STATISTIC,
    TIMEOUT_REQUEST_LONG
  }
) => {
  return action$.pipe(
    ofType(loadAction),
    debounceTime(DEBOUNCE_TIME_STATISTIC),
    mergeMap((action) => {
      return graphql(
        `query { summaryPageHistogram { ${query} } }`,
        prepareRequestParams(state$, action),
        {timeout: TIMEOUT_REQUEST_LONG}
      ).pipe(
        map(
          onSuccess ||
            (({summaryPageHistogram}) => {
              return {
                type: successAction,
                [customResponsePayload]: summaryPageHistogram
              };
            })
        ),
        catchError((error) => {
          return of({
            type: failureAction,
            error
          });
        })
      );
    })
  );
};

const loadInitialInsights = (
  action$,
  state$,
  {actionsName, loadAction, loadRankingAction, rankingStateParamName}
) => {
  return action$.pipe(
    ofType(...actionsName),
    mergeMap(() => {
      const params = getStandardParamsFromState(state$, rankingStateParamName);
      const initialActions = [
        {
          type: loadAction,
          ...params
        }
      ];

      if (loadRankingAction && state$.value.getIn(['insight', 'periodTabSelected']) === 'classic') {
        initialActions.push({
          type: loadRankingAction,
          ...params
        });
      }

      return initialActions;
    })
  );
};

export {loadGraphInformation, loadInitialInsights};
