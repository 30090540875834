import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  addEventuallyMessageInConversation,
  failSendingMessageInConversation,
  startSendingMessageInConversation,
  succeedSendingMessageInConversation
} from '../../../lib/conversation-item-helper';
import {
  DEFAULT_STATE_THREAD,
  DEFAULT_STATE_THREAD_FORM,
  checkMessageForm,
  failUploadFile,
  persistMessageFormText,
  removeUploadedFile,
  startUploadFile,
  succeedUploadFile
} from '../../../lib/message-form-helper';
import {deletePersistedState, resetNotPersistedState} from '../../../lib/persistence-helper';
import {APP_LOGOUT} from '../../../../actions/app-actions';
import {
  CUSTOMER_CONVERSATION_INTERNAL_FILE_REMOVE_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD,
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_FAILURE,
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_SUCCESS,
  CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_PERSIST,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_SUCCESS,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_FAILURE,
  CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION,
  CUSTOMER_THREAD_INVISIBLE_LOAD,
  CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-invisible-actions';
import {
  EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_FROM_ME,
  EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME
} from '../../../../actions/ext-actions';
import {
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS
} from '../../../../actions/customer-new-thread-actions';
import {CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD} from '../../../../actions/customer-thread-actions';
import {CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE} from '../../../../actions/customer-thread-invisible-mention-popup-actions';
import {
  CONVERSATION_ITEM_TYPES,
  CONVERSATION_MESSAGES_LOAD_DIRECTIONS
} from '../../../../data/thread/message';
import {ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER} from '../../../../actions/router-actions';

const DEFAULT_STATE_CONVERSATION_INTERNAL_FORM = {
  ...DEFAULT_STATE_THREAD_FORM,
  ...{
    userToMention: null
  }
};

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_THREAD,
  ...{
    hasMoreMessagesAfter: false,
    id: null,
    loadingAfter: false,
    conversationItems: {
      edges: [],
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false
      }
    }
  }
};

const updateUserToMention = (state, data) => {
  const userToMention =
    data && data.payload && data.payload.user ? fromJS(data.payload.user) : null;

  return checkMessageForm(state).setIn(
    ['threadsForm', state.get('id'), 'userToMention'],
    userToMention
  );
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Clean storage on logout.
   */
  [APP_LOGOUT]: deletePersistedState,

  /**
   * Message form handling.
   */
  [CUSTOMER_CONVERSATION_INTERNAL_FILE_REMOVE_BUTTON_CLICK]: removeUploadedFile,
  [CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD]: startUploadFile,
  [CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_FAILURE]: failUploadFile,
  [CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD_SUCCESS]: succeedUploadFile,

  [CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_PERSIST]: persistMessageFormText,

  /**
   * Start to load a customer invisible thread in some direction.
   * @param {Immutable.Map} state
   * @param {String} direction
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_INVISIBLE_LOAD]: (state, {direction}) => {
    const {AFTER, BEFORE} = CONVERSATION_MESSAGES_LOAD_DIRECTIONS;

    return state
      .set('direction', direction)
      .set('loading', ![AFTER, BEFORE].includes(direction))
      .set('loadingAfter', direction === AFTER)
      .set('loadingBefore', direction === BEFORE);
  },

  /**
   * Triggered when the thread has successfully ended to load from the server.
   * Could be a partial messages part to add to current (loadingAfter / loadingBefore)
   * or a new one (loading).
   * @param {Immutable.Map} state
   * @param {Object} conversation
   * @param {String} direction
   * @param {String} messageCursor
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS]: (
    state,
    {
      payload: {
        data: {conversation},
        params: {direction, messageCursor}
      }
    }
  ) => {
    const {AFTER, AROUND, BEFORE} = CONVERSATION_MESSAGES_LOAD_DIRECTIONS;

    const newConversationItems = fromJS(conversation.conversationItems);
    const newStateCommon = state
      .set('direction', direction)
      .set('loading', false)
      .set('loadingAfter', false)
      .set('loadingBefore', false);

    if (direction === BEFORE) {
      return newStateCommon.update('conversationItems', (conversationItems) =>
        conversationItems
          .setIn(
            ['pageInfo', 'hasPreviousPage'],
            conversation.conversationItems.pageInfo.hasPreviousPage
          )
          .set(
            'edges',
            newConversationItems.get('edges').concat(state.getIn(['conversationItems', 'edges']))
          )
      );
    }

    if (direction === AFTER) {
      return newStateCommon.update('conversationItems', (conversationItems) =>
        conversationItems
          .setIn(['pageInfo', 'hasNextPage'], conversation.conversationItems.pageInfo.hasNextPage)
          .set(
            'edges',
            state.getIn(['conversationItems', 'edges']).concat(newConversationItems.get('edges'))
          )
      );
    }

    const newState = checkMessageForm(
      newStateCommon.set('conversationItems', newConversationItems).set('id', conversation.id),
      DEFAULT_STATE_CONVERSATION_INTERNAL_FORM
    );

    if (direction === AROUND) {
      return newState.updateIn(['conversationItems', 'edges'], (edges) => {
        const foundMessageIndex = edges.findIndex((edge) => edge.get('cursor') === messageCursor);

        return foundMessageIndex > -1
          ? edges.update(foundMessageIndex, (edge) => edge.setIn(['node', 'selected'], true))
          : edges;
      });
    }

    return newState;
  },

  /**
   * New message.
   */
  [EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_FROM_ME]: addEventuallyMessageInConversation,
  [EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME]: addEventuallyMessageInConversation,

  [CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD]: (state) =>
    resetNotPersistedState(state, DEFAULT_STATE).set('loading', true),

  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD]: (state) =>
    resetNotPersistedState(state, DEFAULT_STATE).set('loading', true),

  /**
   * Update the mentioned user.
   *
   * @param {Immutable.Map} state
   * @param {Object} user
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE]: updateUserToMention,

  /**
   * Remove the mentioned user.
   *
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION]: updateUserToMention,

  /**
   * Eventually add optimistically the current employee message at the end of internal conversation.
   * The message is ignored if current conversation items page is not the last one.
   * @param {Immutable.Map} state
   * @param {Object} employee
   * @param {Object} message
   * @param {Array} mentions
   * @param {Object} organization
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE]: (
    state,
    {payload: {employee, mentions, message, organization}}
  ) => {
    if (state.getIn(['conversationItems', 'pageInfo', 'hasNextPage'])) {
      return state;
    }

    return updateUserToMention(
      startSendingMessageInConversation({
        messageType: CONVERSATION_ITEM_TYPES.INTERNAL,
        employee,
        mentions,
        message,
        organization,
        state
      })
    );
  },

  [CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_SUCCESS]: succeedSendingMessageInConversation,
  [CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_FAILURE]: failSendingMessageInConversation,
  [CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS]: (state) => state.set('loading', false),
  [CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START]: (state) =>
    resetNotPersistedState(state, DEFAULT_STATE),
  [ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER]: (state) =>
    resetNotPersistedState(state, DEFAULT_STATE).set('loading', true)
});
