import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {CUSTOMER_THREAD_INVISIBLE_LOAD} from '../../../../actions/customer-thread-invisible-actions';
import {
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_CLOSE,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE
} from '../../../../actions/customer-thread-invisible-mention-popup-actions';

export const DEFAULT_STATE = {
  loading: false,
  open: false,
  text: '',
  items: []
};

export default createReducer(DEFAULT_STATE, {
  [CUSTOMER_THREAD_INVISIBLE_LOAD]: (state) => {
    return state.set('open', false);
  },

  [CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE]: (state, {payload: {text}}) => {
    return state.set('items', fromJS([])).set('loading', true).set('text', text);
  },

  [CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS]: (
    state,
    {payload: {items}}
  ) => {
    return state.set('items', fromJS(items)).set('loading', false);
  },

  [CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE]: (state) => {
    return state.set('open', false);
  },

  [CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN]: (state) => {
    return state.set('open', true);
  },

  [CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_CLOSE]: (state) => {
    return state.set('open', false);
  }
});
