import {PureComponent} from 'react';
import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';
import context from '../../../../shared/component/context';
import RetryableHeadedLayout from '../common/layout/retryable-headed-layout-component';
import {fontWeight} from '../../../../shared/style/variables';
import {VERIFY_EMAIL_SEND_EMAIL_RETRY_DELAY} from '../../data/settings';

class VerifyEmailComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {emailAddress, isResendingVerifyEmail, doLogout, doResendVerificationEmail} = this.props;

    return (
      <RetryableHeadedLayout
        dataTestId="verify-email"
        isRetrying={isResendingVerifyEmail}
        retryButtonLabel={i18n.t('verifyEmail.retryButton')}
        retryDelay={VERIFY_EMAIL_SEND_EMAIL_RETRY_DELAY}
        retryInText={i18n.t('verifyEmail.canResendIn')}
        doLogout={doLogout}
        onClickRetry={doResendVerificationEmail}
        text={i18n.t('verifyEmail.yourEmailIsNotVerifiedYet', {
          emailAddress: ReactDomServer.renderToStaticMarkup(
            <span style={{fontWeight: fontWeight.bold}}>{emailAddress}</span>
          )
        })}
      />
    );
  }
}

VerifyEmailComponent.contextTypes = context;

VerifyEmailComponent.propTypes = {
  emailAddress: PropTypes.string,
  isResendingVerifyEmail: PropTypes.bool.isRequired,
  doLogout: PropTypes.func.isRequired,
  doResendVerificationEmail: PropTypes.func.isRequired
};

export default VerifyEmailComponent;
