import React from 'react';
import PropTypes from 'prop-types';
import Emoji from './emoji-component';
import emojiMatchCharToAvoid from './emoji-match-char-to-avoid';
import emojiRegex from './emoji-regex';

const _renderTextFragment = (text, textIndex) => (
  <React.Fragment key={textIndex}>{text}</React.Fragment>
);
/**
 * Finally, the component.
 */
const EmojiRender = ({children, transformer}) => {
  if (typeof children !== 'string') {
    return children;
  }

  const emojisArr = [];
  const textSplited = [];

  let match;
  let index = 0;

  emojiRegex.lastIndex = 0;
  // eslint-disable-next-line no-cond-assign
  while ((match = emojiRegex.exec(children)) !== null) {
    const firstMatch = match[0];

    if (emojiMatchCharToAvoid(firstMatch)) {
      continue;
    }

    if (match.index === 0) {
      textSplited.push(children.slice(2, 0));
    } else {
      textSplited.push(children.slice(index, match.index));
      textSplited.push('');
    }

    index = match.index + firstMatch.length;

    const emoji = <Emoji decoratedText={[...firstMatch]} />;
    if (emoji) {
      emojisArr.push(emoji);
    }
  }

  textSplited.push(children.slice(index));

  return (
    <React.Fragment>
      {textSplited.map((text, textIndex) => {
        if (text === '') {
          return emojisArr.length > 0 ? _renderTextFragment(emojisArr.shift(), textIndex) : null;
        }

        return _renderTextFragment(transformer ? transformer(text) : text, textIndex);
      })}
    </React.Fragment>
  );
};

EmojiRender.propTypes = {
  children: PropTypes.node.isRequired,
  transformer: PropTypes.func
};

export default EmojiRender;
