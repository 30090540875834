import PropTypes from 'prop-types';
import ThreadWhatsAppTemplateHeaderComponent from './thread-whatsapp-template-header';
import ThreadWhatsAppTemplateItem from './thread-whatsapp-template-item';
import {spacing} from '../../../../../../../../../shared/style/variables';
import {lightSmoke} from '../../../../../../../../../shared/style/colors';

const ThreadWhatsAppTemplateListComponent = ({
  i18n,
  whatsappTemplates,
  doCancelWhatsappTemplatesScreen,
  onWhatsappTemplateClick
}) => {
  const templatesLastItemIndex = whatsappTemplates.length - 1;

  return (
    <div
      data-test-id="whatsapp-templates-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderTop: 0,
        padding: spacing.medium,
        textAlign: 'center'
      }}
    >
      <ThreadWhatsAppTemplateHeaderComponent {...{i18n, doCancelWhatsappTemplatesScreen}} />
      <div style={{width: '100%'}}>
        <ul data-test-id="whatsapp-templates-list">
          {whatsappTemplates.map((template, index) => (
            <ThreadWhatsAppTemplateItem
              key={String(index)}
              {...{
                template,
                onWhatsappTemplateClick
              }}
              innerStyle={{
                borderBottom: index === templatesLastItemIndex ? 0 : `1px solid ${lightSmoke}`
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

ThreadWhatsAppTemplateListComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  whatsappTemplates: PropTypes.arrayOf(PropTypes.object),
  doCancelWhatsappTemplatesScreen: PropTypes.func.isRequired,
  onWhatsappTemplateClick: PropTypes.func.isRequired
};

export default ThreadWhatsAppTemplateListComponent;
