/**
 * Rules:
 *  less than 5 days selected : all aggregations are available
 *  from 5 days selected : hourly not available
 *  from 100 days : daily not available
 *  from 112 days (16 week data points) : weekly not available
 *  from 180 days (6 month data points) : monthly not available
 */
function checkCustomAggregationPeriods(startDate, endDate) {
  const diff = startDate ? endDate.diff(startDate, 'days') : 0;

  return [
    {
      value: 'PT1H',
      forbidden: diff >= 5
    },
    {
      value: 'P1D',
      forbidden: diff >= 100
    },
    {
      value: 'P1W',
      forbidden: diff >= 112
    },
    {
      value: 'P1M',
      forbidden: diff >= 180
    },
    {
      value: 'P1Y'
    }
  ];
}

export default checkCustomAggregationPeriods;
