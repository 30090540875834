import PropTypes from 'prop-types';
import AdministrationBusinessPageChannelGbmHeaderItem from './administration-business-page-channels-gbm-header-item-component';
import AdministrationBusinessPageChannelsLinkedTextComponent from '../administration-business-page-channels-lnked-text-component';
import {lightGrey} from '../../../../../../../../../shared/style/colors';

const AdministrationBusinessPageChannelGbmNotSetup = ({
  dataTestId,
  description,
  i18n,
  linkLabel,
  linkProps
}) => {
  return (
    <div
      data-test-id={dataTestId}
      style={{paddingBottom: '1em', borderBottom: `1px solid ${lightGrey}`, marginBottom: '1em'}}
    >
      <AdministrationBusinessPageChannelGbmHeaderItem {...{i18n}} />
      <AdministrationBusinessPageChannelsLinkedTextComponent
        {...{
          description,
          linkLabel,
          linkProps
        }}
      />
    </div>
  );
};

AdministrationBusinessPageChannelGbmNotSetup.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkProps: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationBusinessPageChannelGbmNotSetup;
