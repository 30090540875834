const AdministrationFormOverlayComponent = () => (
  <div
    style={{
      backgroundColor: 'white',
      height: '100%',
      opacity: 0.6,
      position: 'absolute',
      width: '100%',
      zIndex: 1
    }}
  />
);

export default AdministrationFormOverlayComponent;
