import isDecimal from 'validator/lib/isDecimal';

/**
 * Check if a value is acceptable as an amount input.
 * @param {String} value
 * @param {String} localeOrLanguage
 * @returns {Boolean}
 */
const isAcceptableAsAmountInput = (value, localeOrLanguage) => {
  if (value === '') {
    return true;
  }

  const language =
    typeof localeOrLanguage === 'string' ? localeOrLanguage.substring(0, 2).toLowerCase() : 'en';

  const VALIDATOR_LOCALE_MAPPING = {es: 'es-ES', fr: 'fr-FR'};

  return isDecimal(value, {
    decimal_digits: '0,2',
    locale: VALIDATOR_LOCALE_MAPPING[language] || 'en-US'
  });
};

export default isAcceptableAsAmountInput;
