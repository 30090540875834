import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {getAuthenticationHeader} from '../../../shared/store/middle-end-accessors';
import getLegacyToken from '../../../shared/epic/lib/get-legacy-token';
import {
  SIGNIN_IMPROVE_PASSWORD_CONTINUE,
  SIGNIN_IMPROVE_PASSWORD_SUBMIT,
  SIGNIN_IMPROVE_PASSWORD_SUBMIT_FAILURE,
  SIGNIN_IMPROVE_PASSWORD_SUBMIT_SUCCESS,
  SIGNIN_PASSWORD_COMPLIANT_SUCCESS
} from '../actions/signin-improve-actions';

const SignInImprovePasswordEpic =
  ({graphql}) =>
  (action$, state$) => {
    const continueToTheApp = action$.pipe(
      ofType(SIGNIN_IMPROVE_PASSWORD_CONTINUE),
      map(() => {
        return {
          type: SIGNIN_PASSWORD_COMPLIANT_SUCCESS
        };
      })
    );

    const newPasswordSubmit = action$.pipe(
      ofType(SIGNIN_IMPROVE_PASSWORD_SUBMIT),
      mergeMap(({isFormValid, password}) => {
        if (!isFormValid) {
          return of({
            type: SIGNIN_IMPROVE_PASSWORD_SUBMIT_FAILURE,
            errors: []
          });
        }

        return graphql(
          'update-logged-user-password-mutation',
          {password},
          {
            headers: getAuthenticationHeader(getLegacyToken(state$))
          }
        ).pipe(
          map(() => {
            return {
              type: SIGNIN_IMPROVE_PASSWORD_SUBMIT_SUCCESS
            };
          }),
          catchError((errors) => {
            return of({
              type: SIGNIN_IMPROVE_PASSWORD_SUBMIT_FAILURE,
              errors
            });
          })
        );
      })
    );

    return merge(continueToTheApp, newPasswordSubmit);
  };

export default SignInImprovePasswordEpic;
