/**
 * Build a user "full name".
 *
 * @param {String} firstName
 * @param {String} lastName
 * @returns {String} fullName
 */
export default function buildUserFullName(firstName, lastName) {
  return [firstName, lastName]
    .filter((value) => typeof value === 'string')
    .map((value) => value.trim())
    .filter((value) => value !== '')
    .join(' ');
}
