import {jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Get query to load mentions of employee currently logged.
 * @param {Object} slice
 * @returns {String}
 */
const graphqlGetEmployeeMentionsQuery = (slice) => {
  const query = {
    query: {
      employeeMentions: {
        __args: {
          slice: {
            first: slice.first,
            ...(slice.after ? {after: slice.after} : null),
            ...(slice.before ? {after: slice.before} : null)
          }
        },
        edges: {
          cursor: true,
          node: {
            conversation: {
              id: true
            },
            customer: {
              company: true,
              displayName: true,
              id: true
            },
            date: true,
            employee: {
              displayName: true,
              pictureHref: true
            },
            id: true,
            isRead: true,
            message: {
              cursor: true
            }
          }
        },
        pageInfo: {
          hasNextPage: true
        }
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

// eslint-disable-next-line import/prefer-default-export
export {graphqlGetEmployeeMentionsQuery};
