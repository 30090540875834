import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import handleErrors from '../../../shared/epic/lib/handle-errors';
import {
  VERIFY_EMAIL_CONFIRM,
  VERIFY_EMAIL_CONFIRM_FAILURE,
  VERIFY_EMAIL_CONFIRM_SUCCESS
} from '../actions/verify-actions';

const ERROR_MAPPING = {
  40303: 'errorTokenInvalid',
  40400: 'errorEmailUnknown'
};

/**
 * The epic itself.
 */
const VerifyEpic =
  ({graphql}) =>
  (action$) => {
    const verifyConfirm = action$.pipe(
      ofType(VERIFY_EMAIL_CONFIRM),
      mergeMap(({email, token}) => {
        // prevent useless ajax requests
        if (!email || !token) {
          return of({
            type: VERIFY_EMAIL_CONFIRM_FAILURE,
            errors: ['default']
          });
        }

        return graphql('verify-email-mutation', {
          verifyToken: token,
          email
        }).pipe(
          map(() => {
            return {
              type: VERIFY_EMAIL_CONFIRM_SUCCESS
            };
          }),
          catchError((errors) => {
            return of({
              type: VERIFY_EMAIL_CONFIRM_FAILURE,
              errors: handleErrors(errors, ERROR_MAPPING)
            });
          })
        );
      })
    );

    return merge(verifyConfirm);
  };

export default VerifyEpic;
