import {createReducer} from 'redux-immutablejs';
import {screenWidth} from '../style/variables';
import {
  UI_END_MOUNT,
  UI_LANGUAGE_UPDATE_SUCCESS,
  UI_WINDOW_DOM_CONTENT_LOADED,
  UI_WINDOW_FOCUSED,
  UI_WINDOW_RESIZED,
  UI_WINDOW_UNFOCUSED
} from '../actions/ui-actions';

export const DEFAULT_STATE = {
  height: null,
  isSmallScreen: null,
  isTinyScreen: null,
  language: null,
  mountedAt: null,
  revision: REVISION,
  width: null,
  windowFocused: true
};

const updateWindowDimensions = (state, {width, height}) =>
  state
    .set('height', height)
    .set('isSmallScreen', width <= screenWidth.smallScreenMax)
    .set('isTinyScreen', width <= screenWidth.mobileScreenMax)
    .set('width', width);
/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * End to mount UI.
   *
   * @param {Object} state
   * @param {String} language
   * @returns {Object} new state
   */
  [UI_END_MOUNT]: (state, {language}) => {
    return state.set('language', language).set('mountedAt', new Date());
  },

  /**
   * Language updated successfully.
   *
   * @param {Object} state
   * @param {String} language
   * @returns {Object} new state
   */
  [UI_LANGUAGE_UPDATE_SUCCESS]: (state, {language}) => {
    return state.set('language', language);
  },

  /**
   * User focused window.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [UI_WINDOW_FOCUSED]: (state) => {
    return state.set('windowFocused', true);
  },

  /**
   * User unfocused window.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [UI_WINDOW_UNFOCUSED]: (state) => {
    return state.set('windowFocused', false);
  },

  /**
   * Window dimensions update.
   *
   * @param {Object} state
   * @param {Number} width
   * @param {Number} height
   * @returns {Object} new state
   */
  [UI_WINDOW_DOM_CONTENT_LOADED]: updateWindowDimensions,
  [UI_WINDOW_RESIZED]: updateWindowDimensions
});
