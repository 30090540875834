import {of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

const createFileFormData = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return formData;
};

const defaultSuccessMap =
  (type) =>
  ({data}) =>
    [
      {
        data,
        type
      }
    ];

/**
 * Upload a file and return it's data.
 * @param {Object} actions
 * @param {Function} command
 * @param {File} file
 * @param {Function} [successMap]
 * @returns {Object} command result
 */
export default ({actions, command, file, successMap}) =>
  command('upload-file', createFileFormData(file)).pipe(
    mergeMap(successMap || defaultSuccessMap(actions.success)),
    catchError((error) =>
      of({
        type: actions.failure,
        error
      })
    )
  );
