import buildUserFullName from './build-user-full-name';

/**
 * Build a customer "participant name" from user available properties.
 *
 * @param {String} email
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} phoneNumber
 * @returns {String} participantName
 */
export default (email, firstName, lastName, phoneNumber) => {
  if (firstName || lastName) {
    return buildUserFullName(firstName, lastName);
  }

  return email || phoneNumber;
};
