import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Radium from 'radium';
import CappedBadge from '../../common/capped-badge-component';
import TOP_MENU_LINK_STYLE from '../lib/utils';
import {spacing} from '../../../../../shared/style/variables';
import {darkSmoke, red} from '../../../../../shared/style/colors';

const LINK_STYLE = {
  display: 'flex',
  alignItems: 'center',
  padding: spacing.large,
  textDecoration: 'none',
  textTransform: 'uppercase',
  position: 'relative',
  cursor: 'pointer'
};

/**
 * Finally, the component.
 */
const BadgedLinkComponent = Radium(
  ({badgeStyle, href, isActive, label, number, style, ...others}) => {
    const badge =
      number > 0 ? (
        <CappedBadge
          data-test-id="badge"
          number={number}
          badgeStyle={{
            backgroundColor: red,
            borderColor: red,
            ...badgeStyle
          }}
        />
      ) : null;

    const linkLabel = (
      <span data-test-id={isActive ? 'active' : null}>
        <span
          data-test-id="label"
          style={{
            marginRight: spacing.small,
            ...TOP_MENU_LINK_STYLE
          }}
        >
          {label}
        </span>
      </span>
    );

    if (typeof href === 'object') {
      // Do not use activeStyle because some link may have many matching pathname and React won't detect it for all
      return (
        <Link
          to={href}
          {...others}
          style={{
            color: isActive ? 'white' : darkSmoke,
            ...LINK_STYLE,
            ...style
          }}
        >
          {linkLabel}
          {badge}
        </Link>
      );
    }

    return (
      <a
        href={href}
        {...others}
        style={{
          color: darkSmoke,
          ...LINK_STYLE,
          ...style
        }}
      >
        {linkLabel}
        {badge}
      </a>
    );
  }
);

BadgedLinkComponent.propTypes = {
  badgeStyle: PropTypes.objectOf(PropTypes.any),
  isActive: PropTypes.bool,
  label: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  number: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any)
};

export default BadgedLinkComponent;
