import qs from 'qs';

/**
 * Encapsulate qs parse to remove first '?' from search param.
 * Default result seems necessary to protect against wrong package behaviour (see AWI-6919).
 *
 * @param {String} search
 * @returns {Object}
 */
export function parseSearch(search) {
  return qs.parse(search, {ignoreQueryPrefix: true}) || {};
}

/**
 * Encapsulate qs stringify to add '?' in query string.
 *
 * @param {Object} obj
 * @returns {String}
 */
export function queryfyObject(obj) {
  return qs.stringify(obj, {addQueryPrefix: true});
}
