import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import CustomerThreadComponent from '../../../../component/content/customer/thread/customer-thread-component';
import conversationItemsTransform from '../../../../selector/conversation-items-transform';
import doRegisterTutorial from '../../../lib/actions/tutorials-dispatch-to-props';
import {getCurrentConversationFromConversations} from '../../../../epic/content/customer/thread/lib/customer-thread-conversations-helper';
import getSecuredThreadsForm from '../../../../lib/get-secured-threads-form';
import threadContainerSetup from '../../../../lib/thread-container-setup';
import {CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_BUTTON_CLICK} from '../../../../actions/customer-new-thread-actions';
import {
  CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK,
  CUSTOMER_THREAD_CONVERSATION_TAB_CLICK,
  CUSTOMER_THREAD_PAGE_LEAVE,
  CUSTOMER_THREAD_STATUS_BUTTON_CLICK,
  CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE,
  CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE,
  CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE,
  CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK,
  CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK,
  CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK,
  CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK,
  CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK
} from '../../../../actions/customer-thread-app-payment-actions';
import {
  CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK,
  CUSTOMER_THREAD_APPS_AREA_MINIMIZE,
  CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT
} from '../../../../actions/customer-thread-apps-actions';
import {
  CUSTOMER_THREAD_RELEASE_BUTTON_CLICK,
  CUSTOMER_THREAD_TAKE_BUTTON_CLICK
} from '../../../../actions/customer-thread-assignment-actions';
import {
  CUSTOMER_CONVERSATION_INTERNAL_FILE_REMOVE_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD,
  CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_CHANGED,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION_BUTTON_CLICK,
  CUSTOMER_THREAD_INVISIBLE_LOAD
} from '../../../../actions/customer-thread-invisible-actions';
import {
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_CLOSE,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE
} from '../../../../actions/customer-thread-invisible-mention-popup-actions';
import {
  CUSTOMER_THREAD_PROFILE_SHOW,
  CUSTOMER_THREAD_PROFILE_CLOSE,
  CUSTOMER_THREAD_PROFILE_EDIT_CLOSE,
  CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE,
  CUSTOMER_THREAD_PROFILE_EDIT_OPEN,
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD
} from '../../../../actions/customer-thread-profile-actions';
import {
  CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_CHANGED,
  CUSTOMER_THREAD_VISIBLE_FILE_REMOVE,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK,
  CUSTOMER_THREAD_VISIBLE_PAGINATE,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_BUTTON_CLICK,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY_BUTTON_CLICK,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK
} from '../../../../actions/customer-thread-visible-actions';
import {GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP} from '../../../../actions/global-actions';
import {APP_THREAD_FILE_PREVIEW_OPEN} from '../../../../actions/thread-actions';
import {CONVERSATION_VISIBLE_STATUS_VALUES} from '../../../../data/thread/conversation-status';
import {UI_AREA_CLICK} from '../../../../../../shared/actions/ui-actions';

const getXsibleThreadProps = (visibility, threadState, xsibleState, conversationId) => {
  return {
    conversationItemsBatches: conversationItemsTransform(xsibleState),
    direction: xsibleState.get('direction'),
    hasNextPage: xsibleState.getIn(['conversationItems', 'pageInfo', 'hasNextPage']),
    hasPreviousPage: xsibleState.getIn(['conversationItems', 'pageInfo', 'hasPreviousPage']),
    isActiveThreadType: threadState.get('activeThreadType') === visibility,
    latestMessage: xsibleState.getIn(['conversationItems', 'edges', -1, 'cursor']),
    loading: xsibleState.get('loading'),
    loadingAfter: xsibleState.get('loadingAfter'),
    loadingBefore: xsibleState.get('loadingBefore'),
    oldestMessage: xsibleState.getIn(['conversationItems', 'edges', 0, 'cursor']),
    sending: xsibleState.get('sending'),
    threadForm: getSecuredThreadsForm(xsibleState, conversationId),
    uploadingFile: xsibleState.get('uploadingFile')
  };
};

/**
 * Finally, the container.
 */
const CustomerThreadContainer = connect(
  createSelector(
    [
      (state, router) => router,
      (state) => state.get('account'),
      (state) => state.get('customerThread'),
      (state) => state.getIn(['customerThreadAppPayment', 'threadsForm']),
      (state) => state.getIn(['customer', 'thread', 'conversations']),
      (state) => state.get('customerThreadMention'),
      (state) => state.getIn(['customer', 'thread', 'profile']),
      (state) => state.get('customerVisibleThread'),
      (state) => state.get('customerInvisibleThread'),
      (state) => state.get('featureToggle'),
      (state) => state.get('ui')
    ],
    (
      router,
      account,
      customerThread,
      customerThreadAppPaymentThreadsForm,
      customerThreadConversations,
      customerThreadMention,
      customerThreadProfile,
      customerVisibleThread,
      customerInvisibleThread,
      featureToggle,
      ui
    ) => {
      const {
        isHistoryAction,
        isNewConversation,
        isNewConversationWithNewCustomer,
        isNewThread,
        participationId,
        route
      } = threadContainerSetup(router);
      const business = customerThread.get('business');

      const conversation =
        getCurrentConversationFromConversations(customerThreadConversations)?.toJS() || {};
      const {
        assignedTo: conversationAssignedTo,
        id: conversationId,
        status: conversationStatus,
        unreadInternalMessages,
        unreadVisibleMessages
      } = conversation;

      const customerThreadAppPaymentForConversation =
        customerThreadAppPaymentThreadsForm.get(conversationId);

      return {
        conversationId: isNewConversation ? 'new' : conversationId,
        conversationStatus,
        isHistoryAction,
        isNewConversation,
        isNewConversationWithNewCustomer,
        isNewThread,
        participationId,
        unreadInternalMessages,
        unreadVisibleMessages,
        account: account.toJS(),
        activeThreadType: customerThread.get('activeThreadType'),
        business: business ? business.toJS() : null,
        cannedResponses: customerThread.get('cannedResponses').toJS(),
        conversationOrderSelected: customerThreadConversations.get('selectedItemOrder'),
        customerId: customerThreadProfile.get('customerId'),
        customerThreadConversations: customerThreadConversations.get('items').toJS(),
        disableThreadInputPersistence: featureToggle.get(
          'desktop_thread_input_persistence_disabled'
        ),
        enableCustomerProfile: featureToggle.get('customer_profile'),
        enablePaymentFeature: featureToggle.get('enable_payment_feature'),
        enableUIMessengerChannelFeature: featureToggle.get('enable_ui_messenger_channel'),
        enableUIWhatsAppChannelFeature: featureToggle.get('enable_ui_whatsapp_channel'),
        fullName: account.get('fullName'),
        isSmallScreen: ui.get('isSmallScreen'),
        newThreadEmail: route.params.email,
        newThreadPhoneNumber: route.params.phoneNumber,
        organizationPicture: account.get('organizationPicture'),

        // Customer profile
        customerProfile: customerThreadProfile.toJS(),
        customerChannel: customerThreadProfile.get('channel'),

        picture: account.get('picture'),
        startNewThreadByUnknownContact: customerThread.get('startNewThreadByUnknownContact'),
        userId: account.get('id'),
        whatsappTemplates: customerVisibleThread.get('whatsappTemplates').toJS(),
        invisibleProps: {
          mention: customerThreadMention.toJS(),
          ...getXsibleThreadProps(
            'invisible',
            customerThread,
            customerInvisibleThread,
            conversationId
          )
        },
        visibleProps: {
          assignedTo: conversationAssignedTo || null,
          customerThreadAppPayment: customerThreadAppPaymentForConversation
            ? customerThreadAppPaymentForConversation.toJS()
            : null,
          footerHasFocus: customerVisibleThread.get('footerHasFocus'),
          language: ui.get('language'),
          readOnly: customerVisibleThread.get('readOnly'),
          releasing: customerVisibleThread.get('releasing'),
          selectedFileExceedMaxSize: customerVisibleThread.get('selectedFileExceedMaxSize'),
          showAssignment:
            featureToggle.get('assign_conversation_to_employee') &&
            !isNewThread &&
            !customerVisibleThread.get('loading') &&
            conversationStatus === CONVERSATION_VISIBLE_STATUS_VALUES.WAITING, // this last test should not be required, but we prefer to protect from wrong backend's data
          taking: customerVisibleThread.get('taking'),
          updatingThreadStatus: customerVisibleThread.get('updatingThreadStatus'),
          windowExpiration: customerVisibleThread.get('windowExpiration'),
          ...getXsibleThreadProps('visible', customerThread, customerVisibleThread, conversationId)
        }
      };
    }
  ),
  {
    onUIAreaClick(area) {
      return {
        type: UI_AREA_CLICK,
        payload: {
          area
        }
      };
    },
    doRegisterTutorial,
    doThreadVisiblePaginate(participationId, messageCursor, direction) {
      return {
        type: CUSTOMER_THREAD_VISIBLE_PAGINATE,
        direction,
        messageCursor,
        participationId
      };
    },
    doThreadPageLeave(businessId, customerId, visibleConversationStatus) {
      return {
        type: CUSTOMER_THREAD_PAGE_LEAVE,
        directedToBusinessIdentifier: businessId,
        participantId: customerId,
        status: visibleConversationStatus
      };
    },

    // visible / invisible display
    doThreadSwitchVisibilityToType(threadType) {
      return {
        type: CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE,
        _googleAnalytics: true,
        threadType
      };
    },

    // colleague invitation
    doColleagueInvitationPopupOpen() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP,
        _googleAnalytics: true
      };
    },

    // visible thread
    onWhatsappTemplatesLoadButtonClick(businessId) {
      return {
        type: CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK,
        _googleAnalytics: true,
        businessId
      };
    },
    onWhatsappTemplateClick(text) {
      return {
        type: CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK,
        _googleAnalytics: true,
        payload: {
          text
        }
      };
    },
    onThreadVisibleSendMessageButtonClick(text, attachment) {
      return {
        type: CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_BUTTON_CLICK,
        _googleAnalytics: true,
        payload: {
          message: {
            attachment,
            text
          }
        }
      };
    },
    onThreadVisibleSendMessageRetryButtonClick(clientItemId) {
      return {
        type: CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY_BUTTON_CLICK,
        _googleAnalytics: true,
        payload: {
          clientItemId
        }
      };
    },
    onNewConversationSendMessageButtonClick(businessId, text, attachment) {
      return {
        type: CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_BUTTON_CLICK,
        _googleAnalytics: true,
        payload: {
          businessId,
          message: {
            attachment,
            text
          }
        }
      };
    },
    onConversationVisibleMessageFormTextChanged(conversationId, text) {
      return {
        type: CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_CHANGED,
        payload: {
          conversationId,
          text
        }
      };
    },
    doThreadVisibleFileRemove() {
      return {
        type: CUSTOMER_THREAD_VISIBLE_FILE_REMOVE,
        _googleAnalytics: true
      };
    },
    onThreadVisibleFileSelect(file) {
      return {
        type: CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
        _googleAnalytics: true,
        file
      };
    },
    onThreadVisibleFileSelectedExceedMaxSizeClose() {
      return {
        type: CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    onThreadReleaseButtonClick() {
      return {
        type: CUSTOMER_THREAD_RELEASE_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    onThreadTakeButtonClick() {
      return {
        type: CUSTOMER_THREAD_TAKE_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },

    // invisible thread
    doThreadInvisibleLoad(participationId, customerId, messageCursor, direction) {
      return {
        type: CUSTOMER_THREAD_INVISIBLE_LOAD,
        customerId,
        direction,
        messageCursor,
        participationId
      };
    },
    doConversationInternalFileRemoveButtonClick() {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_FILE_REMOVE_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doConversationInternalFileUpload(file) {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_FILE_UPLOAD,
        _googleAnalytics: true,
        file
      };
    },
    onConversationInternalSendMessageRetryButtonClick(clientItemId) {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_RETRY_BUTTON_CLICK,
        _googleAnalytics: true,
        payload: {
          clientItemId
        }
      };
    },
    doConversationInternalMentionPopupSearch(businessId, text) {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE,
        _googleAnalytics: true,
        payload: {
          businessId,
          text
        }
      };
    },
    doConversationInternalMentionPopupSelect(user) {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SELECT_COLLEAGUE,
        _googleAnalytics: true,
        payload: {
          user
        }
      };
    },
    doConversationInternalMentionUnselect() {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_UNSELECT_COLLEAGUE_TO_MENTION_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doConversationInternalMentionPopupOpen() {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doConversationInternalMentionPopupClose() {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_CLOSE,
        _googleAnalytics: true
      };
    },
    doThreadFilePreviewOpen(src) {
      return {
        type: APP_THREAD_FILE_PREVIEW_OPEN,
        _googleAnalytics: true,
        src
      };
    },
    onConversationInternalSendMessageButtonClick({attachment, text, mentionedUser}) {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_SEND_MESSAGE_BUTTON_CLICK,
        _googleAnalytics: true,
        payload: {
          message: {
            attachment,
            mentionedUser,
            text
          }
        }
      };
    },
    onConversationInternalMessageFormTextChanged(conversationId, text) {
      return {
        type: CUSTOMER_CONVERSATION_INTERNAL_MESSAGE_FORM_TEXT_CHANGED,
        payload: {
          conversationId,
          text
        }
      };
    },

    // customer profile
    doCustomerProfileShow() {
      return {
        type: CUSTOMER_THREAD_PROFILE_SHOW,
        _googleAnalytics: true
      };
    },
    doCustomerProfileClose() {
      return {
        type: CUSTOMER_THREAD_PROFILE_CLOSE,
        _googleAnalytics: true
      };
    },
    doCustomerProfileEditClose() {
      return {
        type: CUSTOMER_THREAD_PROFILE_EDIT_CLOSE,
        _googleAnalytics: true
      };
    },
    doCustomerProfileEditOpen() {
      return {
        type: CUSTOMER_THREAD_PROFILE_EDIT_OPEN,
        _googleAnalytics: true
      };
    },
    doCustomerProfileEditTextUpdate(
      participationId,
      customerId,
      displayName,
      firstName,
      lastName,
      company
    ) {
      return {
        type: CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE,
        _googleAnalytics: true,
        company,
        customerId,
        displayName,
        firstName,
        lastName,
        participationId
      };
    },
    doCustomerProfilePictureUpload(participationId, customerId, file) {
      return {
        type: CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
        _googleAnalytics: true,
        customerId,
        file,
        participationId
      };
    },

    // apps
    doAppsAreaMenuButtonClick() {
      return {
        type: CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doAppsAreaMinimize(conversationId) {
      return {
        type: CUSTOMER_THREAD_APPS_AREA_MINIMIZE,
        conversationId
      };
    },
    doAppsMenuSelectPayment(conversationId) {
      return {
        type: CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT,
        _googleAnalytics: true,
        conversationId
      };
    },
    doAppPaymentCancelButtonClick() {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doAppPaymentMaximizeButtonClick(conversationId) {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK,
        _googleAnalytics: true,
        conversationId
      };
    },
    doAppPaymentMinimizeButtonClick(conversationId) {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK,
        _googleAnalytics: true,
        conversationId
      };
    },
    doAppPaymentStepperBackButtonClick(conversationId) {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK,
        _googleAnalytics: true,
        conversationId
      };
    },
    doAppPaymentStepperNextButtonClick() {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    onAppPaymentAmountChange(amount, conversationId) {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE,
        _googleAnalytics: true,
        amount,
        conversationId
      };
    },
    onAppPaymentCustomChange(comment, conversationId) {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE,
        _googleAnalytics: true,
        comment,
        conversationId
      };
    },
    onAppPaymentCustomerChange(customerData, conversationId) {
      return {
        type: CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE,
        _googleAnalytics: true,
        conversationId,
        customerData
      };
    },
    onConversationTabClick(orderTab) {
      return {
        type: CUSTOMER_THREAD_CONVERSATION_TAB_CLICK,
        _googleAnalytics: true,
        payload: {
          orderTab
        }
      };
    },
    onThreadStatusButtonClick(status) {
      return {
        type: CUSTOMER_THREAD_STATUS_BUTTON_CLICK,
        _googleAnalytics: true,
        payload: {
          status
        }
      };
    },
    onConversationNewConversationFormOpenLinkClick() {
      return {
        type: CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK,
        _googleAnalytics: true
      };
    }
  }
)(CustomerThreadComponent);

export default CustomerThreadContainer;
