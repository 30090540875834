import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import {formatRelativeDate} from '../../../lib/date-time-helper';
import {fontSize, fontWeight} from '../../../../../shared/style/variables';
import {black} from '../../../../../shared/style/colors';
import {CONVERSATION_ITEM_TYPES} from '../../../data/thread/message';
import {PADDING_THREAD} from '../../../data/settings';

const ManualMetaDataRowComponent = ({account, date, employee, type}, {i18n}) => {
  const isMe = employee && account.id === employee.id;

  const ownEmployeeLabel = (label) => {
    return i18n.t(label, {
      assignedAt: formatRelativeDate(date, i18n, 'customerThread.visibleThread.manualMetaData')
    });
  };

  const externalEmployeeLabel = (label) => {
    return i18n.t(label, {
      assignedAt: formatRelativeDate(date, i18n, 'customerThread.visibleThread.manualMetaData'),
      employee: employee ? employee.displayName : null
    });
  };

  const typeMetadataMessage = {
    [CONVERSATION_ITEM_TYPES.ENGAGED]: isMe
      ? ownEmployeeLabel('customerThread.visibleThread.manualMetaData.engaged.ownEmployeeLabel')
      : externalEmployeeLabel(
          'customerThread.visibleThread.manualMetaData.engaged.externalEmployeeLabel'
        ),
    [CONVERSATION_ITEM_TYPES.RELEASED]: isMe
      ? ownEmployeeLabel('customerThread.visibleThread.manualMetaData.released.ownEmployeeLabel')
      : externalEmployeeLabel(
          'customerThread.visibleThread.manualMetaData.released.externalEmployeeLabel'
        )
  }[type];

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        padding: PADDING_THREAD,
        lineHeight: '16px',
        fontSize: fontSize.small,
        fontWeight: fontWeight.semiBold,
        color: black
      }}
    >
      <div data-test-id="item-label">{typeMetadataMessage}</div>
    </div>
  );
};

ManualMetaDataRowComponent.contextTypes = context;

ManualMetaDataRowComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  employee: PropTypes.objectOf(PropTypes.any), // Should be required, but some old data may not contain this info
  type: PropTypes.string.isRequired
};

export default ManualMetaDataRowComponent;
