import {fromJS} from 'immutable';
import moment from 'moment';

export const DEFAULT_STATE_THREAD_FORM = {
  fileUploaded: null,
  text: '',
  updatedAt: null
};

export const DEFAULT_STATE_THREAD = {
  direction: null,
  hasMoreMessagesBefore: false,
  loading: false,
  loadingBefore: false,
  participationId: null,
  selectedFileExceedMaxSize: false,
  sending: false,
  threadsForm: {},
  uploadingFile: false
};

/**
 * Some useful short helpers.
 */
const getNowAsUpdatedAt = () => moment().format();

// Once thread legacy code is dropped, this function can be replaced by just "state.get('id')"
const getCurrentConversationFormIdentifier = (state) =>
  state.get('id') || state.get('participationId');

/**
 * Create current conversation's form state if it doesn't exists, and remove others that are empty.
 * @param {Immutable.Map} state
 * @param {Object} defaultStateThreadForm
 * @returns {Immutable.Map} new state
 */
export function checkMessageForm(state, defaultStateThreadForm = DEFAULT_STATE_THREAD_FORM) {
  const currentConversationFormIdentifier = getCurrentConversationFormIdentifier(state);
  const currentConversationFormPath = ['threadsForm', currentConversationFormIdentifier];

  // check existence of current currentConversationFormIdentifier message form
  const newState =
    typeof state.getIn(currentConversationFormPath) === 'undefined'
      ? state.setIn(
          currentConversationFormPath,
          fromJS(defaultStateThreadForm).set('updatedAt', getNowAsUpdatedAt())
        )
      : state.setIn([...currentConversationFormPath, 'updatedAt'], getNowAsUpdatedAt());

  // remove expired forms in order to keep a lightweight state
  return newState.update('threadsForm', (threadsForm) =>
    threadsForm.filter(
      (form, conversationFormIdentifier) =>
        currentConversationFormIdentifier === conversationFormIdentifier ||
        moment().diff(moment(form.get('updatedAt')), 'days') < 1
    )
  );
}

/**
 * Persist text in conversation state, which is synchronized automagically into local storage.
 * @param {Immutable.Map} state
 * @param {String} conversationId
 * @param {String} participationId
 * @param {String} text
 * @returns {Immutable.Map} new state
 */
export function persistMessageFormText(state, {payload: {conversationId, participationId, text}}) {
  const currentConversationFormPath = ['threadsForm', conversationId || participationId];

  // In some edge cases ("logout" for example), the conversationId/participationId's threadForm may not exists anymore
  if (typeof state.getIn(currentConversationFormPath) === 'undefined') {
    return state;
  }

  return state.updateIn(currentConversationFormPath, (threadForm) =>
    threadForm.set('text', text).set('updatedAt', getNowAsUpdatedAt())
  );
}

/**
 * Mark file upload as started.
 * @param {Immutable.Map} state
 * @returns {Immutable.Map} new state
 */
export function startUploadFile(state) {
  const currentConversationFormIdentifier = getCurrentConversationFormIdentifier(state);

  return state
    .set('uploadingFile', true)
    .setIn(['threadsForm', currentConversationFormIdentifier, 'updatedAt'], getNowAsUpdatedAt());
}

/**
 * Update state after successful file upload.
 * @param {Immutable.Map} state
 * @param {Object} data uploaded file data
 * @returns {Immutable.Map} new state
 */
export function succeedUploadFile(state, {data}) {
  const currentConversationFormIdentifier = getCurrentConversationFormIdentifier(state);

  return state
    .set('uploadingFile', false)
    .updateIn(['threadsForm', currentConversationFormIdentifier], (threadForm) =>
      threadForm.set('fileUploaded', fromJS(data)).set('updatedAt', getNowAsUpdatedAt())
    );
}

/**
 * Update state after failed file upload.
 * @param {Immutable.Map} state
 * @returns {Immutable.Map} new state
 */
export function failUploadFile(state) {
  const currentConversationFormIdentifier = getCurrentConversationFormIdentifier(state);

  return state
    .set('uploadingFile', false)
    .setIn(['threadsForm', currentConversationFormIdentifier, 'updatedAt'], getNowAsUpdatedAt());
}

/**
 * Remove a file uploaded.
 * @param {Immutable.Map} state
 * @returns {Immutable.Map} new state
 */
export function removeUploadedFile(state) {
  const currentConversationFormIdentifier = getCurrentConversationFormIdentifier(state);

  return state.updateIn(['threadsForm', currentConversationFormIdentifier], (threadForm) =>
    threadForm.set('fileUploaded', null).set('updatedAt', getNowAsUpdatedAt())
  );
}
