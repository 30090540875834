export const CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE =
  'CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE';
export const CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE =
  'CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE';
export const CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE =
  'CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE';

export const CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK =
  'CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK';
export const CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK =
  'CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK';
export const CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK =
  'CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK';

export const CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD =
  'CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD';
export const CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE =
  'CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE';
export const CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS =
  'CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS';

export const CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL = 'CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL';

export const CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK =
  'CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK';
export const CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK =
  'CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK';

export const CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE =
  'CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE';

export const CUSTOMER_THREAD_APP_PAYMENT_SUBMIT = 'CUSTOMER_THREAD_APP_PAYMENT_SUBMIT';
export const CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE =
  'CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE';
export const CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS =
  'CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS';

// Legacy
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE';

export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK';

export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL';

export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK';

export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE';

export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE';
export const LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS';
