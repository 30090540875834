import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  APP_TWILIO_TOKEN_LOAD,
  APP_TWILIO_TOKEN_LOAD_FAILURE,
  APP_TWILIO_TOKEN_LOAD_SUCCESS
} from '../../actions/app-actions';

const TwilioEpic =
  ({graphql}) =>
  (action$) => {
    const loadTwilioToken = action$.pipe(
      ofType(APP_TWILIO_TOKEN_LOAD),
      mergeMap(() => {
        return graphql('get-twilio-token-query').pipe(
          map(({twilioToken}) => {
            return {
              type: APP_TWILIO_TOKEN_LOAD_SUCCESS,
              data: {
                twilioToken
              }
            };
          }),
          catchError((errors) => {
            return of({
              type: APP_TWILIO_TOKEN_LOAD_FAILURE,
              errors
            });
          })
        );
      })
    );

    return merge(loadTwilioToken);
  };

export default TwilioEpic;
