import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CreditCard} from '@material-ui/icons';
import numeral from 'numeral';
import LoadingIndicator from '../../../../../../../../../../shared/component/loading-indicator-component';
import LegacyPaymentAmount from './step/legacy-payment-amount-component';
import LegacyPaymentCustom from './step/legacy-payment-custom-component';
import LegacyPaymentCustomer from './step/legacy-payment-customer-component';
import LegacyPaymentHeader from './legacy-payment-header-component';
import LegacyPaymentStepLayout from './layout/legacy-payment-step-layout-component';
import PaymentLayout from '../../../apps/payment/layout/payment-layout-component';
import {PAYMENT_AMOUNT_MAX} from '../../../../../../../../data/settings';

const StyledCreditCard = withStyles({
  fontSizeLarge: {
    fontSize: '52px'
  }
})(CreditCard);

/**
 * Finally, the component.
 */
const LegacyPaymentMaximizedComponent = ({
  doAppPaymentCancelButtonClick,
  doAppPaymentMinimizeButtonClick,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick,
  i18n,
  legacyCustomerThreadAppPayment: {
    amount,
    comment,
    customer,
    initializing,
    loadingPspAccount,
    paymentStep,
    pspAccount,
    submitHadError,
    submitting
  },
  onAppPaymentAmountChange,
  onAppPaymentCustomChange,
  onAppPaymentCustomerChange,
  participationId
}) => {
  if (initializing || loadingPspAccount) {
    return (
      <PaymentLayout>
        <LoadingIndicator />
      </PaymentLayout>
    );
  }

  if (!pspAccount || !pspAccount.paymentConfiguration) {
    return (
      <PaymentLayout>
        <LegacyPaymentHeader
          amount={amount}
          onlyShowCancelButton
          onClickCancelButton={doAppPaymentCancelButtonClick}
          onClickMinimizeButton={doAppPaymentMinimizeButtonClick}
          step={paymentStep}
          {...{
            i18n,
            participationId,
            submitting
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'inherit',
            marginTop: '3em'
          }}
        >
          <StyledCreditCard color="primary" fontSize="large" />
          <h3 data-test-id="no-configuration">
            {i18n.t('customerThread.visibleThread.apps.list.payment.noConfiguration.title')}
          </h3>
          <span>
            {i18n.t('customerThread.visibleThread.apps.list.payment.noConfiguration.description')}
          </span>
        </div>
      </PaymentLayout>
    );
  }

  const stepSetup = {
    amount: () => {
      const amountAsNumber = numeral(amount).value();
      const isAmountInRange = amountAsNumber >= 1 && amountAsNumber <= PAYMENT_AMOUNT_MAX;

      return {
        component: (
          <LegacyPaymentAmount {...{amount, i18n, onAppPaymentAmountChange, participationId}} />
        ),
        canGoNext: isAmountInRange,
        errorMessage:
          amount && !isAmountInRange
            ? i18n.t('customerThread.visibleThread.apps.list.payment.amount.error', {
                amountMax: PAYMENT_AMOUNT_MAX
              })
            : null,
        title:
          !amount || !isAmountInRange
            ? i18n.t('customerThread.visibleThread.apps.list.payment.amount.titleWithoutAmount')
            : i18n.t('customerThread.visibleThread.apps.list.payment.amount.titleWithAmount', {
                amount
              })
      };
    },
    customer: () => {
      const isCustomerValid =
        !!(customer && customer.firstName) && !!(customer && customer.lastName);

      return {
        component: (
          <LegacyPaymentCustomer
            {...{
              customer,
              i18n,
              onAppPaymentCustomerChange,
              participationId
            }}
          />
        ),
        canGoNext: isCustomerValid
      };
    },
    custom: () => {
      const {paymentConfiguration} = pspAccount;
      const isCustomValid = !!comment;

      return {
        component: (
          <LegacyPaymentCustom
            commentPlaceHolder={paymentConfiguration.comment}
            {...{
              comment,
              i18n,
              onAppPaymentCustomChange,
              participationId,
              submitting
            }}
          />
        ),
        canGoNext: isCustomValid,
        errorMessage:
          submitHadError && !submitting
            ? i18n.t('customerThread.visibleThread.apps.list.payment.custom.submitErrorMessage')
            : null,
        nextButtonLabelI18NPath: submitHadError
          ? 'customerThread.visibleThread.apps.list.payment.custom.nextButtonLabelTryAgain'
          : null,
        subTitle: paymentConfiguration.title
      };
    }
  }[paymentStep]();

  return (
    <LegacyPaymentStepLayout
      amount={amount}
      canGoNext={stepSetup.canGoNext}
      errorMessage={stepSetup.errorMessage}
      nextButtonLabelI18NPath={stepSetup.nextButtonLabelI18NPath}
      onClickCancelButton={doAppPaymentCancelButtonClick}
      onClickMinimizeButton={doAppPaymentMinimizeButtonClick}
      step={paymentStep}
      subTitle={stepSetup.subTitle}
      title={stepSetup.title}
      {...{
        i18n,
        participationId,
        submitting,
        doAppPaymentStepperBackButtonClick,
        doAppPaymentStepperNextButtonClick
      }}
    >
      {stepSetup.component}
    </LegacyPaymentStepLayout>
  );
};

LegacyPaymentMaximizedComponent.propTypes = {
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  legacyCustomerThreadAppPayment: PropTypes.objectOf(PropTypes.any).isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  participationId: PropTypes.string.isRequired
};

export default LegacyPaymentMaximizedComponent;
