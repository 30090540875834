import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {resetThreadWarnings} from '../lib/header-helper';
import {
  CUSTOMER_THREAD_PAGE_LEAVE,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_LOAD
} from '../../actions/customer-thread-actions';
import {
  CUSTOMER_THREAD_VISIBLE_LOAD,
  CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS
} from '../../actions/customer-thread-visible-actions';
import {CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START} from '../../actions/customer-new-thread-actions';

// "active" means warning should be shown
export const DEFAULT_STATE = {
  threadBusinessDeactivated: {
    active: null,
    businessName: null
  },
  threadConversationsNotFound: {
    active: null
  }
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Set as warning when the thread compatibility loading has failed.
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_FAILURE]: (state) =>
    state.setIn(['threadConversationsNotFound', 'active'], true),

  /**
   * Reset the thread warnings on some events.
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_PAGE_LEAVE]: resetThreadWarnings(DEFAULT_STATE),
  [LEGACY_CUSTOMER_THREAD_LOAD]: resetThreadWarnings(DEFAULT_STATE), // Only for legacy
  [CUSTOMER_THREAD_VISIBLE_LOAD]: resetThreadWarnings(DEFAULT_STATE),
  [CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START]: resetThreadWarnings(DEFAULT_STATE),

  /**
   * Set the thread business deactivated status after a loading.
   * @param {Object} state
   * @param {Object} business
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (
    state,
    {
      payload: {
        data: {
          conversation: {business}
        }
      }
    }
  ) => {
    if (!business.deactivated) {
      return state;
    }

    return state.set(
      'threadBusinessDeactivated',
      fromJS({
        active: true,
        businessName: business.name
      })
    );
  },

  /**
   * Set the thread business deactivated status after a loading.
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    if (!data.deactivated) {
      return state;
    }

    return state.set(
      'threadBusinessDeactivated',
      fromJS({
        active: true,
        businessName: data.directedToBusinessName
      })
    );
  }
});
