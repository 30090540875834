import {merge, of} from 'rxjs';
import {catchError, delay, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import uploadFile from '../../../lib/upload-file';
import {
  LEGACY_CUSTOMER_THREAD_PROFILE_LOAD,
  LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS
} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';

const LegacyCustomerProfileEpic =
  ({command, graphql}) =>
  (action$) => {
    const editCustomerProfile = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE),
      mergeMap(({company, customerId, firstName, lastName, participantName, participationId}) => {
        return graphql('update-customer-profile-name-mutation', {
          company,
          customerId,
          firstName,
          lastName
        }).pipe(
          map(() => {
            return {
              type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS,
              company,
              firstName,
              lastName,
              participantName,
              participationId
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadCustomerProfile = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_PROFILE_LOAD),
      mergeMap(({customerId, customerType}) => {
        return graphql('get-customer-profile-query', {customerId, customerType}).pipe(
          map(({customer}) => {
            return {
              type: LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_SUCCESS,
              customer
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_PROFILE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadCustomerProfileOnLoadLegacyThreadSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS),
      delay(100),
      map(({data}) => {
        return {
          type: LEGACY_CUSTOMER_THREAD_PROFILE_LOAD,
          customerId: data.participantId,
          customerType: data.participantRegistered ? 'registeredUser' : 'prospect'
        };
      })
    );

    const uploadPicture = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD),
      mergeMap(({customerId, file, participationId}) =>
        uploadFile({
          command,
          file,
          actions: {
            failure: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE
          },
          successMap: ({data}) => {
            return graphql('update-customer-profile-picture-mutation', {
              fileUUID: data.uuid,
              customerId
            }).pipe(
              map(() => {
                return {
                  type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
                  href: data.href,
                  participationId
                };
              })
              // @todo Handle upload failure
            );
          }
        })
      )
    );

    return merge(
      editCustomerProfile,
      loadCustomerProfile,
      loadCustomerProfileOnLoadLegacyThreadSuccess,
      uploadPicture
    );
  };

export default LegacyCustomerProfileEpic;
