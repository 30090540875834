export const CUSTOMER_INBOX_BUSINESS_FILTER_CLOSE = 'CUSTOMER_INBOX_BUSINESS_FILTER_CLOSE';
export const CUSTOMER_INBOX_BUSINESS_FILTER_OPEN = 'CUSTOMER_INBOX_BUSINESS_FILTER_OPEN';
export const CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES =
  'CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES';
export const CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_FAILURE =
  'CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_FAILURE';
export const CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_SUCCESS =
  'CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_SUCCESS';

export const CUSTOMER_INBOX_GO_TO_THREAD = 'CUSTOMER_INBOX_GO_TO_THREAD';

export const CUSTOMER_INBOX_LEAVE = 'CUSTOMER_INBOX_LEAVE';

export const CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED =
  'CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED';
export const CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK =
  'CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK';
export const CUSTOMER_INBOX_NEW_CONVERSATION_NEW_CUSTOMER_FORM_CUSTOMER_CLICK =
  'CUSTOMER_INBOX_NEW_CONVERSATION_NEW_CUSTOMER_FORM_CUSTOMER_CLICK';
export const CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH =
  'CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH';
export const CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_FAILURE =
  'CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_FAILURE';
export const CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_SUCCESS =
  'CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_SUCCESS';

export const CUSTOMER_INBOX_SEARCH_ALL = 'CUSTOMER_INBOX_SEARCH_ALL';
export const CUSTOMER_INBOX_SEARCH_ALL_FAILURE = 'CUSTOMER_INBOX_SEARCH_ALL_FAILURE';
export const CUSTOMER_INBOX_SEARCH_ALL_SUCCESS = 'CUSTOMER_INBOX_SEARCH_ALL_SUCCESS';

export const CUSTOMER_INBOX_SEARCH_FORM_RESET = 'CUSTOMER_INBOX_SEARCH_FORM_RESET';
export const CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE = 'CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE';
export const CUSTOMER_INBOX_SEARCH_FORM_TEXT_CLEAR_BUTTON_CLICK =
  'CUSTOMER_INBOX_SEARCH_FORM_TEXT_CLEAR_BUTTON_CLICK';
export const CUSTOMER_INBOX_SEARCH_FORM_TOGGLE = 'CUSTOMER_INBOX_SEARCH_FORM_TOGGLE';

export const CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS = 'CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS';
export const CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK =
  'CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK';
export const CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE =
  'CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE';
export const CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS =
  'CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS';

export const CUSTOMER_INBOX_SEARCH_MORE_MESSAGES = 'CUSTOMER_INBOX_SEARCH_MORE_MESSAGES';
export const CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK =
  'CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK';
export const CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE =
  'CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE';
export const CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS =
  'CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS';

export const CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK =
  'CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK';
export const CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK =
  'CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK';

export const CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE =
  'CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE';

export const CUSTOMER_INBOX_SNACK_NOTIFICATION_HIDE = 'CUSTOMER_INBOX_SNACK_NOTIFICATION_HIDE';
export const CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW = 'CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW';

export const CUSTOMER_INBOX_THREADS_LIST_FILTER_BY_STATUS =
  'CUSTOMER_INBOX_THREADS_LIST_FILTER_BY_STATUS';
export const CUSTOMER_INBOX_THREADS_LIST_LOAD = 'CUSTOMER_INBOX_THREADS_LIST_LOAD';
export const CUSTOMER_INBOX_THREADS_LIST_LOAD_FAILURE = 'CUSTOMER_INBOX_THREADS_LIST_LOAD_FAILURE';
export const CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS =
  'CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS';
export const CUSTOMER_INBOX_THREADS_LIST_LOAD_SUCCESS = 'CUSTOMER_INBOX_THREADS_LIST_LOAD_SUCCESS';
export const CUSTOMER_INBOX_THREADS_LIST_RELOAD = 'CUSTOMER_INBOX_THREADS_LIST_RELOAD';
export const CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD =
  'CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD';

export const CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK = 'CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK';

// Shared with legacy

export const CUSTOMER_INBOX_NEW_THREAD_START_CLOSE = 'CUSTOMER_INBOX_NEW_THREAD_START_CLOSE';
export const CUSTOMER_INBOX_NEW_THREAD_START_SHOW = 'CUSTOMER_INBOX_NEW_THREAD_START_SHOW';

// Legacy

export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH =
  'LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH';
export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_FAILURE =
  'LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_FAILURE';
export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_SUCCESS =
  'LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_SUCCESS';
export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT =
  'LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT';

export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_START = 'LEGACY_CUSTOMER_INBOX_NEW_THREAD_START';

export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER =
  'LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER';
export const LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER_SUCCESS =
  'LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER_SUCCESS';

export const LEGACY_CUSTOMER_INBOX_SEARCH_ALL = 'LEGACY_CUSTOMER_INBOX_SEARCH_ALL';
export const LEGACY_CUSTOMER_INBOX_SEARCH_ALL_FAILURE = 'LEGACY_CUSTOMER_INBOX_SEARCH_ALL_FAILURE';
export const LEGACY_CUSTOMER_INBOX_SEARCH_ALL_SUCCESS = 'LEGACY_CUSTOMER_INBOX_SEARCH_ALL_SUCCESS';

export const LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS =
  'LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS';
export const LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE =
  'LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE';
export const LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS =
  'LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS';

export const LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES =
  'LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES';
export const LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE =
  'LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE';
export const LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS =
  'LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS';

export const LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER =
  'LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER';
export const LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE =
  'LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE';
export const LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD =
  'LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD';
