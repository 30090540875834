import {createStructuredSelector} from 'reselect';
import {parseSearch} from '../../../shared/lib/route-helper';
import {
  CUSTOMER_NEW_CONVERSATION_URL_REGEX,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX
} from './route-helper';

/**
 * Setup a thread container.
 *
 * @param {Object} router
 * @returns {Object}
 */
export default (router) => {
  const route = createStructuredSelector({
    participationId: (routerParam) => routerParam.match.params.participationId,
    isNewConversation: (routerParam) =>
      CUSTOMER_NEW_CONVERSATION_URL_REGEX.test(routerParam.location.pathname) ||
      CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX.test(routerParam.location.pathname),
    isNewConversationWithNewCustomer: (routerParam) =>
      CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX.test(routerParam.location.pathname),
    params: (routerParam) => parseSearch(routerParam.location.search)
  })(router);

  const {isNewConversation, isNewConversationWithNewCustomer, participationId} = route;

  return {
    isHistoryAction: router.history.action === 'POP',
    isNewThread: participationId === 'new',
    isNewConversation,
    isNewConversationWithNewCustomer,
    participationId,
    route
  };
};
