import {jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Get mutation to cancel an organization GBM agent's verification.
 * @returns {String}
 */
const graphqlCancelAgentVerificationMutation = () => {
  const mutation = {
    mutation: {
      cancelAgentVerification: {
        flag: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Get mutation to create an organization GBM agent.
 * We voluntarily prefer to explicit properties mapping.
 * @param {Object} agent
 * @param {Boolean} applyLogoToOrg
 * @param {Object} brandContactInformation
 * @returns {String}
 */
const graphqlCreateAgentMutation = ({agent, applyLogoToOrg, brandContactInformation}) => {
  const mutation = {
    mutation: {
      createBrand: {
        __args: {
          name: agent.displayName
        },
        name: true
      },
      createAgent: {
        __args: {
          agent: {
            displayName: agent.displayName,
            logoUuid: agent.logoUuid,
            mainLanguage: agent.mainLanguage,
            privacyPolicyUrl: agent.privacyPolicyUrl,
            welcomeMessage: agent.welcomeMessage
          },
          applyLogoToOrg,
          brandContactInformation: {
            contactEmailAddress: brandContactInformation.contactEmailAddress,
            contactName: brandContactInformation.contactName,
            websiteUrl: brandContactInformation.websiteUrl
          }
        },
        status: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Get query to load administration organization GBM agent.
 * @returns {String}
 */
const graphqlGetOrganizationAgentQuery = () => {
  const query = {
    query: {
      organizationAgent: {
        agentLogoUrl: true,
        brandName: true,
        brandWebsiteUrl: true,
        contactEmail: true,
        contactName: true,
        locale: true,
        privacyPolicyUrl: true,
        status: true,
        welcomeMessage: true
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

/**
 * Get mutation to update an organization GBM agent.
 * @param {String} mainLanguage
 * @param {String} privacyPolicyUrl
 * @param {String} welcomeMessage
 * @returns {String}
 */
const graphqlUpdateAgentMutation = ({mainLanguage, privacyPolicyUrl, welcomeMessage}) => {
  const mutation = {
    mutation: {
      updateAgent: {
        __args: {
          agent: {
            mainLanguage,
            privacyPolicyUrl,
            welcomeMessage
          }
        },
        status: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

export {
  graphqlCancelAgentVerificationMutation,
  graphqlCreateAgentMutation,
  graphqlGetOrganizationAgentQuery,
  graphqlUpdateAgentMutation
};
