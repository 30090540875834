import {jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Send mutation to take a conversation
 * @param {String} clientItemId
 * @param {String} conversationId
 * @returns {String}
 */
const graphqlTakeConversationMutation = ({clientItemId, conversationId}) => {
  const mutation = {
    mutation: {
      takeConversation: {
        __args: {
          clientItemId,
          conversationId
        },
        id: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

/**
 * Send mutation to release a conversation
 * @param {String} clientItemId
 * @param {String} conversationId
 * @returns {String}
 */
const graphqlReleaseConversationMutation = ({clientItemId, conversationId}) => {
  const mutation = {
    mutation: {
      releaseConversation: {
        __args: {
          clientItemId,
          conversationId
        },
        id: true
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

export {graphqlReleaseConversationMutation, graphqlTakeConversationMutation};
