import PropTypes from 'prop-types';
import numeral from 'numeral';
import context from '../../../../../../shared/component/context';
import {canShowRankingTable} from '../../../../lib/insights/insights-helper';
import {
  generateChartSubtitle,
  generateChartTitle,
  getLinkedFigure
} from '../lib/chart-title-generator';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import TableRanked from '../common/table/table-ranked-component';
import {
  graphGreen,
  graphGreenArea,
  graphRedArea,
  graphRedMain,
  graphYellow,
  graphYellowArea
} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'conversations';

/**
 * Finally, the component.
 */
class InsightConversationsComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      account,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod,
      onClickRankingButtonShowAllItems
    } = this.props;

    const {businesses: accountBusinesses} = account;

    const {conversations} = insight.conversationsPageHistogram;

    const {loadingCsv} = insight.ranking;

    const rankingItem = insight.ranking.conversations;

    const isClassicPeriod = periodTabSelected === 'classic';
    const aggregationPeriod = isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod;

    const conversationsNumber = conversations.data.conversations;

    const showRankingTable = canShowRankingTable(periodTabSelected, account);

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout ref={this.wrapperPanelContentRef} onScroll={this._handleScroll}>
          <InsightPanelContentBlock dataTestId="conversations">
            <HistogramChart
              failed={conversations.failed}
              items={conversations.data.conversationsHistogram}
              loading={conversations.loading}
              showHelpPageUrl={false}
              stacking="normal"
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.items.conversations.chart`, {
                returnObjects: true
              })}
              {...{
                aggregationPeriod
              }}
              subtitle={
                conversations.data.conversationsHistogram.length
                  ? generateChartSubtitle({
                      helpPageUrl: i18n.t(
                        `insight.pages.${INSIGHT_PAGE}.items.conversations.chart.helpPageUrl`
                      ),
                      label: i18n.t(
                        `insight.pages.${INSIGHT_PAGE}.items.conversations.chart.secondTitle`
                      ),
                      figure: getLinkedFigure(
                        conversationsNumber,
                        numeral(conversations.data.conversationsOngoing).format()
                      )
                    })
                  : null
              }
              title={generateChartTitle({
                figure: numeral(conversationsNumber).format(),
                hasData: conversations.data.conversationsHistogram.length,
                label: i18n.t(`insight.pages.${INSIGHT_PAGE}.items.conversations.chart.title`),
                showVariation: isClassicPeriod,
                variation: conversations.data.conversationsIncrement
              })}
              series={[
                {
                  property: 'conversationsOngoing',
                  color: graphRedMain,
                  fillColor: graphRedArea
                },
                {
                  property: 'conversationsInbound',
                  color: graphGreen,
                  fillColor: graphGreenArea
                },
                {
                  property: 'conversationsOutbound',
                  color: graphYellow,
                  fillColor: graphYellowArea
                }
              ]}
            />
          </InsightPanelContentBlock>

          {showRankingTable ? (
            <TableRanked
              dataTestId="conversations-ranking-table"
              downloadCsvLabelTranslation={i18n.t(`insight.pages.${INSIGHT_PAGE}.downloadCsvLabel`)}
              failed={rankingItem.failed}
              insightDownloadCsv={this._doInsightDownloadCsv}
              insightSortRankings={this._doInsightSortButtonClick}
              items={rankingItem.data}
              loading={rankingItem.loading}
              onClickButtonShowAllItems={onClickRankingButtonShowAllItems}
              showRankingControlButtons
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.ranking.conversations`, {
                returnObjects: true
              })}
              {...{loadingCsv}}
              showCsvButton
              showSortButton
            />
          ) : null}
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightConversationsComponent.contextTypes = context;

InsightConversationsComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any),
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  uiWidth: PropTypes.number.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doInsightSortButtonClick: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired,
  onClickRankingButtonShowAllItems: PropTypes.func.isRequired
};

export default InsightConversationsComponent;
