import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import ContentLayout from '../../../common/layout/content-layout-component';
import LegacyInputMessage, {
  INITIAL_INPUT_MESSAGE_HEIGHT
} from '../../../common/input-message/legacy-input-message-component';
import RightPanelColumnLayout from '../../../common/layout/right-panel-column-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import LegacyScrollableThreadMessagesList from '../../../common/thread/legacy-scrollable-thread-messages-list-component';
import SimpleContentHeader from '../../../common/content-header/simple-content-header-component';
import ThreadInteractionArea from '../../../common/thread/thread-interaction-area-component';
import {mediumSnow} from '../../../../../../shared/style/colors';

class ColleagueThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputHeight: INITIAL_INPUT_MESSAGE_HEIGHT
    };

    this._loadMore = this._loadMore.bind(this);
    this._sendMessage = this._sendMessage.bind(this);
  }

  componentWillUnmount() {
    const {doLeaveColleagueThread} = this.props;
    doLeaveColleagueThread();
  }

  _setInputHeight = ({height}) => {
    this.setState({
      inputHeight: height
    });
  };

  _loadMore(fromId, direction) {
    const {participationId, doLoadColleagueThread} = this.props;
    doLoadColleagueThread(participationId, fromId, direction);
  }

  _sendMessage(clientMessageId, text) {
    const {
      fullName,
      threadForm,
      participantId,
      picture,
      userId,
      isNewThread,
      participantName,
      doSendMessageInNewThread,
      doSendMessage
    } = this.props;

    if (isNewThread) {
      doSendMessageInNewThread({
        clientMessageId,
        fullName,
        picture,
        text,
        userId,
        attachment: threadForm.fileUploaded,
        colleagueId: participantId,
        colleagueName: participantName
      });
    } else {
      doSendMessage({
        clientMessageId,
        fullName,
        picture,
        text,
        userId,
        attachment: threadForm.fileUploaded
      });
    }
  }

  render() {
    const {i18n} = this.context;
    const {
      direction,
      disableThreadInputPersistence,
      hasMoreMessagesBefore,
      isHistoryAction,
      latestMessage,
      loading,
      loadingBefore,
      participationId,
      threadForm,
      messages,
      participantName,
      oldestMessage,
      sending,
      uploadingFile,
      doRemoveFile,
      doSendMessageRetry,
      doThreadFilePreviewOpen,
      doUpdateMessage,
      doUploadFile
    } = this.props;
    const {inputHeight} = this.state;

    return (
      <RightPanelContentLayout>
        <SimpleContentHeader title={participantName} dataTestId="thread-participant-name" />

        <ContentLayout>
          <RightPanelColumnLayout dataTestId="colleague-thread">
            <LegacyScrollableThreadMessagesList
              dataTestId="thread-result"
              loadMore={this._loadMore}
              threadType="colleague"
              {...{
                direction,
                hasMoreMessagesBefore,
                i18n,
                inputHeight,
                latestMessage,
                loading,
                loadingBefore,
                messages,
                oldestMessage,
                sending,
                doSendMessageRetry,
                doThreadFilePreviewOpen
              }}
            />

            {loading ? null : (
              <ThreadInteractionArea
                onResize={this._setInputHeight}
                style={{
                  background: mediumSnow
                }}
              >
                <LegacyInputMessage
                  dataTestId="message-input"
                  data={threadForm}
                  disableThreadInputPersistence={disableThreadInputPersistence}
                  isFocused
                  isHistoryAction={isHistoryAction}
                  placeholder={i18n.t('colleagueThread.inputMessagePlaceHolder')}
                  sending={sending}
                  tabIndex={0}
                  threadType="colleague"
                  uploadingFile={uploadingFile}
                  doRemoveFile={doRemoveFile}
                  doSendMessage={this._sendMessage}
                  doUpdateMessage={doUpdateMessage}
                  onSelectFile={doUploadFile}
                  {...{
                    participationId
                  }}
                />
              </ThreadInteractionArea>
            )}
          </RightPanelColumnLayout>
        </ContentLayout>
      </RightPanelContentLayout>
    );
  }
}

ColleagueThreadComponent.propTypes = {
  direction: PropTypes.string,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  hasMoreMessagesBefore: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingBefore: PropTypes.bool.isRequired,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  oldestMessage: PropTypes.string,
  participantId: PropTypes.string,
  participantName: PropTypes.string, // Can't be required because it's undefined on thread reloading
  participationId: PropTypes.string.isRequired,
  picture: PropTypes.string,
  sending: PropTypes.bool.isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  doLeaveColleagueThread: PropTypes.func.isRequired,
  doLoadColleagueThread: PropTypes.func.isRequired,
  doRemoveFile: PropTypes.func.isRequired,
  doSendMessage: PropTypes.func.isRequired,
  doSendMessageInNewThread: PropTypes.func.isRequired,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  doUpdateMessage: PropTypes.func.isRequired,
  doUploadFile: PropTypes.func.isRequired
};

ColleagueThreadComponent.contextTypes = context;

export default ColleagueThreadComponent;
