export const INTEGRATION_AREA_WIDGET_BUBBLE_BACKGROUND_COLOR_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_BACKGROUND_COLOR_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_BORDER_COLOR_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_BORDER_COLOR_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_BUSINESS_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_BUSINESS_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_COMMUNICATION_METHOD_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_COMMUNICATION_METHOD_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_HAS_BORDER_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_HAS_BORDER_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_HAS_SHADOW_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_HAS_SHADOW_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_EMAIL_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_EMAIL_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_PHONE_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_PHONE_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_EMAIL_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_EMAIL_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_PHONE_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_PHONE_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_LANGUAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_LANGUAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_RESET_STATE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_RESET_STATE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_SIDE_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_SIDE_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_THANKYOU_MESSAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_THANKYOU_MESSAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_BUBBLE_TOOLTIP_CHANGE =
  'INTEGRATION_AREA_WIDGET_BUBBLE_TOOLTIP_CHANGE';

export const INTEGRATION_AREA_WIDGET_CTA_ACTION_BTN_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_ACTION_BTN_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_BUSINESS_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_BUSINESS_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_COMMUNICATION_METHOD_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_COMMUNICATION_METHOD_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_EMAIL_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_EMAIL_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_PHONE_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_PHONE_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_EMAIL_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_EMAIL_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_PHONE_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_PHONE_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_LANGUAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_LANGUAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_RESET_STATE = 'INTEGRATION_AREA_WIDGET_CTA_RESET_STATE';
export const INTEGRATION_AREA_WIDGET_CTA_SNIPPET_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_SNIPPET_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE';
export const INTEGRATION_AREA_WIDGET_CTA_THANKYOU_MESSAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_CTA_THANKYOU_MESSAGE_CHANGE';

export const INTEGRATION_AREA_WIDGET_EMBED_FORM_ADD_NEW_BUSINESS =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_ADD_NEW_BUSINESS';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_BACKGROUND_COLOR_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_BACKGROUND_COLOR_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_BUSINESS_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_BUSINESS_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_COMMUNICATION_METHOD_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_COMMUNICATION_METHOD_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_DELETE_BUSINESS =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_DELETE_BUSINESS';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_EMPTY_MESSAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_EMPTY_MESSAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_EMAIL_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_EMAIL_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_PHONE_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_PHONE_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_HEADER_TEXT_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_HEADER_TEXT_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_HELPER_TEXT_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_HELPER_TEXT_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_EMAIL_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_EMAIL_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_PHONE_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_PHONE_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_LANGUAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_LANGUAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_MESSAGE_PLACEHOLDER_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_MESSAGE_PLACEHOLDER_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_FOR_BUSINESS_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_FOR_BUSINESS_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_TEXT_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_TEXT_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_RESET_STATE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_RESET_STATE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_BUTTON_TEXT_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_BUTTON_TEXT_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_NEW_MESSAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_NEW_MESSAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE';
export const INTEGRATION_AREA_WIDGET_EMBED_FORM_THANKYOU_MESSAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_EMBED_FORM_THANKYOU_MESSAGE_CHANGE';

export const INTEGRATION_AREA_WIDGET_MOBILE_BACKGROUND_COLOR_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_BACKGROUND_COLOR_CHANGE';
export const INTEGRATION_AREA_WIDGET_MOBILE_CUSTOM_TEXT_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_CUSTOM_TEXT_CHANGE';
export const INTEGRATION_AREA_WIDGET_MOBILE_LANGUAGE_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_LANGUAGE_CHANGE';
export const INTEGRATION_AREA_WIDGET_MOBILE_PHONE_NUMBER_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_PHONE_NUMBER_CHANGE';
export const INTEGRATION_AREA_WIDGET_MOBILE_RESET_STATE =
  'INTEGRATION_AREA_WIDGET_MOBILE_RESET_STATE';
export const INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_CHANGE';
export const INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE';
export const INTEGRATION_AREA_WIDGET_MOBILE_TEXT_COLOR_CHANGE =
  'INTEGRATION_AREA_WIDGET_MOBILE_TEXT_COLOR_CHANGE';
