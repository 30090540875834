import {fromJS} from 'immutable';

/**
 * Add a transfer message at the end of state's messages.
 *
 * @param {Object} state
 * @param {String} businessName
 * @param {String} senderName
 * @param {Date} date
 * @returns {Object} new state
 */
export default function addTransferMessage(state, {businessName, senderName, date}) {
  return state.update('messages', (messages) =>
    messages.push(
      fromJS({
        businessName,
        type: 'transfer',
        senderName,
        date
      })
    )
  );
}
