import PropTypes from 'prop-types';
import {Drawer} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CustomerProfileHeader from './customer-profile-header-component';
import CustomerProfileContent from './customer-profile-content-component';
import {whiteSmoke} from '../../../../../../../shared/style/colors';
import {CUSTOMER_PROFILE_DRAWER_TRANSITION_VALUE} from '../../../../../data/settings';

const StyledDrawer = withStyles({
  root: {
    inset: 'inherit !important'
  },
  paper: {
    backgroundColor: whiteSmoke,
    maxWidth: '460px',
    width: '100%',
    top: '50px'
  }
})(Drawer);

const CustomerProfileDrawerComponent = ({
  customerProfile,
  i18n,
  participationId,
  doCustomerProfileClose,
  doCustomerProfileEditClose,
  doCustomerProfileEditOpen,
  doCustomerProfileEditTextUpdate,
  doCustomerProfilePictureUpload
}) => {
  return (
    <StyledDrawer
      data-test-id="customer-profile-drawer"
      anchor="right"
      elevation={20}
      ModalProps={{hideBackdrop: true}}
      onClose={doCustomerProfileClose}
      open={customerProfile.showDrawer}
      transitionDuration={CUSTOMER_PROFILE_DRAWER_TRANSITION_VALUE}
    >
      <CustomerProfileHeader
        {...{
          customerProfile,
          i18n,
          participationId,
          doCustomerProfileClose,
          doCustomerProfileEditOpen,
          doCustomerProfilePictureUpload
        }}
      />

      <CustomerProfileContent
        {...{
          customerProfile,
          i18n,
          participationId,
          doCustomerProfileEditClose,
          doCustomerProfileEditTextUpdate
        }}
      />
    </StyledDrawer>
  );
};

CustomerProfileDrawerComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  participationId: PropTypes.string,
  doCustomerProfileClose: PropTypes.func.isRequired,
  doCustomerProfileEditClose: PropTypes.func.isRequired,
  doCustomerProfileEditOpen: PropTypes.func.isRequired,
  doCustomerProfileEditTextUpdate: PropTypes.func.isRequired,
  doCustomerProfilePictureUpload: PropTypes.func.isRequired
};

export default CustomerProfileDrawerComponent;
