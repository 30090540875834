import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';

const StyledBlockIcon = withStyles({
  root: {
    marginRight: '3px'
  }
})(BlockIcon);

/**
 * Finally, the component.
 */
const ContentModeratedComponent = ({i18n}) => (
  <span
    data-test-id="item-label"
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <StyledBlockIcon fontSize="small" />
    {i18n.t('thread.messages.messageModerated')}
  </span>
);

ContentModeratedComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ContentModeratedComponent;
