import {merge, of} from 'rxjs';
import {catchError, debounceTime, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {cloneDeep} from 'lodash';
import parseFilepath from 'parse-filepath';
import debug from '../../../../../../shared/lib/debug';
import {findMessageIndexInConversationItem} from '../../../../lib/thread/conversation-item-helper';
import getChannelSetting from '../../../../data/thread/channels-settings';
import getNewClientItemId from '../../../../lib/message-helper';
import {
  getCurrentConversationFromState,
  getSliceParams
} from './lib/customer-thread-conversations-helper';
import {getFilterIsCurrentPageInHeadingCustomers} from '../../../lib/route-helper';
import {getSendMessageActionFromMessageForm} from './lib/conversation-item-helper';
import {graphqlGetConversationQuery} from './graphql-queries/graphql-conversation-query-helper';
import {graphqlUpdateConversationStatusMutation} from './graphql-queries/graphql-update-thread-conversation-status-query-helper';
import {
  graphqlGetEmployeeStartCustomerConversationToEmailMutation,
  graphqlGetEmployeeStartCustomerConversationToPhoneMutation
} from './graphql-queries/graphql-new-conversation-query-helper';
import {isCurrentPageCustomerThread} from '../../../../lib/route-helper';
import {isEmployeeOnConversationType} from '../../../lib/conversation-helper';
import {postToServerTheMessageInConversation} from '../../../lib/conversation-item-helper';
import uploadFile from '../../../lib/upload-file';
import {
  CUSTOMER_CONVERSATION_MARK_AS_READ,
  CUSTOMER_THREAD_STATUS_BUTTON_CLICK,
  CUSTOMER_THREAD_STATUS_MANUAL_UPDATE,
  CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE,
  CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_CHANGED,
  CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_PERSIST,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED,
  CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD,
  CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE,
  CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_LOAD,
  CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED,
  CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE,
  CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_PAGINATE,
  CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_BUTTON_CLICK,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY_BUTTON_CLICK,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_START,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_BUTTON_CLICK,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_FAILURE,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION
} from '../../../../actions/customer-new-thread-actions';

import {
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
} from '../../../../actions/ext-actions';
import {DEBOUNCE_TIME_TYPING_THREAD} from '../../../../data/settings';
import {getCurrentDateTime} from '../../../../lib/date-time-helper';

const CustomerThreadVisibleEpic =
  ({command, graphql}) =>
  (action$, state$) => {
    const loadVisibleThreadOnLoadConversationsSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS),
      map(
        ({
          payload: {
            customer: {id: customerId},
            direction,
            fromId,
            invisibleDirection,
            invisibleFromId,
            participationId
          }
        }) => {
          return {
            type: CUSTOMER_THREAD_VISIBLE_LOAD,
            customerId,
            direction,
            fromId,
            invisibleDirection,
            invisibleFromId,
            participationId
          };
        }
      )
    );

    const loadVisibleThread = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_LOAD),
      mergeMap(({customerId, direction, messageCursor, participationId}) => {
        const conversationId = getCurrentConversationFromState(state$).get('id');
        const slice = getSliceParams(direction, messageCursor);

        return graphql(
          graphqlGetConversationQuery({conversationId, isInternal: false, slice})
        ).pipe(
          map(({conversation}) => {
            if (
              !isCurrentPageCustomerThread({conversationId, customerId, participationId, state$})
            ) {
              return {
                type: CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED,
                // useful only for debug
                conversationId,
                customerId,
                participationId
              };
            }

            return {
              type: CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
              payload: {
                data: {
                  conversation
                },
                params: {
                  messageCursor
                }
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    // UI does it optimistically in load in order to avoid counters clipping,
    // but we ensure that there is no loading error before doing it for real
    const markConversationVisibleAsReadOnLoadSuccess = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS),
      map(
        ({
          payload: {
            data: {conversation}
          }
        }) => ({
          type: CUSTOMER_CONVERSATION_MARK_AS_READ,
          payload: {
            conversation: {
              id: conversation.id
            },
            internal: false
          }
        })
      )
    );

    const markConversationVisibleAsReadOnNewMessageNotFromMe = action$.pipe(
      ofType(
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
      ),
      filter(({payload: {conversation}}) =>
        isEmployeeOnConversationType(state$, conversation, 'visible')
      ),
      map(({payload: {conversation}}) => ({
        type: CUSTOMER_CONVERSATION_MARK_AS_READ,
        payload: {
          conversation: {
            id: conversation.id
          },
          internal: false
        }
      }))
    );

    const paginateVisibleThread = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_PAGINATE),
      mergeMap(({direction, messageCursor}) => {
        const conversationId = getCurrentConversationFromState(state$).get('id');
        const slice = getSliceParams(direction, messageCursor);

        return graphql(
          graphqlGetConversationQuery({conversationId, isInternal: false, slice})
        ).pipe(
          map(({conversation}) => {
            return {
              type: CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS,
              payload: {
                data: {
                  conversation
                },
                params: {
                  direction
                }
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const persistMessageFormTextOnTextChanged = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_CHANGED),
      debounceTime(DEBOUNCE_TIME_TYPING_THREAD),
      map(({payload: {conversationId, text}}) => ({
        type: CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_PERSIST,
        payload: {
          conversationId,
          text
        }
      }))
    );

    const selectFileToUploadInTheForm = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_FILE_SELECT),
      delay(1), // necessary to let component render with some state change
      map(({file}) => {
        // Next lines could be split into a chain of actions, but for now I decided to keep it grouped
        const customerChannel = state$.value.getIn(['customer', 'thread', 'profile', 'channel']);

        // 1. Validate extension
        const extensionsSupported = getChannelSetting(customerChannel, 'extensionsSupported');
        if (extensionsSupported) {
          const {name} = file;
          const fileExtension = parseFilepath(name).ext.toLowerCase();

          if (!extensionsSupported.includes(fileExtension)) {
            debug(
              `'File extension not supported for channel "${customerChannel}", where filename = "${name}", extension = "${fileExtension}", and supported extensions = "${extensionsSupported.join()}"`
            );

            return {
              type: CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED
            };
          }
        }

        // 2. Validate file size
        const maxFileSize = getChannelSetting(customerChannel, 'maxFileSize');
        if (maxFileSize) {
          const {size} = file;

          if (size > maxFileSize) {
            debug(
              `'File size exceed max for channel "${customerChannel}", where file size = "${size}" and max file size = "${maxFileSize}"`
            );

            return {
              type: CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE
            };
          }
        }

        return {
          type: CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD,
          file
        };
      })
    );

    const retrySendMessageInVisibleThreadOnButtonClick = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY_BUTTON_CLICK),
      map(({payload: {clientItemId}}) => {
        const customerVisibleThreadState = state$.value.get('customerVisibleThread');

        const messageIndex = findMessageIndexInConversationItem(customerVisibleThreadState, {
          clientItemId
        });

        const failedAction = customerVisibleThreadState
          .getIn(['conversationItems', 'edges', messageIndex, 'node', '_sendAction'])
          .toJS();

        return {
          type: CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY,
          failedAction
        };
      })
    );

    const startSendMessageInVisibleThreadOnButtonClick = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_BUTTON_CLICK),
      map(({payload: {message}}) =>
        getSendMessageActionFromMessageForm(state$, CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE, message)
      )
    );

    const sendMessageInVisibleThread = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE),
      mergeMap((sendAction) => {
        // We voluntarily don't destructure too much inside the whole mergeMap() in order to better understand what we are handling
        const {
          payload: {conversation, message}
        } = sendAction;
        const previousStatus = getCurrentConversationFromState(state$).get('status');

        return postToServerTheMessageInConversation({
          graphql,
          conversation: {
            id: conversation.id,
            internal: false
          },
          message: {
            attachment: message.attachment,
            clientItemId: message.clientItemId,
            text: message.text
          }
        }).pipe(
          // Whatever is the result of send message, we ignore it if the employee left the app section "Customers"
          // Because all impacts are visible only in customer inbox/thread
          filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
          map(({sendMessageToConversation}) => ({
            type: CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS,
            payload: {
              conversation: {
                id: conversation.id,
                previousStatus
              },
              message: {
                clientItemId: message.clientItemId,
                cursor: sendMessageToConversation.cursor,
                id: sendMessageToConversation.id,
                sortId: sendMessageToConversation.sortId
              }
            }
          })),
          catchError((error) => {
            const failedAction = cloneDeep(sendAction);
            delete failedAction._googleAnalytics;

            return of({
              type: CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
              payload: {
                failedAction
              },
              error
            });
          })
        );
      })
    );

    const uploadFileInTheForm = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD),
      mergeMap(({file}) =>
        uploadFile({
          command,
          file,
          actions: {
            success: CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS,
            failure: CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE
          }
        })
      )
    );

    const clickButtonThreadStatus = action$.pipe(
      ofType(CUSTOMER_THREAD_STATUS_BUTTON_CLICK),
      map(({payload: {status}}) => {
        return {
          type: CUSTOMER_THREAD_STATUS_MANUAL_UPDATE,
          payload: {
            status
          }
        };
      })
    );

    const updateThreadStatus = action$.pipe(
      ofType(CUSTOMER_THREAD_STATUS_MANUAL_UPDATE),
      mergeMap(({payload: {status}}) => {
        const conversationId = getCurrentConversationFromState(state$).get('id');
        const clientItemId = getNewClientItemId();

        return graphql(
          graphqlUpdateConversationStatusMutation({
            conversationId,
            clientItemId,
            status
          })
        ).pipe(
          map(() => {
            return {
              type: CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
              payload: {
                conversation: {
                  id: conversationId
                },
                status
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const triggerLoadNewVisibleThreadOnNewOutbound = action$.pipe(
      ofType(
        CUSTOMER_NEW_CONVERSATION_VISIBLE_START,
        CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION
      ),
      map(({payload: {businessId}}) => {
        return {
          type: CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD,
          payload: {
            businessId
          }
        };
      })
    );

    const loadVisibleThreadNew = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD),
      map(({payload: {businessId}}) => {
        const businesses = state$.value.getIn(['account', 'businesses']);

        const business = businesses
          .find((bussinessElement) => {
            return bussinessElement.get('businessId') === businessId;
          })
          .toJS();

        return {
          type: CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS,
          payload: {
            business
          }
        };
      })
    );

    const startSendMessageOnNewConversationOnButtonClick = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_BUTTON_CLICK),
      map(({payload}) => {
        const newConversationSendMessagePayload = {
          ...payload,
          message: {
            ...payload.message,
            clientItemId: getNewClientItemId(),
            date: getCurrentDateTime()
          }
        };

        return {
          type: CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE,
          payload: newConversationSendMessagePayload
        };
      })
    );

    const sendMessageOnNewConversation = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE),
      mergeMap((sendAction) => {
        const {
          payload: {message}
        } = sendAction;

        const businessId = state$.value.getIn(['customerThread', 'business', 'businessId']);
        const customerProfile = state$.value.getIn(['customer', 'thread', 'profile']);
        const customerPhoneNumber = customerProfile.get('phoneNumber');
        const customerEmail = customerProfile.get('emailAddress');
        const {clientItemId} = message;

        const defaultSendMessageNewConversationParams = {
          businessId,
          clientItemId,
          message: {
            attachment: message.attachment ?? null,
            text: message.text
          }
        };

        const sendMessageNewConversation = customerPhoneNumber
          ? graphqlGetEmployeeStartCustomerConversationToPhoneMutation({
              ...defaultSendMessageNewConversationParams,
              customerPhoneNumber
            })
          : graphqlGetEmployeeStartCustomerConversationToEmailMutation({
              ...defaultSendMessageNewConversationParams,
              customerEmail
            });

        return graphql(sendMessageNewConversation).pipe(
          filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
          map((data) => {
            const resultFromNewConversationSendMessage = customerPhoneNumber
              ? data.employeeStartCustomerConversationToPhoneNumber
              : data.employeeStartCustomerConversationToEmail;

            const {conversation, customer} = resultFromNewConversationSendMessage;

            return {
              type: CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS,
              payload: {
                businessId,
                conversation,
                customer: {
                  id: customer.id
                }
              }
            };
          }),
          catchError((error) => {
            const failedAction = cloneDeep(sendAction);
            delete failedAction._googleAnalytics;

            return of({
              type: CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_FAILURE,
              payload: {
                failedAction
              },
              error
            });
          })
        );
      })
    );

    return merge(
      clickButtonThreadStatus,
      loadVisibleThread,
      loadVisibleThreadNew,
      loadVisibleThreadOnLoadConversationsSuccess,
      markConversationVisibleAsReadOnLoadSuccess,
      markConversationVisibleAsReadOnNewMessageNotFromMe,
      paginateVisibleThread,
      persistMessageFormTextOnTextChanged,
      retrySendMessageInVisibleThreadOnButtonClick,
      selectFileToUploadInTheForm,
      sendMessageInVisibleThread,
      sendMessageOnNewConversation,
      startSendMessageInVisibleThreadOnButtonClick,
      startSendMessageOnNewConversationOnButtonClick,
      triggerLoadNewVisibleThreadOnNewOutbound,
      updateThreadStatus,
      uploadFileInTheForm
    );
  };

export default CustomerThreadVisibleEpic;
