import {merge} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import i18n from 'i18next';
import {APP_BROWSER_NOTIFICATION_SHOW} from '../../actions/app-actions';
import {LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED} from '../../actions/ext-actions';
import {BROWSER_NOTIFICATION_DEBOUNCE_TIME} from '../../data/settings';

const LegacyBrowserNotificationEpic = () => (action$) => {
  const legacyNotifyOnNewMention = action$.pipe(
    ofType(LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED),
    debounceTime(BROWSER_NOTIFICATION_DEBOUNCE_TIME),
    map(({payload: {unreadMentionsCount}}) => ({
      type: APP_BROWSER_NOTIFICATION_SHOW,
      message: i18n.t('app.browserNotification.unreadMentions', {count: unreadMentionsCount})
    }))
  );

  return merge(legacyNotifyOnNewMention);
};

export default LegacyBrowserNotificationEpic;
