import context from '../context';
import EnhancedTextField from './enhanced-text-field-component';
import {inputStyle} from '../public/public-form-component';

const EmailFieldComponent = (props, {i18n}) => {
  const label = i18n.t('form.emailAddress');

  return (
    <EnhancedTextField
      data-test-id="email-input"
      autoComplete="email"
      floatingLabelText={label}
      hintText={label}
      maxLength={120}
      name="email"
      style={inputStyle}
      {...props}
    />
  );
};

EmailFieldComponent.contextTypes = context;

export default EmailFieldComponent;
