import {combineEpics} from 'redux-observable';
import combineReducers from 'redux-immutable-combine-reducers';
import ui from '../reducer/ui-reducer';
import uiEpic from '../epic/ui-epic';

/**
 * Create root epic, adding common ones.
 *
 * @param {...Object} epics
 * @returns {*}
 */
export function createRootEpic(...epics) {
  return combineEpics(...epics, uiEpic());
}

/**
 * Create root reducer, adding common ones.
 *
 * @param {Object} reducers
 * @returns {Function}
 */
export function createRootReducer(reducers) {
  return combineReducers({
    ui,
    ...reducers
  });
}
