import moment from 'moment';

/**
 * Format a connection time following some very custom rules.
 * Algorithm is intentionally kind of language-agnostic (simple usage of "if", minimal library)
 * in order to be easily compared with same feature's code on mobile.
 * @see https://instaply.atlassian.net/browse/AWI-4582
 * @param {Number} value time in millisecond
 * @returns {String}
 */
export const formatConnectionTime = (value) => {
  // Milliseconds to seconds
  const duration = Math.floor(value / 1000);

  const seconds = (duration % 3600) % 60;
  const minutes = Math.floor((duration % 3600) / 60);
  const hours = Math.floor(duration / 3600);

  const roundedMinutes = seconds >= 30 ? minutes + 1 : minutes;

  // Less than 1min (Show just seconds)
  if (duration < 60) {
    return `${seconds}sec`;
  }

  // Less than 59 minutes 30 seconds (Show just minutes)
  if (duration < 3600 - 30) {
    return `${roundedMinutes}min`;
  }

  // Show hours and minutes
  // eslint-disable-next-line no-nested-ternary
  const hoursNew = roundedMinutes === 60 ? hours + 1 : hours > 0 ? hours : 1;
  const roundedMinutesNew = roundedMinutes === 60 || hours === 0 ? 0 : roundedMinutes;

  const result = `${hoursNew}h`;

  return roundedMinutesNew === 0 ? result : `${result + `${roundedMinutes}`.padStart(2, '0')}min`;
};

/**
 * Custom relative time format.
 * @param {Object|Number|String} value
 * @param {Object} i18n
 * @param {String} i18nPrefix
 * @returns {String}
 */
export const formatRelativeDate = (value, i18n, i18nPrefix) => {
  const valueAsMoment = moment(value);

  if (!valueAsMoment.isValid()) {
    return '';
  }

  const now = moment();

  // Same day
  if (valueAsMoment.isSame(now, 'day')) {
    const midnight = now.clone().startOf('day');

    // midnight
    if (valueAsMoment.isSame(midnight)) {
      return valueAsMoment.format(i18n.t(`${i18nPrefix}.dateFormats.todayMidnight`));
    }

    // noon
    if (valueAsMoment.isSame(midnight.clone().add(12, 'hours'))) {
      return valueAsMoment.format(i18n.t(`${i18nPrefix}.dateFormats.todayNoon`));
    }

    // default
    return valueAsMoment.format(i18n.t(`${i18nPrefix}.dateFormats.todayDefault`));
  }

  // Yesterday
  if (valueAsMoment.isSame(now.clone().subtract(1, 'day'), 'day')) {
    return valueAsMoment.format(i18n.t(`${i18nPrefix}.dateFormats.yesterday`));
  }

  // Between 2 and 6 days
  if (now.diff(valueAsMoment, 'days') <= 6) {
    return valueAsMoment.format(i18n.t(`${i18nPrefix}.dateFormats.betweenTwoAndSixDays`));
  }

  // More than 6 days
  return valueAsMoment.format(i18n.t(`${i18nPrefix}.dateFormats.moreThanSixDays`));
};

/**
 * Get current date and time as string in same format as middle-end uses for its request response.
 * @return {String}
 */
export const getCurrentDateTime = () => moment().utc().toISOString();
export const getDateTimeLegacy = (value) => moment(value).toISOString();
