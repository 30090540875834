export const UNSUBSCRIBE_CONFIRM_FAILURE = 'UNSUBSCRIBE_CONFIRM_FAILURE';
export const UNSUBSCRIBE_CONFIRM_SUCCESS = 'UNSUBSCRIBE_CONFIRM_SUCCESS';

export const UNSUBSCRIBE_PARAMETERS_VALIDATE = 'UNSUBSCRIBE_PARAMETERS_VALIDATE';
export const UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE = 'UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE';
export const UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS = 'UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS';

export const UNSUBSCRIBE_RESUBSCRIBE = 'UNSUBSCRIBE_RESUBSCRIBE';
export const UNSUBSCRIBE_RESUBSCRIBE_FAILURE = 'UNSUBSCRIBE_RESUBSCRIBE_FAILURE';
export const UNSUBSCRIBE_RESUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_RESUBSCRIBE_SUCCESS';
