import {createReducer} from 'redux-immutablejs';
import reduceReducers from 'reduce-reducers';
import errorsHandler from '../../../shared/reducer/lib/errors-handler';
import {
  VERIFY_EMAIL_CONFIRM_FAILURE,
  VERIFY_EMAIL_CONFIRM_SUCCESS
} from '../actions/verify-actions';

export const DEFAULT_STATE = {
  verified: false,
  verifying: true,
  errors: []
};

export default reduceReducers(
  createReducer(DEFAULT_STATE, {
    /**
     * Verification success.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [VERIFY_EMAIL_CONFIRM_SUCCESS]: (state) => {
      return state.set('verified', true).set('verifying', false);
    },

    /**
     * Verification failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [VERIFY_EMAIL_CONFIRM_FAILURE]: (state) => {
      return state.set('verifying', false);
    }
  }),
  errorsHandler
);
