import PropTypes from 'prop-types';

const AdministrationLeftPanelTitleComponent = ({title}) => (
  <div data-test-id="admin-left-panel">
    <h1
      data-test-id="title"
      style={{
        borderBottom: '1px solid #d9dfe2',
        padding: '15px 20px',
        fontSize: '22px',
        color: '#333',
        lineHeight: '1.4em',
        fontWeight: '400'
      }}
    >
      {title}
    </h1>
  </div>
);

AdministrationLeftPanelTitleComponent.propTypes = {
  title: PropTypes.string.isRequired
};

export default AdministrationLeftPanelTitleComponent;
