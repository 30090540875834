export const ADMINISTRATION_BUSINESS_OPEN = 'ADMINISTRATION_BUSINESS_OPEN';

// Business profile

export const ADMINISTRATION_BUSINESS_INFORMATION_LOAD = 'ADMINISTRATION_BUSINESS_INFORMATION_LOAD';
export const ADMINISTRATION_BUSINESS_INFORMATION_LOAD_FAILURE =
  'ADMINISTRATION_BUSINESS_INFORMATION_LOAD_FAILURE';
export const ADMINISTRATION_BUSINESS_INFORMATION_LOAD_SUCCESS =
  'ADMINISTRATION_BUSINESS_INFORMATION_LOAD_SUCCESS';

// Channels page

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_OPEN =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_OPEN';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_FAILURE';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_FAILURE';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_FAILURE';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_FAILURE';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_FAILURE';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_SUCCESS';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_RESTART_DATA_TO_RECONNECT =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_RESTART_DATA_TO_RECONNECT';
