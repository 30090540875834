import PropTypes from 'prop-types';
import HeaderContentLayout from '../../../common/content-header/header-content-layout-component';
import ThreadHeaderCustomerInformation from './thread-header-customer-information-component';
import {CLICKABLE_AREAS} from '../../../../data/ui/clickable-areas';

const CustomerThreadHeaderComponent = ({
  customerProfile,
  enableCustomerProfile,
  i18n,
  isNewConversationWithNewCustomer,
  isLoadingCustomerThreadMetadata,
  doCustomerProfileShow,
  onUIAreaClick
}) => {
  const onClickConversationHeader = () => {
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.HEADER);
  };

  return (
    <HeaderContentLayout onClick={onClickConversationHeader}>
      <ThreadHeaderCustomerInformation
        {...{
          customerProfile,
          enableCustomerProfile,
          i18n,
          isNewConversationWithNewCustomer,
          isLoadingCustomerThreadMetadata,
          doCustomerProfileShow
        }}
      />
    </HeaderContentLayout>
  );
};

CustomerThreadHeaderComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  enableCustomerProfile: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoadingCustomerThreadMetadata: PropTypes.bool.isRequired,
  isNewConversationWithNewCustomer: PropTypes.bool.isRequired,
  doCustomerProfileShow: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired
};

export default CustomerThreadHeaderComponent;
