import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../shared/component/context';
import FormMessage from '../../../shared/component/form/form-message-component';
import getErrorMessage from '../../../shared/lib/get-graphql-error-message';
import getRouteParam from '../../../shared/lib/get-route-param';
import MailOutlineIcon from '../../../shared/component/icons/mail-outline-icon';
import PublicForm from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';
import StandardRaisedButton from '../../../shared/component/button/standard-raised-button-component';
import SUBSCRIPTION_TYPES from '../data/subscription-types';
import {iconSize} from '../../../shared/style/variables';

/**
 * Finally, the component.
 */
class UnsubscribeComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: getRouteParam('email', props.location),
      token: getRouteParam('token', props.location),
      type: getRouteParam('type', props.location)
    };

    this._doResubscribe = this._doResubscribe.bind(this);
  }

  componentDidMount() {
    const {doUnsubscribe} = this.props;
    const {email, token, type} = this.state;
    doUnsubscribe(email, token, type);
  }

  _getAccountSettingsLink(i18nPrefix) {
    const {i18n} = this.context;

    return (
      <a data-test-id="update-settings-link" href={`${OLD_WEBAPP_URL}/a#/account-settings/general`}>
        {i18n.t(`${i18nPrefix}.updateSettingsLink`)}
      </a>
    );
  }

  _doResubscribe() {
    const {doResubscribe} = this.props;
    const {token, type} = this.state;
    doResubscribe(token, type);
  }

  _getPublicPage(title, content) {
    const {language, doUIEndMount, doUIUpdateLanguage} = this.props;

    return (
      <PublicPage
        dataTestId="unsubscribe"
        {...{
          language,
          title,
          doUIEndMount,
          doUIUpdateLanguage
        }}
      >
        {content}
      </PublicPage>
    );
  }

  render() {
    const {i18n} = this.context;
    const {email, errors, step, submitting} = this.props;
    const {type} = this.state;

    const i18nPrefix = Object.keys(SUBSCRIPTION_TYPES).includes(type)
      ? `unsubscribe.${type}`
      : 'unsubscribe.invalidType';
    const subscriptionTypeConfig = SUBSCRIPTION_TYPES[type];

    const canSubscribeAgain = subscriptionTypeConfig
      ? subscriptionTypeConfig.canSubscribeAgain
      : false;
    const pageTitle = i18n.t(`${i18nPrefix}.${step}.title`);
    const errorMessage = getErrorMessage(errors, `${i18nPrefix}.${step}`, i18n);

    if (!subscriptionTypeConfig || errorMessage) {
      return this._getPublicPage(
        pageTitle,
        <PublicForm
          globalErrorMessage={errorMessage}
          showErrorMessage={false}
          showResultIcon
          title={i18n.t(`${i18nPrefix}.${step}.failure.title`)}
          buttons={this._getAccountSettingsLink(i18nPrefix)}
        >
          <FormMessage
            label={i18n.t(`${i18nPrefix}.${step}.failure.description`)}
            style={{
              marginTop: '20px'
            }}
          />
          <FormMessage
            label={i18n.t(`${i18nPrefix}.updateSettingsDescription`)}
            style={{
              marginTop: '20px'
            }}
          />
        </PublicForm>
      );
    }

    if (step === 'unsubscribe') {
      return this._getPublicPage(
        pageTitle,
        <PublicForm
          doSubmit={this._doResubscribe}
          showResultIcon={!submitting}
          submitting={submitting}
          submittingLabel={i18n.t(`${i18nPrefix}.unsubscribe.submitting`, {email})}
          title={submitting ? '' : i18n.t(`${i18nPrefix}.unsubscribe.success.title`)}
          buttons={
            canSubscribeAgain ? (
              <StandardRaisedButton
                label={i18n.t(`${i18nPrefix}.unsubscribe.success.subscribeAgainButton`)}
              />
            ) : (
              this._getAccountSettingsLink(i18nPrefix)
            )
          }
        >
          {submitting ? null : (
            <FormMessage
              dataTestId="unsubscribed"
              keepHtml
              label={`${i18n.t(`${i18nPrefix}.unsubscribe.success.description`, {
                email
              })}<br/><br/>${i18n.t(
                `${i18nPrefix}.unsubscribe.success.${
                  canSubscribeAgain
                    ? 'subscribeAgainButtonDescription'
                    : 'subscribeAgainFromAccountSettings'
                }`
              )}`}
              style={{
                marginTop: '20px'
              }}
            />
          )}
        </PublicForm>
      );
    }

    // resubscribe
    return this._getPublicPage(
      pageTitle,
      <PublicForm
        customSuccessIcon={<MailOutlineIcon fontSize={iconSize.xxxlarge} />}
        doSubmit={this._doResubscribe}
        showResultIcon={!submitting}
        submitting={submitting}
        submittingLabel={i18n.t(`${i18nPrefix}.resubscribe.submitting`, {email})}
        title={submitting ? '' : i18n.t(`${i18nPrefix}.resubscribe.success.title`)}
      >
        {submitting ? null : (
          <FormMessage
            dataTestId="resubscribed"
            keepHtml
            label={i18n.t(`${i18nPrefix}.resubscribe.success.description`, {email})}
            style={{
              marginTop: '20px'
            }}
          />
        )}
      </PublicForm>
    );
  }
}

UnsubscribeComponent.contextTypes = context;

UnsubscribeComponent.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  language: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
  step: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  doResubscribe: PropTypes.func.isRequired,
  doUnsubscribe: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired
};

export default UnsubscribeComponent;
