import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import context from '../context';
import {fontWeight} from '../../style/variables';

Highcharts.AST.allowedAttributes.push('data-test-id', 'focusable', 'viewBox');

NoDataToDisplay(Highcharts);

/**
 * Finally, the component.
 */
class HighchartsCustomComponent extends PureComponent {
  _renderNoDataErrorLabel = () => {
    const {i18n} = this.context;
    const {failed} = this.props;

    return failed ? i18n.t('common.errorDefault') : i18n.t('insight.content.common.noData');
  };

  render() {
    const {containerProps, loading, options} = this.props;

    return (
      <HighchartsReact
        highcharts={Highcharts}
        immutable
        options={{
          credits: {
            enabled: false
          },
          lang: {
            noData: loading ? '' : this._renderNoDataErrorLabel()
          },
          noData: {
            style: {
              fontWeight: fontWeight.normal
            }
          },
          ...options
        }}
        {...{
          containerProps
        }}
      />
    );
  }
}

HighchartsCustomComponent.contextTypes = context;

HighchartsCustomComponent.propTypes = {
  containerProps: PropTypes.objectOf(PropTypes.any).isRequired,
  failed: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired
};

export default HighchartsCustomComponent;
