import {PureComponent} from 'react';
import Draggable from 'react-draggable';
import timerHOC from 'react-timer-hoc';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide
} from '@material-ui/core';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import moment from 'moment';
import 'moment-duration-format';
import context from '../../../../../shared/component/context';
import themeV1 from '../../../../../shared/style/theme-v1';
import OrganizationAvatar from '../../common/avatar/organization-avatar-component';
import {avatarSize, fontSize, spacing} from '../../../../../shared/style/variables';

const BUTTON_LEFT_ICON_STYLES = {root: {marginRight: spacing.medium}};

const CallEndIconStyled = withStyles(BUTTON_LEFT_ICON_STYLES)(CallEndIcon);
const CallIconStyled = withStyles(BUTTON_LEFT_ICON_STYLES)(CallIcon);

const PaperDraggable = (props) => (
  <Draggable>
    <Paper
      style={{
        position: 'absolute',
        top: '60px',
        right: '50px',
        width: '350px'
      }}
      {...props}
    />
  </Draggable>
);

const Timer = timerHOC(1000)(({timer}) => (
  <span>{moment.duration(timer.tick, 'seconds').format('h:*mm:ss', {forceLength: true})}</span>
));

const TransitionSlideDown = (props) => <Slide direction="down" {...props} />;

// @see Non-modal Dialog: https://spectrum.chat/material-ui/help/non-modal-dialog~918cdc7f-7fca-4a65-a5ae-4a0c960a82e9
const DialogCustom = withStyles({
  root: {
    pointerEvents: 'none'
  },
  paper: {
    pointerEvents: 'auto'
  }
})((props) => (
  <MuiThemeProvider theme={themeV1}>
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      hideBackdrop
      PaperComponent={PaperDraggable}
      TransitionComponent={TransitionSlideDown}
      {...props}
    />
  </MuiThemeProvider>
));

/**
 * Finally, the component.
 */
class DialogIncomingCallComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {connectionFrom, connectionStatus, onAccept, onDecline, onEnd} = this.props;

    if (!connectionFrom) {
      return null;
    }

    const isPending = connectionStatus === 'pending';

    return (
      <DialogCustom open>
        <DialogTitle>{connectionFrom}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <span style={{display: 'flex', alignItems: 'center'}}>
              <OrganizationAvatar
                size={avatarSize.xxsmall}
                src={`${ASSETS_PATH}/img/logo/instaply-small-square.jpg`}
              />
              <span style={{marginLeft: spacing.small, fontSize: fontSize.small}}>
                {isPending ? i18n.t('app.twilio.incomingCall.title') : <Timer />}
              </span>
            </span>
          </DialogContentText>
        </DialogContent>

        {isPending ? (
          <DialogActions>
            <Button onClick={onDecline} color="secondary" variant="contained">
              <CallEndIconStyled />
              {i18n.t('app.twilio.incomingCall.declineButton')}
            </Button>
            <Button onClick={onAccept} color="primary" variant="contained">
              <CallIconStyled />
              {i18n.t('app.twilio.incomingCall.acceptButton')}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={onEnd} color="secondary" variant="contained">
              <CallEndIconStyled />
              {i18n.t('app.twilio.incomingCall.endButton')}
            </Button>
          </DialogActions>
        )}
      </DialogCustom>
    );
  }
}

DialogIncomingCallComponent.contextTypes = context;

DialogIncomingCallComponent.propTypes = {
  connectionFrom: PropTypes.string,
  connectionStatus: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired
};

export default DialogIncomingCallComponent;
