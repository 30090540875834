import {fromEvent, merge, of} from 'rxjs';
import {catchError, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import findBusinessById from '../../../../lib/find-business-by-id';
import {
  isBusinessInMyScope,
  isInboxFilteredByBusiness,
  isThreadCurrentlyOpened
} from '../../../../lib/customer-state-helper';
import searchOrganizationBusinesses from '../../../lib/search-organization-businesses-helper';
import {CUSTOMER_THREAD_CANNED_RESPONSES_LOAD} from '../../../../actions/customer-thread-canned-responses-actions';
import {
  LEGACY_CUSTOMER_THREAD_TRANSFER,
  LEGACY_CUSTOMER_THREAD_TRANSFER_EXPIRED,
  LEGACY_CUSTOMER_THREAD_TRANSFER_FAILURE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_FAILURE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_SUCCESS,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS
} from '../../../../actions/customer-thread-transfer-actions';
import {EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS} from '../../../../actions/ext-actions';
import {TRANSFER_SUCCESSFUL_EXPIRED_DELAY} from '../../../../data/settings';

const CustomerThreadTransferEpic =
  ({query, command}, socketio) =>
  (action$, state$) => {
    const employeeTransferredCustomerThreadToBusiness = fromEvent(
      socketio,
      'employee_transferred_customer_thread_to_business'
    ).pipe(
      mergeMap((message) => {
        const employeeUserId = message.employeeUserId.toString();
        const participationId = message.participationId.toString();
        const previousBusinessId = message.previousBusinessId.toString();
        const newBusinessId = message.newBusinessId.toString();

        const account = state$.value.get('account');
        const myBusinesses = account.get('businesses');

        const isDoneByOtherUser = employeeUserId !== account.get('id');
        const previousBusinessIsInMyScope = isBusinessInMyScope(myBusinesses, previousBusinessId);
        const newBusinessIsInMyScope = isBusinessInMyScope(myBusinesses, newBusinessId);
        const iAmWorkingInIt = isThreadCurrentlyOpened(state$, participationId);

        const inboxFilterBusinessId = state$.value.getIn(['customerInbox', 'businessId']);
        const inboxFilterScopeIsImpacted =
          isInboxFilteredByBusiness(state$) &&
          [previousBusinessId, newBusinessId].includes(inboxFilterBusinessId.toString());

        const actions = [
          {
            type: EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS,
            data: message,
            doneByOtherUserToCurrentThread: isDoneByOtherUser && iAmWorkingInIt,
            needInboxRefresh:
              isDoneByOtherUser &&
              (!previousBusinessIsInMyScope ||
                !newBusinessIsInMyScope ||
                inboxFilterScopeIsImpacted),
            newBusinessIsInMyScope,
            participationId
          }
        ];

        if (newBusinessIsInMyScope && iAmWorkingInIt) {
          actions.push({
            type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
            data: {
              directedToBusinessIdentifier: newBusinessId
            }
          });
        }

        return actions;
      })
    );

    const searchBusiness = searchOrganizationBusinesses(
      {
        action$,
        query,
        state$
      },
      {
        actionType: LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS,
        success: LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_SUCCESS,
        failure: LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS_FAILURE
      }
    );

    const transferCustomerThreadToBusiness = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_TRANSFER),
      mergeMap(({fullName, id, inScope, name, needInboxRefresh, next, participationId}) => {
        return command('transfer-thread-to-business', {
          businessId: id,
          participationId
        }).pipe(
          map(() => {
            const newInScopeBusiness = findBusinessById(
              id,
              state$.value.getIn(['account', 'businesses']).toJS()
            );
            const previousBusinessId = state$.value.getIn([
              'legacyCustomerThread',
              'directedToBusinessIdentifier'
            ]);

            return {
              type: LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS,
              next,
              needInboxRefresh: needInboxRefresh || !inScope || isInboxFilteredByBusiness(state$),
              newInScopeBusiness,
              params: {
                businessDeactivated: newInScopeBusiness
                  ? newInScopeBusiness.deactivated
                  : undefined, // 'undefined' is because we don't handle yet this information in basic transfer
                fullName,
                id,
                inScope,
                name
              },
              previousBusinessId
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_TRANSFER_FAILURE,
              error
            });
          })
        );
      })
    );

    const transferCustomerThreadToBusinessSuccessfulExpired = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS),
      delay(TRANSFER_SUCCESSFUL_EXPIRED_DELAY),
      map(() => {
        return {
          type: LEGACY_CUSTOMER_THREAD_TRANSFER_EXPIRED
        };
      })
    );

    const transferCustomerThreadToBusinessSuccessfulNextAction = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS),
      filter(({next}) => !!next),
      map(({next}) => next)
    );

    return merge(
      employeeTransferredCustomerThreadToBusiness,
      searchBusiness,
      transferCustomerThreadToBusiness,
      transferCustomerThreadToBusinessSuccessfulExpired,
      transferCustomerThreadToBusinessSuccessfulNextAction
    );
  };

export default CustomerThreadTransferEpic;
