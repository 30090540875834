import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {fontSize, iconSize} from '../../../../../../../../../shared/style/variables';
import {blue, grey, white} from '../../../../../../../../../shared/style/colors';

const ThreadWhatsAppTemplateItemComponent = ({
  innerStyle,
  onWhatsappTemplateClick,
  template: {text, title}
}) => {
  const _onClickWhatsAppTemplate = () => onWhatsappTemplateClick(text);

  return (
    <li
      data-test-id="list-item"
      style={{
        display: 'flex',
        paddingRight: '8px',
        justifyContent: 'space-between'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          padding: '13px 10px',
          width: '100%'
        }}
      >
        <div style={{paddingBottom: '8px', ...innerStyle}}>
          <h4 style={{color: grey}}>{title}</h4>
          <div
            style={{
              padding: '5px 0',
              lineHeight: '20px',
              fontSize: fontSize.medium
            }}
          >
            {text.split('\n').map((item, index) => (
              <p key={String(index)}>{item}</p>
            ))}
          </div>
        </div>
      </div>
      <div style={{alignSelf: 'center'}}>
        <IconButton
          data-test-id="send-message-button"
          onClick={_onClickWhatsAppTemplate}
          style={{
            borderRadius: '100%',
            background: blue,
            width: iconSize.xlarge,
            height: iconSize.xlarge,
            padding: '2px'
          }}
        >
          <ArrowForwardIcon style={{color: white, fontSize: '20px'}} />
        </IconButton>
      </div>
    </li>
  );
};

ThreadWhatsAppTemplateItemComponent.propTypes = {
  innerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  onWhatsappTemplateClick: PropTypes.func.isRequired,
  template: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ThreadWhatsAppTemplateItemComponent;
