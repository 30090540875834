import {auditTime, filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {HEADER_MENTIONS_POPUP_ITEMS_LOAD} from '../../../actions/header-actions';
import {
  HEADER_MENTIONS_POPUP_ITEMS_MISSED_RELOAD_COUNT_MAX,
  HEADER_MENTIONS_POPUP_ITEMS_NEW_LOAD_AUDIT_TIME,
  HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE
} from '../../../data/settings';

const conditionalReloadOfHeaderMentionsPopupMissedItems = (state$, action$, actionType, filterFn) =>
  action$.pipe(
    ofType(actionType),
    filter(() => state$.value.getIn(['headerMention', 'open'])),
    filter(filterFn),
    auditTime(HEADER_MENTIONS_POPUP_ITEMS_NEW_LOAD_AUDIT_TIME),
    map(() => ({
      type: HEADER_MENTIONS_POPUP_ITEMS_LOAD,
      payload: {
        before: true
      }
    }))
  );

const getBeforeFirst = (edgesSize, hasNextPage) => {
  if (
    edgesSize < HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE ||
    (edgesSize === HEADER_MENTIONS_POPUP_ITEMS_PER_PAGE && !hasNextPage)
  ) {
    return HEADER_MENTIONS_POPUP_ITEMS_MISSED_RELOAD_COUNT_MAX;
  }

  return Math.min(HEADER_MENTIONS_POPUP_ITEMS_MISSED_RELOAD_COUNT_MAX, edgesSize);
};

export {conditionalReloadOfHeaderMentionsPopupMissedItems, getBeforeFirst};
