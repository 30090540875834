import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/navigation/close';
import cloneDeep from 'lodash/cloneDeep';
import context from '../../../../../../../shared/component/context';
import {getI18NWidgetPrefix} from '../../lib/integration-area-widgets-helper';
import WidgetPage, {
  SECTION_TITLE_STYLE,
  SECTION_SUBTITLE_STYLE,
  SECTION_DESCRIPTION_STYLE,
  SECTION_BLOCK_STYLE,
  SECTION_CONTENT_STYLE,
  WIDGET_PAGES_COMMON_PROP_TYPES
} from './common/widget-page-component';
import {blue, red, slate} from '../../../../../../../shared/style/colors';

class EmbedFormWidgetComponent extends WidgetPage {
  constructor(props) {
    super(props);

    this.state = {
      containerId: 'iw-expanded-container',
      i18nWidgetPrefix: getI18NWidgetPrefix('embedForm'),
      scriptFile: 'expanded',
      widgetType: 'embedForm',
      snippetProps: [
        'defaultContact',
        'receiveByLabel',
        'contactChoice',
        'businessId',
        'lang',
        'bgColor',
        'color',
        'textBodyPlaceholder',
        'headerText',
        'sendButtonText',
        'businessesList',
        'errorEmptyMessage',
        'errorInvalidEmail',
        'errorInvalidPhone',
        'placeholderEmail',
        'placeholderPhone',
        'placeholderSelect',
        'customPlaceholderSelect',
        'sendNewMsg',
        'thankyouMsg'
      ]
    };

    this._doAddNewBusiness = this._doAddNewBusiness.bind(this);
    this._doDeleteBusiness = this._doDeleteBusiness.bind(this);
    this._doErrorEmptyMessageChange = this._doErrorEmptyMessageChange.bind(this);
    this._doHeaderTextChange = this._doHeaderTextChange.bind(this);
    this._doHelperTextChange = this._doHelperTextChange.bind(this);
    this._doMessagePlaceholderChange = this._doMessagePlaceholderChange.bind(this);
    this._doPlaceholderForBusinessChange = this._doPlaceholderForBusinessChange.bind(this);
    this._doPlaceholderSelectChange = this._doPlaceholderSelectChange.bind(this);
    this._doPlaceholderSelectTextChange = this._doPlaceholderSelectTextChange.bind(this);
    this._doSelectMultipleBusiness = this._doSelectMultipleBusiness.bind(this);
    this._doSendButtonTextChange = this._doSendButtonTextChange.bind(this);
    this._doSendNewMessageChange = this._doSendNewMessageChange.bind(this);
  }

  _doAddNewBusiness() {
    this.__updateWidget();

    this.props.doAddNewBusiness(true);
  }

  _doDeleteBusiness(businessId) {
    const {widgetWindow} = this.state;

    if (widgetWindow._iwq.businessesList) {
      widgetWindow._iwq.businessesList = widgetWindow._iwq.businessesList.filter(
        (b) => b.id !== businessId
      );
      if (widgetWindow._iwq.businessesList.length === 1) {
        widgetWindow._iwq.businessId = widgetWindow._iwq.businessesList[0].id;
        delete widgetWindow._iwq.textBodyPlaceholder;
        delete widgetWindow._iwq.businessesList;
      }
    }
    this.__updateWidget();

    this.props.doDeleteBusiness(businessId);
  }

  _doErrorEmptyMessageChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.errorEmptyMessage = newText;
    } else {
      delete widgetWindow._iwq.errorEmptyMessage;
    }
    this.__updateWidget();

    this.props.doChangeErrorEmptyMessage(newText);
  }

  _doSendNewMessageChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.sendNewMsg = newText;
    } else {
      delete widgetWindow._iwq.sendNewMsg;
    }
    this.__updateWidget();

    this.props.doChangeSendNewMessage(newText);
  }

  _doHeaderTextChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.headerText = newText;
    } else {
      delete widgetWindow._iwq.headerText;
    }
    this.__updateWidget();

    this.props.doChangeHeaderText(newText);
  }

  _doHelperTextChange(e, text) {
    const {widgetWindow} = this.state;

    if (text) {
      widgetWindow._iwq.receiveByLabel = text.replace(/\s{1,}/g, ' ');
    } else {
      delete widgetWindow._iwq.receiveByLabel;
    }
    this.__updateWidget();

    this.props.doChangeHelperText(text.trim());
  }

  _doMessagePlaceholderChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.textBodyPlaceholder = newText;
    } else {
      delete widgetWindow._iwq.textBodyPlaceholder;
    }
    this.__updateWidget();

    this.props.doChangeMessagePlaceholder(newText);
  }

  _doPlaceholderForBusinessChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();
    const index = e.target.dataset.key;
    widgetWindow._iwq.businessesList[index].placeholder = newText;

    if (!widgetWindow._iwq.placeholderSelect) {
      widgetWindow._iwq.textBodyPlaceholder = widgetWindow._iwq.businessesList[0].placeholder;
    } else {
      delete widgetWindow._iwq.textBodyPlaceholder;
    }

    this.__updateWidget();

    this.props.doChangePlaceholderForBusinessChange(index, newText);
  }

  _doPlaceholderSelectChange(e, value) {
    const {widgetWindow} = this.state;

    widgetWindow._iwq.placeholderSelect = value;

    if (value && this.props.textBodyPlaceholder) {
      widgetWindow._iwq.textBodyPlaceholder = this.props.textBodyPlaceholder;
    } else if (this.props.businesses.length > 1) {
      widgetWindow._iwq.textBodyPlaceholder = this.props.businesses[0].placeholder;
    } else {
      delete widgetWindow._iwq.textBodyPlaceholder;
    }

    this.__updateWidget();

    this.props.doChangePlaceholderSelect(value);
  }

  _doPlaceholderSelectTextChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.customPlaceholderSelect = newText;
    } else {
      delete widgetWindow._iwq.customPlaceholderSelect;
    }

    this.__updateWidget();
    this.props.doChangePlaceholderSelectText(newText);
  }

  _doSelectMultipleBusiness(e, i, businessId) {
    const {widgetWindow} = this.state;

    const business = this.props.businessesList.filter((b) => b.businessId == businessId)[0]; // eslint-disable-line eqeqeq

    const currentBusinesses = this.props.businesses.map((b) => {
      const currentBusiness = cloneDeep(b);
      delete currentBusiness.disabled;

      return currentBusiness;
    });
    if (currentBusinesses.length > 0) {
      widgetWindow._iwq.businessesList = currentBusinesses.concat({
        name: business.name,
        id: business.businessId,
        placeholder: ''
      });
      delete widgetWindow._iwq.businessId;
    } else {
      widgetWindow._iwq.businessId = businessId;
      delete widgetWindow._iwq.businessesList;
      delete widgetWindow._iwq.placeholderSelect;
      delete widgetWindow._iwq.customPlaceholderSelect;
    }
    this.__updateWidget();

    this.props.doChangeBusiness(business);
  }

  _doSendButtonTextChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.sendButtonText = newText;
    } else {
      delete widgetWindow._iwq.sendButtonText;
    }
    this.__updateWidget();

    this.props.doChangeSendButtonText(newText);
  }

  _renderErrorEmptyMessage() {
    const {i18n} = this.context;
    const {errorEmptyMessage} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.errorEmptyMessage`;
    const sanitizedErrorEmptyMessage = errorEmptyMessage.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-error-empty-message"
          defaultValue={sanitizedErrorEmptyMessage}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedErrorEmptyMessage ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doErrorEmptyMessageChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderSendNewMessage() {
    const {i18n} = this.context;
    const {sendNewMessage} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.sendNewMessage`;
    const sanitizedSendNewMessage = sendNewMessage.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-send-new-message"
          defaultValue={sanitizedSendNewMessage}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedSendNewMessage ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doSendNewMessageChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      addNewBusiness,
      businessesList,
      businessId,
      businesses,
      headerText,
      helperText,
      placeholderSelect,
      placeholderSelectText,
      sendButtonText,
      textBodyPlaceholder
    } = this.props;

    return this._renderLayout(
      'embed-form-wireframe.png',
      'embed-form.html',
      '360px',
      businessId || businesses.length,
      i18n.t('integrationArea.widgets.embedForm.finalResultAndCode.description'),
      <div>
        {this._renderBusinessSelectorIntroduction()}

        <div style={SECTION_CONTENT_STYLE}>
          {businesses.map((business, key) => (
            <span key={business.id} style={{display: 'flex', alignItems: 'flex-end', width: '90%'}}>
              <SelectField
                data-test-id="business-selector-select"
                disabled={business.disabled}
                floatingLabelFixed
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionBusiness.labelSelect'
                )}
                fullWidth
                hintText={i18n.t('integrationArea.widgets.embedForm.sectionBusiness.hintSelect')}
                name={`business-selector-${key}`}
                onChange={this._doSelectMultipleBusiness}
                style={{minWidth: '100%'}}
                value={business.id}
              >
                {businessesList.map(({businessId: businessListId, name}) => (
                  <MenuItem
                    data-test-id="select-option"
                    value={businessListId}
                    key={businessListId}
                    primaryText={name}
                  />
                ))}
              </SelectField>
              <IconButton
                className="material-icons"
                onClick={() => this._doDeleteBusiness(business.id)}
              >
                <Delete color={red} />
              </IconButton>
            </span>
          ))}

          {addNewBusiness ? (
            <span style={{display: 'flex', alignItems: 'flex-end', width: '90%'}}>
              <SelectField
                data-test-id="business-selector-select"
                floatingLabelFixed
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionBusiness.labelSelect'
                )}
                fullWidth
                hintText={i18n.t('integrationArea.widgets.embedForm.sectionBusiness.hintSelect')}
                onChange={this._doSelectMultipleBusiness}
                style={{minWidth: '100%'}}
              >
                {businessesList.map(({businessId: businessListId, name}) => (
                  <MenuItem
                    data-test-id="select-option"
                    value={businessListId}
                    key={businessListId}
                    primaryText={name}
                  />
                ))}
              </SelectField>
              {businesses.length ? (
                <IconButton className="material-icons" onClick={() => this._doDeleteBusiness(null)}>
                  <Delete color={red} />
                </IconButton>
              ) : null}
            </span>
          ) : null}

          <span
            tabIndex={-1}
            onClick={this._doAddNewBusiness}
            style={{
              display: 'block',
              marginTop: '20px',
              cursor: 'pointer'
            }}
          >
            {i18n.t('integrationArea.widgets.embedForm.sectionBusiness.addBusinessButton')}
          </span>
        </div>

        {this._renderSectionBehaviour()}

        {this._renderSectionStyleAndCustomize(
          <div>
            {this._renderLanguage()}

            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={headerText.trim()}
                floatingLabelFixed
                floatingLabelStyle={{color: headerText ? blue : slate}}
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.headerText.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.headerText.hint'
                )}
                onChange={this._doHeaderTextChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.headerText.description'
                )}
              </span>
            </div>

            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={textBodyPlaceholder.trim()}
                floatingLabelFixed
                floatingLabelStyle={{color: textBodyPlaceholder ? blue : slate}}
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.inputMessagePlaceholder.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.inputMessagePlaceholder.hint'
                )}
                onChange={this._doMessagePlaceholderChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.inputMessagePlaceholder.description'
                )}
              </span>
            </div>

            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={helperText.trim()}
                floatingLabelFixed
                floatingLabelStyle={{color: helperText ? blue : slate}}
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.helperText.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.helperText.hint'
                )}
                onChange={this._doHelperTextChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.helperText.description'
                )}
              </span>
            </div>

            {this._renderInputPlaceholderPhone()}
            {this._renderInputPlaceholderEmail()}
            {this._renderErrorEmptyMessage()}
            {this._renderErrorInvalidEmail()}
            {this._renderErrorInvalidPhone()}

            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={sendButtonText.trim()}
                floatingLabelFixed
                floatingLabelStyle={{color: sendButtonText ? blue : slate}}
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.sendButtonText.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.sendButtonText.hint'
                )}
                onChange={this._doSendButtonTextChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.embedForm.sectionStyleAndCustomize.sendButtonText.description'
                )}
              </span>
            </div>

            {this._renderThankYouMessage()}
            {this._renderSendNewMessage()}

            {businesses.length > 1 ? (
              <div style={SECTION_BLOCK_STYLE}>
                <h3 style={SECTION_TITLE_STYLE}>
                  {i18n.t(
                    'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderSelect.title'
                  )}
                </h3>
                <span style={SECTION_SUBTITLE_STYLE}>
                  <Checkbox
                    checked={placeholderSelect}
                    label={i18n.t(
                      'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderSelect.label'
                    )}
                    onCheck={this._doPlaceholderSelectChange}
                  />
                </span>
              </div>
            ) : null}

            {placeholderSelect ? (
              <div style={SECTION_BLOCK_STYLE}>
                <TextField
                  defaultValue={placeholderSelectText.trim()}
                  floatingLabelFixed
                  floatingLabelStyle={{color: placeholderSelectText ? blue : slate}}
                  floatingLabelText={i18n.t(
                    'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderSelectText.label'
                  )}
                  fullWidth
                  hintText={i18n.t(
                    'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderSelectText.hint'
                  )}
                  name="placeholder-select-text"
                  onChange={this._doPlaceholderSelectTextChange}
                />
                <span style={SECTION_DESCRIPTION_STYLE}>
                  {i18n.t(
                    'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderSelectText.description'
                  )}
                </span>
              </div>
            ) : null}

            {businesses.length > 1 ? (
              <div style={SECTION_BLOCK_STYLE}>
                <h3 style={SECTION_TITLE_STYLE}>
                  {i18n.t(
                    'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderByBusiness.title'
                  )}
                </h3>
                {businesses.map((business, key) => (
                  <TextField
                    data-key={key}
                    defaultValue={placeholderSelectText.trim()}
                    floatingLabelFixed
                    floatingLabelText={i18n.t(
                      'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderByBusiness.label',
                      {businessName: business.name}
                    )}
                    fullWidth
                    hintText={i18n.t(
                      'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderByBusiness.hint',
                      {businessName: business.name}
                    )}
                    key={business.id}
                    name="placeholder-for-business"
                    onChange={this._doPlaceholderForBusinessChange}
                    floatingLabelStyle={{
                      color: placeholderSelectText ? blue : slate,
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  />
                ))}
                <span style={SECTION_DESCRIPTION_STYLE}>
                  {i18n.t(
                    'integrationArea.widgets.embedForm.sectionStyleAndCustomize.placeholderByBusiness.description'
                  )}
                </span>
              </div>
            ) : null}

            {this._renderBackgroundColorPicker()}
          </div>
        )}
      </div>
    );
  }
}

EmbedFormWidgetComponent.contextTypes = context;

EmbedFormWidgetComponent.propTypes = {
  addNewBusiness: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
  businesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessId: PropTypes.string.isRequired,
  communicationMethod: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  placeholderSelect: PropTypes.bool.isRequired,
  placeholderSelectText: PropTypes.string.isRequired,
  sendButtonText: PropTypes.string.isRequired,
  sendNewMessage: PropTypes.string.isRequired,
  textBodyPlaceholder: PropTypes.string.isRequired,
  thankYouMessage: PropTypes.string.isRequired,
  doAddNewBusiness: PropTypes.func.isRequired,
  doChangeBackgroundColor: PropTypes.func.isRequired,
  doChangeBusiness: PropTypes.func.isRequired,
  doChangeCommunicationMethod: PropTypes.func.isRequired,
  doChangeErrorEmptyMessage: PropTypes.func.isRequired,
  doChangeErrorInvalidEmail: PropTypes.func,
  doChangeErrorInvalidPhone: PropTypes.func,
  doChangeHeaderText: PropTypes.func.isRequired,
  doChangeHelperText: PropTypes.func.isRequired,
  doChangeInputPlaceholderEmail: PropTypes.func.isRequired,
  doChangeInputPlaceholderPhone: PropTypes.func.isRequired,
  doChangeMessagePlaceholder: PropTypes.func.isRequired,
  doChangePlaceholderForBusinessChange: PropTypes.func.isRequired,
  doChangePlaceholderSelect: PropTypes.func.isRequired,
  doChangePlaceholderSelectText: PropTypes.func.isRequired,
  doChangeSendButtonText: PropTypes.func.isRequired,
  doChangeSendNewMessage: PropTypes.func.isRequired,
  doChangeThankYouMessage: PropTypes.func.isRequired,
  doDeleteBusiness: PropTypes.func.isRequired,
  ...WIDGET_PAGES_COMMON_PROP_TYPES
};

export default EmbedFormWidgetComponent;
