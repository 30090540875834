import PropTypes from 'prop-types';
import ContentArea from './content-area-component';
import {fontWeight} from '../../../../shared/style/variables';
import {snow} from '../../../../shared/style/colors';

const EmptyAreaWithLogoComponent = ({dataTestId, label}) => (
  <ContentArea
    style={{
      fontWeight: fontWeight.semiBold,
      color: snow
    }}
  >
    <img
      src={`${ASSETS_PATH}/img/logo/instaply-bubble.png`}
      width="141"
      height="122"
      alt="Instaply logo bubble"
    />
    <span data-test-id={dataTestId} style={{textAlign: 'center'}}>
      {label}
    </span>
  </ContentArea>
);

EmptyAreaWithLogoComponent.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export const Error404Content = ({i18n}) => (
  <EmptyAreaWithLogoComponent dataTestId="error-404" label={i18n.t('404Text')} />
);

Error404Content.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EmptyAreaWithLogoComponent;
