import PropTypes from 'prop-types';
import {useEffect} from 'react';
import context from '../../../../../../../../shared/component/context';
import debug from '../../../../../../../../shared/lib/debug';
import AdministrationOrganizationChannelsGbmAgentForm from './steps/administration-organization-channels-gbm-agent-component';
import AdministrationOrganizationChannelsGbmSignIn from './steps/administration-organization-channels-gbm-sign-in-component';
import ErrorMessageAdvanced from '../../../../../common/error-message-advanced-component';
import LoadingIndicator from '../../../../../../../../shared/component/loading-indicator-component';
import {ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES} from '../../../../../../data/administration/administration-organization-data';

const AdministrationOrganizationChannelsGbmComponent = (
  {
    gbmState: {agent, agentDraftFields, agentForm, error, loading},
    onAgentFormLogoRemoveButtonClick,
    onAgentFormLogoSelect,
    onAgentFormSubmitConfirmButtonClick,
    onAgentUpdateButtonClick,
    onAgentValidationCancelConfirmButtonClick,
    onGbmLayoutMounted,
    onGbmHandleDraftFields,
    organizationId
  },
  {i18n}
) => {
  const {
    LAUNCH_STATE_LAUNCHED,
    LAUNCH_STATE_PENDING,
    STATE_NEW,
    STATE_PENDING_AUTHORIZATION,
    VERIFICATION_STATE_PENDING
  } = ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES;
  const agentStatusRequiringForm = [
    LAUNCH_STATE_LAUNCHED,
    LAUNCH_STATE_PENDING,
    STATE_NEW,
    VERIFICATION_STATE_PENDING
  ];
  const errorDefaultMessage = i18n.t(
    'administration.organization.channels.flows.gbm.errorDefaultMessage'
  );

  useEffect(() => {
    onGbmLayoutMounted();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!agent) {
    debug('No agent found.');

    return <ErrorMessageAdvanced title={errorDefaultMessage} />;
  }

  if (agent.status === STATE_PENDING_AUTHORIZATION) {
    return <AdministrationOrganizationChannelsGbmSignIn {...{error, i18n, organizationId}} />;
  }

  if (agentStatusRequiringForm.includes(agent.status)) {
    return (
      <AdministrationOrganizationChannelsGbmAgentForm
        {...{
          agent,
          agentDraftFields,
          agentForm,
          i18n,
          onAgentFormLogoRemoveButtonClick,
          onAgentFormLogoSelect,
          onAgentFormSubmitConfirmButtonClick,
          onAgentUpdateButtonClick,
          onAgentValidationCancelConfirmButtonClick,
          onGbmHandleDraftFields
        }}
      />
    );
  }

  debug('Unhandled agent status:', agent);

  return <ErrorMessageAdvanced title={errorDefaultMessage} />;
};

AdministrationOrganizationChannelsGbmComponent.contextTypes = context;

AdministrationOrganizationChannelsGbmComponent.propTypes = {
  gbmState: PropTypes.objectOf(PropTypes.any).isRequired,
  onAgentFormLogoRemoveButtonClick: PropTypes.func.isRequired,
  onAgentFormLogoSelect: PropTypes.func.isRequired,
  onAgentFormSubmitConfirmButtonClick: PropTypes.func.isRequired,
  onAgentUpdateButtonClick: PropTypes.func.isRequired,
  onAgentValidationCancelConfirmButtonClick: PropTypes.func.isRequired,
  onGbmLayoutMounted: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  onGbmHandleDraftFields: PropTypes.func.isRequired
};

export default AdministrationOrganizationChannelsGbmComponent;
