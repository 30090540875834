import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import getNewClientItemId from '../../../../lib/message-helper';
import {
  CUSTOMER_THREAD_RELEASE_BUTTON_CLICK,
  CUSTOMER_THREAD_RELEASE,
  CUSTOMER_THREAD_RELEASE_FAILURE,
  CUSTOMER_THREAD_RELEASE_SUCCESS,
  CUSTOMER_THREAD_TAKE_BUTTON_CLICK,
  CUSTOMER_THREAD_TAKE,
  CUSTOMER_THREAD_TAKE_FAILURE,
  CUSTOMER_THREAD_TAKE_SUCCESS
} from '../../../../actions/customer-thread-assignment-actions';
import {getCurrentConversationFromState} from './lib/customer-thread-conversations-helper';
import {
  graphqlReleaseConversationMutation,
  graphqlTakeConversationMutation
} from './graphql-queries/graphql-thread-assignment-query-helper';

const CustomerThreadAssignmentEpic =
  ({graphql}) =>
  (action$, state$) => {
    const takeButtonClick = action$.pipe(
      ofType(CUSTOMER_THREAD_TAKE_BUTTON_CLICK),
      map(() => {
        return {
          type: CUSTOMER_THREAD_TAKE
        };
      })
    );

    const takeThread = action$.pipe(
      ofType(CUSTOMER_THREAD_TAKE),
      mergeMap(() => {
        const account = state$.value.get('account');
        const conversationId = getCurrentConversationFromState(state$).get('id');
        const clientItemId = getNewClientItemId();

        return graphql(graphqlTakeConversationMutation({clientItemId, conversationId})).pipe(
          map(() => {
            const displayName = account.get('fullName');
            const pictureHref = account.get('picture');
            const userId = account.get('id');

            return {
              type: CUSTOMER_THREAD_TAKE_SUCCESS,
              payload: {
                conversation: {
                  id: conversationId
                },
                employee: {
                  displayName,
                  id: userId,
                  pictureHref
                }
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_TAKE_FAILURE,
              error
            });
          })
        );
      })
    );

    const releaseButtonClick = action$.pipe(
      ofType(CUSTOMER_THREAD_RELEASE_BUTTON_CLICK),
      map(() => {
        return {
          type: CUSTOMER_THREAD_RELEASE
        };
      })
    );

    const releaseThread = action$.pipe(
      ofType(CUSTOMER_THREAD_RELEASE),
      mergeMap(() => {
        const conversationId = getCurrentConversationFromState(state$).get('id');
        const clientItemId = getNewClientItemId();

        return graphql(graphqlReleaseConversationMutation({clientItemId, conversationId})).pipe(
          map(() => {
            return {
              type: CUSTOMER_THREAD_RELEASE_SUCCESS,
              payload: {
                conversation: {
                  id: conversationId
                }
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_RELEASE_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(releaseButtonClick, releaseThread, takeButtonClick, takeThread);
  };

export default CustomerThreadAssignmentEpic;
