import {
  CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_PER_PAGE,
  CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_PER_PAGE
} from '../../../../../data/settings';

const containStatusOnCustomerInboxFilteredStatuses = (customerInboxState, status) => {
  const filteredStatuses = customerInboxState.get('statuses');
  const statusNew = status.new.toLowerCase();
  const statusPrevious = status.previous.toLowerCase();
  const statusesToQuery = [statusNew, statusPrevious];

  return statusesToQuery.some((filteredStatus) => filteredStatuses.includes(filteredStatus));
};

const getCurrentSearchTextAndCursor = (state$, section) => {
  const customerInboxSearchState = state$.value.getIn(['customerInbox', 'search']);

  return {
    cursor: customerInboxSearchState.getIn([section, 'items']).takeLast(1).getIn([0, 'cursor']),
    text: customerInboxSearchState.get('text')
  };
};

const getCurrentSearchTextAndOffsetLegacy = (state$, section) => {
  const customerInboxSearchState = state$.value.getIn(['customerInbox', 'search']);

  return {
    offset: customerInboxSearchState.getIn([section, 'items']).size,
    text: customerInboxSearchState.get('text')
  };
};

const getSearchSlice = (itemType, cursor) => {
  const first = {
    customers: CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_PER_PAGE,
    messages: CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_PER_PAGE
  }[itemType];

  if (cursor) {
    return {
      after: cursor,
      first
    };
  }

  return {
    first
  };
};

export {
  containStatusOnCustomerInboxFilteredStatuses,
  getCurrentSearchTextAndCursor,
  getCurrentSearchTextAndOffsetLegacy,
  getSearchSlice
};
