import {of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {DEBOUNCE_TIME_TYPING_FILTER} from '../../../../shared/data/settings';

/**
 * Reformat an array of businesses, stringifying their ids.
 * @param {Array} businesses
 * @returns {Array}
 */
const formatBusinesses = (businesses) =>
  businesses.reduce((formattedBusinesses, business) => {
    const formattedBusiness = {
      ...business,
      id: business.id.toString()
    };

    return [...formattedBusinesses, formattedBusiness];
  }, []);

export default function searchOrganizationBusinesses(
  {action$, query, state$},
  {actionType, failure, success}
) {
  return action$.pipe(
    ofType(actionType),
    debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
    mergeMap(({text}) => {
      const params = {
        employeeId: state$.value.getIn(['account', 'id']),
        limit: 9999,
        q: text
      };

      return query('search-organization-stores', params).pipe(
        map(({data: {items, itemsCount}}) => {
          return {
            type: success,
            data: {
              items: formatBusinesses(items),
              itemsCount
            },
            params
          };
        }),
        takeUntil(action$.pipe(ofType(actionType))),
        catchError((error) => {
          return of({
            type: failure,
            error
          });
        })
      );
    })
  );
}
