import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';
import Joyride, {ACTIONS} from 'react-joyride';
import themeV1 from '../../../../../shared/style/theme-v1';
import {getTutorialConfig} from '../../../data/tutorial/tutorial-settings';
import TutorialTooltip from './tutorial-tooltip-component';
import {blue} from '../../../../../shared/style/colors';

const TutorialComponent = ({doCloseTutorial, i18n, tutorial}) => {
  const {
    options: {spotlightStyles},
    persistPermanentlyAsShown,
    steps
  } = getTutorialConfig(tutorial, i18n);

  const callback = ({action}) => {
    if (action === ACTIONS.RESET) {
      persistPermanentlyAsShown();
      doCloseTutorial();
    }
  };

  return (
    <MuiThemeProvider theme={themeV1}>
      <Joyride
        disableCloseOnEsc
        disableOverlayClose
        disableScrolling
        {...{
          callback,
          steps
        }}
        styles={{
          options: {
            arrowColor: blue
          },
          spotlight: spotlightStyles
        }}
        tooltipComponent={(props) => <TutorialTooltip {...props} />}
      />
    </MuiThemeProvider>
  );
};

TutorialComponent.propTypes = {
  doCloseTutorial: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  tutorial: PropTypes.objectOf(PropTypes.any).isRequired
};

export default TutorialComponent;
