import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  ADMINISTRATION_BUSINESS_INFORMATION_LOAD_FAILURE,
  ADMINISTRATION_BUSINESS_INFORMATION_LOAD_SUCCESS,
  ADMINISTRATION_BUSINESS_OPEN
} from '../../../../actions/administration-business-actions';

export const DEFAULT_STATE = {
  businessBotConfiguration: {},
  businessProfile: null,
  loading: false
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  [ADMINISTRATION_BUSINESS_OPEN]: (state) =>
    state
      .set('businessBotConfiguration', fromJS({}))
      .set('businessProfile', null)
      .set('loading', true),

  [ADMINISTRATION_BUSINESS_INFORMATION_LOAD_FAILURE]: (state) => state.set('loading', false),

  [ADMINISTRATION_BUSINESS_INFORMATION_LOAD_SUCCESS]: (
    state,
    {businessBotConfiguration, businessProfileAsAdmin}
  ) =>
    state
      .set('businessBotConfiguration', fromJS(businessBotConfiguration))
      .set('businessProfile', fromJS(businessProfileAsAdmin))
      .set('loading', false)
});
