import {fromEvent, merge, of} from 'rxjs';
import {
  auditTime,
  catchError,
  delay,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap
} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  browserSupportsNotification,
  employeeSelectedBrowserNotificationPermission
} from '../lib/browser-notification-helper';
import {getEmployeeDesktopPreferences} from '../lib/employee-preferences';
import {isEmployeeOnConversationType} from './lib/conversation-helper';
import {isNewOrPreviousWaitingStatus} from './lib/conversation-status-helper';
import {
  EXT_CONVERSATION_STATUS_CHANGED,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
} from '../actions/ext-actions';
import {UI_END_MOUNT} from '../../../shared/actions/ui-actions';
import {
  APP_BROWSER_NOTIFICATION_BANNER_SHOW_FIRST,
  APP_NEW_RELEASE_BANNER_SHOW,
  APP_NEW_RELEASE_CHECK,
  APP_NEW_RELEASE_CHECK_FAILURE,
  APP_NEW_RELEASE_CHECK_SAME_REVISION,
  APP_NEW_RELEASE_IGNORE,
  APP_RELOAD,
  APP_SOCKET_RECONNECT,
  APP_STATE_BOOT_LOAD,
  APP_STATE_BOOT_LOAD_FAILURE,
  APP_STATE_BOOT_LOAD_RETRY,
  APP_STATE_BOOT_LOAD_SUCCESS,
  APP_STATE_REFRESH_LOAD,
  APP_STATE_REFRESH_LOAD_FAILURE,
  APP_STATE_REFRESH_LOAD_SUCCESS
} from '../actions/app-actions';
import {APP_STATE_REFRESH_LOAD_AUDIT_TIME, NEW_RELEASE_BANNER_CHECK_DELAY} from '../data/settings';

const AppEpic =
  ({query}, socketio, {getURL}) =>
  (action$, state$) => {
    const checkNewRelease = action$.pipe(
      ofType(APP_NEW_RELEASE_CHECK),
      // prevent banner to be removed in case of a minor release would follow a critical one but employee app isn't yet refreshed
      filter(() => !state$.value.getIn(['app', 'isVisibleNewReleaseBanner'])),
      mergeMap(() => {
        return getURL('/build.json', {ts: Date.now()}).pipe(
          map(({data: {forceRefresh, revision}}) => {
            if (revision === state$.value.getIn(['ui', 'revision'])) {
              // for debugging purpose, it's better to return an event rather than nothing
              return {
                type: APP_NEW_RELEASE_CHECK_SAME_REVISION
              };
            }

            if (forceRefresh) {
              return {
                type: APP_NEW_RELEASE_BANNER_SHOW
              };
            }

            // for debugging purpose, it's better to return an event rather than nothing
            return {
              type: APP_NEW_RELEASE_IGNORE
            };
          }),
          catchError((error) => {
            return of({
              type: APP_NEW_RELEASE_CHECK_FAILURE,
              error
            });
          })
        );
      })
    );

    const forwardToAppStateBootLoad = action$.pipe(
      ofType(APP_STATE_BOOT_LOAD_RETRY, UI_END_MOUNT),
      map(() => {
        return {
          type: APP_STATE_BOOT_LOAD
        };
      })
    );

    const getAppStateAtBoot = action$.pipe(
      ofType(APP_STATE_BOOT_LOAD),
      mergeMap(() => {
        return query('get-app-initial-state').pipe(
          map(({data}) => {
            return {
              type: APP_STATE_BOOT_LOAD_SUCCESS,
              data
            };
          }),
          catchError((error) => {
            return of({
              type: APP_STATE_BOOT_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const checkBrowserNotificationBannerAppearance = action$.pipe(
      ofType(APP_STATE_BOOT_LOAD_SUCCESS),
      filter(() => {
        // Do not change following conditions sort, except if you really understand what you are doing

        if (
          !browserSupportsNotification() ||
          employeeSelectedBrowserNotificationPermission() ||
          getEmployeeDesktopPreferences('notifAlreadyPrompted')
        ) {
          return false;
        }

        return state$.value.getIn(['account', 'emailVerified']);
      }),
      map(() => {
        return {
          type: APP_BROWSER_NOTIFICATION_BANNER_SHOW_FIRST
        };
      })
    );

    const forwardToAppStateRefreshOnSocketReconnect = action$.pipe(
      ofType(APP_SOCKET_RECONNECT),
      map(() => {
        return {
          type: APP_STATE_REFRESH_LOAD
        };
      })
    );

    const refreshAppState = action$.pipe(
      ofType(APP_STATE_REFRESH_LOAD),
      auditTime(APP_STATE_REFRESH_LOAD_AUDIT_TIME),
      mergeMap(() => {
        return query('get-app-initial-state').pipe(
          map(({data}) => {
            return {
              type: APP_STATE_REFRESH_LOAD_SUCCESS,
              oldData: {
                unreadMentionsCount: state$.value.getIn(['headerMention', 'unreadMentionsCount'])
              },
              data
            };
          }),
          catchError((error) => {
            return of({
              type: APP_STATE_REFRESH_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    // @todo Remove this refresh when counter - already present in socket event payload - will be usable
    const refreshAppStateOnConversationVisibleNewMessageNotFromMe = action$.pipe(
      ofType(
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
        EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
      ),
      filter(
        ({payload: {conversation}}) =>
          !isEmployeeOnConversationType(state$, conversation, 'visible')
      ),
      map(() => ({
        type: APP_STATE_REFRESH_LOAD
      }))
    );

    const refreshAppStateOnConversationVisibleStatusChnage = action$.pipe(
      ofType(EXT_CONVERSATION_STATUS_CHANGED),
      filter(({payload: {status}}) => isNewOrPreviousWaitingStatus(status)),
      map(() => ({
        type: APP_STATE_REFRESH_LOAD
      }))
    );

    const reloadApp = action$.pipe(
      ofType(APP_RELOAD),
      delay(1000), // let loading animation start
      tap(() => {
        document.location.reload(true);
      }),
      ignoreElements()
    );

    const spreadSocketReconnect = fromEvent(socketio, 'reconnect').pipe(
      map(() => {
        return {
          type: APP_SOCKET_RECONNECT
        };
      })
    );

    const triggerRegularlyNewReleaseCheck = action$.pipe(
      ofType(APP_NEW_RELEASE_CHECK, UI_END_MOUNT),
      delay(NEW_RELEASE_BANNER_CHECK_DELAY),
      map(() => {
        return {
          type: APP_NEW_RELEASE_CHECK
        };
      })
    );

    return merge(
      checkBrowserNotificationBannerAppearance,
      checkNewRelease,
      forwardToAppStateBootLoad,
      forwardToAppStateRefreshOnSocketReconnect,
      getAppStateAtBoot,
      refreshAppState,
      refreshAppStateOnConversationVisibleNewMessageNotFromMe,
      refreshAppStateOnConversationVisibleStatusChnage,
      reloadApp,
      spreadSocketReconnect,
      triggerRegularlyNewReleaseCheck
    );
  };

export default AppEpic;
