import {fromJS} from 'immutable';
import endsWith from 'lodash/endsWith';

export const resetErrors = (state) => {
  return state.set('errorContext', null).set('errors', fromJS([]));
};

/**
 * Handle backend errors.
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const errorsHandler = (state, action) => {
  const {type, context, errors} = action;

  if (endsWith(type, '_FAILURE') && errors) {
    return state.set('errorContext', fromJS(context)).set('errors', fromJS(errors));
  }

  return state;
};

export default errorsHandler;
