import PropTypes from 'prop-types';
import {useEffect} from 'react';
import AdministrationBusinessPageChannelGbmNotSetup from './administration-business-page-channels-gbm-not-setup-component';
import AdministrationBusinessPageChannelsConnect from '../administration-business-page-channels-connect-component';
import AdministrationBusinessPageChannelsConnectLayout from '../administration-business-page-channels-connect-layout-component';
import AdministrationBusinessPageChannelsLinkedTextComponent from '../administration-business-page-channels-lnked-text-component';
import LoadingIndicator from '../../../../../../../../../shared/component/loading-indicator-component';
import {setGbmSwitchButtonState} from './lib/gbm-business-location-ui-helper';
import {
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES,
  ADMINISTRATION_ORGANIZATION_CHANNELS
} from '../../../../../../../data/administration/administration-organization-data';
import {
  davysGrey,
  lightGrey,
  mediumGreen,
  veryLightGrey
} from '../../../../../../../../../shared/style/colors';

const AdministrationBusinessPageChannelGbm = ({
  gbmBusinessState,
  gbmOrganizationState,
  isBusinessManager,
  isManager,
  isSwitchButtonChecked,
  i18n,
  onAdministrationBusinessPageChannelsGbmMounted,
  onCheckSwitchButton,
  onOpenBusinessLocation
}) => {
  useEffect(() => {
    onAdministrationBusinessPageChannelsGbmMounted();
  }, []);

  const gbmOrganizationAgentStatus = gbmOrganizationState.agent.status;
  const {businessLocation, loading: businessLocationLoading} = gbmBusinessState;

  const showGbmItemNotYetSetupForOrgAndBusinessManager = isManager && isBusinessManager;
  const showGbmItemNotYetSetupForBusinessManager = isBusinessManager && !isManager;
  const showEmptyGbm = isManager && !isBusinessManager;

  const {LAUNCH_STATE_LAUNCHED, STATE_PENDING_AUTHORIZATION, VERIFICATION_STATE_UNVERIFIED} =
    ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES;

  const showGbmOptionForAgentNotSetup =
    (gbmOrganizationAgentStatus === STATE_PENDING_AUTHORIZATION &&
      showGbmItemNotYetSetupForOrgAndBusinessManager) ||
    showGbmItemNotYetSetupForBusinessManager;

  const gbmIcon = `${ASSETS_PATH}/img/icon/channel/GBM.svg`;
  const gbmTitle = i18n.t('administration.business.pages.channels.gbm.itemTitle');

  const commonGbmProgressProps = {
    labelButton: i18n.t('administration.business.pages.channels.gbm.connectionProgressLabelButton'),
    switchButtonColor: veryLightGrey
  };

  const defaultGbmConnectProps = {
    labelButton: i18n.t('administration.business.pages.channels.gbm.connectLabelButton'),
    switchButtonColor: davysGrey
  };

  let gbmConnectProps = {
    LAUNCH_STATE_LAUNCHED: {
      labelButton: i18n.t('administration.business.pages.channels.gbm.connectedLabelButton'),
      switchButtonColor: mediumGreen
    },
    VERIFICATION_STATE_UNSPECIFIED: commonGbmProgressProps,
    VERIFICATION_STATE_PENDING: commonGbmProgressProps,
    VERIFICATION_STATE_VERIFIED: commonGbmProgressProps,
    VERIFICATION_STATE_SUSPENDED_IN_GMB: commonGbmProgressProps,
    LAUNCH_STATE_UNSPECIFIED: commonGbmProgressProps,
    LAUNCH_STATE_PENDING_UNLAUNCH: commonGbmProgressProps,
    LAUNCH_STATE_UNLAUNCHED: defaultGbmConnectProps,
    STATE_NEW: defaultGbmConnectProps
  }[businessLocation.status];

  gbmConnectProps = gbmConnectProps ?? defaultGbmConnectProps;

  useEffect(() => {
    setGbmSwitchButtonState(businessLocation, onCheckSwitchButton);
  }, [businessLocation]);

  if (showEmptyGbm) {
    return null;
  }

  if (gbmOrganizationState.loading || businessLocationLoading) {
    return <LoadingIndicator />;
  }

  let notYetSetupProps = {
    i18n
  };
  if (
    gbmOrganizationAgentStatus === STATE_PENDING_AUTHORIZATION &&
    showGbmItemNotYetSetupForOrgAndBusinessManager
  ) {
    notYetSetupProps = {
      ...notYetSetupProps,
      dataTestId: 'administration-business-gbm-agent-not-yet-setup-org-business-manager',
      description: i18n.t('administration.business.pages.channels.gbm.notSetupDescription'),
      linkLabel: i18n.t('administration.business.pages.channels.gbm.itemTitle'),
      linkProps: {
        'data-test-id': 'administration-business-gbm-agent-link',
        href: ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.desktopAdminPageUrl
      }
    };
  }

  if (showGbmItemNotYetSetupForBusinessManager) {
    notYetSetupProps = {
      ...notYetSetupProps,
      dataTestId: 'administration-business-gbm-agent-not-yet-setup-business-manager',
      description: i18n.t(
        'administration.business.pages.channels.gbm.storeAdminNotSetupDescription'
      ),
      linkLabel: i18n.t('administration.business.pages.channels.gbm.instaplyLinkLabel'),
      linkProps: {
        'data-test-id': 'administration-business-gbm-agent-link',
        href: i18n.t('administration.business.pages.channels.gbm.storeAdminNotSetupLink'),
        target: '_blank'
      }
    };
  }

  if (showGbmOptionForAgentNotSetup) {
    return <AdministrationBusinessPageChannelGbmNotSetup {...notYetSetupProps} />;
  }

  if (
    ![VERIFICATION_STATE_UNVERIFIED, LAUNCH_STATE_LAUNCHED].includes(gbmOrganizationAgentStatus)
  ) {
    return null;
  }

  const commonLayoutProps = {
    'data-test-id': 'administration-business-gbm-connect',
    icon: gbmIcon,
    title: gbmTitle,
    wrapperStyle: {borderBottom: `1px solid ${lightGrey}`}
  };

  if (
    businessLocation.status === VERIFICATION_STATE_UNVERIFIED ||
    gbmOrganizationAgentStatus === VERIFICATION_STATE_UNVERIFIED
  ) {
    const i18nPrefix =
      'administration.business.pages.channels.gbm.errors.businessLocationUnverified';

    return (
      <AdministrationBusinessPageChannelsConnectLayout {...commonLayoutProps}>
        <AdministrationBusinessPageChannelsLinkedTextComponent
          description={i18n.t(`${i18nPrefix}.description`)}
          linkLabel={i18n.t(`${i18nPrefix}.linkLabel`)}
          linkProps={{
            'data-test-id': 'administration-business-gbm-agent-link',
            href: i18n.t(`${i18nPrefix}.linkHref`),
            target: '_blank'
          }}
          style={{fontSize: '13px'}}
        />
      </AdministrationBusinessPageChannelsConnectLayout>
    );
  }

  return (
    <AdministrationBusinessPageChannelsConnect
      switchButtonDataTestId="gbm-switch-button"
      switchButtonLabelDataTestId="gbm-button-label"
      onHandleChange={onOpenBusinessLocation}
      {...{isSwitchButtonChecked, ...gbmConnectProps}}
      {...commonLayoutProps}
    />
  );
};

AdministrationBusinessPageChannelGbm.propTypes = {
  gbmBusinessState: PropTypes.objectOf(PropTypes.any).isRequired,
  gbmOrganizationState: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isBusinessManager: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  isSwitchButtonChecked: PropTypes.bool.isRequired,
  onAdministrationBusinessPageChannelsGbmMounted: PropTypes.func.isRequired,
  onCheckSwitchButton: PropTypes.func.isRequired,
  onOpenBusinessLocation: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelGbm;
