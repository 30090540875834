import PropTypes from 'prop-types';
import {lightSlate} from '../../../../shared/style/colors';

const LoadingAreaComponent = ({content, dataTestId, height}) => (
  <div
    data-test-id={dataTestId}
    style={{
      height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      textAlign: 'center',
      color: lightSlate
    }}
  >
    {content}
  </div>
);

LoadingAreaComponent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
  dataTestId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default LoadingAreaComponent;
