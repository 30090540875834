import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import {black90Opacity} from '../../../../../shared/style/colors';

class SelectFileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHover: false
    };

    this._attachFile = this._attachFile.bind(this);
    this._fileChange = this._fileChange.bind(this);
    this._hideUploadIcon = this._hideUploadIcon.bind(this);
    this._mapChildren = this._mapChildren.bind(this);
    this._showUploadIcon = this._showUploadIcon.bind(this);
    this.fileRef = React.createRef();
  }

  _showUploadIcon() {
    this.setState({
      isHover: true
    });
  }

  _hideUploadIcon() {
    this.setState({
      isHover: false
    });
  }

  _attachFile() {
    const {isLoading} = this.props;
    if (isLoading) {
      return;
    }

    this.fileRef.click();
  }

  _fileChange() {
    const {doUploadFile} = this.props;
    doUploadFile(this.fileRef.files[0]);

    // Prevent error on cancel file choice when a previous one was successfully uploaded
    this.fileRef.value = '';
  }

  _mapChildren(child) {
    const {isHover} = this.state;
    const style = {
      opacity: isHover ? 0.5 : 1,
      borderRadius: '50%'
    };

    if (isHover) {
      style.backgroundColor = black90Opacity;
    }

    return React.cloneElement(child, {
      style
    });
  }

  render() {
    const {children, dataTestId, enabled, isLoading, isNewEditIcon} = this.props;
    const {isHover} = this.state;
    const iconLeftTopPosition = isNewEditIcon ? '32px' : '18px';

    const legacyEdithIconStyle = {
      color: 'white',
      position: 'absolute',
      top: iconLeftTopPosition,
      left: iconLeftTopPosition
    };
    const editIconStyle = isNewEditIcon
      ? {...legacyEdithIconStyle, height: '38px', width: '38px'}
      : legacyEdithIconStyle;

    if (!enabled) {
      return children;
    }

    return (
      <div
        onClick={this._attachFile}
        onMouseEnter={this._showUploadIcon}
        onMouseLeave={this._hideUploadIcon}
        tabIndex={-1}
        style={{
          cursor: isLoading ? null : 'pointer',
          outline: 'none'
        }}
      >
        <div style={{position: 'relative'}}>
          {isLoading ? (
            <LoadingIndicator size={60} style={{margin: 0}} />
          ) : (
            this._mapChildren(children)
          )}
          {isHover ? <EditIcon style={editIconStyle} /> : null}
        </div>
        <input
          data-test-id={dataTestId}
          onChange={this._fileChange}
          ref={(fileElement) => {
            this.fileRef = fileElement;

            return this.fileRef;
          }}
          style={{display: 'none'}}
          type="file"
        />
      </div>
    );
  }
}

SelectFileComponent.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNewEditIcon: PropTypes.bool.isRequired,
  doUploadFile: PropTypes.func.isRequired
};

export default SelectFileComponent;
