import endsWith from 'lodash/endsWith';
import platform from 'platform';

const checkPlatformName = (check) => platform.name && check(platform);

/**
 * Convenient enriched 'platform' info object.
 */
export default {
  isIE: checkPlatformName((platformDetected) => platformDetected.name === 'IE'),
  // Looks like the suffix 'Mobile' is not added on some browser name, even if used on mobile (like iPhone), so let's add each particular case
  isMobile: checkPlatformName(
    (platformDetected) =>
      platformDetected.product === 'iPhone' || endsWith(platformDetected.name, ' Mobile')
  ),
  isSafari: checkPlatformName((platformDetected) => platformDetected.name.indexOf('Safari') !== -1),
  osFamily: platform.os.family,
  osVersion: platform.os.version
};
