import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {isCurrentPageCustomerThread} from '../../../../lib/route-helper';
import onReceiveMessage from './lib/customer-thread-on-receive-message';
import sendMessage from '../../../lib/send-message';
import triggerTypingThreadDebouncedAction from '../../../lib/trigger-typing-thread-debounced-action';
import uploadFile from '../../../lib/upload-file';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_PERSIST,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_UPDATE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_FAILURE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-thread-invisible-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-thread-visible-actions';
import {EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD} from '../../../../actions/ext-actions';
import {THREAD_ITEMS_PER_PAGE} from '../../../../data/settings';

const LegacyCustomerThreadInvisibleEpic =
  ({command, query}, socketio) =>
  (action$, state$) => {
    const legacyEmployeeSentMessageToInvisibleThread = onReceiveMessage(
      socketio,
      state$,
      'employee_sent_message_to_invisible_thread',
      EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD,
      'invisible'
    );

    const legacyLoadInvisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD),
      mergeMap(({direction, fromId, participationId}) => {
        const params = {
          internal: true,
          limit: THREAD_ITEMS_PER_PAGE,
          direction,
          fromId,
          participationId
        };

        return query('get-conversation', params).pipe(
          map(({data}) => {
            if (!isCurrentPageCustomerThread({state$, participationId})) {
              return {
                type: LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_CANCELED,
                participationId // useful only for debug
              };
            }

            return {
              type: LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS,
              data,
              params
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const legacyLoadInvisibleThreadOnVisibleThreadLoad = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD),
      map(({invisibleDirection, invisibleFromId, participationId}) => {
        return {
          type: LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
          direction: invisibleDirection,
          fromId: invisibleFromId,
          participationId
        };
      })
    );

    const legacyPersistMessageFormText = triggerTypingThreadDebouncedAction(
      action$,
      LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_UPDATE,
      LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_PERSIST
    );

    const legacySendMessageInInvisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE),
      mergeMap((sendAction) => {
        const participationId = state$.value.getIn(['legacyCustomerThread', 'participationId']);

        return sendMessage({
          command,
          participationId,
          sendAction,
          nextActions: {
            success: LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_SUCCESS,
            failure: LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_FAILURE
          },
          extraParams: {
            internal: true,
            mentionedUserId: sendAction.mentionedUser ? sendAction.mentionedUser.userId : undefined
          },
          extraSuccessProps: {
            mentionedUserName: sendAction.mentionedUser
              ? sendAction.mentionedUser.displayName
              : null
          },
          callback: state$.value.getIn(['legacyCustomerInvisibleThread', 'hasMoreMessagesAfter'])
            ? {
                type: LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
                participationId
              }
            : null
        });
      })
    );

    const legacyUploadFileInInvisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD),
      mergeMap(({file}) =>
        uploadFile({
          command,
          file,
          actions: {
            success: LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_SUCCESS,
            failure: LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD_FAILURE
          }
        })
      )
    );

    return merge(
      legacyEmployeeSentMessageToInvisibleThread,
      legacyLoadInvisibleThread,
      legacyLoadInvisibleThreadOnVisibleThreadLoad,
      legacyPersistMessageFormText,
      legacySendMessageInInvisibleThread,
      legacyUploadFileInInvisibleThread
    );
  };

export default LegacyCustomerThreadInvisibleEpic;
