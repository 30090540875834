import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from '../lib/get-insights-map-dispatch-to-props';
import InsightContactCentersUsersComponent from '../../../../../component/content/insights/page/contact-centers/insight-users-component';

const InsightContactCentersUsersContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.get('insight'),
      (state) => state.get('insightBusinessFilter')
    ],
    (businesses, insight, insightBusinessFilter) => {
      return {
        accountBusinesses: businesses.toJS(),
        businessesSelection: insight.get('businessesSelection').toJS(),
        customAggregationPeriod: insight.get('customAggregationPeriod'),
        customPeriodEndDate: insight.get('customPeriodEndDate'),
        customPeriodStartDate: insight.get('customPeriodStartDate'),
        insight: insight.get('contactCentersUsers').toJS(),
        insightBusinessFilter: insightBusinessFilter.toJS(),
        loading: insight.get('loading'),
        periodTabSelected: insight.get('periodTabSelected')
      };
    }
  ),
  getInsightsMapDispatchToProps('contactCentersUsers')
)(InsightContactCentersUsersComponent);

export default InsightContactCentersUsersContainer;
