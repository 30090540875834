import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  FORGOTTEN_PASSWORD_SUBMIT,
  FORGOTTEN_PASSWORD_SUBMIT_FAILURE,
  FORGOTTEN_PASSWORD_SUBMIT_SUCCESS
} from '../actions/forgotten-password-actions';

const ForgottenPasswordEpic =
  ({graphql}) =>
  (action$) => {
    return action$.pipe(
      ofType(FORGOTTEN_PASSWORD_SUBMIT),
      mergeMap(({email, isFormValid}) => {
        if (!isFormValid) {
          return of({
            type: FORGOTTEN_PASSWORD_SUBMIT_FAILURE,
            errors: []
          });
        }

        return graphql('send-forgotten-password-email-mutation', {email}).pipe(
          map(() => {
            return {
              type: FORGOTTEN_PASSWORD_SUBMIT_SUCCESS
            };
          }),
          catchError((errors) => {
            return of({
              type: FORGOTTEN_PASSWORD_SUBMIT_FAILURE,
              errors
            });
          })
        );
      })
    );
  };

export default ForgottenPasswordEpic;
