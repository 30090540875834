import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD} from '../../../../actions/customer-thread-invisible-actions';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_CLOSE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE
} from '../../../../actions/customer-thread-invisible-mention-popup-actions';

export const DEFAULT_STATE = {
  loading: false,
  open: false,
  text: '',
  items: []
};

export default createReducer(DEFAULT_STATE, {
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD]: (state) => {
    return state.set('open', false);
  },

  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE]: (state, {text}) => {
    return state
      .set('items', fromJS([]))
      .set('loading', true)
      .set('text', text || '');
  },

  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS]: (state, {items}) => {
    return state.set('items', fromJS(items)).set('loading', false);
  },

  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE]: (state) => {
    return state.set('open', false);
  },

  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN]: (state) => {
    return state.set('open', true);
  },

  [LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_CLOSE]: (state) => {
    return state.set('open', false);
  }
});
