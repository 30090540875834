import {Component} from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import DoneIcon from 'material-ui/svg-icons/action/done';
import ForwardIcon from 'material-ui/svg-icons/content/forward';
import IconButton from 'material-ui/IconButton';
import context from '../../../../../../shared/component/context';
import HeaderContentLayout from '../../../common/content-header/header-content-layout-component';
import LegacyThreadHeaderCustomerCustomerInformation from './legacy-thread-header-customer-information-component';
import ThreadTransferPopup from '../../../../container/content/customer/thread/customer-thread-transfer-popup-container';
import {fontSize, fontWeight} from '../../../../../../shared/style/variables';
import {darkSmoke, lightSlate, green} from '../../../../../../shared/style/colors';

const STATUS_DATA = [
  {
    key: 'waiting',
    label: 'customerThread.header.statuses.waiting'
  },
  {
    key: 'replied',
    label: 'customerThread.header.statuses.replied'
  },
  {
    key: 'resolved',
    label: 'customerThread.header.statuses.resolved'
  }
];

const disableTransfer = (props) =>
  props.isNewThread ||
  props.loading ||
  !props.participationId ||
  props.readOnly ||
  props.transferRecentlySuccessful ||
  props.transferTransferring;

/**
 * Finally, the component.
 */
class LegacyCustomerThreadHeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transferAnchorEl: null
    };

    this._onDropDownMenuChange = this._onDropDownMenuChange.bind(this);
    this._onClickTransferButton = this._onClickTransferButton.bind(this);
  }

  _getColorForTransferDisabled = (transferDisabled) => {
    return transferDisabled ? darkSmoke : 'inherit';
  };

  _onDropDownMenuChange(events, k, payload) {
    const {participationId, status, doUpdateManuallyThreadStatus} = this.props;

    if (status === payload) {
      return;
    }

    doUpdateManuallyThreadStatus(participationId, status, payload);
  }

  _onClickTransferButton(event) {
    event.preventDefault();
    const {doTransferPopupToggle} = this.props;

    if (disableTransfer(this.props)) {
      return;
    }

    this.setState({
      transferAnchorEl: event.currentTarget
    });

    doTransferPopupToggle();
  }

  render() {
    const {i18n} = this.context;
    const {
      customerChannel,
      directedToBusinessName,
      fullName,
      isSmsPhoneNumber,
      loading,
      organizationPicture,
      participationId,
      participantName,
      readOnly,
      status,
      transferRecentlySuccessful,
      transferShowLabel,
      updatingStatus,
      userId
    } = this.props;
    const {transferAnchorEl} = this.state;

    const marginBetweenBusinessNameAndItsEventualPrefix = customerChannel ? '5px' : 0;
    const transferDisabled = disableTransfer(this.props);

    return (
      <HeaderContentLayout>
        <LegacyThreadHeaderCustomerCustomerInformation
          {...{
            customerChannel,
            directedToBusinessName,
            i18n,
            marginBetweenBusinessNameAndItsEventualPrefix,
            participantName
          }}
        />

        <div
          style={{
            display: 'flex',
            flex: 1,
            maxWidth: transferShowLabel ? '350px' : '290px',
            marginRight: transferShowLabel ? '25px' : '13px',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {status ? (
            <DropDownMenu
              data-test-id="thread-visible-status-select"
              value={status}
              onChange={this._onDropDownMenuChange}
              autoWidth={false}
              disabled={!participationId || loading || readOnly || updatingStatus}
              // disabled dom property looks bugged with current material ui version > adding new property for test
              data-disabled={!participationId || loading || readOnly ? true : undefined}
              style={{width: '150px', textAlign: 'right'}}
              iconStyle={{top: 0}}
              underlineStyle={{border: 0}}
              labelStyle={{
                lineHeight: '48px',
                paddingLeft: '15px',
                fontSize: fontSize.medium,
                fontWeight: fontWeight.semiBold
              }}
            >
              {STATUS_DATA.map(({key, label}) => (
                <MenuItem
                  data-test-id="select-option"
                  key={key}
                  value={key}
                  primaryText={i18n.t(label)}
                  style={{fontSize: fontSize.medium}}
                />
              ))}
            </DropDownMenu>
          ) : null}

          <div
            data-test-id="transfer-button"
            disabled={transferDisabled}
            role="button"
            tabIndex={-1}
            onClick={this._onClickTransferButton}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              width: transferShowLabel ? '120px' : null,
              fontSize: fontSize.medium,
              fontWeight: fontWeight.semiBold,
              color: transferRecentlySuccessful
                ? green
                : this._getColorForTransferDisabled(transferDisabled)
            }}
          >
            <IconButton>
              {transferRecentlySuccessful ? (
                <DoneIcon data-test-id="done" color={green} />
              ) : (
                <ForwardIcon color={transferDisabled ? darkSmoke : lightSlate} />
              )}
            </IconButton>
            {transferShowLabel ? (
              <span>
                {i18n.t(
                  transferRecentlySuccessful
                    ? 'customerThread.transfer.transferred'
                    : 'customerThread.transfer.threadHeaderLabel'
                )}
              </span>
            ) : null}
          </div>

          {loading ? null : (
            <ThreadTransferPopup
              anchorEl={transferAnchorEl}
              {...{
                fullName,
                isSmsPhoneNumber,
                organizationPicture,
                participationId,
                userId
              }}
            />
          )}
        </div>
      </HeaderContentLayout>
    );
  }
}

LegacyCustomerThreadHeaderComponent.contextTypes = context;

LegacyCustomerThreadHeaderComponent.propTypes = {
  customerChannel: PropTypes.string,
  directedToBusinessName: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  isSmsPhoneNumber: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  organizationPicture: PropTypes.string,
  participantName: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  status: PropTypes.string,
  transferRecentlySuccessful: PropTypes.bool.isRequired,
  transferShowLabel: PropTypes.bool.isRequired,
  updatingStatus: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  doTransferPopupToggle: PropTypes.func.isRequired,
  doUpdateManuallyThreadStatus: PropTypes.func.isRequired
};

export default LegacyCustomerThreadHeaderComponent;
