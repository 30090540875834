import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {List, ListItem} from 'material-ui/List';
import IconButton from 'material-ui/IconButton/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import context from '../../../../../../shared/component/context';
import EnhancedPopover from '../../../common/enhanced-popover-component';
import InviteButton from '../../../global/invitation/global-invitation-invite-button-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import UserAvatar from '../../../common/avatar/user-avatar-component';
import {animationSpeed, avatarSize, fontSize} from '../../../../../../shared/style/variables';
import {smoke, lightSlate, lightSnow, blue, black} from '../../../../../../shared/style/colors';

class LegacyInvisibleThreadMentionComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };

    this._doOpenPopin = this._doOpenPopin.bind(this);
    this._doSearchColleague = this._doSearchColleague.bind(this);
  }

  componentDidUpdate(prev) {
    /* eslint-disable react/destructuring-assignment */
    if (this.props.open && !prev.open) {
      setTimeout(() => {
        if (this._textInput) {
          this._textInput.focus();
        }
      }, animationSpeed.medium);
    }
    /* eslint-enable react/destructuring-assignment */
  }

  onClickColleagueInvitationPopupOpenButton = () => {
    const {doColleagueInvitationPopupOpen, doThreadInvisibleMentionPopupClose} = this.props;
    doThreadInvisibleMentionPopupClose();
    doColleagueInvitationPopupOpen();
  };

  _renderColleagueList = () => {
    const {i18n} = this.context;
    const {items, doThreadInvisibleMentionPopupSelect} = this.props;

    return items.length ? (
      items.map((user) => {
        return (
          <ListItem
            key={user.userId}
            onClick={() => doThreadInvisibleMentionPopupSelect(user)}
            innerDivStyle={{width: '100%'}}
            primaryText={
              <span
                data-test-id="item-label"
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {user.displayName}
              </span>
            }
            leftAvatar={
              <UserAvatar
                size={avatarSize.xsmall}
                src={user.pictureHref}
                userIconStyle={{
                  position: 'absolute',
                  left: '16px',
                  top: '8px'
                }}
              />
            }
          />
        );
      })
    ) : (
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        {i18n.t('common.noResult')}
      </span>
    );
  };

  _doOpenPopin(event) {
    event.preventDefault();
    const {userToMention, doThreadInvisibleMentionPopupOpen} = this.props;

    if (userToMention) {
      return;
    }

    this.setState(
      {
        anchorEl: event.currentTarget
      },
      () => {
        doThreadInvisibleMentionPopupOpen();
      }
    );
  }

  _doSearchColleague(event) {
    const {directedToBusinessIdentifier, doThreadInvisibleMentionPopupSearch} = this.props;
    doThreadInvisibleMentionPopupSearch(directedToBusinessIdentifier, event.target.value);
  }

  render() {
    const {i18n} = this.context;
    const {
      directedToBusinessName,
      open,
      loading,
      items,
      text,
      userToMention,
      doThreadInvisibleMentionPopupClose,
      doThreadInvisibleMentionUnselect
    } = this.props;
    const {anchorEl} = this.state;

    const showColleagueInvite = !loading && !items.length;

    return (
      <span style={{display: 'flex'}}>
        <EnhancedPopover
          dataTestId="mention-popup"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{horizontal: 'middle', vertical: 'top'}}
          targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
          onRequestClose={doThreadInvisibleMentionPopupClose}
          style={{
            minHeight: '300px',
            maxHeight: '300px',
            height: '300px',
            width: '300px',
            maxWidth: '300px'
          }}
        >
          <h3
            data-test-id="search-a-colleague-to-mention-title"
            style={{padding: '15px 15px 0 15px'}}
          >
            {i18n.t('customerThread.invisibleThread.mentionPopup.title', {
              businessName: directedToBusinessName
            })}
          </h3>
          <span
            style={{
              display: 'flex',
              padding: '0px 15px 10px 15px'
            }}
          >
            <input
              data-test-id="search-a-colleague-to-mention-input"
              ref={(itself) => {
                this._textInput = itself;

                return this._textInput;
              }}
              value={text}
              placeholder={i18n.t(
                'customerThread.invisibleThread.mentionPopup.searchInputPlaceholder'
              )}
              onChange={this._doSearchColleague}
              style={{
                boxSizing: 'border-box',
                width: '100%',
                marginTop: '8px',
                padding: '7px 32px 7px 12px',
                outline: 'none',
                border: 'none',
                borderRadius: '5px',
                backgroundColor: lightSnow,
                fontSize: fontSize.medium
              }}
            />
          </span>
          <List
            data-test-id="mention-colleagues-list"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              maxHeight: showColleagueInvite ? '180px' : '220px',
              minHeight: '180px',
              overflow: 'scroll',
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            {loading ? (
              <LoadingIndicator color={lightSlate} size={30} />
            ) : (
              this._renderColleagueList()
            )}
          </List>

          {showColleagueInvite ? (
            <InviteButton
              color={blue}
              label={i18n.t('colleagueInvitation.longLinkLabel')}
              doColleagueInvitationPopupOpen={this.onClickColleagueInvitationPopupOpenButton}
              style={{
                marginTop: '10px'
              }}
            />
          ) : null}
        </EnhancedPopover>

        <span onClick={this._doOpenPopin}>
          {userToMention ? (
            <span
              style={{
                display: 'flex',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginBottom: '3px',
                marginLeft: '3px',
                padding: '3px 3px 3px 10px',
                borderRadius: '50px',
                lineHeight: '20px',
                whiteSpace: 'nowrap',
                background: smoke
              }}
            >
              <span
                data-test-id="mention-pill-colleague-to-mention"
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '60px',
                  whiteSpace: 'nowrap'
                }}
              >
                {userToMention.displayName}
              </span>
              <IconButton
                data-test-id="mention-remove-mention-pill-button"
                tabIndex={-1}
                onClick={doThreadInvisibleMentionUnselect}
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '5px',
                  padding: 0,
                  background: 'transparent'
                }}
                iconStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '0px',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50px',
                  background: lightSlate,
                  color: smoke
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          ) : (
            <IconButton
              data-test-id="mention-a-colleague-button"
              tabIndex={-1}
              style={{
                width: '33px',
                height: '33px',
                padding: 0,
                background: 'transparent'
              }}
              iconStyle={{
                width: '25px',
                height: '25px',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '5px',
                position: 'relative',
                borderRadius: '50px',
                fontSize: '18px',
                color: black
              }}
            >
              <span>@</span>
            </IconButton>
          )}
        </span>
      </span>
    );
  }
}

LegacyInvisibleThreadMentionComponent.propTypes = {
  directedToBusinessIdentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  directedToBusinessName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  userToMention: PropTypes.objectOf(PropTypes.any),
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupClose: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupOpen: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupSearch: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupSelect: PropTypes.func.isRequired,
  doThreadInvisibleMentionUnselect: PropTypes.func.isRequired
};

LegacyInvisibleThreadMentionComponent.contextTypes = context;

export default LegacyInvisibleThreadMentionComponent;
