import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {slate} from '../../../../../shared/style/colors';

const StyledTypography = withStyles({
  h3: {
    color: slate
  }
})(Typography);

const CardTitleComponent = ({text, ...props}) => (
  <StyledTypography component="h3" noWrap variant="h3" title={text} {...props}>
    {text}
  </StyledTypography>
);
CardTitleComponent.propTypes = {
  'data-test-id': PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default CardTitleComponent;
