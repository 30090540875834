import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import {fontSize} from '../../../../../../../../../../../shared/style/variables';
import {black, lightSlate} from '../../../../../../../../../../../shared/style/colors';

const StyledTextField = withStyles({
  root: {
    '& .MuiInput-underline:hover:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
    },
    fontSize: fontSize.xlarge,
    marginBottom: '1em'
  }
})(TextField);

const LegacyPaymentCustomerComponent = ({
  customer,
  i18n,
  onAppPaymentCustomerChange,
  participationId
}) => {
  const labelStyles = {
    fontSize: fontSize.xxxlarge,
    color: lightSlate
  };

  const inputStyles = {
    color: black
  };

  const customerData = {
    firstName: customer && customer.firstName ? customer.firstName : '',
    lastName: customer && customer.lastName ? customer.lastName : ''
  };

  const onInputChange = (event, getCustomerDataFn) => {
    onAppPaymentCustomerChange(getCustomerDataFn(event), participationId);
  };

  const filledOutFirstName = (event) => ({
    ...customerData,
    firstName: event.target.value
  });

  const filledOutLastName = (event) => ({
    ...customerData,
    lastName: event.target.value
  });

  const firstNameText = i18n.t('customerThread.visibleThread.apps.list.payment.customer.firstName');
  const lastNameText = i18n.t('customerThread.visibleThread.apps.list.payment.customer.lastName');

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: '0 4em',
        width: '100%'
      }}
    >
      <StyledTextField
        autoFocus
        fullWidth
        label={`${firstNameText}*`}
        placeholder={firstNameText}
        inputProps={{
          'data-test-id': 'firstname-input',
          maxLength: 100,
          style: inputStyles
        }}
        InputLabelProps={{
          style: labelStyles
        }}
        onChange={(event) => onInputChange(event, filledOutFirstName)}
        value={customerData.firstName}
      />

      <StyledTextField
        fullWidth
        autoComplete="family-name"
        label={`${lastNameText}*`}
        placeholder={lastNameText}
        inputProps={{
          'data-test-id': 'lastname-input',
          maxLength: 100,
          style: inputStyles
        }}
        InputLabelProps={{
          style: labelStyles
        }}
        onChange={(event) => onInputChange(event, filledOutLastName)}
        value={customerData.lastName}
      />
    </div>
  );
};

LegacyPaymentCustomerComponent.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any),
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  participationId: PropTypes.string.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired
};

export default LegacyPaymentCustomerComponent;
