import {merge} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {getFilterIsCurrentPageInHeadingCustomers} from '../../../lib/route-helper';
import {CUSTOMER_INBOX_THREADS_LIST_RELOAD} from '../../../../actions/customer-inbox-actions';
import {LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS} from '../../../../actions/ext-actions';

const LegacyCustomerInboxThreadsListEpic = () => (action$, state$) => {
  const legacyReloadThreadsListOnThreadRedirectionOutOfScope = action$.pipe(
    ofType(LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS),
    filter(getFilterIsCurrentPageInHeadingCustomers(state$)),
    filter(({data: {newBusinessOutOfScope}}) => newBusinessOutOfScope),
    map(() => ({
      type: CUSTOMER_INBOX_THREADS_LIST_RELOAD
    }))
  );

  return merge(legacyReloadThreadsListOnThreadRedirectionOutOfScope);
};

export default LegacyCustomerInboxThreadsListEpic;
