import PropTypes from 'prop-types';
import Link from '../../../../../common/link/link-component';
import ThreadChannelFooterLayout from '../../../../../common/thread/permission/thread-channel-footer-layout-component';

const ThreadGbmExpiredFooterComponent = ({
  i18n,
  onConversationNewConversationFormOpenLinkClick
}) => (
  <ThreadChannelFooterLayout data-test-id="gbm-window-expired">
    <span>
      {i18n.t('customerThread.visibleThread.channels.gbm.permission.messagesInformation')}
    </span>

    <Link
      data-test-id="start-conversation-link"
      label={i18n.t('customerThread.visibleThread.channels.gbm.permission.link')}
      onClick={onConversationNewConversationFormOpenLinkClick}
      style={{
        marginTop: '15px',
        textDecoration: 'underline'
      }}
    />
  </ThreadChannelFooterLayout>
);

ThreadGbmExpiredFooterComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onConversationNewConversationFormOpenLinkClick: PropTypes.func.isRequired
};

export default ThreadGbmExpiredFooterComponent;
