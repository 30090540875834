/**
 * Split and clean a string.
 *
 * @param {String} text
 * @returns {Array}
 */
export default function trimSplit(text) {
  return text
    .split(',')
    .map((value) => value.trim())
    .filter((value) => value.length)
    .filter((value, index, self) => self.indexOf(value) === index);
}
