import {fromEvent} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {filterByMultistoreConversationDisabled} from '../../../../../lib/feature-toggle-helper';

export default (socketIO, state$, externalEventName, internalActionName, threadTypeToCheck) => {
  return fromEvent(socketIO, externalEventName).pipe(
    filter(filterByMultistoreConversationDisabled(state$)),
    map((message) => {
      const {orgIcon, participationId} = message;

      const participationIdFromState = state$.value.getIn([
        'legacyCustomerThread',
        'participationId'
      ]);
      const threadTypeFromState = state$.value.getIn(['legacyCustomerThread', 'threadType']);
      const threadIsSelected = participationIdFromState == participationId; // eslint-disable-line eqeqeq
      const visibleThreadIsVisible =
        !state$.value.getIn(['ui', 'isSmallScreen']) || threadTypeToCheck === threadTypeFromState;

      const acknowledged = threadIsSelected && visibleThreadIsVisible;

      if (acknowledged) {
        socketIO.emit('mark-customer-thread-as-read', {
          participationId,
          ...(threadTypeToCheck === 'invisible' ? {internal: true} : {})
        });
      }

      return {
        type: internalActionName,
        needInboxRefresh: true,
        data: {
          acknowledged,
          ...message,
          // @todo Remove next override when backend will fix this property in payload (see web issues AWI-5268 & AWI-5719)
          ...(orgIcon ? {orgIcon: state$.value.getIn(['account', 'organizationPicture'])} : null)
        }
      };
    })
  );
};
