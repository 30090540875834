import {PureComponent} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import ContentLayout from '../../common/layout/content-layout-component';
import CustomerInboxSearchList from './inbox/search-list-component';
import CustomerInboxSearchListHeader from './inbox/search-list-header-component';
import CustomerInboxStartNewThread from './inbox/customer-inbox-start-new-thread-component';
import CustomerInboxThreadList from './inbox/customer-inbox-thread-list-component';
import CustomerInboxThreadListHeader from './inbox/thread-list-header-component';
import LegacyCustomerThreadContainer from '../../../container/content/customer/thread/legacy-customer-thread-container';
import CustomerThreadContainer from '../../../container/content/customer/thread/customer-thread-container';
import EmptyAreaWithLogo from '../../common/empty-area-with-logo-component';
import EnhancedSwitch from '../../common/enhanced-switch-component';
import FloatPanel from '../../common/float-panel-component';
import LeftPanelLayout from '../../common/layout/left-panel-layout-component';
import RightPanelLayout from '../../common/layout/right-panel-layout-component';
import {fontSize, spacing} from '../../../../../shared/style/variables';
import {slate} from '../../../../../shared/style/colors';
import {CLICKABLE_AREAS} from '../../../data/ui/clickable-areas';

class CustomerContentComponent extends PureComponent {
  componentDidMount() {
    const {
      hasBusinesses,
      inbox: {offset, sortBy, statuses},
      doLoadInboxThreadsList
    } = this.props;

    if (hasBusinesses) {
      doLoadInboxThreadsList(statuses, offset, sortBy);
    }
  }

  componentWillUnmount() {
    const {doLeaveCustomerInbox} = this.props;
    doLeaveCustomerInbox();
  }

  onClickCustomerInbox = () => {
    const {onUIAreaClick} = this.props;
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.INBOX);
  };

  render() {
    const {i18n} = this.context;
    const {
      account,
      enableLasRespondentAvatarInInbox,
      enableMultistoreConversation,
      hasBusinesses,
      inbox,
      match,
      doFilterInboxByStatuses,
      doInboxThreadsListThreadClick,
      doLoadInboxThreadsList,

      // search
      customerInboxBusinessFilter,
      doCustomerInboxBusinessFilterClose,
      doCustomerInboxBusinessFilterOpen,
      doCustomerInboxBusinessFilterSearch,
      doLoadInboxThreadsListFilteredByBusiness,
      onInboxSearchFormTextChange,
      onInboxSearchFormTextClearButtonClick,
      doInboxSearchFormToggle,
      onInboxSearchMoreCustomersButtonClick,
      onInboxSearchMoreMessagesButtonClick,
      onInboxSearchResultCustomerClick,
      onInboxSearchResultMessageClick,

      // start new thread
      doCloseStartNewThread,
      doCustomerInboxNewConversationFormChanged,
      doCustomerInboxNewConversationFormCustomerClick,
      doCustomerInboxNewConversationNewCustomerFormCustomerClick,
      doShowStartNewThread,
      doStartNewCustomerThreadLegacy,
      doStartNewCustomerThreadSelectLegacy
    } = this.props;

    const headerStyle = {
      padding: `${spacing.mlarge} 0 ${spacing.large} 0`,
      display: 'flex',
      alignItems: 'center',
      fontSize: fontSize.normal,
      color: slate,
      maxHeight: '48px' // simple fix for headers who contains icon
    };

    return (
      <ContentLayout>
        <LeftPanelLayout onClick={this.onClickCustomerInbox}>
          <FloatPanel
            dataTestId="start-new-conversation"
            animationSide="left"
            doClose={doCloseStartNewThread}
            show={inbox.showStartNewThread}
            title={i18n.t('customerInbox.newThread.title')}
          >
            <CustomerInboxStartNewThread
              businesses={account.businesses}
              {...inbox.newThread}
              {...{
                doCloseStartNewThread,
                doCustomerInboxNewConversationFormChanged,
                doCustomerInboxNewConversationFormCustomerClick,
                doCustomerInboxNewConversationNewCustomerFormCustomerClick,
                doStartNewCustomerThreadLegacy,
                doStartNewCustomerThreadSelectLegacy,
                enableMultistoreConversation
              }}
            />
          </FloatPanel>

          {inbox.showSearchHeader ? (
            <CustomerInboxSearchListHeader
              text={inbox.search.text}
              {...{
                onInboxSearchFormTextChange,
                onInboxSearchFormTextClearButtonClick,
                doInboxSearchFormToggle
              }}
            />
          ) : (
            <CustomerInboxThreadListHeader
              businesses={account.businesses}
              canFilterCustomerThreadsByBusiness={account.canFilterCustomerThreadsByBusiness}
              filterBusinessId={inbox.businessId}
              filterBusinessName={inbox.businessName}
              filterBusinessScope={inbox.businessScope}
              filterItems={customerInboxBusinessFilter.items}
              filterLoading={customerInboxBusinessFilter.loading}
              filterText={customerInboxBusinessFilter.text}
              isAllowedToSeeCustomerThreadsOfAllBusinesses={
                account.isAllowedToSeeCustomerThreadsOfAllBusinesses
              }
              isVisibleBusinessFilterPopup={customerInboxBusinessFilter.isVisiblePopup}
              repliedTotal={inbox.repliedTotal}
              resolvedTotal={inbox.resolvedTotal}
              showOptionsToSortFilter={inbox.showOptionsToSortFilter}
              sortBy={inbox.sortBy}
              statuses={inbox.statuses}
              waitingTotal={inbox.waitingTotal}
              {...{
                doCustomerInboxBusinessFilterClose,
                doCustomerInboxBusinessFilterOpen,
                doCustomerInboxBusinessFilterSearch,
                doFilterInboxByStatuses,
                doInboxSearchFormToggle,
                doLoadInboxThreadsList,
                doLoadInboxThreadsListFilteredByBusiness,
                doShowStartNewThread
              }}
            />
          )}

          {inbox.search.text ? (
            <CustomerInboxSearchList
              {...inbox.search}
              selectedCustomerId={inbox.selectedCustomerId}
              {...{
                enableMultistoreConversation,
                headerStyle,
                onInboxSearchMoreCustomersButtonClick,
                onInboxSearchMoreMessagesButtonClick,
                onInboxSearchResultCustomerClick,
                onInboxSearchResultMessageClick
              }}
            />
          ) : (
            <CustomerInboxThreadList
              {...inbox}
              isAllowedToSeeCustomerThreadsOfAllBusinesses={
                account.isAllowedToSeeCustomerThreadsOfAllBusinesses
              }
              showSnack={inbox.showSnack}
              {...{
                enableLasRespondentAvatarInInbox,
                hasBusinesses,
                headerStyle,
                doFilterInboxByStatuses,
                doInboxThreadsListThreadClick,
                doLoadInboxThreadsList
              }}
            />
          )}
        </LeftPanelLayout>

        <RightPanelLayout>
          <EnhancedSwitch>
            <Route
              exact
              path={match.url}
              render={() => (
                <EmptyAreaWithLogo
                  dataTestId="no-thread-selected"
                  label={i18n.t('thread.emptyThread')}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/:participationId`}
              component={
                enableMultistoreConversation
                  ? CustomerThreadContainer
                  : LegacyCustomerThreadContainer
              }
            />
            {enableMultistoreConversation ? (
              <Route
                exact
                path={`${match.url}/:customerId/conversations/:conversationId`}
                component={CustomerThreadContainer}
              />
            ) : null}
          </EnhancedSwitch>
        </RightPanelLayout>
      </ContentLayout>
    );
  }
}

CustomerContentComponent.contextTypes = context;

CustomerContentComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  customerInboxBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  enableLasRespondentAvatarInInbox: PropTypes.bool.isRequired,
  enableMultistoreConversation: PropTypes.bool.isRequired,
  hasBusinesses: PropTypes.bool.isRequired,
  inbox: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  doCloseStartNewThread: PropTypes.func.isRequired,
  doCustomerInboxBusinessFilterClose: PropTypes.func.isRequired,
  doCustomerInboxBusinessFilterOpen: PropTypes.func.isRequired,
  doCustomerInboxBusinessFilterSearch: PropTypes.func.isRequired,
  doCustomerInboxNewConversationFormChanged: PropTypes.func.isRequired,
  doCustomerInboxNewConversationFormCustomerClick: PropTypes.func.isRequired,
  doCustomerInboxNewConversationNewCustomerFormCustomerClick: PropTypes.func.isRequired,
  doFilterInboxByStatuses: PropTypes.func.isRequired,
  doInboxSearchFormToggle: PropTypes.func.isRequired,
  doInboxThreadsListThreadClick: PropTypes.func.isRequired,
  doLeaveCustomerInbox: PropTypes.func.isRequired,
  doLoadInboxThreadsList: PropTypes.func.isRequired,
  doLoadInboxThreadsListFilteredByBusiness: PropTypes.func.isRequired,
  doShowStartNewThread: PropTypes.func.isRequired,
  doStartNewCustomerThreadLegacy: PropTypes.func.isRequired,
  doStartNewCustomerThreadSelectLegacy: PropTypes.func.isRequired,
  onInboxSearchFormTextChange: PropTypes.func.isRequired,
  onInboxSearchFormTextClearButtonClick: PropTypes.func.isRequired,
  onInboxSearchMoreCustomersButtonClick: PropTypes.func.isRequired,
  onInboxSearchMoreMessagesButtonClick: PropTypes.func.isRequired,
  onInboxSearchResultCustomerClick: PropTypes.func.isRequired,
  onInboxSearchResultMessageClick: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired
};

export default CustomerContentComponent;
