import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import getSidebarWidth from '../../../common/script/get-sidebar-width';
import {shouldShowTutorial, TUTORIALS} from '../../../../data/tutorial/tutorial-settings';
import ContentLayout from '../../../common/layout/content-layout-component';
import CustomerInvisibleThread from './invisible-thread-component';
import CustomerProfileDrawerComponent from './profile/customer-profile-drawer-component';
import CustomerThreadBusinessTabs from './thread-business-tabs-component';
import CustomerThreadHeader from './thread-header-component';
import CustomerVisibleThread from './visible-thread-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import RightPanelColumnWrapper from '../../../common/layout/right-panel-column-wrapper-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import ThreadSidebar from './thread-sidebar-component';
import {lightSmoke, mediumSnow} from '../../../../../../shared/style/colors';
import {CLICKABLE_AREAS} from '../../../../data/ui/clickable-areas';

class CustomerThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tutorialsChecked: {},
      whatsappPermissionFooterRenderedAtLeastOnce: false
    };
  }

  componentDidMount() {
    // It's necessary to test right after mount as in case of page refresh the mount is done with prop customerChannel already set
    this.checkAndSortTutorials({});
    /* eslint-enable react/destructuring-assignment */
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkAndSortTutorials(prevProps, prevState);
  }

  componentWillUnmount() {
    const {business, conversationStatus, customerId, doThreadPageLeave} = this.props;

    // Some props are tested because the page could be left before all thread data was loaded
    doThreadPageLeave(business ? business.id : null, customerId, conversationStatus);
  }

  checkAndSortTutorials = (oldProperties, oldState) => {
    const {
      customerChannel,
      doRegisterTutorial,
      enableUIMessengerChannelFeature,
      enableUIWhatsAppChannelFeature
    } = this.props;

    if (!customerChannel) {
      return;
    }

    const {tutorialsChecked, whatsappPermissionFooterRenderedAtLeastOnce} = this.state;

    const markTutorialAsCheckedInState = (tutorialKey) =>
      this.setState({
        tutorialsChecked: {
          ...tutorialsChecked,
          [tutorialKey]: {
            ...tutorialsChecked[tutorialKey],
            [customerChannel]: true
          }
        }
      });

    // Sort next array by expected tutorials order
    [
      {
        condition: customerChannel !== oldProperties.customerChannel,
        configuration: TUTORIALS.customerThreadHeaderChannel,
        registerParam: {customerChannel}
      },
      {
        condition:
          whatsappPermissionFooterRenderedAtLeastOnce &&
          !oldState.whatsappPermissionFooterRenderedAtLeastOnce,
        configuration: TUTORIALS.customerThreadFooterWhatsappPermission
      }
    ]
      .filter(
        ({condition, key}) =>
          condition && (!tutorialsChecked[key] || !tutorialsChecked[key][customerChannel])
      )
      .forEach(({configuration: {key}, registerParam}) => {
        if (
          shouldShowTutorial({
            customerChannel,
            enableUIMessengerChannelFeature,
            enableUIWhatsAppChannelFeature,
            key
          })
        ) {
          doRegisterTutorial(key, registerParam);
        }

        markTutorialAsCheckedInState(key);
      });
  };

  onConversationInternalClick = () => {
    const {onUIAreaClick} = this.props;
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.INTERNAL);
  };

  _onConversationInternalMessageFormTextChanged = (text) => {
    const {conversationId, onConversationInternalMessageFormTextChanged} = this.props;
    onConversationInternalMessageFormTextChanged(conversationId, text);
  };

  _onConversationVisibleMessageFormTextChanged = (text) => {
    const {conversationId, onConversationVisibleMessageFormTextChanged} = this.props;
    onConversationVisibleMessageFormTextChanged(conversationId, text);
  };

  onThreadSidebarClick = () => {
    const {onUIAreaClick} = this.props;
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.SIDEBAR);
  };

  onWhatsappPermissionFooterConfirmRendered = () => {
    this.setState({
      whatsappPermissionFooterRenderedAtLeastOnce: true
    });
  };

  _renderCustomerThreadLoader = () => {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LoadingIndicator size={60} />
      </div>
    );
  };

  render() {
    const {i18n} = this.context;
    const {
      // shared
      account,
      activeThreadType,
      business,
      conversationId,
      customerId,
      conversationStatus,
      conversationOrderSelected,
      customerThreadConversations,
      disableThreadInputPersistence,
      enableUIWhatsAppChannelFeature,
      fullName,
      isNewConversation,
      isNewConversationWithNewCustomer,
      isNewThread,
      isSmallScreen,
      isHistoryAction,
      newThreadEmail,
      newThreadPhoneNumber,
      organizationPicture,
      participationId,
      picture,
      readOnly,
      startNewThreadByUnknownContact,
      unreadInternalMessages,
      unreadVisibleMessages,
      userId,
      whatsappTemplates,
      doThreadSwitchVisibilityToType,
      doThreadFilePreviewOpen,
      onConversationTabClick,
      onUIAreaClick,
      onWhatsappTemplateClick,
      // visible thread
      cannedResponses,
      enablePaymentFeature,
      visibleProps,
      doAppPaymentCancelButtonClick,
      doAppPaymentMaximizeButtonClick,
      doAppPaymentMinimizeButtonClick,
      doAppPaymentStepperBackButtonClick,
      doAppPaymentStepperNextButtonClick,
      doAppsAreaMenuButtonClick,
      doAppsAreaMinimize,
      doAppsMenuSelectPayment,
      onThreadTakeButtonClick,
      doThreadVisibleFileRemove,
      doThreadVisiblePaginate,
      onThreadVisibleSendMessageRetryButtonClick,
      onAppPaymentAmountChange,
      onAppPaymentCustomChange,
      onAppPaymentCustomerChange,
      onConversationNewConversationFormOpenLinkClick,
      onNewConversationSendMessageButtonClick,
      onThreadReleaseButtonClick,
      onThreadStatusButtonClick,
      onThreadVisibleFileSelect,
      onThreadVisibleFileSelectedExceedMaxSizeClose,
      onThreadVisibleSendMessageButtonClick,
      onWhatsappTemplatesLoadButtonClick,
      // invisible thread
      invisibleProps,
      doColleagueInvitationPopupOpen,
      doConversationInternalFileRemoveButtonClick,
      doConversationInternalFileUpload,
      doThreadInvisibleLoad,
      doConversationInternalMentionPopupClose,
      doConversationInternalMentionPopupOpen,
      doConversationInternalMentionPopupSearch,
      doConversationInternalMentionPopupSelect,
      doConversationInternalMentionUnselect,
      onConversationInternalSendMessageButtonClick,
      onConversationInternalSendMessageRetryButtonClick,
      // customer profile
      customerChannel,
      customerProfile,
      doCustomerProfileClose,
      doCustomerProfileEditClose,
      doCustomerProfileEditOpen,
      doCustomerProfileEditTextUpdate,
      doCustomerProfilePictureUpload,
      doCustomerProfileShow,
      enableCustomerProfile
    } = this.props;

    const isLoadingCustomerThreadMetadata = customerProfile.loading;

    return (
      <RightPanelContentLayout dataTestId="customer-thread">
        <CustomerThreadHeader
          {...{
            customerProfile,
            enableCustomerProfile,
            i18n,
            isNewConversationWithNewCustomer,
            isLoadingCustomerThreadMetadata,
            doCustomerProfileShow,
            onUIAreaClick
          }}
        />

        {enableCustomerProfile ? (
          <CustomerProfileDrawerComponent
            {...{
              customerProfile,
              i18n,
              participationId,
              doCustomerProfileClose,
              doCustomerProfileEditClose,
              doCustomerProfileEditOpen,
              doCustomerProfileEditTextUpdate,
              doCustomerProfilePictureUpload
            }}
          />
        ) : null}

        {isLoadingCustomerThreadMetadata ? (
          this._renderCustomerThreadLoader()
        ) : (
          <React.Fragment>
            <CustomerThreadBusinessTabs
              {...{
                account,
                conversationOrderSelected,
                customerThreadConversations,
                isNewConversation,
                onConversationTabClick,
                onUIAreaClick,
                i18n
              }}
            />
            <ContentLayout>
              {!isSmallScreen || activeThreadType === 'visible' ? (
                <RightPanelColumnWrapper
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexGrow: isSmallScreen ? 1 : 1.4,
                    minHeight: isSmallScreen ? '100%' : null,
                    marginRight: getSidebarWidth(isSmallScreen)
                  }}
                >
                  <CustomerVisibleThread
                    doWhatsappPermissionFooterConfirmRendered={
                      this.onWhatsappPermissionFooterConfirmRendered
                    }
                    onConversationVisibleMessageFormTextChanged={
                      this._onConversationVisibleMessageFormTextChanged
                    }
                    setInputHeight={this._setInputHeight}
                    {...visibleProps}
                    {...{
                      account,
                      business,
                      cannedResponses,
                      conversationId,
                      conversationStatus,
                      customerChannel,
                      disableThreadInputPersistence,
                      enablePaymentFeature,
                      enableUIWhatsAppChannelFeature,
                      fullName,
                      isHistoryAction,
                      isNewConversation,
                      isNewConversationWithNewCustomer,
                      isNewThread,
                      newThreadEmail,
                      newThreadPhoneNumber,
                      organizationPicture,
                      participationId,
                      picture,
                      startNewThreadByUnknownContact,
                      userId,
                      whatsappTemplates,
                      doAppPaymentCancelButtonClick,
                      doAppPaymentMaximizeButtonClick,
                      doAppPaymentMinimizeButtonClick,
                      doAppPaymentStepperBackButtonClick,
                      doAppPaymentStepperNextButtonClick,
                      doAppsAreaMenuButtonClick,
                      doAppsAreaMinimize,
                      doAppsMenuSelectPayment,
                      doThreadFilePreviewOpen,
                      doThreadVisibleFileRemove,
                      onNewConversationSendMessageButtonClick,
                      doThreadVisiblePaginate,
                      onAppPaymentAmountChange,
                      onAppPaymentCustomChange,
                      onAppPaymentCustomerChange,
                      onConversationNewConversationFormOpenLinkClick,
                      onThreadReleaseButtonClick,
                      onThreadStatusButtonClick,
                      onThreadTakeButtonClick,
                      onThreadVisibleFileSelectedExceedMaxSizeClose,
                      onThreadVisibleFileSelect,
                      onThreadVisibleSendMessageButtonClick,
                      onThreadVisibleSendMessageRetryButtonClick,
                      onUIAreaClick,
                      onWhatsappTemplateClick,
                      onWhatsappTemplatesLoadButtonClick
                    }}
                  />
                </RightPanelColumnWrapper>
              ) : null}

              {!isSmallScreen || activeThreadType === 'invisible' ? (
                <RightPanelColumnWrapper
                  onClick={this.onConversationInternalClick}
                  style={{
                    backgroundColor: mediumSnow,
                    display: 'flex',
                    flex: 1,
                    minHeight: '100%',
                    minWidth: 0, // this enforce the truncation on child components
                    marginRight: getSidebarWidth(isSmallScreen)
                  }}
                >
                  <CustomerInvisibleThread
                    onConversationInternalMessageFormTextChanged={
                      this._onConversationInternalMessageFormTextChanged
                    }
                    readOnly={readOnly || isNewConversation}
                    {...invisibleProps}
                    {...{
                      account,
                      business,
                      customerId,
                      disableThreadInputPersistence,
                      isHistoryAction,
                      participationId,
                      doColleagueInvitationPopupOpen,
                      doConversationInternalFileRemoveButtonClick,
                      doConversationInternalFileUpload,
                      doThreadFilePreviewOpen,
                      doThreadInvisibleLoad,
                      doConversationInternalMentionPopupClose,
                      doConversationInternalMentionPopupOpen,
                      doConversationInternalMentionPopupSearch,
                      doConversationInternalMentionPopupSelect,
                      doConversationInternalMentionUnselect,
                      onConversationInternalSendMessageButtonClick,
                      onConversationInternalSendMessageRetryButtonClick
                    }}
                  />
                </RightPanelColumnWrapper>
              ) : null}

              {isSmallScreen ? (
                <div
                  data-test-id="thread-sidebar"
                  onClick={this.onThreadSidebarClick}
                  style={{
                    width: getSidebarWidth(),
                    borderLeft: `1px solid ${lightSmoke}`,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    zIndex: 100,
                    height: '100%',
                    background: 'white'
                  }}
                >
                  <ThreadSidebar
                    {...{
                      activeThreadType,
                      doThreadSwitchVisibilityToType,
                      isNewThread,
                      unreadInternalMessages,
                      unreadVisibleMessages
                    }}
                  />
                </div>
              ) : null}
            </ContentLayout>
          </React.Fragment>
        )}
      </RightPanelContentLayout>
    );
  }
}

CustomerThreadComponent.contextTypes = context;

CustomerThreadComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  activeThreadType: PropTypes.string.isRequired,
  business: PropTypes.objectOf(PropTypes.any),
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  conversationId: PropTypes.string,
  conversationOrderSelected: PropTypes.number,
  conversationStatus: PropTypes.string,
  customerChannel: PropTypes.string,
  customerId: PropTypes.string,
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  customerThreadConversations: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enableCustomerProfile: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  enableUIMessengerChannelFeature: PropTypes.bool.isRequired,
  enableUIWhatsAppChannelFeature: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  invisibleProps: PropTypes.objectOf(PropTypes.any).isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewConversation: PropTypes.bool.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  isNewConversationWithNewCustomer: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  newThreadEmail: PropTypes.string,
  newThreadPhoneNumber: PropTypes.string,
  organizationPicture: PropTypes.string,
  participationId: PropTypes.string,
  picture: PropTypes.string,
  readOnly: PropTypes.bool,
  startNewThreadByUnknownContact: PropTypes.string,
  unreadInternalMessages: PropTypes.number,
  unreadVisibleMessages: PropTypes.number,
  userId: PropTypes.string.isRequired,
  visibleProps: PropTypes.objectOf(PropTypes.any).isRequired,
  whatsappTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsAreaMenuButtonClick: PropTypes.func.isRequired,
  doAppsAreaMinimize: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doConversationInternalFileRemoveButtonClick: PropTypes.func.isRequired,
  doConversationInternalFileUpload: PropTypes.func.isRequired,
  doCustomerProfileClose: PropTypes.func.isRequired,
  doCustomerProfileEditClose: PropTypes.func.isRequired,
  doCustomerProfileEditOpen: PropTypes.func.isRequired,
  doCustomerProfileEditTextUpdate: PropTypes.func.isRequired,
  doCustomerProfilePictureUpload: PropTypes.func.isRequired,
  doCustomerProfileShow: PropTypes.func.isRequired,
  doRegisterTutorial: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  doThreadInvisibleLoad: PropTypes.func.isRequired,
  doConversationInternalMentionPopupClose: PropTypes.func.isRequired,
  doConversationInternalMentionPopupOpen: PropTypes.func.isRequired,
  doConversationInternalMentionPopupSearch: PropTypes.func.isRequired,
  doConversationInternalMentionPopupSelect: PropTypes.func.isRequired,
  doConversationInternalMentionUnselect: PropTypes.func.isRequired,
  doThreadPageLeave: PropTypes.func.isRequired,
  doThreadSwitchVisibilityToType: PropTypes.func.isRequired,
  onThreadTakeButtonClick: PropTypes.func.isRequired,
  doThreadVisibleFileRemove: PropTypes.func.isRequired,
  doThreadVisiblePaginate: PropTypes.func.isRequired,
  onThreadVisibleSendMessageRetryButtonClick: PropTypes.func.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onConversationInternalMessageFormTextChanged: PropTypes.func.isRequired,
  onConversationInternalSendMessageButtonClick: PropTypes.func.isRequired,
  onConversationInternalSendMessageRetryButtonClick: PropTypes.func.isRequired,
  onConversationVisibleMessageFormTextChanged: PropTypes.func.isRequired,
  onConversationTabClick: PropTypes.func.isRequired,
  onConversationNewConversationFormOpenLinkClick: PropTypes.func.isRequired,
  onNewConversationSendMessageButtonClick: PropTypes.func.isRequired,
  onThreadReleaseButtonClick: PropTypes.func.isRequired,
  onThreadStatusButtonClick: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired,
  onThreadVisibleFileSelectedExceedMaxSizeClose: PropTypes.func.isRequired,
  onThreadVisibleSendMessageButtonClick: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired,
  onWhatsappTemplateClick: PropTypes.func.isRequired,
  onWhatsappTemplatesLoadButtonClick: PropTypes.func.isRequired
};

export default CustomerThreadComponent;
