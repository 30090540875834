import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import goToThreadUrl from '../../lib/go-to-thread-url';
import {APP_SOCKET_RECONNECT} from '../../../actions/app-actions';
import {
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_FAILURE,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_SUCCESS,
  COLLEAGUE_INBOX_NEW_THREAD_SHOW,
  COLLEAGUE_INBOX_NEW_THREAD_START,
  COLLEAGUE_INBOX_THREADS_LIST_LOAD,
  COLLEAGUE_INBOX_THREADS_LIST_LOAD_FAILURE,
  COLLEAGUE_INBOX_THREADS_LIST_LOAD_SUCCESS,
  COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../actions/colleague-inbox-actions';
import {
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS,
  COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS
} from '../../../actions/colleague-thread-actions';
import {EXT_COLLEAGUE_SENT_MESSAGE_TO_ME} from '../../../actions/ext-actions';
import {
  DEBOUNCE_TIME_MULTIPLE_CLICK,
  DEBOUNCE_TIME_TYPING_FILTER
} from '../../../../../shared/data/settings';

const PAGE_SIZE = 25;

const goToColleagueThread = (participationId, params) =>
  goToThreadUrl('colleagues', participationId, params);

/**
 * Finally, the epic.
 */
const ColleagueInboxEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadThreadsList = action$.pipe(
      ofType(
        COLLEAGUE_INBOX_THREADS_LIST_LOAD,
        COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS,
        COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS
      ),
      mergeMap(({after, refreshAll}) => {
        let closestGreaterPageSize;
        if (refreshAll) {
          closestGreaterPageSize =
            Math.ceil(state$.value.getIn(['colleagueInbox', 'edges']).size / PAGE_SIZE) * PAGE_SIZE;
        }

        const params = {slice: {first: closestGreaterPageSize || PAGE_SIZE, after}};

        return graphql('get-colleague-inbox-query', params).pipe(
          map((data) => {
            return {
              type: COLLEAGUE_INBOX_THREADS_LIST_LOAD_SUCCESS,
              data: data.colleagueInbox,
              params,
              refreshAll
            };
          }),
          catchError((error) => {
            return of({
              type: COLLEAGUE_INBOX_THREADS_LIST_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const refreshThreadsListOnSomeActions = action$.pipe(
      ofType(APP_SOCKET_RECONNECT, EXT_COLLEAGUE_SENT_MESSAGE_TO_ME),
      map(() => {
        return {
          type: COLLEAGUE_INBOX_THREADS_LIST_LOAD,
          refreshAll: true
        };
      })
    );

    const newThreadSearch = action$.pipe(
      ofType(
        COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE,
        COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET,
        COLLEAGUE_INBOX_NEW_THREAD_SHOW
      ),
      // @todo apply this debounce only to COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      mergeMap(({text = '', offset = 0}) => {
        return graphql('search-colleagues-to-start-thread-query', {text, offset}).pipe(
          map((data) => {
            return {
              type: COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_SUCCESS,
              edges: data.colleagueSearch.edges
            };
          }),
          takeUntil(
            action$.pipe(
              ofType(
                COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE,
                COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET,
                COLLEAGUE_INBOX_NEW_THREAD_SHOW
              )
            )
          ),
          catchError((error) => {
            return of({
              type: COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_FAILURE,
              error
            });
          })
        );
      })
    );

    const newThreadStart = action$.pipe(
      ofType(COLLEAGUE_INBOX_NEW_THREAD_START),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({displayName, userId}) => goToColleagueThread('new', {displayName, userId}))
    );

    const selectThread = action$.pipe(
      ofType(COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({participationId}) => goToColleagueThread(participationId))
    );

    return merge(
      loadThreadsList,
      newThreadSearch,
      newThreadStart,
      refreshThreadsListOnSomeActions,
      selectThread
    );
  };

export default ColleagueInboxEpic;
