import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import {lineHeight} from '../../../../../../shared/style/variables';

class DocumentationPanelComponent extends PureComponent {
  render() {
    const {children, dataTestId} = this.props;

    return (
      <PanelContentLayout
        dataTestId={dataTestId}
        style={{
          lineHeight: lineHeight.large
        }}
      >
        {children}
      </PanelContentLayout>
    );
  }
}

DocumentationPanelComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired
};

export default DocumentationPanelComponent;
