import {filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';

export default function onRouteChange({action, mapFn, regex}) {
  return action.pipe(
    ofType('@@router/LOCATION_CHANGE'),
    filter(({payload: {pathname}}) => pathname.match(regex)),
    map(({payload}) => mapFn(payload))
  );
}
