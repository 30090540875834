import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  addEventuallyEventInConversation,
  addEventuallyMessageInConversation,
  failSendingMessageInConversation,
  startSendingMessageInConversation,
  succeedSendingMessageInConversation
} from '../../../lib/conversation-item-helper';
import {
  deletePersistedState,
  resetNotPersistedState,
  deletePersistedKeyFromState
} from '../../../lib/persistence-helper';
import {
  DEFAULT_STATE_THREAD,
  checkMessageForm,
  failUploadFile,
  persistMessageFormText,
  removeUploadedFile,
  startUploadFile,
  succeedUploadFile
} from '../../../lib/message-form-helper';
import {APP_LOGOUT} from '../../../../actions/app-actions';
import {
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_THREAD_STATUS_BUTTON_CLICK,
  CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE,
  CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_PERSIST,
  CUSTOMER_THREAD_VISIBLE_FILE_REMOVE,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE,
  CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED,
  CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD,
  CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE,
  CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_LOAD,
  CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_PAGINATE,
  CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS,
  CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS,
  CUSTOMER_NEW_CONVERSATION_START,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION
} from '../../../../actions/customer-new-thread-actions';
import {
  CUSTOMER_THREAD_RELEASE_BUTTON_CLICK,
  CUSTOMER_THREAD_RELEASE_FAILURE,
  CUSTOMER_THREAD_RELEASE_SUCCESS,
  CUSTOMER_THREAD_TAKE_BUTTON_CLICK,
  CUSTOMER_THREAD_TAKE_FAILURE,
  CUSTOMER_THREAD_TAKE_SUCCESS
} from '../../../../actions/customer-thread-assignment-actions';
import {
  EXT_CONVERSATION_STATUS_CHANGED,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME,
  EXT_EMPLOYEE_RELEASED_CONVERSATION,
  EXT_EMPLOYEE_TOOK_CONVERSATION
} from '../../../../actions/ext-actions';
import {
  ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION,
  ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION,
  ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER
} from '../../../../actions/router-actions';
import {UI_AREA_CLICK} from '../../../../../../shared/actions/ui-actions';
import {
  CONVERSATION_ITEM_TYPES,
  CONVERSATION_MESSAGES_LOAD_DIRECTIONS
} from '../../../../data/thread/message';
import {CLICKABLE_AREAS_CUSTOMER_CONVERSATION_FOCUSABLE} from '../../../../data/ui/clickable-areas';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_THREAD,
  ...{
    business: null,
    conversationItems: {
      edges: [],
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false
      }
    },
    customerId: null,
    footerHasFocus: false,
    id: null,
    loadingAfter: false,
    readOnly: false,
    releasing: false,
    taking: false,
    updatingThreadStatus: false,
    whatsappTemplates: [],
    windowExpiration: null
  }
};

const resetNotPersistedStateAndStartLoading = (state) =>
  resetNotPersistedState(state, DEFAULT_STATE).set('loading', true);

export default createReducer(DEFAULT_STATE, {
  /**
   * Clean storage on logout.
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [APP_LOGOUT]: deletePersistedState,

  /**
   * Persist message form text.
   * @param {Immutable.Map} state
   * @param {String} participationId
   * @param {String} text
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_CONVERSATION_VISIBLE_MESSAGE_FORM_TEXT_PERSIST]: persistMessageFormText,

  /**
   * Load a customer visible thread.
   * @param {Immutable.Map} state
   * @param {String} direction of loading
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_LOAD]: (state, {direction}) =>
    state.set('direction', direction).set('loading', true),
  [ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION]: (state) => state.set('loading', true),
  [ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION]: (
    state,
    {payload: {customerIdUrlParam}}
  ) => state.set('loading', true).set('customerId', customerIdUrlParam),

  /**
   * Triggered when the conversation visible is successfully loaded from the server.
   * @param {Immutable.Map} state
   * @param {Object} conversation
   * @param {String} messageCursor
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (
    state,
    {
      payload: {
        data: {conversation},
        params: {messageCursor}
      }
    }
  ) => {
    const newState = checkMessageForm(
      state
        .set('conversationItems', fromJS(conversation.conversationItems))
        .set('footerHasFocus', true)
        .set('loading', false)
        .set('loadingAfter', false)
        .set('loadingBefore', false)
        .set('id', conversation.id)
        .set('readOnly', conversation.readOnly)
        .set('windowExpiration', conversation.windowExpiration)
    );

    if (messageCursor) {
      return newState.updateIn(['conversationItems', 'edges'], (edges) => {
        const foundMessageIndex = edges.findIndex((edge) => edge.get('cursor') === messageCursor);

        return foundMessageIndex > -1
          ? edges.update(foundMessageIndex, (edge) => edge.setIn(['node', 'selected'], true))
          : edges;
      });
    }

    return newState;
  },

  /**
   * Paginate a customer thread.
   * @param {Immutable.Map} state
   * @param {String} direction of loading
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_PAGINATE]: (state, {direction}) => {
    const {AFTER, BEFORE} = CONVERSATION_MESSAGES_LOAD_DIRECTIONS;

    return state
      .set('direction', direction)
      .set('loadingAfter', direction === AFTER)
      .set('loadingBefore', direction === BEFORE);
  },

  /**
   * Triggered after a successful pagination call.
   * @param {Immutable.Map} state
   * @param {Object} conversation
   * @param {String} direction
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS]: (
    state,
    {
      payload: {
        data: {conversation},
        params: {direction}
      }
    }
  ) => {
    const {AFTER, BEFORE} = CONVERSATION_MESSAGES_LOAD_DIRECTIONS;

    const loadedMessages = fromJS(conversation.conversationItems.edges);

    const newState = state
      .merge(fromJS(conversation))
      .set('direction', direction)
      .set('loading', false)
      .set('loadingAfter', false)
      .set('loadingBefore', false);

    if (direction === BEFORE) {
      return newState
        .setIn(
          ['conversationItems', 'pageInfo', 'hasNextPage'],
          state.getIn(['conversationItems', 'pageInfo', 'hasNextPage'])
        )
        .setIn(
          ['conversationItems', 'edges'],
          loadedMessages.concat(state.getIn(['conversationItems', 'edges']))
        );
    }

    if (direction === AFTER) {
      return newState
        .setIn(
          ['conversationItems', 'pageInfo', 'hasPreviousPage'],
          state.getIn(['conversationItems', 'pageInfo', 'hasPreviousPage'])
        )
        .setIn(
          ['conversationItems', 'edges'],
          state.getIn(['conversationItems', 'edges']).concat(loadedMessages)
        );
    }

    return newState;
  },

  /**
   * File handling.
   */
  [CUSTOMER_THREAD_VISIBLE_FILE_SELECT]: (state) =>
    state.set('selectedFileExceedMaxSize', false).set('uploadingFile', true),
  [CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE]: (state) =>
    state.set('selectedFileExceedMaxSize', true).set('uploadingFile', false),
  [CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED]: (state) =>
    state.set('uploadingFile', false),

  [CUSTOMER_THREAD_VISIBLE_FILE_REMOVE]: removeUploadedFile,

  [CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD]: startUploadFile,
  [CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS]: succeedUploadFile,
  [CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE]: failUploadFile,

  /**
   * Eventually add optimistically the current employee message at the end of visible conversation.
   * The message is ignored if current conversation items page is not the last one.
   * @param {Immutable.Map} state
   * @param {Object} employee
   * @param {Object} message
   * @param {Object} organization
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE]: (state, {payload: {employee, message, organization}}) => {
    if (state.getIn(['conversationItems', 'pageInfo', 'hasNextPage'])) {
      return state;
    }

    return startSendingMessageInConversation({
      messageType: CONVERSATION_ITEM_TYPES.OUTBOUND,
      employee,
      message,
      organization,
      state
    });
  },

  [CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE]: (state, {payload: {message}}) => {
    return startSendingMessageInConversation({
      messageType: CONVERSATION_ITEM_TYPES.OUTBOUND,
      message,
      state
    });
  },

  [CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE]: failSendingMessageInConversation,
  [CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: succeedSendingMessageInConversation,

  /**
   * Set Whatsapp templates.
   * @param {Immutable.Map} state
   * @param {Object} data
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS]: (state, {data}) =>
    state.set('whatsappTemplates', fromJS(data)),

  /**
   * Take and release.
   */
  [CUSTOMER_THREAD_RELEASE_BUTTON_CLICK]: (state) => state.set('releasing', true),
  [CUSTOMER_THREAD_RELEASE_FAILURE]: (state) => state.set('releasing', false),
  [CUSTOMER_THREAD_RELEASE_SUCCESS]: (state) => state.set('releasing', false),

  [CUSTOMER_THREAD_TAKE_BUTTON_CLICK]: (state) => state.set('taking', true),
  [CUSTOMER_THREAD_TAKE_FAILURE]: (state) => state.set('taking', false),
  [CUSTOMER_THREAD_TAKE_SUCCESS]: (state) => state.set('taking', false),

  [EXT_EMPLOYEE_RELEASED_CONVERSATION]: addEventuallyEventInConversation,
  [EXT_EMPLOYEE_TOOK_CONVERSATION]: addEventuallyEventInConversation,

  /**
   * Update status loader
   */
  [CUSTOMER_THREAD_STATUS_BUTTON_CLICK]: (state) => state.set('updatingThreadStatus', true),
  [CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE]: (state) =>
    state.set('updatingThreadStatus', false),
  [CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS]: (state) =>
    state.set('updatingThreadStatus', false),

  /**
   * New message.
   */
  [EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER]: (
    state,
    {payload: {conversation, message}}
  ) => {
    if (conversation.id !== state.get('id')) {
      return state;
    }

    const newState = state.set('windowExpiration', conversation.windowExpiration);

    return addEventuallyMessageInConversation(newState, {payload: {conversation, message}});
  },
  [EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME]: addEventuallyMessageInConversation,
  [EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME]:
    addEventuallyMessageInConversation,

  [EXT_CONVERSATION_STATUS_CHANGED]: (state, {payload}) => {
    if (payload.manualChange) {
      return addEventuallyEventInConversation(state, {payload});
    }

    return state;
  },

  /**
   * Click on some areas of the ui may give focus to footer.
   * @param {Immutable.Map} state
   * @param {String} area
   * @returns {Immutable.Map} new state
   */
  [UI_AREA_CLICK]: (state, {payload: {area}}) =>
    state.set('footerHasFocus', CLICKABLE_AREAS_CUSTOMER_CONVERSATION_FOCUSABLE.includes(area)),

  /**
   * Load a conversation and profile.
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD]: (state) =>
    resetNotPersistedStateAndStartLoading(state),

  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD]: (state) =>
    resetNotPersistedStateAndStartLoading(state),

  [CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS]: (state) => {
    const newState = checkMessageForm(state.set('loading', false).set('id', 'new'));

    return newState;
  },
  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]: (
    state,
    {payload: {customer}}
  ) => state.set('customerId', customer.id),
  [CUSTOMER_NEW_CONVERSATION_START]: (state) => deletePersistedKeyFromState(state, 'new'),
  [CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS]: (state) =>
    deletePersistedKeyFromState(state, 'new'),
  [CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS]: (state) => state.set('sending', false),
  [CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION]: resetNotPersistedStateAndStartLoading,
  [ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER]: (state) => state.set('loading', true)
});
