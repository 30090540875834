import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import jwtDecode from 'jwt-decode';
import SUBSCRIPTION_TYPES from '../data/subscription-types';
import {
  UNSUBSCRIBE_PARAMETERS_VALIDATE,
  UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE,
  UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS,
  UNSUBSCRIBE_CONFIRM_FAILURE,
  UNSUBSCRIBE_CONFIRM_SUCCESS,
  UNSUBSCRIBE_RESUBSCRIBE,
  UNSUBSCRIBE_RESUBSCRIBE_FAILURE,
  UNSUBSCRIBE_RESUBSCRIBE_SUCCESS
} from '../actions/unsubscribe-actions';

/**
 * Call the middle-end endpoint to update the e-mail subscription of the specified type.
 *
 * @param {Function} graphql
 * @param {String} subscriptionType
 * @param {Boolean} active
 * @param {String|undefined} email
 * @param {String} token
 * @param {String} typeSuccess
 * @param {String} typeFailure
 * @returns {Observable}
 */
const updateEmailSubscription = (
  graphql,
  subscriptionType,
  active,
  email,
  token,
  typeSuccess,
  typeFailure
) =>
  graphql(SUBSCRIPTION_TYPES[subscriptionType].graphql, {
    active,
    email,
    token
  }).pipe(
    map(() => {
      return {
        type: typeSuccess
      };
    }),
    catchError((errors) => {
      return of({
        type: typeFailure,
        errors
      });
    })
  );

/**
 * Finally, the epic.
 */
const UnsubscribeEpic =
  ({graphql}) =>
  (action$) => {
    const validateParameters = action$.pipe(
      ofType(UNSUBSCRIBE_PARAMETERS_VALIDATE),
      map(({email, subscriptionType, token}) => {
        if (!Object.keys(SUBSCRIPTION_TYPES).includes(subscriptionType)) {
          return {
            type: UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE,
            errors: ['default'],
            // Next props are useful only for debug
            params: {
              email,
              subscriptionType,
              token
            }
          };
        }

        try {
          return {
            type: UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS,
            email: email || jwtDecode(token).email,
            subscriptionType,
            token
          };
        } catch (error) {
          return {
            type: UNSUBSCRIBE_PARAMETERS_VALIDATE_FAILURE,
            errors: ['default'],
            // Next props are useful only for debug
            params: {
              email,
              subscriptionType,
              token
            }
          };
        }
      })
    );

    const unsubscribeConfirm = action$.pipe(
      ofType(UNSUBSCRIBE_PARAMETERS_VALIDATE_SUCCESS),
      mergeMap(({email, subscriptionType, token}) =>
        updateEmailSubscription(
          graphql,
          subscriptionType,
          false,
          email,
          token,
          UNSUBSCRIBE_CONFIRM_SUCCESS,
          UNSUBSCRIBE_CONFIRM_FAILURE
        )
      )
    );

    const resubscribe = action$.pipe(
      ofType(UNSUBSCRIBE_RESUBSCRIBE),
      mergeMap(({subscriptionType, token}) =>
        updateEmailSubscription(
          graphql,
          subscriptionType,
          true,
          undefined,
          token,
          UNSUBSCRIBE_RESUBSCRIBE_SUCCESS,
          UNSUBSCRIBE_RESUBSCRIBE_FAILURE
        )
      )
    );

    return merge(resubscribe, unsubscribeConfirm, validateParameters);
  };

export default UnsubscribeEpic;
