import PropTypes from 'prop-types';
import currentPlatform from '../../../../../shared/lib/current-platform';
import HeaderBanner from '../../common/header/header-banner-component';
import {blue} from '../../../../../shared/style/colors';

const InternetExplorerDropSupportBannerComponent = ({i18n}) => {
  if (!currentPlatform.isIE) {
    return null;
  }

  return (
    <HeaderBanner style={{backgroundColor: blue}}>
      {i18n.t('global.internetExplorerDropSupport')}
    </HeaderBanner>
  );
};

InternetExplorerDropSupportBannerComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default InternetExplorerDropSupportBannerComponent;
