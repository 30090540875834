import {createTheme} from '@material-ui/core/styles';
import {badge, fontSize, fontWeight, lineHeight, radius, spacing} from './variables';
import {black, blackOpacity, blue, darkRed, green, lightSlate, red, slate, white} from './colors';
import {FONT_FAMILY} from './theme-v0';

const MUI_DIALOG_VERTICAL_MARGIN = '14px';

export default createTheme({
  palette: {
    primary: {
      main: blue
    }
  },
  overrides: {
    MuiBadge: {
      dot: {
        top: '5%',
        right: '95%',
        minWidth: badge.small,
        height: badge.small,
        backgroundColor: green,
        border: `2px solid ${white}`
      }
    },
    MuiButton: {
      root: {
        borderRadius: '19px',
        textTransform: 'none'
      },
      label: {
        fontSize: fontSize.large
      },
      containedSecondary: {
        backgroundColor: red,
        '&:hover': {
          backgroundColor: darkRed
        }
      },
      textPrimary: {
        color: blue
      }
    },
    MuiDialog: {
      paper: {
        margin: 0,
        borderRadius: radius.xlarge,
        backgroundColor: blackOpacity
      }
    },
    MuiDialogActions: {
      root: {
        marginBottom: MUI_DIALOG_VERTICAL_MARGIN,
        justifyContent: 'space-around'
      }
    },
    MuiDialogContent: {
      root: {
        margin: `${MUI_DIALOG_VERTICAL_MARGIN} 20px`,
        padding: 0,
        '&:first-child': {
          paddingTop: 0
        }
      }
    },
    MuiDialogContentText: {
      root: {
        color: white
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '14px 20px 0 20px',
        '& h6': {
          color: white
        }
      }
    },
    MuiIcon: {
      colorPrimary: {
        color: blue
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: fontSize.large,
        color: lightSlate
      },
      root: {
        fontFamily: FONT_FAMILY
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: blue,
          '&:not(:hover)': {
            backgroundColor: 'inherit !important'
          }
        }
      },
      selected: {}
    },
    MuiListItemIcon: {
      root: {
        color: blue
      }
    },
    MuiListItemSecondaryAction: {
      root: {
        right: '24px'
      }
    },
    MuiListItemText: {
      primary: {
        color: 'inherit'
      }
    },
    MuiListSubheader: {
      root: {
        color: slate,
        fontWeight: fontWeight.semiBold,
        textTransform: 'uppercase'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: FONT_FAMILY,
        color: slate
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '12px 14px'
      }
    },
    MuiPaper: {
      elevation1: {
        padding: `${spacing.large} ${spacing.mlarge}`
      }
    },
    MuiSnackbar: {
      root: {
        opacity: 0.95
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: black
      }
    },
    MuiTab: {
      root: {
        margin: `0 ${spacing.large}`,
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        minWidth: '0 !important'
      },
      wrapper: {
        fontSize: fontSize.large
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: black
      }
    },
    MuiTypography: {
      h3: {
        marginBottom: spacing.xsmall,
        fontFamily: 'inherit',
        fontSize: fontSize.xlarge,
        lineHeight: lineHeight.medium
      },
      colorTextSecondary: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit'
      }
    },
    MuiSvgIcon: {
      colorAction: {
        color: black
      }
    }
  },
  // @todo remove 'typography' when migration to material ui v1 will be finished
  typography: {
    useNextVariants: true
  }
});
