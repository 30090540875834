import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import {Tooltip} from '@material-ui/core';
import FontIcon from 'material-ui/FontIcon';
import MenuItem from 'material-ui/MenuItem';
import context from '../../../../../../shared/component/context';
import findBusinessById from '../../../../lib/find-business-by-id';
import getChannelSetting from '../../../../data/thread/channels-settings';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import StartNewThreadListItem from '../../../common/thread/start-new-thread-list-item-component';
import {fontSize, fontWeight} from '../../../../../../shared/style/variables';
import {oneline} from '../../../../../../shared/style/ellipsis';
import {
  lightSlate,
  lightSmoke,
  lightSnow,
  lightYellow,
  slate
} from '../../../../../../shared/style/colors';

class StartNewThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedBusinessCountry: null,
      selectedBusinessHasPhoneNumber: null,
      selectedBusinessId: null,
      selectedBusinessName: null
    };

    this._getBusinessObject = this._getBusinessObject.bind(this);
    this._getSelectedBusiness = this._getSelectedBusiness.bind(this);
    this._onChangeSearch = this._onChangeSearch.bind(this);
  }

  componentDidMount() {
    const {businessId, businesses} = this.props;

    const initialBusinessId = businessId || businesses[0].businessId;

    this.setState(this._getBusinessObject(initialBusinessId));
  }

  _renderMultipleBusinessOptions = () => {
    const {businesses} = this.props;
    const {selectedBusinessId} = this.state;

    return (
      <DropDownMenu
        data-test-id="new-thread-business-select"
        iconStyle={{top: '0'}}
        onChange={this._getSelectedBusiness}
        underlineStyle={{border: 0}}
        value={selectedBusinessId}
        labelStyle={{
          paddingLeft: '0px',
          overflow: 'hidden',
          wordBreak: 'break-all',
          textOverflow: 'clip',
          height: '48px',
          whiteSpace: 'initial',
          lineHeight: '48px',
          fontSize: fontSize.medium,
          fontWeight: fontWeight.semiBold
        }}
      >
        {businesses.map((business) => (
          <MenuItem
            data-test-id="select-option"
            key={business.businessId}
            primaryText={business.name}
            style={{fontSize: fontSize.medium}}
            value={business.businessId}
          />
        ))}
      </DropDownMenu>
    );
  };

  _renderSingleBusinessOption = () => {
    const {businesses} = this.props;
    const [singleBusiness] = businesses;

    return (
      <Tooltip title={singleBusiness.name}>
        <span
          data-test-id="single-new-thread-business"
          style={{
            padding: '10px 32px 10px 12px',
            fontWeight: fontWeight.semiBold,
            ...oneline
          }}
        >
          {singleBusiness.name}
        </span>
      </Tooltip>
    );
  };

  _renderPhoneNumberWarningMessage = () => {
    const {i18n} = this.context;
    const {businesses} = this.props;
    const {selectedBusinessId} = this.state;

    return (
      <div
        data-test-id="non-allow-phonenumber"
        style={{
          width: '100%',
          backgroundColor: lightYellow,
          display: 'flex',
          padding: '20px'
        }}
      >
        <FontIcon className="material-icons" color={slate} style={{display: 'block'}}>
          warning
        </FontIcon>
        <div style={{marginLeft: '15px'}}>
          <h2 style={{marginBottom: '10px'}}>
            {i18n.t('customerInbox.newThread.unknownPhoneNumberWarningTitle')}
          </h2>
          <span>
            {i18n.t('customerInbox.newThread.unknownPhoneNumberWarningText', {
              businessName: findBusinessById(selectedBusinessId, businesses).name
            })}
          </span>
        </div>
      </div>
    );
  };

  _showSuggestionToSearchByName = () => {
    const {i18n} = this.context;

    return (
      <div
        data-test-id="suggestions"
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 20px',
          borderTop: `1px solid ${lightSmoke}`,
          borderBottom: `1px solid ${lightSmoke}`
        }}
      >
        <ul
          style={{
            listStyleType: 'disc',
            marginLeft: '15px',
            color: lightSlate
          }}
        >
          <li style={{marginBottom: '5px'}}>
            {i18n.t('customerInbox.newThread.textCustomersSuggestion')}
          </li>
          <li>{i18n.t('customerInbox.newThread.searchByName')}</li>
        </ul>
      </div>
    );
  };

  _renderNoResultsContent = () => {
    const {i18n} = this.context;
    const {isValidEmail, isValidSmsPhoneNumber, text} = this.props;

    if (isValidEmail || isValidSmsPhoneNumber) {
      return null;
    }

    return (
      <div
        data-test-id="start-new-conversation-no-results"
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 20px',
          borderTop: `1px solid ${lightSmoke}`,
          borderBottom: `1px solid ${lightSmoke}`
        }}
      >
        <h2
          style={{
            color: lightSlate,
            fontSize: '18px',
            fontWeight: '100',
            marginBottom: '20px'
          }}
        >
          {i18n.t('common.noResultForText', {text})}
        </h2>
        <span
          style={{
            color: lightSlate,
            lineHeight: '16px'
          }}
        >
          {i18n.t('customerInbox.newThread.noResultSuggestion')}
        </span>
      </div>
    );
  };

  _renderCustomerItems = (disableSelection) => {
    const {
      enableMultistoreConversation,
      items,
      doCustomerInboxNewConversationFormCustomerClick,
      doStartNewCustomerThreadSelectLegacy
    } = this.props;
    const {selectedBusinessId, selectedBusinessName} = this.state;

    if (!enableMultistoreConversation) {
      return items.map(
        ({businessParticipationId, customerChannel, customerPictureHref, inScope, title}) => (
          <StartNewThreadListItem
            dataTestId="customer-list-item"
            displayName={title}
            key={businessParticipationId}
            pictureHref={customerPictureHref}
            selectItem={doStartNewCustomerThreadSelectLegacy}
            value={{
              inScope,
              businessId: selectedBusinessId,
              businessName: selectedBusinessName,
              participationId: businessParticipationId
            }}
            {...{
              customerChannel,
              disableSelection
            }}
          />
        )
      );
    }

    return items.map(({node: {customer, title}}) => (
      <StartNewThreadListItem
        customerChannel={customer.channel}
        dataTestId="customer-list-item"
        displayName={title}
        key={customer.id}
        pictureHref={customer.pictureHref}
        selectItem={doCustomerInboxNewConversationFormCustomerClick}
        value={{
          business: {
            id: selectedBusinessId,
            name: selectedBusinessName
          },
          customer
        }}
        {...{
          disableSelection
        }}
      />
    ));
  };

  renderResultArea = () => {
    const {
      enableMultistoreConversation,
      formattedPhoneNumber,
      isValidEmail,
      isValidSmsPhoneNumber,
      items,
      text,
      doStartNewCustomerThreadLegacy,
      doCustomerInboxNewConversationNewCustomerFormCustomerClick
    } = this.props;
    const {selectedBusinessHasPhoneNumber, selectedBusinessId, selectedBusinessName} = this.state;

    if (!text) {
      return this._showSuggestionToSearchByName();
    }

    if (items === null) {
      return <LoadingIndicator />;
    }

    const showPhoneNumberWarningMessage = isValidSmsPhoneNumber && !selectedBusinessHasPhoneNumber;

    if (items.length === 0) {
      if (!isValidEmail && !isValidSmsPhoneNumber) {
        return this._renderNoResultsContent();
      }

      const customerChannel = getChannelSetting(isValidEmail ? 'EMAIL' : 'SMS', 'code');
      const displayName = isValidEmail ? text : formattedPhoneNumber;
      const styleOpacityAmount = showPhoneNumberWarningMessage ? 0.2 : 1;
      const threadListItemValueAttribute = isValidEmail ? 'email' : 'phoneNumber';

      return (
        <span data-test-id="start-new-conversation-customers-list">
          <StartNewThreadListItem
            dataTestId="customer-list-item"
            disableSelection={showPhoneNumberWarningMessage}
            selectItem={
              enableMultistoreConversation
                ? doCustomerInboxNewConversationNewCustomerFormCustomerClick
                : doStartNewCustomerThreadLegacy
            }
            style={{opacity: styleOpacityAmount}}
            value={{
              [threadListItemValueAttribute]: displayName,
              businessId: selectedBusinessId,
              businessName: selectedBusinessName
            }}
            {...{
              customerChannel,
              displayName
            }}
          />

          {showPhoneNumberWarningMessage ? this._renderPhoneNumberWarningMessage() : null}
        </span>
      );
    }

    return (
      <div
        data-test-id="start-new-conversation-customers-list"
        style={{
          flex: 1,
          minHeight: '1px',
          height: '100%',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        {this._renderCustomerItems(showPhoneNumberWarningMessage)}
      </div>
    );
  };

  _getBusinessObject(businessId) {
    const {businesses} = this.props;
    const business = findBusinessById(businessId, businesses);

    return {
      selectedBusinessCountry: business.countryCode,
      selectedBusinessHasPhoneNumber: business.hasPhoneNumber,
      selectedBusinessId: business.businessId,
      selectedBusinessName: business.name
    };
  }

  _getSelectedBusiness(event, index, value) {
    const {doCustomerInboxNewConversationFormChanged} = this.props;
    const business = this._getBusinessObject(value);

    this.setState(business);

    doCustomerInboxNewConversationFormChanged(business.selectedBusinessCountry, this._input.value);
  }

  _onChangeSearch(event) {
    const {doCustomerInboxNewConversationFormChanged} = this.props;
    const {selectedBusinessCountry} = this.state;

    doCustomerInboxNewConversationFormChanged(selectedBusinessCountry, event.target.value);
  }

  render() {
    const {i18n} = this.context;
    const {businesses, text} = this.props;

    const isSingleBusiness = businesses.length === 1;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 20px',
            borderBottom: `1px solid ${lightSmoke}`,
            height: '100px',
            minHeight: '100px'
          }}
        >
          <div
            style={{
              display: 'inherit',
              height: '35px',
              alignItems: 'center'
            }}
          >
            <span style={{minWidth: '50px'}}>{i18n.t('customerInbox.newThread.from')}</span>
            {isSingleBusiness
              ? this._renderSingleBusinessOption()
              : this._renderMultipleBusinessOptions()}
          </div>

          <div
            style={{
              display: 'inherit',
              marginTop: '5px',
              height: '35px',
              alignItems: 'center'
            }}
          >
            <span style={{minWidth: '50px'}}>{i18n.t('customerInbox.newThread.to')}</span>
            <input
              data-test-id="search-customer-input"
              autoFocus
              onChange={this._onChangeSearch}
              placeholder={i18n.t('customerInbox.newThread.searchCustomerPlaceholder')}
              ref={(itself) => {
                this._input = itself;

                return this._input;
              }}
              value={text}
              style={{
                width: '100%',
                padding: '10px 32px 10px 12px',
                outline: 'none',
                border: 'none',
                borderRadius: '0',
                maxHeight: '35px',
                height: '35px',
                boxSizing: 'border-box',
                lineHeight: '20px',
                backgroundColor: lightSnow,
                fontSize: '100%'
              }}
            />
          </div>
        </div>

        {this.renderResultArea()}
      </div>
    );
  }
}

StartNewThreadComponent.contextTypes = context;

StartNewThreadComponent.propTypes = {
  businessId: PropTypes.string,
  businesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  enableMultistoreConversation: PropTypes.bool.isRequired,
  formattedPhoneNumber: PropTypes.string,
  isValidEmail: PropTypes.bool,
  isValidSmsPhoneNumber: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  text: PropTypes.string.isRequired,
  doCustomerInboxNewConversationFormChanged: PropTypes.func.isRequired,
  doCustomerInboxNewConversationFormCustomerClick: PropTypes.func.isRequired,
  doStartNewCustomerThreadLegacy: PropTypes.func.isRequired,
  doStartNewCustomerThreadSelectLegacy: PropTypes.func.isRequired,
  doCustomerInboxNewConversationNewCustomerFormCustomerClick: PropTypes.func.isRequired
};

export default StartNewThreadComponent;
