export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_CLOSE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_CLOSE';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_OPEN =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_OPEN';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_FAILURE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_FAILURE';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_SUCCESS =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_SUCCESS';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SELECT_BUSINESS =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SELECT_BUSINESS';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_SELECTION_SET =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_SELECTION_SET';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_CLOSE = 'GLOBAL_COLLEAGUE_INVITATION_POPUP_CLOSE';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_FAILURE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_FAILURE';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_SUCCESS =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_SUCCESS';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_INPUT_UPDATE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_INPUT_UPDATE';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_INVITE_MORE_OPEN =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_INVITE_MORE_OPEN';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_HEADER =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_HEADER';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_NEW_COLLEAGUE_THREAD =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_NEW_COLLEAGUE_THREAD';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_BUSINESS_REMOVE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_BUSINESS_REMOVE';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_COLLEAGUE_REMOVE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_COLLEAGUE_REMOVE';

export const GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT = 'GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_FAILURE =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_FAILURE';
export const GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_SUCCESS =
  'GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_SUCCESS';
