import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import context from '../../../../../../../shared/component/context';
import {BUTTON_LABEL_STYLE} from '../../../../../../../shared/style/theme-v0';
import themeV1 from '../../../../../../../shared/style/theme-v1';
import ErrorMessage from '../../../../common/error-message-component';
import InsightPanelContentBlock from '../layout/insight-panel-content-block-component';
import MultipleBusinessesSelect from '../multiple-businesses-select-component';
import SearchField from '../../../../../../../shared/component/form/search-field-component';
import TableCellText from './table-cell-text-component';
import {fontWeight, spacing, transitionDuration} from '../../../../../../../shared/style/variables';

const SHOW_MORE_WHEN_SIZE_EXCEED = 20;

/**
 * Finally, the component.
 */
class TableRetryableComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doRetry = this._doRetry.bind(this);
    this._getPaginationLabelDisplayedRowsTranslation =
      this._getPaginationLabelDisplayedRowsTranslation.bind(this);
  }

  _doRetry() {
    const {
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      keyword,
      page,
      rowsPerPage,
      onRetry
    } = this.props;

    onRetry(
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      keyword,
      page,
      rowsPerPage
    );
  }

  _getPaginationLabelDisplayedRowsTranslation({from, to, count}) {
    const {i18n} = this.context;

    return i18n.t('common.pagination.fromToOfCount', {from, to, count});
  }

  _getSlicedItems() {
    const {items, showMore} = this.props;

    return showMore ? items : items.slice(0, SHOW_MORE_WHEN_SIZE_EXCEED);
  }

  _showMoreControl() {
    const {items, showMore} = this.props;

    return !showMore && items.length > SHOW_MORE_WHEN_SIZE_EXCEED;
  }

  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      columns,
      dataTestId,
      description,
      failed,
      insightBusinessFilter,
      keyword,
      keywordPlaceholder,
      loading,
      page,
      rowsPerPage,
      title,
      totalCount,
      onChangeKeyword,
      onChangeResultPage,
      onChangeRowsPerPage,
      doInsightBusinessFilterBusinessesSearch,
      doInsightBusinessesSelectionChangeFromPage,
      onShowMore
    } = this.props;

    const buttonStyle = {
      margin: '15px',
      borderRadius: '18px'
    };
    const rowStyle = {
      paddingLeft: '12px',
      paddingRight: '12px'
    };

    return (
      <InsightPanelContentBlock
        {...{
          dataTestId,
          description,
          loading,
          title
        }}
      >
        <div
          style={{
            width: '100%',
            minHeight: '100px',
            backgroundColor: 'white'
          }}
        >
          <FormControl
            fullWidth
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {doInsightBusinessesSelectionChangeFromPage ? (
              <MultipleBusinessesSelect
                onSelectionChange={doInsightBusinessesSelectionChangeFromPage}
                {...{
                  accountBusinesses,
                  businessesSelection,
                  doInsightBusinessFilterBusinessesSearch,
                  ...insightBusinessFilter
                }}
                style={{
                  marginLeft: 0,
                  minWidth: 0,
                  fontWeight: fontWeight.semiBold
                }}
              />
            ) : null}
            {keywordPlaceholder ? (
              <SearchField
                dataTestId="keyword"
                placeholder={keywordPlaceholder}
                value={keyword}
                onChange={onChangeKeyword}
              />
            ) : null}
          </FormControl>

          {(() => {
            /**
             * failure case
             */
            if (failed) {
              return (
                <ErrorMessage>
                  <FlatButton
                    data-test-id="retry"
                    icon={<FontIcon className="material-icons">error</FontIcon>}
                    label={i18n.t('common.retry')}
                    labelStyle={BUTTON_LABEL_STYLE}
                    style={buttonStyle}
                    onClick={this._doRetry}
                  />
                </ErrorMessage>
              );
            }

            /**
             * empty
             */
            if (!loading && !this._getSlicedItems().length) {
              return (
                <div
                  data-test-id="empty-message"
                  style={{
                    margin: spacing.xlarge,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {i18n.t('insight.content.common.noData')}
                </div>
              );
            }

            /**
             * ok
             */
            return (
              <span>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map(({label}) => (
                        <TableCell
                          key={label}
                          style={{
                            ...rowStyle,
                            whiteSpace: 'normal'
                          }}
                        >
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this._getSlicedItems().map((row, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableRow data-test-id="list-item" key={i} selected={row.selected}>
                        {columns.map(({accessor, columnsDataTestId}) => {
                          const content = accessor(row);
                          const isObjectContent = typeof content === 'object';

                          return (
                            <TableCell
                              data-test-id={columnsDataTestId}
                              key={columnsDataTestId}
                              style={rowStyle}
                              title={isObjectContent ? null : content}
                            >
                              {isObjectContent ? (
                                content
                              ) : (
                                <TableCellText style={{maxWidth: '150px'}}>{content}</TableCellText>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>

                  {onChangeResultPage ? (
                    <TableFooter>
                      <TableRow>
                        <MuiThemeProvider theme={themeV1}>
                          <TablePagination
                            colSpan={2}
                            count={totalCount}
                            labelDisplayedRows={this._getPaginationLabelDisplayedRowsTranslation}
                            labelRowsPerPage={i18n.t('common.pagination.rowsPerPage')}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={onChangeResultPage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            SelectProps={{
                              'data-test-id': 'rows-per-page-menu-button',
                              MenuProps: {
                                transitionDuration: transitionDuration.menu
                              }
                            }}
                          />
                        </MuiThemeProvider>
                      </TableRow>
                    </TableFooter>
                  ) : null}
                </Table>

                {this._showMoreControl() ? (
                  <div style={{textAlign: 'center'}}>
                    <FlatButton
                      data-test-id="show-more"
                      label={i18n.t('insight.content.table.showMoreButton')}
                      labelStyle={BUTTON_LABEL_STYLE}
                      style={buttonStyle}
                      onClick={onShowMore}
                    />
                  </div>
                ) : null}
              </span>
            );
          })()}
        </div>
      </InsightPanelContentBlock>
    );
  }
}

TableRetryableComponent.contextTypes = context;

TableRetryableComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any),
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired,
  description: PropTypes.string,
  failed: PropTypes.bool.isRequired,
  keyword: PropTypes.string,
  keywordPlaceholder: PropTypes.string,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  showMore: PropTypes.bool,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
  onChangeKeyword: PropTypes.func,
  onChangeResultPage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func,
  onRetry: PropTypes.func.isRequired,
  onShowMore: PropTypes.func
};

export default TableRetryableComponent;
