import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  ADMINISTRATION_BUSINESS_INFORMATION_LOAD,
  ADMINISTRATION_BUSINESS_INFORMATION_LOAD_FAILURE,
  ADMINISTRATION_BUSINESS_INFORMATION_LOAD_SUCCESS,
  ADMINISTRATION_BUSINESS_OPEN
} from '../../../../actions/administration-business-actions';

const AdministrationBusinessEpic =
  ({graphql}) =>
  (action$) => {
    const openAdministrationBusiness = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_OPEN),
      map(({businessId}) => ({
        type: ADMINISTRATION_BUSINESS_INFORMATION_LOAD,
        businessId
      }))
    );

    const loadBusinessInformation = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_INFORMATION_LOAD),
      mergeMap(({businessId}) =>
        graphql(
          `query {
            businessBotConfiguration(businessId: "${businessId}") {
              enabled
            }
            businessProfileAsAdmin(businessId: "${businessId}") {
              name
            }
          }`,
          {businessId}
        ).pipe(
          map(({businessBotConfiguration, businessProfileAsAdmin}) => ({
            type: ADMINISTRATION_BUSINESS_INFORMATION_LOAD_SUCCESS,
            businessBotConfiguration,
            businessProfileAsAdmin
          })),
          catchError((error) =>
            of({
              type: ADMINISTRATION_BUSINESS_INFORMATION_LOAD_FAILURE,
              error
            })
          )
        )
      )
    );

    return merge(loadBusinessInformation, openAdministrationBusiness);
  };

export default AdministrationBusinessEpic;
