import {TableBody, TableRow, TableRowColumn} from 'material-ui/Table';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import numeral from 'numeral';
import themeV0 from '../../../../../../shared/style/theme-v0';
import {fontSize, fontWeight, spacing} from '../../../../../../shared/style/variables';

// Override some material ui default styles
const TOOLTIP_ROW_COLUMN_STYLE = {
  paddingLeft: 0,
  paddingRight: 0,
  height: 'auto',
  verticalAlign: 'middle'
};
const TOOLTIP_ROW_STYLE = {height: 'auto'};

/**
 * Format a tooltip.
 *
 * @param {Object} i18n
 * @param {String} insightPage
 * @param {Object} itemsConfig
 * @param {Object} points
 * @param {String} section
 * @param {TableRow} subtitle
 * @returns {*}
 */
const getPercentTooltip = ({i18n, insightPage, itemsConfig, points, section, subtitle}) => {
  const configKeys = Object.keys(itemsConfig);

  return (
    <MuiThemeProvider muiTheme={themeV0}>
      <table
        style={{
          borderSpacing: '10px',
          borderCollapse: 'separate',
          padding: 0,
          lineHeight: 1,
          fontSize: fontSize.large
        }}
      >
        <TableBody displayRowCheckbox={false}>
          {subtitle}

          {points.map((point) => {
            const key = configKeys[point.colorIndex];
            const {color} = point;

            return (
              <TableRow
                key={key}
                selectable={false}
                style={{
                  color,
                  ...TOOLTIP_ROW_STYLE
                }}
              >
                <TableRowColumn style={{textAlign: 'right', ...TOOLTIP_ROW_COLUMN_STYLE}}>
                  <div style={{fontSize: fontSize.xxlarge, fontWeight: fontWeight.semiBold}}>
                    {point.y}
                  </div>
                  {i18n.t(`insight.pages.${insightPage}.${section}.chart.tooltip.columns.${key}`)}
                </TableRowColumn>
                <TableRowColumn style={TOOLTIP_ROW_COLUMN_STYLE}>
                  <div
                    style={{
                      display: 'inline-block',
                      marginRight: spacing.xsmall,
                      height: '30px',
                      minWidth: `${Math.max(1, Math.round(point.percentage))}px`,
                      verticalAlign: 'middle',
                      backgroundColor: color
                    }}
                  />
                  {numeral(point.percentage).format('0.0')}%
                </TableRowColumn>
              </TableRow>
            );
          })}
        </TableBody>
      </table>
    </MuiThemeProvider>
  );
};

export {getPercentTooltip, TOOLTIP_ROW_COLUMN_STYLE, TOOLTIP_ROW_STYLE};
