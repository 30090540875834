/**
 * Simplified assert
 *
 * @param {String|Number|Boolean} condition
 * @throws Exception
 */
export default function assert(condition) {
  if (!condition) {
    throw new Error('Assertion failed');
  }
}
