import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';
import {push} from 'react-router-redux';
import IntegrationAreaComponent from '../../../component/content/integration-area/integration-area-component';

const routeSelector = createStructuredSelector({
  path: (routing) => routing.location.pathname
});

const IntegrationAreaContainer = connect(
  createSelector([(state, route) => routeSelector(route)], (route) => {
    return {
      path: route.path
    };
  }),
  {
    push
  }
)(IntegrationAreaComponent);

export default IntegrationAreaContainer;
