import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import {davysGrey, white} from '../../style/colors';

const StyledSwitch = withStyles((theme) => {
  return {
    root: {
      width: '55px',
      height: '30px',
      padding: 0,
      margin: '2px'
    },
    thumb: {
      width: 24,
      height: 24
    },
    switchBase: {
      color: (props) => `${props.switchcolor}`,
      padding: '2.5px 0 0 2px',
      '&.Mui-checked': {
        transform: 'translateX(26px)',
        color: (props) => `${props.switchcolor}`,
        '& + .MuiSwitch-track': {
          backgroundColor: white,
          opacity: 1,
          border: (props) => `1px solid ${props.switchcolor}`
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: davysGrey,
        border: `6px solid ${davysGrey}`
      }
    },
    track: (props) => {
      return {
        borderRadius: '15px',
        border: `1px solid ${props.switchcolor}`,
        backgroundColor: white,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
      };
    },
    checked: {}
  };
})(Switch);

const SwitchButtonComponent = ({
  isSwitchButtonChecked,
  switchcolor,
  dataTestId,
  onHandleChange
}) => (
  <StyledSwitch
    checked={isSwitchButtonChecked}
    switchcolor={switchcolor}
    data-test-id={dataTestId}
    onChange={onHandleChange}
  />
);

SwitchButtonComponent.propTypes = {
  isSwitchButtonChecked: PropTypes.bool.isRequired,
  switchcolor: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default SwitchButtonComponent;
