import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../context';
import FullscreenLayout from '../fullscreen-layout-component';
import LanguageSelect from '../language-select-component';
import mobileAppsSupported from '../../data/mobile-apps';
import {lineHeight, spacing} from '../../style/variables';

const FooterSubBlock = ({children, style}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      ...style
    }}
  >
    {children}
  </div>
);

FooterSubBlock.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

/**
 * Finally, the component.
 */
class FullscreenPageComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {
      children,
      dataTestId,
      description,
      language,
      showFooter,
      title,
      doUIEndMount,
      doUIUpdateLanguage
    } = this.props;

    return (
      <FullscreenLayout
        dataTestId={dataTestId}
        pageTitle={title}
        showLogo
        doUIEndMount={doUIEndMount}
        innerStyle={{
          maxWidth: '450px'
        }}
        style={{
          overflow: 'auto',
          padding: '10px',
          lineHeight: lineHeight.large
        }}
        titleStyle={{
          padding: '0 20px'
        }}
      >
        {description ? (
          <p
            dangerouslySetInnerHTML={{__html: description}}
            style={{
              marginBottom: '20px',
              padding: '0 8px',
              textAlign: 'center'
            }}
          />
        ) : null}

        {children}

        {showFooter ? (
          <div>
            <FooterSubBlock
              style={{
                justifyContent: 'space-around',
                marginTop: spacing.xlarge
              }}
            >
              {Object.entries(mobileAppsSupported).map((entry) => {
                const item = entry[1];
                const {key} = item;

                return (
                  <a
                    data-test-id={`${key}-link`}
                    href={item.storeUrl}
                    key={key}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={i18n.t(`common.mobile.downloadOnTheStore.${key}`)}
                      src={item.storePictureUrl}
                      style={{
                        height: '40px',
                        border: 0
                      }}
                    />
                  </a>
                );
              })}
            </FooterSubBlock>

            <FooterSubBlock
              style={{
                justifyContent: 'space-between',
                marginTop: spacing.large
              }}
            >
              <span style={{marginTop: '12px'}}>© Instaply Inc.</span>

              <LanguageSelect language={language} doUIUpdateLanguage={doUIUpdateLanguage} />
            </FooterSubBlock>
          </div>
        ) : null}
      </FullscreenLayout>
    );
  }
}

FullscreenPageComponent.defaultProps = {
  showFooter: true
};

FullscreenPageComponent.contextTypes = context;

FullscreenPageComponent.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired,
  description: PropTypes.string,
  language: PropTypes.string,
  showFooter: PropTypes.bool,
  title: PropTypes.string.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired
};

export default FullscreenPageComponent;
