import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import ColleagueInboxThreadListItem from '../../../common/inbox/colleague-thread-list-item-component';
import InboxThreadListLayout from '../../../common/inbox/inbox-thread-list-layout-component';
import InboxThreadListResultLayout from '../../../common/inbox/inbox-thread-list-result-layout-component';

class ColleagueInboxThreadListComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._loadMore = this._loadMore.bind(this);
    this._renderList = this._renderList.bind(this);
  }

  _renderList() {
    const {edges, selected, doSelectColleagueThread} = this.props;

    return (
      <div>
        {edges.map(({cursor, node}, i, list) => (
          <ColleagueInboxThreadListItem
            key={cursor}
            isLastChild={!list[i + 1]}
            participationId={node.participationId}
            displayName={node.colleague.displayName}
            pictureHref={node.colleague.pictureHref}
            lastPublicMessageDate={node.lastMessage.createdAt}
            lastPublicMessageBody={node.lastMessage.text}
            lastPublicMessageAttachmentContentType={
              node.lastMessage.attachment ? node.lastMessage.attachment.contentType : null
            }
            unreadMessagesCount={node.unreadMessagesCount}
            isSelected={node.participationId == selected} // eslint-disable-line eqeqeq
            selectItem={doSelectColleagueThread}
          />
        ))}
      </div>
    );
  }

  _loadMore() {
    const {doLoadColleagueInboxThreads, edges} = this.props;

    doLoadColleagueInboxThreads(edges[edges.length - 1].cursor);
  }

  render() {
    const {i18n} = this.context;
    const {edges, loading, pageInfo} = this.props;

    return (
      <InboxThreadListLayout>
        <InboxThreadListResultLayout
          emptyTitle={i18n.t('colleagueInbox.emptyThreadList')}
          hasNextPage={pageInfo.hasNextPage}
          hasReachedLimit={edges.length >= 200}
          items={edges}
          loadMore={this._loadMore}
          renderList={this._renderList}
          showEmptyMessageFillAll
          {...{
            loading
          }}
        />
      </InboxThreadListLayout>
    );
  }
}

ColleagueInboxThreadListComponent.contextTypes = context;

ColleagueInboxThreadListComponent.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasNextPage: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  pageInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.string,
  doLoadColleagueInboxThreads: PropTypes.func.isRequired,
  doSelectColleagueThread: PropTypes.func.isRequired
};

export default ColleagueInboxThreadListComponent;
