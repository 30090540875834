import PropTypes from 'prop-types';
import numeral from 'numeral';
import context from '../../../../../../shared/component/context';
import {generateChartTitle} from '../lib/chart-title-generator';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';

const INSIGHT_PAGE = 'contacts';

/**
 * Finally, the component.
 */
class InsightContactsComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    const {contacts} = insight.contactsPageHistogram;

    const isClassicPeriod = periodTabSelected === 'classic';
    const aggregationPeriod = isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <div>
            <InsightPanelContentBlock dataTestId="contacts">
              <HistogramChart
                failed={contacts.failed}
                items={contacts.data.contactsHistogram}
                loading={contacts.loading}
                translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.items.contacts.chart`, {
                  returnObjects: true
                })}
                type="line"
                {...{
                  aggregationPeriod
                }}
                title={generateChartTitle({
                  figure: numeral(contacts.data.contacts).format(),
                  hasData: contacts.data.contactsHistogram.length,
                  label: i18n.t(`insight.pages.${INSIGHT_PAGE}.items.contacts.chart.title`),
                  showVariation: isClassicPeriod,
                  variation: contacts.data.contactsIncrement
                })}
                series={[
                  {
                    property: 'contacts'
                  }
                ]}
              />
            </InsightPanelContentBlock>
          </div>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightContactsComponent.contextTypes = context;

InsightContactsComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any),
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightContactsComponent;
