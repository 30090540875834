import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import OrganizationAvatar from '../../common/avatar/organization-avatar-component';
import SelectionItem from './global-invitation-selection-item-component';
import {avatarSize} from '../../../../../shared/style/variables';

class BusinessSelectionComponent extends PureComponent {
  render() {
    const {
      businessesSelection,
      doRemoveColleagueInvitationPopupSelectedBusiness,
      organizationPicture
    } = this.props;

    const itemsCount = businessesSelection.length;

    return (
      <ul>
        {businessesSelection.map((business, key) => (
          <SelectionItem
            dataTestId="business"
            key={business.id}
            index={key}
            item={business}
            itemsCount={itemsCount}
            onDeleteClick={() => doRemoveColleagueInvitationPopupSelectedBusiness(business.id)}
            avatar={
              <OrganizationAvatar
                src={organizationPicture}
                name={business.name}
                size={avatarSize.xsmall}
              />
            }
          />
        ))}
      </ul>
    );
  }
}

BusinessSelectionComponent.propTypes = {
  businessesSelection: PropTypes.arrayOf(PropTypes.any).isRequired,
  organizationPicture: PropTypes.string,
  doRemoveColleagueInvitationPopupSelectedBusiness: PropTypes.func.isRequired
};

export default BusinessSelectionComponent;
