import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import numeral from 'numeral';
import {DEBOUNCE_TIME_MULTIPLE_CLICK} from '../../../../../../../shared/data/settings';
import {
  LEGACY_CUSTOMER_THREAD_APPS_AREA_MAXIMIZE,
  LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT
} from '../../../../../actions/customer-thread-apps-actions';
import {
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS
} from '../../../../../actions/customer-thread-app-payment-actions';

const LegacyCustomerThreadAppPaymentEpic =
  ({graphql}) =>
  (action$, state$) => {
    const legacyCancelPaymentFlowOnCancelButtonClick = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL,
        participationId: state$.value.getIn(['legacyCustomerThread', 'participationId'])
      }))
    );

    const legacyChooseActionOnMenuItemClick = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({participationId}) => {
        if (
          state$.value.getIn([
            'legacyCustomerThreadAppPayment',
            'threadsForm',
            participationId,
            'minimized'
          ])
        ) {
          return {
            type: LEGACY_CUSTOMER_THREAD_APPS_AREA_MAXIMIZE,
            participationId
          };
        }

        return {
          type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD,
          participationId
        };
      })
    );

    const legacyLoadPaymentConfiguration = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD),
      mergeMap(({participationId}) => {
        const businessId = state$.value.getIn([
          'legacyCustomerThread',
          'directedToBusinessIdentifier'
        ]);
        const customerId = state$.value.getIn(['legacyCustomerThread', 'participantId']);

        return graphql(
          `query {
            pspAccount(businessId: "${businessId}") {
              paymentConfiguration {
                businessId
                comment
                title
              }
            }
            customer(id: "${customerId}", type: "prospect") {
              id
              firstName
              lastName
            }
          }`
        ).pipe(
          map(({customer, pspAccount}) => {
            return {
              type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS,
              customer,
              participationId,
              pspAccount
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE,
              error,
              participationId
            });
          })
        );
      })
    );

    const legacyMovePaymentFlowToNextStep = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => {
        const participationId = state$.value.getIn(['legacyCustomerThread', 'participationId']);

        if (
          state$.value.getIn([
            'legacyCustomerThreadAppPayment',
            'threadsForm',
            participationId,
            'paymentStep'
          ]) === 'custom'
        ) {
          return {
            type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT,
            participationId
          };
        }

        return {
          type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE,
          participationId
        };
      })
    );

    const legacySubmitPayment = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT),
      mergeMap(({participationId}) => {
        const paymentState = state$.value.getIn([
          'legacyCustomerThreadAppPayment',
          'threadsForm',
          participationId
        ]);

        return graphql('send-payment-request-mutation', {
          amount: numeral(paymentState.get('amount')).multiply(100).value(),
          businessId: paymentState.getIn(['pspAccount', 'paymentConfiguration', 'businessId']),
          customerId: state$.value.getIn(['legacyCustomerThread', 'participantId']),
          description: paymentState.get('comment'),
          firstName: paymentState.getIn(['customer', 'firstName']),
          lastName: paymentState.getIn(['customer', 'lastName'])
        }).pipe(
          map(() => {
            return {
              type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS,
              participationId
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE,
              error,
              participationId
            });
          })
        );
      })
    );

    return merge(
      legacyCancelPaymentFlowOnCancelButtonClick,
      legacyChooseActionOnMenuItemClick,
      legacyLoadPaymentConfiguration,
      legacyMovePaymentFlowToNextStep,
      legacySubmitPayment
    );
  };

export default LegacyCustomerThreadAppPaymentEpic;
