import {cloneDeep} from 'lodash';
import {THREAD_ITEMS_PER_PAGE} from '../../../../../data/settings';
import {CONVERSATION_MENTION_TARGET_TYPES} from '../../../../../data/thread/message';

const getCurrentConversationFromConversations = (conversations) => {
  const selectedItemOrder = conversations.get('selectedItemOrder');

  return conversations.getIn(['items', selectedItemOrder]);
};

const getCurrentConversationFromState = (state$) => {
  const conversations = state$.value.getIn(['customer', 'thread', 'conversations']);

  return getCurrentConversationFromConversations(conversations);
};

const getSliceParams = (direction, messageCursor) => {
  const slice = {
    after: {
      after: messageCursor,
      first: THREAD_ITEMS_PER_PAGE
    },
    around: {
      around: messageCursor,
      first: THREAD_ITEMS_PER_PAGE,
      last: THREAD_ITEMS_PER_PAGE
    },
    before: {
      before: messageCursor,
      last: THREAD_ITEMS_PER_PAGE
    }
  }[direction];

  return slice || {last: THREAD_ITEMS_PER_PAGE};
};

const getConversationByBusinessId = (conversations, business) =>
  conversations.find((conversation) => conversation.business.id === business.id);

const getMentionTag = (mentionedUser) => `<@${mentionedUser.userId}>`;

const getMentionedUser = (mentions) => {
  const [mentionedUser] = mentions;

  return mentionedUser;
};

const getTextWithMention = (message, mentions) => {
  const mentionedTag = getMentionTag(getMentionedUser(mentions));
  if (message.text && mentions) {
    return `${mentionedTag} ${message.text}`;
  }

  return mentionedTag;
};

const getConversationMentionsFormatted = (mentions) => {
  const mentionsToSend = [];
  const mentionedTag = getMentionTag(getMentionedUser(mentions));

  mentionsToSend.push({
    id: mentionedTag,
    type: CONVERSATION_MENTION_TARGET_TYPES.USER
  });

  return mentionsToSend;
};

const getInternalConversationText = (message, mentions) => {
  if (mentions) {
    return getTextWithMention(message, mentions);
  }

  return message.text;
};

const isNotBusinessInConversation = (conversations, businessId) => {
  return conversations.every((conversation) => conversation.business.id !== businessId);
};

const getBusinessToAddIntoNewConversation = (state$, businessId) => {
  const businesses = state$.value.getIn(['account', 'businesses']);

  const business = businesses.find((bussinessElement) => {
    return bussinessElement.get('businessId') === businessId;
  });

  return {
    business: {
      id: business.get('businessId'),
      name: business.get('name'),
      shortName: business.get('shortName')
    },
    id: 'new'
  };
};

const addNewConversationToConversationsList = ({businessId, conversations, state$}) => {
  if (conversations) {
    const conversationsCopy = cloneDeep(conversations);
    conversationsCopy.unshift(getBusinessToAddIntoNewConversation(state$, businessId));

    return conversationsCopy;
  }

  return [getBusinessToAddIntoNewConversation(state$, businessId)];
};

const getExistingConversations = (state$) => {
  return state$.value
    .getIn(['customer', 'thread', 'conversations', 'items'])
    .filter((conversation) => conversation.get('id') !== 'new');
};

const isThereNewConversation = (state$) => {
  return state$.value
    .getIn(['customer', 'thread', 'conversations', 'items'])
    .some((conversation) => conversation.get('id') === 'new');
};

const getExistingConversation = (state$, businessId) =>
  getExistingConversations(state$).find(
    (conversationItem) => conversationItem.getIn(['business', 'id']) === businessId
  );

const isNotSameBusinessInTheNewConversation = (state$) => () => {
  const bid = state$.value.getIn(['customerThread', 'business', 'businessId']);

  return getCurrentConversationFromState(state$).getIn(['business', 'id']) !== bid;
};

const findExistingConversationsByBusinessId = (state$, businessId) =>
  state$.value
    .getIn(['customer', 'thread', 'conversations', 'items'])
    .find((item) => item.getIn(['business', 'id']) === businessId && item.get('id') !== 'new');

export {
  addNewConversationToConversationsList,
  findExistingConversationsByBusinessId,
  getConversationByBusinessId,
  getConversationMentionsFormatted,
  getCurrentConversationFromConversations,
  getCurrentConversationFromState,
  getExistingConversation,
  getExistingConversations,
  getInternalConversationText,
  getSliceParams,
  isNotSameBusinessInTheNewConversation,
  isNotBusinessInConversation,
  isThereNewConversation
};
