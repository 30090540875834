import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import buildCustomerName from '../../../../lib/build-customer-name';
import getChannelSetting from '../../../../data/thread/channels-settings';
import {APP_STATE_BOOT_LOAD_SUCCESS} from '../../../../actions/app-actions';
import {
  CUSTOMER_INBOX_LEAVE,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-new-thread-actions';
import {
  CUSTOMER_THREAD_PAGE_LEAVE,
  LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_FAILURE,
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_SUCCESS
} from '../../../../actions/customer-thread-canned-responses-actions';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-invisible-actions';
import {LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS} from '../../../../actions/customer-thread-transfer-actions';
import {
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD,
  EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS,
  LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED
} from '../../../../actions/ext-actions';

export const DEFAULT_STATE = {
  customerChannel: null,
  directedToBusinessIdentifier: null,
  directedToBusinessName: null,
  focusedInput: 'visible',
  fullName: null,
  isSmsPhoneNumber: null,
  organizationPicture: null,
  participationId: null,
  participantId: null,
  participantName: null,
  picture: null,
  readOnly: false,
  startNewThreadByUnknownContact: null,
  status: null,
  threadType: 'visible',
  unreadInternalMessagesCount: null,
  unreadMessagesCount: null,
  updatingStatus: false,
  userId: null,
  windowExpiration: null,
  cannedResponses: {
    data: {
      businessName: null,
      byCountry: [],
      byStore: [],
      country: null,
      organizationName: null
    },
    hasCannedResponses: null,
    isLoading: false
  }
};

const resetState = () => fromJS(DEFAULT_STATE);

/**
 * Update thread after a transfer.
 *
 * @param {Object} state
 * @param {Boolean} isInScope
 * @param {Number} newBusinessId
 * @param {String} newBusinessName
 * @returns {Object}
 */
const updateTransferredThread = (state, newBusinessId, newBusinessName, isInScope) => {
  const newState = state
    .set('directedToBusinessIdentifier', newBusinessId)
    .set('directedToBusinessName', newBusinessName)
    .set('readOnly', !isInScope);

  if (isInScope) {
    return newState;
  }

  return newState.set(
    'participantName',
    newState
      .get('participantName')
      .replace(/\s/i, '')
      .replace(/(.{2}).*?(.{2})$/i, '$1*****$2')
  );
};

/**
 * Update thread optimistically.
 *
 * @param {Object} state
 * @param {String} customerChannel
 * @param {String} directedToBusinessName
 * @param {String} participantName
 * @param {String} participationId
 * @returns {Object}
 */
const onSearchInboxSelectItem = (
  state,
  {customerChannel, directedToBusinessName, participantName, participationId}
) =>
  fromJS(DEFAULT_STATE)
    .set('customerChannel', customerChannel)
    .set('directedToBusinessName', directedToBusinessName)
    .set('participantName', participantName)
    .set('participationId', participationId);

/**
 * Update unread message count.
 *
 * @param {Object} state
 * @param {Object} data
 * @returns {Object}
 */
const updateUnreadMessageCount = (state, {data}) => {
  // eslint-disable-next-line eqeqeq
  if (data.participationId != state.get('participationId')) {
    return state;
  }

  return state.set(
    'unreadMessagesCount',
    data.acknowledged ? 0 : data.conversationUnreadMessagesCount
  );
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Make user id available to perform comparisons
   * when new messages are sent by employees.
   *
   * Because we are not using sockets as primary transport
   * for sending messages, we can't use the broadcast function
   * which allow to send message to everyone, except the author.
   *
   * However, we still use the socket notification (event sent to
   * the user who sent the message) in order to give a receive
   * status to the message if it's found in the current conversation.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_SUCCESS]: (state, {data}) => {
    return state
      .set('fullName', data.account.fullName)
      .set('organizationPicture', data.account.organizationPicture)
      .set('picture', data.account.picture)
      .set('userId', data.account.id);
  },

  /**
   * Reset state when leaving thread component
   * in order to prevent badges from not being correctly updated.
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_PAGE_LEAVE]: resetState,

  /**
   * Reset state when leaving an inbox
   * in order to prevent badges from not being correctly updated.
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_LEAVE]: resetState,

  /**
   * Optimistic update of the thread using data from the thread list.
   *
   * @param {Object} state
   * @param {Object} data resulting from the get-inbox resource
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: (state, data) => {
    return state
      .set('customerChannel', null) // maybe to set dynamically in future
      .set('directedToBusinessName', data.directedToBusinessName)
      .set('participantName', data.participantName)
      .set('participationId', data.participationId)
      .set('readOnly', data.readOnly)
      .set('status', data.status)
      .set('threadType', 'visible');
  },

  /**
   * Optimistic update of the thread using data from the thread search result list.
   *
   * @param {Object} state
   * @param {Object} data resulting from the get-inbox resource
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER]: onSearchInboxSelectItem,
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: onSearchInboxSelectItem,

  /**
   * Reset some state props (not updatable optimistically) when the thread visible loading start.
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD]: (state) =>
    state.set('cannedResponses', fromJS(DEFAULT_STATE.cannedResponses)),

  /**
   * The thread is open, either from url, either after a thread select in the inbox.
   *
   * @param {Object} state
   * @param {Object} data resulting of the get-conversation resource
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    return state
      .setIn(['cannedResponses', 'hasCannedResponses'], data.hasCannedResponses)
      .set('customerChannel', data.customerChannel)
      .set('directedToBusinessIdentifier', data.directedToBusinessIdentifier)
      .set('directedToBusinessName', data.directedToBusinessName)
      .set('windowExpiration', data.windowExpiration)
      .set('isSmsPhoneNumber', data.isSmsPhoneNumber)
      .set('participantIcon', data.participantIcon !== 'default' ? data.participantIcon : null)
      .set('participantId', data.participantId)
      .set('participantName', data.participantName)
      .set('participationId', data.id)
      .set('readOnly', data.readOnly)
      .set('status', data.status)
      .set('unreadMessagesCount', 0)
      .set('unreadInternalMessagesCount', data.unreadInternalMessagesCount);
  },

  /**
   * Reset the thread when the employee starts a new thread.
   *
   * @param {Object} state
   * @param {String} businessId
   * @param {String} businessName
   * @param {String} email
   * @param {String} phoneNumber
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD]: (
    state,
    {businessId, businessName, email, phoneNumber}
  ) => {
    const customerChannel = getChannelSetting(email ? 'EMAIL' : 'SMS', 'code');

    return fromJS(DEFAULT_STATE)
      .set('customerChannel', customerChannel)
      .set('directedToBusinessIdentifier', businessId)
      .set('directedToBusinessName', businessName)
      .set('focusedInput', 'visible')
      .set('participantName', buildCustomerName(email, null, null, phoneNumber))
      .set('participationId', 'new')
      .set('threadType', 'visible');
  },

  /**
   * Some impact of invisible thread load.
   * @param {Immutable.Map} state
   * @returns {Immutable.Map} new state
   */
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD]: (state, {fromId}) => {
    if (fromId) {
      return state.set('threadType', 'invisible');
    }

    return state;
  },
  [LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD_SUCCESS]: (state) => {
    return state.set('unreadInternalMessagesCount', 0);
  },

  /**
   * Optimistically update the status.
   *
   * @param {Object} state
   * @param {String} newStatus
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE]: (state, {newStatus}) => {
    return state.set('status', newStatus).set('updatingStatus', true);
  },

  /**
   * Confirm status update.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS]: (state) => {
    return state.set('updatingStatus', false);
  },

  /**
   * Rollback to previous status when update status failed.
   *
   * @param {Object} state
   * @param {String} previousStatus
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE]: (state, {previousStatus}) => {
    return state.set('status', previousStatus).set('updatingStatus', false);
  },

  /**
   * Marks the customer thread has focused.
   *
   * @param {Object} state
   * @param {String} threadType
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE]: (state, {threadType}) => {
    return state.set('focusedInput', threadType);
  },

  /**
   * Triggered when user is in small screen view and switch between invisible and visible thread.
   *
   * @param {Object} state
   * @param {String} threadType
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE]: (state, {threadType}) => {
    return state
      .set('focusedInput', threadType)
      .set('threadType', threadType)
      .set(threadType === 'visible' ? 'unreadMessagesCount' : 'unreadInternalMessagesCount', 0);
  },

  /**
   * Optimistic update after a successful transfer a thread to a business made by current user.
   *
   * @param {Object} state
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS]: (state, {params}) => {
    return updateTransferredThread(state, params.id, params.name, params.inScope);
  },

  /**
   * Change the thread owner & some other properties after it's transferred to a business.
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Boolean} doneByOtherUserToCurrentThread
   * @param {Boolean} newBusinessIsInMyScope
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS]: (
    state,
    {data, doneByOtherUserToCurrentThread, newBusinessIsInMyScope}
  ) => {
    if (doneByOtherUserToCurrentThread) {
      return updateTransferredThread(
        state,
        data.newBusinessId,
        data.newBusinessName,
        newBusinessIsInMyScope
      );
    }

    return state;
  },

  /* Socket notifications */

  /**
   * Change the thread status if opened.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED]: (state, {data}) => {
    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId')) {
      return state;
    }

    return state.set('status', data.newStatus);
  },

  /**
   * Update the inbox when a new message comes from the customer.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD]: (state, {data}) => {
    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId')) {
      return state;
    }

    return state.set(
      'unreadInternalMessagesCount',
      data.acknowledged ? 0 : data.conversationUnreadInternalMessagesCount
    );
  },

  /**
   * Update the inbox when a new message comes from employee on the customer thread.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER]: updateUnreadMessageCount,

  /**
   * Update some thread props when a new message comes from the customer.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION]: (state, {data}) => {
    const newState = updateUnreadMessageCount(state, {data});

    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId')) {
      return newState;
    }

    return newState.set('windowExpiration', data.windowExpiration);
  },

  /**
   * Optimistically set the status to replied while sending a message in a thread which status was "waiting".
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE]: (state) => {
    if (state.get('status') !== 'waiting') {
      return state;
    }

    return state.set('status', 'replied');
  },

  /**
   * Restore previous thread status on send message failure.
   *
   * @param {Object} state
   * @param {String} previousStatus
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE]: (state, {previousStatus}) => {
    return state.set('status', previousStatus);
  },

  /**
   * Set the participation id when thread is created.
   *
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: (state, {participationId}) => {
    return state.set('participationId', participationId);
  },

  /**
   * Canned responses handling.
   */
  [CUSTOMER_THREAD_CANNED_RESPONSES_LOAD]: (state) =>
    state.setIn(['cannedResponses', 'isLoading'], true),

  [CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_FAILURE]: (state) =>
    state.setIn(['cannedResponses', 'isLoading'], false),

  [CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_SUCCESS]: (state, {payload: {data}}) => {
    // "hasCannedResponses" may not be set before during customer thread loading, for example for a new thread
    const hasCannedResponses = data.byCountry.length > 0 || data.byStore.length > 0;

    return state.update('cannedResponses', (cannedResponses) =>
      cannedResponses
        .set('data', fromJS(data))
        .set('hasCannedResponses', hasCannedResponses)
        .set('isLoading', false)
    );
  },

  /**
   * Customer profile details were successfully updated.
   *
   * @param {Object} state
   * @param {String} participantName
   */
  [LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS]: (state, {participantName}) => {
    return state.set('participantName', participantName);
  }
});
