import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mapTo, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {DEBOUNCE_TIME_TYPING_FILTER} from '../../../../../../shared/data/settings';
import {
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN_BUTTON_CLICK,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE,
  CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS
} from '../../../../actions/customer-thread-invisible-mention-popup-actions';

const CustomerThreadInvisibleMentionEpic =
  ({graphql}) =>
  (action$, state$) => {
    const clickButtonColleagueMentionPopup = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN_BUTTON_CLICK),
      mapTo({
        type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN
      })
    );

    const searchColleaguesToMention = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      mergeMap(({payload: {text}}) => {
        return graphql('search-colleagues-to-mention-query', {
          text,
          businessIds: state$.value.getIn(['customerThread', 'business', 'id'])
        }).pipe(
          map(({colleagueSearch}) => {
            return {
              type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS,
              payload: {
                items: colleagueSearch.edges.map(({node}) => node)
              }
            };
          }),
          takeUntil(
            action$.pipe(ofType(CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE))
          ),
          catchError((error) => {
            return of({
              type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE,
              error
            });
          })
        );
      })
    );

    const searchColleaguesOnOpenMention = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_OPEN),
      mapTo({
        type: CUSTOMER_CONVERSATION_INTERNAL_MENTION_POPUP_SEARCH_COLLEAGUE,
        payload: {
          text: ''
        }
      })
    );

    return merge(
      clickButtonColleagueMentionPopup,
      searchColleaguesOnOpenMention,
      searchColleaguesToMention
    );
  };

export default CustomerThreadInvisibleMentionEpic;
