import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import LoadingIndicator from '../../../../../../../../../../../shared/component/loading-indicator-component';
import {fontSize} from '../../../../../../../../../../../shared/style/variables';
import {black, lightSlate} from '../../../../../../../../../../../shared/style/colors';

const LegacyPaymentCustomComponent = ({
  comment,
  commentPlaceHolder,
  onAppPaymentCustomChange,
  participationId,
  submitting
}) => {
  const onCommentInputChange = (event) => {
    onAppPaymentCustomChange(event.target.value, participationId);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 5px',
        width: '100%'
      }}
    >
      <TextField
        autoFocus
        disabled={submitting}
        fullWidth
        inputProps={{
          'data-test-id': 'comment-input',
          maxLength: 80,
          style: {
            color: black
          }
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          disableUnderline: true
        }}
        InputLabelProps={{
          style: {
            fontSize: fontSize.xxxlarge,
            color: lightSlate
          }
        }}
        multiline
        placeholder={commentPlaceHolder}
        rows={4}
        onChange={onCommentInputChange}
        value={comment}
      />
      {submitting ? <LoadingIndicator overlay /> : null}
    </div>
  );
};

LegacyPaymentCustomComponent.propTypes = {
  comment: PropTypes.string,
  commentPlaceHolder: PropTypes.string.isRequired,
  participationId: PropTypes.string.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default LegacyPaymentCustomComponent;
