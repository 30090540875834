import {PureComponent} from 'react';
import context from '../../../../../../shared/component/context';
import SectionTextWithLink from './section-text-with-link-component';

class DocumentationSectionComponent extends PureComponent {
  render() {
    const {i18n} = this.context;

    return (
      <SectionTextWithLink
        linkLabel={i18n.t('integrationArea.apiSdkDocumentation.documentation')}
        {...this.props}
      />
    );
  }
}

DocumentationSectionComponent.contextTypes = context;

export default DocumentationSectionComponent;
