import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import administrationBusinessMapDispatchToProps from '../../lib/administration-business-map-dispatch-to-props';
import AdministrationBusinessPageChannels from '../../../../../component/content/administration/business/page/administration-business-page-channels-component';
import isManagerOfBusiness from '../../../../../lib/is-manager-of-business';
import {
  findBusinessLocaleByBusinessId,
  findBusinessAddressByBusinessId,
  findBusinessNameByBusinessId
} from '../../../../../lib/business-helper';

const AdministrationBusinessPageChannelsContainer = connect(
  createSelector(
    [
      (state) => state.get('account'),
      (state) => state.getIn(['administration', 'business', 'channels', 'gbm']),
      (state) => state.getIn(['administrationBusinessPageChannels', 'facebookChannel']),
      (state) => state.getIn(['administration', 'layout', 'currentPageContext']),
      (state) => state.getIn(['administration', 'organization', 'channels', 'gbm']),
      (state) => state.get('featureToggle')
    ],
    (
      account,
      administrationBusinessChannelsGbm,
      administrationFacebookChannel,
      administrationPageContext,
      administrationOrganizationChannelsGbm,
      featureToggle
    ) => {
      const businessId = administrationPageContext.get('businessId');
      const accountBusinesses = account.get('businesses').toJS();

      const business = {
        name: findBusinessNameByBusinessId(accountBusinesses, businessId),
        address: findBusinessAddressByBusinessId(accountBusinesses, businessId),
        id: businessId,
        locale: findBusinessLocaleByBusinessId(accountBusinesses, businessId)
      };

      return {
        business,
        enableBusinessMessages: featureToggle.get('enable_business_messages'),
        enableChannelAdmin: featureToggle.get('enable_channel_admin'),
        facebookChannel: administrationFacebookChannel.toJS(),
        gbmOrganizationState: administrationOrganizationChannelsGbm.toJS(),
        gbmBusinessState: administrationBusinessChannelsGbm.toJS(),
        isBusinessManager: isManagerOfBusiness(businessId, accountBusinesses),
        isManager: account.get('manager')
      };
    }
  ),
  administrationBusinessMapDispatchToProps
)(AdministrationBusinessPageChannels);

export default AdministrationBusinessPageChannelsContainer;
