export default (char) =>
  [
    // basic chars that should be avoid
    '#',
    '*',
    '®',
    '©',
    // some emojis that are not detected.
    // @todo may become useless when emoji libraries will be upgraded
    '🤩'
  ].includes(char);
