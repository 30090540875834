export const CUSTOMER_CONVERSATION_MARK_AS_READ = 'CUSTOMER_CONVERSATION_MARK_AS_READ';
export const CUSTOMER_CONVERSATION_MARK_AS_READ_FAILURE =
  'CUSTOMER_CONVERSATION_MARK_AS_READ_FAILURE';
export const CUSTOMER_CONVERSATION_MARK_AS_READ_SUCCESS =
  'CUSTOMER_CONVERSATION_MARK_AS_READ_SUCCESS';

export const CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK =
  'CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK';

export const CUSTOMER_CONVERSATION_URL_GO = 'CUSTOMER_CONVERSATION_URL_GO';

export const CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL =
  'CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL';

export const CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE =
  'CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_ACTIVE';
export const CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_INACTIVE =
  'CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_BECAME_INACTIVE';
export const CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD =
  'CUSTOMER_THREAD_ACTIVITY_EMPLOYEE_LEFT_THREAD';
export const CUSTOMER_THREAD_ACTIVITY_START_WATCHING = 'CUSTOMER_THREAD_ACTIVITY_START_WATCHING';
export const CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING = 'CUSTOMER_THREAD_ACTIVITY_STOP_WATCHING';

export const CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_FAILURE =
  'CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_FAILURE';
export const CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_SUCCESS =
  'CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_SUCCESS';

export const CUSTOMER_THREAD_CONVERSATION_TAB_CLICK = 'CUSTOMER_THREAD_CONVERSATION_TAB_CLICK';

export const CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD =
  'CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD';
export const CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE =
  'CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE';
export const CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS =
  'CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS';

export const CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE =
  'CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_FAILURE';
export const CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS =
  'CUSTOMER_THREAD_EMPLOYEE_USAGE_MUTATION_REPORT_SUCCESS';

export const CUSTOMER_THREAD_PAGE_LEAVE = 'CUSTOMER_THREAD_PAGE_LEAVE';

export const CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE =
  'CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE';

export const CUSTOMER_THREAD_STATUS_BUTTON_CLICK = 'CUSTOMER_THREAD_STATUS_BUTTON_CLICK';
export const CUSTOMER_THREAD_STATUS_MANUAL_UPDATE = 'CUSTOMER_THREAD_STATUS_MANUAL_UPDATE';
export const CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE =
  'CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE';
export const CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS =
  'CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS';

// inbox click item flow
export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_START =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_START';

export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD';

export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_FAILURE =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_FAILURE';

export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD';

export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_FAILURE =
  'LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_CONVERSATIONS_LOAD_FAILURE';

// Legacy

export const LEGACY_CUSTOMER_THREAD_CHANGED = 'LEGACY_CUSTOMER_THREAD_CHANGED';

export const LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE = 'LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE';

export const LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE =
  'LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE';
export const LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE =
  'LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_FAILURE';
export const LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE =
  'LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE';

export const LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD =
  'LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD';
export const LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE =
  'LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE';
export const LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK =
  'LEGACY_CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK';

export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECT =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECT';

export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD';
export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_FAILURE =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_FAILURE';
export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_THREAD_LOAD = 'LEGACY_CUSTOMER_THREAD_LOAD';

export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD';
export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_FAILURE =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_FAILURE';
export const LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_CONVERSATIONS_LOAD_SUCCESS';
