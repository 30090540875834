import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {fontWeight, lineHeight, radius, spacing} from '../../../../../shared/style/variables';
import {aliceBlue, mistyRose} from '../../../../../shared/style/colors';

const ContentImportantMessageComponent = ({description, style, title, type, ...otherProps}) => {
  const iconStyle = {fontSize: '1rem'};
  const {backgroundColor, icon} = {
    error: {
      backgroundColor: mistyRose,
      icon: <ReportProblemIcon style={iconStyle} />
    },
    info: {
      backgroundColor: aliceBlue,
      icon: <InfoIcon style={iconStyle} />
    }
  }[type];

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor,
        borderRadius: radius.large,
        display: 'flex',
        lineHeight: lineHeight.large,
        marginBottom: '10px',
        minHeight: '50px',
        padding: spacing.medium,
        width: '100%',
        ...style
      }}
      {...otherProps}
    >
      <div style={{lineHeight: 1}}>{icon}</div>
      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '6px'}}>
        {title ? (
          <span data-test-id="title" style={{fontWeight: fontWeight.semiBold}}>
            {title}
          </span>
        ) : null}
        {description ? (
          <span
            dangerouslySetInnerHTML={{__html: description}}
            data-test-id="description"
            style={{marginTop: '2px'}}
          />
        ) : null}
      </div>
    </div>
  );
};

ContentImportantMessageComponent.propTypes = {
  'data-test-id': PropTypes.string.isRequired,
  description: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default ContentImportantMessageComponent;
