import PropTypes from 'prop-types';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {Tab, Tabs} from '@material-ui/core';
import themeV1 from '../../../../../../shared/style/theme-v1';
import ThreadCustomBusinessTab from './thread-custom-business-tab-component';
import ThreadCustomBusinessTabsScrollButtonsComponent from './thread-custom-business-tabs-scroll-buttons-component';
import {blue, blackPure, lightSmoke} from '../../../../../../shared/style/colors';
import {CLICKABLE_AREAS} from '../../../../data/ui/clickable-areas';

const StyledTabs = withStyles({
  root: {
    borderBottom: `1px solid ${lightSmoke}`,
    height: '55px'
  },
  indicator: {
    backgroundColor: blue
  }
})(Tabs);
const StyledTab = withStyles({
  root: {
    borderTop: '1px solid transparent',
    color: blackPure,
    opacity: 1,
    textTransform: 'none',
    marginRight: '30px',
    width: '160px',
    height: '55px',
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      borderBottom: `3px solid ${blue}`,
      color: blue
    },
    '&$selected': {
      borderBottom: `3px solid ${blue}`,
      color: blue
    }
  },
  selected: {}
})(Tab);

const CustomerThreadBusinessTabsComponent = ({
  account,
  conversationOrderSelected,
  customerThreadConversations,
  i18n,
  isNewConversation,
  onConversationTabClick,
  onUIAreaClick
}) => {
  const takenLabel = i18n.t('customerInbox.threadList.assignment.takenLabel');

  const handleTabChange = (_, newTab) => {
    onConversationTabClick(newTab);
  };

  const onClickBusinessesTabs = () => {
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.BUSINESSES_TABS);
  };

  return (
    <MuiThemeProvider theme={themeV1}>
      <StyledTabs
        data-test-id="thread-store-tabs"
        onChange={handleTabChange}
        onClick={onClickBusinessesTabs}
        scrollButtons="auto"
        ScrollButtonComponent={ThreadCustomBusinessTabsScrollButtonsComponent}
        value={conversationOrderSelected}
        variant="scrollable"
      >
        {customerThreadConversations.map(
          (
            {business, engaged, status, unreadInternalMessages, unreadVisibleMessages},
            tabItemPosition
          ) => (
            <StyledTab
              data-test-id={`customer-thread-tab-${tabItemPosition}`}
              key={business.id}
              label={
                <ThreadCustomBusinessTab
                  selectedTab={conversationOrderSelected}
                  {...{
                    account,
                    business,
                    engaged,
                    i18n,
                    isNewConversation,
                    status,
                    tabItemPosition,
                    takenLabel,
                    unreadInternalMessages,
                    unreadVisibleMessages
                  }}
                />
              }
            />
          )
        )}
      </StyledTabs>
    </MuiThemeProvider>
  );
};

CustomerThreadBusinessTabsComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  conversationOrderSelected: PropTypes.number,
  customerThreadConversations: PropTypes.arrayOf(PropTypes.object).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isNewConversation: PropTypes.bool.isRequired,
  onConversationTabClick: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired
};

export default CustomerThreadBusinessTabsComponent;
