// Header
export const INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH =
  'INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH';
export const INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_FAILURE =
  'INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_FAILURE';
export const INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS =
  'INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS';

export const INSIGHT_PERIOD_TAB_CHANGE = 'INSIGHT_PERIOD_TAB_CHANGE';

// Contacts
export const INSIGHT_CONTACT_CENTERS_CONTACTS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONTACT_CENTERS_CONTACTS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_CONTACT_CENTERS_CONTACTS_OPEN = 'INSIGHT_CONTACT_CENTERS_CONTACTS_OPEN';

export const INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD =
  'INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD';
export const INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_SUCCESS';

// Devices
export const INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD =
  'INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD';
export const INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_FAILURE =
  'INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_FAILURE';
export const INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_SUCCESS =
  'INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_SUCCESS';

export const INSIGHT_DEVICES_OPEN = 'INSIGHT_DEVICES_OPEN';

// Users
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD';
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_SUCCESS';
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_SHOW_MORE =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_SHOW_MORE';

export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD';
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_SUCCESS';
export const INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_SHOW_MORE =
  'INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_SHOW_MORE';

export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_SUCCESS';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE';

export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_SUCCESS';

export const INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE';

export const INSIGHT_CONTACT_CENTERS_USERS_OPEN = 'INSIGHT_CONTACT_CENTERS_USERS_OPEN';
export const INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS';

export const INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

// Web connected users
export const INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN =
  'INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN';
export const INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD =
  'INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD';
export const INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_SUCCESS';

// Waiting customers
export const INSIGHT_WAITING_CUSTOMERS_OPEN = 'INSIGHT_WAITING_CUSTOMERS_OPEN';
export const INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_WAITING_CUSTOMERS_CSV_LOAD = 'INSIGHT_WAITING_CUSTOMERS_CSV_LOAD';
export const INSIGHT_WAITING_CUSTOMERS_CSV_LOAD_FAILURE =
  'INSIGHT_WAITING_CUSTOMERS_CSV_LOAD_FAILURE';
export const INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD = 'INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD';
export const INSIGHT_WAITING_CUSTOMERS_LOAD = 'INSIGHT_WAITING_CUSTOMERS_LOAD';
export const INSIGHT_WAITING_CUSTOMERS_LOAD_FAILURE = 'INSIGHT_WAITING_CUSTOMERS_LOAD_FAILURE';
export const INSIGHT_WAITING_CUSTOMERS_LOAD_SUCCESS = 'INSIGHT_WAITING_CUSTOMERS_LOAD_SUCCESS';

// Messages
export const INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD =
  'INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD';
export const INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_FAILURE =
  'INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_FAILURE';
export const INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_SUCCESS =
  'INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_SUCCESS';

export const INSIGHT_MESSAGES_OPEN = 'INSIGHT_MESSAGES_OPEN';

export const INSIGHT_MESSAGES_CSV_DOWNLOAD = 'INSIGHT_MESSAGES_CSV_DOWNLOAD';
export const INSIGHT_MESSAGES_CSV_LOAD = 'INSIGHT_MESSAGES_CSV_LOAD';

// New customers
export const INSIGHT_NEW_CUSTOMERS_OPEN = 'INSIGHT_NEW_CUSTOMERS_OPEN';

export const INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_NEW_CUSTOMERS_LOAD = 'INSIGHT_NEW_CUSTOMERS_LOAD';
export const INSIGHT_NEW_CUSTOMERS_LOAD_FAILURE = 'INSIGHT_NEW_CUSTOMERS_LOAD_FAILURE';
export const INSIGHT_NEW_CUSTOMERS_LOAD_SUCCESS = 'INSIGHT_NEW_CUSTOMERS_LOAD_SUCCESS';

// Preferred channel
export const INSIGHT_PREFERRED_CHANNEL_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_PREFERRED_CHANNEL_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_PREFERRED_CHANNEL_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_PREFERRED_CHANNEL_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_PREFERRED_CHANNEL_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_PREFERRED_CHANNEL_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_PREFERRED_CHANNEL_OPEN = 'INSIGHT_PREFERRED_CHANNEL_OPEN';

export const INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD =
  'INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD';
export const INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_FAILURE =
  'INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_FAILURE';
export const INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_SUCCESS =
  'INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_SUCCESS';

// Response rate
export const INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE';

export const INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD =
  'INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD';
export const INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_FAILURE =
  'INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_FAILURE';
export const INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_SUCCESS =
  'INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_SUCCESS';

export const INSIGHT_RESPONSE_RATE_OPEN = 'INSIGHT_RESPONSE_RATE_OPEN';

export const INSIGHT_RESPONSE_RATE_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK =
  'INSIGHT_RESPONSE_RATE_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK';

export const INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD';
export const INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_FAILURE =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_FAILURE';
export const INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_SUCCESS =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_SUCCESS';
export const INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_SORT_BUTTON_CLICK =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_SORT_BUTTON_CLICK';
export const INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION';
export const INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD';
export const INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD =
  'INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD';

// Satisfaction
export const INSIGHT_SATISFACTION_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_SATISFACTION_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_SATISFACTION_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_SATISFACTION_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_SATISFACTION_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_SATISFACTION_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_SATISFACTION_NPS_SCORE_LOAD = 'INSIGHT_SATISFACTION_NPS_SCORE_LOAD';
export const INSIGHT_SATISFACTION_NPS_SCORE_LOAD_FAILURE =
  'INSIGHT_SATISFACTION_NPS_SCORE_LOAD_FAILURE';
export const INSIGHT_SATISFACTION_NPS_SCORE_LOAD_SUCCESS =
  'INSIGHT_SATISFACTION_NPS_SCORE_LOAD_SUCCESS';

export const INSIGHT_SATISFACTION_OPEN = 'INSIGHT_SATISFACTION_OPEN';

// Contacts
export const INSIGHT_CONTACTS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONTACTS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_CONTACTS_OPEN = 'INSIGHT_CONTACTS_OPEN';
export const INSIGHT_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';
export const INSIGHT_CONTACTS_LOAD = 'INSIGHT_CONTACTS_LOAD';
export const INSIGHT_CONTACTS_LOAD_FAILURE = 'INSIGHT_CONTACTS_LOAD_FAILURE';
export const INSIGHT_CONTACTS_LOAD_SUCCESS = 'INSIGHT_CONTACTS_LOAD_SUCCESS';

// Connected customers
export const INSIGHT_CONNECTED_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_CONNECTED_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_CONNECTED_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_CONNECTED_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';
export const INSIGHT_CONNECTED_CUSTOMERS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONNECTED_CUSTOMERS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_CONNECTED_CUSTOMERS_OPEN = 'INSIGHT_CONNECTED_CUSTOMERS_OPEN';
export const INSIGHT_CONNECTED_CUSTOMERS_LOAD = 'INSIGHT_CONNECTED_CUSTOMERS_LOAD';
export const INSIGHT_CONNECTED_CUSTOMERS_LOAD_FAILURE = 'INSIGHT_CONNECTED_CUSTOMERS_LOAD_FAILURE';
export const INSIGHT_CONNECTED_CUSTOMERS_LOAD_SUCCESS = 'INSIGHT_CONNECTED_CUSTOMERS_LOAD_SUCCESS';

// Conversations
export const INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';
export const INSIGHT_CONVERSATIONS_OPEN = 'INSIGHT_CONVERSATIONS_OPEN';
export const INSIGHT_CONVERSATIONS_LOAD = 'INSIGHT_CONVERSATIONS_LOAD';
export const INSIGHT_CONVERSATIONS_LOAD_FAILURE = 'INSIGHT_CONVERSATIONS_LOAD_FAILURE';
export const INSIGHT_CONVERSATIONS_LOAD_SUCCESS = 'INSIGHT_CONVERSATIONS_LOAD_SUCCESS';

export const INSIGHT_CONVERSATIONS_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK =
  'INSIGHT_CONVERSATIONS_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK';
export const INSIGHT_CONVERSATIONS_RANKING_LOAD = 'INSIGHT_CONVERSATIONS_RANKING_LOAD';
export const INSIGHT_CONVERSATIONS_RANKING_LOAD_FAILURE =
  'INSIGHT_CONVERSATIONS_RANKING_LOAD_FAILURE';
export const INSIGHT_CONVERSATIONS_RANKING_LOAD_SUCCESS =
  'INSIGHT_CONVERSATIONS_RANKING_LOAD_SUCCESS';
export const INSIGHT_CONVERSATIONS_RANKING_SORT_BUTTON_CLICK =
  'INSIGHT_CONVERSATIONS_RANKING_SORT_BUTTON_CLICK';
export const INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION =
  'INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION';
export const INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD = 'INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD';
export const INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD =
  'INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD';

// Inbound conversations
export const INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_NEW_INBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_NEW_INBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_NEW_INBOUND_CONVERSATIONS_OPEN = 'INSIGHT_NEW_INBOUND_CONVERSATIONS_OPEN';
export const INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD = 'INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD';
export const INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_FAILURE =
  'INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_FAILURE';
export const INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS =
  'INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS';

// Response time
export const INSIGHT_RESPONSE_TIME_OPEN = 'INSIGHT_RESPONSE_TIME_OPEN';
export const INSIGHT_RESPONSE_TIME_LOAD = 'INSIGHT_RESPONSE_TIME_LOAD';
export const INSIGHT_RESPONSE_TIME_LOAD_FAILURE = 'INSIGHT_RESPONSE_TIME_LOAD_FAILURE';
export const INSIGHT_RESPONSE_TIME_LOAD_SUCCESS = 'INSIGHT_RESPONSE_TIME_LOAD_SUCCESS';
export const INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_RESPONSE_TIME_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK =
  'INSIGHT_RESPONSE_TIME_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK';
export const INSIGHT_RESPONSE_TIME_RANKING_LOAD = 'INSIGHT_RESPONSE_TIME_RANKING_LOAD';
export const INSIGHT_RESPONSE_TIME_RANKING_LOAD_FAILURE =
  'INSIGHT_RESPONSE_TIME_RANKING_LOAD_FAILURE';
export const INSIGHT_RESPONSE_TIME_RANKING_LOAD_SUCCESS =
  'INSIGHT_RESPONSE_TIME_RANKING_LOAD_SUCCESS';
export const INSIGHT_RESPONSE_TIME_RANKING_SORT_BUTTON_CLICK =
  'INSIGHT_RESPONSE_TIME_RANKING_SORT_BUTTON_CLICK';
export const INSIGHT_RESPONSE_TIME_UPDATE_SORT_DIRECTION =
  'INSIGHT_RESPONSE_TIME_RANKING_UPDATE_SORT_DIRECTION';
export const INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD = 'INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD';
export const INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD =
  'INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD';

// Outbound conversations
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN = 'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN';
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD =
  'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD';
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE =
  'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE';
export const INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS =
  'INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS';

// Top stores
export const INSIGHT_TOP_STORES_OPEN = 'INSIGHT_TOP_STORES_OPEN';
export const INSIGHT_TOP_STORES_SORT_BUTTON_CLICK = 'INSIGHT_TOP_STORES_SORT_BUTTON_CLICK';

export const INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';

export const INSIGHT_TOP_STORES_CSV_DOWNLOAD = 'INSIGHT_TOP_STORES_CSV_DOWNLOAD';
export const INSIGHT_TOP_STORES_CSV_LOAD = 'INSIGHT_TOP_STORES_CSV_LOAD';

export const INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD =
  'INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD';
export const INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD =
  'INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD';
export const INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_CONTACTS_LOAD = 'INSIGHT_TOP_STORES_CONTACTS_LOAD';
export const INSIGHT_TOP_STORES_CONTACTS_LOAD_FAILURE = 'INSIGHT_TOP_STORES_CONTACTS_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_CONTACTS_LOAD_SUCCESS = 'INSIGHT_TOP_STORES_CONTACTS_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_CONVERSATIONS_LOAD = 'INSIGHT_TOP_STORES_CONVERSATIONS_LOAD';
export const INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD =
  'INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD';
export const INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD =
  'INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD';
export const INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD =
  'INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD';
export const INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD =
  'INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD';
export const INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD =
  'INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD';
export const INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_FAILURE =
  'INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_FAILURE';
export const INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_SUCCESS =
  'INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_SUCCESS';

export const INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION = 'INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION';

// Web AHT
export const INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD =
  'INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD';
export const INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_FAILURE =
  'INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_FAILURE';
export const INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_SUCCESS =
  'INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_SUCCESS';

export const INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE =
  'INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE';
export const INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE';
export const INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE =
  'INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE';

export const INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN = 'INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN';
