import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {cleanHighlight} from '../../../../lib/list-item-helper';
import {filterByMultistoreConversationEnabled} from '../../../../lib/feature-toggle-helper';
import {getCurrentSearchTextAndCursor, getSearchSlice} from './lib/customer-inbox-helper';
import {graphqlSearchCustomersAndOrMessagesQuery} from './graphql-queries/graphql-customer-inbox-helper';
import {
  CUSTOMER_INBOX_SEARCH_ALL,
  CUSTOMER_INBOX_SEARCH_ALL_FAILURE,
  CUSTOMER_INBOX_SEARCH_ALL_SUCCESS,
  CUSTOMER_INBOX_SEARCH_FORM_RESET,
  CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE,
  CUSTOMER_INBOX_SEARCH_FORM_TEXT_CLEAR_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS,
  CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE
} from '../../../../actions/customer-inbox-actions';
import {CUSTOMER_CONVERSATION_URL_GO} from '../../../../actions/customer-thread-actions';
import {
  DEBOUNCE_TIME_MULTIPLE_CLICK,
  DEBOUNCE_TIME_TYPING_FILTER
} from '../../../../../../shared/data/settings';

const CustomerInboxSearchEpic =
  ({graphql}) =>
  (action$, state$) => {
    const goToCustomerConversationOnSearchResultCustomerClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK),
      filter(filterByMultistoreConversationEnabled(state$)),
      map(({payload: {indexInList}}) => {
        const nodeState = state$.value.getIn([
          'customerInbox',
          'search',
          'customers',
          'items',
          indexInList,
          'node'
        ]);
        const customer = nodeState.get('customer');

        return {
          type: CUSTOMER_CONVERSATION_URL_GO,
          payload: {
            conversation: {
              id: nodeState.getIn(['message', 'conversationId'])
            },
            customer: {
              channel: customer.get('channel'),
              company: cleanHighlight(customer.get('company')),
              displayName: customer.get('displayName'),
              emailAddress: customer.get('emailAddress'),
              id: customer.get('id'),
              pictureHref: customer.get('pictureHref'),
              phoneNumber: customer.get('phoneNumber')
            }
          }
        };
      })
    );

    const goToCustomerConversationOnSearchResultMessageClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE),
      map(({payload: {customer, message}}) => ({
        type: CUSTOMER_CONVERSATION_URL_GO,
        payload: {
          conversation: {
            id: message.conversationId
          },
          customer,
          queryStringParams: {vmc: message.cursor}
        }
      }))
    );

    // Shared with legacy
    const resetSearchFormOnTextClearButtonClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_FORM_TEXT_CLEAR_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: CUSTOMER_INBOX_SEARCH_FORM_RESET
      }))
    );

    const searchAll = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_ALL),
      mergeMap(() => {
        const text = state$.value.getIn(['customerInbox', 'search', 'text']);

        return graphql(
          graphqlSearchCustomersAndOrMessagesQuery({
            sliceCustomers: getSearchSlice('customers'),
            sliceMessages: getSearchSlice('messages'),
            text: text.trim()
          })
        ).pipe(
          map(({search: {customers, messages}}) => ({
            type: CUSTOMER_INBOX_SEARCH_ALL_SUCCESS,
            payload: {
              customers,
              messages
            }
          })),
          catchError((error) =>
            of({
              type: CUSTOMER_INBOX_SEARCH_ALL_FAILURE,
              error
            })
          )
        );
      })
    );

    const searchAllOnSearchFormTextChange = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE),
      filter(filterByMultistoreConversationEnabled(state$)),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      filter(({payload: {text}}) => text.trim() !== ''),
      map(() => ({
        type: CUSTOMER_INBOX_SEARCH_ALL
      }))
    );

    const searchMoreCustomers = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS),
      mergeMap(() => {
        const {cursor, text} = getCurrentSearchTextAndCursor(state$, 'customers');

        return graphql(
          graphqlSearchCustomersAndOrMessagesQuery({
            sliceCustomers: getSearchSlice('customers', cursor),
            text
          })
        ).pipe(
          map(({search: {customers}}) => ({
            type: CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS,
            payload: {
              customers
            }
          })),
          catchError((error) =>
            of({
              type: CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE,
              error
            })
          )
        );
      })
    );

    const searchMoreCustomersOnButtonClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK),
      filter(filterByMultistoreConversationEnabled(state$)),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS
      }))
    );

    const searchMoreMessages = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_MORE_MESSAGES),
      mergeMap(() => {
        const {cursor, text} = getCurrentSearchTextAndCursor(state$, 'messages');

        return graphql(
          graphqlSearchCustomersAndOrMessagesQuery({
            sliceMessages: getSearchSlice('messages', cursor),
            text
          })
        ).pipe(
          map(({search: {messages}}) => ({
            type: CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS,
            payload: {
              messages
            }
          })),
          catchError((error) =>
            of({
              type: CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE,
              error
            })
          )
        );
      })
    );

    const searchMoreMessagesOnButtonClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK),
      filter(filterByMultistoreConversationEnabled(state$)),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: CUSTOMER_INBOX_SEARCH_MORE_MESSAGES
      }))
    );

    const selectMessageOnInboxSearchResultMessageClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK),
      filter(filterByMultistoreConversationEnabled(state$)),
      map(({payload: {indexInList}}) => {
        const nodeState = state$.value.getIn([
          'customerInbox',
          'search',
          'messages',
          'items',
          indexInList,
          'node'
        ]);

        return {
          type: CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
          payload: {
            customer: nodeState.get('customer').toJS(),
            message: nodeState.get('message').toJS()
          }
        };
      })
    );

    return merge(
      goToCustomerConversationOnSearchResultCustomerClick,
      goToCustomerConversationOnSearchResultMessageClick,
      resetSearchFormOnTextClearButtonClick,
      searchAll,
      searchAllOnSearchFormTextChange,
      searchMoreCustomers,
      searchMoreCustomersOnButtonClick,
      searchMoreMessages,
      searchMoreMessagesOnButtonClick,
      selectMessageOnInboxSearchResultMessageClick
    );
  };

export default CustomerInboxSearchEpic;
