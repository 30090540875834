import PropTypes from 'prop-types';

const ContentLayoutComponent = ({children, style, ...otherProps}) => (
  <div
    style={{
      display: 'flex',
      flex: '1',
      minHeight: '1px',
      position: 'relative',
      ...style
    }}
    {...otherProps}
  >
    {children}
  </div>
);

ContentLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default ContentLayoutComponent;
