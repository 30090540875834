/**
 * A sort function for immutable js items in customer inbox.
 *
 * @param {String} sortBy
 * @returns {Immutable.List} sorted items
 */
const customerInboxThreadsSorter = (sortBy) => (items) => {
  const sortByMostRecent = sortBy === 'mostRecent';

  return items.sort((itemA, itemB) => {
    const itemAStatus = itemA.get('status');
    const itemBStatus = itemB.get('status');

    if (itemBStatus === itemAStatus) {
      if (sortByMostRecent || itemAStatus !== 'waiting') {
        return itemA.get('lastPublicMessageDate') >= itemB.get('lastPublicMessageDate') ? -1 : 1;
      }

      // sort by waitingTime items that are in status "waiting"
      return itemA.get('pendingSinceDate') <= itemB.get('pendingSinceDate') ? -1 : 1;
    }

    switch (itemAStatus) {
      case 'waiting':
        return -1;
      case 'replied':
        return itemBStatus === 'waiting' ? 1 : -1;
      case 'resolved':
        return 1;
      default:
        break;
    }

    return null;
  });
};

export default customerInboxThreadsSorter;
