import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import EmbedFormWidgetComponent from '../../../../../component/content/integration-area/page/widgets/embed-form-widget-component';
import {
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ADD_NEW_BUSINESS,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_BACKGROUND_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_COMMUNICATION_METHOD_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_DELETE_BUSINESS,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_EMPTY_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_HEADER_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_HELPER_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_MESSAGE_PLACEHOLDER_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_FOR_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_RESET_STATE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_BUTTON_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_NEW_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';
import {
  doChangeErrorInvalidEmail,
  doChangeErrorInvalidPhone,
  doChangeInputPlaceholderEmail,
  doChangeInputPlaceholderPhone,
  doChangeThankYouMessage
} from './lib/widget-page-map-dispatch-to-props';

const EmbedFormWidgetContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.getIn(['integrationArea', 'embedForm'])
    ],
    (businesses, embedForm) => {
      return {
        businessesList: businesses.toJS(),
        ...embedForm.toJS()
      };
    }
  ),
  {
    doChangeLanguage(language) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_LANGUAGE_CHANGE,
        language
      };
    },
    doChangeBusiness(business) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_BUSINESS_CHANGE,
        business
      };
    },
    doChangeCommunicationMethod(method) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_COMMUNICATION_METHOD_CHANGE,
        method
      };
    },
    doChangePlaceholderSelect(value) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_CHANGE,
        value
      };
    },
    doChangePlaceholderSelectText(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_TEXT_CHANGE,
        text
      };
    },
    doChangeBackgroundColor(color) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_BACKGROUND_COLOR_CHANGE,
        color
      };
    },
    doChangePlaceholderForBusinessChange(index, placeholder) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_FOR_BUSINESS_CHANGE,
        index,
        placeholder
      };
    },
    doUpdateSnippet(snippet) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_CHANGE,
        snippet
      };
    },
    doAddNewBusiness(addNew) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_ADD_NEW_BUSINESS,
        addNew
      };
    },
    doDeleteBusiness(businessId) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_DELETE_BUSINESS,
        businessId
      };
    },
    doChangeErrorEmptyMessage(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_EMPTY_MESSAGE_CHANGE,
        text
      };
    },
    doChangeHeaderText(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_HEADER_TEXT_CHANGE,
        text
      };
    },
    doChangeMessagePlaceholder(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_MESSAGE_PLACEHOLDER_CHANGE,
        text
      };
    },
    doChangeHelperText(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_HELPER_TEXT_CHANGE,
        text
      };
    },
    doChangeSendButtonText(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_BUTTON_TEXT_CHANGE,
        text
      };
    },
    doChangeSendNewMessage(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_NEW_MESSAGE_CHANGE,
        text
      };
    },
    doCopyCode(copied) {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE,
        _googleAnalytics: true,
        copied
      };
    },
    doResetState() {
      return {
        type: INTEGRATION_AREA_WIDGET_EMBED_FORM_RESET_STATE
      };
    },
    doChangeErrorInvalidEmail,
    doChangeErrorInvalidPhone,
    doChangeInputPlaceholderEmail,
    doChangeInputPlaceholderPhone,
    doChangeThankYouMessage
  }
)(EmbedFormWidgetComponent);

export default EmbedFormWidgetContainer;
