import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {filterByMultistoreConversationEnabled} from '../../../../lib/feature-toggle-helper';
import {getCurrentConversationFromState} from '../thread/lib/customer-thread-conversations-helper';
import {graphqlSearchCustomersForNewConversationQuery} from './graphql-queries/graphql-customer-inbox-helper';
import {
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_FAILURE,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_INBOX_NEW_CONVERSATION_NEW_CUSTOMER_FORM_CUSTOMER_CLICK,
  CUSTOMER_INBOX_NEW_THREAD_START_SHOW
} from '../../../../actions/customer-inbox-actions';
import {
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START
} from '../../../../actions/customer-new-thread-actions';
import {
  CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK,
  LEGACY_CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK
} from '../../../../actions/customer-thread-actions';
import {DEBOUNCE_TIME_TYPING_FILTER} from '../../../../../../shared/data/settings';

const CustomerInboxNewConversationEpic =
  ({graphql}) =>
  (action$, state$) => {
    const searchCustomers = action$.pipe(
      ofType(CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH),
      mergeMap(({payload: {country, text}}) =>
        graphql(graphqlSearchCustomersForNewConversationQuery(text, country)).pipe(
          map(
            ({search: {customers, formattedPhoneNumber, isValidEmail, isValidSmsPhoneNumber}}) => ({
              type: CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_SUCCESS,
              payload: {
                customers,
                formattedPhoneNumber,
                isValidEmail,
                isValidSmsPhoneNumber
              }
            })
          ),
          catchError((error) =>
            of({
              type: CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH_FAILURE,
              error
            })
          )
        )
      )
    );

    const searchCustomersOnNewConversationFormChanged = action$.pipe(
      ofType(CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED),
      filter(filterByMultistoreConversationEnabled(state$)),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      filter(({payload: {text}}) => text !== ''),
      map(({payload}) => ({
        type: CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_SEARCH,
        payload
      }))
    );

    const triggerCustomerNewOutboundConversationsLoad = action$.pipe(
      ofType(CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK),
      map(({payload: {business, customer}}) => {
        return {
          type: CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD,
          payload: {
            business,
            customer
          }
        };
      })
    );

    const triggerCustomerNewConversationNewCustomerStart = action$.pipe(
      ofType(CUSTOMER_INBOX_NEW_CONVERSATION_NEW_CUSTOMER_FORM_CUSTOMER_CLICK),
      map(({payload}) => {
        return {
          type: CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START,
          payload
        };
      })
    );

    const triggerNewConversationFormOpenOnSomeActions = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK),
      map(() => {
        const businessId = getCurrentConversationFromState(state$).getIn(['business', 'id']);

        return {
          type: CUSTOMER_INBOX_NEW_THREAD_START_SHOW,
          payload: {
            business: {
              id: businessId
            }
          }
        };
      })
    );

    const triggerNewConversationFormOpenOnSomeActionsLegacy = action$.pipe(
      ofType(LEGACY_CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK),
      map(() => {
        const businessId = state$.value.getIn([
          'legacyCustomerThread',
          'directedToBusinessIdentifier'
        ]);

        return {
          type: CUSTOMER_INBOX_NEW_THREAD_START_SHOW,
          payload: {
            business: {
              id: businessId
            }
          }
        };
      })
    );

    return merge(
      searchCustomers,
      searchCustomersOnNewConversationFormChanged,
      triggerCustomerNewOutboundConversationsLoad,
      triggerCustomerNewConversationNewCustomerStart,
      triggerNewConversationFormOpenOnSomeActions,
      triggerNewConversationFormOpenOnSomeActionsLegacy
    );
  };

export default CustomerInboxNewConversationEpic;
