import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import handleErrors from '../../../shared/epic/lib/handle-errors';
import {
  CHANGE_PASSWORD_SUBMIT,
  CHANGE_PASSWORD_SUBMIT_FAILURE,
  CHANGE_PASSWORD_SUBMIT_SUCCESS
} from '../actions/change-password-actions';

const ERROR_MAPPING = {
  40302: 'errorInvalidForgotPasswordKeyCode'
};

/**
 * The epic itself.
 */
const ChangePasswordEpic =
  ({graphql}) =>
  (action$) => {
    return action$.pipe(
      ofType(CHANGE_PASSWORD_SUBMIT),
      mergeMap(({isFormValid, password, token}) => {
        if (!isFormValid) {
          return of({
            type: CHANGE_PASSWORD_SUBMIT_FAILURE,
            errors: []
          });
        }

        return graphql('update-user-password-mutation', {
          passwordToken: token,
          password
        }).pipe(
          map(() => {
            return {
              type: CHANGE_PASSWORD_SUBMIT_SUCCESS
            };
          }),
          catchError((errors) => {
            return of({
              type: CHANGE_PASSWORD_SUBMIT_FAILURE,
              errors: handleErrors(errors, ERROR_MAPPING)
            });
          })
        );
      })
    );
  };

export default ChangePasswordEpic;
