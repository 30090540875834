export const DEFAULT_STATE_WIDGET_COMMON = {
  language: 'en',
  snippet: '',
  snippetCopied: false
};

export const errorInvalidEmailChange = (state, {text}) => state.set('errorInvalidEmail', text);
export const errorInvalidPhoneChange = (state, {text}) => state.set('errorInvalidPhone', text);

export const inputPlaceHolderEmailChange = (state, {text}) =>
  state.set('inputPlaceholderEmail', text);

export const inputPlaceHolderPhoneChange = (state, {text}) =>
  state.set('inputPlaceholderPhone', text);

export const languageChange = (state, {language}) => {
  return state.set('language', language);
};

export const snippetChange = (state, {snippet}) => {
  return state.set('snippet', snippet);
};

export const snippetCopiedChange = (state, {copied}) => {
  return state.set('snippetCopied', copied);
};

export const thankYouMessageChange = (state, {text}) => state.set('thankYouMessage', text);
