export const animationSpeed = {
  medium: 500,
  fast: 300
};

export const avatarSize = {
  xxxsmall: 15,
  xxsmall: 18,
  xsmall: 25,
  small: 30,
  medium: 35,
  large: 48,
  xlarge: 60,
  xxlarge: 80,
  xxxlarge: 100
};

export const badge = {
  small: '12px',
  medium: '18px'
};

export const boxShadow = {
  normal: '0px 2px 5px rgba(0,0,0,.15)'
};

export const fontSize = {
  xxsmall: '10px',
  xsmall: '11px',
  small: '12px',
  medium: '13px',
  large: '14px',
  xlarge: '16px',
  xxlarge: '18px',
  xxxlarge: '20px',
  xxxxlarge: '26px',
  xxxxxlarge: '36px',
  xxxxxxlarge: '64px'
};

export const fontWeight = {
  light: 300,
  normal: 400,
  semiBold: 600,
  bold: 700
};

export const heightSize = {
  small: '30px',
  medium: '35px',
  normal: '50px',
  large: '80px',
  xlarge: '94px'
};

export const iconSize = {
  small: '16px',
  medium: '20px',
  large: '25px',
  xlarge: '35px',
  xxlarge: '40px',
  xxxlarge: '48px'
};

export const inputMaxLength = {
  companyName: 255,
  firstName: 50,
  lastName: 50
};

export const lineHeight = {
  medium: 1.2,
  large: 1.4,
  xlarge: 1.6,
  xxlarge: 2.4
};

export const loaderSize = {
  small: 24,
  medium: 36
};

export const popupHeight = {
  xsmall: '350px',
  small: '380px'
};

export const popupWidth = {
  small: '280px',
  medium: '336px'
};

export const radius = {
  medium: '3px',
  large: '5px',
  xlarge: '10px',
  xxlarge: '18px',
  xxxlarge: '20px'
};

export const screenWidth = {
  mobileMin: 320,
  mobileScreenMax: 414,
  smallScreenMax: 1020
};

export const spacing = {
  xsmall: '2px',
  small: '6px',
  medium: '10px',
  large: '15px',
  mlarge: '20px',
  xlarge: '30px',
  xxlarge: '40px',
  xxxlarge: '56px'
};

export const transitionDuration = {
  menu: {
    enter: 200,
    exit: 100
  }
};
