import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import UnsubscribeComponent from '../component/unsubscribe-component';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {
  UNSUBSCRIBE_PARAMETERS_VALIDATE,
  UNSUBSCRIBE_RESUBSCRIBE
} from '../actions/unsubscribe-actions';

const UnsubscribeContainer = connect(
  createSelector(
    [(state) => state.get('ui'), (state) => state.get('unsubscribe')],
    (ui, unsubscribe) => {
      return {
        language: ui.get('language'),
        ...unsubscribe.toJS()
      };
    }
  ),
  {
    ...uiDispatchToProps,
    doResubscribe(token, type) {
      return {
        type: UNSUBSCRIBE_RESUBSCRIBE,
        _googleAnalytics: true,
        subscriptionType: type,
        token
      };
    },
    doUnsubscribe(email, token, type) {
      return {
        type: UNSUBSCRIBE_PARAMETERS_VALIDATE,
        _googleAnalytics: true,
        subscriptionType: type,
        email,
        token
      };
    }
  }
)(UnsubscribeComponent);

export default UnsubscribeContainer;
