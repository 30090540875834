import {fromEvent, merge, of} from 'rxjs';
import {catchError, delay, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {replace} from 'react-router-redux';
import onRouteChange from '../../lib/on-route-change';
import {parseSearch} from '../../../../../shared/lib/route-helper';
import sendMessage from '../../lib/send-message';
import triggerTypingThreadDebouncedAction from '../../lib/trigger-typing-thread-debounced-action';
import uploadFile from '../../lib/upload-file';
import {
  COLLEAGUE_NEW_THREAD_LOAD,
  COLLEAGUE_NEW_THREAD_LOAD_SUCCESS,
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE,
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE_FAILURE,
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS,
  COLLEAGUE_THREAD_FILE_UPLOAD,
  COLLEAGUE_THREAD_FILE_UPLOAD_FAILURE,
  COLLEAGUE_THREAD_FILE_UPLOAD_SUCCESS,
  COLLEAGUE_THREAD_LOAD,
  COLLEAGUE_THREAD_LOAD_FAILURE,
  COLLEAGUE_THREAD_LOAD_SUCCESS,
  COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_PERSIST,
  COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_UPDATE,
  COLLEAGUE_THREAD_SEND_MESSAGE,
  COLLEAGUE_THREAD_SEND_MESSAGE_FAILURE,
  COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS
} from '../../../actions/colleague-thread-actions';
import {EXT_COLLEAGUE_SENT_MESSAGE_TO_ME} from '../../../actions/ext-actions';
import {THREAD_ITEMS_PER_PAGE} from '../../../data/settings';

const ColleagueThreadEpic =
  ({query, command}, socketio) =>
  (action$, state$) => {
    const colleagueSentMessageToMe = fromEvent(socketio, 'employee_sent_message_to_colleague').pipe(
      map((message) => {
        const participationId = message.receiverParticipationId;

        const acknowledged =
          state$.value.getIn(['colleagueThread', 'participationId']) == participationId; // eslint-disable-line eqeqeq

        if (acknowledged) {
          socketio.emit('mark-colleague-thread-as-read', {
            participationId
          });
        }

        return {
          type: EXT_COLLEAGUE_SENT_MESSAGE_TO_ME,
          data: {
            acknowledged,
            ...message
          }
        };
      })
    );

    const loadThread = action$.pipe(
      ofType(COLLEAGUE_THREAD_LOAD),
      delay(1), // without this, new thread loading state won't be taken into account
      mergeMap(({direction, fromId, participationId}) => {
        const params = {
          limit: THREAD_ITEMS_PER_PAGE,
          direction,
          fromId,
          participationId
        };

        return query('get-conversation', params).pipe(
          map(({data}) => {
            return {
              type: COLLEAGUE_THREAD_LOAD_SUCCESS,
              data,
              params
            };
          }),
          catchError((error) => {
            return of({
              type: COLLEAGUE_THREAD_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadNewThread = action$.pipe(
      ofType(COLLEAGUE_NEW_THREAD_LOAD),
      delay(1), // without this, new thread loading state won't be taken into account
      map(() => {
        return {
          type: COLLEAGUE_NEW_THREAD_LOAD_SUCCESS
        };
      })
    );

    const loadThreadOnRouteChange = onRouteChange({
      action: action$,
      regex: new RegExp('^/app/colleagues/\\d+'),
      mapFn: ({pathname}) => ({
        type: COLLEAGUE_THREAD_LOAD,
        participationId: pathname.split('/').pop()
      })
    });

    const loadNewThreadOnRouteChange = onRouteChange({
      action: action$,
      regex: new RegExp('^/app/colleagues/new'),
      mapFn: ({search}) => ({
        type: COLLEAGUE_NEW_THREAD_LOAD,
        ...parseSearch(search)
      })
    });

    const persistMessageFormTextOnTextChanged = triggerTypingThreadDebouncedAction(
      action$,
      COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_UPDATE,
      COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_PERSIST
    );

    const sendMessageInThread = action$.pipe(
      ofType(COLLEAGUE_THREAD_SEND_MESSAGE),
      mergeMap((sendAction) => {
        return sendMessage({
          command,
          sendAction,
          participationId: state$.value.getIn(['colleagueThread', 'participationId']),
          nextActions: {
            success: COLLEAGUE_THREAD_SEND_MESSAGE_SUCCESS,
            failure: COLLEAGUE_THREAD_SEND_MESSAGE_FAILURE
          }
        });
      })
    );

    const sendMessageInNewThread = action$.pipe(
      ofType(COLLEAGUE_NEW_THREAD_SEND_MESSAGE),
      mergeMap(({attachment, clientMessageId, colleagueId, text}) => {
        return command('start-colleague-thread', {
          attachment,
          clientMessageId,
          colleagueId,
          text
        }).pipe(
          mergeMap(({data}) => {
            return [
              replace(`/app/colleagues/${data.participationId}`),
              {
                type: COLLEAGUE_NEW_THREAD_SEND_MESSAGE_SUCCESS,
                id: data.id,
                participationId: data.participationId,
                clientMessageId
              }
            ];
          }),
          catchError((error) => {
            return of({
              type: COLLEAGUE_NEW_THREAD_SEND_MESSAGE_FAILURE,
              clientMessageId,
              error
            });
          })
        );
      })
    );

    const uploadFileInThread = action$.pipe(
      ofType(COLLEAGUE_THREAD_FILE_UPLOAD),
      mergeMap(({file}) =>
        uploadFile({
          command,
          file,
          actions: {
            success: COLLEAGUE_THREAD_FILE_UPLOAD_SUCCESS,
            failure: COLLEAGUE_THREAD_FILE_UPLOAD_FAILURE
          }
        })
      )
    );

    return merge(
      colleagueSentMessageToMe,
      loadNewThread,
      loadNewThreadOnRouteChange,
      loadThread,
      loadThreadOnRouteChange,
      persistMessageFormTextOnTextChanged,
      sendMessageInNewThread,
      sendMessageInThread,
      uploadFileInThread
    );
  };

export default ColleagueThreadEpic;
