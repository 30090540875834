import PropTypes from 'prop-types';
import {PureComponent} from 'react';

class RightPanelColumnLayoutComponent extends PureComponent {
  render() {
    const {children, dataTestId} = this.props;

    return (
      <div
        data-test-id={dataTestId}
        style={{
          display: 'flex',
          width: '100%',
          position: 'relative'
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            minWidth: 0
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              minWidth: 0
            }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

RightPanelColumnLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired, // Will be array when colleague thread will have more than one child
  dataTestId: PropTypes.string.isRequired
};

export default RightPanelColumnLayoutComponent;
