const selectParticipation = (state, participationId) =>
  state
    .set('selected', participationId)
    .set('showStartNewThread', false)
    .setIn(['newThread', 'text'], '');

const legacyUpdateSelectedParticipation = (state, {participationId}) =>
  selectParticipation(state, participationId);

const updateSelectedParticipation = (state, {payload: {participationId}}) =>
  selectParticipation(state, participationId);

export {legacyUpdateSelectedParticipation, updateSelectedParticipation};
