import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Link} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

/**
 * Generate a styled Link with an InfoOutline icon.
 *
 * @param {Object} style
 * @returns {function({helpPageUrl: *}): XML}
 */
export default (style = {}) => {
  const StyledLink = withStyles({
    root: style.top
      ? {
          position: 'absolute',
          zIndex: 1,
          ...style
        }
      : style
  })(Link);

  // eslint-disable-next-line react/prop-types
  return ({url}) => (
    <StyledLink data-test-id="help-link" href={url} target="_blank">
      <InfoOutlinedIcon color="action" />
    </StyledLink>
  );
};
