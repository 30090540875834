import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../shared/component/context';
import {formatRelativeDate} from '../../lib/date-time-helper';

class FormattedRelativeDateComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {i18nPrefix, value} = this.props;

    return formatRelativeDate(value, i18n, i18nPrefix);
  }
}

FormattedRelativeDateComponent.contextTypes = context;

FormattedRelativeDateComponent.propTypes = {
  i18nPrefix: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]) // not required as it would trigger an error when the employee sends a new day first message
};

export default FormattedRelativeDateComponent;
