import PropTypes from 'prop-types';
import {LoadScript} from '@react-google-maps/api';
import AdministrationBusinessPageChannelsGbmMap from './administration-business-page-channels-gbm-map-component';
import {GBM_MAPS_LIBRARIES, GBM_MAPS_VERSION} from './lib/gbm-map-helper';

const AdministrationBusinessPageChannelsGbmMapWrapperComponent = ({
  business,
  i18n,
  onHandlePlaceUpdate,
  onUserSearchOnMap
}) => {
  return (
    <LoadScript
      googleMapsApiKey={GBM_MAPS_API_KEY}
      libraries={GBM_MAPS_LIBRARIES}
      version={GBM_MAPS_VERSION}
    >
      <AdministrationBusinessPageChannelsGbmMap
        {...{
          business,
          i18n,
          onHandlePlaceUpdate,
          onUserSearchOnMap
        }}
      />
    </LoadScript>
  );
};

AdministrationBusinessPageChannelsGbmMapWrapperComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandlePlaceUpdate: PropTypes.func.isRequired,
  onUserSearchOnMap: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsGbmMapWrapperComponent;
