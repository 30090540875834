import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import invert from 'lodash/invert';
import context from '../../../../../../../shared/component/context';
import themeV1 from '../../../../../../../shared/style/theme-v1';
import getStyledHelpLink from '../get-styled-help-link';
import HeaderContentLayout from '../../../../common/content-header/header-content-layout-component';
import HeaderContentTitle from '../../../../common/content-header/header-content-title-component';
import MultipleBusinessesSelect from '../multiple-businesses-select-component';
import SubHeaderClassicReport from './header/sub-header-classic-report-component';
import SubHeaderCustomReport from './header/sub-header-custom-report-component';
import {spacing} from '../../../../../../../shared/style/variables';

const PERIOD_TABS_MAPPING = {
  classic: 0,
  custom: 1
};

const StyledHelpLink = getStyledHelpLink({
  marginLeft: spacing.medium
});

/**
 * Finally, the component.
 */
class InsightContentHeaderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doPeriodTabChange = this._doPeriodTabChange.bind(this);
  }

  _renderSubHeaderCustomReport = () => {
    const {
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    return doPeriodTabCustomChangeDatesAndPeriod ? (
      <SubHeaderCustomReport
        {...{
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate,
          doPeriodTabCustomChangeDatesAndPeriod
        }}
      />
    ) : null;
  };

  _doPeriodTabChange(event, newTab) {
    const {periodTabSelected, doPeriodTabChange} = this.props;
    // This test should be done by material UI...
    if (PERIOD_TABS_MAPPING[periodTabSelected] !== newTab) {
      doPeriodTabChange(invert(PERIOD_TABS_MAPPING)[newTab]);
    }
  }

  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      domain,
      downloadCsvLabelTranslation,
      insightBusinessFilter,
      insightDownloadCsv,
      insightSortRankings,
      loadingCsv,
      periodTabSelected,
      showDownloadCsvButton,
      showCsvButton,
      showSortButton,
      doInsightBusinessFilterBusinessesSearch,
      doInsightBusinessesSelectionChangeFromPage,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod
    } = this.props;

    const translations = i18n.t(`insight.pages.${domain}`, {returnObjects: true});
    const {helpPageUrl} = translations;

    return (
      <div>
        <HeaderContentLayout>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <HeaderContentTitle
              dataTestId="statistics-type-name"
              label={translations.title}
              style={{
                marginRight: spacing.xlarge
              }}
            />
            {doPeriodTabChange ? (
              <MuiThemeProvider theme={themeV1}>
                <Tabs
                  data-test-id="period-tabs"
                  value={PERIOD_TABS_MAPPING[periodTabSelected]}
                  onChange={this._doPeriodTabChange}
                >
                  <Tab
                    label={i18n.t('insight.contentHeader.periodSelector.classicReport.tabLabel')}
                  />
                  <Tab
                    label={i18n.t('insight.contentHeader.periodSelector.customPeriod.tabLabel')}
                  />
                </Tabs>
              </MuiThemeProvider>
            ) : null}
          </div>

          {doInsightBusinessesSelectionChangeFromPage || helpPageUrl ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: '10px'
              }}
            >
              {doInsightBusinessesSelectionChangeFromPage ? (
                <MultipleBusinessesSelect
                  onSelectionChange={doInsightBusinessesSelectionChangeFromPage}
                  {...{
                    accountBusinesses,
                    businessesSelection,
                    doInsightBusinessFilterBusinessesSearch,
                    ...insightBusinessFilter
                  }}
                />
              ) : null}
              {helpPageUrl ? <StyledHelpLink url={helpPageUrl} /> : null}
            </div>
          ) : null}
        </HeaderContentLayout>

        {periodTabSelected === 'classic' && doPeriodTabClassicChangeDateAndPeriod ? (
          <SubHeaderClassicReport
            {...{
              classicAggregationDate,
              classicAggregationPeriod,
              downloadCsvLabelTranslation,
              insightDownloadCsv,
              insightSortRankings,
              loadingCsv,
              showDownloadCsvButton,
              showCsvButton,
              showSortButton,
              doPeriodTabClassicChangeDateAndPeriod
            }}
          />
        ) : (
          this._renderSubHeaderCustomReport()
        )}
      </div>
    );
  }
}

InsightContentHeaderComponent.contextTypes = context;

InsightContentHeaderComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any),
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  domain: PropTypes.string.isRequired,
  downloadCsvLabelTranslation: PropTypes.string,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any),
  insightDownloadCsv: PropTypes.func,
  insightSortRankings: PropTypes.func,
  loadingCsv: PropTypes.bool,
  periodTabSelected: PropTypes.string,
  showCsvButton: PropTypes.bool,
  showDownloadCsvButton: PropTypes.bool,
  showSortButton: PropTypes.bool,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func,
  doPeriodTabChange: PropTypes.func,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func
};

export default InsightContentHeaderComponent;
