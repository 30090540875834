import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import getStandardParamsFromState from './lib/get-standard-params-from-state';
import prepareRequestParams from './lib/prepare-request-params';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';
import {
  INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD,
  INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_FAILURE,
  INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_SUCCESS,
  INSIGHT_DEVICES_OPEN,
  INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../actions/insight-actions';

const InsightDevicesEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(
        INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_DEVICES_OPEN,
        INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ),
      map(() => {
        return {
          type: INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD,
          ...getStandardParamsFromState(state$)
        };
      })
    );

    const loadEmployeeMessageChannelHistogram = action$.pipe(
      ofType(INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate
        }) => {
          return graphql(
            'get-employee-message-channel-histogram-query',
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            })
          ).pipe(
            map(({employeeMessageChannelHistogram}) => {
              return {
                type: INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_SUCCESS,
                employeeMessageChannelHistogram
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    return merge(loadEmployeeMessageChannelHistogram, loadInsights);
  };

export default InsightDevicesEpic;
