import React from 'react';
import PropTypes from 'prop-types';
import EmojiRender from '../../emoji/emoji-render';
import Linkify from '../../link/linkify-component';
import {black, lightYellow} from '../../../../../../shared/style/colors';

const ContentTextComponent = ({selected, text, textColor}) => {
  const textLines = text.split('\n');
  const lastLineIndex = textLines.length - 1;

  return (
    <Linkify
      dataTestId={selected ? 'highlight' : null}
      className={selected ? 'selected-message' : null}
      {...{
        textColor
      }}
      style={
        selected
          ? {
              color: black,
              backgroundColor: lightYellow
            }
          : null
      }
    >
      {textLines.map((lineOfText, lineIndex) => (
        <React.Fragment key={String(lineIndex)}>
          <EmojiRender>{lineOfText}</EmojiRender>
          {lineIndex < lastLineIndex ? <br /> : null}
        </React.Fragment>
      ))}
    </Linkify>
  );
};

ContentTextComponent.propTypes = {
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired
};

export default ContentTextComponent;
