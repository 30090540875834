import {darkSmoke} from '../../../../../shared/style/colors';

export default {
  canShowTutorial: ({getTutorialIsShownFromLocalStorage}) => getTutorialIsShownFromLocalStorage(),
  className: 'app-header-statistics-real-time',
  getConfig: (getCommonStepOptions, persistTutorialAsShownInLocalStorage) => ({
    options: {
      spotlightStyles: {
        backgroundColor: darkSmoke
      }
    },
    persistPermanentlyAsShown: persistTutorialAsShownInLocalStorage,
    steps: [
      {
        ...getCommonStepOptions('app.tutorial.app.header.statisticsRealTime'),
        spotlightPadding: 0
      }
    ]
  }),
  getLocalStorageKey: () => 'instaplyDesktop.tutorial.app.header.statistics.realTime',
  key: 'appHeaderStatisticsRealTime'
};
