import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FormattedRelativeDate from './formatted-relative-date-component';
import InboxLikeTimeLayout from './inbox/inbox-like-time-layout-component';

class ElapsedComponent extends PureComponent {
  render() {
    const {i18nPrefix, value} = this.props;

    return (
      <InboxLikeTimeLayout data-test-id="elapsed-time">
        <FormattedRelativeDate {...{i18nPrefix, value}} />
      </InboxLikeTimeLayout>
    );
  }
}

ElapsedComponent.propTypes = {
  i18nPrefix: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default ElapsedComponent;
