import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {fontSize, fontWeight, spacing} from '../../../../../shared/style/variables';
import {oneline} from '../../../../../shared/style/ellipsis';
import {black} from '../../../../../shared/style/colors';

class HeaderContentTitleComponent extends PureComponent {
  render() {
    const {dataTestId, label, style} = this.props;

    return (
      <h1
        data-test-id={dataTestId}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: fontWeight.semiBold,
          marginLeft: spacing.medium,
          fontSize: fontSize.xlarge,
          lineHeight: '22px',
          color: black,
          ...oneline,
          ...style
        }}
      >
        {label}
      </h1>
    );
  }
}

HeaderContentTitleComponent.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.node, // Can't be required because it's undefined on thread reloading
  style: PropTypes.objectOf(PropTypes.any)
};

export default HeaderContentTitleComponent;
