import React from 'react';
import PropTypes from 'prop-types';
import LegacyAppPaymentMaximized from './payment/legacy-payment-maximized-component';
import LegacyAppPaymentMinimized from './payment/legacy-payment-minimized-component';
import VisibleThreadAppsAreaLayout from '../../apps/visible-thread-apps-area-layout-component';
import VisibleThreadAppsMenu from '../../apps/visible-thread-apps-menu-component';

const LegacyVisibleThreadAppsAreaComponent = ({
  legacyCustomerThreadAppPayment,
  doAppPaymentCancelButtonClick,
  doAppPaymentMaximizeButtonClick,
  doAppPaymentMinimizeButtonClick,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick,
  doAppsMenuSelectPayment,
  i18n,
  menuVisible,
  onAppPaymentAmountChange,
  onAppPaymentCustomChange,
  onAppPaymentCustomerChange,
  onSelectMenuItem,
  participationId
}) => {
  const renderMenu = () => (
    <VisibleThreadAppsAreaLayout showBorder={false}>
      <VisibleThreadAppsMenu
        onSelectMenuItemPayment={onSelectMenuItem(doAppsMenuSelectPayment)}
        {...{
          i18n
        }}
      />
    </VisibleThreadAppsAreaLayout>
  );
  const renderAppPayment = () =>
    legacyCustomerThreadAppPayment.minimized ? (
      <LegacyAppPaymentMinimized
        amount={legacyCustomerThreadAppPayment.amount}
        {...{
          doAppPaymentMaximizeButtonClick,
          i18n,
          participationId
        }}
      />
    ) : (
      <LegacyAppPaymentMaximized
        {...{
          legacyCustomerThreadAppPayment,
          doAppPaymentCancelButtonClick,
          doAppPaymentMinimizeButtonClick,
          doAppPaymentStepperBackButtonClick,
          doAppPaymentStepperNextButtonClick,
          i18n,
          onAppPaymentAmountChange,
          onAppPaymentCustomChange,
          onAppPaymentCustomerChange,
          participationId
        }}
      />
    );

  return (
    <React.Fragment>
      {menuVisible ? renderMenu() : null}

      {legacyCustomerThreadAppPayment ? (
        <VisibleThreadAppsAreaLayout>{renderAppPayment()}</VisibleThreadAppsAreaLayout>
      ) : null}
    </React.Fragment>
  );
};

LegacyVisibleThreadAppsAreaComponent.propTypes = {
  legacyCustomerThreadAppPayment: PropTypes.objectOf(PropTypes.any),
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  menuVisible: PropTypes.bool.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onSelectMenuItem: PropTypes.func.isRequired,
  participationId: PropTypes.string.isRequired
};

export default LegacyVisibleThreadAppsAreaComponent;
