import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_SUCCESS} from '../../../../actions/customer-thread-actions';
import {CUSTOMER_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-thread-visible-actions';

export const DEFAULT_STATE = {
  loading: false,
  businessSurveyConfiguration: {
    nps: {
      enabled: false
    },
    preferredChannel: {
      enabled: false
    }
  }
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Reset some data while loading thread
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_VISIBLE_LOAD]: (state) => {
    return state
      .set('businessSurveyConfiguration', fromJS(DEFAULT_STATE.businessSurveyConfiguration))
      .set('loading', true);
  },

  /**
   * The thread business survey configuration was successfully loaded
   *
   * @param {Object} state
   * @param {Object} businessSurveyConfiguration
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_BUSINESS_SURVEY_CONFIGURATION_LOAD_SUCCESS]: (
    state,
    {businessSurveyConfiguration}
  ) => {
    return state
      .set('businessSurveyConfiguration', fromJS(businessSurveyConfiguration))
      .set('loading', false);
  }
});
