import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ActionSearch from 'material-ui/svg-icons/action/search';
import ExpandMoreIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';
import IconButton from 'material-ui/IconButton';
import context from '../../../../../../shared/component/context';
import BusinessFilterPopup from './business-filter-popup-component';
import {InboxHeaderLayout, InboxHeaderTitle} from '../../../common/inbox/inbox-header-components';
import NewOutboundMessageIcon from '../../../common/inbox/new-outbound-message-icon';
import {spacing} from '../../../../../../shared/style/variables';
import {lightSlate, lightSmoke} from '../../../../../../shared/style/colors';

class ThreadListHeaderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      businessFilterAnchorEl: null
    };

    this._onClickBusinessFilterTitle = this._onClickBusinessFilterTitle.bind(this);
  }

  _onClickBusinessFilterTitle(event) {
    event.preventDefault();
    const {
      isVisibleBusinessFilterPopup,
      doCustomerInboxBusinessFilterClose,
      doCustomerInboxBusinessFilterOpen
    } = this.props;

    if (isVisibleBusinessFilterPopup) {
      doCustomerInboxBusinessFilterClose();

      return;
    }

    this.setState(
      {
        businessFilterAnchorEl: event.currentTarget
      },
      () => doCustomerInboxBusinessFilterOpen()
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      businesses,
      canFilterCustomerThreadsByBusiness,
      filterBusinessName,
      filterBusinessId,
      filterBusinessScope,
      filterItems,
      filterLoading,
      filterText,
      isAllowedToSeeCustomerThreadsOfAllBusinesses,
      isVisibleBusinessFilterPopup,
      doCustomerInboxBusinessFilterClose,
      doCustomerInboxBusinessFilterSearch,
      doInboxSearchFormToggle,
      doLoadInboxThreadsListFilteredByBusiness,
      doShowStartNewThread
    } = this.props;
    const {businessFilterAnchorEl} = this.state;

    const myBusinessLabel =
      businesses.length !== 1
        ? i18n.t('customerInbox.header.businessFilter.myBusinesses', {
            postProcess: 'interval',
            count: businesses.length
          })
        : businesses[0].name;
    const scopedTitle = {
      mine: myBusinessLabel,
      all: i18n.t('customerInbox.header.businessFilter.allBusinesses')
    }[filterBusinessScope];
    const filterTitle = scopedTitle || filterBusinessName;

    return (
      <InboxHeaderLayout>
        <InboxHeaderTitle style={{maxWidth: 'calc(100% - 102px)', paddingRight: spacing.xsmall}}>
          {canFilterCustomerThreadsByBusiness ? (
            <div>
              <div
                data-test-id="inbox-filter-threads-by-business-popup-open-button"
                onClick={this._onClickBusinessFilterTitle}
                style={{cursor: 'pointer'}}
              >
                <ExpandMoreIcon color={lightSmoke} />
                <span
                  title={filterTitle}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 24px)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {filterTitle}
                </span>
              </div>

              <BusinessFilterPopup
                anchorEl={businessFilterAnchorEl}
                filterBusinessId={filterBusinessId}
                filterBusinessScope={filterBusinessScope}
                filterItems={filterItems}
                filterLoading={filterLoading}
                filterText={filterText}
                isAllowedToSeeCustomerThreadsOfAllBusinesses={
                  isAllowedToSeeCustomerThreadsOfAllBusinesses
                }
                isVisibleBusinessFilterPopup={isVisibleBusinessFilterPopup}
                myBusinessLabel={myBusinessLabel}
                doCustomerInboxBusinessFilterClose={doCustomerInboxBusinessFilterClose}
                doCustomerInboxBusinessFilterSearch={doCustomerInboxBusinessFilterSearch}
                doLoadInboxThreadsListFilteredByBusiness={doLoadInboxThreadsListFilteredByBusiness}
              />
            </div>
          ) : (
            <span data-test-id="inbox-filter-threads-by-business-single">{filterTitle}</span>
          )}
        </InboxHeaderTitle>

        <IconButton
          data-test-id="search-inbox-open-button"
          className="material-icons"
          onClick={() => doInboxSearchFormToggle(true)}
          style={{marginLeft: '10px'}}
        >
          <ActionSearch color={lightSlate} />
        </IconButton>

        {businesses.length > 0 ? (
          <NewOutboundMessageIcon
            onClick={doShowStartNewThread}
            style={{padding: '11px 3px 9px 3px'}}
          />
        ) : null}
      </InboxHeaderLayout>
    );
  }
}

ThreadListHeaderComponent.contextTypes = context;

ThreadListHeaderComponent.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  canFilterCustomerThreadsByBusiness: PropTypes.bool.isRequired,
  filterBusinessId: PropTypes.number,
  filterBusinessName: PropTypes.string,
  filterBusinessScope: PropTypes.string,
  filterItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterLoading: PropTypes.bool.isRequired,
  filterText: PropTypes.string.isRequired,
  isAllowedToSeeCustomerThreadsOfAllBusinesses: PropTypes.bool.isRequired,
  isVisibleBusinessFilterPopup: PropTypes.bool.isRequired,
  doCustomerInboxBusinessFilterClose: PropTypes.func.isRequired,
  doCustomerInboxBusinessFilterOpen: PropTypes.func.isRequired,
  doCustomerInboxBusinessFilterSearch: PropTypes.func.isRequired,
  doInboxSearchFormToggle: PropTypes.func.isRequired,
  doLoadInboxThreadsListFilteredByBusiness: PropTypes.func.isRequired,
  doShowStartNewThread: PropTypes.func.isRequired
};

export default ThreadListHeaderComponent;
