import {CONVERSATION_ITEM_TYPES} from '../../data/thread/message';
import {
  EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_FROM_ME,
  EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
} from '../../actions/ext-actions';

const guessSpreadActionFromMessage = (state$, message) => {
  const {INBOUND, INTERNAL, OUTBOUND} = CONVERSATION_ITEM_TYPES;
  const senderIsMe =
    message.employee && message.employee.id === state$.value.getIn(['account', 'id']);

  switch (message.type) {
    case INBOUND:
      return EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER;

    case INTERNAL:
      return senderIsMe
        ? EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_FROM_ME
        : EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME;

    case OUTBOUND:
      return senderIsMe
        ? EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_FROM_ME
        : EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME;

    default:
      throw new Error(`Unable to guess spread action from message "${JSON.stringify(message)}"`);
  }
};

// eslint-disable-next-line import/prefer-default-export
export {guessSpreadActionFromMessage};
