import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_THREAD_PAGE_LEAVE,
  CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_FAILURE,
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_SUCCESS
} from '../../../../actions/customer-thread-canned-responses-actions';
import {
  CUSTOMER_THREAD_VISIBLE_LOAD,
  CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {UI_AREA_CLICK} from '../../../../../../shared/actions/ui-actions';
import {
  CLICKABLE_AREAS,
  CLICKABLE_AREAS_CUSTOMER_CONVERSATION_VISIBLE
} from '../../../../data/ui/clickable-areas';
import {
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS
} from '../../../../actions/customer-new-thread-actions';

export const DEFAULT_STATE = {
  activeThreadType: 'visible',
  business: null,
  cannedResponses: {
    data: {
      businessName: null,
      byCountry: [],
      byStore: [],
      country: null,
      organizationName: null
    },
    hasCannedResponses: null,
    isLoading: false
  },
  conversationId: null,
  participationId: null
};

const getDefaultState = () => fromJS(DEFAULT_STATE);

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Canned responses handling.
   */
  [CUSTOMER_THREAD_CANNED_RESPONSES_LOAD]: (state) =>
    state.setIn(['cannedResponses', 'isLoading'], true),

  [CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_FAILURE]: (state) =>
    state.setIn(['cannedResponses', 'isLoading'], false),

  [CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_SUCCESS]: (state, {payload: {data}}) => {
    const hasCannedResponses = data.byCountry.length > 0 || data.byStore.length > 0;

    return state.update('cannedResponses', (cannedResponses) =>
      cannedResponses
        .set('data', fromJS(data))
        .set('hasCannedResponses', hasCannedResponses)
        .set('isLoading', false)
    );
  },

  /**
   * Reset state when leaving thread component
   * in order to prevent badges from not being correctly updated.
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_PAGE_LEAVE]: getDefaultState,
  [CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD]: getDefaultState,
  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD]: getDefaultState,

  /**
   * Triggered when user is in small screen view and switch between visible and invisible thread.
   * @param {Object} state
   * @param {String} threadType
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE]: (state, {threadType}) =>
    state.set('activeThreadType', threadType),

  /**
   * Reset some state props when the thread visible loading start
   * because they may not be touched later so they must equal default.
   * @param {Immutable.Map} state
   * @param {String} internalMessageCursor
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_THREAD_VISIBLE_LOAD]: (state, {internalMessageCursor}) =>
    state
      .set('activeThreadType', internalMessageCursor ? 'invisible' : DEFAULT_STATE.activeThreadType)
      .set('cannedResponses', fromJS(DEFAULT_STATE.cannedResponses)),

  /**
   * Update state after successful customer thread visible load.
   * @param {Object} state
   * @param {Object} conversation
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (
    state,
    {
      payload: {
        data: {conversation}
      }
    }
  ) =>
    state
      .set('business', fromJS(conversation.business))
      .set('conversationId', conversation.id)
      .setIn(['cannedResponses', 'hasCannedResponses'], conversation.hasCannedResponses),

  /**
   * Click on an area of the ui.
   * @param {Immutable.Map} state
   * @param {String} area
   * @returns {Immutable.Map} new state
   */
  [UI_AREA_CLICK]: (state, {payload: {area}}) => {
    if (area === CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.INTERNAL) {
      return state.set('activeThreadType', 'invisible');
    }

    if (CLICKABLE_AREAS_CUSTOMER_CONVERSATION_VISIBLE.includes(area)) {
      return state.set('activeThreadType', 'visible');
    }

    return state;
  },
  [CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS]: (state, {payload: {business}}) =>
    state.set('business', fromJS(business)).set('conversationId', 'new')
});
