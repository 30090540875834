import {queryfyObject} from '../../../../shared/lib/route-helper';

const ADMINISTRATION_ORGANIZATION_CHANNELS = {
  GBM: {
    desktopAdminPageUrl: '/app/administration/organization/channels/gbm',
    gbmLogoUrl: `${ASSETS_PATH}/ext/gbm/google-my-business-logo.jpg`,
    steps: {
      createAgent: {
        brandName: {
          charactersMaxCount: 100
        },
        logo: {
          fileExtensionsSupported: ['.jpg', '.jpeg', '.png'],
          fileMaxSize: 51200,
          fileMaxSizeLabel: '50KB'
        }
      },
      signIn: {
        errors: {
          40106: 'accessDenied',
          40107: 'invalidGrant',
          50301: 'dataNotSaved'
        },
        getOAuthLink: (organizationId) => {
          const oAuthBaseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
          const oAuthUrlQueryString = queryfyObject(
            ADMINISTRATION_ORGANIZATION_CHANNELS.GBM.steps.signIn.getOAuthLinkParameters(
              organizationId
            )
          );

          return `${oAuthBaseUrl}${oAuthUrlQueryString}`;
        },
        getOAuthLinkParameters: (organizationId) => ({
          access_type: 'offline',
          client_id: '806219149655-68avgbsalqhqbcl0d89prdq9487a81gu.apps.googleusercontent.com',
          prompt: 'consent',
          redirect_uri: `${MIDDLE_END_URL}/gbm/auth`,
          response_type: 'code',
          scope: [
            'email',
            'openid',
            'profile',
            'https://www.googleapis.com/auth/businesscommunications'
          ].join(' '),
          state: organizationId
        })
      }
    }
  }
};

const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES = {
  LAUNCH_STATE_LAUNCHED: 'LAUNCH_STATE_LAUNCHED',
  LAUNCH_STATE_PENDING: 'LAUNCH_STATE_PENDING',
  LAUNCH_STATE_PENDING_UNLAUNCH: 'LAUNCH_STATE_PENDING_UNLAUNCH',
  LAUNCH_STATE_UNLAUNCHED: 'LAUNCH_STATE_UNLAUNCHED',
  LAUNCH_STATE_UNSPECIFIED: 'LAUNCH_STATE_UNSPECIFIED',
  STATE_NEW: 'STATE_NEW',
  STATE_PENDING_AUTHORIZATION: 'STATE_PENDING_AUTHORIZATION',
  VERIFICATION_STATE_PENDING: 'VERIFICATION_STATE_PENDING',
  VERIFICATION_STATE_SUSPENDED_IN_GMB: 'VERIFICATION_STATE_SUSPENDED_IN_GMB',
  VERIFICATION_STATE_UNSPECIFIED: 'VERIFICATION_STATE_UNSPECIFIED',
  VERIFICATION_STATE_VERIFIED: 'VERIFICATION_STATE_VERIFIED',
  VERIFICATION_STATE_UNVERIFIED: 'VERIFICATION_STATE_UNVERIFIED'
};

export {
  ADMINISTRATION_ORGANIZATION_CHANNELS,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_STATUSES
};
