import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ColleagueMentioned from './colleague-mentionned-component';
import ContentModerated from './content-moderated-component';
import ContentText from './content-text-component';
import MessageAttachment from './message-attachment-component';
import {
  black,
  blue,
  darkGrey,
  darkSnow,
  lightSlate,
  red,
  snow,
  veryDarkGrey,
  veryLightGreen,
  white
} from '../../../../../../shared/style/colors';
import {fontSize, spacing} from '../../../../../../shared/style/variables';
import {
  CONVERSATION_ITEM_TYPES,
  CONVERSATION_MESSAGE_FLOW_STATUS
} from '../../../../data/thread/message';

const MessageComponent = ({
  account,
  attachment,
  clientItemId,
  date,
  doSendMessageRetry,
  doThreadFilePreviewOpen,
  employee,
  flowStatus = CONVERSATION_MESSAGE_FLOW_STATUS.LOADED,
  hasMessageAfter,
  i18n,
  mentionedUserName,
  moderated,
  selected,
  text,
  threadType,
  type
}) => {
  // Handle padding variations based of bubble height
  // @see https://stackoverflow.com/questions/49058890/how-to-get-a-react-components-size-height-width-before-render
  const BUBBLE_MIN_HEIGHT = 30;
  const contentContainerRef = useRef();

  const BUBBLE_LINE_HEIGHT = '20px';
  const isInError = flowStatus === CONVERSATION_MESSAGE_FLOW_STATUS.ERROR;

  const getStyles = () => {
    const PADDING_MAPPING = {
      noBorder: '8px 10px',
      withBorder: '7px 9px'
    };

    if (isInError) {
      return {
        bubble: {
          backgroundColor: white,
          border: `1px solid ${red}`,
          color: red,
          padding: PADDING_MAPPING.withBorder
        }
      };
    }

    if (moderated) {
      return {
        bubble: {
          backgroundColor: snow,
          border: `1px solid ${snow}`,
          color: lightSlate,
          padding: PADDING_MAPPING.withBorder
        },
        time: {
          color: darkGrey
        }
      };
    }

    const isSentByMe = employee && employee.id === account.id;

    switch (type) {
      case CONVERSATION_ITEM_TYPES.INTERNAL: // From anybody in internal
        return {
          bubble: {
            backgroundColor: isSentByMe ? veryLightGreen : white,
            color: black,
            padding: PADDING_MAPPING.withBorder
          },
          time: {
            color: isSentByMe ? veryDarkGrey : darkGrey
          }
        };
      case CONVERSATION_ITEM_TYPES.INBOUND: // From customer in visible
        return {
          bubble: {
            backgroundColor: darkSnow,
            color: black,
            padding: PADDING_MAPPING.noBorder
          },
          time: {
            color: darkGrey
          }
        };
      case CONVERSATION_ITEM_TYPES.OUTBOUND: // From an employee or automatic in visible
        return {
          bubble: {
            backgroundColor: blue,
            color: white,
            padding: PADDING_MAPPING.noBorder
          },
          time: {
            color: white
          }
        };
      default:
        throw new Error(`Unhandled message type "${type}"`);
    }
  };

  const {bubble: bubbleStyle, time: timeStyle} = getStyles();

  const renderFlowState = () => {
    if (isInError) {
      const onClickError = () => doSendMessageRetry(clientItemId);

      return (
        <span
          data-test-id="send-message-retry"
          onClick={onClickError}
          tabIndex={-1}
          style={{
            color: red,
            cursor: 'pointer',
            display: 'block',
            fontSize: fontSize.small,
            padding: '5px'
          }}
        >
          {i18n.t('thread.messages.messageHasNotBeenSent')}
        </span>
      );
    }

    if (flowStatus === CONVERSATION_MESSAGE_FLOW_STATUS.SENDING) {
      return (
        <span
          data-test-id="flow-status-indicator"
          style={{
            color: lightSlate,
            display: 'block',
            fontSize: fontSize.xsmall,
            paddingLeft: spacing.medium,
            paddingRight: spacing.medium,
            paddingTop: hasMessageAfter ? 0 : '3px'
          }}
        >
          {i18n.t('common.sending')}
        </span>
      );
    }

    return null;
  };

  const renderTime = () => {
    const showLabel = [
      CONVERSATION_MESSAGE_FLOW_STATUS.LOADED,
      CONVERSATION_MESSAGE_FLOW_STATUS.SENT
    ].includes(flowStatus);

    return (
      <span
        data-test-id="time"
        style={{
          color: timeStyle.color,
          float: 'right',
          fontSize: fontSize.xsmall,
          minHeight: BUBBLE_LINE_HEIGHT,
          minWidth: '28px',
          marginLeft: spacing.medium,
          position: 'relative',
          right: '-3px',
          top: '6px' // do not touch unless validate new value with Designer
        }}
      >
        {showLabel ? moment(date).format('LT') : null}
      </span>
    );
  };

  const renderAttachment = () => {
    const isInvisibleThread = threadType === 'invisible';
    const wrapperBubbleWidth = contentContainerRef.current
      ? contentContainerRef.current.offsetWidth
      : null;

    return (
      <MessageAttachment
        containerStyle={text ? {marginBottom: spacing.medium} : null}
        invisibleThread={isInvisibleThread}
        linkStyle={{
          color: bubbleStyle.color
        }}
        {...attachment}
        {...{wrapperBubbleWidth, doThreadFilePreviewOpen}}
      />
    );
  };

  const renderText = () => (
    <React.Fragment>
      {mentionedUserName ? <span> </span> : null}
      <span data-test-id="item-label">
        <ContentText
          textColor={bubbleStyle.color}
          {...{
            selected,
            text
          }}
        />
      </span>
    </React.Fragment>
  );

  return (
    <div>
      {/* useful only for test */}
      <span data-test-id="flow-status" data-flow-status={flowStatus} />

      <div
        ref={contentContainerRef}
        title={moment(date).format('LLL')}
        style={{
          borderRadius: '10px',
          display: 'inline-block',
          fontSize: fontSize.large,
          fontWeight: 'regular',
          lineHeight: BUBBLE_LINE_HEIGHT,
          marginBottom: hasMessageAfter ? '5px' : 0,
          maxWidth: '100%',
          minHeight: `${BUBBLE_MIN_HEIGHT}px`,
          textAlign: 'left',
          width: attachment ? '300px' : 'auto',
          wordBreak: 'break-word',
          wordWrap: 'break-word',
          ...bubbleStyle
        }}
      >
        {moderated ? (
          <ContentModerated {...{i18n}} />
        ) : (
          <React.Fragment>
            {attachment ? renderAttachment() : null}
            {mentionedUserName ? <ColleagueMentioned {...{mentionedUserName}} /> : null}
            {text ? renderText() : null}
            {isInError ? null : renderTime()}
          </React.Fragment>
        )}
      </div>

      {renderFlowState()}
    </div>
  );
};

MessageComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  attachment: PropTypes.objectOf(PropTypes.any), // Can be null if text
  clientItemId: PropTypes.string,
  date: PropTypes.string,
  flowStatus: PropTypes.string,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  employee: PropTypes.objectOf(PropTypes.any),
  hasMessageAfter: PropTypes.bool,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  mentionedUserName: PropTypes.string,
  moderated: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string, // Can be empty if attachment
  threadType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default MessageComponent;
