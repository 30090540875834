import PropTypes from 'prop-types';
import {fontSize, spacing} from '../../../../shared/style/variables';
import {lightSnow} from '../../../../shared/style/colors';

const EmptyMessageComponent = ({dataTestId, description, style, title}) => (
  <div
    data-test-id={dataTestId}
    style={{
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      backgroundColor: lightSnow,
      height: '100%',
      ...style
    }}
  >
    <div
      style={{
        padding: spacing.xlarge,
        fontSize: fontSize.large,
        lineHeight: '18px'
      }}
    >
      <strong>{title}</strong>
      <br />
      {description}
    </div>
  </div>
);

EmptyMessageComponent.propTypes = {
  dataTestId: PropTypes.string,
  description: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string.isRequired
};

export default EmptyMessageComponent;
