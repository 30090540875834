import React from 'react';
import PropTypes from 'prop-types';
import {highlight} from '../script/highlight';
import {
  getMarginType,
  getStyledListItem,
  MARGIN_BETWEEN_SUB_ITEMS,
  SUB_ITEM_TITLE_LINE_HEIGHT
} from './lib/thread-list-item-helper';
import AttachmentPreview from './attachment-preview-component';
import Elapsed from '../elapsed-component';
import EmojiRender from '../emoji/emoji-render';
import ThreadChannel from '../icon/thread-channel';
import UserAvatar from '../avatar/user-avatar-component';
import {
  avatarSize,
  fontSize,
  fontWeight,
  radius,
  spacing
} from '../../../../../shared/style/variables';
import {oneline, multiline} from '../../../../../shared/style/ellipsis';
import {darkSlate} from '../../../../../shared/style/colors';

const CustomerInboxThreadListItemComponent = ({
  centerWrapperStyle,
  i18n,
  indexInList,
  isLastChild,
  isSelected,
  muiTheme,
  node: {customer, message, title},
  onItemClick,
  rightWrapperStyle
}) => {
  const onListItemClick = () => {
    onItemClick({indexInList});
  };

  const renderItemLeft = () => (
    <div
      style={{
        position: 'relative',
        padding: '10px 10px 10px 5px'
      }}
    >
      <UserAvatar size={avatarSize.large} src={customer.pictureHref} />
    </div>
  );

  const renderItemCenterChannel = () => (
    <ThreadChannel
      showLabel
      type={customer.channel}
      {...{
        i18n
      }}
      style={{
        height: '20px',
        marginTop: MARGIN_BETWEEN_SUB_ITEMS
      }}
    />
  );

  const renderItemCenterMessage = () => {
    if (!message.text && message.attachment) {
      return <AttachmentPreview {...{contentType: message.attachment.contentType, i18n}} />;
    }

    return (
      <div
        data-test-id="message"
        style={{
          maxHeight: '40px',
          lineHeight: '18px',
          fontSize: fontSize.medium,
          ...multiline(2)
        }}
      >
        <EmojiRender transformer={highlight}>{message.text}</EmojiRender>
      </div>
    );
  };

  const renderItemCenterTitle = () => {
    return (
      <h2
        style={{
          marginBottom: MARGIN_BETWEEN_SUB_ITEMS,
          lineHeight: SUB_ITEM_TITLE_LINE_HEIGHT,
          fontSize: fontSize.xlarge,
          fontWeight: fontWeight.normal,
          color: darkSlate,
          ...oneline
        }}
      >
        <span data-test-id="title">{highlight(title)}</span>
        {customer.company ? (
          <React.Fragment>
            , <span data-test-id="company-name">{highlight(customer.company)}</span>
          </React.Fragment>
        ) : null}
      </h2>
    );
  };

  const renderItemRight = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: `15px ${spacing.medium} 0 ${spacing.medium}`,
        alignSelf: 'flex-start',
        ...rightWrapperStyle
      }}
    >
      <Elapsed value={message.date} i18nPrefix="inbox.list.item" />
    </div>
  );

  const itemExtraProps = {
    ...(isSelected ? {'data-selected': true} : null)
  };
  const itemHeight = '100px';
  const marginType = getMarginType(isLastChild);
  const StyledListItem = getStyledListItem(muiTheme);

  return (
    <div
      data-test-id="customer-list-item"
      style={{
        marginBottom: marginType,
        borderRadius: radius.medium
      }}
      {...itemExtraProps}
    >
      <StyledListItem
        button
        selected={isSelected}
        onClick={onListItemClick}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: itemHeight,
          maxHeight: itemHeight,
          padding: '0 0 10px 0',
          boxSizing: 'border-box',
          borderRadius: radius.medium
        }}
      >
        {renderItemLeft()}

        <div
          style={{
            flex: '1',
            padding: '15px 10px 10px 0',
            overflow: 'hidden',
            ...centerWrapperStyle
          }}
        >
          {renderItemCenterTitle()}
          {renderItemCenterMessage()}
          {renderItemCenterChannel()}
        </div>

        {renderItemRight()}
      </StyledListItem>
    </div>
  );
};

CustomerInboxThreadListItemComponent.propTypes = {
  centerWrapperStyle: PropTypes.objectOf(PropTypes.any),
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  indexInList: PropTypes.number.isRequired,
  isLastChild: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  muiTheme: PropTypes.objectOf(PropTypes.any).isRequired,
  node: PropTypes.objectOf(PropTypes.any).isRequired,
  onItemClick: PropTypes.func.isRequired,
  rightWrapperStyle: PropTypes.objectOf(PropTypes.any)
};

export default CustomerInboxThreadListItemComponent;
