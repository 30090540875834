import {fromEvent, merge} from 'rxjs';
import {delay, map, throttleTime} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import i18n from 'i18next';
import setHtmlLanguage from '../lib/window-helper';
import {setCookie} from '../lib/cookie-helper';
import {
  UI_LANGUAGE_RELOAD,
  UI_LANGUAGE_UPDATE_SUCCESS,
  UI_UPDATE_LANGUAGE,
  UI_WINDOW_DOM_CONTENT_LOADED,
  UI_WINDOW_FOCUSED,
  UI_WINDOW_RESIZED,
  UI_WINDOW_UNFOCUSED
} from '../actions/ui-actions';

const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight
});

let languageReloaded;

const getActionForVisibilityState = (visibilityState) => {
  return visibilityState === 'visible' ? UI_WINDOW_UNFOCUSED : UI_WINDOW_FOCUSED;
};

/**
 * Finally, the epic.
 */
const uiEpic = () => (action$) => {
  const documentOnDOMContentLoaded = fromEvent(document, 'DOMContentLoaded').pipe(
    map((event) => {
      return {
        type: UI_WINDOW_DOM_CONTENT_LOADED,
        target: event.target,
        ...getWindowDimensions()
      };
    })
  );

  const documentOnVisibilityChange = fromEvent(document, 'visibilitychange').pipe(
    map((event) => {
      return {
        type: event.target.hidden ? UI_WINDOW_UNFOCUSED : UI_WINDOW_FOCUSED
      };
    })
  );

  const focusOnDomContentLoaded = action$.pipe(
    ofType(UI_WINDOW_DOM_CONTENT_LOADED),
    map(({target: {hidden, visibilityState}}) => {
      return {
        type: hidden ? UI_WINDOW_UNFOCUSED : getActionForVisibilityState(visibilityState)
      };
    })
  );

  const languageCheckReloaded = action$.pipe(
    ofType(UI_LANGUAGE_RELOAD),
    delay(100),
    map(({language}) => {
      if (languageReloaded) {
        return {
          type: UI_LANGUAGE_UPDATE_SUCCESS,
          language
        };
      }

      return {
        type: UI_LANGUAGE_RELOAD,
        language
      };
    })
  );

  const languageUpdate = action$.pipe(
    ofType(UI_UPDATE_LANGUAGE),
    map(({language}) => {
      setHtmlLanguage(language);

      setCookie('i18next', language, 365);

      languageReloaded = false;
      i18n.changeLanguage(language, () => {
        languageReloaded = true;

        return languageReloaded;
      });

      return {
        type: UI_LANGUAGE_RELOAD,
        language
      };
    })
  );

  const windowOnBlur = fromEvent(window, 'blur').pipe(
    map(() => {
      return {
        type: UI_WINDOW_UNFOCUSED
      };
    })
  );

  const windowOnFocus = fromEvent(window, 'focus').pipe(
    map(() => {
      return {
        type: UI_WINDOW_FOCUSED
      };
    })
  );

  const windowOnResize = fromEvent(window, 'resize').pipe(
    throttleTime(100),
    map(() => {
      return {
        type: UI_WINDOW_RESIZED,
        ...getWindowDimensions()
      };
    })
  );

  return merge(
    documentOnDOMContentLoaded,
    documentOnVisibilityChange,
    focusOnDomContentLoaded,
    languageCheckReloaded,
    languageUpdate,
    windowOnBlur,
    windowOnFocus,
    windowOnResize
  );
};

export default uiEpic;
