import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import assert from '../../../../../../shared/lib/assert';
import {
  findMessageIndex,
  pushMessage,
  sendMessage,
  sendMessageFail,
  sendMessageSuccessful,
  sortMessages
} from '../../../lib/message-helper';
import {
  DEFAULT_STATE_THREAD,
  checkMessageForm,
  failUploadFile,
  persistMessageFormText,
  removeUploadedFile,
  startUploadFile,
  succeedUploadFile
} from '../../../lib/message-form-helper';
import addTransferMessage from '../../../lib/add-transfer-message';
import createThreadFromMessage from '../../../lib/thread-helper';
import {customerThreadNewLoaded, initCustomerThreadNew} from './lib/customer-thread-helper';
import {deletePersistedState, resetNotPersistedState} from '../../../lib/persistence-helper';
import {getDateTimeLegacy} from '../../../../lib/date-time-helper';
import {APP_LOGOUT, APP_STATE_BOOT_LOAD_SUCCESS} from '../../../../actions/app-actions';
import {
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-new-thread-actions';
import {LEGACY_CUSTOMER_THREAD_LOAD} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS
} from '../../../../actions/customer-thread-assignment-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS} from '../../../../actions/customer-thread-transfer-actions';
import {
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_REMOVE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_PERSIST,
  LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
  EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS,
  EXT_ORGANIZATION_SENT_AUTOMATIC_MESSAGE_TO_CUSTOMER,
  LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD,
  LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD
} from '../../../../actions/ext-actions';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_THREAD,
  ...{
    assignedTo: null,
    deactivated: null,
    hasMoreMessagesAfter: false,
    loadingAfter: false,
    messages: [],
    userId: null,
    whatsappTemplates: []
  }
};

/**
 * Update optimistically the visible thread after a transfer,
 * eventually resetting assignee.
 *
 * @param {Object} state
 * @param {String} newBusinessName
 * @param {String} employeeName
 * @param {Integer} timestamp
 * @param {Boolean} inScope
 * @returns {Object}
 */
const updateOptimisticallyAfterTransfer = (
  state,
  newBusinessName,
  employeeName,
  timestamp,
  inScope
) => {
  let newState;

  // 1. assignee
  // eslint-disable-next-line eqeqeq
  if (inScope || state.get('userId') != state.getIn(['assignedTo', 'user', 'id'])) {
    newState = state;
  } else {
    newState = state.set('assignedTo', null);
  }

  if (newState.get('hasMoreMessageAfter')) {
    return newState;
  }

  // 2. message
  return addTransferMessage(newState, {
    businessName: newBusinessName,
    senderName: employeeName,
    date: getDateTimeLegacy(timestamp)
  });
};

/**
 * Assign & release.
 */
const assignIfCurrentThread = (state, {assignedTo, participationId}) => {
  // eslint-disable-next-line eqeqeq
  if (participationId != state.get('participationId')) {
    return state;
  }

  return state.set('assignedTo', fromJS(assignedTo));
};
const releaseIfCurrentThread = (state, {participationId}) => {
  // eslint-disable-next-line eqeqeq
  if (participationId != state.get('participationId')) {
    return state;
  }

  return state.set('assignedTo', null);
};

/**
 * Add an external message to the list of messages, then sort the list.
 *
 * @param {Object} data
 * @param {Immutable.List} messages
 * @param {Object} otherData
 * @returns {Immutable.List} messages sorted
 */
const pushAndSortExternalMessage = (data, messages, otherData) =>
  pushMessage(data, messages, otherData).update(sortMessages);

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Clean storage on logout.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_LOGOUT]: deletePersistedState,

  /**
   * Load some useful current user information.
   *
   * @param {Object} state the current reducer state
   * @param {Object} data
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_SUCCESS]: (state, {data}) => {
    return state.set('userId', data.account.id);
  },

  /**
   * Reset state when thread selection change.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: (state) => {
    return resetNotPersistedState(state, DEFAULT_STATE).set('loading', true);
  },

  /**
   * Paginate a customer thread.
   *
   * @param {Object} state the current reducer state
   * @param {String} direction of loading
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE]: (state, {direction}) => {
    return state
      .set('direction', direction)
      .set('loadingAfter', direction === 'after')
      .set('loadingBefore', direction === 'before');
  },

  /**
   * Load a customer thread.
   *
   * @param {Object} state the current reducer state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_LOAD]: (state, {payload: {participationId}}) => {
    return checkMessageForm(state.set('loading', true).set('participationId', participationId));
  },

  /**
   * Load a customer visible thread.
   *
   * @param {Object} state the current reducer state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD]: (state) => state.set('loading', true),

  /**
   * Triggered when the thread is successfully loaded from the server.
   * May be a partial messages part to add to current (loadingBefore)
   * or a new one (loading).
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data, params}) => {
    const newState = state
      .merge(fromJS(data))
      .set('direction', params.direction)
      .set('loading', false)
      .set('loadingAfter', false)
      .set('loadingBefore', false);

    const {fromId} = params;

    if (fromId) {
      return newState.update('messages', (messages) => {
        const foundMessageIndex = messages.findIndex((message) => message.get('id') == fromId); // eslint-disable-line eqeqeq

        return foundMessageIndex > -1
          ? messages.update(foundMessageIndex, (message) => message.set('selected', true))
          : messages;
      });
    }

    return newState;
  },

  /**
   * Assigned whatsapp templates responses data
   * across visible customer thread.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_SUCCESS]: (state, {data}) => {
    return state.set('whatsappTemplates', fromJS(data));
  },

  /**
   * Triggered when user load a new thread.
   *
   * @param {Object} state
   * @param {String} businessName
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD]: (state, {businessName}) => {
    return initCustomerThreadNew(state, DEFAULT_STATE, businessName);
  },

  /**
   * Triggered when user successfully load a new thread.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS]: customerThreadNewLoaded,

  /**
   * Triggered after a successful pagination call.
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS]: (state, {data, params}) => {
    const loadedMessages = fromJS(data.messages);

    const newState = state
      .merge(fromJS(data))
      .set('direction', params.direction)
      .set('loading', false)
      .set('loadingAfter', false)
      .set('loadingBefore', false);

    if (params.direction === 'before') {
      return newState
        .set('hasMoreMessagesAfter', state.get('hasMoreMessagesAfter'))
        .set('messages', loadedMessages.concat(state.get('messages')));
    }

    if (params.direction === 'after') {
      return newState
        .set('hasMoreMessagesBefore', state.get('hasMoreMessagesBefore'))
        .set('messages', state.get('messages').concat(loadedMessages));
    }

    return newState;
  },

  /**
   * Select customer from the search.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER]: (state) => {
    return resetNotPersistedState(state, DEFAULT_STATE).set('loading', true);
  },

  /**
   * Select message from the search.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: (state) => {
    return resetNotPersistedState(state, DEFAULT_STATE).set('loading', true);
  },

  /**
   * Send message handling.
   * 1st action is an optimistic update, with a temporary identifier
   * to the message so it can be updated in the <..._SUCCESS> action.
   *
   * @param {Object} state
   * @param {String} attachment
   * @param {String} clientMessageId
   * @param {String} fullName
   * @param {String} organizationPicture
   * @param {String} picture
   * @param {String} text
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE]: (
    state,
    {attachment, clientMessageId, fullName, organizationPicture, picture, text}
  ) => {
    if (state.get('hasMoreMessagesAfter')) {
      return state;
    }

    assert(fullName);

    return sendMessage(state, {
      presentationType: 'MineOutgoing',
      message: {
        attachment,
        clientMessageId,
        text
      },
      user: {
        fullName,
        orgIcon: organizationPicture,
        userIcon: picture
      }
    });
  },

  /**
   * Update the state after a message has been sent successfully.
   *
   * @param {Immutable.Map} state
   * @param {String} clientMessageId
   * @param {String} id
   * @returns {Immutable.Map} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: (state, {clientMessageId, id}) => {
    assert(clientMessageId);
    assert(id);

    return sendMessageSuccessful(state, {id, clientMessageId});
  },

  [LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE]: sendMessageFail,

  /**
   * Organization sent automatic message to customer.
   *
   * @param {Object} state the current reducer state
   * @param {Object} data the socket action
   * @returns {Object} new state
   */
  [EXT_ORGANIZATION_SENT_AUTOMATIC_MESSAGE_TO_CUSTOMER]: (state, {data}) => {
    // filtering
    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId') || state.get('hasMoreMessagesAfter')) {
      return state;
    }

    // insert the message
    return state.update('messages', (messages) => {
      return pushAndSortExternalMessage(data, messages, {
        automatic: true,
        clientMessageId: data.clientMessageId,
        presentationType: 'AutomaticOutgoing'
      });
    });
  },

  /**
   * An employee sent a message to the customer.
   * Actions:
   * - filter out message sent on other channels
   * - update the status of the message if found
   * - add the new message to the conversation
   *
   * @param {Object} state the current reducer state
   * @param {Object} data the socket action
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER]: (state, {data}) => {
    // filtering
    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId') || state.get('hasMoreMessagesAfter')) {
      return state;
    }

    return state.set('direction', null).update('messages', (messages) => {
      // update the message with status received (?)
      const index = findMessageIndex(messages, data.id, data.clientMessageId);

      if (index > -1) {
        return messages.update(index, (message) => {
          return message
            .set('id', data.id)
            .set('isFirstOfSegment', data.isFirstOfSegment)
            .set('status', 'sent');
        });
      }

      // insert the message
      const isMe = state.get('userId') == data.senderId; // eslint-disable-line eqeqeq

      return pushAndSortExternalMessage(data, messages, {
        clientMessageId: data.clientMessageId,
        presentationType: isMe ? 'MineOutgoing' : 'ColleagueOutgoing',
        senderName: data.senderName
      });
    });
  },

  /**
   * A customer sent a message to the organization.
   * Actions:
   * - filter out message sent on other channels
   * - avoid duplicate
   * - add the new message to the conversation
   *
   * @param {Object} state the current reducer state
   * @param {Object} data the socket action
   * @returns {Object} new state
   */
  [EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION]: (state, {data}) => {
    // filtering
    // @todo check here if we need to add the condition " || state.get('hasMoreMessagesAfter')", like it's done on other events
    // eslint-disable-next-line eqeqeq
    if (data.participationId != state.get('participationId')) {
      return state;
    }

    // de-duplicating
    // eslint-disable-next-line eqeqeq
    if (state.get('messages').findIndex((message) => message.get('id') == data.id) !== -1) {
      return state;
    }

    // insert the message
    return state.update('messages', (messages) => {
      return pushAndSortExternalMessage(data, messages, {
        presentationType: 'StandardIncoming',
        senderName: data.senderName
      });
    });
  },

  /**
   * An employee send a message in a new thread.
   *
   * @param {Object} state
   * @param {Object} attachment
   * @param {String} clientMessageId
   * @param {String} fullName
   * @param {String} organizationPicture
   * @param {String} picture
   * @param {String} text
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE]: (
    state,
    {attachment, clientMessageId, fullName, organizationPicture, picture, text}
  ) => {
    return state
      .setIn(['threadsForm', 'new', 'fileUploaded'], null)
      .update('messages', (messages) => {
        return pushMessage(
          {
            text,
            date: getDateTimeLegacy(),
            orgIcon: organizationPicture,
            userIcon: picture
          },
          messages,
          {
            attachment,
            clientMessageId,
            presentationType: 'MineOutgoing',
            senderName: fullName,
            status: 'sending'
          }
        );
      });
  },

  /**
   * The first message of a new thread was sent successfully.
   */
  [LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: createThreadFromMessage,

  /**
   * File handling.
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT]: (state) => {
    return state.set('selectedFileExceedMaxSize', false).set('uploadingFile', true);
  },
  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE]: (state) => {
    return state.set('selectedFileExceedMaxSize', true).set('uploadingFile', false);
  },
  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED]: (state) => {
    return state.set('uploadingFile', false);
  },

  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_REMOVE]: removeUploadedFile,

  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD]: startUploadFile,
  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS]: succeedUploadFile,
  [LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE]: failUploadFile,

  /**
   * Optimistic update after a successful transfer a thread to a business made by current user.
   *
   * @param {Object} state
   * @param {Object} params
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_TRANSFER_SUCCESS]: (state, {params}) => {
    return updateOptimisticallyAfterTransfer(
      state,
      params.name,
      params.fullName,
      Date.now(),
      params.inScope
    );
  },

  /**
   * Add message after thread is transferred to a business.
   * Do nothing if it's current user that did the action.
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Boolean} doneByOtherUserToCurrentThread
   * @param {Boolean} newBusinessIsInMyScope
   * @returns {Object} new state
   */
  [EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS]: (
    state,
    {data, doneByOtherUserToCurrentThread, newBusinessIsInMyScope}
  ) => {
    if (doneByOtherUserToCurrentThread) {
      return updateOptimisticallyAfterTransfer(
        state,
        data.newBusinessName,
        data.employeeName,
        data.timestamp,
        newBusinessIsInMyScope
      );
    }

    return state;
  },

  /**
   * Assign the customer thread to the current user / from socket.
   */
  [LEGACY_CUSTOMER_THREAD_TAKE_SUCCESS]: assignIfCurrentThread,
  [LEGACY_EXT_EMPLOYEE_TOOK_CUSTOMER_THREAD]: assignIfCurrentThread,

  /**
   * Release the customer thread from the current user / socket.
   */
  [LEGACY_CUSTOMER_THREAD_RELEASE_SUCCESS]: releaseIfCurrentThread,
  [LEGACY_EXT_EMPLOYEE_RELEASED_CUSTOMER_THREAD]: releaseIfCurrentThread,

  /**
   * Persist message form text.
   *
   * @param {Object} state
   * @param {String} participationId
   * @param {String} text
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_PERSIST]: persistMessageFormText
});
