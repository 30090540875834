export default {
  acceptedInvitation: {
    graphql: 'update-accepted-invitation-email-subscription-with-token-mutation',
    canSubscribeAgain: false
  },
  newMessage: {
    graphql: 'update-new-message-email-subscription-with-token-mutation',
    canSubscribeAgain: false
  },
  stats: {
    graphql: 'update-weekly-email-subscription-with-token-mutation',
    canSubscribeAgain: true
  }
};
