import {merge, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_LAYOUT_MOUNTED,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_FAILURE
} from '../../../../../actions/administration/administration-business-channels-gbm-actions';
import {
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD,
  ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS
} from '../../../../../actions/administration/administration-organization-channels-gbm-actions';
import {
  graphqlCreateBusinessLocation,
  graphqlDisconnectBusinessLocation,
  graphqlGetBusinessLocationQuery
} from '../../graphql-queries/grapql-business-page-channels-gbm-query-helper';
import {
  getBusinessLocation,
  mapBusinessLocationFormToGraphQL
} from '../../lib/administration-business-channel-helper';
import {isCurrentPageInHeadingAdministrationBusinessChannels} from '../../../../../lib/route-helper';

const ERROR_MAPPING_PLACEID_BUSINESS_LOCATION = {
  40108: 'errorPlaceIdAlreadyExist',
  40109: 'errorPlaceIdNotVerified'
};

const AdministrationBusinessPageChannelsGbmEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadBusinessLocationOnGbmAgentLoaded = action$.pipe(
      ofType(ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS),
      filter(isCurrentPageInHeadingAdministrationBusinessChannels(state$)),
      map(() => ({
        type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD
      }))
    );

    const loadBusinessLocationOnGbmBusinessLayoutMounted = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_LAYOUT_MOUNTED),
      map(() => {
        return {
          type: ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD
        };
      })
    );

    const loadBusinessLocation = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD),
      mergeMap(() => {
        const businessId = state$.value.getIn([
          'administration',
          'layout',
          'currentPageContext',
          'businessId'
        ]);

        return graphql(graphqlGetBusinessLocationQuery(businessId)).pipe(
          map((payload) => ({
            type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_SUCCESS,
            payload: {
              businessLocation: getBusinessLocation(payload)
            }
          })),
          catchError((error) =>
            of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_FAILURE,
              error
            })
          )
        );
      })
    );

    const createBusinessLocationFormSubmitButtonClick = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK),
      map(({payload}) => {
        return {
          type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE,
          payload
        };
      })
    );

    const createBusinessLocation = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE),
      mergeMap(({payload: {fields}}) => {
        const businessId = state$.value.getIn([
          'administration',
          'layout',
          'currentPageContext',
          'businessId'
        ]);
        const graphqlParams = mapBusinessLocationFormToGraphQL(businessId, fields);

        return graphql(graphqlCreateBusinessLocation(graphqlParams)).pipe(
          map(() => {
            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS
            };
          }),
          catchError((error) => {
            const agentCreationSuccessfulFakeStorage = localStorage.getItem(
              'instaplyDesktop.fake-business-location-creation-successful'
            );

            const agentCreationPlaceIdErrorfulFakeStorage = localStorage.getItem(
              'instaplyDesktop.fake-business-location-creation-placeid-error'
            );

            if (agentCreationSuccessfulFakeStorage) {
              return of({
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS
              });
            }

            const isPlaceIdError = [40108, 40109].includes(error[0]?.code);

            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_FAILURE,
              payload: {
                error:
                  agentCreationPlaceIdErrorfulFakeStorage || isPlaceIdError
                    ? ERROR_MAPPING_PLACEID_BUSINESS_LOCATION[
                        +agentCreationPlaceIdErrorfulFakeStorage || error[0].code
                      ]
                    : error
              }
            });
          })
        );
      })
    );

    const disconnectBusinessLocationButtonClick = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK),
      map(() => {
        return {
          type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT
        };
      })
    );

    const disconnectBusinessLocation = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT),
      mergeMap(() => {
        const businessId = state$.value.getIn([
          'administration',
          'layout',
          'currentPageContext',
          'businessId'
        ]);

        return graphql(graphqlDisconnectBusinessLocation(businessId)).pipe(
          map(() => {
            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS
            };
          }),
          catchError((error) => {
            const agentCreationSuccessfulFakeStorage = localStorage.getItem(
              'instaplyDesktop.fake-business-location-disconnect-successful'
            );

            if (agentCreationSuccessfulFakeStorage) {
              return of({
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS
              });
            }

            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(
      createBusinessLocation,
      createBusinessLocationFormSubmitButtonClick,
      disconnectBusinessLocationButtonClick,
      disconnectBusinessLocation,
      loadBusinessLocationOnGbmAgentLoaded,
      loadBusinessLocationOnGbmBusinessLayoutMounted,
      loadBusinessLocation
    );
  };

export default AdministrationBusinessPageChannelsGbmEpic;
