import PropTypes from 'prop-types';

const AdministrationLeftMenuIconComponent = ({iconName}) => (
  <img
    alt=""
    src={`${ASSETS_PATH}/img/icon/${iconName}.svg`}
    style={{
      width: '16px'
    }}
  />
);

AdministrationLeftMenuIconComponent.propTypes = {
  iconName: PropTypes.string.isRequired
};

export default AdministrationLeftMenuIconComponent;
