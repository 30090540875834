import PropTypes from 'prop-types';
import FormattedRelativeDate from '../formatted-relative-date-component';
import {fontSize} from '../../../../../shared/style/variables';
import {lightSlate, lightSnow, smokeGreen, veryDarkGrey} from '../../../../../shared/style/colors';

const DateRowComponent = ({date, threadType}) => {
  const isVisibleConversation = threadType === 'visible';

  return (
    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <div
        data-test-id="date"
        style={{
          display: 'flex',
          marginTop: '5px',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: '50px',
          padding: '5px 15px',
          fontSize: fontSize.small,
          background: isVisibleConversation ? lightSnow : smokeGreen,
          color: isVisibleConversation ? lightSlate : veryDarkGrey
        }}
      >
        <FormattedRelativeDate i18nPrefix="thread.daySeparator" value={date} />
      </div>
    </div>
  );
};

DateRowComponent.propTypes = {
  date: PropTypes.string.isRequired,
  threadType: PropTypes.string.isRequired
};

export default DateRowComponent;
