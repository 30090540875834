import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import SurveyIcon from 'material-ui/svg-icons/av/equalizer';
import context from '../../../../../../../shared/component/context';
import getSidebarWidth from '../../../../common/script/get-sidebar-width';
import CloseIcon from '../../../../common/icon/close-icon';
import FloatPanel from '../../../../common/float-panel-component';
import {
  InboxHeaderLayout,
  InboxHeaderTitle
} from '../../../../common/inbox/inbox-header-components';
import LegacyCustomerProfileCardText from './legacy-customer-profile-card-text';
import LegacyCustomerProfileEdit from './legacy-customer-profile-edit-component';
import Link from '../../../../common/link/link-component';
import SelectFile from '../../../../common/file/select-file-component';
import UserAvatar from '../../../../common/avatar/user-avatar-component';
import {
  avatarSize,
  fontSize,
  fontWeight,
  spacing
} from '../../../../../../../shared/style/variables';
import {black, darkSmoke, lightSlate, lightSmoke} from '../../../../../../../shared/style/colors';

const SurveySummary = ({children, dataTestId, survey}, {i18n}) => (
  <li data-test-id={dataTestId} style={{marginBottom: spacing.medium, display: 'flex'}}>
    <span data-test-id="text" style={{flex: 1}}>
      {children}
    </span>
    <span data-test-id="created-at">
      {survey
        ? moment(survey.createdAt).format('L')
        : i18n.t('customerThread.customerProfile.surveys.noAnswer')}
    </span>
  </li>
);

SurveySummary.contextTypes = context;

SurveySummary.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  survey: PropTypes.objectOf(PropTypes.any)
};

/**
 * The component itself.
 */
class LegacyCustomerProfileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doUploadPicture = this._doUploadPicture.bind(this);
  }

  _renderActivateSurveyElement = () => {
    const {i18n} = this.context;
    const {businessId, isAdminOfBusiness} = this.props;

    return isAdminOfBusiness ? (
      <Link
        data-test-id="activate"
        href={`${OLD_WEBAPP_URL}/a#/administration/store/${businessId}/surveys`}
        label={i18n.t('customerThread.customerProfile.surveys.clickToActivate')}
      />
    ) : (
      <span data-test-id="deactivated">
        {i18n.t('customerThread.customerProfile.surveys.deactivated')}
      </span>
    );
  };

  _renderSurveySummaryInformation = () => {
    const {i18n} = this.context;
    const {participantNps, participantPreferredChannel} = this.props;

    return (
      <ul data-test-id="info">
        <SurveySummary dataTestId="current-nps" survey={participantNps}>
          {i18n.t('customerThread.customerProfile.surveys.currentNps')}:
          <strong> {participantNps ? participantNps.text : '-'}</strong>
        </SurveySummary>
        <SurveySummary dataTestId="preferred-channel" survey={participantPreferredChannel}>
          {i18n.t('customerThread.customerProfile.surveys.preferredChannel')}:
          <strong>
            {' '}
            {participantPreferredChannel
              ? i18n.t(
                  `customerThread.customerProfile.surveys.preferredChannelText.${participantPreferredChannel.text}`
                )
              : '-'}
          </strong>
        </SurveySummary>
      </ul>
    );
  };

  _renderNoSurveyInProgress = () => {
    const {i18n} = this.context;
    const {businessSurveyActivated} = this.props;

    return businessSurveyActivated ? (
      <span data-test-id="no-survey-in-progress">
        {i18n.t('customerThread.customerProfile.surveys.empty')}
      </span>
    ) : null;
  };

  _doUploadPicture(file) {
    const {participationId, participantId, doCustomerProfilePictureUpload} = this.props;
    doCustomerProfilePictureUpload(participationId, participantId, file);
  }

  render() {
    const {i18n} = this.context;
    const {
      businessSurveyActivated,
      businessSurveyLoading,
      enableSurveys,
      isSmallScreen,
      participantCompany,
      participantEmail,
      participantFirstName,
      participantIcon,
      participantIconIsUploading,
      participantId,
      participantLastName,
      participantLoading,
      participantName,
      participantNps,
      participantPhone,
      participantPreferredChannel,
      participationId,
      readOnly,
      showCustomerProfile,
      showEditCustomerProfile,
      doCustomerProfileClose,
      doCustomerProfileEditClose,
      doCustomerProfileEditOpen,
      doCustomerProfileEditTextUpdate
    } = this.props;

    const isEditable = !participantLoading;

    const panelStyle = isSmallScreen
      ? {
          paddingRight: getSidebarWidth()
        }
      : {
          borderLeft: `1px solid ${lightSmoke}`
        };

    return (
      <div data-test-id="customer-profile">
        {showEditCustomerProfile ? (
          <FloatPanel
            dataTestId="edit-panel"
            animate={false}
            show={showEditCustomerProfile}
            style={panelStyle}
            doClose={doCustomerProfileEditClose}
            titleComponent={
              <InboxHeaderLayout
                dataTestId="header"
                style={{
                  position: 'relative',
                  alignItems: 'center',
                  padding: spacing.large,
                  borderBottom: `1px solid ${lightSmoke}`
                }}
              >
                <InboxHeaderTitle
                  dataTestId="title"
                  style={{
                    display: 'flex',
                    flex: 9,
                    padding: 0,
                    alignItems: 'center',
                    textAlign: 'left',
                    height: '100%'
                  }}
                >
                  {i18n.t('customerThread.customerProfile.editForm.titleLegacy')}
                </InboxHeaderTitle>
              </InboxHeaderLayout>
            }
          >
            <LegacyCustomerProfileEdit
              {...{
                isSmallScreen,
                participantCompany,
                participantEmail,
                participantFirstName,
                participantId,
                participantLastName,
                participantPhone,
                participationId,
                showEditCustomerProfile,
                doCustomerProfileEditClose,
                doCustomerProfileEditTextUpdate
              }}
            />
          </FloatPanel>
        ) : (
          <FloatPanel
            animate={!isSmallScreen}
            animationSide="right"
            show={showCustomerProfile}
            style={panelStyle}
            doClose={doCustomerProfileClose}
            titleComponent={
              <InboxHeaderLayout
                dataTestId="header"
                style={{
                  position: 'relative',
                  padding: spacing.large,
                  alignItems: 'center',
                  borderBottom: `1px solid ${lightSmoke}`
                }}
              >
                <InboxHeaderTitle
                  dataTestId="title"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    height: '100%',
                    padding: 0
                  }}
                >
                  {i18n.t('customerThread.customerProfile.title')}
                </InboxHeaderTitle>
                <CloseIcon onClick={doCustomerProfileClose} />
              </InboxHeaderLayout>
            }
          >
            <div
              data-test-id="card"
              style={{
                display: 'flex',
                padding: `${spacing.medium} ${spacing.mlarge}`,
                alignItems: 'center'
              }}
            >
              <SelectFile
                dataTestId="user-avatar-input"
                enabled={isEditable}
                iconColor={black}
                isLoading={participantIconIsUploading}
                isNewEditIcon={false}
                doUploadFile={this._doUploadPicture}
              >
                <UserAvatar size={avatarSize.xlarge} src={participantIcon} />
              </SelectFile>

              <LegacyCustomerProfileCardText
                {...{
                  participantCompany,
                  participantEmail,
                  participantName,
                  participantPhone,
                  readOnly
                }}
                emailStyle={{
                  maxWidth: 260
                }}
                style={{
                  overflow: 'hidden'
                }}
              />

              {isEditable ? (
                <EditIcon
                  data-test-id="edit-customer-profile-button"
                  color={lightSlate}
                  hoverColor={darkSmoke}
                  style={{cursor: 'pointer'}}
                  onClick={doCustomerProfileEditOpen}
                />
              ) : null}
            </div>

            <div data-test-id="info" style={{padding: `${spacing.medium} ${spacing.mlarge}`}}>
              {enableSurveys && !participantLoading && !businessSurveyLoading ? (
                <div data-test-id="surveys">
                  <h3
                    data-test-id="title"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: fontSize.xlarge,
                      fontWeight: fontWeight.semiBold,
                      marginBottom: '15px'
                    }}
                  >
                    <SurveyIcon style={{marginRight: '5px'}} />{' '}
                    {i18n.t('customerThread.customerProfile.surveys.title')}
                  </h3>

                  {businessSurveyActivated ? null : this._renderActivateSurveyElement()}

                  {participantNps || participantPreferredChannel
                    ? this._renderSurveySummaryInformation()
                    : this._renderNoSurveyInProgress()}
                </div>
              ) : null}
            </div>
          </FloatPanel>
        )}
      </div>
    );
  }
}

LegacyCustomerProfileComponent.contextTypes = context;

LegacyCustomerProfileComponent.propTypes = {
  businessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  businessSurveyActivated: PropTypes.bool.isRequired,
  businessSurveyLoading: PropTypes.bool,
  enableSurveys: PropTypes.bool.isRequired,
  isAdminOfBusiness: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  participantCompany: PropTypes.string,
  participantEmail: PropTypes.string,
  participantFirstName: PropTypes.string,
  participantIcon: PropTypes.string,
  participantIconIsUploading: PropTypes.bool.isRequired,
  participantId: PropTypes.string,
  participantLastName: PropTypes.string,
  participantLoading: PropTypes.bool.isRequired,
  participantName: PropTypes.string,
  participantNps: PropTypes.objectOf(PropTypes.any),
  participantPhone: PropTypes.string,
  participantPreferredChannel: PropTypes.objectOf(PropTypes.any),
  participationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCustomerProfile: PropTypes.bool.isRequired,
  showEditCustomerProfile: PropTypes.bool.isRequired,
  doCustomerProfileClose: PropTypes.func.isRequired,
  doCustomerProfileEditClose: PropTypes.func.isRequired,
  doCustomerProfileEditOpen: PropTypes.func.isRequired,
  doCustomerProfileEditTextUpdate: PropTypes.func.isRequired,
  doCustomerProfilePictureUpload: PropTypes.func.isRequired
};

export default LegacyCustomerProfileComponent;
