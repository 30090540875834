import PropTypes from 'prop-types';
import {fontSize} from '../../../../../shared/style/variables';

const ThreadChannel = ({className, color, i18n, showLabel = false, style, type}) => {
  const ICON_WIDTH = 20;
  const LABEL_MARGIN = 5;

  if (!type) {
    // reserve space to avoid clipping
    return (
      <span
        style={{
          width: `${ICON_WIDTH + LABEL_MARGIN}px`
        }}
      />
    );
  }

  const typeCode = type.toLowerCase();

  return (
    <span
      className={className}
      data-test-id="channel"
      title={typeCode}
      style={{
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
    >
      <img
        alt=""
        data-test-id={typeCode}
        src={`${ASSETS_PATH}/img/icon/channel/${type}.svg`}
        style={{
          width: `${ICON_WIDTH}px`
        }}
      />
      {showLabel ? (
        <span
          style={{
            marginLeft: `${LABEL_MARGIN}px`,
            fontSize: fontSize.small,
            color
          }}
        >
          {i18n.t(`customerInbox.threadList.customerChannels.${type}`)}
        </span>
      ) : null}
    </span>
  );
};

ThreadChannel.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  showLabel: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string
};

export default ThreadChannel;
