import React, {Component} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import formatInsightDuration from '../lib/format-insight-duration';
import updatePanelContentLayoutScroll from '../lib/insight-panel-layout-helper';

class InsightPageComponent extends Component {
  constructor(props) {
    super(props);

    this.wrapperPanelContentRef = React.createRef();
    this.state = {
      wrapperPanelContentScrollPosition: 0
    };
  }

  componentDidMount() {
    const {doInsightPageOpen} = this.props;
    doInsightPageOpen();
  }

  componentDidUpdate() {
    const {wrapperPanelContentScrollPosition} = this.state;
    if (wrapperPanelContentScrollPosition > 0) {
      this.wrapperPanelContentRef.current.scrollTo(0, wrapperPanelContentScrollPosition);
    }
  }

  _doInsightDownloadCsv = () => {
    const {
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      doInsightDownloadCsv
    } = this.props;
    doInsightDownloadCsv(classicAggregationDate, classicAggregationPeriod, businessesSelection);
  };

  _doInsightSortButtonClick = () => {
    const {doInsightSortButtonClick} = this.props;
    doInsightSortButtonClick();
  };

  _formatInsightDurationFromSec = (value) => {
    const {i18n} = this.context;

    return formatInsightDuration(value * 60 * 1000, i18n);
  };

  _handleScroll = (event) => {
    this.setState({
      wrapperPanelContentScrollPosition: updatePanelContentLayoutScroll(event)
    });
  };

  _onSectionChangeKeyword = (section) => (event) => {
    const {doInsightSectionKeywordChange} = this.props;
    doInsightSectionKeywordChange(section, event.target.value);
  };

  _onSectionChangeResultPage = (section) => (event, page) => {
    const {doInsightSectionResultPageChange} = this.props;
    doInsightSectionResultPageChange(section, page);
  };

  _onSectionChangeRowsPerPage = (section) => (event) => {
    const {doInsightSectionRowsPerPageChange} = this.props;
    doInsightSectionRowsPerPageChange(section, event.target.value);
  };
}

InsightPageComponent.contextTypes = context;

InsightPageComponent.propTypes = {
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doInsightSectionKeywordChange: PropTypes.func.isRequired,
  doInsightSectionResultPageChange: PropTypes.func.isRequired,
  doInsightSectionRowsPerPageChange: PropTypes.func.isRequired,
  doInsightSortButtonClick: PropTypes.func.isRequired
};

export default InsightPageComponent;
