import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import {fontSize, fontWeight} from '../../../../../shared/style/variables';
import {black} from '../../../../../shared/style/colors';
import {PADDING_THREAD} from '../../../data/settings';

const StatusUpdateRowComponent = ({account, employee, status}, {i18n}) => {
  const employeeDisplayName = employee ? employee.displayName : null;
  const isMe = employee && account.id === employee.id;

  const statusMetadataMessage = {
    WAITING: isMe
      ? i18n.t('customerThread.visibleThread.manualMetaData.waiting.ownEmployeeLabel')
      : i18n.t('customerThread.visibleThread.manualMetaData.waiting.externalEmployeeLabel', {
          employee: employeeDisplayName
        }),
    REPLIED: isMe
      ? i18n.t('customerThread.visibleThread.manualMetaData.replied.ownEmployeeLabel')
      : i18n.t('customerThread.visibleThread.manualMetaData.replied.externalEmployeeLabel', {
          employee: employeeDisplayName
        }),
    RESOLVED: isMe
      ? i18n.t('customerThread.visibleThread.manualMetaData.resolved.ownEmployeeLabel')
      : i18n.t('customerThread.visibleThread.manualMetaData.resolved.externalEmployeeLabel', {
          employee: employeeDisplayName
        })
  }[status];

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        padding: PADDING_THREAD,
        lineHeight: '16px',
        fontSize: fontSize.small,
        fontWeight: fontWeight.semiBold,
        color: black
      }}
    >
      <div data-test-id="item-label">{statusMetadataMessage}</div>
    </div>
  );
};

StatusUpdateRowComponent.contextTypes = context;

StatusUpdateRowComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  employee: PropTypes.objectOf(PropTypes.any), // Should be required, but some old data may not contain this info
  status: PropTypes.string.isRequired
};

export default StatusUpdateRowComponent;
