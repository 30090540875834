import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import CustomerProfileComponent from '../../../../component/content/customer/thread/profile/legacy-customer-profile-component';
import isManagerOfBusiness from '../../../../lib/is-manager-of-business';
import {
  LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_CLOSE,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_OPEN,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE
} from '../../../../actions/customer-thread-profile-actions';

const LegacyCustomerProfileContainer = connect(
  createSelector(
    [
      (state) => state.get('account'),
      (state) => state.get('customer'),
      (state) => state.get('legacyCustomerProfile'),
      (state) => state.get('legacyCustomerThread'),
      (state) => state.get('featureToggle'),
      (state) => state.get('ui')
    ],
    (account, customer, legacyCustomerProfile, legacyCustomerThread, featureToggle, ui) => {
      return {
        businessId: legacyCustomerThread.get('directedToBusinessIdentifier'),
        businessSurveyActivated:
          customer.getIn(['thread', 'survey', 'businessSurveyConfiguration', 'nps', 'enabled']) ||
          customer.getIn([
            'thread',
            'survey',
            'businessSurveyConfiguration',
            'preferredChannel',
            'enabled'
          ]) ||
          false,
        businessSurveyLoading: customer.getIn(['thread', 'survey', 'loading']),
        enableSurveys: featureToggle.get('survey'),
        isAdminOfBusiness:
          account.get('manager') ||
          isManagerOfBusiness(
            legacyCustomerThread.get('directedToBusinessIdentifier'),
            account.get('businesses').toJS()
          ),
        isSmallScreen: ui.get('isSmallScreen'),
        participantCompany: legacyCustomerProfile.get('participantCompany'),
        participantEmail: legacyCustomerProfile.get('participantEmail'),
        participantFirstName: legacyCustomerProfile.get('participantFirstName'),
        participantIcon: legacyCustomerProfile.get('participantIcon'),
        participantIconIsUploading: legacyCustomerProfile.get('participantIconIsUploading'),
        participantId: legacyCustomerThread.get('participantId'),
        participantLastName: legacyCustomerProfile.get('participantLastName'),
        participantLoading: legacyCustomerProfile.get('loading'),
        participantName: legacyCustomerThread.get('participantName'),
        participantNps: legacyCustomerProfile.get('participantNps'),
        participantPhone: legacyCustomerProfile.get('participantPhone'),
        participantPreferredChannel: legacyCustomerProfile.get('participantPreferredChannel'),
        participationId: legacyCustomerThread.get('participationId'),
        readOnly: legacyCustomerThread.get('readOnly'),
        showCustomerProfile: legacyCustomerProfile.get('showCustomerProfile'),
        showEditCustomerProfile: legacyCustomerProfile.get('showEditCustomerProfile')
      };
    }
  ),
  {
    doCustomerProfileClose() {
      return {
        type: LEGACY_CUSTOMER_THREAD_PROFILE_CLOSE,
        _googleAnalytics: true
      };
    },
    doCustomerProfileEditOpen() {
      return {
        type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_OPEN,
        _googleAnalytics: true
      };
    },
    doCustomerProfileEditClose() {
      return {
        type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_CLOSE,
        _googleAnalytics: true
      };
    },
    doCustomerProfileEditTextUpdate(
      participationId,
      customerId,
      participantName,
      firstName,
      lastName,
      company
    ) {
      return {
        type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE,
        _googleAnalytics: true,
        company,
        customerId,
        firstName,
        lastName,
        participantName,
        participationId
      };
    },
    doCustomerProfilePictureUpload(participationId, customerId, file) {
      return {
        type: LEGACY_CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
        _googleAnalytics: true,
        customerId,
        file,
        participationId
      };
    }
  }
)(CustomerProfileComponent);

export default LegacyCustomerProfileContainer;
