import appStore from '../domain/app/app-store';
import changePasswordStore from '../domain/change-password/change-password-store';
import forgottenPasswordStore from '../domain/forgotten-password/forgotten-password-store';
import signInStore from '../domain/signin/signin-store';
import signUpStore from '../domain/signup/signup-store';
import unsubscribeStore from '../domain/unsubscribe/unsubscribe-store';
import verifyStore from '../domain/verify/verify-store';

export default {
  app: appStore,
  'change-password': changePasswordStore,
  'forgotten-password': forgottenPasswordStore,
  signin: signInStore,
  signup: signUpStore,
  unsubscribe: unsubscribeStore,
  verify: verifyStore
};
