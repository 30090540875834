import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  CUSTOMER_INBOX_BUSINESS_FILTER_CLOSE,
  CUSTOMER_INBOX_BUSINESS_FILTER_OPEN,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_FAILURE,
  CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_SUCCESS,
  CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS
} from '../../../../actions/customer-inbox-actions';

export const DEFAULT_STATE = {
  isVisiblePopup: false,
  loading: false,
  text: '',
  items: []
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Open the business filter popup
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_BUSINESS_FILTER_OPEN]: (state) => {
    return state.set('isVisiblePopup', true);
  },

  /**
   * Close the business filter popup
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_BUSINESS_FILTER_CLOSE]: (state) => {
    return state.set('isVisiblePopup', false);
  },

  /**
   * Start the search for business filter
   *
   * @param {Object} state
   * @param {String} text
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES]: (state, {text}) => {
    return state.set('items', fromJS([])).set('loading', true).set('text', text);
  },

  /**
   * Successful finish of the search for business filter
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_SUCCESS]: (state, {data}) => {
    return state.set('items', fromJS(data.items)).set('loading', false);
  },

  /**
   * Failed finish of the search for business filter
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_BUSINESS_FILTER_SEARCH_BROWSABLE_BUSINESSES_FAILURE]: (state) => {
    return state.set('loading', false);
  },

  /**
   * Close popup when loading inbox filtered by business
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [CUSTOMER_INBOX_THREADS_LIST_LOAD_FILTERED_BY_BUSINESS]: (state) => {
    return state.set('isVisiblePopup', false);
  }
});
