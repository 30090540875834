import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {shouldShowTutorial, TUTORIALS} from '../../../../data/tutorial/tutorial-settings';
import getSidebarWidth from '../../../common/script/get-sidebar-width';
import ContentLayout from '../../../common/layout/content-layout-component';
import LegacyCustomerInvisibleThread from './legacy-invisible-thread-component';
import LegacyCustomerProfile from '../../../../container/content/customer/profile/legacy-customer-profile-container';
import LegacyCustomerThreadHeader from './legacy-thread-header-component';
import LegacyCustomerVisibleThread from './legacy-visible-thread-component';
import RightPanelColumnWrapper from '../../../common/layout/right-panel-column-wrapper-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import LegacyCustomerThreadSidebar from './legacy-thread-sidebar-component';
import {lightSmoke, snow} from '../../../../../../shared/style/colors';

class LegacyCustomerThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tutorialsChecked: {},
      whatsappPermissionFooterRenderedAtLeastOnce: false
    };

    this._changeVisibility = this._changeVisibility.bind(this);
    this._onTouchInvisibleThread = this._onTouchInvisibleThread.bind(this);
    this._onTouchVisibleThread = this._onTouchVisibleThread.bind(this);
  }

  componentDidMount() {
    /* eslint-disable react/destructuring-assignment */
    this.props.doThreadFocusToType(this.props.isSmallScreen ? this.props.threadType : 'visible');

    // It's necessary to test right after mount as in case of page refresh the mount is done with prop customerChannel already set
    this.checkAndSortTutorials({});
    /* eslint-enable react/destructuring-assignment */
  }

  componentDidUpdate(prevProps, prevState) {
    const {doCustomerThreadChanged, participationId} = this.props;

    if (participationId !== prevProps.participationId) {
      doCustomerThreadChanged(
        prevProps.directedToBusinessIdentifier,
        prevProps.participantId,
        prevProps.status
      );
    }

    this.checkAndSortTutorials(prevProps, prevState);
  }

  componentWillUnmount() {
    const {directedToBusinessIdentifier, participantId, status, doThreadPageLeave} = this.props;
    doThreadPageLeave(directedToBusinessIdentifier, participantId, status);
  }

  checkAndSortTutorials = (oldProperties, oldState) => {
    const {
      customerChannel,
      doRegisterTutorial,
      enableUIMessengerChannelFeature,
      enableUIWhatsAppChannelFeature
    } = this.props;

    if (!customerChannel) {
      return;
    }

    const {tutorialsChecked, whatsappPermissionFooterRenderedAtLeastOnce} = this.state;

    const markTutorialAsCheckedInState = (tutorialKey) =>
      this.setState({
        tutorialsChecked: {
          ...tutorialsChecked,
          [tutorialKey]: {
            ...tutorialsChecked[tutorialKey],
            [customerChannel]: true
          }
        }
      });

    // Sort next array by expected tutorials order
    [
      {
        condition: customerChannel !== oldProperties.customerChannel,
        configuration: TUTORIALS.customerThreadHeaderChannel,
        registerParam: {customerChannel}
      },
      {
        condition:
          whatsappPermissionFooterRenderedAtLeastOnce &&
          !oldState.whatsappPermissionFooterRenderedAtLeastOnce,
        configuration: TUTORIALS.customerThreadFooterWhatsappPermission
      }
    ]
      .filter(
        ({condition, key}) =>
          condition && (!tutorialsChecked[key] || !tutorialsChecked[key][customerChannel])
      )
      .forEach(({configuration: {key}, registerParam}) => {
        if (
          shouldShowTutorial({
            customerChannel,
            enableUIMessengerChannelFeature,
            enableUIWhatsAppChannelFeature,
            key
          })
        ) {
          doRegisterTutorial(key, registerParam);
        }

        markTutorialAsCheckedInState(key);
      });
  };

  onWhatsappPermissionFooterConfirmRendered = () => {
    this.setState({
      whatsappPermissionFooterRenderedAtLeastOnce: true
    });
  };

  _changeVisibility(threadType) {
    const {participationId, doThreadSwitchVisibilityToType} = this.props;
    doThreadSwitchVisibilityToType(participationId, threadType);
  }

  _onTouchVisibleThread() {
    const {isSmallScreen, doThreadFocusToType} = this.props;
    if (isSmallScreen) {
      return false;
    }

    doThreadFocusToType('visible');
  }

  _onTouchInvisibleThread() {
    const {isSmallScreen, doThreadFocusToType} = this.props;
    if (isSmallScreen) {
      return false;
    }

    doThreadFocusToType('invisible');
  }

  render() {
    const {
      // shared
      customerChannel,
      directedToBusinessIdentifier,
      directedToBusinessName,
      disableThreadInputPersistence,
      enableUIWhatsAppChannelFeature,
      fullName,
      isHistoryAction,
      isNewThread,
      isSmallScreen,
      isSmsPhoneNumber,
      loading,
      newThreadEmail,
      newThreadPhoneNumber,
      organizationId,
      organizationPicture,
      participationId,
      picture,
      readOnly,
      startNewThreadByUnknownContact,
      status,
      threadType,
      unreadInternalMessagesCount,
      unreadMessagesCount,
      updatingStatus,
      userId,
      whatsappTemplates,
      windowExpiration,
      doThreadFilePreviewOpen,
      doUpdateManuallyThreadStatus,
      onWhatsappTemplateClick,
      // visible thread
      cannedResponses,
      enablePaymentFeature,
      visibleProps,
      doAppPaymentCancelButtonClick,
      doAppPaymentMaximizeButtonClick,
      doAppPaymentMinimizeButtonClick,
      doAppPaymentStepperBackButtonClick,
      doAppPaymentStepperNextButtonClick,
      doAppsAreaMenuButtonClick,
      doAppsAreaMinimize,
      doAppsMenuSelectPayment,
      doReleaseCustomerThread,
      doSendMessage,
      onAppPaymentAmountChange,
      onAppPaymentCustomChange,
      onAppPaymentCustomerChange,
      onConversationNewConversationFormOpenLinkClick,
      onThreadTakeButtonClick,
      doThreadVisibleFileRemove,
      onThreadVisibleFileSelect,
      onThreadVisibleFileSelectedExceedMaxSizeClose,
      doThreadVisibleMessageFormTextUpdate,
      doThreadVisibleNewSendMessage,
      doThreadVisiblePaginate,
      doThreadVisibleSendMessageRetry,
      onWhatsappTemplatesLoadButtonClick,
      // invisible thread
      invisibleProps,
      doColleagueInvitationPopupOpen,
      doThreadInvisibleFileRemove,
      doThreadInvisibleFileUpload,
      doThreadInvisibleLoad,
      doThreadInvisibleMentionPopupClose,
      doThreadInvisibleMentionPopupOpen,
      doThreadInvisibleMentionPopupSearch,
      doThreadInvisibleMentionPopupSelect,
      doThreadInvisibleMentionUnselect,
      doThreadInvisibleMessageFormTextUpdate,
      doThreadInvisibleSendMessage,
      doThreadInvisibleSendMessageRetry,
      // customer profile
      enableCustomerProfile,
      participantName,
      showCustomerProfile,
      doCustomerProfileShow,
      // transfer
      transferRecentlySuccessful,
      transferTransferring,
      doTransferPopupToggle
    } = this.props;

    const currentSection = isSmallScreen && threadType === 'visible';

    const customerProfileArea = (
      <LegacyCustomerProfile
        style={
          isSmallScreen
            ? {
                paddingRight: getSidebarWidth(),
                borderLeft: 0
              }
            : {
                borderLeft: currentSection ? 0 : `1px solid ${snow}`
              }
        }
      />
    );

    return (
      <RightPanelContentLayout dataTestId="customer-thread">
        <LegacyCustomerThreadHeader
          transferShowLabel={!isSmallScreen}
          {...{
            customerChannel,
            directedToBusinessName,
            fullName,
            isNewThread,
            isSmsPhoneNumber,
            loading,
            organizationPicture,
            participantName,
            participationId,
            readOnly,
            status,
            transferRecentlySuccessful,
            transferTransferring,
            updatingStatus,
            userId,
            doTransferPopupToggle,
            doUpdateManuallyThreadStatus
          }}
        />

        <ContentLayout
          style={{flexDirection: isSmallScreen && showCustomerProfile ? 'column' : null}}
        >
          {isSmallScreen && enableCustomerProfile ? customerProfileArea : null}

          {!isSmallScreen || threadType === 'visible' ? (
            <RightPanelColumnWrapper
              onClick={this._onTouchVisibleThread}
              style={{
                display: 'flex',
                flex: 1,
                flexGrow: !isSmallScreen ? 1.4 : 1,
                minHeight: isSmallScreen ? '100%' : null,
                marginRight: getSidebarWidth(isSmallScreen)
              }}
            >
              <LegacyCustomerVisibleThread
                readOnly={readOnly}
                setInputHeight={this._setInputHeight}
                doUpdateMessage={doThreadVisibleMessageFormTextUpdate}
                doWhatsappPermissionFooterConfirmRendered={
                  this.onWhatsappPermissionFooterConfirmRendered
                }
                {...visibleProps}
                {...{
                  cannedResponses,
                  currentSection,
                  customerChannel,
                  directedToBusinessIdentifier,
                  directedToBusinessName,
                  disableThreadInputPersistence,
                  enablePaymentFeature,
                  enableUIWhatsAppChannelFeature,
                  fullName,
                  isHistoryAction,
                  isNewThread,
                  newThreadEmail,
                  newThreadPhoneNumber,
                  organizationId,
                  organizationPicture,
                  participationId,
                  picture,
                  startNewThreadByUnknownContact,
                  status,
                  userId,
                  whatsappTemplates,
                  windowExpiration,
                  doAppPaymentCancelButtonClick,
                  doAppPaymentMaximizeButtonClick,
                  doAppPaymentMinimizeButtonClick,
                  doAppPaymentStepperBackButtonClick,
                  doAppPaymentStepperNextButtonClick,
                  doAppsAreaMenuButtonClick,
                  doAppsAreaMinimize,
                  doAppsMenuSelectPayment,
                  doReleaseCustomerThread,
                  doSendMessage,
                  doThreadFilePreviewOpen,
                  onThreadTakeButtonClick,
                  doThreadVisibleFileRemove,
                  doThreadVisibleNewSendMessage,
                  doThreadVisiblePaginate,
                  doThreadVisibleSendMessageRetry,
                  onAppPaymentAmountChange,
                  onAppPaymentCustomChange,
                  onAppPaymentCustomerChange,
                  onConversationNewConversationFormOpenLinkClick,
                  onThreadVisibleFileSelect,
                  onThreadVisibleFileSelectedExceedMaxSizeClose,
                  onWhatsappTemplateClick,
                  onWhatsappTemplatesLoadButtonClick
                }}
              />
            </RightPanelColumnWrapper>
          ) : null}

          {!isSmallScreen || threadType === 'invisible' ? (
            <RightPanelColumnWrapper
              onClick={this._onTouchInvisibleThread}
              style={{
                display: 'flex',
                flex: 1,
                minHeight: '100%',
                minWidth: 0, // this enforce the truncation on child components
                marginRight: getSidebarWidth(isSmallScreen)
              }}
            >
              <LegacyCustomerInvisibleThread
                currentSection={isSmallScreen && threadType === 'invisible'}
                customerProfileArea={
                  !isSmallScreen && enableCustomerProfile ? customerProfileArea : null
                }
                readOnly={readOnly || isNewThread}
                doSendMessage={doThreadInvisibleSendMessage}
                doUpdateMessage={doThreadInvisibleMessageFormTextUpdate}
                doUploadFile={doThreadInvisibleFileUpload}
                {...invisibleProps}
                {...{
                  directedToBusinessIdentifier,
                  directedToBusinessName,
                  disableThreadInputPersistence,
                  enableCustomerProfile,
                  fullName,
                  isHistoryAction,
                  isSmallScreen,
                  organizationId,
                  organizationPicture,
                  participantName,
                  participationId,
                  picture,
                  showCustomerProfile,
                  userId,
                  doColleagueInvitationPopupOpen,
                  doCustomerProfileShow,
                  doThreadFilePreviewOpen,
                  doThreadInvisibleFileRemove,
                  doThreadInvisibleLoad,
                  doThreadInvisibleMentionPopupClose,
                  doThreadInvisibleMentionPopupOpen,
                  doThreadInvisibleMentionPopupSearch,
                  doThreadInvisibleMentionPopupSelect,
                  doThreadInvisibleMentionUnselect,
                  doThreadInvisibleSendMessageRetry
                }}
              />
            </RightPanelColumnWrapper>
          ) : null}

          {isSmallScreen ? (
            <div
              data-test-id="thread-sidebar"
              style={{
                width: getSidebarWidth(),
                borderLeft: `1px solid ${lightSmoke}`,
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 100,
                height: '100%',
                background: 'white'
              }}
            >
              <LegacyCustomerThreadSidebar
                changeVisibility={this._changeVisibility}
                {...{
                  enableCustomerProfile,
                  isNewThread,
                  showCustomerProfile,
                  threadType,
                  unreadInternalMessagesCount,
                  unreadMessagesCount,
                  doCustomerProfileShow
                }}
              />
            </div>
          ) : null}
        </ContentLayout>
      </RightPanelContentLayout>
    );
  }
}

LegacyCustomerThreadComponent.propTypes = {
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  customerChannel: PropTypes.string,
  directedToBusinessIdentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  directedToBusinessName: PropTypes.string,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enableCustomerProfile: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  enableUIMessengerChannelFeature: PropTypes.bool.isRequired,
  enableUIWhatsAppChannelFeature: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  invisibleProps: PropTypes.objectOf(PropTypes.any).isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  isSmsPhoneNumber: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  newThreadEmail: PropTypes.string,
  newThreadPhoneNumber: PropTypes.string,
  organizationId: PropTypes.number.isRequired,
  organizationPicture: PropTypes.string,
  participantId: PropTypes.string,
  participantName: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  picture: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  showCustomerProfile: PropTypes.bool.isRequired,
  startNewThreadByUnknownContact: PropTypes.string,
  status: PropTypes.string,
  threadType: PropTypes.string.isRequired,
  transferRecentlySuccessful: PropTypes.bool.isRequired,
  transferTransferring: PropTypes.bool.isRequired,
  unreadInternalMessagesCount: PropTypes.number,
  unreadMessagesCount: PropTypes.number,
  updatingStatus: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  visibleProps: PropTypes.objectOf(PropTypes.any).isRequired,
  whatsappTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowExpiration: PropTypes.string,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsAreaMenuButtonClick: PropTypes.func.isRequired,
  doAppsAreaMinimize: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doCustomerProfileShow: PropTypes.func.isRequired,
  doCustomerThreadChanged: PropTypes.func.isRequired,
  doRegisterTutorial: PropTypes.func.isRequired,
  doReleaseCustomerThread: PropTypes.func.isRequired,
  doSendMessage: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  doThreadFocusToType: PropTypes.func.isRequired,
  doThreadInvisibleFileRemove: PropTypes.func.isRequired,
  doThreadInvisibleFileUpload: PropTypes.func.isRequired,
  doThreadInvisibleLoad: PropTypes.func.isRequired,
  doThreadInvisibleMessageFormTextUpdate: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupClose: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupOpen: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupSearch: PropTypes.func.isRequired,
  doThreadInvisibleMentionPopupSelect: PropTypes.func.isRequired,
  doThreadInvisibleMentionUnselect: PropTypes.func.isRequired,
  doThreadInvisibleSendMessage: PropTypes.func.isRequired,
  doThreadInvisibleSendMessageRetry: PropTypes.func.isRequired,
  doThreadPageLeave: PropTypes.func.isRequired,
  doThreadSwitchVisibilityToType: PropTypes.func.isRequired,
  onThreadTakeButtonClick: PropTypes.func.isRequired,
  doThreadVisibleFileRemove: PropTypes.func.isRequired,
  doThreadVisibleMessageFormTextUpdate: PropTypes.func.isRequired,
  doThreadVisibleNewSendMessage: PropTypes.func.isRequired,
  doThreadVisiblePaginate: PropTypes.func.isRequired,
  doThreadVisibleSendMessageRetry: PropTypes.func.isRequired,
  doTransferPopupToggle: PropTypes.func.isRequired,
  doUpdateManuallyThreadStatus: PropTypes.func.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onConversationNewConversationFormOpenLinkClick: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired,
  onThreadVisibleFileSelectedExceedMaxSizeClose: PropTypes.func.isRequired,
  onWhatsappTemplateClick: PropTypes.func.isRequired,
  onWhatsappTemplatesLoadButtonClick: PropTypes.func.isRequired
};

export default LegacyCustomerThreadComponent;
