import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import ColleagueThreadComponent from '../../../../component/content/colleague/thread/colleague-thread-component';
import getSecuredThreadsForm from '../../../../lib/get-secured-threads-form';
import messageSelector from '../../../../selector/message-selector';
import threadContainerSetup from '../../../../lib/thread-container-setup';
import {
  COLLEAGUE_NEW_THREAD_SEND_MESSAGE,
  COLLEAGUE_THREAD_FILE_REMOVE,
  COLLEAGUE_THREAD_FILE_UPLOAD,
  COLLEAGUE_THREAD_LEAVE,
  COLLEAGUE_THREAD_LOAD,
  COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_UPDATE,
  COLLEAGUE_THREAD_SEND_MESSAGE,
  COLLEAGUE_THREAD_SEND_MESSAGE_RETRY
} from '../../../../actions/colleague-thread-actions';
import {APP_THREAD_FILE_PREVIEW_OPEN} from '../../../../actions/thread-actions';

const ColleagueThreadContainer = connect(
  createSelector(
    [
      (state, router) => router,
      (state) => state.get('account'),
      (state) => state.get('colleagueThread'),
      (state) => state.get('featureToggle')
    ],
    (router, account, colleagueThread, featureToggle) => {
      const {isHistoryAction, isNewThread, participationId} = threadContainerSetup(router);

      return {
        isHistoryAction,
        isNewThread,
        participationId,
        direction: colleagueThread.get('direction'),
        disableThreadInputPersistence: featureToggle.get(
          'desktop_thread_input_persistence_disabled'
        ),
        fullName: account.get('fullName'),
        hasMoreMessagesBefore: colleagueThread.get('hasMoreMessagesBefore'),
        latestMessage: colleagueThread.getIn(['messages', -1, 'id']),
        loading: colleagueThread.get('loading'),
        loadingBefore: colleagueThread.get('loadingBefore'),
        messages: messageSelector(colleagueThread),
        oldestMessage: colleagueThread.getIn(['messages', 0, 'id']),
        participantId: colleagueThread.get('participantId'),
        participantName: colleagueThread.get('participantName'),
        picture: account.get('picture'),
        sending: colleagueThread.get('sending'),
        threadForm: getSecuredThreadsForm(colleagueThread, participationId),
        uploadingFile: colleagueThread.get('uploadingFile'),
        userId: account.get('id')
      };
    }
  ),
  {
    doLeaveColleagueThread() {
      return {
        type: COLLEAGUE_THREAD_LEAVE
      };
    },
    // Messages
    doLoadColleagueThread(participationId, fromId, direction) {
      return {
        type: COLLEAGUE_THREAD_LOAD,
        direction,
        fromId,
        participationId
      };
    },
    doThreadFilePreviewOpen(src) {
      return {
        type: APP_THREAD_FILE_PREVIEW_OPEN,
        _googleAnalytics: true,
        src
      };
    },
    // Message input
    doUploadFile(file) {
      return {
        type: COLLEAGUE_THREAD_FILE_UPLOAD,
        _googleAnalytics: true,
        file
      };
    },
    doRemoveFile() {
      return {
        type: COLLEAGUE_THREAD_FILE_REMOVE,
        _googleAnalytics: true
      };
    },
    doSendMessage({attachment, clientMessageId, fullName, picture, text, userId}) {
      return {
        type: COLLEAGUE_THREAD_SEND_MESSAGE,
        _googleAnalytics: true,
        attachment,
        clientMessageId,
        fullName,
        picture,
        text,
        userId
      };
    },
    doSendMessageRetry(failedAction) {
      return {
        type: COLLEAGUE_THREAD_SEND_MESSAGE_RETRY,
        _googleAnalytics: true,
        failedAction
      };
    },
    doSendMessageInNewThread({
      attachment,
      clientMessageId,
      colleagueId,
      colleagueName,
      fullName,
      picture,
      text,
      userId
    }) {
      return {
        type: COLLEAGUE_NEW_THREAD_SEND_MESSAGE,
        _googleAnalytics: true,
        attachment,
        clientMessageId,
        colleagueId,
        colleagueName,
        fullName,
        picture,
        text,
        userId
      };
    },
    doUpdateMessage(participationId, text) {
      return {
        type: COLLEAGUE_THREAD_MESSAGE_FORM_TEXT_UPDATE,
        payload: {
          participationId,
          text
        }
      };
    }
  }
)(ColleagueThreadComponent);

export default ColleagueThreadContainer;
