import {PureComponent} from 'react';
import PropTypes from 'prop-types';

class FormMessageComponent extends PureComponent {
  render() {
    const {dataTestId, keepHtml, label, style} = this.props;

    const commonAttributes = {
      'data-test-id': dataTestId,
      style: {
        textAlign: 'center',
        ...style
      }
    };

    if (keepHtml) {
      return <div {...commonAttributes} dangerouslySetInnerHTML={{__html: label}} />;
    }

    return <div {...commonAttributes}>{label}</div>;
  }
}

FormMessageComponent.propTypes = {
  dataTestId: PropTypes.string,
  keepHtml: PropTypes.bool,
  label: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default FormMessageComponent;
