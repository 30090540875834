import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';
import themeV1 from '../../../../../../shared/style/theme-v1';
import {StyledTab, StyledTabs} from '../../../../../../shared/style/material-ui/tab-styles';
import {
  getBusinessTabsOptions,
  getIndexTabByPageName
} from '../lib/administration-business-tabs-options';

const AdministrationBusinessTabsMenuComponent = ({
  businessBotConfiguration,
  businessId,
  businessPageName,
  enableBotAPI,
  enableBusinessMessages,
  enableChannelAdmin,
  enablePaymentFeature,
  enableSurvey,
  i18n,
  isManagerOfCurrentBusiness
}) => {
  const [tabSelected, setTabSelected] = useState(false);

  const businessTabsOptions = getBusinessTabsOptions({
    businessId,
    enableBotAPI,
    enableBusinessMessages,
    enableChannelAdmin,
    enablePaymentFeature,
    enableSurvey,
    i18n,
    isManagerOfCurrentBusiness
  })
    .filter(({canShowTabOption}) => canShowTabOption)
    .map(({businessTabDataTestId, businessTabOptionName, url}) => ({
      businessTabDataTestId,
      businessTabOptionName,
      url
    }));

  useEffect(() => {
    const indexTabByPageName = getIndexTabByPageName({
      businessPageName,
      businessTabsOptions
    });

    setTabSelected(indexTabByPageName);
  }, [businessBotConfiguration]);

  const onBusinessTabSelected = (event, newValue) => {
    window.location = businessTabsOptions[newValue].url;
  };

  return (
    <MuiThemeProvider theme={themeV1}>
      <StyledTabs value={tabSelected} onChange={onBusinessTabSelected}>
        {businessTabsOptions.map(({businessTabDataTestId, businessTabOptionName}) => (
          <StyledTab
            key={businessTabDataTestId}
            data-test-id={businessTabDataTestId}
            disableRipple
            label={businessTabOptionName}
          />
        ))}
      </StyledTabs>
    </MuiThemeProvider>
  );
};

AdministrationBusinessTabsMenuComponent.propTypes = {
  businessBotConfiguration: PropTypes.objectOf(PropTypes.any),
  businessId: PropTypes.string.isRequired,
  businessPageName: PropTypes.string.isRequired,
  enableBotAPI: PropTypes.bool.isRequired,
  enableBusinessMessages: PropTypes.bool.isRequired,
  enableChannelAdmin: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  enableSurvey: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isManagerOfCurrentBusiness: PropTypes.bool.isRequired
};

export default AdministrationBusinessTabsMenuComponent;
