import pick from 'lodash/pick';
import convertFromMs from './convert-from-ms';
import {
  INSIGHT_CONTACTS_LOAD,
  INSIGHT_CONTACTS_LOAD_SUCCESS,
  INSIGHT_CONTACTS_LOAD_FAILURE,
  INSIGHT_CONNECTED_CUSTOMERS_LOAD,
  INSIGHT_CONNECTED_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_CONNECTED_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_CONVERSATIONS_LOAD,
  INSIGHT_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_CONVERSATIONS_RANKING_LOAD,
  INSIGHT_CONVERSATIONS_RANKING_LOAD_SUCCESS,
  INSIGHT_CONVERSATIONS_RANKING_LOAD_FAILURE,
  INSIGHT_RESPONSE_TIME_LOAD,
  INSIGHT_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_RESPONSE_TIME_RANKING_LOAD,
  INSIGHT_RESPONSE_TIME_RANKING_LOAD_SUCCESS,
  INSIGHT_RESPONSE_TIME_RANKING_LOAD_FAILURE,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_FAILURE,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_SUCCESS,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD
} from '../../../../actions/insight-actions';

const COMMON_SORT_ATTRIBUTES_DEFAULT_VALUES = {
  sortBy: 'ranking'
};

const definitions = {
  contacts: {
    actionTypes: [
      INSIGHT_CONTACTS_LOAD,
      INSIGHT_CONTACTS_LOAD_SUCCESS,
      INSIGHT_CONTACTS_LOAD_FAILURE
    ],
    query: 'contacts { contacts contactsIncrement contactsHistogram { date contacts } }'
  },
  connectedCustomers: {
    actionTypes: [
      INSIGHT_CONNECTED_CUSTOMERS_LOAD,
      INSIGHT_CONNECTED_CUSTOMERS_LOAD_SUCCESS,
      INSIGHT_CONNECTED_CUSTOMERS_LOAD_FAILURE
    ],
    query:
      'connectedCustomers { connectedCustomers connectedCustomersIncrement connectedCustomersHistogram { date connectedCustomers } }'
  },
  conversations: {
    ...COMMON_SORT_ATTRIBUTES_DEFAULT_VALUES,
    actionTypes: [
      INSIGHT_CONVERSATIONS_LOAD,
      INSIGHT_CONVERSATIONS_LOAD_SUCCESS,
      INSIGHT_CONVERSATIONS_LOAD_FAILURE
    ],
    actionRankingTypes: [
      INSIGHT_CONVERSATIONS_RANKING_LOAD,
      INSIGHT_CONVERSATIONS_RANKING_LOAD_SUCCESS,
      INSIGHT_CONVERSATIONS_RANKING_LOAD_FAILURE
    ],
    epicName: 'topConversations',
    query:
      'conversations { conversations conversationsIncrement conversationsOngoing conversationsHistogram { date conversationsOngoing conversationsInbound conversationsOutbound } }'
  },
  responseRate: {
    ...COMMON_SORT_ATTRIBUTES_DEFAULT_VALUES,
    actionTypes: [INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD],
    actionRankingTypes: [
      INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD,
      INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_SUCCESS,
      INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_FAILURE
    ],
    epicName: 'topEmployeeResponseRate',
    query:
      'employeeResponseRateIndicators { employeeResponseRatePercent employeeResponseRateIncrement }' +
      ' employeeResponseRateHistogram { date employeeResponseRate contacts }'
  },
  responseTime: {
    ...COMMON_SORT_ATTRIBUTES_DEFAULT_VALUES,
    actionTypes: [
      INSIGHT_RESPONSE_TIME_LOAD,
      INSIGHT_RESPONSE_TIME_LOAD_SUCCESS,
      INSIGHT_RESPONSE_TIME_LOAD_FAILURE
    ],
    actionRankingTypes: [
      INSIGHT_RESPONSE_TIME_RANKING_LOAD,
      INSIGHT_RESPONSE_TIME_RANKING_LOAD_SUCCESS,
      INSIGHT_RESPONSE_TIME_RANKING_LOAD_FAILURE
    ],
    epicName: 'topAverageResponseTime',
    query:
      'averageResponseTime { averageResponseTime averageResponseTimeIncrement averageResponseTimeHistogram { date averageResponseTime } }' +
      ' returningCustomers { newInboundConversations }',
    onSuccess: ({summaryPageHistogram}) => {
      return {
        type: INSIGHT_RESPONSE_TIME_LOAD_SUCCESS,
        responseTimePageHistogram: {
          // untouched entries
          ...pick(summaryPageHistogram, [
            'returningCustomers' // i.e. newInboundConversations
          ]),
          // transform before assignment some data in more human-readable format
          averageResponseTime: Object.assign(summaryPageHistogram.averageResponseTime, {
            averageResponseTimeHistogram:
              summaryPageHistogram.averageResponseTime.averageResponseTimeHistogram.map((item) =>
                convertFromMs(item, 'averageResponseTime', 60)
              )
          })
        }
      };
    }
  },
  newInboundConversations: {
    actionTypes: [
      INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD,
      INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_FAILURE,
      INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS
    ],
    query:
      'returningCustomers { returningCustomers returningCustomersIncrement newInboundConversations newInboundConversationsIncrement returningCustomersHistogram { date newCustomers returningCustomers } }',
    onSuccess: ({summaryPageHistogram}) => {
      return {
        type: INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS,
        newInboundConversationsPageHistogram: {
          // untouched entries
          ...pick(summaryPageHistogram, [
            'returningCustomers' // i.e. newInboundConversations
          ])
        }
      };
    }
  },
  newOutboundConversations: {
    actionTypes: [
      INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD,
      INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
      INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE
    ],
    query:
      'averageCustomerResponseTime { averageCustomerResponseTime averageCustomerResponseTimeIncrement averageCustomerResponseTimeHistogram { date averageCustomerResponseTime } }' +
      'averageCustomerResponseRate { averageCustomerResponseRate averageCustomerResponseRateIncrement averageCustomerResponseRateHistogram { date averageCustomerResponseRate } }' +
      'newOutboundConversations { newOutboundConversations newOutboundConversationsIncrement newOutboundConversationsHistogram { date newOutboundConversations } }',
    onSuccess: ({summaryPageHistogram}) => {
      return {
        type: INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
        newOutboundConversationsPageHistogram: {
          // untouched entries
          ...pick(summaryPageHistogram, [
            'averageCustomerResponseRate',
            'newOutboundConversations'
          ]),
          // transform before assignment some data in more human-readable format
          averageCustomerResponseTime: Object.assign(
            summaryPageHistogram.averageCustomerResponseTime,
            {
              averageCustomerResponseTimeHistogram:
                summaryPageHistogram.averageCustomerResponseTime.averageCustomerResponseTimeHistogram.map(
                  (item) => convertFromMs(item, 'averageCustomerResponseTime', 60)
                )
            }
          )
        }
      };
    }
  }
};

export default definitions;
