import PropTypes from 'prop-types';
import AdministrationBusinessPageChannelsConnectHeader from '../administration-business-page-channels-connect-header-component';

const AdministrationBusinessPageChannelGbmHeaderItem = ({i18n}) => {
  const icon = `${ASSETS_PATH}/img/icon/channel/GBM.svg`;

  return (
    <AdministrationBusinessPageChannelsConnectHeader
      title={i18n.t('administration.business.pages.channels.gbm.itemTitle')}
      {...{
        icon
      }}
    />
  );
};

AdministrationBusinessPageChannelGbmHeaderItem.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationBusinessPageChannelGbmHeaderItem;
