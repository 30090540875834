import {PureComponent} from 'react';
import {Waypoint} from 'react-waypoint';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

const _throttleHandler = (scrollHandler) => throttle(scrollHandler, 200);

/**
 * Finally, the component.
 */
class WaypointComponent extends PureComponent {
  render() {
    const {loadMore} = this.props;

    return <Waypoint onEnter={loadMore} throttleHandler={_throttleHandler} />;
  }
}

WaypointComponent.propTypes = {
  loadMore: PropTypes.func.isRequired
};

export default WaypointComponent;
