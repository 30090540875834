/**
 * Convert value from millisecond to second by default,
 * with ability to convert to other unit through additional divisor.
 *
 * @param {Object} item
 * @param {String} restrictTo
 * @param {Number} otherDivisor
 * @returns {Object}
 */
export default (item, restrictTo = null, otherDivisor = 1) => {
  const newItem = {...item};

  Object.keys(item).forEach((key) => {
    if (key === 'date') {
      return;
    }

    if (restrictTo && restrictTo !== key) {
      return;
    }

    const newValue = item[key] / 1000 / otherDivisor;

    newItem[key] = Number(newValue.toPrecision(newValue < 100 ? 3 : 4));
  });

  return newItem;
};
