import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import context from '../context';
import themeV1 from '../../style/theme-v1';
import checkPasswordRules from '../../lib/check-password-rules';
import EnhancedTextField from './enhanced-text-field-component';
import {inputStyle} from '../public/public-form-component';
import {spacing} from '../../style/variables';

/**
 * The Password Field base.
 */
const PasswordFieldBase = ({value, otherProps}, {i18n}) => {
  const {label, ...props} = otherProps;

  const text = label || i18n.t('form.password');

  return (
    <EnhancedTextField
      data-test-id="password-input"
      autoComplete="off"
      floatingLabelText={text}
      hintText={text}
      label={text}
      maxLength={30}
      name="password"
      style={inputStyle}
      type="password"
      value={value}
      {...props}
    />
  );
};
PasswordFieldBase.contextTypes = context;

PasswordFieldBase.propTypes = {
  otherProps: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string
};

/**
 * Finally, the component.
 */
const PasswordFieldComponent = ({showRulesCheck, value, ...otherProps}, {i18n}) => {
  if (!showRulesCheck) {
    return <PasswordFieldBase {...{value, otherProps}} />;
  }

  const rulesCheck = checkPasswordRules(value);

  return (
    <div>
      <PasswordFieldBase {...{value, otherProps}} />

      <div style={{marginTop: spacing.medium}}>
        {Object.keys(rulesCheck)
          .filter((key) => key !== 'isValid')
          .map((key) => (
            <div
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <MuiThemeProvider theme={themeV1}>
                {rulesCheck[key] ? (
                  <CheckCircleOutlineIcon color="primary" />
                ) : (
                  <RadioButtonUncheckedIcon color="primary" />
                )}
              </MuiThemeProvider>
              <span style={{marginLeft: spacing.small}}>{i18n.t(`form.passwordRules.${key}`)}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

PasswordFieldComponent.contextTypes = context;

PasswordFieldComponent.propTypes = {
  label: PropTypes.string,
  showRulesCheck: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PasswordFieldComponent;
