import {IconButton} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {boxShadow} from '../../style/variables';
import {white} from '../../style/colors';

export default (style) =>
  withStyles({
    root: {
      backgroundColor: white,
      padding: 0,
      boxShadow: boxShadow.normal,
      ...style
    }
  })(IconButton);
