import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  DEFAULT_STATE_WIDGET_COMMON,
  languageChange,
  snippetChange,
  snippetCopiedChange
} from '../../../../lib/widget-helper';
import {
  INTEGRATION_AREA_WIDGET_MOBILE_BACKGROUND_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_CUSTOM_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_PHONE_NUMBER_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_RESET_STATE,
  INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_TEXT_COLOR_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_WIDGET_COMMON,
  bgColor: '',
  customText: '',
  phoneNumber: '',
  textColor: ''
};

export default createReducer(DEFAULT_STATE, {
  // Common
  [INTEGRATION_AREA_WIDGET_MOBILE_LANGUAGE_CHANGE]: languageChange,

  [INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_CHANGE]: snippetChange,

  [INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE]: snippetCopiedChange,

  // Specific
  [INTEGRATION_AREA_WIDGET_MOBILE_RESET_STATE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  [INTEGRATION_AREA_WIDGET_MOBILE_PHONE_NUMBER_CHANGE]: (state, {phoneNumber}) => {
    return state.set('phoneNumber', phoneNumber);
  },

  [INTEGRATION_AREA_WIDGET_MOBILE_BACKGROUND_COLOR_CHANGE]: (state, {color}) => {
    return state.set('bgColor', color);
  },

  [INTEGRATION_AREA_WIDGET_MOBILE_TEXT_COLOR_CHANGE]: (state, {color}) => {
    return state.set('textColor', color);
  },

  [INTEGRATION_AREA_WIDGET_MOBILE_CUSTOM_TEXT_CHANGE]: (state, {text}) => {
    return state.set('customText', text);
  }
});
