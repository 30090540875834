import PropTypes from 'prop-types';

const FileDownloadIcon = ({style}) => (
  <img alt="" src={`${ASSETS_PATH}/img/icon/FILE-DOWNLOAD.svg`} style={{width: '16px', ...style}} />
);

FileDownloadIcon.propTypes = {
  style: PropTypes.objectOf(PropTypes.any)
};

export default FileDownloadIcon;
