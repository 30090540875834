import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import EmojiRender from './emoji/emoji-render';
import EnhancedPopover from './enhanced-popover-component';
import LoadingIndicator from '../../../../shared/component/loading-indicator-component';
import {lightSlate, lightSmoke, lightSnow, slate, white} from '../../../../shared/style/colors';
import {fontSize, fontWeight, spacing} from '../../../../shared/style/variables';

const CannedResponsesPopover = ({
  cannedResponses: {data, isLoading},
  i18n,
  selectItem,
  ...otherProps
}) => {
  const renderSectionWithItems = (sectionTitle, sectionItems) => (
    <div>
      <h3
        data-test-id="canned-response-popover-title"
        style={{
          padding: '8px 15px',
          borderTop: `1px solid ${lightSmoke}`,
          background: lightSnow,
          lineHeight: '14px',
          fontSize: fontSize.small,
          fontWeight: fontWeight.semiBold,
          textTransform: 'uppercase',
          color: lightSlate
        }}
      >
        {sectionTitle}
      </h3>

      <div data-test-id="list" style={{padding: 0, width: 'auto'}}>
        {sectionItems.map(({title: cannedResponseTitle, id, message}, index) => {
          const onItemClick = () => selectItem(message);

          return (
            <MenuItem
              data-test-id="item"
              key={id}
              onClick={onItemClick}
              style={{
                padding: '0 15px',
                lineHeight: '18px'
              }}
              innerDivStyle={{
                padding: '10px 0',
                borderBottom: index === sectionItems.length - 1 ? 0 : `1px solid ${lightSmoke}`
              }}
            >
              <h4
                data-test-id="title"
                style={{
                  whiteSpace: 'initial',
                  textTransform: 'capitalize',
                  color: slate,
                  fontSize: fontSize.medium,
                  fontWeight: fontWeight.semiBold,
                  marginBottom: '5px'
                }}
              >
                <EmojiRender>{cannedResponseTitle}</EmojiRender>
              </h4>
              <p
                data-test-id="message"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  fontSize: fontSize.medium,
                  color: slate,
                  lineHeight: '16px',
                  display: '-webkit-box',
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                <EmojiRender>{message}</EmojiRender>
              </p>
            </MenuItem>
          );
        })}
      </div>
    </div>
  );

  const renderSections = () => (
    <div style={{overflowY: 'auto', maxHeight: '300px'}}>
      {data.byStore.length > 0
        ? renderSectionWithItems(
            i18n.t('customerInbox.specificToBusiness', {
              business: data.businessName
            }),
            data.byStore
          )
        : null}
      {data.byCountry.length > 0
        ? renderSectionWithItems(
            i18n.t('customerInbox.specificToBusiness', {
              business: `${data.organizationName} ${data.country}`
            }),
            data.byCountry
          )
        : null}
    </div>
  );

  return (
    <EnhancedPopover
      dataTestId="canned-responses-popup"
      anchorOrigin={{horizontal: 'middle', vertical: 'top'}}
      targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
      {...otherProps}
      style={{
        maxHeight: '350px',
        maxWidth: '336px',
        overflowY: 'hidden'
      }}
    >
      <h2
        style={{
          paddingTop: spacing.medium,
          paddingBottom: spacing.medium,
          paddingLeft: spacing.large,
          paddingRight: spacing.large,
          lineHeight: '22px',
          background: white,
          color: slate,
          textAlign: 'left',
          fontSize: fontSize.xlarge,
          fontWeight: fontWeight.normal,
          textTransform: 'capitalize'
        }}
      >
        {i18n.t('customerThread.cannedResponses')}
      </h2>

      {isLoading ? <LoadingIndicator /> : renderSections()}
    </EnhancedPopover>
  );
};

CannedResponsesPopover.propTypes = {
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  selectItem: PropTypes.func.isRequired
};

export default CannedResponsesPopover;
