import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';
import themeV1 from '../../../../shared/style/theme-v1';
import LoadingIndicator from '../../../../shared/component/loading-indicator-component';

const RightPanelContentLayoutComponent = ({children, loading, dataTestId}) => (
  <MuiThemeProvider theme={themeV1}>
    <div
      data-test-id={dataTestId}
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      {loading ? <LoadingIndicator /> : children}
    </div>
  </MuiThemeProvider>
);
RightPanelContentLayoutComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string,
  loading: PropTypes.bool
};

export default RightPanelContentLayoutComponent;
