import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import numeral from 'numeral';
import {graphqlGetCreatePaymentMutation} from '../graphql-queries/graphql-conversation-query-helper';
import {DEBOUNCE_TIME_MULTIPLE_CLICK} from '../../../../../../../shared/data/settings';
import {
  CUSTOMER_THREAD_APPS_AREA_MAXIMIZE,
  CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT
} from '../../../../../actions/customer-thread-apps-actions';
import {
  CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK,
  CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD,
  CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE,
  CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS,
  CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL,
  CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK,
  CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE,
  CUSTOMER_THREAD_APP_PAYMENT_SUBMIT,
  CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE,
  CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS
} from '../../../../../actions/customer-thread-app-payment-actions';

const CustomerThreadAppPaymentEpic =
  ({graphql}) =>
  (action$, state$) => {
    const cancelPaymentFlowOnCancelButtonClick = action$.pipe(
      ofType(CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL,
        conversationId: state$.value.getIn(['customerThread', 'conversationId'])
      }))
    );

    const chooseActionOnMenuItemClick = action$.pipe(
      ofType(CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({conversationId}) => {
        if (
          state$.value.getIn([
            'customerThreadAppPayment',
            'threadsForm',
            conversationId,
            'minimized'
          ])
        ) {
          return {
            type: CUSTOMER_THREAD_APPS_AREA_MAXIMIZE,
            conversationId
          };
        }

        return {
          type: CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD,
          conversationId
        };
      })
    );

    const loadPaymentConfiguration = action$.pipe(
      ofType(CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD),
      mergeMap(({conversationId}) => {
        const businessId = state$.value.getIn(['customerThread', 'business', 'id']);

        return graphql(
          `query {
            pspAccount(businessId: "${businessId}") {
              paymentConfiguration {
                businessId
                comment
                title
              }
            }
          }`
        ).pipe(
          map(({pspAccount}) => {
            const customerProfileState = state$.value.getIn(['customer', 'thread', 'profile']);

            return {
              type: CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS,
              customer: {
                firstName: customerProfileState.get('firstName'),
                lastName: customerProfileState.get('lastName')
              },
              conversationId,
              pspAccount
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE,
              error,
              conversationId
            });
          })
        );
      })
    );

    const movePaymentFlowToNextStep = action$.pipe(
      ofType(CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => {
        const conversationId = state$.value.getIn(['customerThread', 'conversationId']);
        const isCurrentStepTheLast =
          state$.value.getIn([
            'customerThreadAppPayment',
            'threadsForm',
            conversationId,
            'paymentStep'
          ]) === 'custom';

        if (isCurrentStepTheLast) {
          return {
            type: CUSTOMER_THREAD_APP_PAYMENT_SUBMIT,
            conversationId
          };
        }

        return {
          type: CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE,
          conversationId
        };
      })
    );

    const submitPayment = action$.pipe(
      ofType(CUSTOMER_THREAD_APP_PAYMENT_SUBMIT),
      mergeMap(({conversationId}) => {
        const currentConversationPaymentState = state$.value.getIn([
          'customerThreadAppPayment',
          'threadsForm',
          conversationId
        ]);

        const amount = numeral(currentConversationPaymentState.get('amount')).multiply(100).value();
        const description = currentConversationPaymentState.get('comment');
        const firstName = currentConversationPaymentState.getIn(['customer', 'firstName']);
        const lastName = currentConversationPaymentState.getIn(['customer', 'lastName']);

        return graphql(
          graphqlGetCreatePaymentMutation({
            amount,
            conversationId,
            description,
            firstName,
            lastName
          })
        ).pipe(
          map(() => {
            return {
              type: CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS,
              conversationId
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE,
              error,
              conversationId
            });
          })
        );
      })
    );

    return merge(
      cancelPaymentFlowOnCancelButtonClick,
      chooseActionOnMenuItemClick,
      loadPaymentConfiguration,
      movePaymentFlowToNextStep,
      submitPayment
    );
  };

export default CustomerThreadAppPaymentEpic;
