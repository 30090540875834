import PropTypes from 'prop-types';

const HistogramTooltipLineComponent = ({label, style, value, ...otherProps}) => (
  <li {...otherProps}>
    <span {...{style}}>&#x25CF;</span> {label}: {value}
  </li>
);
HistogramTooltipLineComponent.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.string.isRequired
};

export default HistogramTooltipLineComponent;
