import PropTypes from 'prop-types';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import HeaderContentTitle from '../../../common/content-header/header-content-title-component';
import IconButton from '../../../../../../shared/component/button/icon-button-component';
import ThreadChannel from '../../../common/icon/thread-channel';
import UserAvatar from '../../../common/avatar/user-avatar-component';
import {avatarSize} from '../../../../../../shared/style/variables';
import {TUTORIALS} from '../../../../data/tutorial/tutorial-settings';
import {getCustomerDisplayCompany} from '../../../../../../shared/lib/display-data';

const ThreadHeaderCustomerInformationComponent = ({
  customerProfile,
  enableCustomerProfile,
  i18n,
  isLoadingCustomerThreadMetadata,
  isNewConversationWithNewCustomer,
  doCustomerProfileShow
}) => {
  const MARGIN_TOP = '14px';

  const customerProfileOpenDrawerButtonDisabled =
    isLoadingCustomerThreadMetadata ||
    customerProfile.showDrawer ||
    isNewConversationWithNewCustomer;

  const customerProfileOpenDrawerButtonOnClick = () => {
    if (customerProfileOpenDrawerButtonDisabled) {
      return;
    }

    doCustomerProfileShow();
  };

  const renderCustomerProfileOpenDrawerButton = () => (
    <IconButton
      data-test-id="customer-profile-open-drawer-button"
      aria-label=""
      disabled={customerProfileOpenDrawerButtonDisabled}
      onClick={customerProfileOpenDrawerButtonOnClick}
      style={{paddingTop: MARGIN_TOP, paddingLeft: '8px'}}
    >
      <NavigateNextOutlinedIcon
        color={customerProfileOpenDrawerButtonDisabled ? 'disabled' : 'action'}
      />
    </IconButton>
  );

  const customerThreadHeaderTitle = customerProfile.displayName
    ? `${customerProfile.displayName}${getCustomerDisplayCompany(customerProfile.company)}`
    : '';

  return (
    <div style={{flex: 1, overflow: 'hidden', display: 'flex'}}>
      <div
        data-test-id="header-account-image-content"
        style={{padding: '8px', position: 'relative'}}
      >
        <UserAvatar
          dataTestId="header-account-picture-profile"
          size={avatarSize.medium}
          src={customerProfile.pictureHref}
        />
        <ThreadChannel
          className={TUTORIALS.customerThreadHeaderChannel.className}
          type={customerProfile.channel}
          style={{
            bottom: 0,
            margin: '5px 0px',
            position: 'absolute',
            right: '0px',
            width: '23px'
          }}
          {...{i18n}}
        />
      </div>
      <div>
        <HeaderContentTitle
          dataTestId="thread-participant-name"
          label={customerThreadHeaderTitle}
          style={{marginTop: MARGIN_TOP}}
        />
      </div>
      {enableCustomerProfile ? renderCustomerProfileOpenDrawerButton() : null}
    </div>
  );
};

ThreadHeaderCustomerInformationComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  enableCustomerProfile: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoadingCustomerThreadMetadata: PropTypes.bool.isRequired,
  isNewConversationWithNewCustomer: PropTypes.bool.isRequired,
  doCustomerProfileShow: PropTypes.func.isRequired
};

export default ThreadHeaderCustomerInformationComponent;
