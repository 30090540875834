export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_FAILURE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_FAILURE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_CREATE_SUCCESS';

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_FAILURE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_FAILURE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_LOAD_SUCCESS';

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_REMOVE_BUTTON_CLICK =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_REMOVE_BUTTON_CLICK';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_SELECT =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_SELECT';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_FAILURE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_FAILURE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_SUCCESS =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_LOGO_UPLOAD_SUCCESS';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_SUBMIT_BUTTON_CLICK';

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_BUTTON_CLICK';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_FAILURE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_FAILURE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS_SNACKBAR_HIDE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_UPDATE_SUCCESS_SNACKBAR_HIDE';

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_BUTTON_CLICK';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_FAILURE =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_FAILURE';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_VALIDATION_CANCEL_SUCCESS';

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_LAYOUT_MOUNTED =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_LAYOUT_MOUNTED';

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_ROUTE_REDIRECTED_TO =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_ROUTE_REDIRECTED_TO';

// Persist fields

export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_HANDLE_DRAFT_FIELDS =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_FORM_HANDLE_DRAFT_FIELDS';
export const ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_DRAFT_FIELDS_PERSIST =
  'ADMINISTRATION_ORGANIZATION_CHANNELS_GBM_AGENT_DRAFT_FIELDS_PERSIST';
