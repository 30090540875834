import {merge} from 'rxjs';
import {debounceTime, delay, filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  filterByMultistoreConversationDisabled,
  filterByMultistoreConversationEnabled
} from '../../../../lib/feature-toggle-helper';
import goToThreadUrl from '../../../lib/go-to-thread-url';
import {
  CUSTOMER_INBOX_GO_TO_THREAD,
  CUSTOMER_INBOX_SNACK_NOTIFICATION_HIDE,
  CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW,
  CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD,
  CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK
} from '../../../../actions/customer-inbox-actions';
import {LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_START} from '../../../../actions/customer-thread-actions';
import {CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS} from '../../../../actions/customer-thread-visible-actions';
import {CONVERSATION_VISIBLE_STATUS_VALUES} from '../../../../data/thread/conversation-status';
import {
  DEBOUNCE_TIME_MULTIPLE_CLICK,
  SNACKBAR_HIDE_DEBOUNCE_TIME
} from '../../../../../../shared/data/settings';

const CustomerInboxEpic = () => (action$, state$) => {
  // Shared with legacy
  const goToThread = action$.pipe(
    ofType(CUSTOMER_INBOX_GO_TO_THREAD),
    map(({params, participationId}) => goToThreadUrl('customers', participationId, params))
  );

  // Shared with legacy
  const goToThreadOnThreadsListThreadClick = action$.pipe(
    ofType(CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK),
    filter(filterByMultistoreConversationDisabled(state$)),
    debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
    map(({payload: {participationId}}) => ({
      type: CUSTOMER_INBOX_GO_TO_THREAD,
      participationId
    }))
  );

  const startConversationUrlGeneration = action$.pipe(
    ofType(CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD),
    filter(filterByMultistoreConversationEnabled(state$)),
    debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
    map(({payload: {customer, participationId}}) => ({
      type: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_START,
      payload: {
        customer,
        participationId
      }
    }))
  );

  // Shared with legacy
  const hideNotificationSnackbar = action$.pipe(
    ofType(CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW),
    delay(SNACKBAR_HIDE_DEBOUNCE_TIME),
    map(() => {
      return {
        type: CUSTOMER_INBOX_SNACK_NOTIFICATION_HIDE
      };
    })
  );

  const notifyEmployeeWhenHisActionChangesConversationStatus = action$.pipe(
    ofType(CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS),
    filter(
      ({payload: {conversation}}) =>
        conversation.previousStatus === CONVERSATION_VISIBLE_STATUS_VALUES.WAITING
    ),
    map(() => ({
      type: CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW
    }))
  );

  const selectThreadOnInboxThreadsListItemClick = action$.pipe(
    ofType(CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK),
    filter(filterByMultistoreConversationEnabled(state$)),
    map(
      ({
        payload: {
          company,
          directedToBusinessName,
          participantIcon,
          participantName,
          participationId,
          readOnly,
          status
        }
      }) => ({
        type: CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD,
        payload: {
          business: {
            name: directedToBusinessName
          },
          conversation: {
            readOnly,
            status
          },
          customer: {
            company,
            displayName: participantName,
            pictureHref: participantIcon
          },
          participationId
        }
      })
    )
  );

  return merge(
    goToThread,
    goToThreadOnThreadsListThreadClick,
    hideNotificationSnackbar,
    notifyEmployeeWhenHisActionChangesConversationStatus,
    selectThreadOnInboxThreadsListItemClick,
    startConversationUrlGeneration
  );
};

export default CustomerInboxEpic;
