import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import UserAvatar from '../../../../common/avatar/user-avatar-component';
import SelectFile from '../../../../common/file/select-file-component';
import {oneline} from '../../../../../../../shared/style/ellipsis';
import {avatarSize, fontSize, lineHeight} from '../../../../../../../shared/style/variables';
import {black, blue, lightSlate} from '../../../../../../../shared/style/colors';

const CustomerProfileHeaderComponent = ({
  customerProfile,
  i18n,
  participationId,
  doCustomerProfileClose,
  doCustomerProfileEditOpen,
  doCustomerProfilePictureUpload
}) => {
  const sharedMargin = '20px 15px 0 0';

  const doUploadPicture = (file) => {
    doCustomerProfilePictureUpload(participationId, customerProfile.customerId, file);
  };

  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <CloseIcon
        data-test-id="customer-profile-drawer-close-button"
        htmlColor={black}
        onClick={doCustomerProfileClose}
        style={{margin: '20px 0 0 15px', cursor: 'pointer'}}
      />
      <div
        data-test-id="customer-profile-drawer-user-content"
        style={{
          marginTop: '15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <SelectFile
          dataTestId="user-avatar-input"
          enabled
          iconColor={black}
          isLoading={customerProfile.pictureHrefIsUploading}
          isNewEditIcon
          doUploadFile={doUploadPicture}
        >
          <UserAvatar
            dataTestId="picture-profile"
            size={avatarSize.xxxlarge}
            src={customerProfile.pictureHref}
          />
        </SelectFile>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <div
            data-test-id="name"
            title={customerProfile.displayName}
            style={{
              maxWidth: '350px',
              fontSize: fontSize.xxlarge,
              lineHeight: lineHeight.xlarge,
              ...oneline
            }}
          >
            {customerProfile.displayName}
          </div>
          <div
            data-test-id="company"
            style={{color: lightSlate, marginTop: '10px', textTransform: 'uppercase'}}
          >
            {customerProfile.company}
          </div>
        </div>
      </div>

      {customerProfile.showDrawerEdit || !customerProfile.customerId ? (
        <div style={{margin: sharedMargin, width: '29px'}} />
      ) : (
        <span
          data-test-id="customer-profile-drawer-edit-button"
          onClick={doCustomerProfileEditOpen}
          style={{
            margin: sharedMargin,
            color: blue,
            cursor: 'pointer',
            fontSize: fontSize.xlarge
          }}
        >
          {i18n.t('customerThread.customerProfile.editButton.label')}
        </span>
      )}
    </div>
  );
};

CustomerProfileHeaderComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  participationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  doCustomerProfileClose: PropTypes.func.isRequired,
  doCustomerProfileEditOpen: PropTypes.func.isRequired,
  doCustomerProfilePictureUpload: PropTypes.func.isRequired
};

export default CustomerProfileHeaderComponent;
