import PropTypes from 'prop-types';
import UserAvatar from '../../../../common/avatar/user-avatar-component';
import {formatRelativeDate} from '../../../../../lib/date-time-helper';
import {avatarSize} from '../../../../../../../shared/style/variables';

const ThreadTakenByColleagueDescriptionComponent = ({assignedTo, i18n}) => (
  <div
    data-test-id="taken-by-colleague-description"
    style={{
      display: 'flex',
      flex: 1,
      alignItems: 'center'
    }}
  >
    <UserAvatar
      size={avatarSize.small}
      src={assignedTo.user.picture}
      title={assignedTo.user.displayName}
    />
    <span
      style={{
        marginLeft: '8px'
      }}
    >
      {i18n.t('customerThread.visibleThread.assignment.colleagueTookIt', {
        assignedAt: formatRelativeDate(
          assignedTo.assignedAt,
          i18n,
          'customerThread.visibleThread.assignment'
        ),
        // lastName should never be useful, but just in case firstName is missing or empty...
        assignedName: assignedTo.user.firstName || assignedTo.user.lastName
      })}
    </span>
  </div>
);

ThreadTakenByColleagueDescriptionComponent.propTypes = {
  assignedTo: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ThreadTakenByColleagueDescriptionComponent;
