import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_OPEN
} from '../../../../../actions/administration-business-actions';
import handleErrors from '../../../../../../../shared/epic/lib/handle-errors';
import {
  getFacebookPages,
  handleFacebookPageRequestParams,
  isOnlyOneFacebookPage
} from '../../lib/administration-business-page-facebook-channel-helper';

const ERROR_MAPPING_CHANNEL_STATUS_CONNECTION = {
  40102: 'errorTokenNotValid',
  40104: 'errorTokenNotValid',
  40331: 'errorAdministrator'
};

const AdministrationBusinessPageChannelsEpic =
  ({graphql}) =>
  (action$, state$) => {
    const openAdministrationBusinessPageChannels = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_OPEN),
      map(({businessId}) => {
        return {
          type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD,
          businessId
        };
      })
    );

    const loadFacebookChannelStatusConnection = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_LOAD),
      mergeMap(({businessId}) => {
        const params = {
          businessId
        };

        return graphql('get-facebook-connection-status-query', params).pipe(
          map(({facebookConnectionStatus}, errors) => {
            if (errors) {
              return {
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR,
                errors: handleErrors(errors, ERROR_MAPPING_CHANNEL_STATUS_CONNECTION)
              };
            }

            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_SUCCESS,
              facebookConnectionStatus
            };
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_FAILURE,
              error
            });
          })
        );
      })
    );

    const facebookRetrievedToken = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN),
      map(() => {
        return {
          type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD
        };
      })
    );

    const loadFacebookChannelPagesList = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_LOAD),
      mergeMap(() => {
        const facebookAccessToken = state$.value.getIn([
          'administrationBusinessPageChannels',
          'facebookChannel',
          'facebookLogin',
          'authenticationToken'
        ]);
        const businessId = state$.value.getIn([
          'administrationBusinessPageChannels',
          'facebookChannel',
          'businessId'
        ]);
        const params = {
          facebookAccessToken
        };

        return graphql('get-facebook-pages-query', params).pipe(
          mergeMap(({facebookPages}) => {
            const initialActions = [
              {
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_SUCCESS,
                facebookPages
              }
            ];

            if (isOnlyOneFacebookPage(facebookPages)) {
              initialActions.push({
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION,
                businessId
              });
            }

            return initialActions;
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGES_LIST_FAILURE,
              error
            });
          })
        );
      })
    );

    const facebookPageAutomaticConnection = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION),
      mergeMap(({businessId}) => {
        const facebookPages = getFacebookPages(state$);

        const facebookParams = handleFacebookPageRequestParams(facebookPages);

        const params = {businessId, ...facebookParams};

        return graphql('create-facebook-page-connection-mutation', params).pipe(
          map(({createFacebookPageConnection}, errors) => {
            if (errors) {
              return {
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR,
                errors: handleErrors(errors, ERROR_MAPPING_CHANNEL_STATUS_CONNECTION)
              };
            }

            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_SUCCESS,
              createFacebookPageConnection
            };
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_AUTOMATIC_CONNECTION_FAILURE,
              error
            });
          })
        );
      })
    );

    const connectFacebookPage = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT),
      mergeMap(({businessId, facebookPageId}) => {
        const facebookPages = getFacebookPages(state$);

        const facebookParams = handleFacebookPageRequestParams(facebookPages, facebookPageId);

        const params = {businessId, ...facebookParams};

        return graphql('create-facebook-page-connection-mutation', params).pipe(
          map(({createFacebookPageConnection}, errors) => {
            if (errors) {
              return {
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR,
                errors: handleErrors(errors, ERROR_MAPPING_CHANNEL_STATUS_CONNECTION)
              };
            }

            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT_SUCCESS,
              createFacebookPageConnection
            };
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_STATUS_CONNECTION_CHANNEL_ERROR,
              errors: handleErrors(error, ERROR_MAPPING_CHANNEL_STATUS_CONNECTION)
            });
          })
        );
      })
    );

    const facebookPageDisconnection = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT),
      mergeMap(({businessId}) => {
        const params = {businessId};

        return graphql('disconnect-facebook-page-mutation', params).pipe(
          map(({disconnectFacebookPage}) => {
            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_SUCCESS,
              disconnectFacebookPage
            };
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(
      connectFacebookPage,
      facebookPageAutomaticConnection,
      facebookRetrievedToken,
      facebookPageDisconnection,
      loadFacebookChannelPagesList,
      loadFacebookChannelStatusConnection,
      openAdministrationBusinessPageChannels
    );
  };

export default AdministrationBusinessPageChannelsEpic;
