import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FormControl, MenuItem, Tooltip} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';
import times from 'lodash/times';
import context from '../../../../../../../../shared/component/context';
import getAggregationStartDateFromPeriod from '../../../../../../lib/insights-get-aggregation-start-date-from-period';
import getMomentUnitFromPeriod from '../../../../../../lib/get-moment-unit-from-period';
import getStyledIconButton from '../../../../../../../../shared/component/icons/icon-button';
import SelectNeutral from '../../../../../../../../shared/component/form/select-neutral-component';
import SubHeader from './sub-header-component';
import {fontSize, spacing} from '../../../../../../../../shared/style/variables';
import {red} from '../../../../../../../../shared/style/colors';
import {STATISTICS_CLASSIC_PERIOD_MORE_START_DATE} from '../../../../../../data/settings';

const _applyOffsetToAggregationDate = (
  offset,
  classicAggregationDate,
  classicAggregationPeriod
) => {
  return classicAggregationDate
    .clone()
    .add(offset, getMomentUnitFromPeriod(classicAggregationPeriod));
};

const _getPreviousAggregationDate = (classicAggregationDate, classicAggregationPeriod) => {
  return _applyOffsetToAggregationDate(-1, classicAggregationDate, classicAggregationPeriod);
};

const _isOngoingPeriod = (classicAggregationDate, classicAggregationPeriod) => {
  return getAggregationStartDateFromPeriod(classicAggregationPeriod).isSame(
    getAggregationStartDateFromPeriod(classicAggregationPeriod, classicAggregationDate)
  );
};

const StyledIconButton = getStyledIconButton();

const PERIOD_MORE_START_DATE = moment(STATISTICS_CLASSIC_PERIOD_MORE_START_DATE);

/**
 * Finally, the component.
 */
class InsightSubHeaderClassicReportComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._doChangeAggregationDateBefore = this._doChangeAggregationDateBefore.bind(this);
    this._doChangeAggregationDateDirectly = this._doChangeAggregationDateDirectly.bind(this);
    this._doChangeAggregationDateNext = this._doChangeAggregationDateNext.bind(this);
    this._doChangeAggregationPeriod = this._doChangeAggregationPeriod.bind(this);
  }

  _renderPreviousPeriod = () => {
    const {classicAggregationDate, classicAggregationPeriod} = this.props;

    return classicAggregationDate.isAfter()
      ? _getPreviousAggregationDate(classicAggregationDate, classicAggregationPeriod)
      : classicAggregationDate;
  };

  _doChangeAggregationDateDirectly = (event) => {
    const {classicAggregationPeriod, doPeriodTabClassicChangeDateAndPeriod} = this.props;

    doPeriodTabClassicChangeDateAndPeriod(moment(event.target.value), classicAggregationPeriod);
  };

  _doChangeAggregationDateBefore() {
    const {
      classicAggregationDate,
      classicAggregationPeriod,
      doPeriodTabClassicChangeDateAndPeriod
    } = this.props;

    doPeriodTabClassicChangeDateAndPeriod(
      _getPreviousAggregationDate(classicAggregationDate, classicAggregationPeriod),
      classicAggregationPeriod
    );
  }

  _doChangeAggregationDateNext() {
    const {
      classicAggregationDate,
      classicAggregationPeriod,
      doPeriodTabClassicChangeDateAndPeriod
    } = this.props;

    doPeriodTabClassicChangeDateAndPeriod(
      _applyOffsetToAggregationDate(1, classicAggregationDate, classicAggregationPeriod),
      classicAggregationPeriod
    );
  }

  _doChangeAggregationPeriod(newClassicAggregationPeriod) {
    const {classicAggregationDate, doPeriodTabClassicChangeDateAndPeriod} = this.props;

    doPeriodTabClassicChangeDateAndPeriod(
      _isOngoingPeriod(classicAggregationDate, newClassicAggregationPeriod)
        ? _getPreviousAggregationDate(classicAggregationDate, newClassicAggregationPeriod)
        : this._renderPreviousPeriod(),
      newClassicAggregationPeriod
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      classicAggregationDate,
      classicAggregationPeriod,
      downloadCsvLabelTranslation,
      insightDownloadCsv,
      insightSortRankings,
      loadingCsv,
      showDownloadCsvButton,
      showCsvButton,
      showSortButton
    } = this.props;

    const aggregationPeriodLabel = i18n.t(
      `insight.contentHeader.periodSelector.periods.${classicAggregationPeriod}.label`
    );
    const isOngoingPeriod = _isOngoingPeriod(classicAggregationDate, classicAggregationPeriod);

    const aggregationDate = getAggregationStartDateFromPeriod(
      classicAggregationPeriod,
      classicAggregationDate
    ).format();
    const classicAggregationPeriodForMoment = getMomentUnitFromPeriod(classicAggregationPeriod);
    const now = moment();

    const availablePeriodsList = times(
      Math.ceil(moment().diff(PERIOD_MORE_START_DATE, classicAggregationPeriodForMoment, true)),
      (index) =>
        getAggregationStartDateFromPeriod(classicAggregationPeriod, now)
          .subtract(index, classicAggregationPeriodForMoment)
          .format()
    );
    const isOldestPeriodSelected =
      aggregationDate === availablePeriodsList[availablePeriodsList.length - 1];

    return (
      <SubHeader
        aggregationPeriod={classicAggregationPeriod}
        doChangeAggregationPeriod={this._doChangeAggregationPeriod}
        periods={[{value: 'P1W'}, {value: 'P1M'}, {value: 'P1Y'}]}
        {...{
          downloadCsvLabelTranslation,
          insightDownloadCsv,
          insightSortRankings,
          loadingCsv,
          showDownloadCsvButton,
          showCsvButton,
          showSortButton
        }}
      >
        <div>
          <div
            data-test-id="period-title"
            style={{
              fontSize: fontSize.xxxlarge
            }}
          >
            {isOngoingPeriod ? (
              <span style={{color: red}}>
                {i18n.t('insight.contentHeader.periodSelector.ongoingPeriod')}
              </span>
            ) : (
              <span>
                {i18n.t(
                  `insight.contentHeader.periodSelector.periods.${classicAggregationPeriod}.classicPeriodReportTitle`
                )}
              </span>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: spacing.medium
            }}
          >
            <Tooltip
              title={i18n.t(
                [
                  `insight.contentHeader.periodSelector.periods.${classicAggregationPeriod}.previous`,
                  'insight.contentHeader.periodSelector.classicReport.previous'
                ],
                {aggregationPeriodLabel}
              )}
            >
              <span>
                <StyledIconButton
                  data-test-id="previous"
                  disabled={isOldestPeriodSelected}
                  onClick={this._doChangeAggregationDateBefore}
                >
                  <NavigateBeforeIcon />
                </StyledIconButton>
              </span>
            </Tooltip>

            {isOngoingPeriod ? null : (
              <Tooltip
                title={i18n.t(
                  [
                    `insight.contentHeader.periodSelector.periods.${classicAggregationPeriod}.next`,
                    'insight.contentHeader.periodSelector.classicReport.next'
                  ],
                  {aggregationPeriodLabel}
                )}
              >
                <span style={{marginLeft: spacing.medium}}>
                  <StyledIconButton
                    data-test-id="next"
                    disabled={!classicAggregationDate}
                    onClick={this._doChangeAggregationDateNext}
                  >
                    <NavigateNextIcon />
                  </StyledIconButton>
                </span>
              </Tooltip>
            )}

            {classicAggregationDate ? (
              <FormControl style={{marginLeft: spacing.small}}>
                <SelectNeutral
                  data-test-id="more"
                  onChange={this._doChangeAggregationDateDirectly}
                  value={aggregationDate}
                  MenuProps={{
                    'data-test-id': 'period-more-list'
                  }}
                >
                  {availablePeriodsList.map((value) => {
                    return (
                      <MenuItem data-test-id="option" key={value} value={value}>
                        {i18n.t(
                          `insight.contentHeader.periodSelector.periods.${classicAggregationPeriod}.classicPeriodDateFormat`,
                          {aggregationDate: moment(value)}
                        )}
                      </MenuItem>
                    );
                  })}
                </SelectNeutral>
              </FormControl>
            ) : null}
          </div>
        </div>
      </SubHeader>
    );
  }
}

InsightSubHeaderClassicReportComponent.contextTypes = context;

InsightSubHeaderClassicReportComponent.propTypes = {
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string.isRequired,
  downloadCsvLabelTranslation: PropTypes.string,
  insightDownloadCsv: PropTypes.func,
  insightSortRankings: PropTypes.func,
  loadingCsv: PropTypes.bool,
  showDownloadCsvButton: PropTypes.bool,
  showCsvButton: PropTypes.bool,
  showSortButton: PropTypes.bool,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired
};

export default InsightSubHeaderClassicReportComponent;
