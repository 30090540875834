import {SketchPicker} from 'react-color';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import context from '../../../../../../../shared/component/context';
import {getI18NWidgetPrefix} from '../../lib/integration-area-widgets-helper';
import WidgetPage, {
  SECTION_TITLE_STYLE,
  SECTION_SUBTITLE_STYLE,
  SECTION_DESCRIPTION_STYLE,
  SECTION_BLOCK_STYLE,
  WIDGET_PAGES_COMMON_PROP_TYPES
} from './common/widget-page-component';

class MobileWidgetComponent extends WidgetPage {
  constructor(props) {
    super(props);

    this.state = {
      containerId: 'iw-text-widget',
      i18nWidgetPrefix: getI18NWidgetPrefix('mobile'),
      scriptFile: 'text',
      widgetType: 'mobile',
      snippetProps: ['lang', 'customText', 'bgColor', 'color', 'textNumber']
    };

    this._doCustomTextChange = this._doCustomTextChange.bind(this);
    this._doPhoneNumberChange = this._doPhoneNumberChange.bind(this);
    this._doTextColorChange = this._doTextColorChange.bind(this);
  }

  _doCustomTextChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    if (newText) {
      widgetWindow._iwq.customText = newText;
    } else {
      delete widgetWindow._iwq.customText;
    }
    this.__updateWidget();

    this.props.doChangeButtonText(newText);
  }

  _doPhoneNumberChange(e, i, phoneNumber) {
    const {widgetWindow} = this.state;

    widgetWindow._iwq.textNumber = phoneNumber;
    this.__updateWidget();

    this.props.doChangePhoneNumber(phoneNumber);
  }

  _doTextColorChange(color) {
    const {widgetWindow} = this.state;

    if (color) {
      widgetWindow._iwq.color = color.hex;
    } else {
      delete widgetWindow._iwq.color;
    }
    this.__updateWidget();

    this.props.doChangeTextColor(color.hex);
  }

  render() {
    const {i18n} = this.context;
    const {customText, phoneNumber, textColor} = this.props;

    // @todo Check usefulness of _renderSingleBusinessSelector(), as there is no businessId in this.state.snippetProps
    return this._renderLayout(
      'mobile-wireframe.png',
      'mobile.html',
      '70px',
      phoneNumber,
      i18n.t('integrationArea.widgets.mobile.finalResultAndCode.description'),
      <div>
        {this._renderSingleBusinessSelector('phoneNumber', this._doPhoneNumberChange)}

        {this._renderSectionStyleAndCustomize(
          <div>
            {this._renderLanguage()}

            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={customText.trim()}
                floatingLabelFixed
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.mobile.sectionStyleAndCustomize.customText.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.mobile.sectionStyleAndCustomize.customText.hint'
                )}
                onChange={this._doCustomTextChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.mobile.sectionStyleAndCustomize.customText.description'
                )}
              </span>
            </div>

            {this._renderBackgroundColorPicker('bgColor')}

            <div style={SECTION_BLOCK_STYLE}>
              <h3 style={SECTION_TITLE_STYLE}>
                {i18n.t('integrationArea.widgets.mobile.sectionStyleAndCustomize.textColor.title')}
              </h3>
              <span style={SECTION_SUBTITLE_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.mobile.sectionStyleAndCustomize.textColor.description'
                )}
              </span>

              <SketchPicker color={textColor} onChangeComplete={this._doTextColorChange} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

MobileWidgetComponent.contextTypes = context;

MobileWidgetComponent.propTypes = {
  bgColor: PropTypes.string.isRequired,
  businessesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  customText: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  doChangeBackgroundColor: PropTypes.func.isRequired,
  doChangeButtonText: PropTypes.func.isRequired,
  doChangePhoneNumber: PropTypes.func.isRequired,
  doChangeTextColor: PropTypes.func.isRequired,
  ...WIDGET_PAGES_COMMON_PROP_TYPES
};

export default MobileWidgetComponent;
