import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Check from 'material-ui/svg-icons/navigation/check';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import SubHeader from 'material-ui/Subheader';
import context from '../../../../../../shared/component/context';
import {fontSize, spacing} from '../../../../../../shared/style/variables';

class FilterAndSortMenuComponent extends PureComponent {
  _doLoadInboxThreadsList = (key) => {
    const {statuses, doLoadInboxThreadsList} = this.props;
    doLoadInboxThreadsList(statuses, 0, key);
  };

  _getContextMenuConfiguration(type) {
    const {i18n} = this.context;
    const {repliedTotal, resolvedTotal, waitingTotal} = this.props;

    return {
      filter: [
        {
          key: 'waiting,replied,resolved',
          label: `${i18n.t(
            'customerInbox.header.filterAndSortMenu.filterBy.filters.waitingRepliedResolved'
          )} (${waitingTotal + repliedTotal + resolvedTotal})`
        },
        {
          key: 'waiting,replied',
          label: `${i18n.t(
            'customerInbox.header.filterAndSortMenu.filterBy.filters.waitingReplied'
          )} (${waitingTotal + repliedTotal})`
        },
        {
          key: 'waiting',
          label: `${i18n.t(
            'customerInbox.header.filterAndSortMenu.filterBy.filters.waiting'
          )} (${waitingTotal})`
        },
        {
          key: 'replied',
          label: `${i18n.t(
            'customerInbox.header.filterAndSortMenu.filterBy.filters.replied'
          )} (${repliedTotal})`
        },
        {
          key: 'resolved',
          label: `${i18n.t(
            'customerInbox.header.filterAndSortMenu.filterBy.filters.resolved'
          )} (${resolvedTotal})`
        }
      ],
      sort: [
        {
          key: 'mostRecent',
          label: i18n.t('customerInbox.header.filterAndSortMenu.sortBy.columns.mostRecent')
        },
        {
          key: 'waiting',
          label: i18n.t('customerInbox.header.filterAndSortMenu.sortBy.columns.waitingTime')
        }
      ]
    }[type];
  }

  // Be careful to not move this method outside the component as it would break automatic menu closing on item click
  _renderFilterMenuItem(label, isSelected, onClick) {
    return (
      <MenuItem
        data-test-id="select-option"
        key={label}
        leftIcon={isSelected ? <Check data-test-id="selected" /> : null}
        primaryText={label}
        {...{
          onClick
        }}
        style={{
          paddingLeft: isSelected ? 0 : spacing.xxxlarge,
          fontSize: fontSize.medium
        }}
      />
    );
  }

  render() {
    const {i18n} = this.context;
    const {filterStyles, showOptionsToSortFilter, sortBy, statuses, doFilterInboxByStatuses} =
      this.props;

    const aggregatedStatuses = statuses.join(',');

    return (
      <IconMenu
        data-test-id="filter-by"
        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
        style={{display: 'block'}}
        iconButtonElement={
          <IconButton data-test-id="inbox-filter-and-sort-menu" style={filterStyles}>
            <span data-test-id="filter-label">
              {i18n.t('customerInbox.header.filterAndSortMenu.filterLabel')}
            </span>
          </IconButton>
        }
      >
        <SubHeader data-test-id="filter-title">
          {i18n.t('customerInbox.header.filterAndSortMenu.filterBy.title')}
        </SubHeader>

        {this._getContextMenuConfiguration('filter').map(({label, key}) =>
          this._renderFilterMenuItem(label, key === aggregatedStatuses, () =>
            doFilterInboxByStatuses(key.split(','))
          )
        )}

        {showOptionsToSortFilter ? (
          <React.Fragment>
            <Divider />
            <SubHeader data-test-id="sort-title">
              {i18n.t('customerInbox.header.filterAndSortMenu.sortBy.title')}
            </SubHeader>
            {this._getContextMenuConfiguration('sort').map(({label, key}) =>
              this._renderFilterMenuItem(label, key === sortBy, () =>
                this._doLoadInboxThreadsList(key)
              )
            )}
          </React.Fragment>
        ) : null}
      </IconMenu>
    );
  }
}

FilterAndSortMenuComponent.contextTypes = context;

FilterAndSortMenuComponent.propTypes = {
  filterStyles: PropTypes.objectOf(PropTypes.any),
  repliedTotal: PropTypes.number.isRequired,
  resolvedTotal: PropTypes.number.isRequired,
  showOptionsToSortFilter: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  waitingTotal: PropTypes.number.isRequired,
  doFilterInboxByStatuses: PropTypes.func.isRequired,
  doLoadInboxThreadsList: PropTypes.func.isRequired
};

export default FilterAndSortMenuComponent;
