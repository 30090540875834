import {useEffect} from 'react';
import {interval} from 'rxjs';
import PropTypes from 'prop-types';

const TitleBlinkIndicatorComponent = ({title}) => {
  const TIMER_INTERVAL = 2000;
  const timerForBlink = interval(TIMER_INTERVAL);
  const tmpTitle = document.title;
  let subscriptionToBlinkTimer = null;

  const updateTitle = () => {
    if (document.title !== title) {
      document.title = title;
    } else {
      document.title = tmpTitle;
    }
  };
  const blinkTitle = () => {
    subscriptionToBlinkTimer = timerForBlink.subscribe(() => {
      updateTitle();
    });
  };

  useEffect(() => {
    blinkTitle();

    return () => {
      document.title = tmpTitle;

      if (subscriptionToBlinkTimer) {
        subscriptionToBlinkTimer.unsubscribe();
      }
    };
  }, []);

  return null;
};

TitleBlinkIndicatorComponent.propTypes = {
  title: PropTypes.string.isRequired
};

export default TitleBlinkIndicatorComponent;
