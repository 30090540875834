const PRESENTATION_TYPES = {
  AUTOMATIC_INCOMING: 'AutomaticIncoming',
  AUTOMATIC_OUTGOING: 'AutomaticOutgoing',
  COLLEAGUE_OUTGOING: 'ColleagueOutgoing',
  INTERNAL_INCOMING: 'InternalIncoming',
  INTERNAL_OUTGOING: 'InternalOutgoing',
  MINE_OUTGOING: 'MineOutgoing',
  STANDARD_INCOMING: 'StandardIncoming'
};

const PRESENTATION_TYPE_GROUPS = {
  employeeOutgoingMessage: [PRESENTATION_TYPES.COLLEAGUE_OUTGOING, PRESENTATION_TYPES.MINE_OUTGOING]
};

export default PRESENTATION_TYPE_GROUPS;
