import {useEffect} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import isManagerOfBusiness from '../../../../lib/is-manager-of-business';
import AdministrationBusinessPageChannelsContainer from '../../../../container/content/administration/business/page/administration-business-page-channels-container';
import AdministrationBusinessTabsMenu from './administration-business-tabs-menu-component';
import AdministrationRightPanelLayout from '../layout/administration-right-panel-layout-component';
import EmptyAreaWithLogo, {Error404Content} from '../../../common/empty-area-with-logo-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';

const AdministrationBusinessLayoutComponent = (
  {
    accountBusinesses,
    administrationBusiness: {businessBotConfiguration, businessProfile, loading},
    enableBusinessMessages,
    enableChannelAdmin,
    enablePaymentFeature,
    enableSurvey,
    match: {
      params: {businessId, businessPageName}
    },
    doAdministrationBusinessPageOpen
  },
  {i18n}
) => {
  useEffect(() => {
    doAdministrationBusinessPageOpen(businessId);
  }, []);

  if (loading) {
    return (
      <AdministrationRightPanelLayout>
        <LoadingIndicator />
      </AdministrationRightPanelLayout>
    );
  }

  const errorBusinessNotFound = (
    <EmptyAreaWithLogo
      dataTestId="business-not-found"
      label={i18n.t('administration.content.errorBusinessNotFound')}
    />
  );

  if (!businessProfile) {
    return errorBusinessNotFound;
  }

  const isManagerOfCurrentBusiness = isManagerOfBusiness(businessId, accountBusinesses);

  if (!isManagerOfCurrentBusiness && businessPageName !== 'channels') {
    return errorBusinessNotFound;
  }

  const renderPageContentPerPageName = () => {
    const pageContentPerPageName = {
      channels:
        enableChannelAdmin || enableBusinessMessages ? (
          <AdministrationBusinessPageChannelsContainer {...{i18n}} />
        ) : null
    };

    const contentToRender = pageContentPerPageName[businessPageName];

    if (!contentToRender) {
      return <Error404Content {...{i18n}} />;
    }

    return contentToRender;
  };

  return (
    <AdministrationRightPanelLayout contentStyle={{padding: 0}} pageTitle={businessProfile.name}>
      <AdministrationBusinessTabsMenu
        enableBotAPI={!!businessBotConfiguration.enabled}
        {...{
          businessBotConfiguration,
          businessId,
          businessPageName,
          enableBusinessMessages,
          enableChannelAdmin,
          enablePaymentFeature,
          enableSurvey,
          i18n,
          isManagerOfCurrentBusiness
        }}
      />
      {renderPageContentPerPageName()}
    </AdministrationRightPanelLayout>
  );
};

AdministrationBusinessLayoutComponent.contextTypes = context;

AdministrationBusinessLayoutComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  administrationBusiness: PropTypes.objectOf(PropTypes.any).isRequired,
  enableBusinessMessages: PropTypes.bool.isRequired,
  enableChannelAdmin: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  enableSurvey: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  doAdministrationBusinessPageOpen: PropTypes.func.isRequired
};

export default AdministrationBusinessLayoutComponent;
