import {PureComponent} from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import currentPlatform from '../lib/current-platform';

// Prevent stale rendering on Safari
const toggleRootDisplay = () => {
  if (currentPlatform.isSafari) {
    const rootElement = document.getElementById('root');

    rootElement.style.display = rootElement.style.display === 'flex' ? 'block' : 'flex';
  }
};

/**
 * Finally, the component.
 */
class TopLayoutComponent extends PureComponent {
  componentDidMount() {
    const {doUIEndMount} = this.props;

    if (typeof doUIEndMount === 'function') {
      doUIEndMount();
    }

    toggleRootDisplay();

    setTimeout(toggleRootDisplay);
  }

  render() {
    const {children, dataTestId, style, title, titlePrefix} = this.props;

    const titleBase = document.title
      .split(') ')
      .pop()
      .slice(0, document.title.indexOf('Instaply') + 8);

    return (
      <div
        data-test-id={dataTestId}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          minHeight: '1px', // required for inbox scroll IE 11
          ...style
        }}
      >
        <DocumentTitle title={(titlePrefix || '') + titleBase + (title ? ` - ${title}` : '')} />
        {children}
      </div>
    );
  }
}

TopLayoutComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
  dataTestId: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  titlePrefix: PropTypes.string,
  doUIEndMount: PropTypes.func
};

export default TopLayoutComponent;
