const getFTEnableMultistoreConversation = (state$) =>
  state$.value.getIn(['featureToggle', 'enable_multistore_conversation']);

const isMultistoreConversationDisabled = (state$) =>
  getFTEnableMultistoreConversation(state$) === false;

const isMultistoreConversationEnabled = (state$) =>
  getFTEnableMultistoreConversation(state$) === true;

const filterByMultistoreConversationEnabled = (state$) => () =>
  isMultistoreConversationEnabled(state$);

const filterByMultistoreConversationDisabled = (state$) => () =>
  isMultistoreConversationDisabled(state$);

const isNotAvailableFeatureToggle = (state$) => () =>
  typeof getFTEnableMultistoreConversation(state$) === 'undefined';

const isAvailableFeatureToggle = (state$) => () => getFTEnableMultistoreConversation(state$);

export {
  filterByMultistoreConversationEnabled,
  filterByMultistoreConversationDisabled,
  isAvailableFeatureToggle,
  isNotAvailableFeatureToggle,
  isMultistoreConversationEnabled
};
