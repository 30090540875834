import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import context from '../../../../../../shared/component/context';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {ChartTitleContainer} from '../lib/chart-title-generator';
import {fontSize, spacing} from '../../../../../../shared/style/variables';
import {graphBlue2Main, graphYellow} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'preferredChannel';

/**
 * Finally, the component.
 */
class InsightPreferredChannelComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <InsightPanelContentBlock dataTestId="preferred-channel">
            <HistogramChart
              dataTestId="histogram-chart"
              aggregationPeriod={
                periodTabSelected === 'custom' ? customAggregationPeriod : classicAggregationPeriod
              }
              stacking="percent"
              tooltipShowPercent
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.preferredChannel.chart`, {
                returnObjects: true
              })}
              type="column"
              {...insight.preferredChannelHistogram}
              title={
                <ChartTitleContainer>
                  <span>
                    {i18n.t(`insight.pages.${INSIGHT_PAGE}.preferredChannel.chart.title`)}
                  </span>
                  <ul data-test-id="indicators" style={{marginTop: spacing.large}}>
                    {['messageOverTime', 'phoneOverTime'].map((key) => {
                      return (
                        <li key={key} style={{float: 'left', marginRight: spacing.xlarge}}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span>
                              {i18n.t(
                                `insight.pages.${INSIGHT_PAGE}.preferredChannel.chart.indicators.${key}`
                              )}
                            </span>
                            <span
                              data-test-id={kebabCase(key)}
                              style={{fontSize: fontSize.xxxxlarge}}
                            >
                              {insight.preferredChannelIndicators[key]}%
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </ChartTitleContainer>
              }
              series={[
                {
                  property: 'message',
                  color: graphBlue2Main
                },
                {
                  property: 'phone',
                  color: graphYellow
                }
              ]}
            />
          </InsightPanelContentBlock>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightPreferredChannelComponent.contextTypes = context;

InsightPreferredChannelComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  managerOfABusiness: PropTypes.bool.isRequired,
  organizationManager: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightPreferredChannelComponent;
