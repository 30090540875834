/**
 * Convenient method to sort an immutable 'List' by some sub-object 'Map' property.
 *
 * @param {string} property
 * @returns {function({Map}, {Map})}
 */
export default (property) => {
  return (item1, item2) => {
    if (item1.get(property) < item2.get(property)) {
      return -1;
    }

    if (item1.get(property) > item2.get(property)) {
      return 1;
    }

    return 0;
  };
};
