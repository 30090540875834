const updatePanelContentLayoutScroll = (event) => {
  event.preventDefault();

  if (event.target.scrollTop > 0) {
    return event.target.scrollTop;
  }

  return 0;
};

export default updatePanelContentLayoutScroll;
