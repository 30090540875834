import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import NextIcon from 'material-ui/svg-icons/image/navigate-next';
import context from '../../../../../../../shared/component/context';
import LegacyCustomerProfileCardText from './legacy-customer-profile-card-text';
import UserAvatar from '../../../../common/avatar/user-avatar-component';
import {avatarSize, spacing} from '../../../../../../../shared/style/variables';
import {darkSmoke, lightSlate} from '../../../../../../../shared/style/colors';

const USER_AVATAR_SIZE = avatarSize.xlarge;

/**
 * Finally, the component.
 */
class LegacyCustomerProfileCardComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    const {readOnly, doCustomerProfileShow} = this.props;
    if (!readOnly) {
      doCustomerProfileShow();
    }
  }

  render() {
    const {
      participantCompany,
      participantEmail,
      participantIcon,
      participantName,
      participantPhone,
      readOnly,
      style
    } = this.props;

    return (
      <div
        data-test-id="customer-profile-card"
        onClick={this._onClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: `${spacing.medium} ${spacing.mlarge}`,
          minWidth: 0,
          [readOnly ? undefined : 'cursor']: 'pointer',
          ...style
        }}
      >
        <div style={{width: `${USER_AVATAR_SIZE}px`, height: `${USER_AVATAR_SIZE}px`}}>
          <UserAvatar
            data-test-id="customer-profile-avatar"
            size={USER_AVATAR_SIZE}
            src={participantIcon}
          />
        </div>

        <LegacyCustomerProfileCardText
          {...{
            participantCompany,
            participantEmail,
            participantName,
            participantPhone,
            readOnly
          }}
          style={{
            minWidth: 0
          }}
        />

        {readOnly ? null : (
          <NextIcon data-test-id="open-button" color={lightSlate} hoverColor={darkSmoke} />
        )}
      </div>
    );
  }
}

LegacyCustomerProfileCardComponent.contextTypes = context;

LegacyCustomerProfileCardComponent.propTypes = {
  participantCompany: PropTypes.string,
  participantEmail: PropTypes.string,
  participantIcon: PropTypes.string,
  participantName: PropTypes.string,
  participantPhone: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  doCustomerProfileShow: PropTypes.func.isRequired
};

export default LegacyCustomerProfileCardComponent;
