import {merge, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_FAILURE,
  CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_SUCCESS
} from '../../../../actions/customer-thread-canned-responses-actions';
import {CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';
import {CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-new-thread-actions';

const CustomerThreadCannedResponsesEpic =
  ({query}) =>
  (action$) => {
    // Shared with legacy
    const loadCannedResponses = action$.pipe(
      ofType(CUSTOMER_THREAD_CANNED_RESPONSES_LOAD),
      mergeMap(({data: {directedToBusinessIdentifier}}) =>
        query('get-canned-responses', {
          businessId: directedToBusinessIdentifier
        }).pipe(
          map(({data}) => ({
            type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_SUCCESS,
            payload: {
              data
            }
          })),
          catchError((error) =>
            of({
              type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD_FAILURE,
              error
            })
          )
        )
      )
    );

    const loadCannedResponsesOnLoadVisibleThreadSuccess = action$.pipe(
      ofType(CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS),
      filter(
        ({
          payload: {
            data: {
              conversation: {business, hasCannedResponses, readOnly}
            }
          }
        }) => hasCannedResponses && !readOnly && !business.deactivated
      ),
      map(
        ({
          payload: {
            data: {
              conversation: {business}
            }
          }
        }) => ({
          type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
          data: {
            directedToBusinessIdentifier: business.id
          }
        })
      )
    );

    const loadCannedResponsesOnLoadVisibleNewConversationSuccess = action$.pipe(
      ofType(CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS),
      map(({payload: {business}}) => ({
        type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
        data: {
          directedToBusinessIdentifier: business.businessId
        }
      }))
    );

    return merge(
      loadCannedResponses,
      loadCannedResponsesOnLoadVisibleThreadSuccess,
      loadCannedResponsesOnLoadVisibleNewConversationSuccess
    );
  };

export default CustomerThreadCannedResponsesEpic;
