import React from 'react';
import PropTypes from 'prop-types';
import AutomaticLoadMore from '../automatic-load-more-component';
import DateRow from './date-row-component';
import NewConversationRow from './new-conversation-row-component';
import LegacyMessagesRow from './legacy-messages-row-component';
import RedirectionRow from './redirection-row-component';
import TransferRow from './transfer-row-component';
import {lightSlate, white} from '../../../../../shared/style/colors';
import {getThreadContainerScrollableThreadMessagesListStyles} from './lib/message-helper';

const RENDERERS = {
  date: DateRow,
  message: LegacyMessagesRow,
  newConversation: NewConversationRow,
  redirection: RedirectionRow,
  transfer: TransferRow
};

const SIZE_INPUT_DIFF = 20; // 20 is the size that input grows

/**
 * Finally, the component.
 */
class LegacyScrollableThreadMessagesListComponent extends React.PureComponent {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    /* eslint-disable react/destructuring-assignment */
    const {direction, inputHeight, sending} = nextProps;

    const node = this.scrollRef;
    const scrollPosition = node.scrollTop - (node.scrollHeight - node.clientHeight);
    const scrolledBottom = !node.scrollTop && !direction;

    this.direction = direction;
    this.inputDiff = Math.abs(this.props.inputHeight - inputHeight);
    this.shouldScrollBottom = true;
    this.scrollMoved = scrollPosition < 0;
    this.scrollNotMoved = scrollPosition + (this.scrollMoved ? this.inputDiff : 0) === 0;
    this.scrollHeight = node.scrollHeight;
    this.scrollTop = node.scrollTop;
    this.inputGrowing = this.props.inputHeight < inputHeight;
    this.forceScrollToBottom =
      this.inputDiff === SIZE_INPUT_DIFF &&
      (!this.direction || this.direction === 'before') &&
      this.scrollNotMoved;

    if (!sending && direction === 'after') {
      this.shouldScrollBottom = false;
    } else {
      this.shouldScrollBottom =
        scrolledBottom || this.forceScrollToBottom || this.scrollNotMoved || sending;
    }
    /* eslint-enable react/destructuring-assignment */
  }

  componentDidUpdate() {
    const node = this.scrollRef;

    if (this.shouldScrollBottom) {
      if (this.direction === 'both') {
        const selectedMessageBox = node.getElementsByClassName('selected-message')[0];

        // Selected message may be absent during redraw
        if (!selectedMessageBox) {
          return;
        }

        const messagesBox = node.getBoundingClientRect();
        const selectedMessageBoxBounding = selectedMessageBox.getBoundingClientRect();
        node.scrollTop =
          selectedMessageBoxBounding.top -
          messagesBox.top -
          messagesBox.height / 2 +
          selectedMessageBoxBounding.height / 2;
      } else {
        node.scrollTop = node.scrollHeight;
      }
    } else if (this.direction === 'before') {
      let scrollTop = this.scrollTop + (node.scrollHeight - this.scrollHeight);

      if (!this.scrollNotMoved && this.inputDiff !== 0) {
        scrollTop += this.inputGrowing ? SIZE_INPUT_DIFF : -SIZE_INPUT_DIFF;
      }

      node.scrollTop = scrollTop;
    } else if (!this.scrollNotMoved) {
      let {scrollTop} = this;
      if (this.inputDiff !== 0) {
        scrollTop += this.inputGrowing ? SIZE_INPUT_DIFF : -SIZE_INPUT_DIFF;
      }

      node.scrollTop = scrollTop;
    }
  }

  _loadMoreAfter = () => {
    const {latestMessage, loadMore} = this.props;
    loadMore(latestMessage, 'after');
  };

  _loadMoreBefore = () => {
    const {oldestMessage, loadMore} = this.props;
    loadMore(oldestMessage, 'before');
  };

  _renderMessages(messages) {
    const {
      canShowIncomingAvatar,
      canShowIncomingSenderName,
      doSendMessageRetry,
      doThreadFilePreviewOpen,
      threadType
    } = this.props;

    return messages.map(({type, ...messageOtherProps}, rowIndex) => {
      const renderer = RENDERERS[type];

      if (!renderer) {
        return console.error(`unknown renderer type provided <${type}>`); // eslint-disable-line no-console
      }

      return (
        <div key={String(rowIndex)} data-type={type}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '2.5px 0'
            }}
          >
            {React.createElement(renderer, {
              canShowIncomingAvatar,
              canShowIncomingSenderName,
              doSendMessageRetry,
              doThreadFilePreviewOpen,
              threadType,
              ...messageOtherProps
            })}
          </div>
        </div>
      );
    });
  }

  render() {
    const {
      backgroundColor,
      dataTestId,
      hasMoreMessagesAfter,
      hasMoreMessagesBefore,
      i18n,
      inputHeight,
      loading,
      loadingAfter,
      loadingBefore,
      loadingHeight,
      messages,
      threadType
    } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        ref={(scrollElement) => {
          this.scrollRef = scrollElement;

          return this.scrollRef;
        }}
        style={getThreadContainerScrollableThreadMessagesListStyles({inputHeight, threadType})}
      >
        {loading ? (
          <div
            data-test-id="loading-conversation"
            style={{
              backgroundColor,
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              color: lightSlate
            }}
          >
            {i18n.t('thread.loadingConversation')}
          </div>
        ) : (
          <React.Fragment>
            {hasMoreMessagesBefore ? (
              <AutomaticLoadMore
                dataTestId="load-more-before"
                height={loadingHeight}
                loading={loadingBefore}
                loadMore={this._loadMoreBefore}
              />
            ) : null}
            {this._renderMessages(messages)}
            {hasMoreMessagesAfter ? (
              <AutomaticLoadMore
                dataTestId="load-more-after"
                height={loadingHeight}
                loading={loadingAfter}
                loadMore={this._loadMoreAfter}
              />
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

LegacyScrollableThreadMessagesListComponent.defaultProps = {
  backgroundColor: white,
  loadingHeight: 30
};

LegacyScrollableThreadMessagesListComponent.propTypes = {
  backgroundColor: PropTypes.string,
  canShowIncomingAvatar: PropTypes.bool,
  canShowIncomingSenderName: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
  direction: PropTypes.string,
  hasMoreMessagesAfter: PropTypes.bool,
  hasMoreMessagesBefore: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  inputHeight: PropTypes.number.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingAfter: PropTypes.bool,
  loadingBefore: PropTypes.bool.isRequired,
  loadingHeight: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  oldestMessage: PropTypes.string,
  sending: PropTypes.bool.isRequired,
  threadType: PropTypes.string,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired
};

export default LegacyScrollableThreadMessagesListComponent;
