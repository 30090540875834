import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {KeyboardArrowUp} from '@material-ui/icons';

const PaymentMinimizedComponent = ({
  amount,
  conversationId,
  doAppPaymentMaximizeButtonClick,
  i18n
}) => {
  const onMaximizeButtonClick = () => doAppPaymentMaximizeButtonClick(conversationId);

  return (
    <div
      data-test-id="app-payment-minimized"
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <h3
        style={{
          flex: 1,
          textAlign: 'center',
          marginLeft: '64px' // = width of "maximize" button on the right, so title remains centered
        }}
      >
        {amount
          ? i18n.t('customerThread.visibleThread.apps.list.payment.minimized.titleWithAmount', {
              amount
            })
          : i18n.t('customerThread.visibleThread.apps.list.payment.minimized.titleWithoutAmount')}
      </h3>
      <Button
        data-test-id="maximize-button"
        color="primary"
        onClick={onMaximizeButtonClick}
        size="small"
      >
        <KeyboardArrowUp />
      </Button>
    </div>
  );
};

PaymentMinimizedComponent.propTypes = {
  amount: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PaymentMinimizedComponent;
