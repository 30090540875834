import {merge} from 'rxjs';
import {delay, filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {TUTORIAL_CLOSE, TUTORIAL_RUN_NEXT} from '../../actions/tutorial-actions';

const TutorialEpic = () => (action$, state$) => {
  const chainEventualNextTutorial = action$.pipe(
    ofType(TUTORIAL_CLOSE),
    delay(0), // Mandatory to force whole redraw of tutorial with new configuration
    filter(() => state$.value.getIn(['tutorial', 'nexts']).size > 0),
    map(() => ({
      type: TUTORIAL_RUN_NEXT
    }))
  );

  return merge(chainEventualNextTutorial);
};

export default TutorialEpic;
