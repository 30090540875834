import PropTypes from 'prop-types';
import numeral from 'numeral';
import context from '../../../../../../shared/component/context';
import {
  generateChartSubtitle,
  generateChartTitle,
  getLinkedFigureAndVariation
} from '../lib/chart-title-generator';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {
  graphGreen2,
  graphGreen2Area,
  graphGreen3,
  graphGreen3Area
} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'newInboundConversations';

/**
 * Finally, the component.
 */
class InsightNewInboundConversationsComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      isSmallScreen,
      periodTabSelected,
      uiWidth,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    const {returningCustomers} = insight.newInboundConversationsPageHistogram;

    const isClassicPeriod = periodTabSelected === 'classic';
    const aggregationPeriod = isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod;

    const newInboundConversationsNumber = returningCustomers.data.newInboundConversations;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <InsightPanelContentBlock dataTestId="new-inbound-conversations">
            <HistogramChart
              failed={returningCustomers.failed}
              items={returningCustomers.data.returningCustomersHistogram}
              loading={returningCustomers.loading}
              stacking="normal"
              subtitleAlign="right"
              translations={i18n.t(
                `insight.pages.${INSIGHT_PAGE}.items.newInboundConversations.chart`,
                {returnObjects: true}
              )}
              {...{
                aggregationPeriod
              }}
              subtitle={
                returningCustomers.data.returningCustomersHistogram.length
                  ? generateChartSubtitle({
                      label: i18n.t(
                        `insight.pages.${INSIGHT_PAGE}.items.newInboundConversations.chart.secondTitle`
                      ),
                      showVariation: isClassicPeriod,
                      style: {
                        marginRight: '20px'
                      },
                      ...getLinkedFigureAndVariation(
                        newInboundConversationsNumber,
                        numeral(returningCustomers.data.returningCustomers).format(),
                        returningCustomers.data.returningCustomersIncrement
                      )
                    })
                  : null
              }
              title={generateChartTitle({
                figure: numeral(newInboundConversationsNumber).format(),
                hasData: returningCustomers.data.returningCustomersHistogram.length,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.items.newInboundConversations.chart.${
                    isSmallScreen || uiWidth > 1300 ? 'title' : 'titleSmall'
                  }`
                ),
                showVariation: isClassicPeriod,
                variation: returningCustomers.data.newInboundConversationsIncrement
              })}
              series={[
                {
                  property: 'returningCustomers',
                  color: graphGreen2,
                  fillColor: graphGreen2Area
                },
                {
                  property: 'newCustomers',
                  color: graphGreen3,
                  fillColor: graphGreen3Area
                }
              ]}
            />
          </InsightPanelContentBlock>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightNewInboundConversationsComponent.contextTypes = context;

InsightNewInboundConversationsComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any),
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  uiWidth: PropTypes.number.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightNewInboundConversationsComponent;
