import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import context from '../../../shared/component/context';
import {ContinueOrSignInLink} from '../../../shared/component/link/links-to-desktop-component';
import EmailField from '../../../shared/component/form/email-field-component';
import FormFieldsContainer from '../../../shared/component/form/fields-container-component';
import getErrorMessage from '../../../shared/lib/get-graphql-error-message';
import PageFormHandler from '../../../shared/component/page-form-handler-component';
import PublicForm from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';
import StandardRaisedButton from '../../../shared/component/button/standard-raised-button-component';

class ForgottenPasswordComponent extends PageFormHandler {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showError: {
        email: false
      }
    };

    this._doForgottenPasswordSubmitForm = this._doForgottenPasswordSubmitForm.bind(this);
  }

  _doForgottenPasswordSubmitForm() {
    const {email} = this.state;

    const isEmailValid = isEmail(email);

    this.setState(
      {
        showError: {
          email: !isEmailValid
        }
      },
      () => this.props.doForgottenPasswordSubmit(email, isEmailValid)
    );
  }

  render() {
    const {i18n} = this.context;
    const {emailSent, errors, language, submitting, doUIEndMount, doUIUpdateLanguage} = this.props;
    const {email, showError} = this.state;

    const title = i18n.t('forgottenPassword.title');

    return (
      <PublicPage
        dataTestId="forgotten-password"
        {...{
          language,
          title,
          doUIEndMount,
          doUIUpdateLanguage
        }}
      >
        {emailSent ? (
          <PublicForm
            buttons={<ContinueOrSignInLink />}
            description={i18n.t('forgottenPassword.emailSent')}
            descriptionDataTestId="email-sent"
            title={title}
          />
        ) : (
          <PublicForm
            doSubmit={this._doForgottenPasswordSubmitForm}
            description={i18n.t('forgottenPassword.description')}
            globalErrorMessage={getErrorMessage(errors, 'forgottenPassword', i18n)}
            submittingLabel={i18n.t('form.submitting')}
            {...{
              submitting,
              title
            }}
            buttons={
              submitting
                ? null
                : [
                    <StandardRaisedButton
                      key="submit"
                      label={i18n.t('forgottenPassword.submitButton')}
                      style={{width: 'auto'}}
                    />,
                    <ContinueOrSignInLink key="next" />
                  ]
            }
          >
            <FormFieldsContainer showRequired={false}>
              <EmailField
                autoFocus
                disabled={submitting}
                errorText={showError.email ? i18n.t('form.emailAddressRules') : null}
                onBlur={this._updateFieldErrorStatus}
                onChange={this._onFieldChange}
                onFocus={this._updateFieldErrorStatus}
                value={email}
              />
            </FormFieldsContainer>
          </PublicForm>
        )}
      </PublicPage>
    );
  }
}

ForgottenPasswordComponent.contextTypes = context;

ForgottenPasswordComponent.propTypes = {
  emailSent: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  language: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  doForgottenPasswordSubmit: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired
};

export default ForgottenPasswordComponent;
