export default {
  canShowTutorial: ({
    customerChannel,
    enableUIMessengerChannelFeature,
    enableUIWhatsAppChannelFeature,
    getTutorialIsShownFromLocalStorage
  }) => {
    // Please if update next conditions, keep it readable even if not optimized
    if (customerChannel === 'FACEBOOK') {
      return enableUIMessengerChannelFeature
        ? getTutorialIsShownFromLocalStorage({customerChannel})
        : false;
    }

    // All other channels icon tutorial is hidden behind whatsapp feature totorial
    return enableUIWhatsAppChannelFeature
      ? getTutorialIsShownFromLocalStorage({customerChannel})
      : false;
  },
  className: 'customer-thread-header-channel',
  getConfig: (getCommonStepOptions, persistTutorialAsShownInLocalStorage, options) => {
    const {customerChannel} = options;
    const i18nKeySuffix = ['FACEBOOK', 'WHATSAPP'].includes(customerChannel)
      ? customerChannel
      : 'default';

    return {
      options: {
        spotlightStyles: {
          borderRadius: 16
        }
      },
      persistPermanentlyAsShown: () => persistTutorialAsShownInLocalStorage({customerChannel}),
      steps: [
        {
          ...getCommonStepOptions(`app.tutorial.customerThread.headerChannelIcon.${i18nKeySuffix}`),
          spotlightPadding: 3
        }
      ]
    };
  },
  getLocalStorageKey: ({customerChannel}) => {
    const localStorageKeys = {
      default: 'instaplyDesktop.tutorial.customer.thread.header.channelIcon.default',
      FACEBOOK: 'instaplyDesktop.tutorial.customer.thread.header.channelIcon.facebook',
      WHATSAPP: 'instaplyDesktop.tutorial.customer.thread.header.channelIcon.whatsapp'
    };

    return localStorageKeys[customerChannel] || localStorageKeys.default;
  },
  key: 'customerThreadHeaderChannel'
};
