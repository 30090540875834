import jsCookie from 'js-cookie';

// Keep next functions in this file in order to share this defaults
const cookiesApi = jsCookie.withAttributes({
  domain: DOMAIN
});

/**
 * Delete a cookie.
 *
 * @param {String} name
 */
export function deleteCookie(name) {
  cookiesApi.remove(name);
}

/**
 * Get a cookie.
 *
 * @param {String} name
 */
export function getCookie(name) {
  return cookiesApi.get(name);
}

/**
 * Set a cookie.
 *
 * @param {String} name
 * @param {String} value
 * @param {Number} expires (in days)
 */
export function setCookie(name, value, expires) {
  const options = {
    secure: true
  };

  if (expires) {
    options.expires = expires;
  }

  cookiesApi.set(name, value, options);

  // Temporarily, until the end of old webapp migration over https
  if (ENVIRONMENT === 'development') {
    options.secure = false;
    cookiesApi.set(name, value, options);
  }
}
