/**
 * Get one/all employee's preference(s) related to desktop.
 * @param {String} key
 * @returns {Object|String}
 */
const getEmployeeDesktopPreferences = (key = null) => {
  const lsUserPrefs = localStorage.getItem('ls.userPrefs');
  let employeePreferences;

  if (lsUserPrefs) {
    employeePreferences = JSON.parse(lsUserPrefs);
  } else {
    employeePreferences = {
      notifAlreadyPrompted: false
    };
  }

  if (key) {
    return employeePreferences[key];
  }

  return employeePreferences;
};

/**
 * Update one/many employee's preference(s) related to desktop.
 * @param {Object} preferences
 */
const updateEmployeeDesktopPreferences = (preferences) => {
  localStorage.setItem(
    'ls.userPrefs',
    JSON.stringify({
      ...getEmployeeDesktopPreferences(),
      ...preferences
    })
  );
};

export {getEmployeeDesktopPreferences, updateEmployeeDesktopPreferences};
