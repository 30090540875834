import {INSIGHT_DOMAIN_TO_ACTION_TYPE_MAPPING_FOR_OPEN} from '../../../../../lib/insights/insight-actions-helper';
import {
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH,
  INSIGHT_CONNECTED_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONNECTED_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONNECTED_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_SHOW_MORE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_SHOW_MORE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACTS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONVERSATIONS_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK,
  INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD,
  INSIGHT_CONVERSATIONS_RANKING_SORT_BUTTON_CLICK,
  INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_SORT_BUTTON_CLICK,
  INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_MESSAGES_CSV_LOAD,
  INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_PERIOD_TAB_CHANGE,
  INSIGHT_PREFERRED_CHANNEL_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_PREFERRED_CHANNEL_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_PREFERRED_CHANNEL_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_RESPONSE_RATE_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK,
  INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_RESPONSE_TIME_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK,
  INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD,
  INSIGHT_RESPONSE_TIME_RANKING_SORT_BUTTON_CLICK,
  INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_SATISFACTION_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_SATISFACTION_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_SATISFACTION_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_TOP_STORES_CSV_LOAD,
  INSIGHT_TOP_STORES_SORT_BUTTON_CLICK,
  INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_WAITING_CUSTOMERS_CSV_LOAD
} from '../../../../../actions/insight-actions';

export default (insightPage) => (dispatch) => ({
  // Common to all pages
  doInsightBusinessFilterBusinessesSearch: (text) =>
    dispatch({
      type: INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH,
      text
    }),

  doPeriodTabChange: (newTab) =>
    dispatch({
      type: INSIGHT_PERIOD_TAB_CHANGE,
      insightPage,
      newTab
    }),

  // Depends of the page
  doInsightPageOpen: () =>
    dispatch({
      type: INSIGHT_DOMAIN_TO_ACTION_TYPE_MAPPING_FOR_OPEN[insightPage]
    }),

  doInsightBusinessesSelectionChangeFromPage: (businessesSelection) => {
    const domainToTypeMapping = {
      connectedCustomers: INSIGHT_CONNECTED_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
      contactCentersContacts: INSIGHT_CONTACT_CENTERS_CONTACTS_BUSINESSES_SELECTION_CHANGE,
      contactCentersUsers: INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE,
      contactCentersWebAHT: INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE,
      contactCentersWebConnectedUsers:
        INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE,
      contacts: INSIGHT_CONTACTS_BUSINESSES_SELECTION_CHANGE,
      conversations: INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
      devices: INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE,
      messages: INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE,
      newCustomers: INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
      newInboundConversations: INSIGHT_NEW_INBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
      newOutboundConversations: INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
      preferredChannel: INSIGHT_PREFERRED_CHANNEL_BUSINESSES_SELECTION_CHANGE,
      responseRate: INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE,
      responseTime: INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE,
      satisfaction: INSIGHT_SATISFACTION_BUSINESSES_SELECTION_CHANGE,
      waitingCustomers: INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE
    };

    return dispatch({
      type: domainToTypeMapping[insightPage],
      businessesSelection
    });
  },
  doPeriodTabClassicChangeDateAndPeriod: (classicAggregationDate, classicAggregationPeriod) => {
    const domainToTypeMapping = {
      connectedCustomers: INSIGHT_CONNECTED_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      contactCentersContacts: INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      contactCentersUsers: INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      contactCentersWebAHT: INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      contacts: INSIGHT_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      conversations: INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      devices: INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      messages: INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      newCustomers: INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      newInboundConversations: INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      newOutboundConversations:
        INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      preferredChannel: INSIGHT_PREFERRED_CHANNEL_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      responseRate: INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      responseTime: INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      satisfaction: INSIGHT_SATISFACTION_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
      topStores: INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE
    };

    return dispatch({
      type: domainToTypeMapping[insightPage],
      classicAggregationDate,
      classicAggregationPeriod
    });
  },
  doPeriodTabCustomChangeDatesAndPeriod: (
    customAggregationPeriod,
    customPeriodEndDate,
    customPeriodStartDate
  ) => {
    const domainToTypeMapping = {
      connectedCustomers: INSIGHT_CONNECTED_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      contactCentersContacts: INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      contactCentersUsers: INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      contactCentersWebAHT: INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      contacts: INSIGHT_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      conversations: INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      devices: INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      messages: INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      newCustomers: INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      newInboundConversations: INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      newOutboundConversations:
        INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      preferredChannel: INSIGHT_PREFERRED_CHANNEL_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      responseRate: INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      responseTime: INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
      satisfaction: INSIGHT_SATISFACTION_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
    };

    return dispatch({
      type: domainToTypeMapping[insightPage],
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate
    });
  },

  onClickRankingButtonShowAllItems: () => {
    const domainToTypeMapping = {
      conversations: INSIGHT_CONVERSATIONS_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK,
      responseRate: INSIGHT_RESPONSE_RATE_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK,
      responseTime: INSIGHT_RESPONSE_TIME_RANKING_BUTTON_SHOW_ALL_ITEMS_CLICK
    };

    return dispatch({
      type: domainToTypeMapping[insightPage],
      _googleAnalytics: true
    });
  },

  doInsightDownloadCsv: (classicAggregationDate, classicAggregationPeriod, businessesSelection) => {
    const domainToTypeMapping = {
      conversations: INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD,
      messages: INSIGHT_MESSAGES_CSV_LOAD,
      responseRate: INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD,
      responseTime: INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD,
      topStores: INSIGHT_TOP_STORES_CSV_LOAD,
      waitingCustomers: INSIGHT_WAITING_CUSTOMERS_CSV_LOAD
    };

    return dispatch({
      type: domainToTypeMapping[insightPage],
      _googleAnalytics: true,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod
    });
  },

  doInsightSortButtonClick: () => {
    const domainToTypeMapping = {
      conversations: INSIGHT_CONVERSATIONS_RANKING_SORT_BUTTON_CLICK,
      responseRate: INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_SORT_BUTTON_CLICK,
      responseTime: INSIGHT_RESPONSE_TIME_RANKING_SORT_BUTTON_CLICK,
      topStores: INSIGHT_TOP_STORES_SORT_BUTTON_CLICK
    };

    return dispatch({
      type: domainToTypeMapping[insightPage],
      _googleAnalytics: true
    });
  },

  // Depends of the page and the section
  doInsightLoad:
    (section) =>
    (
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      keyword,
      page,
      rowsPerPage
    ) => {
      const domainAndSectionToTypeMapping = {
        contactCentersUsers: {
          average_employee_logged_time:
            INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD,
          activity_per_business: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD,
          activity_per_employee: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD
        }
      };

      return dispatch({
        type: domainAndSectionToTypeMapping[insightPage][section],
        businessesSelection,
        classicAggregationDate,
        classicAggregationPeriod,
        customAggregationPeriod,
        customPeriodEndDate,
        customPeriodStartDate,
        keyword,
        page,
        rowsPerPage
      });
    },

  doInsightShowMore: (section) => () => {
    const domainAndSectionToTypeMapping = {
      contactCentersUsers: {
        activity_per_business: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_SHOW_MORE,
        activity_per_employee: INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_SHOW_MORE
      }
    };

    return dispatch({
      type: domainAndSectionToTypeMapping[insightPage][section]
    });
  },

  doInsightSectionKeywordChange: (section, keyword) => {
    const domainAndSectionToTypeMapping = {
      contactCentersUsers: {
        average_employee_logged_time:
          INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE
      }
    };

    return dispatch({
      type: domainAndSectionToTypeMapping[insightPage][section],
      keyword
    });
  },
  doInsightSectionResultPageChange: (section, resultPage) => {
    const domainAndSectionToTypeMapping = {
      contactCentersUsers: {
        average_employee_logged_time:
          INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE
      }
    };

    return dispatch({
      type: domainAndSectionToTypeMapping[insightPage][section],
      resultPage
    });
  },
  doInsightSectionRowsPerPageChange: (section, rowsPerPage) => {
    const domainAndSectionToTypeMapping = {
      contactCentersUsers: {
        average_employee_logged_time:
          INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE
      }
    };

    return dispatch({
      type: domainAndSectionToTypeMapping[insightPage][section],
      rowsPerPage
    });
  }
});
