// Black
exports.blackPure = '#000000';
exports.blackOpacity = 'rgba(0, 0, 0, 0.7)';

exports.black = '#18222E';
exports.black90Opacity = 'rgba(24, 34, 46, 0.9)';

// Blue
const blue = '#0093FF';

exports.aliceBlue = '#EDF7FF';
exports.lightBlue = '#E5F4FE';
exports.blue = blue;
exports.darkBlue = '#007CD7';
exports.brightNavyBlue = '#1A73E8';

// Green
exports.honeydew = '#EBFBF1';
exports.transparentLightGreen = '#EAF1E7';
exports.veryLightGreen = '#E1F6CB';
exports.lightGreen = '#85DE55';
exports.mediumGreen = '#8BCC03';
exports.green = '#13CE66';
exports.smokeGreen = '#E0EBDD';

// Grey
exports.veryLightGrey = '#ECECEC';
exports.lightGrey = '#d9dfe2';
exports.grey = '#979797';
exports.mediumGrey = '#333333';
exports.darkGrey = '#6A6A6A';
exports.veryDarkGrey = '#7A7A7A';
exports.davysGrey = '#575757';
exports.americanSilver = '#CCCDD1';
exports.platinum = '#E3E3E3';
exports.sonicSilver = '#7A7A7A';
exports.antiFlashWhite = '#F1F3F4';

// Orange
exports.darkOrange = '#FF5216';

// Purple
const purple = '#CE048B';

exports.purple = purple;

// Rose
exports.mistyRose = '#FFDFDF';

// Red
exports.veryLightRed = '#FFD1BE';
exports.lightRed = '#FF9E7C';
exports.red = '#FF4949';
exports.darkRed = '#DB124E';

// Slate
exports.lightSlate = '#78869B';
exports.slate = '#3C4858';
exports.darkSlate = '#273444';

// Smoke
exports.lightSmoke = '#E0E6ED';
exports.smoke = '#D3DCE6';
exports.darkSmoke = '#C0CCDA';
exports.whiteSmoke = '#f6f6f6';

// Snow
exports.veryLightSnow = '#FBFDFE';
exports.lightSnow = '#F9FAFC';
exports.mediumSnow = '#F2F3EF';
exports.snow = '#EFF2F7';
exports.darkSnow = '#E5E9F2';

// White
exports.white = '#FFFFFF';
exports.white95Opacity = 'rgba(255, 255, 255, 0.95)';

// Yellow
exports.cosmicLatte = '#FFF9E6';
exports.lightYellow = '#FFD55F';
exports.yellow = '#FFC82C';
exports.darkYellow = '#F8B700';

// Graph
exports.graphBlue = '#3D6A97';
exports.graphBlueArea = 'rgb(61, 106, 151, 0.8)';

exports.graphBlue2Area = 'rgb(0, 147, 255, 0.8)';
exports.graphBlue2Main = blue;

exports.graphBlue3Area = 'rgb(0, 3, 255, 0.6)';
exports.graphBlue3Main = '#0003FF';

exports.graphBlue4Main = 'rgb(203,235,255, 0.8)';

exports.graphElectricPurple = '#BD00FF';
exports.graphGreen = '#39D973';
exports.graphGreenArea = 'rgba(208, 249, 227, 0.8)';

exports.graphGreen2 = '#30BA62';
exports.graphGreen2Area = 'rgba(163, 224, 185, 0.8)';

exports.graphGreen3 = '#6EFFA3';
exports.graphGreen3Area = 'rgba(229, 255, 238, 0.8)';

exports.graphRed = '#FA7B7E';
exports.graphRedArea = 'rgba(255, 223, 223, 0.8)';
exports.graphRedMain = '#FF4949';

exports.graphPhilippineGray = '#8C9095';

exports.graphYellow = '#FFC900';
exports.graphYellowArea = 'rgba(255, 241, 188, 0.8)';

exports.graphColor1 = '#99C9E7';
exports.graphColor2 = '#AAF3D0';
exports.graphColor3 = '#C6CE08';
exports.graphColor4 = '#8752A2';
exports.graphColor5 = purple;
exports.graphColor6 = '#F9B69D';
