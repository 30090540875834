import {useState} from 'react';
import PropTypes from 'prop-types';
import InvisibleIcon from 'material-ui/svg-icons/action/visibility-off';
import context from '../../../../../../shared/component/context';
import InputMessage, {
  INITIAL_INPUT_MESSAGE_HEIGHT
} from '../../../common/input-message/input-message-component';
import RightPanelColumnLayout from '../../../common/layout/right-panel-column-layout-component';
import ScrollableThreadMessagesList from '../../../common/thread/scrollable-thread-messages-list-component';
import MentionComponent from './invisible-thread-mention-component';
import ThreadInteractionArea from '../../../common/thread/thread-interaction-area-component';
import {fontSize, fontWeight, lineHeight, spacing} from '../../../../../../shared/style/variables';
import {black, mediumGreen} from '../../../../../../shared/style/colors';

const CustomerInvisibleThreadComponent = (props, {i18n}) => {
  const [inputHeight, setInputHeight] = useState(INITIAL_INPUT_MESSAGE_HEIGHT);

  const loadMore = (messageCursor, loadDirection) => {
    const {customerId, doThreadInvisibleLoad, participationId} = props;
    doThreadInvisibleLoad(participationId, customerId, messageCursor, loadDirection);
  };

  const onInteractionAreaResize = ({height}) => {
    setInputHeight(height);
  };

  const renderHeader = () => (
    <div
      data-test-id="invisible-thread-header"
      style={{
        minHeight: '50px',
        height: '50px',
        borderCollapse: 'collapse',
        display: 'flex'
      }}
    >
      <div
        style={{
          flex: 2,
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          margin: '0 15px'
        }}
      >
        <InvisibleIcon style={{width: '20px', height: '20px'}} color={mediumGreen} />
        <h1
          data-test-id="invisible-thread-title"
          style={{
            fontWeight: fontWeight.semiBold,
            marginLeft: spacing.large,
            fontSize: fontSize.medium,
            lineHeight: lineHeight.large,
            color: black
          }}
        >
          {i18n.t('customerThread.invisibleThread.title')}
        </h1>
      </div>
    </div>
  );

  const sendConversationInternalMesssage = ({text}) => {
    const {onConversationInternalSendMessageButtonClick, threadForm} = props;
    const mentionedUser = threadForm.userToMention;
    const attachment = threadForm.fileUploaded;
    onConversationInternalSendMessageButtonClick({attachment, mentionedUser, text});
  };

  const renderScrollableThreadMessagesList = () => {
    const {
      account,
      conversationItemsBatches,
      direction,
      doThreadFilePreviewOpen,
      hasNextPage,
      hasPreviousPage,
      latestMessage,
      loading,
      loadingAfter,
      loadingBefore,
      oldestMessage,
      sending,
      onConversationInternalSendMessageRetryButtonClick
    } = props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 50px)',
          minHeight: '1px',
          width: '100%',
          flex: 1
        }}
      >
        <ScrollableThreadMessagesList
          dataTestId="invisible-thread-result"
          canShowIncomingSenderName
          doSendMessageRetry={onConversationInternalSendMessageRetryButtonClick}
          threadType="invisible"
          {...{
            account,
            conversationItemsBatches,
            direction,
            doThreadFilePreviewOpen,
            hasNextPage,
            hasPreviousPage,
            i18n,
            inputHeight,
            latestMessage,
            loading,
            loadingAfter,
            loadingBefore,
            loadMore,
            oldestMessage,
            sending
          }}
        />
      </div>
    );
  };

  const renderInteractionArea = () => {
    const {
      business,
      disableThreadInputPersistence,
      doConversationInternalFileRemoveButtonClick,
      doConversationInternalFileUpload,
      isActiveThreadType,
      isHistoryAction,
      mention,
      onConversationInternalMessageFormTextChanged,
      sending,
      threadForm,
      uploadingFile,
      doColleagueInvitationPopupOpen,
      doConversationInternalMentionPopupClose,
      doConversationInternalMentionPopupOpen,
      doConversationInternalMentionPopupSearch,
      doConversationInternalMentionPopupSelect,
      doConversationInternalMentionUnselect
    } = props;

    return (
      <ThreadInteractionArea onResize={onInteractionAreaResize}>
        <InputMessage
          dataTestId="message-invisible-input"
          doRemoveFile={doConversationInternalFileRemoveButtonClick}
          doSendMessage={sendConversationInternalMesssage}
          footerHasFocus={isActiveThreadType}
          mention={
            <MentionComponent
              userToMention={threadForm.userToMention}
              {...mention}
              {...{
                business,
                doColleagueInvitationPopupOpen,
                doConversationInternalMentionPopupClose,
                doConversationInternalMentionPopupOpen,
                doConversationInternalMentionPopupSearch,
                doConversationInternalMentionPopupSelect,
                doConversationInternalMentionUnselect
              }}
            />
          }
          onSelectFile={doConversationInternalFileUpload}
          onTextChanged={onConversationInternalMessageFormTextChanged}
          placeholder={i18n.t('customerThread.invisibleThread.inputMessagePlaceHolder')}
          threadType="invisible"
          {...{
            disableThreadInputPersistence,
            isActiveThreadType,
            isHistoryAction,
            sending,
            threadForm,
            uploadingFile
          }}
        />
      </ThreadInteractionArea>
    );
  };

  const {loading, readOnly} = props;

  return (
    <RightPanelColumnLayout dataTestId="invisible-thread">
      {renderHeader()}
      {renderScrollableThreadMessagesList()}
      {loading || readOnly ? null : renderInteractionArea()}
    </RightPanelColumnLayout>
  );
};

CustomerInvisibleThreadComponent.contextTypes = context;

CustomerInvisibleThreadComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.objectOf(PropTypes.any),
  conversationItemsBatches: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerId: PropTypes.string,
  direction: PropTypes.string,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  isActiveThreadType: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingAfter: PropTypes.bool,
  loadingBefore: PropTypes.bool.isRequired,
  mention: PropTypes.objectOf(PropTypes.any).isRequired,
  oldestMessage: PropTypes.string,
  participationId: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doConversationInternalFileRemoveButtonClick: PropTypes.func.isRequired,
  doConversationInternalFileUpload: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  doThreadInvisibleLoad: PropTypes.func.isRequired,
  doConversationInternalMentionPopupClose: PropTypes.func.isRequired,
  doConversationInternalMentionPopupOpen: PropTypes.func.isRequired,
  doConversationInternalMentionPopupSearch: PropTypes.func.isRequired,
  doConversationInternalMentionPopupSelect: PropTypes.func.isRequired,
  doConversationInternalMentionUnselect: PropTypes.func.isRequired,
  onConversationInternalMessageFormTextChanged: PropTypes.func.isRequired,
  onConversationInternalSendMessageButtonClick: PropTypes.func.isRequired,
  onConversationInternalSendMessageRetryButtonClick: PropTypes.func.isRequired
};

export default CustomerInvisibleThreadComponent;
