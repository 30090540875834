import moment from 'moment';
import getAggregationStartDateFromPeriod from '../../../../lib/insights-get-aggregation-start-date-from-period';
import getMomentUnitFromPeriod from '../../../../lib/get-moment-unit-from-period';

const DATE_TIME_NO_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss';

/**
 * Prepare params for an insight graphql request.
 * @param {Object} state$
 * @param {Array} [businessesSelection]
 * @param {Moment} [classicAggregationDate]
 * @param {String} [classicAggregationPeriod]
 * @param {String} [customAggregationPeriod]
 * @param {Moment} [customPeriodEndDate]
 * @param {Moment} [customPeriodStartDate]
 * @param {Boolean} [forceStartDate]
 * @param {String} [orderBy]
 * @param {Boolean} [getAll]
 * @returns {Object} {aggregationPeriod, businessIds, endDate, startDate}
 */
export default (
  state$,
  {
    businessesSelection,
    classicAggregationDate,
    classicAggregationPeriod,
    customAggregationPeriod,
    customPeriodEndDate,
    customPeriodStartDate,
    forceStartDate = false,
    getAll = false,
    orderBy
  }
) => {
  const params = {};

  // 1. Businesses selection

  if (businessesSelection) {
    params.businessIds = businessesSelection;
  }

  // 2. Dates

  if (classicAggregationDate || customPeriodStartDate) {
    // Get dates from state
    if (state$.value.getIn(['insight', 'periodTabSelected']) === 'classic') {
      const startDate = getAggregationStartDateFromPeriod(
        classicAggregationPeriod,
        classicAggregationDate
      );

      params.aggregationPeriod = classicAggregationPeriod;

      if (forceStartDate) {
        params.startDate = startDate;
      }

      params.endDate = startDate.clone().add(1, getMomentUnitFromPeriod(classicAggregationPeriod));
    } else {
      params.aggregationPeriod = customAggregationPeriod;

      params.startDate = customPeriodStartDate;

      params.endDate = customPeriodEndDate.clone().set({hour: 23, minute: 59, second: 59});
    }

    // Never send an end date in future
    const now = moment();
    if (params.endDate > now) {
      params.endDate = now;
    }

    // Format date parameters as expected by backend
    if (params.startDate) {
      params.startDate = params.startDate.format(DATE_TIME_NO_TIMEZONE);
    }

    params.endDate = params.endDate.format(DATE_TIME_NO_TIMEZONE);
  }

  if (orderBy) {
    params.orderBy = orderBy;
    params.getAll = getAll;
  }

  return params;
};
