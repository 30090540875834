import {useEffect} from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import AdministrationBusinessContainer from '../../../container/content/administration/business/administration-business-container';
import AdministrationLeftMenu from './left-menu/administration-left-menu-component';
import AdministrationOrganizationChannelsContainer from '../../../container/content/administration/organization/channels/administration-organization-channels-container';
import ContentLayout from '../../common/layout/content-layout-component';
import EnhancedSwitch, {Error404} from '../../common/enhanced-switch-component';

const AdministrationComponent = (
  {
    account,
    administrationLayoutState: {currentPageContext, organizationCountries},
    enableBusinessMessages,
    enableIpRanges,
    language,
    location,
    match,
    onAdministrationLayoutMounted
  },
  {i18n}
) => {
  if (!account.manager && !account.managerOfABusiness) {
    return <Error404 />;
  }

  useEffect(() => {
    onAdministrationLayoutMounted();
  }, []);

  return (
    <ContentLayout data-test-id="administration-content">
      <AdministrationLeftMenu
        {...{
          account,
          currentPageContext,
          enableBusinessMessages,
          enableIpRanges,
          i18n,
          language,
          location,
          organizationCountries
        }}
      />

      <EnhancedSwitch>
        <Route
          component={AdministrationBusinessContainer}
          exact
          path={`${match.url}/business/:businessId/:businessPageName`}
        />
        <Route
          component={AdministrationOrganizationChannelsContainer}
          path={`${match.url}/organization/channels`}
        />
      </EnhancedSwitch>
    </ContentLayout>
  );
};

AdministrationComponent.contextTypes = context;

AdministrationComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  administrationLayoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  enableBusinessMessages: PropTypes.bool.isRequired,
  enableIpRanges: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  onAdministrationLayoutMounted: PropTypes.func.isRequired
};

export default AdministrationComponent;
