import PropTypes from 'prop-types';
import {blue} from '../../style/colors';

const BubbleIcon = ({containerStyle, iconName, iconStyle}) => (
  <div
    style={{
      background: blue,
      borderRadius: '50%',
      height: '25px',
      width: '25px',
      ...containerStyle
    }}
  >
    <img
      alt=""
      src={`${ASSETS_PATH}/img/icon/thread-footer/${iconName}.svg`}
      style={{width: '16px', ...iconStyle}}
    />
  </div>
);

BubbleIcon.propTypes = {
  containerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  iconName: PropTypes.string.isRequired,
  iconStyle: PropTypes.objectOf(PropTypes.any).isRequired
};

export default BubbleIcon;
