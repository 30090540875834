import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import ClickableCappedLoader from '../../../common/loader/clickable-capped-loader-component';
import CustomerInboxThreadListItem from '../../../common/inbox/customer-thread-list-item-component';
import EmptyMessage from '../../../common/empty-message-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import {
  renderResultItemForCustomerLegacy,
  renderResultItemForMessageLegacy
} from './lib/search-list-legacy-helper';
import {spacing} from '../../../../../../shared/style/variables';
import {lightSmoke} from '../../../../../../shared/style/colors';

class SearchListComponent extends PureComponent {
  getRenderListItem =
    (itemType) =>
    ({indexInList, list, onResultItemClick, props, selected}) => {
      const {i18n, muiTheme} = this.context;

      const isLastChild = !list[indexInList + 1];

      const SEARCH_ITEM_COMMON_WRAPPER_HEIGHT = '100px';

      return (
        <CustomerInboxThreadListItem
          centerWrapperStyle={{height: SEARCH_ITEM_COMMON_WRAPPER_HEIGHT}}
          rightWrapperStyle={{height: SEARCH_ITEM_COMMON_WRAPPER_HEIGHT}}
          isSelected={props.node[itemType].id === selected}
          key={indexInList}
          node={props.node}
          onItemClick={onResultItemClick}
          {...{
            i18n,
            indexInList,
            isLastChild,
            muiTheme
          }}
        />
      );
    };

  _renderList({
    data,
    domain,
    onLoadMoreButtonClick,
    onResultItemClick,
    resultItemRenderer,
    selected,
    style
  }) {
    if (data.items.length === 0) {
      return null;
    }

    const {i18n} = this.context;
    const {headerStyle} = this.props;

    const dataTestId = {
      customers: 'customers-list',
      messages: 'messages-list'
    }[domain];

    return (
      <div data-test-id={dataTestId}>
        <div
          data-test-id="header"
          style={{
            ...headerStyle,
            ...style
          }}
        >
          <strong>{i18n.t(`customerInbox.header.search.header.${domain}`)}</strong>
        </div>
        <div data-test-id="search-inbox-result">
          {data.items.map((props, indexInList, list) =>
            resultItemRenderer({
              hasMore: data.hasMore,
              indexInList,
              list,
              onResultItemClick,
              props,
              selected
            })
          )}
        </div>
        <ClickableCappedLoader
          hasMore={data.hasMore}
          hasReachedLimit={data.hasReachedLimit}
          limitText={i18n.t('inbox.searchInboxLimit')}
          loading={data.loading}
          loadMore={onLoadMoreButtonClick}
        />
      </div>
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      customers,
      enableMultistoreConversation,
      messages,
      selectedCustomerId,
      onInboxSearchMoreCustomersButtonClick,
      onInboxSearchMoreMessagesButtonClick,
      onInboxSearchResultCustomerClick,
      onInboxSearchResultMessageClick
    } = this.props;

    const hasResults = customers.items.length > 0 || messages.items.length > 0;

    if (!hasResults) {
      const isLoadingAll = customers.loading && messages.loading;

      if (isLoadingAll) {
        return <LoadingIndicator />;
      }

      return (
        <EmptyMessage
          dataTestId="inbox-search-result-empty-message"
          title={i18n.t('customerInbox.header.search.noResult')}
        />
      );
    }

    let resultCustomerRenderer;
    let resultMessageRenderer;
    if (enableMultistoreConversation) {
      resultCustomerRenderer = this.getRenderListItem('customer');
      resultMessageRenderer = this.getRenderListItem('message');
    } else {
      resultCustomerRenderer = renderResultItemForCustomerLegacy;
      resultMessageRenderer = renderResultItemForMessageLegacy;
    }

    return (
      <span
        style={{
          flex: 1,
          minHeight: '1px',
          height: '100%',
          overflowY: 'scroll',
          paddingLeft: spacing.medium,
          paddingRight: spacing.medium,
          WebkitOverflowScrolling: 'touch'
        }}
      >
        {this._renderList({
          data: customers,
          domain: 'customers',
          onLoadMoreButtonClick: onInboxSearchMoreCustomersButtonClick,
          onResultItemClick: onInboxSearchResultCustomerClick,
          resultItemRenderer: resultCustomerRenderer,
          selected: selectedCustomerId
        })}

        {this._renderList({
          data: messages,
          domain: 'messages',
          onLoadMoreButtonClick: onInboxSearchMoreMessagesButtonClick,
          onResultItemClick: onInboxSearchResultMessageClick,
          resultItemRenderer: resultMessageRenderer,
          selected: messages.selected,
          style: {
            borderTop: customers.items.length ? `1px solid ${lightSmoke}` : 'none'
          }
        })}
      </span>
    );
  }
}

SearchListComponent.contextTypes = context;

SearchListComponent.propTypes = {
  customers: PropTypes.objectOf(PropTypes.any).isRequired,
  enableMultistoreConversation: PropTypes.bool.isRequired,
  headerStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  messages: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCustomerId: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onInboxSearchMoreCustomersButtonClick: PropTypes.func.isRequired,
  onInboxSearchMoreMessagesButtonClick: PropTypes.func.isRequired,
  onInboxSearchResultCustomerClick: PropTypes.func.isRequired,
  onInboxSearchResultMessageClick: PropTypes.func.isRequired
};

export default SearchListComponent;
