import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {deletePersistedState} from '../../../../lib/persistence-helper';
import {APP_LOGOUT} from '../../../../../actions/app-actions';
import {
  LEGACY_CUSTOMER_THREAD_APPS_AREA_MAXIMIZE,
  LEGACY_CUSTOMER_THREAD_APPS_AREA_MINIMIZE,
  LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT
} from '../../../../../actions/customer-thread-apps-actions';
import {
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS
} from '../../../../../actions/customer-thread-app-payment-actions';
import {EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS} from '../../../../../actions/ext-actions';
import {paymentSteps} from '../../../../../data/thread/payment-steps';

export const DEFAULT_STATE = {
  threadsForm: {}
};

const deleteOngoingPayment = (state, {participationId}) => {
  return state.deleteIn(['threadsForm', `${participationId}`]);
};

const minimizePayment = (state, {participationId}) => {
  return state.setIn(['threadsForm', participationId, 'minimized'], true);
};

const moveIntoPaymentSteps =
  (increment) =>
  (state, {participationId}) => {
    const currentStepIndex = paymentSteps.indexOf(
      state.getIn(['threadsForm', participationId, 'paymentStep'])
    );

    return state.setIn(
      ['threadsForm', participationId, 'paymentStep'],
      paymentSteps[currentStepIndex + increment]
    );
  };

export default createReducer(DEFAULT_STATE, {
  /**
   * Set as initializing as soon as user select "Payment" in apps menu.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT]: (state, {participationId}) => {
    return state.setIn(['threadsForm', participationId, 'initializing'], true);
  },

  /**
   * Loading PSP.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD]: (state, {participationId}) => {
    return state.updateIn(['threadsForm', participationId], (threadState) =>
      threadState.set('initializing', false).set('loadingPspAccount', true)
    );
  },

  /**
   * Failure of PSP loading.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_FAILURE]: (state, {participationId}) => {
    return state.setIn(['threadsForm', participationId, 'loadingPspAccount'], false);
  },

  /**
   * Success of PSP loading.
   * @param {Object} state
   * @param {Object} customer
   * @param {String} participationId
   * @param {Object} pspAccount
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CONFIGURATION_LOAD_SUCCESS]: (
    state,
    {customer, participationId, pspAccount}
  ) => {
    return state.setIn(
      ['threadsForm', participationId],
      fromJS({
        amount: '',
        comment: '',
        minimized: false,
        paymentStep: 'amount',
        submitting: false,
        customer,
        pspAccount
      })
    );
  },

  /**
   * Change amount.
   * @param {Object} state
   * @param {Number} amount
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE]: (state, {amount, participationId}) => {
    return state.setIn(['threadsForm', participationId, 'amount'], amount);
  },

  /**
   * Change custom comment.
   * @param {Object} state
   * @param {String} comment
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE]: (state, {comment, participationId}) => {
    return state.setIn(['threadsForm', participationId, 'comment'], comment);
  },

  /**
   * Change customer data first name and last name.
   * @param {Object} state
   * @param {Object} customerData
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE]: (
    state,
    {customerData, participationId}
  ) => {
    return state.setIn(['threadsForm', participationId, 'customer'], fromJS(customerData));
  },

  /**
   * Move step back in app "Payment" area.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK]: moveIntoPaymentSteps(-1),

  /**
   * Move step forward in app "Payment" area.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_STEP_MOVE]: moveIntoPaymentSteps(+1),

  /**
   * Maximize app "Payment".
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK]: (state, {participationId}) => {
    return state.setIn(['threadsForm', participationId, 'minimized'], false);
  },

  /**
   * Minimize app "Payment" area.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK]: minimizePayment,
  [LEGACY_CUSTOMER_THREAD_APPS_AREA_MINIMIZE]: minimizePayment,

  /**
   * Maximize app "Payment" area.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APPS_AREA_MAXIMIZE]: (state, {participationId}) => {
    return state.updateIn(['threadsForm', participationId], (threadState) =>
      threadState.set('initializing', false).set('minimized', false)
    );
  },

  /**
   * Cancel ongoing app "Payment".
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_FLOW_CANCEL]: deleteOngoingPayment,
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_SUCCESS]: deleteOngoingPayment,
  [EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS]: deleteOngoingPayment,

  /**
   * Submit app "Payment".
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT]: (state, {participationId}) => {
    return state.setIn(['threadsForm', participationId, 'submitting'], true);
  },

  /**
   * Submit app "Payment" ended.
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_APP_PAYMENT_SUBMIT_FAILURE]: (state, {participationId}) => {
    return state
      .setIn(['threadsForm', participationId, 'submitHadError'], true)
      .setIn(['threadsForm', participationId, 'submitting'], false);
  },

  /**
   * Logout drop all ongoing payments.
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_LOGOUT]: deletePersistedState
});
