import PropTypes from 'prop-types';
import StandardButton from '../../../../../../../shared/component/button/standard-button-component';
import ThreadTakenByColleagueDescription from './thread-taken-by-colleague-description-component';
import {spacing} from '../../../../../../../shared/style/variables';
import {lightSmoke} from '../../../../../../../shared/style/colors';

const LegacyThreadAssignmentInputOverlayComponent = ({
  assignedTo,
  i18n,
  inScope,
  isAssignedToMe,
  onThreadTakeButtonClick
}) => (
  <div
    style={{
      height: inScope && assignedTo ? '87px' : '67px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: `1px solid ${lightSmoke}`,
      padding: spacing.medium
    }}
  >
    {inScope ? (
      <StandardButton
        data-test-id="take-button"
        onClick={onThreadTakeButtonClick}
        variant="outlined"
      >
        {assignedTo
          ? i18n.t('customerThread.visibleThread.assignment.takeIfAssignedButton')
          : i18n.t('customerThread.visibleThread.assignment.takeIfNotAssignedButton')}
      </StandardButton>
    ) : (
      <span data-test-id="out-of-scope-message">
        {i18n.t('customerThread.visibleThread.assignment.threadOutOfScope')}
      </span>
    )}

    {assignedTo && !isAssignedToMe ? (
      <ThreadTakenByColleagueDescription
        {...{
          assignedTo,
          i18n
        }}
      />
    ) : null}
  </div>
);

LegacyThreadAssignmentInputOverlayComponent.propTypes = {
  assignedTo: PropTypes.objectOf(PropTypes.any),
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  inScope: PropTypes.bool,
  isAssignedToMe: PropTypes.bool.isRequired, // useful in case of user transfers out of scope a thread assigned to him
  onThreadTakeButtonClick: PropTypes.func
};

export default LegacyThreadAssignmentInputOverlayComponent;
