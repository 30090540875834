import PropTypes from 'prop-types';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import {spacing} from '../../../../../shared/style/variables';
import {lightSmoke, lightSnow, lightSlate} from '../../../../../shared/style/colors';

const CappedLoaderComponent = ({
  getLoadingTriggerComponent,
  hasMore,
  hasReachedLimit,
  loading,
  loadMore,
  limitText,
  triggerParams
}) => {
  if (hasReachedLimit) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          color: lightSlate,
          padding: spacing.large,
          backgroundColor: lightSnow,
          border: `1px solid ${lightSmoke}`,
          borderLeft: 0,
          borderRight: 0
        }}
      >
        {limitText}
      </div>
    );
  }

  if (loading || hasMore) {
    return (
      <div
        style={{
          height: '50px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {loading ? <LoadingIndicator /> : getLoadingTriggerComponent({loadMore, ...triggerParams})}
      </div>
    );
  }

  return <span style={{height: '30px'}} />;
};

CappedLoaderComponent.propTypes = {
  getLoadingTriggerComponent: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  hasReachedLimit: PropTypes.bool.isRequired,
  limitText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  triggerParams: PropTypes.objectOf(PropTypes.any)
};

export default CappedLoaderComponent;
