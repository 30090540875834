import PropTypes from 'prop-types';
import AdministrationBusinessPageChannelsConnectHeader from './administration-business-page-channels-connect-header-component';

const AdministrationBusinessPageChannelsConnectLayoutComponent = ({
  children,
  icon,
  title,
  wrapperStyle,
  ...otherProps
}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1em',
      paddingBottom: '1em',
      ...wrapperStyle
    }}
    {...otherProps}
  >
    <AdministrationBusinessPageChannelsConnectHeader {...{icon, title}} />

    {children}
  </div>
);

AdministrationBusinessPageChannelsConnectLayoutComponent.propTypes = {
  'data-test-id': PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.objectOf(PropTypes.any)
};

export default AdministrationBusinessPageChannelsConnectLayoutComponent;
