import PropTypes from 'prop-types';
import {lightSmoke} from '../../../../../../../../shared/style/colors';

const VisibleThreadAppsAreaLayoutComponent = ({children, showBorder = true, style}) => (
  <div
    style={{
      minHeight: '35px',
      borderTop: `1px solid ${lightSmoke}`,
      ...(showBorder ? {borderRadius: '10px', boxShadow: '0 -3px 3px -3px rgb(0,0,0,0.12)'} : null),
      ...style
    }}
  >
    {children}
  </div>
);
VisibleThreadAppsAreaLayoutComponent.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  showBorder: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any)
};

export default VisibleThreadAppsAreaLayoutComponent;
