/**
 * Get insight params from a state.
 * @param {Object} state$
 * @param {String} [statePropertyName]
 * @returns {{businessesSelection, classicAggregationDate, classicAggregationPeriod, customAggregationPeriod, customPeriodEndDate, customPeriodStartDate, sortedDirection}}
 */
export default (state$, statePropertyName = 'topStores') => {
  const insightState = state$.value.get('insight');

  return {
    businessesSelection: insightState.get('businessesSelection').toJS(),
    classicAggregationDate: insightState.get('classicAggregationDate'),
    classicAggregationPeriod: insightState.get('classicAggregationPeriod'),
    customAggregationPeriod: insightState.get('customAggregationPeriod'),
    customPeriodEndDate: insightState.get('customPeriodEndDate'),
    customPeriodStartDate: insightState.get('customPeriodStartDate'),
    sortedDirection: insightState.getIn([statePropertyName, 'ranking', 'sortedDirection'])
  };
};
