import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Input, Select} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {spacing} from '../../style/variables';
import {slate} from '../../style/colors';

const StyledInput = withStyles({
  underline: {
    // change background transparency on hover to keep a visual effect
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s'
    },
    // remove all underline
    '&::after': {
      borderBottom: 'none !important'
    },
    '&::before': {
      borderBottom: 'none !important'
    }
  }
})(Input);

const StyledSelect = withStyles({
  select: {
    paddingLeft: spacing.small,
    // remove default background when focused
    '&:focus': {
      background: 'inherit'
    }
  },
  selectMenu: {
    color: slate,
    fontSize: 'inherit'
  }
})(Select);

/**
 * Finally, the component.
 */
class SelectNeutralComponent extends PureComponent {
  render() {
    const {children, ...otherProps} = this.props;

    return (
      <StyledSelect IconComponent={ExpandMoreIcon} input={<StyledInput />} {...otherProps}>
        {children}
      </StyledSelect>
    );
  }
}

SelectNeutralComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default SelectNeutralComponent;
