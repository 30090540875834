import PropTypes from 'prop-types';
import Favicon from 'react-favicon';

const FaviconIndicatorComponent = ({actionNotificationType, windowFocused}) => {
  const faviconUrl = {
    notification: '/favicon/favicon-alert.png',
    default: '/favicon/favicon-96.png'
  };
  const canShowFaviconDot = !windowFocused && !!actionNotificationType;

  if (canShowFaviconDot) {
    return <Favicon url={faviconUrl.notification} />;
  }

  return <Favicon url={faviconUrl.default} />;
};

FaviconIndicatorComponent.propTypes = {
  actionNotificationType: PropTypes.string,
  windowFocused: PropTypes.bool.isRequired
};

export default FaviconIndicatorComponent;
