import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import {canShowRankingTable} from '../../../../lib/insights/insights-helper';
import {generateChartTitle} from '../lib/chart-title-generator';
import HistogramChart from '../common/chart/histogram-chart-component';
import HistogramTooltipLine from '../common/chart/histogram-tooltip-line-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import TableRanked from '../common/table/table-ranked-component';
import TABLES_DEFINITION from '../../../../data/insights/top-stores-data';
import InsightPageComponent from '../common/insight-page-component';

const INSIGHT_PAGE = 'responseRate';

/**
 * Finally, the component.
 */
class InsightResponseRateComponent extends InsightPageComponent {
  VALUE_SUFFIX = '%';

  tooltipValuesFormatter = (points) => {
    const {
      insight: {
        employeeResponseRateHistogram: {items}
      }
    } = this.props;

    const {point} = points[0];
    const {contacts, employeeResponseRate} = items[point.index];

    return (
      <ul style={{listStyle: 'none'}}>
        <HistogramTooltipLine
          label={point.series.userOptions.nameTooltip}
          style={{color: point.color}}
          value={`${employeeResponseRate}${this.VALUE_SUFFIX} (${contacts})`}
        />
      </ul>
    );
  };

  render() {
    const {i18n} = this.context;
    const {
      account,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight: {employeeResponseRateHistogram, employeeResponseRateIndicators, ranking},
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod,
      onClickRankingButtonShowAllItems
    } = this.props;

    const {businesses: accountBusinesses} = account;

    const rankingItem = ranking.employeeResponseRate;

    const {loadingCsv} = ranking;

    const {valueType} = TABLES_DEFINITION.employeeResponseRate;

    const isClassicPeriod = periodTabSelected === 'classic';

    const showRankingTable = canShowRankingTable(periodTabSelected, account);

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout ref={this.wrapperPanelContentRef} onScroll={this._handleScroll}>
          <InsightPanelContentBlock dataTestId="employee-response-rate">
            <HistogramChart
              aggregationPeriod={
                isClassicPeriod ? classicAggregationPeriod : customAggregationPeriod
              }
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.employeeResponseRate.chart`, {
                returnObjects: true
              })}
              tooltipValuesFormatter={this.tooltipValuesFormatter}
              type="line"
              valueSuffix={this.VALUE_SUFFIX}
              {...employeeResponseRateHistogram}
              title={generateChartTitle({
                figure: `${employeeResponseRateIndicators.employeeResponseRatePercent}${this.VALUE_SUFFIX}`,
                hasData: employeeResponseRateHistogram.items.length,
                label: i18n.t(`insight.pages.${INSIGHT_PAGE}.employeeResponseRate.chart.title`),
                showVariation: isClassicPeriod,
                variation: employeeResponseRateIndicators.employeeResponseRateIncrement
              })}
              series={[
                {
                  property: 'employeeResponseRate'
                }
              ]}
            />
          </InsightPanelContentBlock>

          {showRankingTable ? (
            <TableRanked
              dataTestId="employee-response-rate-ranking-table"
              downloadCsvLabelTranslation={i18n.t(`insight.pages.${INSIGHT_PAGE}.downloadCsvLabel`)}
              failed={rankingItem.failed}
              insightDownloadCsv={this._doInsightDownloadCsv}
              insightSortRankings={this._doInsightSortButtonClick}
              items={rankingItem.data}
              loading={rankingItem.loading}
              onClickButtonShowAllItems={onClickRankingButtonShowAllItems}
              showRankingControlButtons
              showCsvButton
              showSortButton
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.ranking.employeeResponseRate`, {
                returnObjects: true
              })}
              {...{
                loadingCsv,
                valueType
              }}
            />
          ) : null}
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightResponseRateComponent.contextTypes = context;

InsightResponseRateComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doInsightSortButtonClick: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired,
  onClickRankingButtonShowAllItems: PropTypes.func.isRequired
};

export default InsightResponseRateComponent;
