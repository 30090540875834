import {fromJS} from 'immutable';

/**
 * Delete persisted state.
 * @param {Immutable.Map} state
 * @returns {Immutable.Map} new state
 */
export function deletePersistedState(state) {
  return state.set('threadsForm', fromJS({}));
}

/**
 * Reset the whole state except the persisted props.
 * @param {Immutable.Map} state
 * @param {Object} defaultState
 * @returns {Immutable.Map} new state
 */
export function resetNotPersistedState(state, defaultState) {
  return fromJS(defaultState).set('threadsForm', state.get('threadsForm'));
}

/**
 * Delete persisted state.
 * @param {Immutable.Map} state
 * @param {String} conversationId
 * @returns {Immutable.Map} new state
 */
export function deletePersistedKeyFromState(state, conversationId) {
  const threadsForm = state.get('threadsForm');
  const threadFormMapIndex = threadsForm.keySeq().findIndex((key) => key === conversationId);
  const threadsFormUpdated = threadsForm.delete(threadFormMapIndex);

  return state.set('threadsForm', threadsFormUpdated);
}
