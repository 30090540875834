import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import CappedLoader from './capped-loader-component';
import Link from '../link/link-component';

const getLoadingTriggerComponent = ({loadMore, loadMoreLabel}) => (
  <Link
    data-test-id="load-more-button"
    label={loadMoreLabel}
    onClick={loadMore}
    style={{
      display: 'flex',
      flexGrow: 1,
      padding: '20px',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  />
);

const ClickableCappedLoaderComponent = (
  {hasMore, hasReachedLimit, limitText, loading, loadMore},
  {i18n}
) => (
  <CappedLoader
    {...{
      getLoadingTriggerComponent,
      hasMore,
      hasReachedLimit,
      limitText,
      loading,
      loadMore
    }}
    triggerParams={{
      loadMoreLabel: i18n.t('common.seeMore')
    }}
  />
);

ClickableCappedLoaderComponent.contextTypes = context;

ClickableCappedLoaderComponent.propTypes = {
  hasMore: PropTypes.bool,
  hasReachedLimit: PropTypes.bool.isRequired,
  limitText: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired
};

export default ClickableCappedLoaderComponent;
