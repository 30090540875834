import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {timer} from 'rxjs';
import context from '../../../../../../shared/component/context';
import {checkBusinessInScope} from '../../../../lib/business-helper';
import highlighted from '../../../../../../shared/lib/highlighted';
import loadScript from '../../../../../../shared/lib/add-script';
import {INITIAL_INPUT_MESSAGE_HEIGHT} from '../../../common/input-message/input-message-component';
import InputOverlay from './visible/visible-thread-input-overlay-component';
import RightPanelColumnLayout from '../../../common/layout/right-panel-column-layout-component';
import ScrollableThreadMessagesList from '../../../common/thread/scrollable-thread-messages-list-component';
import ThreadAssignmentInputOverlay from './assigment/thread-assignment-input-overlay-component';
import ThreadAttachmentExceedSize from '../../../common/thread/attachment-exceed-size-component';
import ThreadBusinessOutOfScope from './thread-business-out-of-scope-component';
import ThreadFacebookExpiredFooter from './channel/facebook/thread-facebook-expired-footer-component';
import ThreadGbmExpiredFooter from './channel/gbm/thread-gbm-expired-footer-component';
import ThreadStatusButtons from '../../../common/thread/thread-status-buttons-component';
import ThreadWhatsappPermissionFooter from './channel/whatsapp/thread-whatsapp-permission-footer-component';
import ThreadWhatsAppTemplateList from './channel/whatsapp/templates/thread-whatsapp-template-list';
import VisibleThreadAppsAreaLayout from './visible/apps/visible-thread-apps-area-layout-component';
import VisibleThreadInteractionArea from './visible/visible-thread-interaction-area-component';
import {isWindowExpired} from '../../../../lib/customer-thread/window-expiration-helper';
import {darkOrange} from '../../../../../../shared/style/colors';
import {CLICKABLE_AREAS} from '../../../../data/ui/clickable-areas';
import {CONVERSATION_ITEM_TYPES} from '../../../../data/thread/message';
import {FILE_UPLOAD_MAX_SIZE_EXCEEDED_TIMEOUT} from '../../../../../../shared/data/settings';
import {THREAD_CHANNELS_SETTINGS} from '../../../../data/thread/channels-settings';

const CTA_MIN_HEIGHT = '120px';

/**
 * Finally, the component.
 */
class CustomerVisibleThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.hideFileMaxSizeExceededTimerSubscription = null;
    this.state = {
      inputHeight: INITIAL_INPUT_MESSAGE_HEIGHT,
      showFileMaxSizeExceeded: false,
      showWhatsappTemplatesList: false
    };

    this._onLoadWidget = this._onLoadWidget.bind(this);
    this._sendMessage = this._sendMessage.bind(this);
    this._sendMessageRetry = this._sendMessageRetry.bind(this);
    this.iframeRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    /* eslint-disable react/destructuring-assignment */
    if (!prevProps.selectedFileExceedMaxSize && this.props.selectedFileExceedMaxSize) {
      this._showFileMaxSizeExceeded();

      this.hideFileMaxSizeExceededTimerSubscription = timer(
        FILE_UPLOAD_MAX_SIZE_EXCEEDED_TIMEOUT
      ).subscribe(this._doHideFileSizeExceededWarning);
    } else if (
      this.state.showFileMaxSizeExceeded &&
      prevProps.selectedFileExceedMaxSize &&
      !this.props.selectedFileExceedMaxSize
    ) {
      this._doHideFileSizeExceededWarning();
    }
    /* eslint-enable react/destructuring-assignment */
  };

  _showFileMaxSizeExceeded = () => {
    this.setState({
      showFileMaxSizeExceeded: true
    });
  };

  _doHideFileSizeExceededWarning = () => {
    this._doIgnoreFileSizeExceededWarningHideTimeout();

    this.setState({
      showFileMaxSizeExceeded: false
    });
  };

  _doIgnoreFileSizeExceededWarningHideTimeout = () => {
    this.hideFileMaxSizeExceededTimerSubscription.unsubscribe();
  };

  _setInputHeight = ({height}) => {
    this.setState({
      inputHeight: height
    });
  };

  _cancelShowWhatsappTemplates = () => {
    this.setState({
      showWhatsappTemplatesList: false
    });
  };

  _loadWhatsappTemplates = () => {
    const {business, onWhatsappTemplatesLoadButtonClick} = this.props;
    this.setState(
      {
        showWhatsappTemplatesList: true
      },
      () => {
        onWhatsappTemplatesLoadButtonClick(business.id);
      }
    );
  };

  _onClickWhatsAppTemplate = (text) => {
    const {onWhatsappTemplateClick} = this.props;
    onWhatsappTemplateClick(text);

    this._cancelShowWhatsappTemplates();
  };

  _renderAreaAboveForm = () => {
    const {i18n} = this.context;
    const {customerChannel, onThreadVisibleFileSelect} = this.props;
    const {showFileMaxSizeExceeded} = this.state;

    if (showFileMaxSizeExceeded) {
      return (
        <ThreadAttachmentExceedSize
          onClickAttachAnotherFile={this._doIgnoreFileSizeExceededWarningHideTimeout}
          onClickClose={this._doHideFileSizeExceededWarning}
          {...{
            customerChannel,
            i18n,
            onThreadVisibleFileSelect
          }}
        />
      );
    }

    return null;
  };

  _renderFacebookExpiredFooter = () => {
    const {i18n} = this.context;

    return <ThreadFacebookExpiredFooter {...{i18n}} />;
  };

  _renderGbmExpiredFooter = () => {
    const {i18n} = this.context;
    const {onConversationNewConversationFormOpenLinkClick} = this.props;

    return <ThreadGbmExpiredFooter {...{i18n, onConversationNewConversationFormOpenLinkClick}} />;
  };

  _renderWhatsappExpiredFooter = () => {
    const {i18n} = this.context;
    const {enableUIWhatsAppChannelFeature, whatsappTemplates} = this.props;
    const {showWhatsappTemplatesList} = this.state;

    if (!enableUIWhatsAppChannelFeature) {
      return null;
    }

    if (showWhatsappTemplatesList && whatsappTemplates) {
      return (
        <VisibleThreadAppsAreaLayout>
          <ThreadWhatsAppTemplateList
            doCancelWhatsappTemplatesScreen={this._cancelShowWhatsappTemplates}
            onWhatsappTemplateClick={this._onClickWhatsAppTemplate}
            {...{
              i18n,
              whatsappTemplates
            }}
          />
        </VisibleThreadAppsAreaLayout>
      );
    }

    const {doWhatsappPermissionFooterConfirmRendered, conversationItemsBatches, windowExpiration} =
      this.props;

    const employeesMessagesBatches = conversationItemsBatches.filter(
      ({type}) => type === CONVERSATION_ITEM_TYPES.OUTBOUND
    );
    // We are not sure than an employee ever answered to the customer
    const lastEmployeeMessagesBatch =
      employeesMessagesBatches.length > 0
        ? employeesMessagesBatches[employeesMessagesBatches.length - 1]
        : null;

    return (
      <ThreadWhatsappPermissionFooter
        {...{
          doWhatsappPermissionFooterConfirmRendered,
          i18n,
          lastEmployeeMessagesBatch,
          windowExpiration,
          onWhatsappTemplatesLoadButtonClick: this._loadWhatsappTemplates
        }}
      />
    );
  };

  _renderChannelExpiredFooter = () => {
    const {customerChannel} = this.props;

    switch (customerChannel) {
      case 'FACEBOOK':
        return this._renderFacebookExpiredFooter();
      case 'GBM':
        return this._renderGbmExpiredFooter();
      default: {
        break;
      }
    }

    return null;
  };

  _loadMore = (messageCursor, direction) => {
    const {participationId, doThreadVisiblePaginate} = this.props;
    doThreadVisiblePaginate(participationId, messageCursor, direction);
  };

  _releaseThread = () => {
    const {onThreadReleaseButtonClick} = this.props;
    onThreadReleaseButtonClick();
  };

  onClickFooterFocusable = () => {
    const {onUIAreaClick} = this.props;
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.VISIBLE.FOOTER);
  };

  onClickScrollableArea = () => {
    const {onUIAreaClick} = this.props;
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.VISIBLE.SCROLLABLE_MESSAGES_LIST);
  };

  _onLoadWidget() {
    const {i18n} = this.context;
    const {language} = this.props;

    const widgetWindow = this.iframeRef.contentWindow;
    widgetWindow._iwq.businessId = SALES_BUSINESS_ID;
    widgetWindow._iwq.lang = language;

    loadScript(`${WIDGET_URL}/bubble.js`, {
      id: 'main-script',
      target: widgetWindow.document
    });

    widgetWindow.document.getElementById('click').textContent = i18n.t(
      'customerThread.visibleThread.clickToContactYourSales'
    );
  }

  _sendMessage({text}) {
    const {
      business,
      isNewConversation,
      threadForm,
      onNewConversationSendMessageButtonClick,
      onThreadVisibleSendMessageButtonClick
    } = this.props;

    if (isNewConversation) {
      onNewConversationSendMessageButtonClick(business.id, text, threadForm.fileUploaded);
    } else {
      onThreadVisibleSendMessageButtonClick(text, threadForm.fileUploaded);
    }
  }

  _sendMessageRetry(clientItemId) {
    const {isNewConversation, onThreadVisibleSendMessageRetryButtonClick} = this.props;
    if (!isNewConversation) {
      onThreadVisibleSendMessageRetryButtonClick(clientItemId);
    }
  }

  renderFocusableFooter(isAssignedToMe) {
    const {i18n} = this.context;
    const {
      assignedTo,
      business,
      cannedResponses,
      conversationId,
      customerChannel,
      customerThreadAppPayment,
      disableThreadInputPersistence,
      enablePaymentFeature,
      footerHasFocus,
      isActiveThreadType,
      isHistoryAction,
      isNewConversation,
      readOnly,
      releasing,
      sending,
      showAssignment,
      taking,
      threadForm,
      uploadingFile,
      updatingThreadStatus,
      windowExpiration,
      doAppPaymentCancelButtonClick,
      doAppPaymentMaximizeButtonClick,
      doAppPaymentMinimizeButtonClick,
      doAppPaymentStepperBackButtonClick,
      doAppPaymentStepperNextButtonClick,
      doAppsAreaMenuButtonClick,
      doAppsAreaMinimize,
      doAppsMenuSelectPayment,
      onThreadTakeButtonClick,
      doThreadVisibleFileRemove,
      onAppPaymentAmountChange,
      onAppPaymentCustomChange,
      onAppPaymentCustomerChange,
      onConversationVisibleMessageFormTextChanged,
      onThreadVisibleFileSelect
    } = this.props;

    const canShowAssignmentInputOverlay =
      showAssignment && !isAssignedToMe && !updatingThreadStatus && !sending;
    const conversationExpired = isWindowExpired(windowExpiration);

    if (business.deactivated) {
      return (
        <InputOverlay
          data-test-id="deactivated-message"
          label={i18n.t('customerThread.visibleThread.businessDeactivated', {
            business: highlighted(business.name, null, 200)
          })}
          labelDataTestId="conversation-deactivated-note"
          labelStyle={{
            position: 'relative',
            top: '-20px'
          }}
          style={{
            height: CTA_MIN_HEIGHT,
            color: darkOrange
          }}
        >
          <iframe
            ref={(iframeElement) => {
              this.iframeRef = iframeElement;

              return this.iframeRef;
            }}
            scrolling="no"
            src={`${ASSETS_PATH}/html/thread/cta.html`}
            title={i18n.t('customerThread.visibleThread.iframeTitle')}
            onLoad={this._onLoadWidget}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              minHeight: CTA_MIN_HEIGHT
            }}
          />
        </InputOverlay>
      );
    }

    if (conversationExpired && customerChannel !== THREAD_CHANNELS_SETTINGS.WHATSAPP.code) {
      return this._renderChannelExpiredFooter();
    }

    if (canShowAssignmentInputOverlay) {
      return (
        <ThreadAssignmentInputOverlay
          {...{
            assignedTo,
            i18n,
            taking,
            onThreadTakeButtonClick
          }}
        />
      );
    }

    if (conversationExpired && customerChannel === THREAD_CHANNELS_SETTINGS.WHATSAPP.code) {
      return this._renderWhatsappExpiredFooter();
    }

    return (
      <VisibleThreadInteractionArea
        sendMessage={this._sendMessage}
        setInputHeight={this._setInputHeight}
        onReleaseThread={this._releaseThread}
        {...{
          conversationId,
          customerChannel,
          customerThreadAppPayment,
          enablePaymentFeature,
          cannedResponses,
          disableThreadInputPersistence,
          footerHasFocus,
          i18n,
          isActiveThreadType,
          isAssignedToMe,
          isHistoryAction,
          isNewConversation,
          readOnly,
          releasing,
          sending,
          showAssignment,
          threadForm,
          uploadingFile,
          doAppPaymentCancelButtonClick,
          doAppPaymentMaximizeButtonClick,
          doAppPaymentMinimizeButtonClick,
          doAppPaymentStepperBackButtonClick,
          doAppPaymentStepperNextButtonClick,
          doAppsAreaMenuButtonClick,
          doAppsAreaMinimize,
          doAppsMenuSelectPayment,
          doThreadVisibleFileRemove,
          onAppPaymentAmountChange,
          onAppPaymentCustomChange,
          onAppPaymentCustomerChange,
          onConversationVisibleMessageFormTextChanged,
          onThreadVisibleFileSelect
        }}
      />
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      account,
      assignedTo,
      business,
      conversationItemsBatches,
      conversationStatus,
      direction,
      hasNextPage,
      hasPreviousPage,
      isNewConversation,
      isNewConversationWithNewCustomer,
      latestMessage,
      loading,
      loadingAfter,
      loadingBefore,
      oldestMessage,
      readOnly,
      sending,
      updatingThreadStatus,
      userId,
      doThreadFilePreviewOpen,
      onThreadStatusButtonClick,
      onUIAreaClick
    } = this.props;
    const {inputHeight} = this.state;

    const isAssignedToMe = assignedTo ? assignedTo.id === userId : false; // do not try to simplify this expression

    return (
      <RightPanelColumnLayout dataTestId="visible-thread">
        <ScrollableThreadMessagesList
          dataTestId="thread-result"
          canShowIncomingAvatar={false}
          loadMore={this._loadMore}
          doSendMessageRetry={this._sendMessageRetry}
          onClick={this.onClickScrollableArea}
          threadType="visible"
          {...{
            account,
            conversationItemsBatches,
            direction,
            hasNextPage,
            hasPreviousPage,
            i18n,
            isAssignedToMe,
            inputHeight,
            latestMessage,
            loading,
            loadingAfter,
            loadingBefore,
            oldestMessage,
            sending,
            doThreadFilePreviewOpen
          }}
        />

        {loading ? null : this._renderAreaAboveForm()}

        {loading || readOnly || isNewConversation || isNewConversationWithNewCustomer ? null : (
          <ThreadStatusButtons
            {...{
              conversationStatus,
              i18n,
              onThreadStatusButtonClick,
              onUIAreaClick,
              updatingThreadStatus
            }}
          />
        )}

        {(() => {
          if (loading) {
            return null;
          }

          const isBusinessInScope =
            business && checkBusinessInScope(account.businesses, business.id);
          if (!isBusinessInScope && !isNewConversation) {
            return <ThreadBusinessOutOfScope {...{i18n, onUIAreaClick}} />;
          }

          return (
            <div onClick={this.onClickFooterFocusable}>
              {this.renderFocusableFooter(isAssignedToMe)}
            </div>
          );
        })()}
      </RightPanelColumnLayout>
    );
  }
}

CustomerVisibleThreadComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  assignedTo: PropTypes.objectOf(PropTypes.any),
  business: PropTypes.objectOf(PropTypes.any),
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  conversationId: PropTypes.string,
  conversationItemsBatches: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerChannel: PropTypes.string,
  conversationStatus: PropTypes.string,
  customerThreadAppPayment: PropTypes.objectOf(PropTypes.any),
  direction: PropTypes.string,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  enableUIWhatsAppChannelFeature: PropTypes.bool.isRequired,
  footerHasFocus: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  isActiveThreadType: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewConversation: PropTypes.bool.isRequired,
  isNewConversationWithNewCustomer: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingAfter: PropTypes.bool.isRequired,
  loadingBefore: PropTypes.bool.isRequired,
  newThreadEmail: PropTypes.string,
  newThreadPhoneNumber: PropTypes.string,
  oldestMessage: PropTypes.string,
  organizationPicture: PropTypes.string,
  participationId: PropTypes.string,
  picture: PropTypes.string,
  readOnly: PropTypes.bool,
  releasing: PropTypes.bool.isRequired,
  selectedFileExceedMaxSize: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  showAssignment: PropTypes.bool.isRequired,
  taking: PropTypes.bool.isRequired,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  updatingThreadStatus: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  whatsappTemplates: PropTypes.arrayOf(PropTypes.object),
  windowExpiration: PropTypes.string,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsAreaMenuButtonClick: PropTypes.func.isRequired,
  doAppsAreaMinimize: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  doThreadVisibleFileRemove: PropTypes.func.isRequired,
  doThreadVisiblePaginate: PropTypes.func.isRequired,
  doWhatsappPermissionFooterConfirmRendered: PropTypes.func.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onConversationNewConversationFormOpenLinkClick: PropTypes.func.isRequired,
  onConversationVisibleMessageFormTextChanged: PropTypes.func.isRequired,
  onNewConversationSendMessageButtonClick: PropTypes.func.isRequired,
  onThreadReleaseButtonClick: PropTypes.func.isRequired,
  onThreadStatusButtonClick: PropTypes.func.isRequired,
  onThreadTakeButtonClick: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired,
  onThreadVisibleSendMessageButtonClick: PropTypes.func.isRequired,
  onThreadVisibleSendMessageRetryButtonClick: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired,
  onWhatsappTemplateClick: PropTypes.func.isRequired,
  onWhatsappTemplatesLoadButtonClick: PropTypes.func.isRequired
};

CustomerVisibleThreadComponent.contextTypes = context;

export default CustomerVisibleThreadComponent;
