import PropTypes from 'prop-types';
import {ListItem} from 'material-ui/List';
import DoneIcon from 'material-ui/svg-icons/action/done';
import {fontSize, fontWeight} from '../../../../shared/style/variables';

const EnhancedListItemComponent = ({dataTestId, isCurrent, onClick, primaryText}) => (
  <div data-test-id={isCurrent ? 'selected' : null}>
    <ListItem
      data-test-id={dataTestId}
      disabled={isCurrent}
      onClick={onClick}
      primaryText={primaryText}
      rightIcon={isCurrent ? <DoneIcon /> : null}
      style={{
        fontSize: fontSize.medium,
        fontWeight: isCurrent ? fontWeight.semiBold : null
      }}
    />
  </div>
);

EnhancedListItemComponent.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  primaryText: PropTypes.string.isRequired
};
export default EnhancedListItemComponent;
