import PropTypes from 'prop-types';
import {lineHeight} from '../../../../../../../../../../shared/style/variables';

const PaymentLayoutComponent = ({children, step}) => (
  <div
    data-test-id="app-payment-maximized"
    style={{
      padding: '10px 0 10px 10px',
      height: '260px',
      lineHeight: lineHeight.xlarge
    }}
  >
    <div
      data-test-id={step}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      {children}
    </div>
  </div>
);
PaymentLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.string
};

export default PaymentLayoutComponent;
