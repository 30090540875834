import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import downloadFileFromUrl from '../../../../../shared/lib/download-file-from-url';
import getStandardParamsFromState from './lib/get-standard-params-from-state';
import prepareRequestParams from './lib/prepare-request-params';
import {
  INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_MESSAGES_CSV_DOWNLOAD,
  INSIGHT_MESSAGES_CSV_LOAD,
  INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD,
  INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_FAILURE,
  INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_SUCCESS,
  INSIGHT_MESSAGES_OPEN,
  INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../actions/insight-actions';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';

const InsightMessagesEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(
        INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_MESSAGES_OPEN,
        INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ),
      map(() => {
        return {
          type: INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD,
          ...getStandardParamsFromState(state$)
        };
      })
    );

    const loadMessagesSent = action$.pipe(
      ofType(INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate
        }) => {
          return graphql(
            'get-messages-sent-insights-query',
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            })
          ).pipe(
            map(({messagesSentHistogram, messagesSentIndicators}) => {
              return {
                type: INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_SUCCESS,
                messagesSentHistogram,
                messagesSentIndicators
              };
            }),
            catchError((error) => {
              return of({
                type: INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_FAILURE,
                error
              });
            })
          );
        }
      )
    );

    const downloadMessagesCsv = downloadFileFromUrl(action$, INSIGHT_MESSAGES_CSV_DOWNLOAD);

    const loadMessagesCsvToDownload = action$.pipe(
      ofType(INSIGHT_MESSAGES_CSV_LOAD),
      mergeMap(({businessesSelection, classicAggregationDate, classicAggregationPeriod}) => {
        return graphql(
          'get-total-messages-report-query',
          prepareRequestParams(state$, {
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            forceStartDate: true
          }),
          {timeout: TIMEOUT_REQUEST_LONG}
        ).pipe(
          map(({totalMessagesReport: {url}}) => {
            return {
              type: INSIGHT_MESSAGES_CSV_DOWNLOAD,
              url
            };
          })
          // @todo handle failure
        );
      })
    );

    return merge(downloadMessagesCsv, loadInsights, loadMessagesCsvToDownload, loadMessagesSent);
  };

export default InsightMessagesEpic;
