import {createReducer} from 'redux-immutablejs';
import {
  APP_BROWSER_NOTIFICATION_BANNER_CLOSE,
  APP_BROWSER_NOTIFICATION_BANNER_SHOW_FIRST,
  APP_BROWSER_NOTIFICATION_BANNER_SHOW_LAST,
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND,
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND_FAILURE,
  APP_GLOBAL_VERIFICATION_EMAIL_RESEND_SUCCESS,
  APP_LOGOUT,
  APP_NEW_RELEASE_BANNER_SHOW,
  APP_RELOAD,
  APP_STATE_BOOT_LOAD_FAILURE,
  APP_STATE_BOOT_LOAD_RETRY,
  APP_STATE_BOOT_LOAD_SUCCESS
} from '../actions/app-actions';
import {UI_WINDOW_FOCUSED} from '../../../shared/actions/ui-actions';
import {
  EXT_COLLEAGUE_SENT_MESSAGE_TO_ME,
  EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
  LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED
} from '../actions/ext-actions';

export const DEFAULT_STATE = {
  browserNotificationBannerStep: null, // flow is null > 'first' > 'last'
  globalErrorsCount: 0,
  isLoading: true,
  isLoggingOut: false,
  isResendingVerifyEmail: false,
  isVisibleNewReleaseBanner: false,
  newNotificationEventType: null
};

const setAsLoading = (state) => {
  return state.set('isLoading', true);
};

const setAsNotSendingVerifyEmail = (state) => {
  return state.set('isResendingVerifyEmail', false);
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Load app success.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_SUCCESS]: (state) => {
    return state.set('globalErrorsCount', 0).set('isLoading', false);
  },

  /**
   * Load app failure.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_FAILURE]: (state) => {
    return state
      .set('globalErrorsCount', state.get('globalErrorsCount') + 1)
      .set('isLoading', false);
  },

  /**
   * Some actions set app as just loading.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_RETRY]: setAsLoading,
  [APP_RELOAD]: setAsLoading,

  /**
   * Verify email send.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_GLOBAL_VERIFICATION_EMAIL_RESEND]: (state) => {
    return state.set('isResendingVerifyEmail', true);
  },

  /**
   * Verify email send finished.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_GLOBAL_VERIFICATION_EMAIL_RESEND_FAILURE]: setAsNotSendingVerifyEmail,
  [APP_GLOBAL_VERIFICATION_EMAIL_RESEND_SUCCESS]: setAsNotSendingVerifyEmail,

  /**
   * Show browser notification's banner 1st ask.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_BROWSER_NOTIFICATION_BANNER_SHOW_FIRST]: (state) => {
    return state.set('browserNotificationBannerStep', 'first');
  },

  /**
   * Show browser notification's banner 2nd ask.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_BROWSER_NOTIFICATION_BANNER_SHOW_LAST]: (state) => {
    return state.set('browserNotificationBannerStep', 'last');
  },

  /**
   * Hide browser notification's banner.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_BROWSER_NOTIFICATION_BANNER_CLOSE]: (state) => {
    return state.set('browserNotificationBannerStep', null);
  },

  /**
   * Show new release banner.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_NEW_RELEASE_BANNER_SHOW]: (state) => {
    return state.set('isVisibleNewReleaseBanner', true);
  },

  /**
   * Log out.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [APP_LOGOUT]: (state) => {
    return state.set('isLoggingOut', true);
  },

  /**
   * New event type modified after new message received to update document title.
   *
   * @param {Object} state
   */
  [EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION]: (state) => {
    return state.set('newNotificationEventType', 'customerMessage');
  },

  /**
   * New event type modified after new message received from colleague to update document title.
   *
   * @param {Object} state
   */
  [EXT_COLLEAGUE_SENT_MESSAGE_TO_ME]: (state) => {
    return state.set('newNotificationEventType', 'colleagueMessage');
  },

  /**
   * Update when a new mention has been received
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED]: (state) => {
    return state.set('newNotificationEventType', 'newHeaderMention');
  },

  /**
   * Restore when window has not focus
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [UI_WINDOW_FOCUSED]: (state) => {
    return state.set('newNotificationEventType', null);
  }
});
