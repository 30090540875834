import PropTypes from 'prop-types';
import {spacing} from '../../../../../../../shared/style/variables';
import {lightSmoke} from '../../../../../../../shared/style/colors';

const InputOverlay = ({children, label, labelDataTestId, labelStyle, style, ...otherProps}) => (
  <div
    {...otherProps}
    style={{
      backgroundColor: lightSmoke,
      height: '67px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: spacing.large,
      ...style
    }}
  >
    <div
      data-test-id={labelDataTestId}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <span dangerouslySetInnerHTML={{__html: label}} style={labelStyle} />
      {children}
    </div>
  </div>
);

InputOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  'data-test-id': PropTypes.string.isRequired,
  label: PropTypes.string,
  labelDataTestId: PropTypes.string,
  labelStyle: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any)
};

export default InputOverlay;
