import {merge, of} from 'rxjs';
import {catchError, delay, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import cloneDeep from 'lodash/cloneDeep';
import parseFilepath from 'parse-filepath';
import {replace} from 'react-router-redux';
import debug from '../../../../../../shared/lib/debug';
import getChannelSetting from '../../../../data/thread/channels-settings';
import {isCurrentPageCustomerThread} from '../../../../lib/route-helper';
import {parseSearch} from '../../../../../../shared/lib/route-helper';
import onReceiveMessage from './lib/customer-thread-on-receive-message';
import onRouteChange from '../../../lib/on-route-change';
import sendMessage from '../../../lib/send-message';
import triggerTypingThreadDebouncedAction from '../../../lib/trigger-typing-thread-debounced-action';
import uploadFile from '../../../lib/upload-file';
import {LEGACY_CUSTOMER_THREAD_LOAD} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
  LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-new-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_UPDATE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_PERSIST,
  LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
  EXT_ORGANIZATION_SENT_AUTOMATIC_MESSAGE_TO_CUSTOMER
} from '../../../../actions/ext-actions';
import {THREAD_ITEMS_PER_PAGE} from '../../../../data/settings';

/**
 * Because of legacy clients, backend always send data, even when it's not useful,
 * so we prefer to clean first on our side before starting to use it.
 * This should be fixed with future migration to new GraphQL endpoint to load a thread.
 */
const _adjustServerData = (threadFromServer) => {
  const thread = cloneDeep(threadFromServer);

  if (
    thread.status !== 'waiting' ||
    !thread.assignedTo // do not remove this test until task to "fully reset thread state on each loading" is not done
  ) {
    thread.assignedTo = null;
  }

  return thread;
};

const LegacyCustomerThreadVisibleEpic =
  ({command, query}, socketio) =>
  (action$, state$) => {
    const legacyCustomerSentMessageToOrganization = onReceiveMessage(
      socketio,
      state$,
      'customer_sent_message_to_organization',
      EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
      'visible'
    );

    const legacyEmployeeSentMessageToCustomer = onReceiveMessage(
      socketio,
      state$,
      'employee_sent_message_to_customer',
      EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
      'visible'
    );

    const legacyOrganizationSentAutomaticMessageToCustomer = onReceiveMessage(
      socketio,
      state$,
      'organization_sent_automatic_message_to_customer',
      EXT_ORGANIZATION_SENT_AUTOMATIC_MESSAGE_TO_CUSTOMER,
      'visible'
    );

    const legacyLoadVisibleThreadOnLoadConversationsSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_LOAD),
      map(
        ({payload: {direction, fromId, invisibleDirection, invisibleFromId, participationId}}) => {
          return {
            type: LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD,
            direction,
            fromId,
            invisibleDirection,
            invisibleFromId,
            participationId
          };
        }
      )
    );

    const legacyLoadVisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD),
      delay(1), // without this, new thread loading state won't be taken into account
      mergeMap(({direction, fromId, participationId}) => {
        const params = {
          limit: THREAD_ITEMS_PER_PAGE,
          direction,
          fromId,
          participationId
        };

        return query('get-conversation', params).pipe(
          map(({data}) => {
            if (!isCurrentPageCustomerThread({state$, participationId})) {
              return {
                type: LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_CANCELED,
                participationId // useful only for debug
              };
            }

            return {
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS,
              data: _adjustServerData(data),
              params
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const legacyLoadVisibleThreadNew = action$.pipe(
      ofType(LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD),
      delay(1), // without this, new thread loading state won't be taken into account
      map(({businessId}) => {
        return {
          type: LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS,
          data: {
            directedToBusinessIdentifier: businessId
          }
        };
      })
    );

    const legacyLoadVisibleThreadNewOnRouteChange = onRouteChange({
      action: action$,
      regex: new RegExp('^/app/customers/new$'),
      mapFn: ({search}) => ({
        type: LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD,
        ...parseSearch(search)
      })
    });

    const legacyPaginateVisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE),
      mergeMap(({direction, fromId, participationId}) => {
        const params = {
          limit: THREAD_ITEMS_PER_PAGE,
          direction,
          fromId,
          participationId
        };

        return query('get-conversation', params).pipe(
          map(({data}) => {
            return {
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_SUCCESS,
              data: _adjustServerData(data),
              params
            };
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const legacyPersistMessageFormText = triggerTypingThreadDebouncedAction(
      action$,
      LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_UPDATE,
      LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_PERSIST
    );

    const legacySendMessageInVisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE),
      mergeMap((sendAction) => {
        const participationId = state$.value.getIn(['legacyCustomerThread', 'participationId']);

        return sendMessage({
          command,
          participationId,
          sendAction,
          nextActions: {
            success: LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS,
            failure: LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_FAILURE
          },
          extraProps: {
            previousStatus: sendAction.status
          },
          extraSuccessProps: {
            needInboxRefresh: true
          },
          callback: state$.value.getIn(['legacyCustomerVisibleThread', 'hasMoreMessagesAfter'])
            ? {
                type: LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE,
                participationId
              }
            : null
        });
      })
    );

    const legacySendMessageInNewVisibleThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE),
      mergeMap(({attachment, businessId, clientMessageId, email, phoneNumber, text}) => {
        return command('start-customer-thread', {
          attachment,
          businessId,
          clientMessageId,
          email,
          phoneNumber,
          text
        }).pipe(
          mergeMap(({data}) => {
            return [
              replace(`/app/customers/${data.participationId}`),
              {
                type: LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS,
                participationId: data.participationId,
                id: data.id,
                clientMessageId
              }
            ];
          }),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_FAILURE,
              clientMessageId,
              error
            });
          })
        );
      })
    );

    const legacySelectFileToUploadInTheForm = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT),
      delay(1), // necessary to let component render with some state change
      map(({file}) => {
        // Next lines could be split into a chain of actions, but for now I decided to keep it grouped
        const customerChannel = state$.value.getIn(['legacyCustomerThread', 'customerChannel']);

        // 1. Validate extension
        const extensionsSupported = getChannelSetting(customerChannel, 'extensionsSupported');
        if (extensionsSupported) {
          const {name} = file;
          const fileExtension = parseFilepath(name).ext.toLowerCase();

          if (!extensionsSupported.includes(fileExtension)) {
            debug(
              `'File extension not supported for channel "${customerChannel}", where filename = "${name}", extension = "${fileExtension}", and supported extensions = "${extensionsSupported.join()}"`
            );

            return {
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXTENSION_NOT_SUPPORTED
            };
          }
        }

        // 2. Validate file size
        const maxFileSize = getChannelSetting(customerChannel, 'maxFileSize');
        if (maxFileSize) {
          const {size} = file;

          if (size > maxFileSize) {
            debug(
              `'File size exceed max for channel "${customerChannel}", where file size = "${size}" and max file size = "${maxFileSize}"`
            );

            return {
              type: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE
            };
          }
        }

        return {
          type: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD,
          file
        };
      })
    );

    const legacyUploadFileInTheForm = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD),
      mergeMap(({file}) =>
        uploadFile({
          command,
          file,
          actions: {
            success: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_SUCCESS,
            failure: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_UPLOAD_FAILURE
          }
        })
      )
    );

    return merge(
      legacyCustomerSentMessageToOrganization,
      legacyEmployeeSentMessageToCustomer,
      legacyLoadVisibleThread,
      legacyLoadVisibleThreadNew,
      legacyLoadVisibleThreadNewOnRouteChange,
      legacyLoadVisibleThreadOnLoadConversationsSuccess,
      legacyOrganizationSentAutomaticMessageToCustomer,
      legacyPaginateVisibleThread,
      legacyPersistMessageFormText,
      legacySelectFileToUploadInTheForm,
      legacySendMessageInNewVisibleThread,
      legacySendMessageInVisibleThread,
      legacyUploadFileInTheForm
    );
  };

export default LegacyCustomerThreadVisibleEpic;
