import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import context from '../../../shared/component/context';
import ChangePasswordForm from '../../../shared/component/form/change-password-form-component';
import CountdownCircleTimer from '../../../shared/component/react-countdown-circle-timer/countdown-circle-timer-component';
import EmailField from '../../../shared/component/form/email-field-component';
import FormFieldsContainer from '../../../shared/component/form/fields-container-component';
import getErrorMessage from '../../../shared/lib/get-graphql-error-message';
import PageFormHandler from '../../../shared/component/page-form-handler-component';
import PasswordField from '../../../shared/component/form/password-field-component';
import PublicForm, {BottomLink} from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';
import StandardRaisedButton from '../../../shared/component/button/standard-raised-button-component';
import TermsOfServiceLink from '../../../shared/component/link/links-to-website';
import {red} from '../../../shared/style/colors';
import {fontSize, spacing} from '../../../shared/style/variables';

const MAX_LOGIN_ATTEMPTS = 5;

/**
 * Finally, the component.
 */
class SignInComponent extends PageFormHandler {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showError: {
        email: false,
        password: false
      }
    };

    this._doSubmitSignInForm = this._doSubmitSignInForm.bind(this);
  }

  _doSubmitSignInForm() {
    const {email, password} = this.state;

    const isEmailValid = isEmail(email);
    const isPasswordValid = password !== '';

    this.setState(
      {
        showError: {
          email: !isEmailValid,
          password: !isPasswordValid
        }
      },
      () =>
        this.props.doSubmitSignIn(
          email,
          this._keepSignedIn.state.switched,
          password,
          isEmailValid && isPasswordValid
        )
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      blockedUntil,
      errorContext,
      errors,
      isTinyScreen,
      language,
      mustUpdatePassword,
      signInImprovePassword,
      submitting,
      doContinueToTheApp,
      doImprovePasswordSubmit,
      doUIEndMount,
      doUIUpdateLanguage,
      onBlockingExpired
    } = this.props;
    const {email, password, showError} = this.state;

    // screen shown after successful login when password is not compliant to new policy
    if (mustUpdatePassword) {
      const title = i18n.t('signIn.improvePassword.title');

      return (
        <PublicPage
          dataTestId="improve-password"
          {...{
            language,
            doUIEndMount,
            doUIUpdateLanguage,
            title
          }}
        >
          <ChangePasswordForm
            description={i18n.t('signIn.improvePassword.description')}
            descriptionKeepHtml
            errors={signInImprovePassword.errors}
            passwordChanged={signInImprovePassword.passwordChanged}
            submitLabel={i18n.t('signIn.improvePassword.submitButton')}
            submitting={signInImprovePassword.submitting}
            doChangePasswordSubmit={doImprovePasswordSubmit}
            {...{
              title,
              doContinueToTheApp
            }}
          />
        </PublicPage>
      );
    }

    const title = i18n.t('signIn.title');

    // screen blocked after X wrong password
    if (blockedUntil) {
      return (
        <PublicPage
          dataTestId="signin-blocked"
          {...{
            language,
            title,
            doUIEndMount,
            doUIUpdateLanguage
          }}
        >
          <PublicForm
            description={
              i18n.t('signIn.blocked.description1', {maxAttempts: MAX_LOGIN_ATTEMPTS}) +
              ReactDomServer.renderToStaticMarkup(
                <span style={{color: red}}>
                  <br />
                  {i18n.t('signIn.blocked.description2')}
                </span>
              )
            }
            descriptionKeepHtml
            title={i18n.t('signIn.blocked.title')}
          >
            <div
              data-test-id="countdown-clock"
              style={{
                marginTop: spacing.mlarge,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <span style={{marginBottom: spacing.medium}}>{i18n.t('signIn.blocked.wait')}</span>
              <CountdownCircleTimer
                duration={moment(blockedUntil).diff(errors[0]._serverDate, 'seconds')}
                fontSize={fontSize.xxxxlarge}
                onComplete={onBlockingExpired}
                size={100}
                strokeWidth={10}
              />
              <span style={{marginTop: spacing.medium}}>
                {i18n.t('signIn.blocked.contactYourAdministrator')}
              </span>
            </div>
          </PublicForm>
        </PublicPage>
      );
    }

    // finally, the screen to sign in
    // test on mustUpdatePassword is to avoid login clipping if login is all successful, but redirection to desktop take a while
    const stillWorking = submitting || mustUpdatePassword !== null;

    const remainingAttempts = errorContext ? errorContext.remainingAttempts : null;
    let globalErrorMessage = getErrorMessage(errors, 'signIn', i18n);
    if (remainingAttempts && remainingAttempts < MAX_LOGIN_ATTEMPTS - 1) {
      globalErrorMessage += i18n.t('signIn.remainingAttempts', {remainingAttempts});
    }

    return (
      <PublicPage
        dataTestId="signin"
        {...{
          language,
          title,
          doUIEndMount,
          doUIUpdateLanguage
        }}
      >
        <PublicForm
          doSubmit={this._doSubmitSignInForm}
          submitting={stillWorking}
          submittingLabel={i18n.t('form.submitting')}
          {...{
            globalErrorMessage,
            isTinyScreen,
            title
          }}
          bottomLinks={[
            <BottomLink
              key="join"
              labelKey="signIn.wantToJoinYourTeam"
              link={
                <a data-test-id="join-team-link" href="/signup">
                  {i18n.t('signIn.signUpClickHere')}
                </a>
              }
            />,
            <BottomLink
              key="terms"
              link={<TermsOfServiceLink>{i18n.t('signIn.termsAndConditions')}</TermsOfServiceLink>}
            />
          ]}
          buttons={
            stillWorking
              ? null
              : [
                  <StandardRaisedButton
                    key="submit"
                    label={title}
                    style={isTinyScreen ? null : {width: 'auto'}}
                  />,
                  <a
                    data-test-id="forgotten-password-link"
                    href="/forgotten-password"
                    key="forgottenPassword"
                    style={isTinyScreen ? {marginTop: spacing.xlarge} : null}
                  >
                    {i18n.t('signIn.forgottenPassword')}
                  </a>
                ]
          }
        >
          <FormFieldsContainer showRequired={false}>
            <EmailField
              autoFocus
              disabled={stillWorking}
              errorText={showError.email ? i18n.t('form.emailAddressRules') : null}
              onBlur={this._updateFieldErrorStatus}
              onChange={this._onFieldChange}
              onFocus={this._updateFieldErrorStatus}
              value={email}
            />
            <PasswordField
              disabled={stillWorking}
              errorText={showError.password ? i18n.t('signIn.passwordRulesLegacy') : null}
              onBlur={this._updateFieldErrorStatus}
              onChange={this._onFieldChange}
              onFocus={this._updateFieldErrorStatus}
              value={password}
            />
            <Checkbox
              data-test-id="keep-signed-in-checkbox"
              disabled={stillWorking}
              label={i18n.t('signIn.keepSignedIn')}
              ref={(itself) => {
                this._keepSignedIn = itself;

                return this._keepSignedIn;
              }}
              style={{
                paddingTop: '20px'
              }}
            />
          </FormFieldsContainer>
        </PublicForm>
      </PublicPage>
    );
  }
}

SignInComponent.contextTypes = context;

SignInComponent.propTypes = {
  blockedUntil: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isTinyScreen: PropTypes.bool,
  language: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  mustUpdatePassword: PropTypes.bool,
  signInImprovePassword: PropTypes.objectOf(PropTypes.any).isRequired,
  submitting: PropTypes.bool.isRequired,
  doContinueToTheApp: PropTypes.func.isRequired,
  doImprovePasswordSubmit: PropTypes.func.isRequired,
  doSubmitSignIn: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired,
  onBlockingExpired: PropTypes.func.isRequired
};

export default SignInComponent;
