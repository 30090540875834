import PropTypes from 'prop-types';
import {TableRow, TableRowColumn} from 'material-ui/Table';
import context from '../../../../../../shared/component/context';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {
  getPercentTooltip,
  TOOLTIP_ROW_COLUMN_STYLE,
  TOOLTIP_ROW_STYLE
} from '../common/survey-tooltip-component';
import {fontSize, fontWeight, spacing} from '../../../../../../shared/style/variables';
import {graphBlue, graphGreen, graphRed} from '../../../../../../shared/style/colors';

const INSIGHT_PAGE = 'satisfaction';

const ITEMS_CONFIG = {
  promoters: graphGreen,
  passives: graphBlue,
  detractors: graphRed
};

const getBiggerNpsColor = (nps) => {
  return nps > 50 ? graphGreen : graphBlue;
};

const getNPSColor = (nps) => {
  return nps < 0 ? graphRed : getBiggerNpsColor(nps);
};

const getPercent = (value, total) => {
  return total > 0 ? (value / total) * 100 : 0;
};

/**
 * Finally, the component.
 */
class InsightSatisfactionComponent extends InsightPageComponent {
  constructor(props) {
    super(props);

    this._formatTooltipNps = this._formatTooltipNps.bind(this);
  }

  // Tooltips formatter by domain
  _formatTooltipNps(points) {
    const {i18n} = this.context;
    const {insight} = this.props;

    const item = insight.npsScoreHistogram.items[points[0].point.index];
    const fixedNps = Math.round(item.nps);

    // here we transform the item into a Highchart object 'Point'
    const customPoints = Object.keys(ITEMS_CONFIG).map((key, index) => {
      const value = item[key];

      return {
        color: ITEMS_CONFIG[key],
        colorIndex: index,
        percentage: getPercent(value, item.totalCount),
        y: value
      };
    });

    return getPercentTooltip({
      i18n,
      insightPage: INSIGHT_PAGE,
      itemsConfig: ITEMS_CONFIG,
      points: customPoints,
      section: 'npsScore',
      subtitle: (
        <TableRow selectable={false} style={TOOLTIP_ROW_STYLE}>
          <TableRowColumn
            style={{
              paddingBottom: spacing.medium,
              textAlign: 'right',
              fontSize: fontSize.xxxxxlarge,
              fontWeight: fontWeight.semiBold,
              color: getNPSColor(fixedNps),
              ...TOOLTIP_ROW_COLUMN_STYLE
            }}
          >
            {fixedNps}
          </TableRowColumn>
          <TableRowColumn
            style={{
              paddingBottom: spacing.medium,
              ...TOOLTIP_ROW_COLUMN_STYLE
            }}
          >
            <span
              style={{
                fontSize: fontSize.xxlarge,
                fontWeight: fontWeight.semiBold
              }}
            >
              {item.totalCount}
            </span>
            <br />
            {i18n.t(`insight.pages.${INSIGHT_PAGE}.npsScore.chart.tooltip.answersCountSuffix`)}
          </TableRowColumn>
        </TableRow>
      )
    });
  }

  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    const aggregationPeriod =
      periodTabSelected === 'custom' ? customAggregationPeriod : classicAggregationPeriod;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <InsightPanelContentBlock title={i18n.t(`insight.pages.${INSIGHT_PAGE}.npsScore.title`)}>
            <HistogramChart
              dataTestId="nps-score-histogram-chart"
              tooltipValuesFormatter={this._formatTooltipNps}
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.npsScore.chart`, {
                returnObjects: true
              })}
              type="line"
              yAxisOptions={{floor: -100, ceiling: 100}}
              {...insight.npsScoreHistogram}
              {...{
                aggregationPeriod
              }}
              series={[
                {
                  property: 'nps'
                }
              ]}
            />
          </InsightPanelContentBlock>

          <InsightPanelContentBlock>
            <HistogramChart
              dataTestId="nps-percent-histogram-chart"
              colors={Object.values(ITEMS_CONFIG)}
              reversedStacks
              stacking="percent"
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.npsPercentPerChoice.chart`, {
                returnObjects: true
              })}
              type="column"
              {...insight.npsScoreHistogram}
              {...{
                aggregationPeriod
              }}
              series={[
                {
                  property: 'promoters'
                },
                {
                  property: 'passives'
                },
                {
                  property: 'detractors'
                }
              ]}
            />
          </InsightPanelContentBlock>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightSatisfactionComponent.contextTypes = context;

InsightSatisfactionComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  managerOfABusiness: PropTypes.bool.isRequired,
  organizationManager: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightSatisfactionComponent;
