import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {INSIGHT_DOMAIN_TO_ACTION_TYPE_MAPPING_FOR_OPEN} from '../../../lib/insights/insight-actions-helper';
import {
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH,
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_FAILURE,
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS,
  INSIGHT_PERIOD_TAB_CHANGE
} from '../../../actions/insight-actions';
import {DEBOUNCE_TIME_TYPING_FILTER} from '../../../../../shared/data/settings';

const InsightEpic =
  ({query}) =>
  (action$) => {
    const searchBusinesses = action$.pipe(
      ofType(INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      mergeMap(({text}) => {
        return query('search-browsable-businesses', {text}).pipe(
          map(({data}) => {
            return {
              type: INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS,
              items: data.items
            };
          }),
          takeUntil(action$.pipe(ofType(INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH))),
          catchError((error) => {
            return of({
              type: INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_FAILURE,
              error
            });
          })
        );
      })
    );

    const reloadPageInsightsWhenPeriodTabChange = action$.pipe(
      ofType(INSIGHT_PERIOD_TAB_CHANGE),
      map(({insightPage}) => {
        return {
          type: INSIGHT_DOMAIN_TO_ACTION_TYPE_MAPPING_FOR_OPEN[insightPage]
        };
      })
    );

    return merge(reloadPageInsightsWhenPeriodTabChange, searchBusinesses);
  };

export default InsightEpic;
