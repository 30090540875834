import getStandardParamsFromState from './get-standard-params-from-state';

const triggerLoadTablesAction = (state$, {loadRankingAction, rankingStateParamName}) => {
  const params = getStandardParamsFromState(state$, rankingStateParamName);

  return {
    type: loadRankingAction,
    ...params
  };
};

export default triggerLoadTablesAction;
