import moment from 'moment';

export default (period, date = null) => {
  const forDate = date ? date.clone() : moment();

  return forDate.startOf(
    {
      P1W: 'isoWeek',
      P1M: 'month',
      P1Y: 'year'
    }[period]
  );
};
