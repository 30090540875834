import PropTypes from 'prop-types';
import StandardButton from '../../../../../shared/component/button/standard-button-component';
import {boxShadow} from '../../../../../shared/style/variables';
import {black, veryLightGrey, white} from '../../../../../shared/style/colors';
import {CLICKABLE_AREAS} from '../../../data/ui/clickable-areas';
import {CONVERSATION_VISIBLE_STATUS_VALUES} from '../../../data/thread/conversation-status';

const ThreadStatusButtonsComponent = ({
  conversationStatus,
  i18n,
  onThreadStatusButtonClick,
  onUIAreaClick,
  updatingThreadStatus
}) => {
  const commonStatusButtonStyles = {
    backgroundColor: white,
    border: `1px solid ${veryLightGrey}`,
    boxShadow: boxShadow.normal,
    color: black,
    marginRight: '10px'
  };

  const floatingButtonsData = [
    {
      dataTestId: 'thread-status-waiting-button',
      status: CONVERSATION_VISIBLE_STATUS_VALUES.WAITING,
      text: i18n.t('customerThread.visibleThread.statusesFloatingButtons.waiting'),
      visible: conversationStatus !== CONVERSATION_VISIBLE_STATUS_VALUES.WAITING
    },
    {
      dataTestId: 'thread-status-replied-button',
      status: CONVERSATION_VISIBLE_STATUS_VALUES.REPLIED,
      text: i18n.t('customerThread.visibleThread.statusesFloatingButtons.replied'),
      visible: conversationStatus !== CONVERSATION_VISIBLE_STATUS_VALUES.REPLIED
    },
    {
      dataTestId: 'thread-status-resolved-button',
      status: CONVERSATION_VISIBLE_STATUS_VALUES.RESOLVED,
      text: i18n.t('customerThread.visibleThread.statusesFloatingButtons.resolved'),
      visible: conversationStatus !== CONVERSATION_VISIBLE_STATUS_VALUES.RESOLVED
    }
  ];

  const handleStatusButtonClick = (e) => {
    const {status} = e.currentTarget.dataset;
    onThreadStatusButtonClick(status);
  };

  const onClickStatusButtonsArea = () => {
    onUIAreaClick(CLICKABLE_AREAS.CUSTOMERS.CONVERSATION.VISIBLE.STATUS_BUTTONS);
  };

  return (
    <div
      data-test-id="status-buttons-area"
      onClick={onClickStatusButtonsArea}
      style={{display: 'flex', padding: '15px'}}
    >
      {floatingButtonsData.map((buttonData) => {
        return buttonData.visible ? (
          <StandardButton
            data-test-id={buttonData.dataTestId}
            data-status={buttonData.status}
            disabled={updatingThreadStatus}
            key={buttonData.dataTestId}
            onClick={handleStatusButtonClick}
            style={commonStatusButtonStyles}
          >
            {buttonData.text}
          </StandardButton>
        ) : null;
      })}

      {/* useful only for test */}
      <div data-test-id="space-around-buttons" style={{minWidth: '1px'}} />
    </div>
  );
};

ThreadStatusButtonsComponent.propTypes = {
  conversationStatus: PropTypes.string.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onThreadStatusButtonClick: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired,
  updatingThreadStatus: PropTypes.bool.isRequired
};

export default ThreadStatusButtonsComponent;
