import {
  INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD,
  INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD,
  INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CONTACTS_LOAD,
  INSIGHT_TOP_STORES_CONTACTS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CONTACTS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CONVERSATIONS_LOAD,
  INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD,
  INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_FAILURE,
  INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD,
  INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD,
  INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_SUCCESS
} from '../../actions/insight-actions';

export const sortBy = 'ranking';

export default {
  conversations: {
    actionTypes: [
      INSIGHT_TOP_STORES_CONVERSATIONS_LOAD,
      INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_FAILURE
    ],
    epicName: 'topConversations'
  },
  employeeResponseRate: {
    actionTypes: [
      INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD,
      INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_FAILURE
    ],
    epicName: 'topEmployeeResponseRate',
    valueType: 'percent'
  },
  averageResponseTime: {
    actionTypes: [
      INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD,
      INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_FAILURE
    ],
    epicName: 'topAverageResponseTime',
    valueType: 'duration'
  },
  inboundConversations: {
    actionTypes: [
      INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD,
      INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_FAILURE
    ],
    epicName: 'topInboundConversations'
  },
  outboundConversations: {
    actionTypes: [
      INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD,
      INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_FAILURE
    ],
    epicName: 'topOutboundConversations'
  },
  customerResponseRate: {
    actionTypes: [
      INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD,
      INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_FAILURE
    ],
    epicName: 'topCustomerResponseRate',
    valueType: 'percent'
  },
  customerResponseTime: {
    actionTypes: [
      INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD,
      INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE
    ],
    epicName: 'topCustomerResponseTime',
    valueType: 'duration'
  },
  contacts: {
    actionTypes: [
      INSIGHT_TOP_STORES_CONTACTS_LOAD,
      INSIGHT_TOP_STORES_CONTACTS_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_CONTACTS_LOAD_FAILURE
    ],
    epicName: 'topContacts'
  },
  connectedCustomers: {
    actionTypes: [
      INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD,
      INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_SUCCESS,
      INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_FAILURE
    ],
    epicName: 'topConnectedCustomers'
  }
};
