import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-duration-format';
import context from '../../../../../shared/component/context';

const MOMENT_DURATION_OPTIONS = {
  trunc: true,
  usePlural: false
};

/**
 * Useful sub-class that is dedicated to render waiting time in a format that depends of its value.
 */
class FormattedWaitingTime extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {diff} = this.props;

    const duration = moment.duration(diff);

    if (duration.asMinutes() < 1) {
      return duration.format(
        i18n.t('inbox.list.item.dateFormatsWaiting.lessThanOneMinute'),
        MOMENT_DURATION_OPTIONS
      );
    }

    if (duration.asHours() < 1) {
      return duration.format(
        i18n.t('inbox.list.item.dateFormatsWaiting.betweenOneMinuteAndOneHours'),
        MOMENT_DURATION_OPTIONS
      );
    }

    if (duration.asDays() < 1) {
      return duration.format(
        i18n.t('inbox.list.item.dateFormatsWaiting.betweenOneHourAndOneDay'),
        MOMENT_DURATION_OPTIONS
      );
    }

    if (duration.asDays() < 31) {
      return duration.format(
        i18n.t('inbox.list.item.dateFormatsWaiting.betweenOneDayAndOneMonth'),
        MOMENT_DURATION_OPTIONS
      );
    }

    if (duration.asYears() < 1) {
      return duration.format(
        i18n.t('inbox.list.item.dateFormatsWaiting.betweenOneMonthAndOneYear'),
        MOMENT_DURATION_OPTIONS
      );
    }

    return duration.format(
      i18n.t('inbox.list.item.dateFormatsWaiting.moreThanOneYear'),
      MOMENT_DURATION_OPTIONS
    );
  }
}

FormattedWaitingTime.contextTypes = context;

FormattedWaitingTime.propTypes = {
  diff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default FormattedWaitingTime;
