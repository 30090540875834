/**
 * Convenient functions to generate some urls.
 */
const generateCustomerConversationUrl = (customerId, conversationId) =>
  `/app/customers/${customerId}/conversations/${conversationId}`;

/**
 * Check if current route match a specific page.
 */
const CUSTOMER_CONVERSATION_URL_REGEX = new RegExp('^/app/customers/(\\d+)/conversations/(\\d+)');
const CUSTOMER_NEW_CONVERSATION_URL_REGEX = new RegExp('^/app/customers/(\\d+)/conversations/new');
const CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX = new RegExp(
  '^/app/customers/new/conversations/new'
);

const isCustomerConversationUrl = (url) => CUSTOMER_CONVERSATION_URL_REGEX.test(url);
const isCustomerNewConversationUrl = (url) => CUSTOMER_NEW_CONVERSATION_URL_REGEX.test(url);

const isCurrentPageCustomerThread = ({conversationId, customerId, participationId, state$}) => {
  if (participationId) {
    return state$.value.get('router').location.pathname === `/app/customers/${participationId}`;
  }

  return (
    state$.value.get('router').location.pathname ===
    generateCustomerConversationUrl(customerId, conversationId)
  );
};

const isCurrentPageInHeading = (heading, routePathname) => {
  const pathRegex = new RegExp(`^/app/${heading}`);

  return pathRegex.test(routePathname);
};

const isCurrentPageInHeadingCustomers = (routePathname) =>
  isCurrentPageInHeading('customers', routePathname);

const filterByCustomerConversationUrlFollowsFormat = (state$) => () => {
  const {pathname} = state$.value.get('router').location;

  return isCustomerConversationUrl(pathname);
};

const filterByCustomerNewConversationUrlFollowsFormat = (state$) => () => {
  const {pathname} = state$.value.get('router').location;

  return isCustomerNewConversationUrl(pathname);
};

const filterByCustomerConversationUrlNotFollowsFormat = (state$) => () =>
  !filterByCustomerConversationUrlFollowsFormat(state$)();

const isCurrentPageInHeadingAdministrationBusinessChannels = (state$) => () => {
  const {pathname} = state$.value.get('router').location;

  return isCurrentPageInHeading('administration/business/(\\d+)/channels', pathname);
};

export {
  CUSTOMER_CONVERSATION_URL_REGEX,
  CUSTOMER_NEW_CONVERSATION_URL_REGEX,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX,
  filterByCustomerConversationUrlFollowsFormat,
  filterByCustomerNewConversationUrlFollowsFormat,
  filterByCustomerConversationUrlNotFollowsFormat,
  generateCustomerConversationUrl,
  isCurrentPageCustomerThread,
  isCurrentPageInHeading,
  isCurrentPageInHeadingAdministrationBusinessChannels,
  isCurrentPageInHeadingCustomers
};
