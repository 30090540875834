import moment from 'moment';

/**
 * Check if a date is after window expiration.
 * @param {String} date
 * @param {String} windowExpiration
 * @returns {Boolean}
 */
const isAfterWindowExpiration = (date, windowExpiration) => {
  const fakeWindowExpiration = localStorage.getItem(
    'instaplyDesktop.fake-thread-window-expiration'
  );

  return moment(date).isAfter(fakeWindowExpiration || windowExpiration);
};

/**
 * Check if window is expired.
 * @param {String} windowExpiration
 * @returns {Boolean}
 */
const isWindowExpired = (windowExpiration) =>
  Boolean(windowExpiration) && isAfterWindowExpiration(moment(), windowExpiration);

export {isAfterWindowExpiration, isWindowExpired};
