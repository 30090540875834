import {PureComponent} from 'react';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Convenient class to handle an input form in a page.
 */
class PageFormHandlerComponent extends PureComponent {
  constructor(props) {
    super(props);

    this._onFieldChange = this._onFieldChange.bind(this);
    this._updateFieldErrorStatus = this._updateFieldErrorStatus.bind(this);
  }

  /**
   * Handle field updates:
   * - value
   * - errors
   *
   * Should be added to any form property.
   *
   * @param {Object} event
   * @private
   */
  _onFieldChange(event) {
    this._updateFieldErrorStatus(event.target.name, false, {
      [event.target.name]: event.target.value
    });
  }

  /**
   * Handle update of a field error.
   * Easily callable without parameters nor parenthesis,
   * thanks to default value that is good by default to reset error.
   *
   * The parameter "otherStateToUpdate" allows to update the state only once
   * when there are other properties to set.
   *
   * @param {Object} nameOrEvent
   * @param {Boolean} showError
   * @param {Object} otherStateToUpdate
   * @private
   */
  _updateFieldErrorStatus(nameOrEvent, showError = false, otherStateToUpdate = null) {
    const {showError: stateShowError} = this.state;
    const fieldName = typeof nameOrEvent === 'string' ? nameOrEvent : nameOrEvent.target.name;
    const newErrorState = cloneDeep(stateShowError);

    newErrorState[fieldName] = showError;

    this.setState({
      ...otherStateToUpdate,
      showError: newErrorState
    });
  }
}

export default PageFormHandlerComponent;
