import {merge} from 'rxjs';
import definitions from './lib/graph-definitions';
import {loadGraphInformation, loadInitialInsights} from './lib/prepare-graph-fill';
import {
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
} from '../../../actions/insight-actions';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';

const InsightNewOutboundConversationsEpic =
  ({graphql}) =>
  (action$, state$) => {
    const {actionTypes, onSuccess, query} = definitions.newOutboundConversations;
    const [loadAction, successAction, failureAction] = actionTypes;

    const loadInsights = loadInitialInsights(action$, state$, {
      actionsName: [
        INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN,
        INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ],
      loadAction
    });

    const loadNewOutboundConversationsGraphs = loadGraphInformation(action$, state$, graphql, {
      query,
      loadAction,
      failureAction,
      successAction,
      onSuccess,
      DEBOUNCE_TIME_STATISTIC,
      TIMEOUT_REQUEST_LONG
    });

    return merge(loadInsights, loadNewOutboundConversationsGraphs);
  };

export default InsightNewOutboundConversationsEpic;
