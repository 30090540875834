import {sendMessageSuccessful} from './message-helper';

/**
 * Create a thread from a message.
 *
 * @param {Object} state
 * @param {Object} clientMessageId
 * @param {String} participationId
 * @param {String} id
 * @returns {Object} new state
 */
function createThreadFromMessage(state, {clientMessageId, participationId, id}) {
  const newState = state.set('participationId', participationId);

  return sendMessageSuccessful(newState, {id, clientMessageId});
}

export default createThreadFromMessage;
