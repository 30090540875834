import PropTypes from 'prop-types';
import {fontWeight} from '../../../../../../shared/style/variables';
import {black} from '../../../../../../shared/style/colors';

const ColleagueMentionedComponent = ({mentionedUserName, style}) => (
  <span
    data-test-id="colleague-mentioned"
    style={{
      fontWeight: fontWeight.semiBold,
      color: black,
      ...style
    }}
  >
    {mentionedUserName}
  </span>
);
ColleagueMentionedComponent.propTypes = {
  mentionedUserName: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any)
};

export default ColleagueMentionedComponent;
