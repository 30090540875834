import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import CheckIcon from '@material-ui/icons/Check';
import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Popover from 'material-ui/Popover';
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import pullAll from 'lodash/pullAll';
import union from 'lodash/union';
import context from '../../../../../../shared/component/context';
import themeV1 from '../../../../../../shared/style/theme-v1';
import CloseOnEscapeComponent from '../../../../../../shared/component/close-on-escape-component';
import LoadingIndicator from '../../../../../../shared/component/loading-indicator-component';
import SearchField from '../../../../../../shared/component/form/search-field-component';
import getBusinessIds from '../../../../lib/get-businesses-ids';
import truncate from '../../../../../../shared/lib/truncate';
import {fontSize} from '../../../../../../shared/style/variables';
import {lightSlate} from '../../../../../../shared/style/colors';

const DesignedButton = ({children, ...props}) => (
  <MuiThemeProvider theme={themeV1}>
    <Button color="primary" {...props}>
      {children}
    </Button>
  </MuiThemeProvider>
);

DesignedButton.propTypes = {
  children: PropTypes.node.isRequired
};

const OutlinedButton = ({children, ...props}) => (
  <DesignedButton size="large" variant="outlined" {...props}>
    {children}
  </DesignedButton>
);

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired
};

const StyledList = withStyles({
  root: {
    maxHeight: '370px',
    overflowY: 'scroll'
  }
})(List);

const StyledOutlinedButton = withStyles({
  root: {
    width: '48%'
  }
})(OutlinedButton);

const getSortedBusinessesIds = (businesses) => getBusinessIds(businesses).sort();
const getSortedBusinessesSelection = (businessesSelection) => clone(businessesSelection).sort();

/**
 * Finally, the component.
 */
class MultipleBusinessesSelectComponent extends CloseOnEscapeComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      filteredItemsIds: getSortedBusinessesIds(props.filteredItems),
      isUpdatingSelection: false,
      isVisibleMenu: false,
      keyword: '',
      newBusinessesSelectionIds: getSortedBusinessesSelection(props.businessesSelection),
      newBusinessesSelectionDefaultIds: getSortedBusinessesIds(props.accountBusinesses) // convenient property to avoid multiple calls to getBusinessIds()
    };

    this._searchInput = React.createRef();

    this._onChangeKeyword = this._onChangeKeyword.bind(this);
    this._onClickReset = this._onClickReset.bind(this);
    this._onClickSelectAll = this._onClickSelectAll.bind(this);
    this._onClickToOpenMenu = this._onClickToOpenMenu.bind(this);
    this._onClickUnselectAll = this._onClickUnselectAll.bind(this);
    this._onConfirmSelection = this._onConfirmSelection.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // (Un)subscription to keyboard for handling [Escape]
    if (!prevState.isVisibleMenu && this.state.isVisibleMenu) {
      window.addEventListener('keydown', this._onKeyDown);
    } else if (prevState.isVisibleMenu && !this.state.isVisibleMenu) {
      window.removeEventListener('keydown', this._onKeyDown);
    }

    // Keep in state some convenient computed data
    if (!isEqual(this.props.filteredItems, prevProps.filteredItems)) {
      this._updateFilteredItemsIds();
    }
  }

  _updateFilteredItemsIds = () => {
    this.setState({
      filteredItemsIds: getSortedBusinessesIds(this.props.filteredItems)
    });
  };

  __doInsightBusinessFilterBusinessesSearch(keyword = '') {
    // Prevent useless reload from server when reopening the popup
    if (keyword === '' && this.state.keyword === keyword) {
      return;
    }

    this.setState({
      keyword
    });

    this.props.doInsightBusinessFilterBusinessesSearch(keyword);
  }

  __getIntersectionCount() {
    return intersection(this.state.filteredItemsIds, this.state.newBusinessesSelectionIds).length;
  }

  __updateSelection(newBusinessesSelectionIds, method) {
    const safeSelection = getSortedBusinessesSelection(newBusinessesSelectionIds);

    this.setState(
      {
        isUpdatingSelection: true
      },
      // Do not remove usage of calls to setTimeout in order to keep overlay effect !!
      () =>
        setTimeout(() =>
          this.setState(
            {
              newBusinessesSelectionIds: method
                ? method(safeSelection, this.state.filteredItemsIds)
                : safeSelection
            },
            () =>
              setTimeout(() =>
                this.setState(
                  {
                    isUpdatingSelection: false
                  },
                  this._searchInput.current.focus()
                )
              )
          )
        )
    );
  }

  _canReset() {
    return !isEqual(
      this.state.newBusinessesSelectionIds,
      this.state.newBusinessesSelectionDefaultIds
    );
  }

  _canSelectAll() {
    return this.__getIntersectionCount() < this.state.filteredItemsIds.length;
  }

  _canUnselectAll() {
    return this.__getIntersectionCount() > 0;
  }

  // Called on [Escape]
  _doClose() {
    this.setState({
      isVisibleMenu: false
    });
  }

  _getCurrentSelectionLabel() {
    const {i18n} = this.context;
    const {accountBusinesses, businessesSelection} = this.props;

    const totalInScope = accountBusinesses.length;
    const totalSelected = businessesSelection.length;
    const totalSelectedInScope = accountBusinesses.reduce(
      (accumulator, item) => accumulator + (businessesSelection.includes(item.businessId) ? 1 : 0),
      0
    );

    // Custom selection
    if (!totalInScope || totalSelectedInScope < totalInScope) {
      return i18n.t('insight.contentHeader.businessesSelector.clickableSelection.custom', {
        totalSelected
      });
    }

    // All businesses in my scope (+ eventually some out of my scope)
    let label = i18n.t('insight.contentHeader.businessesSelector.clickableSelection.myScope');
    if (totalSelected > totalSelectedInScope) {
      label += ` + ${totalSelected - totalSelectedInScope}`;
    }

    return label;
  }

  _onChangeKeyword(event) {
    this.__doInsightBusinessFilterBusinessesSearch(event.target.value);
  }

  _onClickReset() {
    this.__updateSelection(getBusinessIds(this.props.accountBusinesses));
  }

  _onClickSelectAll() {
    this.__updateSelection(this.state.newBusinessesSelectionIds, union);
  }

  _onClickUnselectAll() {
    this.__updateSelection(this.state.newBusinessesSelectionIds, pullAll);
  }

  _onClickToOpenMenu(event) {
    this.setState(
      {
        anchorEl: event.currentTarget,
        isVisibleMenu: true,
        newBusinessesSelectionIds: getSortedBusinessesSelection(this.props.businessesSelection)
      },
      this.__doInsightBusinessFilterBusinessesSearch
    );
  }

  _onConfirmSelection() {
    this.props.onSelectionChange(this.state.newBusinessesSelectionIds);

    this._doClose();
  }

  _renderSubList(resultDataTestId, title, items, subheaderEndAdornment) {
    const {newBusinessesSelectionIds} = this.state;

    return (
      <span data-test-id={resultDataTestId}>
        {title ? (
          <span>
            {subheaderEndAdornment}
            <ListSubheader disableSticky>{title}</ListSubheader>
          </span>
        ) : null}
        {items.map(({businessId, businessName}) => {
          const typedBusinessId = `${businessId}`;
          const isSelected = newBusinessesSelectionIds.includes(typedBusinessId);

          return (
            <ListItem
              data-test-id="item"
              button
              dense
              key={businessId}
              selected={isSelected}
              title={businessName}
              onClick={() =>
                this.setState(
                  {
                    newBusinessesSelectionIds: isSelected
                      ? newBusinessesSelectionIds.filter(
                          (selectBusinessId) => typedBusinessId !== selectBusinessId
                        )
                      : newBusinessesSelectionIds.concat(typedBusinessId).sort()
                  },
                  this._searchInput.current.focus()
                )
              }
            >
              <ListItemText primary={truncate(businessName, 40)} />
              {isSelected ? (
                <ListItemSecondaryAction>
                  <CheckIcon data-test-id="selected" color="primary" />
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          );
        })}
      </span>
    );
  }

  render() {
    const {filteredItems, loading} = this.props;
    const {keyword} = this.state;

    // Don't show label nor menu when useless
    if (keyword === '' && filteredItems.length < 2 && !loading) {
      return null;
    }

    const {i18n} = this.context;
    const {style} = this.props;
    const {anchorEl, isUpdatingSelection, isVisibleMenu, newBusinessesSelectionIds} = this.state;

    const businessesInScope = filteredItems.filter((item) => item.isEmployee);
    const businessesOutOfScope = filteredItems.filter((item) => !item.isEmployee);
    const isUpdating = loading || isUpdatingSelection;
    const totalSelected = newBusinessesSelectionIds.length;

    return (
      <div
        style={{
          display: 'inline-block',
          cursor: 'pointer',
          height: '48px',
          fontSize: fontSize.large,
          minWidth: '150px',
          maxWidth: '400px',
          ...style
        }}
      >
        <div
          data-test-id="business-select-menu-button"
          onClick={this._onClickToOpenMenu}
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <span style={{textAlign: 'right'}}>
            {i18n.t('insight.contentHeader.businessesSelector.clickableSelection.prefix')}{' '}
            {this._getCurrentSelectionLabel()}
          </span>
          <ArrowDropDown color={lightSlate} />
        </div>

        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          targetOrigin={{horizontal: 'right', vertical: 'top'}}
          open={isVisibleMenu}
          onRequestClose={this._doClose}
          style={{
            padding: '12px 0',
            minWidth: '460px'
          }}
        >
          <div data-test-id="businesses-select-menu">
            {isUpdating ? (
              <LoadingIndicator
                label={
                  isUpdatingSelection
                    ? i18n.t(
                        'insight.contentHeader.businessesSelector.popup.selectionUpdateInProgress'
                      )
                    : i18n.t('common.searching')
                }
                overlay
              />
            ) : null}

            <h1
              data-test-id="title"
              style={{
                textAlign: 'center',
                fontSize: fontSize.xlarge
              }}
            >
              {i18n.t('insight.contentHeader.businessesSelector.popup.title', {totalSelected})}
            </h1>

            <div
              style={{
                margin: '15px 24px 5px 24px'
              }}
            >
              <SearchField
                dataTestId="businesses-search-input"
                autoFocus
                fullWidth
                inputRef={this._searchInput}
                placeholder={i18n.t(
                  'insight.contentHeader.businessesSelector.popup.searchInputPlaceholder'
                )}
                value={keyword}
                variant="outlined"
                onChange={this._onChangeKeyword}
              />

              {filteredItems.length ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '15px'
                  }}
                >
                  <StyledOutlinedButton
                    data-test-id="select-all-button"
                    disabled={!this._canSelectAll()}
                    onClick={this._onClickSelectAll}
                  >
                    {i18n.t('insight.contentHeader.businessesSelector.popup.selectAllButton')}
                  </StyledOutlinedButton>
                  <StyledOutlinedButton
                    data-test-id="unselect-all-button"
                    disabled={!this._canUnselectAll()}
                    onClick={this._onClickUnselectAll}
                  >
                    {i18n.t('insight.contentHeader.businessesSelector.popup.clearAllButton')}
                  </StyledOutlinedButton>
                </div>
              ) : null}
            </div>

            <MuiThemeProvider theme={themeV1}>
              <StyledList>
                {businessesInScope.length
                  ? this._renderSubList(
                      'my-scope-result',
                      businessesOutOfScope.length
                        ? i18n.t('insight.contentHeader.businessesSelector.popup.myScopeResult')
                        : null,
                      businessesInScope,
                      <span
                        style={{
                          position: 'absolute',
                          right: '24px',
                          top: '13px',
                          zIndex: 2
                        }}
                      >
                        <DesignedButton
                          data-test-id="reset-button"
                          disabled={!this._canReset()}
                          onClick={this._onClickReset}
                        >
                          {i18n.t('insight.contentHeader.businessesSelector.popup.resetButton')}
                        </DesignedButton>
                      </span>
                    )
                  : null}
                {businessesOutOfScope.length
                  ? this._renderSubList(
                      'others-result',
                      businessesInScope.length
                        ? i18n.t('insight.contentHeader.businessesSelector.popup.othersResult')
                        : null,
                      businessesOutOfScope
                    )
                  : null}
              </StyledList>
            </MuiThemeProvider>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0 24px'
              }}
            >
              <DesignedButton
                data-test-id="cancel-button"
                disabled={isUpdating}
                onClick={this._doClose}
              >
                {i18n.t('insight.contentHeader.businessesSelector.popup.cancelButton')}
              </DesignedButton>
              <DesignedButton
                data-test-id="ok-button"
                disabled={isUpdating || !totalSelected}
                onClick={this._onConfirmSelection}
              >
                {i18n.t('insight.contentHeader.businessesSelector.popup.okButton')}
              </DesignedButton>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

MultipleBusinessesSelectComponent.contextTypes = context;

MultipleBusinessesSelectComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any).isRequired,
  filteredItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired
};

export default MultipleBusinessesSelectComponent;
