// @see https://developer.mozilla.org/en-US/docs/Web/API/notification

/**
 * Check if browser supports notification.
 * @returns {Boolean}
 */
const browserSupportsNotification = () => 'Notification' in window;

/**
 * Check browser's permission to trigger notification.
 * @returns {Boolean}
 */
const employeeGrantedBrowserNotification = () => Notification.permission === 'granted';
const employeeSelectedBrowserNotificationPermission = () => Notification.permission !== 'default';

module.exports = {
  browserSupportsNotification,
  employeeGrantedBrowserNotification,
  employeeSelectedBrowserNotificationPermission
};
