import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import sortImmutableListByMapProperty from '../../../../shared/reducer/lib/sort-immutable-list-by-map-property';
import {
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_CLOSE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_OPEN,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_SUCCESS,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SELECT_BUSINESS,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_SELECTION_SET,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_CLOSE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_SUCCESS,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_INPUT_UPDATE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_INVITE_MORE_OPEN,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_HEADER,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_NEW_COLLEAGUE_THREAD,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_BUSINESS_REMOVE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_COLLEAGUE_REMOVE,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT,
  GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_SUCCESS
} from '../../actions/global-actions';

export const DEFAULT_STATE = {
  inputText: '',
  open: false,
  sending: false,
  validating: false,
  businessesSelection: [],
  colleaguesSelection: [],
  sentTo: [],
  popupBusinessSelect: {
    filteredBusinesses: [],
    initializing: false,
    open: false,
    searchText: ''
  }
};

const openColleagueInvitationPopup = (state) => {
  return state.set('open', true);
};

const filterAndSortColleagueSelection = (selection, filterBy, value = true) => {
  return selection
    .filter((colleague) => colleague.get(filterBy) === value)
    .sort(sortImmutableListByMapProperty('email'));
};

const sortBusinesses = (businesses) => {
  return businesses.sort(sortImmutableListByMapProperty('name'));
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Add colleagues to the colleagues selection
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD]: (state) => {
    return state.set('validating', true);
  },

  /**
   * Add colleagues to the colleagues selection success,
   * remove duplicates,
   * and sort selection in order :
   * 1. valid domain
   * 2. invalid domain
   * 3. existing
   *
   * @param {Object} state
   * @param {Array} edges
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_COLLEAGUES_ADD_SUCCESS]: (state, {edges}) => {
    const formattedEdges = edges.map((item) => {
      const {node} = item;
      const {isRegistered} = node;

      return {
        isRegistered,
        email: node.email,
        isOrganizationDomain: isRegistered ? null : node.isOrganizationDomain,
        pictureHref:
          node.colleague && node.colleague.pictureHref ? node.colleague.pictureHref : null
      };
    });

    const newColleaguesSelection = state
      .get('colleaguesSelection')
      .filter(
        (colleague) =>
          formattedEdges.map((item) => item.email).indexOf(colleague.get('email')) === -1
      )
      .concat(fromJS(formattedEdges));

    return state
      .set('inputText', '')
      .set('validating', false)
      .set(
        'colleaguesSelection',
        fromJS([])
          .concat(filterAndSortColleagueSelection(newColleaguesSelection, 'isOrganizationDomain'))
          .concat(
            filterAndSortColleagueSelection(newColleaguesSelection, 'isOrganizationDomain', false)
          )
          .concat(filterAndSortColleagueSelection(newColleaguesSelection, 'isRegistered'))
      );
  },

  /**
   * Close popup
   *
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_CLOSE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  /**
   * Close popup select business
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_CLOSE]: (state) => {
    return state.set('popupBusinessSelect', fromJS(DEFAULT_STATE.popupBusinessSelect));
  },

  /**
   * Select a business in the businesses popup
   *
   * @param {Object} state
   * @param {Object} business
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SELECT_BUSINESS]: (state, {business}) => {
    return state
      .update('businessesSelection', (businessesSelection) =>
        sortBusinesses(
          businessesSelection
            .filter((item) => item.get('id') !== business.id)
            .push(fromJS(business))
        )
      )
      .updateIn(['popupBusinessSelect', 'filteredBusinesses'], (filteredBusinesses) =>
        filteredBusinesses.filter((item) => item.get('id') !== business.id)
      );
  },

  /**
   * Open popup from several locations
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_HEADER]: openColleagueInvitationPopup,
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP]:
    openColleagueInvitationPopup,
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_NEW_COLLEAGUE_THREAD]: openColleagueInvitationPopup,

  /**
   * Invite more colleagues.
   * Reset only colleagues selection
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_INVITE_MORE_OPEN]: (state) => {
    return state.set('sentTo', fromJS([])).set('colleaguesSelection', fromJS([]));
  },

  /**
   * Open popup select business
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_OPEN]: (state) => {
    return state.update('popupBusinessSelect', (popup) =>
      popup.set('initializing', true).set('open', true)
    );
  },

  /**
   * Remove a business from popup businesses selection
   *
   * @param {Object} state
   * @param {String} businessId
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_BUSINESS_REMOVE]: (state, {businessId}) => {
    return state.set(
      'businessesSelection',
      state.get('businessesSelection').filter((business) => businessId !== business.get('id'))
    );
  },

  /**
   * Remove a colleague from popup colleagues selection
   *
   * @param {Object} state
   * @param {String} email
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_SELECTED_COLLEAGUE_REMOVE]: (state, {email}) => {
    return state.set(
      'colleaguesSelection',
      state.get('colleaguesSelection').filter((colleague) => email !== colleague.get('email'))
    );
  },

  /**
   * Search a business to select.
   *
   * @param {Object} state
   * @param {String} text
   * @returns {*}
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH]: (state, {text}) => {
    return state.setIn(['popupBusinessSelect', 'searchText'], text);
  },

  /**
   * Successful search of a business to select.
   * Exclude from the result the businesses already selected
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {*}
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_POPUP_SEARCH_SUCCESS]: (state, {data}) => {
    const businessIdsToExclude = state
      .get('businessesSelection')
      .map((business) => business.get('id'));

    return state.update('popupBusinessSelect', (popup) =>
      popup.set('initializing', false).set(
        'filteredBusinesses',
        fromJS(
          data.items
            .map((business) => {
              return {
                id: business.id.toString(),
                name: business.name
              };
            })
            .filter((business) => !businessIdsToExclude.includes(business.id))
        )
      )
    );
  },

  /**
   * Set the businesses selection
   *
   * @param {Object} state
   * @param {Array} businesses
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_BUSINESSES_SELECTION_SET]: (state, {businesses}) => {
    return state.set(
      'businessesSelection',
      sortBusinesses(
        fromJS(
          businesses.map((business) => {
            return {
              id: business.businessId,
              name: business.name
            };
          })
        )
      )
    );
  },

  /**
   * Submit popup
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT]: (state) => {
    return state.set('sending', true);
  },

  /**
   * Submit popup success
   *
   * @param {Object} state
   * @param {Array} emails
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_SUBMIT_SUCCESS]: (state, {emails}) => {
    return state.set('sending', false).set(
      'sentTo',
      fromJS(
        emails.map((email) => {
          return {
            email
          };
        })
      )
    );
  },

  /**
   * Update popup colleagues input text
   *
   * @param {Object} state
   * @param {String} inputText
   * @returns {Map} new state
   */
  [GLOBAL_COLLEAGUE_INVITATION_POPUP_INPUT_UPDATE]: (state, {inputText}) => {
    return state.set('inputText', inputText);
  }
});
