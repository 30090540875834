export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_LAYOUT_MOUNTED =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_LAYOUT_MOUNTED';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_LOAD_FAILURE';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_FAILURE';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_CREATE_SUCCESS';

export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_SUCCESS';
export const ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_FAILURE =
  'ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_FAILURE';
