import PropTypes from 'prop-types';
import context from '../../../../../shared/component/context';
import AutomaticCappedLoader from '../loader/automatic-capped-loader-component';
import EmptyMessage from '../empty-message-component';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import {spacing} from '../../../../../shared/style/variables';
import {white} from '../../../../../shared/style/colors';

const InboxThreadListResultLayoutComponent = (
  {
    emptyDescription,
    emptyTitle,
    hasMore,
    hasNextPage,
    hasReachedLimit,
    items,
    loading,
    loadMore,
    offset,
    renderList,
    showEmptyMessageFillAll = false
  },
  {i18n}
) => {
  const renderEmptyMessage = (style) => (
    <EmptyMessage
      dataTestId="thread-list-empty"
      description={emptyDescription}
      title={emptyTitle}
      {...{
        style
      }}
    />
  );
  const renderAsListResultLayout = () => {
    return (
      <div
        data-test-id="threads-list"
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '1px',
          flex: 1,
          backgroundColor: white
        }}
      >
        {!loading || hasNextPage || offset ? (
          <div
            style={{
              overflowY: 'scroll',
              WebkitOverflowScrolling: 'touch',
              flex: 1,
              paddingLeft: spacing.medium,
              paddingRight: spacing.medium
            }}
          >
            {renderList()}

            {items.length
              ? null
              : renderEmptyMessage({position: 'absolute', width: '100%', left: 0})}

            <AutomaticCappedLoader
              hasMore={hasMore || hasNextPage}
              limitText={i18n.t('inbox.searchInboxLimit')}
              {...{
                hasReachedLimit,
                loading,
                loadMore
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  if (!items.length && loading) {
    return <LoadingIndicator />;
  }

  if (!items.length && showEmptyMessageFillAll) {
    return renderEmptyMessage();
  }

  return renderAsListResultLayout();
};

InboxThreadListResultLayoutComponent.contextTypes = context;

InboxThreadListResultLayoutComponent.propTypes = {
  emptyDescription: PropTypes.string,
  emptyTitle: PropTypes.string.isRequired,
  hasMore: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasReachedLimit: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  offset: PropTypes.number,
  renderList: PropTypes.func.isRequired,
  showEmptyMessageFillAll: PropTypes.bool
};

export default InboxThreadListResultLayoutComponent;
