import PropTypes from 'prop-types';

const AdministrationBusinessPageChannelsConnectHeaderComponent = ({icon, title}) => (
  <div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}>
    <img src={icon} alt="" style={{width: '30px'}} />
    <h4
      data-test-id="administration-business-channel-title"
      style={{marginLeft: '8px', fontSize: '16px', fontWeight: '600'}}
    >
      {title}
    </h4>
  </div>
);

AdministrationBusinessPageChannelsConnectHeaderComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default AdministrationBusinessPageChannelsConnectHeaderComponent;
