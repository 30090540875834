import i18next from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18nextIntervalPluralPostProcessor from 'i18next-intervalplural-postprocessor';
import i18nextHTTPBackend from 'i18next-http-backend';
import 'intl-pluralrules'; // @todo Remove this package on drop of support of IE 11
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import availableLanguages from '../shared/data/language';

export const i18nBuilder = (callback) =>
  i18next
    .use(i18nextBrowserLanguageDetector)
    .use(i18nextIntervalPluralPostProcessor)
    .use(i18nextHTTPBackend)
    .init(
      {
        backend: {
          loadPath: `${ASSETS_PATH}/locale/translation-{{lng}}.json`
        },
        cookieDomain: DOMAIN,
        fallbackLng: 'en',
        load: 'languageOnly',
        supportedLngs: availableLanguages.map(({key}) => key),
        interpolation: {
          escapeValue: false,
          format: (value, format, language) => {
            if (moment.isMoment(value)) {
              return language === 'fr' ? upperFirst(value.format(format)) : value.format(format);
            }

            return value;
          }
        }
      },
      callback
    );

export default i18next;
