import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-duration-format';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {blue, lightBlue, lightRed, veryLightRed} from '../../style/colors';

const TRAIL_COLOR_MAPPING = {
  [blue]: lightBlue,
  [lightRed]: veryLightRed
};

/**
 * Finally, the component.
 */
const CountdownCircleTimerComponent = ({
  color = blue,
  duration,
  fontSize,
  onComplete,
  size,
  strokeWidth
}) => {
  // As this package does not look natively compatible with "redux" action:
  // 1. do not remove bracket, as package expect function result to be "undefined" or in fixed custom format
  // 2. @todo test the removal of usage of setTimeout with future package version,
  //  checking that following warning is not triggered anymore on unmount:
  //  "Cannot update during an existing state transition (such as within `render`)..."
  const _onComplete = () => {
    setTimeout(onComplete);
  };

  return (
    <CountdownCircleTimer
      colors={[[color]]}
      isPlaying
      onComplete={_onComplete}
      trailColor={TRAIL_COLOR_MAPPING[color]}
      {...{
        duration,
        size,
        strokeWidth
      }}
    >
      {({remainingTime}) => (
        <span
          data-test-id="time"
          style={{
            fontSize,
            fontWeight: 'bold'
          }}
        >
          {moment.duration(remainingTime, 'seconds').format('h[:]mm[:]ss')}
        </span>
      )}
    </CountdownCircleTimer>
  );
};

CountdownCircleTimerComponent.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number.isRequired,
  fontSize: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired
};

export default CountdownCircleTimerComponent;
