import {PureComponent} from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import context from '../../../shared/component/context';
import BrowserNotificationBanner from './global/browser-notification-banner-component';
import ColleagueInvitationPopup from './global/invitation/global-invitation-popup-component';
import EnhancedSwitch from './common/enhanced-switch-component';
import FilePreview from './common/file/file-preview-component';
import GlobalError from './global/global-error-component';
import GlobalLoading from './global/global-loading-component';
import Header from './header/header-component';
import InternetExplorerDropSupportBanner from './global/banner/internet-explorer-drop-support-banner-component';
import NewReleaseBanner from './global/banner/new-release-banner-component';
import TopLayout from '../../../shared/component/top-layout-component';
import Tutorial from './global/tutorial/tutorial-component';
import VerifyEmail from './global/verify-email-component';
import WarningBanners from './global/banner/warning-banners-component';
import AdministrationContainer from '../container/content/administration/administration-container';
import ColleagueContainer from '../container/content/colleague/colleague-container';
import CustomerContainer from '../container/content/customer/customer-container';
import InsightsContainer from '../container/content/insights/insights-container';
import IntegrationAreaContainer from '../container/content/integration-area/integration-area-container';
import FaviconIndicator from './global/favicon-indicator/favicon-indicator-component';
import TitleBlinkIndicatorWrapper from './global/title-blink-indicator/title-blink-indicator-wrapper-component';

class AppComponent extends PureComponent {
  render() {
    const {i18n, muiTheme} = this.context;
    const {
      match,
      push,
      routePathname,
      doCloseTutorial,
      doFilePreviewClose,
      doRegisterTutorial,
      emailAddress,
      emailVerified,
      enableStatisticsMenu,
      enableStatisticsMenuRealTime,
      fullName,
      globalErrorsCount,
      isLoadingApp,
      isLoggingOut,
      isResendingVerifyEmail,
      isSmallScreen,
      newNotificationEventType,
      organizationPicture,
      picture,
      showAdmin,
      threadFilePreview,
      tutorial,
      unreadColleagueThreadCount,
      unreadCustomerThreadCount,
      userBusinesses,
      warning,
      windowFocused,
      // header mentions
      enableHeaderMentionsPopup,
      headerMention,
      doHeaderMentionsPopupClose,
      doHeaderMentionsPopupItemClick,
      doHeaderMentionsPopupItemsLoadMore,
      doHeaderMentionsPopupOpen,
      // twilio
      twilio,
      twilioEnable,
      doTwilioTokenLoad,
      // browser notification's banner
      browserNotificationBannerStep,
      doBrowserNotificationBannerClose,
      doBrowserNotificationBannerShowLast,
      doLogout,
      // new release banner
      isVisibleNewReleaseBanner,
      doApplicationReload,
      // colleague invitation
      colleagueInvitation,
      doAddColleagueInvitationPopupColleagues,
      doApplicationStateBootLoadRetry,
      doCloseColleagueInvitationPopup,
      doCloseColleagueInvitationPopupBusinessesPopup,
      doColleagueInvitationPopupBusinessesPopupSelectBusiness,
      doColleagueInvitationPopupOpen,
      doColleagueInvitationPopupOpenBusinessesPopup,
      doColleagueInvitationPopupOpenInviteMore,
      doColleagueInvitationPopupSearchBusiness,
      doRemoveColleagueInvitationPopupSelectedBusiness,
      doRemoveColleagueInvitationPopupSelectedColleague,
      doResendVerificationEmail,
      doSetColleagueInvitationPopupBusinessesSelection,
      doSubmitColleagueInvitationPopup,
      doUIEndMount,
      doUpdateColleagueInvitationPopupInput,
      onUIAreaClick
    } = this.props;

    if (isLoggingOut) {
      return <GlobalLoading />;
    }

    if (emailVerified === false) {
      return (
        <VerifyEmail
          {...{
            doLogout,
            doResendVerificationEmail,
            emailAddress,
            isResendingVerifyEmail
          }}
        />
      );
    }

    if (globalErrorsCount) {
      return (
        <GlobalError
          {...{
            doApplicationStateBootLoadRetry,
            isLoadingApp
          }}
        />
      );
    }

    if (isLoadingApp) {
      return <GlobalLoading {...{doUIEndMount}} />;
    }

    return (
      <TopLayout
        dataTestId="app"
        titlePrefix={unreadCustomerThreadCount ? `(${unreadCustomerThreadCount}) ` : null}
      >
        <FaviconIndicator actionNotificationType={newNotificationEventType} {...{windowFocused}} />

        <TitleBlinkIndicatorWrapper
          actionNotificationType={newNotificationEventType}
          {...{i18n, windowFocused}}
        />

        <InternetExplorerDropSupportBanner {...{i18n}} />

        {tutorial && (
          <Tutorial
            {...{
              doCloseTutorial,
              i18n,
              tutorial
            }}
          />
        )}

        {browserNotificationBannerStep ? (
          <BrowserNotificationBanner
            {...{
              browserNotificationBannerStep,
              doBrowserNotificationBannerClose,
              doBrowserNotificationBannerShowLast
            }}
          />
        ) : null}

        {isVisibleNewReleaseBanner ? <NewReleaseBanner {...{doApplicationReload}} /> : null}

        <WarningBanners {...{warning}} />

        {colleagueInvitation.open ? (
          <ColleagueInvitationPopup
            {...{
              colleagueInvitation,
              organizationPicture,
              userBusinesses,
              doAddColleagueInvitationPopupColleagues,
              doCloseColleagueInvitationPopup,
              doCloseColleagueInvitationPopupBusinessesPopup,
              doColleagueInvitationPopupBusinessesPopupSelectBusiness,
              doColleagueInvitationPopupOpenBusinessesPopup,
              doColleagueInvitationPopupOpenInviteMore,
              doColleagueInvitationPopupSearchBusiness,
              doRemoveColleagueInvitationPopupSelectedBusiness,
              doRemoveColleagueInvitationPopupSelectedColleague,
              doSetColleagueInvitationPopupBusinessesSelection,
              doSubmitColleagueInvitationPopup,
              doUpdateColleagueInvitationPopupInput
            }}
          />
        ) : null}

        {threadFilePreview.open ? (
          <FilePreview
            src={threadFilePreview.src}
            {...{
              doFilePreviewClose
            }}
          />
        ) : null}

        <Header
          {...{
            enableHeaderMentionsPopup,
            enableStatisticsMenu,
            enableStatisticsMenuRealTime,
            fullName,
            headerMention,
            i18n,
            isSmallScreen,
            muiTheme,
            organizationPicture,
            picture,
            routePathname,
            showAdmin,
            twilio,
            twilioEnable,
            unreadColleagueThreadCount,
            unreadCustomerThreadCount,
            doHeaderMentionsPopupClose,
            doHeaderMentionsPopupItemClick,
            doHeaderMentionsPopupItemsLoadMore,
            doHeaderMentionsPopupOpen,
            doLogout,
            doColleagueInvitationPopupOpen,
            doRegisterTutorial,
            doTwilioTokenLoad,
            onUIAreaClick,
            push
          }}
        />

        <EnhancedSwitch>
          <Route path={`${match.url}/administration`} component={AdministrationContainer} />
          <Redirect from={match.url} exact to={`${match.url}/customers`} />
          <Route path={`${match.url}/customers`} component={CustomerContainer} />
          <Route path={`${match.url}/colleagues`} component={ColleagueContainer} />
          <Route path={`${match.url}/insights`} component={InsightsContainer} />
          <Route path={`${match.url}/integration-area`} component={IntegrationAreaContainer} />
        </EnhancedSwitch>
      </TopLayout>
    );
  }
}

AppComponent.contextTypes = context;

AppComponent.propTypes = {
  browserNotificationBannerStep: PropTypes.string,
  colleagueInvitation: PropTypes.objectOf(PropTypes.any).isRequired,
  emailAddress: PropTypes.string,
  emailVerified: PropTypes.bool,
  enableHeaderMentionsPopup: PropTypes.bool,
  enableStatisticsMenu: PropTypes.bool.isRequired,
  enableStatisticsMenuRealTime: PropTypes.bool.isRequired,
  fullName: PropTypes.string,
  headerMention: PropTypes.objectOf(PropTypes.any).isRequired,
  globalErrorsCount: PropTypes.number.isRequired,
  isLoadingApp: PropTypes.bool.isRequired,
  isLoggingOut: PropTypes.bool.isRequired,
  isResendingVerifyEmail: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  isVisibleNewReleaseBanner: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  newNotificationEventType: PropTypes.string,
  organizationPicture: PropTypes.string,
  picture: PropTypes.string,
  routePathname: PropTypes.string.isRequired,
  showAdmin: PropTypes.bool,
  threadFilePreview: PropTypes.objectOf(PropTypes.any),
  tutorial: PropTypes.objectOf(PropTypes.any),
  twilio: PropTypes.objectOf(PropTypes.any).isRequired,
  twilioEnable: PropTypes.bool,
  userBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  unreadColleagueThreadCount: PropTypes.number,
  unreadCustomerThreadCount: PropTypes.number,
  warning: PropTypes.objectOf(PropTypes.any).isRequired,
  windowFocused: PropTypes.bool.isRequired,
  doAddColleagueInvitationPopupColleagues: PropTypes.func.isRequired,
  doApplicationStateBootLoadRetry: PropTypes.func.isRequired,
  doApplicationReload: PropTypes.func.isRequired,
  doBrowserNotificationBannerClose: PropTypes.func.isRequired,
  doBrowserNotificationBannerShowLast: PropTypes.func.isRequired,
  doCloseColleagueInvitationPopup: PropTypes.func.isRequired,
  doCloseColleagueInvitationPopupBusinessesPopup: PropTypes.func.isRequired,
  doCloseTutorial: PropTypes.func.isRequired,
  doColleagueInvitationPopupBusinessesPopupSelectBusiness: PropTypes.func.isRequired,
  doColleagueInvitationPopupSearchBusiness: PropTypes.func.isRequired,
  doFilePreviewClose: PropTypes.func.isRequired,
  doHeaderMentionsPopupClose: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemClick: PropTypes.func.isRequired,
  doHeaderMentionsPopupItemsLoadMore: PropTypes.func.isRequired,
  doHeaderMentionsPopupOpen: PropTypes.func.isRequired,
  doLogout: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpenBusinessesPopup: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpenInviteMore: PropTypes.func.isRequired,
  doRegisterTutorial: PropTypes.func.isRequired,
  doRemoveColleagueInvitationPopupSelectedBusiness: PropTypes.func.isRequired,
  doResendVerificationEmail: PropTypes.func.isRequired,
  doRemoveColleagueInvitationPopupSelectedColleague: PropTypes.func.isRequired,
  doSetColleagueInvitationPopupBusinessesSelection: PropTypes.func.isRequired,
  doSubmitColleagueInvitationPopup: PropTypes.func.isRequired,
  doTwilioTokenLoad: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUpdateColleagueInvitationPopupInput: PropTypes.func.isRequired,
  onUIAreaClick: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
};

export default AppComponent;
