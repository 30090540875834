import moment from 'moment';
import {cloneDeep, pickBy} from 'lodash';
import {createSelector} from 'reselect';
import {checkIfDefined} from '../lib/core-helper';
import {
  CONVERSATION_ITEM_TYPES,
  CONVERSATION_MENTION_TARGET_TYPES,
  CONVERSATION_META_ITEM_RENDERERS
} from '../data/thread/message';

/**
 * Reorganize conversation items in order to:
 *  1. insert "date" separator between 2 edges that are not on same day
 *  2. insert "segment" separator between 2 edges that are not on same segment
 *  3. group in same "batch" the consecutive messages that are automatic or from same author
 * A batch has 2 parts:
 *  1. (mandatory) some "metadata" common to its eventual messages, like "type", 'employee"...
 *  2. (optional) messages, that have all data specific to each "sub-"message content like "attachment", "text"...
 * This function handle both customer and employee messages, this explain why sometime we check both.
 * We voluntarily not use too many destructuration in order to understand better where the properties comes from.
 * @param {Array} accumulated
 * @param {Object} edge
 * @param {Number} edgeIndex
 * @param {Array} edges
 * @returns {Array} the list of conversation items batches to render
 */
const groupConversationItemsEdgesPerBatch = (accumulated, edge, edgeIndex, edges) => {
  const conversationItemsBatches = cloneDeep(accumulated);

  const previousEdge = edgeIndex > 0 ? edges[edgeIndex - 1] : null;
  const previousNode = previousEdge ? previousEdge.node : null;
  const previousNodeDate = previousNode ? moment(previousNode.date) : null;

  const currentNode = edge.node;
  const currentNodeDate = moment(currentNode.date);

  const isMessage = [
    CONVERSATION_ITEM_TYPES.INBOUND,
    CONVERSATION_ITEM_TYPES.INTERNAL,
    CONVERSATION_ITEM_TYPES.OUTBOUND
  ].includes(currentNode.type);

  // 1. Eventually insert a date separator
  const insertDateSeparator = () => {
    conversationItemsBatches.push({
      type: CONVERSATION_META_ITEM_RENDERERS.DATE,
      date: currentNode.date
    });
  };

  if (previousNode) {
    const currentDateIsSameAsPrevious = currentNodeDate.isSame(previousNodeDate, 'day');

    if (!currentDateIsSameAsPrevious) {
      insertDateSeparator();
    }
  } else {
    insertDateSeparator();
  }

  // 2. Eventually insert a new segment separator
  if (currentNode.firstOfSegment) {
    conversationItemsBatches.push({
      type: CONVERSATION_META_ITEM_RENDERERS.NEW_CONVERSATION
    });
  }

  // 3. Batching conditions
  const conversationItemsLastGroup = conversationItemsBatches[conversationItemsBatches.length - 1];

  const checkIfNodeCanBeAddedToOngoingBatch = () => {
    // Obviously, it's possible to "add" only if there is an ongoing batch, because "META_..." batches has no property "messages"
    if (!conversationItemsLastGroup.messages) {
      return false;
    }

    // Only a message-like (= contains attachment and/or text) can be grouped into a batch
    if (!isMessage) {
      return false;
    }

    // Not sure this check is helpful, but could in case of edge cases,
    // for example if "customer" is same email than an employee, or maybe for colleague thread
    if (currentNode.type !== previousNode.type) {
      return false;
    }

    // Check if it's the same author in previous and current.
    // Inbound message is automatically eligible for grouping as author is necessarily unique.
    // Same for automatic message as we don't care the (fake employee) author
    const isInboundMessage = currentNode.type === CONVERSATION_ITEM_TYPES.INBOUND;
    if (!isInboundMessage && !currentNode.automatic) {
      const currentNodeEmployee = currentNode.employee;
      const previousNodeEmployee = previousNode.employee;
      if (
        currentNodeEmployee &&
        previousNodeEmployee &&
        currentNodeEmployee.id !== previousNodeEmployee.id
      ) {
        return false;
      }
    }

    // Handle the very edge case where an automatic message was sent with author picked randomly in employees,
    // then the real same employee send manually another message
    if (currentNode.automatic !== previousNode.automatic) {
      return false;
    }

    // Max 2 minutes diff with previous message, comparing the time seen by the employee
    const currentNodeMinutes = currentNodeDate.toObject().minutes;
    const previousNodeMinutes = previousNodeDate.toObject().minutes;

    return Math.abs(currentNodeMinutes - previousNodeMinutes) <= 2;
  };

  // 4. Push into the accumulation only useful properties, creating a new batch if necessary
  let message;
  if (isMessage) {
    message = {
      _sendAction: currentNode._sendAction,
      attachment: currentNode.attachment,
      clientItemId: currentNode.clientItemId,
      date: currentNode.date,
      flowStatus: currentNode.flowStatus,
      moderated: currentNode.moderated,
      selected: currentNode.selected,
      text: currentNode.text
    };

    if (currentNode.mentions) {
      const [firstMention] = currentNode.mentions;

      if (firstMention.type === CONVERSATION_MENTION_TARGET_TYPES.USER) {
        message.mentionedUserName = firstMention.name;
      }
    }

    // This is not mandatory to work, but very useful during unit tests and debug
    message = pickBy(message, checkIfDefined);
  }

  if (checkIfNodeCanBeAddedToOngoingBatch()) {
    conversationItemsLastGroup.messages.push(message);
  } else {
    const newBatch = {
      type: currentNode.type
    };

    if (isMessage) {
      newBatch.automatic = currentNode.automatic;
      newBatch.messages = [message];
    } else {
      newBatch.date = currentNode.date;

      if (currentNode.type === CONVERSATION_ITEM_TYPES.STATUS_UPDATE) {
        newBatch.status = currentNode.status;
      }
    }

    // No need to do the same for object "customer" as we never need its properties for rendering
    if (currentNode.employee) {
      // Assign only properties useful for rendering
      if (currentNode.automatic) {
        newBatch.orgIcon = currentNode.orgIcon;
      } else {
        // We could just assign object the whole "employee" to previous object,
        // but we prefer to explicitly add each interesting props
        newBatch.employee = {
          displayName: currentNode.employee.displayName,
          id: currentNode.employee.id,
          pictureHref: currentNode.employee.pictureHref
        };
      }
    }

    conversationItemsBatches.push(newBatch);
  }

  return conversationItemsBatches;
};

export default createSelector(
  (state) => state.getIn(['conversationItems', 'edges']),
  (edges) => !!edges && edges.toJS().reduce(groupConversationItemsEdgesPerBatch, [])
);
