import {merge, of} from 'rxjs';
import {catchError, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  CUSTOMER_CONVERSATION_URL_REGEX,
  CUSTOMER_NEW_CONVERSATION_URL_REGEX,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX
} from '../../../../lib/route-helper';
import {
  filterByMultistoreConversationEnabled,
  filterByMultistoreConversationDisabled,
  isNotAvailableFeatureToggle
} from '../../../../lib/feature-toggle-helper';
import {graphqlGetMarkCustomerConversationAsReadMutation} from './graphql-queries/graphql-conversation-query-helper';
import onRouteChange from '../../../lib/on-route-change';
import {parseSearch} from '../../../../../../shared/lib/route-helper';
import {
  CUSTOMER_CONVERSATION_MARK_AS_READ,
  CUSTOMER_CONVERSATION_MARK_AS_READ_FAILURE,
  CUSTOMER_CONVERSATION_MARK_AS_READ_SUCCESS,
  CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECT,
  LEGACY_CUSTOMER_THREAD_LOAD
} from '../../../../actions/customer-thread-actions';
import {CUSTOMER_THREAD_INVISIBLE_LOAD} from '../../../../actions/customer-thread-invisible-actions';
import {CUSTOMER_THREAD_VISIBLE_LOAD} from '../../../../actions/customer-thread-visible-actions';
import {
  LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD,
  ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION,
  ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION,
  ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER
} from '../../../../actions/router-actions';
import {CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY} from '../../../../data/settings';

const CustomerThreadEpic =
  ({graphql}) =>
  (action$, state$) => {
    const detectRouterLocationChangedForCustomerThread = onRouteChange({
      action: action$,
      regex: CUSTOMER_CONVERSATION_URL_REGEX,
      mapFn: ({pathname, search}) => {
        const urlMatches = pathname.match(CUSTOMER_CONVERSATION_URL_REGEX);

        return {
          type: ROUTER_LOCATION_CHANGED_TO_CUSTOMER_CONVERSATION,
          payload: {
            conversationIdUrlParam: urlMatches[2],
            customerIdUrlParam: urlMatches[1],
            ...parseSearch(search)
          }
        };
      }
    });

    // Shared with legacy
    const legacyDetectRouterLocationChangedForCustomerThread = onRouteChange({
      action: action$,
      regex: new RegExp('^/app/customers/\\d+$'),
      mapFn: ({pathname, search}) => {
        return {
          type: LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD,
          payload: {
            participationId: pathname.split('/').pop(),
            ...parseSearch(search)
          }
        };
      }
    });

    const detectRouterLocationChangedForNewCustomerConversation = onRouteChange({
      action: action$,
      regex: CUSTOMER_NEW_CONVERSATION_URL_REGEX,
      mapFn: ({pathname, search}) => {
        const urlMatches = pathname.match(CUSTOMER_NEW_CONVERSATION_URL_REGEX);

        return {
          type: ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION,
          payload: {
            customerIdUrlParam: urlMatches[1],
            ...parseSearch(search)
          }
        };
      }
    });

    const detectRouterLocationChangedForNewConversationNewCustomer = onRouteChange({
      action: action$,
      regex: CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_REGEX,
      mapFn: ({search}) => {
        return {
          type: ROUTER_LOCATION_CHANGED_TO_NEW_CONVERSATION_NEW_CUSTOMER,
          payload: {
            ...parseSearch(search)
          }
        };
      }
    });

    const loadThreadOnRouterLocationChangedToCustomerThread = action$.pipe(
      ofType(LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD),
      filter(filterByMultistoreConversationDisabled(state$)),
      map(({payload}) => ({
        type: LEGACY_CUSTOMER_THREAD_LOAD,
        payload
      }))
    );

    const retryLoadThreadOnRouterLocationChangedToCustomerThread = action$.pipe(
      ofType(LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD),
      filter(isNotAvailableFeatureToggle(state$)),
      delay(CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY)
    );

    const redirectFromLegacyToCustomerConversationUrl = action$.pipe(
      ofType(LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD),
      filter(filterByMultistoreConversationEnabled(state$)),
      map(({payload: {participationId}}) => {
        return {
          type: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECT,
          payload: {
            participationId
          }
        };
      })
    );

    const markConversationAsRead = action$.pipe(
      ofType(CUSTOMER_CONVERSATION_MARK_AS_READ),
      mergeMap(({payload: {conversation, internal}}) => {
        return graphql(
          graphqlGetMarkCustomerConversationAsReadMutation(conversation.id, internal)
        ).pipe(
          map(() => ({
            type: CUSTOMER_CONVERSATION_MARK_AS_READ_SUCCESS
          })),
          catchError((error) =>
            of({
              type: CUSTOMER_CONVERSATION_MARK_AS_READ_FAILURE,
              error
            })
          )
        );
      })
    );

    const switchToThreadType = action$.pipe(
      ofType(CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE),
      map(({threadType}) => {
        const customerId = state$.value.getIn(['customer', 'thread', 'profile', 'customerId']);
        const participationId = state$.value.getIn(['customerThread', 'participationId']);

        return {
          type:
            threadType === 'visible'
              ? CUSTOMER_THREAD_VISIBLE_LOAD
              : CUSTOMER_THREAD_INVISIBLE_LOAD,
          customerId,
          participationId
        };
      })
    );

    return merge(
      detectRouterLocationChangedForCustomerThread,
      detectRouterLocationChangedForNewCustomerConversation,
      detectRouterLocationChangedForNewConversationNewCustomer,
      legacyDetectRouterLocationChangedForCustomerThread,
      loadThreadOnRouterLocationChangedToCustomerThread,
      markConversationAsRead,
      redirectFromLegacyToCustomerConversationUrl,
      retryLoadThreadOnRouterLocationChangedToCustomerThread,
      switchToThreadType
    );
  };

export default CustomerThreadEpic;
