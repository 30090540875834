import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import unsubscribe from './reducer/unsubscribe-reducer';
import unsubscribeEpic from './epic/unsubscribe-epic';

export default () => {
  const mea = middleEndAccessors();

  const rootEpic = createRootEpic(unsubscribeEpic(mea));

  const rootReducer = createRootReducer({
    unsubscribe
  });

  return {
    rootEpic,
    rootReducer
  };
};
