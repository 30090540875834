export const CUSTOMER_NEW_CONVERSATION_URL_GO = 'CUSTOMER_NEW_CONVERSATION_URL_GO';
export const CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD = 'CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD';
export const CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS =
  'CUSTOMER_NEW_CONVERSATION_VISIBLE_LOAD_SUCCESS';
export const CUSTOMER_NEW_CONVERSATION_VISIBLE_START = 'CUSTOMER_NEW_CONVERSATION_VISIBLE_START';

export const CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_BUTTON_CLICK =
  'CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_BUTTON_CLICK';

export const CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE =
  'CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE';
export const CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_FAILURE =
  'CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_FAILURE';
export const CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS =
  'CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS';

export const CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD =
  'CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD';
export const CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_SUCCESS =
  'CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_SUCCESS';
export const CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_FAILURE =
  'CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_LOAD_FAILURE';

export const CUSTOMER_NEW_CONVERSATION_START = 'CUSTOMER_NEW_CONVERSATION_START';

export const CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD =
  'CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD';
export const CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS =
  'CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS';
export const CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE =
  'CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_FAILURE';

export const CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS =
  'CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS';

export const CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START =
  'CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START';

export const CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_GO =
  'CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_URL_GO';

export const CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION =
  'CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION';

// legacy
export const LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD = 'LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD';
export const LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS =
  'LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS';

export const LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE =
  'LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE';
export const LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_FAILURE =
  'LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_FAILURE';
export const LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS =
  'LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS';
