import PropTypes from 'prop-types';
import {black} from 'material-ui/styles/colors';
import UserAvatar from '../../../../common/avatar/user-avatar-component';
import {oneline} from '../../../../../../../shared/style/ellipsis';
import {
  avatarSize,
  spacing,
  fontSize,
  lineHeight,
  radius
} from '../../../../../../../shared/style/variables';

const CustomerProfileAccountItemComponent = ({customerProfile, i18n}) => {
  const channelInformationID = {
    EMAIL: customerProfile.emailAddress,
    FACEBOOK: customerProfile.displayName,
    GBM: customerProfile.displayName,
    SDK: customerProfile.displayName,
    SMS: customerProfile.phoneNumber,
    WHATSAPP: customerProfile.phoneNumber
  }[customerProfile.channel];

  return (
    <div
      data-test-id="customer-profile-account-item"
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: radius.large,
        display: 'flex',
        width: '100%',
        padding: '5px'
      }}
    >
      <div data-test-id="account-image-content" style={{position: 'relative'}}>
        <UserAvatar
          dataTestId="account-picture-profile"
          size={avatarSize.large}
          src={customerProfile.pictureHref}
        />
        {channelInformationID ? (
          <img
            alt=""
            data-test-id="account-channel-icon"
            src={`${ASSETS_PATH}/img/icon/channel/${customerProfile.channel}.svg`}
            style={{
              bottom: 0,
              margin: '-2px -5px',
              position: 'absolute',
              right: 0,
              width: '25px'
            }}
          />
        ) : null}
      </div>
      <div
        data-test-id="channel-information"
        style={{
          marginLeft: spacing.large,
          fontSize: fontSize.large,
          lineHeight: lineHeight.large,
          color: black
        }}
      >
        <div data-test-id="channel-name">
          {channelInformationID
            ? i18n.t(`customerThread.customerProfile.customerChannels.${customerProfile.channel}`)
            : null}
        </div>
        <div
          data-test-id="channel-identifier"
          title={channelInformationID}
          style={{
            maxWidth: '345px',
            marginTop: '8px',
            ...oneline
          }}
        >
          {channelInformationID}
        </div>
      </div>
    </div>
  );
};

CustomerProfileAccountItemComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CustomerProfileAccountItemComponent;
