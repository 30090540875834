/**
 * Function that checks that a component property is empty or a url.
 *
 * @param {Object} props
 * @param {String} propName
 * @param {String} componentName
 * @returns {Error}
 */
export default (props, propName, componentName) => {
  const value = props[propName];

  if (value && !value.match(/^(http|\/assets)/)) {
    return new Error(`${componentName} : [${value}] is not a valid url`);
  }
};
