import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {TUTORIALS} from '../../../../../../data/tutorial/tutorial-settings';
import {isAfterWindowExpiration} from '../../../../../../lib/customer-thread/window-expiration-helper';
import StandardButton from '../../../../../../../../shared/component/button/standard-button-component';
import ThreadChannelFooterLayout from '../../../../../common/thread/permission/thread-channel-footer-layout-component';

const ThreadWhatsappPermissionFooterComponent = ({
  i18n,
  lastEmployeeMessagesBatch,
  windowExpiration,
  doWhatsappPermissionFooterConfirmRendered,
  onWhatsappTemplatesLoadButtonClick
}) => {
  useEffect(() => {
    doWhatsappPermissionFooterConfirmRendered();
  }, []);

  const hasEmployeeMessageSentAfterWindowExpired =
    lastEmployeeMessagesBatch && // We are not sure that an employee ever answered to the customer
    isAfterWindowExpiration(lastEmployeeMessagesBatch.date, windowExpiration);
  const askButtonLabelKey = hasEmployeeMessageSentAfterWindowExpired
    ? 'askPermissionAgain'
    : 'askPermission';

  return (
    <ThreadChannelFooterLayout
      className={TUTORIALS.customerThreadFooterWhatsappPermission.className}
      data-test-id="whatsapp-ask-permission-footer-container"
    >
      <StandardButton
        data-test-id="whatsapp-ask-permission-button"
        variant="outlined"
        onClick={onWhatsappTemplatesLoadButtonClick}
      >
        {i18n.t(`customerThread.visibleThread.channels.whatsapp.permission.${askButtonLabelKey}`)}
      </StandardButton>
      <span
        style={{
          maxWidth: '100%' // Fix for IE 11
        }}
      >
        {i18n.t('customerThread.visibleThread.channels.whatsapp.permission.messagesInformation')}
      </span>
    </ThreadChannelFooterLayout>
  );
};

ThreadWhatsappPermissionFooterComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  lastEmployeeMessagesBatch: PropTypes.objectOf(PropTypes.any),
  windowExpiration: PropTypes.string.isRequired,
  doWhatsappPermissionFooterConfirmRendered: PropTypes.func.isRequired,
  onWhatsappTemplatesLoadButtonClick: PropTypes.func.isRequired
};

export default ThreadWhatsappPermissionFooterComponent;
