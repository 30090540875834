import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import numeral from 'numeral';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import context from '../../../../../../../shared/component/context';
import formatInsightDuration from '../../lib/format-insight-duration';
import getStyledHelpLink from '../get-styled-help-link';
import ErrorMessage from '../../../../common/error-message-component';
import InsightPanelContentBlock, {
  SEPARATOR_WIDTH
} from '../layout/insight-panel-content-block-component';
import StandardButton from '../../../../../../../shared/component/button/standard-button-component';
import InsightRankingControlButtons from '../layout/header/insight-ranking-control-buttons';
import {fontSize, fontWeight, spacing} from '../../../../../../../shared/style/variables';
import {blue, green, red} from '../../../../../../../shared/style/colors';
import {STATISTICS_TABLE_RANKED_FIRST_PAGE_ITEMS_COUNT_MAX} from '../../../../../data/settings';

const StyledArrowDownward = withStyles({
  root: {
    color: red
  }
})(ArrowDownward);

const StyledArrowUpward = withStyles({
  root: {
    color: green
  }
})(ArrowUpward);

const StyledHelpLink = getStyledHelpLink({
  position: 'relative',
  right: '-2px'
});

const TABLE_CELL_PADDING_SIDE = '12px';
const TABLE_CELL_PADDING_SIDE_FIRST_COLUMN = 0;

const TABLE_COLUMNS = [
  {
    key: 'ranking',
    paddingRight: 0,
    width: '36px',
    renderer: (value) => numeral(value).format()
  },
  {
    align: 'center',
    key: 'increment',
    width: '50px',
    renderer: (increment) => {
      if (increment === 0) {
        return <span style={{fontSize: fontSize.xxlarge}}>=</span>;
      }

      return (
        <span title={numeral(increment).format('+0')}>
          {increment > 0 ? <StyledArrowUpward /> : <StyledArrowDownward />}
        </span>
      );
    }
  },
  {
    key: 'businessName',
    width: 'auto'
  },
  {
    align: 'right',
    key: 'value',
    paddingRight: 0,
    width: '72px',
    renderer: (value, valueType, i18n) => {
      switch (valueType) {
        case 'duration':
          return formatInsightDuration(value, i18n);
        case 'percent':
          return `${value}%`;
        default:
          break;
      }

      return numeral(value).format();
    }
  }
];

/**
 * Finally, the component.
 */
class TableRankedComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      areVisibleAllItems: !props.onClickButtonShowAllItems
    };
  }

  render() {
    const {i18n} = this.context;
    const {
      columns = ['businessName', 'value'],
      dataTestId,
      downloadCsvLabelTranslation,
      failed,
      isLast,
      items,
      insightDownloadCsv,
      insightSortRankings,
      loadingCsv,
      loading,
      onClickButtonShowAllItems,
      showRankingControlButtons,
      showCsvButton,
      showSortButton,
      translations,
      valueType
    } = this.props;
    const {areVisibleAllItems} = this.state;

    const showAllItems = () => {
      this.setState({areVisibleAllItems: true});

      onClickButtonShowAllItems();
    };

    const ButtonShowAllItems = () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: spacing.medium
        }}
      >
        <StandardButton
          data-test-id="show-all-items-button"
          onClick={showAllItems}
          style={{
            border: `1px solid ${blue}`,
            color: blue
          }}
        >
          {translations.showAllItemsButton}
        </StandardButton>
      </div>
    );
    const firstPageItemsCountMax =
      localStorage.getItem(
        'instaplyDesktop.fake-statistics-table-ranked-first-page-items-count-max'
      ) || STATISTICS_TABLE_RANKED_FIRST_PAGE_ITEMS_COUNT_MAX;
    const hasMoreItemsThanFirstPageMax = items.length > firstPageItemsCountMax;
    const isVisibleButtonShowAllItems =
      hasMoreItemsThanFirstPageMax && !areVisibleAllItems && onClickButtonShowAllItems;

    const itemsToRender = areVisibleAllItems ? items : items.slice(0, firstPageItemsCountMax);

    return (
      <InsightPanelContentBlock
        contentStyle={{
          padding: `${spacing.xlarge} ${spacing.xlarge} ${spacing.medium} ${spacing.xlarge}`
        }}
        style={{
          marginBottom: isLast ? 0 : SEPARATOR_WIDTH
        }}
        {...{
          dataTestId,
          loading
        }}
      >
        <div style={{flex: 1}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: spacing.large,
              fontSize: fontSize.xxlarge
            }}
          >
            {translations.title}
            {translations.helpPageUrl ? <StyledHelpLink url={translations.helpPageUrl} /> : null}
            {showRankingControlButtons ? (
              <InsightRankingControlButtons
                {...{
                  downloadCsvLabelTranslation,
                  i18n,
                  insightDownloadCsv,
                  insightSortRankings,
                  loadingCsv,
                  showCsvButton,
                  showSortButton
                }}
              />
            ) : null}
          </div>

          {(() => {
            /**
             * error
             */
            if (failed) {
              return <ErrorMessage style={{margin: spacing.xlarge}} />;
            }

            /**
             * ok
             */
            const lastItemIndex = itemsToRender.length - 1;

            return (
              <Table>
                <TableBody>
                  {(() => {
                    /**
                     * empty
                     */
                    if (!loading && !itemsToRender.length) {
                      return (
                        <TableRow data-test-id="empty-message">
                          <TableCell align="center" style={{borderBottom: 'none'}}>
                            {i18n.t('insight.content.common.noData')}
                          </TableCell>
                        </TableRow>
                      );
                    }

                    /**
                     * itemsToRender
                     */
                    return itemsToRender.map((row, indexRow) => {
                      return (
                        <TableRow
                          data-test-id="list-item"
                          key={row.businessId}
                          selected={row.selected}
                        >
                          {TABLE_COLUMNS.filter(({key}) => columns.includes(key)).map(
                            ({align = 'left', key, paddingRight, renderer, width}, index) => {
                              const value = row[key];
                              const showBorderBottom =
                                indexRow < lastItemIndex || isVisibleButtonShowAllItems;

                              return (
                                <TableCell
                                  data-test-id={kebabCase(key)}
                                  key={key}
                                  style={{
                                    width,
                                    paddingLeft:
                                      index === 0
                                        ? TABLE_CELL_PADDING_SIDE_FIRST_COLUMN
                                        : TABLE_CELL_PADDING_SIDE,
                                    paddingRight:
                                      typeof paddingRight !== 'undefined'
                                        ? paddingRight
                                        : TABLE_CELL_PADDING_SIDE,
                                    borderBottom: showBorderBottom ? undefined : 'none',
                                    fontWeight: row.isBusinessInUserScope
                                      ? fontWeight.bold
                                      : undefined
                                  }}
                                  {...{
                                    align
                                  }}
                                >
                                  {renderer ? renderer(value, valueType, i18n) : value}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    });
                  })()}
                </TableBody>
              </Table>
            );
          })()}

          {isVisibleButtonShowAllItems ? <ButtonShowAllItems /> : null}
        </div>
      </InsightPanelContentBlock>
    );
  }
}

TableRankedComponent.contextTypes = context;

TableRankedComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  dataTestId: PropTypes.string.isRequired,
  downloadCsvLabelTranslation: PropTypes.string,
  failed: PropTypes.bool.isRequired,
  insightDownloadCsv: PropTypes.func,
  insightSortRankings: PropTypes.func,
  isLast: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingCsv: PropTypes.bool,
  showCsvButton: PropTypes.bool,
  showRankingControlButtons: PropTypes.bool,
  showSortButton: PropTypes.bool,
  translations: PropTypes.objectOf(PropTypes.any).isRequired,
  valueType: PropTypes.string,
  onClickButtonShowAllItems: PropTypes.func
};

export default TableRankedComponent;
