export const UI_AREA_CLICK = 'UI_AREA_CLICK';

export const UI_END_MOUNT = 'UI_END_MOUNT';

export const UI_LANGUAGE_RELOAD = 'UI_LANGUAGE_RELOAD';
export const UI_LANGUAGE_UPDATE_SUCCESS = 'UI_LANGUAGE_UPDATE_SUCCESS';

export const UI_UPDATE_LANGUAGE = 'UI_UPDATE_LANGUAGE';

export const UI_WINDOW_DOM_CONTENT_LOADED = 'UI_WINDOW_DOM_CONTENT_LOADED';
export const UI_WINDOW_FOCUSED = 'UI_WINDOW_FOCUSED';
export const UI_WINDOW_RESIZED = 'UI_WINDOW_RESIZED';
export const UI_WINDOW_UNFOCUSED = 'UI_WINDOW_UNFOCUSED';
