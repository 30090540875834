import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import ThreadTransferPopup from '../../../../component/content/customer/thread/thread-transfer-popup-component';
import {
  LEGACY_CUSTOMER_THREAD_TRANSFER,
  LEGACY_CUSTOMER_THREAD_TRANSFER_CANCEL_WARNING_MESSAGE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SELECT_BUSINESS_OUT_OF_SCOPE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_NO_PHONE,
  LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_OUT_OF_SCOPE
} from '../../../../actions/customer-thread-transfer-actions';

const CustomerThreadTransferPopupContainer = connect(
  createSelector(
    [(state) => state.get('customerThreadTransfer'), (state) => state.get('featureToggle')],
    (customerThreadTransfer, featureToggle) => {
      return {
        ...customerThreadTransfer.toJS(),
        transferBackInScope: featureToggle.get('transfer_back_in_scope')
      };
    }
  ),
  {
    // Transfer
    doTransferPopupToggle() {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE,
        _googleAnalytics: true
      };
    },
    doTransferSearchBusiness(text) {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_SEARCH_BUSINESS,
        _googleAnalytics: true,
        text
      };
    },
    doTransferShowWarningNoPhone() {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_NO_PHONE,
        _googleAnalytics: true
      };
    },
    doTransferShowWarningOutOfScope() {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_SHOW_WARNING_OUT_OF_SCOPE,
        _googleAnalytics: true
      };
    },
    doTransferSelectBusinessOutOfScope(business) {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_SELECT_BUSINESS_OUT_OF_SCOPE,
        _googleAnalytics: true,
        business
      };
    },
    doTransferCancelWarningMessage() {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_CANCEL_WARNING_MESSAGE,
        _googleAnalytics: true
      };
    },
    doTransferDoTransfer(participationId, fullName, id, name, inScope) {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER,
        _googleAnalytics: true,
        fullName,
        id,
        inScope,
        name,
        participationId
      };
    }
  }
)(ThreadTransferPopup);

export default CustomerThreadTransferPopupContainer;
