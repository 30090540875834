import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';

const INSIGHT_PAGE = 'devices';

/**
 * Finally, the component.
 */
class InsightDevicesComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <InsightPanelContentBlock
            description={i18n.t(`insight.pages.${INSIGHT_PAGE}.employeeMessageChannel.description`)}
            title={i18n.t(`insight.pages.${INSIGHT_PAGE}.employeeMessageChannel.title`)}
          >
            <HistogramChart
              dataTestId="employee-message-channel-histogram-chart"
              aggregationPeriod={
                periodTabSelected === 'custom' ? customAggregationPeriod : classicAggregationPeriod
              }
              stacking="normal"
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.employeeMessageChannel.chart`, {
                returnObjects: true
              })}
              {...insight.employeeMessageChannelHistogram}
              series={[
                {
                  property: 'android'
                },
                {
                  property: 'ios'
                },
                {
                  property: 'webapp'
                }
              ]}
            />
          </InsightPanelContentBlock>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightDevicesComponent.contextTypes = context;

InsightDevicesComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightDevicesComponent;
