import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../../shared/component/context';
import {oneline} from '../../../../../../../shared/style/ellipsis';
import {
  fontSize,
  fontWeight,
  lineHeight,
  spacing
} from '../../../../../../../shared/style/variables';
import {lightSlate} from '../../../../../../../shared/style/colors';

class LegacyCustomerProfileCardTextComponent extends PureComponent {
  render() {
    const {
      emailStyle,
      participantCompany,
      participantEmail,
      participantName,
      participantPhone,
      readOnly,
      style
    } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginLeft: spacing.large,
          marginRight: spacing.medium,
          ...style
        }}
      >
        <h3
          data-test-id="name"
          title={participantName}
          style={{
            fontSize: fontSize.xlarge,
            fontWeight: fontWeight.semiBold,
            lineHeight: lineHeight.xlarge,
            ...oneline
          }}
        >
          {participantName}
        </h3>

        {!readOnly && participantEmail && participantEmail !== participantName ? (
          <div
            data-test-id="email"
            title={participantEmail}
            style={{
              marginBottom: spacing.small,
              lineHeight: lineHeight.large,
              ...emailStyle,
              ...oneline
            }}
          >
            {participantEmail}
          </div>
        ) : null}

        {!readOnly && participantPhone && participantPhone !== participantName ? (
          <span data-test-id="phone">{participantPhone}</span>
        ) : null}

        {participantCompany ? (
          <span data-test-id="company" style={{fontSize: fontSize.small, color: lightSlate}}>
            {participantCompany}
          </span>
        ) : null}
      </div>
    );
  }
}

LegacyCustomerProfileCardTextComponent.contextTypes = context;

LegacyCustomerProfileCardTextComponent.propTypes = {
  emailStyle: PropTypes.objectOf(PropTypes.any),
  participantCompany: PropTypes.string,
  participantEmail: PropTypes.string,
  participantName: PropTypes.string,
  participantPhone: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired
};

export default LegacyCustomerProfileCardTextComponent;
