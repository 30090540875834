import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {lightSlate} from '../../../../../shared/style/colors';

class NewOutboundMessageIcon extends PureComponent {
  render() {
    const {onClick, style} = this.props;

    return (
      <span
        data-test-id="start-new-conversation-button"
        className="mdi mdi-square-edit-outline mdi-24px"
        style={{
          cursor: 'pointer',
          color: lightSlate,
          ...style
        }}
        {...{
          onClick
        }}
      />
    );
  }
}

NewOutboundMessageIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired
};

export default NewOutboundMessageIcon;
