import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../shared/component/context';
import {ContinueOrSignInLink} from '../../../shared/component/link/links-to-desktop-component';
import FormMessage from '../../../shared/component/form/form-message-component';
import getErrorMessage from '../../../shared/lib/get-graphql-error-message';
import getRouteParam from '../../../shared/lib/get-route-param';
import PublicForm from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';
import {fontWeight} from '../../../shared/style/variables';
import {blue} from '../../../shared/style/colors';

class VerifyComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: getRouteParam('email', props.location)
    };
  }

  componentDidMount() {
    const {location, doVerifyEmailConfirm} = this.props;
    const {email} = this.state;

    doVerifyEmailConfirm(email, getRouteParam('token', location));
  }

  render() {
    const {i18n} = this.context;
    const {errors, language, verified, verifying, doUIEndMount, doUIUpdateLanguage} = this.props;
    const {email} = this.state;

    const errorMessage = getErrorMessage(errors, 'verifyEmail.confirm', i18n);

    return (
      <PublicPage
        dataTestId="verify"
        title={i18n.t('verifyEmail.confirm.title')}
        {...{
          language,
          doUIEndMount,
          doUIUpdateLanguage
        }}
      >
        <PublicForm
          globalErrorMessage={errorMessage}
          submitting={verifying}
          submittingLabel={i18n.t('verifyEmail.confirm.verifying')}
          title={i18n.t('verifyEmail.confirm.title')}
          buttons={verified ? <ContinueOrSignInLink /> : null}
        >
          {email ? (
            <FormMessage label={email} style={{color: blue, fontWeight: fontWeight.semiBold}} />
          ) : null}
          {verified ? (
            <FormMessage
              dataTestId="verified"
              label={i18n.t('verifyEmail.confirm.verified')}
              style={{marginTop: '20px'}}
            />
          ) : null}
        </PublicForm>
      </PublicPage>
    );
  }
}

VerifyComponent.contextTypes = context;

VerifyComponent.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  language: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
  verified: PropTypes.bool.isRequired,
  verifying: PropTypes.bool.isRequired,
  doUIEndMount: PropTypes.func,
  doUIUpdateLanguage: PropTypes.func.isRequired,
  doVerifyEmailConfirm: PropTypes.func.isRequired
};

export default VerifyComponent;
