import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {english} from 'flatpickr/dist/l10n/default'; // eslint-disable-line import/no-extraneous-dependencies
import {French} from 'flatpickr/dist/l10n/fr'; // eslint-disable-line import/no-extraneous-dependencies
import {Spanish} from 'flatpickr/dist/l10n/es'; // eslint-disable-line import/no-extraneous-dependencies
import ReactFlatPickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // eslint-disable-line import/no-extraneous-dependencies
import './flatpickr-custom.css';

const FLATPICKR_LOCALE_MAPPING = {
  es: Spanish,
  fr: French
};

/**
 * Finally, the component.
 */
class FlatPickrCustomComponent extends PureComponent {
  render() {
    const {options, render} = this.props;

    return (
      <ReactFlatPickr
        options={{
          locale: FLATPICKR_LOCALE_MAPPING[moment.locale()] || english,
          ...options
        }}
        {...{
          render
        }}
      />
    );
  }
}

FlatPickrCustomComponent.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  render: PropTypes.func.isRequired
};

export default FlatPickrCustomComponent;
