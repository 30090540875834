import PropTypes from 'prop-types';
import {getCurrentConversationFromState} from './customer-thread-conversations-helper';
import {getCurrentDateTime} from '../../../../../lib/date-time-helper';
import getNewClientItemId from '../../../../../lib/message-helper';
import {CONVERSATION_MENTION_TARGET_TYPES} from '../../../../../data/thread/message';

const getSendMessageActionFromMessageForm = (state$, actionName, message) => {
  const accountState = state$.value.get('account');
  const conversationId = getCurrentConversationFromState(state$).get('id');

  const payload = {
    conversation: {
      id: conversationId
    },
    employee: {
      displayName: accountState.get('fullName'),
      id: accountState.get('id'),
      pictureHref: accountState.get('picture')
    },
    message: {
      attachment: message.attachment,
      clientItemId: getNewClientItemId(),
      date: getCurrentDateTime(),
      text: message.text
    },
    organization: {
      pictureHref: accountState.get('organizationPicture')
    }
  };

  return {
    type: actionName,
    payload: message.mentionedUser
      ? {
          ...payload,
          mentions: [
            {
              name: message.mentionedUser.displayName,
              type: CONVERSATION_MENTION_TARGET_TYPES.USER,
              userId: message.mentionedUser.userId
            }
          ]
        }
      : payload
  };
};
getSendMessageActionFromMessageForm.propTypes = {
  actionName: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  state$: PropTypes.objectOf(PropTypes.any).isRequired
};

// eslint-disable-next-line import/prefer-default-export
export {getSendMessageActionFromMessageForm};
