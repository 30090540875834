import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {spacing} from '../../../../../shared/style/variables';
import {snow} from '../../../../../shared/style/colors';

const PanelContentLayoutComponent = forwardRef(
  ({children, dataTestId, id, style, onScroll}, ref) => {
    return (
      <div
        data-test-id={dataTestId}
        onScroll={onScroll}
        ref={ref}
        id={id}
        style={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          minHeight: '1px',
          padding: `${spacing.mlarge} ${spacing.xlarge}`,
          overflowY: 'scroll',
          background: snow,
          ...style
        }}
      >
        {children}
      </div>
    );
  }
);

PanelContentLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  onScroll: PropTypes.func
};

export default PanelContentLayoutComponent;
