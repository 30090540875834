import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  findConversationIndexByBusinessId,
  findConversationIndexByConversationId,
  getIncrementCounter
} from './lib/customer-thread-conversations-helper';
import {
  CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL,
  CUSTOMER_THREAD_CONVERSATION_TAB_CLICK,
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
  CUSTOMER_THREAD_PAGE_LEAVE,
  CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE,
  LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_THREAD_RELEASE_SUCCESS,
  CUSTOMER_THREAD_TAKE_SUCCESS
} from '../../../../actions/customer-thread-assignment-actions';
import {CUSTOMER_THREAD_INVISIBLE_LOAD} from '../../../../actions/customer-thread-invisible-actions';
import {
  CUSTOMER_THREAD_VISIBLE_LOAD,
  CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-thread-visible-actions';
import {
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD,
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION,
  CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS,
  CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS
} from '../../../../actions/customer-new-thread-actions';
import {
  EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME,
  EXT_CONVERSATION_STATUS_CHANGED,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME,
  EXT_EMPLOYEE_RELEASED_CONVERSATION,
  EXT_EMPLOYEE_TOOK_CONVERSATION
} from '../../../../actions/ext-actions';
import {CONVERSATION_VISIBLE_STATUS_VALUES} from '../../../../data/thread/conversation-status';

export const DEFAULT_STATE = {
  items: [],
  selectedItemOrder: null
};

const updateConversationItems = ({assignedTo, engaged, index, state}) => {
  const assignedToTransformed = assignedTo ? fromJS(assignedTo) : null;

  return state.updateIn(['items', index], (itemsData) =>
    itemsData.set('assignedTo', assignedToTransformed).set('engaged', engaged)
  );
};

const assignIfCurrentConversation = (state, {payload: {conversation, employee}}) => {
  const index = findConversationIndexByConversationId(state, conversation.id);

  if (index === -1) {
    return state;
  }

  return updateConversationItems({
    assignedTo: employee,
    engaged: true,
    index,
    state
  });
};

const releaseIfCurrentConversation = (state, {payload: {conversation}}) => {
  const index = findConversationIndexByConversationId(state, conversation.id);

  if (index === -1) {
    return state;
  }

  return updateConversationItems({
    assignedTo: null,
    engaged: false,
    index,
    state
  });
};

const updateStatusIntoConversation = (state, {payload: {conversation, status}}) => {
  const index = findConversationIndexByConversationId(state, conversation.id);

  return state.setIn(['items', index, 'status'], status);
};

const resetCurrentConversationCounter = (counterKey) => (state) => {
  const selectedItemOrder = state.get('selectedItemOrder');

  return state.setIn(['items', selectedItemOrder, counterKey], 0);
};

const resetState = () => fromJS(DEFAULT_STATE);

export default createReducer(DEFAULT_STATE, {
  /**
   * Set the conversation tab order.
   * @param {Immutable.Map} state
   * @param {String} orderTab
   * @returns {Immutable.Map} new state
   */
  [CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL]: (state, {payload: {orderTab}}) =>
    state.set('selectedItemOrder', orderTab),
  // Next action can be drop when legacy url handling will not be needed anymore
  [CUSTOMER_THREAD_CONVERSATION_TAB_CLICK]: (state, {payload: {orderTab}}) =>
    state.set('selectedItemOrder', orderTab),

  /**
   * Success of loading of thread's conversations and profile.
   * @param {Object} state
   * @param {Object} conversations
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]: (
    state,
    {payload: {conversations, orderTab}}
  ) => state.set('items', fromJS(conversations)).set('selectedItemOrder', orderTab),

  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]: (
    state,
    {payload: {conversations}}
  ) => state.set('items', fromJS(conversations)).set('selectedItemOrder', 0),

  [LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]: (
    state,
    {payload: {conversations}}
  ) => state.set('items', fromJS(conversations)).set('selectedItemOrder', 0),

  /**
   * Reset state on some events.
   */
  [CUSTOMER_THREAD_PAGE_LEAVE]: resetState,
  [CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD]: resetState,
  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD]: resetState,

  /**
   * Reset unread messages counter on customer thread part's loading start.
   * It's done optimistically (= not on "success") in order to avoid badge clipping.
   */
  [CUSTOMER_THREAD_INVISIBLE_LOAD]: resetCurrentConversationCounter('unreadInternalMessages'),
  [CUSTOMER_THREAD_VISIBLE_LOAD]: resetCurrentConversationCounter('unreadVisibleMessages'),

  /**
   * Triggered when user is in small screen view and switch between visible and invisible thread.
   * @param {Object} state
   * @param {String} threadType
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE]: (state, {threadType}) => {
    const selectedItemOrder = state.get('selectedItemOrder');
    const counterKey =
      threadType === 'visible' ? 'unreadVisibleMessages' : 'unreadInternalMessages';

    return state.setIn(['items', selectedItemOrder, counterKey], 0);
  },

  /**
   * Eventually update optimistically the conversation status on successful sending message in visible conversation.
   * We protect several times from data that could have evolved between sending start and end.
   * @param {Object} state
   * @param {Object} conversation
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS]: (state, {payload: {conversation}}) => {
    const conversationItemIndex = findConversationIndexByConversationId(state, conversation.id);

    // The whole thread may have changed
    if (conversationItemIndex === -1) {
      return state;
    }

    // The conversation status may have changed for another value that is not impacted by employee new message, like "resolved"
    const currentStatus = state.getIn(['items', conversationItemIndex, 'status']);
    if (currentStatus !== CONVERSATION_VISIBLE_STATUS_VALUES.WAITING) {
      return state;
    }

    return state.updateIn(['items', conversationItemIndex], (item) =>
      item.set('status', CONVERSATION_VISIBLE_STATUS_VALUES.REPLIED)
    );
  },
  [CUSTOMER_NEW_CONVERSATION_VISIBLE_SEND_MESSAGE_SUCCESS]: (
    state,
    {payload: {businessId, conversation}}
  ) => {
    const conversationItemIndex = findConversationIndexByBusinessId(state, businessId);

    // The whole thread may have changed
    if (conversationItemIndex === -1) {
      return state;
    }

    return state.updateIn(['items', conversationItemIndex], (item) =>
      item.set('status', CONVERSATION_VISIBLE_STATUS_VALUES.REPLIED).set('id', conversation.id)
    );
  },

  /**
   * Take and release.
   */
  [CUSTOMER_THREAD_TAKE_SUCCESS]: assignIfCurrentConversation,
  [EXT_EMPLOYEE_TOOK_CONVERSATION]: assignIfCurrentConversation,

  [CUSTOMER_THREAD_RELEASE_SUCCESS]: releaseIfCurrentConversation,
  [EXT_EMPLOYEE_RELEASED_CONVERSATION]: releaseIfCurrentConversation,

  /**
   * New message not from me.
   */
  [EXT_CONVERSATION_INTERNAL_NEW_MESSAGE_NOT_FROM_ME]:
    getIncrementCounter('unreadInternalMessages'),

  [EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER]:
    getIncrementCounter('unreadVisibleMessages'),

  [EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME]:
    getIncrementCounter('unreadVisibleMessages'),

  /**
   * Update status
   * @param {Object} state
   * @param {Object} conversation
   * @param {String} status
   * @returns {Object} new state
   */
  [CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS]: updateStatusIntoConversation,
  [EXT_CONVERSATION_STATUS_CHANGED]: (state, {payload: {conversation, status}}) => {
    return updateStatusIntoConversation(state, {payload: {conversation, status: status.new}});
  },

  [CUSTOMER_NEW_CONVERSATION_REMOVE_ITEM_FROM_CONVERSATIONS]: (
    state,
    {payload: {orderTab, conversations}}
  ) => {
    return state.set('items', conversations).set('selectedItemOrder', orderTab);
  },

  [CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION]: (state, {payload: {conversations}}) =>
    state.set('items', fromJS(conversations)).set('selectedItemOrder', 0)
});
