import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  TUTORIAL_CLOSE,
  TUTORIAL_REGISTER_NEW,
  TUTORIAL_RUN_NEXT
} from '../../actions/tutorial-actions';

export const DEFAULT_STATE = {
  current: null,
  nexts: []
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Add an entry:
   * - as current if no entry yet
   * - to the list of nexts to show
   * @param {Any} [options]
   * @param {Object} state
   * @returns {Object} new state
   */
  [TUTORIAL_REGISTER_NEW]: (state, {options, tutorial}) => {
    const newTutorial = fromJS({
      key: tutorial,
      options
    });

    if (state.get('current')) {
      return state.update('nexts', (nexts) => nexts.push(newTutorial));
    }

    return state.set('current', newTutorial);
  },

  /**
   * Delete current tutorial.
   * @param {Object} state
   * @returns {Object} new state
   */
  [TUTORIAL_CLOSE]: (state) => state.set('current', null),
  /**
   * Run next tutorial (= first entry).
   * @param {Object} state
   * @returns {Object} new state
   */
  [TUTORIAL_RUN_NEXT]: (state) =>
    state.set('current', state.get('nexts').first()).update('nexts', (nexts) => nexts.shift())
});
