import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Toolbar} from 'material-ui/Toolbar';
import {black} from '../../../../../shared/style/colors';

class HeaderLayoutComponent extends PureComponent {
  render() {
    const {children, onClick, style} = this.props;

    return (
      <Toolbar
        data-test-id="menu"
        style={{
          height: '50px',
          backgroundColor: black,
          ...style
        }}
        {...{
          onClick
        }}
      >
        {children}
      </Toolbar>
    );
  }
}

HeaderLayoutComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any),
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any)
};

export default HeaderLayoutComponent;
