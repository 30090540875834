import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {cleanHighlight} from '../../../../lib/list-item-helper';
import {filterByMultistoreConversationDisabled} from '../../../../lib/feature-toggle-helper';
import {getCurrentSearchTextAndOffsetLegacy} from './lib/customer-inbox-helper';
import {
  CUSTOMER_INBOX_GO_TO_THREAD,
  CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE,
  CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK,
  LEGACY_CUSTOMER_INBOX_SEARCH_ALL,
  LEGACY_CUSTOMER_INBOX_SEARCH_ALL_FAILURE,
  LEGACY_CUSTOMER_INBOX_SEARCH_ALL_SUCCESS,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE,
  LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
  LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE
} from '../../../../actions/customer-inbox-actions';
import {
  DEBOUNCE_TIME_MULTIPLE_CLICK,
  DEBOUNCE_TIME_TYPING_FILTER
} from '../../../../../../shared/data/settings';
import {
  CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_PER_PAGE,
  CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_PER_PAGE
} from '../../../../data/settings';

const LegacyCustomerInboxSearchEpic =
  ({query}) =>
  (action$, state$) => {
    const legacyGoToThreadOnSearchResultSelectCustomer = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({participationId}) => ({
        type: CUSTOMER_INBOX_GO_TO_THREAD,
        participationId
      }))
    );

    const legacyGoToThreadOnSearchResultSelectMessage = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({messageId, participationId}) => ({
        type: CUSTOMER_INBOX_GO_TO_THREAD,
        params: {
          direction: 'both',
          fromId: messageId
        },
        participationId
      }))
    );

    const legacySearchAll = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_SEARCH_ALL),
      mergeMap(() => {
        const params = {
          customersLimit: CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_PER_PAGE,
          customersOffset: 0,
          messagesLimit: CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_PER_PAGE,
          messagesOffset: 0,
          text: state$.value.getIn(['customerInbox', 'search', 'text']).trim()
        };

        return query('search-inbox', params).pipe(
          map(({data}) => ({
            type: LEGACY_CUSTOMER_INBOX_SEARCH_ALL_SUCCESS,
            data
          })),
          takeUntil(action$.pipe(ofType(LEGACY_CUSTOMER_INBOX_SEARCH_ALL))),
          catchError((error) =>
            of({
              type: LEGACY_CUSTOMER_INBOX_SEARCH_ALL_FAILURE,
              error
            })
          )
        );
      })
    );

    const legacySearchAllOnSearchFormTextChange = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_FORM_TEXT_CHANGE),
      filter(filterByMultistoreConversationDisabled(state$)),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      filter(({payload: {text}}) => text.trim() !== ''),
      map(() => ({
        type: LEGACY_CUSTOMER_INBOX_SEARCH_ALL
      }))
    );

    const legacySearchMoreCustomers = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS),
      mergeMap(() => {
        const {offset, text} = getCurrentSearchTextAndOffsetLegacy(state$, 'customers');

        const params = {
          types: ['customers'],
          customersLimit: CUSTOMER_INBOX_SEARCH_CUSTOMER_ITEMS_PER_PAGE,
          customersOffset: offset,
          text
        };

        return query('search-inbox', params).pipe(
          map(({data}) => ({
            type: LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_SUCCESS,
            data
          })),
          catchError((error) =>
            of({
              type: LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_FAILURE,
              error
            })
          )
        );
      })
    );

    const legacySearchMoreCustomersOnButtonClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS_BUTTON_CLICK),
      filter(filterByMultistoreConversationDisabled(state$)),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: LEGACY_CUSTOMER_INBOX_SEARCH_MORE_CUSTOMERS
      }))
    );

    const legacySearchMoreMessages = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES),
      mergeMap(() => {
        const {offset, text} = getCurrentSearchTextAndOffsetLegacy(state$, 'messages');

        const params = {
          types: ['messages'],
          messagesLimit: CUSTOMER_INBOX_SEARCH_MESSAGE_ITEMS_PER_PAGE,
          messagesOffset: offset,
          text
        };

        return query('search-inbox', params).pipe(
          map(({data}) => ({
            type: LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_SUCCESS,
            data
          })),
          catchError((error) =>
            of({
              type: LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_FAILURE,
              error
            })
          )
        );
      })
    );

    const legacySearchMoreMessagesOnButtonClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_MORE_MESSAGES_BUTTON_CLICK),
      filter(filterByMultistoreConversationDisabled(state$)),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(() => ({
        type: LEGACY_CUSTOMER_INBOX_SEARCH_MORE_MESSAGES
      }))
    );

    const legacySelectCustomerOnInboxSearchResultCustomerClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_RESULT_CUSTOMER_CLICK),
      filter(filterByMultistoreConversationDisabled(state$)),
      map(({payload: {indexInList}}) => {
        const itemState = state$.value.getIn([
          'customerInbox',
          'search',
          'customers',
          'items',
          indexInList
        ]);

        return {
          type: LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_CUSTOMER,
          company: cleanHighlight(itemState.get('company')),
          customerChannel: itemState.get('customerChannel'),
          directedToBusinessName: itemState.get('businessName'),
          participantIcon: itemState.get('customerPictureHref'),
          participantName: cleanHighlight(itemState.get('title')),
          participationId: itemState.get('businessParticipationId')
        };
      })
    );

    const legacySelectMessageOnInboxSearchResultMessageClick = action$.pipe(
      ofType(CUSTOMER_INBOX_SEARCH_RESULT_MESSAGE_CLICK),
      filter(filterByMultistoreConversationDisabled(state$)),
      map(({payload: {indexInList}}) => {
        const itemState = state$.value.getIn([
          'customerInbox',
          'search',
          'messages',
          'items',
          indexInList
        ]);

        return {
          type: LEGACY_CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
          customerChannel: itemState.get('customerChannel'),
          directedToBusinessName: itemState.get('businessName'),
          messageId: itemState.get('messageId'),
          participantName: itemState.get('title'),
          participationId: itemState.get('businessParticipationId')
        };
      })
    );

    return merge(
      legacyGoToThreadOnSearchResultSelectCustomer,
      legacyGoToThreadOnSearchResultSelectMessage,
      legacySearchAll,
      legacySearchAllOnSearchFormTextChange,
      legacySearchMoreCustomers,
      legacySearchMoreCustomersOnButtonClick,
      legacySearchMoreMessages,
      legacySearchMoreMessagesOnButtonClick,
      legacySelectCustomerOnInboxSearchResultCustomerClick,
      legacySelectMessageOnInboxSearchResultMessageClick
    );
  };

export default LegacyCustomerInboxSearchEpic;
