import cloneDeep from 'lodash/cloneDeep';
import TutorialSettingsAppHeaderStatisticsRealTime from './tutorial/tutorial-settings-app-header-statistics-real-time';
import TutorialSettingsCustomerThreadFooterWhatsappPermission from './tutorial/tutorial-settings-customer-thread-footer-whatsapp-permission';
import TutorialSettingsCustomerThreadHeaderChannel from './tutorial/tutorial-settings-customer-thread-header-channel';

/**
 * Main list of configuration.
 * Each item should be an object that match the "type" described below.
 * @type {{canShowTutorial, className, getConfig, getLocalStorageKey, key}}
 */
const TUTORIALS = [
  TutorialSettingsAppHeaderStatisticsRealTime,
  TutorialSettingsCustomerThreadFooterWhatsappPermission,
  TutorialSettingsCustomerThreadHeaderChannel
].reduce((tutorialsConfig, tutorial) => {
  const tutorialsConfigCopy = cloneDeep(tutorialsConfig);
  tutorialsConfigCopy[tutorial.key] = tutorial;

  return tutorialsConfigCopy;
}, {});

const LOCAL_STORAGE_VALUE_ONCE_DONE = 'done';

/**
 * Some higher-order functions that allows to prepare raw functions
 * with generic parameters + other parameters that will vary per tutorial.
 */
const getGetterTutorialIsShownFromLocalStorage = (getLocalStorageKey) => (parameters) => {
  const localStorageKey = getLocalStorageKey(parameters);

  return localStorage.getItem(localStorageKey) !== LOCAL_STORAGE_VALUE_ONCE_DONE;
};

const getPersistTutorialAsShownInLocalStorage = (getLocalStorageKey) => (parameters) => {
  const localStorageKey = getLocalStorageKey(parameters);

  localStorage.setItem(localStorageKey, LOCAL_STORAGE_VALUE_ONCE_DONE);
};

const getGetterCommonStepOptions = (className, i18n) => (i18nPrefix) => ({
  closeButton: i18n.t(`${i18nPrefix}.closeButton`),
  content: i18n.t(`${i18nPrefix}.body`),
  disableBeacon: true,
  target: `.${className}`,
  title: i18n.t(`${i18nPrefix}.title`)
});

/**
 * Check all requirements for a tutorial to decide if it can be shown or not.
 * @param {Object} parameters
 * @returns {Boolean}
 */
const shouldShowTutorial = (parameters) => {
  const {key} = parameters;

  if (!Object.keys(TUTORIALS).includes(key)) {
    throw new Error(`Unknown tutorial "${key}"`);
  }

  const {canShowTutorial, getLocalStorageKey} = TUTORIALS[key];

  return canShowTutorial({
    ...parameters,
    getTutorialIsShownFromLocalStorage: getGetterTutorialIsShownFromLocalStorage(getLocalStorageKey)
  });
};

/**
 * Get a tutorial configuration.
 * @param {String} key
 * @param {Object} [options]
 * @param {Object} i18n
 */
const getTutorialConfig = ({key, options}, i18n) => {
  if (!Object.keys(TUTORIALS).includes(key)) {
    throw new Error(`Unknown tutorial "${key}"`);
  }

  const {className, getConfig, getLocalStorageKey} = TUTORIALS[key];

  return getConfig(
    getGetterCommonStepOptions(className, i18n),
    getPersistTutorialAsShownInLocalStorage(getLocalStorageKey),
    options
  );
};

export {getTutorialConfig, shouldShowTutorial, TUTORIALS};
