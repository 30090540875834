/**
 * Handle a graphql error.
 *
 * @param {Object} errors
 * @param {Object} mapping
 * @returns {Object|String}
 */
export default (errors, mapping) => {
  if (Array.isArray(errors)) {
    return errors.map((error) => {
      const {code} = error;
      if (!code) {
        return error;
      }

      const mappedError = mapping[code];

      return mappedError || error;
    });
  }

  return errors;
};
