import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {KeyboardArrowDown} from '@material-ui/icons';

const PaymentHeaderComponent = ({
  amount,
  conversationId,
  i18n,
  onlyShowCancelButton,
  step,
  submitting = false,
  title,
  onClickCancelButton,
  onClickMinimizeButton
}) => {
  const _onClickMinimizeButton = () => onClickMinimizeButton(conversationId);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Button
        data-test-id="payment-cancel-button"
        color="primary"
        disabled={submitting}
        onClick={onClickCancelButton}
        size="small"
      >
        {i18n.t('customerThread.visibleThread.apps.cancelButton')}
      </Button>

      {onlyShowCancelButton ? null : (
        <React.Fragment>
          <h3 data-test-id="title" style={{flex: 1, textAlign: 'center'}}>
            {title ||
              i18n.t(`customerThread.visibleThread.apps.list.payment.${step}.title`, {amount})}
          </h3>
          <Button
            data-test-id="minimize-button"
            color="primary"
            disabled={submitting}
            onClick={_onClickMinimizeButton}
            size="small"
          >
            <KeyboardArrowDown />
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

PaymentHeaderComponent.propTypes = {
  amount: PropTypes.string,
  conversationId: PropTypes.string.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  step: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClickCancelButton: PropTypes.func.isRequired,
  onClickMinimizeButton: PropTypes.func.isRequired,
  onlyShowCancelButton: PropTypes.bool
};

export default PaymentHeaderComponent;
