import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import themeV1 from '../../style/theme-v1';
import styledBy from '../../style/utils';

const StyledButton = withStyles({
  endIcon: {
    position: styledBy('position', {
      default: 'inherit',
      positionRight: 'absolute'
    }),
    right: 0,
    top: 0
  },
  label: {
    padding: styledBy('padding', {
      default: '0 30px',
      noPadding: '0'
    }),
    position: 'relative'
  },
  startIcon: {
    left: 0,
    position: styledBy('position', {
      default: 'inherit',
      positionLeft: 'absolute'
    }),
    top: 0
  }
})(Button);

/**
 * Finally, the component.
 */
const StandardButtonComponent = (props) => (
  <MuiThemeProvider theme={themeV1}>
    <StyledButton color="primary" padding="default" position="default" size="small" {...props} />
  </MuiThemeProvider>
);

StandardButtonComponent.propTypes = {
  'data-test-id': PropTypes.string.isRequired
};

export default StandardButtonComponent;
