import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH,
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS
} from '../../../actions/insight-actions';

export const DEFAULT_STATE = {
  loading: false,
  filteredItems: null
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Search for browsable businesses.
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH]: (state) => {
    return state.set('loading', true);
  },

  /**
   * Search for browsable businesses success.
   *
   * @param {Object} state
   * @param {Array} items
   * @returns {Object} new state
   */
  [INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS]: (state, {items}) => {
    return state.set('filteredItems', fromJS(items)).set('loading', false);
  }
});
