import PropTypes from 'prop-types';
import {Emoji} from 'emoji-mart';
import {backgroundImageFn, getEmojiCode, getEmojiColons} from './emoji-tools';

const EmojiComponent = ({children, decoratedText}) => {
  const emojiColons = getEmojiColons(getEmojiCode(decoratedText));

  if (!emojiColons) {
    return null;
  }

  return (
    <Emoji backgroundImageFn={backgroundImageFn} emoji={emojiColons} size={20}>
      {children}
    </Emoji>
  );
};

EmojiComponent.propTypes = {
  children: PropTypes.node,
  decoratedText: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default EmojiComponent;
