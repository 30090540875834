import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK,
  CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE,
  CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {
  CUSTOMER_CONVERSATION_URL_GO,
  CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL,
  CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_THREAD_PAGE_LEAVE,
  LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {
  CUSTOMER_THREAD_PROFILE_CLOSE,
  CUSTOMER_THREAD_PROFILE_EDIT_CLOSE,
  CUSTOMER_THREAD_PROFILE_EDIT_OPEN,
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE,
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
  CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS,
  CUSTOMER_THREAD_PROFILE_SHOW
} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN} from '../../../../actions/header-actions';
import {
  LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD,
  ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION
} from '../../../../actions/router-actions';
import {
  CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION,
  CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START
} from '../../../../actions/customer-new-thread-actions';

export const DEFAULT_STATE = {
  channel: null,
  company: null,
  customerId: null,
  displayName: null,
  emailAddress: null,
  firstName: null,
  isLoadingTriggeredBySelect: false,
  lastName: null,
  loading: false,
  phoneNumber: null,
  pictureHref: null,
  pictureHrefIsUploading: false,
  showDrawer: false,
  showDrawerEdit: false
};

const resetState = () => fromJS(DEFAULT_STATE);

/**
 * Reset the state, and update it from selected thread.
 * We skip it if the new customer is the same as in current state.
 * @param {Object} state
 * @param {Object} customer
 * @returns {Object} new state
 */
const updateOptimisticallyCustomerProfile = (state, {payload: {customer}}) =>
  state
    .set('channel', customer.channel)
    .set('company', customer.company)
    .set('displayName', customer.displayName)
    .set('emailAddress', customer.emailAddress)
    .set('isLoadingTriggeredBySelect', true)
    .set('loading', true)
    .set('pictureHref', customer.pictureHref !== 'default' ? customer.pictureHref : null)
    .set('phoneNumber', customer.phoneNumber)
    .set('showDrawer', state.get('showDrawer'));

const updateProfileLoadingAndConversations = (state, {payload: {customer}}) =>
  state
    .set('channel', customer.channel)
    .set('company', customer.company)
    .set('customerId', customer.id)
    .set('displayName', customer.displayName)
    .set('emailAddress', customer.emailAddress)
    .set('firstName', customer.firstName)
    .set('isLoadingTriggeredBySelect', false)
    .set('lastName', customer.lastName)
    .set('loading', false)
    .set('phoneNumber', customer.phoneNumber)
    .set('pictureHref', customer.pictureHref);

const isSameCustomerId = (state, {customer}) => customer.id === state.get('customerId');

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Go to a conversation.
   * @param {Immutable.Map} state
   * @param {Object} payload
   * @returns {Immutable.Map}
   */
  [CUSTOMER_CONVERSATION_URL_GO]: (state, {payload}) => {
    if (isSameCustomerId(state, payload)) {
      return state;
    }

    return updateOptimisticallyCustomerProfile(state, {payload});
  },

  /**
   * Select a thread from any part of the app reset the state, set as loading and eventually update optimistically some props.
   */
  [CUSTOMER_INBOX_SEARCH_RESULT_SELECT_MESSAGE]: updateOptimisticallyCustomerProfile,
  [CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CUSTOMER_CLICK]: (state, {payload}) => {
    if (isSameCustomerId(state, payload)) {
      return state;
    }

    return updateOptimisticallyCustomerProfile(state, {payload});
  },
  [CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD]: (state, {payload}) => {
    // We will remove this temporary code when we implement the new inbox
    if (
      !payload.customer.pictureHref ||
      payload.customer.pictureHref === state.get('pictureHref')
    ) {
      return state;
    }

    return updateOptimisticallyCustomerProfile(state, {payload});
  },
  [LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN]: updateOptimisticallyCustomerProfile,

  /**
   * Fill the state on conversations and profile loading success.
   */
  [LEGACY_CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]:
    updateProfileLoadingAndConversations,

  [CUSTOMER_THREAD_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]: updateProfileLoadingAndConversations,
  [CUSTOMER_NEW_CONVERSATION_CONVERSATIONS_AND_PROFILE_LOAD_SUCCESS]:
    updateProfileLoadingAndConversations,

  [CUSTOMER_THREAD_PAGE_LEAVE]: resetState,

  /**
   * Customer profile drawer toggle.
   */
  [CUSTOMER_THREAD_PROFILE_SHOW]: (state) => state.set('showDrawer', true),

  [CUSTOMER_THREAD_PROFILE_CLOSE]: (state) =>
    state.set('showDrawerEdit', false).set('showDrawer', false),

  /**
   * Customer profile drawer edit.
   */
  [CUSTOMER_THREAD_PROFILE_EDIT_OPEN]: (state) => state.set('showDrawerEdit', true),

  [CUSTOMER_THREAD_PROFILE_EDIT_CLOSE]: (state) => state.set('showDrawerEdit', false),

  [CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS]: (state, {payload: {customer}}) => {
    return state
      .set('company', customer.company)
      .set('displayName', customer.displayName)
      .set('firstName', customer.firstName)
      .set('lastName', customer.lastName)
      .set('showDrawerEdit', false);
  },

  /**
   * Customer profile picture upload.
   */
  [CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD]: (state) =>
    state.set('pictureHrefIsUploading', true),

  [CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE]: (state) =>
    state.set('pictureHrefIsUploading', false),

  [CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS]: (state, {payload: {customer}}) =>
    state.set('pictureHref', customer.pictureHref).set('pictureHrefIsUploading', false),

  [CUSTOMER_CONVERSATIONS_SELECT_CONVERSATION_TAB_FROM_URL]: (state) =>
    state.set('isLoadingTriggeredBySelect', false).set('loading', false),

  [LEGACY_ROUTER_LOCATION_CHANGED_TO_CUSTOMER_THREAD]: (state) =>
    state.set('isLoadingTriggeredBySelect', true).set('loading', true),

  [ROUTER_LOCATION_CHANGED_TO_NEW_CUSTOMER_CONVERSATION]: (
    state,
    {payload: {customerIdUrlParam}}
  ) => state.set('customerId', customerIdUrlParam),
  [CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_START]: (state, {payload: {email, phoneNumber}}) =>
    resetState()
      .set('displayName', email || phoneNumber)
      .set('emailAddress', email || null)
      .set('phoneNumber', phoneNumber || null),
  [CUSTOMER_NEW_CONVERSATION_NEW_CUSTOMER_ADD_CONVERSATION]: (
    state,
    {payload: {email, phoneNumber}}
  ) =>
    state
      .set('displayName', email || phoneNumber)
      .set('emailAddress', email || null)
      .set('phoneNumber', phoneNumber || null)
});
