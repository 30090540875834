import jwtDecode from 'jwt-decode';

/**
 * Get legacy token from the domain store.
 *
 * @param {Object} state$
 * @returns {String}
 */
export default (state$) => {
  return jwtDecode(state$.value.getIn(['signInLogin', 'login', 'accessToken'])).legacyToken;
};
