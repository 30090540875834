import PropTypes from 'prop-types';
import getStyledHelpLink from '../common/get-styled-help-link';
import {fontSize, spacing} from '../../../../../../shared/style/variables';
import {graphBlue, red} from '../../../../../../shared/style/colors';

const NOT_APPLICABLE_LABEL = '-';

const StyledHelpLink = getStyledHelpLink({
  top: '-3px',
  left: '-36px'
});

const _renderVariation = ({isColorInverted, isPercent = true, suffix, value}) => {
  if (typeof value === 'undefined') {
    return null;
  }

  let color;
  let text;

  // This is the tricky part = where we have to do this composite check of possible values to handle them specifically...
  if (NOT_APPLICABLE_LABEL === value) {
    text = NOT_APPLICABLE_LABEL;
  } else if (value === 0) {
    text = '=';
  } else {
    const isVariationLowerThanZero = value < 0;

    text = Math.abs(value);
    if (isPercent) {
      text += '%';
    }

    if (isColorInverted) {
      color = isVariationLowerThanZero ? graphBlue : red;
      if (suffix) {
        text += suffix;
      }
    } else {
      color = isVariationLowerThanZero ? red : graphBlue;
    }

    if (!suffix) {
      text = `${isVariationLowerThanZero ? '-' : '+'}${text}`;
    }
  }

  return (
    <span
      data-test-id="variation"
      style={{
        marginLeft: spacing.medium,
        fontSize: fontSize.medium,
        color
      }}
    >
      {text}
    </span>
  );
};

_renderVariation.propTypes = {
  isColorInverted: PropTypes.bool.isRequired,
  isPercent: PropTypes.bool.isRequired,
  suffix: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export const ChartTitleContainer = ({children}) => (
  <div
    data-test-id="title"
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    {children}
  </div>
);

ChartTitleContainer.propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * Generate a chart title.
 *
 * @param {Number|String} figure
 * @param {Boolean} hasData
 * @param {Boolean} isColorInverted
 * @param {Boolean} isVariationPercent
 * @param {String} label
 * @param {Boolean} showVariation
 * @param {Number|String} variation
 * @returns {XML}
 */
export const generateChartTitle = ({
  figure,
  hasData,
  isColorInverted = false,
  isVariationPercent = true,
  label,
  showVariation = false,
  variation
}) => {
  return (
    <ChartTitleContainer>
      <span dangerouslySetInnerHTML={{__html: label}} />

      {hasData ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline'
          }}
        >
          <span data-test-id="figure" style={{fontSize: fontSize.xxxxlarge}}>
            {figure}
          </span>
          {showVariation
            ? _renderVariation({
                isColorInverted,
                isPercent: isVariationPercent,
                value: variation
              })
            : null}
        </div>
      ) : null}
    </ChartTitleContainer>
  );
};

/**
 * Generate a chart subtitle.
 *
 * @param {Number|String} figure
 * @param {String} helpPageUrl
 * @param {String} label
 * @param {Boolean} showVariation
 * @param {Object} style
 * @param {Number|String} variation
 * @returns {XML}
 */
export const generateChartSubtitle = ({
  figure,
  helpPageUrl,
  label,
  showVariation,
  style,
  variation
}) => {
  return (
    <div
      data-test-id="subtitle"
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style
      }}
    >
      {helpPageUrl ? <StyledHelpLink url={helpPageUrl} /> : null}
      <span>{label}</span>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginTop: '11px'
        }}
      >
        <span data-test-id="figure" style={{fontSize: fontSize.xxlarge}}>
          {figure}
        </span>
        {showVariation ? _renderVariation({value: variation}) : null}
      </div>
    </div>
  );
};

/**
 * Get linked figure.
 *
 * @param {Number} valueToCheck
 * @param {Number} realValue
 * @returns {Number|String}
 */
export const getLinkedFigure = (valueToCheck, realValue) =>
  valueToCheck > 0 ? realValue : NOT_APPLICABLE_LABEL;

/**
 * Get linked figure and variation.
 *
 * @param {Number} valueToCheck
 * @param {Number|String} realValue
 * @param {Number} variation
 * @returns {{figure: Number, variation: Number|String}}
 */
export const getLinkedFigureAndVariation = (valueToCheck, realValue, variation) => {
  const figure = getLinkedFigure(valueToCheck, realValue);

  return {
    figure,
    variation: NOT_APPLICABLE_LABEL === figure ? NOT_APPLICABLE_LABEL : variation
  };
};
