import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';
import context from '../../../../../../../../shared/component/context';
import themeV1 from '../../../../../../../../shared/style/theme-v1';
import Chip from '../../../../../../../../shared/component/chip-component';
import {fontSize, spacing} from '../../../../../../../../shared/style/variables';
import {snow} from '../../../../../../../../shared/style/colors';
import InsightRankingControlButtons from './insight-ranking-control-buttons';

class SubHeaderComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {
      aggregationPeriod,
      children,
      downloadCsvLabelTranslation,
      insightDownloadCsv,
      insightSortRankings,
      loadingCsv,
      periods,
      showDownloadCsvButton,
      showCsvButton,
      showSortButton,
      doChangeAggregationPeriod
    } = this.props;

    return (
      <div
        data-test-id="aggregation-selection"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: `${spacing.mlarge} ${spacing.xlarge} ${spacing.medium} ${spacing.xlarge}`,
          background: snow
        }}
      >
        <div
          data-test-id="aggregation-date"
          style={{
            position: 'relative', // useful for date-picker
            display: 'flex',
            alignItems: 'center',
            fontSize: fontSize.xlarge
          }}
        >
          <MuiThemeProvider theme={themeV1}>{children}</MuiThemeProvider>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
          <div data-test-id="aggregation-period">
            {periods.map((period) => {
              const {forbidden, value} = period;

              if (forbidden) {
                return null;
              }

              const isSelected = aggregationPeriod === value;

              return (
                <Chip
                  data-test-id={value}
                  aria-selected={isSelected}
                  clickable={!isSelected}
                  key={value}
                  label={i18n.t(`insight.contentHeader.periodSelector.periods.${value}.label`)}
                  variant={isSelected ? 'default' : 'outlined'}
                  onClick={isSelected ? null : () => doChangeAggregationPeriod(value)}
                />
              );
            })}
          </div>
          {showDownloadCsvButton ? (
            <InsightRankingControlButtons
              {...{
                downloadCsvLabelTranslation,
                i18n,
                insightDownloadCsv,
                insightSortRankings,
                loadingCsv,
                showCsvButton,
                showSortButton
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

SubHeaderComponent.contextTypes = context;

SubHeaderComponent.propTypes = {
  aggregationPeriod: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  downloadCsvLabelTranslation: PropTypes.string,
  insightDownloadCsv: PropTypes.func,
  insightSortRankings: PropTypes.func,
  loadingCsv: PropTypes.bool,
  periods: PropTypes.arrayOf(PropTypes.any).isRequired,
  showDownloadCsvButton: PropTypes.bool,
  showCsvButton: PropTypes.bool,
  showSortButton: PropTypes.bool,
  doChangeAggregationPeriod: PropTypes.func.isRequired
};

export default SubHeaderComponent;
