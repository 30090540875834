import {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, GoogleMap, InfoWindow, Marker} from '@react-google-maps/api';
import {
  GBM_MAPS_AUTOCOMPLETE_FIELDS,
  GBM_MAPS_CONTAINER_SIZE,
  GBM_MAPS_DEFAULT_ZOOM
} from './lib/gbm-map-helper';
import {fontWeight} from '../../../../../../../../../../shared/style/variables';

const AdministrationBusinessPageChannelsGbmMapComponent = ({
  business,
  i18n,
  onHandlePlaceUpdate,
  onUserSearchOnMap
}) => {
  const [map, setMap] = useState(null);
  const [mapMarkerPosition, setMapMarkerPosition] = useState(null);
  const [mapAddressInformation, setMapAddressInformation] = useState(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const autocompleteMapInput = useRef();

  const onSetMapAddressInformation = (place) => {
    if (place) {
      setMapAddressInformation({
        placeId: place.place_id,
        address: place.formatted_address,
        name: place.name
      });
    } else {
      setMapAddressInformation(null);
    }
  };

  const onShowMarkerData = () => {
    setShowInfoWindow(true);
  };

  const findDataByAdress = () => {
    const request = {
      query: business.address,
      fields: GBM_MAPS_AUTOCOMPLETE_FIELDS
    };

    const service = new window.google.maps.places.PlacesService(map);
    service.findPlaceFromQuery(request, (results) => {
      const [place] = results;
      onSetMapAddressInformation(place);
      setMapMarkerPosition(place.geometry.location);
      onHandlePlaceUpdate(place.place_id);
    });
  };

  useEffect(() => {
    if (map) {
      findDataByAdress();
    }
  }, [map]);

  const onLoadAutocomplete = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null && typeof autoComplete !== 'undefined') {
      const place = autoComplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        return;
      }

      setMapMarkerPosition(place.geometry.location);
      onSetMapAddressInformation(place);
      onHandlePlaceUpdate(place.place_id);
      onUserSearchOnMap(true);
      onShowMarkerData();
    }
  };

  const onLoadMap = (mapEl) => {
    setMap(mapEl);
  };

  const onCloseMarkerData = () => {
    onUserSearchOnMap(false);
    setShowInfoWindow(false);
    autocompleteMapInput.current.value = '';
    autocompleteMapInput.current.focus();
  };

  const clearAutoCompleteInput = () => {
    if (autocompleteMapInput.current.value.length === 0) {
      onUserSearchOnMap(false);
      setShowInfoWindow(false);
    }
  };

  return (
    <div data-test-id="gbm-business-location-map">
      <GoogleMap
        zoom={GBM_MAPS_DEFAULT_ZOOM}
        center={mapMarkerPosition}
        mapContainerStyle={GBM_MAPS_CONTAINER_SIZE}
        onLoad={onLoadMap}
      >
        <Autocomplete
          onLoad={onLoadAutocomplete}
          onPlaceChanged={onPlaceChanged}
          fields={GBM_MAPS_AUTOCOMPLETE_FIELDS}
        >
          <input
            ref={autocompleteMapInput}
            data-test-id="gbm-business-location-map-autocomplete"
            type="text"
            placeholder={i18n.t(
              'administration.business.pages.channels.gbm.maps.autocompletePlaceHolder'
            )}
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '240px',
              height: '32px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipses',
              position: 'absolute',
              left: '50%',
              marginLeft: '-120px',
              marginTop: '10px'
            }}
            onChange={clearAutoCompleteInput}
          />
        </Autocomplete>
        <Marker position={mapMarkerPosition} onClick={onShowMarkerData}>
          {showInfoWindow && mapMarkerPosition ? (
            <InfoWindow onCloseClick={onCloseMarkerData}>
              <div data-test-id="gbm-business-location-map-location-details">
                <h6 data-test-id="name" style={{fontWeight: fontWeight.bold}}>
                  {mapAddressInformation.name}
                </h6>
                <p data-test-id="place-id">
                  <strong>
                    {i18n.t('administration.business.pages.channels.gbm.maps.mapPlaceIdTitle')}
                  </strong>
                  {mapAddressInformation.placeId}
                </p>
                <p data-test-id="address">{mapAddressInformation.address}</p>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      </GoogleMap>
    </div>
  );
};

AdministrationBusinessPageChannelsGbmMapComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandlePlaceUpdate: PropTypes.func.isRequired,
  onUserSearchOnMap: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsGbmMapComponent;
