import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {blue} from '../../style/colors';

const StyledIcon = withStyles({
  colorAction: {
    color: blue
  }
})(MailOutlineIcon);

const MailOutlineIconComponent = ({fontSize}) => (
  <div style={{fontSize}}>
    <StyledIcon color="action" fontSize="inherit" />
  </div>
);

MailOutlineIconComponent.propTypes = {
  fontSize: PropTypes.string
};

export default MailOutlineIconComponent;
