import {withStyles} from '@material-ui/core/styles';
import {Tab, Tabs} from '@material-ui/core';
import {blackPure, blue} from '../colors';

const StyledTab = withStyles({
  root: {
    borderBottom: '3px solid transparent',
    color: blackPure,
    opacity: 1,
    textTransform: 'none',
    '&:hover': {
      borderBottom: `3px solid ${blue}`,
      color: blue
    },
    '&$selected': {
      borderBottom: `3px solid ${blue}`,
      color: blue
    }
  },
  selected: {}
})(Tab);

const StyledTabs = withStyles({
  root: {
    marginBottom: '20px'
  },
  indicator: {
    backgroundColor: blue,
    height: '3px'
  }
})(Tabs);

export {StyledTab, StyledTabs};
