import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import {EditorState, KeyBindingUtil, Modifier, getDefaultKeyBinding} from 'draft-js';
import 'draft-js/dist/Draft.css'; // @see https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Issues-and-Pitfalls.md
import Editor, {createEditorStateWithText} from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import FileIcon from 'material-ui/svg-icons/editor/insert-drive-file';
import IconButton from 'material-ui/IconButton/IconButton';
import ImageEmoji from '@material-ui/icons/SentimentSatisfiedOutlined';
import createEmojiPlugin from './emoji/draft-js/create-emoji-plugin';
import context from '../../../../../shared/component/context';
import ActionButtonsComponent from './action-buttons-component';
import CannedResponsesPopover from '../canned-responses-popover-component';
import currentPlatform from '../../../../../shared/lib/current-platform';
import DroppableArea from '../droppable-area-component';
import EmojisPopover from './emoji/popup/emojis-popover-component';
import FilePreviewRemoveIcon from '../icon/file-preview-remove-icon';
import FileSelector from '../file/file-selector-component';
import getNewClientMessageId from '../../../lib/message-helper';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import {readLocalFile, validateAndForwardFile} from '../../../../../shared/lib/file-upload-helper';
import './input-message-styles.css';
import {
  black,
  blue,
  lightSlate,
  lightSmoke,
  lightSnow,
  white
} from '../../../../../shared/style/colors';
import {fontSize, loaderSize} from '../../../../../shared/style/variables';
import iconFooterPath from './lib/input-messages-icon-helper';

export const INITIAL_INPUT_MESSAGE_HEIGHT = 20;

const emojiPlugin = createEmojiPlugin();

const {hasCommandModifier, isCtrlKeyCommand} = KeyBindingUtil;

const iconButtonStyle = {
  width: '36px',
  height: '36px',
  padding: '0'
};
const iconStyle = {width: '22px', height: '22px'};

const getTextFrom = (state, original = false) => {
  const text = state.getCurrentContent().getPlainText();

  return original ? text : text.trim();
};

const dataURLtoFile = (dataURL, filename) => {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime
  });
};

/**
 * Update the editor state.
 *
 * For simplification purpose, even if there is no selection,
 * a replacement of 'selection' is always performed, so all cases are covered.
 *
 * @param {Object} editorState current editor state
 * @param {String|Object} text text to insert/replace with
 * @returns {Object} new editor state
 */
const updateEditorState = (editorState, text) => {
  return EditorState.push(
    editorState,
    Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), text),
    'replace-text'
  );
};

/**
 * The component itself.
 *
 * Be careful in all callbacks (setTimeout, setState...)
 * to test each time that the editor component is still available
 * before trying to interact with it.
 */
class LegacyInputMessageComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cannedResponsesAnchorEl: null,
      cannedResponsesPopoverIsOpen: false,
      editorState: createEditorStateWithText(
        isNil(props.data.text) || props.disableThreadInputPersistence ? '' : props.data.text
      ), // do not remove isNil check (@see AWI-3521)
      emojiPopoverAnchorEl: null,
      emojiPopoverIsOpen: false,
      focused: false,
      isEditorVisible: false
    };

    this.editorPlugins = [emojiPlugin];

    this._closeCannedResponses = this._closeCannedResponses.bind(this);
    this._closeEmojiPopover = this._closeEmojiPopover.bind(this);
    this._keyBindingFn = this._keyBindingFn.bind(this);
    this._onEditorChange = this._onEditorChange.bind(this);
    this._onPasteFiles = this._onPasteFiles.bind(this);
    this._onPasteText = this._onPasteText.bind(this);
    this._openCannedResponses = this._openCannedResponses.bind(this);
    this._openEmojiPopover = this._openEmojiPopover.bind(this);
    this._selectCannedResponse = this._selectCannedResponse.bind(this);
    this._selectEmoji = this._selectEmoji.bind(this);
    this._sendMessage = this._sendMessage.bind(this);

    this.focusEditor = this.focusEditor.bind(this);
  }

  componentDidMount() {
    this.setState({
      isEditorVisible: true
    });
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/destructuring-assignment */
    if (
      this.props.isFocused &&
      !this.state.focused &&
      // Fix AWI-3420 (prevent IE 11 crash while browsing history)
      !(this.props.isHistoryAction && currentPlatform.isIE)
    ) {
      this.focusEditor();
    }

    if (prevProps.isFocused && !this.props.isFocused) {
      this._removeFocus();
    }
    /* eslint-enable react/destructuring-assignment */
  }

  _removeFocus = () => {
    this.setState({
      focused: false
    });
  };

  _onSelectFileToUpload = (file) => {
    const {onSelectFile} = this.props;
    if (validateAndForwardFile(file, onSelectFile)) {
      this.focusEditor();
    }
  };

  _renderFileUploaded = (fileUploaded, attachmentPreviewDataTestId) => {
    return /image/gi.test(fileUploaded.contentType) ? (
      <img
        data-test-id={attachmentPreviewDataTestId}
        alt={fileUploaded.name}
        src={fileUploaded.href}
        title={fileUploaded.name}
        style={{
          maxWidth: '36px',
          maxHeight: '36px',
          objectFit: 'cover',
          borderRadius: '3px'
        }}
      />
    ) : (
      <FileIcon
        data-test-id={attachmentPreviewDataTestId}
        style={{
          width: '30px',
          height: '30px',
          marginRight: 0,
          color: lightSlate
        }}
      />
    );
  };

  _renderPreviewAttachedImage = () => {
    const {data, isFocused, threadType, uploadingFile, doRemoveFile} = this.props;
    const {fileUploaded} = data;
    const attachmentPreviewDataTestId = {
      colleague: 'colleague-message-attach-file-preview',
      invisible: 'invisible-message-attach-file-preview',
      visible: 'visible-message-attach-file-preview'
    }[threadType];

    return (
      <span className={isFocused ? 'input-option delay15' : 'input-option hide'}>
        {uploadingFile ? <LoadingIndicator size={loaderSize.medium} color={lightSmoke} /> : null}
        {fileUploaded && !uploadingFile ? (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '36px',
              height: '36px'
            }}
          >
            <FilePreviewRemoveIcon
              data-test-id="conversation-message-attachment-preview-delete-button"
              onClick={flow([doRemoveFile, this.focusEditor])}
              style={{
                top: '-5px',
                right: '-5px'
              }}
            />
            {this._renderFileUploaded(fileUploaded, attachmentPreviewDataTestId)}
          </div>
        ) : null}
      </span>
    );
  };

  _keyBindingFn(event) {
    if (event.keyCode === 13 && (hasCommandModifier(event) || isCtrlKeyCommand(event))) {
      if (this._canSendMessage()) {
        this._sendMessage();

        return true;
      }

      return false;
    }

    return getDefaultKeyBinding(event);
  }

  _onEditorChange(editorState, callback) {
    const {data, disableThreadInputPersistence, participationId, doUpdateMessage} = this.props;
    this.setState(
      {
        editorState
      },
      typeof callback === 'function' ? callback : noop
    );

    // @see https://instaply.atlassian.net/browse/AWI-4117
    if (!disableThreadInputPersistence) {
      const newText = getTextFrom(editorState, true) || ''; // test with "||" is a @wip, to try to avoid flaky errors with some null or undefined newText

      // avoid useless action call when other component triggers a repaint
      if (data.text !== newText) {
        doUpdateMessage(participationId, newText);
      }
    }
  }

  _canSendMessage() {
    const {data, mention, readOnly, sending} = this.props;
    const {editorState, isEditorVisible} = this.state;
    if (readOnly || sending) {
      return false;
    }

    if (!isEditorVisible) {
      return false;
    }

    return (
      getTextFrom(editorState) || data.fileUploaded || (mention && mention.props.userToMention)
    );
  }

  _closeCannedResponses() {
    this.setState(
      {
        cannedResponsesPopoverIsOpen: false
      },
      this.focusEditor
    );
  }

  _closeEmojiPopover() {
    this.setState(
      {
        emojiPopoverIsOpen: false
      },
      this.focusEditor
    );
  }

  _openCannedResponses(event) {
    event.preventDefault();

    this.setState({
      cannedResponsesAnchorEl: event.currentTarget,
      cannedResponsesPopoverIsOpen: true
    });
  }

  _openEmojiPopover(event) {
    event.preventDefault();

    this.setState({
      emojiPopoverAnchorEl: event.currentTarget,
      emojiPopoverIsOpen: true
    });
  }

  _selectCannedResponse(cannedResponse) {
    const {editorState} = this.state;
    this.setState(
      {
        cannedResponsesPopoverIsOpen: false,
        editorState: updateEditorState(editorState, cannedResponse)
      },
      this.focusEditor
    );
  }

  _selectEmoji(emoji) {
    const {editorState} = this.state;
    this.setState(
      {
        editorState: updateEditorState(editorState, emoji)
      },
      this.focusEditor
    );
  }

  _sendMessage() {
    const {doSendMessage} = this.props;
    const {editorState} = this.state;
    doSendMessage(getNewClientMessageId(), getTextFrom(editorState));

    // We prefer to reset with a new state in order to not keep the do/undo history
    this._onEditorChange(createEditorStateWithText(''), this.focusEditor);
  }

  _onPasteFiles(files) {
    const file = files[0];
    const self = this;

    readLocalFile(
      file,
      {
        onload: (event) =>
          self._onSelectFileToUpload(dataURLtoFile(event.target.result, file.name || 'pasted_file'))
      },
      'DataURL'
    );

    return 'handled';
  }

  _onPasteText(event) {
    const {editorState} = this.state;
    this.setState(
      {
        editorState: updateEditorState(editorState, event)
      },
      this.focusEditor
    );

    return 'handled';
  }

  // Give the ability to parent components to handle editor focus
  focusEditor() {
    setTimeout(() => {
      if (this.domEditor) {
        this.setState(
          {
            focused: true
          },
          () => {
            if (this.domEditor) {
              this.domEditor.focus();
            }
          }
        );
      }
    }); // DO NOT REMOVE!!, emoji could be break!!
  }

  render() {
    const {i18n} = this.context;
    const {
      cannedResponses,
      data,
      dataTestId,
      extensionsSupported,
      isFocused,
      maxFileSize,
      mention,
      placeholder,
      readOnly,
      tabIndex,
      threadType,
      uploadingFile
    } = this.props;
    const {
      cannedResponsesAnchorEl,
      cannedResponsesPopoverIsOpen,
      editorState,
      emojiPopoverAnchorEl,
      emojiPopoverIsOpen,
      isEditorVisible
    } = this.state;

    const isVisibleThread = threadType === 'visible';
    const isColleagueThread = threadType === 'colleague';
    const isInvisibleThread = threadType === 'invisible';
    const canSendMessage = this._canSendMessage();
    const {fileUploaded} = data;
    const backgroundColorType = isFocused ? white : lightSnow;
    const shouldShowCannedResponse = cannedResponses && cannedResponses.hasCannedResponses;
    const inputOptionHide = 'input-option hide';

    const {
      cannedResponsesPath,
      attachImagePath,
      actionSendPathForVisible,
      actionSendPathForInvisibleAndColleagues
    } = iconFooterPath(threadType);

    const inputMessageBackgroundColor =
      isColleagueThread || isInvisibleThread ? backgroundColorType : null;

    const inputEmojiColor = isColleagueThread || isInvisibleThread ? black : blue;

    const sendMessageButtonIcon =
      isColleagueThread || isInvisibleThread
        ? actionSendPathForInvisibleAndColleagues
        : actionSendPathForVisible;

    const sendButtonDataTestId = {
      colleague: 'send-colleague-message-button',
      invisible: 'send-invisible-message-button',
      visible: 'send-visible-message-button'
    }[threadType];

    return (
      <DroppableArea onDropAccepted={this._onSelectFileToUpload} validateFileSize={!maxFileSize}>
        {canSendMessage && isFocused ? (
          <span
            style={{
              position: 'absolute',
              left: isColleagueThread ? '40px' : '80px',
              bottom: '5px',
              fontSize: fontSize.xsmall,
              color: lightSlate
            }}
          >
            {i18n.t('thread.sendMessageShortcut')}
          </span>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            position: 'relative',
            padding: isVisibleThread ? '15px 15px 15px 0' : '15px'
          }}
        >
          {isColleagueThread || isInvisibleThread ? (
            <ActionButtonsComponent
              onSelectFileToUpload={this._onSelectFileToUpload}
              renderPreviewAttachedImage={this._renderPreviewAttachedImage}
              threadForm={data}
              uploadIconSize="33px"
              {...{
                extensionsSupported,
                mention,
                threadType,
                uploadingFile
              }}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              width: '100%',
              borderRadius: '5px',
              alignItems: 'flex-end',
              marginBottom: '3px',
              backgroundColor: inputMessageBackgroundColor
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '33px'
              }}
            >
              {shouldShowCannedResponse ? (
                <span>
                  <CannedResponsesPopover
                    anchorEl={cannedResponsesAnchorEl}
                    onRequestClose={this._closeCannedResponses}
                    open={cannedResponsesPopoverIsOpen}
                    selectItem={this._selectCannedResponse}
                    {...{cannedResponses, i18n}}
                  />
                  <IconButton
                    data-test-id="canned-response-button"
                    aria-label=""
                    onClick={this._openCannedResponses}
                    style={{...iconButtonStyle, padding: '0 12px'}}
                    tabIndex={-1}
                  >
                    <img
                      alt=""
                      src={cannedResponsesPath}
                      style={{
                        height: '17px',
                        width: '17px'
                      }}
                    />
                  </IconButton>
                </span>
              ) : null}

              <span>
                {isVisibleThread && !fileUploaded && !uploadingFile ? (
                  <FileSelector
                    clickableElement={(doInputClick) => (
                      <IconButton
                        data-test-id="message-file-attach-button"
                        onClick={doInputClick}
                        style={{...iconButtonStyle, paddingRight: '0 15px'}}
                        tabIndex={-1}
                      >
                        <img
                          alt=""
                          src={attachImagePath}
                          style={{
                            height: '17px',
                            width: '17px'
                          }}
                        />
                      </IconButton>
                    )}
                    onSelectFile={this._onSelectFileToUpload}
                    {...{
                      extensionsSupported
                    }}
                  />
                ) : null}
              </span>
              {isVisibleThread ? this._renderPreviewAttachedImage() : null}
            </div>
            <div
              data-test-id={dataTestId}
              style={{
                cursor: 'text',
                flex: 1,
                margin: '6px',
                borderRadius: '5px',
                lineHeight: '20px',
                maxHeight: '101px',
                overflowY: 'scroll',
                fontSize: '15px',
                wordBreak: 'break-word'
              }}
            >
              {isEditorVisible ? (
                <Editor
                  handlePastedFiles={this._onPasteFiles}
                  handlePastedText={this._onPasteText}
                  keyBindingFn={this._keyBindingFn}
                  onChange={this._onEditorChange}
                  plugins={this.editorPlugins}
                  readOnly={readOnly && isVisibleThread}
                  ref={(editor) => {
                    this.domEditor = editor;

                    return this.domEditor;
                  }}
                  spellCheck
                  stripPastedStyles
                  {...{
                    editorState,
                    placeholder,
                    tabIndex
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '33px'
              }}
            >
              <span className={isFocused ? 'input-option delay0' : inputOptionHide}>
                <EmojisPopover
                  anchorEl={emojiPopoverAnchorEl}
                  onRequestClose={this._closeEmojiPopover}
                  open={emojiPopoverIsOpen}
                  selectEmoji={this._selectEmoji}
                />
                <IconButton
                  data-test-id="emoji-button"
                  iconStyle={iconStyle}
                  onClick={this._openEmojiPopover}
                  style={{...iconButtonStyle, paddingRight: '15px'}}
                  tabIndex={-1}
                >
                  <ImageEmoji htmlColor={inputEmojiColor} />
                </IconButton>
              </span>
              <span className={isFocused ? 'input-option delay20' : inputOptionHide}>
                <IconButton
                  data-test-id={sendButtonDataTestId}
                  disabled={!canSendMessage}
                  iconStyle={{width: '27px', height: '27px'}}
                  onClick={this._sendMessage}
                  tabIndex={isFocused ? 0 : -1}
                  style={{width: 'auto', padding: isVisibleThread ? 0 : '0 15px 0 0'}}
                >
                  <img alt="" src={sendMessageButtonIcon} />
                </IconButton>
              </span>
            </div>
          </div>
        </div>
      </DroppableArea>
    );
  }
}

LegacyInputMessageComponent.contextTypes = context;

LegacyInputMessageComponent.propTypes = {
  cannedResponses: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  extensionsSupported: PropTypes.arrayOf(PropTypes.any),
  isFocused: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  maxFileSize: PropTypes.number,
  mention: PropTypes.objectOf(PropTypes.any),
  participationId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  sending: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number.isRequired,
  threadType: PropTypes.string.isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  doRemoveFile: PropTypes.func.isRequired,
  doSendMessage: PropTypes.func.isRequired,
  doUpdateMessage: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired
};

export default LegacyInputMessageComponent;
