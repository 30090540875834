import {debounceTime, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
  INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE
} from '../../../actions/integration-area-widgets-actions';

const IntegrationAreaEpic = () => (action$) => {
  return action$.pipe(
    ofType(
      INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE,
      INTEGRATION_AREA_WIDGET_CTA_SNIPPET_COPIED_CHANGE,
      INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE,
      INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE
    ),
    debounceTime(2000),
    map(({type}) => {
      return {
        copied: false,
        type
      };
    })
  );
};

export default IntegrationAreaEpic;
