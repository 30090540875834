import {merge} from 'rxjs';
import {debounceTime, delay, filter, ignoreElements, map, tap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import {
  browserSupportsNotification,
  employeeGrantedBrowserNotification
} from '../../lib/browser-notification-helper';
import {APP_BROWSER_NOTIFICATION_SHOW} from '../../actions/app-actions';
import {
  EXT_COLLEAGUE_SENT_MESSAGE_TO_ME,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
  EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
} from '../../actions/ext-actions';
import {
  BROWSER_NOTIFICATION_AUTOMATIC_CLOSE_DELAY,
  BROWSER_NOTIFICATION_DEBOUNCE_TIME
} from '../../data/settings';

const triggerNotificationClose = (notif) => {
  const notifCopy = cloneDeep(notif);

  return setTimeout(
    // keep named function in order to facilitate the debug
    function safeTriggerNotificationClose() {
      // see AWI-4150 (on MS Edge, the call to .close() triggers few errors)
      if (
        notifCopy &&
        notifCopy instanceof Notification &&
        typeof notifCopy.close === 'function' &&
        !notifCopy.closed
      ) {
        notifCopy.closed = true;
        notifCopy.close();
      }
    },
    200
  );
};

/**
 * Finally, the epic.
 */
const BrowserNotificationEpic = () => (action$, state$) => {
  const handleNotificationPopup = action$.pipe(
    ofType(APP_BROWSER_NOTIFICATION_SHOW),
    filter(() => {
      if (state$.value.getIn(['ui', 'windowFocused'])) {
        return false;
      }

      return browserSupportsNotification() && employeeGrantedBrowserNotification();
    }),
    map(({message}) => {
      const notif = new Notification('Instaply', {
        icon: `${ASSETS_PATH}/img/logo/instaply-bubble-text-vertical-color.png`,
        body: message
      });

      // not all browsers handle notification's automatic closing after few seconds, so we must do it once for all manually
      notif.onclick = (event) => {
        event.preventDefault();

        window.focus();

        triggerNotificationClose(this);
      };

      return notif;
    }),
    delay(BROWSER_NOTIFICATION_AUTOMATIC_CLOSE_DELAY),
    tap(triggerNotificationClose),
    ignoreElements()
  );

  const notifyOnConversationVisibleNewMessageNotFromMe = action$.pipe(
    ofType(
      EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_FROM_CUSTOMER,
      EXT_CONVERSATION_VISIBLE_NEW_MESSAGE_TO_CUSTOMER_NOT_FROM_ME
    ),
    debounceTime(BROWSER_NOTIFICATION_DEBOUNCE_TIME),
    map(({payload: {unreadConversationsCounter}}) => ({
      type: APP_BROWSER_NOTIFICATION_SHOW,
      message: i18n.t('app.browserNotification.unreadMessagesCustomers', {
        count: unreadConversationsCounter
      })
    }))
  );

  const notifyOnNewDirectMessageFromColleague = action$.pipe(
    ofType(EXT_COLLEAGUE_SENT_MESSAGE_TO_ME),
    debounceTime(BROWSER_NOTIFICATION_DEBOUNCE_TIME),
    map((message) => ({
      type: APP_BROWSER_NOTIFICATION_SHOW,
      message: i18n.t('app.browserNotification.unreadMessagesColleagues', {
        count: message.data.unreadColleagueThreadCount
      })
    }))
  );

  return merge(
    handleNotificationPopup,
    notifyOnConversationVisibleNewMessageNotFromMe,
    notifyOnNewDirectMessageFromColleague
  );
};

export default BrowserNotificationEpic;
