import PropTypes from 'prop-types';
import getChannelSetting from '../../../data/thread/channels-settings';
import CloseIcon from '../icon/close-icon';
import FileSelector from '../file/file-selector-component';
import Link from '../link/link-component';
import {lightSlate} from '../../../../../shared/style/colors';

const ThreadAttachmentExceedSizeComponent = ({
  customerChannel,
  i18n,
  onClickAttachAnotherFile,
  onClickClose,
  onThreadVisibleFileSelect
}) => {
  const i18nPrefix = `customerThread.visibleThread.fileSizeExceedMax.${getChannelSetting(
    customerChannel,
    'code'
  )}`;

  return (
    <div
      data-test-id="file-size-exceed-max-error"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 10px 8px 10px'
      }}
    >
      <div>
        <span
          style={{
            marginRight: '8px',
            color: lightSlate
          }}
        >
          {i18n.t(`${i18nPrefix}.description`)}
        </span>
        <FileSelector
          clickableElement={(doInputClick) => (
            <Link
              data-test-id="select-other-file-link"
              label={i18n.t(`${i18nPrefix}.linkLabel`)}
              onClick={doInputClick}
            />
          )}
          extensionsSupported={getChannelSetting(customerChannel, 'extensionsSupported')}
          onClickAttachFile={onClickAttachAnotherFile}
          onSelectFile={onThreadVisibleFileSelect}
        />
      </div>
      <CloseIcon onClick={onClickClose} />
    </div>
  );
};

ThreadAttachmentExceedSizeComponent.propTypes = {
  customerChannel: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onClickAttachAnotherFile: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired
};

export default ThreadAttachmentExceedSizeComponent;
