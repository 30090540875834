import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton/IconButton';
import FileSelector from '../file/file-selector-component';
import iconFooterPath from './lib/input-messages-icon-helper';

const ActionButtonsComponent = ({
  elementBefore,
  extensionsSupported,
  mention,
  style,
  threadForm: {fileUploaded},
  threadType,
  uploadIconSize,
  uploadingFile,
  onSelectFileToUpload,
  renderPreviewAttachedImage
}) => {
  const {attachImagePath} = iconFooterPath(threadType);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '33px',
        ...style
      }}
    >
      {elementBefore}
      <span>
        {!fileUploaded && !uploadingFile ? (
          <FileSelector
            clickableElement={(doInputClick) => (
              <IconButton
                data-test-id="message-file-attach-button"
                onClick={doInputClick}
                style={{width: uploadIconSize, height: uploadIconSize, padding: 0}}
                tabIndex={-1}
              >
                <img
                  alt=""
                  src={attachImagePath}
                  style={{
                    height: '17px',
                    width: '17px'
                  }}
                />
              </IconButton>
            )}
            onSelectFile={onSelectFileToUpload}
            {...{
              extensionsSupported
            }}
          />
        ) : null}
      </span>
      {renderPreviewAttachedImage()}
      {mention}
    </div>
  );
};

ActionButtonsComponent.propTypes = {
  elementBefore: PropTypes.objectOf(PropTypes.any),
  extensionsSupported: PropTypes.arrayOf(PropTypes.any),
  mention: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  threadType: PropTypes.string.isRequired,
  uploadIconSize: PropTypes.string.isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  onSelectFileToUpload: PropTypes.func.isRequired,
  renderPreviewAttachedImage: PropTypes.func.isRequired
};

export default ActionButtonsComponent;
