import {createRootEpic, createRootReducer} from '../../shared/store/store-creator';
import middleEndAccessors from '../../shared/store/middle-end-accessors';
import forgottenPassword from './reducer/forgotten-password-reducer';
import forgottenPasswordEpic from './epic/forgotten-password-epic';

export default () => {
  const mea = middleEndAccessors();

  const rootEpic = createRootEpic(forgottenPasswordEpic(mea));

  const rootReducer = createRootReducer({
    forgottenPassword
  });

  return {
    rootEpic,
    rootReducer
  };
};
