import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {timer} from 'rxjs';
import context from '../../../../../../shared/component/context';
import highlighted from '../../../../../../shared/lib/highlighted';
import {isWindowExpired} from '../../../../lib/customer-thread/window-expiration-helper';
import loadScript from '../../../../../../shared/lib/add-script';
import {INITIAL_INPUT_MESSAGE_HEIGHT} from '../../../common/input-message/input-message-component';
import LegacyScrollableThreadMessagesList from '../../../common/thread/legacy-scrollable-thread-messages-list-component';
import LegacyThreadAssignmentInputOverlay from './assigment/legacy-thread-assignment-input-overlay-component';
import LegacyThreadWhatsappPermissionFooter from './channel/whatsapp/legacy-thread-whatsapp-permission-footer-component';
import LegacyVisibleThreadInteractionArea from './visible/legacy/legacy-visible-thread-interaction-area-component';
import RightPanelColumnLayout from '../../../common/layout/right-panel-column-layout-component';
import ThreadAttachmentExceedSize from '../../../common/thread/attachment-exceed-size-component';
import ThreadFacebookExpiredFooter from './channel/facebook/thread-facebook-expired-footer-component';
import ThreadGbmExpiredFooter from './channel/gbm/thread-gbm-expired-footer-component';
import LegacyThreadRelease from './assigment/legacy-thread-release-component';
import ThreadWhatsAppTemplateList from './channel/whatsapp/templates/thread-whatsapp-template-list';
import VisibleThreadAppsAreaLayout from './visible/apps/visible-thread-apps-area-layout-component';
import {FILE_UPLOAD_MAX_SIZE_EXCEEDED_TIMEOUT} from '../../../../../../shared/data/settings';
import {THREAD_CHANNELS_SETTINGS} from '../../../../data/thread/channels-settings';
import {spacing} from '../../../../../../shared/style/variables';
import {darkOrange, lightSmoke} from '../../../../../../shared/style/colors';

const CTA_MIN_HEIGHT = '120px';

const InputOverlay = ({children, label, labelDataTestId, labelStyle, style, ...otherProps}) => (
  <div
    {...otherProps}
    style={{
      backgroundColor: lightSmoke,
      height: '67px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: spacing.large,
      ...style
    }}
  >
    <div
      data-test-id={labelDataTestId}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <span dangerouslySetInnerHTML={{__html: label}} style={labelStyle} />
      {children}
    </div>
  </div>
);
InputOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  'data-test-id': PropTypes.string.isRequired,
  label: PropTypes.string,
  labelDataTestId: PropTypes.string,
  labelStyle: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any)
};

/**
 * Finally, the component.
 */
class LegacyCustomerVisibleThreadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.hideFileMaxSizeExceededTimerSubscription = null;
    this.state = {
      inputHeight: INITIAL_INPUT_MESSAGE_HEIGHT,
      showFileMaxSizeExceeded: false,
      showWhatsappTemplatesList: false
    };

    this._loadMore = this._loadMore.bind(this);
    this._onLoadWidget = this._onLoadWidget.bind(this);
    this._releaseThread = this._releaseThread.bind(this);
    this._sendMessage = this._sendMessage.bind(this);
    this._sendMessageRetry = this._sendMessageRetry.bind(this);
    this.iframeRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    /* eslint-disable react/destructuring-assignment */
    if (!prevProps.selectedFileExceedMaxSize && this.props.selectedFileExceedMaxSize) {
      this._showFileMaxSizeExceeded();

      this.hideFileMaxSizeExceededTimerSubscription = timer(
        FILE_UPLOAD_MAX_SIZE_EXCEEDED_TIMEOUT
      ).subscribe(this._doHideFileSizeExceededWarning);
    } else if (
      this.state.showFileMaxSizeExceeded &&
      prevProps.selectedFileExceedMaxSize &&
      !this.props.selectedFileExceedMaxSize
    ) {
      this._doHideFileSizeExceededWarning();
    }
    /* eslint-enable react/destructuring-assignment */
  };

  _showFileMaxSizeExceeded = () => {
    this.setState({
      showFileMaxSizeExceeded: true
    });
  };

  _doHideFileSizeExceededWarning = () => {
    this._doIgnoreFileSizeExceededWarningHideTimeout();

    this.setState({
      showFileMaxSizeExceeded: false
    });
  };

  _doIgnoreFileSizeExceededWarningHideTimeout = () => {
    this.hideFileMaxSizeExceededTimerSubscription.unsubscribe();
  };

  _setInputHeight = ({height}) => {
    this.setState({
      inputHeight: height
    });
  };

  _cancelShowWhatsappTemplates = () => {
    this.setState({
      showWhatsappTemplatesList: false
    });
  };

  _loadWhatsappTemplates = () => {
    const {directedToBusinessIdentifier, onWhatsappTemplatesLoadButtonClick} = this.props;
    this.setState(
      {
        showWhatsappTemplatesList: true
      },
      () => {
        onWhatsappTemplatesLoadButtonClick(directedToBusinessIdentifier);
      }
    );
  };

  _onClickWhatsAppTemplate = (text) => {
    const {onWhatsappTemplateClick} = this.props;
    onWhatsappTemplateClick(text);

    this._cancelShowWhatsappTemplates();
  };

  _renderAreaAboveForm = (isAssignedToMe) => {
    const {i18n} = this.context;
    const {
      assignedTo,
      customerChannel,
      deactivated,
      readOnly,
      showAssignment,
      onThreadVisibleFileSelect
    } = this.props;
    const {showFileMaxSizeExceeded} = this.state;

    if (showFileMaxSizeExceeded) {
      return (
        <ThreadAttachmentExceedSize
          onClickAttachAnotherFile={this._doIgnoreFileSizeExceededWarningHideTimeout}
          onClickClose={this._doHideFileSizeExceededWarning}
          {...{
            customerChannel,
            i18n,
            onThreadVisibleFileSelect
          }}
        />
      );
    }

    const isLocked = deactivated || readOnly;

    if (isAssignedToMe && showAssignment && !isLocked) {
      return (
        <LegacyThreadRelease
          assignedAt={assignedTo.assignedAt}
          onClick={this._releaseThread}
          {...{
            i18n
          }}
        />
      );
    }

    return null;
  };

  _renderFacebookExpiredFooter = () => {
    const {i18n} = this.context;

    return <ThreadFacebookExpiredFooter {...{i18n}} />;
  };

  _renderGbmExpiredFooter = () => {
    const {i18n} = this.context;
    const {onConversationNewConversationFormOpenLinkClick} = this.props;

    return <ThreadGbmExpiredFooter {...{i18n, onConversationNewConversationFormOpenLinkClick}} />;
  };

  _renderWhatsappExpiredFooter = () => {
    const {i18n} = this.context;
    const {whatsappTemplates} = this.props;
    const {showWhatsappTemplatesList} = this.state;

    if (showWhatsappTemplatesList && whatsappTemplates) {
      return (
        <VisibleThreadAppsAreaLayout>
          <ThreadWhatsAppTemplateList
            doCancelWhatsappTemplatesScreen={this._cancelShowWhatsappTemplates}
            onWhatsappTemplateClick={this._onClickWhatsAppTemplate}
            {...{
              i18n,
              whatsappTemplates
            }}
          />
        </VisibleThreadAppsAreaLayout>
      );
    }

    const {doWhatsappPermissionFooterConfirmRendered, messages, windowExpiration} = this.props;
    const lastMessage = messages[messages.length - 1];

    return (
      <LegacyThreadWhatsappPermissionFooter
        {...{
          doWhatsappPermissionFooterConfirmRendered,
          i18n,
          lastMessage,
          windowExpiration,
          onWhatsappTemplatesLoadButtonClick: this._loadWhatsappTemplates
        }}
      />
    );
  };

  _renderChannelExpiredFooter = () => {
    const {customerChannel, enableUIWhatsAppChannelFeature} = this.props;

    switch (customerChannel) {
      case 'FACEBOOK':
        return this._renderFacebookExpiredFooter();
      case 'WHATSAPP':
        if (enableUIWhatsAppChannelFeature) {
          return this._renderWhatsappExpiredFooter();
        }
        break;
      default: {
        break;
      }
    }

    return null;
  };

  _loadMore(fromId, direction) {
    const {participationId, doThreadVisiblePaginate} = this.props;
    doThreadVisiblePaginate(participationId, fromId, direction);
  }

  _onLoadWidget() {
    const {i18n} = this.context;
    const {language} = this.props;

    const widgetWindow = this.iframeRef.contentWindow;
    widgetWindow._iwq.businessId = SALES_BUSINESS_ID;
    widgetWindow._iwq.lang = language;

    loadScript(`${WIDGET_URL}/bubble.js`, {
      id: 'main-script',
      target: widgetWindow.document
    });

    widgetWindow.document.getElementById('click').textContent = i18n.t(
      'customerThread.visibleThread.clickToContactYourSales'
    );
  }

  _sendMessage(clientMessageId, text) {
    const {
      isNewThread,
      participationId,
      threadForm,
      newThreadEmail,
      newThreadPhoneNumber,
      userId,
      picture,
      fullName,
      organizationPicture,
      directedToBusinessIdentifier,
      status,
      doSendMessage,
      doThreadVisibleNewSendMessage
    } = this.props;

    if (isNewThread) {
      doThreadVisibleNewSendMessage(
        organizationPicture,
        directedToBusinessIdentifier,
        newThreadEmail,
        newThreadPhoneNumber,
        fullName,
        picture,
        clientMessageId,
        text,
        threadForm.fileUploaded
      );
    } else {
      doSendMessage({
        attachment: threadForm.fileUploaded,
        clientMessageId,
        fullName,
        organizationPicture,
        participationId,
        picture,
        status,
        text,
        userId
      });
    }
  }

  _sendMessageRetry(failedAction) {
    const {isNewThread, doThreadVisibleSendMessageRetry} = this.props;
    if (!isNewThread) {
      doThreadVisibleSendMessageRetry(failedAction);
    }
  }

  _releaseThread() {
    const {participationId, doReleaseCustomerThread} = this.props;
    doReleaseCustomerThread(participationId);
  }

  render() {
    const {i18n} = this.context;
    const {
      assignedTo,
      cannedResponses,
      customerChannel,
      deactivated,
      directedToBusinessName,
      direction,
      disableThreadInputPersistence,
      enablePaymentFeature,
      hasMoreMessagesAfter,
      hasMoreMessagesBefore,
      isFocused,
      isHistoryAction,
      isNewThread,
      latestMessage,
      legacyCustomerThreadAppPayment,
      loading,
      loadingAfter,
      loadingBefore,
      messages,
      oldestMessage,
      participationId,
      readOnly,
      sending,
      showAssignment,
      threadForm,
      uploadingFile,
      userId,
      windowExpiration,
      doAppPaymentCancelButtonClick,
      doAppPaymentMaximizeButtonClick,
      doAppPaymentMinimizeButtonClick,
      doAppPaymentStepperBackButtonClick,
      doAppPaymentStepperNextButtonClick,
      doAppsAreaMenuButtonClick,
      doAppsAreaMinimize,
      doAppsMenuSelectPayment,
      doThreadFilePreviewOpen,
      doThreadVisibleFileRemove,
      doUpdateMessage,
      onAppPaymentAmountChange,
      onAppPaymentCustomChange,
      onAppPaymentCustomerChange,
      onThreadTakeButtonClick,
      onThreadVisibleFileSelect
    } = this.props;
    const {inputHeight} = this.state;

    // eslint-disable-next-line eqeqeq
    const isAssignedToMe = assignedTo ? assignedTo.user.id == userId : false; // do not try to simplify this expression
    const conversationExpired = isWindowExpired(windowExpiration);

    return (
      <RightPanelColumnLayout dataTestId="visible-thread">
        <LegacyScrollableThreadMessagesList
          dataTestId="thread-result"
          canShowIncomingAvatar={false}
          loadMore={this._loadMore}
          doSendMessageRetry={this._sendMessageRetry}
          threadType="visible"
          {...{
            direction,
            hasMoreMessagesAfter,
            hasMoreMessagesBefore,
            i18n,
            inputHeight,
            latestMessage,
            loading,
            loadingAfter,
            loadingBefore,
            messages,
            oldestMessage,
            sending,
            doThreadFilePreviewOpen
          }}
        />

        {loading ? null : this._renderAreaAboveForm(isAssignedToMe)}

        {(() => {
          if (loading) {
            return null;
          }

          if (deactivated) {
            return (
              <InputOverlay
                data-test-id="deactivated-message"
                label={i18n.t('customerThread.visibleThread.businessDeactivated', {
                  business: highlighted(directedToBusinessName, null, 200)
                })}
                labelDataTestId="conversation-deactivated-note"
                labelStyle={{
                  position: 'relative',
                  top: '-20px'
                }}
                style={{
                  height: CTA_MIN_HEIGHT,
                  color: darkOrange
                }}
              >
                <iframe
                  ref={(iframeElement) => {
                    this.iframeRef = iframeElement;

                    return this.iframeRef;
                  }}
                  scrolling="no"
                  src={`${ASSETS_PATH}/html/thread/cta.html`}
                  title={i18n.t('customerThread.visibleThread.iframeTitle')}
                  onLoad={this._onLoadWidget}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    minHeight: CTA_MIN_HEIGHT
                  }}
                />
              </InputOverlay>
            );
          }

          if (readOnly) {
            // we don't need to test showAssignment here because if the feature is disabled
            // then assignedTo will be empty automatically
            return (
              <LegacyThreadAssignmentInputOverlay
                inScope={false}
                {...{
                  assignedTo,
                  i18n,
                  isAssignedToMe
                }}
              />
            );
          }

          if (conversationExpired && customerChannel === THREAD_CHANNELS_SETTINGS.GBM.code) {
            return this._renderGbmExpiredFooter();
          }

          if (showAssignment && !isAssignedToMe) {
            return (
              <LegacyThreadAssignmentInputOverlay
                inScope
                {...{
                  assignedTo,
                  i18n,
                  isAssignedToMe,
                  onThreadTakeButtonClick
                }}
              />
            );
          }

          if (conversationExpired && customerChannel !== THREAD_CHANNELS_SETTINGS.GBM.code) {
            return this._renderChannelExpiredFooter();
          }

          return (
            <LegacyVisibleThreadInteractionArea
              sendMessage={this._sendMessage}
              setInputHeight={this._setInputHeight}
              {...{
                customerChannel,
                enablePaymentFeature,
                cannedResponses,
                disableThreadInputPersistence,
                i18n,
                isFocused,
                isHistoryAction,
                isNewThread,
                legacyCustomerThreadAppPayment,
                participationId,
                readOnly,
                sending,
                threadForm,
                uploadingFile,
                doAppPaymentCancelButtonClick,
                doAppPaymentMaximizeButtonClick,
                doAppPaymentMinimizeButtonClick,
                doAppPaymentStepperBackButtonClick,
                doAppPaymentStepperNextButtonClick,
                doAppsAreaMenuButtonClick,
                doAppsAreaMinimize,
                doAppsMenuSelectPayment,
                doThreadVisibleFileRemove,
                doUpdateMessage,
                onAppPaymentAmountChange,
                onAppPaymentCustomChange,
                onAppPaymentCustomerChange,
                onThreadVisibleFileSelect
              }}
            />
          );
        })()}
      </RightPanelColumnLayout>
    );
  }
}

LegacyCustomerVisibleThreadComponent.propTypes = {
  assignedTo: PropTypes.objectOf(PropTypes.any),
  cannedResponses: PropTypes.objectOf(PropTypes.any).isRequired,
  customerChannel: PropTypes.string,
  deactivated: PropTypes.bool,
  directedToBusinessIdentifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  directedToBusinessName: PropTypes.string,
  direction: PropTypes.string,
  disableThreadInputPersistence: PropTypes.bool.isRequired,
  enablePaymentFeature: PropTypes.bool.isRequired,
  enableUIWhatsAppChannelFeature: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  hasMoreMessagesAfter: PropTypes.bool.isRequired,
  hasMoreMessagesBefore: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isHistoryAction: PropTypes.bool.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  legacyCustomerThreadAppPayment: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  loadingAfter: PropTypes.bool.isRequired,
  loadingBefore: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  newThreadEmail: PropTypes.string,
  newThreadPhoneNumber: PropTypes.string,
  oldestMessage: PropTypes.string,
  organizationPicture: PropTypes.string,
  participationId: PropTypes.string.isRequired,
  picture: PropTypes.string,
  readOnly: PropTypes.bool,
  selectedFileExceedMaxSize: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  showAssignment: PropTypes.bool.isRequired,
  status: PropTypes.string,
  threadForm: PropTypes.objectOf(PropTypes.any).isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  whatsappTemplates: PropTypes.arrayOf(PropTypes.object),
  windowExpiration: PropTypes.string,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMaximizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  doAppsAreaMenuButtonClick: PropTypes.func.isRequired,
  doAppsAreaMinimize: PropTypes.func.isRequired,
  doAppsMenuSelectPayment: PropTypes.func.isRequired,
  doReleaseCustomerThread: PropTypes.func.isRequired,
  doSendMessage: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  onThreadTakeButtonClick: PropTypes.func.isRequired,
  doThreadVisibleFileRemove: PropTypes.func.isRequired,
  doThreadVisibleNewSendMessage: PropTypes.func.isRequired,
  doThreadVisiblePaginate: PropTypes.func.isRequired,
  doThreadVisibleSendMessageRetry: PropTypes.func.isRequired,
  doUpdateMessage: PropTypes.func.isRequired,
  doWhatsappPermissionFooterConfirmRendered: PropTypes.func.isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired,
  onConversationNewConversationFormOpenLinkClick: PropTypes.func.isRequired,
  onThreadVisibleFileSelect: PropTypes.func.isRequired,
  onWhatsappTemplateClick: PropTypes.func.isRequired,
  onWhatsappTemplatesLoadButtonClick: PropTypes.func.isRequired
};

LegacyCustomerVisibleThreadComponent.contextTypes = context;

export default LegacyCustomerVisibleThreadComponent;
