import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import downloadFileFromUrl from '../../../../../shared/lib/download-file-from-url';
import getStandardParamsFromState from './lib/get-standard-params-from-state';
import prepareRequestParams from './lib/prepare-request-params';
import {
  INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD,
  INSIGHT_WAITING_CUSTOMERS_CSV_LOAD,
  INSIGHT_WAITING_CUSTOMERS_CSV_LOAD_FAILURE,
  INSIGHT_WAITING_CUSTOMERS_LOAD,
  INSIGHT_WAITING_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_WAITING_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_WAITING_CUSTOMERS_OPEN
} from '../../../actions/insight-actions';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';

const InsightWaitingCustomersEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE, INSIGHT_WAITING_CUSTOMERS_OPEN),
      map(() => {
        return {
          type: INSIGHT_WAITING_CUSTOMERS_LOAD,
          businessesSelection: getStandardParamsFromState(state$).businessesSelection
        };
      })
    );

    const loadWaitingCustomers = action$.pipe(
      ofType(INSIGHT_WAITING_CUSTOMERS_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(({businessesSelection}) => {
        return graphql(
          'get-real-time-query',
          prepareRequestParams(state$, {businessesSelection})
        ).pipe(
          map(({realTime}) => {
            return {
              type: INSIGHT_WAITING_CUSTOMERS_LOAD_SUCCESS,
              waitingCustomersList: realTime
            };
          }),
          catchError((error) => {
            return of({
              type: INSIGHT_WAITING_CUSTOMERS_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    const downloadWaitingCustomersCsv = downloadFileFromUrl(
      action$,
      INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD
    );

    const loadWaitingCustomersReportUrl = action$.pipe(
      ofType(INSIGHT_WAITING_CUSTOMERS_CSV_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(() => {
        const {businessesSelection} = getStandardParamsFromState(state$);

        return graphql(
          'get-real-time-report-query',
          prepareRequestParams(state$, {businessesSelection})
        ).pipe(
          map(({realTimeReport: {url}}) => {
            return {
              type: INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD,
              url
            };
          }),
          catchError((error) => {
            return of({
              type: INSIGHT_WAITING_CUSTOMERS_CSV_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(
      downloadWaitingCustomersCsv,
      loadInsights,
      loadWaitingCustomers,
      loadWaitingCustomersReportUrl
    );
  };

export default InsightWaitingCustomersEpic;
