const configLocaleCustomES = {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'mm',
    billion: 'b',
    trillion: 't'
  },
  ordinal(number) {
    const b = number % 10;

    // We are skipping this rule here because this implementation is a copy of the implementation inside the numeral library
    /* eslint-disable no-nested-ternary */
    return b === 1 || b === 3
      ? 'er'
      : b === 2
      ? 'do'
      : b === 7 || b === 0
      ? 'mo'
      : b === 8
      ? 'vo'
      : b === 9
      ? 'no'
      : 'to';
    /* eslint-enable no-nested-ternary */
  },
  currency: {
    symbol: '€'
  }
};

export default configLocaleCustomES;
