const getFacebookPages = (state$) => {
  return state$.value
    .getIn(['administrationBusinessPageChannels', 'facebookChannel', 'facebookPages', 'data'])
    .toJS();
};

const isOnlyOneFacebookPage = (facebookPages) => {
  return facebookPages.length === 1;
};

const findFacebookPage = (facebookPages, facebookPageId) => {
  return facebookPages.find((facebookPage) => facebookPage.id === facebookPageId);
};

const handleFacebookPageRequestParams = (facebookPages, fbPageId) => {
  let facebookPageAccessToken;
  let facebookPageId;
  if (isOnlyOneFacebookPage(facebookPages)) {
    const [facebookPage] = facebookPages;

    facebookPageAccessToken = facebookPage.facebookPageAccessToken;
    facebookPageId = facebookPage.id;
  } else {
    const facebookPageFiltered = findFacebookPage(facebookPages, fbPageId);

    facebookPageAccessToken = facebookPageFiltered.facebookPageAccessToken;
    facebookPageId = facebookPageFiltered.id;
  }

  return {facebookPageAccessToken, facebookPageId};
};

export {getFacebookPages, handleFacebookPageRequestParams, findFacebookPage, isOnlyOneFacebookPage};
