import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import context from '../../../../shared/component/context';
import CloseOnEscapeComponent from '../../../../shared/component/close-on-escape-component';
import {InboxHeaderLayout, InboxHeaderTitle} from './inbox/inbox-header-components';
import Link from './link/link-component';
import {animationSpeed} from '../../../../shared/style/variables';

class FloatPanelComponent extends CloseOnEscapeComponent {
  componentWillUpdate(nextProps) {
    if (this.props.show && !nextProps.show) {
      window.removeEventListener('keydown', this._onKeyDown);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      window.addEventListener('keydown', this._onKeyDown);
    }
  }

  // Called on [Escape]
  _doClose() {
    this.props.doClose();
  }

  _showCancelButton() {
    const {i18n} = this.context;
    const {doClose} = this.props;

    return doClose ? (
      <Link
        data-test-id="cancel-button"
        label={i18n.t('common.cancel')}
        onClick={this._doClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          zIndex: 1,
          padding: '10px'
        }}
      />
    ) : null;
  }

  _showInboxHeaderLayout() {
    const {title, titleComponent} = this.props;

    return title ? (
      <InboxHeaderLayout
        dataTestId="header"
        style={{
          position: 'relative',
          justifyContent: 'flex-end',
          padding: '8px'
        }}
      >
        <InboxHeaderTitle
          dataTestId="title"
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%'
          }}
        >
          {title}
        </InboxHeaderTitle>
        {this._showCancelButton()}
      </InboxHeaderLayout>
    ) : (
      titleComponent
    );
  }

  render() {
    const {animate, animationSide, children, dataTestId, show, style} = this.props;

    return (
      <TransitionGroup>
        {show ? (
          <CSSTransition
            classNames={`${animationSide}Animation`}
            enter={animate}
            exit={animate}
            timeout={animationSpeed.medium}
          >
            <div
              data-test-id={dataTestId}
              style={{
                position: 'absolute',
                top: 0,
                zIndex: 2,
                height: '100%',
                width: '100%',
                background: 'white',
                ...style
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                {this._showInboxHeaderLayout()}

                {children}
              </div>
            </div>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    );
  }
}

FloatPanelComponent.contextTypes = context;

FloatPanelComponent.defaultProps = {
  animate: true
};

FloatPanelComponent.propTypes = {
  animate: PropTypes.bool,
  animationSide: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  titleComponent: PropTypes.objectOf(PropTypes.any),
  doClose: PropTypes.func.isRequired
};

export default FloatPanelComponent;
