import React from 'react';
import PropTypes from 'prop-types';
import AutomaticLoadMore from '../automatic-load-more-component';
import DateRow from './date-row-component';
import {getThreadContainerScrollableThreadMessagesListStyles} from './lib/message-helper';
import ManualMetaDataRow from './manual-meta-data-row-component';
import MessagesRow from './messages-row-component';
import NewConversationRow from './new-conversation-row-component';
import StatusUpdateRow from './status-update-row-component';
import {lightSlate} from '../../../../../shared/style/colors';
import {
  CONVERSATION_ITEM_TYPES,
  CONVERSATION_MESSAGES_LOAD_DIRECTIONS,
  CONVERSATION_META_ITEM_RENDERERS
} from '../../../data/thread/message';

const SIZE_INPUT_DIFF = 20; // 20 is the size that input grows

/**
 * Finally, the component.
 */
class ScrollableThreadMessagesListComponent extends React.PureComponent {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const {AFTER, BEFORE} = CONVERSATION_MESSAGES_LOAD_DIRECTIONS;
    /* eslint-disable react/destructuring-assignment */
    const {direction, inputHeight, sending} = nextProps;

    const node = this.scrollRef;
    const scrollPosition = node.scrollTop - (node.scrollHeight - node.clientHeight);
    const scrolledBottom = !node.scrollTop && !direction;

    this.direction = direction;
    this.inputDiff = Math.abs(this.props.inputHeight - inputHeight);
    this.shouldScrollBottom = true;
    this.scrollMoved = scrollPosition < 0;
    this.scrollNotMoved = scrollPosition + (this.scrollMoved ? this.inputDiff : 0) === 0;
    this.scrollHeight = node.scrollHeight;
    this.scrollTop = node.scrollTop;
    this.inputGrowing = this.props.inputHeight < inputHeight;
    this.forceScrollToBottom =
      this.inputDiff === SIZE_INPUT_DIFF &&
      (!this.direction || this.direction === BEFORE) &&
      this.scrollNotMoved;

    if (!sending && direction === AFTER) {
      this.shouldScrollBottom = false;
    } else {
      this.shouldScrollBottom =
        scrolledBottom || this.forceScrollToBottom || this.scrollNotMoved || sending;
    }
    /* eslint-enable react/destructuring-assignment */
  }

  componentDidUpdate() {
    const {AROUND, BEFORE} = CONVERSATION_MESSAGES_LOAD_DIRECTIONS;
    const node = this.scrollRef;

    if (this.shouldScrollBottom) {
      if (this.direction === AROUND) {
        const selectedMessageBox = node.getElementsByClassName('selected-message')[0];

        // Selected message may be absent during redraw
        if (!selectedMessageBox) {
          return;
        }

        const messagesBox = node.getBoundingClientRect();
        const selectedMessageBoxBounding = selectedMessageBox.getBoundingClientRect();
        node.scrollTop =
          selectedMessageBoxBounding.top -
          messagesBox.top -
          messagesBox.height / 2 +
          selectedMessageBoxBounding.height / 2;
      } else {
        node.scrollTop = node.scrollHeight;
      }
    } else if (this.direction === BEFORE) {
      let scrollTop = this.scrollTop + (node.scrollHeight - this.scrollHeight);

      if (!this.scrollNotMoved && this.inputDiff !== 0) {
        scrollTop += this.inputGrowing ? SIZE_INPUT_DIFF : -SIZE_INPUT_DIFF;
      }

      node.scrollTop = scrollTop;
    } else if (!this.scrollNotMoved) {
      let {scrollTop} = this;
      if (this.inputDiff !== 0) {
        scrollTop += this.inputGrowing ? SIZE_INPUT_DIFF : -SIZE_INPUT_DIFF;
      }

      node.scrollTop = scrollTop;
    }
  }

  _loadMoreAfter = () => {
    const {latestMessage, loadMore} = this.props;
    loadMore(latestMessage, CONVERSATION_MESSAGES_LOAD_DIRECTIONS.AFTER);
  };

  _loadMoreBefore = () => {
    const {oldestMessage, loadMore} = this.props;
    loadMore(oldestMessage, CONVERSATION_MESSAGES_LOAD_DIRECTIONS.BEFORE);
  };

  _renderConversationItemsBatches(conversationItemsBatches) {
    const {
      account,
      canShowIncomingAvatar,
      canShowIncomingSenderName,
      doSendMessageRetry,
      doThreadFilePreviewOpen,
      threadType
    } = this.props;

    const getConversationItemRenderer = (type) => {
      const {ENGAGED, INBOUND, INTERNAL, OUTBOUND, RELEASED, STATUS_UPDATE} =
        CONVERSATION_ITEM_TYPES;
      const {DATE, NEW_CONVERSATION} = CONVERSATION_META_ITEM_RENDERERS;

      const RENDERERS = {
        [DATE]: DateRow,
        [ENGAGED]: ManualMetaDataRow, // will be change when we do the task related to metadata
        [INBOUND]: MessagesRow,
        [INTERNAL]: MessagesRow,
        [NEW_CONVERSATION]: NewConversationRow,
        [OUTBOUND]: MessagesRow,
        [RELEASED]: ManualMetaDataRow, // will be change when we do the task related to metadata
        [STATUS_UPDATE]: StatusUpdateRow
      };

      return RENDERERS[type];
    };

    return conversationItemsBatches.map(({type, ...conversationItemsBatchOtherProps}, rowIndex) => {
      const renderer = getConversationItemRenderer(type);

      if (!renderer) {
        return console.error(`unknown renderer type provided <${type}>`); // eslint-disable-line no-console
      }

      return (
        <div key={String(rowIndex)} data-type={type}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '2.5px 0'
            }}
          >
            {React.createElement(renderer, {
              account,
              canShowIncomingAvatar,
              canShowIncomingSenderName,
              doSendMessageRetry,
              doThreadFilePreviewOpen,
              threadType,
              type,
              ...conversationItemsBatchOtherProps
            })}
          </div>
        </div>
      );
    });
  }

  render() {
    const {
      conversationItemsBatches,
      dataTestId,
      hasNextPage,
      hasPreviousPage,
      i18n,
      inputHeight,
      loading,
      loadingAfter,
      loadingBefore,
      loadingHeight,
      onClick,
      threadType
    } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        ref={(scrollElement) => {
          this.scrollRef = scrollElement;

          return this.scrollRef;
        }}
        style={getThreadContainerScrollableThreadMessagesListStyles({inputHeight, threadType})}
        {...{
          onClick
        }}
      >
        {loading ? (
          <div
            data-test-id="loading-conversation"
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              color: lightSlate
            }}
          >
            {i18n.t('thread.loadingConversation')}
          </div>
        ) : (
          <React.Fragment>
            {hasPreviousPage ? (
              <AutomaticLoadMore
                dataTestId="load-more-before"
                height={loadingHeight}
                loading={loadingBefore}
                loadMore={this._loadMoreBefore}
              />
            ) : null}
            {this._renderConversationItemsBatches(conversationItemsBatches)}
            {hasNextPage ? (
              <AutomaticLoadMore
                dataTestId="load-more-after"
                height={loadingHeight}
                loading={loadingAfter}
                loadMore={this._loadMoreAfter}
              />
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

ScrollableThreadMessagesListComponent.defaultProps = {
  loadingHeight: 30
};

ScrollableThreadMessagesListComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  canShowIncomingAvatar: PropTypes.bool,
  canShowIncomingSenderName: PropTypes.bool,
  conversationItemsBatches: PropTypes.arrayOf(PropTypes.any).isRequired,
  dataTestId: PropTypes.string.isRequired,
  direction: PropTypes.string,
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  inputHeight: PropTypes.number.isRequired,
  latestMessage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  loadingAfter: PropTypes.bool,
  loadingBefore: PropTypes.bool.isRequired,
  loadingHeight: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  oldestMessage: PropTypes.string,
  sending: PropTypes.bool.isRequired,
  threadType: PropTypes.string,
  doSendMessageRetry: PropTypes.func.isRequired,
  doThreadFilePreviewOpen: PropTypes.func.isRequired,
  onClick: PropTypes.func
};

export default ScrollableThreadMessagesListComponent;
