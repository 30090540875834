import PropTypes from 'prop-types';
import {formatRelativeDate} from '../../../../../lib/date-time-helper';
import StandardButton from '../../../../../../../shared/component/button/standard-button-component';

const LegacyThreadReleaseComponent = ({assignedAt, i18n, onClick}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px 0 8px 0'
    }}
  >
    <span
      style={{
        marginRight: '8px'
      }}
    >
      {i18n.t('customerThread.visibleThread.assignment.youTookIt', {
        assignedAt: formatRelativeDate(assignedAt, i18n, 'customerThread.visibleThread.assignment')
      })}
    </span>
    <StandardButton
      data-test-id="release-button"
      variant="outlined"
      {...{
        onClick
      }}
    >
      {i18n.t('customerThread.visibleThread.assignment.releaseButton')}
    </StandardButton>
  </div>
);

LegacyThreadReleaseComponent.propTypes = {
  assignedAt: PropTypes.string.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired
};

export default LegacyThreadReleaseComponent;
