import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CreditCard} from '@material-ui/icons';
import numeral from 'numeral';
import LoadingIndicator from '../../../../../../../../../shared/component/loading-indicator-component';
import PaymentAmount from './step/payment-amount-component';
import PaymentCustom from './step/payment-custom-component';
import PaymentCustomer from './step/payment-customer-component';
import PaymentHeader from './payment-header-component';
import PaymentLayout from './layout/payment-layout-component';
import PaymentStepLayout from './layout/payment-step-layout-component';
import {PAYMENT_AMOUNT_MAX} from '../../../../../../../data/settings';

const StyledCreditCard = withStyles({
  fontSizeLarge: {
    fontSize: '52px'
  }
})(CreditCard);

/**
 * Finally, the component.
 */
const PaymentMaximizedComponent = ({
  conversationId,
  customerThreadAppPayment: {
    amount,
    comment,
    customer,
    initializing,
    loadingPspAccount,
    paymentStep,
    pspAccount,
    submitHadError,
    submitting
  },
  doAppPaymentCancelButtonClick,
  doAppPaymentMinimizeButtonClick,
  doAppPaymentStepperBackButtonClick,
  doAppPaymentStepperNextButtonClick,
  i18n,
  onAppPaymentAmountChange,
  onAppPaymentCustomChange,
  onAppPaymentCustomerChange
}) => {
  if (initializing || loadingPspAccount) {
    return (
      <PaymentLayout>
        <LoadingIndicator />
      </PaymentLayout>
    );
  }

  if (!pspAccount || !pspAccount.paymentConfiguration) {
    return (
      <PaymentLayout>
        <PaymentHeader
          amount={amount}
          onlyShowCancelButton
          onClickCancelButton={doAppPaymentCancelButtonClick}
          onClickMinimizeButton={doAppPaymentMinimizeButtonClick}
          step={paymentStep}
          {...{
            conversationId,
            i18n,
            submitting
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'inherit',
            marginTop: '3em'
          }}
        >
          <StyledCreditCard color="primary" fontSize="large" />
          <h3 data-test-id="no-configuration">
            {i18n.t('customerThread.visibleThread.apps.list.payment.noConfiguration.title')}
          </h3>
          <span>
            {i18n.t('customerThread.visibleThread.apps.list.payment.noConfiguration.description')}
          </span>
        </div>
      </PaymentLayout>
    );
  }

  const stepSetup = {
    amount: () => {
      const amountAsNumber = numeral(amount).value();
      const isAmountInRange = amountAsNumber >= 1 && amountAsNumber <= PAYMENT_AMOUNT_MAX;

      return {
        component: <PaymentAmount {...{amount, conversationId, i18n, onAppPaymentAmountChange}} />,
        canGoNext: isAmountInRange,
        errorMessage:
          amount && !isAmountInRange
            ? i18n.t('customerThread.visibleThread.apps.list.payment.amount.error', {
                amountMax: PAYMENT_AMOUNT_MAX
              })
            : null,
        title:
          !amount || !isAmountInRange
            ? i18n.t('customerThread.visibleThread.apps.list.payment.amount.titleWithoutAmount')
            : i18n.t('customerThread.visibleThread.apps.list.payment.amount.titleWithAmount', {
                amount
              })
      };
    },
    customer: () => {
      const isCustomerValid =
        !!(customer && customer.firstName) && !!(customer && customer.lastName);

      return {
        component: (
          <PaymentCustomer {...{conversationId, customer, i18n, onAppPaymentCustomerChange}} />
        ),
        canGoNext: isCustomerValid
      };
    },
    custom: () => {
      const {paymentConfiguration} = pspAccount;
      const isCustomValid = !!comment;

      return {
        component: (
          <PaymentCustom
            commentPlaceHolder={paymentConfiguration.comment}
            {...{
              comment,
              conversationId,
              i18n,
              onAppPaymentCustomChange,
              submitting
            }}
          />
        ),
        canGoNext: isCustomValid,
        errorMessage:
          submitHadError && !submitting
            ? i18n.t('customerThread.visibleThread.apps.list.payment.custom.submitErrorMessage')
            : null,
        nextButtonLabelI18NPath: submitHadError
          ? 'customerThread.visibleThread.apps.list.payment.custom.nextButtonLabelTryAgain'
          : null,
        subTitle: paymentConfiguration.title
      };
    }
  }[paymentStep]();

  return (
    <PaymentStepLayout
      amount={amount}
      canGoNext={stepSetup.canGoNext}
      errorMessage={stepSetup.errorMessage}
      nextButtonLabelI18NPath={stepSetup.nextButtonLabelI18NPath}
      onClickCancelButton={doAppPaymentCancelButtonClick}
      onClickMinimizeButton={doAppPaymentMinimizeButtonClick}
      step={paymentStep}
      subTitle={stepSetup.subTitle}
      title={stepSetup.title}
      {...{
        conversationId,
        i18n,
        submitting,
        doAppPaymentStepperBackButtonClick,
        doAppPaymentStepperNextButtonClick
      }}
    >
      {stepSetup.component}
    </PaymentStepLayout>
  );
};

PaymentMaximizedComponent.propTypes = {
  conversationId: PropTypes.string.isRequired,
  customerThreadAppPayment: PropTypes.objectOf(PropTypes.any).isRequired,
  doAppPaymentCancelButtonClick: PropTypes.func.isRequired,
  doAppPaymentMinimizeButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperBackButtonClick: PropTypes.func.isRequired,
  doAppPaymentStepperNextButtonClick: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  onAppPaymentAmountChange: PropTypes.func.isRequired,
  onAppPaymentCustomChange: PropTypes.func.isRequired,
  onAppPaymentCustomerChange: PropTypes.func.isRequired
};

export default PaymentMaximizedComponent;
