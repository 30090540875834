import PropTypes from 'prop-types';
import {ListItem} from '@material-ui/core';
import context from '../../../../../../../shared/component/context';
import EmployeeListItem from '../../../../common/employee/employee-list-item-layout-component';
import formatInsightDuration from '../../lib/format-insight-duration';
import HistogramChart from '../../common/chart/histogram-chart-component';
import Indicator from '../../common/indicator-component';
import InsightContentHeader from '../../common/layout/content-header-component';
import InsightPageComponent from '../../common/insight-page-component';
import InsightPanelContentBlock from '../../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../../common/right-panel-content-layout-component';
import TableRetryable from '../../common/table/table-retryable-component';
import UserAvatar from '../../../../common/avatar/user-avatar-component';

const INSIGHT_PAGE = 'contactCentersUsers';

/**
 * Finally, the component.
 */
class InsightContactCentersUsersComponent extends InsightPageComponent {
  constructor(props) {
    super(props);

    this._doInsightBusinessesSelectionChangeFromPage =
      this._doInsightBusinessesSelectionChangeFromPage.bind(this);
  }

  _doInsightBusinessesSelectionChangeFromPage(value) {
    this.props.doInsightBusinessesSelectionChangeFromPage(value);
  }

  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      loading,
      periodTabSelected,
      doInsightBusinessFilterBusinessesSearch,
      doInsightLoad,
      doInsightShowMore,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    return (
      <RightPanelContentLayout {...{loading}}>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          {...{
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            periodTabSelected,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
        />

        <PanelContentLayout>
          <InsightPanelContentBlock
            description={i18n.t(`insight.pages.${INSIGHT_PAGE}.averageLoggedTime.description`)}
            title={i18n.t(`insight.pages.${INSIGHT_PAGE}.averageLoggedTime.title`)}
            indicators={
              <Indicator
                dataTestId="average-logged-time"
                label={i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.averageLoggedTime.indicators.averageLoggedTime`
                )}
                loading={insight.averageLoggedTimeHistogram.loading}
                value={formatInsightDuration(
                  insight.averageLoggedTimeIndicators.averageLoggedTime,
                  i18n
                )}
              />
            }
          >
            <HistogramChart
              dataTestId="average-logged-time-histogram-chart"
              aggregationPeriod={customAggregationPeriod}
              tooltipValueFormatter={this._formatInsightDurationFromSec}
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.averageLoggedTime.chart`, {
                returnObjects: true
              })}
              {...insight.averageLoggedTimeHistogram}
              series={[
                {
                  property: 'averageLoggedTime'
                }
              ]}
            />
          </InsightPanelContentBlock>

          <TableRetryable
            dataTestId="average-logged-time-per-employee"
            description={i18n.t(
              `insight.pages.${INSIGHT_PAGE}.averageEmployeeLoggedTime.table.description`
            )}
            keywordPlaceholder={i18n.t(
              `insight.pages.${INSIGHT_PAGE}.averageEmployeeLoggedTime.table.keywordPlaceholder`
            )}
            loading={insight.averageEmployeeLoggedTime.loading}
            showMore
            title={i18n.t(`insight.pages.${INSIGHT_PAGE}.averageEmployeeLoggedTime.table.title`)}
            doInsightBusinessesSelectionChangeFromPage={
              this._doInsightBusinessesSelectionChangeFromPage
            }
            onChangeKeyword={this._onSectionChangeKeyword('average_employee_logged_time')}
            onChangeResultPage={this._onSectionChangeResultPage('average_employee_logged_time')}
            onChangeRowsPerPage={this._onSectionChangeRowsPerPage('average_employee_logged_time')}
            onRetry={doInsightLoad('average_employee_logged_time')}
            {...insight.averageEmployeeLoggedTime}
            {...{
              accountBusinesses,
              businessesSelection,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate,
              insightBusinessFilter,
              doInsightBusinessFilterBusinessesSearch
            }}
            columns={[
              {
                columnsDataTestId: 'employee',
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.averageEmployeeLoggedTime.table.columns.employee`
                ),

                /* eslint-disable react/prop-types */
                accessor: ({node}) => (
                  <ListItem dense disableGutters>
                    <EmployeeListItem
                      avatar={<UserAvatar src={node.icon} />}
                      displayName={node.displayName}
                      emailAddress={node.emailAddress}
                      textMaxWidth="500px"
                    />
                  </ListItem>
                )
                /* eslint-enable react/prop-types */
              },
              {
                columnsDataTestId: 'average-logged-time',
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.averageEmployeeLoggedTime.table.columns.averageLoggedTime`
                ),
                accessor: (row) => formatInsightDuration(row.node.averageLoggedTime, i18n)
              }
            ]}
          />

          <TableRetryable
            dataTestId="activity-per-business"
            onRetry={doInsightLoad('activity_per_business')}
            onShowMore={doInsightShowMore('activity_per_business')}
            title={i18n.t(`insight.pages.${INSIGHT_PAGE}.activityPerBusiness.table.title`)}
            {...insight.activityPerBusiness}
            {...{
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            }}
            columns={[
              {
                columnsDataTestId: 'business',
                accessor: (row) => row.business.name,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerBusiness.table.columns.business`
                )
              },
              {
                columnsDataTestId: 'contacts',
                accessor: (row) => row.contacts,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerBusiness.table.columns.contacts`
                )
              },
              {
                columnsDataTestId: 'replied',
                accessor: (row) => row.replied,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerBusiness.table.columns.replied`
                )
              },
              {
                columnsDataTestId: 'resolved',
                accessor: (row) => row.resolved,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerBusiness.table.columns.resolved`
                )
              }
            ]}
          />

          <TableRetryable
            dataTestId="activity-per-employee"
            onRetry={doInsightLoad('activity_per_employee')}
            onShowMore={doInsightShowMore('activity_per_employee')}
            title={i18n.t(`insight.pages.${INSIGHT_PAGE}.activityPerEmployee.table.title`)}
            {...insight.activityPerEmployee}
            {...{
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate
            }}
            columns={[
              {
                columnsDataTestId: 'employee',
                /* eslint-disable react/prop-types */
                accessor: ({employee}) => (
                  <EmployeeListItem
                    displayName={employee.displayName}
                    emailAddress={employee.emailAddress}
                    textMaxWidth="400px"
                  />
                ),
                /* eslint-enable react/prop-types */
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerEmployee.table.columns.employee`
                )
              },
              {
                columnsDataTestId: 'replied',
                accessor: (row) => row.replied,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerEmployee.table.columns.replied`
                )
              },
              {
                columnsDataTestId: 'transferred',
                accessor: (row) => row.transferred,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerEmployee.table.columns.transferred`
                )
              },
              {
                columnsDataTestId: 'resolved',
                accessor: (row) => row.resolved,
                label: i18n.t(
                  `insight.pages.${INSIGHT_PAGE}.activityPerEmployee.table.columns.resolved`
                )
              }
            ]}
          />
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightContactCentersUsersComponent.contextTypes = context;

InsightContactCentersUsersComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any),
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightLoad: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doInsightShowMore: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightContactCentersUsersComponent;
