import React, {PureComponent} from 'react';
import {SketchPicker} from 'react-color';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import noop from 'lodash/noop';
import {BUTTON_LABEL_STYLE} from '../../../../../../../../shared/style/theme-v0';
import LoadingIndicator from '../../../../../../../../shared/component/loading-indicator-component';
import PanelContentLayout from '../../../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../../../common/right-panel-content-layout-component';
import SimpleContentHeader from '../../../../../common/content-header/simple-content-header-component';
import loadScript from '../../../../../../../../shared/lib/add-script';
import {
  getScriptFullPath,
  getSelectFloatingLabelColor
} from '../../../lib/integration-area-widgets-helper';
import throttle from '../../../../../../../../shared/lib/observable-custom';
import {BUTTON_STYLE} from '../../../../../../../../shared/component/button/standard-raised-button-component';
import {blue, lightSmoke, red, slate} from '../../../../../../../../shared/style/colors';
import {fontSize, fontWeight, lineHeight} from '../../../../../../../../shared/style/variables';

const SCRIPT_TAG_ID = 'main-script';

/**
 * Styles.
 */
export const SECTION_TITLE_STYLE = {
  marginBottom: '20px',
  fontSize: fontSize.xlarge,
  fontWeight: fontWeight.semiBold,
  lineHeight: '22px'
};

export const SECTION_SUBTITLE_STYLE = {
  marginTop: '10px',
  marginBottom: '20px',
  display: 'block',
  lineHeight: lineHeight.large,
  fontSize: fontSize.medium
};

export const SECTION_DESCRIPTION_STYLE = {
  fontSize: fontSize.medium,
  color: '#8E8E93',
  lineHeight: lineHeight.large
};

export const SECTION_BLOCK_STYLE = {
  marginBottom: '21px'
};

export const SECTION_CONTENT_STYLE = {
  background: 'white',
  padding: '20px',
  marginBottom: '50px'
};

/**
 * Finally, the component.
 */
class WidgetPageComponent extends PureComponent {
  constructor(props) {
    super(props);

    // Must be set by sub classes
    this.state = {
      containerId: null,
      scriptFile: null,
      snippetProps: [],
      widgetType: null,
      widgetWindow: null
    };

    // Common
    this._copyToClipboard = this._copyToClipboard.bind(this);
    this._doSelectLanguage = this._doSelectLanguage.bind(this);
    this._onLoadIframe = this._onLoadIframe.bind(this);

    // Not common
    this._doBackgroundColorChange = this._doBackgroundColorChange.bind(this);
    this._doCommunicationMethodChange = this._doCommunicationMethodChange.bind(this);
    this._doErrorInvalidEmailChange = this._doErrorInvalidEmailChange.bind(this);
    this._doErrorInvalidPhoneChange = this._doErrorInvalidPhoneChange.bind(this);
    this._doInputPlaceHolderChange = this._doInputPlaceHolderChange.bind(this);
    this._doInputPlaceHolderEmailChange = this._doInputPlaceHolderEmailChange.bind(this);
    this._doInputPlaceHolderPhoneChange = this._doInputPlaceHolderPhoneChange.bind(this);
    this._doSingleBusinessSelect = this._doSingleBusinessSelect.bind(this);
    this._doThankYouMessageChange = this._doThankYouMessageChange.bind(this);
    this.iframeRef = React.createRef();
    this.codeHideRef = React.createRef();
    this.codeRef = React.createRef();
    this.previewRef = React.createRef();
  }

  // Common to all sub classes
  componentWillUnmount() {
    const {doResetState} = this.props;
    doResetState();
  }

  __loadMainScript(document) {
    const {scriptFile} = this.state;

    loadScript(getScriptFullPath(scriptFile), {
      id: SCRIPT_TAG_ID,
      target: document
    });
  }

  __updateSnippet() {
    const {doUpdateSnippet} = this.props;
    const {scriptFile, snippetProps, widgetWindow} = this.state;

    let iwObject = '';
    Object.entries(widgetWindow._iwq).forEach(([key, rawValue]) => {
      let value = typeof rawValue === 'string' ? `'${rawValue}'` : rawValue;
      value = typeof value === 'object' ? JSON.stringify(value) : value;

      if (snippetProps.includes(key)) {
        iwObject += `    ${key}: ${value},\n`;
      }
    });
    /* eslint-disable quotes */
    const snippet =
      "<script type='text/javascript'>\n" +
      `  window._iwq = {\n${iwObject}  };\n` +
      '  (function () {\n' +
      "    var iw = document.createElement('script');\n" +
      `    iw.id = '${SCRIPT_TAG_ID}';\n` +
      "    iw.type = 'text/javascript';\n" +
      `    iw.src = '${getScriptFullPath(scriptFile)}';\n` +
      "    var s = document.getElementsByTagName('script')[0];\n" +
      '    s.parentNode.insertBefore(iw, s);\n' +
      '  })()\n' +
      '</script>';
    /* eslint-enable quotes */

    doUpdateSnippet(snippet);
  }

  __updateWidget() {
    const updateWidget = () => {
      const {containerId, widgetWindow} = this.state;

      const removePrevious = () => {
        const scriptNode = widgetWindow.document.querySelector(`script#${SCRIPT_TAG_ID}`);
        if (scriptNode) {
          widgetWindow.document.body.removeChild(scriptNode);
        }

        const containerElement = widgetWindow.document.getElementById(containerId);
        if (containerElement) {
          containerElement.remove();
        }
      };

      const applyNew = () => {
        this.__updateSnippet();
        this.__loadMainScript(widgetWindow.document);
      };

      removePrevious();

      // @todo check if we could use rxjs to throttle instead of use custom function
      throttle(applyNew);
    };

    // @todo check if we could use rxjs to throttle instead of use custom function
    throttle(updateWidget);
  }

  _copyToClipboard() {
    const {doCopyCode} = this.props;
    doCopyCode(true);

    this.codeHideRef.select();
    window.document.execCommand('copy');
  }

  _doSelectLanguage(e, i, language) {
    const {doChangeLanguage} = this.props;
    const {widgetWindow} = this.state;

    widgetWindow._iwq.lang = language;
    this.__updateWidget();

    doChangeLanguage(language);
  }

  _onLoadIframe() {
    this.setState({
      widgetWindow: this.iframeRef.contentWindow
    });
  }

  // Not common
  _doBackgroundColorChange(color) {
    const {doChangeBackgroundColor} = this.props;
    const {widgetWindow} = this.state;

    widgetWindow._iwq.bgColor = color.hex;
    this.__updateWidget();
    doChangeBackgroundColor(color.hex);
  }

  _doCommunicationMethodChange(e, method) {
    const {doChangeCommunicationMethod} = this.props;
    const {widgetWindow} = this.state;

    widgetWindow._iwq.contactChoice = !method;
    widgetWindow._iwq.defaultContact = method || 'email';
    this.__updateWidget();

    doChangeCommunicationMethod(method);
  }

  _doErrorInvalidEmailChange(e, text) {
    const {doChangeErrorInvalidEmail} = this.props;
    const {widgetType, widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.errorInvalidEmail = newText;
    this.__updateWidget();

    doChangeErrorInvalidEmail(widgetType, newText);
  }

  _doErrorInvalidPhoneChange(e, text) {
    const {doChangeErrorInvalidPhone} = this.props;
    const {widgetType, widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.errorInvalidPhone = newText;
    this.__updateWidget();

    doChangeErrorInvalidPhone(widgetType, newText);
  }

  _doInputPlaceHolderChange(e, text) {
    const {doChangeInputPlaceholder} = this.props;
    const {widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.placeholderMsg = newText;
    this.__updateWidget();

    doChangeInputPlaceholder(newText);
  }

  _doInputPlaceHolderEmailChange(e, text) {
    const {doChangeInputPlaceholderEmail} = this.props;
    const {widgetType, widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.placeholderEmail = newText;
    this.__updateWidget();

    doChangeInputPlaceholderEmail(widgetType, newText);
  }

  _doInputPlaceHolderPhoneChange(e, text) {
    const {doChangeInputPlaceholderPhone} = this.props;
    const {widgetType, widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.placeholderPhone = newText;
    this.__updateWidget();

    doChangeInputPlaceholderPhone(widgetType, newText);
  }

  _doSingleBusinessSelect(e, i, businessId) {
    const {doChangeBusiness} = this.props;
    const {widgetWindow} = this.state;

    widgetWindow._iwq.businessId = businessId;
    this.__updateWidget();

    doChangeBusiness(businessId);
  }

  _doThankYouMessageChange(e, text) {
    const {doChangeThankYouMessage} = this.props;
    const {widgetType, widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.thankyouMsg = newText;
    this.__updateWidget();

    doChangeThankYouMessage(widgetType, newText);
  }

  // Common rendering
  _renderBusinessSelectorIntroduction() {
    const {i18n} = this.context;
    const {i18nWidgetPrefix} = this.state;

    return (
      <div>
        <h3 style={SECTION_TITLE_STYLE}>
          {i18n.t(`${i18nWidgetPrefix}.sectionBusiness.title`)}
          <span
            style={{
              display: 'block',
              color: red,
              fontSize: fontSize.xsmall,
              fontWeight: 'normal'
            }}
          >
            {i18n.t(`${i18nWidgetPrefix}.mandatoryFlag`)}
          </span>
        </h3>

        <span
          style={{
            display: 'block',
            marginTop: '10px',
            marginBottom: '20px',
            lineHeight: lineHeight.xlarge
          }}
        >
          {i18n.t(`${i18nWidgetPrefix}.sectionBusiness.subTitle`)}
        </span>
      </div>
    );
  }

  _renderErrorInvalidEmail() {
    const {i18n} = this.context;
    const {errorInvalidEmail} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.errorInvalidEmail`;
    const sanitizedErrorInvalidEmail = errorInvalidEmail.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-error-invalid-email"
          defaultValue={sanitizedErrorInvalidEmail}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedErrorInvalidEmail ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doErrorInvalidEmailChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderErrorInvalidPhone() {
    const {i18n} = this.context;
    const {errorInvalidPhone} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.errorInvalidPhone`;
    const sanitizedErrorInvalidPhone = errorInvalidPhone.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-error-invalid-phone"
          defaultValue={sanitizedErrorInvalidPhone}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedErrorInvalidPhone ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doErrorInvalidPhoneChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderInputPlaceholder() {
    const {i18n} = this.context;
    const {inputPlaceholder} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.inputPlaceholder`;
    const sanitizedInputPlaceholder = inputPlaceholder.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          defaultValue={sanitizedInputPlaceholder}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedInputPlaceholder ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doInputPlaceHolderChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderInputPlaceholderEmail() {
    const {i18n} = this.context;
    const {inputPlaceholderEmail} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.inputPlaceholderEmail`;
    const sanitizedInputPlaceholderEmail = inputPlaceholderEmail.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-placeholder-email"
          defaultValue={sanitizedInputPlaceholderEmail}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedInputPlaceholderEmail ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doInputPlaceHolderEmailChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderInputPlaceholderPhone() {
    const {i18n} = this.context;
    const {inputPlaceholderPhone} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.inputPlaceholderPhone`;
    const sanitizedInputPlaceholderPhone = inputPlaceholderPhone.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-placeholder-phone"
          defaultValue={sanitizedInputPlaceholderPhone}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedInputPlaceholderPhone ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doInputPlaceHolderPhoneChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderLanguage() {
    const {i18n} = this.context;
    const {language} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.language`;

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <SelectField
          floatingLabelFixed
          floatingLabelStyle={{color: getSelectFloatingLabelColor(language)}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doSelectLanguage}
          value={language}
        >
          {['en', 'es', 'fr'].map((lang) => (
            <MenuItem key={lang} primaryText={i18n.t(`common.language.${lang}`)} value={lang} />
          ))}
        </SelectField>

        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderThankYouMessage() {
    const {i18n} = this.context;
    const {thankYouMessage} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.thankyouMessage`;
    const sanitizedThankYouMessage = thankYouMessage.trim();

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <TextField
          data-test-id="input-thank-you-message"
          defaultValue={sanitizedThankYouMessage}
          floatingLabelFixed
          floatingLabelStyle={{color: sanitizedThankYouMessage ? blue : slate}}
          floatingLabelText={i18n.t(`${i18nPrefix}.label`)}
          fullWidth
          hintText={i18n.t(`${i18nPrefix}.hint`)}
          onChange={this._doThankYouMessageChange}
        />
        <span style={SECTION_DESCRIPTION_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>
      </div>
    );
  }

  _renderLayout(
    exampleImageSrc,
    iframeSrc,
    iframeHeight,
    hasSelectedBusiness,
    finalResultAndCodeDescription,
    content
  ) {
    const {i18n} = this.context;
    const {snippet, snippetCopied} = this.props;
    const {i18nWidgetPrefix, widgetWindow, widgetType} = this.state;

    return (
      <RightPanelContentLayout>
        {widgetWindow ? null : <LoadingIndicator overlay />}

        <SimpleContentHeader
          dataTestId="integration-type-name"
          label={i18n.t(`${i18nWidgetPrefix}.title`)}
        />

        <PanelContentLayout
          dataTestId={`${widgetType}-widget-generator-container`}
          id="widget-panel"
        >
          <h3 style={SECTION_TITLE_STYLE}>{i18n.t(`${i18nWidgetPrefix}.example.subTitle`)}</h3>
          <div style={{marginBottom: '30px'}}>{i18n.t(`${i18nWidgetPrefix}.example.title`)}</div>

          <img
            alt={i18n.t(`${i18nWidgetPrefix}.example.imgAlt`)}
            src={`${ASSETS_PATH}/img/integration-area/${exampleImageSrc}`}
            style={{
              display: 'block',
              margin: '0 auto 50px auto',
              maxWidth: '100%',
              maxHeight: '350px'
            }}
          />

          <div>
            {content}

            <h3 style={SECTION_TITLE_STYLE}>
              {i18n.t(`${i18nWidgetPrefix}.finalResultAndCode.title`)}
            </h3>
            <span style={SECTION_SUBTITLE_STYLE}>{finalResultAndCodeDescription}</span>

            <div
              style={{
                ...SECTION_CONTENT_STYLE,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              {hasSelectedBusiness ? (
                <span style={{width: '100%', position: 'relative'}}>
                  <textarea
                    disabled
                    id="code"
                    name="code"
                    readOnly
                    ref={(codeRefElement) => {
                      this.codeRef = codeRefElement;

                      return this.codeRef;
                    }}
                    value={snippet}
                    style={{
                      width: '100%',
                      height: '350px',
                      borderColor: lightSmoke,
                      resize: 'none',
                      overflow: 'visible',
                      fontFamily: 'monospace',
                      fontSize: fontSize.medium
                    }}
                  />
                  <textarea
                    name="codeHide"
                    onChange={noop}
                    ref={(codeHideElement) => {
                      this.codeHideRef = codeHideElement;

                      return this.codeHideRef;
                    }}
                    value={snippet}
                    style={{
                      position: 'absolute',
                      opacity: 0,
                      width: 0,
                      height: 0
                    }}
                  />
                </span>
              ) : (
                <div
                  data-test-id="snippet-not-allowed"
                  style={{
                    textAlign: 'center',
                    lineHeight: lineHeight.xlarge,
                    padding: '20px 0',
                    ...SECTION_DESCRIPTION_STYLE
                  }}
                >
                  {i18n.t(`${i18nWidgetPrefix}.finalResultAndCode.snippetNote`)}
                </div>
              )}

              <RaisedButton
                data-test-id="copy-snippet-button"
                buttonStyle={BUTTON_STYLE}
                disabled={!hasSelectedBusiness || snippetCopied}
                label={i18n.t(
                  `${i18nWidgetPrefix}.finalResultAndCode.${snippetCopied ? 'copied' : 'button'}`
                )}
                labelStyle={BUTTON_LABEL_STYLE}
                onClick={this._copyToClipboard}
                primary
                style={{
                  ...BUTTON_STYLE,
                  marginTop: '30px',
                  cursor: hasSelectedBusiness ? 'pointer' : 'not-allowed',
                  opacity: hasSelectedBusiness ? 1 : 0.6
                }}
              />
            </div>

            <div
              data-test-id="preview-column"
              id="preview"
              ref={(previewRefElement) => {
                this.previewRef = previewRefElement;

                return this.previewRef;
              }}
              style={{width: '100%', textAlign: 'center'}}
            >
              <h3 style={{textAlign: 'left', ...SECTION_TITLE_STYLE}}>
                {i18n.t(`${i18nWidgetPrefix}.realTimePreview.title`)}
              </h3>
              <span style={{textAlign: 'left', width: '100%', ...SECTION_SUBTITLE_STYLE}}>
                {i18n.t(`${i18nWidgetPrefix}.realTimePreview.subTitle`)}
              </span>

              <div style={{position: 'relative'}}>
                {hasSelectedBusiness ? null : (
                  <div
                    data-test-id="preview-not-allowed"
                    style={{
                      textAlign: 'center',
                      lineHeight: lineHeight.xlarge,
                      padding: '20px 0',
                      ...SECTION_DESCRIPTION_STYLE,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'white'
                    }}
                  >
                    {i18n.t(`${i18nWidgetPrefix}.realTimePreview.nonPreviewNote`)}
                  </div>
                )}

                <iframe
                  onLoad={this._onLoadIframe}
                  ref={(iframeElement) => {
                    this.iframeRef = iframeElement;

                    return this.iframeRef;
                  }}
                  scrolling="no"
                  src={`${ASSETS_PATH}/html/integration-area/${iframeSrc}`}
                  title={i18n.t(`${i18nWidgetPrefix}.realTimePreview.iframeTitle`)}
                  style={{
                    overflow: 'hidden',
                    width: '100%',
                    display: 'block',
                    height: iframeHeight
                  }}
                />
              </div>
            </div>
          </div>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }

  _renderSectionBehaviour() {
    const {i18n} = this.context;
    const {communicationMethod} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionBehaviour`;

    return (
      <div>
        <h3 style={SECTION_TITLE_STYLE}>{i18n.t(`${i18nPrefix}.title`)}</h3>
        <span style={SECTION_SUBTITLE_STYLE}>{i18n.t(`${i18nPrefix}.subTitle`)}</span>
        <div style={SECTION_CONTENT_STYLE}>
          <RadioButtonGroup
            defaultSelected={communicationMethod}
            name="communicationMethod"
            onChange={this._doCommunicationMethodChange}
          >
            <RadioButton
              label={i18n.t(`${i18nPrefix}.communicationMethod.option1`)}
              value=""
              style={{marginBottom: '5px'}}
            />
            <RadioButton
              label={i18n.t(`${i18nPrefix}.communicationMethod.option2`)}
              value="sms"
              style={{marginBottom: '5px', marginTop: '5px'}}
            />
            <RadioButton
              label={i18n.t(`${i18nPrefix}.communicationMethod.option3`)}
              value="email"
              style={{marginTop: '5px'}}
            />
          </RadioButtonGroup>
        </div>
      </div>
    );
  }

  _renderSectionStyleAndCustomize(content) {
    const {i18n} = this.context;
    const {i18nWidgetPrefix} = this.state;

    return (
      <div>
        <h3 style={SECTION_TITLE_STYLE}>
          {i18n.t(`${i18nWidgetPrefix}.sectionStyleAndCustomize.title`)}
        </h3>
        <div style={SECTION_CONTENT_STYLE}>{content}</div>
      </div>
    );
  }

  // Not common rendering
  _renderBackgroundColorPicker(i18nKey = 'mainColor') {
    const {i18n} = this.context;
    const {bgColor} = this.props;
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionStyleAndCustomize.${i18nKey}`;

    return (
      <div style={SECTION_BLOCK_STYLE}>
        <h3 style={SECTION_TITLE_STYLE}>{i18n.t(`${i18nPrefix}.title`)}</h3>

        <span style={SECTION_SUBTITLE_STYLE}>{i18n.t(`${i18nPrefix}.description`)}</span>

        <SketchPicker color={bgColor} onChangeComplete={this._doBackgroundColorChange} />
      </div>
    );
  }

  _renderSingleBusinessSelector(propName, onChange) {
    const {i18n} = this.context;
    const {businessesList} = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const propValue = this.props[propName];
    const {i18nWidgetPrefix} = this.state;

    const i18nPrefix = `${i18nWidgetPrefix}.sectionBusiness`;

    return (
      <div>
        {this._renderBusinessSelectorIntroduction()}

        <div style={SECTION_CONTENT_STYLE}>
          <SelectField
            data-test-id="business-selector-select"
            disabled={businessesList.length < 1}
            floatingLabelFixed
            floatingLabelStyle={{color: getSelectFloatingLabelColor(propValue)}}
            floatingLabelText={i18n.t(`${i18nPrefix}.labelSelect`)}
            fullWidth
            hintText={i18n.t(`${i18nPrefix}.hintSelect`)}
            value={propValue}
            {...{
              onChange
            }}
          >
            {businessesList.map((item, key) => (
              <MenuItem
                data-test-id="select-option"
                key={String(key)}
                primaryText={item.name}
                value={item[propName]}
              />
            ))}
          </SelectField>
        </div>
      </div>
    );
  }
}

export const WIDGET_PAGES_COMMON_PROP_TYPES = {
  bgColor: PropTypes.string,
  businessesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  communicationMethod: PropTypes.string,
  errorInvalidEmail: PropTypes.string,
  errorInvalidPhone: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  inputPlaceholderEmail: PropTypes.string,
  inputPlaceholderPhone: PropTypes.string,
  language: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired,
  snippetCopied: PropTypes.bool.isRequired,
  thankYouMessage: PropTypes.string,
  doChangeBackgroundColor: PropTypes.func,
  doChangeBusiness: PropTypes.func,
  doChangeCommunicationMethod: PropTypes.func,
  doChangeErrorInvalidEmail: PropTypes.func,
  doChangeErrorInvalidPhone: PropTypes.func,
  doChangeInputPlaceholder: PropTypes.func,
  doChangeInputPlaceholderEmail: PropTypes.func,
  doChangeInputPlaceholderPhone: PropTypes.func,
  doChangeLanguage: PropTypes.func.isRequired,
  doChangeThankYouMessage: PropTypes.func,
  doCopyCode: PropTypes.func.isRequired,
  doResetState: PropTypes.func.isRequired,
  doUpdateSnippet: PropTypes.func.isRequired
};

WidgetPageComponent.propTypes = {
  bgColor: PropTypes.string,
  businessesList: PropTypes.arrayOf(PropTypes.any.isRequired),
  communicationMethod: PropTypes.string,
  errorInvalidEmail: PropTypes.string.isRequired,
  errorInvalidPhone: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  inputPlaceholderEmail: PropTypes.string.isRequired,
  inputPlaceholderPhone: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired,
  snippetCopied: PropTypes.bool.isRequired,
  thankYouMessage: PropTypes.string.isRequired,
  doChangeBackgroundColor: PropTypes.func.isRequired,
  doChangeBusiness: PropTypes.func.isRequired,
  doChangeCommunicationMethod: PropTypes.func.isRequired,
  doChangeErrorInvalidEmail: PropTypes.func.isRequired,
  doChangeErrorInvalidPhone: PropTypes.func.isRequired,
  doChangeInputPlaceholder: PropTypes.func,
  doChangeInputPlaceholderEmail: PropTypes.func,
  doChangeInputPlaceholderPhone: PropTypes.func,
  doChangeLanguage: PropTypes.func.isRequired,
  doChangeThankYouMessage: PropTypes.func,
  doCopyCode: PropTypes.func.isRequired,
  doResetState: PropTypes.func.isRequired,
  doUpdateSnippet: PropTypes.func.isRequired
};

export default WidgetPageComponent;
