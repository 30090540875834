import PropTypes from 'prop-types';
import AdministrationDisclaimer from '../../../../layout/administration-disclaimer-component';
import AdministrationPaper from '../../../../layout/administration-paper-component';
import ErrorMessageAdvanced from '../../../../../../common/error-message-advanced-component';
import StandardRaisedButton from '../../../../../../../../../shared/component/button/standard-raised-button-component';
import {lineHeight, spacing} from '../../../../../../../../../shared/style/variables';
import {darkGrey, whiteSmoke} from '../../../../../../../../../shared/style/colors';
import {ADMINISTRATION_ORGANIZATION_CHANNELS} from '../../../../../../../data/administration/administration-organization-data';

const AdministrationOrganizationChannelsGbmSignInComponent = ({error, i18n, organizationId}) => {
  const gbmConfiguration = ADMINISTRATION_ORGANIZATION_CHANNELS.GBM;
  const buttonLink = gbmConfiguration.steps.signIn.getOAuthLink(organizationId);

  const i18nPrefix = 'administration.organization.channels.flows.gbm.steps.signIn';
  const paddingSize = spacing.large;

  const renderGBMLogo = () => (
    <div style={{padding: `10px ${paddingSize} 2px ${paddingSize}`}}>
      <img alt="" height="50" src={gbmConfiguration.gbmLogoUrl} />
    </div>
  );

  const renderButtonArea = () => {
    let errorDescription;
    if (error) {
      errorDescription = i18n.t(`${i18nPrefix}.error.descriptions.default`);

      const customErrorKey = gbmConfiguration.steps.signIn.errors[error];
      if (customErrorKey) {
        const customErrorMessage = i18n.t(`${i18nPrefix}.error.descriptions.${customErrorKey}`);

        errorDescription = `${customErrorMessage}<br />${errorDescription}`;
      }
    }

    return (
      <div
        style={{
          alignItems: 'flex-start',
          backgroundColor: whiteSmoke,
          color: darkGrey,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: lineHeight.large,
          padding: paddingSize
        }}
      >
        {errorDescription ? (
          <ErrorMessageAdvanced
            description={errorDescription}
            title={i18n.t(`${i18nPrefix}.error.title`)}
          />
        ) : null}
        <span>{i18n.t(`${i18nPrefix}.description`)}</span>
        <AdministrationDisclaimer
          labels={i18n.t(`${i18nPrefix}.disclaimer`, {returnObjects: true})}
          style={{marginTop: '10px'}}
        />
        <StandardRaisedButton
          data-test-id="sign-in"
          href={buttonLink}
          key="sign-in"
          label={i18n.t(`${i18nPrefix}.buttonLabel`)}
          style={{marginTop: spacing.large, width: 'auto'}}
        />
      </div>
    );
  };

  return (
    <AdministrationPaper>
      {renderGBMLogo()}
      {renderButtonArea()}
    </AdministrationPaper>
  );
};

AdministrationOrganizationChannelsGbmSignInComponent.propTypes = {
  error: PropTypes.string,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  organizationId: PropTypes.number.isRequired
};

export default AdministrationOrganizationChannelsGbmSignInComponent;
