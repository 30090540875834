/**
 * Get a list of business ids as string from an array of business objects.
 *
 * @param {Array} businesses
 * @returns {Array}
 */
export default (businesses) => {
  if (!businesses) {
    return [];
  }

  return businesses.map((item) => `${item.businessId}`);
};
