import {useState} from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import buildCustomerName from '../../../../../lib/build-customer-name';
import {BUTTON_LABEL_STYLE} from '../../../../../../../shared/style/theme-v0';
import {inputMaxLength, radius} from '../../../../../../../shared/style/variables';

const CustomerProfileEditComponent = ({
  customerProfile,
  i18n,
  participationId,
  doCustomerProfileEditClose,
  doCustomerProfileEditTextUpdate
}) => {
  const [firstName, setFirstName] = useState(customerProfile.firstName);
  const [lastName, setLastName] = useState(customerProfile.lastName);
  const [company, setCompany] = useState(customerProfile.company);
  const buttonStyle = {flex: 1, borderRadius: radius.xxlarge, textAlign: 'center'};
  const inputStyle = {width: '100%'};
  const handleSubmit = (e) => {
    e.preventDefault();

    doCustomerProfileEditTextUpdate(
      participationId,
      customerProfile.customerId,
      buildCustomerName(
        customerProfile.emailAddress,
        firstName,
        lastName,
        customerProfile.phoneNumber
      ),
      firstName,
      lastName,
      company
    );
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleCompanyName = (e) => {
    setCompany(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} style={{height: '100%', width: '100%'}}>
      <TextField
        data-test-id="firstname-input"
        autoComplete="given-name"
        defaultValue={customerProfile.firstName}
        floatingLabelText={i18n.t('customerThread.customerProfile.editForm.firstName')}
        hintText={i18n.t('customerThread.customerProfile.editForm.firstName')}
        maxLength={inputMaxLength.firstName}
        onChange={handleFirstName}
        style={inputStyle}
      />
      <TextField
        data-test-id="lastname-input"
        autoComplete="family-name"
        defaultValue={customerProfile.lastName}
        floatingLabelText={i18n.t('form.lastName')}
        hintText={i18n.t('form.lastName')}
        maxLength={inputMaxLength.lastName}
        onChange={handleLastName}
        style={inputStyle}
      />
      <TextField
        data-test-id="company-input"
        autoComplete="organization"
        defaultValue={customerProfile.company}
        floatingLabelText={i18n.t('form.companyName')}
        hintText={i18n.t('form.companyName')}
        maxLength={inputMaxLength.companyName}
        onChange={handleCompanyName}
        style={inputStyle}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          height: '50px'
        }}
      >
        <FlatButton
          data-test-id="cancel-button"
          label={i18n.t('common.cancel')}
          labelStyle={BUTTON_LABEL_STYLE}
          secondary
          style={buttonStyle}
          onClick={doCustomerProfileEditClose}
        />
        <FlatButton
          data-test-id="save-button"
          label={i18n.t('common.save')}
          labelStyle={BUTTON_LABEL_STYLE}
          primary
          style={buttonStyle}
          type="submit"
        />
      </div>
    </form>
  );
};

CustomerProfileEditComponent.propTypes = {
  customerProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  participationId: PropTypes.string,
  doCustomerProfileEditClose: PropTypes.func.isRequired,
  doCustomerProfileEditTextUpdate: PropTypes.func.isRequired
};

export default CustomerProfileEditComponent;
