import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  DEFAULT_STATE_WIDGET_COMMON,
  errorInvalidEmailChange,
  errorInvalidPhoneChange,
  inputPlaceHolderEmailChange,
  inputPlaceHolderPhoneChange,
  languageChange,
  snippetChange,
  snippetCopiedChange,
  thankYouMessageChange
} from '../../../../lib/widget-helper';
import {
  INTEGRATION_AREA_WIDGET_BUBBLE_BACKGROUND_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_BORDER_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_COMMUNICATION_METHOD_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_HAS_BORDER_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_HAS_SHADOW_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_RESET_STATE,
  INTEGRATION_AREA_WIDGET_BUBBLE_SIDE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_THANKYOU_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_TOOLTIP_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_WIDGET_COMMON,
  bgColor: '',
  borderColor: '',
  businessId: '',
  communicationMethod: '',
  contactChoice: true,
  defaultContact: 'email',
  errorInvalidEmail: '',
  errorInvalidPhone: '',
  hasBorder: false,
  hasShadow: false,
  inputPlaceholder: '',
  inputPlaceholderEmail: '',
  inputPlaceholderPhone: '',
  side: 'right',
  thankYouMessage: '',
  tooltip: ''
};

export default createReducer(DEFAULT_STATE, {
  // Common
  [INTEGRATION_AREA_WIDGET_BUBBLE_LANGUAGE_CHANGE]: languageChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_CHANGE]: snippetChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_SNIPPET_COPIED_CHANGE]: snippetCopiedChange,

  // Specific
  [INTEGRATION_AREA_WIDGET_BUBBLE_RESET_STATE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_SIDE_CHANGE]: (state, {side}) => {
    return state.set('side', side);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_BUSINESS_CHANGE]: (state, {businessId}) => {
    return state.set('businessId', businessId);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_TOOLTIP_CHANGE]: (state, {text}) => {
    return state.set('tooltip', text);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_COMMUNICATION_METHOD_CHANGE]: (state, {method}) => {
    return state.set('communicationMethod', method).set('defaultContact', method || 'email');
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_CHANGE]: (state, {text}) => {
    return state.set('inputPlaceholder', text);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_EMAIL_CHANGE]: errorInvalidEmailChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_PHONE_CHANGE]: errorInvalidPhoneChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_EMAIL_CHANGE]: inputPlaceHolderEmailChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_PHONE_CHANGE]: inputPlaceHolderPhoneChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_THANKYOU_MESSAGE_CHANGE]: thankYouMessageChange,

  [INTEGRATION_AREA_WIDGET_BUBBLE_BACKGROUND_COLOR_CHANGE]: (state, {color}) => {
    return state.set('bgColor', color);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_HAS_BORDER_CHANGE]: (state, {hasBorder}) => {
    return state.set('hasBorder', hasBorder);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_BORDER_COLOR_CHANGE]: (state, {color}) => {
    return state.set('borderColor', color);
  },

  [INTEGRATION_AREA_WIDGET_BUBBLE_HAS_SHADOW_CHANGE]: (state, {hasShadow}) => {
    return state.set('hasShadow', hasShadow);
  }
});
