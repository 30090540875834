import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import uploadFile from '../../../lib/upload-file';
import {
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD,
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE,
  CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
  CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE,
  CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE,
  CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS
} from '../../../../actions/customer-thread-profile-actions';

const CustomerThreadProfileEpic =
  ({command, graphql}) =>
  (action$) => {
    const editCustomerProfile = action$.pipe(
      ofType(CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE),
      mergeMap(({company, customerId, displayName, firstName, lastName, participationId}) => {
        return graphql('update-customer-profile-name-mutation', {
          company,
          customerId,
          firstName,
          lastName
        }).pipe(
          map(() => {
            return {
              type: CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_SUCCESS,
              payload: {
                customer: {
                  company,
                  displayName,
                  firstName,
                  lastName
                },
                participationId
              }
            };
          }),
          catchError((error) => {
            return of({
              type: CUSTOMER_THREAD_PROFILE_EDIT_TEXT_UPDATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const uploadPicture = action$.pipe(
      ofType(CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD),
      mergeMap(({customerId, file, participationId}) =>
        uploadFile({
          command,
          file,
          actions: {
            failure: CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_FAILURE
          },
          successMap: ({data}) => {
            return graphql('update-customer-profile-picture-mutation', {
              fileUUID: data.uuid,
              customerId
            }).pipe(
              map(() => {
                return {
                  type: CUSTOMER_THREAD_PROFILE_EDIT_PICTURE_UPLOAD_SUCCESS,
                  payload: {
                    customer: {
                      pictureHref: data.href
                    },
                    participationId
                  }
                };
              })
              // @todo Handle upload failure
            );
          }
        })
      )
    );

    return merge(editCustomerProfile, uploadPicture);
  };

export default CustomerThreadProfileEpic;
