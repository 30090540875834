import {withStyles} from '@material-ui/core/styles';
import Paper from '../../../../../../shared/component/paper-component';
import {radius} from '../../../../../../shared/style/variables';

const AdministrationPaper = withStyles({
  elevation1: {
    padding: 0,
    overflow: 'hidden'
  },
  rounded: {
    borderRadius: radius.xlarge
  }
})((props) => <Paper square={false} {...props} />);

export default AdministrationPaper;
