import {getCurrentConversationFromState} from '../content/customer/thread/lib/customer-thread-conversations-helper';

const isEmployeeOnConversationType = (state$, conversationToCheck, conversationType) => {
  const currentConversation = getCurrentConversationFromState(state$);

  if (!currentConversation) {
    return false;
  }

  if (currentConversation.get('id') !== conversationToCheck.id) {
    return false;
  }

  if (!state$.value.getIn(['ui', 'isSmallScreen'])) {
    return true;
  }

  return state$.value.getIn(['customerThread', 'threadType']) === conversationType;
};

// eslint-disable-next-line import/prefer-default-export
export {isEmployeeOnConversationType};
