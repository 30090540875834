import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import moment from 'moment';
import getAggregationStartDateFromPeriod from '../../../lib/insights-get-aggregation-start-date-from-period';
import getBusinessIds from '../../../lib/get-businesses-ids';
import {
  INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS,
  INSIGHT_CONNECTED_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONNECTED_CUSTOMERS_LOAD,
  INSIGHT_CONNECTED_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_CONNECTED_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_CONNECTED_CUSTOMERS_OPEN,
  INSIGHT_CONNECTED_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONNECTED_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_OPEN,
  INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD,
  INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_SHOW_MORE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_SHOW_MORE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_OPEN,
  INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS,
  INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_SUCCESS,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN,
  INSIGHT_CONTACTS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACTS_LOAD,
  INSIGHT_CONTACTS_LOAD_FAILURE,
  INSIGHT_CONTACTS_LOAD_SUCCESS,
  INSIGHT_CONTACTS_OPEN,
  INSIGHT_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONVERSATIONS_LOAD,
  INSIGHT_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_CONVERSATIONS_OPEN,
  INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD,
  INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD,
  INSIGHT_CONVERSATIONS_RANKING_LOAD,
  INSIGHT_CONVERSATIONS_RANKING_LOAD_FAILURE,
  INSIGHT_CONVERSATIONS_RANKING_LOAD_SUCCESS,
  INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION,
  INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD,
  INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_FAILURE,
  INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_SUCCESS,
  INSIGHT_DEVICES_OPEN,
  INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_FAILURE,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_SUCCESS,
  INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION,
  INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_MESSAGES_CSV_LOAD,
  INSIGHT_MESSAGES_CSV_DOWNLOAD,
  INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD,
  INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_FAILURE,
  INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_SUCCESS,
  INSIGHT_MESSAGES_OPEN,
  INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_CUSTOMERS_LOAD,
  INSIGHT_NEW_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_NEW_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_NEW_CUSTOMERS_OPEN,
  INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_OPEN,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_PERIOD_TAB_CHANGE,
  INSIGHT_PREFERRED_CHANNEL_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_PREFERRED_CHANNEL_OPEN,
  INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD,
  INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_FAILURE,
  INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_SUCCESS,
  INSIGHT_PREFERRED_CHANNEL_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_PREFERRED_CHANNEL_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_FAILURE,
  INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_SUCCESS,
  INSIGHT_RESPONSE_RATE_OPEN,
  INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_RESPONSE_TIME_LOAD,
  INSIGHT_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_RESPONSE_TIME_OPEN,
  INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD,
  INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD,
  INSIGHT_RESPONSE_TIME_RANKING_LOAD,
  INSIGHT_RESPONSE_TIME_RANKING_LOAD_SUCCESS,
  INSIGHT_RESPONSE_TIME_RANKING_LOAD_FAILURE,
  INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_RESPONSE_TIME_UPDATE_SORT_DIRECTION,
  INSIGHT_SATISFACTION_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_SATISFACTION_NPS_SCORE_LOAD,
  INSIGHT_SATISFACTION_NPS_SCORE_LOAD_FAILURE,
  INSIGHT_SATISFACTION_NPS_SCORE_LOAD_SUCCESS,
  INSIGHT_SATISFACTION_OPEN,
  INSIGHT_SATISFACTION_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_SATISFACTION_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD,
  INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD,
  INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CONTACTS_LOAD,
  INSIGHT_TOP_STORES_CONTACTS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CONTACTS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CONVERSATIONS_LOAD,
  INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CSV_DOWNLOAD,
  INSIGHT_TOP_STORES_CSV_LOAD,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE,
  INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD,
  INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_FAILURE,
  INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD,
  INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_OPEN,
  INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD,
  INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_FAILURE,
  INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_SUCCESS,
  INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION,
  INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_WAITING_CUSTOMERS_CSV_LOAD,
  INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD,
  INSIGHT_WAITING_CUSTOMERS_LOAD,
  INSIGHT_WAITING_CUSTOMERS_LOAD_FAILURE,
  INSIGHT_WAITING_CUSTOMERS_LOAD_SUCCESS,
  INSIGHT_WAITING_CUSTOMERS_OPEN
} from '../../../actions/insight-actions';

export const DEFAULT_STATE = {
  businessesSelection: null,
  classicAggregationDate: null,
  classicAggregationPeriod: 'P1W',
  customAggregationPeriod: 'PT1H',
  customPeriodEndDate: null,
  customPeriodStartDate: null,
  loading: false,
  periodTabSelected: 'classic', // 'classic' or 'custom'
  contacts: {
    contactsPageHistogram: {
      contacts: {
        failed: false,
        loading: false,
        data: {
          contacts: null,
          contactsIncrement: null,
          contactsHistogram: []
        }
      }
    }
  },
  connectedCustomers: {
    connectedCustomersPageHistogram: {
      connectedCustomers: {
        failed: false,
        loading: false,
        data: {
          connectedCustomers: null,
          connectedCustomersIncrement: null,
          connectedCustomersHistogram: []
        }
      }
    }
  },
  conversations: {
    conversationsPageHistogram: {
      conversations: {
        failed: false,
        loading: false,
        data: {
          conversations: null,
          conversationsIncrement: null,
          conversationsOngoing: null,
          conversationsHistogram: []
        }
      }
    },
    ranking: {
      loadingCsv: false,
      sortedDirection: 'desc',
      conversations: {
        failed: false,
        loading: false,
        data: []
      }
    }
  },
  newInboundConversations: {
    newInboundConversationsPageHistogram: {
      returningCustomers: {
        failed: false,
        loading: false,
        data: {
          newInboundConversations: null,
          newInboundConversationsIncrement: null,
          returningCustomers: null,
          returningCustomersIncrement: null,
          returningCustomersHistogram: []
        }
      }
    }
  },
  responseTime: {
    responseTimePageHistogram: {
      averageResponseTime: {
        failed: false,
        loading: false,
        data: {
          averageResponseTime: null,
          averageResponseTimeIncrement: null,
          averageResponseTimeHistogram: []
        }
      },
      returningCustomers: {
        failed: false,
        loading: false,
        data: {
          newInboundConversations: null
        }
      }
    },
    ranking: {
      sortedDirection: 'desc',
      loadingCsv: false,
      averageResponseTime: {
        failed: false,
        loading: false,
        data: []
      }
    }
  },
  newOutboundConversations: {
    newOutboundConversationsPageHistogram: {
      averageCustomerResponseRate: {
        failed: false,
        loading: false,
        data: {
          averageCustomerResponseRate: null,
          averageCustomerResponseRateIncrement: null,
          averageCustomerResponseRateHistogram: []
        }
      },
      averageCustomerResponseTime: {
        failed: false,
        loading: false,
        data: {
          averageCustomerResponseTime: null,
          averageCustomerResponseTimeIncrement: null,
          averageCustomerResponseTimeHistogram: []
        }
      },
      newOutboundConversations: {
        failed: false,
        loading: false,
        data: {
          newOutboundConversations: null,
          newOutboundConversationsIncrement: null,
          newOutboundConversationsHistogram: []
        }
      }
    }
  },
  topStores: {
    ranking: {
      sortedDirection: 'asc'
    },
    topStores: {
      loadingCsv: false,
      averageResponseTime: {
        failed: false,
        loading: false,
        data: []
      },
      connectedCustomers: {
        failed: false,
        loading: false,
        data: []
      },
      contacts: {
        failed: false,
        loading: false,
        data: []
      },
      conversations: {
        failed: false,
        loading: false,
        data: []
      },
      customerResponseRate: {
        failed: false,
        loading: false,
        data: []
      },
      customerResponseTime: {
        failed: false,
        loading: false,
        data: []
      },
      employeeResponseRate: {
        failed: false,
        loading: false,
        data: []
      },
      inboundConversations: {
        failed: false,
        loading: false,
        data: []
      },
      outboundConversations: {
        failed: false,
        loading: false,
        data: []
      }
    }
  },
  newCustomers: {
    newCustomersHistogram: {
      failed: false,
      loading: false,
      newInboundCustomers: {
        newInboundCustomers: null,
        newInboundCustomersIncrement: null,
        phoneCallPercent: null,
        smsPercent: null,
        whatsappPercent: null,
        widgetPercent: null,
        sdkPercent: null,
        othersPercent: null,
        newInboundCustomersHistogram: []
      },
      newOutboundCustomers: {
        newOutboundCustomers: null,
        newOutboundCustomersIncrement: null,
        newOutboundCustomersHistogram: []
      }
    }
  },
  preferredChannel: {
    preferredChannelIndicators: {
      messageOverTime: null,
      phoneOverTime: null
    },
    preferredChannelHistogram: {
      failed: false,
      loading: false,
      items: []
    }
  },
  satisfaction: {
    npsScoreHistogram: {
      failed: false,
      loading: false,
      items: []
    }
  },
  messages: {
    messages: {
      loadingCsv: false
    },
    messagesSentIndicators: {
      totalNumberOfMessages: 0,
      totalNumberOfMessagesIncrement: 0
    },
    messagesSentHistogram: {
      failed: false,
      loading: false,
      items: []
    }
  },
  devices: {
    employeeMessageChannelHistogram: {
      failed: false,
      loading: false,
      items: []
    }
  },
  contactCentersWebConnectedUsers: {
    webConnectedUsers: {
      failed: false,
      loading: false,
      employeesRealTime: []
    }
  },
  waitingCustomers: {
    waitingCustomersList: {
      failed: false,
      loading: false,
      loadingCsv: false,
      businessRealTime: [],
      employeesRealTime: []
    }
  },
  contactCentersContacts: {
    organizationContactHistogram: {
      failed: false,
      loading: false,
      items: []
    }
  },
  contactCentersWebAHT: {
    averageHandlingTimeHistogram: {
      failed: false,
      loading: false,
      items: []
    }
  },
  contactCentersUsers: {
    activityPerBusiness: {
      failed: false,
      loading: false,
      showMore: false,
      items: []
    },
    activityPerEmployee: {
      failed: false,
      loading: false,
      showMore: false,
      items: []
    },
    averageLoggedTimeIndicators: {
      averageLoggedTime: null
    },
    averageLoggedTimeHistogram: {
      failed: false,
      loading: false,
      items: []
    },
    averageEmployeeLoggedTime: {
      keyword: '',
      failed: false,
      loading: false,
      page: 0,
      rowsPerPage: 10,
      totalCount: 0,
      items: []
    }
  },
  responseRate: {
    employeeResponseRateHistogram: {
      failed: false,
      loading: false,
      items: []
    },
    employeeResponseRateIndicators: {
      employeeResponseRateIncrement: null,
      employeeResponseRatePercent: null
    },
    ranking: {
      sortedDirection: 'desc',
      loadingCsv: false,
      employeeResponseRate: {
        failed: false,
        loading: false,
        data: []
      }
    }
  }
};

function openInsight(state) {
  const newState = state.set('loading', true);

  // Do nothing if dates were already initialized
  if (state.get('classicAggregationDate')) {
    return newState;
  }

  return newState
    .set(
      'classicAggregationDate',
      getAggregationStartDateFromPeriod(state.get('classicAggregationPeriod')).subtract(1, 'week')
    )
    .set('customPeriodEndDate', moment())
    .set('customPeriodStartDate', moment().subtract(4, 'days').startOf('day'));
}

function loadInsightSuccess(state) {
  return state.set('loading', false);
}

function load(page, section) {
  return (state) =>
    state.updateIn([page, section], (subState) =>
      subState.set('failed', false).set('loading', true)
    );
}

function loadSuccess(page, section) {
  return (state, action) =>
    state.updateIn([page, section], (subState) =>
      subState.set('failed', false).set('items', fromJS(action[section])).set('loading', false)
    );
}

function loadSuccessWithMetadata(page, section) {
  return (state, action) =>
    state.update(page, (pageState) =>
      pageState
        .set(section, pageState.get(section).merge(fromJS(action[section])))
        .setIn([section, 'failed'], false)
        .setIn([section, 'loading'], false)
    );
}

function loadFailure(page, section) {
  return (state) =>
    state.updateIn([page, section], (subState) =>
      subState.set('failed', true).set('items', fromJS([])).set('loading', false)
    );
}

function showMore(page, section) {
  return (state) => state.setIn([page, section, 'showMore'], true);
}

function changeClassicTabDateAndPeriod(state, {classicAggregationDate, classicAggregationPeriod}) {
  return state
    .set('classicAggregationDate', classicAggregationDate)
    .set('classicAggregationPeriod', classicAggregationPeriod);
}

function changeCustomTabDatesAndPeriod(
  state,
  {customAggregationPeriod, customPeriodEndDate, customPeriodStartDate}
) {
  return state
    .set('customAggregationPeriod', customAggregationPeriod)
    .set('customPeriodEndDate', customPeriodEndDate)
    .set('customPeriodStartDate', customPeriodStartDate);
}

function changeBusinessesSelection(state, {businessesSelection}) {
  return state.set('businessesSelection', fromJS(businessesSelection));
}

function changeSectionBusiness(page, section) {
  return (state, {businessesSelection}) =>
    state.set('businessesSelection', fromJS(businessesSelection)).setIn([page, section, 'page'], 0);
}

function changeSectionKeyword(page, section) {
  return (state, {keyword}) =>
    state.updateIn([page, section], (subState) => subState.set('keyword', keyword).set('page', 0));
}

function changeSectionResultPage(page, section) {
  return (state, {resultPage}) => state.setIn([page, section, 'page'], resultPage);
}

function changeSectionRowsPerPage(page, section) {
  return (state, {rowsPerPage}) =>
    state.updateIn([page, section], (subState) =>
      subState.set('rowsPerPage', rowsPerPage).set('page', 0)
    );
}

function loadSuccessWithIndicators(page, indicatorSection, histogramSection) {
  return (state, action) => {
    const newState = state.setIn([page, indicatorSection], fromJS(action[indicatorSection]));

    return loadSuccess(page, histogramSection)(newState, action);
  };
}

function loadSectionRootFailure(page, section) {
  return (state) =>
    state.setIn(
      [page, section],
      fromJS(
        Object.assign(DEFAULT_STATE[page][section], {
          failed: true,
          loading: false
        })
      )
    );
}

function loadSectionRootSuccess(page, section) {
  return (state, action) =>
    state.setIn(
      [page, section],
      fromJS(
        Object.assign(action[section], {
          failed: false,
          loading: false,
          loadingCsv: false
        })
      )
    );
}

function loadPartially(page, section, parts) {
  return (state) =>
    state.updateIn([page, section], (sectionState) => {
      let newState = sectionState;

      parts.forEach((part) => {
        newState = newState.setIn([part, 'failed'], false).setIn([part, 'loading'], true);
      });

      return newState;
    });
}

function loadPartiallySuccess(page, section, parts) {
  return (state, action) =>
    state.updateIn([page, section], (sectionState) => {
      let newState = sectionState;

      parts.forEach((part) => {
        newState = newState
          .setIn([part, 'data'], fromJS(action[section][part]))
          .setIn([part, 'loading'], false);
      });

      return newState;
    });
}

function loadPartiallyFailure(page, section, parts) {
  return (state) =>
    state.updateIn([page, section], (sectionState) => {
      let newState = sectionState;

      parts.forEach((part) => {
        newState = newState
          .setIn([part, 'data'], fromJS(DEFAULT_STATE[page][section][part].data))
          .setIn([part, 'failed'], true)
          .setIn([part, 'loading'], false);
      });

      return newState;
    });
}

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * If never set before, initialize businesses selection
   * with the ids of the ones during first call to search-browsable-businesses.
   *
   * @param {Object} state
   * @param {Array} items
   * @returns {Object} new state
   */
  [INSIGHT_BUSINESS_FILTER_BUSINESSES_SEARCH_SUCCESS]: (state, {items}) => {
    if (state.get('businessesSelection') !== null) {
      return state;
    }

    const itemsInScope = items.filter((item) => item.isEmployee);

    return state.set(
      'businessesSelection',
      fromJS(getBusinessIds(itemsInScope.length ? itemsInScope : items))
    );
  },

  /**
   * Change aggregation period tab.
   *
   * @param {Object} state
   * @param {String} newTab
   * @returns {Object} new state
   */
  [INSIGHT_PERIOD_TAB_CHANGE]: (state, {newTab}) => state.set('periodTabSelected', newTab),

  [INSIGHT_CONNECTED_CUSTOMERS_OPEN]: openInsight,
  [INSIGHT_CONVERSATIONS_OPEN]: openInsight,
  [INSIGHT_NEW_INBOUND_CONVERSATIONS_OPEN]: openInsight,
  [INSIGHT_RESPONSE_TIME_OPEN]: openInsight,
  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_OPEN]: openInsight,
  [INSIGHT_CONTACTS_OPEN]: openInsight,

  [INSIGHT_CONNECTED_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_CONNECTED_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]:
    changeClassicTabDateAndPeriod,
  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]:
    changeCustomTabDatesAndPeriod,
  [INSIGHT_RESPONSE_TIME_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_RESPONSE_TIME_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]:
    changeClassicTabDateAndPeriod,
  [INSIGHT_NEW_INBOUND_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]:
    changeCustomTabDatesAndPeriod,
  [INSIGHT_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_CONNECTED_CUSTOMERS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,
  [INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,
  [INSIGHT_RESPONSE_TIME_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,
  [INSIGHT_NEW_INBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,
  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,
  [INSIGHT_CONTACTS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_CONNECTED_CUSTOMERS_LOAD]: loadPartially(
    'connectedCustomers',
    'connectedCustomersPageHistogram',
    ['connectedCustomers']
  ),
  [INSIGHT_CONNECTED_CUSTOMERS_LOAD_FAILURE]: loadPartiallyFailure(
    'connectedCustomers',
    'connectedCustomersPageHistogram',
    ['connectedCustomers']
  ),
  [INSIGHT_CONNECTED_CUSTOMERS_LOAD_SUCCESS]: loadPartiallySuccess(
    'connectedCustomers',
    'connectedCustomersPageHistogram',
    ['connectedCustomers']
  ),

  [INSIGHT_CONTACTS_LOAD]: loadPartially('contacts', 'contactsPageHistogram', ['contacts']),
  [INSIGHT_CONTACTS_LOAD_FAILURE]: loadPartiallyFailure('contacts', 'contactsPageHistogram', [
    'contacts'
  ]),
  [INSIGHT_CONTACTS_LOAD_SUCCESS]: loadPartiallySuccess('contacts', 'contactsPageHistogram', [
    'contacts'
  ]),

  [INSIGHT_CONVERSATIONS_LOAD]: loadPartially('conversations', 'conversationsPageHistogram', [
    'conversations'
  ]),
  [INSIGHT_CONVERSATIONS_LOAD_FAILURE]: loadPartiallyFailure(
    'conversations',
    'conversationsPageHistogram',
    ['conversations']
  ),
  [INSIGHT_CONVERSATIONS_LOAD_SUCCESS]: loadPartiallySuccess(
    'conversations',
    'conversationsPageHistogram',
    ['conversations']
  ),

  [INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION]: (state, {sortDirection}) =>
    state.setIn(['conversations', 'ranking', 'sortedDirection'], sortDirection),

  [INSIGHT_CONVERSATIONS_RANKING_LOAD]: loadPartially('conversations', 'ranking', [
    'conversations'
  ]),
  [INSIGHT_CONVERSATIONS_RANKING_LOAD_FAILURE]: loadPartiallyFailure('conversations', 'ranking', [
    'conversations'
  ]),
  [INSIGHT_CONVERSATIONS_RANKING_LOAD_SUCCESS]: loadPartiallySuccess('conversations', 'ranking', [
    'conversations'
  ]),

  [INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD]: loadPartially(
    'newInboundConversations',
    'newInboundConversationsPageHistogram',
    ['returningCustomers']
  ),
  [INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_FAILURE]: loadPartiallyFailure(
    'newInboundConversations',
    'newInboundConversationsPageHistogram',
    ['returningCustomers']
  ),
  [INSIGHT_NEW_INBOUND_CONVERSATIONS_LOAD_SUCCESS]: loadPartiallySuccess(
    'newInboundConversations',
    'newInboundConversationsPageHistogram',
    ['returningCustomers']
  ),

  [INSIGHT_RESPONSE_TIME_LOAD]: loadPartially('responseTime', 'responseTimePageHistogram', [
    'averageResponseTime',
    'returningCustomers'
  ]),
  [INSIGHT_RESPONSE_TIME_LOAD_FAILURE]: loadPartiallyFailure(
    'responseTime',
    'responseTimePageHistogram',
    ['averageResponseTime', 'returningCustomers']
  ),
  [INSIGHT_RESPONSE_TIME_LOAD_SUCCESS]: loadPartiallySuccess(
    'responseTime',
    'responseTimePageHistogram',
    ['averageResponseTime', 'returningCustomers']
  ),

  [INSIGHT_RESPONSE_TIME_RANKING_LOAD]: loadPartially('responseTime', 'ranking', [
    'averageResponseTime'
  ]),
  [INSIGHT_RESPONSE_TIME_RANKING_LOAD_FAILURE]: loadPartiallyFailure('responseTime', 'ranking', [
    'averageResponseTime'
  ]),
  [INSIGHT_RESPONSE_TIME_RANKING_LOAD_SUCCESS]: loadPartiallySuccess('responseTime', 'ranking', [
    'averageResponseTime'
  ]),
  [INSIGHT_RESPONSE_TIME_UPDATE_SORT_DIRECTION]: (state, {sortDirection}) => {
    return state.setIn(['responseTime', 'ranking', 'sortedDirection'], sortDirection);
  },

  [INSIGHT_RESPONSE_TIME_RANKING_CSV_LOAD]: (state) =>
    state.setIn(['responseTime', 'ranking', 'loadingCsv'], true),

  [INSIGHT_RESPONSE_TIME_RANKING_CSV_DOWNLOAD]: (state) =>
    state.setIn(['responseTime', 'ranking', 'loadingCsv'], false),

  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD]:
    loadPartially('newOutboundConversations', 'newOutboundConversationsPageHistogram', [
      'averageCustomerResponseRate',
      'averageCustomerResponseTime',
      'newOutboundConversations'
    ]),
  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE]:
    loadPartiallyFailure('newOutboundConversations', 'newOutboundConversationsPageHistogram', [
      'averageCustomerResponseRate',
      'averageCustomerResponseTime',
      'newOutboundConversations'
    ]),
  [INSIGHT_NEW_OUTBOUND_CONVERSATIONS_AND_CUSTOMER_RESPONSE_RATE_AND_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS]:
    loadPartiallySuccess('newOutboundConversations', 'newOutboundConversationsPageHistogram', [
      'averageCustomerResponseRate',
      'averageCustomerResponseTime',
      'newOutboundConversations'
    ]),

  // top stores
  [INSIGHT_TOP_STORES_OPEN]: (state) =>
    openInsight(
      state.set('periodTabSelected', 'classic') // force "classic" tab because "custom" tab is not allowed for "Top stores" insights
    ),

  [INSIGHT_TOP_STORES_UPDATE_SORT_DIRECTION]: (state, {sortDirection}) =>
    state.setIn(['topStores', 'ranking', 'sortedDirection'], sortDirection),

  [INSIGHT_TOP_STORES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,

  [INSIGHT_TOP_STORES_CSV_LOAD]: (state) =>
    state.setIn(['topStores', 'topStores', 'loadingCsv'], true),

  [INSIGHT_TOP_STORES_CSV_DOWNLOAD]: (state) =>
    state.setIn(['topStores', 'topStores', 'loadingCsv'], false),

  [INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD]: (state) =>
    state.setIn(['conversations', 'ranking', 'loadingCsv'], true),

  [INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD]: (state) =>
    state.setIn(['conversations', 'ranking', 'loadingCsv'], false),

  [INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD]: loadPartially('topStores', 'topStores', [
    'averageResponseTime'
  ]),
  [INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['averageResponseTime']
  ),
  [INSIGHT_TOP_STORES_AVERAGE_RESPONSE_TIME_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['averageResponseTime']
  ),

  [INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD]: loadPartially('topStores', 'topStores', [
    'connectedCustomers'
  ]),
  [INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['connectedCustomers']
  ),
  [INSIGHT_TOP_STORES_CONNECTED_CUSTOMERS_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['connectedCustomers']
  ),

  [INSIGHT_TOP_STORES_CONTACTS_LOAD]: loadPartially('topStores', 'topStores', ['contacts']),
  [INSIGHT_TOP_STORES_CONTACTS_LOAD_FAILURE]: loadPartiallyFailure('topStores', 'topStores', [
    'contacts'
  ]),
  [INSIGHT_TOP_STORES_CONTACTS_LOAD_SUCCESS]: loadPartiallySuccess('topStores', 'topStores', [
    'contacts'
  ]),

  [INSIGHT_TOP_STORES_CONVERSATIONS_LOAD]: loadPartially('topStores', 'topStores', [
    'conversations'
  ]),
  [INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_FAILURE]: loadPartiallyFailure('topStores', 'topStores', [
    'conversations'
  ]),
  [INSIGHT_TOP_STORES_CONVERSATIONS_LOAD_SUCCESS]: loadPartiallySuccess('topStores', 'topStores', [
    'conversations'
  ]),

  [INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD]: loadPartially('topStores', 'topStores', [
    'customerResponseRate'
  ]),
  [INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['customerResponseRate']
  ),
  [INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_RATE_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['customerResponseRate']
  ),

  [INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD]: loadPartially('topStores', 'topStores', [
    'customerResponseTime'
  ]),
  [INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['customerResponseTime']
  ),
  [INSIGHT_TOP_STORES_CUSTOMER_RESPONSE_TIME_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['customerResponseTime']
  ),

  [INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD]: loadPartially('topStores', 'topStores', [
    'inboundConversations'
  ]),
  [INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['inboundConversations']
  ),
  [INSIGHT_TOP_STORES_INBOUND_CONVERSATIONS_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['inboundConversations']
  ),

  [INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD]: loadPartially('topStores', 'topStores', [
    'outboundConversations'
  ]),
  [INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['outboundConversations']
  ),
  [INSIGHT_TOP_STORES_OUTBOUND_CONVERSATIONS_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['outboundConversations']
  ),

  [INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD]: loadPartially('topStores', 'topStores', [
    'employeeResponseRate'
  ]),
  [INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_FAILURE]: loadPartiallyFailure(
    'topStores',
    'topStores',
    ['employeeResponseRate']
  ),
  [INSIGHT_TOP_STORES_EMPLOYEES_RESPONSE_RATE_LOAD_SUCCESS]: loadPartiallySuccess(
    'topStores',
    'topStores',
    ['employeeResponseRate']
  ),

  // new customers
  [INSIGHT_NEW_CUSTOMERS_OPEN]: openInsight,

  [INSIGHT_NEW_CUSTOMERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_NEW_CUSTOMERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_NEW_CUSTOMERS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_NEW_CUSTOMERS_LOAD]: load('newCustomers', 'newCustomersHistogram'),
  [INSIGHT_NEW_CUSTOMERS_LOAD_FAILURE]: loadSectionRootFailure(
    'newCustomers',
    'newCustomersHistogram'
  ),
  [INSIGHT_NEW_CUSTOMERS_LOAD_SUCCESS]: loadSectionRootSuccess(
    'newCustomers',
    'newCustomersHistogram'
  ),

  // preferred channel
  [INSIGHT_PREFERRED_CHANNEL_OPEN]: openInsight,

  [INSIGHT_PREFERRED_CHANNEL_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_PREFERRED_CHANNEL_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_PREFERRED_CHANNEL_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD]: load(
    'preferredChannel',
    'preferredChannelHistogram'
  ),
  [INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_SUCCESS]: loadSuccessWithIndicators(
    'preferredChannel',
    'preferredChannelIndicators',
    'preferredChannelHistogram'
  ),
  [INSIGHT_PREFERRED_CHANNEL_PREFERRED_CHANNEL_LOAD_FAILURE]: loadFailure(
    'preferredChannel',
    'preferredChannelHistogram'
  ),

  // response rate
  [INSIGHT_RESPONSE_RATE_OPEN]: openInsight,

  [INSIGHT_RESPONSE_RATE_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_RESPONSE_RATE_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_RESPONSE_RATE_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD]: load(
    'responseRate',
    'employeeResponseRateHistogram'
  ),
  [INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_SUCCESS]: loadSuccessWithIndicators(
    'responseRate',
    'employeeResponseRateIndicators',
    'employeeResponseRateHistogram'
  ),
  [INSIGHT_RESPONSE_RATE_EMPLOYEE_RESPONSE_RATE_LOAD_FAILURE]: loadFailure(
    'responseRate',
    'employeeResponseRateHistogram'
  ),

  [INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD]: loadPartially('responseRate', 'ranking', [
    'employeeResponseRate'
  ]),
  [INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_FAILURE]: loadPartiallyFailure(
    'responseRate',
    'ranking',
    ['employeeResponseRate']
  ),
  [INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_LOAD_SUCCESS]: loadPartiallySuccess(
    'responseRate',
    'ranking',
    ['employeeResponseRate']
  ),
  [INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_LOAD]: (state) =>
    state.setIn(['responseRate', 'ranking', 'loadingCsv'], true),

  [INSIGHT_EMPLOYEE_RESPONSE_RATE_RANKING_CSV_DOWNLOAD]: (state) =>
    state.setIn(['responseRate', 'ranking', 'loadingCsv'], false),

  [INSIGHT_EMPLOYEE_RESPONSE_RATE_UPDATE_SORT_DIRECTION]: (state, {sortDirection}) => {
    return state.setIn(['responseRate', 'ranking', 'sortedDirection'], sortDirection);
  },

  // satisfaction
  [INSIGHT_SATISFACTION_OPEN]: openInsight,

  [INSIGHT_SATISFACTION_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_SATISFACTION_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_SATISFACTION_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_SATISFACTION_NPS_SCORE_LOAD]: load('satisfaction', 'npsScoreHistogram'),
  [INSIGHT_SATISFACTION_NPS_SCORE_LOAD_SUCCESS]: loadSuccess('satisfaction', 'npsScoreHistogram'),
  [INSIGHT_SATISFACTION_NPS_SCORE_LOAD_FAILURE]: loadFailure('satisfaction', 'npsScoreHistogram'),

  // messages
  [INSIGHT_MESSAGES_OPEN]: openInsight,

  [INSIGHT_MESSAGES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_MESSAGES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_MESSAGES_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD]: load('messages', 'messagesSentHistogram'),
  [INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_SUCCESS]: loadSuccessWithIndicators(
    'messages',
    'messagesSentIndicators',
    'messagesSentHistogram'
  ),
  [INSIGHT_MESSAGES_MESSAGES_SENT_INSIGHTS_LOAD_FAILURE]: loadFailure(
    'messages',
    'messagesSentHistogram'
  ),

  [INSIGHT_MESSAGES_CSV_LOAD]: (state) => state.setIn(['messages', 'messages', 'loadingCsv'], true),

  [INSIGHT_MESSAGES_CSV_DOWNLOAD]: (state) =>
    state.setIn(['messages', 'messages', 'loadingCsv'], false),

  // devices
  [INSIGHT_DEVICES_OPEN]: openInsight,

  [INSIGHT_DEVICES_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_DEVICES_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_DEVICES_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD]: load(
    'devices',
    'employeeMessageChannelHistogram'
  ),
  [INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_SUCCESS]: loadSuccess(
    'devices',
    'employeeMessageChannelHistogram'
  ),
  [INSIGHT_DEVICES_EMPLOYEE_MESSAGE_CHANNEL_LOAD_FAILURE]: loadFailure(
    'devices',
    'employeeMessageChannelHistogram'
  ),

  // contact centers real-time
  [INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN]: openInsight,

  [INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE]:
    changeBusinessesSelection,

  [INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD]: load(
    'contactCentersWebConnectedUsers',
    'webConnectedUsers'
  ),
  [INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_FAILURE]: loadSectionRootFailure(
    'contactCentersWebConnectedUsers',
    'webConnectedUsers'
  ),
  [INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_SUCCESS]: loadSectionRootSuccess(
    'contactCentersWebConnectedUsers',
    'webConnectedUsers'
  ),

  // waiting customers

  [INSIGHT_WAITING_CUSTOMERS_OPEN]: openInsight,

  [INSIGHT_WAITING_CUSTOMERS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_WAITING_CUSTOMERS_CSV_LOAD]: (state) =>
    state.setIn(['waitingCustomers', 'waitingCustomersList', 'loadingCsv'], true),

  [INSIGHT_WAITING_CUSTOMERS_CSV_DOWNLOAD]: (state) =>
    state.setIn(['waitingCustomers', 'waitingCustomersList', 'loadingCsv'], false),

  [INSIGHT_WAITING_CUSTOMERS_LOAD]: load('waitingCustomers', 'waitingCustomersList'),
  [INSIGHT_WAITING_CUSTOMERS_LOAD_FAILURE]: loadSectionRootFailure(
    'waitingCustomers',
    'waitingCustomersList'
  ),
  [INSIGHT_WAITING_CUSTOMERS_LOAD_SUCCESS]: loadSectionRootSuccess(
    'waitingCustomers',
    'waitingCustomersList'
  ),

  // contact centers contacts
  [INSIGHT_CONTACT_CENTERS_CONTACTS_OPEN]: openInsight,

  [INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]:
    changeClassicTabDateAndPeriod,
  [INSIGHT_CONTACT_CENTERS_CONTACTS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]:
    changeCustomTabDatesAndPeriod,
  [INSIGHT_CONTACT_CENTERS_CONTACTS_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD]: load(
    'contactCentersContacts',
    'organizationContactHistogram'
  ),
  [INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_SUCCESS]: loadSuccess(
    'contactCentersContacts',
    'organizationContactHistogram'
  ),
  [INSIGHT_CONTACT_CENTERS_CONTACTS_ORGANIZATION_CONTACT_LOAD_FAILURE]: loadFailure(
    'contactCentersContacts',
    'organizationContactHistogram'
  ),

  // contact centers web aht
  [INSIGHT_CONTACT_CENTERS_WEB_AHT_OPEN]: openInsight,

  [INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]:
    changeClassicTabDateAndPeriod,
  [INSIGHT_CONTACT_CENTERS_WEB_AHT_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]:
    changeCustomTabDatesAndPeriod,
  [INSIGHT_CONTACT_CENTERS_WEB_AHT_BUSINESSES_SELECTION_CHANGE]: changeBusinessesSelection,

  [INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD]: load(
    'contactCentersWebAHT',
    'averageHandlingTimeHistogram'
  ),
  [INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_SUCCESS]: loadSuccess(
    'contactCentersWebAHT',
    'averageHandlingTimeHistogram'
  ),
  [INSIGHT_CONTACT_CENTERS_WEB_AHT_AVERAGE_HANDLING_TIME_LOAD_FAILURE]: loadFailure(
    'contactCentersWebAHT',
    'averageHandlingTimeHistogram'
  ),

  // contact centers users
  [INSIGHT_CONTACT_CENTERS_USERS_OPEN]: (state) =>
    openInsight(
      state.set('periodTabSelected', 'custom') // force "custom" tab because "classic" tab is not allowed for "Employees" insights
    ),
  [INSIGHT_CONTACT_CENTERS_USERS_OPEN_SUCCESS]: loadInsightSuccess,

  [INSIGHT_CONTACT_CENTERS_USERS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE]: changeClassicTabDateAndPeriod,
  [INSIGHT_CONTACT_CENTERS_USERS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE]: changeCustomTabDatesAndPeriod,
  [INSIGHT_CONTACT_CENTERS_USERS_BUSINESSES_SELECTION_CHANGE]: changeSectionBusiness(
    'contactCentersUsers',
    'averageEmployeeLoggedTime'
  ), // in fact, is related only to average employee logged time

  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD]: load(
    'contactCentersUsers',
    'activityPerBusiness'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_SUCCESS]: loadSuccess(
    'contactCentersUsers',
    'activityPerBusiness'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_LOAD_FAILURE]: loadFailure(
    'contactCentersUsers',
    'activityPerBusiness'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_BUSINESS_SHOW_MORE]: showMore(
    'contactCentersUsers',
    'activityPerBusiness'
  ),

  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD]: load(
    'contactCentersUsers',
    'activityPerEmployee'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_SUCCESS]: loadSuccess(
    'contactCentersUsers',
    'activityPerEmployee'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_LOAD_FAILURE]: loadFailure(
    'contactCentersUsers',
    'activityPerEmployee'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_ACTIVITY_PER_EMPLOYEE_SHOW_MORE]: showMore(
    'contactCentersUsers',
    'activityPerEmployee'
  ),

  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD]: load(
    'contactCentersUsers',
    'averageLoggedTimeHistogram'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_SUCCESS]: loadSuccessWithIndicators(
    'contactCentersUsers',
    'averageLoggedTimeIndicators',
    'averageLoggedTimeHistogram'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_LOGGED_TIME_LOAD_FAILURE]: loadFailure(
    'contactCentersUsers',
    'averageLoggedTimeHistogram'
  ),

  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_KEYWORD_CHANGE]: changeSectionKeyword(
    'contactCentersUsers',
    'averageEmployeeLoggedTime'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_RESULT_PAGE_CHANGE]:
    changeSectionResultPage('contactCentersUsers', 'averageEmployeeLoggedTime'),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_ROWS_PER_PAGE_CHANGE]:
    changeSectionRowsPerPage('contactCentersUsers', 'averageEmployeeLoggedTime'),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD]: load(
    'contactCentersUsers',
    'averageEmployeeLoggedTime'
  ),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_SUCCESS]:
    loadSuccessWithMetadata('contactCentersUsers', 'averageEmployeeLoggedTime'),
  [INSIGHT_CONTACT_CENTERS_USERS_AVERAGE_EMPLOYEE_LOGGED_TIME_LOAD_FAILURE]: loadFailure(
    'contactCentersUsers',
    'averageEmployeeLoggedTime'
  )
});
