import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import reduceReducers from 'reduce-reducers';
import errorsHandler from '../../../../shared/reducer/lib/errors-handler';
import {
  APP_TWILIO_TOKEN_LOAD,
  APP_TWILIO_TOKEN_LOAD_FAILURE,
  APP_TWILIO_TOKEN_LOAD_SUCCESS
} from '../../actions/app-actions';

export const DEFAULT_STATE = {
  loadingToken: false,
  token: null,
  errors: []
};

export default reduceReducers(
  createReducer(DEFAULT_STATE, {
    /**
     * Load token.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [APP_TWILIO_TOKEN_LOAD]: (state) => {
      return state.set('errors', fromJS([])).set('loadingToken', true);
    },

    /**
     * Load token failure.
     *
     * @param {Object} state
     * @returns {Object} new state
     */
    [APP_TWILIO_TOKEN_LOAD_FAILURE]: (state) => {
      return state.set('loadingToken', false);
    },

    /**
     * Load token success.
     *
     * @param {Object} state
     * @param {Object} data
     * @returns {Object} new state
     */
    [APP_TWILIO_TOKEN_LOAD_SUCCESS]: (state, {data}) => {
      return state.set('loadingToken', false).set('token', data.twilioToken);
    }
  }),
  errorsHandler
);
