export const COLLEAGUE_INBOX_LEAVE = 'COLLEAGUE_INBOX_LEAVE';

export const COLLEAGUE_INBOX_NEW_THREAD_CANCEL = 'COLLEAGUE_INBOX_NEW_THREAD_CANCEL';
export const COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE =
  'COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE';
export const COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_FAILURE =
  'COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_FAILURE';
export const COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET =
  'COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET';
export const COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_SUCCESS =
  'COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_SUCCESS';
export const COLLEAGUE_INBOX_NEW_THREAD_SHOW = 'COLLEAGUE_INBOX_NEW_THREAD_SHOW';
export const COLLEAGUE_INBOX_NEW_THREAD_START = 'COLLEAGUE_INBOX_NEW_THREAD_START';

export const COLLEAGUE_INBOX_THREADS_LIST_LOAD = 'COLLEAGUE_INBOX_THREADS_LIST_LOAD';
export const COLLEAGUE_INBOX_THREADS_LIST_LOAD_FAILURE =
  'COLLEAGUE_INBOX_THREADS_LIST_LOAD_FAILURE';
export const COLLEAGUE_INBOX_THREADS_LIST_LOAD_SUCCESS =
  'COLLEAGUE_INBOX_THREADS_LIST_LOAD_SUCCESS';

export const COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD =
  'COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD';
