import PropTypes from 'prop-types';

const InboxThreadListLayoutComponent = ({children}) => {
  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        minHeight: '1px',
        position: 'relative',
        width: '100%'
      }}
    >
      {children}
    </span>
  );
};

InboxThreadListLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default InboxThreadListLayoutComponent;
