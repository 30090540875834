import lowerFirst from 'lodash/lowerFirst';

const renameAttributeNameFromTopStorePayload = (dataKey) => {
  const regexToRemoveTopFromKey = /^top(.*)$/;

  const newKey = dataKey.match(regexToRemoveTopFromKey)[1];

  return lowerFirst(newKey);
};

const updateTopStorePayloadFromQueryResponse = (data) => {
  return Object.entries(data).reduce((initialTransformValue, currentTopStoreValue) => {
    const [dataKey, dataValue] = currentTopStoreValue;
    const newAttributeNameFromTopStorePayload = renameAttributeNameFromTopStorePayload(dataKey);

    return {...initialTransformValue, [newAttributeNameFromTopStorePayload]: dataValue};
  }, {});
};

export default updateTopStorePayloadFromQueryResponse;
