import {PureComponent} from 'react';
import context from '../../../../../../shared/component/context';
import DocumentationPanel from '../common/documentation-panel-component';
import DocumentationSection from '../common/documentation-section-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import SimpleContentHeader from '../../../common/content-header/simple-content-header-component';

class APISDKDocumentationComponent extends PureComponent {
  render() {
    const {i18n} = this.context;

    return (
      <RightPanelContentLayout>
        <SimpleContentHeader title={i18n.t('integrationArea.apiSdkDocumentation.title')} />

        <DocumentationPanel dataTestId="api-sdk-documentation-panel">
          <DocumentationSection
            description={i18n.t('integrationArea.apiSdkDocumentation.sdk.description')}
            linkDataTestId="sdk-ios-link"
            title={i18n.t('integrationArea.apiSdkDocumentation.sdk.title')}
            url="https://developers.instaply.com/sdk/ios"
          />
          <DocumentationSection
            description={i18n.t('integrationArea.apiSdkDocumentation.bot.description')}
            title={i18n.t('integrationArea.apiSdkDocumentation.bot.title')}
            url="https://developers.instaply.com/bot/api"
          />
          <DocumentationSection
            description={i18n.t('integrationArea.apiSdkDocumentation.graphql.description')}
            title={i18n.t('integrationArea.apiSdkDocumentation.graphql.title')}
            url="https://developers.instaply.com/graphql/accounts"
          />
          <DocumentationSection
            description={i18n.t('integrationArea.apiSdkDocumentation.ivr.description')}
            title={i18n.t('integrationArea.apiSdkDocumentation.ivr.title')}
            url="https://developers.instaply.com/IVR/IVR_Integration"
          />
        </DocumentationPanel>
      </RightPanelContentLayout>
    );
  }
}

APISDKDocumentationComponent.contextTypes = context;

export default APISDKDocumentationComponent;
