import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import AdministrationBusinessPageLayoutComponent from '../../../../component/content/administration/business/administration-business-layout-component';
import administrationBusinessMapDispatchToProps from '../lib/administration-business-map-dispatch-to-props';

const AdministrationBusinessPageContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.get('administrationBusiness'),
      (state) => state.get('featureToggle')
    ],
    (accountBusinesses, administrationBusiness, featureToggle) => ({
      accountBusinesses: accountBusinesses.toJS(),
      administrationBusiness: administrationBusiness.toJS(),
      enableBusinessMessages: featureToggle.get('enable_business_messages'),
      enableChannelAdmin: featureToggle.get('enable_channel_admin'),
      enablePaymentFeature: featureToggle.get('enable_payment_feature'),
      enableSurvey: featureToggle.get('survey')
    })
  ),
  administrationBusinessMapDispatchToProps
)(AdministrationBusinessPageLayoutComponent);

export default AdministrationBusinessPageContainer;
