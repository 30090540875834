import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InvisibleIcon from 'material-ui/svg-icons/action/visibility-off';
import VisibleIcon from 'material-ui/svg-icons/action/visibility';
import CappedBadge from '../../../common/capped-badge-component';
import {black, blue, mediumGreen, snow, white} from '../../../../../../shared/style/colors';

const IconButtonStyled = withStyles({
  root: {
    borderRadius: 0,
    height: '50px',
    position: 'relative',
    width: '50px'
  }
})(IconButton);

const CustomerThreadSidebarComponent = ({
  activeThreadType,
  doThreadSwitchVisibilityToType,
  isNewThread,
  unreadInternalMessages,
  unreadVisibleMessages
}) => {
  const isConversationVisible = activeThreadType === 'visible';

  const doShowVisibleThread = () => {
    doThreadSwitchVisibilityToType('visible');
  };

  const doShowInvisibleThread = () => {
    doThreadSwitchVisibilityToType('invisible');
  };

  const renderNotificationBadge = (number, backgroundColor) => (
    <CappedBadge
      data-test-id="notification"
      badgeStyle={{
        backgroundColor,
        color: white,
        padding: '1px 5px',
        position: 'absolute',
        right: '8px',
        top: '8px',
        border: 0
      }}
      {...{number}}
    />
  );

  return (
    <div>
      <IconButtonStyled
        data-test-id="visible-button"
        disabled={isNewThread}
        onClick={doShowVisibleThread}
        style={{
          background: isConversationVisible ? snow : 'none'
        }}
      >
        {unreadVisibleMessages ? renderNotificationBadge(unreadVisibleMessages, blue) : null}
        <VisibleIcon color={isConversationVisible ? blue : black} />
      </IconButtonStyled>
      <IconButtonStyled
        data-test-id="invisible-button"
        disabled={isNewThread}
        onClick={doShowInvisibleThread}
        style={{
          background: isConversationVisible ? 'none' : mediumGreen
        }}
      >
        {unreadInternalMessages
          ? renderNotificationBadge(unreadInternalMessages, mediumGreen)
          : null}
        <InvisibleIcon color={isConversationVisible ? black : white} />
      </IconButtonStyled>
    </div>
  );
};

CustomerThreadSidebarComponent.propTypes = {
  activeThreadType: PropTypes.string.isRequired,
  doThreadSwitchVisibilityToType: PropTypes.func.isRequired,
  isNewThread: PropTypes.bool.isRequired,
  unreadInternalMessages: PropTypes.number,
  unreadVisibleMessages: PropTypes.number
};

export default CustomerThreadSidebarComponent;
