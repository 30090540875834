import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Badge} from '@material-ui/core';
import Avatar from 'material-ui/Avatar';
import context from '../../../../../shared/component/context';
import propTypeOptionalUrl from '../../../../../shared/component/lib/prop-type-optional-url';
import OrganizationAvatar from './organization-avatar-component';
import {avatarSize} from '../../../../../shared/style/variables';

class UserAvatarComponent extends PureComponent {
  render() {
    const {
      dataTestId,
      isConnected,
      organizationIconSrc,
      organizationIconSize,
      organizationIconStyle,
      size,
      src,
      style,
      title,
      userIconStyle,
      ...others
    } = this.props;

    const USER_ICON_DEFAULT_PROPS = {
      'data-test-id': 'user-avatar',
      ...others
    };

    const organizationIcon = organizationIconSrc ? (
      <OrganizationAvatar
        dataTestId="user-organization-avatar"
        size={organizationIconSize}
        src={organizationIconSrc}
        style={{
          position: 'absolute',
          left: '-3px',
          bottom: '-3px',
          boxShadow: '0 0 2px rgba(0,0,0,.3)',
          borderRadius: '3px',
          backgroundPosition: 'cover',
          ...organizationIconStyle
        }}
      />
    ) : null;

    const userIcon = src ? (
      <Avatar
        style={userIconStyle}
        {...USER_ICON_DEFAULT_PROPS}
        {...{
          size,
          src
        }}
      />
    ) : (
      <img
        alt=""
        src={`${ASSETS_PATH}/img/user/user-avatar-default.png`}
        {...USER_ICON_DEFAULT_PROPS}
        style={{
          backgroundColor: 'white',
          borderRadius: '50%',
          width: size,
          height: size,
          ...userIconStyle
        }}
      />
    );

    const result = (
      <span
        data-test-id={dataTestId}
        {...{
          title
        }}
        style={{
          position: 'relative',
          display: 'flex',
          ...style
        }}
      >
        {userIcon} {organizationIcon}
      </span>
    );

    // this test is temporary, until we finish to migrate to material-ui v1
    if (typeof isConnected === 'undefined') {
      return result;
    }

    return (
      <Badge color="primary" invisible={!isConnected} variant="dot">
        {result}
      </Badge>
    );
  }
}

UserAvatarComponent.contextTypes = context;

UserAvatarComponent.defaultProps = {
  organizationIconSize: avatarSize.xxxsmall,
  size: 40
};

UserAvatarComponent.propTypes = {
  'data-test-id': PropTypes.string,
  dataTestId: PropTypes.string,
  isConnected: PropTypes.bool,
  organizationIconSize: PropTypes.number,
  organizationIconSrc: propTypeOptionalUrl,
  organizationIconStyle: PropTypes.objectOf(PropTypes.any),
  size: PropTypes.number,
  src: propTypeOptionalUrl,
  style: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  userIconStyle: PropTypes.objectOf(PropTypes.any)
};

export default UserAvatarComponent;
