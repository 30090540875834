import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import LegacyCustomerThreadComponent from '../../../../component/content/customer/thread/legacy-customer-thread-component';
import getSecuredThreadsForm from '../../../../lib/get-secured-threads-form';
import threadContainerSetup from '../../../../lib/thread-container-setup';
import messageSelector from '../../../../selector/message-selector';
import doRegisterTutorial from '../../../lib/actions/tutorials-dispatch-to-props';
import {LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE} from '../../../../actions/customer-new-thread-actions';
import {
  CUSTOMER_THREAD_PAGE_LEAVE,
  LEGACY_CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK,
  LEGACY_CUSTOMER_THREAD_CHANGED,
  LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE,
  LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE,
  LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE
} from '../../../../actions/customer-thread-actions';
import {
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK
} from '../../../../actions/customer-thread-app-payment-actions';
import {
  LEGACY_CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_APPS_AREA_MINIMIZE,
  LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT
} from '../../../../actions/customer-thread-apps-actions';
import {
  LEGACY_CUSTOMER_THREAD_RELEASE,
  LEGACY_CUSTOMER_THREAD_TAKE
} from '../../../../actions/customer-thread-assignment-actions';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_REMOVE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_UPDATE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_RETRY,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_UNSELECT_COLLEAGUE_TO_MENTION
} from '../../../../actions/customer-thread-invisible-actions';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_CLOSE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE
} from '../../../../actions/customer-thread-invisible-mention-popup-actions';
import {LEGACY_CUSTOMER_THREAD_PROFILE_SHOW} from '../../../../actions/customer-thread-profile-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE} from '../../../../actions/customer-thread-transfer-actions';
import {
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_REMOVE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
  LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK,
  LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_UPDATE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
  LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK,
  LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK
} from '../../../../actions/customer-thread-visible-actions';
import {GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP} from '../../../../actions/global-actions';
import {APP_THREAD_FILE_PREVIEW_OPEN} from '../../../../actions/thread-actions';

const getXsibleThreadProps = (visibility, threadState, xsibleState, participationId) => {
  return {
    direction: xsibleState.get('direction'),
    hasMoreMessagesAfter: xsibleState.get('hasMoreMessagesAfter'),
    hasMoreMessagesBefore: xsibleState.get('hasMoreMessagesBefore'),
    isFocused: threadState.get('focusedInput') === visibility,
    latestMessage: xsibleState.getIn(['messages', -1, 'id']),
    loading: xsibleState.get('loading'),
    loadingAfter: xsibleState.get('loadingAfter'),
    loadingBefore: xsibleState.get('loadingBefore'),
    messages: messageSelector(xsibleState),
    oldestMessage: xsibleState.getIn(['messages', 0, 'id']),
    sending: xsibleState.get('sending'),
    threadForm: getSecuredThreadsForm(xsibleState, participationId),
    uploadingFile: xsibleState.get('uploadingFile')
  };
};

/**
 * Finally, the container.
 */
const LegacyCustomerThreadContainer = connect(
  createSelector(
    [
      (state, router) => router,
      (state) => state.get('account'),
      (state) => state.get('customerThreadTransfer'),
      (state) => state.get('legacyCustomerThreadMention'),
      (state) => state.get('legacyCustomerProfile'),
      (state) => state.get('legacyCustomerThread'),
      (state) => state.getIn(['legacyCustomerThreadAppPayment', 'threadsForm']),
      (state) => state.get('legacyCustomerVisibleThread'),
      (state) => state.get('legacyCustomerInvisibleThread'),
      (state) => state.get('featureToggle'),
      (state) => state.get('ui')
    ],
    (
      router,
      account,
      customerThreadTransfer,
      legacyCustomerThreadMention,
      legacyCustomerProfile,
      legacyCustomerThread,
      legacyCustomerThreadAppPaymentThreadsForm,
      legacyCustomerVisibleThread,
      legacyCustomerInvisibleThread,
      featureToggle,
      ui
    ) => {
      const {isHistoryAction, isNewThread, participationId, route} = threadContainerSetup(router);
      const assignedTo = legacyCustomerVisibleThread.get('assignedTo');
      const status = legacyCustomerThread.get('status');

      return {
        isHistoryAction,
        isNewThread,
        participationId,
        status,
        cannedResponses: legacyCustomerThread.get('cannedResponses').toJS(),
        customerChannel: legacyCustomerThread.get('customerChannel'),
        directedToBusinessIdentifier: legacyCustomerThread.get('directedToBusinessIdentifier'),
        directedToBusinessName: legacyCustomerThread.get('directedToBusinessName'),
        disableThreadInputPersistence: featureToggle.get(
          'desktop_thread_input_persistence_disabled'
        ),
        enableCustomerProfile: featureToggle.get('customer_profile'),
        enablePaymentFeature: featureToggle.get('enable_payment_feature'),
        enableUIMessengerChannelFeature: featureToggle.get('enable_ui_messenger_channel'),
        enableUIWhatsAppChannelFeature: featureToggle.get('enable_ui_whatsapp_channel'),
        fullName: account.get('fullName'),
        isSmallScreen: ui.get('isSmallScreen'),
        isSmsPhoneNumber: legacyCustomerThread.get('isSmsPhoneNumber'),
        loading:
          legacyCustomerVisibleThread.get('loading') ||
          legacyCustomerInvisibleThread.get('loading'),
        newThreadEmail: route.params.email,
        newThreadPhoneNumber: route.params.phoneNumber,
        organizationId: account.get('organizationId'),
        organizationPicture: account.get('organizationPicture'),
        participantId: legacyCustomerThread.get('participantId'),
        participantName: legacyCustomerThread.get('participantName'),
        picture: account.get('picture'),
        readOnly: legacyCustomerThread.get('readOnly'),
        showCustomerProfile: legacyCustomerProfile.get('showCustomerProfile'),
        startNewThreadByUnknownContact: legacyCustomerThread.get('startNewThreadByUnknownContact'),
        threadType: legacyCustomerThread.get('threadType'),
        transferRecentlySuccessful: customerThreadTransfer.get('recentlySuccessful'),
        transferTransferring: customerThreadTransfer.get('transferring'),
        unreadInternalMessagesCount: legacyCustomerThread.get('unreadInternalMessagesCount'),
        unreadMessagesCount: legacyCustomerThread.get('unreadMessagesCount'),
        updatingStatus: legacyCustomerThread.get('updatingStatus'),
        userId: account.get('id'),
        whatsappTemplates: legacyCustomerVisibleThread.get('whatsappTemplates').toJS(),
        windowExpiration: legacyCustomerThread.get('windowExpiration'),
        invisibleProps: {
          participantCompany: legacyCustomerProfile.get('participantCompany'),
          mention: legacyCustomerThreadMention.toJS(),
          showMentionTooltip: legacyCustomerInvisibleThread.get('showMentionTooltip'),
          // Customer profile
          participantEmail: legacyCustomerProfile.get('participantEmail'),
          participantFirstName: legacyCustomerProfile.get('participantFirstName'),
          participantIcon: legacyCustomerProfile.get('participantIcon'),
          participantLastName: legacyCustomerProfile.get('participantLastName'),
          participantPhone: legacyCustomerProfile.get('participantPhone'),
          ...getXsibleThreadProps(
            'invisible',
            legacyCustomerThread,
            legacyCustomerInvisibleThread,
            participationId
          )
        },
        visibleProps: {
          assignedTo: assignedTo ? assignedTo.toJS() : null,
          deactivated: legacyCustomerVisibleThread.get('deactivated'),
          language: ui.get('language'),
          legacyCustomerThreadAppPayment:
            legacyCustomerThreadAppPaymentThreadsForm.toJS()[participationId],
          selectedFileExceedMaxSize: legacyCustomerVisibleThread.get('selectedFileExceedMaxSize'),
          showAssignment:
            featureToggle.get('assign_conversation_to_employee') &&
            !isNewThread &&
            !legacyCustomerVisibleThread.get('loading') &&
            status === 'waiting', // this last test should not be required, but we prefer to protect from wrong backend's data
          ...getXsibleThreadProps(
            'visible',
            legacyCustomerThread,
            legacyCustomerVisibleThread,
            participationId
          )
        }
      };
    }
  ),
  {
    doRegisterTutorial,
    doThreadVisiblePaginate(participationId, fromId, direction) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_PAGINATE,
        direction,
        fromId,
        participationId
      };
    },
    doCustomerThreadChanged(directedToBusinessIdentifier, participantId, status) {
      return {
        type: LEGACY_CUSTOMER_THREAD_CHANGED,
        oldThread: {
          directedToBusinessIdentifier,
          participantId,
          status
        }
      };
    },
    doThreadPageLeave(directedToBusinessIdentifier, participantId, status) {
      return {
        type: CUSTOMER_THREAD_PAGE_LEAVE,
        directedToBusinessIdentifier,
        participantId,
        status
      };
    },

    // transfer
    doTransferPopupToggle() {
      return {
        type: LEGACY_CUSTOMER_THREAD_TRANSFER_POPUP_TOGGLE,
        _googleAnalytics: true
      };
    },

    // status
    doUpdateManuallyThreadStatus(participationId, previousStatus, newStatus) {
      return {
        type: LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE,
        _googleAnalytics: true,
        newStatus,
        participationId,
        previousStatus
      };
    },

    // visible / invisible display
    doThreadSwitchVisibilityToType(participationId, threadType) {
      return {
        type: LEGACY_CUSTOMER_THREAD_SWITCH_VISIBILITY_TO_TYPE,
        _googleAnalytics: true,
        participationId,
        threadType
      };
    },
    doThreadFocusToType(threadType) {
      return {
        type: LEGACY_CUSTOMER_THREAD_FOCUS_TO_TYPE,
        threadType
      };
    },

    // colleague invitation
    doColleagueInvitationPopupOpen() {
      return {
        type: GLOBAL_COLLEAGUE_INVITATION_POPUP_OPEN_FROM_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP,
        _googleAnalytics: true
      };
    },

    // visible thread
    onWhatsappTemplatesLoadButtonClick(businessId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATES_LOAD_BUTTON_CLICK,
        _googleAnalytics: true,
        businessId
      };
    },
    onWhatsappTemplateClick(text) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_WHATSAPP_TEMPLATE_CLICK,
        _googleAnalytics: true,
        text
      };
    },
    doSendMessage({
      attachment,
      clientMessageId,
      fullName,
      organizationPicture,
      picture,
      status,
      text,
      userId
    }) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE,
        _googleAnalytics: true,
        attachment,
        clientMessageId,
        fullName,
        organizationPicture,
        picture,
        status,
        text,
        userId
      };
    },
    doThreadVisibleSendMessageRetry(failedAction) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_RETRY,
        _googleAnalytics: true,
        failedAction
      };
    },
    doThreadVisibleNewSendMessage(
      organizationPicture,
      businessId,
      email,
      phoneNumber,
      fullName,
      picture,
      clientMessageId,
      text,
      attachment
    ) {
      return {
        type: LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_SEND_MESSAGE,
        _googleAnalytics: true,
        attachment,
        businessId,
        clientMessageId,
        email,
        fullName,
        organizationPicture,
        phoneNumber,
        picture,
        text
      };
    },
    doThreadVisibleMessageFormTextUpdate(participationId, text) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_MESSAGE_FORM_TEXT_UPDATE,
        payload: {
          participationId,
          text
        }
      };
    },
    doThreadVisibleFileRemove() {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_REMOVE,
        _googleAnalytics: true
      };
    },
    onThreadVisibleFileSelect(file) {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECT,
        _googleAnalytics: true,
        file
      };
    },
    onThreadVisibleFileSelectedExceedMaxSizeClose() {
      return {
        type: LEGACY_CUSTOMER_THREAD_VISIBLE_FILE_SELECTED_EXCEED_MAX_SIZE_CLOSE_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    onThreadTakeButtonClick() {
      return {
        type: LEGACY_CUSTOMER_THREAD_TAKE,
        _googleAnalytics: true
      };
    },
    doReleaseCustomerThread(participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_RELEASE,
        _googleAnalytics: true,
        participationId
      };
    },

    // invisible thread
    doThreadInvisibleLoad(participationId, fromId, direction) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_LOAD,
        direction,
        fromId,
        participationId
      };
    },
    doThreadInvisibleFileRemove() {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_REMOVE,
        _googleAnalytics: true
      };
    },
    doThreadInvisibleFileUpload(file) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_FILE_UPLOAD,
        _googleAnalytics: true,
        file
      };
    },
    doThreadInvisibleSendMessage({
      attachment,
      clientMessageId,
      fullName,
      mentionedUser,
      organizationPicture,
      picture,
      text
    }) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE,
        _googleAnalytics: true,
        internal: true,
        attachment,
        clientMessageId,
        fullName,
        mentionedUser,
        organizationPicture,
        picture,
        text
      };
    },
    doThreadInvisibleSendMessageRetry(failedAction) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_SEND_MESSAGE_RETRY,
        _googleAnalytics: true,
        failedAction
      };
    },
    doThreadInvisibleMessageFormTextUpdate(participationId, text) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MESSAGE_FORM_TEXT_UPDATE,
        payload: {
          participationId,
          text
        }
      };
    },
    doThreadInvisibleMentionPopupSearch(businessId, text) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE,
        _googleAnalytics: true,
        businessId,
        text
      };
    },
    doThreadInvisibleMentionPopupSelect(user) {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SELECT_COLLEAGUE,
        _googleAnalytics: true,
        user
      };
    },
    doThreadInvisibleMentionUnselect() {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_UNSELECT_COLLEAGUE_TO_MENTION,
        _googleAnalytics: true
      };
    },
    doThreadInvisibleMentionPopupOpen() {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN,
        _googleAnalytics: true
      };
    },
    doThreadInvisibleMentionPopupClose() {
      return {
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_CLOSE,
        _googleAnalytics: true
      };
    },
    doThreadFilePreviewOpen(src) {
      return {
        type: APP_THREAD_FILE_PREVIEW_OPEN,
        _googleAnalytics: true,
        src
      };
    },

    // customer profile
    doCustomerProfileShow() {
      return {
        type: LEGACY_CUSTOMER_THREAD_PROFILE_SHOW,
        _googleAnalytics: true
      };
    },

    // apps
    doAppsAreaMenuButtonClick() {
      return {
        type: LEGACY_CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doAppsAreaMinimize(participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APPS_AREA_MINIMIZE,
        participationId
      };
    },
    doAppsMenuSelectPayment(participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT,
        _googleAnalytics: true,
        participationId
      };
    },
    doAppPaymentCancelButtonClick() {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CANCEL_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    doAppPaymentMaximizeButtonClick(participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MAXIMIZE_BUTTON_CLICK,
        _googleAnalytics: true,
        participationId
      };
    },
    doAppPaymentMinimizeButtonClick(participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_MINIMIZE_BUTTON_CLICK,
        _googleAnalytics: true,
        participationId
      };
    },
    doAppPaymentStepperBackButtonClick(participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_BACK_BUTTON_CLICK,
        _googleAnalytics: true,
        participationId
      };
    },
    doAppPaymentStepperNextButtonClick() {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_STEPPER_NEXT_BUTTON_CLICK,
        _googleAnalytics: true
      };
    },
    onAppPaymentAmountChange(amount, participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_AMOUNT_CHANGE,
        _googleAnalytics: true,
        amount,
        participationId
      };
    },
    onAppPaymentCustomChange(comment, participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOM_CHANGE,
        _googleAnalytics: true,
        comment,
        participationId
      };
    },
    onAppPaymentCustomerChange(customerData, participationId) {
      return {
        type: LEGACY_CUSTOMER_THREAD_APP_PAYMENT_CUSTOMER_CHANGE,
        _googleAnalytics: true,
        customerData,
        participationId
      };
    },
    onConversationNewConversationFormOpenLinkClick() {
      return {
        type: LEGACY_CUSTOMER_CONVERSATION_NEW_CONVERSATION_FORM_OPEN_LINK_CLICK,
        _googleAnalytics: true
      };
    }
  }
)(LegacyCustomerThreadComponent);

export default LegacyCustomerThreadContainer;
