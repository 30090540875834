import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {InputAdornment, TextField} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import themeV1 from '../../style/theme-v1';

class SearchFieldComponent extends PureComponent {
  render() {
    const {dataTestId, ...otherProps} = this.props;

    return (
      <MuiThemeProvider theme={themeV1}>
        <TextField
          type="search"
          InputProps={{
            inputProps: {
              'data-test-id': dataTestId
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          {...otherProps}
        />
      </MuiThemeProvider>
    );
  }
}

SearchFieldComponent.propTypes = {
  dataTestId: PropTypes.string.isRequired
};

export default SearchFieldComponent;
