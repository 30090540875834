import PropTypes from 'prop-types';
import AdministrationFormOverlay from './administration-form-overlay-component';
import {fontSize} from '../../../../../../shared/style/variables';
import {mediumGrey, red} from '../../../../../../shared/style/colors';

const FieldBlockComponent = ({
  children,
  description,
  disabled,
  errorMessage,
  label,
  subLabel,
  ...otherProps
}) => {
  const renderLabelAndSubLabel = () => (
    <div style={{alignItems: 'flex-end', display: 'flex', marginBottom: '6px'}}>
      <span dangerouslySetInnerHTML={{__html: label}} style={{fontWeight: 600}} />
      {subLabel ? (
        <span
          dangerouslySetInnerHTML={{__html: subLabel}}
          style={{
            fontSize: fontSize.small,
            marginLeft: '5px'
          }}
        />
      ) : null}
    </div>
  );

  const renderDescription = () => (
    <span
      dangerouslySetInnerHTML={{__html: description}}
      style={{
        color: mediumGrey,
        fontSize: fontSize.xsmall,
        marginBottom: '6px'
      }}
    />
  );

  const renderBottomSpaceAndError = () => (
    <span
      dangerouslySetInnerHTML={{__html: errorMessage}}
      data-test-id={errorMessage ? 'error-message' : null}
      style={{
        color: red,
        fontSize: fontSize.small,
        marginTop: '4px',
        minHeight: '12px'
      }}
    />
  );

  return (
    <div
      {...otherProps}
      style={{display: 'flex', flexDirection: 'column', paddingTop: '12px', position: 'relative'}}
    >
      {disabled ? <AdministrationFormOverlay /> : null}
      {renderLabelAndSubLabel()}
      {description ? renderDescription() : null}
      {children}
      {renderBottomSpaceAndError()}
    </div>
  );
};

FieldBlockComponent.propTypes = {
  children: PropTypes.node.isRequired,
  'data-test-id': PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string
};

export default FieldBlockComponent;
