import {fromEvent, merge} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import i18n from 'i18next';
import {filterByMultistoreConversationDisabled} from '../lib/feature-toggle-helper';
import {isBusinessInMyScope} from '../lib/customer-state-helper';
import {APP_BROWSER_NOTIFICATION_SHOW} from '../actions/app-actions';
import {
  LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS,
  LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED
} from '../actions/ext-actions';
import {BROWSER_NOTIFICATION_DEBOUNCE_TIME} from '../data/settings';

const LegacySocketEpic = (socketio) => (action$, state$) => {
  const legacyNotifyOnCustomerMessage = fromEvent(
    socketio,
    'customer_sent_message_to_organization'
  ).pipe(
    filter(filterByMultistoreConversationDisabled(state$)),
    debounceTime(BROWSER_NOTIFICATION_DEBOUNCE_TIME),
    map((message) => ({
      type: APP_BROWSER_NOTIFICATION_SHOW,
      message: i18n.t('app.browserNotification.unreadMessagesCustomers', {
        count: message.unreadCustomerThreadCount
      })
    }))
  );

  const legacyNotifyOnTransfer = fromEvent(
    socketio,
    'employee_transferred_customer_thread_to_business'
  ).pipe(
    filter((message) => message.employeeUserId != state$.value.getIn(['account', 'id'])), // eslint-disable-line eqeqeq
    filter(
      (message) =>
        !!state$.value
          .getIn(['account', 'businesses'])
          .filter((business) => business.get('businessId') == message.newBusinessId).size // eslint-disable-line eqeqeq
    ),
    debounceTime(BROWSER_NOTIFICATION_DEBOUNCE_TIME),
    map(() => ({
      type: APP_BROWSER_NOTIFICATION_SHOW,
      message: i18n.t('app.browserNotification.businessTransferred')
    }))
  );

  const legacySpreadEventNewMention = fromEvent(socketio, 'notification').pipe(
    filter(filterByMultistoreConversationDisabled(state$)),
    filter(() => state$.value.getIn(['featureToggle', 'notification']) === true),
    map(({unreadMentionsCount}) => ({
      type: LEGACY_EXT_MENTION_NOTIFICATION_NEW_ARRIVED,
      payload: {
        unreadMentionsCount
      }
    }))
  );

  const legacySpreadEventThreadRedirectedToBusiness = fromEvent(
    socketio,
    'thread_redirected_to_business'
  ).pipe(
    filter(filterByMultistoreConversationDisabled(state$)),
    map((payload) => ({
      type: LEGACY_EXT_CUSTOMER_THREAD_REDIRECTED_TO_BUSINESS,
      data: {
        newBusinessOutOfScope: !isBusinessInMyScope(
          state$.value.getIn(['account', 'businesses']),
          payload.newBusinessId
        ),
        payload
      }
    }))
  );

  return merge(
    legacyNotifyOnCustomerMessage,
    legacyNotifyOnTransfer,
    legacySpreadEventNewMention,
    legacySpreadEventThreadRedirectedToBusiness
  );
};

export default LegacySocketEpic;
