export const CUSTOMER_THREAD_APPS_AREA_MAXIMIZE = 'CUSTOMER_THREAD_APPS_AREA_MAXIMIZE';
export const CUSTOMER_THREAD_APPS_AREA_MINIMIZE = 'CUSTOMER_THREAD_APPS_AREA_MINIMIZE';

export const CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK =
  'CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK';

export const CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT = 'CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT';

// Legacy
export const LEGACY_CUSTOMER_THREAD_APPS_AREA_MAXIMIZE =
  'LEGACY_CUSTOMER_THREAD_APPS_AREA_MAXIMIZE';
export const LEGACY_CUSTOMER_THREAD_APPS_AREA_MINIMIZE =
  'LEGACY_CUSTOMER_THREAD_APPS_AREA_MINIMIZE';

export const LEGACY_CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK =
  'LEGACY_CUSTOMER_THREAD_APPS_AREA_MENU_BUTTON_CLICK';

export const LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT =
  'LEGACY_CUSTOMER_THREAD_APPS_MENU_SELECT_PAYMENT';
