import {debounceTime, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {DEBOUNCE_TIME_TYPING_THREAD} from '../../data/settings';

/**
 * Debounce a typing in a thread's action for few ms.
 *
 * @param {Object} action$
 * @param {String} typeTriggering
 * @param {String} typeTriggered
 * @returns {Observable}
 */
export default (action$, typeTriggering, typeTriggered) =>
  action$.pipe(
    ofType(typeTriggering),
    // wait X ms that there is no new triggered action, before triggering next action
    // do not set too slow (min 500 ms) as it may trigger a lot of re-rendering which could slow down the ui on some browser
    debounceTime(DEBOUNCE_TIME_TYPING_THREAD),
    map((action) => {
      // Always keep "type: typeTriggered" as last object entry
      return {
        ...action,
        type: typeTriggered
      };
    })
  );
