import cloneDeep from 'lodash/cloneDeep';

const paymentSteps = ['amount', 'customer', 'custom'];

const paymentStepsIndex = paymentSteps.reduce((stepsObject, stepName, stepIndex) => {
  const stepsObjectCopy = cloneDeep(stepsObject);
  stepsObjectCopy[stepName] = stepIndex;

  return stepsObjectCopy;
}, {});

export {paymentSteps, paymentStepsIndex};
