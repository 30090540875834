import forIn from 'lodash/forIn';
import startsWith from 'lodash/startsWith';
import isAuthenticated from './shared/lib/is-authenticated';
import start from './boot/start';
import topLevelRoutes from './boot/top-level-routes';
import {APP_HOME_URL, SIGN_IN_URL} from './shared/data/route';

const rootPath = window.location.pathname.split('/')[1];

if (Object.keys(topLevelRoutes).indexOf(rootPath) > -1) {
  start(rootPath);
} else {
  let newUrl = null;

  // Maintain compatibility with urls prior to december 2017
  const urlHash = window.location.hash;
  if (startsWith(urlHash, '#')) {
    const newSubUrl = urlHash.slice(2);

    const oldUrlMapping = {
      '^customer-thread': 'customers',
      '^colleague-thread': 'colleagues'
    };

    forIn(oldUrlMapping, (value, key) => {
      const keyRegex = new RegExp(key);

      if (keyRegex.test(newSubUrl)) {
        newUrl = newSubUrl.replace(keyRegex, value);
      }
    });
    newUrl = newUrl || newSubUrl;

    newUrl = `/app${newUrl ? `/${newUrl}` : ''}`;
  } else if (/\.(bmp|gif|jp?g|png|svg)$/i.test(window.location.pathname)) {
    // Handle wrong image call
    newUrl = `${ASSETS_PATH}/img/logo/instaply-bubble-text-horizontal-color.png`;
  }

  window.location = newUrl || (isAuthenticated() ? APP_HOME_URL : SIGN_IN_URL);
}
