import {merge, of} from 'rxjs';
import {catchError, delay, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {isCurrentEmployeeVerified} from '../../../lib/employee-helper';
import {
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_FAILURE,
  LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_SUCCESS,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECT,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_FAILURE,
  LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_SUCCESS
} from '../../../../actions/customer-thread-actions';
import {CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY} from '../../../../data/settings';

const CustomerThreadCompatibilityQueryConversationsEpic =
  ({query}) =>
  (action$, state$) => {
    const triggerConversationFromParticipationQuery = (actionFlowType) => {
      const actionsForCompatibilityQuery = {
        generation: {
          failure: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_FAILURE,
          load: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD,
          success: LEGACY_CUSTOMER_CONVERSATION_URL_GENERATION_COMPATIBILITY_QUERY_LOAD_SUCCESS
        },
        redirection: {
          failure: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_FAILURE,
          load: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD,
          success: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD_SUCCESS
        }
      }[actionFlowType];

      return action$.pipe(
        ofType(actionsForCompatibilityQuery.load),
        filter(isCurrentEmployeeVerified(state$, true)),
        mergeMap(({payload: {customer, participationId}}) => {
          const params = {participationId};

          return query('get-conversations-from-participation', params).pipe(
            map(({data: {customerId}}) => {
              return {
                type: actionsForCompatibilityQuery.success,
                payload: {
                  customer: {...customer, id: customerId}
                }
              };
            }),
            catchError((error) => {
              return of({
                type: actionsForCompatibilityQuery.failure,
                error
              });
            })
          );
        })
      );
    };

    const loadCompatibilityQueryConversations =
      triggerConversationFromParticipationQuery('redirection');

    const scheduleRetryLoadConversationsIfAppNotLoaded = action$.pipe(
      ofType(LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD),
      filter(isCurrentEmployeeVerified(state$, false)),
      delay(CUSTOMER_THREAD_COMPATIBILITY_QUERY_CONVERSATIONS_LOAD_RETRY_DELAY)
    );

    const loadCompatibilityQueryOnCustomerConversationUrlGeneration =
      triggerConversationFromParticipationQuery('generation');

    const triggerRedirectionCompatibilityQueryLoad = action$.pipe(
      ofType(LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECT),
      map(({payload}) => ({
        type: LEGACY_CUSTOMER_CONVERSATION_URL_REDIRECTION_COMPATIBILITY_QUERY_LOAD,
        payload
      }))
    );

    return merge(
      loadCompatibilityQueryConversations,
      loadCompatibilityQueryOnCustomerConversationUrlGeneration,
      scheduleRetryLoadConversationsIfAppNotLoaded,
      triggerRedirectionCompatibilityQueryLoad
    );
  };

export default CustomerThreadCompatibilityQueryConversationsEpic;
