import PropTypes from 'prop-types';
import AdministrationLeftMenuIcon from '../../../common/icon/administration-left-menu-icon';

const AdministrationLeftMenuBlockTitle = ({iconName, title}) => (
  <h4 style={{display: 'flex', marginBottom: '10px'}}>
    <AdministrationLeftMenuIcon iconName={iconName} />
    <span
      style={{
        fontSize: '13px',
        fontWeight: 'normal',
        lineHeight: '16px',
        paddingLeft: '10px',
        textTransform: 'uppercase'
      }}
    >
      {title}
    </span>
  </h4>
);

AdministrationLeftMenuBlockTitle.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default AdministrationLeftMenuBlockTitle;
