/**
 * Get a safe thread form.
 * @param {Immutable.Map} threadState
 * @param {String} conversationFormIdentifier ("conversationId" for multistore / "participationId" for legacy)
 * @returns {Object}
 */
export default (threadState, conversationFormIdentifier) => {
  const form = threadState.getIn(['threadsForm', conversationFormIdentifier]);

  return form ? form.toJS() : {};
};
