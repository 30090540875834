export default {
  canShowTutorial: ({
    customerChannel,
    enableUIWhatsAppChannelFeature,
    getTutorialIsShownFromLocalStorage
  }) => {
    if (customerChannel === 'WHATSAPP' && enableUIWhatsAppChannelFeature) {
      return getTutorialIsShownFromLocalStorage();
    }

    return false;
  },
  className: 'customer-thread-footer-whatsapp-permission',
  getConfig: (getCommonStepOptions, persistTutorialAsShownInLocalStorage) => ({
    options: {
      spotlightStyles: {
        borderRadius: 0
      }
    },
    persistPermanentlyAsShown: persistTutorialAsShownInLocalStorage,
    steps: [
      {
        ...getCommonStepOptions('app.tutorial.customerThread.footerWhatsappPermission'),
        spotlightPadding: 0
      }
    ]
  }),
  getLocalStorageKey: () => 'instaplyDesktop.tutorial.customer.thread.footer.whatsapp.permission',
  key: 'customerThreadFooterWhatsappPermission'
};
