import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Paper} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import themeV1 from '../style/theme-v1';

class PaperComponent extends PureComponent {
  render() {
    return (
      <MuiThemeProvider theme={themeV1}>
        <Paper {...this.props} />
      </MuiThemeProvider>
    );
  }
}

PaperComponent.defaultProps = {
  elevation: 1,
  square: true
};

PaperComponent.propTypes = {
  elevation: PropTypes.number,
  square: PropTypes.bool
};

export default PaperComponent;
