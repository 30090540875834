import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import SignUpComponent from '../component/signup-component';
import uiDispatchToProps from '../../../shared/container/ui-dispatch-to-props';
import {SIGNUP_GET_PENDING_INVITATION, SIGNUP_SUBMIT} from '../actions/signup-actions';

const SignUpContainer = connect(
  createSelector([(state) => state.get('signUp'), (state) => state.get('ui')], (signUp, ui) => {
    return {
      language: ui.get('language'),
      ...signUp.toJS()
    };
  }),
  {
    ...uiDispatchToProps,
    doGetPendingInvitation(token) {
      return {
        type: SIGNUP_GET_PENDING_INVITATION,
        _googleAnalytics: true,
        token
      };
    },
    doSubmitSignUp(email, firstName, lastName, locale, password, token, isFormValid) {
      return {
        type: SIGNUP_SUBMIT,
        _googleAnalytics: true,
        email,
        firstName,
        isFormValid,
        lastName,
        locale,
        password,
        token
      };
    }
  }
)(SignUpComponent);

export default SignUpContainer;
