import emojiMatchCharToAvoid from '../../../emoji/emoji-match-char-to-avoid';
import emojiRegex from '../../../emoji/emoji-regex';

// @see https://www.npmjs.com/package/find-with-regex
const findWithRegex = (contentBlock, callback) => {
  // Get the text from the contentBlock
  const text = contentBlock.getText();
  let matchArr;
  let start; // eslint-disable-line
  // Go through all matches in the text and return the indizes to the callback
  emojiRegex.lastIndex = 0;
  while ((matchArr = emojiRegex.exec(text)) !== null) { // eslint-disable-line
    if (emojiMatchCharToAvoid(matchArr[0])) {
      continue;
    }

    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

export default findWithRegex;
