import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import context from '../../../../../../../../shared/component/context';
import checkCustomAggregationPeriods from '../../../lib/check-custom-aggregation-period';
import FlatPickr from '../../../../../../../../shared/component/flatpickr/flatpickr-custom';
import getStyledIconButton from '../../../../../../../../shared/component/icons/icon-button';
import SubHeader from './sub-header-component';
import {STATISTICS_CUSTOM_PERIOD_CALENDAR_LIMIT} from '../../../../../../data/settings';
import {fontSize, spacing} from '../../../../../../../../shared/style/variables';
import {lightSlate} from '../../../../../../../../shared/style/colors';

const StyledExpandMoreIconContainer = getStyledIconButton({
  position: 'relative',
  top: '9px',
  marginLeft: spacing.large
});

const _getCheckPeriodsFromProps = (props) =>
  checkCustomAggregationPeriods(props.customPeriodStartDate, props.customPeriodEndDate);

const _getRangeForCalendar = (startDate, endDate) => [startDate.toDate(), endDate.toDate()];

/**
 * Finally, the component.
 */
class InsightSubHeaderCustomReportComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      periods: _getCheckPeriodsFromProps(props)
    };

    this._doChangeAggregationPeriod = this._doChangeAggregationPeriod.bind(this);
    this._onCalendarClose = this._onCalendarClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/destructuring-assignment */
    if (!prevProps.customPeriodStartDate && this.props.customPeriodStartDate) {
      this._updatePeriods();
    }
    /* eslint-enable react/destructuring-assignment */
  }

  _updatePeriods = () => {
    this.setState({
      periods: _getCheckPeriodsFromProps(this.props)
    });
  };

  /**
   * Apply a tooltip to all disabled days, with text varying according to the reason.
   */
  _applyDisabledTooltipToDays = (calendarInstance) => {
    const {i18n} = this.context;
    const today = moment().startOf('day');

    const children = calendarInstance.days.childNodes;

    for (let i = 0; i < children.length; i++) {
      const dayContainer = children[i];

      if (dayContainer.classList.contains('flatpickr-disabled')) {
        if (moment(dayContainer.getAttribute('data-date-iso')).isBefore(today)) {
          dayContainer.setAttribute(
            'data-title',
            i18n.t(
              'insight.contentHeader.periodSelector.customPeriod.dayDisabledTooltip.maximumSelectablePeriod'
            )
          );
        } else {
          dayContainer.setAttribute(
            'data-title',
            i18n.t(
              'insight.contentHeader.periodSelector.customPeriod.dayDisabledTooltip.cantSelectFutureDate'
            )
          );
        }

        dayContainer.classList.add('tooltip');
      }
    }
  };

  _doChangeAggregationPeriod(customAggregationPeriod) {
    const {customPeriodEndDate, customPeriodStartDate, doPeriodTabCustomChangeDatesAndPeriod} =
      this.props;

    doPeriodTabCustomChangeDatesAndPeriod(
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate
    );
  }

  _getDateFormattedBlock(dataTestId, customDate, labelPath, style) {
    const {i18n} = this.context;

    return (
      <div style={style}>
        <span
          style={{
            display: 'block',
            marginBottom: spacing.small,
            fontSize: fontSize.medium,
            color: lightSlate
          }}
        >
          {i18n.t(labelPath)}
        </span>
        <span
          data-test-id={dataTestId}
          style={{
            fontSize: fontSize.xxxlarge
          }}
        >
          {customDate
            ? i18n.t('insight.contentHeader.periodSelector.customPeriod.dateFormat', {customDate})
            : null}
        </span>
      </div>
    );
  }

  _onCalendarClose([calendarStartDate, calendarEndDate], dateStr, calendarInstance) {
    const {customPeriodStartDate, customPeriodEndDate} = this.props;

    // Always reset eventually disabled range
    // @todo replace by call to .set() when https://github.com/flatpickr/flatpickr/issues/2131 is fixed (IE11 issue)
    // eslint-disable-next-line no-param-reassign
    calendarInstance.config.enable = [];

    // No range selected
    if (!calendarStartDate || !calendarEndDate) {
      calendarInstance.setDate(_getRangeForCalendar(customPeriodStartDate, customPeriodEndDate));

      return;
    }

    // Selection didn't changed
    const newStartDate = moment(calendarStartDate);
    const newEndDate = moment(calendarEndDate);

    if (newStartDate.isSame(customPeriodStartDate) && newEndDate.isSame(customPeriodEndDate)) {
      return;
    }

    // Selection changed
    const {customAggregationPeriod, doPeriodTabCustomChangeDatesAndPeriod} = this.props;

    const checkedCustomAggregationPeriod = checkCustomAggregationPeriods(newStartDate, newEndDate);

    doPeriodTabCustomChangeDatesAndPeriod(
      checkedCustomAggregationPeriod.find(
        (period) => customAggregationPeriod === period.value && !period.forbidden
      )
        ? customAggregationPeriod
        : checkedCustomAggregationPeriod.find((period) => !period.forbidden).value, // take the lower allowed period
      newEndDate,
      newStartDate
    );

    this.setState({
      periods: checkedCustomAggregationPeriod
    });
  }

  render() {
    const {customAggregationPeriod, customPeriodEndDate, customPeriodStartDate} = this.props;
    const {periods} = this.state;

    return (
      <SubHeader
        aggregationPeriod={customAggregationPeriod}
        doChangeAggregationPeriod={this._doChangeAggregationPeriod}
        {...{
          periods
        }}
      >
        {this._getDateFormattedBlock(
          'start-date',
          customPeriodStartDate,
          'insight.contentHeader.periodSelector.customPeriod.startDate'
        )}
        {this._getDateFormattedBlock(
          'end-date',
          customPeriodEndDate,
          'insight.contentHeader.periodSelector.customPeriod.endDate',
          {marginLeft: spacing.large}
        )}

        {customPeriodStartDate ? (
          <FlatPickr
            options={{
              defaultDate: _getRangeForCalendar(customPeriodStartDate, customPeriodEndDate),
              maxDate: new Date(),
              mode: 'range',
              onClose: this._onCalendarClose,
              onChange: ([startDate, endDate], dateStr, calendarInstance) => {
                // Selection of 1st date
                if (!endDate) {
                  const selectedDateDay = moment(startDate).startOf('day');
                  // @todo replace by call to .set() when https://github.com/flatpickr/flatpickr/issues/2131 is fixed (IE11 issue)
                  // eslint-disable-next-line no-param-reassign
                  calendarInstance.config.enable = [
                    {
                      from: selectedDateDay
                        .clone()
                        .subtract(STATISTICS_CUSTOM_PERIOD_CALENDAR_LIMIT, 'days')
                        .toDate(),
                      to: selectedDateDay
                        .add(STATISTICS_CUSTOM_PERIOD_CALENDAR_LIMIT, 'days')
                        .toDate()
                    }
                  ];

                  // Is necessary to apply tooltip again, because Flatpickr re-render everything after 1st date selection
                  this._applyDisabledTooltipToDays(calendarInstance);
                }
              },
              onDayCreate: (selectedDates, dateStr, calendarInstance, dayContainer) => {
                dayContainer.setAttribute('data-date-iso', dayContainer.dateObj.toISOString());
              },
              onOpen: (selectedDates, dateStr, calendarInstance) =>
                this._applyDisabledTooltipToDays(calendarInstance),
              onMonthChange: (selectedDates, dateStr, calendarInstance) =>
                this._applyDisabledTooltipToDays(calendarInstance),
              onYearChange: (selectedDates, dateStr, calendarInstance) =>
                this._applyDisabledTooltipToDays(calendarInstance)
            }}
            render={({defaultValue}, ref) => (
              <StyledExpandMoreIconContainer
                data-test-id="expand"
                disabled={!customPeriodStartDate}
                {...{
                  defaultValue,
                  ref
                }}
              >
                <ExpandMoreIcon />
              </StyledExpandMoreIconContainer>
            )}
          />
        ) : null}
      </SubHeader>
    );
  }
}

InsightSubHeaderCustomReportComponent.contextTypes = context;

InsightSubHeaderCustomReportComponent.propTypes = {
  customAggregationPeriod: PropTypes.string.isRequired,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightSubHeaderCustomReportComponent;
