import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import getInsightsMapDispatchToProps from './lib/get-insights-map-dispatch-to-props';
import InsightPreferredChannelComponent from '../../../../component/content/insights/page/insight-preferred-channel-component';

const InsightPreferredChannelContainer = connect(
  createSelector(
    [
      (state) => state.get('account'),
      (state) => state.get('insight'),
      (state) => state.get('insightBusinessFilter')
    ],
    (account, insight, insightBusinessFilter) => {
      return {
        accountBusinesses: account.get('businesses').toJS(),
        businessesSelection: insight.get('businessesSelection').toJS(),
        classicAggregationDate: insight.get('classicAggregationDate'),
        classicAggregationPeriod: insight.get('classicAggregationPeriod'),
        customAggregationPeriod: insight.get('customAggregationPeriod'),
        customPeriodEndDate: insight.get('customPeriodEndDate'),
        customPeriodStartDate: insight.get('customPeriodStartDate'),
        insight: insight.get('preferredChannel').toJS(),
        insightBusinessFilter: insightBusinessFilter.toJS(),
        managerOfABusiness: account.get('managerOfABusiness'),
        organizationManager: account.get('manager'),
        periodTabSelected: insight.get('periodTabSelected')
      };
    }
  ),
  getInsightsMapDispatchToProps('preferredChannel')
)(InsightPreferredChannelComponent);

export default InsightPreferredChannelContainer;
