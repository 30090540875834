import PropTypes from 'prop-types';
import FileDownloadIcon from '../../../../../../../shared/component/icons/file-download-icon';
import LoadingIndicator from '../../../../../../../shared/component/loading-indicator-component';
import StandardButton from '../../../../../../../shared/component/button/standard-button-component';

const InsightContactCentersRealtimeHeaderComponent = ({doInsightDownloadCsv, i18n, loadingCsv}) => (
  <div style={{marginBottom: '16px'}}>
    {loadingCsv ? (
      <LoadingIndicator size={12} style={{justifyContent: 'normal'}} />
    ) : (
      <StandardButton
        data-test-id="download-csv-button"
        onClick={doInsightDownloadCsv}
        padding="noPadding"
        variant="outlined"
        startIcon={<FileDownloadIcon />}
      >
        {i18n.t('insight.pages.waitingCustomers.downloadCsvLabel')}
      </StandardButton>
    )}
  </div>
);

InsightContactCentersRealtimeHeaderComponent.propTypes = {
  doInsightDownloadCsv: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingCsv: PropTypes.bool.isRequired
};

export default InsightContactCentersRealtimeHeaderComponent;
