import {merge} from 'rxjs';
import {delay, filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-thread-visible-actions';
import {LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS} from '../../../../actions/customer-new-thread-actions';
import {CUSTOMER_THREAD_CANNED_RESPONSE_LOAD_DELAY} from '../../../../data/settings';
import {CUSTOMER_THREAD_CANNED_RESPONSES_LOAD} from '../../../../actions/customer-thread-canned-responses-actions';

const LegacyCustomerThreadCannedResponsesEpic = () => (action$) => {
  const legacyLoadCannedResponsesOnLoadNewVisibleThreadSuccess = action$.pipe(
    ofType(LEGACY_CUSTOMER_NEW_THREAD_VISIBLE_LOAD_SUCCESS),
    delay(CUSTOMER_THREAD_CANNED_RESPONSE_LOAD_DELAY),
    map(({data}) => ({
      type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
      data
    }))
  );

  const legacyLoadCannedResponsesOnLoadExistingVisibleLegacyThreadSuccess = action$.pipe(
    ofType(LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS),
    filter(({data: {hasCannedResponses, readOnly}}) => hasCannedResponses && !readOnly),
    delay(CUSTOMER_THREAD_CANNED_RESPONSE_LOAD_DELAY),
    map(({data}) => ({
      type: CUSTOMER_THREAD_CANNED_RESPONSES_LOAD,
      data
    }))
  );

  return merge(
    legacyLoadCannedResponsesOnLoadExistingVisibleLegacyThreadSuccess,
    legacyLoadCannedResponsesOnLoadNewVisibleThreadSuccess
  );
};

export default LegacyCustomerThreadCannedResponsesEpic;
