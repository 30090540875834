import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import MobileWidgetComponent from '../../../../../component/content/integration-area/page/widgets/mobile-widget-component';
import {
  INTEGRATION_AREA_WIDGET_MOBILE_BACKGROUND_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_CUSTOM_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_PHONE_NUMBER_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_RESET_STATE,
  INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_MOBILE_TEXT_COLOR_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';

const MobileWidgetContainer = connect(
  createSelector(
    [
      (state) => state.getIn(['account', 'businesses']),
      (state) => state.getIn(['integrationArea', 'mobile'])
    ],
    (businesses, mobile) => {
      return {
        businessesList: businesses.filter((business) => business.get('hasPhoneNumber')).toJS(),
        ...mobile.toJS()
      };
    }
  ),
  {
    doChangeLanguage(language) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_LANGUAGE_CHANGE,
        language
      };
    },
    doChangePhoneNumber(phoneNumber) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_PHONE_NUMBER_CHANGE,
        phoneNumber
      };
    },
    doChangeButtonText(text) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_CUSTOM_TEXT_CHANGE,
        text
      };
    },
    doChangeBackgroundColor(color) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_BACKGROUND_COLOR_CHANGE,
        color
      };
    },
    doChangeTextColor(color) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_TEXT_COLOR_CHANGE,
        color
      };
    },
    doResetState() {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_RESET_STATE
      };
    },
    doCopyCode(copied) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_COPIED_CHANGE,
        _googleAnalytics: true,
        copied
      };
    },
    doUpdateSnippet(snippet) {
      return {
        type: INTEGRATION_AREA_WIDGET_MOBILE_SNIPPET_CHANGE,
        snippet
      };
    }
  }
)(MobileWidgetComponent);

export default MobileWidgetContainer;
