import {ignoreElements, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';

const downloadFileFromUrl = (action$, actionName) => {
  return action$.pipe(
    ofType(actionName),
    map(({url}) => {
      window.location = url;

      return null;
    }),
    ignoreElements()
  );
};
export default downloadFileFromUrl;
