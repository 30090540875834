import {PureComponent} from 'react';
import context from '../../../../../../shared/component/context';
import DocumentationPanel from '../common/documentation-panel-component';
import DocumentationSection from '../common/documentation-section-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import SimpleContentHeader from '../../../common/content-header/simple-content-header-component';

class ThirdPartyComponent extends PureComponent {
  render() {
    const {i18n} = this.context;

    return (
      <RightPanelContentLayout>
        <SimpleContentHeader title={i18n.t('integrationArea.thirdPartyIntegrations.title')} />

        <DocumentationPanel dataTestId="third-party-integrations-panel">
          <DocumentationSection
            description={i18n.t('integrationArea.thirdPartyIntegrations.facebookDescription')}
            linkDataTestId="facebook-link"
            title="Facebook"
            url="https://developers.instaply.com/docs/3rd-partys-integrations/facebook"
          />
          <DocumentationSection
            description={i18n.t('integrationArea.thirdPartyIntegrations.instagramDescription')}
            title="Instagram"
            url="https://developers.instaply.com/docs/3rd-partys-integrations/instagram"
          />
          <DocumentationSection
            description={i18n.t('integrationArea.thirdPartyIntegrations.googleAdsDescription')}
            title="Google Ads"
            url="https://developers.instaply.com/docs/3rd-partys-integrations/google-ads"
          />
        </DocumentationPanel>
      </RightPanelContentLayout>
    );
  }
}

ThirdPartyComponent.contextTypes = context;

export default ThirdPartyComponent;
