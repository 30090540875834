import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import ColleagueInboxStartNewThreadFormComponent from './colleague-inbox-start-new-thread-form-component';
import ColleagueInboxStartNewThreadSearchList from './colleague-inbox-start-new-thread-search-list-component';

class ColleagueStartNewThreadComponent extends PureComponent {
  render() {
    const {
      edges,
      loading,
      text,
      doCancelStartNewThreadWithColleague,
      doColleagueInvitationPopupOpen,
      doSearchColleagueToStartThread,
      doSearchColleagueToStartThreadInputReset,
      doSelectItemOnColleagueSearch
    } = this.props;

    return (
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%' // required for IE 11
        }}
      >
        <ColleagueInboxStartNewThreadFormComponent
          searchInputText={text}
          {...{
            doCancelStartNewThreadWithColleague,
            doSearchColleagueToStartThread,
            doSearchColleagueToStartThreadInputReset
          }}
        />

        <ColleagueInboxStartNewThreadSearchList
          doSelectItem={doSelectItemOnColleagueSearch}
          {...{
            edges,
            loading,
            text,
            doCancelStartNewThreadWithColleague,
            doColleagueInvitationPopupOpen
          }}
        />
      </span>
    );
  }
}

ColleagueStartNewThreadComponent.contextTypes = context;

ColleagueStartNewThreadComponent.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  doCancelStartNewThreadWithColleague: PropTypes.func.isRequired,
  doColleagueInvitationPopupOpen: PropTypes.func.isRequired,
  doSearchColleagueToStartThread: PropTypes.func.isRequired,
  doSearchColleagueToStartThreadInputReset: PropTypes.func.isRequired,
  doSelectItemOnColleagueSearch: PropTypes.func.isRequired
};

export default ColleagueStartNewThreadComponent;
