import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import context from '../context';
import {lightSlate} from '../../style/colors';

class FormFieldsContainerComponent extends PureComponent {
  render() {
    const {i18n} = this.context;
    const {children, showRequired} = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{display: 'flex', flexDirection: 'column'}}>{children}</div>

        {showRequired ? (
          <div style={{marginTop: '30px', color: lightSlate}}>{i18n.t('form.requiredFields')}</div>
        ) : null}
      </div>
    );
  }
}

FormFieldsContainerComponent.contextTypes = context;

FormFieldsContainerComponent.defaultProps = {
  showRequired: true
};

FormFieldsContainerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  showRequired: PropTypes.bool
};

export default FormFieldsContainerComponent;
