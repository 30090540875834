import React from 'react';
import PropTypes from 'prop-types';
import {
  americanSilver,
  antiFlashWhite,
  brightNavyBlue,
  platinum,
  white
} from '../../../../../../../../../shared/style/colors';
import {fontWeight} from '../../../../../../../../../shared/style/variables';

const AdministrationOrganizationChannelsGbmPreviewComponent = ({
  previewProps,
  i18n,
  logoPreview
}) => {
  const previewCameraIcon = `${ASSETS_PATH}/img/icon/channel/preview/CAMERA.svg`;
  const previewSendIcon = `${ASSETS_PATH}/img/icon/channel/preview/SEND.svg`;

  const commonLogoPreviewStyles = {
    borderRadius: '150px',
    height: '36px',
    margin: '30px auto 0 auto',
    width: '36px'
  };

  const commonMessageLogoPreviewStyles = {
    alignSelf: 'flex-end',
    borderRadius: '150px',
    height: '18px',
    marginBottom: '3px',
    width: '18px'
  };

  return (
    <div
      data-test-id="create-agent-preview"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '450px',
        marginLeft: '40px',
        maxWidth: '250px',
        position: 'relative',
        top: '30px',
        width: '100%'
      }}
    >
      <span style={{fontWeight: fontWeight.semiBold}}>
        {i18n.t('administration.organization.channels.flows.gbm.steps.agentForm.preview.title')}
      </span>
      <div
        style={{
          border: `1px solid ${platinum}`,
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
          display: 'flex',
          flexDirection: 'column',
          height: '450px',
          marginTop: '6px'
        }}
      >
        {logoPreview ? (
          <img
            data-test-id="logo-preview"
            src={logoPreview}
            style={commonLogoPreviewStyles}
            alt="preview-logo"
          />
        ) : (
          <div
            data-test-id="default-logo-preview"
            style={{
              backgroundColor: americanSilver,
              ...commonLogoPreviewStyles
            }}
          />
        )}

        <p
          data-test-id="brand-name-preview"
          style={{
            fontSize: '12px',
            fontWeight: fontWeight.normal,
            marginTop: '4px',
            textAlign: 'center'
          }}
        >
          {previewProps.brandName}
        </p>
        <div style={{display: 'flex', padding: '0 22px 0 10px'}}>
          {logoPreview ? (
            <img
              src={logoPreview}
              style={commonMessageLogoPreviewStyles}
              alt="preview-message-logo"
            />
          ) : (
            <div
              style={{
                backgroundColor: americanSilver,
                ...commonMessageLogoPreviewStyles
              }}
            />
          )}

          <div style={{display: 'flex', flexDirection: 'column', width: '200px'}}>
            <p
              data-test-id="brand-name-small-label-preview"
              style={{fontSize: '8px', margin: '15px 0 0 11px'}}
            >
              {previewProps.brandName}
            </p>
            <div
              data-test-id="welcome-message-preview"
              style={{
                backgroundColor: antiFlashWhite,
                borderRadius: '10px',
                fontSize: '10px',
                lineHeight: '11px',
                margin: '1px 8px 1px 3px',
                padding: '8px',
                wordBreak: 'break-all'
              }}
            >
              {previewProps.welcomeMessage}
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: 'end',
            backgroundColor: brightNavyBlue,
            borderRadius: '30px',
            color: white,
            fontSize: '10px',
            lineHeight: '11px',
            margin: '10px 16px 1px 3px',
            padding: '8px',
            textAlign: 'center',
            width: '50px'
          }}
        >
          {i18n.t(
            'administration.organization.channels.flows.gbm.steps.agentForm.preview.staticMessage'
          )}
        </div>

        <div
          style={{
            bottom: '0',
            display: 'flex',
            marginBottom: '15px',
            marginTop: '78px',
            padding: '0 12px 0 8px',
            position: 'absolute'
          }}
        >
          <img src={previewCameraIcon} alt="preview-camera-icon" width={32} height={32} />
          <img
            src={previewSendIcon}
            style={{marginLeft: '6px', width: '190px'}}
            alt="preview-send-icon"
          />
        </div>
      </div>
    </div>
  );
};

AdministrationOrganizationChannelsGbmPreviewComponent.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  logoPreview: PropTypes.string,
  previewProps: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdministrationOrganizationChannelsGbmPreviewComponent;
