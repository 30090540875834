import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import context from '../../../../../../shared/component/context';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import TableRanked from '../common/table/table-ranked-component';
import TABLES_DEFINITION from '../../../../data/insights/top-stores-data';

const INSIGHT_PAGE = 'topStores';

const TABLES_DEFINITION_LAST_INDEX = TABLES_DEFINITION.length - 1;

/**
 * Finally, the component.
 */
class InsightTopStoresComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      classicAggregationDate,
      classicAggregationPeriod,
      insight,
      periodTabSelected,
      doInsightSortButtonClick,
      doPeriodTabClassicChangeDateAndPeriod
    } = this.props;

    const {topStores} = insight;
    const {loadingCsv} = topStores;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          downloadCsvLabelTranslation={i18n.t(`insight.pages.${INSIGHT_PAGE}.downloadCsvLabel`)}
          {...{
            classicAggregationDate,
            classicAggregationPeriod,
            loadingCsv,
            periodTabSelected,
            doPeriodTabClassicChangeDateAndPeriod
          }}
          insightDownloadCsv={this._doInsightDownloadCsv}
          insightSortRankings={doInsightSortButtonClick}
          showDownloadCsvButton
          showCsvButton
          showSortButton
        />

        <PanelContentLayout>
          {Object.entries(TABLES_DEFINITION).map(([tableName, {valueType}], index) => {
            const item = topStores[tableName];

            return (
              <TableRanked
                dataTestId={`${kebabCase(tableName)}-table`}
                columns={['businessName', 'increment', 'ranking', 'value']}
                failed={item.failed}
                isLast={TABLES_DEFINITION_LAST_INDEX === index}
                items={item.data}
                key={tableName}
                loading={item.loading}
                translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.tables.${tableName}`, {
                  returnObjects: true
                })}
                {...{
                  valueType
                }}
              />
            );
          })}
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightTopStoresComponent.contextTypes = context;

InsightTopStoresComponent.propTypes = {
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightSortButtonClick: PropTypes.func.isRequired
};

export default InsightTopStoresComponent;
