import {merge, of} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import definitions from './lib/graph-definitions';
import downloadFileFromUrl from '../../../../../shared/lib/download-file-from-url';
import {getFilterInsightsCanShowRankingTable} from '../../../lib/insights/insights-helper';
import {loadGraphInformation, loadInitialInsights} from './lib/prepare-graph-fill';
import prepareRequestParams from './lib/prepare-request-params';
import triggerLoadTablesAction from './lib/trigger-table-ranking-actions';
import updateSortDirection from './lib/update-sort-direction';
import updateTopStorePayloadFromQueryResponse from './lib/transform-top-store-response';
import {
  INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONVERSATIONS_OPEN,
  INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD,
  INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD,
  INSIGHT_CONVERSATIONS_RANKING_SORT_BUTTON_CLICK,
  INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE,
  INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION
} from '../../../actions/insight-actions';
import {TIMEOUT_REQUEST_LONG} from '../../../../../shared/data/settings';
import {DEBOUNCE_TIME_STATISTIC} from '../../../data/settings';

const rankingStateParamName = 'conversations';

/**
 * Finally, the epic.
 */
const InsightConversationsEpic =
  ({graphql}) =>
  (action$, state$) => {
    const {actionRankingTypes, actionTypes, epicName, query, sortBy} = definitions.conversations;
    const [loadAction, successAction, failureAction] = actionTypes;
    const [loadRankingAction, successRankingAction, failureRankingAction] = actionRankingTypes;

    const loadInsights = loadInitialInsights(action$, state$, {
      actionsName: [
        INSIGHT_CONVERSATIONS_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_CONVERSATIONS_OPEN,
        INSIGHT_CONVERSATIONS_TAB_CLASSIC_DATE_AND_PERIOD_CHANGE,
        INSIGHT_CONVERSATIONS_TAB_CUSTOM_DATES_AND_PERIOD_CHANGE
      ],
      loadAction,
      loadRankingAction,
      rankingStateParamName
    });

    const handleClickSortDirection = action$.pipe(
      ofType(INSIGHT_CONVERSATIONS_RANKING_SORT_BUTTON_CLICK),
      map(() => {
        const storedSortDirection = state$.value.getIn([
          'insight',
          'conversations',
          'ranking',
          'sortedDirection'
        ]);

        return {
          type: INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION,
          sortDirection: updateSortDirection(storedSortDirection)
        };
      })
    );

    const updateSortInsights = action$.pipe(
      ofType(INSIGHT_CONVERSATIONS_UPDATE_SORT_DIRECTION),
      map(() => triggerLoadTablesAction(state$, {loadRankingAction, rankingStateParamName}))
    );

    const loadConversationsGraphs = loadGraphInformation(action$, state$, graphql, {
      customResponsePayload: 'conversationsPageHistogram',
      query,
      loadAction,
      failureAction,
      successAction,
      DEBOUNCE_TIME_STATISTIC,
      TIMEOUT_REQUEST_LONG
    });

    const loadConversationsRankingTable = action$.pipe(
      ofType(loadRankingAction),
      filter(getFilterInsightsCanShowRankingTable(state$)),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(
        ({
          businessesSelection,
          classicAggregationDate,
          classicAggregationPeriod,
          customAggregationPeriod,
          customPeriodEndDate,
          customPeriodStartDate,
          sortedDirection
        }) =>
          graphql(
            `query { topStores { ${epicName} { businessId businessName increment isBusinessInUserScope ranking value } } }`,
            prepareRequestParams(state$, {
              businessesSelection,
              classicAggregationDate,
              classicAggregationPeriod,
              customAggregationPeriod,
              customPeriodEndDate,
              customPeriodStartDate,
              forceStartDate: true,
              getAll: true,
              orderBy: [{field: sortBy, order: sortedDirection}]
            }),
            {timeout: TIMEOUT_REQUEST_LONG}
          ).pipe(
            map(({topStores}) => ({
              type: successRankingAction,
              ranking: updateTopStorePayloadFromQueryResponse(topStores)
            })),
            catchError((error) =>
              of({
                type: failureRankingAction,
                error
              })
            )
          )
      )
    );

    const downloadConversationsCsv = downloadFileFromUrl(
      action$,
      INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD
    );

    const loadConversationsCsvToDownload = action$.pipe(
      ofType(INSIGHT_CONVERSATIONS_RANKING_CSV_LOAD),
      mergeMap(({classicAggregationDate, classicAggregationPeriod}) =>
        graphql(
          'get-conversations-ranking-report-query',
          prepareRequestParams(state$, {
            classicAggregationDate,
            classicAggregationPeriod,
            forceStartDate: true
          }),
          {timeout: TIMEOUT_REQUEST_LONG}
        ).pipe(
          map(({conversationsRankingReport}) => ({
            type: INSIGHT_CONVERSATIONS_RANKING_CSV_DOWNLOAD,
            url: conversationsRankingReport.url
          }))
          // @todo handle failure
        )
      )
    );

    return merge(
      downloadConversationsCsv,
      handleClickSortDirection,
      loadConversationsCsvToDownload,
      loadConversationsGraphs,
      loadConversationsRankingTable,
      loadInsights,
      updateSortInsights
    );
  };

export default InsightConversationsEpic;
