import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import {Device} from 'twilio-client';
import context from '../../../../../shared/component/context';
import debug from '../../../../../shared/lib/debug';
import DialogIncomingCall from './dialog-incoming-call-component';
import SwitchLight from '../../../../../shared/component/form/switch-light-component';
import {DEBOUNCE_TIME_TWILIO_TOKEN_RELOAD} from '../../../data/settings';
import {blue, darkSmoke, white} from '../../../../../shared/style/colors';

const SWITCH_ICON_STYLE = {
  padding: '2px',
  borderRadius: '10px',
  fontSize: '16px',
  color: white
};

let twilioComponent;

/**
 * Finally, the component.
 *
 * @see https://www.twilio.com/docs/voice/client/javascript/device
 * @see https://www.twilio.com/docs/voice/client/javascript/connection
 */
class TwilioComponent extends PureComponent {
  constructor(props) {
    super(props);

    twilioComponent = this;

    this.state = {
      connection: null,
      connectionFrom: null,
      connectionStatus: null,
      deviceEventsDefined: false,
      deviceStatus: 'offline',
      renewingExpiredToken: false,
      switchChecked: false,
      switching: false
    };

    this._onCallAccept = this._onCallAccept.bind(this);
    this._onCallDecline = this._onCallDecline.bind(this);
    this._onCallEnd = this._onCallEnd.bind(this);
    this._onSwitchChange = this._onSwitchChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.twilio.loadingToken && !this.props.twilio.loadingTokenn) {
      this._setupTwilio();
    }
  }

  _renderTooltipTitle = () => {
    const {i18n} = this.context;
    const {deviceStatus, renewingExpiredToken, switching} = this.state;

    return deviceStatus === 'offline' && !switching && !renewingExpiredToken
      ? i18n.t('app.twilio.switch.tooltip.clickToEnable')
      : '';
  };

  _onCallAccept() {
    const {connection} = this.state;
    debug('Accepting call...');

    connection.accept();
  }

  _onCallDecline() {
    const {connection} = this.state;
    debug('Declining call...');

    connection.reject(); // was preferred to .ignore()
  }

  _onCallEnd() {
    const {connection} = this.state;
    debug('Ending call...');

    connection.disconnect();
  }

  _onSwitchChange(event) {
    const {doTwilioTokenLoad} = this.props;
    const switchChecked = event.target.checked;

    this.setState(
      {
        switchChecked,
        switching: true
      },
      () => {
        if (switchChecked) {
          doTwilioTokenLoad();
        } else {
          twilioComponent.setState(
            {
              deviceEventsDefined: false
            },
            () => Device.destroy()
          );
        }
      }
    );
  }

  _setupTwilio() {
    const {twilio} = this.props;
    const {deviceEventsDefined} = this.state;
    if (!deviceEventsDefined) {
      Device.on('offline', () => {
        debug('Twilio.Device > Offline');

        const {switching} = twilioComponent.state;

        twilioComponent.setState(
          {
            deviceStatus: 'offline',
            ...(switching
              ? {
                  renewingExpiredToken: false,
                  switching: false
                }
              : {
                  renewingExpiredToken: true
                })
          },
          () => {
            if (twilioComponent.state.renewingExpiredToken) {
              setTimeout(() => {
                // by security we check that, meanwhile timeout, the user didn't disabled manually its connection
                if (twilioComponent.state.renewingExpiredToken) {
                  twilioComponent.props.doTwilioTokenLoad();
                }
              }, DEBOUNCE_TIME_TWILIO_TOKEN_RELOAD);
            }
          }
        );
      });

      Device.on('ready', () => {
        debug('Twilio.Device > Ready!');

        twilioComponent.setState({
          deviceStatus: 'ready',
          renewingExpiredToken: false,
          switching: false
        });
      });

      // Device vs Connection handling
      Device.on('cancel', () => {
        debug('Twilio.Device > Call cancelled by caller.');

        twilioComponent.setState({
          connectionFrom: null
        });
      });

      Device.on('connect', (connection) => {
        debug('Twilio.Device > Successfully established call!');

        twilioComponent.setState({
          connectionStatus: connection.status(),
          deviceStatus: 'busy'
        });
      });

      Device.on('disconnect', () => {
        debug('Twilio.Device > Call ended.');

        twilioComponent.setState({
          connectionFrom: null,
          deviceStatus: Device.status()
        });
      });

      Device.on('incoming', (connection) => {
        debug(`Twilio.Device > Incoming connection from ${connection.parameters.From}`);

        connection.on('reject', () => {
          debug('Twilio Connection > The call was rejected');

          twilioComponent.setState({
            connectionFrom: null
          });
        });

        twilioComponent.setState({
          connection,
          connectionFrom: connection.parameters.From,
          connectionStatus: connection.status()
        });
      });
    }

    this.setState(
      {
        deviceEventsDefined: true
      },
      () => Device.setup(twilio.token)
    );
  }

  render() {
    const {i18n} = this.context;
    const {connectionFrom, connectionStatus, deviceStatus, switchChecked, switching} = this.state;

    return (
      <span>
        <Tooltip
          title={
            Device.isSupported
              ? this._renderTooltipTitle()
              : i18n.t('app.twilio.switch.tooltip.notSupported')
          }
        >
          <SwitchLight
            data-test-id="twilio-switch"
            checkedIcon={
              <CallIcon
                style={{
                  backgroundColor: deviceStatus === 'ready' ? blue : darkSmoke,
                  ...SWITCH_ICON_STYLE
                }}
              />
            }
            data-checked={switchChecked || undefined}
            disabled={!Device.isSupported || switching}
            icon={<CallIcon style={{backgroundColor: darkSmoke, ...SWITCH_ICON_STYLE}} />}
            onChange={this._onSwitchChange}
          />
        </Tooltip>
        <DialogIncomingCall
          onAccept={this._onCallAccept}
          onDecline={this._onCallDecline}
          onEnd={this._onCallEnd}
          {...{
            connectionFrom,
            connectionStatus
          }}
        />
      </span>
    );
  }
}

TwilioComponent.contextTypes = context;

TwilioComponent.propTypes = {
  twilio: PropTypes.objectOf(PropTypes.any).isRequired,
  doTwilioTokenLoad: PropTypes.func.isRequired
};

export default TwilioComponent;
