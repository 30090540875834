import PropTypes from 'prop-types';
import context from '../../../../../../shared/component/context';
import HistogramChart from '../common/chart/histogram-chart-component';
import InsightContentHeader from '../common/layout/content-header-component';
import InsightPageComponent from '../common/insight-page-component';
import InsightPanelContentBlock from '../common/layout/insight-panel-content-block-component';
import PanelContentLayout from '../../../common/layout/panel-content-layout-component';
import RightPanelContentLayout from '../../../common/right-panel-content-layout-component';
import {
  graphBlue2Main,
  graphBlue4Main,
  graphGreen,
  graphGreenArea,
  graphRedArea,
  graphRedMain,
  graphYellow,
  graphYellowArea
} from '../../../../../../shared/style/colors';
import {generateChartTitle} from '../lib/chart-title-generator';

const INSIGHT_PAGE = 'messages';

/**
 * Finally, the component.
 */
class InsightMessagesComponent extends InsightPageComponent {
  render() {
    const {i18n} = this.context;
    const {
      accountBusinesses,
      businessesSelection,
      classicAggregationDate,
      classicAggregationPeriod,
      customAggregationPeriod,
      customPeriodEndDate,
      customPeriodStartDate,
      insight,
      insightBusinessFilter,
      periodTabSelected,
      doInsightBusinessesSelectionChangeFromPage,
      doInsightBusinessFilterBusinessesSearch,
      doPeriodTabChange,
      doPeriodTabClassicChangeDateAndPeriod,
      doPeriodTabCustomChangeDatesAndPeriod
    } = this.props;

    const isClassicPeriod = periodTabSelected === 'classic';

    const {
      messagesSentHistogram,
      messagesSentIndicators: {totalNumberOfMessages, totalNumberOfMessagesIncrement}
    } = insight;

    const {loadingCsv} = insight.messages;

    return (
      <RightPanelContentLayout>
        <InsightContentHeader
          domain={INSIGHT_PAGE}
          downloadCsvLabelTranslation={i18n.t(`insight.pages.${INSIGHT_PAGE}.downloadCsvLabel`)}
          {...{
            accountBusinesses,
            businessesSelection,
            classicAggregationDate,
            classicAggregationPeriod,
            customAggregationPeriod,
            customPeriodEndDate,
            customPeriodStartDate,
            insightBusinessFilter,
            loadingCsv,
            periodTabSelected,
            doInsightBusinessesSelectionChangeFromPage,
            doInsightBusinessFilterBusinessesSearch,
            doPeriodTabChange,
            doPeriodTabClassicChangeDateAndPeriod,
            doPeriodTabCustomChangeDatesAndPeriod
          }}
          insightDownloadCsv={this._doInsightDownloadCsv}
          showDownloadCsvButton
          showCsvButton
          showSortButton={false}
        />

        <PanelContentLayout>
          <InsightPanelContentBlock>
            <HistogramChart
              dataTestId="messages-sent-histogram-chart"
              aggregationPeriod={
                periodTabSelected === 'custom' ? customAggregationPeriod : classicAggregationPeriod
              }
              title={generateChartTitle({
                figure: totalNumberOfMessages,
                hasData: messagesSentHistogram.items.length,
                label: i18n.t(`insight.pages.${INSIGHT_PAGE}.messagesSent.title`),
                showVariation: isClassicPeriod,
                variation: totalNumberOfMessagesIncrement
              })}
              translations={i18n.t(`insight.pages.${INSIGHT_PAGE}.messagesSent.chart`, {
                returnObjects: true
              })}
              {...messagesSentHistogram}
              series={[
                {
                  property: 'customerToOrganizationMessageCount',
                  color: graphBlue2Main,
                  fillColor: graphBlue4Main
                },
                {
                  property: 'employeeToCustomerMessageCount',
                  color: graphGreen,
                  fillColor: graphGreenArea
                },
                {
                  property: 'automaticMessageCount',
                  color: graphYellow,
                  fillColor: graphYellowArea
                },
                {
                  property: 'apiMessageCount',
                  color: graphRedMain,
                  fillColor: graphRedArea
                }
              ]}
            />
          </InsightPanelContentBlock>
        </PanelContentLayout>
      </RightPanelContentLayout>
    );
  }
}

InsightMessagesComponent.contextTypes = context;

InsightMessagesComponent.propTypes = {
  accountBusinesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  classicAggregationDate: PropTypes.objectOf(PropTypes.any),
  classicAggregationPeriod: PropTypes.string,
  customAggregationPeriod: PropTypes.string,
  customPeriodEndDate: PropTypes.objectOf(PropTypes.any),
  customPeriodStartDate: PropTypes.objectOf(PropTypes.any),
  insight: PropTypes.objectOf(PropTypes.any).isRequired,
  insightBusinessFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  periodTabSelected: PropTypes.string.isRequired,
  doInsightBusinessesSelectionChangeFromPage: PropTypes.func.isRequired,
  doInsightBusinessFilterBusinessesSearch: PropTypes.func.isRequired,
  doInsightDownloadCsv: PropTypes.func.isRequired,
  doInsightPageOpen: PropTypes.func.isRequired,
  doPeriodTabChange: PropTypes.func.isRequired,
  doPeriodTabClassicChangeDateAndPeriod: PropTypes.func.isRequired,
  doPeriodTabCustomChangeDatesAndPeriod: PropTypes.func.isRequired
};

export default InsightMessagesComponent;
