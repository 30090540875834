import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

const ThreadCustomBusinessTabsScrollButtonsComponent = ({direction, disabled, onClick}) => {
  const containerStyleButton = {
    alignSelf: 'center',
    borderRadius: '100%',
    boxShadow: '0px 5px 11px -3px',
    margin: '0 15px',
    padding: '6px'
  };

  const renderScrollButton = () => {
    const iconScrollButton = {
      left: <ArrowBackIosOutlinedIcon fontSize="small" />,
      right: <ArrowForwardIosOutlinedIcon fontSize="small" />
    }[direction];

    if (disabled) {
      return null;
    }

    return (
      <IconButton aria-label="close" onClick={onClick} style={containerStyleButton}>
        {iconScrollButton}
      </IconButton>
    );
  };

  return renderScrollButton() || null;
};

ThreadCustomBusinessTabsScrollButtonsComponent.propTypes = {
  direction: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ThreadCustomBusinessTabsScrollButtonsComponent;
