import {PureComponent} from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ListItem} from 'material-ui/List';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import context from '../../../../../shared/component/context';
import canSeeThreadsFromMoreThanOneBusiness from '../../../lib/can-see-threads-from-more-than-one-business';
import EnhancedSwitch from '../../common/enhanced-switch-component';
import ContentLayout from '../../common/layout/content-layout-component';
import EmptyAreaWithLogo from '../../common/empty-area-with-logo-component';
import LeftPanelWithMenuLayout, {
  LEFT_LIST_ITEM_STYLE
} from '../../common/layout/left-panel-with-menu-layout-component';
import LoadingIndicator from '../../../../../shared/component/loading-indicator-component';
import RightPanelLayout from '../../common/layout/right-panel-layout-component';
import InsightContactCentersContactsContainer from '../../../container/content/insights/page/contact-centers/insight-contacts-container';
import InsightContactCentersWebConnectedUsersContainer from '../../../container/content/insights/page/contact-centers/insight-web-connected-users-container';
import InsightContactCentersUsersContainer from '../../../container/content/insights/page/contact-centers/insight-users-container';
import InsightContactCentersWebAHTContainer from '../../../container/content/insights/page/contact-centers/insight-web-aht-container';
import InsightDevicesContainer from '../../../container/content/insights/page/insight-devices-container';
import InsightMessagesContainer from '../../../container/content/insights/page/insight-messages-container';
import InsightNewCustomersContainer from '../../../container/content/insights/page/insight-new-customers-container';
import InsightNewInboundConversationsContainer from '../../../container/content/insights/page/insight-new-inbound-conversations-container';
import InsightNewOutboundConversationsContainer from '../../../container/content/insights/page/insight-new-outbound-conversations-container';
import InsightPreferredChannelContainer from '../../../container/content/insights/page/insight-preferred-channel-container';
import InsightResponseRateContainer from '../../../container/content/insights/page/insight-response-rate-container';
import InsightSatisfactionContainer from '../../../container/content/insights/page/insight-satisfaction-container';
import InsightConversationsContainer from '../../../container/content/insights/page/insight-conversations-container';
import InsightContactsContainer from '../../../container/content/insights/page/insight-contacts-container';
import InsightConnectedCustomersContainer from '../../../container/content/insights/page/insight-connected-customers-container';
import InsightResponseTimeContainer from '../../../container/content/insights/page/insight-response-time-container';
import InsightTopStoresContainer from '../../../container/content/insights/page/insight-top-stores-container';
import InsightWaitingCustomersContainer from '../../../container/content/insights/page/insight-waiting-customers-container';
import {fontSize} from '../../../../../shared/style/variables';

const INSIGHTS_DOMAIN = [
  {name: 'conversations'},
  {name: 'responseRate'},
  {name: 'responseTime'},
  {name: 'waitingCustomers'},
  {name: 'newInboundConversations'},
  {name: 'newOutboundConversations'},
  {name: 'newCustomers'},
  {name: 'contacts'},
  {name: 'connectedCustomers'},
  {name: 'topStores'},
  {name: 'messages'},
  {name: 'devices'},
  {name: 'satisfaction'},
  {name: 'preferredChannel'},
  {
    name: 'contactCenters',
    labelPath: 'insight.leftMenu.contactCenters',
    items: [
      {name: 'contactCentersWebConnectedUsers'},
      {name: 'contactCentersContacts'},
      {name: 'contactCentersWebAHT'},
      {name: 'contactCentersUsers'}
    ]
  }
];

const MIGRATED_STATS_OPTIONS = {
  conversations: 'summary',
  connectedCustomers: 'summary',
  responseTime: 'summary',
  waitingCustomers: 'contactCentersRealTime',
  newInboundConversations: 'summary',
  newOutboundConversations: 'summary',
  contacts: 'summary',
  contactCentersWebConnectedUsers: 'contactCentersRealTime',
  contactCentersUsers: 'contactCentersRealTime'
};

/**
 * Finally, the component.
 */
class InsightsComponent extends PureComponent {
  constructor(props) {
    super(props);

    const firstLevelDomainsCount = INSIGHTS_DOMAIN.length - 1;

    this.state = {
      defaultPage: kebabCase(
        this._filterDisabledDomains(INSIGHTS_DOMAIN.slice(0, firstLevelDomainsCount)).concat(
          this._filterDisabledDomains(INSIGHTS_DOMAIN[firstLevelDomainsCount].items)
        )[0].name
      )
    };
  }

  componentDidMount() {
    const {isInsightBusinessesSelectionInitialized, doInsightBusinessesSelectionInitialize} =
      this.props;

    if (!isInsightBusinessesSelectionInitialized) {
      doInsightBusinessesSelectionInitialize();
    }
  }

  _renderInsightRoutes = () => {
    const {i18n} = this.context;
    const {businessesSelection, match} = this.props;
    const {defaultPage} = this.state;

    const baseUrl = match.url;

    return businessesSelection.length > 0 ? (
      <EnhancedSwitch>
        <Redirect from={baseUrl} exact to={`${baseUrl}/${defaultPage}`} />
        <Route
          path={`${baseUrl}/contact-centers-contacts`}
          exact
          component={InsightContactCentersContactsContainer}
        />
        <Route
          path={`${baseUrl}/contact-centers-web-connected-users`}
          exact
          component={InsightContactCentersWebConnectedUsersContainer}
        />
        <Route
          path={`${baseUrl}/contact-centers-users`}
          exact
          component={InsightContactCentersUsersContainer}
        />
        <Route
          path={`${baseUrl}/contact-centers-web-aht`}
          exact
          component={InsightContactCentersWebAHTContainer}
        />
        <Route path={`${baseUrl}/contacts`} exact component={InsightContactsContainer} />
        <Route path={`${baseUrl}/conversations`} exact component={InsightConversationsContainer} />
        <Route
          path={`${baseUrl}/connected-customers`}
          exact
          component={InsightConnectedCustomersContainer}
        />
        <Route path={`${baseUrl}/devices`} exact component={InsightDevicesContainer} />
        <Route path={`${baseUrl}/messages`} exact component={InsightMessagesContainer} />
        <Route path={`${baseUrl}/new-customers`} exact component={InsightNewCustomersContainer} />
        <Route
          path={`${baseUrl}/new-inbound-conversations`}
          exact
          component={InsightNewInboundConversationsContainer}
        />
        <Route
          path={`${baseUrl}/new-outbound-conversations`}
          exact
          component={InsightNewOutboundConversationsContainer}
        />
        <Route
          path={`${baseUrl}/preferred-channel`}
          exact
          component={InsightPreferredChannelContainer}
        />
        <Route path={`${baseUrl}/response-rate`} exact component={InsightResponseRateContainer} />
        <Route path={`${baseUrl}/response-time`} exact component={InsightResponseTimeContainer} />
        <Route path={`${baseUrl}/satisfaction`} exact component={InsightSatisfactionContainer} />
        <Route path={`${baseUrl}/top-stores`} exact component={InsightTopStoresContainer} />
        <Route
          path={`${baseUrl}/waiting-customers`}
          exact
          component={InsightWaitingCustomersContainer}
        />
        {/* @todo in the future remove this redirections from migrated routes */}
        <Redirect
          from={`${baseUrl}/contact-centers-employees`}
          exact
          to={`${baseUrl}/contact-centers-users`}
        />
        <Redirect
          from={`${baseUrl}/contact-centers-real-time`}
          exact
          to={`${baseUrl}/contact-centers-web-connected-users`}
        />
        <Redirect from={`${baseUrl}/summary`} exact to={`${baseUrl}/conversations`} />
      </EnhancedSwitch>
    ) : (
      <EmptyAreaWithLogo
        dataTestId="no-business-to-select"
        label={i18n.t('insight.content.noBusinessToSelect')}
      />
    );
  };

  _renderList(domains, style) {
    const {i18n} = this.context;
    const {match, push} = this.props;

    return this._filterDisabledDomains(domains).map((domain) => {
      const domainName = domain.name;
      const kebabDomain = kebabCase(domainName);
      const {labelPath} = domain;
      const itemCommonProps = {
        'data-test-id': `${kebabDomain}-menu-item`
      };

      if (labelPath) {
        return (
          <ListItem
            {...itemCommonProps}
            key={domainName}
            nestedItems={this._renderList(domain.items, {fontSize: fontSize.large})}
            primaryTogglesNestedList
            style={LEFT_LIST_ITEM_STYLE}
          >
            {i18n.t(labelPath)}
          </ListItem>
        );
      }

      const value = `${match.url}/${kebabDomain}`;

      return (
        <ListItem
          {...itemCommonProps}
          key={domainName}
          onClick={() => push(value)}
          value={value}
          style={{
            ...LEFT_LIST_ITEM_STYLE,
            ...style
          }}
        >
          {i18n.t(`insight.pages.${domainName}.titleLeftMenu`)}
        </ListItem>
      );
    });
  }

  _filterDisabledDomains(domains) {
    const {account, featureToggles} = this.props;

    return domains.filter((domain) => {
      const domainName = MIGRATED_STATS_OPTIONS[domain.name] || domain.name;

      return (
        !featureToggles[`statistics_disable_menu_${snakeCase(domainName)}`] &&
        (!['preferredChannel', 'satisfaction'].includes(domainName) || featureToggles.survey) &&
        (domainName !== 'contactCentersWebAHT' || featureToggles.thread_activity) &&
        (domainName !== 'responseRate' || featureToggles.statistics_enable_response_rate) &&
        (domainName !== 'topStores' || canSeeThreadsFromMoreThanOneBusiness(account))
      );
    });
  }

  render() {
    const {i18n} = this.context;
    const {isInsightBusinessesSelectionInitialized, path} = this.props;

    return (
      <ContentLayout>
        <LeftPanelWithMenuLayout path={path} title={i18n.t('header.insights')}>
          {this._renderList(INSIGHTS_DOMAIN)}
        </LeftPanelWithMenuLayout>

        <RightPanelLayout>
          {isInsightBusinessesSelectionInitialized ? (
            this._renderInsightRoutes()
          ) : (
            <LoadingIndicator overlay />
          )}
        </RightPanelLayout>
      </ContentLayout>
    );
  }
}

InsightsComponent.contextTypes = context;

InsightsComponent.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  businessesSelection: PropTypes.arrayOf(PropTypes.any),
  featureToggles: PropTypes.objectOf(PropTypes.any).isRequired,
  isInsightBusinessesSelectionInitialized: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  doInsightBusinessesSelectionInitialize: PropTypes.func.isRequired
};

export default InsightsComponent;
