import {merge} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {filterByMultistoreConversationDisabled} from '../../../../lib/feature-toggle-helper';
import {
  CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW,
  CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK,
  LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../../actions/customer-inbox-actions';
import {LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS} from '../../../../actions/customer-thread-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS} from '../../../../actions/customer-thread-visible-actions';

const LegacyCustomerInboxEpic = () => (action$, state$) => {
  const notifyEmployeeWhenHisActionChangesThreadStatus = action$.pipe(
    ofType(
      LEGACY_CUSTOMER_THREAD_STATUS_MANUAL_UPDATE_SUCCESS,
      LEGACY_CUSTOMER_THREAD_VISIBLE_SEND_MESSAGE_SUCCESS
    ),
    filter(
      ({clientMessageId, newStatus, previousStatus}) =>
        (clientMessageId && previousStatus === 'waiting') || // current employee successfully sent a message into a "waiting" visible conversation
        (newStatus === 'replied' && previousStatus !== 'replied') // current employee successfully updated manually the conversation status
    ),
    map(() => ({
      type: CUSTOMER_INBOX_SNACK_NOTIFICATION_SHOW
    }))
  );

  const selectThreadOnInboxThreadsListItemClick = action$.pipe(
    ofType(CUSTOMER_INBOX_THREADS_LIST_THREAD_CLICK),
    filter(filterByMultistoreConversationDisabled(state$)),
    map(
      ({
        payload: {
          company,
          directedToBusinessName,
          participantIcon,
          participantName,
          participationId,
          readOnly,
          status
        }
      }) => ({
        type: LEGACY_CUSTOMER_INBOX_THREADS_LIST_SELECT_THREAD,
        company,
        directedToBusinessName,
        participantIcon,
        participantName,
        participationId,
        readOnly,
        status
      })
    )
  );

  return merge(
    notifyEmployeeWhenHisActionChangesThreadStatus,
    selectThreadOnInboxThreadsListItemClick
  );
};

export default LegacyCustomerInboxEpic;
