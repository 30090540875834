import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {legacyUpdateSelectedParticipation} from '../../lib/update-selected-participation';
import {
  COLLEAGUE_INBOX_LEAVE,
  COLLEAGUE_INBOX_NEW_THREAD_CANCEL,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_SUCCESS,
  COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET,
  COLLEAGUE_INBOX_NEW_THREAD_SHOW,
  COLLEAGUE_INBOX_THREADS_LIST_LOAD,
  COLLEAGUE_INBOX_THREADS_LIST_LOAD_SUCCESS,
  COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD
} from '../../../actions/colleague-inbox-actions';
import {
  COLLEAGUE_NEW_THREAD_LOAD,
  COLLEAGUE_THREAD_LEAVE,
  COLLEAGUE_THREAD_LOAD,
  COLLEAGUE_THREAD_LOAD_SUCCESS
} from '../../../actions/colleague-thread-actions';

export const DEFAULT_STATE = {
  loading: false,
  selected: null,
  showStartNewThread: false,
  edges: [],
  newThread: {
    loading: false,
    text: '',
    edges: []
  },
  pageInfo: {
    hasNextPage: false
  }
};

export default createReducer(DEFAULT_STATE, {
  /**
   * Reset state when leaving an inbox
   * in order to prevent badges from not being correctly updated
   *
   * @returns {Object} new state
   */
  [COLLEAGUE_INBOX_LEAVE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  /**
   * Reset selected thread when leaving thread component
   * in order to prevent badges from not being correctly updated
   *
   * @param {Object} state
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LEAVE]: (state) => {
    return state.set('selected', null);
  },

  /**
   * Prepare load of colleague inbox threads
   *
   * @param {Object} state
   * @param {String} after
   * @param {Boolean} refreshAll
   * @returns {Map} new state
   */
  [COLLEAGUE_INBOX_THREADS_LIST_LOAD]: (state, {after, refreshAll}) => {
    const newState = state.set('loading', !refreshAll);

    if (after || refreshAll) {
      return newState;
    }

    return newState.set('edges', fromJS([]));
  },

  /**
   * Successful load of colleague inbox threads
   *
   * @param {Object} state
   * @param {Object} data
   * @param {Object} params
   * @param {Boolean} refreshAll
   * @returns {Map} new state
   */
  [COLLEAGUE_INBOX_THREADS_LIST_LOAD_SUCCESS]: (state, {data, params, refreshAll}) => {
    let edges = fromJS(data.edges);

    if (!refreshAll && params.slice && params.slice.after) {
      edges = state.get('edges').concat(edges);
    } else {
      const selected = state.get('selected');
      if (selected) {
        // Prevent counters to be > 0 on EXT_COLLEAGUE_SENT_MESSAGE_TO_ME if socket mark-as-read is not finished
        edges = edges.map((edge) =>
          selected == edge.getIn(['node', 'participationId']) // eslint-disable-line eqeqeq
            ? edge.update('node', (node) => node.set('unreadMessagesCount', 0))
            : edge
        );
      }
    }

    return state.set('edges', edges).set('loading', false).set('pageInfo', fromJS(data.pageInfo));
  },

  /**
   * Select a colleague thread
   *
   * @param {Object} state
   * @param {String} participationId
   * @returns {Map} new state
   */
  [COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD]: legacyUpdateSelectedParticipation,

  /**
   * Update the selected participation on loading colleague thread
   *
   * @param {Object} state
   * @param {String} participationId
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LOAD]: legacyUpdateSelectedParticipation,

  /**
   * Reset counter on colleague load thread success
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LOAD_SUCCESS]: (state, {data}) => {
    const index = state
      .get('edges')
      .findIndex((edge) => edge.getIn(['node', 'participationId']) == data.id); // eslint-disable-line eqeqeq

    if (index === -1) {
      return state;
    }

    return state.updateIn(['edges', index], (edge) =>
      edge.setIn(['node', 'unreadMessagesCount'], 0)
    );
  },

  /**
   * Cancel start new conversation with a colleague
   *
   * @param {Object} state
   */
  [COLLEAGUE_INBOX_NEW_THREAD_CANCEL]: (state) => {
    return state
      .set('showStartNewThread', false)
      .setIn(['newThread', 'loading'], false)
      .setIn(['newThread', 'text'], '')
      .setIn(['newThread', 'edges'], fromJS([]));
  },

  /**
   * Show start new conversation with a colleague
   *
   * @param {Object} state
   */
  [COLLEAGUE_INBOX_NEW_THREAD_SHOW]: (state) => {
    return state.setIn(['newThread', 'loading'], true).set('showStartNewThread', true);
  },

  /**
   * Search a colleague to start a conversation
   *
   * @param {Object} state
   * @param {String} text
   */
  [COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE]: (state, {text}) => {
    return state.setIn(['newThread', 'text'], text).setIn(['newThread', 'loading'], true);
  },

  /**
   * Reset input to start conversation with a colleague
   *
   * @param {Object} state
   */
  [COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_INPUT_RESET]: (state) => {
    return state.setIn(['newThread', 'text'], '').setIn(['newThread', 'edges'], fromJS([]));
  },

  /**
   * Action when success search a colleague to start a conversation
   *
   * @param {Object} state
   * @param {Array<Object>} edges
   * @returns {Map} new state
   */
  [COLLEAGUE_INBOX_NEW_THREAD_SEARCH_COLLEAGUE_SUCCESS]: (state, {edges}) => {
    return state
      .setIn(['newThread', 'edges'], fromJS(edges))
      .setIn(['newThread', 'loading'], false);
  },

  /**
   * Start new conversation with a colleague
   *
   * @param {Object} state
   * @returns {Map} new state
   */
  [COLLEAGUE_NEW_THREAD_LOAD]: (state) => {
    return state
      .set('selected', null)
      .set('showStartNewThread', false)
      .setIn(['newThread', 'text'], '')
      .setIn(['newThread', 'edges'], fromJS([]));
  }
});
