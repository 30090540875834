import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import getStandardParamsFromState from '../lib/get-standard-params-from-state';
import prepareRequestParams from '../lib/prepare-request-params';
import {DEBOUNCE_TIME_STATISTIC} from '../../../../data/settings';
import {
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_FAILURE,
  INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_SUCCESS
} from '../../../../actions/insight-actions';

const InsightContactCentersWebConnectedUsersEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadInsights = action$.pipe(
      ofType(
        INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_BUSINESSES_SELECTION_CHANGE,
        INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_OPEN
      ),
      map(() => {
        return {
          type: INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD,
          businessesSelection: getStandardParamsFromState(state$).businessesSelection
        };
      })
    );

    const loadWebConnectedUsers = action$.pipe(
      ofType(INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD),
      debounceTime(DEBOUNCE_TIME_STATISTIC),
      mergeMap(({businessesSelection}) => {
        return graphql(
          'get-real-time-query',
          prepareRequestParams(state$, {businessesSelection})
        ).pipe(
          map(({realTime}) => {
            return {
              type: INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_SUCCESS,
              webConnectedUsers: realTime
            };
          }),
          catchError((error) => {
            return of({
              type: INSIGHT_CONTACT_CENTERS_WEB_CONNECTED_USERS_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(loadInsights, loadWebConnectedUsers);
  };

export default InsightContactCentersWebConnectedUsersEpic;
