import {SketchPicker} from 'react-color';
import PropTypes from 'prop-types';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import context from '../../../../../../../shared/component/context';
import {getI18NWidgetPrefix} from '../../lib/integration-area-widgets-helper';
import WidgetPage, {
  SECTION_TITLE_STYLE,
  SECTION_SUBTITLE_STYLE,
  SECTION_DESCRIPTION_STYLE,
  SECTION_BLOCK_STYLE,
  WIDGET_PAGES_COMMON_PROP_TYPES
} from './common/widget-page-component';
import {blue, slate} from '../../../../../../../shared/style/colors';

/**
 * Finally, the component.
 */
class BubbleWidgetComponent extends WidgetPage {
  constructor(props) {
    super(props);

    this.state = {
      containerId: 'iw-bubble-container',
      i18nWidgetPrefix: getI18NWidgetPrefix('bubble'),
      scriptFile: 'bubble',
      widgetType: 'bubble',
      snippetProps: [
        'lang',
        'bgColor',
        'color',
        'defaultContact',
        'contactChoice',
        'enableMobile',
        'businessId',
        'bubbleTooltip',
        'errorInvalidEmail',
        'errorInvalidPhone',
        'placeholderEmail',
        'placeholderMsg',
        'placeholderPhone',
        'thankyouMsg',
        'hasBorder',
        'borderColor',
        'shadowOpacity',
        'side'
      ]
    };

    this._doBorderColorChange = this._doBorderColorChange.bind(this);
    this._doBubbleTooltipChange = this._doBubbleTooltipChange.bind(this);
    this._doChangeSide = this._doChangeSide.bind(this);
    this._doHasBorderChange = this._doHasBorderChange.bind(this);
    this._doHasShadowChange = this._doHasShadowChange.bind(this);
  }

  _doBorderColorChange(color) {
    const {widgetWindow} = this.state;

    widgetWindow._iwq.borderColor = color.hex;
    this.__updateWidget();

    this.props.doChangeBorderColor(color.hex);
  }

  _doBubbleTooltipChange(e, text) {
    const {widgetWindow} = this.state;

    const newText = text.trim();

    widgetWindow._iwq.bubbleTooltip = newText;
    this.__updateWidget();

    this.props.doChangeBubbleTooltip(newText);
  }

  _doChangeSide(e, side) {
    const {widgetWindow} = this.state;

    widgetWindow._iwq.side = side;
    this.__updateWidget();

    this.props.doChangeSide(side);
  }

  _doHasBorderChange(e, hasBorder) {
    const {widgetWindow} = this.state;

    widgetWindow._iwq.hasBorder = hasBorder;
    this.__updateWidget();

    this.props.doChangeHasBorder(hasBorder);
  }

  _doHasShadowChange(e, hasShadow) {
    const {widgetWindow} = this.state;

    widgetWindow._iwq.shadowOpacity = hasShadow ? 0.3 : 0;
    this.__updateWidget();

    this.props.doChangeHasShadow(hasShadow);
  }

  render() {
    const {i18n} = this.context;
    const {borderColor, businessId, hasBorder, hasShadow, side, tooltip} = this.props;

    return this._renderLayout(
      'floating-action-button-wireframe.png',
      'bubble.html',
      '300px',
      businessId,
      i18n.t('integrationArea.widgets.bubble.finalResultAndCode.description'),
      <div>
        {this._renderSingleBusinessSelector('businessId', this._doSingleBusinessSelect)}

        {this._renderSectionBehaviour()}

        {this._renderSectionStyleAndCustomize(
          <div>
            {this._renderLanguage()}

            <div style={SECTION_BLOCK_STYLE}>
              <TextField
                defaultValue={tooltip.trim()}
                floatingLabelFixed
                floatingLabelStyle={{color: tooltip ? blue : slate}}
                floatingLabelText={i18n.t(
                  'integrationArea.widgets.bubble.sectionStyleAndCustomize.tooltip.label'
                )}
                fullWidth
                hintText={i18n.t(
                  'integrationArea.widgets.bubble.sectionStyleAndCustomize.tooltip.hint'
                )}
                onChange={this._doBubbleTooltipChange}
              />
              <span style={SECTION_DESCRIPTION_STYLE}>
                {i18n.t(
                  'integrationArea.widgets.bubble.sectionStyleAndCustomize.tooltip.description'
                )}
              </span>
            </div>

            {this._renderInputPlaceholder()}

            {this._renderInputPlaceholderPhone()}
            {this._renderInputPlaceholderEmail()}
            {this._renderErrorInvalidEmail()}
            {this._renderErrorInvalidPhone()}

            {this._renderThankYouMessage()}

            <div style={SECTION_BLOCK_STYLE}>
              <h3 style={SECTION_TITLE_STYLE}>
                {i18n.t('integrationArea.widgets.bubble.sectionStyleAndCustomize.side.title')}
              </h3>
              <RadioButtonGroup defaultSelected={side} name="side" onChange={this._doChangeSide}>
                <RadioButton
                  label={i18n.t(
                    'integrationArea.widgets.bubble.sectionStyleAndCustomize.side.sideOption.left'
                  )}
                  value="left"
                  style={{
                    marginBottom: '5px'
                  }}
                />
                <RadioButton
                  data-test-id="side-option"
                  label={i18n.t(
                    'integrationArea.widgets.bubble.sectionStyleAndCustomize.side.sideOption.center'
                  )}
                  value="center"
                  style={{
                    marginBottom: '5px'
                  }}
                />
                <RadioButton
                  label={i18n.t(
                    'integrationArea.widgets.bubble.sectionStyleAndCustomize.side.sideOption.right'
                  )}
                  value="right"
                  style={{
                    marginBottom: '5px'
                  }}
                />
              </RadioButtonGroup>
            </div>

            <div style={SECTION_BLOCK_STYLE}>
              <h3 style={SECTION_TITLE_STYLE}>
                {i18n.t('integrationArea.widgets.bubble.sectionStyleAndCustomize.border.title')}
              </h3>
              <span style={SECTION_SUBTITLE_STYLE}>
                <Checkbox
                  checked={hasBorder}
                  label={i18n.t(
                    'integrationArea.widgets.bubble.sectionStyleAndCustomize.border.label'
                  )}
                  onCheck={this._doHasBorderChange}
                />

                {hasBorder ? (
                  <div style={{padding: '20px'}}>
                    <SketchPicker
                      color={borderColor}
                      onChangeComplete={this._doBorderColorChange}
                    />
                  </div>
                ) : null}
              </span>
            </div>

            <div style={SECTION_BLOCK_STYLE}>
              <h3 style={SECTION_TITLE_STYLE}>
                {i18n.t('integrationArea.widgets.bubble.sectionStyleAndCustomize.shadow.title')}
              </h3>
              <span style={SECTION_SUBTITLE_STYLE}>
                <Checkbox
                  checked={hasShadow}
                  label={i18n.t(
                    'integrationArea.widgets.bubble.sectionStyleAndCustomize.shadow.label'
                  )}
                  onCheck={this._doHasShadowChange}
                />
              </span>
            </div>

            {this._renderBackgroundColorPicker()}
          </div>
        )}
      </div>
    );
  }
}

BubbleWidgetComponent.contextTypes = context;

BubbleWidgetComponent.propTypes = {
  bgColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  businessesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessId: PropTypes.string.isRequired,
  communicationMethod: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool.isRequired,
  hasShadow: PropTypes.bool.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  thankYouMessage: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  doChangeBackgroundColor: PropTypes.func.isRequired,
  doChangeBorderColor: PropTypes.func.isRequired,
  doChangeBubbleTooltip: PropTypes.func.isRequired,
  doChangeBusiness: PropTypes.func.isRequired,
  doChangeErrorInvalidEmail: PropTypes.func,
  doChangeErrorInvalidPhone: PropTypes.func,
  doChangeHasBorder: PropTypes.func.isRequired,
  doChangeHasShadow: PropTypes.func.isRequired,
  doChangeCommunicationMethod: PropTypes.func.isRequired,
  doChangeInputPlaceholder: PropTypes.func.isRequired,
  doChangeInputPlaceholderEmail: PropTypes.func.isRequired,
  doChangeInputPlaceholderPhone: PropTypes.func.isRequired,
  doChangeSide: PropTypes.func.isRequired,
  doChangeThankYouMessage: PropTypes.func.isRequired,
  ...WIDGET_PAGES_COMMON_PROP_TYPES
};

export default BubbleWidgetComponent;
