import PropTypes from 'prop-types';
import {fontSize, fontWeight, heightSize, spacing} from '../../../../../shared/style/variables';
import {black} from '../../../../../shared/style/colors';

export const InboxHeaderLayout = ({children, dataTestId, style}) => (
  <div
    data-test-id={dataTestId}
    style={{
      display: 'flex',
      minHeight: heightSize.normal,
      height: heightSize.normal,
      width: '100%',
      borderCollapse: 'collapse',
      ...style
    }}
  >
    {children}
  </div>
);

InboxHeaderLayout.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any)
};

export const InboxHeaderTitle = ({dataTestId, style, children}) => (
  <h3
    data-test-id={dataTestId || 'inbox-title'}
    style={{
      color: black,
      fontSize: fontSize.xlarge,
      lineHeight: '23px',
      fontWeight: fontWeight.light,
      width: '100%',
      padding: spacing.medium,
      ...style
    }}
  >
    {children}
  </h3>
);

InboxHeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any)
};
