import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  DEFAULT_STATE_WIDGET_COMMON,
  errorInvalidEmailChange,
  errorInvalidPhoneChange,
  inputPlaceHolderEmailChange,
  inputPlaceHolderPhoneChange,
  languageChange,
  snippetChange,
  snippetCopiedChange,
  thankYouMessageChange
} from '../../../../lib/widget-helper';
import {
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ADD_NEW_BUSINESS,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_BACKGROUND_COLOR_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_COMMUNICATION_METHOD_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_DELETE_BUSINESS,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_EMPTY_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_HEADER_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_HELPER_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_LANGUAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_MESSAGE_PLACEHOLDER_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_FOR_BUSINESS_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_RESET_STATE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_BUTTON_TEXT_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_NEW_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_THANKYOU_MESSAGE_CHANGE
} from '../../../../../actions/integration-area-widgets-actions';

export const DEFAULT_STATE = {
  ...DEFAULT_STATE_WIDGET_COMMON,
  addNewBusiness: true,
  bgColor: '',
  businessId: '',
  communicationMethod: '',
  contactChoice: true,
  defaultContact: 'email',
  errorEmptyMessage: '',
  errorInvalidEmail: '',
  errorInvalidPhone: '',
  headerText: '',
  helperText: '',
  inputPlaceholderEmail: '',
  inputPlaceholderPhone: '',
  placeholderSelect: false,
  placeholderSelectText: '',
  sendButtonText: '',
  sendNewMessage: '',
  textBodyPlaceholder: '',
  thankYouMessage: '',
  businesses: []
};

export default createReducer(DEFAULT_STATE, {
  // Common
  [INTEGRATION_AREA_WIDGET_EMBED_FORM_LANGUAGE_CHANGE]: languageChange,

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_CHANGE]: snippetChange,

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_SNIPPET_COPIED_CHANGE]: snippetCopiedChange,

  // Specific
  [INTEGRATION_AREA_WIDGET_EMBED_FORM_RESET_STATE]: () => {
    return fromJS(DEFAULT_STATE);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_BUSINESS_CHANGE]: (state, {business}) => {
    const newBusinesses = state.updateIn(['businesses'], (businesses) => {
      return businesses.push(
        fromJS({
          disabled: true,
          id: business.businessId,
          name: business.name,
          placeholder: ''
        })
      );
    });

    const thereIsOnlyOne = newBusinesses.get('businesses').size === 1;

    return newBusinesses
      .set('addNewBusiness', false)
      .set('businessId', thereIsOnlyOne ? newBusinesses.get('businesses').first().get('id') : '');
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_DELETE_BUSINESS]: (state, {businessId}) => {
    const newBusinesses = state.updateIn(['businesses'], (businesses) => {
      return businesses.filter((b) => b.get('id') !== businessId);
    });

    const thereIsOnlyOne = newBusinesses.get('businesses').size === 1;

    return newBusinesses
      .set('addNewBusiness', !newBusinesses.get('businesses').size)
      .set('businessId', thereIsOnlyOne ? newBusinesses.get('businesses').first().get('id') : '');
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_ADD_NEW_BUSINESS]: (state, {addNew}) => {
    return state.set('addNewBusiness', addNew);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_COMMUNICATION_METHOD_CHANGE]: (state, {method}) => {
    return state.set('communicationMethod', method).set('defaultContact', method || 'email');
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_BACKGROUND_COLOR_CHANGE]: (state, {color}) => {
    return state.set('bgColor', color);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_EMPTY_MESSAGE_CHANGE]: (state, {text}) =>
    state.set('errorEmptyMessage', text),

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_EMAIL_CHANGE]: errorInvalidEmailChange,

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_PHONE_CHANGE]: errorInvalidPhoneChange,

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_EMAIL_CHANGE]: inputPlaceHolderEmailChange,

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_PHONE_CHANGE]: inputPlaceHolderPhoneChange,

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_FOR_BUSINESS_CHANGE]: (
    state,
    {index, placeholder}
  ) => {
    return state.setIn(['businesses', index, 'placeholder'], placeholder);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_CHANGE]: (state, {value}) => {
    return state.set('placeholderSelect', value);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_PLACEHOLDER_SELECT_TEXT_CHANGE]: (state, {text}) => {
    return state.set('placeholderSelectText', text);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_HEADER_TEXT_CHANGE]: (state, {text}) => {
    return state.set('headerText', text);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_MESSAGE_PLACEHOLDER_CHANGE]: (state, {text}) => {
    return state.set('textBodyPlaceholder', text);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_HELPER_TEXT_CHANGE]: (state, {text}) => {
    return state.set('helperText', text);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_BUTTON_TEXT_CHANGE]: (state, {text}) => {
    return state.set('sendButtonText', text);
  },

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_SEND_NEW_MESSAGE_CHANGE]: (state, {text}) =>
    state.set('sendNewMessage', text),

  [INTEGRATION_AREA_WIDGET_EMBED_FORM_THANKYOU_MESSAGE_CHANGE]: thankYouMessageChange
});
