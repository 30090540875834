import {
  INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_BUBBLE_THANKYOU_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_CTA_THANKYOU_MESSAGE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_EMAIL_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_PHONE_CHANGE,
  INTEGRATION_AREA_WIDGET_EMBED_FORM_THANKYOU_MESSAGE_CHANGE
} from '../../../../../../actions/integration-area-widgets-actions';

const doChangeErrorInvalidEmail = (widgetType, text) => {
  const TYPE_ACTION_MAPPING = {
    bubble: INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_EMAIL_CHANGE,
    cta: INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_EMAIL_CHANGE,
    embedForm: INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_EMAIL_CHANGE
  };

  return {
    type: TYPE_ACTION_MAPPING[widgetType],
    text
  };
};

const doChangeErrorInvalidPhone = (widgetType, text) => {
  const TYPE_ACTION_MAPPING = {
    bubble: INTEGRATION_AREA_WIDGET_BUBBLE_ERROR_INVALID_PHONE_CHANGE,
    cta: INTEGRATION_AREA_WIDGET_CTA_ERROR_INVALID_PHONE_CHANGE,
    embedForm: INTEGRATION_AREA_WIDGET_EMBED_FORM_ERROR_INVALID_PHONE_CHANGE
  };

  return {
    type: TYPE_ACTION_MAPPING[widgetType],
    text
  };
};

const doChangeInputPlaceholderEmail = (widgetType, text) => {
  const TYPE_ACTION_MAPPING = {
    bubble: INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_EMAIL_CHANGE,
    cta: INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_EMAIL_CHANGE,
    embedForm: INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_EMAIL_CHANGE
  };

  return {
    type: TYPE_ACTION_MAPPING[widgetType],
    text
  };
};

const doChangeInputPlaceholderPhone = (widgetType, text) => {
  const TYPE_ACTION_MAPPING = {
    bubble: INTEGRATION_AREA_WIDGET_BUBBLE_INPUT_PLACEHOLDER_PHONE_CHANGE,
    cta: INTEGRATION_AREA_WIDGET_CTA_INPUT_PLACEHOLDER_PHONE_CHANGE,
    embedForm: INTEGRATION_AREA_WIDGET_EMBED_FORM_INPUT_PLACEHOLDER_PHONE_CHANGE
  };

  return {
    type: TYPE_ACTION_MAPPING[widgetType],
    text
  };
};

const doChangeThankYouMessage = (widgetType, text) => {
  const TYPE_ACTION_MAPPING = {
    bubble: INTEGRATION_AREA_WIDGET_BUBBLE_THANKYOU_MESSAGE_CHANGE,
    cta: INTEGRATION_AREA_WIDGET_CTA_THANKYOU_MESSAGE_CHANGE,
    embedForm: INTEGRATION_AREA_WIDGET_EMBED_FORM_THANKYOU_MESSAGE_CHANGE
  };

  return {
    type: TYPE_ACTION_MAPPING[widgetType],
    text
  };
};

export {
  doChangeErrorInvalidEmail,
  doChangeErrorInvalidPhone,
  doChangeInputPlaceholderEmail,
  doChangeInputPlaceholderPhone,
  doChangeThankYouMessage
};
