import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import assert from '../../../../shared/lib/assert';
import {
  APP_STATE_BOOT_LOAD_SUCCESS,
  APP_STATE_REFRESH_LOAD_SUCCESS
} from '../../actions/app-actions';
import {COLLEAGUE_INBOX_LEAVE} from '../../actions/colleague-inbox-actions';
import {
  COLLEAGUE_THREAD_LEAVE,
  COLLEAGUE_THREAD_LOAD_SUCCESS
} from '../../actions/colleague-thread-actions';
import {CUSTOMER_INBOX_LEAVE} from '../../actions/customer-inbox-actions';
import {CUSTOMER_THREAD_PAGE_LEAVE} from '../../actions/customer-thread-actions';
import {LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS} from '../../actions/customer-thread-visible-actions';
import {
  EXT_COLLEAGUE_SENT_MESSAGE_TO_ME,
  EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION,
  LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER,
  EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD,
  EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS
} from '../../actions/ext-actions';

export const DEFAULT_STATE = {
  canFilterCustomerThreadsByBusiness: false,
  emailAddress: null,
  emailVerified: null,
  fullName: null,
  href: null,
  id: null,
  isAllowedToSeeCustomerThreadsOfAllBusinesses: false,
  manager: false,
  managerOfABusiness: false,
  picture: null,
  organizationId: null,
  organizationName: null,
  organizationPicture: null,
  selectedParticipationId: null,
  showInvisibleThread: true,
  totalBusinessCount: null,
  unreadColleagueThreadCount: 0,
  unreadCustomerThreadCount: 0,
  businesses: []
};

const resetSelectedThread = (state) => state.set('selectedParticipationId', null);

/**
 * Update state when a new message is received:
 * - update unread customer thread count
 *
 * @param {Object} state
 * @param {Object} data
 * @returns {Object} new state
 */
const onReceiveMessage = (state, {data}) => {
  if (data.acknowledged) {
    return state;
  }

  return state.set('unreadCustomerThreadCount', data.unreadCustomerThreadCount);
};

/**
 * Update the state after the thread made some big move (status update, transfer...).
 *
 * @param {Object} state
 * @param {Object} data
 * @returns {Object} new state
 */
const updateStateAfterThreadMove = (state, {data}) => {
  // @todo decide if we always refresh unread counter
  // eslint-disable-next-line eqeqeq
  if (state.get('selectedParticipationId') == data.participationId) {
    return state;
  }

  return state.set('unreadCustomerThreadCount', data.unreadCustomerThreadCount);
};

/**
 * Finally, the reducer.
 */
export default createReducer(DEFAULT_STATE, {
  /**
   * Merge the state with the result of the server resource
   * and set as not loading.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [APP_STATE_BOOT_LOAD_SUCCESS]: (state, {data}) => {
    assert(data.account.fullName);

    return state
      .merge(fromJS(data.account))
      .set(
        'managerOfABusiness',
        data.account.businesses
          ? data.account.businesses.findIndex((business) => business.manager) > -1
          : false
      )
      .set('unreadColleagueThreadCount', data.unreadColleagueThreadCount)
      .set('unreadCustomerThreadCount', data.unreadCustomerThreadCount);
  },

  /**
   * Update few properties that could need refresh
   * when app state is reloaded from server.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [APP_STATE_REFRESH_LOAD_SUCCESS]: (state, {data}) => {
    return state
      .set('unreadColleagueThreadCount', data.unreadColleagueThreadCount)
      .set('unreadCustomerThreadCount', data.unreadCustomerThreadCount);
  },

  /**
   * Mark the thread as being opened so we can avoid counter increments.
   *
   * We are not doing this change in COLLEAGUE_INBOX_THREADS_LIST_SELECT_THREAD action since their is no guarantee that this
   * action is fired when user refresh the page.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [COLLEAGUE_THREAD_LOAD_SUCCESS]: (state, {data}) => {
    const newState = state.set('selectedParticipationId', data.id);

    if (data.unreadMessagesCount) {
      return newState.update('unreadColleagueThreadCount', (counter) => counter - 1);
    }

    return newState;
  },

  /**
   * Mark the thread as being opened so we can avoid counter increments.
   *
   * We are not doing this change in select_thread action since their is no guarantee that this
   * action is fired when user refresh the page.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [LEGACY_CUSTOMER_THREAD_VISIBLE_LOAD_SUCCESS]: (state, {data}) => {
    const newState = state.set('selectedParticipationId', data.id);

    if (data.status !== 'waiting' || !data.unreadMessagesCount) {
      return newState;
    }

    return newState.update('unreadCustomerThreadCount', (counter) => counter - 1);
  },

  /**
   * Reset selected thread when leaving a thread component or an inbox
   * in order to prevent badges from not being correctly updated.
   */
  [COLLEAGUE_INBOX_LEAVE]: resetSelectedThread,
  [COLLEAGUE_THREAD_LEAVE]: resetSelectedThread,
  [CUSTOMER_INBOX_LEAVE]: resetSelectedThread,
  [CUSTOMER_THREAD_PAGE_LEAVE]: resetSelectedThread,

  /**
   * Update unread colleague thread count.
   *
   * @param {Object} state
   * @param {Object} data
   * @returns {Object} new state
   */
  [EXT_COLLEAGUE_SENT_MESSAGE_TO_ME]: (state, {data}) => {
    if (data.acknowledged) {
      return state;
    }

    return state.set('unreadColleagueThreadCount', data.unreadColleagueThreadCount);
  },

  /**
   * Update state when a new message is received.
   */
  [EXT_CUSTOMER_SENT_MESSAGE_TO_ORGANIZATION]: onReceiveMessage,
  [EXT_EMPLOYEE_SENT_MESSAGE_TO_CUSTOMER]: onReceiveMessage,
  [EXT_EMPLOYEE_SENT_MESSAGE_TO_INVISIBLE_THREAD]: onReceiveMessage,

  /**
   * Update unread customer message count after a customer thread "move":
   * - status change
   * - transfer
   */
  [LEGACY_EXT_CUSTOMER_THREAD_STATUS_CHANGED]: updateStateAfterThreadMove,
  [EXT_EMPLOYEE_TRANSFERRED_CUSTOMER_THREAD_TO_BUSINESS]: updateStateAfterThreadMove
});
