import {merge, of} from 'rxjs';
import {catchError, debounceTime, delay, filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {filterByMultistoreConversationDisabled} from '../../../../lib/feature-toggle-helper';
import goToThreadUrl from '../../../lib/go-to-thread-url';
import {
  CUSTOMER_INBOX_GO_TO_THREAD,
  CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_FAILURE,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_SUCCESS,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_START,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER,
  LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER_SUCCESS
} from '../../../../actions/customer-inbox-actions';
import {LEGACY_CUSTOMER_THREAD_TRANSFER} from '../../../../actions/customer-thread-transfer-actions';
import {
  DEBOUNCE_TIME_MULTIPLE_CLICK,
  DEBOUNCE_TIME_TYPING_FILTER,
  DELAY_TRANSFER_BACK_IN_SCOPE_REDIRECT
} from '../../../../../../shared/data/settings';

const getActionToGoToThread = (participationId) => ({
  type: CUSTOMER_INBOX_GO_TO_THREAD,
  participationId
});

/**
 * Finally, the epic.
 */
const LegacyCustomerInboxNewThreadEpic =
  ({query}) =>
  (action$, state$) => {
    const legacyForwardThreadTransferToTransferAction = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER),
      map(({businessId, businessName, participationId}) => {
        return {
          type: LEGACY_CUSTOMER_THREAD_TRANSFER,
          participationId,
          fullName: state$.value.getIn(['account', 'fullName']),
          id: businessId,
          inScope: true,
          name: businessName,
          needInboxRefresh: true,
          next: {
            type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER_SUCCESS,
            participationId
          }
        };
      })
    );

    const legacySearchCustomer = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH),
      mergeMap(({payload: {country, text}}) => {
        return query('search-customer', {
          customersLimit: 5,
          customersOffset: 0,
          messagesLimit: 25,
          messagesOffset: 0,
          country,
          text
        }).pipe(
          map(({data: {formattedPhoneNumber, items, isValidEmail, isValidSmsPhoneNumber}}) => {
            return {
              type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_SUCCESS,
              formattedPhoneNumber,
              isValidEmail,
              isValidSmsPhoneNumber,
              items
            };
          }),
          takeUntil(action$.pipe(ofType(LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH))),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH_FAILURE,
              error
            });
          })
        );
      })
    );

    const legacySearchCustomersOnNewConversationFormChanged = action$.pipe(
      ofType(CUSTOMER_INBOX_NEW_CONVERSATION_FORM_CHANGED),
      filter(filterByMultistoreConversationDisabled(state$)),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      filter(({payload: {text}}) => text !== ''),
      map(({payload}) => ({
        type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SEARCH,
        payload
      }))
    );

    const legacySelectThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_NEW_THREAD_CUSTOMER_SELECT),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({businessId, businessName, inScope, participationId}) => {
        // Some organizations are allowed to transfer back in their scope the threads that are out of current employee scope
        if (
          inScope === false &&
          state$.value.getIn(['featureToggle', 'transfer_back_in_scope']) === true
        ) {
          return {
            type: LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER,
            businessId,
            businessName,
            participationId
          };
        }

        return getActionToGoToThread(participationId);
      })
    );

    const legacyStartThread = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_NEW_THREAD_START),
      debounceTime(DEBOUNCE_TIME_MULTIPLE_CLICK),
      map(({businessId, businessName, email, phoneNumber}) =>
        goToThreadUrl('customers', 'new', {
          businessId,
          businessName,
          email,
          phoneNumber
        })
      )
    );

    const legacyGoToThreadUrlAfterTransferSuccess = action$.pipe(
      ofType(LEGACY_CUSTOMER_INBOX_NEW_THREAD_TRANSFER_SUCCESS),
      delay(DELAY_TRANSFER_BACK_IN_SCOPE_REDIRECT), // let backend really finish transfer stuff, see AWI-5882
      map(({participationId}) => getActionToGoToThread(participationId))
    );

    return merge(
      legacyForwardThreadTransferToTransferAction,
      legacyGoToThreadUrlAfterTransferSuccess,
      legacySearchCustomer,
      legacySearchCustomersOnNewConversationFormChanged,
      legacySelectThread,
      legacyStartThread
    );
  };

export default LegacyCustomerInboxNewThreadEpic;
