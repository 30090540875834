import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  APP_THREAD_FILE_PREVIEW_CLOSE,
  APP_THREAD_FILE_PREVIEW_OPEN
} from '../../../actions/thread-actions';

export const DEFAULT_STATE = {
  open: false,
  src: null
};

export default createReducer(DEFAULT_STATE, {
  [APP_THREAD_FILE_PREVIEW_OPEN]: (state, {src}) => {
    return state.set('open', true).set('src', src);
  },

  [APP_THREAD_FILE_PREVIEW_CLOSE]: () => {
    return fromJS(DEFAULT_STATE);
  }
});
