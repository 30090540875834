export const HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ =
  'HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ';
export const HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_FAILURE =
  'HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_FAILURE';
export const HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_SUCCESS =
  'HEADER_MENTIONS_MARK_USER_HAS_NEW_MENTIONS_AS_READ_SUCCESS';

export const HEADER_MENTIONS_POPUP_CLOSE = 'HEADER_MENTIONS_POPUP_CLOSE';
export const HEADER_MENTIONS_POPUP_OPEN = 'HEADER_MENTIONS_POPUP_OPEN';

export const HEADER_MENTIONS_POPUP_ITEM_CLICK = 'HEADER_MENTIONS_POPUP_ITEM_CLICK';
export const HEADER_MENTIONS_POPUP_ITEM_OPEN = 'HEADER_MENTIONS_POPUP_ITEM_OPEN';

export const HEADER_MENTIONS_POPUP_ITEMS_LOAD = 'HEADER_MENTIONS_POPUP_ITEMS_LOAD';
export const HEADER_MENTIONS_POPUP_ITEMS_LOAD_FAILURE = 'HEADER_MENTIONS_POPUP_ITEMS_LOAD_FAILURE';
export const HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS = 'HEADER_MENTIONS_POPUP_ITEMS_LOAD_SUCCESS';

export const HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ = 'HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ';
export const HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_FAILURE =
  'HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_FAILURE';
export const HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_SUCCESS =
  'HEADER_MENTIONS_POPUP_MARK_ONE_AS_READ_SUCCESS';

// Legacy
export const LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN = 'LEGACY_HEADER_MENTIONS_POPUP_ITEM_OPEN';
