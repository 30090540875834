import {
  ADMINISTRATION_BUSINESS_OPEN,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_OPEN,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_RESTART_DATA_TO_RECONNECT
} from '../../../../actions/administration-business-actions';

import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_LAYOUT_MOUNTED
} from '../../../../actions/administration/administration-business-channels-gbm-actions';

export default {
  doAdministrationBusinessPageOpen: (businessId) => ({
    type: ADMINISTRATION_BUSINESS_OPEN,
    businessId
  }),
  doAdministrationBusinessPageChannelsOpen: (businessId) => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_OPEN,
    _googleAnalytics: true,
    businessId
  }),
  doStoreFacebookChannelRetrievedToken: (accessToken) => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_RETRIEVED_TOKEN,
    _googleAnalytics: true,
    accessToken
  }),
  doFacebookPageConnection: (businessId, facebookPageId = null) => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_CONNECT,
    _googleAnalytics: true,
    businessId,
    facebookPageId
  }),
  doFacebookPageDisconnection: (businessId) => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_DISCONNECT,
    _googleAnalytics: true,
    businessId
  }),
  doFacebookPageRestartDataToReconnection: () => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_FACEBOOK_PAGE_RESTART_DATA_TO_RECONNECT,
    _googleAnalytics: true
  }),

  // GBM
  onAdministrationBusinessPageChannelsGbmMounted: () => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_LAYOUT_MOUNTED
  }),
  onGbmBusinessLocationFormSubmitButtonClick: (fields) => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_FORM_SUBMIT_BUTTON_CLICK,
    _googleAnalytics: true,
    payload: {
      fields
    }
  }),
  onDisconnectGbmBusinesLocationButtonClick: () => ({
    type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_GBM_BUSINESS_LOCATION_DISCONNECT_BUTTON_CLICK,
    _googleAnalytics: true
  })
};
