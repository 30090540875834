import {PureComponent} from 'react';
import PropTypes from 'prop-types';

class TermsOfServiceLink extends PureComponent {
  render() {
    const {children} = this.props;

    return (
      <a
        data-test-id="terms-of-service-link"
        href="https://www.instaply.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
}

TermsOfServiceLink.propTypes = {
  children: PropTypes.string.isRequired
};

export default TermsOfServiceLink;
