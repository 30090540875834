import {merge, of} from 'rxjs';
import {catchError, debounceTime, map, mapTo, mergeMap, takeUntil} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {DEBOUNCE_TIME_TYPING_FILTER} from '../../../../../../shared/data/settings';
import {
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE,
  LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS
} from '../../../../actions/customer-thread-invisible-mention-popup-actions';

const LegacyCustomerThreadInvisibleMentionEpic =
  ({graphql}) =>
  (action$, state$) => {
    const searchColleaguesOnOpenMention = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_OPEN),
      mapTo({
        type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE
      })
    );

    const searchColleaguesToMention = action$.pipe(
      ofType(LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE),
      debounceTime(DEBOUNCE_TIME_TYPING_FILTER),
      mergeMap(({text = ''}) => {
        return graphql('search-colleagues-to-mention-query', {
          text,
          businessIds: state$.value.getIn(['legacyCustomerThread', 'directedToBusinessIdentifier'])
        }).pipe(
          map(({colleagueSearch}) => {
            return {
              type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_SUCCESS,
              items: colleagueSearch.edges.map(({node}) => node)
            };
          }),
          takeUntil(
            action$.pipe(ofType(LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE))
          ),
          catchError((error) => {
            return of({
              type: LEGACY_CUSTOMER_THREAD_INVISIBLE_MENTION_POPUP_SEARCH_COLLEAGUE_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(searchColleaguesOnOpenMention, searchColleaguesToMention);
  };

export default LegacyCustomerThreadInvisibleMentionEpic;
